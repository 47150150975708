import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";
import { ScrollingModule } from "@angular/cdk/scrolling";

// Cesium
import { CesiumMapModule } from "../cesium-map-module/cesium-map.module";

// Games
import { GamesModule } from "../games-module/games.module";

// Nav
import { NavModule } from "../nav-module/nav.module";

// Notification
import { NotificationModule } from "../notification-module/notification.module";

// Panel
import { MainPanelComponent } from "../panel-module/main-panel/main-panel.component";

// Routes
import { RoutesModule } from "../../routes.module";

// Spinner
import { NgxSpinnerModule } from "ngx-spinner";

// Subscriber
import { SubscriberModule } from "../subscriber-module/subscriber.module";

// Task
import { TaskModule } from "../task-module/task.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,
    ScrollingModule,

    // Cesium
    CesiumMapModule,

    // Games
    GamesModule,

    // Notification
    NotificationModule,

    // Nav
    NavModule,

    // Routes
    RoutesModule,

    // Spinner
    NgxSpinnerModule,

    // Subscriber
    SubscriberModule,

    // Task
    TaskModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [MainPanelComponent],
  exports: [MainPanelComponent],
})
export class PanelModule {}
