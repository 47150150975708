// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterService } from "../../../devices/meter/MeterService.service";
// Componentes
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableCupsFilters,
} from "../../../../../modules/table-module/TableInterface.type";
import { Balance, BalanceEdition } from "../../DataAnalysisInterface.type";
import {
  SectorCups,
  SectorDevice,
} from "../../../data-management/DataManagementInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-newhydraulicBalance",
  templateUrl: "./data-analysis-balance-new.component.html",
  styleUrls: ["./data-analysis-balance-new.component.scss"],
})
export class NewHydraulicBalanceComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Balance
  balanceId: number;
  balance: Balance;

  // Tabla
  exportFileName: string;
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  maxReg: number = 100;
  tableData: SectorCups[];
  tableDataOriginal: SectorCups[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  filters: TableCupsFilters[];
  selectedChilds: number[];

  // Mapa
  ignoreTableUpdate: boolean = true;
  mapData: SectorDevice[];
  mapType: string = "balanceEdition";
  metersData: SectorCups[];
  activateAllLayers: boolean = true;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  // Guardado
  saveIcon = "fas fa-save";
  saveTitle = this.translate.instant("save");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private location: Location,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.balanceId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.balance =
      this.balanceId && history.state.data ? history.state.data : new Balance();
    this.selectedChilds =
      !this.balanceId && history.state.data ? history.state.data : null;
    this.exportFileName = this.balance.name;
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    let httpRequest: Observable<object> = this.balanceId
      ? this.DataAnalysisController.show(this.balanceId)
      : this.DataAnalysisController.table(this.currentAgrupation.id);

    httpRequest.subscribe((response) => {
      if (response["code"] == 0) {
        // CUPS
        let entityCupsData = this.MeterService.getEntityCupsData();
        this.tableCupsColumns = entityCupsData.entityCupsColumns;
        this.cups = entityCupsData.entityCups;
        this.entityNroSerie = entityCupsData.entityNroSerie;

        this.setColumns();
        let tableData: SectorCups[] = response["body"]["sectorDeviceList"];

        tableData.map((cups: SectorCups) => {
          let nroSerieListExport = [];
          cups.nroSerieList = `<div class="table-meter-list">`;
          cups.meters.forEach((meter) => {
            cups.nroSerieList += `<span>` + meter.nroSerie + `</span>`;
            nroSerieListExport.push(meter.nroSerie);
          });
          cups.nroSerieList += `</div>`;
          cups.nroSerieListExport = nroSerieListExport;
          cups.claveParsed =
            cups.meters.length > 1 || cups.clave != null
              ? cups.clave
              : null;
        });

        if (this.balanceId) {
          tableData.map((cups: SectorCups) => {
            cups.selected = cups.isSelected && cups.isChildParent == 0;
            cups.selectedBis = cups.isSelected && cups.isChildParent == 1;
          });
        }

        if (this.selectedChilds && this.selectedChilds.length > 0) {
          tableData.map((cups: SectorCups) => {
            cups.selected = false;
            cups.selectedBis = this.selectedChilds.includes(cups.id);
          });
        }

        this.tableData = JSON.parse(JSON.stringify(tableData));
        this.tableDataOriginal = [...this.tableData];
        this.parseTableDataForMap();
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "select",
        data: "fathers",
        sort: "selected",
        search: "selected",
        excluding: true,
        visible: true,
      },
      {
        title: "selectBis",
        data: "childs",
        sort: "selectedBis",
        search: "selectedBis",
        excluding: true,
        visible: true,
      },
      {
        title: this.cups ? this.cups.name : "CUPS",
        data: "claveParsed",
        search: "claveParsed",
        sort: "claveParsed",
        visible: this.cups ? true : null,
      },
      {
        title: this.entityNroSerie?.label
          ? this.entityNroSerie.label
          : "serial-number",
        data: "nroSerieList",
        search: "nroSerieList",
        sort: "nroSerieList",
        html: true,
        export: "nroSerieListExport",
        visible: true,
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
    this.getFilters();
  }

  // Obtención de los filtros del cups
  getFilters(): void {
    let filters: TableCupsFilters[] = [];
    this.columns
      .filter(
        (column: TableActionColumn | TableSelectColumn | TableDataColumn) =>
          column.title != "action" &&
          column.title != "select" &&
          column.title != "selectBis"
      )
      .forEach((column: TableDataColumn) => {
        filters.push({
          name: column.title,
          data: column.data,
          value: null,
        });
      });
    this.filters = filters;
  }

  // Actualización en grupo desde mapa
  updateByMap(selectedMeters: SectorDevice[], type?: string): void {
    if (selectedMeters?.length > 0) {
      if (!type) {
        this.ToastService.fireAlertWithTripleOptions(
          "info",
          this.translate.instant("balance-add-as") + ": ",
          this.translate.instant("cancel"),
          this.translate.instant("father"),
          this.translate.instant("child")
        ).then((userSelection: string) => {
          // Padre
          if (userSelection == "option1") {
            type = "FATHER";
            // Hijo
          } else if (userSelection == "option2") {
            type = "CHILD";
            // Cancelar
          } else if (userSelection == "option3") {
            type = "";
          }
          this.selectTableByMap(selectedMeters, type);
        });
      } else {
        this.selectTableByMap(selectedMeters, type);
      }
    }
  }

  // Actualización de selección en tabla por mapa
  selectTableByMap(selectedData: SectorDevice[], type: string): void {
    this.ignoreTableUpdate = true;
    this.mapController?.mapComponent?.deleteDrawedPolygon();
    this.tableData.map((cups) => {
      let updatedCups = selectedData.find(
        (selectedMeter) => selectedMeter.claveMaestraId == cups.id
      );
      if (updatedCups) {
        if (type == "FATHER") {
          cups.selected = !cups.selected;
          cups.selectedBis = false;
        } else if (type == "CHILD") {
          cups.selected = false;
          cups.selectedBis = !cups.selectedBis;
        }
        selectedData
          .filter((meter) => meter.claveMaestraId == updatedCups.claveMaestraId)
          .map((meter) => (meter.meterType = type != "" ? type : null));
      }
    });
    this.tableData = [...this.tableData];
  }

  // Actualización de selección en mapa por tabla
  selectMapByTable(): void {
    if (this.ignoreTableUpdate) {
      this.ignoreTableUpdate = false;
    } else {
      this.parseTableDataForMap();
    }
  }

  // Conversión de los datos de la tabla para el mapa
  parseTableDataForMap(): void {
    let newMapData = [];
    this.tableData.forEach((cups) => {
      cups.meters.forEach((meter) => {
        newMapData.push({
          claveMaestraId: cups.id,
          claveMaestraKey: cups.claveParsed ? cups.clave : null,
          meterId: meter.meterId,
          meterNroSerie: meter.nroSerie,
          metrologyType: meter.metrologyType,
          latitude: meter.latitude,
          longitude: meter.longitude,
          meterType: cups.selected
            ? "FATHER"
            : cups.selectedBis
            ? "CHILD"
            : null,
        });
      });
    });
    this.mapData = newMapData;
    this.mapController.activateAllLayers =
      !this.mapController.activateAllLayers;
  }

  // Guardado de balance
  saveBalance(): void {
    let balanceFathers = this.tableData.filter(
      (cups: SectorCups) => cups.selected
    );
    let balanceChilds = this.tableData.filter(
      (cups: SectorCups) => cups.selectedBis
    );
    if (balanceChilds.length == 0) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-selected-childs")
      );
    } else if (balanceFathers.length == 0) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-selected-fathers")
      );
    } else if (balanceChilds.length != 0 && balanceFathers.length != 0) {
      let data: BalanceEdition = {
        name: this.balance.name,
        description: this.balance.description,
        parentList: balanceFathers.map((meter: SectorCups) => {
          return meter.id;
        }),
        childList: balanceChilds.map((meter: SectorCups) => {
          return meter.id;
        }),
      };

      if (this.balanceId != null) {
        // Actualización
        data["id"] = this.balanceId;
        this.DataAnalysisController.edit(
          this.currentAgrupation.id,
          data
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("balance-updated")
            );
            if (this.balance.redirect == "meterDetail") {
              this.router.navigate(
                ["/dispositivos/detalle/contador/" + this.balance.meterId],
                { state: { data: this.balance } }
              );
            } else if (this.balance.redirect == "detail") {
              this.router.navigate(
                ["/analisis-datos/grupo/" + this.balanceId],
                { state: { data: this.balance } }
              );
            } else if (this.balance.redirect == "cups") {
              this.location.back();
            } else {
              this.router.navigate(["/analisis-datos/balance"]);
            }
          }
        });
      } else {
        // Creación
        this.DataAnalysisController.save(
          this.currentAgrupation.id,
          data
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("balance-created")
            );
            this.router.navigate(["/analisis-datos/balance"]);
          }
        });
      }
    }
  }
}
