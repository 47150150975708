<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <div></div>
    <!-- Estado de alarma -->
    <app-material-select
      [fullWidth]="true"
      [noSelection]="true"
      [title]="'state' | translate"
      [bindValue]="'value'"
      [bindLabel]="'name'"
      [selected]="initialSelection"
      [data]="alarmStateOptions"
      (selectedOption)="state = $event?.value"
    ></app-material-select>
    <!-- Comentarios de la alarma -->
    <mat-form-field class="alarm-edit-comment" appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="comments"></textarea>
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="data?.length > 1 ? editMultipleAlarmsSave() : editAlarmSave()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
