import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { formatNumber } from "@angular/common";
import { forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Turf
import * as Turf from "@turf/turf";
// Curve matcher
import { shapeSimilarity } from "curve-matcher";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { DataAnalysisControllerService } from "../../../../../../services/server/DataAnalysisController.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { CoordMeasureService } from "../../../../../../services/shared/CoordMeasureService.service";
import { HomeControllerService } from "../../../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
// Componentes
import { MapControllerComponent } from "../../../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import {
  GraphMeterData,
  GraphRequestData,
} from "../../../DataAnalysisInterface.type";
import { SectorCups } from "../../../../data-management/DataManagementInterface.type";
import { PossibleCups } from "./data-analysis-balance-checker-interface";
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";
// Variables
import { GRAPH_TYPES } from "../../../../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";

@Component({
  selector: "app-data-analysis-balance-checker",
  templateUrl: "./data-analysis-balance-checker.component.html",
  styleUrls: ["./data-analysis-balance-checker.component.scss"],
})
export class DataAnalysisBalanceCheckerComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  otherMeters: SectorCups[];
  mapMeters: SectorCups[];
  parentCups: SectorCups[];
  childCups: SectorCups[];
  cupsInRange: SectorCups[];
  externalCupsInRange: any[];
  cupsGraphs: PossibleCups[];
  possibleCups: PossibleCups[];
  similarity: number;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  meterAverage: number;
  differenceAverage: string;
  differenceAccumulated: number[];
  differenceAccumulatedMax: number;
  otherCupsInRange: SectorCups[];
  balancePosition: number[];
  selectionUpdated: boolean = false;
  showAllMetersInRange: boolean = false;
  selectionLayers: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CoordMeasureService: CoordMeasureService,
    private DataAnalysisController: DataAnalysisControllerService,
    private HomeController: HomeControllerService,
    private MapDeviceMinimalParseService: MapDeviceMinimalParseService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getBalanceMeters();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los contadores del balance
  getBalanceMeters(): void {
    this.parentCups = [];
    this.childCups = [];
    this.DataAnalysisController.show(this.data.balance.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let mapData = response["body"].sectorDeviceList;
          let entityAgrupations =
            this.SessionDataService.getCurrentEntity().agrupations;
          mapData.map((cups: SectorCups) => {
            cups.meterNroSerie = cups.meters[0].nroSerie;
            cups.agrupationName = entityAgrupations.find(
              (agrupation) => agrupation.id == this.data.balance.agrupation
            ).name;
            cups.selected = cups.isSelected && cups.isChildParent == 0;
            if (cups.selected) {
              cups.meterType = "FATHER";
              this.parentCups.push(cups);
            }
            cups.selectedBis = cups.isSelected && cups.isChildParent == 1;
            if (cups.selectedBis) {
              cups.meterType = "CHILD";
              this.childCups.push(cups);
            }
          });

          this.otherMeters = mapData?.filter(
            (device: SectorCups) =>
              device.meterType != "FATHER" && device.meterType != "CHILD"
          );
          this.mapMeters = mapData;

          // Valor medio diferencia
          this.differenceAverage = (
            this.data.difference
              .map((reading: number[]) => reading[1])
              .reduce((a: number, b: number) => a + b) /
            this.data.difference.length
          ).toFixed(3);
          // Diferencia acumulada
          this.differenceAccumulated = this.getAccumulatedArray(
            this.data.difference
          );
          this.differenceAccumulatedMax = Math.floor(
            this.differenceAccumulated.reduce((a, b) => a + b)
          );
          this.similarity = this.differenceAccumulatedMax / 2;
          // Distancia
          if (this.parentCups.length > 1) {
            let features = Turf.points(
              this.parentCups.map((cups: SectorCups) => [
                cups.meters[0].longitude,
                cups.meters[0].latitude,
              ])
            );
            let center = Turf.center(features);
            this.balancePosition = [
              center.geometry.coordinates[1],
              center.geometry.coordinates[0],
            ];
          } else {
            this.balancePosition = [
              this.parentCups[0].meters[0].latitude,
              this.parentCups[0].meters[0].longitude,
            ];
          }
        }
      }
    );
  }

  // Obtención de las gráficas de consumo de los contadores en rango
  getCupsGraphs(): void {
    if (this.selectionUpdated || this.externalCupsInRange?.length > 0) {
      this.cupsGraphs = [];
      let requests: Observable<object>[] = [];

      // Contadores en rango no incluidos
      this.otherCupsInRange = this.cupsInRange.filter((cups: SectorCups) =>
        this.otherMeters.some(
          (otherMeter: SectorCups) => otherMeter.id == cups.id
        )
      );

      // Petición de contadores en rango no incluidos de la agrupación
      if (this.otherCupsInRange.length > 0) {
        let data: GraphRequestData = {
          meterList: this.otherCupsInRange.map(
            (meter: SectorCups) => meter.meterId
          ),
          agrupation: this.SessionDataService.getCurrentAgrupation().id,
          fromTimestamp: parseInt(this.data.from),
          toTimestamp: parseInt(this.data.to),
          graphType: this.otherCupsInRange.some(
            (cups: SectorCups) =>
              cups.meters[0].metrologyType == METROLOGY_TYPE.GAS
          )
            ? 3
            : 2,
        };
        requests.push(this.DataAnalysisController.getGraphGroup(1, data));
      }

      // Peticiones de agrupaciones externas
      if (this.externalCupsInRange?.length > 0) {
        requests = requests.concat(this.getExternalRequests());
      }

      if (requests.length > 0) {
        forkJoin(requests).subscribe((responses) => {
          responses.forEach((response) => {
            if (response["code"] == 0) {
              response["body"]?.forEach((deviceRadings: GraphMeterData) => {
                let possibleCups = this.otherCupsInRange.find(
                  (cups: SectorCups) => cups.meterId == deviceRadings.meterId
                );
                if (!possibleCups) {
                  possibleCups = this.externalCupsInRange.find(
                    (cups: SectorCups) => cups.meterId == deviceRadings.meterId
                  );
                }
                this.cupsGraphs.push({
                  cups: possibleCups,
                  readings: deviceRadings.readings,
                });
              });
            }
          });
          this.selectionUpdated = false;
          this.checkMetersGraph();
        });
      }
    } else {
      this.checkMetersGraph();
    }
  }

  // Chequeo de la gráfica de los contadores
  checkMetersGraph(): void {
    this.possibleCups = [];
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();

    this.cupsGraphs.forEach((cupsData: PossibleCups) => {
      // Valor medio contador
      let meterAverage: number;
      if (cupsData?.readings?.length > 0) {
        meterAverage =
          cupsData?.readings
            ?.map((reading: number[]) => reading[1])
            ?.reduce((a: number, b: number) => a + b) /
          this.data.difference.length;
      }
      // Similaridad
      let isNotNull = cupsData.readings.some(
        (reading: number[]) => reading[1] != 0
      );
      let isInAverage = this.meterAverage
        ? meterAverage >= this.meterAverage
        : true;
      let testResult =
        isNotNull && isInAverage
          ? this.kolmogorovSmirnovTest(cupsData.readings)
          : null;
      // Distancia
      let distance = this.CoordMeasureService.measure(
        cupsData.cups.meters[0].latitude,
        cupsData.cups.meters[0].longitude,
        this.balancePosition[0],
        this.balancePosition[1]
      );
      cupsData.distance = formatNumber(Math.round(distance), numberFormat);

      if (testResult && testResult <= this.similarity) {
        cupsData.similarity = Math.round(testResult);
        cupsData.average =
          formatNumber(meterAverage, numberFormat) +
          (this.data.graphType == GRAPH_TYPES.CONSUMPTION
            ? " m³"
            : this.data.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
            ? " Nm³"
            : " kWh");
        cupsData.showDifference = false;
        cupsData.graph = {
          series: [
            // Serie de diferencia
            {
              id: cupsData.cups.meters[0].nroSerie + " difference",
              data: this.data.difference,
              type: "area",
              opacity: 0.3,
              color: "#4caf50",
              name:
                this.data.graphType == GRAPH_TYPES.CONSUMPTION
                  ? this.translate.instant("consumption")
                  : this.data.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                  ? this.translate.instant("consumption-normalized")
                  : this.translate.instant("energy"),
              tooltip: {
                valueSuffix:
                  this.data.graphType == GRAPH_TYPES.CONSUMPTION
                    ? " m³"
                    : this.data.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                    ? " Nm³"
                    : " kWh",
                valueDecimals: 3,
              },
            },
            // Serie de contador
            {
              id: cupsData.cups.meters[0].nroSerie,
              data: cupsData.readings,
              name:
                this.data.graphType == GRAPH_TYPES.CONSUMPTION
                  ? this.translate.instant("consumption")
                  : this.data.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                  ? this.translate.instant("consumption-normalized")
                  : this.translate.instant("energy"),
              tooltip: {
                valueSuffix:
                  this.data.graphType == GRAPH_TYPES.CONSUMPTION
                    ? " m³"
                    : this.data.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                    ? " Nm³"
                    : " kWh",
                valueDecimals: 3,
              },
            },
          ],
        };
        this.possibleCups.push(cupsData);
      }
    });

    if (this.possibleCups.length > 0) {
      setTimeout(() => {
        this.SessionDataService.sendDialogAction({
          action: "balance-update",
          data: this.possibleCups.sort((a, b) => a.similarity - b.similarity),
          maxMeters:
            this.otherCupsInRange.length +
            (this.externalCupsInRange ? this.externalCupsInRange.length : 0),
          parentList: this.parentCups.map((meter: SectorCups) => meter.id),
          childList: this.childCups.map((meter: SectorCups) => meter.id),
          differenceMax: this.differenceAccumulatedMax,
        });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }, 1000);
    } else {
      setTimeout(() => {
        this.ToastService.fireToast(
          "info",
          this.translate.instant("balance-find-empty")
        );
      }, 1000);
    }
  }

  // Comparación de la curva de las gráficas
  compareGraphCurves(readings: number[][]): number {
    let difference = this.data.difference.map((reading: number[], i) => {
      return { x: i, y: reading[1] };
    });
    let values = readings.map((reading: number[], i) => {
      return { x: i, y: reading[1] };
    });
    let similarity = shapeSimilarity(difference, values, {
      estimationPoints: this.data.difference.length,
      checkRotations: false,
    });
    return similarity;
  }

  // Comparación de curvas con test de Kolmogorov-Smirnov
  kolmogorovSmirnovTest(readings: number[][]): number {
    let meterAccumulated = this.getAccumulatedArray(readings);
    let accumulatedDifference = this.differenceAccumulated.map(
      (accumulated: number, i) => Math.abs(accumulated - meterAccumulated[i])
    );
    let testResult = accumulatedDifference.reduce(
      (a: number, b: number) => a + b
    );
    return testResult;
  }

  // Obtención de array de acumulaciones
  getAccumulatedArray(readings: number[][]): number[] {
    let accumulatedDifference: number[] = readings.map(
      (reading: number[], i) => {
        if (i > 0) {
          return reading[1] - readings[i - 1][1];
        } else {
          return reading[1];
        }
      }
    );
    let accumulated = 0;
    let accumulatedArray: number[] = accumulatedDifference.map(
      (difference: number) => (accumulated += difference)
    );
    return accumulatedArray;
  }

  getGraphs(): void {
    if (this.showAllMetersInRange) {
      this.getAllMetersInRange();
    } else {
      this.getCupsGraphs();
    }
  }

  // Mostrar todos los contadores en rango
  getAllMetersInRange(): void {
    // Agrupación virtual
    let virtualAgrupation =
      this.SessionDataService.getCurrentEntity()?.agrupations.find(
        (agrupation) => agrupation.showAllEntity
      );
    if (virtualAgrupation) {
      // Contadores de la agrupación virtual
      this.HomeController.getMarkers(virtualAgrupation.id).subscribe(
        (response) => {
          if (response["code"] == 0) {
            // Parseo de dispositivos
            let devices = this.MapDeviceMinimalParseService.parseDevices(
              response["body"]["contadores"]
            );
            let devicesInRange = devices.filter(
              (device) =>
                // Filtrado de dispositivos ya añadidos en padres
                !this.parentCups.some(
                  (fatherMeter) => fatherMeter.meterId == device.id
                ) &&
                // Filtrado de dispositivos ya añadidos en hijos
                !this.childCups.some(
                  (childMeter) => childMeter.meterId == device.id
                ) &&
                // Filtrado de dispositivos ya añadidos en otros
                !this.otherMeters.some(
                  (otherMeter) => otherMeter.meterId == device.id
                ) &&
                // Filtrado por tipo de dispositivo
                (device.metrologyType == METROLOGY_TYPE["WATER"] ||
                  device.metrologyType == METROLOGY_TYPE["GAS"] ||
                  device.metrologyType ==
                    METROLOGY_TYPE["MBUS_CONCENTRATOR"]) &&
                // Filtrao por capas de selección
                this.selectionLayers.some((layer) => {
                  let feature = layer.toGeoJSON();
                  return Turf.booleanWithin(
                    Turf.point([
                      parseFloat(device.longitude),
                      parseFloat(device.latitude),
                    ]),
                    feature
                  );
                })
            );
            // Contadores encontrados
            if (devicesInRange?.length > 0) {
              let entityAgrupations =
                this.SessionDataService.getCurrentEntity().agrupations;
              let newMeters = devicesInRange.map((device) => {
                return {
                  id: device.id,
                  nroSerie: device.nroSerie,
                  latitude: parseFloat(device.latitude),
                  longitude: parseFloat(device.longitude),
                  isSelected: false,
                  isChildParent: null,
                  metrologyType: device.metrologyType,
                  meterNroSerie: device.nroSerie,
                  meterId: device.id,
                  selected: true,
                  agrupation: device.agrupation,
                  agrupationName: entityAgrupations.find(
                    (agrupation) => agrupation.id == device.agrupation
                  ).name,
                  otherAgrupation: true,
                };
              });
              this.externalCupsInRange = newMeters;
            }
            this.getCupsGraphs();
          }
        }
      );
    }
  }

  // Obtención de las peticiones a otras agrupaciones
  getExternalRequests(): Observable<object>[] {
    let externalRequests: Observable<object>[] = [];
    let agrupationMeters: object = {};
    this.externalCupsInRange.forEach((meter) => {
      if (!agrupationMeters[meter.agrupation]) {
        agrupationMeters[meter.agrupation] = [meter.id];
      } else {
        agrupationMeters[meter.agrupation].push(meter.id);
      }
    });
    for (let agrupation in agrupationMeters) {
      let data: GraphRequestData = {
        meterList: agrupationMeters[agrupation],
        agrupation: parseInt(agrupation),
        fromTimestamp: parseInt(this.data.from),
        toTimestamp: parseInt(this.data.to),
        graphType: this.otherCupsInRange.some(
          (cups: SectorCups) =>
            cups.meters[0].metrologyType == METROLOGY_TYPE.GAS
        )
          ? 3
          : 2,
      };
      externalRequests.push(this.DataAnalysisController.getGraphGroup(1, data));
    }
    return externalRequests;
  }
}
