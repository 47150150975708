import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { RF_MODULE } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-replace-rf-modal",
  templateUrl: "./device-replace-rf-modal.component.html",
  styleUrls: ["./device-replace-rf-modal.component.scss"],
})
export class DeviceReplaceRfModalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  rfList: RF_MODULE[];
  selectedRf: RF_MODULE;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getRfModuleList();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del listado de módulos RF
  getRfModuleList(): void {
    this.MeterController.getRfModuleList(
      this.SessionDataService.getCurrentEntity().id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.rfList = response["body"];
      }
    });
  }

  // Sustitución de módulo RF
  replaceRfModule(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("rfmodule-replace-question") +
        "\n" +
        this.translate.instant("current") +
        ": " +
        this.data.rfModule +
        " - " +
        this.translate.instant("new") +
        ": " +
        this.selectedRf.rfModule
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.replaceRfModule(
          this.data.id,
          this.selectedRf.id
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("rfmodule-replaced")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }
}
