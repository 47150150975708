// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { DeviceSector } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-sector-table",
  templateUrl: "./device-sector-table.component.html",
  styleUrls: ["./device-sector-table.component.scss"],
})
export class DeviceSectorTableComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() device: any;
  @Input() sectorTableData: DeviceSector[];

  // Tabla de sectores
  tableRowNumbers: boolean = true;
  tableMaxReg: number = 10;
  tableData: DeviceSector[];
  tableExportFileName: string =
    this.translate.instant("sector-export") + " " + moment().format("ll");
  tableColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show",
          tooltip: "show",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: "showDisable",
        },
      ],
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "description",
      data: "description",
      search: "description",
      sort: "description",
      visible: true,
    },
    {
      title: "type",
      data: "typeParsed",
      search: "typeParsed",
      sort: "typeParsed",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private router: Router,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getTableSectorData(this.sectorTableData);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos para la tabla de sectores
  getTableSectorData(sectorTableData?: DeviceSector[]): void {
    if (sectorTableData) {
      this.tableData = this.getSectorType(sectorTableData);
    } else {
      this.MeterController.getMeterSector(this.device.id).subscribe(
        (response) => {
          if (response["code"] == 0 && response["body"]) {
            this.tableData = this.getSectorType(response["body"]);
          } else {
            this.tableData = [];
          }
        }
      );
    }
  }

  // Obtención de los tipos de sector
  getSectorType(tableData: DeviceSector[]): DeviceSector[] {
    tableData.forEach((sector: DeviceSector) => {
      switch (sector.type) {
        case 0:
          sector.typeParsed = this.translate.instant("simple");
          sector.showDisable = true;
          break;
        case 1:
          sector.typeParsed = this.translate.instant("hydraulic-groups");
          sector.showDisable = false;
          break;
        default:
          break;
      }
    });
    return tableData;
  }

  // Acciones de la tabla de sectores
  tableActions(action: string, sector: DeviceSector): void {
    switch (action) {
      case "show":
        this.router.navigate(["/analisis-datos/grupo/" + sector.id], {
          state: { data: sector },
        });
        break;
      default:
        break;
    }
  }
}
