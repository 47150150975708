// @angular
import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Interfaces
import { DialogData } from "../TableInterface.type";

@Component({
  selector: "app-table-special-filter-dialog",
  templateUrl: "./table-special-filter-dialog.component.html",
  styleUrls: ["./table-special-filter-dialog.component.scss"],
})
export class TableSpecialFilterDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: DialogData;
  comparisonType: string;
  booleanSelection: boolean;
  comparisonOptions: string[] = ["==", ">=", "<=", ">", "<"];
  numericalComparisonList: any[] = [{ selected: "==", value: null }];
  numericalInputCheck: boolean = false;
  dateComparisonList: any[] = [{ selected: "==", date: null, time: null }];
  dateInputCheck: boolean = false;
  addDisabled: boolean = true;
  showNull: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de las opciones de filtrado
  filterTable(): void {
    let filterData: string;
    let filterDataArray: any[];
    let inputDataString: string = "";

    switch (this.data.comparisonType) {
      case "boolean":
        filterData = this.booleanSelection ? "t" : "f";
        inputDataString +=
          " = " +
          (this.booleanSelection
            ? this.translate.instant("yes")
            : this.translate.instant("no"));
        break;
      case "numerical":
        filterDataArray = this.numericalComparisonList.map(
          (comparison: any) => {
            inputDataString +=
              " " + comparison.selected + " " + comparison.value;
            return comparison.selected + comparison.value;
          }
        );
        filterData = filterDataArray.toString();
        break;
      case "date":
        filterDataArray = this.dateComparisonList.map((comparison: any) => {
          let date = comparison.date;
          if (comparison.time) {
            date += " " + comparison.time;
          }
          let dateTimestamp: string = moment(date)
            .tz(this.data.timezone)
            .format("x")
            .valueOf();
          inputDataString += " " + comparison.selected + " " + date;
          return comparison.selected + dateTimestamp;
        });
        filterData = filterDataArray.join(";");
        break;
      default:
        break;
    }

    this.SessionDataService.sendDialogAction({
      action: "filter",
      filterData: filterData,
      inputDataString: inputDataString,
      showNull: this.showNull,
    });
    this.comparisonType = null;
  }

  // Adición de una comparativa numérica
  addNumericalComparison(): void {
    this.numericalComparisonList.push({ selected: ">=", value: null });
    this.numericalInputCheck = false;
  }

  // Eliminación de una comparativa numérica
  removeNumericalComparison(): void {
    if (this.numericalComparisonList.length > 1) {
      this.numericalComparisonList.pop();
    }
    this.numericalInputCheck =
      this.numericalComparisonList[0].value != null ? true : false;
  }

  // Comprobación de los input numéricos
  checkNumericalInput(): void {
    this.numericalInputCheck = this.numericalComparisonList
      .map((comparison: any) => {
        if (comparison.value != null) {
          return true;
        } else {
          return false;
        }
      })
      .reduce((a, b) => a && b);
  }

  // Adición de una comparativa numérica
  addDateComparison(): void {
    this.dateComparisonList.push({ selected: ">=", date: null, time: null });
    this.dateInputCheck = false;
  }

  // Eliminación de una comparativa numérica
  removeDateComparison(): void {
    if (this.dateComparisonList.length > 1) {
      this.dateComparisonList.pop();
    }
    this.dateInputCheck = this.dateComparisonList[0].date ? true : false;
  }

  // Comprobación de los input de fecha
  checkDateInput(): void {
    this.dateInputCheck = this.dateComparisonList
      .map((comparison: any) => {
        if (comparison.date) {
          return true;
        } else {
          return false;
        }
      })
      .reduce((a, b) => a && b);
  }
}
