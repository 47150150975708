<div class="header-container">
  <div class="navbar-top-container">
    <div
      class="navbar-top-home-link pointer"
      [routerLink]="profileStartUrl ? [profileStartUrl] : ['/principal']"
    ></div>
    <!-- Barra de navegación -->
    <ul class="navbar-top">
      <!-- ANCHOR Desplegable clientes -->
      <li *ngIf="sessionProfile == PROFILES.ARSON">
        <app-navbar-top-dropdown
          [help]="'help-nav-client-selector' | translate"
          [type]="'client'"
          [classes]="'fa fa-user-tie fa-fw'"
          [selectorClasses]="'fa fa-user-tie'"
          [title]="'clients'"
          [listLink]="'/clientes/listado'"
          [newLink]="'/clientes/formulario/nuevo'"
          [currentElement]="currentClient"
          [elementList]="clientList"
          [allowList]="sessionProfile == PROFILES.ARSON"
          [allowNew]="sessionProfile == PROFILES.ARSON"
          [attributeShowed]="'clientName'"
          (dropdownFilterFlag)="dropdownFilter($event.filterValue, $event.type)"
          (updateCurrentElementFlag)="updateCurrentClient($event)"
          (resetSearchBoxFlag)="resetSearchBox($event)"
        >
        </app-navbar-top-dropdown>
      </li>

      <!-- ANCHOR Desplegable entidades -->
      <li *ngIf="sessionProfile != PROFILES.TELEMEDIDA">
        <app-navbar-top-dropdown
          [help]="'help-nav-entity-selector' | translate"
          [type]="'entity'"
          [classes]="'fa fa-sitemap fa-fw'"
          [selectorClasses]="'fa fa-sitemap'"
          [title]="'entities'"
          [listLink]="'/entidades/listado'"
          [newLink]="'/entidades/formulario/nuevo'"
          [currentElement]="currentEntity"
          [elementList]="entityList"
          [allowList]="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD
          "
          [allowNew]="sessionProfile == PROFILES.ARSON"
          [attributeShowed]="'entity'"
          (dropdownFilterFlag)="
            dropdownFilter($event.filterValue, $event.type, $event.alias)
          "
          (updateCurrentElementFlag)="updateCurrentEntity($event)"
          (resetSearchBoxFlag)="resetSearchBox($event)"
        >
        </app-navbar-top-dropdown>
      </li>

      <!-- ANCHOR Desplegable agrupaciones -->
      <li *ngIf="sessionProfile != PROFILES.TELEMEDIDA">
        <app-navbar-top-dropdown
          [help]="'help-nav-agrupation-selector' | translate"
          [type]="'agrupation'"
          [classes]="'fa fa-map fa-fw'"
          [selectorClasses]="'fa fa-map'"
          [highlight]="'showAllEntity'"
          [title]="'agrupations'"
          [listLink]="'/agrupaciones/listado'"
          [newLink]="'/agrupaciones/formulario/nuevo'"
          [currentElement]="currentAgrupation"
          [elementList]="agrupationList"
          [allowList]="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE
          "
          [allowNew]="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE
          "
          [attributeShowed]="'name'"
          (dropdownFilterFlag)="
            dropdownFilter($event.filterValue, $event.type, $event.alias)
          "
          (updateCurrentElementFlag)="updateCurrentAgrupation($event)"
          (resetSearchBoxFlag)="resetSearchBox($event)"
        >
        </app-navbar-top-dropdown>
      </li>

      <!-- ANCHOR Caja de búsqueda -->
      <li
        *ngIf="
          sessionProfile != PROFILES.ADMIN_INSTALLATION &&
          sessionProfile != PROFILES.TELEMEDIDA
        "
        class="navbar-top-search navbar-top-align-right"
      >
        <!-- Ayuda -->
        <div
          *ngIf="sessionProfile == PROFILES.ARSON"
          class="navbar-top-help"
          [ngClass]="{ 'navbar-top-help-active': helpActive }"
          [title]="
            helpActive
              ? ('help-nav-help' | translate)
              : ('activate-help' | translate)
          "
        >
          <button mat-icon-button (click)="toggleHelp()">
            <i class="fas fa-question-circle"></i>
          </button>
        </div>

        <app-navbar-top-search-box
          [help]="'help-nav-search' | translate"
          [type]="'search'"
          [currentCupsName]="currentCupsName"
          [meterList]="meterList"
          [cupsList]="cupsList"
          [sessionProfile]="sessionProfile"
          (resetSearchBoxFlag)="resetSearchBox($event)"
          (goToSelectionFlag)="
            goToSelection($event.cupsSelected, $event.selection)
          "
          (dropdownFilterFlag)="
            dropdownFilter($event.filterValue, $event.type, $event.cupsSelected)
          "
        >
        </app-navbar-top-search-box>
      </li>

      <!-- ANCHOR Desplegable usuario -->
      <li
        [ngClass]="{
          'navbar-top-align-right':
            sessionProfile == PROFILES.ADMIN_INSTALLATION ||
            sessionProfile == PROFILES.TELEMEDIDA
        }"
      >
        <app-navbar-user-dropdown
          [help]="'help-nav-user-menu' | translate"
          [sessionUserName]="sessionUserName"
          [sessionProfile]="sessionProfile"
          [languageName]="languageName"
          [allowList]="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION ||
            sessionProfile == PROFILES.ADMIN_INSTALLATION
          "
          (switchLanguageFlag)="switchLanguage($event)"
          (switchNumberFormatFlag)="switchNumberFormat($event)"
          (switchDateFormatFlag)="switchDateFormat($event)"
          (deleteUserLocalSettingsFlag)="deleteUserLocalSettings()"
          (logoutFlag)="logout()"
          (reloginFlag)="relogin()"
          (updateUserRestrictionsFlag)="updateUserRestrictions($event)"
          (updateUserSuspicionFlag)="updateUserSuspicion($event)"
          [pendingNotifications]="pendingNotifications"
        >
        </app-navbar-user-dropdown>
      </li>
    </ul>

    <!-- ANCHOR Barra de navegación responsive -->
    <ul class="navbar-top-responsive">
      <!-- Logo link -->
      <li class="navbar-logo" [routerLink]="['/principal']">
        <a class="navbar-top-logo">
          <i *ngIf="logoLoading" class="fas fa-spinner fa-pulse"></i>
          <img
            class="navbar-top-logo-mini"
            [hidden]="logoLoading"
            (load)="onLogoLoad()"
            src="{{ navbarLogo }}"
            alt="Logo"
          />
        </a>
      </li>

      <li class="navbar-responsive-datamenu">
        <!-- ANCHOR Cliente, entidad y agrupación en curso -->
        <div class="navbar-responsive-current">
          <div class="navbar-responsive-current-data">
            <div
              class="navbar-responsive-current-client"
              *ngIf="sessionProfile == PROFILES.ARSON"
            >
              <p *ngIf="!currentClient" class="navbar-top-selected">-</p>
              <p *ngIf="currentClient" class="navbar-top-selected">
                {{ currentClient.clientName }}
              </p>
            </div>
            <div class="navbar-responsive-current-entity">
              <p *ngIf="!currentClient" class="navbar-top-selected">-</p>
              <p *ngIf="currentClient" class="navbar-top-selected">
                {{ currentEntity?.entity }}
              </p>
            </div>
            <div class="navbar-responsive-current-agrupation">
              <p *ngIf="!currentClient" class="navbar-top-selected">-</p>
              <p *ngIf="currentClient" class="navbar-top-selected">
                {{ currentAgrupation?.name }}
              </p>
            </div>
          </div>
          <div class="navbar-responsive-current-icon">
            <i class="fa fa-users fa-fw"></i>
            <i class="fa fa-sitemap fa-fw"></i>
            <i class="fa fa-map fa-fw"></i>
          </div>
        </div>

        <!-- Menú responsive -->
        <div class="navbar-responsive-menu" (click)="showResponsiveMenu()">
          <i class="fas fa-bars"></i>
        </div>
      </li>
    </ul>
  </div>

  <!-- ANCHOR Reloj y zona horaria -->
  <div class="navbar-top-clock">
    <!-- Totales de entidad -->
    <div *ngIf="currentAgrupation" class="navbar-top-clock-data">
      <div
        class="navbar-top-clock-entity-totals"
        *ngIf="
          sessionProfile != PROFILES.ADMIN_INSTALLATION &&
          sessionProfile != PROFILES.TELEMEDIDA
        "
      >
        <app-entity-total-snapshot></app-entity-total-snapshot>
      </div>
      <div>
        <i class="fas fa-calendar-day"></i>
        {{ clockTime?.date != null ? clockTime?.date : "-" }}
      </div>
      <div>
        <i class="fa fa-clock-o"></i>
        {{ clockTime?.time != null ? clockTime?.time : "-" }}
      </div>
      <div>
        <i class="fas fa-globe"></i>
        {{ currentAgrupation ? currentAgrupation?.timezone : "-" }}
        {{
          sessionProfile == PROFILES.ARSON
            ? "(" + (clockTime?.offset != null ? clockTime?.offset : "-") + ")"
            : ""
        }}
      </div>

      <!-- ANCHOR Datos de clima -->
      <div *ngIf="sessionProfile == PROFILES.ARSON" class="navbar-top-weather">
        <button
          *ngIf="!weatherData"
          type="button"
          (click)="updateWeatherData()"
        >
          <i *ngIf="weatherLoading" class="fas fa-spinner fa-pulse"></i>
          <span *ngIf="!weatherLoading">{{ "load-weather" | translate }}</span>
        </button>
        <div *ngIf="weatherData">
          <i class="{{ weatherData.icon }}"></i>
          <span>{{ weatherData.text }}</span>
          <button
            class="navbar-top-weather-update"
            [disabled]="!weatherUpdateEnabled"
            (click)="weatherData = null; updateWeatherData()"
          >
            <i class="fas fa-sync-alt"></i>
          </button>
        </div>

        <!-- Datos extra de clima -->
        <div *ngIf="weatherData" class="navbar-top-weather-extra-info">
          <div class="navbar-top-weather-data-container">
            <div class="navbar-top-weather-data-agrupation">
              {{ currentWeatherCoords.agrupation }}
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.temp">
              <div>
                <i class="fas fa-thermometer-half"></i>
                <b>{{ "weather-temp" | translate }}:</b>
              </div>
              {{ weatherData.temp }}°
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.minTemp">
              <div>
                <i class="fas fa-thermometer-quarter"></i>
                <b>{{ "weather-min-temp" | translate }}:</b>
              </div>
              {{ weatherData.minTemp }}°
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.maxTemp">
              <div>
                <i class="fas fa-thermometer-full"></i>
                <b>{{ "weather-max-temp" | translate }}:</b>
              </div>
              {{ weatherData.maxTemp }}°
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.pressure">
              <div>
                <i class="fas fa-tachometer-alt"></i>
                <b>{{ "weather-pressure" | translate }}:</b>
              </div>
              {{ weatherData.pressure }} hPa
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.humidity">
              <div>
                <i class="fas fa-tint"></i>
                <b>{{ "weather-humidity" | translate }}:</b>
              </div>
              {{ weatherData.humidity }}%
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.clouds">
              <div>
                <i class="fas fa-cloud"></i>
                <b>{{ "weather-clouds" | translate }}:</b>
              </div>
              {{ weatherData.clouds }}%
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.sunrise">
              <div>
                <i class="fas fa-sun"></i>
                <b>{{ "weather-sunrise" | translate }}:</b>
              </div>
              {{ weatherData.sunrise }}
            </div>
            <div class="navbar-top-weather-data" *ngIf="weatherData.sunset">
              <div>
                <i class="fas fa-moon"></i>
                <b>{{ "weather-sunset" | translate }}:</b>
              </div>
              {{ weatherData.sunset }}
            </div>
          </div>
          <div class="navbar-top-weather-source">OpenWeather®</div>
        </div>
      </div>
    </div>
  </div>

  <app-navbar-responsive
    *ngIf="showResponsiveMenuFlag && hideDesktopMenus"
    (showResponsiveMenuFlag)="showResponsiveMenu()"
    (languageChangeFlag)="switchLanguage($event)"
    (logoutFlag)="logout()"
    [meterList]="meterList"
    [cupsList]="cupsList"
    [currentCupsConf]="currentEntityCupsConf"
    [currentCupsName]="currentCupsName"
    [sessionProfile]="sessionProfile"
    [sessionUserName]="sessionUserName"
    [currentClient]="currentClient"
    [currentEntity]="currentEntity"
    [currentAgrupation]="currentAgrupation"
    [clientList]="clientList"
    [entityList]="entityList"
    [agrupationList]="agrupationList"
    (resetFilterFlag)="resetSearchBox($event)"
    (searchSelection)="goToSelection($event.cupsSelected, $event.selection)"
    (dropdownFilterFlag)="
      dropdownFilter($event.filterValue, $event.type, $event.cupsSelected)
    "
    (updateAgrupationFlag)="updateCurrentAgrupation($event)"
    (updateEntityFlag)="updateCurrentEntity($event)"
    (updateClientFlag)="updateCurrentClient($event)"
    (deleteUserLocalSettingsFlag)="deleteUserLocalSettings()"
    [pendingNotifications]="pendingNotifications"
  >
  </app-navbar-responsive>
</div>
