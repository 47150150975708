// @angular
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
  TableGlobalAction,
} from "../../../../../modules/table-module/TableInterface.type";
import { LwMbusCommand } from "../../ConcentratorInterface.type";
// Variables
import {
  LW_MBUS_COMMAND,
  LW_MBUS_PROCESS,
} from "../../ConcentratorInterface.type";

@Component({
  selector: "app-concentrator-commands",
  templateUrl: "./concentrator-commands.component.html",
  styleUrls: ["./concentrator-commands.component.scss"],
})
export class ConcentratorCommandsComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Table
  concentratorId: number = parseInt(this.route.snapshot.paramMap.get("id"));
  loadingCommands: boolean = false;
  tableMaxReg: number = 50;
  tableData: LwMbusCommand[] = this.parseCommandsData(history.state.data);
  tableSelectedData: LwMbusCommand[];
  orderBy: object = { attribute: "timestamp", reverse: true };
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "retry",
          tooltip: "retry",
          icon: "fas fa-redo-alt",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      data: null,
      search: null,
      sort: null,
      visible: true,
    },
    {
      title: "sales-unit",
      data: "gatewayDes",
      search: "gatewayDes",
      sort: "gatewayDes",
      visible: true,
    },
    {
      title: "command",
      data: "puertoParsed",
      search: "puertoParsed",
      sort: "puertoParsed",
      visible: true,
    },
    {
      title: "date",
      data: "timestampEnvioParsed",
      search: "timestampEnvioParsed",
      sort: "timestampEnvio",
      date: true,
      visible: true,
    },
    {
      title: "command-number",
      data: "nroComando",
      search: "nroComando",
      sort: "nroComando",
      numerical: true,
      visible: true,
    },
    {
      title: "command-retrys",
      data: "nroReintento",
      search: "nroReintento",
      sort: "nroReintento",
      numerical: true,
      visible: true,
    },
    {
      title: "command-process",
      data: "tipoProcesoParsed",
      search: "tipoProcesoParsed",
      sort: "tipoProcesoParsed",
      visible: true,
    },
    {
      title: "command-response",
      data: "responseCodeParsed",
      search: "responseCodeParsed",
      sort: "responseCodeParsed",
      visible: true,
    },
    {
      title: "payload",
      data: "payload",
      search: "payload",
      sort: "payload",
      long: true,
      visible: true,
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "remove-selected-data",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private route: ActivatedRoute,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (!this.tableData) {
      this.getLwMbusCommands();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de la cola de comandos de LW MBUS
  getLwMbusCommands(): void {
    this.loadingCommands = true;
    this.MeterController.getLwMbusCommands(this.concentratorId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let commandsData = response["body"];
          this.tableData = this.parseCommandsData(commandsData);
          this.loadingCommands = false;
        }
      }
    );
  }

  parseCommandsData(commandsData): LwMbusCommand[] {
    commandsData.forEach((command: LwMbusCommand) => {
      command.tipoProcesoParsed =
        command.tipoProceso != null
          ? LW_MBUS_PROCESS[command.tipoProceso]
          : null;
      command.puertoParsed = command.puerto
        ? LW_MBUS_COMMAND[command.puerto]
        : null;
      command.responseCodeParsed = this.translate.instant(
        "httpError" + command.responseCode
      );
      if (command.responseCodeParsed.includes("httpError")) {
        command.responseCodeParsed =
          this.translate.instant("error-text") + " " + command.responseCode;
      }
    });
    return commandsData;
  }

  // Acciones de la tabla de contadores hijos
  mbusCommandTableActions(actionData: any): void {
    let action: any = actionData.action;
    let command: any = actionData.element;

    switch (action.name) {
      case "retry":
        this.retryLwMbusCommand(command.id);
        break;
      case "delete":
        this.deleteLwMbusCommand([command.id]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  mbusCommandTableGlobalAction(action: string): void {
    switch (action) {
      case "remove-selected-data":
        this.deleteLwMbusCommand(
          this.tableSelectedData.map((command) => command.id)
        );
        break;
      default:
        break;
    }
  }

  // Reintentar comando de LW MBUS
  retryLwMbusCommand(command: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("command-retry-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.retryLwMbusCommand(
          this.concentratorId,
          command
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.translate.instant("command-sent");
          }
        });
      }
    });
  }

  // Desasignar contador de LW MBUS
  deleteLwMbusCommand(commands: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        commands.length > 1
          ? "remove-multiple-from-check-question"
          : "remove-from-check-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteLwMbusCommand({
          element: null,
          listElements: commands,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.translate.instant("elements-deleted");
            this.getLwMbusCommands();
          }
        });
      }
    });
  }
}
