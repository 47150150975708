import { Injectable } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
// Interfaces
import { Task } from "../../request-progress-panel/request-progress-task-interface";

@Injectable({
  providedIn: "root",
})
export class RequestQueueDataService {
  constructor(
    private translate: TranslateService,
    private MeterController: MeterControllerService,
    private ManagementController: ManagementControllerService,
    private GatewayController: GatewayControllerService,
    private SessionDataService: SessionDataService
  ) {}

  // Obtención del texto de acción
  getActionText(action: string, device: any): string {
    // Texto de acción
    switch (action) {
      // Asignar como principal
      case "allocateAsMain":
        return this.translate.instant("assign-as-main-meter");
      // Activar dispositivo
      case "activate":
        return this.translate.instant("activate");
      // Desactivar dispositivo
      case "deactivate":
        return this.translate.instant("deactivate");
      // Divorciar dispositivo
      case "divorce":
        return this.translate.instant("divorce");
      // Borrar dispositivo
      case "delete":
        return this.translate.instant("delete");
      // Asignar/Desasignar dispositivo a gateway
      case "allocate/deallocate":
        return device.assignable
          ? this.translate.instant("assign")
          : this.translate.instant("unassign");
      // Asignar/Desasignar dispositivo a gateway
      case "deallocateDevice":
        return this.translate.instant("unassign");
      // Refrescar puntos finales en gateway
      case "refresh":
        return this.translate.instant("refresh");
      // Asignar mejor gateway
      case "assignBest":
        return this.translate.instant("assign");
      // Asignar mejor gateway redundante
      case "assignBestRedundant":
        return device.task == "allocate"
          ? this.translate.instant("assign-as-main-meter")
          : this.translate.instant("unassign");
      // Asignar mejor gateway en toda la agrupación
      case "agrupationAssignBest":
        return this.translate.instant("assign-best-gateway");
      // Reasignar dispositivos antes del borrado de gateway
      case "reassign":
        return this.translate.instant("reassign");
      // Reasignar dispositivos manualmente antes del borrado de gateway
      case "reassignManually":
        return this.translate.instant("reassign-manually");
      // Actualización de SF min y ADR
      case "updateSfAdr":
        return this.translate.instant("sf-adr-update");
      // Actualización de SF
      case "updateSf":
        return this.translate.instant("sf-update");
      // Limpieza de gateways redundantes
      case "cleanGateways":
        return this.translate.instant("clean-redundant-gateways");
      // Guardado de APP EUI
      case "saveAppEui":
        return this.translate.instant("save") + " App EUI";
      // Configuración de canales de gateway
      case "configureGatewayChannels":
        return this.translate.instant("update-channels");
      // Cambiar agrupación
      case "changeAgrupation":
        return this.translate.instant("change-agrupation");
      // Cambiar entidad
      case "changeEntity":
        return this.translate.instant("change-entity");
      // Asignar mejor y limpiar
      case "optimize":
        return this.translate.instant("network-optimize");
      // Relanzar gateway
      case "relaunchGateway":
        return this.translate.instant("frames-relaunch");
      // Borrar puntos finales de gateway
      case "deleteGatewayEndPoints":
        return this.translate.instant("end-point-deletion");
      // Limpiar alarmas
      case "cleanAlarms":
        return this.translate.instant("clean-alarms");
      default:
        break;
    }
  }

  // Obtención de la petición
  getRequest(device: any, task: Task): Observable<object> {
    switch (task.action) {
      // Asignar como principal
      case "allocateAsMain":
        return this.GatewayController.asignate(
          [device.deviceId],
          task.taskGateway.id,
          true
        );
      // Desactivar dispositivo
      case "deactivate":
        return this.MeterController.resetMeter(device.deviceId, true);
      // Desactivar dispositivo
      case "activate":
        return this.MeterController.activate(device.activationData, true);
      // Divorciar dispositivo
      case "divorce":
        return this.MeterController.resetInactiveMeter(device.deviceId, true);
      // Borrar dispositivo
      case "delete":
        return this.MeterController.removeMeter(
          device.deviceId,
          this.SessionDataService.getCurrentAgrupation().id,
          true
        );
      // Asignar/Desasignar dispositivo a gateway
      case "allocate/deallocate":
        return device.assignable
          ? this.GatewayController.saveDropdown(
              task.taskGateway.id,
              [device.nroSerie],
              true
            )
          : this.GatewayController.unasignate(
              {
                gateway: task.taskGateway.id,
                meterList: [device.deviceId],
              },
              true
            );
      // Desasignar dispositivo de gateways
      case "deallocateDevice":
        // Gateway como dispositivo y dispositivo como gateway para reutilizar las funcionalidades
        return this.GatewayController.unasignate(
          {
            gateway: device.deviceId,
            meterList: [task.taskGateway.id],
          },
          true
        );
      // Refrescar puntos finales en gateway
      case "refresh":
        // Borrado de puntos finales de gateway
        if (device.deleteEndPoints) {
          return this.GatewayController.deleteGatewayEndPoints(
            device.deviceId,
            true
          );
          // Refresco
        } else {
          return this.GatewayController.refreshGatewayEndPointsByDevice(
            task.taskGateway.id,
            [device.deviceId],
            true
          );
        }
      // Asignar mejor gateway
      case "assignBest":
        if (task.reconfigure) {
          return this.ManagementController.refreshConfiguration(
            device.newGatewayId,
            [device.deviceId],
            true
          );
        } else {
          return this.ManagementController.selectBest([device.deviceId], true);
        }
      // Asignar mejor gateway redundante
      case "assignBestRedundant":
        return device.task == "allocate"
          ? this.GatewayController.asignate(
              [device.deviceId],
              device.gatewayId,
              true
            )
          : this.GatewayController.unasignate(
              {
                gateway: device.gatewayId,
                meterList: [device.deviceId],
              },
              true
            );
      // Asignar mejor gateway en toda la agrupación
      case "agrupationAssignBest":
        if (task.reconfigure) {
          return this.ManagementController.refreshConfiguration(
            device.newGatewayId,
            [device.deviceId],
            true
          );
        } else {
          return this.ManagementController.selectBest([device.deviceId], true);
        }
      // Reasignar dispositivos antes del borrado de gateway
      case "reassign":
        return this.MeterController.asignateWithoutCommandDisabledGateway(
          [device.deviceId],
          device.newGatewayId,
          true
        );
      // Reasignar dispositivos manualmente antes del borrado de gateway
      case "reassignManually":
        return null;
      // Actualización de SF min y ADR
      case "updateSfAdr":
        return this.MeterController.updateSfAdr(
          {
            contadorId: device.deviceId,
            min_sf: device.min_sf,
            adrPermission: device.adrPermission ? 1 : 0,
          },
          true
        );
      // Actualización de SF
      case "updateSf":
        return this.MeterController.updateSfList(
          device.deviceId,
          device.sf,
          true
        );
      // Limpieza de gateways redundantes
      case "cleanGateways":
        return this.ManagementController.cleanRedundants(
          [device.deviceId],
          true
        );
      // Guardado de APP EUI
      case "saveAppEui":
        // Borrado de puntos finales de gateway
        if (device.deleteEndPoints) {
          return this.GatewayController.deleteGatewayEndPoints(device.deviceId);
          // Borrado de APP EUIs de gateway
        } else if (device.deleteAppEuis) {
          return this.GatewayController.deleteAllFromGateway(device.deviceId);
        } else {
          return this.GatewayController.saveAppEui({
            entity: device.entityId,
            gateway: device.deviceId,
            appEui: device.appEui,
          });
        }
      // Configuración de canales de gateway
      case "configureGatewayChannels":
        return this.GatewayController.updateGatewayChannels(
          device.deviceId,
          device.channels,
          true
        );
      // Cambiar agrupación
      case "changeAgrupation":
        return this.MeterController.change(device.deviceId, device.agrupation);
      // Cambiar entidad
      case "changeEntity":
        return this.MeterController.changeDeviceEntity(
          device.deviceId,
          device.entity
        );
      // Optimizar
      case "optimize":
        return this.ManagementController.assignBestAndClearRedundantByDevice([
          device.deviceId,
        ]);
      // Relanzar tramas de gateway
      case "relaunchGateway":
        if (device.relaunch.relaunchOnlyMic) {
          return this.MeterController.relaunchMeterMicFrames(
            device.deviceId,
            device.relaunch.relaunchWithAlarms,
            device.relaunch.startDate,
            device.relaunch.endDate
          );
        } else {
          return forkJoin([
            this.MeterController.relaunchMeterMicFrames(
              device.deviceId,
              device.relaunch.relaunchWithAlarms,
              device.relaunch.startDate,
              device.relaunch.endDate
            ),
            this.MeterController.relaunchMeterFrames(
              device.deviceId,
              device.relaunch.relaunchWithAlarms,
              device.relaunch.startDate,
              device.relaunch.endDate
            ),
          ]);
        }
      // Limpiar alarmas
      case "cleanAlarms":
        return this.MeterController.clearAlarms(device.deviceId, true);
      default:
        break;
    }
  }

  // Obtención del título y url de la tarea
  getTaskTitleAndUrl(
    action: string,
    data?: any,
    extraData?: any
  ): { title: string; url: string } {
    switch (action) {
      // Asignar como principal
      case "allocateAsMain":
        return {
          title:
            extraData?.unidadVenta +
            ": " +
            this.translate.instant("assign-as-main-meter"),
          url: "/gateways/asignar-desasignar",
        };
      // Desactivar dispositivo
      case "deactivate":
        return {
          title: this.translate.instant("deactivate"),
          url: "/dispositivos/desactivar",
        };
      // Activar dispositivo
      case "activate":
        return {
          title: this.translate.instant("activate"),
          url: "/dispositivos/activar",
        };
      // Divorciar dispositivo
      case "divorce":
        return {
          title: this.translate.instant("divorce"),
          url: "/dispositivos/divorciar",
        };
      // Borrar dispositivo
      case "delete":
        return {
          title: this.translate.instant("delete"),
          url: "/dispositivos/eliminar",
        };
      // Asignar/Desasignar dispositivo a gateway
      case "allocate/deallocate":
        return {
          title:
            extraData?.unidadVenta +
            ": " +
            this.translate.instant("assign") +
            "/" +
            this.translate.instant("unassign"),
          url: "/gateways/asignar-desasignar",
        };
      // Asignar/Desasignar dispositivo a gateway
      case "deallocateDevice":
        return {
          title:
            extraData?.nroSerie + ": " + this.translate.instant("unassign"),
          url: "/dispositivos/desasignar",
        };
      // Refrescar puntos finales en gateway
      case "refresh":
        return {
          title:
            extraData?.unidadVenta +
            ": " +
            this.translate.instant("gateway-refresh"),
          url: "/gateways/refrescar",
        };
      // Asignar mejor gateway
      case "assignBest":
        return {
          title: this.translate.instant("assign-best-gateway"),
          url: "/gateways/asignar-mejor",
        };
      // Asignar mejor gateway redundante
      case "assignBestRedundant":
        return {
          title: this.translate.instant("assign-best-redundant-gateway"),
          url: "/gateways/asignar-mejor-redundante",
        };
      // Asignar mejor gateway en toda la agrupación
      case "agrupationAssignBest":
        return {
          title:
            this.translate.instant("network-management") +
            ": " +
            extraData?.name,
          url: "/mantenimiento/red/asignar-mejor",
        };
      // Reasignar dispositivos antes del borrado de gateway
      case "reassign":
        return {
          title:
            extraData?.unidadVenta + ": " + this.translate.instant("reassign"),
          url: "/gateways/reasignar",
        };
      // Reasignar dispositivos manualmente antes del borrado de gateway
      case "reassignManually":
        return {
          title:
            extraData?.unidadVenta +
            ": " +
            this.translate.instant("reassign-manually"),
          url: "/gateways/reasignar-manualmente",
        };
      // Actualización de SF min y ADR
      case "updateSfAdr":
        return {
          title: this.translate.instant("sf-adr-update"),
          url: "/dispositivos/actualizar-sf-adr",
        };
      // Actualización de SF
      case "updateSf":
        return {
          title: this.translate.instant("sf-update"),
          url: "/dispositivos/actualizar-sf",
        };
      // Limpieza de gateways redundantes
      case "cleanGateways":
        return {
          title: this.translate.instant("clean-redundant-gateways"),
          url: "/dispositivos/limpiar-redundantes",
        };
      // Guardado de APP EUI
      case "saveAppEui":
        return {
          title:
            this.translate.instant("update") +
            " APP EUIs: " +
            (data[0]?.entity ? data[0]?.entity : data[1]?.entity),
          url: "/gateways/guardar-app-eui",
        };
      // Configuración de canales de gateway
      case "configureGatewayChannels":
        return {
          title: this.translate.instant("update-channels"),
          url: "/gateways/configurar-canales",
        };
      // Cambiar agrupación
      case "changeAgrupation":
        return {
          title:
            this.translate.instant("change-agrupation") + ": " + extraData.name,
          url: "/dispositivos/cambiar-agrupacion",
        };
      // Cambiar entidad
      case "changeEntity":
        return {
          title:
            this.translate.instant("change-entity") + ": " + extraData.entity,
          url: "/dispositivos/cambiar-entidad",
        };
      // Asignar mejor gateway y limpiar redundantes
      case "optimize":
        return {
          title: this.translate.instant("network-optimization"),
          url: "/mantenimiento/red/optimizar",
        };
      // Relanzar gateway
      case "relaunchGateway":
        return {
          title:
            this.translate.instant("frames-relaunch") +
            ": " +
            extraData.gateway.unidadVenta,
          url: "/gateways/detalle/relanzar-tramas/proceso",
        };
      // Limpiar alarmas
      case "cleanAlarms":
        return {
          title: this.translate.instant("clean-alarms"),
          url: "/alarmas/limpiar-alarmas",
        };
      default:
        break;
    }
  }
}
