<div class="file-import-buttons">
  <div class="file-import-button">
    <span class="btn btn-block btn-default btn-file">
      {{
        fileToImport != null || filesToImport != null
          ? filesToImportNames
          : ("file-search" | translate)
      }}
      <input
        #importInput
        type="file"
        [accept]="fileType"
        id="file"
        (click)="
          fileToImport = null;
          filesToImport = null;
          FileImportService.resetFile(importInput)
        "
        (change)="
          multiple
            ? (filesToImport = FileImportService.handleMultipleFileInputKeys(
                $event.target.files,
                fileType
              ))
            : (fileToImport = FileImportService.handleFileInputKeys(
                $event.target.files,
                fileType
              ));
          getFilesNames();
          fileChange.emit(fileToImport)
        "
        [multiple]="multiple"
      />
    </span>
  </div>
  <div *ngIf="!removeButton && (fileToImport || filesToImport?.length > 0)">
    <button
      mat-button
      name="importUser"
      (click)="
        multiple ? fileReady.emit(filesToImport) : fileReady.emit(fileToImport)
      "
      class="mat-button-icon-text"
    >
      <i class="fas fa-upload"></i>
      {{ "import" | translate }}
    </button>
  </div>
</div>
