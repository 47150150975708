// @angular
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
// Interfaces
import {
  TableSelectColumn,
  TableDataColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import { AssignedDevice, DetailDevice, LowBatteryDevice } from "../../../DeviceInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import { Client } from "../../../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../../../../global/LOCAL_TIMEZONE";
import { MeterService } from "../../MeterService.service";
import { MeterBatteryDialogComponent } from "./meter-battery-dialog/meter-battery-dialog.component";


@Component({
  selector: "app-meter-battery",
  templateUrl: "./meter-battery.component.html",
  styleUrls: ["./meter-battery.component.scss"],
})
export class MeterBatteryComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;
  clientList: Client[];
  entityList: Entity[];
  agrupationList: Agrupation[];
  globalAgrupationList: Agrupation[];

  // Selectores
  selectedClient: number;
  selectedEntity: number = -1;
  selectedAgrupation: number = null;
  batteryLoad: number = 60;

  // Table
  tableData: LowBatteryDevice[];
  originalTableData: LowBatteryDevice[];
  tableSelectedData: LowBatteryDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  tableGlobalActions: object[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },{
    title: "show-battery-multigraph",
    icon: "fa-solid fa-battery-three-quarters",
    selectionRequired: true,
    help: "help-table-battery",
    },
  ];

  columns: (TableSelectColumn | TableDataColumn)[] = [
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nro_serie",
      search: "nro_serie",
      sort: "nro_serie",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupationName",
      search: "agrupationName",
      sort: "agrupationName",
      visible: true,
    },
    {
      title: "manufacturer",
      data: "fabricanteParsed",
      search: "fabricanteParsed",
      sort: "fabricanteParsed",
      visible: true,
    },
    {
      title: "model",
      data: "devTypeParsed",
      search: "devTypeParsed",
      sort: "devTypeParsed",
      visible: true,
    },
    {
      title: "battery-date",
      data: "fechaBateriaParsed",
      search: "fechaBateriaParsed",
      sort: "fechaBateria",
      date: true,
      visible: true,
    },
    {
      title: "installation-date",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      visible: true,
    },
    {
      title: "days",
      data: "days",
      search: "days",
      sort: "days",
      visible: true,
    },
    {
      title: "battery-load",
      data: "valueParsed",
      search: "valueParsed",
      sort: "value",
      numerical: true,
      visible: true,
    },
    {
      title: "battery-graph",
      data: null,
      search: null,
      sort: null,
      visible: true,
      batteryGraph: true,
      graph: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;
  @ViewChild(MeterBatteryDialogComponent) meterBatteryDialogComponent: MeterBatteryDialogComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private MeterService: MeterService,
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentClient = this.SessionDataService.getCurrentClient();

    // Escucha de cambios en agrupación
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    if (this.currentClient) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.clientList = JSON.parse(
      JSON.stringify(this.SessionDataService.getCurrentClientList())
    );
    this.clientList.unshift({
      clientId: -1,
      clientName: this.translate.instant("all"),
      entityList: null,
    });
    this.updateClient(this.currentClient);
    this.getGlobalAgrupationList();
    this.getData();
  }

  // Obtención de la lista global de agrupaciones
  getGlobalAgrupationList(): void {
    this.globalAgrupationList = [];
    this.clientList.forEach((client: Client) =>
      client.entityList?.forEach(
        (entity: Entity) =>
          (this.globalAgrupationList = this.globalAgrupationList.concat(
            entity.agrupations
          ))
      )
    );
  }

  // Actualización de cliente
  updateClient(client: Client): void {
    this.selectedClient = client.clientId;
    this.entityList = JSON.parse(JSON.stringify(client.entityList));
    if (this.entityList) {
      this.entityList.unshift({
        id: -1,
        client: client.clientId,
        entity: this.translate.instant("all"),
        agrupations: null,
      });
    }
    this.selectedEntity = -1;
    this.agrupationList = null;
    this.selectedAgrupation = null;
  }

  // Actualización de entidad
  updateEntity(entity: Entity): void {
    this.selectedEntity = entity.id;
    if (this.selectedEntity == -1) {
      this.agrupationList = null;
    } else {
      this.agrupationList = JSON.parse(
        JSON.stringify(
          entity.agrupations.filter(
            (agrupation: Agrupation) => !agrupation.showAllEntity
          )
        )
      );
      this.agrupationList.unshift({
        entity: null,
        id: -1,
        name: this.translate.instant("all"),
        showAllEntity: null,
        timezone: LOCAL_TIMEZONE,
      });
      this.selectedAgrupation = -1;
    }
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.mapSelectorActive = false;
    this.MeterController.getLowBattery({
      cliente: this.selectedClient != -1 ? this.selectedClient : null,
      entidad: this.selectedEntity != -1 ? this.selectedEntity : null,
      agrupacion:
        this.selectedAgrupation != -1 ? this.selectedAgrupation : null,
      bateria: this.batteryLoad,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        let tableData: LowBatteryDevice[] =
          response["body"]?.listaContadoresBat;
        tableData?.forEach((meter: LowBatteryDevice) => {

          meter.agrupationName = this.globalAgrupationList.find(
            (agrupation: Agrupation) => agrupation?.id == meter.agrupation
          )?.name;
          meter.valueParsed = meter.value + "%";
          meter.days = Math.round(
            (meter.fechaBateria - meter.installation) / (3600000 * 24)
          );
          let manufacturer = this.ManufacturerService.getManufacturer(
            meter.fabricante,
            meter.devType,
            this.SessionDataService.getCurrentLanguage()
          );
          meter.fabricanteParsed = manufacturer?.manufacturerText;
          meter.devTypeParsed = manufacturer?.deviceText;

          // Gráfica
          meter.loadBatteryGraph =true;
        });

        this.tableData = tableData;
        this.originalTableData = tableData;
      } else {
        this.tableData = [];
        this.originalTableData = [];
      }
    });
  }

  // Actualización de los datos
  updateData(): void {
    this.tableData = null;
    this.getData();
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  //Visualizacion de bateria de varios contadores en graficos
  showOnGraph() {
    let meters: DetailDevice[] = JSON.parse(JSON.stringify(this.tableSelectedData));
    this.MeterService.openBatteryMultiGraph(meters);
  }
  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-battery-multigraph":
        this.showOnGraph();
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla
  tableActions(action:string, meter:AssignedDevice){
    switch (action) {
      case "load-battery-graph":
        this.MeterService.getBatteryGraph(meter);
      default:
        break;
    }
  }
}
