<!-- Log -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "file-images" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Imágenes -->
    <div class="gateway-log-images">
      <img
        *ngIf="taskImage; else imageNotFound"
        class="map-modal-img img-rounded"
        [src]="taskImage"
      />
    </div>
  </div>

  <!-- Cerrar -->
  <div class="dialog-footer">
    <!-- Cerrar -->
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>

  <!-- Imagen no encontrada -->
  <ng-template #imageNotFound>
    <div class="image-not-found">
      <i class="fas fa-image"></i>
      <span>{{ "image-not-found" | translate }}</span>
    </div>
  </ng-template>
</div>
