<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "new-meter-order" | translate }}
    </h5>
  </div>
  <div class="dialog-body cups-order-body">
    <app-material-select
    [title]="'groups'|translate"
    [fullWidth]="true"
    [selected]="currentAgrupation.id"
    [bindValue]="'value'"
    [bindLabel]="'name'"
    [data]="dropdownAgrupationData"
    (selectedOption)="onAgrupationChange($event)"
    [disabled]="currentEntity.agrupations.length<=1"
    [required]="true"
  ></app-material-select>

  <app-material-select
    [title]="'new-meter-order'|translate"
    [fullWidth]="true"
    [selected]="cupsOrdersData[0]['value']"
    [bindValue]="'value'"
    [bindLabel]="'name'"
    [data]="cupsOrdersData"
    (selectedOption)="onOrderChange($event)"
    [required]="true"
  ></app-material-select>

    <!-- Nuevo campo de texto (por ejemplo, para comentarios) -->
    <mat-form-field appearance="standard" class="full-width">
      <mat-label>{{ 'comments' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="comment"></textarea>
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button mat-button class="mat-button-icon-text" (click)="save()">
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
