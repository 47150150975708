// @angular
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Servicios propios
import { ClientControllerService } from "../../../../services/server/ClientController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { ClientListData } from "../ClientInterface.type";

@Component({
  selector: "app-clientlist",
  templateUrl: "./clients-list.component.html",
  styleUrls: ["./clients-list.component.scss"],
})
export class ClientListComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;

  // Botón nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-client");

  // Tabla
  clientList: ClientListData[];
  exportFileName: string =
    this.translate.instant("client-export") +
    " " +
    moment_timezone(moment()).format("ll");
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "highlightClient", color: "green", title: "current-client" },
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit-client",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete-client",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "gas",
      data: "gas",
      search: "gas",
      sort: "gas",
      alter: {
        condition: "gas",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
          { rule: null, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private translate: TranslateService,
    private ClientController: ClientControllerService,
    public router: Router,
    private SessionDataService: SessionDataService,
    private ReloadComponentService: ReloadComponentService,
    private ToastService: ToastService,
    private RouteCheckService: RouteCheckService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentClient = this.SessionDataService.getCurrentClient();

    // Escucha de cambios en los valores de agrupación e idioma
    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      this.currentClient = client;
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentClient) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de la lista de entidades
  getData(): void {
    this.ClientController.getClientList().subscribe((response) => {
      if (response["code"] == 0) {
        let clientList: ClientListData[] = response["body"];
        this.clientList = clientList.map((client: ClientListData) => {
          client.highlightClient = client.id == this.currentClient.clientId;
          return client;
        });
      } else {
        this.clientList = [];
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, client: ClientListData): void {
    switch (action) {
      case "edit-client":
        this.router.navigate(["/clientes/formulario/editar/" + client.id]);
        break;
      case "delete-client":
        this.deleteClient(client);
        break;
      default:
        break;
    }
  }

  // Redirección a creación de cliente
  newClient(): void {
    this.router.navigate(["/clientes/formulario/nuevo"]);
  }

  // Borrado de cliente
  deleteClient(client: ClientListData): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("delete-client-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.ClientController.removeClient(client.id).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("client-deleted")
                );
                if (client.id == this.currentClient.clientId) {
                  this.SessionDataService.sendClient(
                    this.SessionDataService
                      .getCurrentClientList()
                      .find(
                        (listClient: Client) => listClient.clientId != client.id
                      )
                  );
                }
                this.SessionDataService.sendUpdateNavFlag();
                this.ReloadComponentService.reload();
              }
            }
          );
        }
      });
  }
}
