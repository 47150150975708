<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <div>
          <div class="coverage-heatmap-control">
            <app-material-select
              class="material-header-select"
              [title]="'coverage-map' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="'installed'"
              [data]="coverageSelectOptions"
              (selectedOption)="dataSource = $event?.value; loadComponent()"
            ></app-material-select>
          </div>
        </div>
      </div>

      <div #coverageHeatmapPanel class="panel-body coverage-heatmap-panel">
        <!-- Mapa de contadores -->
        <div class="coverage-heatmap-map">
          <app-map-controller
            *ngIf="mapHeight"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [map3d]="sessionProfile == PROFILES.ARSON"
            [allowKml]="true"
            [polygonHeatValues]="RSSI_LORA_THRESHOLDS"
            [polygonHeatWeight]="polygonWeight"
            [disableCluster]="true"
            [imageOverlayArray]="imageOverlayArray"
            [imageOverlayOpacity]="contrastOpacity"
            [activateAllLayers]="activateAllLayers"
            (actionFlag)="
              dataSource == 'installed' ? filterByGateway($event, true) : null
            "
            [boundsWithGateways]="true"
            (activate3dMapFlag)="activate3dMap()"
            [gatewaysData]="gateways"
            [locationsData]="locations"
            [metersData]="mapData"
          >
          </app-map-controller>

          <div *ngIf="filterGateway" class="coverage-heatmap-filter-gateway">
            <span
              >{{ "filtered-by-gateway" | translate }}:
              <b>{{ filterGateway.unidadVenta }}</b></span
            >
            <button type="button" (click)="resetFilter()">
              <i class="fas fa-times"></i>
            </button>
          </div>

          <div
            *ngIf="mapData && gateways && mapHeight"
            class="coverage-heatmap-filters-container"
            [ngClass]="{ 'coverage-heatmap-filters-hidden': !showRanges }"
          >
            <div class="coverage-heatmap-filters">
              <!-- Radio de las agrupaciones de contadores -->
              <div
                class="coverage-heatmap-range"
                [ngClass]="{ hidden: !showRanges }"
              >
                <label for="deviceRange">
                  {{ "coverage-meter-radius" | translate }}: {{ deviceRange }} m
                </label>
                <div class="coverage-heatmap-range-limits">
                  <span>0 m</span><span>{{ maxRange }} m</span>
                </div>
                <input
                  type="range"
                  class="pointer"
                  max="{{ maxRange }}"
                  min="0"
                  step="1"
                  [(ngModel)]="deviceRange"
                  (change)="updateMapData(true)"
                  [disabled]="mapData?.length == 0"
                />
              </div>

              <!-- Peso de los polígonos -->
              <div
                class="coverage-heatmap-range"
                [ngClass]="{ hidden: !showRanges }"
              >
                <label for="polygonWeight">
                  {{ "coverage-polygon-weight" | translate }}:
                  {{ polygonWeight }}
                </label>
                <div class="coverage-heatmap-range-limits">
                  <span>1</span><span>20</span>
                </div>
                <input
                  type="range"
                  class="pointer"
                  max="20"
                  min="1"
                  step="1"
                  [(ngModel)]="polygonWeight"
                  (change)="updateMapData(false)"
                  [disabled]="mapData?.length == 0"
                />
              </div>

              <!-- Opacidad del contraste -->
              <div
                class="coverage-heatmap-range"
                [ngClass]="{ hidden: !showRanges }"
              >
                <label for="opacity">
                  {{ "coverage-opacity" | translate }}
                </label>
                <div class="coverage-heatmap-range-limits">
                  <span>{{ "min" | translate }}</span
                  ><span>{{ "max" | translate }}</span>
                </div>
                <input
                  type="range"
                  class="pointer"
                  max="1"
                  min="0.1"
                  step="any"
                  [(ngModel)]="contrastOpacity"
                />
              </div>
            </div>
            <button
              type="button"
              class="controls-toggler"
              (click)="showRanges = !showRanges"
            >
              <i *ngIf="showRanges" class="fas fa-caret-down"></i>
              <i *ngIf="!showRanges" class="fas fa-caret-up"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
