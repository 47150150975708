import { BatteryGraphData } from './../../../screens/dashboard/devices/meter/meter-list/meter-battery/meter-battery-dialog/BatteryInterface.type';
// @angular
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ExportTableService } from "../../../services/shared/ExportTableService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { RedirectToService } from "../../../services/shared/RedirectToService.service";
import { NavigationHelperService } from "../../../services/shared/NavigationHelperService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGroupBy,
} from "../TableInterface.type";
import { numberFormat } from "highcharts";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Inputs
  @Input() originalData: any[];
  @Input() columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @Input() rowNumbers: boolean;
  @Input() waitForData: any;
  @Input() columnOrderDisabled: boolean;
  @Input() paginationDisabled: boolean;
  @Input() topPaginationDisabled: boolean;
  @Input() parentElement: boolean;
  @Input() noPinnedColumns: boolean;
  @Input() language: string;
  @Input()
  get transposeTable(): boolean {
    return this._transposeTable;
  }
  set transposeTable(transposeTable: boolean) {
    this._transposeTable = transposeTable;
    if (this._transposeTable) {
      this.resetColumnsWidth();
      this.calculateTransposeHeight();
      this.calculateExtraTableTransposeHeight();
    } else {
      setTimeout(() => {
        if (!this.avoidFixColumns) {
          this.fixColumnWidth();
        }
        this.setTopScrollBar();
        this.removeNestedListListeners();
        this.setNestedListListeners();
      }, 0);
    }
  }
  _transposeTable: boolean;
  // @Input() transpose: boolean;
  @Input()
  get data(): any[] {
    return this._data;
  }
  set data(data: any[]) {
    this._data = data;
    this.currentPage = 0;
    this.getDataPage();
    setTimeout(() => {
      if (!this.avoidFixColumns) {
        this.fixColumnWidth();
      }
      this.calculateTransposeHeight();
      this.calculateExtraTableTransposeHeight();
      this.setTopScrollBar();
      this.removeNestedListListeners();
      this.setNestedListListeners();
      this.setColumnTotals();
    }, 0);
  }
  _data: any[];
  @Input()
  get maxReg(): number {
    return this._maxReg;
  }
  set maxReg(maxReg: number) {
    this._maxReg = maxReg;
    this.currentPage = 0;
    this.getDataPage();
    setTimeout(() => {
      this.setTopScrollBar();
      this.removeNestedListListeners();
      this.setNestedListListeners();
    }, 0);
  }
  _maxReg: number;
  @Input() avoidFixColumns: boolean;

  // Ordenación de tabla
  @Output() sortTable = new EventEmitter<any>();

  // Paginación de la tabla
  currentPage: number = 0;
  maxPage: number;
  dataPage: any[] = [];
  disablePageForward: boolean = false;

  // Acciones
  @Output() tableAction = new EventEmitter<any>();
  @Output() extraTableAction = new EventEmitter<any>();
  @Output() selectAllRowsFlag = new EventEmitter<any>();
  @Output() selectedDataUpdateFlag = new EventEmitter<any>();
  @Output() extraSelectedDataUpdateFlag = new EventEmitter<any>();
  @Output() resetExtraSelectedDataFlag = new EventEmitter<any>();
  @Output() getExtraTableDataFlag = new EventEmitter<any>();
  @Output() expandGraphFlag = new EventEmitter<any>();

  // Selectores
  @ViewChild("tableCheckbox") tableCheckbox: ElementRef;
  @ViewChild("tableCheckboxBis") tableCheckboxBis: ElementRef;
  @Input() selectedCount: number;
  @Input() selectedBisCount: number;
  @Input() lockSelected: number;
  @Input() lockSelectedBis: number;
  @Output() lockSelectedFlag = new EventEmitter<any>();
  @Output() lockSelectedBisFlag = new EventEmitter<any>();

  // Tabla
  @ViewChild("tableData") tableData: ElementRef;
  longPressSelectionFlag: boolean = false;
  lastSelectedIndex: number;
  lastSelectedBisIndex: number;
  groupBy: TableGroupBy;
  @Output() groupByFlag = new EventEmitter<any>();

  // Modal
  modalData: any;
  @Output() showModalFlag = new EventEmitter<any>();

  // Scrolls
  @ViewChild("tableTopScroll") tableTopScroll: ElementRef;
  @ViewChild("tableContainer") tableContainer: ElementRef;
  tableScrollbarActive: boolean;
  tableScrolling: boolean;
  tableScrollX: number;

  // Tabla anidada
  childTableResetFlag: boolean = false;
  @Input() extraTableNoCollapse: boolean;

  // Margen de las columnas fijadas
  columnWidths: { index: number; width: number }[] = [];

  // Filtros por tipología
  @Input() typologyFiltersActive: boolean;
  @Output() showTypologyFiltersFlag = new EventEmitter<string>();

  //constantes para tipos de grafica
  GRAPH_TYPE_BATTERY:string = "Battery";


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private element: ElementRef,
    private ExportTableService: ExportTableService,
    private NavigationHelperService: NavigationHelperService,
    public RedirectToService: RedirectToService,
    private router: Router,
    public SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.removeNestedListListeners();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado del componente
  /***************************************************************************/

  // Funciones tras el renderizado
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.avoidFixColumns) {
        this.fixColumnWidth();
      }
      this.setTopScrollBar();
      this.setNestedListListeners();
    }, 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Aviso de ordenación por columna
  sortByColumn(columnTitle: string, add: boolean, event?: any): void {
    if (event.target.type != "checkbox") {
      event.preventDefault();
      let sortData: any = {
        columnTitle: columnTitle,
        add: add,
      };
      this.sortTable.emit(sortData);
    }
  }

  // Obtención de la página de la tabla
  getDataPage(currentPage?: number): void {
    if (currentPage != null) {
      this.currentPage = currentPage;
    }
    let firstDataIndex: number = this.currentPage * this._maxReg;
    let lastDataIndex: number = (this.currentPage + 1) * this._maxReg;
    this.dataPage = this._data.slice(firstDataIndex, lastDataIndex);
  }

  // Actualización de la paginación de la tabla
  updatePagination(pageEvent: PageEvent): void {
    this.maxReg = pageEvent.pageSize;
    this.getDataPage(pageEvent.pageIndex);
  }

  // Obtención de la acción para el elemento
  getAction(actionData: any): void {
    this.tableAction.emit(actionData);
  }

  // Obtención de la acción para el elemento de la tabla anidada
  getExtraTableAction(actionData: any): void {
    this.extraTableAction.emit(actionData);
  }

  // Exportación de la tabla
  exportTableData(
    fileName: string,
    selection: boolean,
    clipboard: boolean
  ): void {
    let columnsHeaders: any[] = this.columns
      .map((column: any) => {
        if (
          column.visible &&
          column.title != "action" &&
          column.title != "select" &&
          !column.noExport
        ) {
          return this.translate.instant(column.title);
        } else {
          return null;
        }
      })
      .filter((column: string) => column != null);

    let rows: any[] = [];
    this._data.forEach((rowData: any) => {
      if (
        !selection ||
        (selection && (rowData.selected || rowData.selectedBis))
      ) {
        let row: any = {};
        this.columns.forEach((column: any) => {
          if (
            column.visible &&
            column.title != "action" &&
            column.title != "select" &&
            !column.noExport
          ) {
            row[this.translate.instant(column.title)] = rowData[column.data]
              ? column.html
                ? rowData[column.export].toString()
                : rowData[column.data].toString()
              : "-";
          }
        });
        rows.push(row);
      }
    });

    this.ExportTableService.exportToCsv(
      rows,
      fileName,
      columnsHeaders,
      clipboard
    );
  }

  // Selección/deselección de todas las filas
  selectAllRows(
    selectAll: boolean,
    bis: boolean,
    excluding: boolean,
    checkbox?: ElementRef
  ): void {
    if (checkbox && !excluding) {
      checkbox.nativeElement.checked = false;
    }
    this.selectAllRowsFlag.emit({
      selectAll: selectAll,
      bis: bis,
      excluding: excluding,
    });
  }

  // Actualización de los datos seleccionados
  selectedDataUpdate(): void {
    // Columna de selección principal
    if (
      this.tableCheckbox &&
      this._data.find((element: any) => !element.selected)
    ) {
      this.tableCheckbox.nativeElement.checked = false;
    } else if (this.tableCheckbox && this._data.length > 0) {
      this.tableCheckbox.nativeElement.checked = true;
    }
    // Columna de selección secundaria
    if (
      this.tableCheckboxBis &&
      this._data.find((element: any) => !element.selectedBis)
    ) {
      this.tableCheckboxBis.nativeElement.checked = false;
    } else if (this.tableCheckboxBis && this._data.length > 0) {
      this.tableCheckboxBis.nativeElement.checked = true;
    }
    this.selectedDataUpdateFlag.emit();
  }

  // Actualización de los datos seleccionados de tabla anidada
  extraSelectedDataUpdate(actionData: any): void {
    this.extraSelectedDataUpdateFlag.emit(actionData);
  }

  // Muestra el modal de información del elemento
  showModalInfo(element: any): void {
    this.modalData = {
      title: element.modalInfoData.title,
      html: element.modalInfoData.html,
    };
    this.showModalFlag.emit(this.modalData);
  }

  // Seteo del ancho de las columnas tras el renderizado para evitar su redimensionamiento
  fixColumnWidth(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] =
      this.element.nativeElement.querySelectorAll(".table-th");
    let columnsWidth: number[] = Array.from(columns).map((column: any) => {
      return column.offsetWidth;
    });
    let i: number = 0;
    columns.forEach((column: any) => {
      column.style.minWidth = columnsWidth[i] + "px";
      column.style.maxWidth = columnsWidth[i] + "px";
      column.style.width = columnsWidth[i] + "px";
      i++;
    });
  }

  // Reseteo del ancho de columnas
  resetColumnsWidth(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] =
      this.element.nativeElement.querySelectorAll(".table-th");
    columns.forEach((column: any) => {
      column.style.minWidth = "unset";
      column.style.maxWidth = "unset";
      column.style.width = "unset";
    });
  }

  // Repintado de tabla para actualizar el ancho de columnas
  updateColumnsWidth(): void {
    setTimeout(() => {
      this.columns = [...this.columns];
    }, 0);
  }

  // Cálculo del margen izquierdo al bloquear una columna
  calculatePinnedColumnLeft(): void {
    setTimeout(() => {
      let titleRow: any =
        this.element.nativeElement.querySelectorAll(".table-th");
      let columnCount: number = titleRow.length;
      let dataRow: any =
        this.element.nativeElement.querySelectorAll(".table-column-data");
      let left: number = this.rowNumbers ? titleRow[0].offsetWidth : 0;
      titleRow.forEach((column: any, i) => {
        if (column.classList.contains("table-pinned-column")) {
          column.style.setProperty("--table-pinned-column-left", left);
          for (
            let j = i - (this.rowNumbers ? 1 : 0);
            j < dataRow.length;
            j += columnCount - (this.rowNumbers ? 1 : 0)
          ) {
            dataRow[j].style.setProperty("--table-pinned-column-left", left);
          }
          left += column.offsetWidth;
        }
      });
    }, 0);
  }

  // Seteo de la barra de scroll superior
  setTopScrollBar(): void {
    if (this.tableTopScroll) {
      let tableWidth: number = this.tableData.nativeElement.offsetWidth;
      this.tableTopScroll.nativeElement.style.setProperty(
        "--table-width",
        tableWidth
      );
    }
  }

  // Acción al hacer scroll en la barra de scroll inferior
  scrollBottom(): void {
    if (this.tableTopScroll) {
      if (this.tableScrollbarActive) {
        this.tableScrollbarActive = false;
      } else {
        this.tableScrollbarActive = true;
        this.tableContainer.nativeElement.scrollLeft =
          this.tableTopScroll.nativeElement.scrollLeft;
      }
    }
  }

  // Acción al hacer scroll en la barra de scroll superior
  scrollTop(): void {
    if (this.tableTopScroll) {
      if (this.tableScrollbarActive) {
        this.tableScrollbarActive = false;
      } else {
        this.tableScrollbarActive = true;
        this.tableTopScroll.nativeElement.scrollLeft =
          this.tableContainer.nativeElement.scrollLeft;
      }
    }
  }

  // Visualización de tabla extra
  showExtraTable(selectedElement: any): void {
    let showExtraTable: boolean = selectedElement.showExtraTable;
    if (!this.extraTableNoCollapse) {
      this._data.map((element: any) => (element.showExtraTable = false));
    }
    selectedElement.showExtraTable = !showExtraTable;
    this.getExtraTableDataFlag.emit(selectedElement);
    this.resetExtraSelectedDataFlag.emit();
  }

  // Reseteo del checkbox de selección de filas
  resetAllRowSelection(): void {
    if (this.tableCheckbox && (!this.lockSelected || this.parentElement)) {
      this.tableCheckbox.nativeElement.checked = false;
    }
    if (
      this.tableCheckboxBis &&
      (!this.lockSelectedBis || this.parentElement)
    ) {
      this.tableCheckboxBis.nativeElement.checked = false;
    }
    this.childTableResetFlag = !this.childTableResetFlag;
    this.selectedDataUpdate();
  }

  // Actualización de bloqueo de selección
  lockSelectedUpdate(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.lockSelectedFlag.emit();
  }

  // Actualización de bloqueo de selección secundaria
  lockSelectedBisUpdate(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.lockSelectedBisFlag.emit();
  }

  // Detección de la pulsación del ratón sobre la tabla para hacer scroll horizontal
  tableMouseDown(e: any): void {
    if (e.button === 2) {
      e.preventDefault();
      this.tableScrolling = true;
      this.tableScrollX = e.pageX;
    }
  }

  // Detección del final de la pulsación del ratón sobre la tabla para finalizar el scroll horizontal
  tableMouseUp(): void {
    this.tableScrolling = false;
    this.tableScrollX = null;
  }

  // Anulación del menú contextual en la tabla
  tableContextMenu(e: any): void {
    e.preventDefault();
  }

  // Detección del movimiento del ratón sobre la tabla para hacer scroll horizontal
  tableMouseMove(e: any): void {
    if (!this.tableScrolling) return;
    e.preventDefault();
    let x: number = e.pageX;
    let walk: number = (x - this.tableScrollX) * 3; //scroll-fast
    this.transposeTable
      ? (this.tableData.nativeElement.scrollLeft -= walk)
      : (this.tableContainer.nativeElement.scrollLeft -= walk);
  }

  // Cáculo de la altura de tabla transpuesta
  calculateTransposeHeight(): void {
    let transposeHeight: string =
      this.columns.length * 3 + (this.rowNumbers ? 3 : 0) + "rem";
    this.tableData?.nativeElement.style.setProperty(
      "--table-transpose-height",
      transposeHeight
    );
  }

  // Cáculo de la altura de tabla transpuesta
  calculateExtraTableTransposeHeight(): void {
    let extraTableData: any = this._data?.find(
      (element: any) => element?.extraTableData?.columns
    )?.extraTableData;
    if (extraTableData?.columns) {
      let transposeHeight: string =
        extraTableData.columns.length * 3 +
        (extraTableData.rowNumbers ? 3 : 0) +
        "rem";
      this.tableData?.nativeElement.style.setProperty(
        "--table-extra-transpose-height",
        transposeHeight
      );
    }
  }

  // Selección múltiple con ratón
  longPressGroupSelection(
    event: any,
    element: any,
    column: any,
    selectedBis: boolean
  ): void {
    if (event.buttons == 1 || event.buttons == 3) {
      selectedBis
        ? (element.selectedBis = !element.selectedBis)
        : (element.selected = !element.selected);
      if (
        column.excluding &&
        ((!selectedBis && element.selected) ||
          (selectedBis && element.selectedBis))
      ) {
        selectedBis
          ? (element.selected = false)
          : (element.selectedBis = false);
      }
      this.selectedDataUpdate();
    }
  }

  // Selección con pulsación larga
  // longPressSelection(element: any, column: any, selectedBis: boolean): void {
  //   if(!this.longPressSelectionFlag) {
  //     this.longPressSelectionFlag = true;
  //     selectedBis ? element.selectedBis = !element.selectedBis : element.selected = !element.selected;
  //     if(column.excluding && ((!selectedBis && element.selected) || (selectedBis && element.selectedBis))) {
  //       selectedBis ? element.selected = false : element.selectedBis = false;
  //     }
  //     this.selectedDataUpdate();
  //   }
  // }

  // Selección de elemento
  elementSelection(
    event: any,
    element: any,
    column: any,
    index: number,
    selectedBis: boolean
  ): void {
    if (event.buttons == 1 || event.buttons == 3) {
      let selectColumn = selectedBis ? "selectedBis" : "selected";
      let excludingSelectColumn = selectedBis ? "selected" : "selectedBis";

      // Selección de elemento
      element[selectColumn] = !element[selectColumn];
      if (column.excluding && element[selectColumn]) {
        element[excludingSelectColumn] = false;
      }

      // Comprobación de selección múltiple
      if (event.shiftKey) {
        let lastIndex: number = selectedBis
          ? this.lastSelectedBisIndex
          : this.lastSelectedIndex;
        let increment = (lastIndex && lastIndex < index) || !lastIndex ? 1 : -1;
        if (
          lastIndex == null ||
          !this.dataPage.find((element: any) => element[selectColumn])
        ) {
          lastIndex = -1;
        }
        for (
          let i = lastIndex + increment;
          increment > 0 ? i < index : i > index;
          i = i + increment
        ) {
          this.dataPage[i][selectColumn] = !this.dataPage[i][selectColumn];
          if (column.excluding && this.dataPage[i][selectColumn]) {
            this.dataPage[i][excludingSelectColumn] = false;
          }
        }
      }

      // Actualización de la selección
      this.selectedDataUpdate();

      // Actualización de último elemento seleccionado
      selectedBis
        ? (this.lastSelectedBisIndex = index)
        : (this.lastSelectedIndex = index);
    }
  }

  // Seteo de los listeners de la lista anidada
  setNestedListListeners(): void {
    let clickElements = this.tableData?.nativeElement.querySelectorAll(
      ".list-caret, .list-icon, .list-title"
    );
    clickElements?.forEach((clickElement: any) => {
      clickElement.addEventListener("click", this.nestedListClickListener);
    });
  }

  // Listener de click en lista anidada
  nestedListClickListener(e: any): void {
    e.target.parentNode.querySelector(".nested")?.classList.toggle("active");
    if (e.target.classList.contains("list-caret")) {
      e.target.classList.toggle("list-caret-down");
    } else {
      e.target.parentNode
        .querySelector(".list-caret")
        ?.classList.toggle("list-caret-down");
    }
  }

  // Eliminación de los listeners de la lista anidada
  removeNestedListListeners(): void {
    let clickElements = this.tableData?.nativeElement.querySelectorAll(
      ".list-caret, .list-icon, .list-title"
    );
    clickElements?.forEach((clickElement: any) => {
      clickElement.removeEventListener("click", this.nestedListClickListener);
    });
  }

  // Redirección a url
  openLink(
    url: string,
    newTab: boolean,
    linkCheck?: { condition: string; attribute: string; check: string },
    element?: any
  ) {
    // Comprobación de link para actualizar la navegación
    if (linkCheck) {
      switch (linkCheck.condition) {
        case "agrupation":
          if (
            this.SessionDataService.getCurrentAgrupation()[linkCheck.check] !=
              element[linkCheck.attribute] &&
            element[linkCheck.attribute]
          ) {
            let navigationUpdate =
              this.NavigationHelperService.findAgrupationInAllClients(
                linkCheck.check,
                element[linkCheck.attribute]
              );
            this.SessionDataService.sendClient(navigationUpdate.client);
            this.SessionDataService.sendEntity(navigationUpdate.entity);
            this.SessionDataService.sendAgrupation(navigationUpdate.agrupation);
          }
          break;
        case "entity":
          if (
            this.SessionDataService.getCurrentEntity()[linkCheck.check] !=
              element[linkCheck.attribute] &&
            element[linkCheck.attribute]
          ) {
            let navigationUpdate =
              this.NavigationHelperService.findEntityInAllClients(
                linkCheck.check,
                element[linkCheck.attribute]
              );
            this.SessionDataService.sendClient(navigationUpdate.client);
            this.SessionDataService.sendEntity(navigationUpdate.entity);
          }
          break;
        default:
          break;
      }
    }
    newTab
      ? this.RedirectToService.openNewTab(url)
      : this.router.navigate([url]);
  }

  // Expandir gráfica
  expandGraph(columnTitle: string, device: any, type:any): void {
    this.expandGraphFlag.emit({
      type: type,
      title: device.graphData?.title
        ? device.graphData.title
        : this.translate.instant(columnTitle),
      device: device,
    });
  }



  // Agrupación por atributo
  updateGroupBy(columnIndex: number, groupBy: TableGroupBy): void {
    this.columns.map((column) => (column.grouped = false));
    if (this.groupBy && this.groupBy.groupAttribute == groupBy.groupAttribute) {
      this.groupBy = null;
      this.groupByFlag.emit({ groupAttribute: null, groupName: null });
    } else {
      this.columns[columnIndex].grouped = !this.columns[columnIndex].grouped;
      this.groupBy = groupBy;
      this.groupByFlag.emit(groupBy);
    }
  }

  // Visualización de filtros por tipología
  showTypologyFilters(columnTitle: string) {
    this.showTypologyFiltersFlag.emit(columnTitle);
  }

  // Seteo de los totales de columna
  setColumnTotals(): void {
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();
    let columnsWithTotals = this.columns.filter(
      (column: any) =>
        column.showTotal || column.showAverage || column.totalReferenceColumns
    );
    let columns: any = this.columns;

    columnsWithTotals?.forEach((column: TableDataColumn) => {
      let columnData = this._data
        ?.map((element) => element[column.sort])
        ?.filter((data) => data != null);

      // Total
      if (columnData?.length > 0 && column.showTotal) {
        column.total = formatNumber(
          columnData?.reduce((a, b) => a + b),
          numberFormat
        );
      }

      // Media
      if (columnData?.length > 0 && column.showAverage) {
        column.average =
          columnData?.length > 1
            ? formatNumber(
                columnData?.reduce((a, b) => a + b) / columnData?.length,
                numberFormat
              )
            : formatNumber(columnData[0], numberFormat);
      }

      // Proporción entre columnas
      if (columnData?.length > 0 && column.totalReferenceColumns) {
        let columnTotal1 = columns.findIndex(
          (columnTotal: any) =>
            columnTotal.title == column.totalReferenceColumns[0]
        );
        let columnTotal2 = columns.findIndex(
          (columnTotal: any) =>
            columnTotal.title == column.totalReferenceColumns[1]
        );
        column.total =
          columns[columnTotal2].total > 0
            ? formatNumber(
                (columns[columnTotal1].total / columns[columnTotal2].total) *
                  100,
                numberFormat
              )
            : 0;
      }
    });
  }
}
