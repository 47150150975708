// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
// Servicios propios
import { ClientControllerService } from "../../../../services/server/ClientController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-agrupationform",
  templateUrl: "./clients-form.component.html",
  styleUrls: ["./clients-form.component.scss"],
})
export class ClientFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Cliente
  id: number;
  name: string;
  isGas: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ClientController: ClientControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.id = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    if (this.id) {
      this.ClientController.getClient(this.id).subscribe((response) => {
        if (response["code"] == 0) {
          this.name = response["body"]["name"];
          this.isGas = response["body"]["gas"];
        }
      });
    }
  }

  // Actualización de cliente
  updateClient(form: NgForm): void {
    form.value["id"] = this.id;
    this.ClientController.editClient(form.value).subscribe((response) => {
      if (response["code"] == 0) {
        this.SessionDataService.clearUpdateNavFlag();
        this.router.navigate(["/clientes/listado"]);
      }
    });
  }

  // Creación de nuevo cliente
  newClient(form: NgForm): void {
    this.ClientController.saveClient(form.value).subscribe((response) => {
      if (response["code"] == 0) {
        this.SessionDataService.clearUpdateNavFlag();
        this.router.navigate(["/clientes/listado"]);
      }
    });
  }
}
