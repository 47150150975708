import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { EntityControllerService } from "../../../../services/server/EntityController.service";

@Component({
  selector: "app-entity-dialog",
  templateUrl: "./entity-dialog.component.html",
  styleUrls: ["./entity-dialog.component.scss"],
})
export class EntityDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EntityController: EntityControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Salvar cambios en alarma
  editEntityAlias(): void {
    this.EntityController.updateEntityAlias({
      idEntity: this.data.id,
      alias: this.data.alias,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
