<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Menú de opciones del panel -->
        <div class="substitution-data-select">
          <app-material-select
            class="material-header-select"
            [title]="'substitutions' | translate"
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [selected]="currentAgrupation?.id"
            [data]="substitutionDataSources"
            (selectedOption)="selectedDataSource = $event; getData(from, to)"
          ></app-material-select>
        </div>

        <!-- Botón de descarga de informe -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="downloadIcon"
          [title]="downloadTitle"
          [disabled]="
            meterList?.length == 0 || selectedDataSource?.type == 'entity'
          "
          (action)="downloadSubstitutionFile()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'meterSubstitutions'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [highlightRow]="tableHighlightRow"
          [quickFilters]="quickFilters"
          [columns]="columns"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          (dataRequest)="getData($event.from, $event.to)"
          (selectedDataUpdateFlag)="
            tableSelectedData = $event; updateMapSelected()
          "
          [data]="meterList"
        >
        </app-table-controller>
      </div>
    </div>

    <!-- Mapa -->
    <div *ngIf="mapData?.length > 0" class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "substitutions-map" | translate }}</b>
        </h5>
      </div>
      <div class="panel-body">
        <app-map-controller
          #meterSubstitutionMap
          [mapType]="mapType"
          [mapHeight]="mapHeight"
          [disableMeasure]="true"
          [activateAllLayers]="activateAllLayers"
          [gatewaysData]="[]"
          [metersData]="mapData"
        >
        </app-map-controller>
      </div>
    </div>

    <!-- Gráfica -->
    <div *ngIf="meterList?.length > 0" class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "substitutions-graph" | translate }}</b>
        </h5>
      </div>
      <div class="panel-body">
        <div class="substitutions-graph">
          <app-graph-controller
            [chartConstructor]="chartConstructor"
            [highchartsOptions]="highchartsOptions"
            [onlyGraph]="true"
            [data]="chartOptions"
          >
          </app-graph-controller>
        </div>
      </div>
    </div>
  </div>
</section>
