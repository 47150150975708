import { GatewayLocation } from "../../../interfaces/GatewayGlobalInterface.type";

// Umbrales cobertura LoRa
export const RSSI_LORA_THRESHOLDS = [-127, -117, -107, -97];

// Umbrales cobertura MBus
export const RSSI_MBUS_THRESHOLDS = [-98, -94, -92, -90];

// ANCHOR Dispositivo de mapa de cobertura
export interface CoverDevice {
  // Back End
  latitud: number;
  longitud: number;
  devEui: string;
  timestamp: number;
  locationList: GatewayLocation[];
  comment?: string;

  // Front End
  timestampParsed?: string;
  extraTableData?: any;
  rssi?: number;
  snr?: number;
  distance?: number;
}

// ANCHOR Gateway de dispositivo
export interface CoverDeviceGateway {
  id: number;
  unidadVenta: string;
  rssi: number;
  distance: number;
  timestamp: number;
}

// ANCHOR Dispositivo
export interface HeatMapDevice {
  rssi: number;
  latitude: number;
  longitude: number;
  id: number;
  nroSerie: string;
  unidadVenta: string;
}

// ANCHOR Datos de mapa
export interface HeatMapData {
  latLngs: number[][];
  averageValue: number;
  devicesData: HeatMapDeviceData[];
}

// ANCHOR Dispositivo de mapa
export interface HeatMapDeviceData {
  id: number;
  nroSerie: string;
  rssi: number;
}

// ANCHOR Test
export interface Test {
  longitude: number;
  latitude: number;
  device: string;
}

export interface MBusCoverage {
  concentrador: number;
  contador: number;
  rssi: number;
  timestamp: number;
}
