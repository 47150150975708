export const LAST_PATCH_NOTES_USER = {
  es: `### Versión 5.44 (13/11/2024)

* Dispositivo -> Listados:
  - Añadido el último valor de la batería en los listados interno y en red/asignado.
  - Ahora si el nivel la bateria es mayor de 100, se nivela a 100 en los graficos y valor.

* Analisis de datos -> Consultas en BBDD:
  - Añadida  nueva consulta para comparar consumos de contadores entre dos periodos distintos.

* Listado de usuarios -> Nuevo usuario:
  - Desbloqueado el perfil de telemedida para los nuevos usuarios.
  - Desbloqueado el check de telemedida para los nuevos usuarios.

* Telecontrol -> Telemedida:
  - Acceso abierto para admin. cliente, admin. entidad y usuarios de telemedida.
  - Las filas de la tabla ahora se ordenan en función de su color.
  - Corregidos valores de las ventanas en configuración.
`,
  en: `### Version 5.44 (13/11/2024)

* Device -> Listings:
  - Added the latest battery value in the internal and network/assigned listings.
  - Now, if the battery level is greater than 100, it is capped at 100 in the graphs and values.

* Device -> Listings -> Review Orders:
  - Table rows are now sorted based on the line color.

* Data Analysis -> Database Queries:
  - Added a new query to compare counter consumption between two different periods.

* User List -> New User:
  - Telemetry profile unlocked for new users.

* Telecontrol -> Telemetry:
  - Open access for client admin, entity admin, and telemetry users.
  - Fixed values for windows in configuration.
`,
  "es-ca": null,
  fr: null,
  it: null,
  el: null,
};
