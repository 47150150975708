// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { CupsControllerService } from "../../../../services/server/CupsController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MeterService } from "../../devices/meter/MeterService.service";
// Componentes
import { DeviceCupsDialogComponent } from "../../devices/devices-common-components/device-cups-table/device-cups-dialog/device-cups-dialog.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableCupsFilters,
  TableQuickFilter,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import {
  ClaveMaestra,
  EntityDefinition,
} from "../../../../interfaces/CupsGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { CupsDetailDialogComponent } from "../../cups/cups-detail/cups-detail-dialog/cups-detail-dialog.component";
import { CupsDetailOrderDialogComponent } from "../../cups/cups-detail/cups-detail-order-dialog/cups-detail-order-dialog.component";

@Component({
  selector: "app-data-management-cups",
  templateUrl: "./data-management-cups.component.html",
  styleUrls: ["./data-management-cups.component.scss"],
})
export class DataManagementCupsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Tabla
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  maxReg: number = 100;
  tableData: ClaveMaestra[];
  tableSelectedData: ClaveMaestra[];
  exportFileName: string =
    this.translate.instant("export-info") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  entityCupsDefinitions: EntityDefinition[];
  filters: TableCupsFilters[];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "without-devices",
        columnSearch: "withoutDevices",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "delete",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
      ],
      help: "help-table-map",
    },
    {
      title: "meter-orders",
      icon: "fas fa-list-check fa-fw",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
      ],
      help: "help-table-map",
    },
  ];

  // Nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.CupsController.getEntityCups(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let tableData: ClaveMaestra[] = response["body"]["claveMaestraList"];
          tableData.forEach((cups) => {
            cups.pcsValue = cups.pcs ? cups.pcs : null;
            cups.claveParsed = cups.clave != null ? cups.clave : null;
            cups.cupsLink = cups.claveParsed
              ? "/cups/detalle/" + cups.id
              : null;
            cups.subscriber = cups.name
              ? cups.name + " " + (cups.surname ? cups.surname : "")
              : null;
            cups.deletionDisabled =
              cups.meters > 0 ||
              cups.sensors > 0 ||
              (this.sessionProfile != PROFILES.ARSON &&
                this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
                this.sessionProfile != PROFILES.ADMIN_ENTIDAD);
              cups.withoutDevices = cups.meters==0 && cups.sensors == 0
          });
          // CUPS
          let entityCupsData = this.MeterService.getEntityCupsData();
          this.tableCupsColumns = entityCupsData.entityCupsColumns;
          this.cups = entityCupsData.entityCups;
          this.entityNroSerie = entityCupsData.entityNroSerie;
          this.entityCupsDefinitions = entityCupsData.entityCupsDefinitions;
          this.entityCupsDefinitions.push({
            colPosition: "conversionFactor",
            label: "FCV",
            name: "FCV",
            text: "FCV",
            required: false
          });
          this.entityCupsDefinitions.push({
            colPosition: "pcsValue",
            label: "PCS",
            name: "PCS",
            text: "PCS",
            required: false
          });
          this.entityCupsDefinitions.push({
            colPosition: "latitude",
            label: "latitude",
            name: this.translate.instant("latitude"),
            text: "latitude",
            required: false,
          });
          this.entityCupsDefinitions.push({
            colPosition: "longitude",
            label: "longitude",
            name: this.translate.instant("longitude"),
            text: "longitude",
            required: false
          });
          this.setColumns();
          this.tableData = tableData;
        }
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let fcvAndPcsVisible = this.SessionDataService.getCurrentClient().gas;
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: "deletionDisabled",
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: this.cups ? this.cups.name : "CUPS",
        data: "claveParsed",
        search: "claveParsed",
        sort: "claveParsed",
        visible: this.cups ? true : null,
        link: "cupsLink",
      },
      {
        title: this.translate.instant("pcs"),
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: fcvAndPcsVisible==true,
      },
      {
        title: this.translate.instant("fcv"),
        data: "fcv",
        search: "fcv",
        sort: "fcv",
        visible: fcvAndPcsVisible==true,
      },
      {
        title: this.translate.instant("latitude"),
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible:true,
      },
      {
        title: this.translate.instant("longitude"),
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible:true,
      },
      {
        title: "creation-date",
        data: "installation",
        search: "installation",
        sort: "installation",
        date: true,
        visible:true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "subscriber",
        data: "subscriber",
        search: "subscriber",
        sort: "subscriber",
        visible: true,
      },
      {
        title: "meters",
        data: "meters",
        search: "meters",
        sort: "meters",
        numerical: true,
        visible: true,
      },
      {
        title: "sensors",
        data: "sensors",
        search: "sensors",
        sort: "sensors",
        numerical: true,
        visible: true,
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
    this.getFilters();
  }

  // Obtención de los filtros del cups
  getFilters(): void {
    let filters: TableCupsFilters[] = [];
    this.columns
      .filter(
        (column: TableActionColumn | TableDataColumn) =>
          column.title != "action"
      )
      .forEach((column: TableDataColumn) => {
        filters.push({
          name: column.title,
          data: column.data,
          value: null,
        });
      });
    this.filters = filters;
  }

  // Acciones de la tabla
  tableActions(action: string, cups: ClaveMaestra): void {
    switch (action) {
      case "delete":
        this.deleteCups([cups.id]);
        break;
      case "edit":
        this.editCupsModal(cups);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "delete":
        this.deleteCups(this.tableSelectedData.map((cups) => cups.id));
        break;
      case "meter-orders":
        this.openOrderDialog(this.tableSelectedData.map((cups) => cups.id));
        break;
      default:
        break;
    }
  }

  openOrderDialog(cupsIds: number[]){
    this.MaterialDialogService.openDialog(
      CupsDetailOrderDialogComponent,
      {
        cupsIdList: cupsIds,
      }
    )
  }
  // Borrado de CUPS
  deleteCups(cupsIds: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("action-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.deleteEntityCups(cupsIds).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("deleted-success")
            );
            this.SessionDataService.sendUpdateSearchBoxFlag();
            this.SessionDataService.sendReloadPanelFlag();
          } else {
            let errors = response["body"]?.filter(
              (error) => error.responseCode != 0
            );
            if (errors.length > 1) {
              this.ToastService.fireToast(
                "error",
                this.translate.instant("some-element-error")
              );
            } else {
              this.ToastService.fireToast(
                "error",
                this.translate.instant("httpError" + errors[0].responseCode)
              );
            }
          }
          this.getData();
        });
      }
    });
  }

  // Mostrar modal de edición de CUPS
  editCupsModal(cups: ClaveMaestra): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "edit",
      cupsExist: true,
      cupsData: {
        columns: this.entityCupsDefinitions,
        cups: JSON.parse(JSON.stringify(this.cups)),
        data: JSON.parse(JSON.stringify(cups)),
      },
      newCups: JSON.parse(JSON.stringify(cups)),
    });
  }

  // Mostrar modal de edición de CUPS
  newCupsModal(): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "new",
      cupsExist: true,
      cupsData: {
        columns: this.entityCupsDefinitions,
        cups: JSON.parse(JSON.stringify(this.cups)),
        data: {},
      },
      newCups: {},
    });
  }
}
