<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "entity-data-erase" | translate }}</b>
        </h5>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'entityDelete'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>

        <div *ngIf="deleteResponse" class="entity-data-erase-response">
          <h5>
            {{ "entity-erase-response-title" | translate }}
            {{ deleteResponse?.entity }}:
          </h5>
          <div
            *ngIf="deleteResponse?.message"
            class="entity-data-erase-response-data"
          >
            <span>{{ "error-text" | translate }}: </span
            >{{ deleteResponse?.message }}
          </div>
          <div *ngIf="!deleteResponse?.message">
            <div class="entity-data-erase-response-data">
              <span
                >{{
                  "entity-erase-response-gateway-events" | translate
                }}: </span
              >{{ deleteResponse?.gatewayEventsResponse }}
            </div>
            <h4>{{ "entity-erase-response-pending" | translate }}:</h4>
            <div class="entity-data-erase-response-data">
              <span>{{ "alarms" | translate }}: </span
              >{{ deleteResponse?.pendingMetersAlarmsResponse }}
            </div>
            <div class="entity-data-erase-response-data">
              <span>{{ "frames" | translate }}: </span
              >{{ deleteResponse?.pendingMetersFramesResponse }}
            </div>
            <div class="entity-data-erase-response-data">
              <span>{{ event | translate }}: </span
              >{{ deleteResponse?.pendingMetersEventsResponse }}
            </div>
            <div *ngFor="let agrupation of deleteResponse?.agrupationsResponse">
              <h4>{{ "groups" | translate }} {{ agrupation.agrupation }}:</h4>
              <div class="entity-data-erase-response-data">
                <span>{{ "indexes" | translate }}: </span
                >{{ agrupation.readingsResponse }}
              </div>
              <div class="entity-data-erase-response-data">
                <span>{{ "consumptions" | translate }}: </span
                >{{ agrupation.consumptionsResponse }}
              </div>
              <div class="entity-data-erase-response-data">
                <span>{{ "frames" | translate }}: </span
                >{{ agrupation.framesResponse }}
              </div>
              <div class="entity-data-erase-response-data">
                <span>{{ "alarms" | translate }}: </span
                >{{ agrupation.alarmsResponse }}
              </div>
              <div class="entity-data-erase-response-data">
                <span>{{ event | translate }}: </span
                >{{ agrupation.eventsResponse }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
