<footer
  class="footer-container"
  [ngClass]="{
    'subscriber-footer': subscriberFooter,
    'footer-container-toggled': sidebarLocked
  }"
>
  <div class="footer-content">
    <div class="footer-left">
      <span class="footer-highlight"
        ><p>{{ date | date : "yyyy" }} -</p></span
      >
      <span class="footer-highlight"
        ><a href="{{ footerLink }}" target="_blank">{{ footerText }}</a></span
      >
      <span class="footer-highlight"><p>-</p></span>
      <span class="footer-highlight pointer" (click)="showContact()"
        ><a>{{ "contact" | translate }}</a></span
      >
    </div>
    <div class="footer-right">
      <i
        *ngIf="sessionProfile != PROFILES.ABONADO"
        class="fas fa-sync-alt pointer footer-update-version"
        title="{{ 'force-update' | translate }}"
        (click)="forceUpdate()"
      ></i>
      <p
        [ngClass]="{ pointer: latestPatchInfo }"
        (click)="latestPatchInfo ? showLastPathNotes() : null"
        [title]="versionTitle"
      >
        <span class="footer-highlight">{{ "version" | translate }}</span>
        {{ frontVersion }}
      </p>
    </div>
  </div>
</footer>
