export const LAST_PATCH_NOTES_ARSON = `
  ### Versión 5.54 (03/04/2025)

* Dispositivo -> Listado -> Asignados:
  - Añadida opción para exportar solo los dispostivos con comunicación (el documento refleja también el CUPS).
  - Mostrar en el listado en red también los dispositivos LoRaWAN activos no asignados, por defecto en la tabla están ocultos pero a los filtros se añade la opción para verlos/ocultarlos.
* Fabricantes:
  - Añadido fabricante "Pietro Fiorentini" y modelo "SSM AQUO".
* Gateway -> Detalle -> Configurar:
  - Añadida opción para recibir el  el listado de APP EUI enviando el comando al gateway.
  - Añadida opción para eliminar el  el listado de APP EUI enviando el comando al gateway.
  - Redistribución de pantalla.
* Dispositivos -> Listado -> Sustituciones -> Editar:
  - Añadida opción para editar latitud y longitud de una sustitución. 
* Mantenimiento -> Global -> CUPS con múltiples dispositivos:
  - Solucionado error por el que no redirigia correctamente a la página de detalle del CUPS al abrirla en nueva pestaña.
* Módulo Cesium:
  - Revertido a versión previa por incompatibilidad con el código actual.
* Principal:
  - Ahora se contabilizarán aparte los dispositivos externos sin comunicaciones en la gráfica global.
  - Corrección, cuando los dispositivos están pendientes en la gráfica, ahora se filtran por "activo" en la tabla de "no asignados".
  - Corrección, se redirige a la página de concentradores desde la gráfica principal.
* Dispositivo -> Nuevo:
  - Corregido tipo de modelo Zenner IUW LWMBUS.
* Estado de red -> KPIs:
  - Corregido un error por el que no se mostraban todas las series con la opción de todas las agrupaciones.
* Análisis de datos -> Balances:
  - Corregida gráfica circular para mostrar dataLabels.
* Mantenimiento -> Construcción de red:
  - Creada pantalla para la construcción conceptual de redes de suministro de agua.
* Dispositivo -> Detalle:
  - Ahora en la geolocalización de contador aparecerá el resultado de la ponderación por contador y de la triangulación por gateways.

  ### Versión 5.53 (26/03/2025)
  
* Dispositivo -> Tarjetas SIM:
  - Añadido selector de operador.
* Dispositivos -> Detalle -> Contador:
  - Añadida tabla de sniffers si hay algun registro en la pantalla main del contador ( Arson )
*  Dispositivos -> Detalle -> Lw-mbus:
  - Añadida tabla de sniffers si hay algun registro en la pantalla main del contador ( Arson )
* Gateways -> Listado -> Sniffer:
  - Añadido listado de registros de sniffers. ( Arson )
  - Añadidas opciones de eliminación y limpieza a la tabla. ( Arson )
  - Se permite asignar uno o varios contadores como redundante desde la tabla. ( Arson )
* Gateways -> Detalle -> Gateway:
  - Si hay listado de sniffer para el GW aparece la opción para abrir la tabla de sniffer. ( Arson )
* Dispositivo -> Listado -> Asignados:
  - Añadida columna que indica si hay registros en el sniffer de algún GW para el contador. ( Arson )
  - Añadida acción que nos permite abrir la lista de sniffer desde la tabla en caso de que tenga alguno. ( Arson )
  
  ### Versión 5.52 (20/03/2025)
* Dispositivos -> Detalle -> Contador -> Sustitucion Completa:
  - Se permite sustituir un contador activo por otro activo si se ha activado por error antes de sustituirlo.
* Dispositivos -> Listados -> Sustituciones:
  - Añadida la columna de motivo de sustitución al listado de sustituciones.
  - Añadido el motivo de sustitución a la edición de sustituciones.
* Dispositivos -> Listado -> Asignados:
 - Añadida posibilidad desde la tabla para acceder a la descarga de ficheros por contadores.
* Módulo mapa:
  - Añadida opción de búsqueda por dirección a menú de mapa.
* Dispositivo -> Detalle:
  - Corregida geolocalización por promedio de contadores.
  - Añadida selección de agrupación de referencia en geolocalización.
  - Deshabilitada geolocalización por triangulación de gateways por falta de datos de referencia.
* Módulo gráfica:
  - Añadido plugin zipcelx para exportación en XLSX.
  - Sobreescrita funcionalidad de Highcharts de descarga XLS con descarga en XLSX mediante zipcelx.
  - Añadido control de detección de período activo.
  - Ahora en la exportación se tomarán los datos agrupados de los puntos de la gráfica con el rango seleccionado en vez de los originales para que la exportación coincida con la visualización.
  - Ahora se parsearán los datos antes de exportarlos para forzar un máximo de 3 decimales.
* Estado de red -> KPIs:
  - Abierto para administradores de entidad.
* Análisis de datos -> Mapa de calor dinámico:
 - Ahora pueden seleccionarse contadores en el mapa de calor con las mismas funcionalidades que en la pantalla principal.
* Alarmas -> Alarmas de agrupación/Dispositivos con alarmas continuas:
  - Actualizados servicios y adaptados componentes.
* Gestión de datos -> CUPS:
  - Añadida pantalla para CUPS con múltiples contadores.

 ### Versión 5.51 (17/03/2025)
* Análisis de datos -> Evolución de consumo:
  - Añadidas capas de contadores en mapa de calor.
* Análisis de datos -> Balances:
  - Añadido enlace de acceso directo a mapa de calor de los hijos.
* Telecontrol -> Dataloggers:
  - Añadidas columnas para múltiples CUPS posibles.
* Principal:
  - Actualizados textos de contadores lora y mbus.
  - Corregidos estilos responsive.
  - Corregida interacción de opacidad con gráfica global.
  - Corregidos enlaces de gráfica global.
  - Corregida gestión de capas desde gráficas de dispositivos.
  - Corrección de visualización de KPIs respecto a perfiles.
  - Corrección de enlace de KPIs en virtual.
* CUPS -> Importar:
  - Añadido control para chequear que las cabeceras del fichero coinciden con las requeridas.
* Dispositivo -> Detalle:
  - Añadido estado de contador bajo número de serie.
  - Añadida opción de geolocalización de dispositivo a menú de tabla del panel de estado (no funciona).
* Idioma:
  - Forzada actualización de página al cambiar idioma para asegurar que se traduzca completamente la aplicación al nuevo idioma.
* Navegación:
  - Añadida personalización de logo para Gestagua.
* Mantenimiento -> Global -> Gateways:
  - Añadida opción global para ver seleccion en el mapa  y poder filtrar por selección en mapa.
  - Añadida opción global para recargar los puntos finales de un gateway.
  - Añadido opcion global para mostrar el gráfico de carga para los gateways seleccionados en la tabla.

### Versión 5.50 (11/03/2025)
* Sensórica -> Mapa interactivo de fugas:
  - Ahora se seleccionarán automáticamente los contadores al filtrar por nivel de ruido.
  - Ahora los datos adicionales al ruido se ocultarán inicialmente en la gráfica.
  - Ahora en la gráfica solo aparecerá en la leyenda el número de serie de cada contador.
  - Ahora en la gráfica solo serán seleccionables las líneas de ruido y mostrarán en el tooltip la información completa.
  - Corregida interacción entre selección y visibilidad del radio de contadores en el mapa.
  - Ahora la línea de consumo asociada a un sensor en la gráfica también se asociará a la línea de ruido y el dato aparecerá en el mismo tooltip.
* Login:
  - Bloqueado acceso para el perfil PERMANENT_TOKEN.
* Módulo mapa:
  - Añadida funcionalidad para mostrar/ocultar modelos de dispositivos.
  - Corregido un error en la importación de archivos KML por polígonos cuando existían múltiples etiquetas de coordenadas en el mismo polígono.
  - Actualizado control de bloqueo de zoom a contenedor del controlador de mapa.
  - Corregido un error por el que no se cargaban los shapefiles en el mapa de calor.
  - Añadido control para actualizar capas visibles en el mapa de manera dinámica.
  - Actualizado tamaño inicial de marcadores al 75%.
  - Deshabilitado tamaño actual como opción en el menú de tamaño de marcadores.
  - Añadido control de bloqueo de mapa al filtrar por modelos.
* Dispositivo -> Detalle:
  - Eliminado timeout de servicio de sustitución de módulo RF.
  - Actualizada visualización de concentradores sin comunicación a concentradores no recibidos.
* Dispositivo -> Listado sin comunicación/pendientes:
  - Actualizados filtros rápidos en coherencia con el listado en red.
* Gateway -> Actualización de estado:
  - Corregido un error por el que no se mostraban correctamente los código de error.
* Gateway -> Detalle:
  - Ahora se mostrará siempre la tabla de contadores independientemente del estado del gateway.
* Dispositivo -> Nuevo:
  - Ahora cuando se guarde un nuevo dispositivo correctamente, se reseteará el componente.
  - Añadido valor obligatorio de ID de archivo de importación para dispositivo externo Sectorial Tipo 1.
  - Corregido un error por el que aparecía el módulo RF como obligatorio en modelos no aplicables.
* Principal:
  - Rediseño de tarjetas y presentación de datos.
  - Añadido control de capas de mapa desde gráficas de tipo de dispositivo.
  - Ahora no se tendrán en cuenta los concentradores en el cálculo global.
  - Añadido control para cambiar el orden de los datos dependiendo del perfil.
* Usuarios -> Formulario:
  - El perfil de instalador ahora pasa a tener nivel de entidad.
* Mantenimiento -> Concentradores ( Entidad & Global ):
  - Añadida columna de ultimo voltaje del concentrador.
  - Añadida columna de SF y limitados decimales de batería a uno.
* Dispositivos -> Detalle -> Contador:
  - Los dispositivos ahora pueden tener mas de una imagen.
  - Se añade un carrusel para mostrar todas las imagenes disponibles.
  - Se permite eliminar y añadir cada una de las imagenes.
* Dispositivo -> Listado -> Sustituciones:
  - Las sustituciones ahora pueden tener más de una imagen.
  - Se añade un carrusel para mostrar todas las imagenes disponibles.
  - Se permite eliminar y añadir cada una de las imagenes.
  - Desbloqueada edición de sustituciones a perfiles ADMIN.
  - Añadida edición del contador nuevo.
  - Inhabilitada edición de sustitución desde agrupación virtual.
  - Corregida edición de nuevo contador.

### Versión 5.49 (17/02/2025)
* Detalle -> Gateway:
  - Añadida tarjeta de alertas del gateway.
* Ficheros de salida -> Descarga de fichero:
  - Añadido el fichero GTWIN en Comú d'Andorra la Vella.
* Gestion datos -> CUPS -> Ordenes:
  - La tabla ahora busca por fecha con una diferencia inicial de 30 dias desde el dia actual.
* Dispositivos -> Listado -> Ordenes:
  - La tabla ahora busca por fecha con una diferencia inicial de 30 dias desde el dia actual.
* Alarmas -> Alarmas globales:
  - Añadida la columna cliente en las alarmas globales.
* Gestion datos -> CUPS -> Listado:
  - Añadida la columna "ordenes de trabajo".
  - Añadido filtro rápido por ordenes de trabajo.
* Listado -> Gateways:
  - Añadida columna de puntos finales en todas las tablas de gateways con la columna "micro".
  - Añadida la columna de alerta de puntos finales en la bbdd.
  - Añadido filtro por alerta de gateway puntos finales en los listados.
* Detalle -> CUPS -> Ordenes de trabajo:
  - Se añaden ordenes de trabajo para el CUPS, con sus respectivas listas.
  - Añadida orden de "instalar contador".
* Detalle -> CUPS:
  - Se añaden las propiedades longitud y latitud al CUPS.
* Datos customizados -> CUPS:
  - Se añaden los parámetros de  latitud y longitud al CUPS.
* Dispostivo -> Listados -> Dialogo de activación:
  - Parametro de CUPS añadido.
* Módulo mapa:
  - Ahora el zoom con ratón estará desactivado hasta hacer click en el mapa y se volverá a desactivar al perder el foco.
* Navegación:
  - Corrección en el guardado de sesión tras una caída de servidor.
  - Corrección en la carga de idiomas inicial al recuperar sesión tras una caída de servidor.
  - Corrección de carga de usuario al recuperar sesión tras una caída de servidor.
* Formulario cliente/entidad/agrupación:
  - Limitada longitud de textos acorde a servidor.
* Estilos:
  - Unificación y corrección de estilos para modales con solo selector de fecha.
* Principal:
  - Añadidas gráficas de porcentaje de dispositivos activos por tecnología.
* CUPS -> Importar:
  - Corregido timeout de servicio actualizado de importación.
* Datos customizados -> Dispositivos sin CUPS:
  - Eliminada columna de CUPS.

### Versión 5.48 (03/02/2025)

* Dataloggers:
  - Se añade una pantalla para mostrar información sobre los dispositivos PLUM que están en red.
* Datos customizados:
  - Unificadas las secciones de información de contadores y CUPS.
  - Unificadas la importacion de CUPS e importacion de información de contadores.
* Cliente -> Listado:
  - Añadido el campo de "gas" a la tabla.
* Cliente -> Nuevo/Actualizar:
  - Añadido el campo de "gas".
* Datos customizados:
  - Unificadas las secciones de información de contadores y CUPS:
  - Unificadas la importacion de CUPS e importacion de información de contadores.
* Datos customizados -> Metrológica -> Listado de PCS/FCV:
  - Añadida pantalla con tabla en la que rapidamente se podrán ver los dispositivos cuyos FCV/PCS han sido sustituidos respecto al CUPS.
* Alarmas -> Alarmas globales:
  - Añadido un aviso en las alarmas sobre los dispositivos de gas cuyos FCV/PCS no coincidan con los del CUPS.
  - Añadida tabla y dialogo con los dispositivos de gas cuyos FCV/PCS no coincidan con los del CUPS.

### Versión 5.47 (08/01/2025)

* Dispositivos -> Listado -> Errores de activación:
  - Añadida columna de CUPS a la tabla.
* Dispositivos -> Importaciones -> Precarga de datos:
  - Añadida columna de fichero Mbus ID.
* Mantenimiento -> Global -> Gateway:
  - Añadidos filtros por estado para la tabla de mantenimiento global de gateways.
* Usuarios -> Formulario -> Nuevo
  - Añadida la posibilidad de elegir perfil API_WALK_BY al crear nuevo usuario.
* Dispositivo -> Nuevo:
  - Añadido el campo MBus ID a la hora de crear dispositivos WMBUS.
* Dispositivo -> Detalle -> Contador:
  - Añadida la columna de Mbus Id a los detalles del contador en el popup.
  - Añadidos FCV y PCS a detalles del contador para dispositivos activos de gas ( popup ).
  - Si no se dispone de FCV o PCS, saldrá un aviso en la página principal del contador.
  - La opción de sustituir desaparece para los contadores inactivos.
* Datos customizados -> CUPS -> Listado:
  - Añadidos PCS y FCV a la tabla.
  - Añadidos PCS y FCV al dialogo de edición de cups.
* Datos customizados -> CUPS -> Detalle:
  - Añadidos PCS y FCV a la tabla de CUPS.
* Dispositivo -> Detalle -> Sensor:
  - Tarjeta de info. de bateria añadida a los sensores acusticos.

### Versión 5.46 (12/12/2024)

* Gateways -> Detalle Gateway:
  - Solucionado el error que impedía cargar los datos al realizar una búsqueda por fecha en la gráfica.
* Mantenimiento -> Global:
  - Eliminada la opción "Limpiar registros" del menú.
* Telecontrol -> Telemedida:
  - Añadidos los campos de último voltaje y último periodo de vida de la batería en la tabla de telemedida.
  - Añadido un nuevo gráfico de voltaje en la tabla de telemedida.
  - Incluido el tiempo de desfase con el servidor en la tabla de telemedida.
  - Añadida la opción de búsqueda de errores por fecha en la tabla de errores.
  - Añadida la opción para seleccionar la hora al descargar el fichero de facturación.
  - Solucionado el error que generaba un fallo al solicitar un fichero con la fecha por defecto.
* Alarmas -> Listado:
  - Establecido un rango por defecto de una semana al solicitar todas las alarmas.
  - Solucionado el error que impedía cargar las alarmas al cambiar de "todas las alarmas" a "alarmas activas" desde el desplegable.
* Principal -> Dispositivos con alarmas activas:
  - Solucionado el error que mostraba todas las alarmas inicialmente al hacer clic sobre la tarjeta de dispositivos con alarmas activas.
* Ficheros de salida -> Descarga de fichero:
  - Solucionado el error que impedía la descarga de archivos con valores cercanos.
* Gráficas -> Exportar:
  - Solucionado el error que descargaba el archivo sin extensión en algunos formatos al exportar desde la gráfica.
* Mantenimiento -> Concentradores:
  - Añadido el porcentaje de batería en la tabla de concentradores MBUS.
  - Añadida pantalla con la tabla de concentradores por entidad al pulsar sobre la tarjeta de concentradores en red.
  - Acceso directo añadido al menú lateral.
* Dispositivo -> Listado -> Listado de disp. ignorados:
  - Añadido nuevo listado de dispositivos ignorados.
* Dispositivo -> Detalle:
  - Añadida opción para poder enviar a la lista de ignorados un contador.
* Telecontrol -> Telemedida:
  - Añadida acción a la tabla para probar la comunicación manual y mostrar un toast con el resultado de la prueba.
* Dispositivos -> Importaciones -> Importar imágenes:
  - Añadida tabla para mostrar los errores de importación de imágenes.

### Versión 5.45 (20/11/2024)

* Telecontrol -> Telemedida:
  - Corrección de redirección: Se solucionó un problema donde, al hacer clic en un contador de una agrupación diferente, se redirigía incorrectamente a la página de telemetría.
  - Redirección fallida: Se resolvió un error que impedía la correcta redirección al hacer clic en el enlace de un contador.
  - Extension de ficheros: La extension de los ficheros ha sido cambiada de "csv" a "txt"

* Dispositivo -> Log de tramas:
  - Nuevo parseador: Se añadió soporte para el parseo de datos de Octave de Contazara.

* Dispositivo -> Detalle -> Tabla CUPS:
  - Indicador de CUPS no asignados: Si un CUPS no está asignado, la fila correspondiente se resalta en rojo y se muestra una leyenda explicativa.
  - Desactivación de opciones: Si el dispositivo no está activo, las opciones en la tabla se deshabilitan automáticamente.
  - Recarga de dropdown: Al eliminar un CUPS, el menú desplegable (dropdown) del menú se actualiza de manera automática.

* Datos customizados -> Información de contadores -> Dispositivos sin CUPS:
  - Nuevo listado: Se ha añadido un nuevo listado que muestra los dispositivos sin CUPS asignados.
  - Acceso rápido: El listado de dispositivos sin CUPS se ha integrado al menú lateral en agrupaciones con CUPS, con las traducciones correspondientes.

* Otros -> Errores:
- Agrupación de errores: Cuando se reciben errores se agruparán. Si se recibe el mismo error varias veces, se mostrará junto al número de ocurrencias.

### Versión 5.44 (13/11/2024)

* Dispositivo -> Listados:
  - Añadido el último valor de la batería en los listados interno y en red/asignado.
  - Ahora si el nivel la bateria es mayor de 100, se nivela a 100 en los graficos y valor.

* Dispositivo -> Detalle -> Contador:
  -Añadida en el menú opción para eliminar valores entre fechas para un contador. (Solo Arson)

* Analisis de datos -> Consultas en BBDD:
  - Añadida  nueva consulta para comparar consumos de contadores entre dos periodos distintos.

* Listado de usuarios -> Nuevo usuario:
  - Desbloqueado el perfil de telemedida para los nuevos usuarios.
  - Desbloqueado el check de telemedida para los nuevos usuarios.

* Telecontrol -> Telemedida:
  - Acceso abierto para admin. cliente, admin. entidad y usuarios de telemedida.
  - Las filas de la tabla ahora se ordenan en función de su color.
  - Corregidos valores de las ventanas en configuración.


### Versión 5.43 (05/11/2024)

* Dispositivo -> Detalle -> Contador:
  - Se ha añadido a la tarjeta de batería un botón que nos permite visualizar un grafico sobre la evolución de batería del dispositivo.
  - Se ha corregido la gráfica circular de consumo, se tienen en cuenta tambien el número de valores interpolados a la hora de hacer el cálculo.

* Dispositivo -> Listados:
  - Afecta a los listados de bateria baja, interno y asignados.
  - Se ha añadido a los listados un minigrafico de evolución de bateria en la tabla.
  - Se ha añadido una opcion general a las tablas que permite comparar la evolucion de bateria entre varios dispositivos seleccionados previamente.

### Versión 5.42 (30/10/2024)

* Telecontrol -> Telemetría:
  - Desbloqueados test aunque no haya conexión a red.
  - Actualizado ordenamiento de tabla por fecha de último envío de fichero con errores de envío al principio.
  - Actualizado control de fichero no enviado teniendo en cuenta la hora de consulta.
  - Añadido modal con información extra de los contadores.
  - Añadida opción de descarga de fichero de facturación y fichero de valores.
  - Añadido resaltado de error de valores idénticos.
  - Añadido modal de listado de errores.
* Telecontrol -> Test:
  - Creado componente para dispositivos EK280/OWASYS en test.
* Dispositivo/Análisis de datos/Datos customizados:
  - Actualizada gestión de columnas de campos personalizados para evitar redundancia de código y adaptados componentes que las muestren.
  - Actualizado título de columna de número de serie en listados que muestren columnas personalizadas para mostrar el label personalizado.
* Dispositivo -> Nuevo:
  - Limitado número de serie OWASYS a 9 caracteres.
  - Ahora tras un guardado exitoso se volverá automáticamente a la pantalla anterior.
  - Ahora se permite la creación de EK280/OWASYS sin activación.
* Dispositivo -> Configuración EK280/OWASYS:
  - Corregido control de activación de botón de guardado.
  - Actualizados textos para OWASYS.
* Dispositivo -> Test EK280/OWASYS:
  - Actualizados textos para OWASYS.
* Alarmas -> Listado:
  - Añadidas columnas de campos personalizados.
* Dispositivo -> Detalle:
  - Añadidos datos específicos de EK280/OWASYS al modal de información de contador.
* Módulo tabla:
  - Añadida opción de permitir duplicados en filtrado por archivo y portapapeles.
  - Añadida propiedad de columna para visualizar total/media en título de columna.
  - Añadido filtrado por asociación de contadores.
  - Ahora se visualizará si existen filtros activos de fichero o asociación.
* Principal:
  - Añadido enlace en tarjeta de gateways para perfiles no ARSON a listado de gateways de cliente.
* Gateway -> Detalle:
  - Añadida opción a menú de componente para actualizar canales del gateway.
* Estado de red -> Global:
  - Añadidas nuevas columnas de KPI Valores horarios (%), licencia de la entidad, nº de contadores del proyecto, GWS instalados, GWs en stock en la entidad.
  - Añadido valor total/media de la columna en título de columna.
* Análisis de datos -> Asociaciones de contadores:
  - Añadida opción de crear orden de trabajo para toda una asociación.
* Ficheros de salida:
  - Actualizados timeouts de servicio de ficheros especiales.
  - Corregida comprobación de rango de intervalo.
* Módulo mapa:
  - Ahora las capas aparecerán ordenadas alfabéticametente en el selector.
* Dispositivo -> Sustituciones:
  - Desbloqueada pantalla de edición de sustituciones para perfiles admin.

### Versión 5.41 (23/10/2024)

* Dispositivo -> Log de tramas:
  - Añadido parseador de tramas para Flonidan.
* Telecontrol -> Telemedida:
  - Creado componente para visualización de EK280/OWASYS.
* Usuario -> Formulario:
  - Añadido check telemedida.
  - Añadido usuario telemedida.
* Mantenimiento -> Entidad:
  - Añadido componente de listado de gateways para clientes.
* Usuario -> Telemedida:
  - Añadida redirección a telemedida en login.
  - Añadida variable de control de telemedida en datos de sesión.
  - Actualizada navegación con solo acceso a pantalla de telemedida.
* Dispositivo -> En red:
  - Añadida gráfica de posibles fugas en modal de gráfica de consumo.
  - Añadida opción de cambiar rango de fecha en modal de gráfica de consumo.
  - Actualizado texto de filtro rápido en red.
* Dispositivo -> Detalle:
  - Ahora no se utilizarán los valores interpolados a la hora de calcular la gráfica de posibles fugas.
  - Ahora el cálculo de la gráfica de fugas se hace teniendo en cuenta el menor valor diario.
  - Corregido un error por el que la gráfica de fugas se mostraba con los índices.
  - Añadida opción para ocultar la gráfica de fugas.
  - Actualizada visibilidad de opciones de SIM para EK280/OWASYS para perfiles admin.
  - Ahora al actualizar nonces se recargará automáticamente el componente.
  - Ahora el timestamp de sustitución completa se envía en segundos en coherencia con la app.
* Dispositivo -> Nuevo:
  - Ahora se podrá seleccionar entidad en la creación de EK280/OWASYS.
* Principal:
  - Añadida opción tras selección en mapa de ir a listado en red filtrando por selección.
  - Corregido un error por el que no se contabilizaban los modelos OWASYS en las gráficas.
* Alarmas:
  - Añadidas alarmas para GWF SONICO NANO.
  - Añadidas alarmas para Flonidan Sciflo G4.
* Mantenimiento -> Global -> Eventos de usuario:
  - Ahora al ir a un contador desde su enlace en tabla se actualizará la agrupación en curso si es posible.
* Navegación:
  - Actualizada navegación para usuario Telemedida con solo acceso a pantalla de telemedida.
  - Actualizados estilos de total de dispositivos de entidad en barra superior.
  - Actualizado control de enlace de logo de menú para ir a la ruta de entrada correspondiente dependiendo del perfil.
* Dispositivo -> Pendientes:
  - Borrado de servidor EK solo para perfil ARSON (falta borrado múltiple).
* Dispositivo -> Importación:
  - Añadidos nuevos errores de importación para EK280/OWASYS.

### Versión 5.40 (16/10/2024)

* Dispositivo -> Órdenes de trabajo:
  - Habilitada visualización a todos los perfiles.
  - Restringido creado/borrado a perfiles admins.
* Usuario -> Formulario:
  - Corregido control de guardado para que no se permita salvar los cambios si alguno de los campos requeridos no está rellenado.
  - Añadido perfil de token permanente y visualización de token en la edición.
* Módulo tabla:
  - Corregida posición de botones de filtro por tipología.
* CUPS -> Detalle:
  - Corregido un error por el que no se cargaba la gráfica de consumo si se había sustituido un contador del CUPS.
* Dispositivo -> Nuevo:
  - Corregido un error por el que no se cargaba automáticamente fabricante/modelo para OWASYS en precarga.
  - Actualizado texto de número de serie de conversor para OWASYS.
  - Ahora se precargará la versión de LoRa 1.0.3 y TX POWER a 0 para modelos Diehl HRLc G3 v4, HRLc G3 v4.2, RF HRLc G3 v4, RF HRLc G3 v4.2 y Itron Cyble 5.
  - Corregido un error por el que no aparecía la edición de las ventanas de comunicación para EK280/OWASYS.
  - Añadidos nuevos campos (conectado a red, excluir de fichero, número de serie en fichero) para EK280/OWASYS.
* Dispositivo -> Detalle:
  - Añadido resalte de fugas en gráfica de consumo.
  - Ahora la tabla de sectores sólo aparecerá en caso de que el dispositivo esté asignado a alguna asociación.
  - Añadida opción de desasignar SIM para EK280.
  - Actualizado modal de configuración de EK280 con nuevos campos (conectado a red, excluir de fichero, número de serie en fichero).
* Principal:
  - Corregido un error por el que se sumaban los TXN en el número total de dispositivos.
  - Añadida tarjeta de TXN.
  - Corregido un error por el que no se contabilizan en la gráfica los contadores EK280/PLUM/OWASYS.
* Dispositivo -> Precarga de datos:
  - Añadidos nuevos campos (conectado a red, excluir de fichero, número de serie en fichero) para EK280/OWASYS.
* Navegación:
  - Añadida gráfica de dispositivos en el projecto/con licencia/activados en la barra de reloj si la entidad en curso tiene los datos configurados.

### Versión 5.39 (04/10/2024)

* Dispositivo -> Log de tramas:
  - Añadido parseador de tramas para Honeywell SIGFOX.
  - Añadido parseador de tramas para Constrat LSV ZY SMART y Aquaguard.
* Dispositivo -> Nuevo:
  - Corregido un error por el que aparecía el fabricante BMeter sin modelo en el listado de creación de WMBus.
* Gateway -> Detalle:
  - Añadida opción de asignar contadores a mejor redundante sin comunicación en menú de componente para gateways desactivados.
* Módulo mapa:
  - Corregido un error por el que no se visualizaban los iconos para los TXN.
  - Añadidas capas para SIGFOX.
  - Añadida opción de búsqueda por asociación a menú de mapa.
* Módulo tabla:
  - Añadida opción de filtrado por tipología en columna.
* Alarmas -> Listado:
  - Añadida columna con el total de alarmas de contador.
* Dispositivo ->  Detalle:
  - Sustituida gráfica de estado de válvula por tabla.
* Principal:
  - Añadida tarjeta de válvulas.
* Entidad -> Formulario:
  - Añadidos campos para dispositivos en proyecto y dispositivos incluidos en la licencia.

### Versión 5.38 (30/09/2024)

* Principal:
  - Corregido un error por el que si se deshacía la selección mientras estaba fijada en el mapa, no se devolvían los marcadores a su capa correspondiente.
  - Actualizada gráfica de totales para mostrar la información de manera más clara.
  - Actualizados enlaces de tarjetas para filtrar correctamente por tipo al acceder a los listados correspondientes.
* Fabricantes/Modelos:
  - Añadido modelo HB G3 SIGFOX de Honeywell.
  - Añadidas alarmas 2700 a 2714 para HB G3 SIGFOX.
* Dispositivo -> Nuevo:
  - Añadido tipo de dispositivo Externo y modelos correspondientes.
  - Corregido un error que hacía que desapareciesen opciones al cambiar de modelo en un mismo fabricante.
  - Filtrados números de serie de PLUM acorde al modelo seleccionado GPRS/NBIoT.
* Dispositivo -> Log de tramas:
  - Añadido parseador de tramas para GWF Sonico Nano.
  - Añadido control para envío de trama a contador.
* Dispositivo -> Detalle:
  - Habilitada sustitución de sólo contador y completa.
  - Añadida válvula como tipo de dispositivo independiente y adaptado componente.
  - Añadida gráfica de estado de válvula.
* Dispositivo -> Órdenes de trabajo:
  - Actualizados servicios y adaptados componentes dependientes.
* Dispositivo -> Precarga de datos/Nuevo:
  - Añadido dato para estado de válvula (sólo ARSON).
* CUPS -> Detalle:
  - Añadida válvula como tipo de dispositivo independiente y adaptado componente.
* Sensórica -> Mapa interactivo de fugas:
  - Actualizados colores y tipos de línea en gráfica de ruido para que haya mayor contraste.
* Dispositivo -> Sustituciones:
  - Limitado tamaño de imágenes en modal para optimizar la visualización.
  - Ahora se actualizarán las imágenes visualizadas al guardar una nueva.
* Análisis de datos -> Asociaciones de contadores:
  - Corregido un error por el que no se guardaban los cambios realizados al editar una asociación.
* Módulo tabla:
  - Corregido un error por el que el filtro especial para valores numéricos no permitía comparar con 0 o valores negativos.

### Versión 5.37 (18/09/2024)

* Dispositivo -> Nuevo:
  - Cambiado campo "Alias" por "Módulo RF" para dispositivo externo por API.
  - Añadido PLUM NBIoT a las opciones de creación.
  - Actualizada gestión de atributos acorde a los fabricantes/modelos.
* Fabricantes/Modelos:
  - Actualizado modelo PLUM por MACR6.
  - Añadido modelo PLUM MACR6 NBIoT.
  - Añadidos fabricante GWF y modelo sonico NANO.
  - Añadidos fabricante CONSTRAT y modelos válvula de agua LSV ZY SMART y AQUAGUARD.
* CUPS -> Detalle:
  - Añadida opción de órdenes de trabajo a tabla de contadores.
* Telecontrol -> Test:
  - Añadida pantalla para equipos en test.
* Dispositivo -> Detalle:
  - Añadida sustitución de sólo contador y completa.
* Dispositivo -> Órdenes de trabajo:
  - Actualizados servicios y adaptados componentes dependientes.
* Principal:
  - Actualizada gráfica de totales de dispositivos para diferenciar comunicaciones LoRaWAN y MBUS.
  - Añadida búsqueda por portapapeles en mapa.
* Entidad -> Formulario:
  - Corregido un error por el que si no se enviaba dato en el límite de días fallaba el guardado.
* Módulo mapa:
  - Añadido guardado de estado de mapa por tipo al cambiar de url.
  - Añadida carga de datos de mapa si existen datos guardados.
  - Añadido control para fijar y desfijar selección en mapa independientemente de las capas en /principal.
  - Añadidas variables de mapa para cobertura MBus.
* Concentrador -> Detalle:
  - Añadida gráfica de reintentos de máscara para LW MBUS.
* Cobertura -> Mapa de cobertura MBus:
  - Añadido mapa de cobertura para contadores MBus respecto a concentradores LW MBus.
* Dispositivo -> Dispositivos desconocidos:
  - Actualizados rangos de cobertura.

### Versión 5.36 (09/09/2024)

* Dispositivo -> Log de tramas:
  - Actualizado parseador de tramas para Honeywell gas.
  - Corregido un error al parsear el tipo de trama de Honeywell gas.
  - Actualizado parseador de tramas para Viewshine.
  - Actualizado parseador de tramas para Sagemcom Gas.
  - Actualizado parseador de tramas para Beta Gas.
* Dispositivo -> Listados:
  - Corregidas redirecciones a creación de nueva asociación.
* Análisis de datos -> Asociaciones -> Nueva:
  - Corregida preselección de CUPS a partir de preselección contadores.
* Concentrador -> Detalle:
  - Corregido un error por el que se pedía la cola de comandos para perfiles sin acceso.
* Navegación:
  - Añadido retardo a la apertura de los selectores de cliente/entidad/agrupación y al menú lateral para evitar aperturas accidentales al pasar el ratón.
  - Reducido tamaño de barra de navegación en responsive para optimizar el espacio en pantalla.
* Dispositivos -> Desactivar:
  - Aumentado el timeout del servicio a 2 minutos.

### Versión 5.35 (12/08/2024)

* Dispositivo -> Precarga de datos:
  - Añadida columna ACTIVATE a preselección sin comunicación.
* Dispositivo -> Detalle:
  - Corregido un error al guardar imagen si el número de serie contenía "/".
* Dispositivo -> Sustituciones:
  - Corregido un error al guardar imagen si el número de serie contenía "/".
* Navegación:
  - Corregido un error por el que continuaba la sincronización de notificaciones aunque el token de usuario hubiese caducado.
  - Ahora si la sesión caduca y el perfil es ARSON, no se deslogueará automáticamente aunque no se renueve la sesión.
  - Ahora si la sesión caduca y hubiera tareas pendientes, se pausarán.
  - Añadido archivo de notas de versión por idiomas para perfiles no ARSON.
  - Añadido control para habilitar la visualización de notas de versión a perfiles no ARSON solo en caso de existir.
  - Añadido control para fijar el menú lateral y evitar el colapso automático.
  - Añadido manifiesto de service worker específico para url de Constrat.
  - Añadido control para actualizar el manifiesto de service worker dependiendo de la url.
  - Elminada precarga de logo ARSON en barra de menú superior.
* Módulo mapa:
  - Añadidos estilos de icono para resaltar contadores pendientes de sustitución.

### Versión 5.34 (05/08/2024)

* Mantenimiento -> Global -> Eventos de usuario:
  - Añadidos eventos faltantes 29, 30 y 31.
  - Añadido filtro en selector de evento.
* Idiomas:
  - Corrección de textos de menús en griego.
* Dispositivo -> Detalle:
  - Añadida opción de orden de sustitución a menú.
* Dispositivo -> En red:
  - Añadida opción de orden de sustitución a menú.
* Dispositivo -> Nuevo:
  - Corregido un error por el que no se visualizaban correctamente las etiquetas de tipos de dispositivo en el selector.
* Navegación:
  - Añadido control para cambiar el título y favicon de la aplicación dependiendo de la url.
  - Añadida personalización de título y favicon para Constrat.
* Dispositivo -> Listados -> Órdenes de trabajo:
  - Añadido componente para listado de órdenes de trabajo.
* Dispositivo -> Detalle -> Órdenes de trabajo:
  - Añadido componente para listado de órdenes de trabajo.
* Dispositivo -> Log de tramas:
  - Actualizado parseador Kamstrup.
* Gateway -> Detalle:
  - Corregido un error por el que no se tenía en cuenta el resultado nulo al refrescar un dispositivo.
* Fabricantes:
  - Actualizado modelo Hermes a fabricante Externo.

### Versión 5.33 (22/07/2024)

* Navegación:
  - Añadida opción de notas de versión para perfiles no ARSON.
* Dispositivo -> Nuevo:
  - Añadida opción de "Externo sin comunicación".
  - Añadida opción de "Externo por API".
* Dispositivo -> Detalle:
  - Corregido un error por el que la serie de ruido se mostraba en un eje incorrecto en la gráfica de consumo.
  - Corregida fecha de última comunicación en tooltip de mapa para dispositivos externos.
* Concentrador -> Detalle:
  - Añadida opción en menú de panel para actualizar la habilitación de reintentos de máscara para LW MBUS.
* Navegación:
  - Añadido control para personalizar logo de barra lateral y pie de página según url.
  - Añadida personalización para url Constrat.
* Datos customizados -> CUPS:
  - Corregido timeout de importación.

### Versión 5.32 (16/07/2024)

* Concentrador -> Detalle:
  - Añadidos dispositivos fijados no recibidos a mapa de dispositivos asociados para concentrador LW MBUS.
* Navegación:
  - Añadida opción de icono como subíndice en menús de barra lateral.
  - Actualización de iconos en barra lateral.
  - Añadido el idioma griego en el menú responsive.
* Módulo mapa:
  - Actualizada visualización de dispositivos TXN para mostrar el modelo en el icono.
* Mantenimiento -> Eventos:
  - Añadido componente para mostrar eventos por tipo.

### Versión 5.31 (11/07/2024)

* Dispositivo -> Log de tramas:
  - Añadida opción de parseo de payload para BETA.
  - Añadida opción de parseo de payload para AIMEI.
  - Deshabilitado parseo para tramas con MIC ERROR.
* Datos customizados -> CUPS:
  - Actualizado listado para borrar varios CUPS a la vez.
* Dispositivo -> Listado -> Orden de revisión:
  - Corregida visualización de cups.
  - Corregidos enlaces a cups.
* Fabricantes:
  - Añadidos modelos de contador y módulo RF, EU868 y AS923 de AIMEI.
  - Añadidas alarmas para modelos nuevos de AIMEI.
  - Añadido modelo MERLIN (MBUS) de Honeywell.
  - Corregido modelo V200H de Honeywell.
* Idiomas:
  - Corregido un error por el que no se cargaba correctamente la aplicación al iniciar sesión en griego.
* Gateway -> Detalle:
  - Añadidos canales 3 a 7 de ruido en gráfica.
* Dispositivo -> Detalle:
  - Corregido un error en la visualización de las ventanas del EK280.
* Entidad -> Formulario:
  - Ahora si no se especifica la restricción de visualización, por defecto no se aplicará restricción alguna (antes se enviaba 0 días).
* Gateway -> En stock:
  - Añadido campo "canales" a importación y creación de gateway.
* Alarmas -> Dispositivos con alarmas continuas:
  - Añadido componente para mostrar alarmas agrupadas por tipo.
* Ficheros de salida -> Descarga de fichero:
  - Añadido fichero IZFE para Hernani.

### Versión 5.30 (04/07/2024)

* Entidad -> Listado:
  - Abierto a perfiles administradores.
* Entidad/Agrupación:
  - Añadido alias a opciones de edición.
  - Añadido alias a listado con opción de edición para perfiles con acceso.
* Navegación -> Selectores de entidad/agrupación:
  - Ahora aparecerán los alias de las entidades/agrupaciones bajo el nombre si existen.
  - Ahora al filtrar entidades/agrupaciones se buscarán coincidencias también en los alias.
* Análisis de datos -> Balances:
  - Corregido un error al mostrar los porcentajes de lecturas padres/hijos si era 0.
  - Añadidos valores totales de lecturas padres/hijos.
* Datos customizados -> Información de contadores:
  - Corregida visualización en menú lateral.
  - Corregido parseo de errores en feedback de importación.
* Mantenimiento -> Global -> Concentradores:
  - Corregida actualización de selectores al ir a detalle de concentrador.

### Versión 5.29 (02/07/2024)

* Concentrador -> Detalle:
  - Fusionadas tarjetas de consumo para LW MBUS.
  - Destacado consumo total en tarjeta de consumos.
  - Añadida tarjeta de carga de batería restante para LW MBUS.
* Principal:
  - Añadido número de contadores MBUS leídos a tarjeta de contadores MBUS.
* Análisis de datos -> Balances:
  - Añadido porcentaje de lecturas de padres e hijos bajo la gráfica.
* Alarmas:
  - Añadidas alarmas 336, 343, 346 y 347.
  - Añadidas alarmas de HONEYWELL BK G4 E.
* Mantenimiento -> Concentradores:
  - Añadido listado global de concentradores.
* Navegación:
  - Añadida traducción de textos en cliente Constrat en desplegables entidad/agrupación para perfil ARSON.
* Módulo mapa:
  - Añadido link de contador MBUS a concentrador en el mapa principal.
  - Añadido tipo y controles para marcadores TXN.
* Ficheros de salida -> Descarga de fichero:
  - Añadido fichero ATMGTnet para Lillo.
* CUPS:
  - Actualización global de CUPS para aceptar múltiples contadores.

### Versión 5.28 (20/06/2024)

* Fabricantes:
  - Añadido modelo BKG4 E de Honeywell.
* Ficheros de salida -> Descarga de fichero:
  - Actualizado control de ficheros por defecto para clientes/entidades/agrupaciones concretas.
* Sensórica -> Mapa interactivo de fugas:
  - Actualizados servicios para hacer la petición de lecturas de varios sensores a la vez.
* Dispositivo -> Listado interno:
  - Añadida columna para saber si el dispositivo tiene foto asociada.
* Módulo cesium:
  - Corregido un error por el que no se visualizaban las fotos de dispositivo.
* Dispositivo -> Detalle:
  - Corregido acceso a botones de válvula y modo sólo para administradores.
* Dispositivo -> Listados -> En red:
  - Corregida visualización de acciones por perfil.
* Dispositivo -> Listados -> Pendiente:
  - Corregida visualización de acciones por perfil.
* Idiomas:
  - Añadido archivo de idiomas para griego y actualizados selectores de idioma.
  - Corregidos algunas etiquetas y eliminadas traducciones repetidas.
* Concentrador -> Detalle:
  - Ahora la tarjeta y la gráfica de tensión sólo estarán disponibles para perfil ARSON.
* Ficheros de salida -> Descarga de fichero:
  - Añadido tipo de archivo POSEIDONIA para Puerto de Vigo.
  - Corregido un error por el que fallaba la descarga del fichero de UGENA.
* Dispositivo -> Log de tramas:
  - Añadida opción de parseo de payload para HONEYWELL GAS.

### Versión 5.27 (14/06/2024)

* Entidad -> Formulario:
  - Corregido un error por el que no se habilitaba el API ID al cargar.
* Sensórica -> Mapa interactivo de fugas:
  - Corregido un error por el que no se estaban teniendo en cuenta los contadores con la misma localización que el sensor al generar el mapa de calor.
  - Corregida visualización de gráfica de filtro en responsive.
  - Añadido aviso de agrupación sin sensores.
* Dispositivo -> Detalle:
  - Actualizado control para ocultar serie de ruido con Highcharts.
  - Ahora si los timestamps de la serie de ruido no coinciden con la de consumo se recalculará para adpatarse a ella.
* Módulo mapa -> Mapa de calor:
  - Añadido control para valor mínimo y así evitar el 0 que no ofrece resultados.

### Versión 5.26 (10/06/2024)

* Entidad -> Edición/Nuevo:
  - Corregido un error por el que no se podían actualizar entidades por la variable timezone.
* Dispositivo -> Listados -> Desconocidos:
  - Añadido total de concentradores asociados en columna de concentradores.
  - Actualizados colores en mapa por cobertura.
  - Corregido un error por el que se cerraban los tooltips al pasar el ratón.
  - Añadida activación automática en localización del concentrador con mejor señal al crear nuevo dispositivo.
  - Añadida referencia a dispositivo de tabla actual en mapa.
* Dispositivo -> Detalle:
  - Corregido un error por el que siempre se visualizaba el botón para mostrar/ocultar ruido.
* Módulo mapa:
  - Añadido control para actualizar posicionamiento del mapa de manera dinámica.
* Sensórica -> Mapa interactivo de fugas:
  - Añadida opción de mostrar mapas de calor de consumo y ruido alternando mapas.
  - Añadido control compartido de posicionamiento y zoom entre mapas de calor.
  - Añadido control de máximo valor en mapas de calor.
* Ficheros de salida -> Descarga de fichero:
  - Añadido tipo de fichero ATM a Aqualia/Lillo.

### Versión 5.25 (05/06/2024)

* Fabricantes:
  - Añadido modelo EF4EVO+ NBIOT de Sagemcom.
  - Añadidas alarmas para EF4EVO+ NBIOT.
* Dispositivo -> Listados -> Errores de activación:
  - Añadida activación de selección por tareas.
* Entidad/Agrupación -> Edición/Nuevo:
  - Corrección de textos y permisos de opciones referentes a Aqualia.
* Módulo tabla:
  - Corregido un error por el que no se actualizaba el desplegable de "Ir a página" al actualizar los datos de la tabla.
* Sensórica -> Mapa interactivo de fugas:
  - Añadido filtro por nivel de ruido.
  - Añadida opción de mostrar gráfica de contadores individualmente.
  - Añadido mapa de calor superpuesto.
  - Recolocados elementos para para optimizar el uso de los controles.
* Módulo mapa:
  - Añadido tipo de contador NBIoT.
* Principal:
  - Añadida tarjeta de contadores NBIoT.
* Dispositivo -> Detalle:
  - Ahora puede alternarse la visualización de datos de ruido en la gráfica de consumo.

### Versión 5.24 (31/05/2024)

* Fabricantes:
  - Añadido fabricante VIEWSHINE y modelo UWR2.
  - Añadidas alarmas para VIEWSHINE.
* Módulo mapa:
  - Corregidos enlaces a dispositivos de tipo sensor acústico.
* Módulo tabla:
  - Corregidos enlaces a dispositivos de tipo sensor acústico.
* Gateway -> Detalle y listados:
  - Añadido parseo de bit de LTE en máscara del HELLO.
* Principal:
  - Corregido reescalado del panel cuando están todas las tarjetas posibles activas.
* Sensórica -> Mapa interactivo de fugas:
  - Actualizada visualización de gráfica en formato similar a gráfica de contadores.
* Concentrador -> Detalle:
  - Corregido un error por el que no se recibía feedback al asignar/desasignar un contador como fijo de un LW MBUS.
  - Corregido texto de menú de tabla de LW MBUS para fijar nuevos contadores.
* Dispositivo -> Detalle:
  - Ahora la gráfica de ruido aparecerá junto a la de consumo para contadores con sensor integrado.

### Versión 5.23 (27/05/2024)

* Dispositivo -> Log de tramas:
  - Actualizados parseadores de Diehl, Honeywell e Itron G3 con correcciones.
* Angular:
  - Actualizados plugins a última versión disponible compatible.
* Ficheros de salida -> Descarga de fichero:
  - Añadido tipo de archivo UGENA para Aqualia/Ugena.
* Análisis de datos -> Balances:
  - Corregidas acciones de botones de contadores en el mapa de edición de balances.
* Entidad -> Editar:
  - Ahora es posible editar la zona horaria de la entidad.
* Sensórica -> Mapa interactivo de fugas:
  - Ahora pueden seleccionarse múltiples sensores para ver la gráfica conjunta.
* Módulo mapa:
  - Corregido un error por el que dejaban de funcionar algunas acciones de tooltip tras hacer una selección de elementos.

### Versión 5.22 (20/05/2024)

* Concentrador -> Cola de comandos:
  - Añadida pregunta de confirmación en la opción de reintento.
* Dispositivo -> Detalle:
  -  Corregidas acciones de tabla de concentradores LW MBUS asociados.
* Módulo mapa:
  - Añadido botón para borrar localizaciones en tooltip.
  * Alarmas -> Ver alarmas:
  - Añadida opción de crear orden de revisión.
* Análisis de datos -> Modelos analíticos:
  - Creado nuevo componente para listar las gráficas de los perfiles existentes.
  - Reubicado componente anterior en la opción de editar.
  - Añadida opción de crear nuevo modelo al editar.
* Fabricantes:
  - Añadidos Diehl Metering: NBIoT, Sagemcom: Siconia Water WM NBIoT, Kamstrup: Multical LoRaWAN y Multical NBIoT.
* Dispositivo -> Precarga de datos:
  - Actualizdos textos de tipos de dispositivos.
* Dispositivo -> Nuevo:
  - Actualizdos textos de tipos de dispositivos.
* Dispositivo -> Orden de trabajo:
  - Añadida fecha y usuario al editar una orden de trabajo.
* Mantenimiento -> Global -> Incidencias:
  - Ahora puede exportarse la tabla.

### Versión 5.21 (16/05/2024)

* Análisis de datos -> Modelos analíticos:
  - Corregido posicionamiento de botón de hora en gráfica de consumo.
  - Añadidos valores a eje Y de patrón.
* Mantenimiento -> Incidencias:
  - Corregido un error por el que no se visualizaban los clústeres en el mapa.
* Dispositivo -> Detalle:
  - Corregido un error por el que al crear o actualizar una orden de revisión se cargaba el comentario incorrecto en el modal.
  - Corregida visualización de datos de concentrador LW MBUS en mapa y añadido enlace a dispositivo.
* Dispositivo -> Fabricantes MBUS:
  - Actualizado parseo de código de fabricante.
* Dispositivo -> Log de tramas:
  - Actualizado parseo de payload para VIEWSHINE para las tramas de alarmas y batería.
* Dispositivo -> Candidatos MBUS:
  - Añadido componente para candidatos MBUS similar a fabricantes MBUS.
* Concentrador -> Detalle:
  - Añadida tabla de dispositivos con clave privada.
  - Añadida tabla de dispositivos fijados.
  - Añadida tabla de cola de comandos.
  - Añadida opción de "Autoconfigurar" al menú del componente.
* Mantenimiento -> Global -> Incidencias:
  - Eliminada actualización automática.
  - Corregido un error por el que no se actualizaban la entidad/agrupación al seleccionar ver contador/gateway.
* Módulo de tarjetas:
  - Ahora las tarjetas que muestren un listado tendrán altura máxima, visualizando un scroll en caso de desbordarse.
  - Ahora al hacer click con el botón derecho en un enlace en los datos de tarjeta se abrirá una nueva pestaña.
* Gateway -> Disponible:
  - Añadido botón para desactivar gateway eliminando la entidad asociada.

### Versión 5.20 (10/05/2024)

* Dispositivo -> Listado -> Listado interno GPRS:
  - Ahora las acciones no aparecerán deshabilitadas si el contador es PLUM.
* Dipositivo -> Detalle:
  - Corregido un error por el que no se podía guardar ni actualizar la orden de revisión.
* Dispositivo -> Log de tramas:
  - Añadida opción de parseo de payload para VIEWSHINE.
* Modelos analíticos:
  - Actualizada visualización de patrones para adaptar el patrón normalizado a patrón de consumo a partir de los valores medios del contador analizado.
  - Corregido guardado y visualización de patrón anual.
  - Añadidos botones de selección de rango de eje X en predicción.
* Gateway -> Disponible:
  - Habilitado botón de activación.
* Dispositivo -> Detalle -> Editar metrológica:
  - Corregido un error por el que no se habilitaba el botón de guardado cuando se creaba una nueva metrológica.
* Análisis de datos -> Gráfica de contadores:
  - Añadida opción en gráfica para predicción del próximo mes a partir de los 2 meses previos.

### Versión 5.19 (07/05/2024)

* Dispositivo -> Detalle:
  - Actualizado texto de opción de menú "A revisar" por "Orden de revisión".
  - Añadidas alarmas personalizadas al listado de alarmas configurables de contador y adaptada configuración.
  - Corregido un error por el que no se recargaba al actualizar los datos del CUPS.
* Dispositivo -> Listados -> Orden de revisión:
  - Actualizado listado "A revisar" como "Orden de revisión".
  - Añadida columna de CUPS y estado a la tabla.
  - Actualizada edición de revisión con comentarios y estado en modal.
  - Añadido sombreado de líneas dependiente del estado.
* Alarmas -> Configuración -> Alarmas personalizadas:
  - Añadido componente para configurar las alarmas personalizadas por asociación de contadores.
  - Añadidos links desde listado de asociaciones.
* Dispositivo -> Metrológica:
  - Corregido un error por el que no se visualizaba la edición metrológica si no existían datos en servidor.
* Abonado:
  - Añadida configuración de alarmas personalizadas de contador.
  - Reubicado icono de notificaciones.
  - Corregido cambio de idioma en configuración de usuario.
  - Ahora la sesión no finalizará al recargar la aplicación.
  - Corregida navegación tras recarga de aplicación.
* Alarmas -> Listado:
  - Abierto borrado de alarmas BMeter a todos los perfiles.
* Sensórica -> Mapa interactivo de fugas:
  - Ahora no se redirigirá a principal tras cambiar de agrupación.
* Navegación:
  - Actualizado icono de gateway en menús.
* Principal:
  - Ahora al acceder al listado de sin comunicación a través de la tarjeta, se filtrarán los dispositivos LoRaWAN.
* Dispositivo -> Listados -> Sin comunicación:
  - Añadido filtro rápido para tipo LoRaWAN.
* Entidad -> Formulario:
  - Independizado campo API Id de entidades Aqualia.
* Análisis de datos -> Modelos analíticos:
  - Añadido componente para crear y asignar perfiles de consumo a contadores según su patrón.

### Versión 5.18 (23/04/2024)

* Dispositivo -> Detalle:
  - Añadido botón para limpiar alarmas en tabla de alarmas del dispositivo para contadores BMeter.
* Dispositivo -> Listados -> Dispositivos desconocidos:
  - Corregido un error en el cálculo de números de serie a partir de la dirección MBUS por la supresión de un 0 a la izquierda durante las conversiones de hexadecimal a decimal.
  - Añadida opción de exportar tabla.
* Alarmas -> Ver alarmas:
  - Añadida opción de limpiar alarmas en el menú de tabla si existen contadores BMeter en el listado.
* Dispositivo -> Nuevo:
  - Añadido timezone como parámetro requerido para Conthidra eRegister.
* Gateway -> Log de tramas:
  - Corregido un error por el que no se visualizaba el modal para relanzar tramas.
* Sensórica -> Mapa interactivo de fugas:
  - Corregido un error por el que no se habilitaba inicialmente el botón de mostrar contadores en modal.
* Estado de red -> KPIs:
  - Corregido un error por el que no se recargaba la gráfica al cambiar de fecha con el total de entidad seleccionado.
  - Ahora cuando se muestren los valores por punto en la gráfica también aparecerá la cantidad de dispositivos recibidos/totales.
* Módulo tabla:
  - Ahora al concatenar 2 filtros o más mediante la caja de búsqueda aparecerá un botón para hacer los filtros excluyentes o no.

### Versión 5.17 (19/04/2024)

* Dispositivo -> Log de tramas:
  - Añadida opción de parseo de payload para SAGEMCOM.
  - Añadida opción de parseo de payload para INTEGRA.
  - Corregido uso de zona horaria en el parseo de Conthidra eRegister.
* Gateway -> Detalle:
  - Reubicada opción del menú de componente "Asignar principales al mejor redundante" en submenú "Actualizar".
  - Actualizado comportamiento de la asignación de principales al mejor redundante para que asigne los que sean posibles y muestre los que no.
  - Corrección de los estilos de los menús para que los textos no aparezcan cortados.
* Estado de la red -> KPIs:
  - Corregido un error por el que se recargaban las gráficas de las tarjetas referentes al total de entidad al actualizar la fecha de búsqueda.
* Notificaciones:
  - Ahora cuando se chequeen las notificaciones pendientes se mostrará un aviso si existen.
* Mantenimiento -> Global -> Incidencias:
  - Actualizado nombre de menú de "Sospechas" a "Incidencias".
  - Añadido control para visualizar la tabla de incidencias por tipo.
  - Añadido tipo de incidencias para gateways y contadores.
* Navegación:
  - Actualizados iconos de menú "Gateways" de barra lateral.
* Módulo tabla:
  - Ahora al agrupar los datos por columna también se indicará el número total de filas coincidentes en la línea de título de cada agrupación.

### Versión 5.16 (11/04/2024)

* Módulo tabla:
  - Actualizados estilos de gráfica en modal para aprovechar mejor el espacio en pantalla.
* CUPS -> Detalle:
  - Corrección de icono de tabla de alarmas.
  - Corrección de tamaño de iconos.
* Dispositivo -> Detalle:
  - Corregidos estilos del modal de información para que la fotografía no pise la tabla independientemente del ancho de cada una.
* Gateway -> Detalle:
  - Corregidos estilos del modal de información para que la fotografía no pise la tabla independientemente del ancho de cada una.
* Ficheros de salida -> Descarga de ficheros:
  - Añadido tipo de archivo Geiser para Nortegas.
  - Actualizada gestión de tipos de archivo para Geiser.
  - Actualizados estilos de selectores.

### Versión 5.15 (09/04/2024)

* Fabricantes:
  - Añadido modelo IUW de ZENNER.
* Módulo tabla:
  - Corregido un error por el que se añadía un filtro vacío al recargar la tabla desde el selector de fecha cuando existían filtros previos activos.
* Mantenimiento -> Idioma:
  - Añadido parseo de alarmas y descripciones faltantes para BMeter, Conthidra y Kamstrup.
* Alarmas -> Ver alarmas:
  - Corregido un error por el que no se visualizaban los clústeres de los dispositivos con alarmas seleccionadas en el mapa.
  - Añadidas alarmas para ZENNER IUW.
* Dispositivo -> Precarga de datos:
  - Añadido conversor de archivos KEM2.
* Dispositivo -> Detalle:
  - Añadida tarjeta de ruido para sensores acústicos con enlace a mapa interactivo de fugas.
* Sensórica -> Mapa interactivo de fugas:
  - Añadidos enlaces a dispositivo en tooltips.
* Entidad -> Formulario:
  - Corregido un error por el que se mostraban agrupaciones virtuales seleccionables como agrupación por defecto.
  - Añadida zona horaria por defecto en la creación de nueva entidad.
* Módulo material:
  - Actualizados estilos de selector para que la caja de búsqueda no desaparezca al hacer scroll en el listado.

### Versión 5.14 (04/04/2024)

* Módulo tabla:
  - Actualizados estilos de caja de búsqueda en diseño responsive.
* Módulo gráfica:
  - Actualizados estilos de selección de fecha en diseño responsive.
* Alarmas:
  - Añadidas alarmas correspondientes a Conthidra eRegister LoRa+WMBUS.
* Fabricantes:
  - Corregido tipo de contador para flowIQ®2200 de Kamstrup.
  - Añadido modelo Conthidra eRegister LoRa+WMBUS.
* Dispositivo -> Listados -> Batería baja:
  - Añadidas columnas de fabricante y modelo.
* Sensórica -> Mapa interactivo de fugas:
  - Actualizado componente con gestión de datos reales para sensores acústicos y contadores con sensor acústico integrado.
  - Añadido radio externo a los sensores para aumentar el rango de selección de contadores para la gráfica.
  - Abierto a todos lo perfiles.
* Dispositivo -> Detalle:
  - Añadido rango de sensor en información/edición de contador.
* Dispositivo -> Log de tramas:
  - Actualizado parseador de Kamstrup.
  - Actualizado parseador LoRaWAN.
  - Actualizado descriptor de tramas de LW MBUS.

### Versión 5.13 (25/03/2024)

* Concentrador -> Detalle:
  - Añadidas tarjetas de consumo MBUS, consumo LW, consumo transmisión y consumo base para concentrador LW MBUS.
* Navegación -> Login:
  - Ahora se suprimirán automáticamente los espacios al inicio y al final del usuario y la contraseña.
* Dispositivo -> Detalle:
  - Añadida gráfica de datos de sensor para contadores con sensor de ruido.
* Dispositivo -> Log de tramas:
  - Añadida opción de parseo de payload para LoRaWAN MBUS.
  - Corregido un error por el que no se visualizaban objetos de la respuesta de parseo con una anidación mayor de dos niveles.
  - Añadida columna de tipo de trama para LoRaWAN MBUS.
  - Corrección de parseador de BMeter.

### Versión 5.12 (20/03/2024)

* Alarmas:
  - Añadidas alarmas para Sewerin, BMeter y Kamstrup.
* Navegación:
  - Corregido un error por el que no funcionaba el cambio de formato de fecha.
* Estado de red -> KPIs:
  - Corregido un error que evitaba el filtrado de la gráfica sólo por fabricante y sin especificar modelo.
* Dispositivo -> Listado en red:
  - Añadida columna de porcentaje de lecturas para agrupaciones con dispositivos MBUS.
* Dispositivo -> Listado interno:
  - Añadida columna de porcentaje de lecturas para agrupaciones con dispositivos MBUS.
* Módulo tabla:
  - Corregido un error por el que los tooltips de las gráficas se desplazaban a veces fuera de pantalla en el modal de tabla.
* Módulo mapa:
  - Corregido un error al resaltar los contadores de un concentrador LW MBUS si había contadores externos en el mapa.
  - Corregido tooltip para sensores acústicos.
* Módulo Cesium:
  - Corregido un error por el que el mapa 3D no cargaba si había concentradores LW MBUS en la agrupación.
  - Añadida capa de contadores MBUS con comunicación.
* Gateway -> Detalle:
  - Recolocada imagen en modal de información de dispositivo.
* Dispositivo -> Detalle:
  - Recolocada imagen en modal de información de dispositivo.
  - Ajustados límites de eje Y en gráfica de sensor acústico.
  - Corrección de IDs de series en gráfica de sensor acústico.
  - Corrección de url para sensores acústicos.
  - Corrección de título de modal de información para sensores.
  - Corregido un error por el que el formato de fecha no se aplicaba en la tabla de alarmas.
* Concentrador -> Detalle:
  - Corrección de iconos en tabla de contadores de LW MBUS.
  - Corrección de título de modal de información.
* Fabricantes:
  - Corregido tipo para contador Hydrodigit Combo de BMeter.
  - Añadido modelo flowIQ®2200 para Kamstrup.

### Versión 5.11 (13/03/2024)

* Concentrador -> Detalle:
  - Ahora se resaltarán los contadores fijados en el concentrador para LW MBUS en la tabla de contadores y en el mapa.
  - Añadidos controles para fijar o desfijar un contador de un concentrador LW MBUS.
  - Añadida tarjeta con total de contadores fijados.
* Dispositivo -> Detalle:
  - Ahora se resaltarán los concentradores fijados en el contador para LW MBUS en la tabla de concentradores.
  - Añadidos controles para fijar o desfijar un concentrador.
  - Añadida tarjeta con concentradores fijados.
  - Añadida gráfica de datos de sensor para sensores acústicos.
* Dispositivo -> Importar:
  - Añadido componente para importar coordenadas.
  - Añadida opción de importar coordenadas a menú lateral.
* Agrupaciones -> Listado:
  - Corregida visualización de agrupaciones solo ARSON.
* Fabricantes:
  - Actualización de modelos de BMeter.
* Dispositivo -> Logs:
  - Añadida opción de parseo de payload para Dhiel.
  - Añadida opción de parseo de payload para Itron.
  - Añadida opción de parseo de payload para Honeywell.
  - Añadida opción de parseo de payload para BMeter.

### Versión 5.10 (07/03/2024)

* Ficheros de salida -> Descarga de fichero:
  - Ahora se visualizará la descarga de fichero ATM también para la entidad "El Tanque".
* Sensórica -> Evolución de caudal:
  - Añadido nuevo tipo de mapa y marcadores para los sensores de caudal.
  - Añadidos controles para visualizar la evolución del caudal a la par entre la gráfica y el mapa.
* Entidad -> Formulario:
  - Añadida opción de agrupación por defecto para entidades editadas.
* Dispositivo -> Log de comunicaciones:
  - Añadida opción de parseo de payload para Conthidra.
  - Añadida opción de parseo de payload para Conthidra eRegister.
  - Actualizada visualización de parseo para contemplar objetos anidados.
* Navegación:
  - Ahora las agrupaciones que tienen activa la ocultación en virtual solo se mostrarán para perfil ARSON.
* Estilos:
  - Correcciones en modificaciones dinámicas de estilos para evitar errores de sobreescritura.
* Dispositivo -> Log de tramas:
  - Añadida opción de parseo igual que en el log de comunicaciones.

### Versión 5.09 (04/03/2024)
* Módulo mapa:
  - Actualización de textos de leyenda.
* Módulo tabla:
  - Añadida opción de cambiar el color de letra de celda de dato.
* Concentrador -> Detalle:
  - Actualizado parseo de número de serie para conservar el que mande el servicio si ya se encuentra en BBDD.
* Dispositivo -> Listado de desconocidos:
  - Actualizado parseo de número de serie para conservar el que mande el servicio si ya se encuentra en BBDD.
* Navegación:
  - Corregido un error por el que algunos submenús quedaban abiertos en la barra lateral si se situaba el ratón sobre otra opción diferente debido al retardo de cierre.
* Listado de selección:
  - Añadida opción de detectar valores anómalos a la selección de contadores.
* Dispositivo -> Log de comunicaciones:
  - Añadida opción de parseo de payload para Sewerin.
  - Añadida opción de parseo de payload para Kamstrup.
* Sensórica -> Evolución de caudal:
  - Añadido componente con mapa y gráfica para mostrar evolución de caudal (En proceso).
* Dispositivo -> Listado de selección:
  - Añadida opción de detectar valores atípicos (En pruebas).
* Dispositivo:
  - Añadido tipo metrológico 6 para sensores acústicos y adaptados componentes dependientes.

### Versión 5.08 (22/02/2024)

* Módulo gráfica:
  - Añadido guardado en memoria de última media/sarima calculada para superponer en gráfica.
* Análisis de datos -> Asociaciones de contadores:
  - Añadida visualización de energía acumulada para gas en gráfica.
* Módulo mapa:
  - Ahora al pulsar sobre un concentrador LW MBUS en el mapa de /principal se resaltarán los contadores asociados al concentrador.
  - Ahora al cargar un shapefile se preguntará si las coordenadas están proyectadas o no para parsear correctamente el archivo.
* Usuarios:
  - Actualizado perfil AQUALIA_API como API.
* Concentrador -> Detalle:
  - Añadido parseo de número de serie para dispositivos desconocidos de los fabricantes SAP y RAN.
* Dispositivo -> Listado de desconocidos:
  - Añadido parseo de número de serie para dispositivos desconocidos de los fabricantes SAP y RAN.

### Versión 5.07 (19/02/2024)

* Módulo mapa:
  - Añadida opción de cambiar tamaño de iconos al menú.
  - Añadida variable global de tamaño de iconos y adaptados estilos de iconos para cambiar en proporción a la variable.
* Análisis de datos -> Gráfica de contadores:
  - Añadidos controles para cálculo de sarima.
  - Añadidos controles para cálculo de media diaria.
* Dispositivo -> Detalle:
  - Añadidos controles para cálculo de sarima.
  - Añadidos controles para cálculo de media diaria.
  - Corregido un error por el que no se mostraba correctamente el texto de error al desasignar varios gateways a la vez.
  - Corregido un error por el que se bloqueaba la pantalla si el servicio de actualizar SF fallaba.
* Concentrador -> Detalle:
  - Corregido un error por el que no se podían asignar gateways desde el mapa.
* Módulo gráfica:
  - Añadido cálculo de sarima mediante https://www.npmjs.com/package/arima.
  - Añadido cálculo de media diaria.
* Dispositivo -> Nuevo:
  - Corregido un error por el que no aparecían ciertos fabricantes en el listado de MBUS.
* Gateway -> Log de tramas:
  - Corregido un error por el que no se ordenaba correctamente la columna de concentrador.
* Módulo tabla:
  - Ahora se podrá filtrar con el texto en blanco para obtener los datos nulos de la columna.
* Análisis de datos -> Asociaciones de contadores:
  - Corregido un error por el que no se actualizaba la gráfica al seleccionar una asociación diferente.
  - Aumentado timeout del servicio de gráficas individuales.

### Versión 5.06 (13/02/2024)

* Módulo mapa:
  - Se han añadido iconos para destacar los gateways dañados o en reparación en el mapa principal.
* Análisis de datos -> Gráfica de contadores:
  - Añadida opción de comparar valores acumulados en gráfica de suma total para gas.
  - Añadido valor total acumulado para gráficas sumatorias de energía en gas.
* Principal:
  - Reajustado tamaño de gráficas.
* Módulo material:
  - Corregido un error por el que los selectores de fechas no actualizaban los datos por defecto al renovar sesión.
* Mantenimiento -> Reporte de comunicaciones:
  - Corregido posicionamiento de la opción para superponer la gráfica global.
* Sensórica -> Mapa interactivo de presión:
  - Ahora los tooltips de los sensores se abrirán automáticamente al cargar el mapa.
* Navegación:
  - Actualizado comportamiento de desplegables de selección de cliente/entidad/agrupación para que tengan un retardo de medio segundo al cerrarse.
  - Actualizado comportamiento del menú lateral para que tengan un retardo de medio segundo al cerrarse.
* Dispositivos -> Sin comunicación:
  - Ocultada columna de estado para perfiles no ARSON.
* Dispositivos -> En red:
  - Añadidas columnas de FCV y PCS para gas.
* Eventos de usuario:
  - Añadido evento de cambio de entidad.
* Gateway -> Detalle:
  - Reubicada opción de menú de "Tareas de gateway" a submenú de logs como "Log de tareas".
  - Aumentado timeout de petición de gráfica de ruido a 2 minutos.
  - Añadido guardado de imagen y actualización de APP EUI en cambio de estado.
* Gateway -> Log de tareas:
  - Añadida visualización de imagen de tarea.
* Mantenimiento:
  - Reubicada opción de "Tareas de gateway" a menú Global como "Log de tareas".

### Versión 5.05 (29/01/2024)

* Gateway -> Detalle:
  - Corregido un error por el que no se visualizaban en algunos casos todos los filtros rápidos pertinentes.
  - Corregido un error en la sustitución de gateway que utilizaba un servicio deprecado.
* Dispositivo -> Gráfica de consumo:
  - Eliminado tooltip duplicado en línea de duración de alarma.
* Mantenimiento -> Global:
  - Corrección de color según contadores de gateway en tooltips del mapa.
* Estilos:
  - Retoque de estilo de enlaces en tarjetas y tablas.
* Sensórica:
  - Añadido mapa interactivo de presión similar a mapa de fugas.

### Versión 5.04 (23/01/2024)

* Análisis de datos -> Mapa de calor dinámico:
  - Corregido un error de variable indefinida por el que se paraba la ejecución del mapa.
* Cobertura -> Test de cobertura:
  - Corregido un error por el que no reseteaba correctamente la capa de localizaciones al hacer click en un contador.
* Análisis de datos -> Consultas en BBDD:
  - Añadido enlace a contadores en tabla.
* Módulo material:
  - Añadido a los selectores de fechas un menú con botón derecho del ratón para copiar y pegar fechas a través del portapapeles.
* Navegación:
  - Corregido un error por el que algunos submenús laterales aparecían desplazados en perfiles no ARSON.
  - Control de cancelación de servicio en análisis de datos para las consultas de gráficas de balances y gráficas de contadores.
* Módulo gráfica:
  - Corregido un error por el que se ejecutaba la visualización de puntos con el botón derecho en gráficas pictóricas.
* Módulo mapa:
  - Corregido un error por el que se reseteaba la agrupación en curso en ciertas situaciones al editar su contorno.
  - Corregido un error por el que desaparecían los contornos de agrupación al pular ESC en el mapa de edición de agrupaciones.
  - Añadida capa para contadores MBUS con comunicación.
* Dispositivo -> LW MBUS:
  - Abierto para perfiles administradores.
* Análisis de datos -> Balances:
  - Añadida opción de buscar contadores en toda la entidad al buscar contadores faltantes.
  - Adaptada búsqueda para hacer peticiones a distintas agrupaciones.
* Navegación:
  - Añadido control para redirigir todos los servicios desde la url ia.iotwater.es a srvia.iotwater.es.

### Versión 5.03 (18/01/2024)

* Agrupación:
  - Añadida opción de deshabilitar visualización de agrupación en mapas de agrupaciones virtuales.
  - Añadido filtrado de agrupaciones deshabilitadas a mapas de principal y gráfica de contadores.
* Módulo mapa:
  - Corregido un error por el que fallaba la carga de KML por polígonos.
* LW MBUS -> Detalle:
  - Corregido un error por el que no se visulizaba la gráfica de batería si la tensión superaba los 8V.
* Análisis de datos -> Gráfica de contadores:
  - Añadida opción de seleccionar un gateway para superponer la gráfica de temperatura.
* Navegación:
  - Añadido control para poder cancelar servicios que tarden demasiado en análisis de datos para las consultas de gráficas de balances y gráficas de contadores (desactivado por el momento).
* Gateway:
  - Corregido parseo de opciones de memoria NOR a MRAM QSPI y añadido bit 4 de MRAM SPI.
* Dispositivo -> Listado de selección:
  - Añadida opción de cambiar localización de contadores seleccionados.

### Versión 5.02 (11/01/2024)

* Concentrador -> Detalle:
  - Añadidos contadores no recibidos al mapa del panel de contadores del concentrador LW MBUS.
  - Añadida opción de selección con resalte en mapa a la tabla de contadores del panel de contadores del concentrador LW MBUS.
* Módulo mapa:
  - Ahora los concentradores aparecerán en sus propias capas por separado.
  - Añadido nuevo tipo de mapa para detección de fugas.
  - Añadidas nuevas variables de marcadores y tooltip para mapa de detección de fugas.
  - Añadido doble marcador para representar área de influencia de los marcadores de sensores de fuga.
  - Añadida opción de importar archivos shapefile.
  - Añadida librerías proj4leaflet, shpjs y leaflet.utm para gestionar archivos shapefile.
  - Añadidos controles para crear capas a partir de datos UTM.
* Menú de usuario -> Log de usuario:
  - Ahora el log se precargará con la tabla vacía por si el servicio falla.
* Módulo gráfica:
  - Ahora las celdas con gráfica ocultarán la gráfica al hacer click con el botón derecho del ratón.
* Gateway -> Detalle:
  - Corregido un error por el que no se mostraba correctamente el texto de error al desasignar contadores individualmente.
  - Corregido un error por el que no se mostraba correctamente el texto de error al refrescar un dispositivo individualmente.
  - Añadidas opciones de asignar/desasignar desde mapa.
  - Añadido log de tramas.
  - Correcciones en la selección de contadores por mapa.
  * Gateway -> Datalogger:
  - Corregido un error por el que no se visualizaba el botón de borrar datalogger.
* Dispositivo -> Detalle:
  - Corregido un error por el que no se mostraba correctamente el texto de error al desasignar gateways individualmente.
  - Corregido un error por el que no se mostraba correctamente el texto de error al refrescar un gateway.
  - Ahora al desasignar varios gateways a la vez se realizará el proceso mediante tareas.
  - Eliminados servicios duplicados en la asignación/desasignación de gateways.
* Telecontrol:
  - Corregido un error por el que no se visualizaban las fechas en el modal de alerta.
* Dispositivo -> En red:
  - Añadida opción al menú de tabla para cambiar localización de dispositivos seleccionados.
* Sensórica:
  - Creado apartado en menú lateral para mapa interactivo de fugas.
  - Creado componente mapa interactivo de fugas.
  - Creado apartado en menú lateral para mapa interactivo de presión.

### Versión 5.01 (21/12/2023)

* Dispositivo -> Sustituciones:
  - Corregido un error por el que no funcionaban los selectores de la tabla debido a una interacción con el mapa.
* Análisis de datos -> Balances:
  - Corregido un error por el que no se actualizaba correctamente la selección al guardar cambios en el balance.
* Dispositivo -> Detalle -> Actualizar SF/SF min:
  - Corregido un error por el que fallaba la actualización al enviar los servicios simultáneamente.
* Alarmas:
  - Actualizados textos de alarmas para evitar errores con el filtrado de sospechas.
* Navegación:
  - Ahora si al cargar los datos guardados de sesión no se encuentran datos de cliente/entidad/agrupación se seleccionará automáticamente la primera.
  - Ahora no se guardarán los datos de sesión si no existe un usuario en curso para evitar problemas en carga.
* Dispositivo -> Importar imágenes:
  - Añadida opción de importar archivos de imagen directamente sin fichero.
* Dispositivo -> Detalle:
  - Corregido un error por el que no se ordenaban correctamente los gateways en el panel de estado para concentradores LW MBUS.
  - Corregido un error por el que las gráficas de los concentradores se mostraban por hora aunque los datos fuesen diarios.
* Dispositivo -> Precarga de datos:
  - Corregido un error por el que no se actualizaba el feedback de la precarga si se repetía el proceso.
* Dispositivo -> Listado de desconocidos:
  - Corregido un error por el que no se cargaba el listado si alguno de los servicios devolvía null.
* Módulo mapa:
  - Corregido un error por el que fallaba la selección de dispositivos si había capas ocultas.
* Concentrador -> Detalle:
  - Añadida tarjeta de último hello.
  - Corregido un error por el que no se mostraba la fecha en la tarjeta de última comunicación.
* Mantenimiento -> Entidad -> Editar agrupaciones:
  - Corregido un error por el que al cambiar de entidad desde la edición se producía un bucle de llamadas a servidor que colapsaba la aplicación.

### Versión 5.00 (29/11/2023)

* Navegación
  - Ahora los selectores de cliente/entidad/agrupación tendrán un segundo de retardo en el cierre mientras se escriba en la caja de búsqueda.
  - Añadida opción de guardar selección para comparar en menú de la tabla de contadores seleccionados.
  - Corregido un error por el que no funcionaba el link a principal desde el logo.
  - Corregido un error por el que no se bloqueaban los enlaces pertinentes en el menú lateral.
* Cobertura -> Test de cobertura:
  - Corregido un error por el que no aparecía el filtro de fecha para perfiles no ARSON.
* Análisis de datos -> Balances:
  - Corregido un error por el que no se podían añadir padres e hijos desde el mapa.
* Análisis de datos -> Gráfica de contadores:
  - Corregido un error por el que no se actualizaba el color de la selección al cargar el mapa.
* Porcentaje de tramas recibidas:
  - Eliminadas todas las referencias.
* Dispositivo -> Listado de desconocidos:
  - Añadido listado de dispositivos inactivos/desconocidos de LW MBUS.
  - Añadido mapa para mostrar el área de recepción de un dispositivo desconocido.
  - Añadido enlace a nuevo dispositivo con precarga de MBUS.
* Gateway -> Detalle:
  - Añadido cambio de estado a menú de panel.
  - Añadido cambio de entidad a menú de panel.
  - Actualizados servicios y estados de gateway.
  - Añadido estado en la cabecera del panel.
  - Ahora los botones de asignación/desasignación de la tabla de contadores se bloquearán si los gateways no están activos.
  - Corregido un error por el que no se cargaba la gráfica de tensión si se pedían los datos mientras se mostraba el ruido.
* Gateways -> Listados:
  - Añadido listado de gateways averiados.
  - Añadido listado de gateways en reparación.
  - Añadida opción de cambiar de estado y cambiar de entidad.
  - Actualizados textos: Pendiente a Disponible, Inactivo a En stock.
  - Añadida columna de estado en el listado de instalados.
* Concentrador -> Detalle:
  - Añadidas tablas de dispositivos inactivos y desconocidos para LW MBUS.
* Dispositivo -> Fabricantes Mbus:
  - Creado nuevo componente de listado de fabricantes Mbus.
  - Añadida edición y borrado de fabricantes.
  - Añadido parseo de identificador de fabricante.
  - Añadida importación por fichero.
  - Añadidos servicios.
* Módulo mapa:
  - Ahora en el mapa de cambio de localización de dispositivo se podrá recolocar el elemento haciendo click en el mapa.
  - Añadido plugin de buscador por dirección.
  - Añadidos tipos de Google Maps para búsquedas por dirección.
  - Habilitada búsqueda por dirección en mapa de cambio de localización de dispositivo.
  - Corregido un error por el que no se mostraba la foto de gateway a perfiles no ARSON.
  - Corregido un error por el que no se mostraba la capa de sensores.
  - Actualizados filtros para que actúen como los filtros especiales de las tablas.
* Dispositivo -> EK280:
  - Corregido un error por el que no se mostraba la fecha del test.
  - Abierto test y configuración a todos los perfiles.
* Dispositivo -> Importar -> Sustituir:
  - Reubicado en Datos customizados contadores de CUPS.
  - Eliminado timeout del front en la importación.
* Dispositivo -> Detalle:
  - Añadida tabla de concentradoes asociados para MBUS.
  - Corregido un error por el que no se recargaba la localización original al cancelar un cambio de localización en el mapa.
* Módulo tabla:
  - Corregido un error por el que no se podían borrar filtros de la caja de búsqueda.
* Dispositivo -> Alarmas:
  - Corregido un error por el que no se podían editar alarmas.
`;
