<!-- Editar umbral -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ data.edit ? ("edit" | translate) : ("create" | translate) }}
    </h5>
  </div>
  <div class="dialog-body data-analysis-threshold">
    <!-- Umbral de observación -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "observation" | translate }} (0%-100%)</mat-label>
      <input
        matInput
        [(ngModel)]="data.balanceThresholds.observationThreshold"
        type="number"
        step="any"
        min="0"
        max="100"
        placeholder="{{ 'observation' | translate }}..."
      />
    </mat-form-field>
    <!-- Umbral crítico -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "critical" | translate }} (0%-100%)</mat-label>
      <input
        matInput
        [(ngModel)]="data.balanceThresholds.criticalThreshold"
        type="number"
        step="any"
        min="0"
        max="100"
        placeholder="{{ 'critical' | translate }}..."
      />
    </mat-form-field>
    <!-- Intervalo -->
    <mat-form-field appearance="standard">
      <mat-label
        >{{ "interval" | translate }} -
        {{ "day-selector" | translate }}</mat-label
      >
      <input
        matInput
        [(ngModel)]="data.balanceThresholds.intervalDays"
        type="number"
        step="any"
        min="1"
        placeholder="{{ 'interval' | translate }}..."
        required
      />
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        data.balanceThresholds.intervalDays == null ||
        data.balanceThresholds.observationThreshold > 100 ||
        data.balanceThresholds.observationThreshold < 0 ||
        data.balanceThresholds.criticalThreshold > 100 ||
        data.balanceThresholds.criticalThreshold < 0
      "
      (click)="saveThreshold()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      *ngIf="data.delete"
      mat-button
      class="mat-button-icon-text"
      (click)="resetThreshold()"
    >
      <i class="fas fa-eraser"></i>
      {{ "erase" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
