// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
// Interfaces
import { CustomAlarm } from "../../../../screens/dashboard/alarms/AlarmInterface.type";

@Component({
  selector: "app-subscriber-custom-alarms",
  templateUrl: "./subscriber-custom-alarms.component.html",
  styleUrls: ["./subscriber-custom-alarms.component.scss"],
})
export class SubscriberCustomAlarmsComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Alarmas
  @Input() meterId: number;
  alarmConfig: CustomAlarm[];
  customAlarmsList: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getAlarms();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del listado de alarmas personalizadas
  getAlarms(): void {
    this.AlarmController.getCustomAlarms().subscribe((response) => {
      if (response["code"] == 0) {
        this.customAlarmsList = response["body"]?.filter((alarm: CustomAlarm) =>
          this.meterId ? alarm.meterId != null : alarm.associationId != null
        );
        this.updateAlarms();
      }
    });
  }

  // Actualización de asociacion en curso
  updateAlarms(): void {
    this.alarmConfig = this.customAlarmsList
      ?.filter((alarm) => alarm.meterId == this.meterId)
      .sort((a, b) => a.type - b.type);
    if (!this.alarmConfig || this.alarmConfig?.length == 0) {
      this.alarmConfig = [];
      for (let i = 0; i < 8; i++) {
        this.alarmConfig.push(new CustomAlarm(null, i, null, this.meterId));
      }
    }
  }

  // Reseteo a limpio
  resetAlarms(): void {
    this.alarmConfig = this.alarmConfig.map(
      (alarm) =>
        new CustomAlarm(
          alarm.id,
          alarm.type,
          alarm.associationId,
          alarm.meterId
        )
    );
  }

  // Guardado de alarmas personalizadas
  saveAlarms(): void {
    this.AlarmController.updateCustomAlarms(this.alarmConfig).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
        }
      }
    );
  }
}
