<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "mbus-candidates" | translate }}</b>
        </h5>

        <!-- Botón exportar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newCandidate()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Importación de archivo -->
        <div class="mbus-manufacturer-import">
          <h4>
            <b>{{ "file-import" | translate }} </b>
            <i class="fas fa-info-circle pointer" (click)="showInfo()"></i>
          </h4>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="importCandidates($event)"
          ></app-file-import>
        </div>

        <!-- Resultado -->
        <div *ngIf="showResult" class="manufacturers-import-result">
          <hr />
          <div>
            <h5>
              <b>{{ "readings-rows-error" | translate }}:</b>
              {{ failedManufacturers?.length }}
            </h5>
            <!-- Descargar csv de errores -->
            <div *ngIf="failedManufacturers?.length > 0">
              <button
                mat-button
                class="mat-button-icon-text"
                (click)="downloadErrorFile()"
              >
                <i class="fas fa-download"></i>
                {{ "download-file-error" | translate }}
              </button>
              <!-- Reintentar fallidos -->
              <button mat-button class="mat-button-icon-text" (click)="retry()">
                <i class="fas fa-redo-alt"></i>
                {{ "retry-failed" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- Tabla de fabricantes -->
        <app-table-controller
          [tableId]="'mbusCandidates'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
