// @angular
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { Association } from "../../data-management/DataManagementInterface.type";
// Componentes
import { AlarmsCustomPanelComponent } from "./alarms-custom-panel/alarms-custom-panel.component";

@Component({
  selector: "app-alarms-custom-config",
  templateUrl: "./alarms-custom-config.component.html",
  styleUrls: ["./alarms-custom-config.component.scss"],
})
export class AlarmsCustomConfigComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Alarmas
  associationList: Association[];
  selectedAssociation: number;
  preselection: number;
  meterId: number;
  meterNroSerie: string;
  @ViewChild(AlarmsCustomPanelComponent)
  alarmsCustomPanel: AlarmsCustomPanelComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private route: ActivatedRoute,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga de componente
  loadComponent(): void {
    this.meterId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.meterNroSerie = history.state.data;
    if (!this.meterId) {
      this.selectedAssociation = history.state.data;
      this.preselection = this.selectedAssociation;
      this.getAssociationData();
    }
  }

  // Obtención de los datos de asociaciones
  getAssociationData(): void {
    this.AssociationController.getAssociationList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.associationList = response["body"];
        if (!this.selectedAssociation) {
          this.selectedAssociation = this.associationList[0]?.id;
          this.preselection = this.selectedAssociation;
        }
      }
    });
  }

  // Guardado
  saveCustomAlarms(): void {
    this.alarmsCustomPanel.saveAlarms();
  }
}
