// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment_timezone from "moment-timezone";
// Servicios propios
import { AgrupationControllerService } from "../../../../services/server/AgrupationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
import { AgrupationData } from "../AgrupationInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-agrupationform",
  templateUrl: "./agrupation-form.component.html",
  styleUrls: ["./agrupation-form.component.scss"],
})
export class AgrupationFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentEntity: Entity;
  currentAgrupation: Agrupation;
  sessionProfile: string;
  entitySub: Subscription;
  readonly PROFILES = PROFILES;
  
  // Agrupación
  agrupationData: AgrupationData;
  id: number;
  initialTimezone: string;
  tzNames: string[];
  timezoneOptions: MaterialSelectOption[];
  showAllEntityDisabled: boolean;
  entityTimezones: { timezone: string; count: number }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AgrupationController: AgrupationControllerService,
    public ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.id = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.tzNames = moment_timezone.tz.names();
    this.timezoneOptions = this.tzNames?.map((timezone: string) => {
      return { value: timezone, name: timezone };
    });

    // Deshabilitación de agrupación virtual si ya existe una en la entidad
    this.showAllEntityDisabled = this.currentEntity.agrupations.some(
      (agrupation: Agrupation) => agrupation.showAllEntity
    );

    if (this.id) {
      this.AgrupationController.getAgrupation(this.id).subscribe((response) => {
        if (response["code"] == 0) {
          this.agrupationData = response["body"];
          this.initialTimezone = this.agrupationData.timezone;
          // Zona horaria de entidad
          this.getAgrupationTimezone();
        }
      });
    } else {
      this.agrupationData = new AgrupationData();
      this.agrupationData.entity = this.currentEntity.id;
      this.agrupationData.aqualiaFtpHour = 6;
      // Zona horaria de entidad
      this.getAgrupationTimezone();
    }
  }

  // Actualización de la agrupación
  updateAgrupation(): void {
    delete this.agrupationData.highlightAgrupation;
    this.AgrupationController.updateAgrupation(this.agrupationData).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.SessionDataService.clearUpdateNavFlag();
          this.router.navigate(["/agrupaciones/listado"]);
        }
      }
    );
  }

  // Creación de la agrupación
  newAgrupation(): void {
    delete this.agrupationData.highlightAgrupation;
    this.AgrupationController.createAgrupation(this.agrupationData).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.SessionDataService.clearUpdateNavFlag();
          this.router.navigate(["/agrupaciones/listado"]);
        }
      }
    );
  }

  // Obtención de la zona horaria de la agrupación
  getAgrupationTimezone(): void {
    if (this.currentEntity?.timezone) {
      this.agrupationData.timezone = this.currentEntity.timezone;
    } else {
      this.agrupationData.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    this.initialTimezone = this.agrupationData.timezone;
  }
}
