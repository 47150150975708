// @angular
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { MapControllerComponent } from "../../../../../../../modules/map-module/map-controller/map-controller.component";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
// Interfaces
import { SavedSelection } from "../../../../../data-analysis/DataAnalysisInterface.type";
import { AssignedDevice } from "../../../../DeviceInterface.type";

@Component({
  selector: "app-meter-assigned-dialog",
  templateUrl: "./meter-assigned-dialog.component.html",
  styleUrls: ["./meter-assigned-dialog.component.scss"],
})
export class MeterAssignedDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  selectionName: string;
  mapLocation: { longitude: number; latitude: number };
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  mapType: string = "changeLocation";
  mapHeight: number = 400;
  initialZoom: number = 17;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.mapLocation = {
      longitude: this.data.meters[0].longitude,
      latitude: this.data.meters[0].latitude,
    };
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Cierre de modal
  saveSelection(): void {
    let selections: SavedSelection[] =
      this.SessionDataService.getCurrentSavedSelection();
    if (selections) {
      selections.push({
        name: this.selectionName,
        meters: this.data.meters,
        agrupation: this.SessionDataService.getCurrentAgrupation()?.id,
      });
      this.SessionDataService.sendSavedSelection(selections);
    } else {
      this.SessionDataService.sendSavedSelection([
        {
          name: this.selectionName,
          meters: this.data.meters,
          agrupation: this.SessionDataService.getCurrentAgrupation()?.id,
        },
      ]);
    }
    this.SessionDataService.sendDialogAction({ action: "close" });
  }

  // Actualización de mapa
  updateMap(): void {
    if (
      this.mapLocation.latitude != null &&
      this.mapLocation.longitude != null &&
      Math.abs(this.mapLocation.latitude) <= 90 &&
      Math.abs(this.mapLocation.longitude) <= 180
    ) {
      this.mapController.resetLayers();
      this.mapController.flyTo(
        [this.mapLocation.latitude, this.mapLocation.longitude],
        17,
        { animate: false }
      );
    }
  }

  // Seteo de la nueva posición del contador
  setDeviceNewLocation(newLocation: { lat: number; lng: number }): void {
    this.mapLocation.latitude = newLocation.lat;
    this.mapLocation.longitude = newLocation.lng;
  }

  // Actualización de la posición del contador
  saveNewLocation(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("change-location-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let requests = this.data.meters.map((device: AssignedDevice) => {
          return this.MeterController.newCoords({
            id: device.id,
            latitude: this.mapLocation.latitude,
            longitude: this.mapLocation.longitude,
          });
        });
        forkJoin(requests).subscribe((responses: any[]) => {
          if (responses.every((response) => response["code"] == 0)) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-location-sucessfull")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }
}
