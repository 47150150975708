import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";

@Component({
  selector: "app-satelite-modify-dialog",
  templateUrl: "./satelite-modify-dialog.component.html",
  styleUrls: ["./satelite-modify-dialog.component.scss"],
})
export class SateliteModifyDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  initialPulseWeight: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.initialPulseWeight = this.data.pulseWeight;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Modificación de valores
  modifySateliteValues(): void {
    this.MeterController.modifySateliteValue(
      this.data.id,
      this.data.lastReadedValue,
      this.data.overflow,
      this.data.pulseWeight != null && this.data.pulseWeight != ""
        ? this.data.pulseWeight
        : null
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("satelite-request-sent")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
