// @angular
import { Injectable, inject } from "@angular/core";
import { Router, CanActivateFn } from "@angular/router";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  /***************************************************************************/
  // Constructor
  /***************************************************************************/
  constructor(
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // Funciones
  /***************************************************************************/

  canActivate(): boolean {
    if (!this.SessionDataService.getCurrentUserLogged()) {
      this.router.navigate(["/acceso-denegado"]);
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    if (!this.SessionDataService.getCurrentUserLogged()) {
      this.router.navigate(["/acceso-denegado"]);
      return false;
    }
    return true;
  }
}

export const AuthGuard: CanActivateFn = (): boolean => {
  return inject(AuthGuardService).canActivate();
};
