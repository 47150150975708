// Angular
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
// Interfaces
import { GwSnifferData } from "../../../GatewayInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableQuickFilter,
  TableSelectColumn,
} from "./../../../../../../modules/table-module/TableInterface.type";
// Services
import { GatewayControllerService } from "./../../../../../../services/server/GatewayController.service";
import { DateParserService } from "./../../../../../../services/shared/DateParserService.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "./../../../../../../services/shared/ToastService.service";
import { SessionDataService } from "./../../../../../../services/shared/SessionDataService.service";
import { RouteCheckService } from "./../../../../../../services/shared/RouteCheckService.service";
import { ReloadComponentService } from "./../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "./../../../../../../services/server/MeterController.service";

/***************************************************************************/
// ANCHOR Componente
/***************************************************************************/

@Component({
  selector: "app-gateways-sniffer-list-dialog",
  templateUrl: "./gateways-sniffer-list-dialog.component.html",
  styleUrls: ["./gateways-sniffer-list-dialog.component.scss"],
})
export class GatewaysSnifferListDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  @Input() meter: any;
  @Input() data: any;

  //Subscriptions
  clientSub: Subscription;
  agrupationSub: Subscription;
  entitySub: Subscription;

  //Data
  gatewayId: number = Number(this.route.snapshot.paramMap.get("id"));
  unidadVenta: string;

  //Table
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      class: "btn-red",
      help: "help-table-delete",
      selectionRequired: true,
    },
    {
      title: "assign-as-redundant",
      icon: "fas fa-plus",
      help: "help-table-assign",
      selectionRequired: true,
    },
  ];
  tableData: GwSnifferData[];
  tableSelectedData: GwSnifferData[] = [];
  orderBy: object = { attribute: "timestamp", reverse: true };
  tableQuickFilters: TableQuickFilter[][];
  columns: (TableActionColumn | TableDataColumn | TableSelectColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-meter",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "assign-as-redundant",
          tooltip: "assign-as-redundant",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: "assigned",
        },
        {
          name: "delete-meter",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          warning: true,
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "gateway",
      data: "gatewaySerialNumber",
      search: "gatewaySerialNumber",
      sort: "gatewaySerialNumber",
      link: "gatewayLink",
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "SNR",
      data: "snr",
      search: "snr",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "assigned",
      search: "assigned",
      sort: "assigned",
      boolean: true,
      alter: {
        condition: "assigned",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "assigned-as",
      data: "assignedAs",
      search: "assignedAs",
      sort: "assignedAs",
      visible: true,
    },
    {
      title: "date",
      data: "timestamp",
      search: "timestamp",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "received-times",
      data: "receivedTimes",
      search: "receivedTimes",
      sort: "receivedTimes",
      numerical: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private route: ActivatedRoute,
    private meterController: MeterControllerService,
    private gatewayController: GatewayControllerService,
    private dateParserService: DateParserService,
    private router: Router,
    private translate: TranslateService,
    private ToastService: ToastService,
    private SessionDataService: SessionDataService,
    private RouteCheckService: RouteCheckService,
    private ReloadComponentService: ReloadComponentService
  ) {}

  /***************************************************************************/
  // ANCHOR: Inicialización del componente
  /***************************************************************************/
  ngOnInit() {
    this.setSubscriptions();
    this.getData();
  }

  /***************************************************************************/
  // ANCHOR: Destrucción del componente
  /***************************************************************************/
  ngOnDestroy() {
    this.clientSub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR: Funciones de suscripción
  /***************************************************************************/
  setSubscriptions() {
    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Suscribirse al servicio de cliente para verificar cambios
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      // Verificar si el cliente debe permanecer en la misma ruta o navegar a otra
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload() // Recargar el componente si está en la misma ruta
        : this.router.navigate(["/principal"]); // Navegar a la ruta principal si es necesario
    });
    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );
  }

  /***************************************************************************/
  // ANCHOR: Funciones
  /***************************************************************************/
  getData() {
    this.meterController
      .findSniffersByMeterId(this.data.meter.id)
      .subscribe((response) => {
        let data: GwSnifferData[] = response["body"];
        data.forEach((snifferRegist) => {
          this.unidadVenta = snifferRegist.gatewaySerialNumber;
          snifferRegist.gatewayLink =
            "/gateways/detalle/gateway/" + snifferRegist.gatewayId;
          snifferRegist.timestampParsed = this.dateParserService.parseDate(
            snifferRegist.timestamp,
            "L HH:mm:ss"
          );
          snifferRegist.assigned =
            snifferRegist.assignedAsMainForGw ||
            snifferRegist.assignedAsRedundantForGw;
          if (snifferRegist.assigned) {
            snifferRegist.assignedAs = snifferRegist.assignedAsMainForGw
              ? this.translate.instant("main")
              : this.translate.instant("redundant");
          }
        });
        this.tableData = data;
      });
    this.setQuickFilters();
  }

  setQuickFilters() {
    this.tableQuickFilters = [
      [
        {
          name: "redundant",
          columnSearch: "assignedAsRedundantForGw",
          condition: { type: "boolean", rule: true },
          active: false,
        },
        {
          name: "main",
          columnSearch: "assignedAsMainForGw",
          condition: { type: "boolean", rule: true },
          active: false,
        },
      ],
      [
        {
          name: "assigned",
          columnSearch: "assigned",
          condition: { type: "boolean", rule: true },
          active: false,
        },
      ],
    ];
  }

  tableActions(event: any): void {
    switch (event.action.name) {
      case "show-meter":
        this.router.navigate([
          "/dispositivos/detalle/contador/" + event.element.meterId,
        ]);
        break;
      case "delete-meter":
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("sniffer-selected-delete-question")
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.gatewayController
              .deleteGatewaySnifferById(event.element.gatewayId, [
                event.element.id,
              ])
              .subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("sniffer-deleted")
                  );
                  this.getData();
                }
              });
          }
        });
        break;
      case "assign-as-redundant":
        this.gatewayController
          .assignMeterAsRedundant(event.element.gatewayId, [
            event.element.meterId,
          ])
          .subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("selected-assign")
              );
              this.getData();
            }
          });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: any): void {
    switch (action.title) {
      case "assign-as-redundant":
        let snifferIds = [];
        this.tableSelectedData.forEach((selectedRegister) => {
          if (!selectedRegister.assigned) {
            snifferIds.push(selectedRegister.meterId);
          }
        });
        this.gatewayController
          .assignMeterAsRedundant(this.gatewayId, snifferIds)
          .subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("selected-assign")
              );
            }
            this.getData();
          });
        break;
      case "clean-all":
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("sniffer-delete-question")
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.gatewayController
              .deleteGatewaySniffer(this.gatewayId, false)
              .subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("sniffer-deleted")
                  );
                  this.getData();
                }
              });
          }
        });
        break;
      case "clean-assigned":
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("sniffer-delete-question")
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.gatewayController
              .deleteGatewaySniffer(this.gatewayId, true)
              .subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("sniffer-deleted")
                  );
                  this.getData();
                }
              });
          }
        });
        break;
      case "erase-selection":
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("sniffer-assigned-delete-question")
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            let snifferIds = [];
            this.tableSelectedData.forEach((element) => {
              snifferIds.push(element.id);
            });
            this.gatewayController
              .deleteGatewaySnifferById(this.gatewayId, snifferIds)
              .subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("sniffer-deleted")
                  );
                  this.getData();
                }
              });
          }
        });
        break;
      default:
        break;
    }
  }
}
