import { Component } from '@angular/core';

@Component({
  selector: 'app-gas-effect',
  templateUrl: './gas-effect.component.html',
  styleUrls: ['./gas-effect.component.scss']
})
export class GasEffectComponent {

}
