import { ToastService } from './../../../../../services/shared/ToastService.service';
import { Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { SessionDataService } from '../../../../../services/shared/SessionDataService.service';
import { RouteCheckService } from '../../../../../services/shared/RouteCheckService.service';
import { ReloadComponentService } from '../../../../../services/shared/ReloadComponentService.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GraphOptionsService } from '../../../../../modules/graph-module/GraphOptionsService.service';
import { forkJoin, Subscription } from 'rxjs';
import { Client } from '../../../../../interfaces/ClientGlobalInterface.type';
import { MeterControllerService } from './../../../../../services/server/MeterController.service';

@Component({
  selector: 'app-device-delete-readings',
  templateUrl: './device-delete-readings.component.html',
  styleUrls: ['./device-delete-readings.component.scss']
})
export class DeviceDeleteReadingsComponent {

  /***************************************************************************/
  // ANCHOR Variables - Definición de variables
  /***************************************************************************/

  private clientSub: Subscription;  // Suscripción al cliente
  private currentClient: Client;    // Cliente actual

  @Input() data: any;           // Parámetro de entrada para el ID del medidor

  selectedDateRange: any;           // Rango de fechas seleccionado

  /***************************************************************************/
  // ANCHOR Constructor - Inyección de dependencias
  /***************************************************************************/

  constructor(
    private SessionDataService: SessionDataService,
    private RouteCheckService: RouteCheckService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private translate: TranslateService,
    private MeterController: MeterControllerService,
    private ToastService: ToastService,
  ) {}

  /***************************************************************************/
  // ANCHOR ngOnInit - Inicialización del componente
  /***************************************************************************/

  ngOnInit() {
    this.initializeClient();  // Inicializa los datos del cliente
  }

  /***************************************************************************/
  // ANCHOR Métodos privados - Inicialización y lógica interna
  /***************************************************************************/

  private initializeClient(): void {
    // Obtener el cliente actual de la sesión
    this.currentClient = this.SessionDataService.getCurrentClient();

    // Suscribirse al servicio de cliente para verificar cambios
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      // Verificar si el cliente debe permanecer en la misma ruta o navegar a otra
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()  // Recargar el componente si está en la misma ruta
        : this.router.navigate(["/principal"]);  // Navegar a la ruta principal si es necesario
    });
  }

  /***************************************************************************/
  // ANCHOR Métodos públicos - Lógica del diálogo y eliminación
  /***************************************************************************/

  // Cerrar el diálogo
  close() {
    this.SessionDataService.sendDialogAction({ action: "close" });  // Enviar acción de cierre
  }

  // Eliminar el rango de lecturas
// Eliminar el rango de lecturas
deleteRange() {
  // Mostrar un mensaje de confirmación antes de proceder
  this.ToastService
    .fireAlertWithCaptcha(
      "question",  // Tipo de alerta
      this.translate.instant("delete-readings-question")  // Mensaje de confirmación traducido
    )
    .then((userConfirmation: boolean) => {
      // Si el usuario confirma, proceder con la eliminación
      if (userConfirmation) {
        // Llamada al servicio para eliminar el rango de lecturas
        console.log(          this.SessionDataService.getCurrentAgrupation().id,  // ID de agrupación actual
      )
        this.MeterController.deleteReadingsPeriod(
          this.data.meter,  // ID del medidor
          this.SessionDataService.getCurrentAgrupation().id,  // ID de agrupación actual
          this.selectedDateRange.startDate.valueOf().toString(),  // Fecha de inicio seleccionada
          this.selectedDateRange.endDate.valueOf().toString()    // Fecha de fin seleccionada
        ).subscribe({
          next: (result) => {
            console.log('Lecturas eliminadas:', result);
          }, 
          error: (error) => {
            console.error('Error al eliminar las lecturas:', error);
          }
        });

        // Cerrar el diálogo después de realizar la acción
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
}


}
