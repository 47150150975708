// @angular
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { MaterialDialogService } from "../../material-module/material-dialog/material-dialog.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
// Componentes
import { GraphComponent } from "../graph/graph.component";
import { GraphControllerDialogComponent } from "./graph-controller-dialog/graph-controller-dialog.component";
import { MaterialDateSelectorComponent } from "../../material-module/material-date-selector/material-date-selector.component";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import {
  GraphColorByPoint,
  GraphFilterOptionSelected,
} from "../GraphInterface.type";
import { ConsumptionProfile } from "../../../screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-patterns";
// Variables
import { LOCAL_TIMEZONE } from "../../../global/LOCAL_TIMEZONE";
import { PROFILES } from "../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-graph-controller",
  templateUrl: "./graph-controller.component.html",
  styleUrls: ["./graph-controller.component.scss"],
})
export class GraphControllerComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Flag de componente inicializado
  componentInitiated: boolean = false;

  // Variables de sesión
  currentAgrupation: Agrupation;
  sessionProfile: string;
  sessionLanguage: string;
  languageSub: Subscription;
  agrupationSub: Subscription;
  dialog: Subscription;
  numberFormat: string;
  @Input() meterConsumptionProfile: ConsumptionProfile;
  readonly PROFILES = PROFILES;

  // Variables de la gráfica
  @Output() dataRequest = new EventEmitter<any>();
  @ViewChild("dateSelector") dateSelector: MaterialDateSelectorComponent;
  // @ViewChild("graphForm") graphForm: ElementRef;
  @ViewChild(GraphComponent) graphComponent: GraphComponent;
  @Input() highchartsOptions: object;
  @Input()
  get data(): object {
    return this.chartOptions;
  }
  set data(data: object) {
    // Eliminación de duplicados
    if (data && this.avoidDuplicates?.some((serie: boolean) => serie)) {
      this.chartOptions = this.filterSerieDuplicates(data);
    } else {
      this.chartOptions = data;
    }

    // Parseo de opciones
    if (this.chartOptions && !this.noOptionsParse) {
      this.getParsedChartOptions();
    } else if (this.chartOptions && this.noOptionsParse) {
      this.parsedChartOptions = data;
    }
  }
  chartOptions: any;
  @Input() chartConstructor: string;
  @Input() columns: boolean;
  @Input() graphLegend: string;
  @Input() noOptionsParse: boolean;
  @Input() seriesToCheck: number[];
  @Input() seriesToUpdate: number[];
  @Input() seriesToggle: { serie: string; visible: boolean }[];
  @Input() avoidDuplicates: boolean[];
  @Input() yAxisMinRange: number;
  @Input() yAxisMaxRange: number;
  @Input() seriesDisplace: boolean;
  @Input() disableShowPoints: boolean;
  @Input() avoidCustomExport: boolean;  
  displacePreselect: any;
  resetDisplaceSelection: boolean;
  @Output() seriesDisplaceFlag = new EventEmitter<any>();
  @Output() serieToggled = new EventEmitter<
    { serie: string; visible: boolean }[]
  >();
  @Input()
  get colorByPoint(): GraphColorByPoint {
    return this._colorByPoint;
  }
  set colorByPoint(colorByPoint: GraphColorByPoint) {
    if (colorByPoint) {
      this._colorByPoint = colorByPoint;
      this.updatePointsColor();
    }
  }
  _colorByPoint: GraphColorByPoint;
  parsedOptions: any;
  parsedChartOptions: any;
  interpolatedFlag: boolean = false;
  interpolatedZones: any[];
  showInterpolatedZones: boolean = false;
  userGraphType: string = "default";
  graphTypeOptions: object[];
  @Output() zoomFlag = new EventEmitter<object>();
  @Output() chartObject = new EventEmitter<any>();

  // Selector
  @Input() dateRange: { startDate: moment.Moment; endDate: moment.Moment };
  @Input() maxDate: moment.Moment;
  @Input() maxInterval: number;
  @Input() initialDateNull: boolean;
  @Input() dateDisabled: boolean;
  @Input() dateHint: string;
  @Input() forceLocalTime: boolean;
  dateRangeSelected: any;
  dateRangeUpdatedTimeout: any;
  dateRangeActive: boolean;
  @Input() noDateSelector: boolean;
  @Input() noTypeSelector: boolean;
  @Input() pointDeletion: boolean;
  @Input() showCumulative: boolean;
  @Input() showCumulativeTotal: boolean;
  cumulativeComparison = [
    { type: "none", name: this.translate.instant("no") },
    { type: "yearly", name: this.translate.instant("yearly") },
  ];
  accumulatedSerie: boolean;
  @Input() avoidInitialLoad: boolean;

  // Filtros
  @Input() filters: any[];
  @Input() onlyGraph: boolean;
  @Output() filterFlag = new EventEmitter<GraphFilterOptionSelected>();

  // Desplazamiento
  displaceOptions: { name: string; value: number }[] = [
    { name: this.translate.instant("displace-axis-x"), value: 0 },
    { name: this.translate.instant("displace-axis-y"), value: 1 },
  ];
  displaceOptionSelected: number;

  // Patrones
  @Input() showPrediction: boolean;
  @Output() prediction = new EventEmitter<any>();

  // Gráfica de fugas
  @Input() leaksActive: boolean;
  @Output() hideLeaksFlag = new EventEmitter<boolean>();
  hideLeaks: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    public router: Router,
    public sanitizer: DomSanitizer,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();

    // Escucha de cambios en los valores de entidad, agrupación e idioma
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.sessionLanguage = language;
      }
    );

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: any) => {
        if (dialogAction.action == "displace") {
          this.seriesDisplaceFlag.emit({
            seriesId: dialogAction.seriesId,
            displacement: dialogAction.displacement,
            axis: dialogAction.axis,
            displacementSign: dialogAction.displacementSign,
          });
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      setTimeout(() => this.loadComponent(), 1000);
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.languageSub.unsubscribe();
    clearTimeout(this.dateRangeUpdatedTimeout);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getParsedHighchartsOptions();
    this.graphTypeOptions = [
      { name: this.translate.instant("graph-type-default"), value: "default" },
      { name: this.translate.instant("graph-type-line"), value: "line" },
      { name: this.translate.instant("graph-type-area"), value: "area" },
      { name: this.translate.instant("graph-type-bars"), value: "column" },
    ];
    if (this.initialDateNull) {
      setTimeout(() => (this.parsedChartOptions = {}), 100);
    }
  }

  // Petición de los datos según el selector de rango de fechas
  loadGraphData(e?): void {
    this.parsedChartOptions = null;
    this.interpolatedFlag = false;
    this.showInterpolatedZones = false;
    let from: string;
    let to: string;
    if (this.dateRangeSelected?.startDate) {
      from = moment_timezone(this.dateRangeSelected?.startDate)
        .tz(
          this.forceLocalTime
            ? LOCAL_TIMEZONE
            : this.currentAgrupation?.timezone
            ? this.currentAgrupation.timezone
            : LOCAL_TIMEZONE,
          true
        )
        .startOf("day")
        .valueOf()
        .toString();
    } else {
      from = null;
    }
    if (this.dateRangeSelected?.endDate) {
      to = moment_timezone(this.dateRangeSelected?.endDate)
        .tz(
          this.forceLocalTime
            ? LOCAL_TIMEZONE
            : this.currentAgrupation?.timezone
            ? this.currentAgrupation.timezone
            : LOCAL_TIMEZONE,
          true
        )
        .endOf("day")
        .valueOf()
        .toString();
    } else {
      to = null;
    }
    this.dataRequest.emit({
      from: from,
      to: to,
      alt: this.sessionProfile == PROFILES.ARSON && e?.altKey,
    });
    this.componentInitiated = true;
    this.avoidInitialLoad = false;
  }

  // Actualización del rango seleccionado
  updateDateRange(
    selected: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    },
    getData?: boolean
  ): void {
    this.dateRangeSelected = selected;
    if ((!this.componentInitiated && !this.avoidInitialLoad) || getData) {
      clearTimeout(this.dateRangeUpdatedTimeout);
      this.dateRangeUpdatedTimeout = setTimeout(() => this.loadGraphData(), 0);
    }
  }

  // Actualización del selector de fecha
  updateSelectorDateRange(dateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    this.dateSelector.updateSelectorDateRange(dateRange);
    this.updateDateRange(dateRange, true);
  }

  // Traducción de las opciones de lenguaje de la gráfica
  getParsedHighchartsOptions(): void {
    const self = this;
    let options: any = { ...this.highchartsOptions };

    for (let attribute in options.lang) {
      if (options.lang[attribute].constructor === Array) {
        options.lang[attribute] = options.lang[attribute].map(
          (data: string) => {
            return this.translate.instant(data);
          }
        );
      } else {
        options.lang[attribute] = this.translate.instant(
          options.lang[attribute]
        );
      }
    }

    options["time"] = {
      timezone: this.currentAgrupation?.timezone
        ? this.currentAgrupation?.timezone?.toString()
        : LOCAL_TIMEZONE,
    };

    if (!options.lang) {
      options.lang = {};
    }
    options.lang.decimalPoint =
      this.numberFormat == LANGUAGE.INGLES ? "." : ",";
    options.lang.thousandsSep =
      this.numberFormat == LANGUAGE.INGLES ? "," : ".";

    // Flag de redibujado
    options.chart = {
      events: {
        redraw: function () {
          self.zoomFlag.emit(this.xAxis[0].getExtremes());
        },
      },
    };

    this.parsedOptions = options;
  }

  // Traducción de los botones de la gráfica
  getParsedChartOptions(): void {
    let chartOptions: any = { ...this.chartOptions };

    // Botones de selección de rango
    chartOptions.rangeSelector?.buttons.forEach((button: any) => {
      button.text = this.translate.instant(button.text);
    });

    if (!this.columns) {
      chartOptions.rangeSelector?.buttons.forEach((button: any) => {
        button.events = {
          click: () => this.changeGraphType(button.text),
        };
      });
    } else {
      chartOptions.rangeSelector?.buttons.forEach((button: any) => {
        button.events = {
          click: () => this.dateRangeSelectedUpdate(button.text),
        };
      });
    }

    // Formato numérico de labels de eje y
    chartOptions["yAxis"]?.map((yaxis: any) => {
      if (!yaxis.labels) {
        yaxis.labels = {};
      }
      if (!yaxis.labels.formatter) {
        yaxis.labels.formatter = function () {
          return Highcharts.numberFormat(this.value, -1);
        };
      }
    });

    this.parsedChartOptions = chartOptions;
    // Zonas interpoladas
    this.getInterpolatedZones();
  }

  // Obtención de las zonas con datos interpolados
  getInterpolatedZones(): void {
    this.interpolatedZones = [];
    this.seriesToCheck?.map((serie: any) => {
      // Obtención de zonas nulas si existen datos en la serie
      if (
        this.parsedChartOptions.series[serie]?.data?.length > 0 &&
        this.parsedChartOptions.series[serie]?.data?.some(
          (data) => data[1] != null
        )
      ) {
        this.interpolatedZones = this.getNullZones(
          this.parsedChartOptions.series[serie].data
        );
      }
      // Obtención de datos interpolados si vienen indicados desde el BackEnd en data[2]
      if (
        this.parsedChartOptions.series[serie]?.data?.length > 0 &&
        this.parsedChartOptions.series[serie]?.data[0].length == 3
      ) {
        this.parsedChartOptions.series[serie]?.data.map((data: any[]) => {
          if (data[2]) {
            this.interpolatedFlag = true;
            this.interpolatedZones.push({ value: data[0] - 3599999 });
            this.interpolatedZones.push({
              value: data[0],
              color: "rgb(255, 124, 124)",
            });
          }
        });
      }
    });
    this.interpolatedZones?.sort((a, b) => a.value - b.value);
  }

  // Comprobación de los datos horarios
  getNullZones(seriesData: any): any[] {
    let prevTimestamp: number = Math.trunc(seriesData[0][0] / 1000);
    let nullTimestamps: any[] = [];
    let nullZones: any[] = [];

    // Obtención de timestamps faltantes en la serie por hora
    for (let i = 1; i < seriesData.length; i++) {
      let currentTimestamp = Math.trunc(seriesData[i][0] / 1000);
      let expectedTimestamp = prevTimestamp + 3600;
      if (
        currentTimestamp != expectedTimestamp &&
        currentTimestamp != prevTimestamp
      ) {
        nullTimestamps.push(expectedTimestamp * 1000);
        prevTimestamp = expectedTimestamp;
        if (currentTimestamp > expectedTimestamp) {
          i--;
        }
      } else {
        prevTimestamp = currentTimestamp;
      }
    }

    // Resalte de timestamps faltantes en la serie
    if (nullTimestamps.length > 0) {
      this.interpolatedFlag = true;
    }
    nullTimestamps.forEach((timestamp: number) => {
      nullZones.push({ value: timestamp - 3599999 });
      nullZones.push({ value: timestamp, color: "rgb(255, 124, 124)" });
    });

    return nullZones;
  }

  // Actualización de los colores de los puntos
  updatePointsColor(): void {
    this._colorByPoint.coloredData = [];
    let colored: boolean = false;

    if (
      this._colorByPoint &&
      this.parsedChartOptions?.series[this._colorByPoint.serie]?.data?.length
    ) {
      this.parsedChartOptions.series[this._colorByPoint.serie].data.map(
        (data: any[]) => {
          if (
            data[this._colorByPoint.conditionIndex] ==
            this._colorByPoint.conditionValue
          ) {
            this.colorByPoint.coloredData.push({
              value: data[0] + 3600000,
              color: this._colorByPoint.color,
            });
            colored = true;
          } else {
            this.colorByPoint.coloredData.push({ value: data[0] + 3600000 });
          }
        }
      );
    }

    if (colored) {
      this.graphLegend = this.colorByPoint.legend;
    } else {
      this.graphLegend = null;
    }
  }

  // Visualización de las zonas con datos interpolados
  updateInterpolatedZones(): void {
    this.graphComponent.updateSeriesInterpolated(
      this.seriesToCheck,
      this.showInterpolatedZones ? this.interpolatedZones : []
    );
  }

  // Cambio del tipo de gráfica
  changeGraphType(text: string): void {
    if (this.userGraphType == "default") {
      this.graphComponent.updateGraphType(
        text.toUpperCase() === this.translate.instant("hour").toUpperCase() ||
          text.toUpperCase() === this.translate.instant("minute").toUpperCase()
          ? "area"
          : "column"
      );
    }

    this.dateRangeSelectedUpdate(text);
  }

  // Actualización de rango seleccionado
  dateRangeSelectedUpdate(text: string): void {
    this.dateRangeActive =
      text.toUpperCase() != this.translate.instant("hour").toUpperCase() &&
      text.toUpperCase() != this.translate.instant("minute").toUpperCase();
  }

  // Actualización de datos de serie
  updateSeriesData(serieId: string, data: number[][]): void {
    this.graphComponent?.updateSeriesData(serieId, data);
  }

  // Actualización de tipo de gráfica
  updateGraphType(): void {
    this.graphComponent.updateGraphType(this.userGraphType);
  }

  // Eliminación de duplicados en las series
  filterSerieDuplicates(data: any): any {
    this.avoidDuplicates.forEach((serie: boolean, i) => {
      if (serie) {
        let filteredSerie: number[][] = [];
        data.series[i].data.forEach((serieData: number[]) => {
          if (
            !filteredSerie.some(
              (filteredData: number[]) => filteredData[0] == serieData[0]
            )
          ) {
            filteredSerie.push(serieData);
          }
        });
        data.series[i].data = filteredSerie;
      }
    });
    return data;
  }

  // Desplazar gráfica
  displaceGraph(): void {
    this.resetDisplaceSelection = !this.resetDisplaceSelection;
    this.MaterialDialogService.openDialog(GraphControllerDialogComponent, {
      series: this.parsedChartOptions.series.filter(
        (serie: any) => serie.displace
      ),
      option: this.displaceOptionSelected,
    });
    setTimeout(() => (this.displacePreselect = null), 0);
  }

  // Visualización de línea entre timestamps
  addTimestampsLine(
    start: number,
    end: number,
    id: string,
    name: string
  ): void {
    this.graphComponent.addTimestampsLine(start, end, id, name);
  }

  // Eliminación de línea entre timestamps
  removeTimestampsLine(id: string): void {
    this.graphComponent.removeTimestampsLine(id);
  }

  // Ocultar serie
  hideSerie(serieIndex: number): void {
    this.graphComponent.hideSerie(serieIndex);
  }

  // Visualizar serie
  showSerie(serieIndex: number): void {
    this.graphComponent.showSerie(serieIndex);
  }

  // Ocultar eje y
  hideYAxis(axisIndex: number): void {
    this.graphComponent.hideYAxis(axisIndex);
  }

  // Visualizar eje y
  showYAxis(axisIndex: number): void {
    this.graphComponent.showYAxis(axisIndex);
  }

  // Ver acumulados
  showCumulativeSum(cumulativeType: string): void {
    this.accumulatedSerie = cumulativeType != "none";
    this.graphComponent.showCumulativeSum(
      cumulativeType != "none" ? cumulativeType : null
    );
  }

  // Visualización de anomalías
  showAnomalies(anomalies: number[][]): void {
    this.graphComponent.showAnomalies(anomalies);
  }

  // Hover serie index
  hoverIndex(index: number, serieIndex?: number): void {
    this.graphComponent?.hoverIndex(index, serieIndex);
  }

  // Calcular predicción
  calculatePrediction(): void {
    this.prediction.emit();
  }
}
