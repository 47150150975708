<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <app-material-select
          class="material-header-select"
          [title]="'show-alert-level' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [selected]="initialAlertLevel"
          [data]="alertLevels"
          (selectedOption)="
            dataSource = $event?.value;
            alertsList = null;
            mapData = null;
            getData()
          "
        ></app-material-select>

        <div
          class="control-alert-counter"
          [ngClass]="
            cardsData?.controlAlerts?.data > 0
              ? 'bg-red-gradient'
              : 'bg-green-gradient'
          "
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{
            cardsData?.controlAlerts?.data != null
              ? cardsData.controlAlerts.data
              : "-"
          }}
        </div>
      </div>

      <div #controlPanel class="panel-body control-container">
        <!-- Mapa de alertas -->
        <div class="control-map">
          <app-map-controller
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            (actionFlag)="showModal($event)"
            [activateAllLayers]="activateAllLayers"
            [gatewaysData]="[]"
            [metersData]="mapData"
          >
          </app-map-controller>
        </div>

        <!-- Tabla de alertas -->
        <div class="control-table">
          <app-table-controller
            [tableId]="'control'"
            [export]="false"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="tableMaxReg"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [onlyTable]="onlyTable"
            [highlightRow]="tableHighlightRow"
            [data]="alertsList"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
