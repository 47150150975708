<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default network-state-panel">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <!-- Título -->
          <h5>{{ "entity-evolution" | translate }}</h5>

          <!-- Botón actualizar -->
          <app-material-panel-menu
            [onlyButton]="true"
            [title]="updateTitle"
            [disabled]="cardsLoading"
            [updating]="cardsLoading"
            (action)="getCards(true)"
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tarjetas de datos -->
        <app-cards-controller
          [cardsId]="'networkStateEntity'"
          [data]="cardsData"
          [cardsOrder]="cardsOrder"
          [cardWidth]="30"
          [cardsGap]="1.5"
        >
        </app-cards-controller>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'networkStateEntity'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [data]="entityData"
        >
        </app-table-controller>

        <!-- Gráfica -->
        <app-graph-controller
          [highchartsOptions]="highchartsOptions"
          [data]="chartOptions"
          [chartConstructor]="chartConstructor"
          (dataRequest)="loadGraphData($event.from, $event.to)"
        >
        </app-graph-controller>
      </div>
    </div>
  </div>
</section>
