// @angular
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
// Servicios propios
import { HttpRequestService } from "./HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class HttpRequestQueueService {
  /***************************************************************************/
  // Constructor
  /***************************************************************************/

  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // Intercepción de petición
  /***************************************************************************/

  // Intercepción de llamada HTTP
  request(
    req: HttpRequest<any>,
    next: HttpHandler,
    requestTimeout: number
  ): Observable<HttpEvent<any>> {
    return (
      next
        .handle(req)
        // Timeout variable dependiendo de la ruta
        .pipe(
          this.HttpRequestService.timeoutWhen(
            requestTimeout ? true : false,
            requestTimeout
          )
        )
        // Comprobación de los errores en la respuesta del servidor
        .pipe(
          tap((event) => {
            this.HttpRequestService.httpErrorManagement(event);
          })
        )
        .pipe(
          // Control de errores en la petición HttpRequestService
          catchError((error: HttpErrorResponse) => {
            let httpError = JSON.parse(JSON.stringify(error));
            httpError.code = -1;
            this.HttpRequestService.getErrorText(httpError);
            return throwError(() => error);
          })
        )
    );
  }
}
