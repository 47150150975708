<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <!-- Número de serie -->
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "label" | translate }}: {{ "serial-number" | translate }}</b>
        </h5>

        <!-- Botón guardado -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveButtonIcon"
          [title]="saveButtonTittle"
          [disabled]="nroSerieLabel == null"
          (action)="serialNumber ? updateNroSerieLabel() : saveNroSerieLabel()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <mat-form-field appearance="standard">
          <mat-label>{{ "label" | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="nroSerieLabel"
            placeholder="{{ 'label' | translate }}..."
            required
          />
        </mat-form-field>
      </div>
    </div>

    <!-- CUPS -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "master-key" | translate }}</b>
        </h5>

        <!-- Botón guardado -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveButtonIcon"
          [title]="saveButtonTittle"
          [disabled]="
            !cupsExtraDataEnabled ||
            cups.name == '' ||
            cups.name == null ||
            cups.label == '' ||
            cups.label == null
          "
          (action)="newCupsFlag ? saveCups() : updateCups()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div>
          <app-material-toggle
            *ngIf="tableData"
            [onText]="'activated' | translate"
            onColor="warning"
            [offText]="'activate' | translate"
            offColor="primary"
            [disabled]="cupsExtraDataEnabled"
            [toggle]="cupsExtraDataEnabled"
            (change)="cupsExtraDataEnabled = $event; updateCupsOptions()"
          ></app-material-toggle>
        </div>

        <div *ngIf="cups" class="data-management-config-option">
          <!-- Nombre -->
          <mat-form-field appearance="standard">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="cups.name"
              placeholder="{{ 'name' | translate }}..."
              [disabled]="!cupsExtraDataEnabled"
              required
            />
          </mat-form-field>
          <!-- Etiqueta -->
          <mat-form-field appearance="standard">
            <mat-label>{{ "label" | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="cups.label"
              placeholder="{{ 'label' | translate }}..."
              [disabled]="!cupsExtraDataEnabled"
              required
            />
          </mat-form-field>
          <!-- Descripción -->
          <mat-form-field appearance="standard">
            <mat-label>{{ "description" | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="cups.description"
              placeholder="{{ 'description' | translate }}..."
              [disabled]="!cupsExtraDataEnabled"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Datos extra -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "meters-extra-data" | translate }}</b>
        </h5>

        <!-- Botón exportar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newExtraColumn()"
          [disabled]="extraColumns?.length == 20"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataManagementConfiguration'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="maxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="extraColumns"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
