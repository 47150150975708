export const CESIUM_MAP_CONSTS = {
  // ANCHOR Leyendas para cada tipo de mapa
  legend: {
    standard: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-play rotated90 greenIcon", text: "sensor" },
      { icon: "fas fa-circle greenIcon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
      { icon: "fas fa-tint greenIcon", text: "lw-une-ok" },
      { icon: "fas fa-tint redIcon", text: "lw-une-fail" },
      {
        icon: "fas fa-star greenIcon map-color-legend-icon",
        text: "no-lora-meter-ok",
      },
      {
        icon: "fas fa-star redIcon map-color-legend-icon",
        text: "no-lora-meter-fail",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com-meter",
      },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "no-assigned",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meter-mbus-wavenis",
      },
    ],
    onlyGateways: [
      { icon: "fas fa-circle blueIcon map-color-legend-icon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
    ],
    coverageMap: [
      { icon: "fas fa-circle blueIcon map-color-legend-icon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
      {
        icon: "fas fa-square greenIcon map-color-legend-icon",
        text: "coverage-total-rssi",
      },
      {
        icon: "fas fa-square yellowGreenIcon map-color-legend-icon",
        text: "coverage-high-rssi",
      },
      {
        icon: "fas fa-square yellowIcon map-color-legend-icon",
        text: "coverage-mid-rssi",
      },
      {
        icon: "fas fa-square orangeIcon map-color-legend-icon",
        text: "coverage-low-rssi",
      },
      {
        icon: "fas fa-square redIcon map-color-legend-icon",
        text: "coverage-critical-rssi",
      },
      {
        icon: "fas fa-square blackIcon map-color-legend-icon",
        text: "coverage-null",
      },
    ],
    coverage: [
      { icon: "fas fa-circle yellowIcon", text: "other" },
      { icon: "fas fa-circle redIcon", text: "test-fail" },
      { icon: "fas fa-circle greenIcon", text: "test-ok" },
    ],
    control: [
      { icon: "fas fa-exclamation-circle redIcon", text: "alert-open" },
      { icon: "fas fa-exclamation-circle", text: "alert-assigned" },
      { icon: "fas fa-circle blueIcon", text: "meters-close-valve" },
      { icon: "fas fa-circle purpleIcon", text: "sensors" },
    ],
    meterDetail: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-play rotated90 greenIcon", text: "sensor" },
      { icon: "fas fa-circle greenIcon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
      { icon: "fas fa-tint greenIcon", text: "lw-une-ok" },
      { icon: "fas fa-tint redIcon", text: "lw-une-fail" },
      {
        icon: "fas fa-star greenIcon map-color-legend-icon",
        text: "no-lora-meter-ok",
      },
      {
        icon: "fas fa-star redIcon map-color-legend-icon",
        text: "no-lora-meter-fail",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com-meter",
      },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "no-assigned",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meter-mbus-wavenis",
      },
    ],
    cupsDetail: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-play rotated90 greenIcon", text: "sensor" },
      { icon: "fas fa-circle greenIcon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
      { icon: "fas fa-tint greenIcon", text: "lw-une-ok" },
      { icon: "fas fa-tint redIcon", text: "lw-une-fail" },
      {
        icon: "fas fa-star greenIcon map-color-legend-icon",
        text: "no-lora-meter-ok",
      },
      {
        icon: "fas fa-star redIcon map-color-legend-icon",
        text: "no-lora-meter-fail",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com-meter",
      },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "no-assigned",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meter-mbus-wavenis",
      },
    ],
    meterGraph: [
      { icon: "fas fa-circle yellowIcon", text: "selected" },
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-play rotated90 greenIcon", text: "sensor" },
      { icon: "fas fa-circle greenIcon", text: "actives" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "without-communication",
      },
      { icon: "fas fa-tint greenIcon", text: "lw-une-ok" },
      { icon: "fas fa-tint redIcon", text: "lw-une-fail" },
      {
        icon: "fas fa-star greenIcon map-color-legend-icon",
        text: "no-lora-meter-ok",
      },
      {
        icon: "fas fa-star redIcon map-color-legend-icon",
        text: "no-lora-meter-fail",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com-meter",
      },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "no-assigned",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meter-mbus-wavenis",
      },
    ],
    balanceDetail: [
      { icon: "fas fa-circle blueIcon", text: "childs" },
      { icon: "fas fa-circle greenIcon", text: "fathers" },
    ],
    meterList: [
      { icon: "fas fa-circle turquoiseIcon", text: "devices" },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    meterAssignable: [
      { icon: "fas fa-circle turquoiseIcon", text: "redundant-meter" },
      { icon: "fas fa-circle orangeIcon", text: "assignable-meter" },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    alarmsMeterList: [
      { icon: "fas fa-circle redIcon", text: "alarm-new" },
      { icon: "fas fa-circle yellowIcon", text: "alarm" },
    ],
    gatewayDetail: [
      { icon: "fas fa-circle greenIcon", text: "main-meter" },
      { icon: "fas fa-circle turquoiseIcon", text: "redundant-meter" },
      { icon: "fas fa-circle orangeIcon", text: "assignable-meter" },
    ],
  },
  // ANCHOR Variables de los marcadores de contadores
  markerVariables: {
    OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommOk greenIcon",
      markerTitle: "actives",
      markerZindex: 1500,
      color: "GREEN",
    },
    NO_ASIGNADO: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceNoAssignated purpleIcon",
      markerTitle: "no-assigned",
      markerZindex: 300,
      color: "PURPLE",
    },
    NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommError redIcon",
      markerTitle: "without-communication",
      markerZindex: 500,
      color: "RED",
    },
    CONCENTRATOR_OK: {
      iconHtml: "<i class='fas fa-tint fa-2x'></i>",
      iconClasses: "deviceCommOk greenIcon",
      markerTitle: "actives",
      markerZindex: 580,
      color: "GREEN",
    },
    CONCENTRATOR_NO_ASIGNADO: {
      iconHtml: "<i class='fas fa-tint fa-2x'></i>",
      iconClasses: "deviceNoAssignated purpleIcon",
      markerTitle: "no-assigned",
      markerZindex: 550,
      color: "PURPLE",
    },
    CONCENTRATOR_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-tint fa-2x'></i>",
      iconClasses: "deviceCommError redIcon",
      markerTitle: "without-communication",
      markerZindex: 560,
      color: "RED",
    },
    CONCENTRATOR_NO_RECIBIDO: {
      iconHtml: "<i class='fas fa-tint fa-2x'></i>",
      iconClasses: "deviceCommError grayIcon",
      markerTitle: "no-received",
      markerZindex: 560,
      color: "GRAY",
    },
    NO_LORA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceNoLora turquoiseIcon",
      markerTitle: "no-lora-meter",
      markerZindex: 700,
      color: "TURQUOISE",
    },
    NO_LORA_NO_COM: {
      iconHtml:
        "<i class='fa fa-circle fa-lg'></i><i class='fas fa-times fa-lg'></i>",
      iconClasses: "deviceNoLoraNoCom grayIcon",
      markerTitle: "no-lora-no-com-meter",
      markerZindex: 700,
      color: "SILVER",
    },
    MBUS: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceMbus blueIcon",
      markerTitle: "Mbus",
      markerZindex: 600,
      color: "BLUE",
    },
    MBUS_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommunicateMbus cadetblueIcon",
      markerTitle: "mbus-communicate",
      markerZindex: 580,
      color: "CADETBLUE",
    },
    WAVENIS: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceWavenis blueIcon",
      markerTitle: "Wavenis",
      markerZindex: 600,
      color: "BLUE",
    },
    UNE_OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceUneCommOk greenIcon",
      markerTitle: "lw-une-meter-ok",
      markerZindex: 600,
      color: "GREEN",
    },
    UNE_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceUneCommError redIcon",
      markerTitle: "lw-une-meter-fail",
      markerZindex: 500,
      color: "RED",
    },
    UNE: {
      markerZindex: 500,
      color: "ORANGE",
    },
    EK_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceEkCommOk greenIcon",
      markerTitle: "ek-meter-ok",
      markerZindex: 400,
      color: "GREEN",
    },
    EK_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceEkCommError redIcon",
      markerTitle: "ek-meter-fail",
      markerZindex: 300,
      color: "RED",
    },
    EXTERNO_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceExternoCommOk greenIcon",
      markerTitle: "no-lora-meter-ok",
      markerZindex: 400,
      color: "GREEN",
    },
    EXTERNO_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceExternoCommError redIcon",
      markerTitle: "no-lora-meter-fail",
      markerZindex: 300,
      color: "RED",
    },
    ERM_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceErmCommOk greenIcon",
      markerTitle: "erm-meter-ok",
      markerZindex: 400,
      color: "GREEN",
    },
    ERM_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceErmCommError redIcon",
      markerTitle: "erm-meter-fail",
      markerZindex: 300,
      color: "RED",
    },
    PLUM_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "devicePlumCommOk greenIcon",
      markerTitle: "plum-meter-ok",
      markerZindex: 400,
      color: "GREEN",
    },
    PLUM_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "devicePlumCommError redIcon",
      markerTitle: "plum-meter-fail",
      markerZindex: 300,
      color: "RED",
    },
    COVERAGE_OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "coverageOk greenIcon",
      markerTitle: "test-ok",
      markerZindex: 500,
      tooltipTitle: "test-ok",
      color: "GREEN",
    },
    COVERAGE_ERROR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "coverageError redIcon",
      markerTitle: "test-fail",
      markerZindex: 1000,
      tooltipTitle: "test-fail",
      color: "RED",
    },
    GATEWAY: {
      okIconUrl: "assets/img/arson_marker.svg",
      errorIconUrl: "assets/img/arson_marker_error.svg",
      nodeviceIconUrl: "assets/img/arson_marker_nodevice.svg",
      grayIconUrl: "assets/img/arson_marker_gray.svg",
      locationUrl: "assets/img/arson_marker_location.png",
      markerIndex: 2000,
      color: "ORANGE",
    },
    CONTROL_VALVE_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "controlValve blueIcon",
      markerTitle: "meters-close-valve",
      markerZindex: 400,
      tooltipTitle: "meters-close-valve",
      color: "BLUE",
    },
    CONTROL_ALERT_ASSIGNED: {
      iconHtml: "<i class='fas fa-exclamation-circle fa-2x'></i>",
      iconClasses: "controlAlertAssigned",
      markerTitle: "alert-assigned",
      markerZindex: 1000,
      tooltipTitle: "alert-assigned",
      color: "ORANGE",
    },
    CONTROL_ALERT_OPEN: {
      iconHtml: "<i class='fas fa-exclamation-circle fa-2x'></i>",
      iconClasses: "controlAlertOpen redIcon bounce",
      markerTitle: "alert-open",
      markerZindex: 1500,
      tooltipTitle: "alert-open",
      color: "RED",
    },
    CONTROL_SENSOR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "controlSensor purpleIcon",
      markerTitle: "sensor",
      markerZindex: 500,
      tooltipTitle: "sensor",
      color: "PURPLE",
    },
    METER_DETAIL_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "devideDetail blueIcon",
      markerTitle: "meter",
      markerZindex: 1000,
      tooltipTitle: "meter",
      color: "BLUE",
    },
    CUPS_DETAIL_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "cupsDetail blueIcon",
      markerTitle: "device",
      markerZindex: 1000,
      tooltipTitle: "device",
      color: "BLUE",
    },
    METER_LOCATION_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceLocation blueIcon",
      markerTitle: "meter",
      markerZindex: 800,
      tooltipTitle: "meter",
      color: "BLUE",
    },
    SELECTED: {
      iconHtml: "<i class='fas fa-circle iconSelected'></i>",
      iconClasses: "deviceSelected yellowIcon",
      markerTitle: "selected",
      markerZindex: 2000,
      tooltipTitle: "selected",
      color: "YELLOW",
    },
    ERROR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceError redIcon",
      markerTitle: "without-communication",
      markerZindex: 500,
      tooltipTitle: "without-communication",
      color: "RED",
    },
    FATHER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceFather greenIcon",
      markerTitle: "father",
      markerZindex: 1000,
      tooltipTitle: "father",
      color: "GREEN",
    },
    CHILD: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceChild blueIcon",
      markerTitle: "child",
      markerZindex: 500,
      tooltipTitle: "child",
      color: "BLUE",
    },
    METER_LIST: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceList turquoiseIcon",
      markerTitle: "device",
      markerZindex: 500,
      tooltipTitle: "device",
      color: "TURQUOISE",
    },
    ALARMS_METER_LIST: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceList yellowIcon",
      markerTitle: "device",
      markerZindex: 500,
      tooltipTitle: "device",
      color: "YELLOW",
    },
    ALARMS_METER_LIST_NEW: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "newAlarm redIcon",
      markerTitle: "device",
      markerZindex: 1000,
      tooltipTitle: "device",
      color: "RED",
    },
    GATEWAY_DETAIL_MAIN_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "gatewayDetailMain greenIcon",
      markerTitle: "main-meter",
      markerZindex: 1000,
      tooltipTitle: "main-meter",
      color: "GREEN",
    },
    GATEWAY_DETAIL_REDUNDANT_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "gatewayDetailRedundant turquoiseIcon",
      markerTitle: "redundant-meter",
      markerZindex: 500,
      tooltipTitle: "redundant-meter",
      color: "TURQUOISE",
    },
    GATEWAY_DETAIL_ASSIGNABLE_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "gatewayDetailAssignable orangeIcon",
      markerTitle: "assignable-meter",
      markerZindex: 500,
      tooltipTitle: "assignable-meter",
      color: "ORANGE",
    },
    // Mapa de sustituciones
    SUBSTITUTIONS_REPLACED: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "replaced greenIcon",
      markerTitle: "substitution-replaced",
      markerZindex: 580,
      tooltipTitle: "substitution-replaced",
      color: "rgba(67, 160, 71, 1)",
    },
    SUBSTITUTIONS_PENDING: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "pending blueIcon",
      markerTitle: "substitution-pending",
      markerZindex: 540,
      tooltipTitle: "substitution-pending",
      color: "rgba(25, 118, 210, 1)",
    },
    // Localizaciones
    LOCATION: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "pending blueIcon",
      markerTitle: "substitution-pending",
      markerZindex: 1000,
      tooltipTitle: "substitution-pending",
    },
    // Dispositivos desconocidos
    UNKNOWN_DEVICE: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "unknown grayIcon",
      markerTitle: "unknown-device",
      markerZindex: 540,
      tooltipTitle: "unknown-device",
      color: "rgba(158, 158, 158, 1)",
    },
    // Concentrador MBUS
    MBUS_CONCENTRATOR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "mbusConcentrator greenIcon",
      markerTitle: "concentrator-mbus",
      markerZindex: 580,
      tooltipTitle: "concentrator-mbus",
      color: "rgba(67, 160, 71, 1)",
    },
    // Sensor de fugas
    LEAK_SENSOR: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "leakSensor turquoiseIcon",
      markerTitle: "sensor",
      markerZindex: 560,
      tooltipTitle: "sensor",
    },
    // Alarma de sensor
    SENSOR_WARNING: {
      iconHtml: "<i class='fas fa-exclamation-triangle fa-lg'></i>",
      iconClasses: "leakSensor warningSensor yellowIcon",
      markerTitle: "sensor",
      markerZindex: 580,
      tooltipTitle: "sensor",
    },
    // Alarma de sensor
    SENSOR_FLOW: {
      iconUrl: "assets/img/pipe.png",
      iconClasses: "flowSensor",
      markerTitle: "sensor",
      markerZindex: 580,
      tooltipTitle: "sensor",
    },
    // NBIOT
    NBIOT: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceNbiot orangeIcon",
      markerTitle: "NBIOT",
      markerZindex: 570,
      color: "#854000",
    },
    // TXN
    TXN: {
      iconHtml: "<i class='fas fa-tint fa-2x'></i>",
      iconClasses: "deviceTxn grayIcon",
      markerTitle: "TXN",
      markerZindex: 570,
      tooltipTitle: "TXN",
      color: "GRAY",
    },
  },
};
