<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "fixed-devices" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <!-- Agrupación -->
    <app-material-select
      *ngIf="data?.mainMeters.length < 16"
      [title]="'devices' | translate"
      [fullWidth]="true"
      [multiple]="true"
      [clearable]="true"
      [bindValue]="'id'"
      [bindLabel]="'n'"
      [hint]="hint"
      [hintError]="hintError"
      [data]="devices"
      (selectedOption)="selectedDevices = $event; updateHint()"
      required
    ></app-material-select>
    <div *ngIf="data?.mainMeters.length >= 16">
      {{ "mbus-max-fixed-reached" | translate }}
    </div>
  </div>

  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="assignLwMbusPermanent()"
      [disabled]="
        !selectedDevices ||
        selectedDevices?.length == 0 ||
        data?.mainMeters.length > 16 ||
        data?.mainMeters.length + selectedDevices?.length > 16
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
