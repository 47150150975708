import { ExtraTableData } from "../modules/table-module/TableInterface.type";
import { GatewayOperator } from "../screens/dashboard/gateways/GatewayInterface.type";

export interface Gateway {
  // BackEnd
  amei: string;
  comments: string;
  comunica: boolean;
  entity: number;
  fwVersion: string;
  hwVersion: string;
  micro: number;
  options: number;
  banda: number;
  gatewayImage: string;
  id?: number;
  inMaintenance: boolean;
  installation: number;
  lastChargeReaded: number;
  lastCommunication: number;
  lastFile: {
    id: number;
    gateway: number;
    fileState: number;
    timestamp: number;
    // FrontEnd
    lastFileStateText?: string;
    timestampParsed?: string;
  };
  lastFileReceived: number;
  lastHello: number;
  lastLightMinutes: number;
  lastRssi: number;
  lastVbat: number;
  latitude: number;
  longitude: number;
  nroSerie: string;
  unidadVenta: string;
  nroRedundantContadores: number;
  nroMainContadores: number;
  ip: string;
  iccid: string;
  plmn?: string;
  operadora?: string;
  state: number;
  canales?: number;
  bbddFinalPointsError?: boolean;
  haveSnifferMeters?: boolean;
  
  // FrontEnd
  communicate?: boolean;
  installationParsed?: string;
  lastCommunicationParsed?: string;
  lastFileReceivedParsed?: string;
  lastVbatParsed?: string;
  lastHelloParsed?: string;
  gatewayImageUrl?: string;
  mainMeters?: number;
  redundantMeters?: number;
  totalMeters?: number;
  sfList?: Sf[];
  other?: boolean;
  microParsed?: string;
  bandaParsed?: string;
  norMemory?: boolean;
  mramQspi?: boolean;
  mramSpi?: boolean;
  gps?: boolean;
  lte?: boolean;
  localPort?: boolean;
  debug?: boolean;
  replace?: boolean;
  postInstallation?: boolean;
  endPoints?: number | string;
  gatewayLink?: string;
  deleteGateway?: boolean;
  stateParsed?: string;
  highlightNoActive?: boolean;
  canalesParsed?: number;
}

// Estados de gateway
export const GATEWAY_STATES = {
  // Gateway cargado en la plataforma pero no está asignado ni activado, no
  // tiene ni entidad ni coordenadas
  0: "in-stock",
  // Disponible, tiene entidad asociada pero no ha sido activado, así que carece de
  // coordenadas
  1: "available",
  // Activado, gateway asignado y activado, tiene entidad asignada y coordenadas
  2: "activated",
  // Averiado, estará asignado a una entidad pero puede tener ubicación o no
  3: "damaged",
  // En reparación, se ha mandado al servicio de reparación
  4: "repair",
  // Irrecuperable
  5: "discarded",
  IN_STOCK: 0,
  AVAILABLE: 1,
  ACTIVATED: 2,
  DAMAGED: 3,
  REPAIR: 4,
  DISCARDED: 5,
};

export interface Sf {
  dataRate: number;
  percentage: number;
}

export interface GatewayMapMeter {
  // BackEnd
  id: number;
  nroSerie: string;
  longitude: string;
  latitude: string;
  lastRssi: number;
  lastDataRate: number;
  lastSnr: number;
  gwUnidadVenta: string;
  gateway: number;
  percentajeReadedFrames: number;
  fabricante: number;
  devType: number;
  metrologyType: number;
  operadoras?: GatewayOperator[];

  // FrontEnd
  main?: boolean;
  selected?: boolean;
}

export interface GatewayTableMeter {
  // BackEnd
  contadorId: number;
  contadorNroSerie: string;
  contadorSF: number;
  contadorRssi: number;
  contadorRssiTimestamp: number;
  contadorSnr: number;
  contadorFramePercentage: number;
  bestPercentage: number;
  metrologyType: number;
  mainGateway: {
    id: number;
    unidadVenta: string;
    dataRate: number;
    rssi: number;
    rssiTimestamp: number;
    snr: number;
  };
  redundantGateways: GatewayMinimal[];
  isBMode: boolean;

  // FrontEnd
  main?: boolean;
  selectDisabled?: boolean;
  contadorRssiTimestampParsed?: string;
  redundantGatewaysLength?: number;
  rssiGreaterThanMain?: boolean;
  rssiLowerThanMain?: boolean;
  bestPercentageParsed?: string;
  contadorFramePercentageParsed?: string;
  secondaryTableFlag?: boolean;
  extraTableData?: ExtraTableData;
  meterLink?: string;
  actionsDisabled?: boolean;
  refreshDisabled?: boolean;
}

export interface GatewayMinimal {
  // BackEnd
  id: number;
  unidadVenta: string;
  dataRate: number;
  rssi: number;
  rssiTimestamp: number;
  snr: number;
  state?: number;

  // FrontEnd
  main?: boolean;
  rssiTimestampParsed?: string;
  selectDisabled?: boolean;
  highlightCurrentGateway?: boolean;
  gatewayLink?: string;
  stateParsed?: string;
}

export interface AssignableMeter {
  // BackEnd
  nroSerie: string;
  radioId: number;
  id: number;
  latitude: number;
  longitude: number;
  tipo: number;
  metrologyType: number;

  // FrontEnd
  selected?: boolean;
  assignable?: boolean;
}

export interface NotAssignedGateway {
  id: number;
  amei: string;
  nroSerie: string;
  unidadVenta: string;
  fwVersion: string;
  longitude: number;
  latitude: number;
  lastRssi: number;
  lastVbat: number;
  lastHello: number;
  lastCommunication: number;
  lastChargeReaded: number;
  lastChargeReadedTotal: number;
  lastLightMinutes: number;
  lastLightMinutesTotal: number;
  lastFileReceived: number;
  installation: number;
  inMaintenance: boolean;
  entity: number;
  comments: string;
  comunica: boolean;
  imagePath: string;
}

export interface GatewayGraphData {
  vbats: number[][];
  charge: number[][];
  temperature: number[][];
  noise?: object[];
  gatewayId?: number;
}

export interface MapGateway {
  id: number;
  unidadVenta: string;
  longitude: string;
  latitude: string;
  comunica: boolean;
  contadoresPrincipal: string[];
  contadoresRedundante: string[];
  imagePath: string;
  haveImage: boolean;
  selected?: boolean;
}

export interface GatewayMongoGraphData {
  id: string;
  tm: number;
  gw: number;
  luz: number;
  carga: number;
  temp: number;
  vbat: number;
  parcial: number;
}

export interface GatewayDeleteResponse {
  gatewayUnidadVenta: string;
  gateway: number;
  meterNroSerie: string;
  meter: number;
  metrologyType: number;
  result: number;
  agrupation: string;
}

export interface GatewayOptions {
  mramQspi: boolean;
  mramSpi: boolean;
  gps: boolean;
  localPort: boolean;
  debug: boolean;
  lte: boolean;
}

export interface BestRedundantGateway {
  gatewayUnidadVenta: string;
  gateway: number;
  meterNroSerie: string;
  meter: number;
  metrologyType: number;
  result: number;
  agrupation: string;
}

export interface GatewayLocation {
  // Back End
  id: number;
  longitude?: number;
  latitude?: number;
  entity?: number;
  distance?: number;
  timestamp?: number;
  rssi?: number;
  snr?: number;
  createTimestamp?: number;

  // Front End
  postInstallation?: boolean;
  pertenece?: boolean;
  best?: boolean;
}

export interface GatewayLocationData {
  // Back End
  gatewayId: number;
  unidadVenta: string;
  timestamp: number;

  // Front End
  timestampParsed: string;
}

export const GATEWAY_ACTIVATE_STATES = {
  // El contador no estï¿½ activado, esto quiere decir que se ha dado de alta por
  // primera vez y estï¿½ a la espera de ser geolocalizado
  NOT_ACTIVATED: 0,
  // El contador estï¿½ desactivado, esto quiere decir que en algun momento ha
  // estado activado pero se ha desactivado
  DISABLED: 1,
  // El contador funciona correctamente
  ACTIVATED: 2,
  // El contaodor no comunica
  NOT_COMUNICATE: 3,
  // Recibimos tramas del contador pero no tenemos datos, esto puede ser porque
  // solo lleguen tramas que no podemos parsear porque sean privadas del
  // fabricante
  NOT_DATA_RECEIVED: 4, //<-- SOLO SE CONSULTA. NUNCA SE INSERTA EN BBDD
  // El contador llega con error en el MIC al enviar un DATA UP, pero no llega en
  // todos los Gateways, solo en alguno de ellos
  DATA_UP_ERROR_ANY: 5,
  // El contador llega con error en el MIC al enviar un DATA UP en todos los
  // Gateways desde los que se recibe
  DATA_UP_ERROR_ALL: 6,
  // El contador solo envï¿½a Joins, por lo que quiere decir que no estamos
  // consiguiendo hacer el join correctamente
  JOIN_NOT_SUCCESS: 7,
  // Error en el MIC al hacer el Join
  JOIN_MIC_ERROR: 8,
  0: "GatewayActivateState0",
  1: "GatewayActivateState1",
  2: "GatewayActivateState2",
  3: "GatewayActivateState3",
  4: "GatewayActivateState4",
  5: "GatewayActivateState5",
  6: "GatewayActivateState6",
  7: "GatewayActivateState7",
  8: "GatewayActivateState8",
};
