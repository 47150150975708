<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "deactivate" | translate }}: {{ currentAgrupation?.name }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
          </h4>
          <h5 class="meter-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileChange)="devicesNotFound = null; readFile($event)"
            (fileReady)="deactivateFile()"
          ></app-file-import>
        </div>

        <app-import-table [importColumns]="importColumns"> </app-import-table>

        <div
          *ngIf="devicesNotFound?.length > 0"
          class="device-import-not-found"
        >
          <b>{{ "readings-device-error" | translate }}:</b>
          <div>
            <span *ngFor="let device of devicesNotFound"> {{ device }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
