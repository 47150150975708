<div class="navbar-top-search-container" (mouseleave)="searchActive = false">
  <div class="navbar-top-search-border">
    <div class="navbar-top-search-box">
      <!-- Filtro de contadores/CUPS -->
      <div class="navbar-top-search-filter">
        <i class="fas fa-search navbar-top-search-icon"></i>
        <input
          #searchFilter
          class="navbar-top-search-input"
          [ngClass]="{ disabled: loading }"
          type="text"
          id="searchFilter"
          name="searchFilter"
          autocomplete="off"
          (focus)="searchActive = true; setSearchDropdownHeight()"
          (click)="searchActive = true"
          (keyup)="
            searchActive = true;
            dropdownFilter($event.target.value, 'search', cupsSelected)
          "
          (keyup.enter)="checkDeviceToGo($event.target.value, cupsSelected)"
          placeholder="{{ searchOptionSelected }}..."
        />
        <i
          *ngIf="!searchActive"
          class="fas fa-caret-down navbar-top-search-caret"
          (click)="setSearchDropdownHeight(); searchActive = true"
        ></i>
        <i
          *ngIf="searchActive"
          class="fas fa-caret-up navbar-top-search-caret"
          (click)="searchActive = false"
        ></i>
      </div>
      <!-- Opciones de búsqueda -->
      <div *ngIf="currentCupsName" class="navbar-top-search-options">
        <!-- Dispositivo -->
        <input
          #searchDeviceOption
          class="pointer"
          type="radio"
          id="searchDeviceOption"
          name="searchOptions"
          [value]="false"
          [(ngModel)]="cupsSelected"
          (change)="resetSearchBox(type)"
        />
        <label
          class="pointer"
          for="searchDeviceOption"
          (click)="searchDeviceOption.click()"
          >{{ "device" | translate }}</label
        >
        <!-- CUPS -->
        <input
          #searchCupsOption
          class="pointer"
          type="radio"
          id="searchCupsOption"
          name="searchOptions"
          [value]="true"
          [(ngModel)]="cupsSelected"
          (change)="resetSearchBox(type)"
        />
        <label
          class="pointer"
          for="searchCupsOption"
          (click)="searchCupsOption.click()"
          >{{ currentCupsName }}</label
        >
      </div>
      <div #searchDropdown class="navbar-top-search-dropdown">
        <!-- Selector de contadores -->
        <div
          #metersListContainer
          *ngIf="!cupsSelected && searchActive && _meterList?.length > 0"
          class="navbar-top-search-select"
        >
          <cdk-virtual-scroll-viewport
            #metersScroll
            itemSize="10"
            class="navbar-top-virtual-scroller"
          >
            <div
              *cdkVirtualFor="let meter of _meterList"
              (click)="goToSelection(cupsSelected, meter); resetSearchBox(type)"
            >
              <p class="navbar-top-search-option">{{ meter.descripcion }}</p>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
        <!-- Selector de CUPS -->
        <div
          #cupsListContainer
          *ngIf="cupsSelected && searchActive && _cupsList?.length > 0"
          class="navbar-top-search-select"
        >
          <cdk-virtual-scroll-viewport
            #cupsScroll
            itemSize="10"
            class="navbar-top-virtual-scroller"
          >
            <div
              *ngFor="let cups of _cupsList"
              (click)="goToSelection(cupsSelected, cups); resetSearchBox(type)"
            >
              <p class="navbar-top-search-option">{{ cups.descripcion }}</p>
            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>
    </div>
  </div>
</div>
