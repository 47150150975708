<!-- Modal de cambio de agrupación -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "change-agrupation" | translate }}</h5>
  </div>
  <div class="dialog-body change-agrupation-dialog">
    <app-material-select
      [disabled]="updating"
      [filter]="true"
      [title]="('agrupations' | translate)"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [autofocus]="true"
      [data]="data.agrupationList"
      (selectedOption)="data.selectedAgrupation = $event"
      [required]="true"
    ></app-material-select>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="data.selectedAgrupation == null"
      (click)="changeAgrupation()"
    >
      <span *ngIf="!updating">
        <i class="fas fa-save"></i>
        {{ "save" | translate }}
      </span>
      <span *ngIf="updating">
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
