// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { UserControllerService } from "../../../../services/server/UserController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  UserResponse,
  UserResponseCups,
  UserImportTableData,
} from "../UserInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ImportColumn } from "../../../../modules/table-module/TableInterface.type";

@Component({
  selector: "app-userimport",
  templateUrl: "./users-import.component.html",
  styleUrls: ["./users-import.component.scss"],
})
export class UsersImport implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  currentAgrupation: Agrupation;
  sessionProfile: string;
  currentUser: string;
  entitySub: Subscription;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;

  // CUPS
  cupsName: string;
  cupsLabel: string;

  // Archivo
  importColumns: ImportColumn[];

  // Tabla
  tableData: UserImportTableData[];
  showTable: boolean = false;
  orderBy: object = { attribute: "state", reverse: false };
  exportFileName: string =
    this.translate.instant("import-result") + " " + this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "state",
      data: "state",
      search: "state",
      sort: "state",
      alter: {
        condition: "state",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "username-import",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeText",
      search: "responseCodeText",
      sort: "responseCodeText",
      extraInfo: true,
      visible: true,
    },
  ];

  // Errores posibles en archivo de datos
  fileErrors: object = {
    0: " ",
    1000: "unauthorized",
    1001: "aquacity-groups",
    1002: "user-already",
    1003: "user-not-found",
    1004: "wrong-email",
    1005: "current-password-not-found",
    1006: "new-password-not-found",
    1007: "username-not-found",
    1008: "wrong-username",
    1009: "wrong-password",
    1010: "missing-entity",
    1011: "wrong-language",
    1012: "pass-unauthorized",
    1013: "client-not-infomed",
    1014: "wrong-client",
    1015: "cups-incorrect",
    1016: "header-username-not-found",
    1017: "header-name-not-found",
    1018: "header-surname-not-found",
    1019: "header-password-not-found",
    1020: "header-cups-not-found",
    1021: "cups-not-found",
    1022: "username-not-found",
    1023: "password-not-found",
    1024: "name-not-found",
    1025: "surname-not-found",
    1026: "wrong-language",
    1028: "user-cups-already",
  };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserController: UserControllerService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentUser = this.SessionDataService.getCurrentUser();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.getCupsData();

    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityCupsConf = this.SessionDataService
      .getEntityCupsConf()
      .subscribe((entityCupsConf) => {
        this.currentEntityCupsConf = entityCupsConf;
        this.getCupsData();
      });
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.entityCupsConf.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del nombre y el label del CUPS de la entidad
  getCupsData(): void {
    this.cupsName = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.name;
    this.cupsLabel = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.label;
    this.getImportColumns();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = [
      {
        title: "USERNAME",
        info: this.translate.instant("username-import"),
        required: true,
        profile: true,
      },
      {
        title: "NAME",
        info: this.translate.instant("name-import"),
        required: true,
        profile: true,
      },
      {
        title: "SURNAME",
        info: this.translate.instant("surname-import"),
        required: true,
        profile: true,
      },
      {
        title: "PASSWORD",
        info: this.translate.instant("password-import"),
        required: true,
        profile: true,
      },
      {
        title: "CUPS",
        info: this.cupsName
          ? this.cupsName
          : this.translate.instant("cups-import"),
        required: true,
        profile: true,
      },
      {
        title: "LANGUAGE",
        info: this.translate.instant("language-import"),
        required: false,
        profile: true,
      },
    ];

    this.importColumns = importColumns;
  }

  // Importación de usuario
  importUser(file: File): void {
    let userFormData: FormData = new FormData();
    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file"),
        this.translate.instant("close")
      );
    } else {
      this.ToastService
        .fireAlertWithOptions(
          "warning",
          this.translate.instant("question-import")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            let tableData: UserImportTableData[] = [];
            let errorData: string;
            userFormData.set("file", file);

            this.UserController.importSubscriber(
              this.currentEntity.id,
              userFormData
            ).subscribe((response) => {
              if (response["code"] == 0 || response["code"] == 1) {
                response["body"]?.forEach((user: UserResponse) => {
                  user.responseCodeText = this.fileErrors[user.responseCode]
                    ? this.translate.instant(this.fileErrors[user.responseCode])
                    : this.translate.instant("error-text") +
                      " " +
                      user.responseCode;
                  user.state = user.responseCode == 0 ? true : false;

                  if (user.responseCode == 1028) {
                    let alreadyAssignedCups: UserResponseCups[] =
                      user.cupsErrorList.alreadyAssignedCups;
                    errorData = alreadyAssignedCups
                      ?.map(
                        (cups: UserResponseCups) =>
                          cups.claveMastra +
                          " (" +
                          this.translate.instant("profiles11") +
                          ": " +
                          cups.username +
                          ")"
                      )
                      ?.join(", ");
                  }

                  tableData.push({
                    username: user.username,
                    responseCodeText: user.responseCodeText,
                    errorCode: user.responseCode,
                    state: user.state,
                    extraInfoData: errorData,
                    showExtraInfo: errorData ? true : false,
                  });
                });
              }

              if (response["code"] == 0) {
                this.ToastService.fireToastWithConfirmation(
                  "success",
                  this.translate.instant("success-import")
                );
              } else if (response["code"] == 1 && response["body"]) {
                this.ToastService.fireToastWithConfirmation(
                  "warning",
                  this.translate.instant("some-user-error")
                );
              }

              this.showTable = tableData.length > 0 ? true : false;
              if (this.showTable) {
                setTimeout(
                  () => this.viewportScroller.scrollToAnchor("import-errors"),
                  0
                );
              }
              this.tableData = tableData;
            });
          }
        });
    }
  }
}
