import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";

@Component({
  selector: "app-slider-button",
  templateUrl: "./slider-button.component.html",
  styleUrls: ["./slider-button.component.scss"],
})
export class SliderButtonComponent implements OnInit, AfterViewInit {
  @Input() status: boolean;
  @Input() round: boolean;
  @Input()
  get width(): number {
    return this._width;
  }
  set width(width: number) {
    this._width = width;
    this.switch?.nativeElement?.style?.setProperty(
      "--switch-width",
      this._width
    );
  }
  _width: number;
  @Input()
  get height(): number {
    return this._height;
  }
  set height(height: number) {
    this._height = height;
    this.switch?.nativeElement?.style?.setProperty(
      "--switch-height",
      this._height
    );
  }
  _height: number;
  @ViewChild("switch") switch: ElementRef;
  @Output() statusUpdate = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.switch.nativeElement.style.setProperty("--switch-width", this._width);
    this.switch.nativeElement.style.setProperty("--switch-height", this._height);
  }
}
