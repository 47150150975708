// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { EntityControllerService } from "../../../../services/server/EntityController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityListEntity } from "../EntityInterface.type";
// Componentes
import { EntityDialogComponent } from "../entity-dialog/entity-dialog.component";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-entitylist",
  templateUrl: "./entity-list.component.html",
  styleUrls: ["./entity-list.component.scss"],
})
export class EntityListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;

  // Botón nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-entity");

  // Tabla
  entityList: EntityListEntity[];
  exportFileName: string =
    this.translate.instant("entity-export") + " " + moment().format("ll");
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "highlightEntity", color: "green", title: "current-entity" },
  ];
  columns: (TableActionColumn | TableDataColumn)[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EntityController: EntityControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en los valores de entidad y cliente
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      this.currentClient = client;
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.clientSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
    this.setColumns();
  }

  // Obtención de la lista de entidades
  getData(): void {
    this.EntityController.getEntityList().subscribe((response) => {
      if (response["code"] == 0) {
        let entityList: {
          clientName: string;
          comments: string;
          descripcion: string;
          id: number;
        }[] = response["body"];
        this.entityList = entityList
          .filter(
            (entity: EntityListEntity) =>
              entity.clientName == this.currentClient.clientName
          )
          .map((entity: EntityListEntity) => {
            entity.highlightEntity =
              entity.id == this.currentEntity.id ? true : false;
            return entity;
          });
      } else {
        this.entityList = [];
      }
    });
  }

  // Seteo de columnas
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "edit-entity",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: {
              attribute: null,
              rule: true,
            },
            disabled: false,
          },
          {
            name: "delete-entity",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: {
              attribute: null,
              rule: this.sessionProfile == PROFILES.ARSON ? true : null,
            },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "entity",
        data: "descripcion",
        search: "descripcion",
        sort: "descripcion",
        visible: true,
      },
      {
        title: "alias",
        data: "alias",
        search: "alias",
        sort: "alias",
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, entity: EntityListEntity): void {
    switch (action) {
      case "edit-entity":
        if (this.sessionProfile == PROFILES.ARSON) {
          this.router.navigate(["/entidades/formulario/editar/" + entity.id]);
        } else {
          this.MaterialDialogService.openDialog(EntityDialogComponent, {
            ...entity,
          });
        }
        break;
      case "delete-entity":
        this.deleteEntity(entity);
        break;
      default:
        break;
    }
  }

  // Redirección a creación de entidad
  newAgrupation(): void {
    this.router.navigate(["/entidades/formulario/nuevo"]);
  }

  // Borrado de entidad
  deleteEntity(entity: EntityListEntity): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-entity-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.EntityController.deleteEntity(entity.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("entity-deleted")
            );
            if (entity.id == this.currentEntity.id) {
              this.SessionDataService.sendEntity(
                this.SessionDataService.getCurrentClient().entityList.find(
                  (clientEntity: Entity) => clientEntity.id != entity.id
                )
              );
            }
            this.SessionDataService.clearUpdateNavFlag();
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }
}
