// Dispositivo de mapa
export interface MapDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  longitude: string;
  latitude: string;
  lastRssi: number;
  lastDataRate: number;
  lastSnr: number;
  unidadVentaGw: string;
  comunica: boolean;
  alarm: boolean;
  alarmList: MapDeviceAlarm[];
  alarmCodes?: number[];
  lastCommunication: number;
  imagePath: string;
  haveImage: boolean;
  fabricante: number;
  devType: number;
  claveMaestraId: number;
  claveMaestraKey: string;
  isAssignedCups: boolean;
  metrologyType: number;
  tipo: number;
  listaConcentradores: {
    idConcentrador: number;
    nroSerieConcentrador: string;
  }[];
  installation: number;
  agrupation: number;
  hasAs?: boolean;
  distanciaAcustica?: number;

  // FrontEnd
  selected?: boolean;
  metrologyTypeParsed?: string;
  lastCommunicationParsed?: string;
  deviceLink?: string;
  gatewayLink?: string;
  isConcentrator?: boolean;
  graphData?: any;
  showGraph?: boolean;
  outliers?: string;
  extraRange?: number;
  flow?: number;
  flowPercentage?: number | string;
  filter?: string;
  ignoreCoverValue?: boolean;
}

// Alarmas de dispositivo de mapa
export interface MapDeviceAlarm {
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
}

export const METROLOGY_TYPE = {
  0: "meter",
  1: "sensor",
  2: "gas-meter",
  3: "concentrator-une",
  4: "satelite",
  5: "concentrator-mbus",
  6: "acoustic-sensor",
  7: "water-valve",
  WATER: 0,
  SENSOR: 1,
  GAS: 2,
  UNE_CONCENTRATOR: 3,
  SATELITE: 4,
  MBUS_CONCENTRATOR: 5,
  ACOUSTIC_SENSOR: 6,
  WATER_VALVE: 7,
};

// Dispositivo de mapa minimizado para optimizar la descarga
export interface MapDeviceMinimal {
  id: number;
  // Número de serie
  ns: string;
  // Longitud
  lg: string;
  // Latitud
  lt: string;
  // Last rssi
  lr: number;
  // Last data rate
  ld: number;
  // Last snr
  ls: number;
  // Unidad venta gw
  uv: string;
  // Comunica
  c: boolean;
  // Alarm
  a: boolean;
  // Last comunication
  lc: number;
  // Fabricante
  f: number;
  // DevType
  d: number;
  // MetrologyType
  mt: number;
  // Tipo
  t: number;
  // Agrupation
  ag: number;
  // Sensor
  ha: boolean;
  // Rango de sensor
  da: number;
  // Códigos de alarma
  cal?: number[];
}

// Datos de tooltip para servicio minimizado
export interface MapDeviceTooltip {
  // Número de serie
  ns: string;
  // AlarmList
  al: any[];
  // Image path
  ip: string;
  // HaveImage
  hi: boolean;
  // Clave maestra id
  cm: number;
  // Clave maestra key
  ck: string;
  // isAssignedCups
  ac: boolean;
  // Listado de concentradores
  cl: {
    // Número serie concentrador
    nc: string;
    // Id concentrador
    ic: number;
  }[];
  // Installation
  i: number;
  // Fabricante
  f: number;
  // DevType
  d: number;
}
