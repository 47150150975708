// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { NetworkStateControllerService } from "../../../../services/server/NetworkStateController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
// Componentes
import { DeviceTypeFilterComponent } from "../../devices/devices-common-components/device-type-filter/device-type-filter.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  KpisCardsData,
  KpisCards,
  KpisGraphData,
  KpisGraphSerie,
} from "../NetworkStateInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-network-state-kpis",
  templateUrl: "./network-state-kpis.component.html",
  styleUrls: ["./network-state-kpis.component.scss"],
})
export class NetworkStateKpisComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  sessionLanguage: string;

  // Variables de las tarjetas
  cardsData: KpisCards;
  kpisData: KpisCardsData;
  entityKpisData: KpisCardsData;

  // Gráfica
  agrupationSeries: any;
  cardsAgrupationSeries: any;
  graphSeries: object[];
  graphData: KpisGraphData[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  lastFiveDaysSerie: any;
  lastDaySerie: any;
  hourlySerie: any;
  allowedDevices: { id: number; devTypes: number[] }[];
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  graphInitiated: boolean = false;
  lastFiveDaysActive: boolean = true;
  lastDayActive: boolean = false;
  hourlyActive: boolean = false;
  yAxisMinRange: number = 0;

  // Agrupación, fabricante y tipo
  agrupationList: Agrupation[] = [];
  selectedAgrupation: number = -1;
  selectedManufacturer: string;
  selectedModel: { manufacturerId: string; device: string };
  @ViewChild(DeviceTypeFilterComponent)
  deviceTypeFilter: DeviceTypeFilterComponent;
  agrupationPreselection: number = -1;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GraphOptionsService: GraphOptionsService,
    private NetworkStateController: NetworkStateControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    if (history.state?.data?.agrupationId) {
      this.agrupationPreselection = history.state.data.agrupationId;
      this.selectedAgrupation = history.state.data.agrupationId;
    }
    this.currentEntity.agrupations.forEach((agrupation: Agrupation) => {
      if (!agrupation.showAllEntity) {
        this.agrupationList.push(agrupation);
      }
    });
    this.agrupationList.sort((a, b) => a.name.localeCompare(b.name));
    // Todas las agrupaciones
    this.agrupationList.unshift({
      entity: null,
      id: -2,
      name: this.translate.instant("kpis-all"),
      showAllEntity: null,
      timezone: null,
    });
    // Totales
    this.agrupationList.unshift({
      entity: null,
      id: -1,
      name: this.translate.instant("kpis-total"),
      showAllEntity: null,
      timezone: null,
    });

    this.setHighchartsOptions();
  }

  // Obtención de los datos de las tarjetas
  getCardsData(): void {
    if (this.selectedAgrupation > 0) {
      this.NetworkStateController.getKpisCards(
        this.selectedAgrupation
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.kpisData = response["body"];
          this.getCards();
        }
      });
    } else if (this.selectedAgrupation == -1) {
      this.kpisData = { ...this.entityKpisData };
      this.getCards();
    } else if (this.selectedAgrupation == -2) {
      this.kpisData = {
        lastDayAverage: null,
        lastFiveDaysAverage: null,
        readingsLastDayAverage: null,
        timestamp: null,
      };
      this.getCards();
    }
  }

  // Generación de las tarjetas
  getCards(): void {
    this.getCardsGraphs();
    this.cardsData = {
      // Últimos 5 días
      kpisLastFiveDaysAverage: {
        data: this.kpisData?.lastFiveDaysAverage,
        date: this.kpisData?.timestamp,
        type: "%",
        graph: { series: this.lastFiveDaysSerie, min: 0, max: 100 },
      },
      // Último día
      kpisDayAverage: {
        data: this.kpisData?.lastDayAverage,
        date: this.kpisData?.timestamp,
        type: "%",
        graph: { series: this.lastDaySerie, min: 0, max: 100 },
      },
      // Horaria
      kpisHourlyAverage: {
        data: this.kpisData?.readingsLastDayAverage,
        date: this.kpisData?.timestamp,
        type: "%",
        graph: { series: this.hourlySerie, min: 0, max: 100 },
      },
    };
  }

  // Obtención de las gráficas de las tarjetas
  getCardsGraphs(): void {
    // Series
    let tooltip: any = {
      pointFormatter: function () {
        return (
          `<span style="color:` +
          this.color +
          `">` +
          this.series.name +
          `</span>: <b>` +
          Highcharts.numberFormat(this.y, 2) +
          `%</b>`
        );
      },
    };
    // Serie de últimos 5 días
    this.lastFiveDaysSerie = [
      {
        id: "lastFiveDaysSerie",
        name: this.translate.instant("kpis-last-five-days"),
        data: this.cardsAgrupationSeries[this.selectedAgrupation]
          ?.lastFiveDaysSerie,
        tooltip: tooltip,
      },
    ];
    // Serie de último día
    this.lastDaySerie = [
      {
        id: "lastDaySerie",
        name: this.translate.instant("kpis-day"),
        data: this.cardsAgrupationSeries[this.selectedAgrupation]?.lastDaySerie,
        tooltip: tooltip,
      },
    ];
    // Serie de valores horarios
    this.hourlySerie = [
      {
        id: "hourlySerie",
        name: this.translate.instant("kpis-hourly"),
        data: this.cardsAgrupationSeries[this.selectedAgrupation]?.hourlySerie,
        tooltip: tooltip,
      },
    ];
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.NetworkStateController.getKpisGraphByEntity(
      this.currentEntity.id,
      from,
      to
    ).subscribe((response) => {
      let graphData: KpisGraphData[] = [];
      if (response["code"] == 0 && response["body"]) {
        graphData = response["body"];
      }
      this.graphData = graphData;
      this.getAgrupationSeries(this.graphData);
    });
  }

  // Obtención de las series de agrupaciones
  getAgrupationSeries(graphData: KpisGraphData[]): void {
    let agrupationSeries: object = {};
    let cardsAgrupationSeries: object = {};
    let allowedDevices: { id: number; devTypes: number[] }[] = [];

    graphData?.forEach((data: KpisGraphData) => {
      // Datos de cada serie por agrupación, filtrando por fabricante y modelo
      if (
        (data.fabricante == parseInt(this.selectedManufacturer) &&
          data.devType == parseInt(this.selectedModel?.device)) ||
        (data.fabricante == null &&
          this.selectedManufacturer == null &&
          data.devType == null &&
          this.selectedModel == null) ||
        (data.fabricante == parseInt(this.selectedManufacturer) &&
          this.selectedModel == null)
      ) {
        if (!agrupationSeries[data.agrupation]) {
          agrupationSeries[data.agrupation] = {
            lastFiveDaysSerie: [],
            lastDaySerie: [],
            hourlySerie: [],
          };
        }

        // Últimos 5 días
        agrupationSeries[data.agrupation].lastFiveDaysSerie.push([
          data.timestamp,
          data.lastFiveDaysAverage,
          data.metersReadedLastFiveDays,
          data.totalMetersLastFiveDays,
        ]);
        // Último día
        agrupationSeries[data.agrupation].lastDaySerie.push([
          data.timestamp,
          data.lastDayAverage,
          data.metersReadedLastDay,
          data.totalMetersLastDay,
        ]);
        // Horaria
        agrupationSeries[data.agrupation].hourlySerie.push([
          data.timestamp,
          data.readingsLastDayAverage,
          data.readingsLastDay,
          data.totalReadingsLastDayExpected,
        ]);
      }

      // Datos de series para las tarjetas que solo se calculan al cargar la gráfica inicial
      if (
        !this.graphInitiated &&
        data.fabricante == null &&
        data.devType == null
      ) {
        if (!cardsAgrupationSeries[data.agrupation]) {
          cardsAgrupationSeries[data.agrupation] = {
            lastFiveDaysSerie: [],
            lastDaySerie: [],
            hourlySerie: [],
          };
        }

        // Últimos 5 días
        cardsAgrupationSeries[data.agrupation].lastFiveDaysSerie.push([
          data.timestamp,
          data.lastFiveDaysAverage,
        ]);
        // Último día
        cardsAgrupationSeries[data.agrupation].lastDaySerie.push([
          data.timestamp,
          data.lastDayAverage,
        ]);
        // Horaria
        cardsAgrupationSeries[data.agrupation].hourlySerie.push([
          data.timestamp,
          data.readingsLastDayAverage,
        ]);
      }

      // Comprobación de modelos existentes para los desplegables de filtrado
      if (
        data.agrupation == this.selectedAgrupation ||
        this.selectedAgrupation < 0
      ) {
        let manufacturerFound = allowedDevices.find(
          (manufacturer: { id: number; devTypes: number[] }) =>
            manufacturer.id == data.fabricante
        );
        if (manufacturerFound) {
          if (
            !manufacturerFound.devTypes.some(
              (dev: number) => dev == data.devType
            )
          ) {
            manufacturerFound.devTypes.push(data.devType);
          }
        } else if (data.fabricante != null && data.devType != null) {
          allowedDevices.push({
            id: data.fabricante,
            devTypes: [data.devType],
          });
        }
      }
    });

    if (!this.graphInitiated) {
      // Series de tarjetas
      this.cardsAgrupationSeries = cardsAgrupationSeries;
    }

    if (this.selectedAgrupation == -1) {
      // Serie total de entidad
      agrupationSeries["-1"] = this.getTotalSeries(agrupationSeries);
    }

    this.allowedDevices = allowedDevices;
    this.agrupationSeries = agrupationSeries;
    this.getSeries(agrupationSeries);
    this.getCardsData();
  }

  // Obtención de las series
  getSeries(agrupationSeries: any): void {
    const self = this;
    let series: object[] = [];

    // 3 series por agrupación
    for (let agrupation in agrupationSeries) {
      let agrupationName = this.agrupationList.find(
        (agrupationListItem: Agrupation) =>
          agrupationListItem.id == parseInt(agrupation)
      )?.name;
      let seriesType = [
        {
          type: "lastFiveDays",
          name:
            "<b>" +
            agrupationName +
            "</b> | " +
            this.translate.instant("kpis-last-five-days"),
          serie: "lastFiveDaysSerie",
        },
        {
          type: "lastDay",
          name:
            "<b>" +
            agrupationName +
            "</b> | " +
            this.translate.instant("kpis-day"),
          serie: "lastDaySerie",
        },
        {
          type: "hourly",
          name:
            "<b>" +
            agrupationName +
            "</b> | " +
            this.translate.instant("kpis-hourly"),
          serie: "hourlySerie",
        },
      ];

      seriesType.forEach(
        (serieType: { type: string; name: string; serie: string }) => {
          if (
            (this.selectedAgrupation == -2 ||
              parseInt(agrupation) == this.selectedAgrupation) &&
            ((serieType.type == "lastFiveDays" && this.lastFiveDaysActive) ||
              (serieType.type == "lastDay" && this.lastDayActive) ||
              (serieType.type == "hourly" && this.hourlyActive))
          ) {
            series.push({
              id: serieType.name,
              name: serieType.name,
              tooltip: {
                valueDecimals: 3,
                pointFormatter: function () {
                  return (
                    `<span style="color:` +
                    this.color +
                    `">` +
                    this.series.name +
                    "</span>: <b>" +
                    Highcharts.numberFormat(this.y, 2) +
                    `%</b> | 
                        <span style="color:` +
                    this.color +
                    `"> ` +
                    self.translate.instant("devices") +
                    "</span>: <b>" +
                    Highcharts.numberFormat(
                      this.series.options.data[this.index][2],
                      0
                    ) +
                    `</b> / <b>` +
                    Highcharts.numberFormat(
                      this.series.options.data[this.index][3],
                      0
                    ) +
                    "</b>"
                  );
                },
              },
              data: agrupationSeries[agrupation][serieType.serie],
              dataGrouping: { approximation: "sum" },
            });
          }
        }
      );
    }

    this.graphSeries = series;
    this.setChartOptions();
  }

  // Obtención de la serie total
  getTotalSeries(agrupationSeries: object): KpisGraphSerie {
    let totalSerie: KpisGraphSerie = {
      lastFiveDaysSerie: [],
      lastDaySerie: [],
      hourlySerie: [],
    };

    // Suma de todos los valores de cada serie para cada timestamp
    for (let agrupation in agrupationSeries) {
      if (parseInt(agrupation) >= 0) {
        for (let serieType in totalSerie) {
          agrupationSeries[agrupation][serieType].forEach(
            (serieData: number[]) => {
              let timestampIndex =
                totalSerie[serieType].length > 0
                  ? totalSerie[serieType].findIndex(
                      (data: number[]) => data[0] == serieData[0]
                    )
                  : -1;
              if (timestampIndex >= 0) {
                totalSerie[serieType][timestampIndex][2] += serieData[2];
                totalSerie[serieType][timestampIndex][3] += serieData[3];
              } else {
                totalSerie[serieType].push([
                  serieData[0],
                  0,
                  serieData[2],
                  serieData[3],
                ]);
              }
            }
          );
          totalSerie[serieType].sort((a, b) => a[0] - b[0]);
        }
      }
    }

    // Cálculo del valor porcentual del total
    let maxTimestamp: number = null;
    for (let serieType in totalSerie) {
      totalSerie[serieType] = totalSerie[serieType].map((data: number[]) => {
        data[1] = parseFloat(((data[2] / data[3]) * 100).toFixed(2));
        if (!maxTimestamp || maxTimestamp < data[0]) {
          maxTimestamp = data[0];
        }
        return data;
      });
    }

    if (!this.graphInitiated) {
      // Valores de tarjeta de entidad
      this.entityKpisData = {
        lastFiveDaysAverage: totalSerie["lastFiveDaysSerie"].find(
          (data: number[]) => data[0] == maxTimestamp
        )[1],
        lastDayAverage: totalSerie["lastDaySerie"].find(
          (data: number[]) => data[0] == maxTimestamp
        )[1],
        readingsLastDayAverage: totalSerie["hourlySerie"].find(
          (data: number[]) => data[0] == maxTimestamp
        )[1],
        timestamp: maxTimestamp,
      };

      // Gráficas de tarjeta de entidad
      this.cardsAgrupationSeries["-1"] = {
        lastFiveDaysSerie: totalSerie["lastFiveDaysSerie"],
        lastDaySerie: totalSerie["lastDaySerie"],
        hourlySerie: totalSerie["hourlySerie"],
      };
      this.graphInitiated = true;
    }

    return totalSerie;
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["series"] = this.graphSeries;
    chartOptions["rangeSelector"].allButtonsEnabled = false;
    chartOptions["yAxis"] = [
      {
        opposite: false,
        labels: {
          enabled: true,
          formatter: function () {
            return Highcharts.numberFormat(this.value, -1) + "%";
          },
        },
        min: 0,
        max: 100,
        visible: true,
      },
    ];
    this.chartOptions = chartOptions;
  }

  // Actualización de gráfica por dispositivo seleccionado
  updateGraph(): void {
    if (
      (this.selectedManufacturer != null && this.selectedModel != null) ||
      (this.selectedManufacturer == null && this.selectedModel == null)
    ) {
      this.getAgrupationSeries(this.graphData);
    }
  }

  // Reseteo de los filtros de fabricante y tipo
  resetFilters(): void {
    this.deviceTypeFilter.resetFilter();
    this.getSeries(this.graphData);
  }

  // Actualización de las series activas
  updateSeriesActive(
    lastFiveDayasActive: boolean,
    lastDayActive: boolean,
    hourlyActive: boolean
  ): void {
    this.lastFiveDaysActive = lastFiveDayasActive;
    this.lastDayActive = lastDayActive;
    this.hourlyActive = hourlyActive;
  }
}
