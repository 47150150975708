// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import {
  COMM_BIT_Y_DEVICE,
  DeviceTypeService,
} from "../../../../../services/shared/DeviceTypeService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../MeterService.service";
import { SuspicionsService } from "../../../../../services/shared/SuspicionsService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../../services/shared/DeviceRouteSelectorService.service";
// Componentes
import { DeviceInfoModalComponent } from "../../devices-common-components/device-info-modal/device-info-modal.component";
import { DeviceEditModalComponent } from "../../devices-common-components/device-edit-modal/device-edit-modal.component";
import { DeviceAgrupationDialogComponent } from "../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { Ek280ConfigurationComponent } from "../../ek-280/ek280-configuration/ek280-configuration.component";
import { Ek280TestComponent } from "../../ek-280/ek280-test/ek280-test.component";
import { DeviceSfUpdateComponent } from "../../devices-common-components/device-sf-update/device-sf-update.component";
import { SateliteModifyDialogComponent } from "../../satelite/satelite-modify-dialog/satelite-modify-dialog.component";
import { DeviceMoveReadingsDialogComponent } from "../../devices-common-components/device-move-readings-dialog/device-move-readings-dialog.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../../modules/material-module/MaterialInterface.type";
import {
  DetailDevice,
  DetailDeviceAlarm,
  DetailDeviceCardsData,
  DetailDeviceGateway,
  DetailDeviceStateCards,
  VALVE_STATES,
  ValveStateData,
} from "../../DeviceInterface.type";
import {
  ClaveMaestra,
  Cups,
  EntityDefinition,
} from "../../../../../interfaces/CupsGlobalInterface.type";
import { DeviceEk280, DeviceSim } from "../../DeviceInterface.type";
import { DeviceReplaceRfModalComponent } from "../../devices-common-components/device-replace-rf-modal/device-replace-rf-modal.component";
import { DeviceReplaceDialogComponent } from "../../devices-common-components/device-replace-dialog/device-replace-dialog.component";
import {
  LwMbusMainMeter,
  MapMbusMeter,
  MbusMeter,
  MbusMeterConcentrator,
} from "../../ConcentratorInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
// Variables
import { ENVIRONMENT } from "../../../../../../environments/environment";
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { GATEWAY_STATES } from "../../../../../interfaces/GatewayGlobalInterface.type";
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";
import { MANUFACTURER_INDEX } from "../../../../../../assets/manufacturers/MANUFACTURER_INDEX";
import { DeviceDeleteReadingsComponent } from "../../devices-common-components/device-delete-readings/device-delete-readings.component";

@Component({
  selector: "app-metersdetail",
  templateUrl: "./meter-detail.component.html",
  styleUrls: ["./meter-detail.component.scss"],
})
export class MetersDetailComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  meterAgrupation: string;
  entityList: Entity[];
  dateFormat: string;
  componentDataSub: Subscription;
  readonly PROFILES = PROFILES;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  // Contador
  meter: DetailDevice;
  meterTypeByMask: string;
  meterAlarms: DetailDeviceAlarm[];
  meterId: number;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;
  readonly MANUFACTURER_INDEX = MANUFACTURER_INDEX;

  // Tarjetas
  stateCardsData: DetailDeviceStateCards;
  meterCardsData: DetailDeviceCardsData;
  meterCardsOrder: string[] = [
    "meterDetailLastKnownValue",
    "meterDetailLastDataMonth",
    "meterDetailLastMonthConsume",
    "meterDetailConsumeMonthAverage",
    "meterDetailCurrentMonthConsume",
    "meterDetailConsumeAverageDaily",
    "meterDetailValve",
    "meterDetailMode",
    "meterDetailLastMinConsumption",
    "meterDetailLastMaxConsumption",
    "meterDetailLastTotalConsumption",
    "meterDetailBattery",
    "meterDetailUneFather",
    "meterDetailMbusFather",
    "meterPermanentLwMbus",
    "noiseData",
  ];
  valveCardsOrder: string[] = [
    "meterDetailLastKnownValue",
    "meterDetailSateliteValve",
    "meterDetailConsumeLastWeek",
    "meterDetailLastFlow",
    "meterDetailLastAverageFlow",
  ];
  valveInterval: any;
  valveTimer: number = 5000;

  // Tabla de CUPS asociados al contador
  meterCups: { columns: EntityDefinition[]; data: Cups };

  // Tabla de gateways asociados al contador
  meterGatewayList: DetailDeviceGateway[];
  otherEntityGatewayList: DetailDeviceGateway[] = [];

  // Gráfica
  valveGraphData: number[][];
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  graphEnabled: boolean = false;

  // Tabla de estados de válvula
  valveStateData: ValveStateData[];
  valveStateColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[] = [
    {
      title: "date",
      data: "timestamp",
      search: "timestamp",
      sort: "timestamp",
      visible: true,
      date: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
  ];

  // Mapa
  mapType: string = "meterDetail";
  mapGateways: DetailDeviceGateway[];
  mapMeter: (DetailDevice | MapMbusMeter)[];
  mapHeight: number = 400;
  initialZoom: number = 17;

  // Cambio de localización
  changeLocationActive: boolean = false;

  // Modales
  @ViewChild(DeviceInfoModalComponent)
  deviceInfoModal: DeviceInfoModalComponent;
  @ViewChild(DeviceEditModalComponent)
  deviceEditModal: DeviceEditModalComponent;
  stayOnRoute: boolean = false;

  // Modal de configuración de EK280
  ek280ConfigData: DeviceEk280;
  simList: DeviceSim[];

  // Tabla concentradores LW MBUS asociados
  mbusConcentratorsLinked: MbusMeterConcentrator[];
  mbusConentratorsSelected: MbusMeterConcentrator[];
  lwMbusColumns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "assign-permanent",
          tooltip: "assign-permanent",
          icon: "fas fa-exchange-alt",
          visible: { attribute: "main", rule: false },
          disabled: false,
        },
        {
          name: "unassign-permanent",
          tooltip: "unassign-permanent",
          icon: "fas fa-exchange-alt",
          visible: { attribute: "main", rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "permanent",
      data: "main",
      search: "main",
      sort: "main",
      alter: {
        condition: "main",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
      link: "concentratorLink",
    },
    {
      title: "date",
      data: "lastCommParsed",
      search: "lastCommParsed",
      sort: "lastComm",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "total-communications",
      data: "totalComms",
      search: "totalComms",
      sort: "totalComms",
      numerical: true,
      visible: true,
    },
  ];
  lwMbusTableOrderBy = { attribute: "main", reverse: true };
  actionsEnabled: boolean = true;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    public MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityList = this.SessionDataService.getCurrentEntityList();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity") && !this.stayOnRoute) {
        this.router.navigate(["/principal"]);
      }
    });

    this.componentDataSub =
      this.SessionDataService.getComponentData().subscribe((componentData) => {
        // Actualización de tarjetas
        if (componentData.updateCards) {
          this.getMeterCards();
        }
        // Actualización de alarmas
        if (componentData.meterAlarms) {
          this.meterAlarms = componentData.meterAlarms;
        }
        // Actualización de tarjetas de satélite
        if (componentData.updateValveCards) {
          this.getValveCards();
        }
        // Actualización de tabla de concentradores LW MBUS
        if (componentData.realoadLwMusTable) {
          this.getLwMbusMain();
        }
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.valveInterval);
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.componentDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meterId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getMeterData();
  }

  // Obtención de los datos de contadores
  getMeterData(): void {
    this.MeterController.main(
      this.meterId,
      this.DateParserService.getMomentDate(
        this.defaultDateRange.startDate,
        "x"
      ),
      this.DateParserService.getMomentDate(this.defaultDateRange.endDate, "x")
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.meter = response["body"]["contadorMain"];
        this.meter["initialState"] =
          response["body"]["contadorMain"]["valveState"];
        this.meter.installationParsed = this.DateParserService.parseDate(
          this.meter.installation,
          this.dateFormat + " HH:mm"
        );
        this.meter.redundantGateways = response["body"][
          "gatewayMeterMark"
        ].filter(
          (gateway: DetailDeviceGateway) =>
            gateway.unidadVenta != this.meter.gateway
        ).length;
        this.meterTypeByMask = this.DeviceTypeService.getDeviceTypeByMask(
          this.meter.tipo,
          this.meter.metrologyType,
          this.meter.idFabricante
        );
        // Alarmas activas
        let meterAlarms = response["body"]["activeAlarms"];
        if (meterAlarms != null) {
          meterAlarms.forEach((alarm: DetailDeviceAlarm) => {
            alarm.initDateParsed = this.DateParserService.parseDate(
              alarm.initDate,
              this.dateFormat + " HH:mm:ss"
            );
            alarm.code != null
              ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
              : "";
          });
        }
        this.meterAlarms =
          this.SessionDataService.getCurrentProfile() == PROFILES.ARSON &&
          this.SessionDataService.getCurrentSuspicionActive()
            ? meterAlarms
            : this.SuspicionsService.filterSuspicions(meterAlarms);
        // Máscara de tipo de contador
        this.meter.type =
          response["body"]["contadorMain"]["tipo"] != null
            ? response["body"]["contadorMain"]["tipo"].toString(2)
            : "1";
        this.meter.isLora =
          this.meter.type?.charAt(this.meter.type?.length - 1) == "1";
        // Comprobación de fabricante/tipo
        this.meter.isAddEnprotechValve =
          this.meter.idFabricante == MANUFACTURER_INDEX.ADDENPROTECH &&
          this.meter.idDevType == MANUFACTURER_INDEX.ADDENPROTECH_WM_VALVE;
        this.meter.isBMeter =
          this.meter.idFabricante == MANUFACTURER_INDEX.BMETER;
        // Tabla de CUPS
        this.meterCups = {
          columns: response["body"]["claveMaestraDefinitions"],
          data: response["body"]["claveMaestra"],
        };

        if (this.meterCups.data && this.meterCups.data.isAssigned !== undefined && this.meterCups.data.isAssigned !== null) {
          if (!this.meterCups.data.isAssigned) {
            this.meterCups.data.highLightRow = true;
          }
        }


        if (this.meter.activate < 2) {
          this.actionsEnabled = false;
        }
        // Tabla de gateways
        let meterGatewayList: DetailDeviceGateway[] =
          response["body"]["gatewayMeterMark"];
        meterGatewayList.forEach((gateway: DetailDeviceGateway) => {
          if (
            gateway.unidadVenta == response["body"]["contadorMain"]["gateway"]
          ) {
            gateway.principal = true;
          } else {
            gateway.principal = false;
          }
          gateway.idFabricante =
            response["body"]["contadorMain"]["idFabricante"];
          gateway.idMeter = response["body"]["contadorMain"]["id"];
          gateway.asignateDisabled =
            (gateway.principal &&
              gateway.idFabricante != MANUFACTURER_INDEX.ARSON) ||
            gateway.state != GATEWAY_STATES.ACTIVATED;
          gateway.stateParsed = gateway.state
            ? this.translate.instant(GATEWAY_STATES[gateway.state])
            : null;
          gateway.showNoncesDisabled =
            this.sessionProfile != PROFILES.ARSON ||
            gateway.state != GATEWAY_STATES.ACTIVATED;
          gateway.gatewayLink = "/gateways/detalle/gateway/" + gateway.id;
        });
        this.meterGatewayList =
          this.MeterService.sortMeterGatewayArray(meterGatewayList);
        // Mapa de contador
        this.otherEntityGatewayList = response["body"][
          "otherEntityGateways"
        ].sort((a: DetailDeviceGateway, b: DetailDeviceGateway) =>
          a.unidadVenta.localeCompare(b.unidadVenta)
        );
        if (this.sessionProfile == PROFILES.ARSON && this.meter.isLora) {
          this.mapGateways = this.meterGatewayList
            .map((gateway: DetailDeviceGateway) => {
              if (gateway) {
                gateway.otherEntity = false;
                gateway.unidadVenta == this.meter.gateway
                  ? (gateway.mainGateway = true)
                  : false;
                return gateway;
              }
            })
            .concat(
              response["body"]["otherEntityGateways"].map(
                (gateway: DetailDeviceGateway) => {
                  gateway.otherEntity = true;
                  return gateway;
                }
              )
            )
            .filter((gateway: DetailDeviceGateway) => gateway);
        } else {
          this.mapGateways = [];
        }

        // Contador Mbus hijo
        if (
          response["body"]["lwMBusComunicaciones"] &&
          this.meter.listaConcentradores?.length > 0 &&
          !this.meter.isLora
        ) {
          let mbusConcentrators = response["body"]["lwMBusComunicaciones"].map(
            (meter: MbusMeter) => {
              return {
                id: meter.elementoId,
                nroSerie: meter.elemento,
                rssi: meter.rssi,
                latitude: meter.latitude,
                longitude: meter.longitude,
                lastComm: meter.ultimaComunicacion,
                totalComms: meter.nroComunicaciones,
                tipo: this.DeviceTypeService.getCommTypeMaskByDevice([
                  COMM_BIT_Y_DEVICE.LORA,
                ]),
                metrologyType: METROLOGY_TYPE.MBUS_CONCENTRATOR,
                unidadVentaGw: "",
                comunica: meter.ultimaComunicacion != null ? true : false,
              };
            }
          );
          this.mbusConcentratorsLinked = mbusConcentrators
            .filter((concentrator: MbusMeterConcentrator) =>
              this.meter.listaConcentradores.some(
                (meterConcentrator) =>
                  meterConcentrator.idConcentrador == concentrator.id
              )
            )
            .map((concentrator: MbusMeterConcentrator) => {
              concentrator.concentratorLink =
                this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  METROLOGY_TYPE.MBUS_CONCENTRATOR,
                  concentrator.id
                );
              concentrator.main = false;
              return concentrator;
            });
          this.mapMeter = [this.meter, ...mbusConcentrators];
          this.getLwMbusMain();
        } else {
          this.mapMeter = [this.meter];
        }

        // Opciones del menú de componente
        this.setPanelMenuOptions();
        // Tarjetas de estado
        this.getStateCards();
        if (
          this.meter.metrologyType != METROLOGY_TYPE.SENSOR &&
          this.meter.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR
        ) {
          // Tarjetas de contador
          if (this.meter.metrologyType != METROLOGY_TYPE.SATELITE) {
            this.getMeterCards();
          }
          // Gráfica
          this.graphEnabled = true;
          // Comprobación de estado de la válvula
          if (this.meter.metrologyType == METROLOGY_TYPE.SATELITE) {
            this.MeterService.updateValveData(this.meter);
            this.setValveUpdateInterval();
          }
        }
        // Gráfica de estado de válvula
        if (this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE) {
          this.getValveData(
            this.defaultDateRange.startDate.valueOf(),
            this.defaultDateRange.endDate.valueOf()
          );
        }
      }
    });
  }

  // Comprobación de si la agrupación en curso es la correcta
  checkAgrupation(): void {
    if (
      this.currentAgrupation.id != this.meter.agrupation &&
      this.meter.agrupation != null
    ) {
      this.router.navigate(["/principal"]);
    }
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de contador
  /***************************************************************************/

  // Creación de las tarjetas de contador
  getMeterCards(): void {
    let meterCardsData: DetailDeviceCardsData = {
      // Último valor
      meterDetailLastKnownValue: {
        lastReadedValue: this.meter.lastReadedValue,
        lastReadedTimestamp: this.meter.lastReadedTimestamp,
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Último mes
      meterDetailLastDataMonth: {
        data: this.meter.consumptionCards.lastMonthlyValue,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Consumo último mes
      meterDetailLastMonthConsume: {
        data: this.meter.consumptionCards.lastMonth,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Consumo medio mensual
      meterDetailConsumeMonthAverage: {
        data: this.meter.consumptionCards.monthlyAverage,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Consumo mes actual
      meterDetailCurrentMonthConsume: {
        data: this.meter.consumptionCards.currentMonth,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Consumo medio diario
      meterDetailConsumeAverageDaily: {
        data: this.meter.consumptionCards.dailyAverage,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Último consumo mínimo
      meterDetailLastMinConsumption: {
        data: this.meter.lastConsumptionMin,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Último consumno máximo
      meterDetailLastMaxConsumption: {
        data: this.meter.lastConsumptionMax,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Último consumo total
      meterDetailLastTotalConsumption: {
        data: this.meter.lastConsumptionTotal,
        type: "consumption",
        hideCondition: this.meter.metrologyType == METROLOGY_TYPE.WATER_VALVE,
      },
      // Batería
      // Batería
      meterDetailBattery: {
        data: this.meter.lastBatValue,
        type: "%",
        buttons: [
          {
            action: "openBatteryGraphicPopUp",
            icon: "fa-solid fa-clock-rotate-left",
            title: this.translate.instant("Historial"),
          },
        ],
      },
      // Modo
      meterDetailMode: {
        buttons:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ? [
                {
                  action: "changeModeA",
                  text: "A",
                  title: this.translate.instant("mode") + " A",
                },
                {
                  action: "changeModeA+",
                  text: "A+",
                  title: this.translate.instant("mode") + " A+",
                  hide:
                    this.meter.idFabricante == MANUFACTURER_INDEX.BETA &&
                    this.meter.idDevType == MANUFACTURER_INDEX.BETA_WG4,
                },
                {
                  action: "changeModeB",
                  text: "B",
                  title: this.translate.instant("mode") + " B",
                },
              ]
            : null,
        hideCondition:
          !(
            this.meter.initialState != null &&
            ((this.meter.idFabricante == MANUFACTURER_INDEX.SAGEMCOM &&
              this.meter.idDevType == MANUFACTURER_INDEX.SAGEMCOM_EF4EVO) ||
              (this.meter.idFabricante == MANUFACTURER_INDEX.BETA &&
                this.meter.idDevType == MANUFACTURER_INDEX.BETA_WG4))
          ) ||
          (ENVIRONMENT.production && this.sessionProfile != PROFILES.ARSON),
      },
      // Estado de válvula
      meterDetailValve: {
        data: VALVE_STATES[this.meter.valveState]
          ? this.translate.instant(VALVE_STATES[this.meter.valveState].text)
          : this.translate.instant("unknown"),
        type: "text",
        background: VALVE_STATES[this.meter.valveState]
          ? VALVE_STATES[this.meter.valveState].background
          : "bg-gray-gradient",
        date: this.meter.valveStateChangedTimestamp,
        buttons:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ? this.meter.isAddEnprotechValve
              ? [
                  {
                    action: "open-valve",
                    icon: "fas fa-lock-open",
                    title: this.translate.instant("open"),
                  },
                  {
                    action: "open-valve-50",
                    icon: "fas fa-lock-open",
                    text: "50%",
                    title: this.translate.instant("open-valve-50"),
                  },
                  {
                    action: "open-valve-10",
                    icon: "fas fa-lock-open",
                    text: "10%",
                    title: this.translate.instant("open-valve-10"),
                  },
                  {
                    action: "close-valve",
                    icon: "fas fa-lock",
                    title: this.translate.instant("close"),
                  },
                ]
              : this.meter.isLora
              ? [
                  {
                    action: "open-valve",
                    icon: "fas fa-lock-open",
                    title: this.translate.instant("open"),
                  },
                  {
                    action: "close-valve",
                    icon: "fas fa-lock",
                    title: this.translate.instant("close"),
                  },
                  {
                    action: "read-valve",
                    icon: "fab fa-readme",
                    title: this.translate.instant("read"),
                  },
                ]
              : null
            : null,
        hideCondition:
          this.meter.initialState == null &&
          this.meter.metrologyType != METROLOGY_TYPE.WATER_VALVE,
      },
    };

    // Listado de concentradores asociados
    if (
      this.sessionProfile === PROFILES.ARSON &&
      this.meter.listaConcentradores?.length > 0
    ) {
      meterCardsData[
        this.meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR
          ? "meterDetailUneFather"
          : "meterDetailMbusFather"
      ] = {
        data: this.meter.listaConcentradores.map((concentrator) => {
          return {
            title: "",
            data: concentrator.nroSerieConcentrador,
            link:
              this.meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR
                ? "#/dispositivos/detalle/lw-une/" + concentrator.idConcentrador
                : "#/dispositivos/detalle/lw-mbus/" +
                  concentrator.idConcentrador,
          };
        }),
        type: "list",
      };
    }
    this.meterCardsData = meterCardsData;
  }

  // Tarjeta de ruido para sensores acústicos
  getNoiseCard(noiseDataSerie: any): void {
    if (!this.meterCardsData) {
      this.meterCardsData = {};
    }
    this.meterCardsData["noiseData"] = {
      data: "",
      type: "text",
      graph: { series: [noiseDataSerie], min: 0, max: 3000 },
    };
    this.meterCardsData = { ...this.meterCardsData };
  }

  // Creación de las tarjetas de estado
  getStateCards(): void {
    let sf: string =
      this.meter.lastDataRate != null ? "SF" + this.meter.lastDataRate : "-";
    let srssi: string =
      this.meter.lastRssi != null ? this.meter.lastRssi + " dBm" : "-";
    let snr: string =
      this.meter.lastSnr != null ? this.meter.lastSnr + " dB" : "-";
    let sfRssiSnr: string =
      (sf ? sf + " / " : "") + (srssi ? srssi + " / " : "") + snr;
    this.stateCardsData = {
      meterDetailSfRssiSnr: { data: sfRssiSnr, type: "text" },
      meterDetailLastFrame: {
        data: this.DateParserService.parseDate(
          this.meter.lastCommunication,
          this.dateFormat + " HH:mm:ss"
        ),
        type: "text",
      },
      meterDetailLastJoin: {
        data: this.DateParserService.parseDate(
          this.meter.lastJoin,
          this.dateFormat + " HH:mm:ss"
        ),
        type: "text",
      },
      meterDetailMainGateway: { data: this.meter.gateway, type: "text" },
      meterDetailRedundantGateways: {
        data: this.meter.redundantGateways,
        type: "number",
      },
    };
  }

  // Acciones de las tarjetas
  cardsActions(action: string): void {
    switch (action) {
      case "changeModeA":
        this.MeterService.changeMode("A", this.meter);
        break;
      case "changeModeA+":
        this.MeterService.changeMode("A+", this.meter);
        break;
      case "changeModeB":
        this.MeterService.changeMode("B", this.meter);
        break;
      case "open-valve":
        this.MeterService.openValve(this.meter);
        break;
      case "open-valve-10":
        this.MeterService.openValve(this.meter, 10);
        break;
      case "open-valve-50":
        this.MeterService.openValve(this.meter, 50);
        break;
      case "close-valve":
        this.MeterService.closeValve(this.meter);
        break;
      case "read-valve":
        this.MeterService.readValve(this.meter);
        break;
      case "openBatteryGraphicPopUp":
        this.MeterService.openBatteryGraph(this.meter);
      default:
        break;
    }
  }

  // Acción del evento click sobre las tarjetas
  cardClickEvent(action: string): void {
    switch (action) {
      case "goToGraph":
        this.viewportScroller.scrollToAnchor("meter-graph");
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Válvula
  /***************************************************************************/

  // Creación de las tarjetas para válvula satélite
  getValveCards(valveGraphData?: number[][]): void {
    if (valveGraphData) {
      this.valveGraphData = valveGraphData;
    }

    // Gráfica de consumo
    let consumptionSerie = [
      {
        id: "consumeLastWeek",
        name: this.translate.instant("consumption"),
        data: this.valveGraphData,
        tooltip: {
          valueSuffix: " m³",
          valueDecimals: 3,
        },
      },
    ];
    let meterCardsData: DetailDeviceCardsData = {
      // Último valor
      meterDetailLastKnownValue: {
        lastReadedValue: this.meter.lastReadedValue,
        lastReadedTimestamp: this.meter.lastReadedTimestamp,
        buttons: [
          {
            action: "read-valve",
            icon: "fas fa-sync-alt",
            title: this.translate.instant("update"),
          },
        ],
      },
      // Último caudal instantáneo
      meterDetailLastFlow: {
        data: this.meter.lastFlow,
        date: this.meter.lastFlowTimestamp,
        type: "consumption",
        hideCondition: this.meter.lastFlowTimestamp == null,
      },
      // Último caudal medio
      meterDetailLastAverageFlow: {
        data: this.meter.lastAverageFlow,
        date: this.meter.lastFlowTimestamp,
        type: "consumption",
        hideCondition: this.meter.lastFlowTimestamp == null,
      },
      // Consumo última semana
      meterDetailConsumeLastWeek: {
        data: null,
        type: "text",
        click: "goToGraph",
        graph: { series: consumptionSerie },
      },
      // Estado de válvula
      meterDetailSateliteValve: {
        data: VALVE_STATES[this.meter.valveState]
          ? this.translate.instant(VALVE_STATES[this.meter.valveState].text)
          : this.translate.instant("unknown"),
        date: this.meter.lastStateTimestamp,
        type: "text",
        background: VALVE_STATES[this.meter.valveState]
          ? VALVE_STATES[this.meter.valveState].background
          : "bg-gray-gradient",
        img: VALVE_STATES[this.meter.valveState]
          ? VALVE_STATES[this.meter.valveState].img
          : null,
        buttons:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ? [
                {
                  action: "open-valve",
                  icon: "fas fa-lock-open",
                  title: this.translate.instant("open"),
                },
                {
                  action: "close-valve",
                  icon: "fas fa-lock",
                  title: this.translate.instant("close"),
                },
                {
                  action: "read-valve",
                  icon: "fab fa-readme",
                  title: this.translate.instant("read"),
                },
              ]
            : null,
        hideCondition: this.meter.initialState == null,
      },
    };
    this.meterCardsData = meterCardsData;
  }

  // Intervalo de actualización de estado de válvula
  setValveUpdateInterval(): void {
    clearInterval(this.valveInterval);
    this.valveInterval = setInterval(
      () => this.MeterService.updateValveData(this.meter),
      this.valveTimer
    );
  }

  // Obtención de gráfica de estado de válvula
  getValveData(from: number, to: number): void {
    const self = this;
    this.MeterController.getValveStateGraph(this.meterId, from, to).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let valveStateData = response["body"];
          valveStateData.forEach((valveData) => {
            valveData.stateParsed = VALVE_STATES[valveData.status]
              ? this.translate.instant(VALVE_STATES[valveData.status].text)
              : null;
          });
          this.valveStateData = valveStateData;
        }
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "logs",
        icon: "fas fa-list-alt",
        text: this.translate.instant("Logs"),
        visible: true,
        submenu: [
          {
            action: "event-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("event-log"),
            visible: this.sessionProfile == PROFILES.ARSON,
          },
          {
            action: "user-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("user-log"),
            visible: true,
          },
          {
            action: "communication-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("communication-log"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.currentAgrupation.id == 60,
          },
          {
            action: "frame-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("frame-log"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.currentAgrupation.id == 60,
          },
        ],
      },
      {
        action: "updates",
        icon: "fas fa-edit",
        text: this.translate.instant("update"),
        visible: true,
        submenu: [
          {
            action: "change-location",
            icon: "fas fa-map-marker-alt",
            text: this.translate.instant("change-location"),
            visible:
              this.meter.latitude != null && this.meter.longitude != null,
          },
          {
            action: "change-agrupation",
            icon: "fas fa-map",
            text: this.translate.instant("change-agrupation"),
            visible:
              this.sessionProfile == PROFILES.ARSON ||
              this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
              this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
              this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
          },
          {
            action: "edit-metrologic",
            icon: "fas fa-edit",
            text: this.translate.instant("edit-metrologic"),
            visible: this.meter.metrologyType == METROLOGY_TYPE.GAS,
          },
          {
            action: "edit-sf",
            icon: "fas fa-edit",
            text: this.translate.instant("edit") + " SF",
            visible:
              this.sessionProfile == PROFILES.ARSON && this.meter?.isLora,
          },
          {
            action: "edit-values",
            icon: "fas fa-edit",
            text: this.translate.instant("edit-values"),
            visible: this.meter.metrologyType == METROLOGY_TYPE.SATELITE,
          },
          {
            action: "move-readings",
            icon: "fas fa-database",
            text: this.translate.instant("move-readings"),
            visible: this.sessionProfile == PROFILES.ARSON,
          },
        ],
      },
      {
        action: "delete-readings",
        icon: "fas fa-trash",
        text: this.translate.instant("delete-readings"),
        visible: this.sessionProfile == PROFILES.ARSON,
        highlight: true,
        bottom: true,
      },
      {
        action: "ignore-in-blacklist",
        icon: "fas fa-eye-slash",
        text: this.translate.instant("ignore-in-blacklist"),
        visible: this.sessionProfile == PROFILES.ARSON,
        disabled: this.meter.devEui==null,
        highlight: this.meter.devEui!=null,
        bottom: true,
      },
      {
        action: "alarms-config",
        icon: "fas fa-cog",
        text: this.translate.instant("alarms-config"),
        visible: true,
      },
      {
        action: "to-pending",
        icon: "fas fa-binoculars",
        text: this.translate.instant("to-pending"),
        visible:
          this.meter.metrologyType != METROLOGY_TYPE.SENSOR &&
          this.meter.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
          !this.meter.enRevision,
      },
      {
        action: "check-update",
        icon: "fas fa-edit",
        text: this.translate.instant("meter-check-update"),
        visible:
          this.meter.metrologyType != METROLOGY_TYPE.SENSOR &&
          this.meter.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR &&
          this.meter.enRevision,
      },
      {
        action: "ek-config",
        icon: "fas fa-cog",
        text:
          this.translate.instant("configuration") +
          (this.meterTypeByMask === DEVICE_BY_COMM.EK280
            ? " EK280"
            : " OWASYS"),
        visible:
          this.meterTypeByMask === DEVICE_BY_COMM.EK280 ||
          this.meterTypeByMask === DEVICE_BY_COMM.OWASYS,
      },
      {
        action: "ek-test",
        icon: "fas fa-broadcast-tower",
        text:
          this.translate.instant("test-comm") +
          (this.meterTypeByMask === DEVICE_BY_COMM.EK280
            ? " EK280"
            : " OWASYS"),
        visible:
          this.meterTypeByMask === DEVICE_BY_COMM.EK280 ||
          this.meterTypeByMask === DEVICE_BY_COMM.OWASYS,
      },
      {
        action: "meter-orders",
        icon: "fas fa-list-check",
        text: this.translate.instant("meter-orders"),
        visible: true,
      },
      {
        action: "replace",
        icon: "fas fa-exchange-alt",
        text: this.translate.instant("replace"),
        visible: this.sessionProfile == PROFILES.ARSON,
        bottom: true,
        highlight: true,
        submenu: [
          {
            action: "substitution-only-meter",
            icon: "fas fa-tachometer-alt",
            text: this.translate.instant("substitution-only-meter"),
            disabled:
              this.sessionProfile != PROFILES.ARSON || !this.meter.rfModule,
            visible: true,
          },
          {
            action: "substitution-only-rf",
            icon: "fas fa-tower-broadcast",
            text: this.translate.instant("substitution-only-rf"),
            disabled: !this.meter.rfModule,
            visible: true,
          },
          {
            action: "substitution-all",
            icon: "fas fa-sync-alt",
            text: this.translate.instant("substitution-all"),
            visible: true,
          },
        ],
      },
      {
        action: "deactivate",
        icon: "fas fa-eraser",
        text: this.translate.instant("deactivate"),
        visible:
          (this.sessionProfile == PROFILES.ARSON ||
            this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
            this.sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
          this.meter.activate >= 2,
        bottom: true,
        highlight: true,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "edit-metrologic":
        this.router.navigate(
          ["/gestion-datos/metrologia/formulario/editar/" + this.meter.id],
          { state: { data: this.meter.nroSerie } }
        );
        break;
      case "alarms-config":
        this.router.navigate(
          ["/dispositivos/detalle/alarmas/configuracion/" + this.meter.id],
          { state: { data: this.meter.nroSerie } }
        );
        break;
      case "change-location":
        this.changeLocationActive = !this.changeLocationActive;
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "deactivate":
        this.MeterService.resetMeter(this.meter);
        break;
      case "to-pending":
        this.MeterService.toCheck(this.meter);
        break;
      case "check-update":
        this.MeterService.toCheck(this.meter);
        break;
      case "event-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/eventos/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "user-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/usuarios/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "communication-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/comunicaciones/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "frame-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/tramas/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "meter-orders":
        this.router.navigate(
          ["/dispositivos/detalle/ordenes/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "ek-config":
        this.MaterialDialogService.openDialog(Ek280ConfigurationComponent, {
          sessionProfile: this.sessionProfile,
          editModalData: JSON.parse(JSON.stringify(this.ek280ConfigData)),
          simList: this.simList,
          meterId: this.meter.id,
          meterType: this.meterTypeByMask,
        });
        break;
      case "ek-test":
        this.MaterialDialogService.openDialog(Ek280TestComponent, this.meter);
        break;
      case "edit-sf":
        this.changeSfAddrModal();
        break;
      case "edit-values":
        this.MaterialDialogService.openDialog(
          SateliteModifyDialogComponent,
          JSON.parse(JSON.stringify(this.meter))
        );
        break;
      case "move-readings":
        this.MaterialDialogService.openDialog(
          DeviceMoveReadingsDialogComponent,
          this.meter.nroSerie
        );
        break;
      case "substitution-only-meter":
        this.showReplaceModal("onlyMeter");
        break;
      case "substitution-only-rf":
        this.showReplaceModal("onlyModule");
        break;
      case "substitution-all":
        this.showReplaceModal("all");
        break;
      case "delete-readings":
        this.showDeleteReadingsModal();
        break;
      case "ignore-in-blacklist":
        this.ignoreMeterInBlackList();
        break;
      default:
        break;
    }
  }

  // Acciones del mapa
  mapAction(action: string, gateway: DetailDeviceGateway): void {
    switch (action) {
      case "assign":
        this.allocateExternalGateway(gateway.id);
        break;
      default:
        break;
    }
  }

  // Asignación de gateway de otra entidad
  allocateExternalGateway(gateway): void {
    this.MeterController.saveDropdown(gateway, [this.meter.nroSerie]).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("selected-assign")
          );
          this.ReloadComponentService.reload();
        }
      }
    );
  }

  ignoreMeterInBlackList() {
    this.ToastService.fireAlertWithOptions("question",this.translate.instant("meter-ignore-question")).then((userConfirmation: boolean) => {
      if(userConfirmation==true){
        this.MeterController.ignoreMeter(this.meter.nroSerie).subscribe(
          (response) =>{
            if(response["code"]==0){
              this.ToastService.fireToast("success",this.translate.instant("success-ignore-meter"))
            }
            else{
              this.ToastService.fireToast("error",this.translate.instant("ignore-meter-error"))
            }
          }
        );
      }
    });
  }
  /***************************************************************************/
  // ANCHOR Modales
  /***************************************************************************/

  // Modal de datos de dispositivo
  showMeterData(): void {
    this.MaterialDialogService.openDialog(DeviceInfoModalComponent, {
      device: this.meter,
      deviceTypeByMask: this.meterTypeByMask,
      ekData: this.ek280ConfigData,
    });
  }

  // Modal de cambio de número de serie
  changeSerialNumberModal(): void {
    this.MaterialDialogService.openDialog(DeviceEditModalComponent, this.meter);
  }

  // Modal de cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: [this.meter],
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }

  // Modal de sustitución de contador/módulo RF
  showReplaceModal(type: string): void {
    if (type == "onlyModule") {
      this.MaterialDialogService.openDialog(
        DeviceReplaceRfModalComponent,
        this.meter
      );
    } else {
      this.MaterialDialogService.openDialog(DeviceReplaceDialogComponent, {
        meter: this.meter,
        type: type,
      });
    }
  }

  // Modal de cambio de SF/ADR
  changeSfAddrModal(): void {
    this.MaterialDialogService.openDialog(DeviceSfUpdateComponent, {
      deviceAdr: this.meter.adrPermission == 1 ? true : false,
      deviceSfMin: this.meter.minSf,
      deviceSf: this.meter.lastDataRate,
      deviceTxPower: this.meter.txPower,
      device: this.meter,
    });
  }

  // Modal de eliminacion de valores/consumos
  showDeleteReadingsModal() {
    this.MaterialDialogService.openDialog(DeviceDeleteReadingsComponent, {
      meter: this.meter.id,
    });
  }
  /***************************************************************************/
  // ANCHOR Concentradores asociados
  /***************************************************************************/

  // Obtención de contadores principales de LW MBUS
  getLwMbusMain(): void {
    this.MeterController.getMeterMainLwMbus(this.meter.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let mainConcentrators = response["body"];
          this.meter.mainConcentrators = mainConcentrators;
          this.meter.mainConcentrators.forEach(
            (concentrator: LwMbusMainMeter) => {
              let foundTableConcentrator = this.mbusConcentratorsLinked.find(
                (linkedConcentrator: MbusMeterConcentrator) =>
                  linkedConcentrator.id == concentrator.concentrador
              );
              if (foundTableConcentrator) {
                foundTableConcentrator.main = true;
              }
            }
          );
          this.mbusConcentratorsLinked = [...this.mbusConcentratorsLinked];
          if (this.meter.mainConcentrators.length > 0) {
            this.meterCardsData["meterPermanentLwMbus"] = {
              data: this.meter.mainConcentrators.map((concentrator) => {
                return {
                  title: "",
                  data: concentrator.nro_serie,
                  link:
                    "#/dispositivos/detalle/lw-mbus/" +
                    concentrator.concentrador,
                };
              }),
              type: "list",
            };
          }
          this.meterCardsData["meterDetailMbusFather"] = {
            data: this.meter.listaConcentradores
              .filter(
                (concentrator) =>
                  !this.meter.mainConcentrators.some(
                    (mainConcentrator) =>
                      mainConcentrator.concentrador ==
                      concentrator.idConcentrador
                  )
              )
              .map((concentrator) => {
                return {
                  title: "",
                  data: concentrator.nroSerieConcentrador,
                  link:
                    "#/dispositivos/detalle/lw-mbus/" +
                    concentrator.idConcentrador,
                };
              }),
            type: "list",
          };
          this.meterCardsData = { ...this.meterCardsData };
        }
      }
    );
  }

  // Acciones de la tabla de contadores hijos
  lwMbusTableActions(actionData: any): void {
    let action: any = actionData.action;
    let concentrator: any = actionData.element;

    switch (action.name) {
      case "assign-permanent":
        this.MeterService.assignLwMbusPermanent(concentrator.id, [
          this.meter.id,
        ]);
        break;
      case "unassign-permanent":
        this.MeterService.unassignLwMbusPermanent(concentrator.id, [
          this.meter.id,
        ]);
        break;
      default:
        break;
    }
  }
}
