import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Interfaces
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
// Variables
import { MENU, MenuItem } from "./navbar-menu";
import { NAVBAR_SHORTCUTS } from "./navbar-shortcuts";
import { PROFILES } from "../../../../assets/profiles/profiles";

@Injectable({
  providedIn: "root",
})
export class NavbarMenuService implements OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  sessionProfile: string;
  sessionProfileSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  cupsDependentMenus = ["cups-info", "not-cups-assigned-devices"];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/
  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {
    //Inicializamos en el constructor,
    this.initializeSubscriptions();
  }

  /***************************************************************************/
  // ANCHOR ngOnDestroy
  /***************************************************************************/
  ngOnDestroy(): void {
    this.unsubscribeFromAll();
  }

  /***************************************************************************/
  // ANCHOR Inicialización de suscripciones
  /***************************************************************************/
  private initializeSubscriptions(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    this.entityCupsConfSub = this.SessionDataService.getEntityCupsConf().subscribe((entityCupsConf) => {
      this.handleEntityCupsConfChange(entityCupsConf);
    });

    this.sessionProfileSub = this.SessionDataService.getProfile().subscribe((sessionProfile) => {
      this.handleProfileChange(sessionProfile);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe((agrupation) => {
      this.handleAgrupationChange(agrupation);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.handleEntityChange(entity);
    });
  }

  /***************************************************************************/
  // ANCHOR Limpieza de suscripciones
  /***************************************************************************/
  private unsubscribeFromAll(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.sessionProfileSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Manejo de cambios de configuración CUPS
  /***************************************************************************/
  private handleEntityCupsConfChange(entityCupsConf: EntityDefinition[]): void {
    this.currentEntityCupsConf = entityCupsConf;
    this.cups = this.currentEntityCupsConf?.find((col: EntityDefinition) => col.colPosition == 0);
    this.sendUpdatedMenu();
  }

  /***************************************************************************/
  // ANCHOR Manejo de cambios de perfil
  /***************************************************************************/
  private handleProfileChange(sessionProfile: string): void {
    this.sessionProfile = sessionProfile;
    this.sendUpdatedMenu();
  }

  /***************************************************************************/
  // ANCHOR Manejo de cambios de agrupación
  /***************************************************************************/
  private handleAgrupationChange(agrupation: Agrupation): void {
    this.currentAgrupation = agrupation;
    this.sendUpdatedMenu();
  }

  /***************************************************************************/
  // ANCHOR Manejo de cambios de entidad
  /***************************************************************************/
  private handleEntityChange(entity: Entity): void {
    this.currentEntity = entity;
    this.sendUpdatedMenu();
  }

  /***************************************************************************/
  // ANCHOR Enviar el menú actualizado
  /***************************************************************************/
  private sendUpdatedMenu(): void {
    this.SessionDataService.sendNavbarMenu(
      this.setMenu(this.cups, this.currentEntityCupsConf, this.sessionProfile, this.currentAgrupation, this.currentEntity)
    );
  }

  /***************************************************************************/
  // ANCHOR Inicialización de menú
  /***************************************************************************/
  loadMenu(): void {
    this.sendUpdatedMenu();
  }

  /***************************************************************************/
  // ANCHOR Seteo del menú
  /***************************************************************************/
  setMenu(
    cups: EntityDefinition,
    entityCupsConf: EntityDefinition[],
    sessionProfile: string,
    agrupation: Agrupation,
    entity: Entity
  ): MenuItem[] {
    let menuList: MenuItem[] = [];
    MENU.forEach((menuItem) => {
      this.setMenuItem(menuList, menuItem, cups, entityCupsConf, sessionProfile, agrupation, entity);
    });
    return menuList;
  }

  /***************************************************************************/
  // ANCHOR Seteo de opción de menú
  /***************************************************************************/
  private setMenuItem(
    menuList: any[],
    menuItem: any,
    cups: EntityDefinition,
    entityCupsConf: EntityDefinition[],
    sessionProfile: string,
    agrupation: Agrupation,
    entity: Entity
  ): void {
    // Verificar si el perfil y cliente están permitidos
    if (this.isProfileAllowed(menuItem, sessionProfile) && this.isClientAllowed(menuItem, entity)) {
      if (this.shouldIncludeMenuItem(menuItem, cups)) {
        let newMenuItem: MenuItem = this.createMenuItem(menuItem, cups, sessionProfile, entity);
        this.applyMenuItemVisibility(menuItem, newMenuItem, entityCupsConf, agrupation);
        this.addSubMenuItems(newMenuItem, menuItem, cups, entityCupsConf, sessionProfile, agrupation, entity);
        this.pushMenuItemIfVisible(menuList, newMenuItem);
      }
    }
  }


  /***************************************************************************/
  // ANCHOR Decidir si incluir el ítem de menú
  /***************************************************************************/
  private shouldIncludeMenuItem(menuItem: any, cups: EntityDefinition): boolean {
    return !this.cupsDependentMenus.includes(menuItem.name) || (this.cupsDependentMenus.includes(menuItem.name) && Boolean(cups));
  }

  /***************************************************************************/
  // ANCHOR Crear un nuevo ítem de menú
  /***************************************************************************/
  private createMenuItem(
    menuItem: any,
    cups: EntityDefinition,
    sessionProfile: string,
    entity: Entity
  ): MenuItem {
    let newMenuItem: MenuItem = new MenuItem();
    newMenuItem.name = this.getMenuItemName(menuItem, cups);
    newMenuItem.link = menuItem.link;
    newMenuItem.icon = menuItem.icon;
    newMenuItem.subicon = menuItem.subicon;
    newMenuItem.profile = menuItem.profile?.length > 0 ? menuItem.profile : [sessionProfile];  // Aseguramos que sea un array de strings
    newMenuItem.client = menuItem.client?.length > 0 ? menuItem.client : [entity?.client];  // Aseguramos que sea un array de strings
    newMenuItem.submenu = menuItem.submenu?.length > 0 ? [] : null;
    newMenuItem.shortcut = NAVBAR_SHORTCUTS[menuItem.link] ? NAVBAR_SHORTCUTS[menuItem.link] : null;
    this.applyMenuItemDisabledState(menuItem, newMenuItem, cups, this.SessionDataService.getCurrentEntityCupsConf());
    return newMenuItem;
  }

  /***************************************************************************/
  // ANCHOR Obtener el nombre del ítem de menú
  /***************************************************************************/
  private getMenuItemName(menuItem: any, cups: EntityDefinition): string {
    if (menuItem.name === "cups-info") {
      return cups?.name || "CUPS";
    }
    if (menuItem.name === "not-cups-assigned-devices") {
      return this.translate.instant("devices-without") + " " + (cups?.name || "CUPS");
    }
    if (menuItem.name == "cups-list"){
      return this.translate.instant("list-of") + " " + (cups?.name || "CUPS");
    }
    if (menuItem.name === "gateways-client" && this.sessionProfile === PROFILES.ARSON) {
      return this.translate.instant("gateways") + " (" + this.translate.instant("client") + ")";
    }
    return menuItem.name;
  }

  /***************************************************************************/
  // ANCHOR Verificar si el perfil está permitido para este ítem
  /***************************************************************************/
  private isProfileAllowed(menuItem: any, sessionProfile: string): boolean {
    return menuItem.profile?.length > 0 ? menuItem.profile.includes(sessionProfile) : true;
  }

  /***************************************************************************/
  // ANCHOR Verificar si el cliente está permitido para este ítem
  /***************************************************************************/
  private isClientAllowed(menuItem: any, entity: Entity): boolean {
    return menuItem.client?.length > 0 ? menuItem.client.includes(entity?.client) : true;
  }

  /***************************************************************************/
  // ANCHOR Aplicar estado de deshabilitado
  /***************************************************************************/
  private applyMenuItemDisabledState(menuItem: any, newMenuItem: MenuItem, cups: EntityDefinition, entityCupsConf: EntityDefinition[]): void {
    switch (menuItem.disabled) {
      case "cups":
        newMenuItem.disabled = cups == null;
        break;
      case "entityCupsConf":
        newMenuItem.disabled = !(entityCupsConf?.find((column: any) => column.colPosition === 0));
        break;
      case "virtual":
        newMenuItem.disabled = this.currentAgrupation?.showAllEntity;
        break;
      case "isTlm":
        newMenuItem.disabled =
          this.sessionProfile !== PROFILES.ARSON &&
          this.sessionProfile !== PROFILES.ADMIN_CLIENTE &&
          !this.SessionDataService.getCurrentTelemetryActive();
        break;
      case "disabled":
        newMenuItem.disabled = true;
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Aplicar visibilidad del ítem de menú
  /***************************************************************************/
  private applyMenuItemVisibility(menuItem: any, newMenuItem: MenuItem, entityCupsConf: EntityDefinition[], agrupation: Agrupation): void {
    if (menuItem.visible) {
      switch (menuItem.visible) {
        case "gas":
          newMenuItem.visible = this.SessionDataService.getCurrentClient()?.gas==true;
          break;
        case "!entityCupsConf":
          newMenuItem.visible = !(entityCupsConf?.find((column: any) => column.colPosition === 0));
          break;
        case "entityCupsConf":
          newMenuItem.visible = (entityCupsConf?.some((column: any) => column.colPosition === 0));
          break;
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Agregar submenús
  /***************************************************************************/
  private addSubMenuItems(
    newMenuItem: MenuItem,
    menuItem: any,
    cups: EntityDefinition,
    entityCupsConf: EntityDefinition[],
    sessionProfile: string,
    agrupation: Agrupation,
    entity: Entity
  ): void {
    if (menuItem.submenu) {
      menuItem.submenu.forEach((submenuItem) => {
        this.setMenuItem(
          newMenuItem.submenu,
          submenuItem,
          cups,
          entityCupsConf,
          sessionProfile,
          agrupation,
          entity
        );
      });
    }
  }

  /***************************************************************************/
  // ANCHOR Añadir ítem de menú si es visible
  /***************************************************************************/
  private pushMenuItemIfVisible(menuList: any[], newMenuItem: MenuItem): void {
    if (newMenuItem.visible !== false) {
      menuList.push(newMenuItem);
    }
  }
}
