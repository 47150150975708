<!-- Tabla de sectores -->
<div *ngIf="tableData?.length > 0" class="panel panel-default">
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b>{{ "sector-table" | translate }}</b>
    </h5>
  </div>

  <div class="panel-body">
    <!-- Tabla -->
    <app-table-controller
      [export]="true"
      [exportFileName]="tableExportFileName"
      [columns]="tableColumns"
      [rowNumbers]="tableRowNumbers"
      [maxReg]="tableMaxReg"
      (tableAction)="tableActions($event.action.name, $event.element)"
      [data]="tableData"
    >
    </app-table-controller>
  </div>
</div>
