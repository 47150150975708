<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "inactive-gateways-list" | translate }}</b>
        </h5>

        <!-- Botón exportar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newGatewayModal()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Importación de archivo -->
        <div class="gateways-installed-import">
          <h4>
            <b>{{ "file-import" | translate }} </b>
            <i class="fas fa-info-circle pointer" (click)="showInfo()"></i>
          </h4>
          <app-file-import
            [multiple]="true"
            [fileType]="'.csv'"
            (fileReady)="importGateways($event)"
          ></app-file-import>
        </div>

        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewaysInactive'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
