<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "readings-import" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
          </h4>
          <h5 class="readings-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="importDevice($event)"
          ></app-file-import>
        </div>

        <div class="readings-import-force">
          <input type="checkbox" name="forceImport" [(ngModel)]="force" />
          <label for="forceImport">{{ "readings-force" | translate }}</label>
        </div>

        <app-import-table
          [importColumns]="importColumns"
          [importPreselects]="null"
          (tableActionFlag)="(null)"
        >
        </app-import-table>

        <div *ngIf="importResponse" class="readings-import-response">
          <div>
            <b>{{ "readings-saved" | translate }}:</b><br />
            <span>{{ importResponse.lecturasGuardadas }}</span>
          </div>
          <div>
            <b>{{ "readings-total-rows" | translate }}:</b><br />
            <span>{{ importResponse.totalFilas }}</span>
          </div>
          <div>
            <b>{{ "readings-total-errors" | translate }}:</b><br />
            <span>{{ importResponse.totalErrores }}</span>
          </div>
          <div *ngIf="importResponse.contadoresNoExistentes?.length > 0">
            <b>{{ "readings-device-error" | translate }}:</b><br />
            <span>{{ importResponse.contadoresNoExistentesParsed }}</span>
          </div>
          <div *ngIf="importResponse.listaErrorValores?.length > 0">
            <b>{{ "readings-rows-error" | translate }}:</b><br />
            <ng-container
              *ngFor="let error of importResponse.listaErrorValores"
            >
              <span>{{ error }}</span
              ><br />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
