import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ManagementControllerService } from "../../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interface
import {
  TableDataColumn,
  TableHighlightRow,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  BestSignalResponse,
  ClearRedundant,
  DeviceForClear,
  DeviceGateway,
} from "../../ManagementInterface.type";
import { GatewayCleanResponse } from "../../../devices/DeviceInterface.type";

@Component({
  selector: "app-management-network-dialog",
  templateUrl: "./management-network-dialog.component.html",
  styleUrls: ["./management-network-dialog.component.scss"],
})
export class ManagementNetworkDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  // Tabla
  tableSelectedData: DeviceForClear[];
  tableData: DeviceForClear[];
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightOld",
      color: "yellow",
      title: "sales-unit-old",
    },
    { condition: "highlightNew", color: "green", title: "sales-unit-new" },
    {
      condition: "highlightRemoved",
      color: "red",
      title: "redundant-gateway-deleted",
    },
  ];
  columns: TableDataColumn[] = [
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
      link: "deviceLink",
      linkCheck: {
        condition: "agrupation",
        attribute: "agrupationId",
        check: "id",
      },
    },
    {
      title: "gateways",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
      noExport: true,
    },
    {
      title: "sales-unit-old",
      data: "unidadVentaMainGateway",
      search: "unidadVentaMainGateway",
      sort: "unidadVentaMainGateway",
      visible: true,
    },
    {
      title: "sales-unit-new",
      data: "newGatewayUnidadVenta",
      search: "newGatewayUnidadVenta",
      sort: "newGatewayUnidadVenta",
      visible: true,
    },
  ];
  extraTableColumns: TableDataColumn[] = [
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "SNR",
      data: "snrParsed",
      search: "snrParsed",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "RSSI (dBm)",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-rssi-received",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    let tableData = JSON.parse(JSON.stringify(this.data.deviceList));
    tableData.forEach((device: DeviceForClear) => {
      let bestSignal = this.data.bestSignal.find(
        (response: BestSignalResponse) => response.meter == device.nroSerie
      );
      let clearRedundant = this.data.clearRedundant.find(
        (response: ClearRedundant) => response.meter == device.nroSerie
      );
      device.newGatewayUnidadVenta =
        bestSignal.code == 4200
          ? this.translate.instant("best-gateway-already")
          : bestSignal.code == 4205
          ? this.translate.instant("no-signal-data")
          : bestSignal.newGatewayUnidadVenta;
      device.extraTableData.data = null;
      device.bestSignal = bestSignal;
      device.clearRedundant = clearRedundant.responseList;
    });
    this.tableData = tableData;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos de la tabla anidada
  getExtraTableData(device: DeviceForClear): void {
    this.ManagementController.getDeviceGatewayList(device.id).subscribe(
      (response) => {
        let data = [];
        if (response["code"] == 0) {
          data = response["body"];
          data.forEach((gateway: DeviceGateway) => {
            gateway.highlightOld =
              gateway.unidadVenta == device.unidadVentaMainGateway;
            gateway.highlightNew = gateway.id == device.bestSignal.newGatewayId;
            gateway.highlightRemoved = device.clearRedundant.some(
              (redundantGateway: GatewayCleanResponse) =>
                redundantGateway.gatewayId == gateway.id
            );
          });
        }

        device.extraTableData.highlightRow = [
          { condition: "highlightRemoved", color: "red" },
          { condition: "highlightNew", color: "green" },
          { condition: "highlightOld", color: "yellow" },
        ];
        device.extraTableData.data = data.sort((a, b) =>
          a === b ? 0 : a.highlightOld ? -1 : a.highlightNew ? -1 : 1
        );
      }
    );
  }
}
