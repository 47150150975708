<section class="content-header">
  <div class="container-fluid">
    <!-- Panel de jobs -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "output-file" | translate }}: Aqualia</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Selector de rango de fechas -->
        <div class="output-files-date-selector">
          <app-material-date-selector
            [dateRange]="dateRange"
            (dateRangeUpdated)="dateRangeSelected = $event"
          ></app-material-date-selector>

          <!-- Cargar gráfica -->
          <button
            mat-button
            class="mat-button-icon"
            (click)="filesLoading = true; jobAgrupationList = null; getData()"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>

        <!-- Tabla -->
        <ng-container *ngIf="filesLoading">
          <app-table-controller
            #jobTable
            [tableId]="'aqualiaJobList'"
            [columns]="jobColumns"
            [rowNumbers]="true"
            [maxReg]="jobTableMaxReg"
            [specialFilter]="true"
            [highlightRow]="jobHighlightRow"
            (tableAction)="jobTableActions($event.action.name, $event.element)"
            (extraTableAction)="
              jobAgrupationTableActions(
                $event.action.name,
                $event.parentElement,
                $event.childElement
              )
            "
            [data]="jobList"
          >
          </app-table-controller>
        </ng-container>
      </div>
    </div>
  </div>
</section>
