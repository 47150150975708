import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";
import { SessionDataService } from "../../../../../../../../services/shared/SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class IntegraDecoderService {
  constructor(
    private SepemDecoderService: SepemDecoderService,
    private SessionDataService: SessionDataService
  ) {}

  tiposDatos = {
    ENTERO_8BITS: 1,
    ENTERO_16BITS: 2,
    ENTERO_24BITS: 3,
    ENTERO_32BITS: 4,
    REAL_32BITS: 5,
    ENTERO_48BITS: 6,
    ENTERO_64BITS: 7,
    BCD_2DIGITOS: 9,
    BCD_4DIGITOS: 10,
    BCD_6DIGITOS: 11,
    BCD_8DIGITOS: 12,
    LONGITUD_VARIABLE: 13,
    BCD_12DIGITOS: 14,
  };

  timezone: string;

  Integra_decoder(frmPayload, fport) {
    if (!frmPayload) {
      return {
        errors: ["Unknown frmPayload."],
      };
    } else if (fport != 10 && fport != 11) {
      return {
        errors: ["fport no válido (debe ser 0x10 o 0x11)"],
      };
    } else if (!frmPayload.bytes) {
      return {
        errors: ["Unknown frmPayload.bytes."],
      };
    } else if (frmPayload.bytes.length != 47) {
      return {
        errors: ["Tamaño del FRMPayload no válido (debe ser 47 bytes)."],
      };
    } else {
      var outputData = {};
      var outputErrors = [];
      this.timezone = this.SessionDataService.getCurrentAgrupation()?.timezone;
      var timestamp = this.readU32Lsb(frmPayload.bytes, 0);
      var date: any = new Date(timestamp * 1000);
      var alarmas = this.readU16Lsb(frmPayload.bytes, 4);
      var readOut24 = this.readU32Lsb(frmPayload.bytes, 6);
      var readOutBase = this.readU32Lsb(frmPayload.bytes, 10);
      var deltas = [];
      for (let nDelta = 0, indice = 14; nDelta < 11; nDelta++, indice += 3) {
        deltas[nDelta] = this.read24Lsb(frmPayload.bytes, indice);
      }

      outputData["Tipo"] = fport == 11 ? "DATOS 00h-12h" : "DATOS 12h-24h";
      outputData["Fecha"] = date.toLocaleString("es-ES", {
        timeZone: this.timezone,
      });
      outputData["Alarmas"] = {};
      if (alarmas & 0x0001) {
        outputData["Alarmas"]["Manipulación"] = true;
      }
      if (alarmas & 0x0002) {
        outputData["Alarmas"]["Burbujas de aire"] = true;
      }
      if (alarmas & 0x0004) {
        outputData["Alarmas"]["Burst"] = true;
      }
      if (alarmas & 0x0008) {
        outputData["Alarmas"]["Fuga"] = true;
      }
      if (alarmas & 0x0010) {
        outputData["Alarmas"]["Helada"] = true;
      }
      if (alarmas & 0x0020) {
        outputData["Alarmas"]["Calor"] = true;
      }
      if (alarmas & 0x0040) {
        outputData["Alarmas"]["Temperatura excesiva"] = true;
      }
      if (alarmas & 0x0080) {
        outputData["Alarmas"]["Sin consumo"] = true;
      }
      if (alarmas & 0x0100) {
        outputData["Alarmas"]["Batería baja"] = true;
      }
      if (alarmas & 0x0200) {
        outputData["Alarmas"]["Flujo inverso"] = true;
      }
      if (alarmas & 0x0400) {
        outputData["Alarmas"]["Caudal excesivo"] = true;
      }
      if (alarmas & 0x0800) {
        outputData["Alarmas"]["Seco"] = true;
      }
      if (alarmas & 0x1000) {
        outputData["Alarmas"]["Límite mínimo de temperatura del agua"] = true;
      }
      if (alarmas & 0x2000) {
        outputData["Alarmas"]["Límite máximo de temperatura del agua"] = true;
      }
      if (alarmas & 0x4000) {
        outputData["Alarmas"]["Límite mínimo de temperatura ambiente"] = true;
      }
      if (alarmas & 0x8000) {
        outputData["Alarmas"]["Límite máximo de temperatura ambiente"] = true;
      }
      // Valor 24h
      date.setMinutes(0);
      date.setSeconds(0);
      let date24 = new Date(date);
      date24.setUTCHours(0);
      outputData["Valor24h"] =
        date24.toLocaleString("es-ES", { timeZone: this.timezone }) +
        (readOut24 * 0.001).toLocaleString("es-ES") +
        " m3";
      if (fport == 10) {
        // Los valores del puerto 10 son de las 12:00 a las 24:00 del día anterior
        date = new Date(date - 24 * 60 * 60 * 1000);
        date.setUTCHours(12);
      } else {
        // Los del puerto 11 son de las 0:00 a las 12:00 del dia en curso
        date.setUTCHours(0);
      }
      outputData["Valores"] = {};
      outputData["Valores"][
        date.toLocaleString("es-ES", { timeZone: this.timezone })
      ] = (readOutBase * 0.001).toLocaleString("es-ES") + " m3";
      var valor = readOutBase;
      for (let ndxDelta = 0; ndxDelta < 11; ndxDelta++) {
        date = new Date(date.getTime() + 60 * 60 * 1000);
        valor += deltas[ndxDelta];
        outputData["Valores"][
          date.toLocaleString("es-ES", { timeZone: this.timezone })
        ] = (valor * 0.001).toLocaleString("es-ES") + " m3";
      }
      return {
        data: outputData,
      };
    }
  }

  /* Helper Methods */

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  readU16Msb(bytes, start) {
    var res = (bytes[start] << 8) + bytes[start + 1];
    return res;
  }

  read16Msb(bytes, start) {
    var res = (bytes[start] << 8) + bytes[start + 1];
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  read24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x800000) {
      res = res - 0xffffff - 1;
    }
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  Integra_Descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);
      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 10:
                return "DATOS 12h-24h";
              case 11:
                return "DATOS 00h-12h";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frameBytes[5] & 0x0f;
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
