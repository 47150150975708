import { SuspicionsService } from "./../../../../services/shared/SuspicionsService.service";
// @angular
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Servicios propios
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayVersionParserService } from "../GatewaVersionParserService.service";
import { GatewayService } from "../GatewayService.service";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
import { GatewayDetailDialogComponent } from "./gateway-detail-dialog/gateway-detail-dialog.component";
import { GatewayInstockDialogComponent } from "../gateways-list/gateways-instock/gateway-instock-dialog/gateway-instock-dialog.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  Gateway,
  GatewayMapMeter,
  GatewayMinimal,
  GatewayTableMeter,
  Sf,
  AssignableMeter,
  GatewayGraphData,
  GatewayMongoGraphData,
  GatewayOptions,
} from "../../../../interfaces/GatewayGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
import { GatewayEcho } from "../GatewayInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { GraphColorByPoint } from "../../../../modules/graph-module/GraphInterface.type";
import { GATEWAY_STATES } from "../../../../interfaces/GatewayGlobalInterface.type";
import { ENVIRONMENT } from "../../../../../environments/environment";
import { RANDOM_COLORS } from "../../../../modules/map-module/map-variables/MAP_COLORS";
import { link } from "fs";
import { DetailDeviceAlarm } from "../../devices/DeviceInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-gatewaydetail",
  templateUrl: "./gateway-detail.component.html",
  styleUrls: ["./gateway-detail.component.scss"],
})
export class GatewaysDetailComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  dateFormat: string;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];
  snifferListActive: boolean = false;
  // Gateway
  gatewayId: number;
  gateway: Gateway;
  resettingGateway: boolean = false;

  // Tarjetas
  gatewayCardsData: object;
  gatewayCardsOrder: string[] = [
    "gatewayDetailMainMeters",
    "gatewayDetailRedundantMeters",
    "gatewayDetailLastCommunication",
    "gatewayDetailRssi",
    "gatewayDetailLastHello",
    "gatewayDetailLastFile",
    "gatewayDetailSf",
    "gatewayDetailOperator",
  ];

  // Mapa
  mapType: string = "gatewayDetail";
  mapGateways: (Gateway | GatewayMinimal)[];
  mapMeters: (GatewayMapMeter | AssignableMeter)[];
  mapHeight: number = 500;
  mapActiveLayers: string[];

  // Tabla de contadores asociados al gateway
  exportFileName: string =
    this.translate.instant("gateway-meter-export") +
    " " +
    this.DateParserService.getDate();
  gatewayMetersRowNumbers: boolean = true;
  gatewayMetersTableMaxReg: number = 10;
  gatewayMetersList: GatewayTableMeter[];
  selectedMeterList: GatewayTableMeter[] = [];
  otherEntityGatewayList: GatewayMinimal[] = [];
  gatewayToAllocate: GatewayMinimal;
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "meters-assign-gateway",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      help: "help-table-meters-assign-gateway",
    },
    {
      title: "assign-best-gateway",
      icon: "fas fa-check-double",
      selectionRequired: true,
      help: "help-table-gateway-best",
    },
    {
      title: "clean-redundant-gateways",
      icon: "fas fa-broom",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-clean-redundant",
    },
    {
      title: "meters-unassign",
      icon: "fas fa-tachometer-alt",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-meters-unassign",
    },
    {
      title: "gateway-unassign",
      icon: "gateway-icon gateway-icon-red",
      selectionExtraRequired: true,
      class: "btn-red",
      help: "help-table-gateway-unassign",
    },
  ];
  gatewayMetersQuickFilters: TableQuickFilter[][] = [
    [
      {
        name: "main",
        columnSearch: "main",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "redundant",
        columnSearch: "main",
        condition: { type: "boolean", rule: false },
        active: false,
      },
    ],
    [
      {
        name: this.translate.instant("best-rssi"),
        columnSearch: "rssiGreaterThanMain",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: this.translate.instant("best-redundant-rssi"),
        columnSearch: "rssiLowerThanMain",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
  ];
  gatewayMetersQuickFiltersExclusion: boolean[] = [true, true];
  gatewayMetersColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[];

  // Tabla de contadores anidada
  meterTableRowNumbers: boolean = true;
  meterTableData: GatewayMapMeter[];
  selectedGatewayList: GatewayMinimal[] = [];
  tableCurrentMeter: GatewayTableMeter;
  meterTableColumns: (
    | TableActionColumn
    | TableSelectColumn
    | TableDataColumn
  )[] = [
    {
      title: "action",
      data: [
        {
          name: "assign-meter-gateway-main",
          tooltip: "assign-meter-gateway-main",
          icon: "fas fa-exchange-alt black",
          visible: { attribute: null, rule: true },
          disabled: "selectDisabled",
        },
        {
          name: "meter-unassign-gateway",
          tooltip: "meter-unassign-gateway",
          icon: "fas fa-exchange-alt",
          visible: { attribute: null, rule: true },
          disabled: "selectDisabled",
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
      disabled: "main",
    },
    {
      title: "main",
      data: "main",
      search: "main",
      sort: "main",
      alter: {
        condition: "main",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
      link: "gatewayLink",
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "RSSI (dBm)",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "SNR (dB)",
      data: "snrParsed",
      search: "snrParsed",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "last-frames-received",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      visible: true,
    },
  ];

  // Cambio de localización
  mapGateway: Gateway[];
  changeLocationActive: boolean = false;
  changeLocationMapType: string = "changeLocation";
  gatewayNewLocation: { lat: number; lng: number };

  // Modal de selección de contadores
  deallocatedMetersSelected: number[];
  metersRange: number;
  selectionMapActive: boolean = false;
  hideSelectionMap: boolean = false;
  metersInRangeSelect: MaterialSelectOption[] = [
    { value: 0, name: "200m" },
    { value: 1, name: "300m" },
    { value: 2, name: "400m" },
    { value: 3, name: "500m" },
    { value: 4, name: "1km" },
  ];
  showMeterSelectFileColumns: boolean = false;
  meterSelectFileColumns: MaterialSelectOption[];
  meterSelectFileColumnIndex: number;
  meterSelectColumns: MaterialSelectOption[];
  meterSelectColumnIndex: number;
  fileFilterInverted: boolean = false;

  // Gráfica
  graphSeries: object[];
  graphData: GatewayGraphData;
  mongoGraphData: GatewayMongoGraphData[];
  highchartsOptions: object;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("6");
  graphTemperatureActive: boolean = false;
  graphNoiseActive: boolean = false;
  from: string;
  to: string;
  colorByPoint: GraphColorByPoint;

  // Mapa de selección de contadores
  allocateMapType: string = "meterAssignable";
  allocateMapHeight: number = window.innerHeight - 520;
  metersSelectionActive: boolean = false;
  @ViewChild("meterSelectionMap")
  meterSelectionMapController: MapControllerComponent;
  deallocatedMetersList: AssignableMeter[];
  deallocateRedundantList: GatewayMapMeter[];
  deallocateRedundantSelected: number[];
  selectionMapMeters: (GatewayMapMeter | AssignableMeter)[];
  selectFileData: any[];
  activateAllLayers: boolean;

  // Puntos finales
  endPointsTotal: number | string;
  gatewayAlarms: any[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private SuspicionsService: SuspicionsService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GatewayService: GatewayService,
    private GatewayController: GatewayControllerService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private GraphOptionsService: GraphOptionsService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getData();
    this.getTableData();
    this.loadGraph();
  }

  // Obtención de los datos de las tarjetas y el mapa
  getData(): void {
    this.GatewayController.getMarkers(
      this.gatewayId,
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let gatewayAlarms = response["body"]["activeAlarms"];
        if (gatewayAlarms != null) {
          gatewayAlarms.forEach((alarm: DetailDeviceAlarm) => {
            alarm.initDateParsed = this.DateParserService.parseDate(
              alarm.initDate,
              this.dateFormat + " HH:mm:ss"
            );
            alarm.code != null
              ? (alarm.name = this.translate.instant(
                  "GatewayAlarm" + alarm.code
                ))
              : "";
          });
        }
        this.gatewayAlarms =
          this.SessionDataService.getCurrentProfile() == PROFILES.ARSON &&
          this.SessionDataService.getCurrentSuspicionActive()
            ? gatewayAlarms
            : this.SuspicionsService.filterSuspicions(gatewayAlarms);
        let mainMeters: GatewayMapMeter[] = response["body"]["mainMeters"];
        let redundantMeters: GatewayMapMeter[] =
          response["body"]["redundantMeters"];
        this.gateway = response["body"]["gateway"];
        let mapGateways: Gateway[] = response["body"]["entityGateways"];
        this.SessionDataService.sendWeatherCoords({
          latitude: this.gateway.latitude,
          longitude: this.gateway.longitude,
          agrupation: this.currentAgrupation.name,
        });
        mapGateways.map((gateway: Gateway) => (gateway.other = true));
        this.mapGateways = [this.gateway].concat(mapGateways);
        mainMeters.map((meter: GatewayMapMeter) => (meter.main = true));
        redundantMeters.map((meter: GatewayMapMeter) => (meter.main = false));
        this.deallocateRedundantList = [...redundantMeters];
        this.mapMeters = mainMeters.concat(redundantMeters);
        this.getMeterSelectionDropdown(
          this.gatewayId,
          this.currentAgrupation.id
        );
        this.gateway.installation != null
          ? (this.gateway.installationParsed = this.DateParserService.parseDate(
              this.gateway.installation,
              this.dateFormat + " HH:mm"
            ))
          : "-";
        if (this.gateway?.lastFile != null) {
          this.gateway.lastFile.lastFileStateText =
            this.gateway.lastFile.fileState != null
              ? this.translate.instant(
                  "fileState" + this.gateway.lastFile.fileState
                )
              : this.translate.instant("unknown");
        }
        this.gateway.sfList = response["body"]["dataRateList"];
        this.gateway.mainMeters = mainMeters.length;
        this.gateway.redundantMeters = redundantMeters.length;
        this.gateway.communicate = response["body"]["comunica"];
        this.gateway.stateParsed = this.gateway.state
          ? this.translate.instant(GATEWAY_STATES[this.gateway.state])
          : null;
        // Canales
        let canalesBinary = this.gateway.canales?.toString(2);
        this.gateway.canalesParsed = canalesBinary
          ?.split("")
          ?.filter((value) => value).length;
        // Actualización de opciones de menú de panel de contadores
        if (this.gateway.state == GATEWAY_STATES.ACTIVATED) {
          this.tableGlobalActions.unshift({
            title: "select-meters",
            icon: "fas fa-tasks",
          });
        }

        // Actualización de botones de tabla de contadores
        if (this.gatewayMetersList?.length > 0) {
          this.gatewayMetersList.map((meter) => {
            meter.actionsDisabled =
              meter.main || this.gateway?.state != GATEWAY_STATES.ACTIVATED;
            meter.refreshDisabled =
              this.gateway?.state != GATEWAY_STATES.ACTIVATED;
          });
        }

        this.snifferListActive = response["body"]["haveSnifferReceivedMeters"];
        this.setPanelMenuOptions();
        this.getGatewayCards();
      }
    });
  }

  // Mostrar datos del gateway
  showGateway(gateway: Gateway): void {
    let gatewayOptions: GatewayOptions;
    if (this.gateway?.options) {
      gatewayOptions = this.GatewayVersionParserService.getOptions(
        this.gateway.options
      );
    }

    let gatewayData = [
      {
        title: this.translate.instant("serial-number"),
        text: gateway.nroSerie,
      },
      { title: "Amei", text: gateway.amei },
      {
        title: this.translate.instant("version-firmware"),
        text: gateway.fwVersion,
      },
      {
        title: this.translate.instant("version-hardware"),
        text: gateway.hwVersion,
      },
      {
        title: this.translate.instant("band"),
        text: this.GatewayVersionParserService.getBand(this.gateway.banda),
      },
      {
        title: this.translate.instant("micro"),
        text: this.GatewayVersionParserService.getMicro(this.gateway.micro),
      },
      {
        title: "IP",
        text: gateway.ip,
      },
      {
        title: "ICCID",
        text: gateway.iccid,
      },
      {
        title: this.translate.instant("gateway-options-mramqspi"),
        text: gatewayOptions?.mramQspi
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: this.translate.instant("gateway-options-mramspi"),
        text: gatewayOptions?.mramSpi
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: this.translate.instant("gateway-options-gps"),
        text: gatewayOptions?.gps
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: this.translate.instant("gateway-options-port"),
        text: gatewayOptions?.localPort
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: this.translate.instant("gateway-options-debug"),
        text: gatewayOptions?.debug
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: "LTE",
        text: gatewayOptions?.lte
          ? this.translate.instant("yes")
          : this.translate.instant("no"),
        hidden: this.gateway?.options == null,
      },
      {
        title: this.translate.instant("channels"),
        text:
          this.gateway?.canales == 0
            ? this.translate.instant("no-configured")
            : this.gateway?.canalesParsed,
      },
      {
        title: this.translate.instant("installation-date"),
        text: gateway.installationParsed,
      },
      {
        title: this.translate.instant("comments"),
        text: gateway.comments,
      },
    ];
    this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
      action: "detail",
      gateway: this.gateway,
      gatewayData: gatewayData,
      gatewayImage: this.gateway.gatewayImage,
    });
  }

  // Mostrar modal de edición de gateway
  editGatewayData(): void {
    this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
      action: "edit",
      gateway: this.gateway,
      fileName: this.gateway.gatewayImage,
      gatewayImage: this.gateway.gatewayImage,
      finalImageSent: null,
      updateImage: false,
    });
  }

  // Reseteo de gateway
  resetGateway(gatewayId: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("question-gateway-reset")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.resettingGateway = true;
        this.GatewayController.resetGateway(gatewayId).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("gateway-reset")
            );
          }
          this.resettingGateway = false;
        });
      }
    });
  }

  // Mostrar modal de sustitución de gateway
  replaceGatewayModal(): void {
    let gatewayList = [];

    // Obtención de los gateways pendientes
    this.GatewayController.getEntityAvailableGateways(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"] != null) {
        gatewayList = response["body"];
      }
      this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
        action: "replace",
        gateway: this.gateway,
        gatewayList: gatewayList,
        gatewayReplaceDelete: false,
      });
    });
  }

  // Obtención del total de puntos finales del gateway
  getEndPointsTotal(): void {
    this.GatewayController.getEndPointsTotal(this.gatewayId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.endPointsTotal =
            response["body"] != null
              ? response["body"]
              : this.translate.instant("unknown");
          this.ToastService.fireToastWithConfirmation(
            "info",
            this.translate.instant("end-points-total") +
              ": " +
              this.endPointsTotal
          );
        }
      }
    );
  }

  // Actualización de dispositivos de gateway
  saveGatewayDevices(): void {
    this.GatewayService.saveGatewayDevices(
      this.gateway,
      this.deallocatedMetersSelected,
      this.deallocatedMetersList,
      this.deallocateRedundantSelected,
      this.deallocateRedundantList
    ).then(() => (this.metersSelectionActive = false));
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "logs",
        icon: "fas fa-list-alt",
        text: this.translate.instant("Logs"),
        visible: true,
        submenu: [
          {
            action: "datalogger",
            icon: "fas fa-list-alt",
            text: this.translate.instant("Datalogger"),
            visible: true,
          },
          {
            action: "event-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("event-log"),
            visible: true,
          },
          {
            action: "reset-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("reset-log"),
            visible: true,
          },
          {
            action: "frames-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("frames-logs"),
            visible: true,
          },
          {
            action: "user-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("user-log"),
            visible: true,
          },
          {
            action: "tasks-log",
            icon: "fas fa-list-alt",
            text: this.translate.instant("tasks-log"),
            visible: true,
          },
        ],
      },
      {
        action: "updates",
        icon: "fas fa-edit",
        text: this.translate.instant("update"),
        visible: true,
        submenu: [
          {
            action: "change-location",
            icon: "fas fa-compass",
            text: this.translate.instant("change-location"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "update",
            icon: "fas fa-sync-alt",
            text: this.translate.instant("update-firmware"),
            visible: true,
          },
          {
            action: "update-appeui",
            icon: "fas fa-broadcast-tower",
            text: this.translate.instant("update-appeui"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "relaunch-frames",
            icon: "fas fa-wave-square",
            text: this.translate.instant("frames-relaunch"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "tasks",
            icon: "fas fa-tasks",
            text: this.gateway?.inMaintenance
              ? this.translate.instant("gateway-without-tasks")
              : this.translate.instant("gateway-pending-tasks"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "change-entity",
            icon: "fas fa-sitemap",
            text: this.translate.instant("change-entity"),
            visible: this.gateway?.state == 1 ? true : null,
          },
          {
            action: "refresh-gateway",
            icon: "fas fa-tachometer-alt",
            text: this.translate.instant("gateway-refresh"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "assign-main-redundant",
            icon: "fas fa-tachometer-alt",
            text: this.translate.instant("assign-main-redundant"),
            visible:
              this.gateway?.state == GATEWAY_STATES.ACTIVATED ? true : null,
            bottom: true,
          },
          {
            action: "assign-main-redundant-deactivated",
            icon: "fas fa-tachometer-alt",
            text: this.translate.instant("assign-main-redundant"),
            visible:
              this.gateway?.state == GATEWAY_STATES.ACTIVATED ? null : true,
            bottom: true,
          },
          {
            action: "update-channels",
            icon: "fas fa-satellite-dish",
            text: this.translate.instant("update-channels"),
            visible: true,
          },
          {
            action: "change-state",
            icon: "fas fa-arrows-alt-h",
            text: this.translate.instant("change-state"),
            visible: this.gateway?.state >= 2 ? true : null,
          },
          {
            action: "gateway-replace",
            icon: "fas fa-exchange-alt",
            text: this.translate.instant("gateway-replace"),
            visible:
              this.gateway?.latitude != null && this.gateway?.longitude != null,
          },
          {
            action: "reset",
            icon: "fas fa-undo-alt",
            text: this.translate.instant("reset"),
            visible: true,
          },
        ],
      },
      {
        action: "configure",
        icon: "fas fa-cog",
        text: this.translate.instant("configure"),
        visible: true,
      },
      {
        action: "management",
        icon: "fas fa-tools",
        text: this.translate.instant("management"),
        visible: true,
      },
      {
        action: "alarms",
        icon: "fas fa-exclamation-triangle",
        text: this.translate.instant("alarms"),
        visible: true,
      },
      {
        action: "end-points-total",
        icon: "fas fa-tachometer-alt",
        text: this.translate.instant("end-points-total"),
        visible: true,
      },
      {
        action: "delete",
        icon: "fas fa-eraser",
        text: this.translate.instant("gateway-delete"),
        visible: this.gateway?.state == GATEWAY_STATES.IN_STOCK ? true : null,
        highlight: true,
        bottom: true,
      },
      {
        action: "delete-development",
        icon: "fas fa-eraser",
        text:
          this.translate.instant("gateway-delete") +
          " (" +
          this.translate.instant("gateway-dev-delete") +
          ")",
        visible: !ENVIRONMENT.production ? true : null,
        highlight: true,
        bottom: true,
      },
      {
        action: "coverage-contrast",
        icon: "fas fa-map-marked-alt",
        text: this.translate.instant("coverage-contrast"),
        visible:
          this.gateway?.latitude != null && this.gateway?.longitude != null,
      },
      {
        action: "operator-historical",
        icon: "fas fa-sim-card",
        text: this.translate.instant("gateway-operator-historical"),
        visible: true,
      },
      {
        action: "location-historical",
        icon: "fas fa-map-marker-alt",
        text: this.translate.instant("location-historical"),
        visible: true,
      },
      {
        action: "load",
        icon: "fas fa-battery-three-quarters",
        text: this.translate.instant("load"),
        visible: true,
      },
      {
        action: "sniffer-list",
        icon: "fas fa-bullseye",
        text: this.translate.instant("sniffer-list"),
        visible:
          this.snifferListActive && this.sessionProfile == PROFILES.ARSON,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "configure":
        this.router.navigate(
          ["/gateways/detalle/configurar/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "alarms":
        this.router.navigate(["/gateways/detalle/alarmas/" + this.gatewayId], {
          state: { data: this.gateway.unidadVenta },
        });
        break;
      case "coverage-contrast":
        this.router.navigate(
          ["/gateways/detalle/contraste-cobertura/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "change-location":
        this.mapGateway = [this.gateway];
        this.changeLocationActive = !this.changeLocationActive;
        break;
      case "tasks":
        this.markWithTasks(this.gateway?.inMaintenance == true ? false : true);
        break;
      case "change-entity":
        this.MaterialDialogService.openDialog(GatewayInstockDialogComponent, {
          action: "changeEntity",
          entity: this.currentEntity.id,
          gateways: [this.gateway.id],
        });
        break;
      case "change-state":
        this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
          action: "changeState",
          gateway: this.gateway,
        });
        break;
      case "update-channels":
        this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
          action: "updateChannels",
          gateway: this.gateway,
        });
        break;
        break;
      case "refresh-gateway":
        this.GatewayService.refreshGatewayAsking(this.gateway);
        break;
      case "relaunch-frames":
        this.router.navigate(
          ["/gateways/detalle/relanzar-tramas/" + this.gatewayId],
          { state: { data: this.gateway } }
        );
        break;
      case "update-appeui":
        this.GatewayService.updateAppEui(this.gateway);
        break;
      case "end-points-total":
        this.getEndPointsTotal();
        break;
      case "tasks-log":
        this.router.navigate(["/mantenimiento/tareas-gateways"], {
          state: { data: this.gateway },
        });
        break;
      case "reset":
        this.resetGateway(this.gateway?.id);
        break;
      case "assign-main-redundant":
        this.GatewayService.assignBestRedundant(
          this.gateway,
          this.gatewayMetersList.filter((meter) => meter.main)
        );
        break;
      case "assign-main-redundant-deactivated":
        this.GatewayService.assignBestRedundantWithoutRead(this.gateway);
        break;
      case "delete":
        this.GatewayService.deleteGateway(this.gateway);
        break;
      case "delete-development":
        this.GatewayService.deleteDevelopmentGateway(this.gateway);
        break;
      case "gateway-replace":
        this.replaceGatewayModal();
        break;
      case "update":
        this.router.navigate(
          ["/gateways/detalle/actualizar/firmware/" + this.gatewayId],
          {
            state: {
              data: {
                unidadVenta: this.gateway.unidadVenta,
                hwVersion: this.gateway.hwVersion,
                fwVersion: this.gateway.fwVersion,
              },
            },
          }
        );
        break;
      case "management":
        this.router.navigate(
          ["/gateways/detalle/mantenimiento/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "load":
        this.router.navigate(["/gateways/detalle/load/" + this.gatewayId], {
          state: { data: this.gateway.unidadVenta },
        });
        break;
      case "operator-historical":
        this.router.navigate(["/gateways/detalle/operator/" + this.gatewayId], {
          state: { data: this.gateway.unidadVenta },
        });
        break;
      case "location-historical":
        this.MaterialDialogService.openDialog(GatewayDetailDialogComponent, {
          action: "location",
          gatewayId: this.gateway.id,
        });
        break;
      case "event-log":
        this.router.navigate(
          ["/gateways/detalle/log/eventos/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "user-log":
        this.router.navigate(
          ["/gateways/detalle/log/usuarios/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "sniffer-list":
        this.router.navigate(["/gateways/listado/sniffer/" + this.gatewayId]);
        break;
      case "datalogger":
        this.router.navigate(
          ["/gateways/detalle/log/datalogger/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "reset-log":
        this.router.navigate(
          ["/gateways/detalle/log/reset/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      case "frames-log":
        this.router.navigate(
          ["/gateways/detalle/log/tramas/" + this.gatewayId],
          { state: { data: this.gateway.unidadVenta } }
        );
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Tabla de contadores asociados
  /***************************************************************************/

  // Obtención de los datos de la tabla
  getTableData(): void {
    this.GatewayController.getGatewayMeters(
      this.gatewayId,
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let bModeColumn: boolean = false;
        let gatewayMetersList: GatewayTableMeter[] = response["body"];
        gatewayMetersList.forEach((meter: GatewayTableMeter) => {
          meter.main = meter.mainGateway?.id == this.gatewayId;
          if (meter.isBMode) {
            bModeColumn = true;
          }
          meter.meterLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            meter.metrologyType,
            meter.contadorId
          );
          meter.redundantGatewaysLength = meter.redundantGateways.length;
          meter.rssiGreaterThanMain = false;
          meter.rssiLowerThanMain = false;
          meter.actionsDisabled =
            meter.main || this.gateway?.state != GATEWAY_STATES.ACTIVATED;
          meter.refreshDisabled =
            this.gateway?.state != GATEWAY_STATES.ACTIVATED;
          // Contadores para los que el gateway es redundante y tiene RSSI mayor que el principal y los redundantes.
          let gatewayFound: GatewayMinimal = meter.redundantGateways?.find(
            (gateway: GatewayMinimal) => gateway.id == this.gatewayId
          );
          if (meter.mainGateway?.id != this.gatewayId) {
            let mainRssi = meter.mainGateway?.rssi
              ? meter.mainGateway?.rssi
              : -300;
            if (
              gatewayFound?.rssi &&
              gatewayFound?.rssi >= mainRssi &&
              !meter.redundantGateways?.some(
                (gateway: GatewayMinimal) =>
                  gateway.rssi && gateway.rssi > gatewayFound?.rssi
              )
            ) {
              meter.rssiGreaterThanMain = true;
            }
          }
          // Contadores para los que el gateway es principal y tiene RSSI menor que algún redundante
          if (meter.mainGateway?.id == this.gatewayId) {
            let mainRssi = meter.mainGateway.rssi
              ? meter.mainGateway.rssi
              : -300;
            if (
              meter.redundantGateways?.some(
                (gateway: GatewayMinimal) =>
                  gateway.rssi && mainRssi <= gateway.rssi
              )
            ) {
              meter.rssiLowerThanMain = true;
            }
          }
          // Tabla anidada
          if (meter.redundantGateways?.length > 0) {
            meter.extraTableData = {
              columns: this.meterTableColumns,
              highlightRow: [
                { condition: "highlightCurrentGateway", color: "green" },
              ],
              data: this.getExtraTableData(meter),
            };
            meter.secondaryTableFlag = true;
          } else {
            meter.secondaryTableFlag = false;
          }
        });
        this.setGatewayMeterTableColumns(bModeColumn);
        this.gatewayMetersList = this.sortGatewayMeterArray(gatewayMetersList);
      } else {
        this.setGatewayMeterTableColumns(false);
        this.gatewayMetersList = [];
      }
      this.setPanelMenuOptions();
    });
  }

  // Seteo de las columnas de la tabla de contadores
  setGatewayMeterTableColumns(bModeColumn: boolean): void {
    this.gatewayMetersColumns = [
      {
        title: "gateways",
        data: null,
        search: "secondaryTableFlag",
        sort: "secondaryTableFlag",
        extraTable: true,
        visible: true,
        noExport: true,
      },
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "assign-as-main-meter",
            tooltip: "assign-as-main-meter",
            icon: "fas fa-exchange-alt black",
            visible: { attribute: null, rule: true },
            disabled: "actionsDisabled",
          },
          {
            name: "meter-unassign-gateway",
            tooltip: "meter-unassign-gateway",
            icon: "fas fa-exchange-alt",
            visible: { attribute: null, rule: true },
            disabled: "actionsDisabled",
            warning: true,
          },
          {
            name: "gateway-refresh-device",
            tooltip: "gateway-refresh-device",
            icon: "fas fa-sync-alt",
            visible: { attribute: null, rule: true },
            disabled: "refreshDisabled",
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "main",
        data: "main",
        search: "main",
        sort: "main",
        alter: {
          condition: "main",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
        boolean: true,
      },
      {
        title: "serial-number",
        data: "contadorNroSerie",
        search: "contadorNroSerie",
        sort: "contadorNroSerie",
        visible: true,
        link: "meterLink",
      },
      {
        title: "SF",
        data: "contadorSFParsed",
        search: "contadorSFParsed",
        sort: "contadorSF",
        numerical: true,
        visible: true,
      },
      {
        title: "RSSI (dBm)",
        data: "contadorRssiParsed",
        search: "contadorRssiParsed",
        sort: "contadorRssi",
        numerical: true,
        visible: true,
      },
      {
        title: "b-mode",
        data: "isBMode",
        search: "isBMode",
        sort: "isBMode",
        alter: {
          condition: "isBMode",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: bModeColumn ? true : null,
        boolean: true,
      },
      {
        title: "redundant-gateway",
        data: "redundantGatewaysLengthParsed",
        search: "redundantGatewaysLengthParsed",
        sort: "redundantGatewaysLength",
        numerical: true,
        visible: true,
      },
      {
        title: "last-frames-received",
        data: "contadorRssiTimestampParsed",
        search: "contadorRssiTimestampParsed",
        sort: "contadorRssiTimestamp",
        date: true,
        visible: true,
      },
    ];
  }

  // Obtención de los datos de la tabla anidada
  getExtraTableData(meter: GatewayTableMeter): GatewayMinimal[] {
    let extraTableData: GatewayMinimal[] = [];

    if (meter.mainGateway) {
      extraTableData.push(meter.mainGateway);
      extraTableData[0].main = true;
    }

    if (meter.redundantGateways) {
      meter.redundantGateways.map(
        (gateway: GatewayMinimal) => (gateway.main = false)
      );
      extraTableData = extraTableData.concat(
        this.sortArrayByAttribute(meter.redundantGateways, "rssi")
      );
    }

    extraTableData.forEach((gateway: GatewayMinimal) => {
      gateway.gatewayLink = "/gateways/detalle/gateway/" + gateway.id;
      gateway.selectDisabled =
        gateway.main || gateway.state != GATEWAY_STATES.ACTIVATED;
      gateway.highlightCurrentGateway = gateway.id == this.gatewayId;
      gateway.stateParsed = gateway.state
        ? this.translate.instant(GATEWAY_STATES[gateway.state])
        : null;
    });

    return extraTableData;
  }

  // Ordenamiento del array de contadores
  sortGatewayMeterArray(
    gatewayMetersList: GatewayTableMeter[]
  ): GatewayTableMeter[] {
    let mainMeters: GatewayTableMeter[] = [];
    let redundantMeters: GatewayTableMeter[] = [];
    gatewayMetersList.forEach((meter: GatewayTableMeter) => {
      if (meter.mainGateway?.id == this.gatewayId) {
        mainMeters.push(meter);
      } else {
        redundantMeters.push(meter);
      }
    });

    if (mainMeters.length > 0) {
      mainMeters = this.sortArrayByAttribute(mainMeters, "contadorRssi");
    }
    if (redundantMeters.length > 0) {
      redundantMeters = this.sortArrayByAttribute(
        redundantMeters,
        "contadorRssi"
      );
    }

    return mainMeters.concat(redundantMeters);
  }

  // Ordenamiento de array por atributo
  sortArrayByAttribute(array: any[], attribute: string): any[] {
    array.sort((a, b) => {
      if (!a[attribute] && !b[attribute]) {
        return 0;
      } else if (!a[attribute]) {
        return 1;
      } else if (!b[attribute]) {
        return -1;
      } else {
        return b[attribute] - a[attribute];
      }
    });
    return array;
  }

  // Acciones de la tabla de contadores asociados al gateway
  gatewayMetersListTableActions(
    action: string,
    meter: GatewayTableMeter
  ): void {
    switch (action) {
      case "show-detail":
        this.DeviceRouteSelectorService.getDeviceRoute(
          meter.metrologyType,
          meter.contadorId
        );
        break;
      case "assign-as-main-meter":
        this.GatewayService.allocateMeterAsMain(
          this.gateway,
          null,
          meter.contadorId
        );
        break;
      case "meter-unassign-gateway":
        this.GatewayService.deallocateMeter(
          this.gateway,
          null,
          meter.contadorId
        );
        break;
      case "gateway-refresh-device":
        this.GatewayService.refreshDevice(this.gateway.id, meter.contadorId);
        break;
      default:
        break;
    }
  }

  // Actualización de los datos seleccionados en la tabla
  selectedDataUpdate(data: GatewayTableMeter[]): void {
    this.selectedMeterList = [...data];
  }

  // Tabla anidada

  // Acciones de la tabla de gateways asociados a contador
  meterGatewaysListTableActions(
    action: string,
    gateway: GatewayMinimal,
    currentMeter: GatewayTableMeter
  ): void {
    this.tableCurrentMeter = currentMeter;

    switch (action) {
      case "assign-meter-gateway-main":
        this.GatewayService.allocateGateway(
          gateway,
          this.tableCurrentMeter.contadorId
        );
        break;
      case "meter-unassign-gateway":
        this.GatewayService.deallocateGateway(
          [gateway],
          this.tableCurrentMeter.contadorId
        );
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "select-meters":
        this.selectMeters();
        break;
      case "meters-assign-gateway":
        this.GatewayService.allocateMeterAsMain(
          this.gateway,
          this.selectedMeterList
        );
        break;
      case "meters-unassign":
        this.GatewayService.deallocateMeter(
          this.gateway,
          this.selectedMeterList
        );
        break;
      case "gateway-unassign":
        this.GatewayService.deallocateGateway(
          this.selectedGatewayList,
          this.tableCurrentMeter.contadorId
        );
        break;
      case "assign-best-gateway":
        this.GatewayService.assignBestGateway(this.selectedMeterList);
        break;
      case "clean-redundant-gateways":
        this.GatewayService.cleanRedundantGateways(this.selectedMeterList);
        break;
      default:
        break;
    }
  }

  // Actualización de los datos seleccionados en la tabla anidada
  extraSelectedDataUpdate(
    currentMeter: GatewayTableMeter,
    gatewayList: GatewayMinimal[]
  ): void {
    this.tableCurrentMeter = currentMeter;
    this.selectedGatewayList = gatewayList;
  }

  // Reseteo de las selecciones de las tablas
  resetSelection(): void {
    this.selectedMeterList = [];
    this.selectedGatewayList = [];
  }

  /***************************************************************************/
  // ANCHOR Panel de selección de contadores
  /***************************************************************************/

  // Obtención del desplegable de contadores
  getMeterSelectionDropdown(gatewayId: number, agrupationId: number): void {
    this.GatewayController.meterDropdown(gatewayId, agrupationId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.deallocatedMetersList = response["body"];
          this.mapMeters = this.mapMeters.concat(
            this.deallocatedMetersList.map((meter: AssignableMeter) => {
              meter.assignable = true;
              return meter;
            })
          );
          this.selectionMapMeters = [
            ...this.deallocatedMetersList,
            ...this.deallocateRedundantList,
          ];
          this.activateAllLayers = !this.activateAllLayers;
        }
      }
    );
  }

  // Selección de contadores
  selectMeters(): void {
    this.deallocatedMetersSelected = null;
    this.deallocateRedundantSelected = null;
    this.metersRange = null;
    this.metersSelectionActive = true;
    setTimeout(() => {
      this.viewportScroller.setOffset([0, 150]);
      this.viewportScroller.scrollToAnchor("gateway-detail-selection-panel");
      this.viewportScroller.setOffset([0, 0]);
    }, 0);
  }

  // Obtención de los contadores en el rango seleccionado
  getMetersInRange(): void {
    let deallocatedMetersSelected = [];
    if (this.metersRange != null) {
      this.deallocatedMetersList.forEach((meter: AssignableMeter) => {
        if (meter.radioId != null && meter.radioId <= this.metersRange) {
          meter.selected = true;
          deallocatedMetersSelected.push(meter.id);
        }
      });
    }

    if (this.metersRange != null && deallocatedMetersSelected.length == 0) {
      this.ToastService.fireToast(
        "info",
        this.translate.instant("no-elements")
      );
    } else {
      this.deallocatedMetersSelected = deallocatedMetersSelected;
      this.meterSelectionMapController?.updateSelected();
    }
  }

  // Selección de dispositivo en mapa
  updateMeterSingleSelection(
    meterSelected: GatewayMapMeter | AssignableMeter
  ): void {
    meterSelected.selected = !meterSelected.selected;
    this.meterSelectionMapController.updateSelected();
    this.updateSelectedFromMap();
  }

  // Actualización de selección
  updateSelectedFromSelects(
    selection: (GatewayMapMeter | AssignableMeter)[],
    assign: boolean
  ): void {
    if (assign) {
      this.deallocatedMetersSelected = selection.map((meter) => meter.id);
    } else {
      this.deallocateRedundantSelected = selection.map((meter) => meter.id);
    }
    this.selectionMapMeters.map((meter) => {
      meter.selected =
        this.deallocateRedundantSelected?.includes(meter.id) ||
        this.deallocatedMetersSelected?.includes(meter.id);
    });
    this.meterSelectionMapController?.updateSelected();
  }

  // Actualización de selección
  updateSelectedFromMap(): void {
    this.deallocatedMetersSelected = this.deallocatedMetersList
      .filter((meter) => meter.selected)
      .map((meter) => meter.id);
    this.deallocateRedundantSelected = this.deallocateRedundantList
      .filter((meter) => meter.selected)
      .map((meter) => meter.id);
  }

  // Visualización del mapa de selección en dos pasos para evitar error de clúster
  showSelectionMap(): void {
    if (!this.selectionMapActive) {
      this.selectionMapActive = true;
      setTimeout(() => {
        this.meterSelectionMapController?.updateSelected();
      }, 0);
    } else {
      this.hideSelectionMap = !this.hideSelectionMap;
    }
  }

  // Selección de contadores por portapapeles
  selectByFile(): void {
    navigator.clipboard.readText().then((file) => {
      let rows: any = file.split("\n");
      rows = rows.map((row: string) => {
        return row.split(";").map((element: any) => element.replace("\r", ""));
      });
      let columns = [...rows[0]];
      this.selectFileData = [...rows.slice(1)];
      if (columns.length > 0 && this.deallocatedMetersList?.length > 0) {
        this.meterSelectFileColumns = columns.map((column: any, i) => {
          return { value: i, name: column };
        });
        this.meterSelectColumns = Object.keys(
          this.deallocatedMetersList[0]
        ).map((key: any, i) => {
          return { value: key, name: key };
        });
        this.showMeterSelectFileColumns = true;
      }
    });
  }

  // Filtrado de contadores por portapapeles
  filterSelectByFile(): void {
    let selected = this.deallocateRedundantSelected
      ? [...this.deallocateRedundantSelected]
      : [];
    this.selectFileData.forEach((data) => {
      let meterFound = this.deallocatedMetersList.find(
        (meter: AssignableMeter) =>
          meter[this.meterSelectColumnIndex] ==
          data[this.meterSelectFileColumnIndex]
      );
      if (meterFound && !selected?.includes(meterFound.id)) {
        selected.push(meterFound.id);
      }
    });
    this.deallocatedMetersSelected = selected;
    // this.updateMapMetersSelected();
    this.meterSelectionMapController?.updateSelected();
    this.showMeterSelectFileColumns = false;
  }

  /***************************************************************************/
  // ANCHOR Cambio de estado del gateway
  /***************************************************************************/

  markWithTasks(tasks: boolean): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("action-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.markWithTasks(this.gatewayId, tasks).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("state-change")
              );
              this.ReloadComponentService.reload();
            }
          }
        );
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de gateway
  /***************************************************************************/

  // Creación de las tarjetas de gateway
  getGatewayCards(): void {
    this.gatewayCardsData = {
      gatewayDetailMainMeters: {
        data: this.gateway.mainMeters,
        type: "number",
      },
      gatewayDetailRedundantMeters: {
        data: this.gateway.redundantMeters,
        type: "number",
      },
      gatewayDetailRssi: {
        rssi: this.gateway.lastRssi,
        battery: this.gateway.lastVbat,
      },
      gatewayDetailLastCommunication: {
        lastCommunication: this.DateParserService.parseDate(
          this.gateway.lastCommunication,
          this.dateFormat + " HH:mm"
        ),
        communicate: this.gateway.communicate,
        background: this.gateway.communicate
          ? "bg-green-gradient"
          : "bg-red-gradient",
      },
      gatewayDetailSf: {
        data: this.gateway.sfList.map((sf: Sf) => {
          return { title: "SF" + sf.dataRate + ":", data: sf.percentage + "%" };
        }),
        infoBoxNumber: "",
        type: "list",
      },
      gatewayDetailLastHello: {
        data: this.DateParserService.parseDate(
          this.gateway.lastHello,
          this.dateFormat + " HH:mm"
        ),
        type: "text",
      },
      gatewayDetailLastFile: {
        data: this.gateway.lastFile?.lastFileStateText,
        date: this.gateway.lastFile?.timestamp,
        background:
          this.gateway.lastFile?.fileState == 0
            ? "bg-green-gradient"
            : "bg-red-gradient",
        link: ["/gateways/detalle/files/" + this.gatewayId],
        state: { data: this.gateway?.unidadVenta },
      },
      gatewayDetailOperator: {
        data: this.gateway.operadora,
        detail:
          "PLMN: " +
          (this.gateway.plmn
            ? this.gateway.plmn
            : this.translate.instant("unknown")),
        type: "text",
        link: ["/gateways/detalle/operator/" + this.gatewayId],
      },
    };
  }

  /***************************************************************************/
  // ANCHOR Mapa
  /***************************************************************************/

  // Acciones del mapa
  mapAction(action: string, device: Gateway | GatewayMapMeter): void {
    switch (action) {
      case "reset":
        this.reset(device.id);
        break;
      case "echo":
        this.echo(device.id);
        break;
      case "allocateDevice":
        this.GatewayService.allocateMeters(
          this.gateway,
          [device.id],
          this.deallocatedMetersList
        );
        break;
      case "allocateDeviceAsMain":
        this.GatewayService.allocateMeterAsMain(this.gateway, null, device.id);
        break;
      case "deallocateDevice":
        this.GatewayService.deallocateMeter(this.gateway, null, device.id);
        break;
      default:
        break;
    }
  }

  // Acción de reset del gateway
  reset(id: number): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("question-gateway-reset-from")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: GatewayEcho = {
          sourceGwId: id,
          targetGwId: this.gatewayId,
          commandCode: 1,
        };

        this.GatewayController.resetEcho(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("reset-sucessfull")
            );
          }
        });
      }
    });
  }

  // Acción de echo del gateway
  echo(id: number): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("echo-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: GatewayEcho = {
          sourceGwId: id,
          targetGwId: this.gatewayId,
          commandCode: 2,
        };

        this.GatewayController.resetEcho(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("echo-sucessfull")
            );
          }
        });
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.graphData = {
      vbats: [],
      charge: [],
      temperature: [],
      noise: [],
    };

    this.graphNoiseActive = true;
    this.showNoise();
    // Gráfica de tensión
    this.GatewayController.getMongoGraph(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      let mongoGraphData: GatewayMongoGraphData[] = [];
      if (response["code"] === 0) {
        mongoGraphData = response["body"]["statusGwList"];
      }
      this.mongoGraphData = mongoGraphData;
      this.mongoGraphData
        .sort((a, b) => a.tm - b.tm)
        .map((data: GatewayMongoGraphData) => {
          this.graphData.vbats.push([data.tm, data.vbat]);
          this.graphData.charge.push([
            data.tm - 3600000,
            data.carga,
            data.luz,
            data.parcial,
          ]);
          this.graphData.temperature.push([data.tm, data.temp]);
        });

      this.getSeries();

      // Gráfica de ruido
      this.getNoiseData();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    const self = this;
    let vbatsSeries: object[] = [];
    let chargeSeries: object[] = [];
    let graphSeries: object[] = [];
    let temperatureSeries: object[] = [];
    let noiseSeries: object[] = [];

    // Serie de valores de tensión
    if (!this.graphNoiseActive && this.graphData.vbats?.length > 0) {
      vbatsSeries = [
        {
          id: "tension",
          name: this.translate.instant("tension"),
          type: "line",
          navigatorOptions: {
            type: "line",
          },
          tooltip: {
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueSuffix: " v",
            valueDecimals: 3,
          },
          data: this.graphData.vbats,
          color: "#ef5350",
          zIndex: 1,
        },
      ];
    }

    // Serie de valores de carga
    if (!this.graphNoiseActive && this.graphData.charge?.length > 0) {
      // Serie
      chargeSeries = [
        {
          id: "carga",
          name: this.translate.instant("load"),
          type: "column",
          navigatorOptions: {
            type: "column",
          },
          tooltip: {
            valueDecimals: 3,
            pointFormatter: function () {
              return (
                `<span style="color:` +
                this.color +
                `">` +
                this.series.name +
                "</span>: <b>" +
                Highcharts.numberFormat(this.y, 3) +
                ` mAh</b> |
                      <span style="color:` +
                this.color +
                `"> ` +
                self.translate.instant("time-light") +
                "</span>: <b>" +
                Math.floor(this.series.options.data[this.index][2] / 60)
                  .toString()
                  .padStart(2, "0") +
                ":" +
                (this.series.options.data[this.index][2] % 60)
                  .toString()
                  .padStart(2, "0") +
                "</b><br>"
              );
            },
          },
          data: this.graphData.charge,
          color: "#42a5f5",
          yAxis: 1,
        },
      ];
    }

    // Serie de valores de temperatura
    if (
      !this.graphNoiseActive &&
      this.graphTemperatureActive &&
      this.graphData.temperature?.length > 0
    ) {
      temperatureSeries = [
        {
          id: "temperatura",
          name: this.translate.instant("temperature"),
          type: "line",
          marker: {
            symbol: "square",
          },
          tooltip: {
            valueDecimals: 0,
            pointFormat:
              '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            valueSuffix: "°C ",
          },
          data: this.graphData.temperature,
          color: "#ff9800",
          yAxis: 2,
        },
      ];
    }

    // Serie de valores de ruido
    if (this.graphNoiseActive) {
      this.graphData.noise.forEach(
        (noiseData: { name: string; data: number[][]; color: string }) => {
          noiseSeries.push({
            id: noiseData.name,
            name: noiseData.name,
            type: "line",
            tooltip: {
              valueDecimals: 0,
              pointFormat:
                "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
              valueSuffix: "dBm ",
            },
            data: noiseData.data,
            color: noiseData.color,
          });
        }
      );
    }

    graphSeries = this.graphNoiseActive
      ? noiseSeries
      : vbatsSeries.concat(chargeSeries).concat(temperatureSeries);
    this.graphSeries = graphSeries;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("gateways-export")
      );
    highchartsOptions.scrollbar = { enabled: false };
    highchartsOptions.plotOptions.column.pointWidth = 5;
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    delete chartOptions["yAxis"];
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    let yAxis: object[];

    if (this.graphNoiseActive) {
      yAxis = [
        {
          opposite: false,
          labels: {
            format: "{value} dBm",
            style: {
              color: "{color}",
            },
          },
          title: {
            text: this.translate.instant("noise"),
            style: {
              color: "#000",
              fontWeight: "bold",
            },
          },
          min: -130,
          max: -65,
          visible: true,
        },
      ];
    } else {
      yAxis = [
        // Tensión
        {
          opposite: false,
          labels: {
            format: "{value} v",
            style: {
              color: "#ef5350",
            },
          },
          title: {
            text: this.translate.instant("battery-tension"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          min: 0,
          max:
            Math.ceil(
              Math.max.apply(
                Math,
                this.graphData.vbats.map((vbat: number[]) => vbat[1])
              )
            ) + 1,
          visible: true,
        },
        // Batería
        {
          title: {
            text: this.translate.instant("battery-load"),
            style: {
              color: "#42a5f5",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} mAh ",
            style: {
              color: "#42a5f5",
            },
          },
          min: 0,
          visible: true,
          opposite: true,
        },
        // Temperatura
        {
          title: {
            text: this.translate.instant("temperature"),
            style: {
              color: "#ff9800",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} °C ",
            style: {
              color: "#ff9800",
            },
          },
          visible: false,
          opposite: true,
        },
      ];
    }
    chartOptions["yAxis"] = yAxis;
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
    setTimeout(() => this.setPartialsColor(), 0);
  }

  // Color resaltado para valores de carga parciales
  setPartialsColor(): void {
    if (!this.graphNoiseActive && this.graphData.charge?.length > 0) {
      // Valores parciales
      this.colorByPoint = {
        serie: 1,
        color: "#f9d71c",
        conditionIndex: 3,
        conditionValue: 1,
        legend:
          `<div>
            <i class="fas fa-circle" style="color:#f9d71c; margin-right: 0.5rem;"></i>
            <span>` +
          this.translate.instant("partial-values") +
          `</span>
          </div>`,
      };
    }
  }

  // Reseteo de la gráfica
  showTemperature(): void {
    this.graphTemperatureActive = !this.graphTemperatureActive;
    this.getSeries();
    this.chartOptions["yAxis"][2].visible = this.graphTemperatureActive;
  }

  // Reseteo de gráfica
  showNoise(): void {
    this.graphTemperatureActive = false;
    this.graphNoiseActive = !this.graphNoiseActive;
    this.getSeries();
  }

  // Obtención de los datos de ruido
  getNoiseData(): void {
    this.GatewayController.getGraphNoise(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let noiseChannels = response["body"];
        let noiseData: object[] = [];
        if (noiseChannels) {
          for (let channel in noiseChannels) {
            if (noiseChannels[channel].length > 0) {
              noiseData.push({
                name:
                  this.translate.instant("channel") +
                  " " +
                  channel.replace("channel", ""),
                data: noiseChannels[channel],
              });
            }
          }
        }
        this.graphData.noise = noiseData;
      }
    });
  }
}
