<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "data-preload" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
            <button
              *ngIf="
                selectedManufacturer == DEVICE_BY_COMM.LW ||
                selectedManufacturer == DEVICE_BY_COMM.MBUS
              "
              mat-button
              class="mat-button-icon-text device-import-kem2-button"
              (click)="kem2File()"
            >
              {{ "kem2-converter" | translate }}
            </button>
          </h4>
          <h5 class="device-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileChange)="resetFileVariables(); checkFile($event)"
            (fileReady)="importDevice()"
          ></app-file-import>
        </div>

        <app-import-table
          [importColumns]="importColumns"
          [importPreselects]="importPreselects"
          (tableActionFlag)="tableActions($event.action, $event.data)"
        >
        </app-import-table>

        <!-- Resultado -->
        <div *ngIf="showResult" class="device-import-result">
          <hr />
          <h5>
            <b>{{ "readings-rows-success" | translate }}:</b>
            {{ successDevices }}
          </h5>
          <div>
            <h5>
              <b>{{ "readings-rows-error" | translate }}:</b>
              {{ errorData?.length }}
            </h5>
            <!-- Descargar csv de errores -->
            <div>
              <button
                *ngIf="showTable"
                mat-button
                class="mat-button-icon-text"
                (click)="downloadErrorFile()"
                [disabled]="!errorFile"
              >
                <i class="fas fa-download"></i>
                {{ "download-file-error" | translate }}
              </button>
              <!-- Reintentar fallidos -->
              <button
                *ngIf="showTable"
                mat-button
                class="mat-button-icon-text"
                (click)="retry()"
                [disabled]="!errorFile"
              >
                <i class="fas fa-redo-alt"></i>
                {{ "retry-failed" | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- Errores -->
        <div *ngIf="showTable" class="device-import-errors">
          <!-- Tabla de resultados -->
          <app-table-controller
            id="import-errors"
            [tableId]="'metersImport'"
            [maxReg]="100"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [data]="errorData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
