// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { OutputFilesControllerService } from "../../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { FileTemplate } from "../../OutputFileInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-templateFiles",
  templateUrl: "./output-files-templates-list.component.html",
  styleUrls: ["./output-files-templates-list.component.scss"],
})
export class TemplateFilesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  entityDefinitions: EntityDefinition[];
  entityDefinitionsSub: Subscription;

  // Botón nuevo
  newIcon = "fas fa-plus";
  newTitle = this.translate.instant("new-template");

  // Table
  types = {
    0: this.translate.instant("closest value"),
    1: this.translate.instant("last-know-value"),
    2: this.translate.instant("interval"),
  };
  languages = {
    es: "Castellano",
    en: "English",
  };
  tableData: FileTemplate[];
  columns = [
    {
      title: "action",
      data: [
        {
          name: "edit",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "name",
      data: "templateName",
      search: "templateName",
      sort: "templateName",
      visible: true,
    },
    {
      title: "language",
      data: "languageParsed",
      search: "languageParsed",
      sort: "languageParsed",
      visible: true,
    },
    {
      title: "extra-data",
      data: "extraDataParsed",
      search: "extraDataParsed",
      sort: "extraDataParsed",
      visible: true,
    },
    {
      title: "days",
      data: "diasParsed",
      search: "diasParsed",
      sort: "diasParsed",
      visible: true,
    },
    {
      title: "limit",
      data: "limiteDias",
      search: "limiteDias",
      sort: "limiteDias",
      visible: true,
    },
    {
      title: "type",
      data: "tipoParsed",
      search: "tipoParsed",
      sort: "tipoParsed",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private OutputFilesController: OutputFilesControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityDefinitions = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación, entidad e idioma
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Escucha de cambios en los valores de agrupación, entidad e idioma
    this.entityDefinitionsSub =
      this.SessionDataService.getEntityCupsConf().subscribe(() => {
        this.ReloadComponentService.reload();
      });

    // Carga del componente
    if (this.currentEntity && this.entityDefinitions) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.entityDefinitionsSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.OutputFilesController.getTemplateList(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"].length > 0) {
          let tableData: FileTemplate[] = response["body"];
          tableData.forEach((template: FileTemplate) => {
            template.extraDataParsed = this.entityDefinitions
              .filter((entityDefinition) =>
                template.extraData.includes(entityDefinition.colPosition)
              )
              .map((entityDefiniton) =>
                entityDefiniton.description
                  ? entityDefiniton.description
                  : entityDefiniton.label
              )
              ?.join(", ");
            template.tipoParsed = this.types[template.tipo];
            template.diasParsed = template.dias?.join(", ");
            template.languageParsed =
              this.languages[template.language.toLowerCase()];
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    );
  }

  // Acciones de la tabla
  tableActions(action: string, template: any): void {
    switch (action) {
      case "edit":
        this.router.navigate(["/ficheros/plantillas/editar/" + template.id], {
          state: { data: template },
        });
        break;
      case "delete":
        this.deleteTemplate(template);
        break;
      default:
        break;
    }
  }

  // Borrado de plantilla
  deleteTemplate(template: any): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-template-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.OutputFilesController.removeTemplate(template.id).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("template-deleted")
              );
              this.getData();
            }
          }
        );
      }
    });
  }

  // Redirección a creación de plantilla
  newTemplate(): void {
    this.router.navigate(["/ficheros/plantillas/nuevo"]);
  }
}
