// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
import {
  User,
  UserSave,
} from "../../screens/dashboard/users/UserInterface.type";
import { CupsUser } from "../../screens/dashboard/cups/CupsInterface.type";

@Injectable({
  providedIn: "root",
})
export class UserControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Formulario
  /***************************************************************************/

  // Obtener datos de usuario
  getUser(userId: number): Observable<object> {
    return this.HttpRequestService.get("/user/show/" + userId);
  }

  // Creación de nuevo usuario
  createUser(data: UserSave): Observable<object> {
    return this.HttpRequestService.post("/user/save/", data);
  }

  // Actualización de usuario
  updateUser(data: UserSave): Observable<object> {
    return this.HttpRequestService.post("/user/update/", data);
  }

  // Cambio de cups de usuario
  replaceCups(data: CupsUser): Observable<object> {
    return this.HttpRequestService.post("/user/replace-cups/", data);
  }

  // Datos de formulario de usuario
  getDropdowns(): Observable<object> {
    return this.HttpRequestService.get("/user/formdata/");
  }

  // Importar abonado
  import(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/user/import-abonado/" + entityId,
      data
    );
  }

  // Desplegable de todos los CUPS a los que el usuario tiene permisos
  getCUPS(): Observable<object> {
    return this.HttpRequestService.get("/cm/find-by-user");
  }

  // Desplegable de números de claves maestras de una agrupacion no asignadas a abonados
  getSubscriberCUPS(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/cm/list/" + entityId + "/notAssigned");
  }

  // Actualización de las restricciones de usuario
  updateRestrictions(data: boolean): Observable<object> {
    return this.HttpRequestService.post("/user/update-restrictions", data);
  }

  // Obtención del nombre de usuario
  getUserName(userId: string): Observable<object> {
    return this.HttpRequestService.post("/user/get-name", userId);
  }

  /***************************************************************************/
  // ANCHOR Listado
  /***************************************************************************/

  // Obtener lista de usuario por agrupación
  getUsers(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/user/list/" + agrupationId);
  }

  // Borrar usuario
  deleteUser(userId: number): Observable<object> {
    return this.HttpRequestService.get("/user/delete/" + userId);
  }

  // Deshabilitar usuario
  disableUser(userId: number): Observable<object> {
    return this.HttpRequestService.get("/user/disable/" + userId);
  }

  /***************************************************************************/
  // ANCHOR Abonado
  /***************************************************************************/

  // Importar abonado
  importSubscriber(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/user/import-abonado/" + entityId,
      data
    );
  }

  // Pantalla principal de CUPS de un abonado
  getSubscriberMain(subscriberId: number): Observable<object> {
    return this.HttpRequestService.get("/guest/cm/main/" + subscriberId);
  }

  // Listado de CUPS para el desplegable del abonado
  getSubscriberCupsDropdown(): Observable<object> {
    return this.HttpRequestService.get("/guest/cm/dropdown");
  }

  // Lecturas de los valores de energía de un CUPS
  getEnergyReadings(
    cupsId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    // type: 1 Diario, 2 Semanal, 3 Mensual, 4 Horario
    return this.HttpRequestService.get(
      "/guest/energy-readings/" + cupsId + "/type/4/from/" + from + "/to/" + to
    );
  }

  // Abonados disponibles para un CUPS
  getSubscriberList(cupsId: number): Observable<object> {
    return this.HttpRequestService.get("/guest/availables/" + cupsId);
  }

  // Obtener datos personales de abonado
  getSubscriberData(): Observable<object> {
    return this.HttpRequestService.get("/guest/show");
  }

  // Guardar datos personales de abonado
  updateSubscriberData(data: User): Observable<object> {
    return this.HttpRequestService.post("/guest/save", data);
  }

  // Lecturas de valores según el tipo, de un CUPS
  getReadings(data: {
    claveMaestraId: number;
    type: number;
    meter: number;
    from: string | number;
    to: string | number;
  }): Observable<object> {
    return this.HttpRequestService.post("/guest/cups-readings", data);
  }
}
