import { PROFILES } from "../../../assets/profiles/profiles";
import {
  DEVICE_BY_COMM,
  DEVICE_BY_METROLOGY,
} from "../../services/shared/DeviceTypeService.service";

export const CARD_TYPES = {
  // ANCHOR Tarjeta de gateway
  gateways: {
    containerClasses: "card-container",
    link: ["/gateways/listado/asignados"],
    state: null,
    title: "gateways-list",
    classes: "info-box-icon bg-none",
    background: "bg-black-gradient",
    icon: "bg-gateway",
    linkIcon: true,
    infoBoxText: "gateways",
    visibility: false,
    help: "help-card-gateways",
  },
  // ANCHOR Tarjeta de contadores
  meters: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_COMM.LW, "ACTIVE"] },
    title: "meter-list",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "meters-lw-network",
    visibility: true,
    help: "help-card-meters",
  },
  // ANCHOR Tarjeta de válvulas
  valves: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "valve-list",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-faucet",
    linkIcon: false,
    infoBoxText: "valves",
    visibility: true,
    help: "help-card-valves",
  },
  // ANCHOR Tarjeta de concentradores
  concentrators: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_METROLOGY.CONCENTRATOR, "ACTIVE"] },
    title: "concentrators",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tint",
    linkIcon: true,
    infoBoxText: "concentrators-online",
    visibility: true,
    help: "help-card-hubs",
  },
  // ANCHOR Tarjeta de concentradores
  txn: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "TXN",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-tint",
    linkIcon: false,
    infoBoxText: "TXN",
    visibility: true,
    help: "help-card-hubs",
  },
  // ANCHOR Tarjeta de satelites
  satelites: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_METROLOGY.SATELITE] },
    title: "satelite",
    classes: "info-box-icon bg-none",
    background: "bg-turquoise-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "satelite-online",
    visibility: true,
    help: "help-card-stelites",
  },
  // ANCHOR Tarjeta de MBus
  mbus: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_COMM.MBUS] },
    title: "MBus",
    classes: "info-box-icon bg-none",
    background: "bg-blue-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "meters-mbus",
    visibility: true,
    help: "help-card-mbus",
  },
  // ANCHOR Tarjeta de Wavenis
  wavenis: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "Wavenis",
    classes: "info-box-icon bg-none",
    background: "bg-blue-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "meters-wavenis",
    visibility: true,
    help: "help-card-wavenis",
  },
  // ANCHOR Tarjeta de UNE
  une: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_COMM.UNE] },
    title: "UNE",
    classes: "info-box-icon bg-none",
    background: "bg-turquoise-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "meters-une",
    visibility: true,
    help: "help-card-une",
  },
  // ANCHOR Tarjeta de Externos
  external: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "external",
    classes: "info-box-icon bg-none",
    background: "bg-brown-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "external",
    visibility: true,
    help: "help-card-external",
  },
  // ANCHOR Tarjeta de NBIoT
  nbiot: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: null,
    title: "NBIoT",
    classes: "info-box-icon bg-none",
    background: "bg-turquoise-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "NBIoT",
    visibility: true,
    help: "help-card-nbiot",
  },
  // ANCHOR Tarjeta de sensores
  sensors: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/asignados"],
    state: { data: [DEVICE_BY_METROLOGY.SENSOR] },
    title: "sensor-list",
    classes: "info-box-icon bg-none",
    background: "bg-turquoise-gradient",
    icon: "fa fa-wave-square",
    linkIcon: true,
    infoBoxText: "sensors",
    visibility: true,
    help: "help-card-sensors",
  },
  // ANCHOR Tarjeta de no comunican
  noComunication: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/sin-comunicacion"],
    state: { data: [DEVICE_BY_COMM.LW] },
    title: "device-last-5-days",
    classes: "info-box-icon bg-none",
    background: "bg-red-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: true,
    infoBoxText: "lw-devices-whitout-comm",
    visibility: false,
    help: "help-card-no-com",
  },
  // ANCHOR Tarjeta de alarmas
  alerts: {
    containerClasses: "card-container",
    link: ["/alarmas/listado"],
    state: { data: "full" },
    title: "meters-with-alarms",
    classes: "info-box-icon bg-none",
    background: "bg-yellow-gradient",
    icon: "fa fa-exclamation-triangle",
    linkIcon: true,
    infoBoxText: "meters-with-alarms",
    visibility: true,
    help: "help-card-alarms",
  },
  // ANCHOR Tarjeta de últimas alarmas
  lastAlarms: {
    containerClasses: "card-container",
    link: ["/alarmas/listado"],
    state: null,
    title: "alarms-5-day",
    classes: "info-box-icon bg-none",
    background: "bg-yellow-gradient",
    icon: "fa fa-exclamation-triangle",
    linkIcon: true,
    infoBoxText: "alarms-5-day",
    visibility: true,
    help: "help-card-alarms-5",
  },
  // ANCHOR Tarjeta de no asignados
  noAssigned: {
    containerClasses: "card-container",
    link: ["/dispositivos/listado/no-asignados"],
    state: { data: "asignate" },
    title: "meters-pending-list",
    classes: "info-box-icon bg-none",
    background: "bg-purple-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: true,
    infoBoxText: "no-detected",
    visibility: true,
    help: "help-card-pending",
  },
  // ANCHOR Tarjeta de consumo mensual
  monthConsume: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "total-consumption-last-month",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "total-consumption-last-month",
    visibility: true,
    help: "help-card-consumption-month",
  },
  // ANCHOR Tarjeta de consumo último día
  dayConsume: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "total-consumption-last-day-title",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "total-consumption-last-day",
    visibility: true,
    help: "help-card-consumption-day",
  },
  // ANCHOR Tarjeta de contadores en red
  metersInNetwork: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "meters-in-network",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "meters-in-network",
    visibility: true,
    help: "help-card-meters",
  },
  // ANCHOR Tarjeta de contadores con lectura válida
  metersValidRead: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "meters-valid-read",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-calendar-check",
    linkIcon: false,
    infoBoxText: "meters-valid-read",
    visibility: true,
    help: "help-card-network-readings",
  },
  // ANCHOR Tarjeta de contadores último mes
  metersReadLastMonth: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "meters-last-month",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "meters-last-month",
    visibility: true,
    help: "help-card-network-month-readings",
  },
  // ANCHOR Tarjeta de alertas de telecontrol
  controlAlerts: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: null,
    classes: "info-box-icon bg-none",
    background: "bg-red-gradient",
    icon: "fa fa-exclamation-triangle",
    linkIcon: false,
    infoBoxText: "alerts",
    visibility: true,
    help: "help-card-control-alerts",
  },
  // ANCHOR Tarjeta de SF/RSSI/SNR
  meterDetailSfRssiSnr: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "SF / RSSI / SNR",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "SF / RSSI / SNR",
    visibility: true,
    help: "help-card-meter-sf",
  },
  // ANCHOR Tarjeta de tramas recibidas
  meterDetailFrameReceived: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "frames-received",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "frames-received",
    visibility: true,
    help: "help-card-meter-frame-average",
  },
  // ANCHOR Tarjeta de fecha de última trama
  meterDetailLastFrame: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-frames-received",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "last-frames-received",
    visibility: true,
    help: "help-card-meter-last-frame",
  },
  // ANCHOR Tarjeta de fecha último join
  meterDetailLastJoin: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-join-date",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "last-join-date",
    visibility: true,
    help: "help-card-meter-last-join",
  },
  // ANCHOR Tarjeta de gateway principal
  meterDetailMainGateway: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "main-gateway",
    classes: "info-box-icon bg-none",
    background: "bg-black-gradient",
    icon: "bg-gateway",
    linkIcon: false,
    infoBoxText: "main-gateway",
    visibility: true,
    help: "help-card-meter-main",
  },
  // ANCHOR Tarjeta de gateways redundantes
  meterDetailRedundantGateways: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "redundant-gateway",
    classes: "info-box-icon bg-none",
    background: "bg-black-gradient",
    icon: "bg-gateway",
    linkIcon: false,
    infoBoxText: "redundant-gateway",
    visibility: true,
    help: "help-card-meter-redundant",
  },
  // ANCHOR Tarjeta de último valor leído
  meterDetailLastKnownValue: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-know-value",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "last-know-value",
    visibility: true,
    help: "help-card-meter-last-value",
  },
  // ANCHOR Tarjeta de dato último mes
  meterDetailLastDataMonth: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-data-month",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "last-data-month",
    visibility: true,
    help: "help-card-meter-month-value",
  },
  // ANCHOR Tarjeta consumo último mes
  meterDetailLastMonthConsume: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-month-consumption",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "last-month-consumption",
    visibility: true,
    help: "help-card-meter-last-month-consumption",
  },
  // ANCHOR Tarjeta consumo medio mes
  meterDetailConsumeMonthAverage: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "consumption-month-average",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "consumption-month-average",
    visibility: true,
    help: "help-card-meter-month-average",
  },
  // ANCHOR Tarjeta consumo mes actual
  meterDetailCurrentMonthConsume: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "current-month-consumption",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "current-month-consumption",
    visibility: true,
    help: "help-card-meter-month-consumption",
  },
  // ANCHOR Tarjeta consumo medio diario
  meterDetailConsumeAverageDaily: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "consumption-average-daily",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "consumption-average-daily",
    visibility: true,
    help: "help-card-meter-day-average",
  },
  // ANCHOR Tarjeta consumo medio diario
  meterDetailLastMinConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-consumption-min",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-chart-area",
    linkIcon: false,
    infoBoxText: "last-consumption-min",
    visibility: true,
    help: "help-card-last-consumption-min",
  },
  // ANCHOR Tarjeta consumo medio diario
  meterDetailLastMaxConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-consumption-max",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-chart-area",
    linkIcon: false,
    infoBoxText: "last-consumption-max",
    visibility: true,
    help: "help-card-last-consumption-max",
  },
  // ANCHOR Tarjeta consumo medio diario
  meterDetailLastTotalConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-consumption-total",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-chart-area",
    linkIcon: false,
    infoBoxText: "last-consumption-total",
    visibility: true,
    help: "help-card-last-consumption-total",
  },
  // ANCHOR Tarjeta batería
  meterDetailBattery: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "battery-only",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-three-quarters",
    linkIcon: false,
    infoBoxText: "battery-only",
    visibility: true,
    help: "help-card-meter-battery",
  },
  // ANCHOR Tarjeta de modo
  meterDetailMode: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "mode",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-exchange-alt",
    linkIcon: false,
    infoBoxText: "mode",
    visibility: true,
    help: "help-card-meter-mode",
  },
  // ANCHOR Tarjeta de vávula
  meterDetailValve: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "valve-state",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-faucet",
    linkIcon: false,
    infoBoxText: "valve-state",
    visibility: true,
    help: "help-card-meter-valve",
  },
  // ANCHOR Tarjeta de balance padre
  balanceParentsSize: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "fathers",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-sitemap",
    linkIcon: false,
    infoBoxText: "fathers",
    visibility: true,
    help: "help-card-balance-fathers",
  },
  // ANCHOR Tarjeta de balance hijos
  balanceChildsSize: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "childs",
    classes: "info-box-icon bg-none",
    background: "bg-blue-gradient",
    icon: "fa fa-sitemap",
    linkIcon: false,
    infoBoxText: "childs",
    visibility: true,
    help: "help-card-balance-childs",
  },
  // ANCHOR Tarjeta de balance sin facturar
  balanceUnbilled: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "unbilled",
    classes: "info-box-icon bg-none",
    background: "bg-red-gradient",
    icon: "fa fa-tint",
    linkIcon: false,
    infoBoxText: "unbilled-balance",
    visibility: true,
    help: "help-card-balance-no-registered",
  },
  // ANCHOR Tarjeta de balance umbral
  balanceThresholdAlarm: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "threshold-alarm",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-sliders",
    linkIcon: false,
    infoBoxText: "threshold-alarm",
    visibility: true,
    help: "help-card-balance-threshold",
  },
  // ANCHOR Gateway: número de contadores principales
  gatewayDetailMainMeters: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "main-number",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "main-number",
    visibility: true,
    help: "help-card-gateway-main",
  },
  // ANCHOR Gateway: número de contadores redundantes
  gatewayDetailRedundantMeters: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "redundants-number",
    classes: "info-box-icon bg-none",
    background: "bg-turquoise-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "redundants-number",
    visibility: true,
    help: "help-card-gateway-redundant",
  },
  // ANCHOR Gateway: no comunicados
  gatewayDetailAlarms: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "no-communicates",
    classes: "info-box-icon bg-none",
    background: "bg-red-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "no-communicates",
    visibility: true,
    help: "help-card-gateway-no-com",
  },
  // ANCHOR Gateway: RSSI / Batería
  gatewayDetailRssi: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "battery-rssi",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "battery-rssi",
    visibility: true,
    help: "help-card-gateway-battery",
  },
  // ANCHOR Gateway: tramas leídas
  gatewayDetailFramesReaded: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "frames-read",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "frames-read",
    visibility: true,
    help: "help-card-gateway-frames",
  },
  // ANCHOR Gateway: última comunicación
  gatewayDetailLastCommunication: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-communication",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "last-communication",
    visibility: true,
    help: "help-card-gateway-last-com",
  },
  // ANCHOR Gateway: SF
  gatewayDetailSf: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "sf-list",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "sf-list",
    visibility: true,
    help: "help-card-gateway-sf",
  },
  // ANCHOR Gateway: última Hello
  gatewayDetailLastHello: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-hello",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: false,
    infoBoxText: "last-hello",
    visibility: true,
    help: "help-card-gateway-hello",
  },
  // ANCHOR Gateway: último fichero
  gatewayDetailLastFile: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-file",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-calendar-day",
    linkIcon: true,
    infoBoxText: "last-file",
    visibility: true,
    help: "help-card-gateway-file",
  },
  // ANCHOR Gateway: operadora
  gatewayDetailOperator: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "operator",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-sim-card",
    linkIcon: true,
    infoBoxText: "operator",
    visibility: true,
    help: "help-card-gateway-operator",
  },
  // ANCHOR UNE: Número de contadores
  meterDetailNroContadores: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "meters",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "meters",
    visibility: true,
    help: "help-card-une-meters",
  },
  // ANCHOR MBUS: Número de contadores medio
  meterDetailNroContadoresMedio: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "lw-mbus-average-meters",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "lw-mbus-average-meters",
    visibility: true,
    help: "help-card-mbus-meters",
  },
  // ANCHOR UNE: VBat
  meterDetailVbat: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "VBat",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-wave-square",
    linkIcon: false,
    infoBoxText: "VBat",
    visibility: true,
    help: "help-card-une-vbat",
  },
  // ANCHOR UNE: Tiempo de uso del bus
  meterDetailTiempoUsoBusUne: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-bus-time",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-clock",
    linkIcon: false,
    infoBoxText: "une-bus-time",
    visibility: true,
    help: "help-card-une-bus",
  },
  // ANCHOR UNE: Número de lecturas
  meterDetailNroLecturas: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-lectures",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "une-lectures",
    visibility: true,
    help: "help-card-balance-readings",
  },
  // ANCHOR UNE: Número de colisiones
  meterDetailNroColisiones: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-collisions",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-compress-arrows-alt",
    linkIcon: false,
    infoBoxText: "une-collisions",
    visibility: true,
    help: "help-card-balance-colisions",
  },
  // ANCHOR UNE: Número de tramas
  meterDetailNroTx: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-frames",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-broadcast-tower",
    linkIcon: false,
    infoBoxText: "une-frames",
    visibility: true,
    help: "help-card-une-frames",
  },
  // ANCHOR UNE: Número de repeticiones
  meterDetailNroRepeticiones: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-repetitions",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-redo-alt",
    linkIcon: false,
    infoBoxText: "une-repetitions",
    visibility: true,
    help: "help-card-une-rep",
  },
  // ANCHOR UNE: Consumo por tiempo de de bus
  meterDetailConsumos: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "une-consumptions",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fas fa-battery-three-quarters",
    linkIcon: false,
    infoBoxText: "une-consumptions",
    visibility: true,
    help: "help-card-une-bus-time",
  },
  // ANCHOR UNE: Concentrador asociado
  meterDetailUneFather: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "associated-lw-une",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-tint",
    linkIcon: false,
    infoBoxText: "associated-lw-une",
    visibility: true,
    help: "help-card-une-hub",
  },
  // ANCHOR MBUS: Concentrador asociado
  meterDetailMbusFather: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "associated-lw-mbus",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-tint",
    linkIcon: false,
    infoBoxText: "associated-lw-mbus",
    visibility: true,
    help: "help-card-mbus-hub",
  },
  // ANCHOR KPIs: Últimos 5 días
  kpisLastFiveDaysAverage: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "kpis-last-five-days",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fas fa-calendar-alt",
    linkIcon: false,
    infoBoxText: "kpis-last-five-days",
    visibility: true,
    help: "help-card-kapis-5days",
  },
  // ANCHOR KPIs: Día
  kpisDayAverage: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "kpis-day",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fas fa-calendar-day",
    linkIcon: false,
    infoBoxText: "kpis-day",
    visibility: true,
    help: "help-card-kapis-day",
  },
  // ANCHOR KPIs: Horario
  kpisHourlyAverage: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "kpis-hourly",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fas fa-clock",
    linkIcon: false,
    infoBoxText: "kpis-hourly",
    visibility: true,
    help: "help-card-kapis-hour",
  },
  // ANCHOR Tarjeta de vávula satélite: Consumo
  meterDetailConsumeLastWeek: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-week-consumption",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fas fa-calendar-week",
    linkIcon: false,
    infoBoxText: "last-week-consumption",
    visibility: true,
    help: "help-card-satelite-consumption",
  },
  // ANCHOR Tarjeta de vávula satélite: Estado
  meterDetailSateliteValve: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "valve-state",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-faucet",
    linkIcon: false,
    infoBoxText: "valve-state",
    visibility: true,
    help: "help-card-satelite-state",
  },
  // ANCHOR Tarjeta de vávula satélite: Último caudal instantáneo
  meterDetailLastFlow: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-flow",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-water",
    linkIcon: false,
    infoBoxText: "last-flow",
    units: " m³/h",
    visibility: true,
    help: "help-card-satelite-last-flow",
  },
  // ANCHOR Tarjeta de vávula satélite: Último caudal medio
  meterDetailLastAverageFlow: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "last-average-flow",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-water",
    linkIcon: false,
    infoBoxText: "last-flow-average",
    units: " m³/h",
    visibility: true,
    help: "help-card-satelite-last-average-flow",
  },
  // ANCHOR Reporte de comunicaciones: dispositivos por día
  communicationReportDevicesDay: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "communication-device-average",
    classes: "info-box-icon bg-none",
    background: "bg-green-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "communication-device-average",
    visibility: true,
    help: "help-card-report-day",
  },
  // ANCHOR Reporte de comunicaciones: hora punta máximos
  communicationReportMaxHour: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "communication-max-hour",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-clock",
    linkIcon: false,
    infoBoxText: "communication-max-hour",
    visibility: true,
    help: "help-card-report-hour-max",
  },
  // ANCHOR Reporte de comunicaciones: hora punta mínimos
  communicationReportMinHour: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "communication-min-hour",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-clock",
    linkIcon: false,
    infoBoxText: "communication-min-hour",
    visibility: true,
    help: "help-card-report-hour-min",
  },
  // ANCHOR Contadores permanentes en concentrador LW MBUS
  lwMbusPermanentMeters: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "permanent-mbus-meters",
    classes: "info-box-icon bg-none",
    background: "bg-blue-gradient",
    icon: "fa fa-tachometer-alt",
    linkIcon: false,
    infoBoxText: "permanent-mbus-meters",
    visibility: true,
    help: "help-card-mbus-permanent-meters",
  },
  // ANCHOR Concentradores LW MBUS permanentes en contador
  meterPermanentLwMbus: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "permanent-meter-concentrators",
    classes: "info-box-icon bg-none",
    background: "bg-orange-gradient",
    icon: "fa fa-tint",
    linkIcon: false,
    infoBoxText: "permanent-meter-concentrators",
    visibility: true,
    help: "help-card-meter-permanent-concentrators",
  },
  // ANCHOR LW MBUS: Consumo MBUS
  meterDetailMbusConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "lw-mbus-consumption-mbus",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-half",
    linkIcon: false,
    infoBoxText: "lw-mbus-consumption-mbus",
    visibility: true,
    help: "help-card-lw-mbus-consumption-mbus",
  },
  // ANCHOR LW MBUS: Consumo LW
  meterDetailLwConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "lw-mbus-consumption-lw",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-half",
    linkIcon: false,
    infoBoxText: "lw-mbus-consumption-lw",
    visibility: true,
    help: "help-card-lw-mbus-consumption-lw",
  },
  // ANCHOR LW MBUS: Consumo transmisión
  meterDetailTransmissionConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "lw-mbus-consumption-transmission",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-half",
    linkIcon: false,
    infoBoxText: "lw-mbus-consumption-transmission",
    visibility: true,
    help: "help-card-lw-mbus-consumption-transmission",
  },
  // ANCHOR LW MBUS: Consumo base
  meterDetailBaseConsumption: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "lw-mbus-consumption-base",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-half",
    linkIcon: false,
    infoBoxText: "lw-mbus-consumption-base",
    visibility: true,
    help: "help-card-lw-mbus-consumption-base",
  },
  // ANCHOR LW MBUS: Consumo base
  meterDetailBatteryRemain: {
    containerClasses: "card-container",
    link: null,
    state: null,
    title: "battery-load-remaining",
    classes: "info-box-icon bg-none",
    background: "bg-gray-gradient",
    icon: "fa fa-battery-half",
    linkIcon: false,
    infoBoxText: "battery-load-remaining",
    visibility: true,
    help: "help-card-battery-load-remaining",
  },
  // ANCHOR Ruido
  noiseData: {
    containerClasses: "card-container",
    link: ["/sensorica/fugas"],
    state: null,
    title: "noise",
    classes: "info-box-icon bg-none",
    background: "bg-red-gradient",
    icon: "fas fa-wave-square",
    linkIcon: true,
    infoBoxText: "noise-last-days",
    visibility: true,
    help: "help-card-noise",
  },
};
