// @angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Agrupations
import { AgrupationFormComponent } from "./screens/dashboard/agrupations/agrupation-form/agrupation-form.component";
import { AgrupationListComponent } from "./screens/dashboard/agrupations/agrupations-list/agrupations-list.component";
import { AgrupationUserLogComponent } from "./screens/dashboard/agrupations/agrupations-logs/agrupation-log-user/agrupation-log-user.component";

// Alarmas
import { AlarmsComponent } from "./screens/dashboard/alarms/alarms-list/alarms-list.component";
import { AlarmsAgrupationConfigurationComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-configuration-agrupation/alarms-configuration-agrupation.component";
import { AlarmsEntityConfigurationComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-configuration-entity/alarms-configuration-entity.component";
import { AlarmsGlobalComponent } from "./screens/dashboard/alarms/alarms-global/alarms-global.component";
import { AlarmsEntityTemplateComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-entity-template/alarms-entity-template.component";
import { AlarmsCustomConfigComponent } from "./screens/dashboard/alarms/alarms-custom-config/alarms-custom-config.component";
import { AlarmsDevicesComponent } from "./screens/dashboard/alarms/alarms-devices/alarms-devices.component";

// Clients
import { ClientListComponent } from "./screens/dashboard/clients/clients-list/clients-list.component";
import { ClientFormComponent } from "./screens/dashboard/clients/clients-form/clients-form.component";

// Control
import { ControlComponent } from "./screens/dashboard/control/control.component";
import { ControlHistoricComponent } from "./screens/dashboard/control/control-historic/control-historic.component";
import { ControlTelemetryComponent } from "./screens/dashboard/control/control-telemetry/control-telemetry.component";
import { ControlTestComponent } from "./screens/dashboard/control/control-test/control-test.component";

// Cobertura
import { CoverageTestComponent } from "./screens/dashboard/coverage/coverage-test/coverage-test.component";
import { CoverageHeatmapComponent } from "./screens/dashboard/coverage/coverage-heatmap/coverage-heatmap.component";
import { CoverageHeatmapMbusComponent } from "./screens/dashboard/coverage/coverage-heatmap-mbus/coverage-heatmap-mbus.component";

// Cups
import { CupsDetailComponent } from "./screens/dashboard/cups/cups-detail/cups-detail.component";
import { CupsAlarmsComponent } from "./screens/dashboard/cups/cups-alarms/cups-alarms.component";
import { CupsUserLogComponent } from "./screens/dashboard/cups/cups-logs/cups-log-user/cups-log-user.component";
import { AlarmsCUPSConfigurationComponent } from "./screens/dashboard/cups/cups-alarms/cups-alarms-configuration/cups-alarms-configuration.component";
import { CupsAlertsComponent } from "./screens/dashboard/cups/cups-alerts/cups-alerts.component";

// Data anlysis
import { MeterGraphComponent } from "./screens/dashboard/data-analysis/data-analysis-meter-graph/data-analysis-meter-graph.component";
import { AdvancedAnalyticsComponent } from "./screens/dashboard/data-analysis/data-analysis-advanced-analytics/data-analysis-advanced-analytics.component";
import { HydraulicBalanceComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance.component";
import { NewHydraulicBalanceComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-new/data-analysis-balance-new.component";
import { HydraulicBalanceGroupComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-group/data-analysis-balance-group.component";
import { DataAnalysisConsumptionEvolutionComponent } from "./screens/dashboard/data-analysis/data-analysis-consumption-evolution/data-analysis-consumption-evolution.component";
import { DataAnalysisModelsComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-models.component";
import { DataAnalysisModelsListComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-list/data-analysis-models-list.component";
import { DataAnalysisModelsMetersComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-meters/data-analysis-models-meters.component";

// Data management
import { ManagementConfigurationComponent } from "./screens/dashboard/data-management/data-management-configuration/data-management-configuration.component";
import { ManagementAsociationComponent } from "./screens/dashboard/data-management/data-management-association/data-management-association.component";
import { ManagementImportComponent } from "./screens/dashboard/data-management/data-management-import/data-management-import.component";
import { ManagementInfoComponent } from "./screens/dashboard/data-management/data-management-info/data-management-info.component";
import { NewAsociationComponent } from "./screens/dashboard/data-management/data-management-association/data-management-association-new/data-management-association-new.component";
import { MetrologicalListComponent } from "./screens/dashboard/data-management/data-management-metrological/data-management-metrological-list/data-management-metrological-list.component";
import { MetrologicalFormComponent } from "./screens/dashboard/data-management/data-management-metrological/data-management-metrological-form/data-management-metrological-form.component";
import { DataAnalysisGraphComparisonComponent } from "./screens/dashboard/data-analysis/data-analysis-graph-comparison/data-analysis-graph-comparison.component";
import { DataManagementCupsComponent } from "./screens/dashboard/data-management/data-management-cups/data-management-cups.component";
import { ManagementUserEventsComponent } from "./screens/dashboard/management/management-user-events/management-user-events.component";

// Devices
import { DeviceImportComponent } from "./screens/dashboard/devices/imports/device-import/device-import.component";
import { MeterLogFramesComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-frames/meter-log-frames.component";
import { MeterLogCommunicationComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-communications/meter-log-communications.component";
import { AlarmsMeterConfigurationComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-alarms/meter-alarms-configuration/meter-alarms-configuration.component";
import { MetersListDeactivatedComponent } from "./screens/dashboard/devices/meter/meter-list/meter-deactivated/meter-deactivated.component";
import { MeterUserLogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-user/meter-log-user.component";
import { ReviewListComponent } from "./screens/dashboard/devices/meter/meter-list/meter-review/meter-review.component";
import { MetersListAssignComponent } from "./screens/dashboard/devices/meter/meter-list/meter-assigned/meter-assigned.component";
import { MetersListNoAssignComponent } from "./screens/dashboard/devices/meter/meter-list/meter-unnassigned/meter-unassigned.component";
import { MetersListInternalComponent } from "./screens/dashboard/devices/meter/meter-list/meter-internal/meter-internal.component";
import { MeterEventLogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-events/meter-log-events.component";
import { MetersDetailComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-detail.component";
import { MeterAlarmsComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-alarms/meter-alarms.component";
import { ConcentratorDetailComponent } from "./screens/dashboard/devices/concentrator/concentrator-detail/concentrator-detail.component";
import { DeviceNewComponent } from "./screens/dashboard/devices/device-new/device-new.component";
import { DeviceSimCardsComponent } from "./screens/dashboard/devices/device-sim-cards/device-sim-cards.component";
import { DeviceGprsListComponent } from "./screens/dashboard/devices/device-gprs-list/device-gprs-list.component";
import { MeterActivationErrorComponent } from "./screens/dashboard/devices/meter/meter-list/meter-activation-error/meter-activation-error.component";
import { DeviceReplaceComponent } from "./screens/dashboard/devices/imports/device-replace/device-replace.component";
import { DeviceReadingsImportComponent } from "./screens/dashboard/devices/imports/device-readings-import/device-readings-import.component";
import { MeterSelectionComponent } from "./screens/dashboard/devices/meter/meter-list/meter-selection/meter-selection.component";
import { DeviceDeactivateComponent } from "./screens/dashboard/devices/imports/device-deactivate/device-deactivate.component";
import { MeterSubstitutionsComponent } from "./screens/dashboard/devices/meter/meter-list/meter-substitutions/meter-substitutions.component";
import { MeterPendingSubstitutionsComponent } from "./screens/dashboard/devices/meter/meter-list/meter-pending-substitutions/meter-pending-substitutions.component";
import { MeterBatteryComponent } from "./screens/dashboard/devices/meter/meter-list/meter-battery/meter-battery.component";
import { DeviceImageImportComponent } from "./screens/dashboard/devices/imports/device-image-import/device-image-import.component";
import { MbusManufacturersComponent } from "./screens/dashboard/devices/mbus/mbus-manufacturers/mbus-manufacturers.component";
import { MbusUnknownListComponent } from "./screens/dashboard/devices/mbus/mbus-unknown-list/mbus-unknown-list.component";
import { DeviceLocationImportComponent } from "./screens/dashboard/devices/imports/device-location-import/device-location-import.component";
import { MbusCandidatesComponent } from "./screens/dashboard/devices/mbus/mbus-candidates/mbus-candidates.component";
import { ConcentratorCommandsComponent } from "./screens/dashboard/devices/concentrator/concentrator-commands/concentrator-commands.component";
import { MeterOrdersComponent } from "./screens/dashboard/devices/meter/meter-list/meter-orders/meter-orders.component";
import { MeterPendingOrdersComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-pending-orders/meter-pending-orders.component";

// Entities
import { EntityListComponent } from "./screens/dashboard/entities/entity-list/entity-list.component";
import { EntityFormComponent } from "./screens/dashboard/entities/entity-form/entity-form.component";

// Errors
import { NotFoundComponent } from "./screens/errors/404/404.component";
import { AccesDeniedComponent } from "./screens/errors/401/401.component";

// Gateway
import { GatewaysListAssignComponent } from "./screens/dashboard/gateways/gateways-list/gateways-assigned/gateways-assigned.component";
import { GatewaysAvailableComponent } from "./screens/dashboard/gateways/gateways-list/gateways-available/gateways-available.component";
import { GatewaysDetailComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-detail.component";
import { GatewayEventLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-events/gateway-events.component";
import { GatewayDataLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-datalogger/gateway-datalogger.component";
import { GatewayUserLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-users/gateway-users.component";
import { APPEUIComponent } from "./screens/dashboard/gateways/gateways-appeui/gateways-appeui.component";
import { GatewayResetComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-resets/gateway-resets.component";
import { GatewayAlarmsComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-alarms/gateway-alarms.component";
import { FilesComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-files/gateway-files.component";
import { LoadComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-load/gateway-load.component";
import { GatewayUpateComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-update/gateway-update.component";
import { GatewayConfigureComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-configure/gateway-configure.component";
import { GatewayManagementComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-management/gateway-management.component";
import { GatewayCoverageContrastComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-coverage-contrast/gateway-coverage-contrast.component";
import { RequestQueueTableComponent } from "./modules/task-module/request-queue/request-queue-table.component";
import { GatewayOperatorHistoricalComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-operator-historical/gateway-operator-historical.component";
import { GatewayRelaunchFramesComponent } from "./screens/dashboard/gateways/gateway-relaunch-frames/gateway-relaunch-frames.component";
import { GatewaysInstockComponent } from "./screens/dashboard/gateways/gateways-list/gateways-instock/gateways-instock.component";
import { GatewayTasksComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-tasks/gateway-tasks.component";
import { GatewaysDamagedComponent } from "./screens/dashboard/gateways/gateways-list/gateways-damaged/gateways-damaged.component";
import { GatewaysRepairComponent } from "./screens/dashboard/gateways/gateways-list/gateways-repair/gateways-repair.component";
import { GatewayFramesComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-frames/gateway-frames.component";

// Home
import { HomeComponent } from "./screens/dashboard/home/home.component";

// IP
import { IPListComponent } from "./screens/dashboard/ip/ip-list/ip-list.component";

// Mantenimiento
import { ManagementEntityGatewaysComponent } from "./screens/dashboard/management/management-entity-gateways/management-entity-gateways.component";
import { ManagementGlobalComponent } from "./screens/dashboard/management/management-global/management-global.component";
import { ManagementGlobalSearchComponent } from "./screens/dashboard/management/management-global-search/management-global-search.component";
import { ManagementAlarmsComponent } from "./screens/dashboard/management/management-alarms/management-alarms.component";
import { ManagementCronComponent } from "./screens/dashboard/management/management-cron/management-cron.component";
import { ManagementAgrupationEditionComponent } from "./screens/dashboard/management/management-agrupation-edition/management-agrupation-edition.component";
import { ManagementCommandsTailComponent } from "./screens/dashboard/management/management-commands-tail/management-commands-tail.component";
import { ManagementNetworkComponent } from "./screens/dashboard/management/management-network/management-network.component";
import { ManagementEntityComponent } from "./screens/dashboard/management/management-entity/management-entity.component";
import { ManagementUserComponent } from "./screens/dashboard/management/management-user/management-user.component";
import { ManagementCommunicationReportComponent } from "./screens/dashboard/management/management-communication-report/management-communication-report.component";
import { ManagementGlobalWarningsComponent } from "./screens/dashboard/management/management-global-warnings/management-global-warnings.component";
import { ManagementLanguageLabelsComponent } from "./screens/dashboard/management/management-language-labels/management-language-labels.component";
import { ManagementManufacturersComponent } from "./screens/dashboard/management/management-manufacturers/management-manufacturers.component";
import { ManagementGlobalConcentratorsComponent } from "./screens/dashboard/management/management-global-concentrators/management-global-concentrators.component";
import { ClientEntityGatewaysComponent } from "./screens/dashboard/management/client-entity-gateways/client-entity-gateways.component";

// Network state
import { BillingEntityComponent } from "./screens/dashboard/network-state/network-state-entities/network-state-entity.component";
import { BillingAgrupationComponent } from "./screens/dashboard/network-state/network-state-agrupation/network-state-agrupation.component";
import { NetworkStateKpisComponent } from "./screens/dashboard/network-state/network-state-kpis/network-state-kpis.component";
import { NetworkStateGlobalComponent } from "./screens/dashboard/network-state/network-state-global/network-state-global.component";
import { TotalsSummaryComponent } from "./screens/dashboard/network-state/totals-summary/totals-summary.component";

// Notificaciones
import { NotificationImportComponent } from "./modules/notification-module/notification-import/notification-import.component";

// Output files
import { DownloadFilesComponent } from "./screens/dashboard/output-files/output-files-download/output-files-download.component";
import { TemplateFilesComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-templates-list/output-files-templates-list.component";
import { TemplateFormComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-templates-form/output-files-templates-form.component";
import { OutputFileEk280Component } from "./screens/dashboard/output-files/output-file-ek280/output-file-ek280.component";
import { OutputFilesTimerComponent } from "./screens/dashboard/output-files/output-files-timer/output-files-timer.component";
import { OutputFilesAqualiaComponent } from "./screens/dashboard/output-files/output-files-aqualia/output-files-aqualia.component";
import { OutputFilesTemplateExportComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-template-export/output-files-template-export.component";
import { OutputFilesTemplateCronComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-template-cron/output-files-template-cron.component";

// Queries
import { QueriesComponent } from "./screens/dashboard/queries/queries.component";

// Sensory
import { SensoryLeaksMapComponent } from "./screens/dashboard/sensory/sensory-leaks/sensory-leaks-map/sensory-leaks-map.component";
import { SensoryPressureMapComponent } from "./screens/dashboard/sensory/sensory-pressure/sensory-pressure-map/sensory-pressure-map.component";
import { SensoryFlowMapComponent } from "./screens/dashboard/sensory/sensory-flow/sensory-flow-map/sensory-flow-map.component";

// Servicios de autorización
import { AuthGuard } from "./services/shared/AuthGuardService.service";
import { RoleGuard } from "./services/shared/RoleGuardService.service";
import { CanDeactivateGuard } from "./services/shared/CanDeactivateGuardService.service";

// Subscriber
import { SubscriberMainComponent } from "./modules/subscriber-module/subscriber-main/subscriber-main.component";
import { SubscriberEditComponent } from "./modules/subscriber-module/subscriber-edit/subscriber-edit.component";

// Users
import { UsersListComponent } from "./screens/dashboard/users/users-list/user-list.component";
import { UserFormComponent } from "./screens/dashboard/users/users-form/users-form.component";
import { UsersImport } from "./screens/dashboard/users/users-import/users-import.component";

// Variables
import { ENVIRONMENT } from "../environments/environment";
import { PROFILES } from "../assets/profiles/profiles";
import { DataManagementNoCupsComponent } from "./screens/dashboard/data-management/data-management-cups/data-management-no-cups/data-management-no-cups.component";
import { ManagementEntityConcentratorsComponent } from "./screens/dashboard/management/management-entity-concentrators/management-entity-concentrators.component";
import { MeterIgnoredComponent } from "./screens/dashboard/devices/meter/meter-list/meter-ignored/meter-ignored.component";
import { DataManagementPcsFcvListComponent } from "./screens/dashboard/data-management/data-management-metrological/data-management-pcs-fcv-list/data-management-pcs-fcv-list.component";
import { ControlDataloggersComponent } from "./screens/dashboard/control/control-dataloggers/control-dataloggers.component";
import { DataManagementCupsOrdersComponent } from "./screens/dashboard/data-management/data-management-cups/data-management-cups-orders/data-management-cups-orders.component";
import { CupsPendingOrdersComponent } from "./screens/dashboard/cups/cups-pending-orders/cups-pending-orders.component";

/****************************************************************************************************/
// Rutas
/****************************************************************************************************/

const routes: Routes = [
  // Abonado
  {
    path: "abonado",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [PROFILES.ABONADO],
    },
    children: [
      {
        path: "configuracion",
        component: SubscriberEditComponent,
        data: { animation: "subscriberConfig" },
      },
      {
        path: "principal",
        component: SubscriberMainComponent,
        data: { animation: "subscriberMain" },
      },
    ],
  },
  // Agrupación
  {
    path: "agrupacion",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "log",
        children: [
          {
            path: "usuarios",
            component: AgrupationUserLogComponent,
          },
        ],
      },
    ],
  },
  // Agrupaciones
  {
    path: "agrupaciones",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "listado",
        component: AgrupationListComponent,
      },
      {
        path: "formulario",
        children: [
          {
            path: "editar/:id",
            component: AgrupationFormComponent,
          },
          {
            path: "nuevo",
            component: AgrupationFormComponent,
          },
        ],
      },
    ],
  },
  // Alarmas
  {
    path: "alarmas",
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: "listado",
        component: AlarmsComponent,
      },
      {
        path: "personalizadas/configuracion",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: AlarmsCustomConfigComponent,
      },
      {
        path: "dispositivos",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: AlarmsDevicesComponent,
      },
      {
        path: "personalizadas/configuracion/contador/:id",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: AlarmsCustomConfigComponent,
      },
      {
        path: "agrupacion/configuracion",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_AGRUPACION,
          ],
        },
        component: AlarmsAgrupationConfigurationComponent,
      },
      {
        path: "entidad/configuracion",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: AlarmsEntityConfigurationComponent,
      },
      {
        path: "global",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: AlarmsGlobalComponent,
      },
      {
        path: "entidad/plantilla",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: AlarmsEntityTemplateComponent,
      },
      {
        path: "limpiar-alarmas/:id",
        component: RequestQueueTableComponent,
      },
    ],
  },
  // Análisis de datos
  {
    path: "analisis-datos",
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    canActivateChild: [RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "asociaciones",
        component: ManagementAsociationComponent,
      },
      {
        path: "asociaciones/nuevo",
        component: NewAsociationComponent,
      },
      {
        path: "asociaciones/editar/:id",
        component: NewAsociationComponent,
      },
      {
        path: "balance",
        component: HydraulicBalanceComponent,
      },
      {
        path: "nuevo",
        component: NewHydraulicBalanceComponent,
      },
      {
        path: "editar/:id",
        component: NewHydraulicBalanceComponent,
      },
      {
        path: "grupo/:id",
        component: HydraulicBalanceGroupComponent,
      },
      {
        path: "grafica",
        component: MeterGraphComponent,
      },
      {
        path: "analitica-avanzada",
        component: AdvancedAnalyticsComponent,
      },
      {
        path: "evolucion-consumo",
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: DataAnalysisConsumptionEvolutionComponent,
      },
      // Queries
      {
        path: "consultas",
        component: QueriesComponent,
      },
      // Comparativa de gráficas
      {
        path: "comparativa-graficas",
        component: DataAnalysisGraphComparisonComponent,
      },
      // Modelos analíticos: editar
      {
        path: "modelos-analiticos/editar",
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DataAnalysisModelsComponent,
      },
      // Modelos analíticos: patrones
      {
        path: "modelos-analiticos/patrones",
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DataAnalysisModelsListComponent,
      },
      // Modelos analíticos: listado
      {
        path: "modelos-analiticos/listado",
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DataAnalysisModelsMetersComponent,
      },
    ],
  },
  // Clientes
  {
    path: "clientes",
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      expectedRole: [PROFILES.ARSON],
    },
    children: [
      {
        path: "listado",
        component: ClientListComponent,
      },
      {
        path: "formulario",
        children: [
          {
            path: "editar/:id",
            component: ClientFormComponent,
          },
          {
            path: "nuevo",
            component: ClientFormComponent,
          },
        ],
      },
    ],
  },
  // Cobertura
  {
    path: "cobertura",
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_CLIENTE,
      ],
    },
    children: [
      {
        path: "test",
        component: CoverageTestComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
      },
      {
        path: "mapa",
        component: CoverageHeatmapComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
      {
        path: "mapa-mbus",
        component: CoverageHeatmapMbusComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
    ],
  },
  // CUPS
  {
    path: "cups",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "detalle/:id",
        component: CupsDetailComponent,
      },
      {
        path: "alarmas/:id",
        component: CupsAlarmsComponent,
      },
      {
        path: "alertas/:id",
        component: CupsAlertsComponent,
      },
      {
        path: "configuracion/:id",
        component: AlarmsCUPSConfigurationComponent,
      },
      {
        path: "detalle/ordenes/:id",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
            PROFILES.ADMIN_INSTALLATION,
          ],
        },
        component: CupsPendingOrdersComponent,
      },
      {
        path: "log",
        children: [
          {
            path: "usuarios/:id",
            component: CupsUserLogComponent,
          },
        ],
      },
    ],
  },
  // Dispositivos
  {
    path: "dispositivos",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
        PROFILES.ADMIN_INSTALLATION,
        PROFILES.TELEMEDIDA,
      ],
    },
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: "importar",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: DeviceImportComponent,
      },
      {
        path: "importar-lecturas",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: DeviceReadingsImportComponent,
      },
      {
        path: "importar-imagenes",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DeviceImageImportComponent,
      },
      {
        path: "sustituir",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: DeviceReplaceComponent,
      },
      {
        path: "importar-coordenadas",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DeviceLocationImportComponent,
      },
      {
        path: "desactivar-fichero",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DeviceDeactivateComponent,
      },
      {
        path: "actualizar-sf-adr/:id",
        canActivate: [RoleGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "actualizar-sf/:id",
        canActivate: [RoleGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "limpiar-redundantes/:id",
        canActivate: [RoleGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "cambiar-agrupacion/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "cambiar-entidad/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "nuevo",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.TELEMEDIDA,
          ],
        },
        component: DeviceNewComponent,
      },
      {
        path: "sim",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: DeviceSimCardsComponent,
      },
      {
        path: "listado",
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: "seleccionados",
            component: MeterSelectionComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
              ],
            },
          },
          {
            path: "asignados",
            component: MetersListAssignComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
              ],
            },
          },
          {
            path: "no-asignados",
            component: MetersListNoAssignComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
              ],
            },
          },
          {
            path: "desactivados",
            component: MetersListDeactivatedComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
              ],
            },
          },
          {
            path: "revision",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_AGRUPACION,
              ],
            },
            component: ReviewListComponent,
          },
          {
            path: "sin-comunicacion",
            component: MetersListInternalComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
              ],
            },
          },
          {
            path: "sustituciones",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
                PROFILES.ADMIN_INSTALLATION,
              ],
            },
            component: MeterSubstitutionsComponent,
          },
          {
            path: "sustituciones-pendientes",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
                PROFILES.ADMIN_INSTALLATION,
              ],
            },
            component: MeterPendingSubstitutionsComponent,
          },
          {
            path: "interno",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: MetersListInternalComponent,
          },
          {
            path: "interno-gprs",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: DeviceGprsListComponent,
          },
          {
            path: "error-activacion",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: MeterActivationErrorComponent,
          },
          {
            path: "bateria-baja",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: MeterBatteryComponent,
          },
          {
            path: "ordenes",
            component: MeterOrdersComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
                PROFILES.ADMIN_INSTALLATION,
              ],
            },
          },
          {
            path: "fabricantes-mbus/clave-comun",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: MbusManufacturersComponent,
          },
          {
            path: "fabricantes-mbus/clave-primaria",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: MbusCandidatesComponent,
          },
          {
            path: "desconocidos-mbus",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
              ],
            },
            component: MbusUnknownListComponent,
          },
          {
            path: "dispositivos-ignorados",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
              ],
            },
            component: MeterIgnoredComponent,
          },
        ],
      },
      {
        path: "detalle",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        children: [
          {
            path: "sensor",
            children: [
              {
                path: "fidegas/:id",
                canDeactivate: [CanDeactivateGuard],
                component: MetersDetailComponent,
              },
            ],
          },
          {
            path: "lw-une/:id",
            canDeactivate: [CanDeactivateGuard],
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: ConcentratorDetailComponent,
          },
          {
            path: "lw-mbus/:id",
            canDeactivate: [CanDeactivateGuard],
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
              ],
            },
            component: ConcentratorDetailComponent,
          },
          {
            path: "lw-mbus/cola-comandos/:id",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
            component: ConcentratorCommandsComponent,
          },
          {
            path: "ordenes/:id",
            canActivate: [RoleGuard],
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.USUARIO_CLIENTE,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.USUARIO_ENTIDAD,
                PROFILES.ADMIN_AGRUPACION,
                PROFILES.USUARIO_AGRUPACION,
                PROFILES.ADMIN_INSTALLATION,
              ],
            },
            component: MeterPendingOrdersComponent,
          },
          {
            path: "log",
            canDeactivate: [CanDeactivateGuard],
            children: [
              {
                path: "eventos/:id",
                component: MeterEventLogComponent,
              },
              {
                path: "tramas/:id",
                canActivate: ENVIRONMENT.production ? [RoleGuard] : null,
                data: {
                  expectedRole: [PROFILES.ARSON],
                },
                component: MeterLogFramesComponent,
              },
              {
                path: "comunicaciones/:id",
                canActivate: ENVIRONMENT.production ? [RoleGuard] : null,
                data: {
                  expectedRole: [PROFILES.ARSON],
                },
                component: MeterLogCommunicationComponent,
              },
              {
                path: "usuarios/:id",
                component: MeterUserLogComponent,
              },
            ],
          },
          {
            path: "contador/:id",
            canDeactivate: [CanDeactivateGuard],
            component: MetersDetailComponent,
          },
          {
            path: "sensor/:id",
            canDeactivate: [CanDeactivateGuard],
            component: MetersDetailComponent,
          },
          {
            path: "alarmas",
            canDeactivate: [CanDeactivateGuard],
            children: [
              {
                path: "listado/:id",
                component: MeterAlarmsComponent,
              },
              {
                path: "configuracion/:id",
                component: AlarmsMeterConfigurationComponent,
              },
            ],
          },
        ],
      },
      {
        path: "desactivar/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "activar/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "divorciar/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "eliminar/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "desasignar/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
    ],
  },
  // Entidades
  {
    path: "entidades",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
      ],
    },
    children: [
      {
        path: "listado",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
          ],
        },
        component: EntityListComponent,
      },
      {
        path: "formulario",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        children: [
          {
            path: "editar/:id",
            component: EntityFormComponent,
          },
          {
            path: "nuevo",
            component: EntityFormComponent,
          },
        ],
      },
    ],
  },
  // Estado de red
  {
    path: "estado-red",
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    children: [
      {
        path: "global",
        component: NetworkStateGlobalComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
      {
        path: "entidad",
        component: BillingEntityComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
      },
      {
        path: "agrupacion",
        component: BillingAgrupationComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_AGRUPACION,
          ],
        },
      },
      {
        path: "kpis",
        component: NetworkStateKpisComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON, PROFILES.ADMIN_CLIENTE],
        },
      },
      {
        path: "resumen-global",
        component: TotalsSummaryComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
    ],
  },
  // Ficheros
  {
    path: "ficheros",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "aqualia",
        component: OutputFilesAqualiaComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
      {
        path: "descarga",
        component: DownloadFilesComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
      },
      {
        path: "plantillas",
        children: [
          {
            path: "listado",
            component: TemplateFilesComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
          {
            path: "editar/:id",
            component: TemplateFormComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
          {
            path: "nuevo",
            component: TemplateFormComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
          {
            path: "exportacion",
            component: OutputFilesTemplateExportComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
          {
            path: "cron/nuevo",
            component: OutputFilesTemplateCronComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
          {
            path: "cron/editar/:id",
            component: OutputFilesTemplateCronComponent,
            canActivate: [RoleGuard],
            data: {
              expectedRole: [PROFILES.ARSON],
            },
          },
        ],
      },
      {
        path: "horarios",
        component: OutputFilesTimerComponent,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
      {
        path: "ek280",
        component: OutputFileEk280Component,
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
      },
    ],
  },
  // Sensórica
  {
    path: "sensorica",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "fugas",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: SensoryLeaksMapComponent,
      },
      {
        path: "presion",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: SensoryPressureMapComponent,
      },
      {
        path: "caudal",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: SensoryFlowMapComponent,
      },
    ],
  },
  // Gateways
  {
    path: "gateways",
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      expectedRole: [PROFILES.ARSON],
    },
    children: [
      {
        path: "appeui",
        canDeactivate: [CanDeactivateGuard],
        component: APPEUIComponent,
      },
      {
        path: "asignar-mejor/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "asignar-mejor-redundante/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "reasignar/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "reasignar-manualmente/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "asignar-desasignar/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "refrescar/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "guardar-app-eui/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "configurar-canales/:id",
        canDeactivate: [CanDeactivateGuard],
        component: RequestQueueTableComponent,
      },
      {
        path: "listado",
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: "asignados",
            component: GatewaysListAssignComponent,
          },
          {
            path: "disponibles",
            component: GatewaysAvailableComponent,
          },
          {
            path: "en-stock",
            component: GatewaysInstockComponent,
          },
          {
            path: "averiados",
            component: GatewaysDamagedComponent,
          },
          {
            path: "reparacion",
            component: GatewaysRepairComponent,
          },
        ],
      },
      {
        path: "detalle",
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: "log",
            canDeactivate: [CanDeactivateGuard],
            children: [
              {
                path: "usuarios/:id",
                component: GatewayUserLogComponent,
              },
              {
                path: "eventos/:id",
                component: GatewayEventLogComponent,
              },
              {
                path: "datalogger/:id",
                component: GatewayDataLogComponent,
              },
              {
                path: "reset/:id",
                component: GatewayResetComponent,
              },
              {
                path: "tramas/:id",
                component: GatewayFramesComponent,
              },
            ],
          },
          {
            path: "gateway/:id",
            component: GatewaysDetailComponent,
          },

          {
            path: "files/:id",
            component: FilesComponent,
          },
          {
            path: "load/:id",
            component: LoadComponent,
          },
          {
            path: "operator/:id",
            component: GatewayOperatorHistoricalComponent,
          },
          {
            path: "mantenimiento/:id",
            component: GatewayManagementComponent,
          },
          {
            path: "actualizar/firmware/:id",
            component: GatewayUpateComponent,
          },
          {
            path: "configurar/:id",
            component: GatewayConfigureComponent,
          },
          {
            path: "alarmas/:id",
            component: GatewayAlarmsComponent,
          },
          {
            path: "contraste-cobertura/:id",
            component: GatewayCoverageContrastComponent,
          },
          {
            path: "relanzar-tramas/:id",
            component: GatewayRelaunchFramesComponent,
          },
          {
            path: "relanzar-tramas/proceso/:id",
            component: RequestQueueTableComponent,
          },
        ],
      },
    ],
  },
  // Gestión de datos
  {
    path: "gestion-datos",
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_AGRUPACION,
      ],
    },
    children: [
      {
        path: "informacion",
        component: ManagementInfoComponent,
      },
      {
        path: "configuracion",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: ManagementConfigurationComponent,
      },
      {
        path: "cups",
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_AGRUPACION,
          ],
        },
        children: [
          {
            path: "listado",
            component: DataManagementCupsComponent,
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_AGRUPACION,
              ],
            },
          },
          {
            path: "dispositivos-sin-cups",
            component: DataManagementNoCupsComponent,
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_AGRUPACION,
              ],
            },
          },
          {
            path: "ordenes",
            component: DataManagementCupsOrdersComponent,
            data: {
              expectedRole: [
                PROFILES.ARSON,
                PROFILES.ADMIN_ENTIDAD,
                PROFILES.ADMIN_CLIENTE,
                PROFILES.ADMIN_AGRUPACION,
              ],
            },
          }
        ]
      },

      {
        path: "importar/:element",
        component: ManagementImportComponent,
      },
      {
        path: "notificaciones",
        component: NotificationImportComponent,
      },
      {
        path: "metrologia",
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: "listado",
            component: MetrologicalListComponent,
          },
          {
            path: "listado-pcs-fcv",
            component: DataManagementPcsFcvListComponent,
          },
          {
            path: "formulario",
            children: [
              {
                path: "editar/:id",
                component: MetrologicalFormComponent,
              },
              {
                path: "nuevo",
                component: MetrologicalFormComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  // Home
  {
    path: "principal",
    component: HomeComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
  },
  // IP
  {
    path: "ip",
    component: IPListComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [PROFILES.ARSON],
    },
  },
  // Mantenimiento
  {
    path: "mantenimiento",
    canActivate: [AuthGuard, RoleGuard],
    canDeactivate: [CanDeactivateGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
      ],
    },
    children: [
      {
        path: "gateways-cliente",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
          ],
        },
        component: ClientEntityGatewaysComponent,
      },
      {
        path: "gateways",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementEntityGatewaysComponent,
      },
      {
        path: "tareas-gateways",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: GatewayTasksComponent,
      },
      {
        path: "global",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementGlobalComponent,
      },
      {
        path: "red",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementNetworkComponent,
      },
      {
        path: "concentradores-global",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementGlobalConcentratorsComponent,
      },
      {
        path: "concentradores-entidad",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementEntityConcentratorsComponent,
      },
      {
        path: "red/optimizar/:id",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "borrado-datos",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementEntityComponent,
      },
      {
        path: "red/asignar-mejor/:id",
        canDeactivate: [CanDeactivateGuard],
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: RequestQueueTableComponent,
      },
      {
        path: "cola-comandos",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementCommandsTailComponent,
      },
      {
        path: "alarmas",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementAlarmsComponent,
      },
      {
        path: "incidencias",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementGlobalWarningsComponent,
      },
      {
        path: "idiomas",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementLanguageLabelsComponent,
      },
      {
        path: "fabricantes",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementManufacturersComponent,
      },
      {
        path: "busqueda-global",
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
        component: ManagementGlobalSearchComponent,
      },
      {
        path: "cron",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementCronComponent,
      },
      {
        path: "editar-agrupaciones",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_CLIENTE,
          ],
        },
        component: ManagementAgrupationEditionComponent,
      },
      {
        path: "usuarios",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementUserComponent,
      },
      // Eventos de usuario
      {
        path: "eventos",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementUserEventsComponent,
      },
      {
        path: "reporte-comunicaciones",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [PROFILES.ARSON],
        },
        component: ManagementCommunicationReportComponent,
      },
    ],
  },
  // Telecontrol
  {
    path: "telecontrol",
    canDeactivate: [CanDeactivateGuard],
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
        PROFILES.TELEMEDIDA,
      ],
    },
    children: [
      {
        path: "alertas-activas",
        component: ControlComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
      },
      {
        path: "historico",
        component: ControlHistoricComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.USUARIO_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.USUARIO_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
            PROFILES.USUARIO_AGRUPACION,
          ],
        },
      },
      {
        path: "telemedida",
        component: ControlTelemetryComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.TELEMEDIDA,
          ],
        },
      },
      {
        path: "test",
        component: ControlTestComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
             PROFILES.ADMIN_CLIENTE,
             PROFILES.ADMIN_ENTIDAD,
             PROFILES.TELEMEDIDA,
          ],
        },
      },
      {
        path: "dataloggers",
        component: ControlDataloggersComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
             PROFILES.ADMIN_CLIENTE,
             PROFILES.ADMIN_ENTIDAD,
             PROFILES.TELEMEDIDA,
          ],
        },
      },
    ],
  },
  // Usuarios
  {
    path: "usuarios",
    canActivate: [AuthGuard, RoleGuard],
    data: {
      expectedRole: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.USUARIO_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.USUARIO_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
        PROFILES.USUARIO_AGRUPACION,
        PROFILES.ADMIN_INSTALLATION,
        PROFILES.TELEMEDIDA,
      ],
    },
    children: [
      {
        path: "listado",
        component: UsersListComponent,
      },
      {
        path: "importar",
        canActivate: [RoleGuard],
        data: {
          expectedRole: [
            PROFILES.ARSON,
            PROFILES.ADMIN_CLIENTE,
            PROFILES.ADMIN_ENTIDAD,
            PROFILES.ADMIN_AGRUPACION,
          ],
        },
        component: UsersImport,
      },
      {
        path: "formulario",
        children: [
          {
            path: "editar/:id",
            component: UserFormComponent,
          },
          {
            path: "nuevo",
            component: UserFormComponent,
          },
        ],
      },
    ],
  },
  // Especiales
  {
    path: "acceso-denegado",
    component: AccesDeniedComponent,
  },
  {
    path: "**",
    component: NotFoundComponent,
  },
];

/****************************************************************************************************/
// Exports
/****************************************************************************************************/

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesModule {}
