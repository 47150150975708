// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { OutputFilesControllerService } from "../../../../services/server/OutputFilesController.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  AgrupationFiles,
  UpdatedAgrupationFiles,
} from "../OutputFileInterface.type";
import { MaterialTreeNode } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-output-files-timer",
  templateUrl: "./output-files-timer.component.html",
  styleUrls: ["./output-files-timer.component.scss"],
})
export class OutputFilesTimerComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  clientSub: Subscription;
  currentEntityList: Entity[];
  entityListSub: Subscription;

  // Ventanas
  jobs: object;
  hours: string[];
  hoursTree: MaterialTreeNode[][];
  originalFiles: AgrupationFiles[] = [];
  clientFiles: AgrupationFiles[];
  originalClientFiles: AgrupationFiles[];
  otherClientFiles: AgrupationFiles[];
  emptyHours: number[] = [];
  newHour: number;

  // Botón nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new-hour");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private OutputFilesController: OutputFilesControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR nicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntityList = this.SessionDataService.getCurrentEntityList();

    // Escucha de cambios en lista de entidades e idioma
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityListSub = this.SessionDataService.getEntityList().subscribe(
      (entityList) => {
        this.currentEntityList = entityList;
        if (this.currentEntityList) {
          this.loadComponent();
        }
      }
    );

    // Carga del componente
    if (this.currentEntityList) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientSub.unsubscribe();
    this.entityListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getFilesConfig();
  }

  // Obtención de la configuración actual
  getFilesConfig(): void {
    this.OutputFilesController.getAqualiaFtp().subscribe((response) => {
      if (response["code"] == 0) {
        this.jobs = response["body"]?.jobs;
        this.hours = this.jobs ? Object.keys(this.jobs) : [];
        this.hours?.forEach((hour: string) => {
          let agrupations = this.jobs[hour]
            ?.split(",")
            ?.map((agrupationId: string) => {
              return parseInt(agrupationId);
            });
          this.originalFiles.push({ hour: hour, agrupations: agrupations });
        });
        this.getClientAgrupationFiles();
        this.originalClientFiles = JSON.parse(JSON.stringify(this.clientFiles));
        this.updateEmptyHours();
        this.getTreeNodes();
      }
    });
  }

  // Obtención de los árboles de cada hora
  getTreeNodes(): void {
    let hoursTree: MaterialTreeNode[][] = [];
    this.hours.forEach((hour: string) =>
      hoursTree.push(this.getTreeNode(hour))
    );
    this.hoursTree = hoursTree;
  }

  // Obtención de los nodos del árbol
  getTreeNode(hour: string): MaterialTreeNode[] {
    let treeNode: MaterialTreeNode[] = [];
    this.currentEntityList?.map((entity: Entity) => {
      if (entity.agrupations?.length > 0) {
        let nodeItem = {
          value: entity.id,
          text: entity.entity,
          children: entity.agrupations
            ?.filter((agrupation: Agrupation) => !agrupation.showAllEntity)
            .map((agrupation: Agrupation) => {
              return {
                value: agrupation.id,
                text: agrupation.name,
                checked: this.checkJob(hour, agrupation.id),
              };
            }),
        };
        treeNode.push(nodeItem);
      }
    });
    return treeNode;
  }

  // Comprobación de si existe tarea de creación de fichero en la agrupación
  checkJob(hour: string, agrupationId: number): boolean {
    let job = this.clientFiles.find(
      (clientFile: AgrupationFiles) => clientFile.hour == hour
    );
    return job?.agrupations?.find((id: number) => id == agrupationId)
      ? true
      : false;
  }

  // Actualización de selección
  onSelectedChange(hour: string, agrupationsIds: number[]): void {
    let hourIndex = this.clientFiles.findIndex(
      (agrupationFiles: AgrupationFiles) => agrupationFiles.hour == hour
    );
    this.clientFiles[hourIndex ? hourIndex : 0].agrupations = agrupationsIds;
    this.clientFiles[hourIndex ? hourIndex : 0].selected =
      this.clientFiles[hourIndex ? hourIndex : 0].agrupations.length;
  }

  // Obtención de las agrupaciones con fichero pertenecientes al cliente en curso
  getClientAgrupationFiles(): void {
    this.clientFiles = [];
    this.otherClientFiles = [];
    this.originalFiles.forEach((files: AgrupationFiles) => {
      let clientAgrupations: number[] = [];
      let otherClientAgrupations: number[] = [];

      // Comprobación de si la agrupación pertenece a alguna entidad del cliente
      files.agrupations.forEach((agrupationId: number) => {
        if (
          this.currentEntityList.find((entity: Entity) =>
            entity.agrupations?.find(
              (agrupation: Agrupation) => agrupation.id == agrupationId
            )
          )
        ) {
          clientAgrupations.push(agrupationId);
        } else if (agrupationId) {
          otherClientAgrupations.push(agrupationId);
        }
      });

      // Ficheros del cliente
      this.clientFiles.push({
        hour: files.hour,
        agrupations: clientAgrupations,
        selected: clientAgrupations?.length,
        showTree: false,
        showHour: clientAgrupations?.length > 0,
      });

      // Ficheros de otros clientes
      this.otherClientFiles.push({
        hour: files.hour,
        agrupations: otherClientAgrupations,
      });
    });
  }

  // Guardado de configuración
  saveFilesConfig(): void {
    let data: UpdatedAgrupationFiles[] = this.getNewConfigData();
    let saveRequest: Observable<object>[] = [];
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("files-save-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        data?.map((hourData: UpdatedAgrupationFiles) => {
          saveRequest.push(
            this.OutputFilesController.updateAqualiaFtp(hourData)
          );
        });

        forkJoin(saveRequest).subscribe((results) => {
          if (results.every((result: any) => result["code"] == "0")) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
          }
          this.ReloadComponentService.reload();
        });
      }
    });
  }

  // Obtención de la nueva configuración
  getNewConfigData(): UpdatedAgrupationFiles[] {
    let data: UpdatedAgrupationFiles[] = [];

    this.clientFiles.forEach((files: AgrupationFiles) => {
      let mode: number;
      let agrupationIdList: number[];
      let allIncluded: boolean;
      let originalClientFiles = this.originalClientFiles?.find(
        (originalAgrupationFiles: AgrupationFiles) =>
          originalAgrupationFiles.hour == files.hour
      );
      let otherClientAgrupationFiles = this.otherClientFiles?.find(
        (otherFiles: AgrupationFiles) => otherFiles.hour == files.hour
      );

      // Comprobación de si todas la agrupaciones previas de ficheros del cliente siguen incluidas en la nueva selección
      if (originalClientFiles) {
        allIncluded = originalClientFiles.agrupations.every(
          (agrupationId: number) => files.agrupations.includes(agrupationId)
        );
      }

      // Si había datos de ficheros anteriores y todas las agrupaciones previas están incluidas en la nueva selección, se añaden las nuevas
      if (originalClientFiles && allIncluded) {
        mode = 1;
        agrupationIdList = files.agrupations.filter(
          (agrupationId: number) =>
            !originalClientFiles.agrupations.includes(agrupationId)
        );
        // Si había datos de ficheros anteriores pero alguna agrupación no está incluida en la nueva selección, se sustituyen los ficheros
      } else if (originalClientFiles && !allIncluded) {
        mode = 0;
        if (otherClientAgrupationFiles) {
          agrupationIdList = otherClientAgrupationFiles.agrupations.concat(
            files.agrupations
          );
        } else {
          agrupationIdList = files.agrupations;
        }
        // Si no había datos de ficheros anteriores se añaden los nuevos ficheros
      } else if (!originalClientFiles) {
        mode = 1;
        if (otherClientAgrupationFiles) {
          agrupationIdList = otherClientAgrupationFiles.agrupations.concat(
            files.agrupations
          );
        } else {
          agrupationIdList = files.agrupations;
        }
      }

      // Si hay nuevas agrupaciones con ficheros o si se han elimninado todas las agrupaciones previas, se generan los datos de petición
      if (agrupationIdList?.length > 0 || mode == 0) {
        data.push({
          hour: parseInt(files.hour),
          modo: mode,
          agrupationIdList: agrupationIdList,
        });
      }
    });

    return data;
  }

  // Creación de nueva hora de envío
  newFiles(): void {
    let hourExist = this.clientFiles.find(
      (clientFile: AgrupationFiles) => parseInt(clientFile.hour) == this.newHour
    );
    if (hourExist) {
      hourExist.agrupations = [];
      hourExist.selected = 0;
      hourExist.showHour = true;
      hourExist.showTree = false;
    } else {
      this.clientFiles.push({
        hour: this.newHour.toString(),
        agrupations: [],
        selected: 0,
        showHour: true,
        showTree: false,
      });
      this.clientFiles.sort((a, b) => parseInt(a.hour) - parseInt(b.hour));
      this.hours.push(this.newHour.toString());
    }
    this.hours.sort((a, b) => parseInt(a) - parseInt(b));
    this.getTreeNodes();
    this.updateEmptyHours();
  }

  // Actualización de horas sin ficheros
  updateEmptyHours(): void {
    this.emptyHours = [];
    for (let i = 0; i < 24; i++) {
      if (
        !this.clientFiles.find(
          (clientFile: AgrupationFiles) =>
            parseInt(clientFile.hour) == i && clientFile.showHour
        )
      ) {
        this.emptyHours.push(i);
      }
    }
    this.newHour = this.emptyHours[0];
  }

  // Eliminación de hora de ficheros
  deleteHour(hour: string): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("files-delete-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let clientFile = this.clientFiles.find(
          (clientFile: AgrupationFiles) => clientFile.hour == hour
        );
        clientFile.agrupations = [];
        clientFile.showHour = false;
        this.updateEmptyHours();
      }
    });
  }

  // Selección de todas las agrupaciones
  selectAll(hour: string): void {
    let clientFile = this.clientFiles.find(
      (clientFile: AgrupationFiles) => clientFile.hour == hour
    );
    clientFile.agrupations = [];
    this.currentEntityList.map((entity: Entity) =>
      entity.agrupations
        ?.filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        ?.map((agrupation: Agrupation) =>
          clientFile.agrupations.push(agrupation.id)
        )
    );
    clientFile.selected = clientFile.agrupations.length;
    this.getTreeNodes();
  }

  // Reseteo de datos
  resetData(): void {
    this.hours = this.jobs ? Object.keys(this.jobs) : [];
    this.clientFiles = JSON.parse(JSON.stringify(this.originalClientFiles));
    this.updateEmptyHours();
    this.getTreeNodes();
  }
}
