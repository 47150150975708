<section class="content-header">
  <!-- ANCHOR Mapa de cambio de localización -->
  <app-device-change-location
    *ngIf="meter"
    [device]="meter"
    [changeLocationActive]="changeLocationActive"
    (changeLocationDisableFlag)="changeLocationActive = false"
    (reloadFlag)="ReloadComponentService.reload()"
  >
  </app-device-change-location>

  <!-- ANCHOR Panel de contador -->
  <div class="panel panel-default">
    <!-- Encabezado del panel -->
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>

      <div class="meter-detail-heading-info">
        <i
          class="fas fa-eye pointer"
          [ngClass]="{
            'meter-detail-info-large':
              sessionProfile != PROFILES.ARSON &&
              sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              sessionProfile != PROFILES.ADMIN_CLIENTE &&
              sessionProfile != PROFILES.ADMIN_AGRUPACION
          }"
          title="{{ 'more-info' | translate }}"
          (click)="showMeterData()"
        >
        </i>
        <i
          *ngIf="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION
          "
          class="fas fa-edit pointer"
          title="{{ 'edit' | translate }}"
          (click)="changeSerialNumberModal()"
        >
        </i>
      </div>

      <div class="meter-detail-title">
        <h5>
          <b>
            {{
              meter != null && meter.nroSerie != null
                ? meter.nroSerie
                : ("no-data" | translate)
            }}
            {{
              meter != null && meter.comments != null && meter.comments != ""
                ? "(" + meter.comments + ")"
                : null
            }}
          </b>

          <div *ngIf="meter?.enRevision" class="meter-detail-review">
            <a
              [routerLink]="['/dispositivos/listado/revision']"
              [state]="{ data: meter?.nroSerie }"
            >
              {{ "meter-in-review" | translate }}
            </a>
          </div>
        </h5>

        <div *ngIf="METER_STATES[meter?.activate]" class="meter-detail-state">
          {{ METER_STATES[meter?.activate] | translate }}
        </div>
      </div>

      <!-- ANCHOR Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>

    <div class="panel-body meter-detail-info">
      <div class="meter-detail-box">
        <div class="meter-detail-cards-box">
          <!-- ANCHOR Tarjetas de contador -->
          <div class="meter-detail-cards">
            <!-- Tarjetas de datos -->
            <app-cards-controller
              [cardsId]="'concentratorDetail'"
              [cardsOrder]="meterCardsOrder"
              [metrologyType]="meter?.metrologyType"
              [cardStretch]="true"
              [cardWidth]="30"
              [cardsGap]="1.5"
              (cardClickEventFlag)="cardClickEvent($event)"
              [data]="meterCardsData"
            >
            </app-cards-controller>
          </div>

          <!-- Listado de alarmas -->
          <div *ngIf="meterCardsData && meterAlarms" class="meter-alarms">
            <table>
              <tr
                [ngClass]="
                  meterAlarms.length == 0 || meterAlarms == null
                    ? 'table-bg-green-gradient'
                    : 'table-bg-red-gradient'
                "
              >
                <th
                  colspan="2"
                  [routerLink]="[
                    '/dispositivos/detalle/alarmas/listado',
                    meterId
                  ]"
                  [state]="{ data: meter.nroSerie }"
                >
                  <i class="fa fa-exclamation-triangle"></i>
                  {{ "device-alarms" | translate }}
                  <i class="fas fa-link meter-alarms-table-link"></i>
                </th>
              </tr>
              <tr *ngIf="meterAlarms.length == 0 || meterAlarms == null">
                <td>
                  {{ "no-alarms" | translate }}
                </td>
              </tr>
              <ng-container *ngFor="let alarm of meterAlarms">
                <tr>
                  <td class="meter-alarms-table-date">
                    {{ alarm.initDate | date : "dd/MM/yyyy" || null }}
                  </td>
                  <td class="meter-alarms-table-alarm">
                    {{ alarm.name }}
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <!-- ANCHOR Mapa de contador -->
        <div class="meter-detail-map" *ngIf="meter">
          <app-map-controller
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            [disableCluster]="true"
            (actionFlag)="mapAction($event.action, $event.data)"
            [gatewaysData]="mapGateways"
            [metersData]="[meter]"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>

  <!-- ANCHOR Panel de estado -->
  <app-device-state-panel
    *ngIf="sessionProfile == PROFILES.ARSON"
    [meter]="meter"
    [meterGatewayList]="meterGatewayList"
    [otherEntityGatewayList]="otherEntityGatewayList"
    [stateCardsData]="stateCardsData"
  >
  </app-device-state-panel>

  <!-- ANCHOR Tabla de contadores hijos -->
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5 *ngIf="meterTypeByMask != 'LW_MBUS_CON'">
        <b>{{ "meters-table" | translate }}</b>
      </h5>

      <div class="lw-mbus-table-select">
        <app-material-select
          *ngIf="meterTypeByMask == 'LW_MBUS_CON'"
          [fullWidth]="true"
          class="material-header-select"
          [title]="'show' | translate"
          [bindValue]="'id'"
          [bindLabel]="'value'"
          [selected]="'meters-table'"
          [data]="lwMbusTables"
          (selectedOption)="selectedTable = $event?.id"
        ></app-material-select>
      </div>
    </div>

    <div class="panel-body" [ngSwitch]="selectedTable">
      <!-- Tabla -->
      <div *ngSwitchCase="'meters-table'">
        <app-table-controller
          [tableId]="'lwUneDetailChilds'"
          [export]="true"
          [exportFileName]="childsTableExportFileName"
          [orderBy]="childsTableOrderBy"
          [columns]="childsTableColumns"
          [rowNumbers]="childsTableRowNumbers"
          [maxReg]="childsTableMaxReg"
          (tableAction)="childsTableActions($event)"
          (selectedDataUpdateFlag)="updateMapChildSelected($event)"
          [specialFilter]="true"
          [data]="childsTableData"
        >
        </app-table-controller>

        <div *ngIf="meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="
              mapChildShowNoCom = !mapChildShowNoCom; showMapChildsNoCom()
            "
          >
            <i
              [ngClass]="mapChildShowNoCom ? 'fas fa-eye-slash' : 'fas fa-eye'"
            ></i>
            {{
              (mapChildShowNoCom ? "hide-no-received" : "show-not-received")
                | translate
            }}
          </button>
        </div>

        <!-- Mapa de contadores hijo -->
        <div
          *ngIf="meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR"
          class="meter-childs-detail-map"
        >
          <app-map-controller
            #childsMap
            [mapType]="mapChildType"
            [mapHeight]="mapChildHeight"
            [activateAllLayers]="childsMapactivateAllLayers"
            [gatewaysData]="[]"
            [metersData]="mapChildMeters"
            (actionFlag)="lwMbusMapAction($event.action, $event.data)"
          >
          </app-map-controller>
        </div>
      </div>

      <!-- ANCHOR Tabla de contadores inactivos -->
      <div *ngSwitchCase="'inactive-devices'">
        <app-table-controller
          [export]="true"
          [exportFileName]="childsTableExportFileName"
          [tableId]="'lwMbusDetailInactive'"
          [columns]="lwMbusInactiveColumns"
          [rowNumbers]="true"
          [maxReg]="20"
          [specialFilter]="true"
          [data]="lwMbusInactiveTableData"
        >
        </app-table-controller>
      </div>

      <!-- ANCHOR Tabla de contadores desconocidos -->
      <div *ngSwitchCase="'unknown-devices'">
        <app-table-controller
          [export]="true"
          [exportFileName]="childsTableExportFileName"
          [tableId]="'lwMbusDetailUnkonwn'"
          [columns]="lwMbusUnknownColumns"
          [rowNumbers]="true"
          [maxReg]="20"
          [specialFilter]="true"
          [data]="lwMbusUnknownTableData"
        >
        </app-table-controller>
      </div>

      <!-- ANCHOR Tabla de contadores fijados MBUS  -->
      <div *ngSwitchCase="'permanent'">
        <app-table-controller
          [tableId]="'lwMbusDetailDevices'"
          [columns]="mbusFixedDevicesTableColumns"
          [rowNumbers]="true"
          [export]="true"
          [exportFileName]="childsTableExportFileName"
          [maxReg]="20"
          (tableAction)="mbusDevicesTableActions($event)"
          [tableGlobalActions]="mbusFixedDevicesTableGlobalActions"
          (tableGlobalAction)="mbusDevicesTableGlobalAction($event.title)"
          (selectedDataUpdateFlag)="mbusFixedDevicesTableSelected = $event"
          [specialFilter]="true"
          [data]="meter.mainMeters"
        >
        </app-table-controller>
      </div>

      <!-- ANCHOR Tabla de contadores MBUS  -->
      <div *ngSwitchCase="'private-key'">
        <app-table-controller
          [tableId]="'lwMbusDetailDevices'"
          [columns]="mbusDevicesTableColumns"
          [rowNumbers]="true"
          [export]="true"
          [exportFileName]="childsTableExportFileName"
          [maxReg]="20"
          (tableAction)="mbusDevicesTableActions($event)"
          [tableGlobalActions]="mbusDevicesTableGlobalActions"
          (tableGlobalAction)="mbusDevicesTableGlobalAction($event.title)"
          (selectedDataUpdateFlag)="mbusDevicesTableSelected = $event"
          [specialFilter]="true"
          [data]="mbusDevicesTableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>

  <!-- ANCHOR Gráfica de tramas perdidas -->
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "frames-lost" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body meter-detail-graph">
      <!-- Gráfica -->
      <!-- UNE -->
      <app-graph-controller
        *ngIf="highchartsOptionsMeters && meter?.metrologyType == 3"
        [highchartsOptions]="highchartsOptionsMeters"
        [data]="chartOptionsMeters"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to, 'meters')"
      >
      </app-graph-controller>

      <!-- MBUS -->
      <app-graph-controller
        *ngIf="
          highchartsOptionsMeters &&
          meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
        "
        [highchartsOptions]="highchartsOptionsMeters"
        [data]="chartOptionsMeters"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="getMetersSeries()"
      >
      </app-graph-controller>
    </div>
  </div>

  <!-- ANCHOR Gráfica de batería -->
  <div
    *ngIf="sessionProfile == PROFILES.ARSON"
    id="battery-graph"
    class="panel panel-default"
  >
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "battery-only" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body meter-detail-graph">
      <!-- Gráfica -->
      <!-- UNE -->
      <app-graph-controller
        *ngIf="
          highchartsOptionsBattery &&
          meter?.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR
        "
        [highchartsOptions]="highchartsOptionsBattery"
        [data]="chartOptionsBattery"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to, 'battery')"
      >
      </app-graph-controller>

      <!-- MBUS -->
      <app-graph-controller
        *ngIf="
          highchartsOptionsBattery &&
          meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
        "
        [highchartsOptions]="highchartsOptionsBattery"
        [data]="chartOptionsBattery"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="getBatterySeries()"
      >
      </app-graph-controller>
    </div>
  </div>

  <!-- ANCHOR Gráfica de bloques reintentados LW MBUS -->
  <div
    *ngIf="sessionProfile == PROFILES.ARSON"
    id="mask-retrys-graph"
    class="panel panel-default"
  >
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "lw-mbus-retrys" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body meter-detail-graph">
      <!-- Gráfica -->
      <app-graph-controller
        *ngIf="
          maksRetrysHighchartsOptions &&
          meter?.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
        "
        [highchartsOptions]="maksRetrysHighchartsOptions"
        [data]="chartOptionsMaskRetrys"
        [chartConstructor]="chartConstructor"
        [dateRange]="maskRetrysDefaultDateRange"
        (dataRequest)="loadMaskRetrysGraphData($event.from, $event.to)"
      >
      </app-graph-controller>
    </div>
  </div>

  <!-- ANCHOR Tabla de sniffer -->
  <ng-container *ngIf="snifferTableData && sessionProfile == PROFILES.ARSON">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "sniffer-list" | translate }}</b>
        </h5>
      </div>
      <div class="panel-body">
        <app-table-controller
          [tableId]="'gatewaysSniffer'"
          [columns]="snifferColumns"
          [rowNumbers]="true"
          [orderBy]="snifferOrderBy"
          (tableAction)="snifferTableActions($event)"
          [data]="snifferTableData"
          [maxReg]="10"
        >
        </app-table-controller>
      </div>
    </div>
  </ng-container>
</section>
