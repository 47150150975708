// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts";
// Estadísticas
import * as ss from "simple-statistics";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import {
  CONSUMPTION_PROFILES,
  ConsumptionProfile,
} from "../data-analysis-models-edit/data-analysis-patterns";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-data-analysis-models-list",
  templateUrl: "./data-analysis-models-list.component.html",
  styleUrls: ["./data-analysis-models-list.component.scss"],
})
export class DataAnalysisModelsListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Gráfica
  consumptionProfileList: ConsumptionProfile[];
  profileCharts: any[];
  highchartsOptions: Options;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastNaturalWeeks("8");
  pattern: {
    patternSerie: number[][];
    normalizedPattern: number[];
    consumptionPattern: number[];
    consumptionSdPattern: number[];
  };
  yearlyPattern: boolean;
  patternSelectOptions = [
    { id: "weekly", name: this.translate.instant("weekly-pattern") },
    { id: "yearly", name: this.translate.instant("yearly-pattern") },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    private GraphOptionsService: GraphOptionsService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    public SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getConsumptionProfiles();
    this.setHighchartsOptions();
  }

  // Obtención de perfiles de consumo
  getConsumptionProfiles(): void {
    let consumptionProfileList = [];
    this.DataAnalysisController.getConsumptionProfiles().subscribe(
      (response) => {
        if (response["code"] == 0) {
          let consumptionProfileData = response["body"];
          consumptionProfileData.forEach((consumptionProfile) => {
            if (consumptionProfile.profilePatterns) {
              consumptionProfile.profilePatterns = JSON.parse(
                consumptionProfile.profilePatterns
              );
            } else {
              consumptionProfile.profilePatterns = {};
            }
            consumptionProfile.meterPattern = consumptionProfile.meterPattern
              ?.split(",")
              .map((data) => parseFloat(data));
          });
          consumptionProfileList = consumptionProfileData.filter(
            (consumptionProfile: ConsumptionProfile) =>
              !consumptionProfile.meterId
          );
        }
        let profiles = Object.keys(CONSUMPTION_PROFILES);
        for (let i = 0; i < profiles.length / 2; i++) {
          if (i == 8) {
            i++;
          }
          let profile = consumptionProfileList.find(
            (consumptionProfile) => consumptionProfile.profileId == i
          );
          if (profile) {
            profile.profileName = this.translate.instant(
              "consumptionProfile" + i
            );
          } else {
            consumptionProfileList.push({
              profileId: i,
              profileName: this.translate.instant("consumptionProfile" + i),
              profileSimilarity: 0.95,
            });
          }
        }
        this.consumptionProfileList = consumptionProfileList.sort(
          (a, b) => a.profileId - b.profileId
        );
        this.setProfileChartsOptions();
      }
    );
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    highchartsOptions["navigation"] = { buttonOptions: { enabled: false } };
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setProfileChartsOptions(): void {
    Highcharts.seriesType("column2", "column", {});
    const self = this;
    this.profileCharts = [];
    this.consumptionProfileList.forEach((profile, i) => {
      let chartOptions: object = JSON.parse(
        JSON.stringify(GRAPH_CONFIG.default.chartOptions)
      );
      chartOptions["navigator"]["enabled"] = false;
      chartOptions["legend"]["enabled"] = false;
      chartOptions["rangeSelector"]["buttons"] = [];
      chartOptions["pointPlacement"] = "between";
      // Patrón de perfil
      chartOptions["yAxis"] = [
        {
          labels: {
            enabled: false,
          },
          opposite: false,
        },
      ];
      chartOptions["xAxis"] = this.yearlyPattern
        ? [
            {
              dateTimeLabelFormats: {
                millisecond: "%b",
                second: "%b",
                minute: "%b",
                hour: "%b",
                day: "%b",
                week: "%b",
                month: "%b",
                year: "%b",
              },
              startOnTick: true,
              showFirstLabel: true,
              endOnTick: false,
              tickInterval: 1,
              units: [["month", [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]]],
            },
            { visible: false },
          ]
        : [
            {
              dateTimeLabelFormats: {
                millisecond: "%A",
                second: "%A",
                minute: "%A",
                hour: "%A",
                day: "%A",
                week: "%A",
                month: "%A",
                year: "%A",
              },
              startOnTick: true,
              showFirstLabel: true,
              endOnTick: false,
            },
            { visible: false },
          ];
      chartOptions["tooltip"] = {
        split: false,
        shared: true,
        valueDecimals: 3,
        formatter: function () {
          return Highcharts.dateFormat(
            self.yearlyPattern ? "%B" : "%A %H:%M",
            this.point.x
          );
        },
      };
      chartOptions["series"] = [
        {
          id: "profilePatternRange" + i,
          name: this.translate.instant(
            "consumptionProfile" + profile.profileId
          ),
          data: profile?.profilePatterns
            ? profile?.profilePatterns[
                this.yearlyPattern ? "yearly" : "hourly"
              ]?.map((data, i) => {
                let min =
                  data *
                    ss.standardDeviation(
                      profile?.profilePatterns[
                        this.yearlyPattern ? "yearly" : "hourly"
                      ]
                    ) +
                  ss.mean(
                    profile?.profilePatterns[
                      this.yearlyPattern ? "yearly" : "hourly"
                    ]
                  ) -
                  ss.standardDeviation(
                    profile?.profilePatterns[
                      this.yearlyPattern ? "yearly" : "hourly"
                    ]
                  ) *
                    (this.yearlyPattern ? 0.07 : 1);
                let max =
                  data *
                    ss.standardDeviation(
                      profile?.profilePatterns[
                        this.yearlyPattern ? "yearly" : "hourly"
                      ]
                    ) +
                  ss.mean(
                    profile?.profilePatterns[
                      this.yearlyPattern ? "yearly" : "hourly"
                    ]
                  ) +
                  ss.standardDeviation(
                    profile?.profilePatterns[
                      this.yearlyPattern ? "yearly" : "hourly"
                    ]
                  ) *
                    (this.yearlyPattern ? 0.07 : 1);
                return [
                  this.defaultDateRange.startDate.valueOf() +
                    60 * 60 * 1000 * i,
                  min > 0 ? min : 0,
                  max > 0 ? max : 0,
                ];
              })
            : [],
          dataGrouping: {
            forced: true,
            units: this.yearlyPattern ? [["month", [1]]] : [["hour", [1]]],
            approximation: this.yearlyPattern ? "sum" : null,
          },
          type: this.yearlyPattern ? "column" : "arearange",
          color: this.yearlyPattern ? "#ef5350" : "#4caf50",
          fillColor: "rgba(76, 175, 80, 0.3)",
          zIndex: 9,
        },
      ];
      if (this.yearlyPattern) {
        let maxLine = JSON.parse(JSON.stringify(chartOptions["series"][0]));
        maxLine.id = "profilePatternMaxRange";
        maxLine.data = maxLine.data?.map((data) => [data[0], data[2]]);
        maxLine.color = "#4caf50";
        maxLine.zIndex = 0;
        chartOptions["series"].push(maxLine);
        maxLine.type = "column2";
        maxLine.xAxis = 1;
      }
      this.profileCharts.push(chartOptions);
    });
  }

  // Nuevo perfil de consumo
  newProfile(): void {
    this.router.navigate(["/analisis-datos/modelos-analiticos/editar"]);
  }

  // Patrón semanal/anual
  updatePatternType(): void {
    this.defaultDateRange = this.yearlyPattern
      ? this.DateParserService.getLastNaturalYears("2")
      : this.DateParserService.getLastNaturalWeeks("8");
    this.setProfileChartsOptions();
  }
}
