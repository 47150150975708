// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
  TableHighlightRow,
} from "../../../../modules/table-module/TableInterface.type";
import { DeviceForClear, DeviceGateway } from "../ManagementInterface.type";
import { MaterialSelectGroup } from "../../../../modules/material-module/MaterialInterface.type";
// Componentes
import { ManagementNetworkDialogComponent } from "./management-network-dialog/management-network-dialog.component";

@Component({
  selector: "app-globalnetwork",
  templateUrl: "./management-network.component.html",
  styleUrls: ["./management-network.component.scss"],
})
export class ManagementNetworkComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  clientList: Client[];
  clientListSub: Subscription;

  // Selectores
  globalEntityList: Entity[];
  globalAgrupationList: Agrupation[];
  taskSelectedEntities: number[];
  optimizationList: { id: number; name: string }[];
  optimizationListSelected: { id: number; name: string };
  optimizationOptions: object = [
    {
      name: this.translate.instant("network-optimize-entity"),
      value: "entity",
    },
    {
      name: this.translate.instant("network-optimize-agrupation"),
      value: "agrupation",
    },
  ];
  currentOptimization = this.optimizationOptions[0];
  clientGroups: MaterialSelectGroup[];
  entityGroups: MaterialSelectGroup[];
  selectGroups: MaterialSelectGroup[];
  groupsBindValue: string;
  simulating: boolean = false;
  updating: boolean = false;

  // Tabla
  tableSelectedData: DeviceForClear[];
  tableData: DeviceForClear[];
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightCurrentGateway",
      color: "green",
      title: "main",
    },
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
      link: "deviceLink",
      linkCheck: {
        condition: "agrupation",
        attribute: "agrupationId",
        check: "id",
      },
    },
    {
      title: "gateways",
      data: null,
      search: null,
      sort: null,
      extraTable: true,
      visible: true,
      noExport: true,
    },
    {
      title: "groups",
      data: "agrupation",
      search: "agrupation",
      sort: "agrupation",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVentaMainGateway",
      search: "unidadVentaMainGateway",
      sort: "unidadVentaMainGateway",
      visible: true,
    },
    {
      title: "last-join",
      data: "lastJoinTimestampParsed",
      search: "lastJoinTimestampParsed",
      sort: "lastJoinTimestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "last-clear-date",
      data: "lastClearExecutionParsed",
      search: "lastClearExecutionParsed",
      sort: "lastClearExecution",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "redundants-number",
      data: "nroRedundantGatewaysParsed",
      search: "nroRedundantGatewaysParsed",
      sort: "nroRedundantGateways",
      numerical: true,
      visible: true,
    },
    {
      title: "main-changed-date",
      data: "lastMainChangeParsed",
      search: "lastMainChangeParsed",
      sort: "lastMainChange",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "redundant-clear-date",
      data: "lastRedundantReduceParsed",
      search: "lastRedundantReduceParsed",
      sort: "lastRedundantReduce",
      date: true,
      dateLocal: true,
      visible: true,
    },
  ];
  extraTableColumns: TableDataColumn[] = [
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "SNR",
      data: "snrParsed",
      search: "snrParsed",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "RSSI (dBm)",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-rssi-received",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MaterialDialogService: MaterialDialogService,
    private ManagementController: ManagementControllerService,
    private requestQueue: RequestQueueService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.clientList = this.SessionDataService.getCurrentClientList();

    this.clientListSub = this.SessionDataService
      .getClientList()
      .subscribe((clientList) => {
        if (clientList) {
          this.clientList = clientList;
          this.loadComponent();
        }
      });

    // Carga del componente
    if (this.clientList) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Entidades con optimización automática
    this.getTaskSelectedEntities();

    // Listado global de entidades
    this.globalEntityList = [];
    this.clientList.map(
      (client: Client) =>
        (this.globalEntityList = this.globalEntityList.concat(
          client.entityList.sort((a, b) => a.entity.localeCompare(b.entity))
        ))
    );

    // Grupos de selección de clientes
    this.clientGroups = this.clientList.map((client: Client) => {
      return {
        value: client.clientId,
        name: client.clientName,
        disabled: false,
      };
    });

    // Grupos de selección de entidades
    this.entityGroups = this.globalEntityList.map((entity: Entity) => {
      return {
        value: entity.id,
        name: entity.entity,
        disabled: false,
      };
    });

    // Listado global de agrupaciones
    this.globalAgrupationList = [];
    let sortedGlobalEntityList = this.globalEntityList.sort((a, b) =>
      a.entity.localeCompare(b.entity)
    );
    sortedGlobalEntityList.map(
      (entity: Entity) =>
        (this.globalAgrupationList = this.globalAgrupationList.concat(
          entity.agrupations.sort((a, b) => a.name.localeCompare(b.name))
        ))
    );

    this.updateOptimizationList();
  }

  // Obtención de las entidades con optimización automática
  getTaskSelectedEntities(): void {
    this.ManagementController.getClearAssignGatewayTask().subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.taskSelectedEntities = response["body"];
        }
      }
    );
  }

  // Actualización de entidades seleccionadas de la tarea
  updateTaskSelectedEntities(selectedEntities: Entity[]): void {
    this.taskSelectedEntities = selectedEntities.map((entity: Entity) => {
      return entity.id;
    });
  }

  // Actualización de las entidades de la tarea
  updateTaskEntities(): void {
    this.ManagementController.saveClearAssignGatewayTask(
      this.taskSelectedEntities
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
      }
    });
  }

  // Actualización del listado de selección de entidad/agrupación a optimizar
  updateOptimizationList(): void {
    if (this.currentOptimization.value == "entity") {
      this.selectGroups = this.clientGroups;
      this.groupsBindValue = "client";
      this.optimizationList = this.globalEntityList.map((entity: Entity) => {
        return { id: entity.id, name: entity.entity, client: entity.client };
      });
    } else {
      this.selectGroups = this.entityGroups;
      this.groupsBindValue = "entity";
      this.optimizationList = this.globalAgrupationList.map(
        (agrupation: Agrupation) => {
          return {
            id: agrupation.id,
            name: agrupation.name,
            entity: agrupation.entity,
          };
        }
      );
    }
  }

  // Actualización de datos de la tabla
  updateTableData(): void {
    this.tableData = null;
    this.updating = true;
    let url =
      this.currentOptimization.value == "entity"
        ? this.ManagementController.getClearEntityDeviceList(
            this.optimizationListSelected.id
          )
        : this.ManagementController.getClearAgrupationDeviceList(
            this.optimizationListSelected.id
          );
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let tableData: DeviceForClear[] = response["body"];
        tableData.forEach((device: DeviceForClear) => {
          device.extraTableData = {
            columns: this.extraTableColumns,
            highlightRow: [
              { condition: "highlightCurrentGateway", color: "green" },
            ],
            data: null,
          };
          device.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            device.metrologyType,
            device.id
          );
        });
        this.tableData = tableData;
        this.updating = false;
      }
    });
  }

  // Optimizar selección
  optimizeSelection(all?: boolean) {
    this.requestQueue.setTask(
      "optimize",
      all ? this.tableData : this.tableSelectedData
    );
  }

  // Obtención de los datos de la tabla anidada
  getExtraTableData(device: DeviceForClear): void {
    this.ManagementController.getDeviceGatewayList(device.id).subscribe(
      (response) => {
        let data = [];
        if (response["code"] == 0) {
          data = response["body"];
          data.forEach((gateway: DeviceGateway) => {
            gateway.highlightCurrentGateway =
              gateway.unidadVenta == device.unidadVentaMainGateway;
          });
        }
        device.extraTableData.data = data.sort((a, b) =>
          a === b ? 0 : a ? -1 : 1
        );
      }
    );
  }

  // Simulación de la optimización de todos los dispositivos
  simulateOptimization(): void {
    this.simulating = true;
    this.ManagementController.assignBestAndClearRedundantByDevice(
      this.tableData.map((device: DeviceForClear) => device.id),
      true
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.simulating = false;
        this.MaterialDialogService.openDialog(ManagementNetworkDialogComponent, {
          deviceList: this.tableData,
          bestSignal: response["body"]?.map((result: any) => result.bestSignal),
          clearRedundant: response["body"]?.map(
            (result: any) => result.clearRedundant
          ),
        });
      }
    });
  }
}
