<div class="dialog-container">
  <div class="dialog-body">
    <app-graph-controller
    [noTypeSelector]="true" [highchartsOptions]="highchartsOptions"     [noTypeSelector]="false"
     [chartConstructor]="chartConstructor"  [dateRange]="defaultDateRange"    [yAxisMinRange]="0" [yAxisMaxRange]="100"
     [data]="chartOptions" (dataRequest)="loadGraphData($event.from, $event.to)">
   </app-graph-controller>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="close()">{{ 'back' | translate }}</button>
  </div>
</div>
