export const GRAPH_CONFIG = {
  default: {
    options: {
      accessibility: {
        enabled: false,
      },
      lang: {
        loading: "loading",
        months: [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ],
        weekdays: [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ],
        shortMonths: [
          "jan",
          "feb",
          "mar",
          "apr",
          "ma",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ],
        noData: "graph-no-data",
        viewFullscreen: "graph-full",
        rangeSelectorFrom: "graph-from",
        rangeSelectorTo: "graph-to",
        rangeSelectorZoom: "graph-zoom",
        downloadPNG: "graph-png",
        // downloadJPEG: "graph-jpg",
        // downloadPDF: "graph-pdf",
        // downloadSVG: "graph-svg",
        downloadCSV: "graph-csv",
        downloadXLS: "graph-xls",
        printChart: "graph-print",
        resetZoom: "graph-resetZoom",
        resetZoomTitle: "graph-resetZoom",
        viewData: "graph-table",
        thousandsSep: "graph-thousand",
        decimalPoint: "graph-decimal",
        contextButtonTitle: "graph-menu",
      },
      title: {
        text: "",
      },
      rangeSelector: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        ordinal: false,
        crosshair: {
          width: 1,
          color: "red",
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: undefined,
          dataLabels: { enabled: false, formatter: undefined },
          minPointLength: 5,
          borderWidth: undefined,
          borderColor: "#334eff",
          pointWidth: undefined,
          pointPadding: 0.1,
          groupPadding: 0.2,
          colorByPoint: false,
        },
        bar: {
          pointWidth: undefined,
          pointPadding: 0.1,
          groupPadding: 0.2,
          colorByPoint: false,
        },
        line: {
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
        },
        area: {
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          stacking: undefined,
          dataGrouping: {
            forced: true,
            units: [["hour", [1]]],
          },
          marker: {
            enabled: true,
          },
          cursor: "default",
          connectNulls: true,
          animation: false,
          turboThreshold: 50000,
          enableMouseTracking: true,
          dataLabels: {
            enabled: false,
          },
        },
      },
    },
    chartOptions: {
      navigator: {
        enabled: true,
      },
      legend: {
        enabled: true,
        maxHeight: 100,
      },
      rangeSelector: {
        enabled: true,
        allButtonsEnabled: true,
        inputEnabled: false,
        buttons: [
          {
            type: "year",
            text: "hour-selector",
            dataGrouping: {
              forced: true,
              units: [["hour", [1]]],
            },
            events: null,
          },
          {
            type: "year",
            text: "day-selector",
            dataGrouping: {
              forced: true,
              units: [["day", [1]]],
            },
            events: null,
          },
          {
            type: "year",
            text: "week-selector",
            dataGrouping: {
              forced: true,
              units: [["week", [1]]],
            },
            events: null,
          },
          {
            type: "year",
            text: "month-selector",
            dataGrouping: {
              forced: true,
              units: [["month", [1]]],
            },
            events: null,
          },
        ],
        buttonTheme: {
          width: 100,
        },
      },
      chart: {
        height: "50%",
        zoomType: "xy",
        panning: true,
        type: "line",
        navigatorOptions: {
          type: "line",
        },
        panKey: "shift",
      },
      yAxis: [
        {
          opposite: false,
          labels: {
            format: "{value}",
            style: {
              color: "#42a5f5",
            },
          },
          title: {
            style: {
              color: "#42a5f5",
              fontWeight: "bold",
            },
          },
          showFirstLabel: true,
          showLastLabel: true,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                height: 300,
              },
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              navigator: {
                enabled: false,
              },
            },
          },
        ],
      },
    },
  },
};
