<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <h5>
            <b>{{ "cups-multiple-devices" | translate }}</b>
          </h5>

          <!-- CUPS -->
          <div
            *ngIf="cupsSelectorOptions?.length > 1"
            class="cups-multiple-devices-selector"
          >
            <app-material-select
              class="material-header-select"
              [title]="'table-columns-show-of' | translate"
              [bindValue]="'entityId'"
              [bindLabel]="'label'"
              [selected]="cupsSelectorOptions[0]?.entityId"
              [data]="cupsSelectorOptions"
              (selectedOption)="selectedCups = $event.entityId; setColumns()"
            ></app-material-select>
          </div>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataManagementCupsMultipleDevices'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="maxReg"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
