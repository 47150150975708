// @angular
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "./SessionDataService.service";
// Interfaces
import { Agrupation } from "../../interfaces/AgrupationGlobalInterface.type";
import { Client } from "../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../interfaces/EntityGlobalInterface.type";
import { METROLOGY_TYPE } from "../../interfaces/DeviceGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class DeviceRouteSelectorService {
  constructor(
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  // Redirección dependiendo del tipo de dispositivo
  getDeviceRoute(deviceMetrology: number, deviceId: number): void {
    switch (deviceMetrology) {
      case METROLOGY_TYPE.SENSOR:
        this.router.navigate([
          "/dispositivos/detalle/sensor/fidegas/" + deviceId,
        ]);
        break;
      case METROLOGY_TYPE.UNE_CONCENTRATOR:
        this.router.navigate(["/dispositivos/detalle/lw-une/" + deviceId]);
        break;
      case METROLOGY_TYPE.MBUS_CONCENTRATOR:
        this.router.navigate(["/dispositivos/detalle/lw-mbus/" + deviceId]);
        break;
      case METROLOGY_TYPE.ACOUSTIC_SENSOR:
        this.router.navigate(["/dispositivos/detalle/sensor/" + deviceId]);
        break;
      default:
        this.router.navigate(["/dispositivos/detalle/contador/" + deviceId]);
        break;
    }
  }

  // Redirección dependiendo del tipo de dispositivo
  getDeviceRouteUrl(deviceMetrology: number, deviceId: number): string {
    switch (deviceMetrology) {
      case METROLOGY_TYPE.SENSOR:
        return "/dispositivos/detalle/sensor/fidegas/" + deviceId;
      case METROLOGY_TYPE.UNE_CONCENTRATOR:
        return "/dispositivos/detalle/lw-une/" + deviceId;
      case METROLOGY_TYPE.MBUS_CONCENTRATOR:
        return "/dispositivos/detalle/lw-mbus/" + deviceId;
      case METROLOGY_TYPE.ACOUSTIC_SENSOR:
        return "/dispositivos/detalle/sensor/" + deviceId;
      default:
        return "/dispositivos/detalle/contador/" + deviceId;
    }
  }

  // Obtención de la ruta y el nombre de dipositivo dependiendo de su tipo para los tooltips de los mapas
  getMapTooltipDeviceUrl(metrologyType: number): any {
    switch (metrologyType) {
      case METROLOGY_TYPE.SENSOR:
        return {
          url: "#/dispositivos/detalle/sensor/fidegas/",
          name: this.translate.instant("sensor"),
        };
      case METROLOGY_TYPE.UNE_CONCENTRATOR:
        return {
          url: "#/dispositivos/detalle/lw-une/",
          name: this.translate.instant("LW UNE"),
        };
      case METROLOGY_TYPE.MBUS_CONCENTRATOR:
        return {
          url: "#/dispositivos/detalle/lw-mbus/",
          name: this.translate.instant("LW MBUS"),
        };
      case METROLOGY_TYPE.ACOUSTIC_SENSOR:
        return {
          url: "#/dispositivos/detalle/sensor/",
          name: this.translate.instant("sensor"),
        };
      case METROLOGY_TYPE.WATER_VALVE:
        return {
          url: "#/dispositivos/detalle/contador/",
          name: this.translate.instant("valve"),
        };
      default:
        return {
          url: "#/dispositivos/detalle/contador/",
          name: this.translate.instant("meter"),
        };
    }
  }

  // Actualización de la entidad en curso por id
  updateEntity(entityId: number, agrupationId?: number): void {
    let clientList: Client[] = this.SessionDataService.getCurrentClientList();
    let newEntity: Entity;
    clientList.find((client: Client) => {
      let entityFound: Entity = client.entityList?.find((entity: Entity) => {
        if (entity.id == entityId) {
          newEntity = entity;
        }
      });
      if (entityFound) {
        newEntity = entityFound;
      }
    });
    if (newEntity) {
      this.SessionDataService.sendEntity(newEntity);
    }
    if (agrupationId) {
      this.updateAgrupation(agrupationId);
    }
  }

  // Actualización de la entidad en curso por nombre
  updateEntityByName(entityName: string, agrupationName?: string): void {
    let clientList: Client[] = this.SessionDataService.getCurrentClientList();
    let newEntity: Entity;
    clientList.forEach((client: Client) => {
      let entityFound: Entity = client.entityList?.find(
        (entity: Entity) =>
          entity.entity.toUpperCase() == entityName.toUpperCase()
      );
      if (entityFound) {
        newEntity = entityFound;
      }
    });
    if (newEntity) {
      this.SessionDataService.sendEntity(newEntity);
    }
    if (agrupationName) {
      this.updateAgrupationByName(agrupationName);
    }
  }

  // Actualización de la agrupación en curso por id
  updateAgrupation(agrupationId: number): void {
    let entityList: Entity[] = this.SessionDataService.getCurrentEntityList();
    let newAgrupation: Agrupation;
    entityList.find((entity: Entity) => {
      let agrupationFound: Agrupation = entity.agrupations.find(
        (agrupation: Agrupation) => {
          if (agrupation.id == agrupationId) {
            newAgrupation = agrupation;
          }
        }
      );
      if (agrupationFound) {
        newAgrupation = agrupationFound;
      }
    });
    if (newAgrupation) {
      this.SessionDataService.sendAgrupation(newAgrupation);
    }
  }

  // Actualización de la agrupación en curso por nombre
  updateAgrupationByName(agrupationName: string): void {
    let entityList: Entity[] = this.SessionDataService.getCurrentEntityList();
    let newAgrupation: Agrupation;
    entityList.find((entity: Entity) => {
      let agrupationFound: Agrupation = entity.agrupations.find(
        (agrupation: Agrupation) => {
          if (agrupation.name == agrupationName) {
            newAgrupation = agrupation;
          }
        }
      );
      if (agrupationFound) {
        newAgrupation = agrupationFound;
      }
    });
    if (newAgrupation) {
      this.SessionDataService.sendAgrupation(newAgrupation);
    }
  }
}
