<div class="complete-carousel">
  <!-- Botón para añadir imagen, siempre visible -->
  <div class="carousel-header" *ngIf="addImageActive">
    <button mat-button (click)="triggerFileInput()" class="btn-save">
      <i class="fas fa-plus-circle"></i> {{"add-image" | translate}}
    </button>
    <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/x-png,image/jpeg,image/ico" />
  </div>

  <!-- Contenedor del carrusel -->
  <div class="carousel-container" *ngIf="imageList?.length > 0">
    <div class="carousel" [style.transform]="'translateX(' + -currentIndex * 100 + '%)'">
      <div *ngFor="let image of imageList; let i = index" class="carousel-slide">
        <!-- Botón para eliminar la imagen -->
        <div class="carousel-slide-body">
          <div class="delete-button-container" *ngIf="deleteImageActive">
            <button mat-icon-button class="delete-button" (click)="deleteImage(i)">
              <i class="fas fa-trash"></i>
            </button>
          </div>
          <!-- Mostrar la imagen -->
          <img *ngIf="imageList[i]" class="img-responsive img-rounded" [src]="imageList[i]" alt="Meter Image"  />
        </div>
        <div class="extra-buttons">
          <button *ngIf="fullScreenActive" mat-icon-button class="fullscreen-button" (click)="openFullScreen(imageList[i])">
            <i class="fas fa-expand"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Botones de navegación y el índice solo si hay imágenes -->
  <div *ngIf="imageList?.length > 0" class="carousel-buttons">
    <button mat-icon-button class="carousel-button prev" (click)="previousSlide()">
      <i class="fas fa-arrow-left"></i>
    </button>

    <!-- Mostrar el índice solo si hay imágenes -->
    <div class="index-label">
      {{ currentIndex + 1 }} / {{ imageList?.length }}
    </div>

    <button mat-icon-button class="carousel-button next" (click)="nextSlide()">
      <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</div>
<div *ngIf="isFullScreenActive" class="fullscreen-container" (click)="closeFullScreen()">
  <img [src]="fullScreenImage" class="fullscreen-img" alt="Fullscreen Image" />
</div>
