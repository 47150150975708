<div
  #entityTotalsGraph
  class="entity-totals-container"
  id="entity-totals-container"
>
  <div
    *ngIf="
      entityTotals?.project != null &&
      entityTotals?.license != null &&
      entityTotals?.activated != null
    "
    class="entity-totals-graph"
  >
    <i class="fas fa-sitemap"></i>
    <div class="entity-totals-number">
      <div class="entity-totals-graph-project-text">
        <span>{{ "project" | translate }}:</span>
        {{ entityTotals.projectParsed }}
      </div>
      <div class="entity-totals-graph-license-text">
        <span>{{ "license" | translate }}:</span>
        {{ entityTotals.licenseParsed }}
      </div>
    </div>
    <div class="entity-totals-chart">
      <div class="entity-totals-graph-project"></div>
      <div class="entity-totals-graph-actives"></div>
      <div
        class="entity-totals-graph-license"
        [ngClass]="{
          'entity-totals-graph-license-red':
            entityTotals.activated > entityTotals.license
        }"
      ></div>
      <div
        class="entity-totals-graph-project-max"
        [ngClass]="{
          'entity-totals-graph-license-red':
            entityTotals.activated > entityTotals.project
        }"
      ></div>
      <div class="entity-totals-graph-activated-text">
        <div>
          <span>{{ "actives" | translate }}:</span>
          {{ entityTotals.activatedParsed }}
        </div>
        <div>
          <span>{{ "actives" | translate }}:</span>
          {{ entityTotals.activatedParsed }}
        </div>
      </div>
    </div>
  </div>
</div>
