import { ElementRef, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MapSizeService {
  constructor() {}

  // Cálculo de la altura del mapa
  calcMapHeight(
    panel: ElementRef,
    mapContainer: ElementRef,
    padding: number
  ): number {
    if (window.innerWidth > 980) {
      return (
        panel?.nativeElement?.offsetHeight -
        (mapContainer ? mapContainer?.nativeElement?.offsetTop : 0) +
        padding
      );
    } else {
      return 700;
    }
  }
}
