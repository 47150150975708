export class DeviceTypes {
  public gateways: {
    total: number;
    com: number;
    noCom: number;
    noDevice: number;
  };
  public txn: { total: number; txn1: number; txn4: number };
  public meters: {
    total: number;
    com: number;
    noCom: number;
    noAssigned: number;
  };
  public concentrators: {
    total: number;
    com: number;
    noCom: number;
    noAssigned: number;
  };
  public satelites: {
    total: number;
    com: number;
    noCom: number;
  };
  public sensors: {
    total: number;
    com: number;
    noCom: number;
  };
  public mbus: {
    total: number;
    com: number;
    noCom: number;
  };
  public wavenis: {
    total: number;
    com: number;
    noCom: number;
  };
  public une: {
    total: number;
    com: number;
    noCom: number;
  };
  public external: {
    total: number;
    com: number;
    noCom: number;
  };
  public externalWithoutCom: {
    total: number;
    noCom: number;
  };
  public nbiot: {
    total: number;
    com: number;
    noCom: number;
  };
  public valves: {
    total: number;
    com: number;
    noCom: number;
  };

  constructor() {
    this.gateways = {
      total: 0,
      com: 0,
      noCom: 0,
      noDevice: 0,
    };
    this.txn = {
      total: 0,
      txn1: 0,
      txn4: 0,
    };
    this.meters = {
      total: 0,
      com: 0,
      noCom: 0,
      noAssigned: 0,
    };
    this.concentrators = {
      total: 0,
      com: 0,
      noCom: 0,
      noAssigned: 0,
    };
    this.satelites = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.sensors = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.mbus = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.wavenis = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.une = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.external = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.externalWithoutCom = {
      total: 0,
      noCom: 0,
    };
    this.nbiot = {
      total: 0,
      com: 0,
      noCom: 0,
    };
    this.valves = {
      total: 0,
      com: 0,
      noCom: 0,
    };
  }
}

export interface HomeCardData {
  deviceType: string;
  linkDeviceType?: string[] | null;
  icon: string;
  total?: number;
  com?: number;
  comIcon?: string;
  noCom?: number;
  noComIcon?: string;
  noAssigned?: number;
  noAssignedIcon?: string;
  txn1?: number;
  txn1Icon?: string;
  txn4?: number;
  txn4Icon?: string;
  noDevice?: number;
  noDeviceIcon?: string;
  monthConsumption?: string;
  dayConsumption?: string;
  activeAlarms?: number;
  last5DaysAlarms?: number;
}
