<div *ngIf="columns && originalData; else elseBlock" class="table-controller">
  <!-- ANCHOR Control de columnas visibles -->
  <div *ngIf="!onlyTable" class="table-selects">
    <div class="table-controls" [ngStyle]="{ 'margin-bottom.rem': onlyMenu ? 0.5 : 0 }">
      <!-- ANCHOR Menu de la tabla -->
      <div class="table-menu-list-container">
        <!-- Acciones globales de tabla -->
        <button mat-icon-button class="table-menu-icon" [matMenuTriggerFor]="tableMenu">
          <mat-icon><i class="fas fa-ellipsis-v"></i></mat-icon>
        </button>

        <mat-menu #tableMenu="matMenu">
          <!-- Buscar responsive -->
          <button *ngIf="tableResponsive" mat-menu-item (click)="responsiveSearchActive = !responsiveSearchActive"
            title="{{ search | translate }}">
            <mat-icon class="table-mat-icon"><i class="fas fa-search"></i></mat-icon>
            <span>{{ "search" | translate }}</span>
          </button>
          <ng-container *ngIf="tableGlobalActions">
            <!-- Opción sin submenú -->
            <ng-container *ngFor="let action of tableGlobalActions">
              <button *ngIf="
                  !action.submenu &&
                  (!action.profiles || action.profiles.includes(sessionProfile))
                " mat-menu-item [ngClass]="{
                  'option-highlighted-red': action.class == 'btn-red'
                }" [disabled]="
                  (action.selectionRequired && !selectedCount) ||
                  (action.selectionExtraRequired && !extraSelectedCount) ||
                  action.disabled
                " (click)="sendTableGlobalAction(action)" title="{{ action.title | translate }}"
                [help]="action.help | translate">
                <mat-icon class="table-mat-icon"><i class="{{ action.icon }}"></i></mat-icon>
                <span>{{ action.title | translate }}</span>
              </button>

              <!-- Opción con submenú -->
              <button *ngIf="
                  action.submenu &&
                  (!action.profiles || action.profiles.includes(sessionProfile))
                " mat-menu-item [ngClass]="{
                  'option-highlighted-red': action.class == 'btn-red'
                }" [disabled]="
                  (action.selectionRequired && !selectedCount) ||
                  (action.selectionExtraRequired && !extraSelectedCount) ||
                  action.disabled
                " (mouseover)="actionSubmenuOptions = action.submenu" [matMenuTriggerFor]="actionSubmenu">
                <mat-icon class="table-mat-icon"><i class="{{ action.icon }}"></i></mat-icon>
                <span>{{ action.title | translate }}</span>
              </button>
            </ng-container>
            <hr class="table-menu-separator" />
            <!-- Submenú -->
            <mat-menu #actionSubmenu="matMenu">
              <ng-container *ngFor="let actionSubmenuOption of actionSubmenuOptions">
                <button *ngIf="
                    !actionSubmenuOption.profiles ||
                    actionSubmenuOption.profiles.includes(sessionProfile)
                  " mat-menu-item [disabled]="actionSubmenuOption.disabled"
                  (click)="sendTableGlobalAction(actionSubmenuOption)"
                  title="{{ actionSubmenuOption.title | translate }}" [help]="actionSubmenuOption.help | translate">
                  <mat-icon class="table-mat-icon"><i class="{{ actionSubmenuOption.icon }}"></i></mat-icon>
                  <span>{{ actionSubmenuOption.title | translate }}</span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
          <button mat-menu-item [matMenuTriggerFor]="tableDefaultMenu">
            <mat-icon class="table-mat-icon"><i class="fas fa-table"></i></mat-icon>
            <span>{{ "table-options" | translate }}</span>
          </button>
        </mat-menu>
        <mat-menu #tableDefaultMenu="matMenu">
          <!-- Filtros rápidos -->
          <button *ngIf="quickFilters" mat-menu-item [ngClass]="{ 'option-highlighted-green': showQuickFilters }"
            (click)="showQuickFilters = !showQuickFilters" [help]="'help-table-quick-filters' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-filter"></i></mat-icon>
            <span>{{ "filters-extra" | translate }}</span>
          </button>
          <!-- Filtros de CUPS -->
          <button *ngIf="cupsFilters" mat-menu-item (click)="showCupsFilters = !showCupsFilters"
            [ngClass]="{ 'option-highlighted-green': showCupsFilters }" [help]="'help-table-cups-filters' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-filter"></i></mat-icon>
            <span>{{ "filter-cups" | translate }}</span>
          </button>
          <!-- Columnas visibles -->
          <button mat-menu-item (click)="visibleColumns = !visibleColumns"
            [ngClass]="{ 'option-highlighted-green': visibleColumns }"
            [help]="'help-table-column-visibility' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-eye"></i></mat-icon>
            <span>{{ "visible-columns" | translate }}</span>
          </button>
          <!-- Ordenamiento de columnas -->
          <button mat-menu-item (click)="draggableColumns = !draggableColumns"
            [ngClass]="{ 'option-highlighted-green': draggableColumns }" [help]="'help-table-column-order' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-arrows-alt"></i></mat-icon>
            <span>{{ "draggable-columns" | translate }}</span>
          </button>
          <!-- Transponer tabla -->
          <button mat-menu-item *ngIf="!transposeDisabled" (click)="transpose = !transpose"
            [help]="'help-table-tranpos' | translate">
            <mat-icon class="table-mat-icon">
              <i class="fas fa-columns" [ngClass]="{ 'table-transpose-rows': !transpose }"></i></mat-icon>
            <span>{{ "transpos-table" | translate }}</span>
          </button>
          <!-- Exportar tabla -->
          <button *ngIf="export" mat-menu-item class="table-export-button" (click)="exportTable(false)"
            (contextmenu)="exportTable(false, true, $event)" [help]="'help-table-export' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-download"></i></mat-icon>
            <span>
              <div class="table-export-info">
                {{ "export" | translate }}
                <div>
                  <i class="fas fa-mouse mouse-right-click"></i>:
                  {{ "export-to-clipboard" | translate }}
                </div>
              </div>
            </span>
          </button>
          <!-- Exportar selección -->
          <button *ngIf="exportSelection" mat-menu-item class="table-export-button" (click)="exportTable(true)"
            (contextmenu)="exportTable(true, true, $event)" [help]="'help-table-export-sel' | translate">
            <mat-icon class="table-mat-icon"><i class="fa fa-download"></i></mat-icon>
            <span>
              <div class="table-export-info">
                {{ "export-selection" | translate }}
                <div>
                  <i class="fas fa-mouse mouse-right-click"></i>:
                  {{ "export-to-clipboard" | translate }}
                </div>
              </div>
            </span>
          </button>
          <hr class="table-menu-separator" />
          <!-- Guardar configuración -->
          <button mat-menu-item (click)="saveTableConfig()" [help]="'help-table-save-config' | translate">
            <mat-icon class="table-mat-icon"><i class="fas fa-save"></i></mat-icon>
            <span>{{ "save-configuration" | translate }}</span>
          </button>
          <!-- Borrar configuración -->
          <button mat-menu-item class="option-highlighted-red" (click)="deleteTableConfig()"
            [help]="'help-table-delete-config' | translate">
            <mat-icon class="table-mat-icon"><i class="fas fa-eraser"></i></mat-icon>
            <span>{{ "delete-configuration" | translate }}</span>
          </button>
        </mat-menu>
      </div>

      <!-- ANCHOR Filtros -->
      <div *ngIf="!onlyMenu" class="table-filters" [ngClass]="{ hidden: tableResponsive && !responsiveSearchActive }">
        <div class="table-filter-box">
          <!-- Desplegable de filtros -->
          <app-material-select class="table-filters-select" [fullWidth]="true" [help]="'help-table-filters' | translate"
            [title]="'filters' | translate" [bindValue]="'value'" [bindLabel]="'name'" [noSelection]="true"
            [selected]="initialSelection" [resetSelection]="resetFilterSelection" [data]="filterOptions"
            (selectedOption)="filterBySelectedOption($event?.value)"></app-material-select>
          <!-- Caja de búsqueda -->
          <div class="table-filter-input">
            <mat-form-field appearance="standard">
              <mat-label>{{ "search" | translate }}...</mat-label>
              <input matInput #searchBoxInput [ngClass]="{
                  'table-filter-input-esp':
                    numericalComparison || booleanComparison || dateComparison
                }" type="text" (keyup.enter)="updateFilters(true, true)"
                (click)="espComparison ? activateSpecialFilter(true) : null" [(ngModel)]="filterInput"
                autocomplete="off" />
            </mat-form-field>
            <ng-container *ngIf="specialFilter">
              <!-- Activación de búsqueda especial  -->
              <button mat-button *ngIf="
                  numericalComparison || dateComparison || booleanComparison
                " class="table-filter-esp-comparison" [ngClass]="{
                  'table-filter-esp-comparison-active': espComparison
                }" (click)="activateSpecialFilter()" title="{{ 'filter-esp' | translate }}">
                <i class="fas fa-remove-format table-filter-comparison-icon"></i>
              </button>
            </ng-container>
          </div>
        </div>

        <div class="table-filter-buttons">
          <!-- Recarga de tabla -->
          <button mat-button class="mat-button-icon" (click)="updateFilters(true, true)"
            title="{{ 'filter-table' | translate }}">
            <i class="fas fa-filter"></i>
          </button>

          <!-- Filtrado por fichero -->
          <div class="table-filter-file-control">
            <!-- Botón de filtrado por archivo -->
            <button mat-button class="mat-button-icon table-filter-file" (click)="filterFile.click()"
              title="{{ 'filter-table-by-file' | translate }}">
              <i class="fas fa-file"></i>
              <i class="fas fa-filter"></i>
              <input #filterFile type="file" accept=".csv" id="file" (click)="resetFile()"
                (change)="handleFileInputKeys($event.target.files)" />
            </button>
            <!-- Coincidencia de columnas -->
            <div *ngIf="showFileColumns" class="table-filter-file-columns">
              <span>{{ "filter-table-columns" | translate }}</span>
              <app-material-select [title]="'file-columns' | translate" [bindValue]="'value'" [bindLabel]="'name'"
                [data]="fileColumnOptions"
                (selectedOption)="filterFileColumnIndex = $event?.value"></app-material-select>
              <app-material-select [title]="'table-columns' | translate" [bindValue]="'value'" [bindLabel]="'name'"
                [data]="tableColumnOptions"
                (selectedOption)="filterTableColumnIndex = $event?.value"></app-material-select>
              <!-- Filtrado invertido -->
              <mat-checkbox class="mat-option" [(ngModel)]="fileFilterInverted" (change)="
                  fileFilterInverted
                    ? (fileFilterAllowDuplicates = false)
                    : null
                ">
                {{ "filter-inverted" | translate }}
              </mat-checkbox>
              <!-- Permitir duplicados-->
              <mat-checkbox class="mat-option" [(ngModel)]="fileFilterAllowDuplicates" (change)="
                  fileFilterAllowDuplicates
                    ? (fileFilterInverted = false)
                    : null
                ">
                {{ "filter-allow-duplicates" | translate }}
              </mat-checkbox>
              <div>
                <button mat-button class="mat-button-icon" (click)="
                    filterByClipboardActive = false;
                    filterByFileActive = false;
                    csv
                      ? (filterByFileActive = true)
                      : (filterByClipboardActive = true);
                    updateFilters()
                  " [disabled]="
                    filterFileColumnIndex == null ||
                    filterTableColumnIndex == null
                  ">
                  {{ "accept" | translate }}
                </button>
                <button mat-button class="mat-button-icon" (click)="resetFile()">
                  {{ "cancel" | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- Filtrado por asociación -->
          <div *ngIf="filterByAssociationShow" class="table-filter-file-control">
            <div class="table-filter-file-columns table-filter-file-columns-association">
              <app-material-select [title]="'meters-groups' | translate" [bindValue]="'id'" [bindLabel]="'name'"
                [fullWidth]="true" [data]="filterAssociation"
                (selectedOption)="filterSelectedAssociation = $event"></app-material-select>
              <!-- Filtrado invertido -->
              <mat-checkbox class="mat-option" [(ngModel)]="associationFilterInverted">
                {{ "filter-inverted" | translate }}
              </mat-checkbox>
              <div>
                <button mat-button class="mat-button-icon" (click)="filterByAssociation()"
                  [disabled]="!filterSelectedAssociation">
                  {{ "accept" | translate }}
                </button>
                <button mat-button class="mat-button-icon" (click)="resetAssociationFilter()">
                  {{ "cancel" | translate }}
                </button>
              </div>
            </div>
          </div>

          <!-- Reseteo de tabla -->
          <button mat-button class="mat-button-icon" (click)="resetData()"
            title="{{ 'filter-table-reset' | translate }}">
            <i class="fas fa-undo-alt"></i>
          </button>
        </div>
        <!-- Selector de rango de fechas -->
        <div *ngIf="dateRangeSelector" class="table-date-range">
          <div>
            <app-material-date-selector [dateRange]="dateRange" [maxDate]="maxDate" [initialDateNull]="initialDateNull"
              (dateRangeUpdated)="dateRangeSelected = $event"></app-material-date-selector>
          </div>

          <button mat-button class="mat-button-icon" (click)="loadTableData()" title="{{ 'search' | translate }}">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- ANCHOR Lista de columnas -->
    <div *ngIf="visibleColumns" class="table-options-container">
      <div class="table-options-title">
        <i class="fas fa-times" (click)="visibleColumns = false"></i>
        {{ "visible-columns" | translate }}
      </div>
      <div class="table-visible-columns-list">
        <!-- Ver todas -->
        <button mat-button class="mat-button-icon" (click)="showHideAll(true); setTopScrollBar()">
          {{ "show-all" | translate }}
        </button>
        <!-- Ocultar todas -->
        <button mat-button class="mat-button-icon" (click)="showHideAll(false); setTopScrollBar()">
          {{ "show-none" | translate }}
        </button>
        <!-- Columnas seleccionables -->
        <ng-container *ngFor="let column of columns; index as i">
          <button *ngIf="column.visible != null" mat-button class="mat-button-icon"
            [ngClass]="{ inactive: !column.visible }" (click)="
              column.visible = !column.visible;
              column.pinned = false;
              setTopScrollBar()
            ">
            <i *ngIf="column.visible" class="fas fa-eye"></i>
            <i *ngIf="!column.visible" class="fas fa-eye-slash"></i>
            {{ column.title | translate }}
          </button>
        </ng-container>
      </div>
    </div>

    <!-- ANCHOR Ordenamiento de columnas -->
    <div *ngIf="draggableColumns" class="table-options-container"
      [ngStyle]="{ 'margin-top.rem': visibleColumns ? 3 : 0 }">
      <div class="table-options-title">
        <i class="fas fa-times" (click)="draggableColumns = false"></i>
        {{ "draggable-columns" | translate }}
      </div>
      <div class="table-visible-columns-list">
        <!-- Columnas seleccionables -->
        <ng-container *ngFor="let column of columns; index as i">
          <div *ngIf="
              column.title != 'action' &&
              column.title != 'select' &&
              column.title != 'selectBis' &&
              column.visible != null
            " mat-button class="mat-button-icon-text table-draggable-column" [ngClass]="{ inactive: !column.visible }"
            draggable="true" (dragstart)="
              columnDragged = i;
              SessionDataService.sendComponentData({ enableDrop: true })
            " (drop)="columnDrop(i)" (dragend)="
              columnDragged = null;
              SessionDataService.sendComponentData({ enableDrop: false })
            ">
            <i class="fas fa-arrows-alt"></i>
            {{ column.title | translate }}
          </div>
        </ng-container>
      </div>
    </div>

    <!-- ANCHOR Filtros por tipología -->
    <ng-container *ngIf="typologyFilters">
      <div class="table-options-container" *ngFor="let typologyColumn of typologyColumns">
        <ng-container *ngIf="typologyFiltersToShow[typologyColumn.title]">
          <div class="table-options-title">
            <i class="fas fa-times" (click)="typologyFiltersToShow[typologyColumn.title] = false"></i>
            {{ "typology-filters" | translate }}:
            {{ typologyColumn.title | translate }}
          </div>
          <div class="table-typology-filters-box">
            <!-- Filtros -->
            <ng-container *ngFor="let typology of typologyColumn.typology.typologyList">
              <div>
                <input type="checkbox" name="{{ typology.name }}" id="{{ typology.name }}"
                  [(ngModel)]="typology.active" />
                <label for="{{ typology.name }}">{{
                  typology.name | translate
                  }}</label>
              </div>
            </ng-container>
            <div class="table-typology-filters-box-controls">
              <button mat-button (click)="updateFilters()">
                <i class="fas fa-filter"></i>
              </button>
              <button mat-button (click)="resetTypologyFilters(typologyColumn)">
                <i class="fas fa-undo-alt"></i>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <!-- ANCHOR Filtros rápidos -->
    <div *ngIf="quickFilters && showQuickFilters" class="table-options-container">
      <div class="table-options-title">
        <i class="fas fa-times" (click)="showQuickFilters = false"></i>
        {{ "filters-extra" | translate }}
      </div>
      <div class="table-quick-filters-box">
        <!-- Filtros -->
        <ng-container *ngFor="let filterGroup of quickFilters; index as i">
          <button mat-button *ngFor="let filter of filterGroup; index as j" class="mat-button-icon"
            [ngClass]="{ inactive: !filter.active }" (click)="filter.active = !filter.active; updateFilters()">
            <div *ngIf="!filter.active" class="table-quick-filters-inactive-icon">
              <i class="fas fa-slash"></i>
              <i class="fas fa-filter"></i>
            </div>
            <div *ngIf="filter.active" class="table-quick-filters-inactive-icon">
              <i class="fas fa-filter"></i>
            </div>
            {{ filter.name | translate }}
          </button>
          <!-- Modo de concatenación de filtros -->
          <div *ngIf="quickFiltersExclusion?.length > i">
            <button mat-button class="table-quick-filter-group" title="{{
                quickFiltersExclusion[i]
                  ? ('filter-excluding' | translate)
                  : ('filter-complementary' | translate)
              }}" (click)="
                quickFiltersExclusion[i] = !quickFiltersExclusion[i];
                updateFilters()
              ">
              <i *ngIf="!quickFiltersExclusion[i]" class="table-quick-filters-exclusion">{{ "quick-filter-or" |
                translate }}</i>
              <i *ngIf="quickFiltersExclusion[i]" class="table-quick-filters-exclusion">{{ "quick-filter-and" |
                translate }}</i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- ANCHOR Filtros de CUPS -->
    <div *ngIf="cupsFilters && showCupsFilters" class="table-cups-filters">
      <app-cups-filters [filters]="cupsFilters" (resetFlag)="resetData()" (filterFlag)="filterData($event, false)">
      </app-cups-filters>
    </div>

    <!-- ANCHOR Filtros activos de fichero -->
    <div *ngIf="
        filterByFileActive ||
        filterByClipboardActive ||
        filterByAssociationActive
      " class="table-search-filters">
      <!-- Filtro por fichero -->
      <div *ngIf="filterByFileActive">
        <span [ngClass]="{ 'table-search-filter-inverse': fileFilterInverted }">{{ "filtered-by-file" | translate
          }}</span>
        <i class="fas fa-strikethrough" (click)="fileFilterInverted = !fileFilterInverted; updateFilters()"
          [title]="'filter-inverted' | translate"></i>
        <i class="fas fa-times" (click)="filterByFileActive = false; updateFilters()"
          [title]="'filter-reset' | translate"></i>
      </div>
      <!-- Filtro por portapapeles -->
      <div *ngIf="filterByClipboardActive">
        <span [ngClass]="{ 'table-search-filter-inverse': fileFilterInverted }">{{ "filtered-by-clipboard" | translate
          }}</span>
        <i class="fas fa-strikethrough" (click)="fileFilterInverted = !fileFilterInverted; updateFilters()"
          [title]="'filter-inverted' | translate"></i>
        <i class="fas fa-times" (click)="filterByClipboardActive = false; updateFilters()"
          [title]="'filter-reset' | translate"></i>
      </div>
      <!-- Filtro por asociación -->
      <div *ngIf="filterByAssociationActive">
        <span [ngClass]="{
            'table-search-filter-inverse': associationFilterInverted
          }">{{ "filtered-by-association" | translate }}:
          {{ filterSelectedAssociation.name }}</span>
        <i class="fas fa-strikethrough" (click)="
            associationFilterInverted = !associationFilterInverted;
            updateFilters()
          " [title]="'filter-inverted' | translate"></i>
        <i class="fas fa-times" (click)="filterByAssociationActive = false; updateFilters()"
          [title]="'filter-reset' | translate"></i>
      </div>
    </div>

    <!-- ANCHOR Filtros activos -->
    <div *ngIf="searchFiltersActive?.length > 0" class="table-search-filters">
      <!-- Exclusión de filtros de búsqueda -->
      <button *ngIf="searchFiltersActive?.length > 1" mat-button
        class="mat-button-icon-text table-search-filters-exclusion" [title]="
          (searchFilterExclusion ? 'filter-search-and' : 'filter-search-or')
            | translate
        " (click)="
          searchFilterExclusion = !searchFilterExclusion; updateFilters()
        ">
        <i *ngIf="searchFilterExclusion" class="table-quick-filters-exclusion">{{ "quick-filter-and" | translate }}</i>
        <i *ngIf="!searchFilterExclusion" class="table-quick-filters-exclusion">{{ "quick-filter-or" | translate }}</i>
      </button>
      <!-- Filtros de búsqueda activos -->
      <div *ngFor="let filter of searchFiltersActive; index as i">
        <span>{{ filter.label }}</span><span *ngIf="filter.label && !filter.espComparison"
          class="table-search-filter-sign">=</span><span
          [ngClass]="{ 'table-search-filter-inverse': filter.inverse }">{{
          filter.espComparison ? filter.inputDataString : filter.inputData
          }}</span>
        <i class="fas fa-strikethrough" (click)="invertActiveSearch(i)" [title]="'filter-inverted' | translate"></i>
        <i class="fas fa-times" (click)="removeActiveSearch(i)" [title]="'filter-reset' | translate"></i>
      </div>
    </div>

    <!-- ANCHOR Leyenda de tabla -->
    <div *ngIf="!onlyMenu" class="table-legend-container">
      <div>
        <div *ngIf="highlightRow && (highlightRowActive || legendAlwaysActive)" class="table-highlight">
          <ng-container *ngFor="let highlight of highlightRow">
            <div *ngIf="highlight.title">
              <i class="fas fa-circle" [ngStyle]="{ color: highlight.color }"></i>
              {{ highlight.title | translate }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- ANCHOR Componente tabla -->
  <app-table *ngIf="columns; else elseBlock" [columns]="columns" [rowNumbers]="rowNumbers" [data]="_data"
    [originalData]="originalData" (sortTable)="sortTable($event)" [maxReg]="maxReg" (tableAction)="getAction($event)"
    (selectAllRowsFlag)="
      selectAllRows($event.selectAll, $event.bis, $event.excluding)
    " (selectedDataUpdateFlag)="selectedDataUpdate()" (extraSelectedDataUpdateFlag)="extraSelectedDataUpdate($event)"
    (getExtraTableDataFlag)="getExtraTableData($event)" (resetExtraSelectedDataFlag)="resetExtraTableRowSelection()"
    (extraTableAction)="getExtraTableAction($event)" [extraTableNoCollapse]="extraTableNoCollapse"
    (showModalFlag)="showTableModal($event)" [columnOrderDisabled]="columnOrderDisabled"
    [paginationDisabled]="paginationDisabled" [waitForData]="waitForData" [selectedCount]="selectedCount"
    [selectedBisCount]="selectedBisCount" [lockSelected]="lockSelected" [lockSelectedBis]="lockSelectedBis"
    (lockSelectedFlag)="lockSelected = !lockSelected" (lockSelectedBisFlag)="lockSelectedBis = !lockSelectedBis"
    (groupByFlag)="updateGroupBy($event)" [parentElement]="parentElement ? true : false" [transposeTable]="transpose"
    [noPinnedColumns]="noPinnedColumns" [topPaginationDisabled]="topPaginationDisabled" [language]="sessionLanguage"
    [avoidFixColumns]="avoidFixColumns" (expandGraphFlag)="expandGraph($event)"
    [typologyFiltersActive]="typologyFiltersActive" (showTypologyFiltersFlag)="showTypologyFilters($event)">
  </app-table>
</div>
