// @angular
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  DeviceActivationData,
  DeviceBatteryData,
  DeviceCheckData,
  DeviceCoords,
  DeviceDeallocateData,
  DeviceMoveData,
  DeviceNetworkData,
  DeviceRemoveData,
  DeviceReviewData,
  DeviceSfAdrUpdateData,
  EditedDeviceData,
  MbusManufacturersDeletionData,
  MbusManufacturersSaveData,
  NonceData,
  SubstitutionCorrectionData,
  SubstitutionUpdateData,
} from "../../screens/dashboard/devices/DeviceInterface.type";
import { ClaveMaestra } from "../../interfaces/CupsGlobalInterface.type";
import { DeviceReplaceData } from "../../screens/dashboard/devices/devices-common-components/device-replace-dialog/device-replace-dialog-interface";

@Injectable({
  providedIn: "root",
})
export class MeterControllerService {


  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Detalle
  /***************************************************************************/

  // Pantalla principal del dispositivo
  main(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/main/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  importMeterImages(formData: FormData, entityId:number) {
    return this.HttpRequestService.post(
      "/contador/import-images/"+ entityId,formData
    );
  }
  ignoreMeter(nroSerie: string): Observable<object> {
    const data = [nroSerie.toString()]; // Convierte el nroSerie a string y lo coloca en un array
    return this.HttpRequestService.post(
      "/contador/descartar",
      data
    );
  }
  getIgnoredMeterList(agrupacionId?: number, entidadId?: number): Observable<object> {
    // Si entidadId está presente y no es null, usarlo. Si no, usar agrupacionId.
    const data = entidadId != null
      ? { entidad: entidadId.toString() } // Si entidadId está presente y no es null, usarlo
      : agrupacionId != null
        ? { agrupacion: agrupacionId.toString() } // Si entidadId no está presente, usar agrupacionId si está disponible
        : {}; // Si ninguno está presente, enviar un objeto vacío o manejarlo como corresponda

    // Realizar la solicitud POST con los datos correspondientes
    return this.HttpRequestService.post(
      "/contador/descartar/list",
      data
    );
  }
  // Lecturas de dispositivo
  getGraph(
    deviceId: number,
    type: string,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/readings/contador/" +
        deviceId +
        "/type/" +
        type +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }
  getManualCommunication(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/extws/manual-communication/"+deviceId
    )
  }

    // Método para realizar el POST a la API
    deleteReadingsPeriod(meter: number, agrupation: number, fromTimestamp: number, toTimestamp: number): Observable<object> {
      // Cuerpo del request
      const body = {
        meterList: [meter], // meter ahora es un array con el valor de meter
        agrupation: agrupation,
        fromTimestamp: fromTimestamp,
        toTimestamp: toTimestamp
      };

      // Realizando la solicitud POST
      return this.HttpRequestService.post('/readings/eliminar/periodo', body);
    }

  // Obtener historial de batería por ID de contador
getBatteryHistoryById(
  idContador: number,
  fechaInicio?: number,
  fechaFin?: number
): Observable<object> {

  // Timestamps predefinidos, 1/1/1999 y hoy
  let fechaInicioDef: number = 915148800;
  let fechaFinDef: number = Date.now();

  if (fechaInicio) {
    fechaInicioDef = fechaInicio;
  }
  if (fechaFin) {
    fechaFinDef = fechaFin;
  }

  return this.HttpRequestService.get(
    "/contador/bat/history/entity/" +
      idContador +
      "/from/" +
      fechaInicioDef +
      "/to/" +
      fechaFinDef
  );
}


  // Corrección de valores en gráfica
  updateReadings(
    deviceId: number,
    from: number,
    to: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/readings/mod/neg/consumptions/" +
        deviceId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Lecturas de dispositivo
  getSateliteGraph(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/readings/satelite/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Histórico de estado de válvula
  getValveStateGraph(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/valve-status/list/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Sustitución del Gateway principal de un listado de dispositivos con lectura
  asignateWithCommand(data: number[], gatewayId: number): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/chgateway/" + gatewayId,
      data
    );
  }

  // Sustitución del Gateway principal de un listado de dispositivos con lectura
  asignateWithoutCommand(
    data: number[],
    gatewayId: number
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/chgateway/" + gatewayId + "/withoutread/1",
      data
    );
  }

  // Sustitución del Gateway principal de un listado de dispositivos sin lectura
  asignateWithoutCommandDisabledGateway(
    data: number[],
    gatewayId: number,
    queue?: boolean
  ): Observable<object> {
    // El antiguo gateway no funciona o está apagado y no hay que comunicar con el
    return this.HttpRequestService.post(
      "/contador/chgateway/" +
        gatewayId +
        "/withoutread/0" +
        (queue ? "/queue" : ""),
      data
    );
  }

  // Desasignar dispositivo de un listado de Gateways
  desasignate(data: DeviceDeallocateData): Observable<object> {
    return this.HttpRequestService.post("/contador/deallocate/", data);
  }

  // Cambiar dispositivo de agrupación
  change(deviceId: number, agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/ch-group/" + deviceId + "/to/" + agrupationId
    );
  }

  // Cambio de entidad de contador
  changeDeviceEntity(deviceId: number, entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/ch-entity/" + deviceId + "/to/" + entityId
    );
  }

  // Actualizar los datos extra de un CUPS
  editExtra(data: ClaveMaestra): Observable<object> {
    return this.HttpRequestService.post("/cm/update/", data);
  }

  // Actualizar número de serie, precinto y comentarios del dispositivo
  editDevice(data: EditedDeviceData): Observable<object> {
    return this.HttpRequestService.post("/contador/update-nro-serie/", data);
  }

  // Subir la imagen del dispositivo
  updateImage(nroSerie: string, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/upload-image/" + nroSerie.replace("/", "$2F"),
      data
    );
  }

  // Guardar un dispositivo en pendientes de revisar
  moveToCheck(data: DeviceCheckData): Observable<object> {
    return this.HttpRequestService.post("/contador/pr/save", data);
  }

  // Leer nonces
  nonceTable(gatewayId: number, deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/meter-conf/read-nonces/" + gatewayId + "/meter/" + deviceId
    );
  }

  // Propagar nonces
  noncePropagate(deviceId: number, data: NonceData): Observable<object> {
    return this.HttpRequestService.post(
      "/meter-conf/propagar-nonces/" + deviceId,
      data
    );
  }

  // Actualizar nonces
  nonceSave(deviceId: number, data: NonceData): Observable<object> {
    return this.HttpRequestService.post(
      "/meter-conf/update-nonces/" + deviceId,
      data
    );
  }

  // Cierre de válvula
  closeValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/downlink/meter/close/" + deviceId);
  }

  // Abrir la válvula de un contador de gas
  openValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/downlink/meter/open/" + deviceId);
  }

  // Abrir la válvula al 10%
  openValve10(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/downlink/meter/open-10/" + deviceId);
  }

  // Abrir la válvula al 50%
  openValve50(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/downlink/meter/open-50/" + deviceId);
  }

  // Limpiar alarmas de contador
  clearAlarms(deviceId: number, queue?: boolean): Observable<object> {
    return this.HttpRequestService.get(
      "/downlink/meter/clear-alarms/" + deviceId + (queue ? "/queue" : "")
    );
  }

  // Lectura del estado de la válvula de un contador de gas
  readValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/downlink/meter/read/" + deviceId);
  }

  // Cierre de válvula
  closeSateliteValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/modifySateliteValve/" + deviceId + "/0"
    );
  }

  // Abrir la válvula de un contador de gas
  openSateliteValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/modifySateliteValve/" + deviceId + "/1"
    );
  }

  // Lectura del estado de la válvula de un contador de gas
  readSateliteValve(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/sateliteStatus/" + deviceId);
  }

  // Modificación de valores de satélite
  modifySateliteValue(
    deviceId: number,
    newValue: number,
    overflow: number,
    pulseWeight: number
  ): Observable<object> {
    return this.HttpRequestService.post("/modifySatelite/", {
      contador: deviceId,
      newValue: newValue,
      overflow: overflow,
      pulseWeight: pulseWeight,
    });
  }

  // Comprobar el estado de la válvula del contador asociado a la alerta
  readValveModal(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/alert/valve-state/" + deviceId);
  }

  // Comprobar el estado de la válvula del contador asociado a la alerta
  updateValveData(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/satelite/estado/" + deviceId);
  }

  // Asignar dispositivos a un Gateway
  saveDropdown(gatewayId: number, data: string[]): Observable<object> {
    return this.HttpRequestService.post("/contador/assign/" + gatewayId, data);
  }

  // Actualizar geolocalización de un dispositivo
  newCoords(data: DeviceCoords): Observable<object> {
    return this.HttpRequestService.post("/contador/update-coords", data);
  }

  // Cambio de modo (A, A+, B) en contador sagencom
  changeMode(deviceId: number, mode: string): Observable<object> {
    return this.HttpRequestService.get(
      "/downlink/meter/mode/" + deviceId + "/" + mode
    );
  }

  // Log de tramas
  getMeterFrames(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/full/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Borrado de tramas
  deleteMeterFrames(deviceId: number, frames: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/contador-frames/delete/" + deviceId,
      frames
    );
  }

  // Relanzar tramas en un intervalo de fechas
  relaunchMeterFrames(
    deviceId: number,
    alarm: boolean,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/relaunch/meter/" +
        deviceId +
        "/alarms/" +
        alarm +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Relanzar tramas con mic error en un intervalo de fechas
  relaunchMeterMicFrames(
    deviceId: number,
    alarm: boolean,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/relaunch-micerror/meter/" +
        deviceId +
        "/alarms/" +
        alarm +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Relanzar tramas por id
  relaunchMeterIdFrames(
    deviceId: number,
    alarm: boolean,
    data: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador-frames/relaunch/meter/" + deviceId + "/alarms/" + alarm,
      data
    );
  }

  // Relanzar tramas con mic error por id
  relaunchMeterIdMicFrames(
    deviceId: number,
    alarm: boolean,
    data: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador-frames/relaunch-micerror/meter/" +
        deviceId +
        "/alarms/" +
        alarm,
      data
    );
  }

  // Log de comunicaciones
  getMeterFramesPartial(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/partial/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Envío de trama a contador
  sendFrameMeter(deviceId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/downlink/meter/send-payload/" + deviceId,
      data
    );
  }

  // Obtener eventos de un dispositivo
  getMeterEvents(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-events/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Eventos de usuario del dispositivo
  getMeterUserEvents(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/user-events/by-device/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Sectores del dispositivo
  getMeterSector(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/by-meter/" + deviceId);
  }

  // Buscar nonces del dispositivo
  findNonces(data: { meterId: number }): Observable<object> {
    return this.HttpRequestService.post("/meter-conf/find-nonces", data);
  }

  // Peticion de bateria y nro de contadores de un concentrador UNE
  getUneGraphs(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/UNE/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  getEkBatteryStateGraph(
    meterId: number,
    from: number | string,
    to: number | string): Observable<any[]> {
      // Función para generar datos de prueba
      return this.HttpRequestService.get(
        "/extws/ek280/battery/" + meterId + "/" + from + "/" + to
      );
  }
  // Actualización de los datos de punto final de un dispositivo
  updateSfAdr(data: DeviceSfAdrUpdateData, queue?: boolean) {
    return this.HttpRequestService.post(
      "/contador/update/puntoFinal" + (queue ? "/queue" : ""),
      data
    );
  }

  // Modificación del SF del dispositivo
  updateSf(deviceId: number, sf: number) {
    return this.HttpRequestService.get("/modifySf/" + deviceId + "/" + sf);
  }

  // Modificación del SF del dispositivo
  updateSfList(deviceId: number, sf: number, queue?: boolean) {
    return this.HttpRequestService.get(
      "/modifySf/" + deviceId + "/" + sf + (queue ? "/queue" : "")
    );
  }

  // Obtención de la lista de módulos RF de la entidad
  getRfModuleList(entityId: number) {
    return this.HttpRequestService.get(
      "/contador/listado/rf/entidad/" + entityId
    );
  }

  // Modificación del módulo RF del dispositivo
  replaceRfModule(deviceId: number, rfModuleId: number) {
    return this.HttpRequestService.get(
      "/contador/substitution/rf/" + deviceId + "/" + rfModuleId
    );
  }

  // Sustitución del dispositivo
  replaceOnlyMeter(data: DeviceReplaceData) {
    return this.HttpRequestService.post(
      "/contador/substitution/only-meter",
      data
    );
  }

  // Sustitución total del dispositivo
  replaceFullDevice(data: DeviceReplaceData) {
    return this.HttpRequestService.post("/contador/substitution", data);
  }

  // Comprobación de mover lecturas/consumos
  checkReadingsMove(data: DeviceMoveData) {
    return this.HttpRequestService.post("/readings/move/check", data);
  }

  // Mover lecturas
  moveReadings(data: DeviceMoveData) {
    return this.HttpRequestService.post("/readings/move/readings", data);
  }

  // Mover consumos
  moveConsumptions(data: DeviceMoveData) {
    return this.HttpRequestService.post("/readings/move/consumptions", data);
  }

  // Obtención de contadores inactivos para LW Mbus
  getLwMbusMaskRetrys(
    concentratorId: number,
    from: number | string,
    to: number | string
  ) {
    return this.HttpRequestService.get(
      "/lorawanMbus/concentrador/reintentos/" +
        concentratorId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Obtención de contadores inactivos para LW Mbus
  getLwMbusInactiveDevices(concentratorId: number) {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/inactivos/" + concentratorId
    );
  }

  // Obtención de contadores desconocidos para LW Mbus
  getLwMbusUnkownDevices(concentratorId: number) {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/desconocidos/" + concentratorId
    );
  }

  // Habilitar que un concentrador LW Mbus haga o no reintentos de la máscara
  enableLwMbusRetrys(concentratorId: number, enable: boolean) {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/concentrador/" +
        concentratorId +
        "/habilitarReintentos/" +
        enable
    );
  }

  // Obtención de contadores desconocidos para LW Mbus
  getEkFullReadings(
    meterId: number,
    from: number | string,
    to: number | string
  ) {
    return this.HttpRequestService.get(
      "/extws/ek280/lecturas-completas/" + meterId + "/" + from + "/" + to
    );
  }

  /***************************************************************************/
  // ANCHOR Listados
  /***************************************************************************/

  // Actualización de los datos de radio de un dispositivo
  editMeterNotAssign(data: DeviceNetworkData): Observable<object> {
    return this.HttpRequestService.post("/contador/update", data);
  }

  // Desactivar dispositivo
  resetMeter(deviceId: number, queue?: boolean): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/disable/" + deviceId + (queue ? "/queue" : "")
    );
  }

  // Divorciar dispositivo
  resetInactiveMeter(deviceId: number, queue?: boolean): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/divorce/" + deviceId + (queue ? "/queue" : "")
    );
  }

  // Modal con los datos de radio de un dispositivo no asignado
  showMeter(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/notasigned/info/" + deviceId);
  }

  // Importación del fichero de dispositivos
  import(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post("/devices/import/" + entityId, data);
  }

  // Importación del fichero kem2
  importKem2(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/file/kem2", data, {
      responseType: "blob" as "json",
    });
  }

  // Sustitución de dipositivo mediante fichero
  replace(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/cm/update-meter/file", data);
  }

  // Importación de coordenadas por fichero
  coordsImport(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/devices/import/coordinates", data);
  }

  // Exportación del fichero de todos los dispositivos de una agrupación
  download(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/devices/export/" + agrupationId, {
      responseType: "blob",
      headers: new HttpHeaders({
        endings: "native",
      }),
    });
  }

  // Listado de los dispositivos activados en una agrupación
  table(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/list-all/" + agrupationId);
  }

  // Listado de dispositivos desactivados de una agrupación
  tableDeactivated(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/list-desactivated/" + agrupationId
    );
  }

  // Activación de contadores desde la App LBep
  activate(data: DeviceActivationData[], queue?: boolean): Observable<object> {
    return this.HttpRequestService.post(
      "/app/activate/" + (queue ? "/queue" : ""),
      data
    );
  }

  // Finalización de error de activación
  processActivationError(data: number[], deviceId: number): Observable<object> {
    return this.HttpRequestService.post(
      "/app/activate/process-list/" + deviceId,
      data
    );
  }

  // Eliminar dispositivo
  removeMeter(
    deviceId: number,
    agrupationId: number,
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/delete/" +
        deviceId +
        "/group/" +
        agrupationId +
        (queue ? "/queue" : "")
    );
  }

  // Eliminar varios dispositivos
  removeMultipleMeter(data: DeviceRemoveData): Observable<object> {
    return this.HttpRequestService.post("/contador/delete", data);
  }

  // Listado de dispositivos pendientes de revisar
  reviewMeterList(data: DeviceReviewData): Observable<object> {
    return this.HttpRequestService.post("/contador/pr/list", data);
  }

  // Eliminar un registro de pendientes de revisar
  deleteFromReviewMeterList(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/pr/delete/" + deviceId);
  }

  // Eliminar un registro de pendientes de revisar
  deleteMultipleFromReviewMeterList(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/contador/pr/delete", data);
  }

  // Listado de dispositivos no activos de una agrupación
  getNoAssignedMeterList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/notasigned/list/" + agrupationId
    );
  }

  // Listado interno de los dispositivos activados en una agrupación
  getInternalList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/list-internal/" + agrupationId
    );
  }

  // Listado de los dispositivos sin comunicación
  getNoCommunicateList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/no-comunica/list-internal/" + agrupationId
    );
  }

  // Listar números de serie de Plum
  getPlumList(): Observable<object> {
    return this.HttpRequestService.get("/extws/list/available/plum");
  }

  // Listado interno de los dispsositivos activados en una agrupación
  getInternalGprsList(): Observable<object> {
    return this.HttpRequestService.get("/extws/device/list");
  }

  // Listado errores de activación en una agrupación
  getActivationErrorList(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/app/activate/notify-list/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Listado de contadores con batería baja
  getLowBattery(data: DeviceBatteryData): Observable<object> {
    return this.HttpRequestService.post("/contador/bat/low", data);
  }

  // Activación/Desactivación de PLUM/EK280
  updateStatus(nroSerie: string, action: boolean): Observable<object> {
    return this.HttpRequestService.post(
      "/extws/update/devices/" + nroSerie + "/activo/" + action,
      null
    );
  }

  // Listado de sustituciones por agrupación
  getSubstitutionListByAgrupation(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/agrupation/" + agrupationId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de sustituciones
  getSubstitutionListByEntity(
    entityId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/entity/" + entityId + "/from/" + from + "/to/" + to
    );
  }

  // Actualización de los datos de radio de un dispositivo
  updateSubstitutionData(
    agrupationId: number,
    deviceId: number,
    data: SubstitutionUpdateData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/substitution/modify/" + agrupationId + "/" + deviceId,
      data
    );
  }

  // Listado de sustituciones pendientes
  getPendingSubstitutions(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/w-c/list-all/" + agrupationId
    );
  }

  // Imágenes de sustituciones
  getSubstitutionImage(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/substitution/get-image", data);
  }

  // Audio de sustiticiones
  getSubstitutionAudio(substitution: number): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/" + substitution + "/get-audio"
    );
  }

  // Corrección de sustitición
  fixSubstitution(
    substitutionId: number,
    agrupationId: number,
    data: SubstitutionCorrectionData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/repair/meter-wc/substitution/" +
        substitutionId +
        "/" +
        agrupationId,
      data
    );
  }

  // Actualización de imagen de sustitución
  updateSubstitutionImage(
    substitutionId: number,
    nroSerie: string,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/substitution/upload-image/" +
        nroSerie.replace("/", "$2F") +
        "/" +
        substitutionId,
      data
    );
  }

  // Descarga fichero de sustituciones
  downloadSubstitutionsFile(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/report/" + agrupationId + "/from/" + from + "/to/" + to,
      {
        responseType: "blob" as "json",
      }
    );
  }

  // Descarga zip de imágenes de sustituciones
  downloadSubstitutionsImagesZip(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/download-images/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Descarga zip de audios de sustituciones
  downloadSubstitutionsAudiosZip(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/substitution/download-audios/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Obtención de los fabricantes Mbus de la entidad
  getMbusManufacturers(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/fabricantesMbus/" + entityId
    );
  }

  // Obtención de los dispositivos inactivos de LW Mbus de la entidad
  getEntityLwMbusInactive(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/inactivos/entidad/" + entityId
    );
  }

  // Borrado de fabricantes Mbus
  deleteMbusManufacturers(
    data: MbusManufacturersDeletionData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/delete/fabricantesMbus",
      data
    );
  }

  // Obtención de los candidatos Mbus de la entidad
  getMbusCandidates(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/candidatosMbus/" + entityId
    );
  }

  // Borrado de candidatos Mbus
  deleteMbusCandidates(
    data: MbusManufacturersDeletionData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/delete/candidatosMbus",
      data
    );
  }

  // Grabado de candidatos Mbus por fichero
  insertMbusManufacturersByFile(entityId: number, data): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/insert/fabricantesMBus/fichero/" + entityId,
      data
    );
  }

  // Grabado de candidatos Mbus
  insertMbusCandidates(data: MbusManufacturersSaveData): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/insert/candidatosMbus",
      data
    );
  }

  // Grabado de fabricantes Mbus por fichero
  insertMbusCandidatesByFile(entityId: number, data): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/insert/candidatosMbus/fichero/" + entityId,
      data
    );
  }

  // Grabado de fabricantes Mbus
  insertMbusManufacturers(data: MbusManufacturersSaveData): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/insert/fabricantesMbus",
      data
    );
  }

  // Obtención de contadores inactivos para LW Mbus
  getEntityLwMbusInactiveDevices(entityId: number) {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/inactivos/entidad/" + entityId
    );
  }

  // Obtención de contadores desconocidos para LW Mbus
  getEntityLwMbusUnkownDevices(entityId: number) {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/desconocidos/entidad/" + entityId
    );
  }

  // Listado de contadores recibidos para LW MBUS
  getLwMbusReceivedDevices(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/main/concentrador/" + deviceId
    );
  }

  // Listado de concentradores que tienen como fijo el contador
  getMeterMainLwMbus(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/fijos/listado/contador/" + deviceId
    );
  }

  // Listado: Servicio para listar los contadores dispositivos
  // (que están con dirMbus y clave especifica, fuera de lo que
  // hay en fabricantes o candidatos) asignados a un concentrador
  getLwMbusMeters(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/dispositivos/listado/concentrador/" + deviceId
    );
  }

  //  Listado: Concentradores que tienen idContador como contador dispositivo
  getMeterLwMbus(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/dispositivos/listado/contador/" + deviceId
    );
  }

  // Listado de contadores que están como fijos en el concentrador
  getLwMbusMainMeters(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/fijos/listado/concentrador/" + deviceId
    );
  }

  //  Insercion: Servicio para concretar como dispositivo un listado de contadores a un concentrador
  setLwMbusMeters(data: unknown): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/dispositivos/insertar",
      data
    );
  }

  // BORRAR: Se borran los ids indicados como dispositivos del concentrador
  deleteLwMbusMeters(data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/api/dispositivos/borrar",
      data
    );
  }

  // Borrar fijos de un concentrador LW MBUS
  deleteLwMbusMainMeters(data: {
    element: number;
    listElements: number[];
  }): Observable<object> {
    return this.HttpRequestService.post("/lorawanMbus/api/fijos/borrar", data);
  }

  // Insertar contadores como fijo en un concentrador LW MBUS
  addLwMbusMainMeters(data: {
    element: number;
    listElements: number[];
  }): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/api/fijos/insertar",
      data
    );
  }

  // Insertar contadores como fijo en un concentrador LW MBUS
  getLwMbusCommands(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/listado/comandos/" + deviceId
    );
  }

  // Insertar contadores como fijo en un concentrador LW MBUS
  deleteLwMbusCommand(data: {
    element: number;
    listElements: number[];
  }): Observable<object> {
    return this.HttpRequestService.post(
      "/lorawanMbus/listado/comandos/borrar",
      data
    );
  }

  // Reintenta el comando seleccionado al concentrador asociado LW MBUS
  retryLwMbusCommand(deviceId: number, commandId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/reintentar/" + deviceId + "/cola/" + commandId
    );
  }

  // Autoconfiguración LW MBUS
  autoconfigureLwMbus(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/api/concentrador/" + deviceId + "/autoconfigurar"
    );
  }

  // Guardar orden de trabajo
  saveOrder(data: {
    meterList: number[];
    type: number;
    comments: string;
  }): Observable<object> {
    return this.HttpRequestService.post("/orden/save", data);
  }

  // Borrar orden
  deleteOrder(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/orden/delete", data);
  }

  // Cancelar orden
  cancelOrder(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/orden/cancel", data);
  }

  // Listado de órdenes por dispositivo
  getDevicesOrders(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/orden/list/device/" + deviceId);
  }

  // Listado de órdenes por agrupación
  getAgrupationOrders(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/orden/list/agrupation/" + agrupationId
    );
  }

  // Listado de órdenes por entidad
  getEntityOrders(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/orden/list/entity/" + entityId);
  }

  // Listado de EK280/OWASYS
  getEkList(): Observable<object> {
    return this.HttpRequestService.get("/telemedida/list-all/");
  }

  getEkErrorList(meterId:number, fromTS:string, toTS:string): Observable<object>{
    return this.HttpRequestService.get("/extws/ek280/error/list/"+meterId+"/"+fromTS+"/"+toTS);
  }
  // Listado de EK280/OWASYS pendientes
  getPendingEkList(): Observable<object> {
    return this.HttpRequestService.get("/telemedida/pending/list-all");
  }

  /***************************************************************************/
  // ANCHOR Importación
  /***************************************************************************/

  // Importación de lecturas
  importReadings(force: boolean, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/extws/lecturas/import/" + force,
      data
    );
  }

  // Listado de APIs
  getApiUrls(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/config/external-api/list/" + entityId);
  }
}
