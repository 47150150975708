// @angular
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Leaflet
import "@runette/leaflet-fullscreen/dist/leaflet.fullscreen.min.js";
import "leaflet-edgebuffer/src/leaflet.edgebuffer.js";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../material-module/material-dialog/material-dialog.service";
import { TableFilterService } from "../../table-module/TableFilterService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import {
  DialogAction,
  TableSearchFilter,
} from "../../table-module/TableInterface.type";
import { MapFilterOption } from "../MapInterface.type";
// Compontentes
import { TableSpecialFilterDialogComponent } from "../../table-module/table-special-filter-dialog/table-special-filter-dialog.component";
import { LOCAL_TIMEZONE } from "../../../global/LOCAL_TIMEZONE";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";

@Component({
  selector: "app-map-filters-main",
  templateUrl: "./map-filters-main.component.html",
  styleUrls: ["./map-filters-main.component.scss"],
})
export class MapFiltersMainComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;
  currentAgrupation: Agrupation;

  // Arrays de contadores
  metersFiltered: any[];
  @Input() metersOriginalArray: any[];
  @Input() filterType: string;

  // Filtro múltiple
  @Output() searchResult = new EventEmitter<any[]>();
  dialog: Subscription;
  selectedFilter: any;
  preselectedFilter: string;
  resetFilterSelection: boolean;
  searchFiltersActive: TableSearchFilter[] = [];
  filterOptionsByProfile: MapFilterOption[];
  filterOptions: object = {
    main: [
      {
        value: "lastCommunication",
        name: this.translate.instant("last-communication"),
        comparisonType: "date",
        profile: [PROFILES.ARSON],
      },
      {
        value: "lastSnr",
        name: this.translate.instant("SNR"),
        comparisonType: "numerical",
        profile: [PROFILES.ARSON],
      },
      {
        value: "lastRssi",
        name: this.translate.instant("RSSI"),
        comparisonType: "numerical",
        profile: [PROFILES.ARSON],
      },
      {
        value: "lastDataRate",
        name: this.translate.instant("Spreading factor"),
        comparisonType: "numerical",
        profile: [PROFILES.ARSON],
      },
      {
        value: "alarm",
        name: this.translate.instant("alarms"),
        comparisonType: "boolean",
      },
    ],
    coverage: [
      {
        value: "timestamp",
        name: this.translate.instant("test-date"),
        comparisonType: "date",
        profile: [PROFILES.ARSON],
      },
    ],
  };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    private TableFilterService: TableFilterService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.metersFiltered = [...this.metersOriginalArray];
    this.filterOptionsByProfile = this.filterOptions[this.filterType].filter(
      (option) =>
        !option.profile || option.profile.includes(this.sessionProfile)
    );

    // Dialog
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: DialogAction) => {
        if (dialogAction.action == "filter" && this.selectedFilter) {
          this.searchFiltersActive.push({
            inputData: dialogAction.filterData,
            inputDataString: dialogAction.inputDataString,
            selectedFilter: this.selectedFilter.value,
            selectedFilterRaw: this.selectedFilter.value,
            espComparison: true,
            dateComparison: this.selectedFilter.comparisonType == "date",
            booleanComparison: this.selectedFilter.comparisonType == "boolean",
            numericalComparison:
              this.selectedFilter.comparisonType == "numerical",
            showNull: dialogAction.showNull,
            label: this.selectedFilter.name,
          });
          this.updateFilters();
        } else if (dialogAction.action == "close") {
          this.selectedFilter = null;
          this.resetFilterSelection = !this.resetFilterSelection;
        }
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Filtrado por opción seleccionada
  filterBySelectedOption(filter: MapFilterOption): void {
    this.selectedFilter = filter;
    this.preselectedFilter = null;
    if (this.selectedFilter) {
      this.MaterialDialogService.openDialog(TableSpecialFilterDialogComponent, {
        comparisonType: filter.comparisonType,
        timezone: this.currentAgrupation.timezone,
      });
    }
  }

  // Eliminación de búsqueda activa
  removeActiveSearch(index: number): void {
    this.searchFiltersActive.splice(index, 1);
    this.updateFilters();
  }

  // Inversión de búsqueda activa
  invertActiveSearch(index: number): void {
    this.searchFiltersActive[index].inverse =
      !this.searchFiltersActive[index].inverse;
    this.updateFilters();
  }

  // Actualización de filtrado
  updateFilters(): void {
    let newData: any[] = [...this.metersOriginalArray];
    this.searchFiltersActive?.forEach((search: TableSearchFilter) => {
      newData = this.TableFilterService.filterData(
        search.inputData,
        newData,
        null,
        search.selectedFilter,
        search.selectedFilterRaw,
        search.espComparison,
        search.dateComparison,
        search.booleanComparison,
        search.numericalComparison,
        LOCAL_TIMEZONE,
        search.showNull,
        search.inverse
      );
    });
    this.metersFiltered = [...newData];
    this.searchFinsished();
    this.resetFilterSelection = !this.resetFilterSelection;
  }

  // Reseteo de filtros
  resetFilters(): void {
    this.searchFiltersActive = [];
    this.selectedFilter = null;
    this.resetFilterSelection = !this.resetFilterSelection;
    this.metersFiltered = [...this.metersOriginalArray];
    this.searchResult.emit(this.metersOriginalArray);
  }

  // Devolución de array de contadores filtrado al controlador de mapa
  searchFinsished(): void {
    if (this.metersFiltered.length == 0) {
      this.ToastService.fireToast(
        "info",
        this.translate.instant("no-meters-filters-match")
      );
      this.searchResult.emit(this.metersFiltered);
    } else {
      this.searchResult.emit(this.metersFiltered);
    }
  }
}
