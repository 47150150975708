// @angular
import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Cron Validator
import * as CronValidator from "cron-expression-validator";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { CronManagementControllerService } from "../../../../../services/server/CronManagementController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";

@Component({
  selector: "app-management-cron-dialog",
  templateUrl: "./management-cron-dialog.component.html",
  styleUrls: ["./management-cron-dialog.component.scss"],
})
export class ManagementCronDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  cronValid: boolean;
  misfireOptions: object = {
    ONCE_NOW: 1,
    DO_NOTHING: 2,
  };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CronManagementController: CronManagementControllerService,
    private ReloadComponentService: ReloadComponentService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data.action == "editCron") {
      this.validateCron();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización de la expresión Cron
  updateCronExpression(): void {
    this.CronManagementController.modifyCron({
      group: this.data.currentTrigger.triggerGroup,
      name: this.data.currentTrigger.triggerName,
      cronExpression: this.data.updatedCronExp,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendDialogAction({ action: "close" });
        this.ReloadComponentService.reload();
      }
    });
  }

  // Actualización de Misfire
  updateMisfire(): void {
    this.CronManagementController.changeMisfire({
      group: this.data.currentTrigger.triggerGroup,
      name: this.data.currentTrigger.triggerName,
      misFireInstr: this.misfireOptions[this.data.updatedMisfire],
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendDialogAction({ action: "close" });
        this.ReloadComponentService.reload();
      }
    });
  }

  // Validación de cron
  validateCron(): void {
    this.cronValid = CronValidator.isValidCronExpression(
      this.data.updatedCronExp
    );
  }
}
