import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ExportTableService } from "../../../services/shared/ExportTableService.service";
import { ImportColumn } from "../TableInterface.type";

@Component({
  selector: "app-import-table",
  templateUrl: "./import-table.component.html",
  styleUrls: ["./import-table.component.scss"],
})
export class ImportTableComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // @Input() importColumns: ImportColumn[];
  @Input()
  get importColumns(): ImportColumn[] {
    return this._importColumns;
  }
  set importColumns(importColumns: ImportColumn[]) {
    this._importColumns = importColumns;
    this.loadComponent();
  }
  _importColumns: ImportColumn[];
  @Input() importPreselects: any[];
  @Output() tableActionFlag = new EventEmitter<string>();
  @ViewChild("preselect") preselect: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ExportTableService: ExportTableService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let selection: string =
      this.importPreselects != null ? this.importPreselects[0].id : null;
    this.preselectData(selection);
  }

  // Acción de tabla
  tableAction(actionData: string): void {
    this.tableActionFlag.emit(actionData);
  }

  // Exportación de la plantilla
  exportTemplate(): void {
    let columnsHeaders: any[] = [];
    let row: any = {};
    this._importColumns.forEach((column: any) => {
      if (column.selected) {
        columnsHeaders.push(column.title);
        if (column.required) {
          row[column.title] = this.translate.instant("required");
        } else {
          row[column.title] = "";
        }
      }
    });
    this.ExportTableService.exportToCsv(
      [row],
      this.translate.instant("import-template"),
      columnsHeaders
    );
  }

  // Preselección de opciones
  preselectData(selection: string): void {
    this._importColumns?.map((column: any) => {
      if (
        column.profile &&
        (!selection ||
          column.preselect.length == 0 ||
          column.preselect.includes(selection))
      ) {
        column.visible = true;
        column.selected = true;
      } else {
        column.visible = false;
        column.selected = false;
      }
    });
  }
}
