// @angular
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { Subscription } from "rxjs";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { NotificationModalService } from "../../../modules/notification-module/notification-service/notification-modal.service";
import { ThemeService } from "../../../../app/themes/theme.service";
import { NavbarMenuService } from "../navbar-side/navbar-menu.service";
// Interfaces
import { Client } from "../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { MenuItem } from "../navbar-side/navbar-menu";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-navbar-responsive",
  templateUrl: "./navbar-responsive.component.html",
  styleUrls: ["./navbar-responsive.component.scss"],
})
export class NavbarResponsiveComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  @Input() sessionProfile: string;
  @Input() sessionUserName: { name: string; surname: string };
  @Input() currentClient: Client;
  @Input() currentEntity: Entity;
  @Input() currentAgrupation: Agrupation;
  @Input() clientList: Client[];
  @Input() entityList: Entity[];
  @Input() agrupationList: Agrupation[];
  currentUserId: number;
  readonly PROFILES = PROFILES;
  readonly LANGUAGE = LANGUAGE;

  // Control de navegación
  componentInitiated: boolean = false;
  activeMenu: string;
  dropdownActive = "";
  languageMenu: boolean = false;
  themeMenu: boolean = false;
  searchActive: string = null;
  cupsSelected: boolean = false;
  searchOptionSelected: string = this.translate.instant("meter-search");
  @Output() showResponsiveMenuFlag = new EventEmitter<any>();
  @Output() logoutFlag = new EventEmitter<any>();
  @Output() languageChangeFlag = new EventEmitter<string>();

  // Variables de búsqueda
  @Input()
  get meterList(): any[] {
    return this._meterList;
  }
  set meterList(meterList: any[]) {
    this._meterList = meterList;
    if (!this.cupsSelected && this.componentInitiated) {
      this.setSearchDropdownHeight();
    }
  }
  _meterList: any[];
  @Input()
  get cupsList(): any[] {
    return this._cupsList;
  }
  set cupsList(cupsList: any[]) {
    this._cupsList = cupsList;
    if (this.cupsSelected && this.componentInitiated) {
      this.setSearchDropdownHeight();
    }
  }
  _cupsList: any[];
  @Input() currentCupsName: string;
  @Input() currentCupsConf: any;
  @Input() pendingNotifications: number;
  @Output() resetFilterFlag = new EventEmitter<any>();
  @Output() searchSelection = new EventEmitter<any>();
  @Output() dropdownFilterFlag = new EventEmitter<any>();
  @Output() updateClientFlag = new EventEmitter<any>();
  @Output() updateEntityFlag = new EventEmitter<any>();
  @Output() updateAgrupationFlag = new EventEmitter<any>();
  @Output() deleteUserLocalSettingsFlag = new EventEmitter<any>();
  @ViewChild("responsiveOptionsDropdown") responsiveOptionsDropdown: ElementRef;
  @ViewChild("responsiveSearchFilter") responsiveSearchFilter: ElementRef;
  @ViewChild("responsiveClientFilter") responsiveClientFilter: ElementRef;
  @ViewChild("responsiveEntityFilter") responsiveEntityFilter: ElementRef;
  @ViewChild("responsiveAgrupationFilter")
  responsiveAgrupationFilter: ElementRef;
  @ViewChild("responsiveSearchDropdown") responsiveSearchDropdown: ElementRef;
  @ViewChild("responsiveClientDropdown") responsiveClientDropdown: ElementRef;
  @ViewChild("responsiveEntityDropdown") responsiveEntityDropdown: ElementRef;
  @ViewChild("responsiveAgrupationDropdown")
  responsiveAgrupationDropdown: ElementRef;
  @ViewChild("responsiveSearchBoxDropdown")
  responsiveSearchBoxDropdown: ElementRef;
  @ViewChild("searchDeviceResponsiveButton")
  searchDeviceResponsiveButton: ElementRef;

  // Menu
  menuList: MenuItem[];
  menuListBy3: MenuItem[][];
  menuListSub: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private navbarMenu: NavbarMenuService,
    public notificationModal: NotificationModalService,
    private SessionDataService: SessionDataService,
    private theme: ThemeService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentUserId = this.SessionDataService.getCurrentUserId();
    this.navbarMenu.loadMenu();
    this.menuList = this.SessionDataService.getCurrentNavbarMenu();
    this.chunkMenuList();

    this.menuListSub = this.SessionDataService.getNavbarMenu().subscribe(
      (navbarMenu) => {
        this.menuList = navbarMenu;
        this.chunkMenuList();
      }
    );

    this.componentInitiated = true;
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.menuListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Construcción de menú
  chunkMenuList(): void {
    let menuListBy3: MenuItem[][] = [];
    let chunkSize = 3;

    for (let i = 0; i < this.menuList.length; i += chunkSize) {
      let chunk = this.menuList.slice(i, i + chunkSize);
      menuListBy3.push(chunk);
    }

    this.menuListBy3 = menuListBy3;
  }

  // Oculta el menú
  hideResponsiveMenu(): void {
    this.activeMenu = "";
    this.showResponsiveMenuFlag.emit();
  }

  // Logout
  logout(): void {
    this.logoutFlag.emit();
  }

  // Aviso de cambio del idioma para el componente padre
  switchLanguage(newLanguage: string): void {
    this.languageChangeFlag.emit(newLanguage);
  }

  // Texto de la caja de búsqueda
  searchSelect(searchOption: string): void {
    if (searchOption === "device") {
      this.cupsSelected = false;
      this.searchOptionSelected = this.translate.instant("meter-search");
    } else {
      this.cupsSelected = true;
      this.searchOptionSelected =
        this.translate.instant("search-of") + " " + this.currentCupsName;
    }
  }

  // Aviso de reseteo de todas las cajas de búsqueda
  resetAllSearchBox(): void {
    this.resetSearchBox(this.responsiveAgrupationFilter, "agrupation");
    this.resetSearchBox(this.responsiveEntityFilter, "entity");
    this.resetSearchBox(this.responsiveClientFilter, "client");
    this.resetSearchBox(this.responsiveSearchFilter, "search");
  }

  // Aviso de reseteo para el componente padre
  resetSearchBox(filter: any, type: string): void {
    filter.value = "";
    this.resetFilterFlag.emit(type);
  }

  // Aviso de ir a selección para el componente padre
  goToSelection(cupsSelected: boolean, selection: any): void {
    this.searchSelection.emit({
      cupsSelected: cupsSelected,
      selection: selection,
    });
  }

  // Cierre de dropdown al hacer click
  closeDropdown(dropdown: any): void {
    dropdown.classList.remove("responsive-nav-dropdown-active");
    this.searchActive = null;
  }

  // Apertura de dropdown al hacer click
  openDropdown(dropdown: any, dropdownType?: string): void {
    dropdown.classList.add("responsive-nav-dropdown-active");
  }

  dropdownFilter(filterValue: any, type: string, cupsSelected?: boolean): void {
    this.dropdownFilterFlag.emit({
      filterValue: filterValue,
      type: type,
      cupsSelected: cupsSelected,
    });
  }

  updateCurrentAgrupation(agrupation: any): void {
    this.updateAgrupationFlag.emit(agrupation);
  }

  updateCurrentEntity(entity: any): void {
    this.updateEntityFlag.emit(entity);
  }

  updateCurrentClient(client: any): void {
    this.updateClientFlag.emit(client);
  }

  resetSearchDevice(): void {
    this.searchDeviceResponsiveButton.nativeElement.click();
  }

  // Seteo de altura de la caja de búsqueda
  setSearchDropdownHeight(): void {
    let searchList: any[] = this.cupsSelected ? this.cupsList : this.meterList;
    if (searchList?.length < 10) {
      this.responsiveSearchDropdown.nativeElement.style.setProperty(
        "--search-height",
        searchList.length
      );
    } else {
      this.responsiveSearchDropdown.nativeElement.style.setProperty(
        "--search-height",
        10
      );
    }
  }

  // Borrado de la configuración local
  deleteUserLocalSettings(): void {
    this.deleteUserLocalSettingsFlag.emit();
  }

  // Actualización del tema de estilos
  updateTheme(theme: string): void {
    this.theme.setTheme(theme);
    this.theme.saveThemeConfig(theme);
  }
}
