import { Injectable, OnDestroy, Injector } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../services/shared/ToastService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { RequestQueueDataService } from "./request-queue-data.service";
import { RequestQueueResponseManagementService } from "./request-queue-response-management.service";
import { GatewayService } from "../../../../screens/dashboard/gateways/GatewayService.service";
// Interfaces
import { RequestResponse } from "../../request-queue.type";
import { Task } from "../../request-progress-panel/request-progress-task-interface";
import {
  Gateway,
  GatewayDeleteResponse,
} from "../../../../interfaces/GatewayGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class RequestQueueService implements OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tareas en progreso
  taskActionSub: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private injector: Injector,
    private requestQueueData: RequestQueueDataService,
    private requestQueueResponseManagement: RequestQueueResponseManagementService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {
    // Acciones sobre las tareas en progreso
    this.taskActionSub = this.SessionDataService.getTaskAction().subscribe(
      (taskAction) => {
        let wip = this.SessionDataService.getCurrentWip();
        if (taskAction.action == "cancelAll") {
          // Confirmación de usuario
          this.ToastService.fireAlertWithOptions(
            "question",
            this.translate.instant("question-cancel-all-process")
          ).then((userConfirmation: boolean) => {
            if (userConfirmation) {
              // Confirmación
              this.SessionDataService.clearWip();
            }
          });
        } else if (taskAction.action == "update") {
          this.updateWip(taskAction.task, true);
        } else {
          wip[taskAction.index].status = taskAction.action;
          // Reintentar y reconfigurar
          if (
            taskAction.action == "retry" ||
            taskAction.action == "reconfigure"
          ) {
            wip[taskAction.index].progress = 0;
            wip[taskAction.index].progressIndex = 0;
            // Reintentar
            if (taskAction.action == "retry") {
              wip[taskAction.index].retry = true;
            }
            // Reconfigurar
            if (taskAction.action == "reconfigure") {
              wip[taskAction.index].reconfigure = true;
            }
            wip[taskAction.index].status = "run";
            this.updateWip(wip[taskAction.index]);
            // Cancelar
          } else if (taskAction.action == "cancel") {
            // Si no se ha finalizado la tarea
            if (
              wip[taskAction.index].progress != 100 ||
              wip[taskAction.index].error > 0
            ) {
              wip[taskAction.index].status = "stop";
              this.updateWip(wip[taskAction.index]);
              // Confirmación de usuario
              this.ToastService.fireAlertWithOptions(
                "question",
                this.translate.instant("question-cancel-process")
              ).then((userConfirmation: boolean) => {
                // Confirmación
                if (!userConfirmation) {
                  wip[taskAction.index].status = "run";
                  this.updateWip(wip[taskAction.index]);
                  this.startTask(taskAction.index);
                  // Negación
                } else {
                  wip[taskAction.index].status = "cancel";
                  this.updateWip(wip[taskAction.index]);
                }
              });
              // Si se ha finalizado la tarea
            } else {
              this.updateWip(wip[taskAction.index]);
            }
          }
          // Relanzar tarea
          if (
            taskAction.action == "run" ||
            taskAction.action == "retry" ||
            taskAction.action == "reconfigure"
          ) {
            this.startTask(taskAction.index);
          }
        }
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.taskActionSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Datos de la tarea
  setTask(action: string, data: any, extraData?: any, wait?: boolean): void {
    let taskData: any[] = [];
    let prevDeviceId: number;
    // Procesado de dispositivo
    data.map((device: any) => {
      // Nuevo dispositivo
      let deviceData: RequestResponse = new RequestResponse();

      // Actualización de acción si se trata de una sustitución de gateway
      if (action == "replaceGateway" && device.eraseGateway) {
        // Borrado de puntos finales del gateway original
        action = "deleteGatewayEndPoints";
      } else if (
        action == "replaceGateway" ||
        action == "deleteGatewayEndPoints"
      ) {
        // Actualización de acción a refresco
        if (extraData != null) {
          action = "refresh";
        } else {
          // Actualización de acción a APP EUI
          action = "saveAppEui";
          extraData = { replace: true };
        }
      }

      // Datos genéricos
      deviceData.deviceId = device.id ? device.id : device.contadorId;
      deviceData.deviceMetrologyType = device.metrologyType;
      deviceData.nroSerie = device.nroSerie
        ? device.nroSerie
        : device.contadorNroSerie;
      deviceData.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
        deviceData.deviceMetrologyType,
        deviceData.deviceId
      );
      deviceData.requestState = this.translate.instant("pending");
      deviceData.assignable = device.assignable;
      deviceData.agrupation = device.agrupation;
      deviceData.entity = device.entity;
      deviceData.newGatewayId = device.newGatewayId;
      deviceData.newGatewayUnidadVenta = device.newGatewayUnidadVenta;

      // Reasignación
      if (action == "reassign") {
        deviceData.reassignResult = device.reassignResult;
      }

      // Reasignación manual
      if (action == "reassignManually") {
        deviceData.checked = false;
        deviceData.errorText = this.translate.instant(
          "reassign-error-" + device.reassignResult
        );
      }

      // Actualización de SF/ADR
      if (action == "updateSfAdr") {
        deviceData.min_sf = extraData?.min_sf;
        deviceData.adrPermission = extraData?.adrPermission;
        deviceData.txPower = extraData?.txPower;
      }

      // Actualización de SF
      if (action == "updateSf") {
        deviceData.sf = extraData?.sf;
      }

      // Guardado de App EUI
      if (action == "saveAppEui") {
        deviceData.unidadVenta = device.unidadVenta;
        deviceData.appEui = device.appEui;
        deviceData.entityId = device.entityId;
        deviceData.entity = device.entity;
        deviceData.manufacturer = device.manufacturer;
        deviceData.deviceLink =
          "/gateways/detalle/gateway/" + deviceData.deviceId;

        // Detección de nuevo gateway para borrado de APP EUIs
        if (device.deletePreviousAppEui && device.id != prevDeviceId) {
          let newDeviceData = JSON.parse(JSON.stringify(deviceData));
          newDeviceData.appEui = null;
          newDeviceData.manufacturer = null;
          newDeviceData.deleteAppEuis = true;
          newDeviceData.action = this.translate.instant("appeui-deleting-all");
          taskData.push(newDeviceData);
        }
        prevDeviceId = device.id;
      }

      // Configuración de canales de gateway
      if (action == "configureGatewayChannels") {
        deviceData.unidadVenta = device.unidadVenta;
        deviceData.deviceLink =
          "/gateways/detalle/gateway/" + deviceData.deviceId;
        deviceData.channels = extraData?.channels;
      }

      // Optimización
      if (action == "optimize") {
        deviceData.nroRedundantGateways = device.nroRedundantGateways;
      }

      // Relanzar gateway
      if (action == "relaunchGateway") {
        deviceData.gateway = extraData.gateway;
        deviceData.relaunch = extraData.relaunch;
      }

      // Asignar mejor gateway redundante
      if (action == "assignBestRedundant") {
        deviceData.gatewayId = device.gatewayId;
        deviceData.gatewayUnidadVenta = device.gatewayUnidadVenta;
        deviceData.task = device.task;
        deviceData.gatewayLink =
          "/gateways/detalle/gateway/" + device.gatewayId;
      }

      // Borrado de puntos finales
      if (action == "deleteGatewayEndPoints") {
        deviceData.unidadVenta = device.unidadVenta;
        deviceData.deviceLink =
          "/gateways/detalle/gateway/" + deviceData.deviceId;
        deviceData.deleteEndPoints = true;
      }

      // Desasignar contador de gateways
      if (action == "deallocateDevice") {
        deviceData.unidadVenta = device.unidadVenta;
        deviceData.deviceLink =
          "/gateways/detalle/gateway/" + deviceData.deviceId;
      }

      // Activar
      if (action == "activate") {
        deviceData.activationData = device.activationData;
      }

      // Texto de acción
      deviceData.action = this.requestQueueData.getActionText(action, device);

      // Datos de la tarea
      taskData.push(deviceData);
    });

    // Creación de la tarea
    let newTask = this.createWip(taskData, action, extraData, wait);

    // Si no se requiere acción manual, se lanza la tarea
    if (action != "reassignManually" && !wait) {
      this.startTask(newTask.taskIndex);
    }

    // Redirección a tabla de la tarea en el caso de asignar mejor
    if (action == "agrupationAssignBest") {
      this.router.navigate([
        "/mantenimiento/red/asignar-mejor/" + newTask.taskIndex,
      ]);
    }
  }

  // Procesado del dispositivo
  processDevice(task: Task): void {
    let device = task.taskData[task.progressIndex];
    // Actualización de estado
    device.requestState = this.translate.instant("waiting-response");
    device.commandResponse = "waiting";
    device.highlightCurrent = true;
    device.highlightSuccess = false;
    device.highlightError = false;
    device.highlightWarning = false;
    let request = this.requestQueueData.getRequest(device, task);
    this.updateWip(task);
    // Petición
    request?.subscribe((response: any) => {
      device.requestState = "";
      device.errorText = "";
      // Asignación/Desasignación
      if (
        task.action == "allocate/deallocate" ||
        task.action == "deallocateDevice" ||
        task.action == "allocateAsMain"
      ) {
        device =
          this.requestQueueResponseManagement.allocateDeallocateResponseManagement(
            response,
            device
          );
        // Asignar mejor gateway
      } else if (
        task.action == "assignBest" ||
        task.action == "agrupationAssignBest"
      ) {
        device =
          this.requestQueueResponseManagement.assignBestResponseManagement(
            response,
            device,
            task
          );
        // Actualización de SF/ADR
      } else if (
        task.action == "updateSfAdr" ||
        task.action == "updateSf" ||
        task.action == "saveAppEui" ||
        task.action == "configureGatewayChannels"
      ) {
        device = this.requestQueueResponseManagement.singleResponseManagement(
          response,
          device,
          task
        );
        // Optimización
      } else if (task.action == "optimize") {
        // Respuesta de la asignación de mejor gateway
        let assignDevice = JSON.parse(
          JSON.stringify(
            this.requestQueueResponseManagement.assignBestResponseManagement(
              {
                code: response?.code,
                body: { responseList: [response?.body[0]?.bestSignal] },
              },
              device,
              task
            )
          )
        );

        device.errorText = null;
        // Respuesta de limpieza de redundantes
        let cleanDevice = JSON.parse(
          JSON.stringify(
            this.requestQueueResponseManagement.defaultResponseManagement(
              {
                code: response?.code,
                body: {
                  responseList: response?.body[0]?.cleanDevice?.responseList,
                },
              },
              device,
              task
            )
          )
        );

        // Cohesión de respuestas
        device.responseCode = response["code"];
        device.commandResponse =
          assignDevice.commandResponse == "error" ||
          cleanDevice.highlightError == "error"
            ? "error"
            : assignDevice.commandResponse == "warning" ||
              cleanDevice.highlightError == "warning"
            ? "warning"
            : "success";
        device.nroRedundantGatewaysLeft =
          device.nroRedundantGateways -
          response["body"][0]?.clearRedundant.responseList.filter(
            (gateway: any) => gateway.responseCode == 0
          )?.length;
        device.highlightError =
          assignDevice.highlightError || cleanDevice.highlightError;
        device.highlightWarning =
          !device.highlightError &&
          (assignDevice.highlightWarning || cleanDevice.highlightWarning);
        device.highlightSuccess =
          assignDevice.highlightSuccess && cleanDevice.highlightSuccess;
        device.errorText =
          (assignDevice.errorText ? assignDevice.errorText : "") +
          (cleanDevice.errorText
            ? (assignDevice.errorText ? ", " : "") + cleanDevice.errorText
            : "");

        // Refresco
      } else if (task.action == "refresh") {
        device = this.requestQueueResponseManagement.refreshResponseManagement(
          response,
          device
        );
        // Relanzar gateway
      } else if (task.action == "relaunchGateway") {
        device =
          this.requestQueueResponseManagement.relaunchGatewayResponseManagement(
            response,
            device,
            task
          );
        // Resto
      } else {
        device = this.requestQueueResponseManagement.defaultResponseManagement(
          response,
          device,
          task
        );
      }
      // Actualización de progreso
      task.progressIndex = task.progressIndex + 1;
      this.updateWip(task);
      this.checkFinish(task);
    });
  }

  // Comienzo de la tarea
  startTask(taskIndex: number): void {
    let wip = this.SessionDataService.getCurrentWip();
    // Si no ha finalizado ni se ha parado la tarea
    if (
      wip[taskIndex] &&
      wip[taskIndex].progressIndex < wip[taskIndex].taskData.length &&
      wip[taskIndex].status != "stop" &&
      wip[taskIndex].status != "cancel"
    ) {
      // Si no es reintento ni reconfiguración o si los son y se cumple la condición necesaria para volver a procesar el dispositivo
      if (
        (!wip[taskIndex].retry && !wip[taskIndex].reconfigure) ||
        (wip[taskIndex].retry &&
          (wip[taskIndex].taskData[wip[taskIndex].progressIndex]
            .commandResponse == "error" ||
            wip[taskIndex].taskData[wip[taskIndex].progressIndex]
              .commandResponse == "warning")) ||
        (wip[taskIndex].reconfigure &&
          wip[taskIndex].taskData[wip[taskIndex].progressIndex]
            .codeModifyNewGateway == 4203)
      ) {
        // Procesado de dispositivo
        this.processDevice(wip[taskIndex]);
      } else {
        // Paso al siguiente dispositivo y actualización
        wip[taskIndex].progressIndex = wip[taskIndex].progressIndex + 1;
        this.updateWip(wip[taskIndex]);
        this.checkFinish(wip[taskIndex]);
      }
    }
  }

  // Comprobación de fin de tarea
  checkFinish(task: Task): void {
    if (task && task.progressIndex == task.taskData.length) {
      this.updateWip(task);
      let errors = task.taskData.some(
        (device: RequestResponse) => device.responseCode != 0
      );
      this.ToastService.fireToast(
        "info",
        errors
          ? this.translate.instant("some-meter-error")
          : this.translate.instant("action-success")
      );
      // Comprobación para el caso de borrado de gateway
      this.checkGatewayDelete(task, errors);
      this.checkReplace(task, errors);
    } else {
      this.startTask(task.taskIndex);
    }
  }

  // Comprobación de si hay que borrar gateway tras reasignar
  checkGatewayDelete(task: Task, errors: RequestResponse[]): void {
    if (task.taskGateway?.deleteGateway && !errors) {
      this.ToastService.fireAlertWithOptions(
        "question",
        task.taskGateway.unidadVenta +
          ": " +
          this.translate.instant("reassign-completed") +
          ". " +
          this.translate.instant("gateway-delete-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.deleteGatewayTask(task.taskGateway, 1);
        }
      });
    }
  }

  // Comprobación si es una sustitución de gateway para refrescar puntos finales
  checkReplace(task: Task, errors: RequestResponse[]): void {
    if (task.taskGateway?.replace) {
      this.ToastService.fireAlertWithOptions(
        "question",
        task.taskData[0].unidadVenta +
          ": " +
          (errors
            ? this.translate.instant("replace-gateway-error-continue")
            : this.translate.instant("replace-gateway-continue"))
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          task.replaceGateway = false;
          let gatewayActions =
            this.injector.get<GatewayService>(GatewayService);
          gatewayActions.refreshGateway({
            id: task.taskData[0].deviceId,
            unidadVenta: task.taskData[0].unidadVenta,
          });
        }
      });
    }
  }

  // Actualización de las tareas en progreso
  updateWip(task: Task, replaceTask?: boolean): void {
    let wip = this.SessionDataService.getCurrentWip();
    if (replaceTask) {
      wip[task.taskIndex] = task;
    } else {
      // Actualización de errores y progreso
      if (wip && task.status != "cancel") {
        task.progress =
          task.progressIndex >= task.taskData.length
            ? 100
            : Math.floor((task.progressIndex / task.taskData.length) * 100);
        task.success = task.taskData.filter(
          (device: RequestResponse) => device.highlightSuccess
        ).length;
        task.error = task.taskData.filter(
          (device: RequestResponse) =>
            device.highlightError || device.highlightWarning
        ).length;
        wip[task.taskIndex] = task;
      }
    }

    // Actualización de tarea
    if (wip) {
      this.SessionDataService.sendWip(
        wip
          .filter((task: Task) => task.status != "cancel")
          .map((task: Task, index) => {
            task.taskIndex = index;
            return task;
          })
      );
    }
  }

  // Creación de la tarea en progreso
  createWip(
    taskData: any[],
    action: string,
    extraData?: any,
    wait?: boolean
  ): Task {
    let wip = this.SessionDataService.getCurrentWip();
    // Si no existen tareas en curso se crea nuevo wip
    if (!wip) {
      wip = [];
    }

    // Título y url de la tarea
    let titleAndUrl = this.requestQueueData.getTaskTitleAndUrl(
      action,
      taskData,
      extraData
    );

    // Nueva tarea
    let newTask = {
      name: titleAndUrl.title,
      action: action,
      progress: 0,
      progressIndex: 0,
      success: 0,
      error: 0,
      url: titleAndUrl.url,
      taskData: taskData,
      taskIndex: wip.length,
      taskGateway: extraData,
      taskAction: action,
      status:
        action == "agrupationAssignBest" || wait
          ? "stop"
          : action == "reassignManually"
          ? null
          : "run",
      retry: false,
      reconfigure: false,
      client: this.SessionDataService.getCurrentClient(),
      entity: this.SessionDataService.getCurrentEntity(),
      agrupation: this.SessionDataService.getCurrentAgrupation(),
    };

    // Actualización de tareas
    wip.push(newTask);
    this.SessionDataService.sendWip(wip);
    return newTask;
  }

  // Borrado de gateway con reasignación de dispositivos
  deleteGatewayTask(gateway: Gateway, communication: number) {
    this.GatewayController.deleteCommGateway(
      gateway.id,
      communication
    ).subscribe((response) => {
      if (response["code"] == 0) {
        // Gateway borrado
        if (response["body"]?.deletedGateway) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("gateway-deleted")
          );
          this.SessionDataService.sendReloadPanelFlag();
        } else {
          // Dispositivos pendientes de reasignación
          if (response["body"]?.allMeterReasigned) {
            this.ToastService.fireAlertInfoText(
              "info",
              this.translate.instant("reassign-devices-task")
            );
            // Reasignación manual
          } else {
            this.ToastService.fireAlertInfoText(
              "warning",
              this.translate.instant("reassign-devices-manually-task")
            );
          }
          // Listado de dispositivos a reasignar
          let data = response["body"].listaResultados?.map(
            (device: GatewayDeleteResponse) => {
              return {
                id: device.meter,
                nroSerie: device.meterNroSerie,
                agrupation: device.agrupation,
                metrologyType: device.metrologyType,
                reassignResult: device.result,
                newGatewayId: device.gateway,
                newGatewayUnidadVenta: device.gatewayUnidadVenta,
              };
            }
          );

          let gatewayData = { ...gateway };
          gatewayData.deleteGateway = true;
          // Tarea reasignación/reasignación manual
          response["body"]?.allMeterReasigned
            ? this.setTask("reassign", data, gatewayData)
            : this.setTask("reassignManually", data, gateway);
        }
      }
    });
  }
}
