// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { LanguageControllerService } from "../../../services/language/LanguageController.service";
import { UserControllerService } from "../../../services/server/UserController.service";
import { EntityControllerService } from "../../../services/server/EntityController.service";
import { NotificationModalService } from "../../../modules/notification-module/notification-service/notification-modal.service";
import { NotificationControllerService } from "../../../services/server/NotificationController.service";
// Variables
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-subscriber-nav",
  templateUrl: "./subscriber-nav.component.html",
  styleUrls: ["./subscriber-nav.component.scss"],
})
export class SubscriberNavComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionUser: string;
  sessionLanguage: string;
  languageSub: Subscription;
  subscriberCupsList: any[];

  // Idioma
  languageName: string;

  // Salir
  @Output() logoutFlag = new EventEmitter<any>();

  // Logo
  logoImg: any;
  logoImgUrl: any;
  logoLoading: boolean = true;
  logoId: number;

  // Caja de búsqueda
  searchActive: boolean = false;
  searchPlaceholder: string = this.translate.instant("search");
  subscriberList: any[] = [];
  subscriberListFiltered: any[] = [];
  selection: string;

  // Body
  body: any = document.body;

  // CUPS
  cupsData: any;
  cupsId: number;
  cupsIdSub: Subscription;
  pendingNotifications: number = 0;
  notificationsRequestTimerMS: number = 300000;
  notificationsRequestTimer: any;

  // Idiomas
  languageList = [
    {
      id: LANGUAGE.ESPANOL,
      title: "Castellano",
      img: "assets/img/languages/spain-flag.png",
    },
    { id: LANGUAGE.CATALAN, title: "Catalá", img: "assets/img/languages/cat-flag.png" },
    { id: LANGUAGE.INGLES, title: "English", img: "assets/img/languages/uk-flag.png" },
    {
      id: LANGUAGE.FRANCES,
      title: "Français",
      img: "assets/img/languages/france-flag.png",
    },
    { id: LANGUAGE.ITALIANO, title: "Italiano", img: "assets/img/languages/it-flag.png" },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private domSanitizer: DomSanitizer,
    private EntityController: EntityControllerService,
    private LanguageController: LanguageControllerService,
    private NotificationController: NotificationControllerService,
    public notificationModal: NotificationModalService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private UserController: UserControllerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/
  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionUser = this.SessionDataService.getCurrentUser();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.languageSelector();
    this.updateLanguageList(
      this.sessionLanguage == "CA" ? LANGUAGE.CATALAN : this.sessionLanguage
    );

    this.cupsIdSub = this.SessionDataService.getSubscriberCups().subscribe(
      (cupsId) => {
        if (this.cupsId != cupsId) {
          this.cupsId = cupsId;
          this.getCupsData(cupsId);
          if (this.subscriberCupsList) {
            this.getEntityImg(
              this.subscriberCupsList?.find((cups: any) => cups.id == cupsId)
                ?.entityId
            );
          }
        }
      }
    );

    // Escucha de cambios en idioma
    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.sessionLanguage = language;
        this.languageSelector();
        this.updateLanguageList(language == "CA" ? LANGUAGE.CATALAN : language);
      }
    );

    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
    clearInterval(this.notificationsRequestTimer);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getSubscriberData();
    this.getPendingNotifications();
    clearInterval(this.notificationsRequestTimer);
    this.notificationsRequestTimer = setInterval(
      () => this.getPendingNotifications(),
      this.notificationsRequestTimerMS
    );
  }

  // Obtención del logo de la entidad
  getEntityImg(logoId: number): void {
    if (logoId && logoId != this.logoId) {
      this.logoId = logoId;
      this.EntityController.getImg(this.logoId).subscribe((response) => {
        if (response["code"] == 0) {
          this.logoImg = response["body"];
          if (this.logoImg) {
            this.logoImgUrl = this.domSanitizer.bypassSecurityTrustUrl(
              "data:image/png;base64, " + this.logoImg
            );
          } else {
            this.logoImgUrl = "assets/img/logos/arsondata.png";
          }
        }
      });
    }
  }

  // Obtención de las entidades y agrupaciones del abonado
  getSubscriberData(): void {
    this.UserController.getSubscriberCupsDropdown().subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        this.subscriberCupsList = response["body"];
      } else {
        this.subscriberCupsList = [];
      }
      if (this.cupsId) {
        this.getEntityImg(
          this.subscriberCupsList?.find((cups: any) => cups.id == this.cupsId)
            ?.entityId
        );
      } else {
        this.getEntityImg(this.subscriberCupsList[0]?.entityId);
      }
      this.SessionDataService.sendSubscriberCupsList(this.subscriberCupsList);
    });
  }

  // Obtención de los datos del CUPS
  getCupsData(currentCupsId: number): void {
    this.UserController.getSubscriberMain(currentCupsId).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          let cupsData: any = response["body"];
          if (cupsData.contadores) {
            cupsData?.contadores?.forEach((meter) => {
              meter?.lastReadedTimestamp != null
                ? (meter.lastReadedTimestampParsed = moment_timezone(
                    meter?.lastReadedTimestamp
                  )
                    .tz(
                      cupsData?.timeZone ? cupsData?.timeZone : "Europe/Madrid"
                    )
                    .format("L"))
                : "-";
            });
          } else {
            cupsData.contadores = [];
          }
          this.cupsData = cupsData;
        }
        this.SessionDataService.sendSubscriberCupsData(this.cupsData);
      }
    );
  }

  // Selector de idioma
  languageSelector(): void {
    switch (this.sessionLanguage) {
      case LANGUAGE.INGLES:
        this.languageName = "English";
        break;
      case LANGUAGE.ESPANOL:
        this.languageName = "Castellano";
        break;
      case "ca":
        this.languageName = "Català";
        break;
      case LANGUAGE.CATALAN:
        this.languageName = "Català";
        break;
      case LANGUAGE.FRANCES:
        this.languageName = "Français";
        break;
      default:
        this.languageName = "English";
        break;
    }
  }

  // Cambio del idioma del usuario
  switchLanguage(newLanguage: string): void {
    this.SessionDataService.sendLanguage(newLanguage);
    // Actualización del idioma en el perfil del usuario
    this.LanguageController.changeLang(
      newLanguage == LANGUAGE.CATALAN ? "CA" : newLanguage.toUpperCase()
    ).subscribe();
  }

  // Actualización del orden de la lista de idiomas
  updateLanguageList(newLanguage: string): void {
    let currentLanguage: any[] = this.languageList.splice(
      this.languageList.findIndex(
        (language: any) => language.id == newLanguage
      ),
      1
    );
    this.languageList.unshift(currentLanguage[0]);
  }

  // Salir
  logout(): void {
    this.logoutFlag.emit();
  }

  // Ir a configuración de abonado
  goToConfig(): void {
    // Evita las barras de scroll en la interfaz de abonado
    this.body.style.overflow = "hidden";
    this.router.navigate(["/abonado/configuracion"]);
  }

  // Obtención del número de notificaciones pendientes
  getPendingNotifications() {
    this.NotificationController.getUserPendingNotifications().subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.pendingNotifications = response["body"];
        }
      }
    );
  }
}
