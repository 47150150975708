// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { SuspicionsService } from "../../../../services/shared/SuspicionsService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import {
  AlarmData,
  DeviceContinousAlarms,
} from "../../management/ManagementInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-alarms-devices",
  templateUrl: "./alarms-devices.component.html",
  styleUrls: ["./alarms-devices.component.scss"],
})
export class AlarmsDevicesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;

  // Tabla
  tableData: DeviceContinousAlarms[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  claveMaestraKeyVisibility: boolean = null;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("1", "week");
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];

  // Select
  alarmSelectOptions: MaterialSelectOption[] = [
    { value: true, name: this.translate.instant("alarm-all") },
    { value: false, name: this.translate.instant("alarm-active-enabled") },
  ];
  alarmSelect: boolean = true;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    // Inicialización
    if (this.currentAgrupation && this.currentEntityCupsConf) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConf.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getAlarms(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de alarmas
  getAlarms(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let url = this.alarmSelect
      ? this.AlarmController.getAlarmsFromTo(
          this.currentAgrupation.id,
          from,
          to
        )
      : this.AlarmController.getActiveEnabledAlarmsFromTo(
          this.currentAgrupation.id,
          from,
          to
        );
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let claveMaestraKeyArray: string[] = [];
        let alarmList = response["body"];
        alarmList.forEach((alarm: AlarmData) => {
          alarm.metrologyType = alarm.metrology_type;
          // Claves maestras
          claveMaestraKeyArray.push(alarm.claveMaestraKey);
          // Código de alarma
          alarm.code != null
            ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
            : "";
        });
        // Visibilidad de la columna de clave maestra
        if (claveMaestraKeyArray.length > 0) {
          if (claveMaestraKeyArray.reduce((a, b) => a || b)) {
            this.claveMaestraKeyVisibility = true;
          } else {
            this.claveMaestraKeyVisibility = null;
          }
        }
        this.getDataByDevices(
          this.SessionDataService.getCurrentSuspicionActive()
            ? alarmList
            : this.SuspicionsService.filterSuspicions(alarmList)
        );
        this.setColumns();
      }
    });
  }

  // Obtención de los datos por contador
  getDataByDevices(alarmList: AlarmData[]): void {
    let devices = {};
    alarmList.forEach((alarm: AlarmData) => {
      if (devices[alarm.contador] && devices[alarm.contador][alarm.code]) {
        devices[alarm.contador][alarm.code].push(alarm);
      } else {
        if (!devices[alarm.contador]) {
          devices[alarm.contador] = {};
        }
        devices[alarm.contador][alarm.code] = [alarm];
      }
    });
    let tableData = [];
    for (let device in devices) {
      for (let code in devices[device]) {
        tableData.push({
          claveMaestraKey: devices[device][code][0].claveMaestraKey,
          cupsLink: "/cups/detalle/" + devices[device][code][0].claveMaestra,
          nroSerie: devices[device][code][0].nroSerie,
          meterLink: this.DeviceRouteSelectorService.getDeviceRouteUrl(
            devices[device][code][0].metrologyType,
            devices[device][code][0].contador
          ),
          alarm: devices[device][code][0].name,
          repetitions: devices[device][code].length,
        });
      }
    }
    this.tableData = tableData;
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let cups: EntityDefinition = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );

    this.columns = [
      {
        title: cups?.name ? cups?.name : "CUPS",
        data: "claveMaestraKey",
        search: "claveMaestraKey",
        sort: "claveMaestraKey",
        visible: cups && this.claveMaestraKeyVisibility,
        link: "cupsLink",
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "meterLink",
      },
      {
        title: "alarm",
        data: "alarm",
        search: "alarm",
        sort: "alarm",
        visible: true,
      },
      {
        title: "une-repetitions",
        data: "repetitions",
        search: "repetitions",
        sort: "repetitions",
        numerical: true,
        visible: true,
      },
    ];
  }
}
