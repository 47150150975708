<div #snakeContainer class="snake-container">
  <!-- Modal de aviso -->
  <div *ngIf="snakeModalHtml" class="snake-modal">
    <div [innerHtml]="snakeModalHtml"></div>
    <button (click)="startGame()">{{ "start-game" | translate }}</button>
  </div>
  <div class="snake-control">
    <!-- Tiempo -->
    <div class="snake-timer">
      {{ timerMinutes > 9 ? timerMinutes : "0" + timerMinutes }}:
      {{ timerSeconds > 9 ? timerSeconds : "0" + timerSeconds }}
    </div>
    <div class="snake-points">
      <span><b>Bytes: </b>{{ bytes }}</span>
      <span><b>bits: </b>{{ bits }}</span>
    </div>
  </div>
  <!-- Tablero de juego -->
  <div #snakeBoard class="snake-board">
    <div
      *ngIf="boardReady"
      #snakeMapContainer
      leaflet
      class="snake-map"
      [leafletOptions]="mapOptions"
    ></div>
    <!-- Comida -->
    <div #food class="snake-food hidden">
      <img src="../../../../assets/img/arson_marker.svg" />
    </div>
    <!-- Comida mala -->
    <div
      class="snake-food hidden"
      id="bad-food-{{ i }}"
      *ngFor="let element of badFood; index as i"
    >
      <img src="../../../../assets/img/arson_marker_error.svg" />
    </div>
    <!-- Cabeza de serpiente -->
    <div #snakeHead class="snake-head hidden">
      <i class="fas fa-wave-square"></i>
    </div>
    <!-- Cuerpo de serpiente -->
    <div
      class="snake-body hidden"
      id="snake-body-{{ i }}"
      *ngFor="let bodyElement of snakeBody; index as i"
    >
      {{ bodyElement[2] }}
    </div>
  </div>
</div>
