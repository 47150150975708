export const MAP_CONFIG = {
  // ANCHOR Variables de polígonos de agrupación
  agrupationPolygon: {
    concavity: 5,
  },

  // ANCHOR Variables de las capas de control del mapa
  baseLayers: [
    {
      layerName: "OpenStreetMap",
      layerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      tileLayerOptions: {
        attribution:
          "Map data &copy; <a href='http://openstreetmap.org'>OpenStreetMap</a>",
        maxZoom: 18,
      },
    },
    {
      layerName: "Esri",
      layerUrl:
        "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      tileLayerOptions: {
        attribution:
          "Map data &copy; <a href='http://arcgisonline.com'>ESRI</a>",
        maxZoom: 20,
      },
    },
    {
      layerName: "EsriWorld",
      layerUrl:
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
      tileLayerOptions: {
        attribution:
          "Map data &copy; <a href='http://arcgisonline.com'>ESRI</a>",
        maxZoom: 18,
      },
    },
    {
      layerName: "Google",
      layerUrl: "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
      tileLayerOptions: {
        attribution: "Map data &copy; <a href='http://google.com'>Google</a>",
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      },
    },
    {
      layerName: "TopoMap",
      layerUrl: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
      tileLayerOptions: {
        attribution:
          "Map data &copy; <a href='http://opentopomap.org'>TopoMap</a>",
        maxZoom: 20,
      },
    },
  ],

  // ANCHOR Opciones del mapa
  mapOptions: {
    center: [43.31882, -3.004561],
    zoomSnap: 0.25,
    zoom: 10,
    edgeBufferTiles: 5,
    attributionControl: true,
    preferCanvas: true,
    updateWhenIdle: true,
    updateWhenZooming: false,
  },

  // ANCHOR Capa base inicial del mapa
  startLayer: "OpenStreetMap",

  // ANCHOR Leyendas para cada tipo de mapa
  legend: {
    // Mapa estándar
    standard: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-circle greenIcon", text: "devices-active" },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "devices-not-assigned",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meters-mbus-wavenis",
      },
      {
        icon: "fas fa-circle cadetblueIcon map-color-legend-icon",
        text: "mbus-communicate",
      },
      {
        icon: "fas fa-circle orangeIcon map-color-legend-icon",
        text: "NBIoT",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com",
      },
      { icon: "fas fa-circle yellowIcon", text: "selected-one" },
      { icon: "fas fa-circle whiteIcon", text: "meter" },
      {
        icon: "fas fa-star whiteIcon map-color-legend-icon",
        text: "no-lora-meter",
      },
      { icon: "fas fa-play rotated90 whiteIcon", text: "sensor" },
      { icon: "fas fa-tint whiteIcon", text: "concentrator" },
    ],
    // Mapa de gateways
    onlyGateways: [
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "devices-active",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
    ],
    // Mapa de cobertura
    coverageMap: [
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "devices-active",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-square greenIcon map-color-legend-icon",
        text: "coverage-total-rssi",
      },
      {
        icon: "fas fa-square yellowGreenIcon map-color-legend-icon",
        text: "coverage-high-rssi",
      },
      {
        icon: "fas fa-square yellowIcon map-color-legend-icon",
        text: "coverage-mid-rssi",
      },
      {
        icon: "fas fa-square orangeIcon map-color-legend-icon",
        text: "coverage-low-rssi",
      },
      {
        icon: "fas fa-square redIcon map-color-legend-icon",
        text: "coverage-critical-rssi",
      },
      {
        icon: "fas fa-square blackIcon map-color-legend-icon",
        text: "coverage-null",
      },
    ],
    // Mapa de dispositivos desconocidos
    coverageMbusMap: [
      {
        icon: "fas fa-circle greenIcon map-color-legend-icon",
        text: "coverage-total-mbus-rssi",
      },
      {
        icon: "fas fa-circle yellowGreenIcon map-color-legend-icon",
        text: "coverage-high-mbus-rssi",
      },
      {
        icon: "fas fa-circle yellowIcon map-color-legend-icon",
        text: "coverage-mid-mbus-rssi",
      },
      {
        icon: "fas fa-circle orangeIcon map-color-legend-icon",
        text: "coverage-low-mbus-rssi",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "coverage-critical-mbus-rssi",
      },
      { icon: "fas fa-circle blackIcon", text: "coverage-null" },
    ],
    // Test de cobertura
    coverage: [
      { icon: "fas fa-circle yellowIcon", text: "other" },
      { icon: "fas fa-circle redIcon", text: "test-fail" },
      { icon: "fas fa-circle greenIcon", text: "test-ok" },
    ],
    // Mapa de telecontrol
    control: [
      { icon: "fas fa-exclamation-circle redIcon", text: "alert-open" },
      { icon: "fas fa-exclamation-circle", text: "alert-assigned" },
      { icon: "fas fa-circle blueIcon", text: "meters-close-valve" },
      { icon: "fas fa-circle purpleIcon", text: "sensors" },
    ],
    // Mapa de detalle de contador
    meterDetail: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-circle greenIcon", text: "devices-active" },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "devices-not-assigned",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meters-mbus-wavenis",
      },
      {
        icon: "fas fa-circle cadetblueIcon map-color-legend-icon",
        text: "mbus-communicate",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com",
      },
      { icon: "fas fa-circle whiteIcon", text: "meter" },
      {
        icon: "fas fa-star whiteIcon map-color-legend-icon",
        text: "no-lora-meter",
      },
      { icon: "fas fa-play rotated90 whiteIcon", text: "sensor" },
      { icon: "fas fa-tint whiteIcon", text: "concentrator" },
    ],
    // Mapa de detalle de CUPS
    cupsDetail: [
      { icon: "fas fa-circle grayIcon", text: "no-main-gateway" },
      { icon: "fas fa-circle greenIcon", text: "devices-active" },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "devices-not-assigned",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meters-mbus-wavenis",
      },
      {
        icon: "fas fa-circle cadetblueIcon map-color-legend-icon",
        text: "mbus-communicate",
      },
      {
        icon: "fas fa-circle orangeIcon map-color-legend-icon",
        text: "NBIoT",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com",
      },
      { icon: "fas fa-circle whiteIcon", text: "meter" },
      {
        icon: "fas fa-star whiteIcon map-color-legend-icon",
        text: "no-lora-meter",
      },
      { icon: "fas fa-play rotated90 whiteIcon", text: "sensor" },
      { icon: "fas fa-tint whiteIcon", text: "concentrator" },
    ],
    // Mapa de gráfica de contadores
    meterGraph: [
      { icon: "fas fa-circle greenIcon", text: "devices-active" },
      {
        icon: "fas fa-circle purpleIcon map-color-legend-icon",
        text: "devices-not-assigned",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meters-mbus-wavenis",
      },
      {
        icon: "fas fa-circle cadetblueIcon map-color-legend-icon",
        text: "mbus-communicate",
      },
      {
        icon: "fas fa-circle orangeIcon map-color-legend-icon",
        text: "NBIoT",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        iconExtra: "fas fa-times whiteIcon",
        text: "no-lora-no-com",
      },
      { icon: "fas fa-circle yellowIcon", text: "selected-one" },
      { icon: "fas fa-circle whiteIcon", text: "meter" },
      {
        icon: "fas fa-star whiteIcon map-color-legend-icon",
        text: "no-lora-meter",
      },
      { icon: "fas fa-play rotated90 whiteIcon", text: "sensor" },
      { icon: "fas fa-tint whiteIcon", text: "concentrator" },
    ],
    // Mapa de balance
    balanceDetail: [
      { icon: "fas fa-circle blueIcon", text: "childs" },
      { icon: "fas fa-circle greenIcon", text: "fathers" },
    ],
    // Mapa de balance
    balanceEdition: [
      { icon: "fas fa-circle blueIcon", text: "childs" },
      { icon: "fas fa-circle greenIcon", text: "fathers" },
    ],
    // Mapa de listados de contadores
    meterList: [
      { icon: "fas fa-circle turquoiseIcon", text: "devices" },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    // Mapa de listados de contadores seleccionables
    meterAssignable: [
      { icon: "fas fa-circle turquoiseIcon", text: "redundant-meter" },
      { icon: "fas fa-circle orangeIcon", text: "assignable-meter" },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    // Mapa de listado de alarmas
    alarmsMeterList: [
      { icon: "fas fa-circle redIcon", text: "alarm-new" },
      { icon: "fas fa-circle yellowIcon", text: "alarm" },
    ],
    // Mapa de detalle de gateway
    gatewayDetail: [
      { icon: "fas fa-circle greenIcon", text: "main-meter" },
      { icon: "fas fa-circle turquoiseIcon", text: "redundant-meter" },
      { icon: "fas fa-circle orangeIcon", text: "assignable-meter" },
    ],
    // Mapa de detalle de concentrador MBUS
    mbusConcentratorDetail: [
      { icon: "fas fa-circle greenIcon", text: "devices-active" },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "devices-not-communicate",
      },
      {
        icon: "fas fa-circle grayIcon map-color-legend-icon",
        text: "devices-main-not-communicate",
      },
      {
        icon: "fas fa-circle blueIcon map-color-legend-icon",
        text: "meters-mbus",
      },
      {
        icon: "fas fa-circle cadetblueIcon map-color-legend-icon",
        text: "permanent-mbus-meters",
      },
      { icon: "fas fa-circle whiteIcon", text: "meter" },
      { icon: "fas fa-tint whiteIcon", text: "concentrator" },
    ],
    // Mapa de sustituciones
    substitutions: [
      { icon: "fas fa-circle blueIcon", text: "substitution-pending" },
      { icon: "fas fa-circle greenIcon", text: "substitution-replaced" },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    // Mapa de sospechas
    warnings: [
      { icon: "fas fa-circle turquoiseIcon", text: "device" },
      { icon: "fas fa-circle yellowIcon", text: "selected-one" },
    ],
    // Mapa de dispositivos desconocidos
    unknownDevices: [
      {
        icon: "fas fa-circle greenIcon map-color-legend-icon",
        text: "coverage-total-mbus-rssi",
      },
      {
        icon: "fas fa-circle yellowGreenIcon map-color-legend-icon",
        text: "coverage-high-mbus-rssi",
      },
      {
        icon: "fas fa-circle yellowIcon map-color-legend-icon",
        text: "coverage-mid-mbus-rssi",
      },
      {
        icon: "fas fa-circle orangeIcon map-color-legend-icon",
        text: "coverage-low-mbus-rssi",
      },
      {
        icon: "fas fa-circle redIcon map-color-legend-icon",
        text: "coverage-critical-mbus-rssi",
      },
      { icon: "fas fa-circle grayIcon", text: "coverage-null" },
    ],
    // Mapa de fugas
    leakDetection: [
      { icon: "fas fa-play rotated90 turquoiseIcon", text: "sensor" },
      { icon: "fas fa-circle rangeIcon", text: "sensor-range" },
      { icon: "fas fa-circle extraRangeIcon", text: "sensor-range-extra" },
      { icon: "fas fa-play rotated90 redIcon", text: "sensors-filter-over" },
      {
        icon: "fas fa-play rotated90 purpleIcon",
        text: "sensors-filter-below",
      },
      // {
      //   icon: "fas fa-play rotated90 grayIcon",
      //   text: "sensors-filter-no-data",
      // },
      { icon: "fas fa-circle yellowIcon", text: "selected" },
    ],
    // Mapa de presión
    pressureDetection: [
      { icon: "fas fa-play rotated90 turquoiseIcon", text: "sensor" },
    ],
    // Mapa de caudal
    flowDetection: [
      { icon: "fas fa-play rotated90 turquoiseIcon", text: "sensor" },
    ],
  },

  // ANCHOR Opciones de cluster
  clusterOptions: {
    chunkedLoading: false,
    chunkInterval: 200, // process markers for a maximum of ~ n milliseconds (then trigger the chunkProgress callback)
    chunkDelay: 50, // at the end of each interval, give n milliseconds back to system/browser
    chunkProgress: null, // progress callback: function(processed, total, elapsed) (e.g. for a progress indicator)
    maxClusterRadius: 30,
    polygonOptions: {
      fillColor: "#ff6659",
      color: "#d32f2f",
      opacity: 1,
      fillOpacity: 0.5,
    },
    singleMarkerMode: true,
    markers: {
      // Mapa estándar
      standard: {
        attribute: "className",
        options: [
          {
            condition: "deviceTxn",
            color: "gray",
            tooltip: "TXN",
          },
          {
            condition: "deviceCommError",
            color: "red",
            tooltip: "devices-not-communicate",
          },
          {
            condition: "deviceCommOk",
            color: "green",
            tooltip: "devices-active",
          },
          {
            condition: "deviceNoAssignated",
            color: "purple",
            tooltip: "devices-not-assigned",
          },
          {
            condition: "deviceNoLoraNoCom",
            color: "gray",
            tooltip: "no-lora-no-com-meters",
          },
          { condition: "gray", color: "gray", tooltip: "no-main-gateway" },
          {
            condition: "deviceNoLora",
            color: "turquoise",
            tooltip: "no-lora-meter",
          },
          { condition: "deviceMbus", color: "blue", tooltip: "MBUS" },
          {
            condition: "deviceCommunicateMbus",
            color: "cadetblue",
            tooltip: "mbus-communicate",
          },
          {
            condition: "deviceNbiot",
            color: "orange",
            tooltip: "NBIoT",
          },
          { condition: "deviceWavenis", color: "blue", tooltip: "Wavenis" },
          {
            condition: "deviceUneCommOk",
            color: "green",
            tooltip: "lw-une-meters-ok",
          },
          {
            condition: "deviceUneCommError",
            color: "red",
            tooltip: "lw-une-meters-fail",
          },
          {
            condition: "deviceEkCommOk",
            color: "green",
            tooltip: "ek-meters-ok",
          },
          {
            condition: "deviceEkCommError",
            color: "red",
            tooltip: "ek-meters-fail",
          },
          {
            condition: "devicePlumCommOk",
            color: "green",
            tooltip: "plum-meters-ok",
          },
          {
            condition: "devicePlumCommError",
            color: "red",
            tooltip: "plum-meters-fail",
          },
          {
            condition: "deviceExternoCommOk",
            color: "green",
            tooltip: "no-lora-meters-ok",
          },
          {
            condition: "deviceExternoCommError",
            color: "red",
            tooltip: "no-lora-meters-fail",
          },
          {
            condition: "deviceDeactivated",
            color: "gray",
            tooltip: "no-main-gateway",
          },
          {
            condition: "deviceSigfoxCommOk",
            color: "green",
            tooltip: "sigfox-ok",
          },
          {
            condition: "deviceSigfoxCommError",
            color: "red",
            tooltip: "sigfox-no-comm",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de gateways
      onlyGateways: {
        attribute: "className",
        options: [
          {
            condition: "gatewayError",
            color: "red",
            tooltip: "gateway-no-communicate",
          },
          { condition: "gatewayOk", color: "green", tooltip: "gateway" },
        ],
      },
      // Test de cobertura
      coverage: {
        attribute: "className",
        options: [
          { condition: "gatewayOk", color: "blue", tooltip: "gateways" },
          { condition: "coverageError", color: "red", tooltip: "test-fail" },
          { condition: "coverageOk", color: "green", tooltip: "test-ok" },
          { condition: "yellow", color: "yellow", tooltip: "other" },
        ],
      },
      // Mapa de cobertura
      coverageMap: {
        attribute: "className",
        options: [
          {
            condition: "gatewayError",
            color: "red",
            tooltip: "gateway-no-communicate",
          },
          { condition: "gatewayOk", color: "green", tooltip: "gateway" },
        ],
      },
      // Mapa de cobertura MBus
      coverageMbusMap: {
        attribute: "className",
        options: [
          {
            condition: "deviceCommError",
            color: "red",
            tooltip: "devices-not-communicate",
          },
          {
            condition: "deviceCommOk",
            color: "green",
            tooltip: "devices-active",
          },
        ],
      },
      // Mapa de telecontrol
      control: {
        attribute: "className",
        options: [
          {
            condition: "controlAlertOpen",
            color: "red",
            tooltip: "alert-open",
          },
          {
            condition: "controlAlertAssigned",
            color: "green",
            tooltip: "alert-assigned",
          },
          { condition: "controlSensor", color: "purple", tooltip: "sensors" },
          {
            condition: "controlValve",
            color: "blue",
            tooltip: "meters-close-valve",
          },
        ],
      },
      // Mapa de detalle de contador
      meterDetail: {
        attribute: "className",
        options: [
          {
            condition: "deviceCommError",
            color: "red",
            tooltip: "devices-not-communicate",
          },
          {
            condition: "deviceCommOk",
            color: "green",
            tooltip: "devices-active",
          },
          {
            condition: "deviceNoAssignated",
            color: "purple",
            tooltip: "devices-not-assigned",
          },
          {
            condition: "deviceNoLoraNoCom",
            color: "gray",
            tooltip: "no-lora-no-com-meters",
          },
          { condition: "gray", color: "gray", tooltip: "no-main-gateway" },
          {
            condition: "deviceNoLora",
            color: "turquoise",
            tooltip: "no-lora-meter",
          },
          { condition: "deviceMbus", color: "blue", tooltip: "MBUS" },
          {
            condition: "deviceCommunicateMbus",
            color: "cadetblue",
            tooltip: "mbus-communicate",
          },
          {
            condition: "deviceNbiot",
            color: "orange",
            tooltip: "NBIoT",
          },
          {
            condition: "deviceTxn",
            color: "gray",
            tooltip: "TXN",
          },
          { condition: "deviceWavenis", color: "blue", tooltip: "Wavenis" },
          {
            condition: "deviceUneCommOk",
            color: "green",
            tooltip: "lw-une-meters-ok",
          },
          {
            condition: "deviceUneCommError",
            color: "red",
            tooltip: "lw-une-meters-fail",
          },
          {
            condition: "deviceEkCommOk",
            color: "green",
            tooltip: "ek-meters-ok",
          },
          {
            condition: "deviceEkCommError",
            color: "red",
            tooltip: "ek-meters-fail",
          },
          {
            condition: "devicePlumCommOk",
            color: "green",
            tooltip: "plum-meters-ok",
          },
          {
            condition: "devicePlumCommError",
            color: "red",
            tooltip: "plum-meters-fail",
          },
          {
            condition: "deviceExternoCommOk",
            color: "green",
            tooltip: "no-lora-meters-ok",
          },
          {
            condition: "deviceExternoCommError",
            color: "red",
            tooltip: "no-lora-meters-fail",
          },
          {
            condition: "deviceSigfoxCommOk",
            color: "green",
            tooltip: "sigfox-ok",
          },
          {
            condition: "deviceSigfoxCommError",
            color: "red",
            tooltip: "sigfox-no-comm",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de gráfica de contadores
      meterGraph: {
        attribute: "className",
        options: [
          {
            condition: "deviceCommError",
            color: "red",
            tooltip: "devices-not-communicate",
          },
          {
            condition: "deviceCommOk",
            color: "green",
            tooltip: "devices-active",
          },
          {
            condition: "deviceNoAssignated",
            color: "purple",
            tooltip: "devices-not-assigned",
          },
          {
            condition: "deviceNoLoraNoCom",
            color: "gray",
            tooltip: "no-lora-no-com-meters",
          },
          { condition: "gray", color: "gray", tooltip: "no-main-gateway" },
          {
            condition: "deviceNoLora",
            color: "turquoise",
            tooltip: "no-lora-meter",
          },
          { condition: "deviceMbus", color: "blue", tooltip: "MBUS" },
          {
            condition: "deviceCommunicateMbus",
            color: "cadetblue",
            tooltip: "mbus-communicate",
          },
          {
            condition: "deviceNbiot",
            color: "orange",
            tooltip: "NBIoT",
          },
          { condition: "deviceWavenis", color: "blue", tooltip: "Wavenis" },
          {
            condition: "deviceUneCommOk",
            color: "green",
            tooltip: "lw-une-meters-ok",
          },
          {
            condition: "deviceUneCommError",
            color: "red",
            tooltip: "lw-une-meters-fail",
          },
          {
            condition: "deviceEkCommOk",
            color: "green",
            tooltip: "ek-meters-ok",
          },
          {
            condition: "deviceEkCommError",
            color: "red",
            tooltip: "ek-meters-fail",
          },
          {
            condition: "devicePlumCommOk",
            color: "green",
            tooltip: "plum-meters-ok",
          },
          {
            condition: "devicePlumCommError",
            color: "red",
            tooltip: "plum-meters-fail",
          },
          {
            condition: "deviceExternoCommOk",
            color: "green",
            tooltip: "no-lora-meters-ok",
          },
          {
            condition: "deviceExternoCommError",
            color: "red",
            tooltip: "no-lora-meters-fail",
          },
          {
            condition: "deviceSigfoxCommOk",
            color: "green",
            tooltip: "sigfox-ok",
          },
          {
            condition: "deviceSigfoxCommError",
            color: "red",
            tooltip: "sigfox-no-comm",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de balance
      balanceDetail: {
        attribute: "className",
        options: [
          { condition: "deviceList", color: "turquoise", tooltip: "devices" },
          { condition: "deviceFather", color: "green", tooltip: "fathers" },
          { condition: "deviceChild", color: "blue", tooltip: "childs" },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de edición de balance
      balanceEdition: {
        attribute: "className",
        options: [
          { condition: "deviceList", color: "turquoise", tooltip: "devices" },
          { condition: "deviceFather", color: "green", tooltip: "fathers" },
          { condition: "deviceChild", color: "blue", tooltip: "childs" },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de listados de contadores
      meterList: {
        attribute: "className",
        options: [
          { condition: "deviceList", color: "turquoise", tooltip: "devices" },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de listados de contadores seleccionables
      meterAssignable: {
        attribute: "className",
        options: [
          {
            condition: "gatewayDetailAssignable",
            color: "orange",
            tooltip: "devices",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
          {
            condition: "gatewayDetailRedundant",
            color: "turquoise",
            tooltip: "redundant-meter",
          },
        ],
      },
      // Mapa de listado de alarmas
      alarmsMeterList: {
        attribute: "className",
        options: [
          { condition: "deviceList", color: "yellow", tooltip: "devices" },
          { condition: "newAlarm", color: "red-alarm", tooltip: "devices" },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de sospechas
      warnings: {
        attribute: "className",
        options: [
          {
            condition: "deviceList",
            color: "turquoise",
            tooltip: "devices",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de detalle de gateway
      gatewayDetail: {
        attribute: "className",
        options: [
          {
            condition: "gatewayDetailMain",
            color: "green",
            tooltip: "main-meter",
          },
          {
            condition: "gatewayDetailRedundant",
            color: "turquoise",
            tooltip: "redundant-meter",
          },
          {
            condition: "gatewayDetailAssignable",
            color: "orange",
            tooltip: "devices",
          },
        ],
      },
      // Mapa de detalle de concentrador MBUS
      mbusConcentratorDetail: {
        attribute: "className",
        options: [
          { condition: "deviceMbus", color: "blue", tooltip: "MBUS" },
          {
            condition: "deviceCommunicateMbus",
            color: "cadetblue",
            tooltip: "permanent-mbus-meters",
          },
          {
            condition: "deviceMainNoCommunicateMbus",
            color: "gray",
            tooltip: "devices-main-not-communicate",
          },
          {
            condition: "deviceCommError",
            color: "red",
            tooltip: "devices-not-communicate",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de sustituciones
      substitutions: {
        attribute: "className",
        options: [
          {
            condition: "replaced",
            color: "green",
            tooltip: "substitution-replaced",
          },
          {
            condition: "pending",
            color: "blue",
            tooltip: "substitution-pending",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Mapa de dispositivos desconocidos
      unknownDevices: {
        attribute: "className",
        options: [
          {
            condition: "unknown",
            color: "gray",
            tooltip: "unknown-devices",
          },
          {
            condition: "mbusConcentrator",
            color: "green",
            tooltip: "concentrators",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
      // Detección de fugas
      leakDetection: {
        attribute: "className",
        options: [
          {
            condition: "leakSensor",
            color: "turquoise",
            tooltip: "sensors",
          },
          {
            condition: "leakFilterOver",
            color: "red",
            tooltip: "sensors-filter-over",
          },
          {
            condition: "leakFilterBelow",
            color: "purple",
            tooltip: "sensors-filter-below",
          },
          {
            condition: "leakFilterNoData",
            color: "gray",
            tooltip: "sensors-filter-no-data",
          },
          { condition: "deviceSelected", color: "yellow", tooltip: "selected" },
        ],
      },
    },
  },

  // ANCHOR Variables de los marcadores de contadores
  markerVariables: {
    // Mapa estándar
    OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommOk greenIcon",
      markerTitle: "actives",
      markerZindex: 580,
      iconColor: "rgba(67, 160, 71, 1)",
    },
    NO_ASIGNADO: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceNoAssignated purpleIcon",
      markerTitle: "no-assigned",
      markerZindex: 550,
      iconColor: "rgba(171, 71, 188, 1)",
    },
    NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommError redIcon",
      markerTitle: "without-communication",
      markerZindex: 560,
      iconColor: "rgba(211, 47, 47, 1)",
    },
    CONCENTRATOR_OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "deviceCommOk greenIcon",
      markerTitle: "actives",
      markerZindex: 580,
      iconColor: "rgba(67, 160, 71, 1)",
    },
    CONCENTRATOR_NO_ASIGNADO: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "deviceNoAssignated purpleIcon",
      markerTitle: "no-assigned",
      markerZindex: 550,
      iconColor: "rgba(171, 71, 188, 1)",
    },
    CONCENTRATOR_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "deviceCommError redIcon",
      markerTitle: "without-communication",
      markerZindex: 560,
      iconColor: "rgba(211, 47, 47, 1)",
    },
    NO_LORA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceNoLora turquoiseIcon",
      markerTitle: "no-lora-meter",
      markerZindex: 580,
      iconColor: "rgb(42, 192, 202)",
    },
    NO_LORA_NO_COM: {
      iconHtml:
        "<i class='fa fa-circle fa-lg'></i><i class='fas fa-times fa-lg'></i>",
      iconClasses: "deviceNoLoraNoCom grayIcon",
      markerTitle: "no-lora-no-com-meter",
      markerZindex: 590,
      iconColor: "rgba(158, 158, 158, 1)",
    },
    MBUS: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceMbus blueIcon",
      markerTitle: "MBUS",
      markerZindex: 570,
      iconColor: "rgba(25, 118, 210, 1)",
    },
    MBUS_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceCommunicateMbus cadetblueIcon",
      markerTitle: "mbus-communicate",
      markerZindex: 580,
      iconColor: "rgba(95, 158, 160, 1)",
    },
    MBUS_MAIN_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceMainNoCommunicateMbus grayIcon",
      markerTitle: "devices-main-not-communicate",
      markerZindex: 580,
      iconColor: "gray",
    },
    WAVENIS: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceWavenis blueIcon",
      markerTitle: "Wavenis",
      markerZindex: 570,
      iconColor: "rgba(25, 118, 210, 1)",
    },
    UNE_OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceUneCommOk greenIcon",
      markerTitle: "lw-une-meter-ok",
      markerZindex: 570,
      iconColor: "rgba(67, 160, 71, 1)",
    },
    UNE_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceUneCommError redIcon",
      markerTitle: "lw-une-meter-fail",
      markerZindex: 540,
      iconColor: "rgba(211, 47, 47, 1)",
    },
    UNE: {
      markerZindex: 570,
    },
    EK_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceEkCommOk greenIcon",
      markerTitle: "ek-meter-ok",
      markerZindex: 580,
    },
    EK_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceEkCommError redIcon",
      markerTitle: "ek-meter-fail",
      markerZindex: 560,
    },
    EXTERNO_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceExternoCommOk greenIcon",
      markerTitle: "no-lora-meter-ok",
      markerZindex: 580,
    },
    EXTERNO_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceExternoCommError redIcon",
      markerTitle: "no-lora-meter-fail",
      markerZindex: 560,
    },
    ERM_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceErmCommOk greenIcon",
      markerTitle: "erm-meter-ok",
      markerZindex: 580,
    },
    ERM_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceErmCommError redIcon",
      markerTitle: "erm-meter-fail",
      markerZindex: 560,
    },
    PLUM_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "devicePlumCommOk greenIcon",
      markerTitle: "plum-meter-ok",
      markerZindex: 580,
    },
    PLUM_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "devicePlumCommError redIcon",
      markerTitle: "plum-meter-fail",
      markerZindex: 560,
    },
    // Test de cobertura
    COVERAGE_OK: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "coverageOk greenIcon",
      markerTitle: "test-ok",
      markerZindex: 570,
      tooltipTitle: "test-ok",
      iconColor: "rgba(67, 160, 71, 1)",
    },
    COVERAGE_ERROR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "coverageError redIcon",
      markerTitle: "test-fail",
      markerZindex: 580,
      tooltipTitle: "test-fail",
      iconColor: "rgba(211, 47, 47, 1)",
    },
    GATEWAY: {
      okIconUrl: "assets/img/arson_marker.svg",
      errorIconUrl: "assets/img/arson_marker_error.svg",
      nodeviceIconUrl: "assets/img/arson_marker_nodevice.svg",
      maintenanceIconUrl: "assets/img/arson_marker_maintenance.svg",
      grayIconUrl: "assets/img/arson_marker_gray.svg",
      locationUrl: "<i class='fas fa-broadcast-tower fa-2x'></i>",
      markerZindex: 1000,
    },
    // Mapa de telecontrol
    CONTROL_VALVE_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "controlValve blueIcon",
      markerTitle: "meters-close-valve",
      markerZindex: 520,
      tooltipTitle: "meters-close-valve",
    },
    CONTROL_ALERT_ASSIGNED: {
      iconHtml: "<i class='fas fa-exclamation-circle fa-2x'></i>",
      iconClasses: "controlAlertAssigned",
      markerTitle: "alert-assigned",
      markerZindex: 560,
      tooltipTitle: "alert-assigned",
    },
    CONTROL_ALERT_OPEN: {
      iconHtml: "<i class='fas fa-exclamation-circle fa-2x'></i>",
      iconClasses: "controlAlertOpen redIcon bounce",
      markerTitle: "alert-open",
      markerZindex: 580,
      tooltipTitle: "alert-open",
    },
    CONTROL_SENSOR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "controlSensor purpleIcon",
      markerTitle: "sensor",
      markerZindex: 540,
      tooltipTitle: "sensor",
    },
    // Mapa de detalle de contador
    METER_DETAIL_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "devideDetail blueIcon",
      markerTitle: "meter",
      markerZindex: 580,
      tooltipTitle: "meter",
    },
    // Mapa de detalle de CUPS
    CUPS_DETAIL_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "cupsDetail blueIcon",
      markerTitle: "device",
      markerZindex: 580,
      tooltipTitle: "device",
    },
    // Mapa de cambio de localización
    METER_LOCATION_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceLocation blueIcon",
      markerTitle: "meter",
      markerZindex: 580,
      tooltipTitle: "meter",
    },
    // Genéricos
    SELECTED: {
      iconHtml: "<i class='fas fa-circle iconSelected'></i>",
      iconClasses: "deviceSelected yellowIcon",
      markerTitle: "selected",
      markerZindex: 590,
      tooltipTitle: "selected",
      iconColor: "rgba(255, 235, 59, 1)",
    },
    ERROR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceError redIcon",
      markerTitle: "without-communication",
      markerZindex: 580,
      tooltipTitle: "without-communication",
      iconColor: "rgba(211, 47, 47, 1)",
    },
    // Mapa de balance
    FATHER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceFather greenIcon",
      markerTitle: "father",
      markerZindex: 580,
      tooltipTitle: "father",
      iconColor: "rgba(67, 160, 71, 1)",
    },
    CHILD: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceChild blueIcon",
      markerTitle: "child",
      markerZindex: 570,
      tooltipTitle: "child",
      iconColor: "rgba(25, 118, 210, 1)",
    },
    // Mapa de listados de contadores
    METER_LIST: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceList turquoiseIcon",
      markerTitle: "device",
      markerZindex: 560,
      tooltipTitle: "device",
      iconColor: "rgb(42, 192, 202)",
    },
    // Mapa de listado de alarmas
    ALARMS_METER_LIST: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceList yellowIcon",
      markerTitle: "device",
      markerZindex: 570,
      tooltipTitle: "device",
      iconColor: "rgba(255, 235, 59, 1)",
    },
    ALARMS_METER_LIST_NEW: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "newAlarm redIcon",
      markerTitle: "device",
      markerZindex: 580,
      tooltipTitle: "device",
      iconColor: "rgba(211, 47, 47, 1)",
    },
    // Mapa de detalle de gateway
    GATEWAY_DETAIL_MAIN_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "gatewayDetailMain greenIcon",
      markerTitle: "main-meter",
      markerZindex: 580,
      tooltipTitle: "main-meter",
      iconColor: "rgba(67, 160, 71, 1)",
    },
    GATEWAY_DETAIL_REDUNDANT_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "gatewayDetailRedundant turquoiseIcon",
      markerTitle: "redundant-meter",
      markerZindex: 560,
      tooltipTitle: "redundant-meter",
      iconColor: "rgb(42, 192, 202)",
    },
    GATEWAY_DETAIL_ASSIGNABLE_METER: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconHtmlUne:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>UNE</span></i>",
      iconHtmlMbus:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>MBUS</span></i>",
      iconClasses: "gatewayDetailAssignable orangeIcon",
      markerTitle: "assignable-meter",
      markerZindex: 570,
      tooltipTitle: "assignable-meter",
      iconColor: "#854000",
    },
    // Mapa de sustituciones
    SUBSTITUTIONS_REPLACED: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "replaced greenIcon",
      markerTitle: "substitution-replaced",
      markerZindex: 580,
      tooltipTitle: "substitution-replaced",
      iconColor: "rgba(67, 160, 71, 1)",
    },
    SUBSTITUTIONS_PENDING: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "pending blueIcon",
      markerTitle: "substitution-pending",
      markerZindex: 540,
      tooltipTitle: "substitution-pending",
      iconColor: "rgba(25, 118, 210, 1)",
    },
    // Localizaciones
    LOCATION: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "pending blueIcon",
      markerTitle: "substitution-pending",
      markerZindex: 1000,
      tooltipTitle: "substitution-pending",
    },
    // Dispositivos desconocidos
    UNKNOWN_DEVICE: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "unknown grayIcon",
      markerTitle: "unknown-device",
      markerZindex: 540,
      tooltipTitle: "unknown-device",
      iconColor: "rgba(158, 158, 158, 1)",
    },
    // Concentrador MBUS
    MBUS_CONCENTRATOR: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "mbusConcentrator greenIcon",
      markerTitle: "concentrator-mbus",
      markerZindex: 580,
      tooltipTitle: "concentrator-mbus",
      iconColor: "rgba(67, 160, 71, 1)",
    },
    // Sensor de fugas
    LEAK_SENSOR: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "leakSensor turquoiseIcon",
      markerTitle: "sensor",
      markerZindex: 560,
      tooltipTitle: "sensor",
    },
    // Sensor de fugas: Por encima de filtro
    LEAK_SENSOR_OVER: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "leakFilterOver redIcon",
      markerTitle: "sensor",
      markerZindex: 560,
      tooltipTitle: "sensor",
    },
    // Sensor de fugas: Por debajo de filtro
    LEAK_SENSOR_BELOW: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "leakFilterBelow purpleIcon",
      markerTitle: "sensor",
      markerZindex: 560,
      tooltipTitle: "sensor",
    },
    // Sensor de fugas: Sin datos de filtro
    LEAK_SENSOR_NO_DATA: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "leakFilterNoData grayIcon",
      markerTitle: "sensor",
      markerZindex: 560,
      tooltipTitle: "sensor",
    },
    // Alarma de sensor
    SENSOR_WARNING: {
      iconHtml: "<i class='fas fa-exclamation-triangle fa-lg'></i>",
      iconClasses: "leakSensor warningSensor yellowIcon",
      markerTitle: "sensor",
      markerZindex: 580,
      tooltipTitle: "sensor",
    },
    // Alarma de sensor
    LEAK_SENSOR_SELECTED: {
      iconHtml: "<i class='fas fa-play fa-lg rotated90'></i>",
      iconClasses: "deviceSelected yellowIcon",
      markerTitle: "sensor",
      markerZindex: 580,
      tooltipTitle: "sensor",
    },
    // Alarma de sensor
    SENSOR_FLOW: {
      iconUrl: "assets/img/pipe.png",
      iconClasses: "flowSensor",
      markerTitle: "sensor",
      markerZindex: 580,
      tooltipTitle: "sensor",
    },
    // NBIOT
    NBIOT: {
      iconHtml: "<i class='fas fa-circle fa-lg'></i>",
      iconClasses: "deviceNbiot orangeIcon",
      markerTitle: "NBIOT",
      markerZindex: 570,
      iconColor: "#854000",
    },
    // TXN
    TXN: {
      iconHtml:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>TXN</span></i>",
      iconHtmlN1:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>N1</span></i>",
      iconHtmlN3:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>N3</span></i>",
      iconHtmlN4:
        "<i class='fas fa-tint fa-2x'><span class='concentrator-icon'>N4</span></i>",
      iconClasses: "deviceTxn grayIcon",
      markerTitle: "TXN",
      markerZindex: 570,
      iconColor: "gray",
    },
    // SIGFOX
    SIGFOX_OK: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceSigfoxCommOk greenIcon",
      markerTitle: "sigfox-ok",
      markerZindex: 580,
      iconColor: "rgba(67, 160, 71, 1)",
    },
    SIGFOX_NO_COMUNICA: {
      iconHtml: "<i class='fas fa-star fa-lg'></i>",
      iconClasses: "deviceSigfoxCommError redIcon",
      markerTitle: "sigfox-no-comm",
      markerZindex: 560,
      iconColor: "rgba(211, 47, 47, 1)",
    },
  },
};
