<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading global-search-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <div class="panel-nav">
          <!-- Título -->
          <h5>{{ "users" | translate }}</h5>
        </div>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div *ngIf="tableData" class="user-filter">
          <!-- Selección de filtro -->
          <!-- Filtro de perfil -->
          <div class="user-filter-block">
            <div class="user-filter-profile">
              <i class="fas fa-id-badge"></i>
              <span>{{ "profile" | translate }}</span>
            </div>
            <app-material-select
              *ngIf="profileSelect"
              class="user-profile-select"
              [noSelection]="true"
              [title]="'profile' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [data]="profileSelect"
              (selectedOption)="selectedProfile = $event?.value; updateFilter()"
            ></app-material-select>
          </div>

          <div class="user-filter-block">
            <div class="user-filter-selector">
              <!-- Cliente -->
              <div class="user-filter-option">
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="
                    filterSelected = 'client'; updateSelectedFilter(clientList)
                  "
                  [ngClass]="{ inactive: filterSelected != 'client' }"
                >
                  <i class="fas fa-user-tie"></i>
                  <span>{{ "client" | translate }}</span>
                </button>
              </div>
              <!-- Entidad -->
              <div class="user-filter-option">
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="
                    filterSelected = 'entity'; updateSelectedFilter(entityList)
                  "
                  [ngClass]="{ inactive: filterSelected != 'entity' }"
                >
                  <i class="fas fa-sitemap"></i>
                  <span>{{ "entity" | translate }}</span>
                </button>
              </div>
              <!-- Agrupación -->
              <div class="user-filter-option">
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="
                    filterSelected = 'agrupation';
                    updateSelectedFilter(agrupationList)
                  "
                  [ngClass]="{ inactive: filterSelected != 'agrupation' }"
                >
                  <i class="fas fa-map"></i>
                  <span>{{ "groups" | translate }}</span>
                </button>
              </div>
            </div>
            <!-- Filtro -->
            <app-material-select
              *ngIf="filterSelect"
              class="user-filter-select"
              [noSelection]="true"
              [fullWidth]="true"
              [title]="
                filterSelected != 'agrupation'
                  ? (filterSelected | translate)
                  : ('groups' | translate)
              "
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [data]="filterSelect"
              (selectedOption)="selectedItem = $event?.value; updateFilter()"
            ></app-material-select>
          </div>
        </div>

        <!-- Tabla de usuarios -->
        <div>
          <app-table-controller
            [tableId]="'globalSearchUser'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [maxReg]="tableMaxReg"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [specialFilter]="true"
            [data]="tableData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
