export const LAST_PATCH_NOTES_USER = {
  es: `### Versión 5.46 (12/12/2024)

* Gateways -> Detalle Gateway:
  - Solucionado el error que impedía cargar los datos al realizar una búsqueda por fecha en la gráfica.

* Mantenimiento -> Global:
  - Eliminada la opción "Limpiar registros" del menú.

* Telecontrol -> Telemedida:
  - Añadidos los campos de último voltaje y último periodo de vida de la batería en la tabla de telemedida.
  - Añadido un nuevo gráfico de voltaje en la tabla de telemedida.
  - Incluido el tiempo de desfase con el servidor en la tabla de telemedida.
  - Añadida la opción de búsqueda de errores por fecha en la tabla de errores.
  - Añadida la opción para seleccionar la hora al descargar el fichero de facturación.
  - Solucionado el error que generaba un fallo al solicitar un fichero con la fecha por defecto.

* Alarmas -> Listado:
  - Establecido un rango por defecto de una semana al solicitar todas las alarmas.
  - Solucionado el error que impedía cargar las alarmas al cambiar de "todas las alarmas" a "alarmas activas" desde el desplegable.

* Principal -> Dispositivos con alarmas activas:
  - Solucionado el error que mostraba todas las alarmas inicialmente al hacer clic sobre la tarjeta de dispositivos con alarmas activas.

* Ficheros de salida -> Descarga de fichero:
  - Solucionado el error que impedía la descarga de archivos con valores cercanos.

* Gráficas -> Exportar:
  - Solucionado el error que descargaba el archivo sin extensión en algunos formatos al exportar desde la gráfica.

* Mantenimiento -> Concentradores:
  - Añadido el porcentaje de batería en la tabla de concentradores MBUS.
  - Añadida pantalla con la tabla de concentradores por entidad al pulsar sobre la tarjeta de concentradores en red.
  - Acceso directo añadido al menú lateral.

* Dispositivo -> Listado -> Listado de disp. ignorados:
  - Añadido nuevo listado de dispositivos ignorados.

* Dispositivo -> Detalle:
  - Añadida opción para poder enviar a la lista de ignorados un contador.

* Telecontrol -> Telemedida:
  - Añadida acción a la tabla para probar la comunicación manual y mostrar un toast con el resultado de la prueba.

* Dispositivos -> Importaciones -> Importar imágenes:
  - Añadida tabla para mostrar los errores de importación de imágenes.
`,
  en: `### Version 5.46 (12/12/2024)

* Gateways -> Gateway Detail:
  - Fixed the issue that prevented loading data when performing a date-based search in the chart.

* Maintenance -> Global:
  - Removed the "Clear records" option from the menu.

* Telecontrol -> Telemetry:
  - Added the fields for the last voltage and the last battery life period to the telemetry table.
  - Added a new voltage chart to the telemetry table.
  - Included the time offset with the server in the telemetry table.
  - Added the option to search errors by date in the error table.
  - Added the option to select the time when downloading the billing file.
  - Fixed the error that caused a failure when requesting a file with the default date.

* Alarms -> List:
  - Set the default range to one week when requesting all alarms.
  - Fixed the error that prevented alarms from loading when switching from "all alarms" to "active alarms" from the dropdown.

* Main -> Devices with Active Alarms:
  - Fixed the issue that showed all alarms initially when clicking on the "devices with active alarms" card.

* Output Files -> File Download:
  - Fixed the error that prevented file downloads for values close to each other.

* Graphs -> Export:
  - Fixed the error that downloaded files without an extension in some formats when exporting from the graph.

* Maintenance -> Concentrators:
  - Added the battery percentage in the MBUS concentrators table.
  - Added a screen with the concentrators table by entity when clicking on the concentrator card in the network.
  - Direct access added to the sidebar menu.

* Device -> List -> Ignored Devices List:
  - Added a new list of ignored devices.

* Device -> Detail:
  - Added option to send a counter to the ignored list.

* Telecontrol -> Telemetry:
  - Added action to the table to manually test communication and show a toast with the result.

* Devices -> Imports -> Import Images:
  - Added table to show import errors for images.
`,
  "es-ca": null,
  fr: null,
  it: null,
  el: null,
};
