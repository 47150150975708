import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-401",
  templateUrl: "./401.component.html",
  styleUrls: ["./401.component.scss"],
})
export class AccesDeniedComponent implements OnInit {
  constructor(private location: Location, private router: Router) {}

  ngOnInit() {}

  goBack() {
    // Guardamos la URL actual antes de ir hacia atrás
    const currentUrl = this.location.path();

    // Realizamos la acción de ir hacia atrás
    this.location.back();

    // Esperamos un pequeño intervalo para verificar la URL después de volver
    setTimeout(() => {
      const afterUrl = this.location.path();

      // Si la URL antes y después son iguales (es decir, no ha cambiado de ruta), redirige a /principal
      if (currentUrl === afterUrl) {
        this.router.navigate(['/principal']);
      }
    }, 100); // El tiempo de espera puede ajustarse según el comportamiento deseado
  }
}
