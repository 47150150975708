<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b
            >{{ "gateways-management" | translate }}:
            {{ currentEntity?.entity }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <h4>
          <b>{{ "gateways-state" | translate }}</b>
        </h4>
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'managementGateways'"
          [columns]="columnsState"
          [rowNumbers]="rowNumbersState"
          [maxReg]="tableMaxRegState"
          (tableAction)="tableActionsState($event.action.name, $event.element)"
          [specialFilter]="true"
          [data]="gatewayStateData"
        >
        </app-table-controller>

        <hr />

        <h4>
          <b>{{ "gateways-registry" | translate }}</b>
        </h4>

        <div class="management-gateway-groups">
          <app-material-select
            [title]="'group' | translate"
            [bindValue]="'id'"
            [bindLabel]="'value'"
            [noSelection]="true"
            [data]="groupSelect"
            (selectedOption)="groupSelected = $event?.id"
          ></app-material-select>
        </div>

        <!-- Tabla de mantenimiento -->
        <app-table-controller
          [tableId]="'managementAgrupation'"
          [columns]="columnsMaintenance"
          [rowNumbers]="rowNumbersMaintenance"
          [maxReg]="tableMaxRegMaintenance"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getGatewayMaintenanceData($event.from, $event.to)"
          (extraTableAction)="
            extraTableActionsMaintenance(
              $event.action.name,
              $event.parentElement,
              $event.childElement
            )
          "
          [data]="gatewayMaintenanceData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
