import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

// Cesium
import { AngularCesiumModule } from "angular-cesium";
import { CesiumMapControllerComponent } from "./cesium-map-controller/cesium-map-controller.component";
import { CesiumMapComponent } from "./cesium-map/cesium-map.component";
import { CesiumMapTooltipComponent } from "./cesium-map/cesium-map-tooltip/cesium-map-tooltip.component";
import { CeisumKeyboardControlComponent } from "./cesium-map/ceisum-keyboard-control/ceisum-keyboard-control.component";
import { CesiumMapContainerComponent } from "./cesium-map-container/cesium-map-container.component";

// Map
import { MapModule } from "../map-module/map.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,

    // Cesium
    AngularCesiumModule,

    // Map
    MapModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    CesiumMapControllerComponent,
    CesiumMapComponent,
    CesiumMapTooltipComponent,
    CeisumKeyboardControlComponent,
    CesiumMapContainerComponent,
  ],
  exports: [CesiumMapContainerComponent],
})
export class CesiumMapModule {}
