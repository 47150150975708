// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AssociationControllerService } from "../../../../../services/server/AssociationController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterService } from "../../../devices/meter/MeterService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableCupsFilters,
  TableGlobalAction,
} from "../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import {
  SectorCups,
  AssociationEditionData,
  SectorDevice,
} from "../../DataManagementInterface.type";
// Componentes
import { TableControllerComponent } from "../../../../../modules/table-module/table-controller/table-controller.component";
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";

@Component({
  selector: "app-newAssociation",
  templateUrl: "./data-management-association-new.component.html",
  styleUrls: ["./data-management-association-new.component.scss"],
})
export class NewAsociationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Tabla
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  exportFileName: string;
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  maxReg: number = 100;
  tableData: SectorCups[];
  tableDataOriginal: SectorCups[];
  tableSelectedData: SectorCups[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  filters: TableCupsFilters[];
  showFilters: boolean = false;
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "show-consumption-evolution",
      icon: "fas fa-faucet",
      selectionRequired: true,
      help: "help-table-evolution",
    },
  ];

  // Nueva asociación
  associationName: string;
  associationDescription: string;

  // Editar asociación
  historyData: any = history.state.data;
  associationId: number;

  // Guardado
  saveIcon = "fas fa-save";
  saveTitle = this.translate.instant("save");

  // Mapa
  ignoreTableUpdate: boolean;
  mapData: SectorDevice[];
  mapType: string = "meterList";
  activateAllLayers: boolean = true;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private location: Location,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.associationId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    if (this.historyData?.name) {
      this.associationName = this.historyData.name;
      this.associationDescription = this.historyData.description;
    }
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    let httpRequest: Observable<object> = this.historyData?.name
      ? this.AssociationController.show(this.associationId)
      : this.AssociationController.deviceList(this.currentAgrupation.id);

    httpRequest.subscribe((response) => {
      if (response["code"] == 0) {
        let tableData: SectorCups[] = response["body"]["sectorDeviceList"];
        tableData.map((cups: SectorCups) => {
          let nroSerieListExport = [];
          cups.nroSerieList = `<div class="table-meter-list">`;
          cups.meters.forEach((meter) => {
            cups.nroSerieList += `<span>` + meter.nroSerie + `</span>`;
            nroSerieListExport.push(meter.nroSerie);
          });
          cups.nroSerieList += `</div>`;
          cups.nroSerieListExport = nroSerieListExport;
          cups.claveParsed =
            cups.meters.length > 1 || cups.clave != cups.meters[0].nroSerie
              ? cups.clave
              : null;
          cups.cupsLink = cups.claveParsed ? "/cups/detalle/" + cups.id : null;
        });

        // CUPS
        let entityCupsData = this.MeterService.getEntityCupsData();
        this.tableCupsColumns = entityCupsData.entityCupsColumns;
        this.cups = entityCupsData.entityCups;
        this.entityNroSerie = entityCupsData.entityNroSerie;

        if (this.historyData?.name) {
          this.tableSelectedData = [];
          tableData.forEach((cups: SectorCups) => {
            cups.selected = cups.isSelected;
            if (cups.selected) {
              this.tableSelectedData.push(cups);
            }
          });
        }

        if (this.historyData && !this.historyData?.name) {
          this.tableSelectedData = [];
          tableData.forEach((cups: SectorCups) => {
            cups.selected = cups.meters.some((meter) =>
              this.historyData.includes(meter.meterId)
            );
            if (cups.selected) {
              this.tableSelectedData.push(cups);
            }
          });
        }

        this.setColumns();
        this.tableData = tableData;
        this.tableDataOriginal = [...this.tableData];
        this.parseTableDataForMap();
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: this.cups ? this.cups.name : "CUPS",
        data: "claveParsed",
        search: "claveParsed",
        sort: "claveParsed",
        visible: this.cups ? true : null,
        link: "cupsLink",
      },
      {
        title: this.entityNroSerie?.label
          ? this.entityNroSerie.label
          : "serial-number",
        data: "nroSerieList",
        search: "nroSerieList",
        sort: "nroSerieList",
        html: true,
        export: "nroSerieListExport",
        visible: true,
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
    this.getFilters();
  }

  // Obtención de los filtros del cups
  getFilters(): void {
    let filters: TableCupsFilters[] = [];
    this.columns
      .filter(
        (column: TableActionColumn | TableSelectColumn | TableDataColumn) =>
          column.title != "action" &&
          column.title != "select" &&
          column.title != "selectBis"
      )
      .forEach((column: TableDataColumn) => {
        filters.push({
          name: column.title,
          data: column.data,
          value: null,
        });
      });
    this.filters = filters;
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "show-consumption-evolution":
        this.router.navigate(["/analisis-datos/evolucion-consumo"], {
          state: {
            data: this.tableSelectedData.map((device: SectorCups) => {
              return device.id;
            }),
          },
        });
        break;
      default:
        break;
    }
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((meter: SectorCups) => {
          return meter.id;
        }),
        to: null,
      },
    });
  }

  // Creación de nueva asociación
  newAssociation(): void {
    if (!this.tableSelectedData || this.tableSelectedData?.length == 0) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-selected")
      );
    } else {
      let data: AssociationEditionData = {
        name: this.associationName,
        description: this.associationDescription,
        cmList: this.tableSelectedData.map((cups: SectorCups) => {
          return cups.id;
        }),
      };

      this.AssociationController.saveAssociation(
        this.currentAgrupation.id,
        data
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("association-created")
          );
          this.router.navigate(["/analisis-datos/asociaciones"]);
        } else if (response["code"] == 2302) {
          this.ToastService.fireToast(
            "error",
            this.translate.instant("association-name")
          );
        }
      });
    }
  }

  // Edición de asociación
  editAssociation(): void {
    if (!this.tableSelectedData || this.tableSelectedData?.length == 0) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("must-selected")
      );
    } else {
      let data: AssociationEditionData = {
        name: this.associationName,
        description: this.associationDescription,
        cmList: this.tableSelectedData.map((cups: SectorCups) => {
          return cups.id;
        }),
        id: this.associationId,
      };

      this.AssociationController.edit(
        this.currentAgrupation.id,
        data
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("association-edited")
          );
          this.location.back();
        }
      });
    }
  }

  // Actualización de selección en tabla por mapa
  selectTableByMap(selectedData: SectorDevice[]): void {
    this.ignoreTableUpdate = true;
    this.mapController?.mapComponent?.deleteDrawedPolygon();
    this.tableData.map((cups) => {
      let updatedCups = selectedData.find(
        (selectedMeter) => selectedMeter.claveMaestraId == cups.id
      );
      if (updatedCups) {
        cups.selected = !cups.selected;
      }
    });
    this.tableData = [...this.tableData];
  }

  // Actualización de selección en mapa por tabla
  selectMapByTable(selectedCups: SectorCups[]): void {
    this.tableSelectedData = selectedCups;
    if (this.ignoreTableUpdate) {
      this.ignoreTableUpdate = false;
    } else {
      this.parseTableDataForMap();
    }
  }

  // Conversión de los datos de la tabla para el mapa
  parseTableDataForMap(): void {
    let newMapData = [];
    this.tableData.forEach((cups) => {
      cups.meters.forEach((meter) => {
        newMapData.push({
          claveMaestraId: cups.id,
          claveMaestraKey: cups.claveParsed ? cups.clave : null,
          id: meter.meterId,
          nroSerie: meter.nroSerie,
          metrologyType: meter.metrologyType,
          latitude: meter.latitude,
          longitude: meter.longitude,
          selected: cups.selected,
        });
      });
    });
    this.mapData = newMapData;
    this.mapController.activateAllLayers =
      !this.mapController.activateAllLayers;
  }
}
