import { Injectable, Inject, EventEmitter } from "@angular/core";
import { THEMES, ACTIVE_THEME, Theme } from "./symbols";
import { BrowserStorageLocalService } from "../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { SessionDataService } from "../services/shared/SessionDataService.service";
import { UserConfig } from "../interfaces/UserGlobalInterface.type";

@Injectable()
export class ThemeService {
  themeChange = new EventEmitter<Theme>();

  constructor(
    @Inject(THEMES) public themes: Theme[],
    @Inject(ACTIVE_THEME) public theme: string,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private SessionDataService: SessionDataService
  ) {}

  getTheme(name: string) {
    const THEME = this.themes.find((t) => t.name === name);
    if (!THEME) {
      throw new Error(`Theme not found: '${name}'`);
    }
    return THEME;
  }

  getActiveTheme() {
    return this.getTheme(this.theme);
  }

  getProperty(propName: string) {
    return this.getActiveTheme().properties[propName];
  }

  setTheme(name: string) {
    this.theme = name;
    this.themeChange.emit(this.getActiveTheme());
  }

  registerTheme(theme: Theme) {
    this.themes.push(theme);
  }

  updateTheme(name: string, properties: { [key: string]: string }) {
    const THEME = this.getTheme(name);
    THEME.properties = {
      ...THEME.properties,
      ...properties,
    };

    if (name === this.theme) {
      this.themeChange.emit(THEME);
    }
  }

  // Guardado de la configuración de estilos
  saveThemeConfig(theme: string): void {
    let currentUser = this.SessionDataService.getCurrentUser();
    let configData = this.BrowserStorageLocalService.getJsonValue("config");
    let userConfig: any;

    // Comprobación de si existe perfil local de usuario
    if (configData) {
      userConfig = configData.find((config: any) => config.user == currentUser);
    } else {
      configData = [];
    }
    if (!userConfig) {
      userConfig = new UserConfig(currentUser);
    }

    // Actualización de los estilos asociados al usuario
    userConfig.themeConf = theme;
    // Guardado en local
    this.BrowserStorageLocalService.setJsonValue("config", configData);
  }

  // Carga de la configuración de estilos
  loadThemeConfig(user: string): void {
    let configData = this.BrowserStorageLocalService.getJsonValue("config");
    let theme = configData?.find(
      (config: any) => config.user == user
    )?.themeConf;
    if (theme) {
      this.setTheme(theme);
    }
    // } else if (this.getActiveTheme()?.name != "default") {
    //   this.setTheme("default");
    // }
  }
}
