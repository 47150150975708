// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityAppEui } from "../GatewayInterface.type";
// Componentes
import { GatewaysAppeuiDialogComponent } from "./gateways-appeui-dialog/gateways-appeui-dialog.component";

@Component({
  selector: "app-appeui",
  templateUrl: "./gateways-appeui.component.html",
  styleUrls: ["./gateways-appeui.component.scss"],
})
export class APPEUIComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Gateway
  gatewayId: number;
  unidadVenta: string;

  // Table
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  exportFileName: string =
    this.translate.instant("appeui-export") + " " + moment().format("ll");
  tableData: object[];
  tableSelectedData: object[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "new",
      icon: "fa fa-plus",
      help: "help-table-appeui-new",
    },
    {
      title: "appeui-update-all",
      icon: "fas fa-sync-alt",
      help: "help-table-appeui-update",
    },
    {
      title: "remove-selected-data",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-appeui-remove-sel",
    },
    {
      title: "remove-all-table-date",
      icon: "fas fa-trash",
      class: "btn-red",
      help: "help-table-appeui-remove",
    },
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "App EUI",
      data: "appEui",
      search: "appEui",
      sort: "appEui",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "manufacturer",
      data: "manufacturerText",
      search: "manufacturerText",
      sort: "manufacturerText",
      visible: true,
    },
    {
      title: "device-type",
      data: "devTypeText",
      search: "devTypeText",
      sort: "devTypeText",
      visible: true,
    },
  ];

  // AppEui
  appEuiList: EntityAppEui[];
  appEuiSelected: number[];
  appEuiInput: string;
  saveAppEuiDisabled: boolean = true;
  dialog: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation && this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.getData();
    this.getAppEuis();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.GatewayController.getAppEui(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"].length > 0) {
          let tableData: EntityAppEui[] = response["body"];
          tableData.forEach((appEui: EntityAppEui) => {
            let manufacturer: { manufacturerText: string; deviceText: string } =
              this.ManufacturerService.getManufacturer(
                appEui?.fabricante?.toString(),
                appEui?.devType?.toString(),
                this.sessionLanguage
              );
            appEui.manufacturerText = manufacturer.manufacturerText;
            appEui.devTypeText = manufacturer.deviceText;
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    );
  }

  // Creación de nuevo AppEui
  newAppEui(): void {
    this.MaterialDialogService.openDialog(
      GatewaysAppeuiDialogComponent,
      this.appEuiList
    );
  }

  // Borrado de App EUI
  deleteAppEui(): void {
    let data: string[] = [
      ...this.tableSelectedData.map((appEui: EntityAppEui) => {
        return appEui.appEui;
      }),
    ];

    if (!(this.tableSelectedData?.length > 0)) {
      this.ToastService.fireToast("error", this.translate.instant("must-selected"));
    } else if (this.tableSelectedData?.length > 0) {
      this.ToastService
        .fireAlertWithOptions(
          "question",
          this.translate.instant("question-deleted-selected")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.GatewayController.deleteItems(
              this.currentEntity.id,
              data
            ).subscribe((response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("elements-deleted")
                );
                this.getData();
              }
            });
          }
        });
    }
  }

  // Borrado de todos los App EUI
  deleteAllAppEui(): void {
    this.ToastService
      .fireAlertWithOptions(
        "question",
        this.translate.instant("question-data-deleted")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.GatewayController.deleteAll(this.currentEntity.id).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("data-deleted")
                );
                this.getData();
              }
            }
          );
        }
      });
  }

  // Guardar todos los AppEui en todos los gateways
  saveAll(): void {
    this.GatewayController.getEntityGateways(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let entityGateways = response["body"];
          let data = [];
          entityGateways.forEach((gateway: any) => {
            this.appEuiList.forEach((appEui: any) => {
              data.push({
                id: gateway.id,
                unidadVenta: gateway.unidadVenta,
                appEui: appEui.appEui,
                manufacturer: appEui.manufacturerText,
                entity: this.currentEntity.entity,
                entityId: this.currentEntity.id,
                deletePreviousAppEui: true,
              });
            });
          });
          this.requestQueue.setTask("saveAppEui", data);
        }
      }
    );
  }

  // Obtención de los AppEui de la entidad
  getAppEuis(): void {
    this.GatewayController.appDropdown(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let appEuiList: EntityAppEui[] = response["body"];
          appEuiList.forEach((appEui: EntityAppEui, index) => {
            let manufacturer: { manufacturerText: string; deviceText: string } =
              this.ManufacturerService.getManufacturer(
                appEui?.fabricante?.toString(),
                "0",
                this.sessionLanguage
              );
            appEui.manufacturerText = manufacturer.manufacturerText;
            appEui.id = index;
          });
          this.appEuiList = appEuiList.filter(
            (appEui: EntityAppEui) => appEui.appEui
          );
        }
      }
    );
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "new":
        this.newAppEui();
        break;
      case "appeui-update-all":
        this.saveAll();
        break;
      case "remove-selected-data":
        this.deleteAppEui();
        break;
      case "remove-all-table-date":
        this.deleteAllAppEui();
        break;
      default:
        break;
    }
  }
}
