// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";
import {
  DEVICE_BY_METROLOGY,
  DeviceTypeService,
} from "../../../../../../services/shared/DeviceTypeService.service";
import { MeterService } from "../../MeterService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { DeviceAgrupationDialogComponent } from "../../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { MeterAssignedDialogComponent } from "./meter-assigned-dialog/meter-assigned-dialog.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";
import { AssignedDevice, DetailDevice, METER_ORDER } from "../../../DeviceInterface.type";
// Variables
import {
  MapDevice,
  METROLOGY_TYPE,
} from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { VALVE_STATES } from "../../../DeviceInterface.type";
import { PROFILES } from "../../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-meterslistassign",
  templateUrl: "./meter-assigned.component.html",
  styleUrls: ["./meter-assigned.component.scss"],
})
export class MetersListAssignComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  deviceType: string;

  // Tabla
  tableSelectedData: AssignedDevice[];
  tableMaxReg: number = 100;
  meterList: AssignedDevice[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  isGasAgrupation: boolean;
  originalMeterList: AssignedDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][];
  quickFiltersExclusion: boolean[] = [true, true];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-battery-multigraph",
      icon: "fa-solid fa-battery-three-quarters",
      selectionRequired: true,
      help: "help-table-battery",
    },
    {
      title: "save-selection",
      icon: "fas fa-save",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "show-consumption-evolution",
      icon: "fas fa-faucet",
      selectionRequired: true,
      help: "help-table-evolution",
    },
    {
      title: "association-new",
      icon: "fas fa-layer-group",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-asoc",
    },
    {
      title: "change-agrupation",
      icon: "fas fa-map",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-agrupation",
    },
    {
      title: "change-location",
      icon: "fas fa-map-marked-alt",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-evolution",
    },
    {
      title: "substitution-order",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      profiles: [PROFILES.ARSON],
      submenu: [
        {
          title: "substitution-only-meter",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "substitution-only-rf",
          icon: "fas fa-tower-broadcast",
        },
        {
          title: "substitution-all",
          icon: "fas fa-sync-alt",
        },
      ],
    },
    {
      title: "deactivate-multiple",
      icon: "fas fa-eraser",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      class: "btn-red",
      help: "help-table-deactivate",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  stayOnRoute: boolean = false;
  homeDevicesFilter: MapDevice[];
  homeDevicesFilterActive: boolean;
  homeDevicesOriginal: AssignedDevice[];
  homeDevicesFilteredOriginal: AssignedDevice[];

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];
  exportIcon: string = "fas fa-download";
  exportTitle: string = this.translate.instant("export-all-devices");

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    if (history.state.data?.devices) {
      this.homeDevicesFilter = history.state.data.devices;
    } else {
      this.deviceType = history.state.data;
    }
    this.setPanelMenuOptions();
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.mapSelectorActive = false;
    this.MeterController.table(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          // CUPS
          let entityCupsData = this.MeterService.getEntityCupsData();
          this.tableCupsColumns = entityCupsData.entityCupsColumns;
          this.cups = entityCupsData.entityCups;
          this.entityNroSerie = entityCupsData.entityNroSerie;

          // Contadores
          let meterList: AssignedDevice[] =
          this.MeterService.parseAssignedMeterList(
            response["body"]["meterList"]
          );

          meterList.forEach((meter: AssignedDevice) => {
            let manufacturer = this.ManufacturerService.getManufacturer(
              `${meter.fabricante}`, // Interpolación que convierte a string el fabricante
              `${meter.devType}`,    // Interpolación que convierte a string el devtype
              this.SessionDataService.getCurrentLanguage()
            );


            meter.fabricanteParsed = manufacturer?.manufacturerText;
            meter.devTypeParsed = manufacturer?.deviceText;
            // Metrología
            meter.metrologyTypeParsed =
              meter.metrologyType != null
                ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
                : "-";
            // CUPS
            meter.cups = meter.isAssigned ? meter.clave : null;
            // En red
            meter.inNetwork = meter.comunica;
            // Válvula
            meter.valveStateParsed =
              meter.valveState != null &&
              meter.valveState != -1 &&
              VALVE_STATES[meter.valveState]
                ? this.translate.instant(VALVE_STATES[meter.valveState].text)
                : this.translate.instant("unknown");
            // Agrupación
            meter.agrupationName = this.currentEntity.agrupations.find(
              (agrupation: Agrupation) => agrupation.id == meter.agrupation
            )?.name;
            // Fabricante
            meter.deviceType = this.ManufacturerService.getDeviceType(
              meter.fabricante,
              meter.devType
            );
            // Concentrador
            meter.isConcentrator =
              meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR ||
              meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR;
            // Gráfica
            meter.loadGraph =
              meter.metrologyType == METROLOGY_TYPE.WATER ||
              meter.metrologyType == METROLOGY_TYPE.GAS ||
              meter.metrologyType == METROLOGY_TYPE.SATELITE;

            meter.loadBatteryGraph=!meter.isConcentrator;

          });
          // Filtrado desde mapa principal
          if (this.homeDevicesFilter) {
            this.homeDevicesOriginal = meterList;
            this.homeDevicesFilteredOriginal = meterList.filter((meter) =>
              this.homeDevicesFilter.some((device) => device.id == meter.id)
            );
            this.meterList = [...this.homeDevicesFilteredOriginal];
            this.homeDevicesFilterActive = true;
          } else {
            this.meterList = meterList;
          }
          this.originalMeterList = meterList;
          this.isGasAgrupation = this.meterList.some(
            (meter) => meter.metrologyType == METROLOGY_TYPE.GAS
          );
          this.setColumns();
          this.setQuickFilters();
        }
      }
    );
  }

  // Actualización de datos por filtro desde mapa
  updateData(): void {
    if (this.homeDevicesFilterActive) {
      this.meterList = [...this.homeDevicesFilteredOriginal];
    } else {
      this.meterList = [...this.homeDevicesOriginal];
    }
  }

  // Parseo de columnas de CUPS
  parseEntityDefinitions(entityDefinitions): EntityDefinition[] {
    return entityDefinitions.map((entityDefinition) => {
      return {
        id: entityDefinition.id,
        entity: entityDefinition.e,
        colPosition: entityDefinition.p,
        name: entityDefinition.n,
        label: entityDefinition.l,
        description: entityDefinition.d,
        show: entityDefinition.s,
        required: entityDefinition.r,
      };
    });
  }

  // Seteo de los filtros extra
  setQuickFilters(): void {
    this.quickFilters = [
      [
        {
          name: "in-network-with-comm",
          columnSearch: "inNetwork",
          condition: { type: "boolean", rule: true },
          active: this.deviceType?.includes("ACTIVE"),
        },
      ],
      [
        {
          name: "meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.WATER },
          active:
            this.deviceType?.includes(DEVICE_BY_METROLOGY.METER) ||
            this.deviceType?.includes(DEVICE_BY_COMM.MBUS) ||
            this.deviceType?.includes(DEVICE_BY_COMM.UNE) ||
            this.deviceType?.includes(DEVICE_BY_COMM.LW)
              ? true
              : false,
        },
        {
          name: "gas-meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.GAS },
          active:
            this.deviceType?.includes(DEVICE_BY_METROLOGY.METER) ||
            this.deviceType?.includes(DEVICE_BY_COMM.LW),
        },
        {
          name: "sensors",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.SENSOR },
          active: this.deviceType?.includes(DEVICE_BY_METROLOGY.SENSOR),
        },
        {
          name: "concentrators",
          columnSearch: "isConcentrator",
          condition: { type: "boolean", rule: true },
          active: this.deviceType?.includes(DEVICE_BY_METROLOGY.CONCENTRATOR),
        },
        {
          name: "valves",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.WATER_VALVE },
          active: this.deviceType?.includes(DEVICE_BY_METROLOGY.VALVE),
        },
        {
          name: "satelite",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.SATELITE },
          active: this.deviceType?.includes(DEVICE_BY_METROLOGY.SATELITE),
        },
      ],
      [
        {
          name: "LoRaWAN",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.LW },
          active: this.deviceType?.includes(DEVICE_BY_COMM.LW),
        },
        {
          name: "MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.MBUS },
          active: this.deviceType?.includes(DEVICE_BY_COMM.MBUS),
        },
        {
          name: "UNE",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.UNE },
          active: this.deviceType?.includes(DEVICE_BY_COMM.UNE),
        },
        {
          name: "LoRaWAN MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.LW_MBUS },
          active:
            this.deviceType?.includes(DEVICE_BY_COMM.LW_MBUS) ||
            this.deviceType?.includes(DEVICE_BY_COMM.LW),
        },
        {
          name: this.translate.instant("external"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.EXTERNO },
          active: false,
        },
        {
          name: "ERM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.ERM },
          active: false,
        },
        {
          name: "EK280",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.EK280 },
          active: false,
        },
        {
          name: "PLUM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.PLUM },
          active: false,
        },
        {
          name: this.translate.instant("no-lora-no-com"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.NO_LORA_NO_COM },
          active: false,
        },
      ],
    ];
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "meter-orders",
            tooltip: "meter-orders",
            icon: "fas fa-list-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "deactivate",
            tooltip: "deactivate",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: !(
              this.sessionProfile == PROFILES.ARSON ||
              this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
              this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
              this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ),
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: this.cups?.name ? this.cups.name : "CUPS",
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: this.cups != null ? true : null,
      },
      {
        title: this.entityNroSerie?.label
          ? this.entityNroSerie.label
          : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "rf-module",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "FCV",
        data: "fcv",
        search: "fcv",
        sort: "fcv",
        visible: this.isGasAgrupation ? true : null,
      },
      {
        title: "PCS",
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: this.isGasAgrupation ? true : null,
      },
      {
        title: "groups",
        data: "agrupationName",
        search: "agrupationName",
        sort: "agrupationName",
        visible: this.currentAgrupation.showAllEntity ? true : null,
      },
      {
        title: "manufacturer",
        data: "fabricanteParsed",
        search: "fabricanteParsed",
        sort: "fabricanteParsed",
        visible: true,
      },
      {
        title: "model",
        data: "devTypeParsed",
        search: "devTypeParsed",
        sort: "devTypeParsed",
        visible: true,
      },

      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "month-consumption",
        data: "consumptionCardsLastMonthParsed",
        search: "consumptionCardsLastMonthParsed",
        sort: "consumptionCardsLastMonth",
        numerical: true,
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
        batteryGraph: false,
      },
      {
        title: "battery-load",
        data: "lastBatValue",
        search: "lastBatValue",
        sort: "lastBatValue",
        numerical: true,
        visible: true,
      },
      {
        title: "battery-graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        batteryGraph: true,
        graph: true,

      },
      {
        title: "readings-percentage",
        data: "pcParsed",
        search: "pcParsed",
        sort: "pc",
        numerical: true,
        visible: this.meterList.some(
          (meter: AssignedDevice) =>
            this.DeviceTypeService.getDeviceTypeByMask(meter.tipo) ==
            DEVICE_BY_COMM.MBUS
        )
          ? true
          : null,
      },
      {
        title: "last-consumption-min",
        data: "lastConsumptionMinParsed",
        search: "lastConsumptionMinParsed",
        sort: "lastConsumptionMin",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-max",
        data: "lastConsumptionMaxParsed",
        search: "lastConsumptionMaxParsed",
        sort: "lastConsumptionMax",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-total",
        data: "lastConsumptionTotalParsed",
        search: "lastConsumptionTotalParsed",
        sort: "lastConsumptionTotal",
        numerical: true,
        visible: true,
      },
      {
        title: "valve-state",
        data: "valveStateParsed",
        search: "valveStateParsed",
        sort: "valveStateParsed",
        visible: this.meterList?.some(
          (meter: AssignedDevice) => meter.valveState != null
        )
          ? true
          : null,
      },
      {
        title: "valve-state-date",
        data: "valveStateChangedTimestamp",
        search: "valveStateChangedTimestamp",
        sort: "valveStateChangedTimestamp",
        date: true,
        visible: this.meterList?.some(
          (meter: AssignedDevice) => meter.valveState != null
        )
          ? true
          : null,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: true,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: true,
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: AssignedDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "meter-orders":
        this.router.navigate(["/dispositivos/detalle/ordenes/" + meter.id], {
          state: { data: meter },
        });
        break;
      case "deactivate":
        this.resetMeter(meter);
        break;
      case "load-graph":
        this.MeterService.getAlarmGraph(meter);
        break;
      case "load-battery-graph":
        this.MeterService.getBatteryGraph(meter);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "show-consumption-evolution":
        this.router.navigate(["/analisis-datos/evolucion-consumo"], {
          state: {
            data: this.tableSelectedData.map((device: AssignedDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "change-location":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "changeLocation",
          meters: this.tableSelectedData,
        });
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      case "association-new":
        this.router.navigate(["/analisis-datos/asociaciones/nuevo"], {
          state: {
            data: this.tableSelectedData.map(
              (device: AssignedDevice) => device.id
            ),
          },
        });
        break;
      case "save-selection":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "saveSelection",
          meters: this.tableSelectedData.map((meter) => {
            return {
              id: meter.id,
              nroSerie: meter.nroSerie,
              metrologyType: meter.metrologyType,
            };
          }),
        });
        break;
      case "substitution-only-meter":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_ONLY_DEVICE);
        break;
      case "substitution-only-rf":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_RF_MODULE);
        break;
      case "substitution-all":
        this.saveSubstitutionMeterOrder(
          METER_ORDER.SUSTITUTION_DEVICE_COMPLETE
        );
        break;
      case "show-battery-multigraph":
        this.showOnBatteryGraph();
      default:
        break;
    }
  }

  // Guardado de orden de sustitución
  saveSubstitutionMeterOrder(order: number): void {
    this.ToastService.fireAlertWithTextarea(
      "info",
      this.translate.instant("substitution-order"),
      this.translate.instant("comments"),
      null,
      this.translate.instant("save"),
      this.translate.instant("cancel")
    ).then((userComments) => {
      if (userComments) {
        this.MeterController.saveOrder({
          idList: this.tableSelectedData.map((device) => device.id),
          type: order,
          comments: userComments,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
          }
        });
      }
    });
  }

  // Redirección a contador
  showMeter(meter: AssignedDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Reseteo de contador
  resetMeter(meter: AssignedDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: AssignedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }

  // Exportación de todos los contadores
  exportAll(): void {
    this.MeterController.download(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response != null && response["code"] != 1000) {
          saveAs(
            response,
            this.translate.instant("all-meters-list-export") +
              " " +
              this.DateParserService.getDate() +
              ".csv"
          );
        }
      }
    );
  }

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "update-data",
        icon: "fas fa-sync-alt",
        text: this.translate.instant("update"),
        visible: true,
      },
      {
        action: "export-all",
        icon: "fas fa-download",
        text: this.translate.instant("export-all-devices"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update-data":
        this.meterList = null;
        this.getData();
        break;
      case "export-all":
        this.exportAll();
        break;
      default:
        break;
    }
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((meter: AssignedDevice) => {
          return meter.id;
        }),
        to: null,
      },
    });
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: this.tableSelectedData,
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }

  //Visualizacion de bateria de varios contadores en graficos
  showOnBatteryGraph() {
    let meters: DetailDevice[] = JSON.parse(JSON.stringify(this.tableSelectedData));
    this.MeterService.openBatteryMultiGraph(meters);
  }
}
