<div class="coverage-contrast-control">
  <span class="btn btn-block btn-default btn-file coverage-contrast-import">
    <div>
      <span>{{ "KML: " }}</span>
      <span>{{
        contrastKml?.length > 0
          ? contrastKml.join(", ")
          : ("unselected" | translate)
      }}</span>
    </div>
    <div>
      <span>{{ "PNG: " }}</span>
      <span>{{
        contrastImages?.length > 0
          ? contrastImages.join(", ")
          : ("unselected" | translate)
      }}</span>
    </div>
    <input
      #importInput
      type="file"
      accept=".kml,.kmz,.png"
      id="file"
      (click)="reset()"
      (change)="fileChanged($event)"
      multiple
    />
  </span>

  <!-- Botón importar -->
  <app-material-panel-menu
    [onlyButton]="true"
    [icon]="importIcon"
    [title]="importTitle"
    (action)="showContrast()"
  >
  </app-material-panel-menu>
</div>
