import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ManagementControllerService } from "../../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";

@Component({
  selector: "app-management-commands-tail-dialog",
  templateUrl: "./management-commands-tail-dialog.component.html",
  styleUrls: ["./management-commands-tail-dialog.component.scss"],
})
export class ManagementCommandsTailDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  selectedGateway: number;

  // Selector de fecha
  daterangePickerLang = {
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    applyLabel: this.translate.instant("accept"),
  };
  maxDate: moment.Moment = moment().subtract(6, "month");
  dateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: this.maxDate,
    endDate: this.maxDate,
  };
  dateRangeSelected: { startDate: moment.Moment; endDate: moment.Moment };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ManagementController: ManagementControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  //Relanzamiento de cola
  relaunchTail() {
    this.ManagementController.relaunchTail(this.selectedGateway).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("tail-relaunched")
          );
          this.SessionDataService.sendReloadPanelFlag();
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      }
    );
  }
}
