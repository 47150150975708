// zipcelx
import zipcelx from "zipcelx";

// Exportación de gráficos a Excel
export default function (Highcharts) {
  const H = Highcharts;

  // Exportación a excel
  H.wrap(H.Chart.prototype, "downloadXLS", function () {
    const div = document.createElement("div");
    let name,
      xlsxRows = [];
    div.style.display = "none";
    document.body.appendChild(div);
    const rows = this.getDataRows(true);
    xlsxRows = rows.slice(1).map(function (row) {
      return row.map(function (column) {
        return {
          type: typeof column === "number" ? "number" : "string",
          value: column,
        };
      });
    });

    // Get the filename, copied from the Chart.fileDownload function
    if (this.options.exporting.filename) {
      name = this.options.exporting.filename;
    } else if (this.title && this.title.textStr) {
      name = this.title.textStr.replace(/ /g, "-").toLowerCase();
    } else {
      name = "chart";
    }

    zipcelx({
      filename: name,
      sheet: {
        data: xlsxRows,
      },
    });
  });
}
