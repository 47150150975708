import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { QueryData } from "./QueryInterface.type";
import { QueriesControllerService } from "../../../services/server/QueriesController.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";

@Injectable({
  providedIn: "root",
})
export class QueriesRequestsService {
  constructor(
    private DateParserService: DateParserService,
    private QueriesController: QueriesControllerService
  ) {}

  getQueryRequest(
    query: QueryData,
    agrupationId: number,
    entityId: number
  ): Observable<object> {
    let data = this.getQueryData(query, agrupationId, entityId);

    switch (query.id) {
      case 1:
        return this.QueriesController.getMetersByConsumtionAndHour(data);
      case 2:
        return this.QueriesController.getStoppedMeters(data);
      case 3:
        return this.QueriesController.getNegativeConsumption(data);
      case 4:
        return this.QueriesController.getLeaks(data);
      case 5:
        return this.QueriesController.getAbnormalSpikes(data);
      case 6:
        return;
      case 9:
        return this.QueriesController.getConsumptionComparation(data);
      default:
        break;
    }
  }

  // Obtención de los parámetros para la consulta
  getQueryData(query: QueryData, agrupationId: number, entityId: number): any {
    let data = {
      agrupacion: agrupationId,
      entidad: entityId,
    };
    let params: string[] = ["select", "input", "date"];

    params.forEach((param: string) => {
      query.params[param].forEach((element: any) => {
        switch (param) {
          case "date":
            if (element.range) {
              data[element.keyStart] = element.value.startDate?.valueOf();
              data[element.keyEnd] = element.value.endDate?.valueOf();
            } else {
              data[element.key] = this.DateParserService.getInputDateTimestamp(
                element.value,
                null,
                "DD/MM/YYYY HH:mm"
              );
            }
            break;
          case "input":
            data[element.key] =
              element.units == "%" ? element.value / 100 : element.value;
            break;
          default:
            data[element.key] = element.value;
            break;
        }
      });
    });

    return data;
  }
}
