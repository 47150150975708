<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{
            (metrologic?.contadorNroSerie != null
              ? ("edit-metrologic" | translate)
              : ("new-metrologic" | translate)) +
              (metrologic?.contadorNroSerie
                ? ": " + metrologic?.contadorNroSerie
                : "")
          }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <div
          *ngIf="metrologicReady; else elseBlock"
          class="metrological-form-data"
        >
          <!-- Contador -->
          <div *ngIf="metrologic.contadorNroSerie == null">
            <app-material-select
              [title]="serialNumberLabel"
              [bindValue]="'id'"
              [bindLabel]="'nroSerie'"
              [data]="meterList"
              (selectedOption)="metrologic.contador = $event?.id"
              [required]="true"
            ></app-material-select>
          </div>
          <!-- Factor de conversión -->
          <mat-form-field appearance="standard">
            <mat-label>{{ "conversion-factor" | translate }}</mat-label>
            <input
              matInput
              type="number"
              autocomplete="off"
              [(ngModel)]="metrologic.conversionFactor"
              required
            />
          </mat-form-field>
          <!-- PCS -->
          <mat-form-field appearance="standard">
            <mat-label>PCS</mat-label>
            <input
              matInput
              type="text"
              autocomplete="off"
              [(ngModel)]="metrologic.pcs"
              required
            />
          </mat-form-field>
        </div>

        <!-- Botones -->
        <div *ngIf="metrologic" class="metrological-form-buttons">
          <!-- Guardar -->
          <button
            mat-button
            class="mat-button-icon-text"
            [disabled]="
              metrologic.contador == null ||
              metrologic.conversionFactor == null ||
              metrologic.pcs == null
            "
            (click)="saveMetrologic()"
          >
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
          <!-- Reseteo -->
          <button mat-button (click)="ReloadComponentService.reload()">
            {{ "restart" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
