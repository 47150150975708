import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Cosine similarity
const similarity = require("compute-cosine-similarity");
// Seasonal Hybrid ESD (S-H-ESD), Twitter's AnomalyDetection
const detect_ts = require("s-h-esd");
// Detección de outliers
const outlier = require("outlier2");
// Estadísticas
import * as ss from "simple-statistics";
// Servicios propios
import { DateParserService } from "./DateParserService.service";
import { SessionDataService } from "./SessionDataService.service";
import { MeterControllerService } from "../server/MeterController.service";
// Interfaces
import { OutliersData } from "../../modules/graph-module/GraphInterface.type";
import { MapDevice } from "../../interfaces/DeviceGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class MachineLearningService {
  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService
  ) {}

  // Calcular media horaria
  calculateHourlyAverage(
    seriesData: number[][],
    data?: number[],
    threshold?: number
  ): {
    meanSerie: number[];
    sdSerie: number[];
  } {
    if (!data) {
      data = seriesData.map((data) => data[1]);
    }
    let meanData = this.getHourlyMean(data);
    let sdData = this.getHourlySd(data);
    let meanDataSerie = meanData;
    let sdDataSerie = sdData;
    for (let i = 0; i < data.length / (24 * 7) - 1; i++) {
      meanDataSerie = meanDataSerie.concat(meanData);
      sdDataSerie = sdDataSerie.concat(sdData);
    }
    seriesData.map((data, i) => {
      data[2] = parseFloat(meanDataSerie[i].toFixed(3));
      data[3] = parseFloat(
        (sdDataSerie[i] * (threshold ? threshold : 1)).toFixed(3)
      );
      data[4] = parseFloat(
        Math.abs(ss.zScore(data[1], meanDataSerie[i], sdDataSerie[i])).toFixed(
          3
        )
      );
    });
    return { meanSerie: meanDataSerie, sdSerie: sdDataSerie };
  }

  // Calcular media diaria
  calculateDailyAverage(
    seriesData: number[][],
    data?: number[],
    threshold?: number
  ): {
    meanSerie: number[];
    sdSerie: number[];
  } {
    if (!data) {
      data = seriesData.map((data) => data[1]);
    }
    let meanData = this.getDailyMean(data);
    let sdData = this.getDailySd(data);
    let meanDataSerie = meanData;
    let sdDataSerie = sdData;
    for (let i = 0; i < data.length / 7 - 1; i++) {
      meanDataSerie = meanDataSerie.concat(meanData);
      sdDataSerie = sdDataSerie.concat(sdData);
    }
    seriesData.map((data, i) => {
      data[2] = parseFloat(meanDataSerie[i].toFixed(3));
      data[3] = parseFloat(
        (sdDataSerie[i] * (threshold ? threshold : 1)).toFixed(3)
      );
      data[4] = parseFloat(
        Math.abs(ss.zScore(data[1], meanDataSerie[i], sdDataSerie[i])).toFixed(
          3
        )
      );
    });
    return { meanSerie: meanDataSerie, sdSerie: sdDataSerie };
  }

  // Cálculo de media horaria
  getHourlyMean(data: number[], yearly?: boolean): number[] {
    let hourlyMean = [];
    for (let i = 0; i < (yearly ? 365 : 7); i++) {
      hourlyMean[i] = [];
      for (let j = 0; j < 24; j++) {
        let hourData = data.filter(
          (hourData, k) => j + i * 24 == k % (24 * (yearly ? 365 : 7))
        );
        if (hourData?.length > 0) {
          hourlyMean[i][j] = ss.mean(hourData);
        } else {
          hourlyMean[i][j] = 0;
        }
      }
    }
    return hourlyMean.reduce((a, b) => a.concat(b));
  }

  // Cálculo de desviación horaria
  getHourlySd(data: number[], yearly?: boolean): number[] {
    let hourlySd = [];
    for (let i = 0; i < (yearly ? 365 : 7); i++) {
      hourlySd[i] = [];
      for (let j = 0; j < 24; j++) {
        let hourData = data.filter(
          (hourData, k) => j + i * 24 == k % (24 * (yearly ? 365 : 7))
        );
        if (hourData?.length > 1) {
          hourlySd[i][j] = ss.standardDeviation(hourData);
        } else {
          hourlySd[i][j] = 0;
        }
      }
    }
    return hourlySd.reduce((a, b) => a.concat(b));
  }

  // Cálculo de media diaria
  getDailyMean(data: number[]): number[] {
    let dailyMean = [];
    for (let i = 0; i < 7; i++) {
      dailyMean[i] = [];
      let dailyData = data.filter(
        (dailyData, k) => i * 24 <= k % (24 * 7) && i * 24 + 24 > k % (24 * 7)
      );
      if (dailyData?.length > 0) {
        dailyMean[i] = ss.mean(dailyData);
      } else if (dailyMean?.length == 1) {
        dailyMean[i] = dailyData[0];
      } else {
        dailyMean[i] = 0;
      }
    }
    return dailyMean;
  }

  // Cálculo de desviación diaria
  getDailySd(data: number[]): number[] {
    let dailySd = [];
    for (let i = 0; i < 7; i++) {
      dailySd[i] = [];
      let dailyData = data.filter(
        (dailyData, k) => i * 24 <= k % (24 * 7) && i * 24 + 24 > k % (24 * 7)
      );
      if (dailyData?.length > 1) {
        dailySd[i] = ss.standardDeviation(dailyData);
      } else {
        dailySd[i] = 0;
      }
    }
    return dailySd;
  }

  // Detectar anomalías
  detectAnomalies(
    seriesData: number[][],
    threshold?: string | number
  ): number[][] {
    if (seriesData.length >= 48) {
      let df = seriesData.map((data) => {
        return [
          this.DateParserService.parseDate(data[0], "YYYY-MM-DD HH:mm:ss"),
          data[1] ? data[1] : 0.0001,
        ];
      });
      let result = detect_ts(df, {
        // max_anoms: 0.02,
        // alpha: 0.001,
        // threshold:  "None" | "med_max" | "p95" | "p99",
        threshold: threshold,
        direction: "both",
      });
      return result?.anoms?.map((anom) => {
        return [
          this.DateParserService.toRawTimestamp(
            anom.timestamp,
            "YYYY-MM-DD HH:mm:ss"
          ),
          anom.anom,
        ];
      });
    }
    return null;
  }

  // Detectar anomalías
  detectOutliers(
    seriesData: number[][],
    method?: string,
    threshold?: any
  ): number[][] {
    if (seriesData?.length > 1) {
      if (method == "twitter") {
        return this.detectAnomalies(seriesData, threshold);
      } else {
        let outliersIndex = this.getOutlierMethod(
          method,
          seriesData,
          threshold
        );
        let outliersData = outliersIndex.map((outlierIndex) => {
          if (method == "average-hourly" || method == "average-daily") {
            return seriesData[outlierIndex[0]]
              .slice(0, 2)
              .concat(outlierIndex.slice(1));
          } else {
            return seriesData[outlierIndex]?.slice(0, 2);
          }
        });
        return outliersData;
      }
    }
    return null;
  }

  getOutlierMethod(
    method: string,
    seriesData: number[][],
    threshold?: number
  ): number[] | number[][] {
    let data = seriesData.map((data) => data[1]);
    switch (method) {
      case "average-hourly":
        return this.getAverageOutliers(
          data,
          this.calculateHourlyAverage(seriesData, null, threshold),
          threshold
        );
      case "average-daily":
        return this.getAverageOutliers(
          data,
          this.calculateDailyAverage(seriesData, null, threshold),
          threshold
        );
      case "3-sigma":
        return outlier.sigma(data, { indexes: true });
      case "GRABBS":
        return outlier.grubbs(data, { indexes: true });
      case "IRQ":
        return outlier.iqr(data, { indexes: true });
      case "MAD":
        return outlier.mad(data, { indexes: true });
      case "MD":
        return outlier.md(data, { indexes: true });
      default:
        return outlier.mad(data, { indexes: true });
    }
  }

  getAverageOutliers(
    data: number[],
    average: {
      meanSerie: number[];
      sdSerie: number[];
    },
    threshold: number
  ): number[][] {
    let outliers = [];
    if (!threshold) {
      threshold = 1;
    }
    data.forEach((value, i) => {
      if (
        Math.abs(ss.zScore(value, average.meanSerie[i], average.sdSerie[i])) >=
        threshold
      ) {
        outliers.push([
          i,
          average.meanSerie[i]?.toFixed(3),
          (average.sdSerie[i] * threshold).toFixed(3),
          Math.abs(
            ss.zScore(value, average.meanSerie[i], average.sdSerie[i])
          ).toFixed(3),
        ]);
      }
    });
    return outliers;
  }

  // Comprobar similitud de sarima
  checkSarimaSimilarity(averageData: number[], seriesData: number[][]): string {
    let serieData = seriesData
      .map((data) => {
        return [data[1]];
      })
      .reduce((a, b) => a.concat(b))
      .slice(0, averageData.length);
    return similarity(
      serieData,
      averageData.slice(0, serieData.length)
    ).toFixed(2);
  }

  // Detección de valores atípicos
  detectSelectionOutliers(
    meters: MapDevice[],
    method: string,
    from: string,
    to: string,
    dateRange: { startDate: moment.Moment; endDate: moment.Moment },
    outliersData?: OutliersData[],
    threshold?: string
  ): void {
    if (from && to) {
      let requests = meters?.map((meter) => {
        return this.MeterController.getGraph(
          meter.id,
          meter.metrologyType == 2 ? "3" : "2",
          from,
          to
        );
      });
      this.requestLoop(meters, method, dateRange, threshold, requests, 0);
    } else {
      outliersData.map((outlierData) => {
        outlierData.outliers = this.detectOutliers(
          outlierData.meterSeries,
          method,
          threshold
        );
      });
      this.SessionDataService.sendDialogAction({
        action: "show-all-outliers",
        data: outliersData,
      });
    }
  }

  // Bucle de llamadas
  requestLoop(
    meters: MapDevice[],
    method: string,
    dateRange: { startDate: moment.Moment; endDate: moment.Moment },
    threshold,
    requests: Observable<object>[],
    requestIndex: number
  ): void {
    this.SessionDataService.sendDisableSpinner(true);
    requests[requestIndex].subscribe((response) => {
      if (response["code"] == 0) {
        this.SessionDataService.sendDialogAction({
          action: "show-outlier",
          data: {
            meterId: meters[requestIndex].id,
            meterSeries: response["body"]["readings"],
            outliers: this.detectOutliers(
              response["body"]["readings"],
              method,
              threshold
            ),
            method: method,
            dateRange: dateRange,
          },
        });
      }
      if (requestIndex < requests.length - 1) {
        this.requestLoop(
          meters,
          method,
          dateRange,
          threshold,
          requests,
          ++requestIndex
        );
      } else {
        this.SessionDataService.clearDisableSpinner();
      }
    });
  }

  // Cálculo de patrón de los contadores seleccionados
  calculatePatterns(
    series: number[][],
    yearly?: boolean
  ): {
    patternSerie: number[][];
    normalizedPattern: number[];
    consumptionPattern: number[];
    consumptionSdPattern: number[];
  } {
    let timestamps: number[];
    let normalizedData = series.map((serie) => {
      timestamps = serie.map((data) => data[0]);
      let consumption = serie.map((data) => data[1]);
      let mean = ss.mean(consumption);
      let sd = ss.standardDeviation(consumption);
      return serie.map((reading) => (reading[1] - mean) / sd);
    });
    let consumptionData = series.map((serie) => serie.map((data) => data[1]));
    let consumptionMean = consumptionData.map((data) =>
      this.getHourlyMean(data, yearly)
    );
    let consumptionSd = consumptionData.map((data) =>
      this.getHourlySd(data, yearly)
    );
    let patterns = normalizedData.map((data) =>
      this.getHourlyMean(data, yearly)
    );
    let meanPattern = [];
    let consumptionSdPattern = [];
    let consumptionPattern = [];
    for (let i = 0; i < patterns[0].length; i++) {
      meanPattern.push(
        patterns.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0) /
          patterns.length
      );
      consumptionSdPattern.push(
        consumptionSd.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0) /
          consumptionSd.length
      );
      consumptionPattern.push(
        consumptionMean.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0) /
          consumptionMean.length
      );
    }
    let meanPatternSerie = [];
    for (let i = 0; i < 24 * (yearly ? 365 : 7); i++) {
      meanPatternSerie.push([timestamps[i], meanPattern[i]]);
    }
    return {
      patternSerie: meanPatternSerie,
      normalizedPattern: meanPattern,
      consumptionPattern: consumptionPattern,
      consumptionSdPattern: consumptionSdPattern,
    };
  }

  // Comprobación de similitud de curvas
  checkSimilarity(pattern: number[], data: number[][]): number {
    let dataValues = data
      .map((data) => {
        return [data[1]];
      })
      .reduce((a, b) => a.concat(b))
      .slice(0, pattern.length);
    return parseFloat(similarity(dataValues, pattern).toFixed(2));
  }
}
