<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{
        data.action == "edit"
          ? ("edit" | translate)
          : data.action == "new"
          ? ("new" | translate)
          : ("assign" | translate)
      }}
    </h5>
  </div>
  <div class="dialog-body device-cups-dialog">

      <!-- Edición de CUPS -->
      <div *ngIf="data.action == 'edit' || data.action == 'new'">

        <!-- Contenedor para las columnas de CUPS -->
        <div class="cups-columns-container">

          <!-- Título del CUPS -->
          <div *ngIf="cupsTitleColumn" class="cups-title column-group">
            <div class="column-group-title">
              <h5>{{ cupsTitleColumn?.name }}</h5> <!-- Título de las columnas de definición -->
            </div>
            <mat-form-field appearance="standard">
              <mat-label>{{ cupsTitleColumn?.name }}</mat-label>
              <input
                matInput
                [(ngModel)]="data.newCups.clave"
                placeholder="{{ cupsTitleColumn?.description }}"
                autocomplete="off"
                [required]="cupsTitleColumn?.required"
              />
            </mat-form-field>
          </div>

        <!-- Contenedor para las columnas de definición (con borde y título) -->
        <div class="column-group" *ngIf="cupsDeffinitionColumns.length > 0">
          <div class="column-group-title">
            <h5>{{ "definition" | translate }}</h5> <!-- Título de las columnas de definición -->
          </div>
          <div class="column-items">
            <ng-container *ngFor="let column of cupsDeffinitionColumns">
              <div *ngIf="column.colPosition !== 'col00' && column.colPosition !== 'col100'" class="column-item">
                <mat-form-field appearance="standard">
                  <mat-label>{{ column.name }}</mat-label>
                  <!-- Input numérico solo si el nombre de la columna es 'fcv' -->
                  <input
                    matInput
                    [(ngModel)]="data.newCups[column.colPosition]"
                    placeholder="{{ column.description }}"
                    autocomplete="off"
                    [required]="column.required"
                    [type]="['FCV', 'latitude', 'longitude'].includes(column.label) ? 'number' : 'text'"
                    [step]="['FCV', 'latitude', 'longitude'].includes(column.label) ? '0.01' : '1'"
                    />
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </div>

          <!-- Contenedor para las columnas de datos extra (con borde y título) -->
          <div class="column-group" *ngIf="cupsExtraColumns.length > 0">
            <div class="column-group-title">
              <h5>{{ "extra-data" | translate }}</h5> <!-- Título de las columnas de datos extra -->
            </div>
            <div class="column-items">
              <ng-container *ngFor="let column of cupsExtraColumns">
                <div *ngIf="column.colPosition !== 'col00' && column.colPosition !== 'col100'" class="column-item">
                  <mat-form-field appearance="standard">
                    <mat-label>{{ column.name }}</mat-label>
                    <input
                      matInput
                      [(ngModel)]="data.newCups[column.colPosition]"
                      placeholder="{{ column.description }}"
                      autocomplete="off"
                      [required]="column.required"
                    />
                  </mat-form-field>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
    </div>


    <!-- Asignación de CUPS -->
    <div *ngIf="data.action == 'assign'">
      <app-material-select
        [fullWidth]="true"
        [title]="data.cupsName"
        [bindValue]="'id'"
        [bindLabel]="'descripcion'"
        [data]="data.cupsList"
        (selectedOption)="assignedCups = $event?.id"
        [required]="true"
      ></app-material-select>
    </div>
  </div>

  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="
        data.action == 'edit'
          ? editCups()
          : data.action == 'new'
          ? newCups()
          : assignCups()
      "
      [disabled]="
        (data.action == 'assign' && assignedCups === null) ||
        ((data.action == 'edit' || data.action == 'new') &&
          data.cupsData.cups?.required &&
          (data.newCups.clave == null || data.newCups.clave == ''))
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
