import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// Servicio propios
import { DomControllerService } from "../../../services/shared/DomControllerService.service";
// Componentes
import { MapControllerComponent } from "../map-controller/map-controller.component";
import { TableControllerComponent } from "../../../modules/table-module/table-controller/table-controller.component";

@Component({
  selector: "app-map-selector",
  templateUrl: "./map-selector.component.html",
  styleUrls: ["./map-selector.component.scss"],
})
export class MapSelectorComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Mapa
  mapType: string = "meterList";
  mapHeight: number = window.innerHeight - 520;
  meters: any[];
  @Input() originalDataList: any[];
  @Input() dataList: any[];
  @Input() tableSelectedData: any[];
  @Input() table: TableControllerComponent;
  @Input() filterAttribute: string;
  @Input() anchorToScroll: string;
  @Output() updateData = new EventEmitter<any[]>();
  gateways: [] = [];
  selectedMeters: any[];
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private DomControllerService: DomControllerService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Visualización de contadores en mapa
  showOnMap(): void {
    let meters = JSON.parse(JSON.stringify(this.tableSelectedData));
    this.meters = meters.map((meter: any) => {
      meter.selected = false;
      return meter;
    });
    this.DomControllerService.goToElement("#map-panel-anchor");
  }

  // Selección de dispositivos en mapa
  selectMeters(mapSelectedMeters: any): void {
    let selectedMeters: any[] = [];
    this.meters.map((meter: any) => {
      if (
        mapSelectedMeters.some(
          (selectedMeter: any) => meter.id == selectedMeter.id
        )
      ) {
        meter.selected = true;
        selectedMeters.push(meter);
      } else {
        meter.selected = false;
      }
    });
    this.selectedMeters = selectedMeters;
  }

  // Selección individual de dispositivo en mapa
  selectSingleMeter(mapSelectedMeter: any): void {
    let selectedMeter = this.meters.find(
      (meter: any) => meter.id == mapSelectedMeter.id
    );
    selectedMeter.selected = !selectedMeter.selected;
    if (selectedMeter.selected) {
      this.selectedMeters
        ? this.selectedMeters.push(selectedMeter)
        : (this.selectedMeters = [selectedMeter]);
    } else {
      this.selectedMeters.splice(
        this.selectedMeters.findIndex(
          (meter: any) => meter.id == selectedMeter.id
        ),
        1
      );
    }
    this.mapController.resetMap();
  }
}
