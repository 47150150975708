// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { HttpClient } from "@angular/common/http";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
// Variables
import { IMAGES_IMPORT_COLUMNS } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-image-import",
  templateUrl: "./device-image-import.component.html",
  styleUrls: ["./device-image-import.component.scss"],
})
export class DeviceImageImportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  importFile: File;
  fileData: any[][];
  fileColumns: string[];
  nroSerieColumn: number;
  urlColumn: number;
  importColumns: ImportColumn[];
  importResponse: { total: number; success: number; fail: number } = {
    total: null,
    success: null,
    fail: null,
  };
  errors: { nroSerie: string; image: File }[];
  retrys: { nroSerie: string; image: File }[];

  // Importación por archivos de imagen
  imagesFiles: File[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private http: HttpClient,
    private MeterController: MeterControllerService,
    private spinner: NgxSpinnerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadComponent(): void {
    this.importColumns = JSON.parse(JSON.stringify(IMAGES_IMPORT_COLUMNS)).map(
      (column: ImportColumn) => {
        column.info = this.translate.instant(column.info);
        return column;
      }
    );
  }

  // Importación de dispositivo
  processFile(file: File): void {
    this.SessionDataService.sendLockSpinner(true);
    this.spinner.show("spinner-hard");
    this.fileData = null;
    this.imagesFiles = null;
    this.importResponse.total = null;
    this.importResponse.success = 0;
    this.importResponse.fail = 0;
    this.errors = [];
    this.importFile = file;
    let reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        let readedFile: any = reader.result;
        let filterRows = readedFile.split("\n");
        filterRows = filterRows.map((row: string) => {
          return row
            .split(";")
            .map((element: any) => element.replace("\r", ""));
        });
        this.fileColumns = [...filterRows[0]];
        this.nroSerieColumn = filterRows[0].indexOf("NRO_SERIE");
        this.urlColumn = filterRows[0].indexOf("URL");
        this.fileData = [...filterRows.slice(1)];
        if (this.fileData[this.fileData.length - 1][0] == "") {
          this.fileData.pop();
        }
        this.importResponse.total = this.fileData.length;
        this.getImage(0);
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }

  // Obtención de las imágenes
  getImage(index: number): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("image-getting") +
        " (" +
        (index + 1) +
        "/" +
        this.importResponse.total +
        ")..."
    );
    if (!this.imagesFiles) {
      this.http
        .get(this.fileData[index][this.urlColumn], {
          responseType: "blob",
        })
        .subscribe((data) => {
          if (index >= this.fileData.length - 1) {
            this.SessionDataService.sendLockSpinner(false);
          }
          this.updateImage(
            this.fileData[index][this.nroSerieColumn],
            data,
            index
          );
        });
    } else {
      if (index >= this.imagesFiles.length - 1) {
        this.SessionDataService.sendLockSpinner(false);
      }
      this.updateImage(
        this.imagesFiles[index].name.split(".")[0],
        this.imagesFiles[index],
        index
      );
    }
  }

  // Actualización de la imagen de dispositivo
  updateImage(
    nroSerie: string,
    image: any,
    index: number,
    retrying?: boolean
  ): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("image-updating") +
        " (" +
        (index + 1) +
        "/" +
        this.importResponse.total +
        ")..."
    );
    this.MeterController.updateImage(nroSerie, image).subscribe((response) => {
      if (response["code"] == 0) {
        this.importResponse.success++;
      } else {
        this.importResponse.fail++;
        this.errors.push({
          nroSerie: nroSerie,
          image: image,
        });
      }
      if (
        !retrying &&
        ((!this.imagesFiles && index < this.fileData?.length - 1) ||
          (this.imagesFiles && index < this.imagesFiles?.length - 1))
      ) {
        this.getImage(++index);
      } else if (retrying && index < this.retrys.length - 1) {
        index++;
        this.updateImage(
          this.retrys[index].nroSerie,
          this.retrys[index].image,
          index,
          true
        );
      } else {
        this.spinner.hide("spinner-hard");
        this.SessionDataService.clearSpinnerText();
      }
    });
  }

  // Reintentar fallidos
  retry(): void {
    this.importResponse.fail = 0;
    this.retrys = [...this.errors];
    this.errors = [];
    this.updateImage(this.retrys[0].nroSerie, this.retrys[0].image, 0, true);
  }

  // Descarga de archivo de errores
  downloadErrors(): void {
    let errors = this.fileData.filter((data: any) =>
      this.errors.some((error) => error.nroSerie == data[this.nroSerieColumn])
    );
    let errorFile =
      this.fileColumns.join(";") +
      "\r\n" +
      errors.map((data: any) => data.join(";")).join("\r\n");
    saveAs(
      new Blob([errorFile], {
        type: "text/csv",
      }),
      this.translate.instant("errors-list") + ".csv"
    );
  }

  // Procesamiento de archivos de imagen
  processImageFiles(images: File[]): void {
    this.SessionDataService.sendLockSpinner(true);
    this.spinner.show("spinner-hard");
    this.imagesFiles = images;
    this.fileData = null;
    this.importResponse.success = 0;
    this.importResponse.fail = 0;
    this.errors = [];
    this.importResponse.total = this.imagesFiles.length;
    this.getImage(0);
  }
}
