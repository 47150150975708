// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../../../modules/table-module/TableInterface.type";
import { DetailDevice, DeviceEventLog } from "../../../../DeviceInterface.type";
import { PanelMenuOption } from "../../../../../../../modules/material-module/MaterialInterface.type";
// Variables
import { PROFILES } from "../../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-metereventlog",
  templateUrl: "./meter-log-events.component.html",
  styleUrls: ["./meter-log-events.component.scss"],
})
export class MeterEventLogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 50;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("1");
  meter: DetailDevice;
  meterEvents: DeviceEventLog[];
  orderBy: object = { attribute: "timestamp", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "type",
      data: "frameType",
      search: "frameType",
      sort: "frameType",
      visible: true,
    },
    {
      title: "command",
      data: "command",
      search: "command",
      sort: "command",
      visible: true,
    },
    {
      title: "subcommand",
      data: "subcommand",
      search: "subcommand",
      sort: "subcommand",
      visible: true,
    },
    {
      title: "command-response",
      data: "responseCommand",
      search: "responseCommand",
      sort: "responseCommand",
      visible: true,
    },
  ];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService
      .getAgrupation()
      .subscribe((agrupation) => {
        this.currentAgrupation = agrupation;
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meter = history.state.data;
    this.setPanelMenuOptions();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.MeterController
      .getMeterEvents(this.meter.id, this.from, this.to)
      .subscribe((response) => {
        if (response["code"] == 0) {
          if (response["body"].length > 0) {
            let meterEvents: DeviceEventLog[] = response["body"];
            this.meterEvents = meterEvents;
          } else {
            this.meterEvents = [];
          }
        }
      });
  }

  // Actualización del componente
  updateData(): void {
    this.meterEvents = [];
    this.getData(this.from, this.to);
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "user-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("user-log"),
        visible: true,
      },
      {
        action: "communication-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("communication-log"),
        visible:
          this.sessionProfile == PROFILES.ARSON || this.currentAgrupation.id == 60,
      },
      {
        action: "frame-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("frame-log"),
        visible:
          this.sessionProfile == PROFILES.ARSON || this.currentAgrupation.id == 60,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "user-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/usuarios/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "communication-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/comunicaciones/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "frame-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/tramas/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      default:
        break;
    }
  }
}
