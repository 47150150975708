// @angular
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, Injector, NgModule, LOCALE_ID } from "@angular/core";
import { CommonModule, LOCATION_INITIALIZED } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { MeterBatteryDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-battery/meter-battery-dialog/meter-battery-dialog.component";


// Angular Material
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRippleModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSliderModule } from "@angular/material/slider";
import { MatSelectModule } from "@angular/material/select";
import {
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from "@angular/material/checkbox";
import {
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MaterialModule } from "./modules/material-module/material.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { NgxSliderModule } from "@angular-slider/ngx-slider";

// Alarms
import { AlarmsComponent } from "./screens/dashboard/alarms/alarms-list/alarms-list.component";
import { AlarmsAgrupationConfigurationComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-configuration-agrupation/alarms-configuration-agrupation.component";
import { AlarmsEntityConfigurationComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-configuration-entity/alarms-configuration-entity.component";
import { AlarmsListComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-table/alarms-table.component";
import { AlarmsDialogComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-dialog/alarms-dialog.component";
import { AlarmsGlobalComponent } from "./screens/dashboard/alarms/alarms-global/alarms-global.component";
import { AlarmEditDialogComponent } from "./screens/dashboard/alarms/alarm-edit-dialog/alarm-edit-dialog.component";
import { AlarmsEntityTemplateComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-entity-template/alarms-entity-template.component";
import { AlarmsEntityTemplateDialogComponent } from "./screens/dashboard/alarms/alarms-configuration/alarms-entity-template/alarms-entity-template-dialog/alarms-entity-template-dialog.component";
import { AlarmsCustomConfigComponent } from "./screens/dashboard/alarms/alarms-custom-config/alarms-custom-config.component";
import { AlarmsCustomPanelComponent } from "./screens/dashboard/alarms/alarms-custom-config/alarms-custom-panel/alarms-custom-panel.component";
import { AlarmsDevicesComponent } from "./screens/dashboard/alarms/alarms-devices/alarms-devices.component";

// Agrupations
import { AgrupationFormComponent } from "./screens/dashboard/agrupations/agrupation-form/agrupation-form.component";
import { AgrupationListComponent } from "./screens/dashboard/agrupations/agrupations-list/agrupations-list.component";
import { AgrupationUserLogComponent } from "./screens/dashboard/agrupations/agrupations-logs/agrupation-log-user/agrupation-log-user.component";

// Cards
import { CardModule } from "./modules/card-module/card.module";

// Cesium
import { AngularCesiumModule } from "angular-cesium";
import { CesiumMapModule } from "./modules/cesium-map-module/cesium-map.module";

// Clients
import { ClientListComponent } from "./screens/dashboard/clients/clients-list/clients-list.component";
import { ClientFormComponent } from "./screens/dashboard/clients/clients-form/clients-form.component";

// Components
import { AppComponent } from "./app.component";

// Control
import { ControlComponent } from "./screens/dashboard/control/control.component";
import { ControlModalComponent } from "./screens/dashboard/control/control-modal/control-modal.component";
import { ControlHistoricComponent } from "./screens/dashboard/control/control-historic/control-historic.component";
import { ControlHistoricDialogComponent } from "./screens/dashboard/control/control-historic/control-historic-dialog/control-historic-dialog.component";
import { ControlTelemetryComponent } from "./screens/dashboard/control/control-telemetry/control-telemetry.component";
import { ControlTestComponent } from "./screens/dashboard/control/control-test/control-test.component";
import { ControlTelemetryDialogComponent } from './screens/dashboard/control/control-telemetry/control-telemetry-dialog/control-telemetry-dialog.component';

// Coverage
import { CoverageTestComponent } from "./screens/dashboard/coverage/coverage-test/coverage-test.component";
import { CoverageHeatmapComponent } from "./screens/dashboard/coverage/coverage-heatmap/coverage-heatmap.component";
import { CoverageHeatmapMbusComponent } from "./screens/dashboard/coverage/coverage-heatmap-mbus/coverage-heatmap-mbus.component";

// Cups
import { CupsDetailComponent } from "./screens/dashboard/cups/cups-detail/cups-detail.component";
import { CupsAlarmsComponent } from "./screens/dashboard/cups/cups-alarms/cups-alarms.component";
import { CupsUserLogComponent } from "./screens/dashboard/cups/cups-logs/cups-log-user/cups-log-user.component";
import { AlarmsCUPSConfigurationComponent } from "./screens/dashboard/cups/cups-alarms/cups-alarms-configuration/cups-alarms-configuration.component";
import { CupsAlertsComponent } from "./screens/dashboard/cups/cups-alerts/cups-alerts.component";
import { CupsAlertsDialogComponent } from "./screens/dashboard/cups/cups-alerts/cups-alerts-dialog/cups-alerts-dialog.component";
import { CupsDetailDialogComponent } from "./screens/dashboard/cups/cups-detail/cups-detail-dialog/cups-detail-dialog.component";

// Data analysis
import { MeterGraphComponent } from "./screens/dashboard/data-analysis/data-analysis-meter-graph/data-analysis-meter-graph.component";
import { AdvancedAnalyticsComponent } from "./screens/dashboard/data-analysis/data-analysis-advanced-analytics/data-analysis-advanced-analytics.component";
import { HydraulicBalanceComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance.component";
import { NewHydraulicBalanceComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-new/data-analysis-balance-new.component";
import { HydraulicBalanceGroupComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-group/data-analysis-balance-group.component";
import { BalanceGraphsComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-graphs/data-analysis-balance-graphs.component";
import { DataAnalysisConsumptionEvolutionComponent } from "./screens/dashboard/data-analysis/data-analysis-consumption-evolution/data-analysis-consumption-evolution.component";
import { DataAnalysisBalanceDialogComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-group/data-analysis-balance-dialog/data-analysis-balance-dialog.component";
import { DataAnalysisBalanceCheckerComponent } from "./screens/dashboard/data-analysis/data-analysis-balance/data-analysis-balance-graphs/data-analysis-balance-checker/data-analysis-balance-checker.component";
import { DataAnalysisGraphComparisonComponent } from "./screens/dashboard/data-analysis/data-analysis-graph-comparison/data-analysis-graph-comparison.component";
import { DataAnalysisModelsComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-edit/data-analysis-models.component";
import { DataAnalysisModelsListComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-list/data-analysis-models-list.component";
import { DataAnalysisModelsDialogComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-dialog/data-analysis-models-dialog.component";
import { DataAnalysisModelsMetersComponent } from "./screens/dashboard/data-analysis/data-analysis-models/data-analysis-models-meters/data-analysis-models-meters.component";

// Data management
import { ManagementConfigurationComponent } from "./screens/dashboard/data-management/data-management-configuration/data-management-configuration.component";
import { ManagementAsociationComponent } from "./screens/dashboard/data-management/data-management-association/data-management-association.component";
import { ManagementImportComponent } from "./screens/dashboard/data-management/data-management-import/data-management-import.component";
import { ManagementInfoComponent } from "./screens/dashboard/data-management/data-management-info/data-management-info.component";
import { NewAsociationComponent } from "./screens/dashboard/data-management/data-management-association/data-management-association-new/data-management-association-new.component";
import { MetrologicalListComponent } from "./screens/dashboard/data-management/data-management-metrological/data-management-metrological-list/data-management-metrological-list.component";
import { MetrologicalFormComponent } from "./screens/dashboard/data-management/data-management-metrological/data-management-metrological-form/data-management-metrological-form.component";
import { DataManagementConfigurationDialogComponent } from "./screens/dashboard/data-management/data-management-configuration/data-management-configuration-dialog/data-management-configuration-dialog.component";
import { DataManagementCupsComponent } from "./screens/dashboard/data-management/data-management-cups/data-management-cups.component";
import { DataManagementAssociationDialogComponent } from './screens/dashboard/data-management/data-management-association/data-management-association-dialog/data-management-association-dialog.component';

// Devices
import { DeviceImportComponent } from "./screens/dashboard/devices/imports/device-import/device-import.component";
import { ValveStatesDialogComponent } from "./screens/dashboard/devices/imports/device-import/valve-states-dialog/valve-states-dialog.component";
import { ManufacturerDialogComponent } from "./screens/dashboard/devices/imports/device-import/manufacturer-dialog/manufacturer-dialog.component";
import { MeterLogFramesComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-frames/meter-log-frames.component";
import { MeterLogCommunicationComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-communications/meter-log-communications.component";
import { AlarmsMeterConfigurationComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-alarms/meter-alarms-configuration/meter-alarms-configuration.component";
import { MetersListDeactivatedComponent } from "./screens/dashboard/devices/meter/meter-list/meter-deactivated/meter-deactivated.component";
import { MeterUserLogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-user/meter-log-user.component";
import { ReviewListComponent } from "./screens/dashboard/devices/meter/meter-list/meter-review/meter-review.component";
import { MetersListAssignComponent } from "./screens/dashboard/devices/meter/meter-list/meter-assigned/meter-assigned.component";
import { MetersListNoAssignComponent } from "./screens/dashboard/devices/meter/meter-list/meter-unnassigned/meter-unassigned.component";
import { MetersListInternalComponent } from "./screens/dashboard/devices/meter/meter-list/meter-internal/meter-internal.component";
import { MeterEventLogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-events/meter-log-events.component";
import { MetersDetailComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-detail.component";
import { MeterAlarmsComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-alarms/meter-alarms.component";
import { ConcentratorDetailComponent } from "./screens/dashboard/devices/concentrator/concentrator-detail/concentrator-detail.component";
import { DeviceStatePanelComponent } from "./screens/dashboard/devices/devices-common-components/device-state-panel/device-state-panel.component";
import { DeviceInfoModalComponent } from "./screens/dashboard/devices/devices-common-components/device-info-modal/device-info-modal.component";
import { DeviceEditModalComponent } from "./screens/dashboard/devices/devices-common-components/device-edit-modal/device-edit-modal.component";
import { DeviceAgrupationDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { DeviceEntityDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-entity-dialog/device-entity-dialog.component";
import { DeviceChangeLocationComponent } from "./screens/dashboard/devices/devices-common-components/device-change-location/device-change-location.component";
import { DeviceSectorTableComponent } from "./screens/dashboard/devices/devices-common-components/device-sector-table/device-sector-table.component";
import { DeviceCupsTableComponent } from "./screens/dashboard/devices/devices-common-components/device-cups-table/device-cups-table.component";
import { Ek280ConfigurationComponent } from "./screens/dashboard/devices/ek-280/ek280-configuration/ek280-configuration.component";
import { DeviceSimTableComponent } from "./screens/dashboard/devices/devices-common-components/device-sim-table/device-sim-table.component";
import { DeviceNewComponent } from "./screens/dashboard/devices/device-new/device-new.component";
import { DeviceSimCardsComponent } from "./screens/dashboard/devices/device-sim-cards/device-sim-cards.component";
import { Ek280TestComponent } from "./screens/dashboard/devices/ek-280/ek280-test/ek280-test.component";
import { DeviceGprsListComponent } from "./screens/dashboard/devices/device-gprs-list/device-gprs-list.component";
import { DeviceTypeFilterComponent } from "./screens/dashboard/devices/devices-common-components/device-type-filter/device-type-filter.component";
import { MeterActivationErrorComponent } from "./screens/dashboard/devices/meter/meter-list/meter-activation-error/meter-activation-error.component";
import { DeviceReplaceComponent } from "./screens/dashboard/devices/imports/device-replace/device-replace.component";
import { DeviceReadingsImportComponent } from "./screens/dashboard/devices/imports/device-readings-import/device-readings-import.component";
import { MeterSelectionComponent } from "./screens/dashboard/devices/meter/meter-list/meter-selection/meter-selection.component";
import { DeviceSfUpdateComponent } from "./screens/dashboard/devices/devices-common-components/device-sf-update/device-sf-update.component";
import { DeviceDeactivateComponent } from "./screens/dashboard/devices/imports/device-deactivate/device-deactivate.component";
import { MeterLogFramesDialogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-logs/meter-log-frames/meter-log-frames-dialog/meter-log-frames-dialog.component";
import { DeviceActivationDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-activation-dialog/device-activation-dialog.component";
import { MeterSubstitutionsComponent } from "./screens/dashboard/devices/meter/meter-list/meter-substitutions/meter-substitutions.component";
import { MeterSubstitutionDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-substitutions/meter-substitution-dialog/meter-substitution-dialog.component";
import { MeterUnassignedDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-unnassigned/meter-unassigned-dialog/meter-unassigned-dialog.component";
import { MeterPendingSubstitutionsComponent } from "./screens/dashboard/devices/meter/meter-list/meter-pending-substitutions/meter-pending-substitutions.component";
import { DeviceCupsDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-cups-table/device-cups-dialog/device-cups-dialog.component";
import { DeviceStatePanelDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-state-panel/device-state-panel-dialog/device-state-panel-dialog.component";
import { MeterBatteryComponent } from "./screens/dashboard/devices/meter/meter-list/meter-battery/meter-battery.component";
import { DeviceReplaceRfModalComponent } from "./screens/dashboard/devices/devices-common-components/device-replace-rf-modal/device-replace-rf-modal.component";
import { DeviceConsumptionGraphComponent } from "./screens/dashboard/devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
import { DeviceMoveReadingsDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-move-readings-dialog/device-move-readings-dialog.component";
import { MeterAssignedDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-assigned/meter-assigned-dialog/meter-assigned-dialog.component";
import { DeviceImageImportComponent } from "./screens/dashboard/devices/imports/device-image-import/device-image-import.component";
import { MbusManufacturersComponent } from "./screens/dashboard/devices/mbus/mbus-manufacturers/mbus-manufacturers.component";
import { MbusManufacturersDialogComponent } from "./screens/dashboard/devices/mbus/mbus-manufacturers/mbus-manufacturers-dialog/mbus-manufacturers-dialog.component";
import { MbusUnknownListComponent } from "./screens/dashboard/devices/mbus/mbus-unknown-list/mbus-unknown-list.component";
import { MeterSelectionDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-selection/meter-selection-dialog/meter-selection-dialog.component";
import { DeviceLocationImportComponent } from "./screens/dashboard/devices/imports/device-location-import/device-location-import.component";
import { MeterReviewDialogComponent } from "./screens/dashboard/devices/meter/meter-list/meter-review/meter-review-dialog/meter-review-dialog.component";
import { MbusCandidatesComponent } from "./screens/dashboard/devices/mbus/mbus-candidates/mbus-candidates.component";
import { ConcentratorDialogComponent } from "./screens/dashboard/devices/concentrator/concentrator-dialog/concentrator-dialog.component";
import { ConcentratorCommandsComponent } from "./screens/dashboard/devices/concentrator/concentrator-commands/concentrator-commands.component";
import { MeterOrdersComponent } from "./screens/dashboard/devices/meter/meter-list/meter-orders/meter-orders.component";
import { MeterPendingOrdersComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-pending-orders/meter-pending-orders.component";
import { MeterNewOrderDialogComponent } from "./screens/dashboard/devices/meter/meter-detail/meter-pending-orders/meter-new-order-dialog/meter-new-order-dialog.component";
import { DeviceReplaceDialogComponent } from "./screens/dashboard/devices/devices-common-components/device-replace-dialog/device-replace-dialog.component";

// Entities
import { EntityListComponent } from "./screens/dashboard/entities/entity-list/entity-list.component";
import { EntityFormComponent } from "./screens/dashboard/entities/entity-form/entity-form.component";
import { EntityDialogComponent } from "./screens/dashboard/entities/entity-dialog/entity-dialog.component";

// Errors
import { NotFoundComponent } from "./screens/errors/404/404.component";
import { AccesDeniedComponent } from "./screens/errors/401/401.component";
import { MaintenanceComponent } from "./screens/errors/maintenance/maintenance.component";

// Gateway
import { GatewaysListAssignComponent } from "./screens/dashboard/gateways/gateways-list/gateways-assigned/gateways-assigned.component";
import { GatewaysAvailableComponent } from "./screens/dashboard/gateways/gateways-list/gateways-available/gateways-available.component";
import { GatewaysDetailComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-detail.component";
import { GatewayEventLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-events/gateway-events.component";
import { GatewayDataLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-datalogger/gateway-datalogger.component";
import { GatewayUserLogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-users/gateway-users.component";
import { APPEUIComponent } from "./screens/dashboard/gateways/gateways-appeui/gateways-appeui.component";
import { GatewayResetComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-resets/gateway-resets.component";
import { GatewayAlarmsComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-alarms/gateway-alarms.component";
import { FilesComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-files/gateway-files.component";
import { LoadComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-load/gateway-load.component";
import { GatewayUpateComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-update/gateway-update.component";
import { GatewayConfigureComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-configure/gateway-configure.component";
import { GatewayManagementComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-management/gateway-management.component";
import { GatewayCoverageContrastComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-coverage-contrast/gateway-coverage-contrast.component";
import { GatewayOperatorHistoricalComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-operator-historical/gateway-operator-historical.component";
import { GatewaysAppeuiDialogComponent } from "./screens/dashboard/gateways/gateways-appeui/gateways-appeui-dialog/gateways-appeui-dialog.component";
import { GatewayInstockDialogComponent } from "./screens/dashboard/gateways/gateways-list/gateways-instock/gateway-instock-dialog/gateway-instock-dialog.component";
import { GatewayDetailDialogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-detail-dialog/gateway-detail-dialog.component";
import { GatewayManagementDialogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-management/gateway-management-dialog/gateway-management-dialog.component";
import { GatewayRelaunchFramesComponent } from "./screens/dashboard/gateways/gateway-relaunch-frames/gateway-relaunch-frames.component";
import { GatewayRelaunchFramesDialogComponent } from "./screens/dashboard/gateways/gateway-relaunch-frames/gateway-relaunch-frames-dialog/gateway-relaunch-frames-dialog.component";
import { GatewaysInstockComponent } from "./screens/dashboard/gateways/gateways-list/gateways-instock/gateways-instock.component";
import { GatewayTasksComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-tasks/gateway-tasks.component";
import { GatewayLogDialogComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-log-dialog/gateway-log-dialog.component";
import { GatewaysDamagedComponent } from "./screens/dashboard/gateways/gateways-list/gateways-damaged/gateways-damaged.component";
import { GatewaysRepairComponent } from "./screens/dashboard/gateways/gateways-list/gateways-repair/gateways-repair.component";
import { GatewayAvailableDialogComponent } from "./screens/dashboard/gateways/gateways-list/gateways-available/gateway-available-dialog/gateway-available-dialog.component";
import { GatewayFramesComponent } from "./screens/dashboard/gateways/gateway-detail/gateway-logs/gateway-frames/gateway-frames.component";

// Home
import { HomeComponent } from "./screens/dashboard/home/home.component";

// Graphs
import { GraphModule } from "./modules/graph-module/graph.module";

// IP
import { IPListComponent } from "./screens/dashboard/ip/ip-list/ip-list.component";
import { IpListDialogComponent } from "./screens/dashboard/ip/ip-list/ip-list-dialog/ip-list-dialog.component";

// Login
import { LoginControllerComponent } from "./screens/login/login-controller/login-controller.component";

// Management
import { ManagementEntityGatewaysComponent } from "./screens/dashboard/management/management-entity-gateways/management-entity-gateways.component";
import { ManagementGlobalComponent } from "./screens/dashboard/management/management-global/management-global.component";
import { ManagementNetworkComponent } from "./screens/dashboard/management/management-network/management-network.component";
import { ManagementGlobalSearchComponent } from "./screens/dashboard/management/management-global-search/management-global-search.component";
import { ManagementAlarmsComponent } from "./screens/dashboard/management/management-alarms/management-alarms.component";
import { ManagementCronComponent } from "./screens/dashboard/management/management-cron/management-cron.component";
import { ManagementAgrupationEditionComponent } from "./screens/dashboard/management/management-agrupation-edition/management-agrupation-edition.component";
import { ManagementCommandsTailComponent } from "./screens/dashboard/management/management-commands-tail/management-commands-tail.component";
import { ManagementEntityComponent } from "./screens/dashboard/management/management-entity/management-entity.component";
import { ManagementUserComponent } from "./screens/dashboard/management/management-user/management-user.component";
import { ManagementEntityDialogComponent } from "./screens/dashboard/management/management-entity/management-entity-dialog/management-entity-dialog.component";
import { ManagementCommunicationReportComponent } from "./screens/dashboard/management/management-communication-report/management-communication-report.component";
import { ManagementGlobalDialogComponent } from "./screens/dashboard/management/management-global/management-global-dialog/management-global-dialog.component";
import { ManagementCommandsTailDialogComponent } from "./screens/dashboard/management/management-commands-tail/management-commands-tail-dialog/management-commands-tail-dialog.component";
import { ManagementCronDialogComponent } from "./screens/dashboard/management/management-cron/management-cron-dialog/management-cron-dialog.component";
import { ManagementNetworkDialogComponent } from "./screens/dashboard/management/management-network/management-network-dialog/management-network-dialog.component";
import { ManagementEntityGatewaysDialogComponent } from "./screens/dashboard/management/management-entity-gateways/management-entity-gateways-dialog/management-entity-gateways-dialog.component";
import { ManagementGlobalWarningsComponent } from "./screens/dashboard/management/management-global-warnings/management-global-warnings.component";
import { ManagementLanguageLabelsComponent } from "./screens/dashboard/management/management-language-labels/management-language-labels.component";
import { ManagementManufacturersComponent } from "./screens/dashboard/management/management-manufacturers/management-manufacturers.component";
import { ManagementGlobalConcentratorsComponent } from "./screens/dashboard/management/management-global-concentrators/management-global-concentrators.component";
import {ManagementEntityConcentratorsComponent} from "./screens/dashboard/management/management-entity-concentrators/management-entity-concentrators.component";
import { ManagementUserEventsComponent } from "./screens/dashboard/management/management-user-events/management-user-events.component";
import { ClientEntityGatewaysComponent } from "./screens/dashboard/management/client-entity-gateways/client-entity-gateways.component";

// Map
import { MapModule } from "./modules/map-module/map.module";

// Nav
import { NavModule } from "./modules/nav-module/nav.module";
import { PanelModule } from "./modules/panel-module/panel.module";

// Network state
import { BillingEntityComponent } from "./screens/dashboard/network-state/network-state-entities/network-state-entity.component";
import { BillingAgrupationComponent } from "./screens/dashboard/network-state/network-state-agrupation/network-state-agrupation.component";
import { NetworkStateKpisComponent } from "./screens/dashboard/network-state/network-state-kpis/network-state-kpis.component";
import { NetworkStateGlobalComponent } from "./screens/dashboard/network-state/network-state-global/network-state-global.component";
import { TotalsSummaryComponent } from "./screens/dashboard/network-state/totals-summary/totals-summary.component";

// Notificatons
import { NotificationModule } from "./modules/notification-module/notification.module";

// Output files
import { DownloadFilesComponent } from "./screens/dashboard/output-files/output-files-download/output-files-download.component";
import { TemplateFilesComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-templates-list/output-files-templates-list.component";
import { TemplateFormComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-templates-form/output-files-templates-form.component";
import { OutputFileEk280Component } from "./screens/dashboard/output-files/output-file-ek280/output-file-ek280.component";
import { OutputFilesTimerComponent } from "./screens/dashboard/output-files/output-files-timer/output-files-timer.component";
import { OutputFilesAqualiaComponent } from "./screens/dashboard/output-files/output-files-aqualia/output-files-aqualia.component";
import { OutputFilesAqualiaDialogComponent } from "./screens/dashboard/output-files/output-files-aqualia/output-files-aqualia-dialog/output-files-aqualia-dialog.component";
import { OutputFilesTemplateExportComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-template-export/output-files-template-export.component";
import { OutputFilesTemplateCronComponent } from "./screens/dashboard/output-files/output-files-templates/output-files-template-cron/output-files-template-cron.component";

// Queries
import { QueriesComponent } from "./screens/dashboard/queries/queries.component";

// Routes
import { RoutesModule } from "./routes.module";
import { RouteReuseStrategy } from "@angular/router";
import { CustomReuseStrategy } from "./reuse-strategy";

// Satelite
import { SateliteSchedulerComponent } from "./screens/dashboard/devices/satelite/satelite-scheduler/satelite-scheduler.component";
import { SateliteTurnsClockComponent } from "./screens/dashboard/devices/satelite/satelite-scheduler/satelite-turns-clock/satelite-turns-clock.component";
import { SateliteModifyDialogComponent } from "./screens/dashboard/devices/satelite/satelite-modify-dialog/satelite-modify-dialog.component";
import { SateliteOffsetDialogComponent } from "./screens/dashboard/devices/satelite/satelite-scheduler/satelite-offset-dialog/satelite-offset-dialog.component";

// Sensory
import { SensoryLeaksMapComponent } from "./screens/dashboard/sensory/sensory-leaks/sensory-leaks-map/sensory-leaks-map.component";
import { SensoryLeaksDialogComponent } from "./screens/dashboard/sensory/sensory-leaks/sensory-leaks-dialog/sensory-leaks-dialog.component";
import { SensoryPressureMapComponent } from "./screens/dashboard/sensory/sensory-pressure/sensory-pressure-map/sensory-pressure-map.component";
import { SensoryPressureDialogComponent } from "./screens/dashboard/sensory/sensory-pressure/sensory-pressure-dialog/sensory-pressure-dialog.component";
import { SensoryFlowMapComponent } from "./screens/dashboard/sensory/sensory-flow/sensory-flow-map/sensory-flow-map.component";
import { SensorDataComponent } from "./screens/dashboard/devices/sensor/sensor-data/sensor-data.component";

// Subscriber
import { SubscriberModule } from "./modules/subscriber-module/subscriber.module";

// Table
import { TableModule } from "./modules/table-module/table.module";

// Themes
import { ThemeModule } from "./themes/theme.module";
import { DEFAULT_THEME } from "./themes/default-theme";
import { DARK_THEME } from "./themes/dark-theme";
import { WATER_THEME } from "./themes/water-theme";
import { NORTEGAS_THEME } from "./themes/nortegas-theme";
import { GAS_THEME } from "./themes/gas-theme";

// Timeout
import { BnNgIdleService } from "bn-ng-idle";

// Traductor
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Users
import { UsersListComponent } from "./screens/dashboard/users/users-list/user-list.component";
import { UserFormComponent } from "./screens/dashboard/users/users-form/users-form.component";
import { UsersImport } from "./screens/dashboard/users/users-import/users-import.component";

// Resize
import { AngularResizeEventModule } from "angular-resize-event";

// Selectors
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

// Slider button
import { SliderButtonComponent } from "./global/slider-button/slider-button.component";

// Spinner
import { NgxSpinnerModule } from "ngx-spinner";

/****************************************************************************************************/

// Services
import { HttpRequestInterceptorService } from "./services/shared/HttpRequestServices/HttpRequestInterceptorService.service";

/****************************************************************************************************/

// Directives
import { DirectivesModule } from "./directives/directives.module";

// Imports para el formato de las fechas
import es from "@angular/common/locales/es";
import en from "@angular/common/locales/en";
import fr from "@angular/common/locales/fr";
import cat from "@angular/common/locales/ca-ES-VALENCIA";
import el from "@angular/common/locales/el";
import { registerLocaleData } from "@angular/common";
registerLocaleData(es);
registerLocaleData(en);
registerLocaleData(fr);
registerLocaleData(cat);
registerLocaleData(el);

// Service Worker
import { ServiceWorkerModule } from "@angular/service-worker";
import { ENVIRONMENT } from "../environments/environment";
import { LANGUAGE } from "./services/language/LanguageController.service";
import { DeviceDeleteReadingsComponent } from './screens/dashboard/devices/devices-common-components/device-delete-readings/device-delete-readings.component';
import { DataManagementNoCupsComponent } from "./screens/dashboard/data-management/data-management-no-cups/data-management-no-cups.component";
import { MeterVoltageDialogComponent } from './screens/dashboard/devices/meter/meter-list/meter-voltage/meter-voltage-dialog/meter-voltage-dialog.component';
import { MeterIgnoredComponent } from "./screens/dashboard/devices/meter/meter-list/meter-ignored/meter-ignored.component";

/****************************************************************************************************/
// Module imports, declarations & providers
/****************************************************************************************************/

@NgModule({
  imports: [
    ThemeModule.forRoot({
      themes: [
        DEFAULT_THEME,
        DARK_THEME,
        WATER_THEME,
        NORTEGAS_THEME,
        GAS_THEME,
      ],
      active: "default",
    }),

    // @angular
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // @angular-material
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSliderModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    MatSlideToggleModule,
    MaterialModule,
    ScrollingModule,
    NgxSliderModule,

    // Cards
    CardModule,

    // Cesium
    AngularCesiumModule.forRoot(),
    CesiumMapModule,

    // Directives
    DirectivesModule,

    // Graph
    GraphModule,

    // Map
    MapModule,

    // Nav
    NavModule,
    PanelModule,

    // Notification
    NotificationModule,

    // Routes
    RoutesModule,

    // Selectors
    NgxDaterangepickerMd.forRoot({
      format: "DD/MM/YYYY",
      direction: "ltr",
      weekLabel: "W",
      separator: " - ",
      clearLabel: "",
      firstDay: 1,
    }),

    // Spinner
    NgxSpinnerModule,

    // Table
    TableModule,

    // Traductor
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    // Resize
    AngularResizeEventModule,

    // Service Worker
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: ENVIRONMENT.production,
      // enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),

    // Subscriber
    SubscriberModule,
  ],
  declarations: [
    AppComponent,

    // Alarms
    AlarmsComponent,
    AlarmsAgrupationConfigurationComponent,
    AlarmsEntityConfigurationComponent,
    AlarmsMeterConfigurationComponent,
    AlarmsCUPSConfigurationComponent,
    AlarmsListComponent,
    AlarmsDialogComponent,
    AlarmsGlobalComponent,
    AlarmEditDialogComponent,
    AlarmsEntityTemplateComponent,
    AlarmsEntityTemplateDialogComponent,
    AlarmsCustomConfigComponent,
    AlarmsCustomPanelComponent,
    AlarmsDevicesComponent,

    // Agrupations
    AgrupationListComponent,
    AgrupationFormComponent,
    AgrupationUserLogComponent,

    // Clients
    ClientListComponent,
    ClientFormComponent,

    // Control
    ControlComponent,
    ControlModalComponent,
    ControlHistoricComponent,
    ControlHistoricDialogComponent,
    ControlTelemetryComponent,
    ControlTestComponent,
    ControlTelemetryDialogComponent,

    // Coverage
    CoverageTestComponent,
    CoverageHeatmapComponent,
    CoverageHeatmapMbusComponent,

    // Cups
    CupsDetailComponent,
    CupsAlarmsComponent,
    CupsUserLogComponent,
    CupsAlertsComponent,
    CupsAlertsDialogComponent,
    CupsDetailDialogComponent,

    // Data anlysis
    MeterGraphComponent,
    HydraulicBalanceComponent,
    NewHydraulicBalanceComponent,
    HydraulicBalanceGroupComponent,
    AdvancedAnalyticsComponent,
    BalanceGraphsComponent,
    DataAnalysisConsumptionEvolutionComponent,
    DataAnalysisBalanceDialogComponent,
    DataAnalysisBalanceCheckerComponent,
    DataAnalysisGraphComparisonComponent,
    DataAnalysisModelsComponent,
    DataAnalysisModelsListComponent,
    DataAnalysisModelsDialogComponent,
    DataAnalysisModelsMetersComponent,

    // Data management
    ManagementConfigurationComponent,
    ManagementInfoComponent,
    ManagementAsociationComponent,
    ManagementImportComponent,
    MetrologicalFormComponent,
    MetrologicalListComponent,
    NewAsociationComponent,
    DataManagementConfigurationDialogComponent,
    DataManagementCupsComponent,
    DataManagementNoCupsComponent,
    DataManagementAssociationDialogComponent,
    // Devices
    MeterBatteryDialogComponent,
    DeviceImportComponent,
    ValveStatesDialogComponent,
    ManufacturerDialogComponent,
    MeterEventLogComponent,
    MetersDetailComponent,
    MetersListAssignComponent,
    MetersListNoAssignComponent,
    MeterLogFramesComponent,
    MeterLogCommunicationComponent,
    MeterUserLogComponent,
    MetersListDeactivatedComponent,
    MeterAlarmsComponent,
    MetersListInternalComponent,
    ConcentratorDetailComponent,
    DeviceStatePanelComponent,
    DeviceInfoModalComponent,
    DeviceEditModalComponent,
    DeviceAgrupationDialogComponent,
    DeviceEntityDialogComponent,
    DeviceChangeLocationComponent,
    DeviceSectorTableComponent,
    DeviceCupsTableComponent,
    Ek280ConfigurationComponent,
    DeviceSimTableComponent,
    DeviceNewComponent,
    DeviceSimCardsComponent,
    Ek280TestComponent,
    DeviceGprsListComponent,
    DeviceTypeFilterComponent,
    MeterActivationErrorComponent,
    DeviceReplaceComponent,
    DeviceReadingsImportComponent,
    MeterSelectionComponent,
    SateliteSchedulerComponent,
    DeviceSfUpdateComponent,
    DeviceDeactivateComponent,
    MeterLogFramesDialogComponent,
    DeviceActivationDialogComponent,
    MeterSubstitutionsComponent,
    MeterSubstitutionDialogComponent,
    MeterUnassignedDialogComponent,
    MeterPendingSubstitutionsComponent,
    DeviceCupsDialogComponent,
    DeviceStatePanelDialogComponent,
    MeterBatteryComponent,
    DeviceReplaceRfModalComponent,
    DeviceConsumptionGraphComponent,
    DeviceMoveReadingsDialogComponent,
    MeterAssignedDialogComponent,
    DeviceImageImportComponent,
    MbusManufacturersComponent,
    MbusManufacturersDialogComponent,
    MbusUnknownListComponent,
    MeterSelectionDialogComponent,
    DeviceLocationImportComponent,
    MeterReviewDialogComponent,
    MbusCandidatesComponent,
    ConcentratorDialogComponent,
    ConcentratorCommandsComponent,
    MeterOrdersComponent,
    MeterPendingOrdersComponent,
    MeterNewOrderDialogComponent,
    DeviceReplaceDialogComponent,
    MeterIgnoredComponent,

    // Entities
    EntityListComponent,
    EntityFormComponent,
    EntityDialogComponent,

    // Errors
    NotFoundComponent,
    AccesDeniedComponent,
    MaintenanceComponent,

    // Gateway
    GatewaysListAssignComponent,
    GatewaysAvailableComponent,
    GatewaysDetailComponent,
    GatewayEventLogComponent,
    GatewayDataLogComponent,
    GatewayUserLogComponent,
    GatewayConfigureComponent,
    GatewayManagementComponent,
    GatewayUpateComponent,
    GatewayResetComponent,
    GatewayAlarmsComponent,
    LoadComponent,
    APPEUIComponent,
    ReviewListComponent,
    GatewayCoverageContrastComponent,
    GatewayOperatorHistoricalComponent,
    GatewaysAppeuiDialogComponent,
    GatewayInstockDialogComponent,
    GatewayDetailDialogComponent,
    GatewayManagementDialogComponent,
    GatewayRelaunchFramesComponent,
    GatewayRelaunchFramesDialogComponent,
    GatewaysInstockComponent,
    GatewayTasksComponent,
    GatewayLogDialogComponent,
    GatewaysDamagedComponent,
    GatewaysRepairComponent,
    GatewayAvailableDialogComponent,
    GatewayFramesComponent,

    // Home
    HomeComponent,

    // IP
    IPListComponent,
    IpListDialogComponent,

    // Login
    LoginControllerComponent,

    // Management
    ManagementEntityGatewaysComponent,
    ManagementGlobalComponent,
    ManagementNetworkComponent,
    ManagementGlobalSearchComponent,
    ManagementAlarmsComponent,
    ManagementCronComponent,
    ManagementAgrupationEditionComponent,
    ManagementCommandsTailComponent,
    ManagementEntityComponent,
    ManagementUserComponent,
    ManagementEntityDialogComponent,
    ManagementCommunicationReportComponent,
    ManagementGlobalDialogComponent,
    ManagementCommandsTailDialogComponent,
    ManagementCronDialogComponent,
    ManagementNetworkDialogComponent,
    ManagementEntityGatewaysDialogComponent,
    ManagementGlobalWarningsComponent,
    ManagementLanguageLabelsComponent,
    ManagementManufacturersComponent,
    ManagementGlobalConcentratorsComponent,
    ManagementEntityConcentratorsComponent,
    ManagementUserEventsComponent,
    ClientEntityGatewaysComponent,

    // Network state
    BillingAgrupationComponent,
    BillingEntityComponent,
    NetworkStateKpisComponent,
    NetworkStateGlobalComponent,
    TotalsSummaryComponent,

    // Output files
    FilesComponent,
    TemplateFilesComponent,
    DownloadFilesComponent,
    TemplateFormComponent,
    OutputFileEk280Component,
    OutputFilesTimerComponent,
    OutputFilesAqualiaComponent,
    OutputFilesAqualiaDialogComponent,
    OutputFilesTemplateExportComponent,
    OutputFilesTemplateCronComponent,

    // Queries
    QueriesComponent,

    // Sensory
    SensoryLeaksMapComponent,
    SensoryLeaksDialogComponent,
    SensoryPressureMapComponent,
    SensoryPressureDialogComponent,
    SensoryFlowMapComponent,
    SensorDataComponent,

    // Satelite
    SateliteTurnsClockComponent,
    SateliteModifyDialogComponent,
    SateliteOffsetDialogComponent,

    // Slider button
    SliderButtonComponent,

    // Users
    UsersListComponent,
    UserFormComponent,
    UsersImport,
    DeviceDeleteReadingsComponent,
    MeterVoltageDialogComponent,
  ],
  providers: [
    BnNgIdleService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "es-ES" },
    { provide: LOCALE_ID, useValue: "en-EN" },
    { provide: LOCALE_ID, useValue: "es-CA" },
    { provide: LOCALE_ID, useValue: "fr-FR" },
    { provide: LOCALE_ID, useValue: "el-GR" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true,
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
})

/****************************************************************************************************/
// Exports
/****************************************************************************************************/
export class AppModule {}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      const storedLang = localStorage.getItem("lang");

      locationInitialized.then(() => {
        translate.addLangs([
          LANGUAGE.ESPANOL,
          LANGUAGE.CATALAN,
          LANGUAGE.INGLES,
          LANGUAGE.FRANCES,
          LANGUAGE.GRIEGO,
        ]);
        let browserLang = translate.getBrowserLang();
        let lang: string;

        if (browserLang.match(/en|es|fr|es-ca|el/)) {
          lang = browserLang.toLowerCase();
        } else {
          lang = LANGUAGE.INGLES;
        }

        if (translate.getLangs().includes(storedLang ? storedLang : lang)) {
          translate.use(storedLang ? storedLang : lang).subscribe({
            error: (e) => console.error(e),
            complete: () => resolve(null),
          });
        } else {
          translate.use(LANGUAGE.INGLES).subscribe({
            error: (e) => console.error(e),
            complete: () => resolve(null),
          });
        }
      });
    });
}
