// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment-timezone";
import moment_timezone from "moment-timezone";
// Fichero
import saveAs from "file-saver";
// Servicios propios
import { OutputFilesControllerService } from "../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { DatePickerConfigService } from "../../../../services/shared/DatePickerConfigService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { HttpRequestService } from "../../../../services/shared/HttpRequestServices/HttpRequestService.service";
import { DataConverterService } from "../../../../services/shared/DataConverterService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import { AtmResponse, OutputFileData } from "../OutputFileInterface.type";
// Componentes
import { FileImportComponent } from "../../../../modules/material-module/file-import/file-import.component";
// Variables
import { DEFAULT_FILES } from "./output-files-default-files";
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-templateFiles",
  templateUrl: "./output-files-download.component.html",
  styleUrls: ["./output-files-download.component.scss"],
})
export class DownloadFilesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Fichero
  fileUnits: string[];
  cupsOptions: any[];
  agrupationList: any[];
  selectedAgrupations: Agrupation[];
  selectedCupsColumns: EntityDefinition[];
  selectedTimePeriod: number = 0;
  closestValueMargin: number = 1;
  columnsAlreadyIncluded: string = "(";

  // Selector de fecha
  daterangePickerLang: any;
  intialRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("6", "days"),
    endDate: moment().endOf("day"),
  };
  dateRangeSelected: { startDate: moment.Moment; endDate: moment.Moment } =
    JSON.parse(JSON.stringify(this.intialRange));
  initialDate: { startDate: moment.Moment } = {
    startDate: moment().endOf("day"),
  };
  dateSelected: { startDate: moment.Moment } = JSON.parse(
    JSON.stringify(this.initialDate)
  );
  dateOutOfRange: boolean = false;
  limitOutOfRange: boolean = false;

  // Selectores
  fileTypeSelected: string;
  fileTypes: { type: string }[] = [];

  // Archivo csv
  fileToImport: File = null;
  errorFile: string;

  // Deshabilitaciones
  disableAgrupation: boolean = false;
  disableCups: boolean = false;
  disableLastValue: boolean = false;
  disableClosestValue: boolean = false;
  disableInterval: boolean = false;

  // Ficheros
  @ViewChild("fileImportInput") fileImportInput: FileImportComponent;
  atmResponse: AtmResponse;
  showAtmMeters: boolean;
  showAtmReadings: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataConverterService: DataConverterService,
    private DatePickerConfigService: DatePickerConfigService,
    private OutputFilesController: OutputFilesControllerService,
    private HttpRequestService: HttpRequestService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.entityCupsConf.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Unidades
    this.fileUnits = [
      this.translate.instant("cubic-meter"),
      this.translate.instant("liters"),
    ];

    // Columnas de CUPS
    this.cupsOptions = this.currentEntityCupsConf?.filter(
      (column: EntityDefinition) =>
        column.colPosition != 0 && column.colPosition != 100
    );

    // Agrupaciones
    this.agrupationList = this.currentEntity.agrupations.filter(
      (agrupation: Agrupation) => !agrupation.showAllEntity
    );

    // Texto aclaratorio columnas
    let nroSerieLabel = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 100
    )?.label;
    let cups = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.label;

    this.columnsAlreadyIncluded +=
      (nroSerieLabel ? nroSerieLabel : "NRO_SERIE") +
      ", " +
      (cups ? cups : "CUPS") +
      ", Timestamp, " +
      this.translate.instant("date") +
      ", " +
      this.translate.instant("groups") +
      " " +
      this.translate.instant("y") +
      " " +
      this.translate.instant("value") +
      " " +
      this.translate.instant("already-included") +
      ")";

    // Selector de fecha
    let DatePickerConfigService =
      this.DatePickerConfigService.setDateRangePickerOptions();
    this.daterangePickerLang = DatePickerConfigService.daterangePickerLang;

    // Ficheros por defecto para clientes/entidades/agrupaciones concretas
    for (let file in DEFAULT_FILES) {
      if (
        (!DEFAULT_FILES[file].clients ||
          DEFAULT_FILES[file].clients.includes(this.currentClient.clientId)) &&
        (!DEFAULT_FILES[file].entities ||
          DEFAULT_FILES[file].entities.includes(this.currentEntity.id)) &&
        (!DEFAULT_FILES[file].agrupations ||
          DEFAULT_FILES[file].agrupations.includes(
            this.currentAgrupation.id
          )) &&
        DEFAULT_FILES[file].profiles.includes(this.sessionProfile)
      ) {
        this.fileTypes.push({ type: file });
      }
    }
  }

  // Descarga de fichero
  download(): void {
    let date: string;
    let startDate: any;
    let endDate: any;
    let requestUrl: Observable<object>;
    let data: OutputFileData = {
      agrupations: this.selectedAgrupations.map(
        (agrupation: Agrupation) => agrupation.id
      ),
      extraData: this.selectedCupsColumns
        ? this.selectedCupsColumns.map(
            (column: EntityDefinition) => column.colPosition
          )
        : [],
    };

    // Comprobación de fecha fuera de rango
    if (
      this.sessionProfile != PROFILES.ARSON &&
      this.selectedTimePeriod == 2 &&
      this.dateOutOfRange
    ) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("wrong-date-interval")
      );
    } // Comprobación de fecha fuera de rango
    else if (
      this.sessionProfile != PROFILES.ARSON &&
      this.selectedTimePeriod == 1 &&
      this.limitOutOfRange
    ) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("wrong-date-limit")
      );
    } else {
      // Por valor más cercano
      if (this.selectedTimePeriod == 1) {
        date = this.dateSelected.startDate
          .tz(this.currentAgrupation.timezone, true)
          .startOf("day")
          .valueOf()
          .toString();
        requestUrl = this.OutputFilesController.downloadFileByClosestValue(
          date,
          this.closestValueMargin,
          data
        );
        // Por intervalo
      } else if (this.selectedTimePeriod == 2) {
        startDate = moment_timezone(this.dateRangeSelected.startDate)
          .tz(this.currentAgrupation.timezone, true)
          .startOf("day")
          .valueOf()
          .toString();

        endDate = moment_timezone(this.dateRangeSelected.endDate)
          .tz(this.currentAgrupation.timezone, true)
          .endOf("day")
          .valueOf()
          .toString();

        requestUrl = this.OutputFilesController.downloadFileByInterval(
          startDate,
          endDate,
          data
        );
        // Por último valor
      } else {
        requestUrl = this.OutputFilesController.downloadFileByLastValue(data);
      }

      // Descarga
      requestUrl.subscribe((response: any) => {
        let parsedResponse: any;
        let reader = new FileReader();

        reader.addEventListener(
          "load",
          () => {
            parsedResponse = reader.result;
            if (
              this.isJSON(parsedResponse) &&
              JSON.parse(parsedResponse).code != 0
            ) {
              this.HttpRequestService.getErrorText(JSON.parse(parsedResponse));
            } else {
              saveAs(response, this.translate.instant("export-file") + ".csv");
            }
          },
          false
        );

        if (response) {
          reader.readAsText(response, "utf8");
        }
      });
    }
  }

  // Comprobación de si un string es covertible a JSON
  isJSON(str: string) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }

  // Comprobación del rango de fecha
  checkDateRange(): void {
    let diff = Math.floor(
      this.dateRangeSelected.endDate?.diff(this.dateRangeSelected.startDate) /
        (24 * 3600000)
    );
    if (diff > 7) {
      this.dateOutOfRange = true;
    } else {
      this.dateOutOfRange = false;
    }
  }

  // Actualización de tipo de fichero
  updateFileType(): void {
    switch (this.fileTypeSelected) {
      case "ATM":
      case "ATMGTnet":
      case "IZFE":
      case "UGENA":
      case "GEISER":
        this.disableAgrupation = true;
        this.disableCups = true;
        this.disableLastValue = true;
        this.disableInterval = true;
        this.fileToImport = null;
        this.atmResponse = null;
        this.fileImportInput?.clear();
        break;
      case "POSEIDONIA":
        this.disableCups = true;
        this.disableLastValue = true;
        this.disableInterval = true;
        this.fileToImport = null;
        this.atmResponse = null;
        this.fileImportInput?.clear();
        break;
      default:
        this.enableAll();
        break;
    }
  }

  // Habilitación de todos los selectores
  enableAll(): void {
    this.disableAgrupation = false;
    this.disableCups = false;
    this.disableLastValue = false;
    this.disableInterval = false;
    this.disableClosestValue = false;
  }

  // Descarga de fichero ATM/GEISER
  downloadByFile(): void {
    let date = this.dateSelected.startDate
      .tz(this.currentAgrupation.timezone, true)
      .startOf("day")
      .valueOf();
    let formData: FormData = new FormData();
    formData.set("file", this.fileToImport);
    this.getFileUrl(date, formData)?.subscribe((response) => {
      if (response["code"] == 0 && response["body"]?.file) {
        if (
          this.fileTypeSelected == "ATM" ||
          this.fileTypeSelected == "ATMGTnet" ||
          this.fileTypeSelected == "IZFE"
        ) {
          this.atmResponse = response["body"];
        }
        saveAs(
          this.DataConverterService.getDownloadBlobFromByteFile(
            response["body"].file,
            "text/plain;charset=ansi"
          ),
          this.translate.instant("processed").toUpperCase() +
            "_" +
            (this.fileToImport?.name
              ? this.fileToImport?.name
              : this.fileTypeSelected + ".csv")
        );
      } else if (response["code"] == 0 && !response["body"]?.file) {
        this.ToastService.fireToast(
          "warning",
          this.translate.instant("file-not-available")
        );
      }
    });
  }

  // Obtención del servicio del fichero correspondiente
  getFileUrl(date: number, data: FormData): Observable<object> {
    switch (this.fileTypeSelected) {
      case "ATM":
        return this.OutputFilesController.downloadAtmFile(
          this.currentEntity.id,
          date,
          this.closestValueMargin,
          data
        );
      case "ATMGTnet":
        return this.OutputFilesController.downloadAtmXmlFile(
          this.currentEntity.id,
          date,
          this.closestValueMargin,
          data
        );
      case "IZFE":
        return this.OutputFilesController.downloadIzfeFile(
          this.currentEntity.id,
          date,
          this.closestValueMargin,
          data
        );
      case "GEISER":
        return this.OutputFilesController.downloadGeiserFile(
          this.currentClient.clientId,
          date,
          this.closestValueMargin,
          data
        );
      case "UGENA":
        return this.OutputFilesController.downloadUgenaFile(
          this.currentEntity.id,
          date,
          this.closestValueMargin
        );
      case "POSEIDONIA":
        return this.OutputFilesController.downloadPoseidoniaFile(
          date,
          this.closestValueMargin,
          {
            agrupations: this.selectedAgrupations.map(
              (agrupation) => agrupation.id
            ),
          }
        );
      default:
        return;
    }
  }
}
