<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <div class="cups-detail-heading-info">
        <i
          class="fas fa-bell pointer"
          [ngClass]="{ 'cups-notification-active': pendingNotifications > 0 }"
          title="{{ 'notifications' | translate }}"
          (click)="
            pendingNotifications = 0;
            notificationModal.showNotifications(cupsId)
          "
        >
        </i>
      </div>

      <h5 class="cups-detail-title">
        <b
          >{{ currentEntityCupsName ? currentEntityCupsName + ": " : null }}
          {{ cupsData?.data?.clave }}
        </b>
        <span *ngIf="subscriberData" class="cups-detail-subscriber">
          <b>{{ "profiles11" | translate }}: {{ subscriberData.name }}</b>
        </span>
      </h5>

      <!-- ANCHOR Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>

    <div class="panel-body cups-detail-info">
      <!-- ANCHOR Tabla de CUPS -->
      <app-device-cups-table
        *ngIf="cupsData"
        [cupsData]="cupsData"
        [source]="'cups'"
        [sessionProfile]="sessionProfile"
      >
      </app-device-cups-table>

      <div class="cups-detail-box">
        <div class="cups-detail-cards-box">
          <!-- ANCHOR Tarjetas de contador -->
          <div class="cups-detail-cards">
            <!-- Tarjetas de datos -->
            <app-cards-controller
              [cardsId]="'cupsDetail'"
              [data]="cardsData"
              [metrologyType]="
                isGas ? METROLOGY_TYPE.GAS : METROLOGY_TYPE.WATER
              "
              [cardStretch]="true"
              [cardWidth]="30"
              [cardsGap]="1.5"
            >
            </app-cards-controller>
          </div>

          <!-- Listado de sensores -->
          <div *ngIf="sensorList; else elseBlock" class="cups-alarms">
            <table>
              <tr class="table-bg-blue-gradient">
                <th colspan="2">
                  <i class="fa fa-wave-square"></i>
                  {{ "sensors" | translate }}
                  <i
                    *ngIf="
                      sessionProfile == PROFILES.ARSON ||
                      sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                      sessionProfile == PROFILES.ADMIN_AGRUPACION
                    "
                    title="{{ 'new' | translate }}"
                    (click)="showModal('1', 'deviceAssign')"
                    class="fas fa-plus pointer cups-alarms-table-link"
                  ></i>
                </th>
              </tr>
              <tr *ngIf="sensorList.length == 0 || sensorList == null">
                <td>
                  {{ "no-sensors" | translate }}
                </td>
              </tr>
              <ng-container *ngFor="let sensor of sensorList">
                <tr>
                  <td class="cups-alarms-table-date">
                    <i
                      title="{{ 'delete' | translate }}"
                      (click)="unassignDevice(sensor.id)"
                      class="pointer fas fa-trash cups-detail-card-buttons"
                    ></i>
                  </td>
                  <td class="cups-alarms-table-alarm">
                    <span
                      (click)="openLink(sensor)"
                      (contextmenu)="openLink(sensor, true)"
                      class="pointer"
                    >
                      {{ sensor.nroSerie }}
                      <i class="fas fa-link cups-detail-table-link"></i>
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>

          <!-- ANCHOR Listado de contadores -->
          <div *ngIf="meters; else elseBlock" class="cups-alarms">
            <table>
              <tr class="table-bg-blue-gradient">
                <th colspan="2">
                  <i class="fas fa-tachometer-alt"></i>
                  {{ "meters" | translate }}
                </th>
                <th>
                  <i class="fas fa-database"></i
                  >{{ "last-know-value" | translate }}
                </th>
                <th>
                  <i class="fas fa-calendar-day"></i>
                  {{ "date" | translate }}
                  <i
                    *ngIf="
                      sessionProfile == PROFILES.ARSON ||
                      sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                      sessionProfile == PROFILES.ADMIN_AGRUPACION
                    "
                    title="{{ 'new' | translate }}"
                    (click)="showModal('-1', 'deviceAssign')"
                    class="pointer fas fa-plus cups-alarms-table-link"
                  ></i>
                </th>
              </tr>
              <tr *ngIf="!metersAvailable">
                <td>
                  {{ "no-meters" | translate }}
                </td>
              </tr>
              <ng-container *ngFor="let meter of meters">
                <tr *ngIf="meter.metrologyType != METROLOGY_TYPE.WATER_VALVE">
                  <td class="cups-alarms-table-date">
                    <i
                      *ngIf="
                        sessionProfile == PROFILES.ARSON ||
                        sessionProfile == PROFILES.ADMIN_CLIENTE ||
                        sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                        sessionProfile == PROFILES.ADMIN_AGRUPACION
                      "
                      title="{{ 'replace' | translate }}"
                      (click)="
                        showModal(
                          meter.metrologyType,
                          'deviceReplace',
                          meter.id
                        )
                      "
                      [ngClass]="{ disabled: !meter.id }"
                      class="pointer fas fa-exchange-alt cups-detail-card-buttons"
                    ></i>
                    <i
                      *ngIf="
                        sessionProfile == PROFILES.ARSON ||
                        sessionProfile == PROFILES.ADMIN_CLIENTE ||
                        sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                        sessionProfile == PROFILES.ADMIN_AGRUPACION
                      "
                      title="{{ 'meter-orders' | translate }}"
                      [routerLink]="[
                        '/dispositivos/detalle/ordenes/' + meter.id
                      ]"
                      [state]="{ data: this.meter }"
                      [ngClass]="{ disabled: !meter.id }"
                      class="pointer fas fa-list-check cups-detail-card-buttons"
                    ></i>
                    <i
                      *ngIf="
                        sessionProfile == PROFILES.ARSON ||
                        sessionProfile == PROFILES.ADMIN_CLIENTE ||
                        sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                        sessionProfile == PROFILES.ADMIN_AGRUPACION
                      "
                      title="{{ 'delete' | translate }}"
                      (click)="unassignDevice(meter.id)"
                      [ngClass]="{ disabled: !meter.id }"
                      class="pointer fas fa-trash cups-detail-card-buttons"
                    ></i>
                  </td>
                  <td class="cups-alarms-table-alarm">
                    <span *ngIf="!meter.id">
                      {{ meter.nroSerie }}
                    </span>
                    <span
                      *ngIf="meter.id"
                      (click)="openLink(meter)"
                      (contextmenu)="openLink(meter, true)"
                      class="pointer"
                    >
                      {{ meter.nroSerie }}
                      <i class="fas fa-link cups-detail-table-link"></i>
                    </span>
                  </td>
                  <!-- Último valor -->
                  <td class="cups-alarms-table-alarm">
                    <span class="center-block">
                      {{ meter.lastReadedValueParsed }}</span
                    >
                  </td>
                  <!-- Fecha último valor -->
                  <td class="cups-alarms-table-alarm">
                    <span class="center-block"
                      >{{ meter.lastReadedTimestampParsed }}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>

          <!-- ANCHOR Listado de válvulas -->
          <div *ngIf="meters; else elseBlock" class="cups-alarms">
            <table>
              <tr class="table-bg-blue-gradient">
                <th colspan="2">
                  <i class="fa fa-faucet"></i>
                  {{ "valve-state" | translate }}
                </th>
                <th>
                  <i class="fas fa-tachometer-alt"></i
                  >{{ "device" | translate }}
                  <i
                    *ngIf="
                      sessionProfile == PROFILES.ARSON ||
                      sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                      sessionProfile == PROFILES.ADMIN_AGRUPACION
                    "
                    title="{{ 'new' | translate }}"
                    (click)="
                      showModal(METROLOGY_TYPE.WATER_VALVE, 'deviceAssign')
                    "
                    class="fas fa-plus pointer cups-alarms-table-link"
                  ></i>
                </th>
              </tr>
              <tr *ngIf="meters.length == 0 || meters == null || noValve">
                <td>
                  {{ "no-valves" | translate }}
                </td>
              </tr>
              <ng-container *ngIf="!noValve">
                <ng-container *ngFor="let meter of meters">
                  <tr *ngIf="meter.valveState != null">
                    <td
                      class="cups-alarms-table-date"
                      [ngClass]="{ disabled: !meter.isLora }"
                    >
                      <i
                        *ngIf="
                          sessionProfile == PROFILES.ARSON ||
                          sessionProfile == PROFILES.ADMIN_CLIENTE ||
                          sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                          sessionProfile == PROFILES.ADMIN_AGRUPACION
                        "
                        class="fas fa-lock-open pointer cups-detail-card-buttons"
                        title="{{ 'open' | translate }}"
                        (click)="openValve(meter)"
                      ></i>
                      <i
                        *ngIf="
                          sessionProfile == PROFILES.ARSON ||
                          sessionProfile == PROFILES.ADMIN_CLIENTE ||
                          sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                          sessionProfile == PROFILES.ADMIN_AGRUPACION
                        "
                        class="fas fa-lock pointer cups-detail-card-buttons"
                        title="{{ 'close' | translate }}"
                        (click)="closeValve(meter)"
                      ></i>
                      <i
                        class="fab fa-readme pointer cups-detail-card-buttons"
                        title="{{ 'read' | translate }}"
                        (click)="readValve(meter)"
                      ></i>
                      <i
                        *ngIf="
                          (sessionProfile == PROFILES.ARSON ||
                            sessionProfile == PROFILES.ADMIN_CLIENTE ||
                            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                            sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
                          meter.metrologyType == METROLOGY_TYPE.WATER_VALVE
                        "
                        title="{{ 'replace' | translate }}"
                        (click)="
                          showModal(
                            meter.metrologyType,
                            'deviceReplace',
                            meter.id
                          )
                        "
                        [ngClass]="{ disabled: !meter.id }"
                        class="pointer fas fa-exchange-alt cups-detail-card-buttons"
                      ></i>
                      <i
                        *ngIf="
                          (sessionProfile == PROFILES.ARSON ||
                            sessionProfile == PROFILES.ADMIN_CLIENTE ||
                            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                            sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
                          meter.metrologyType == METROLOGY_TYPE.WATER_VALVE
                        "
                        title="{{ 'delete' | translate }}"
                        (click)="unassignDevice(meter.id)"
                        [ngClass]="{ disabled: !meter.id }"
                        class="pointer fas fa-trash cups-detail-card-buttons"
                      ></i>
                    </td>
                    <td class="cups-alarms-table-alarm">
                      {{ valveStates[meter.valveState]?.text | translate }}
                    </td>
                    <td class="cups-alarms-table-alarm">
                      <span
                        *ngIf="meter.id"
                        (click)="openLink(meter)"
                        (contextmenu)="openLink(meter, true)"
                        class="center-block pointer"
                      >
                        {{ meter.nroSerie }}
                        <i class="fas fa-link cups-detail-table-link"></i>
                      </span>
                    </td>
                  </tr> </ng-container
              ></ng-container>
            </table>
          </div>

          <!-- ANCHOR Listado de alarmas -->
          <div *ngIf="activeAlarms; else elseBlock" class="cups-alarms">
            <table>
              <tr
                [ngClass]="
                  activeAlarms.length == 0 || activeAlarms == null
                    ? 'table-bg-green-gradient'
                    : 'table-bg-red-gradient'
                "
              >
                <th
                  colspan="2"
                  [routerLink]="['/cups/alarmas', cupsId]"
                  [state]="{ data: cupsData?.data?.clave }"
                  class="pointer"
                >
                  <i class="fas fa-exclamation-triangle"></i>
                  {{ "alarms" | translate }}
                </th>
                <th
                  [routerLink]="['/cups/alarmas', cupsId]"
                  [state]="{ data: cupsData?.data?.clave }"
                  class="pointer"
                >
                  <i class="fas fa-tachometer-alt"></i
                  >{{ "device" | translate }}
                  <i class="fas fa-link cups-alarms-table-link"></i>
                </th>
              </tr>
              <tr *ngIf="activeAlarms.length == 0 || activeAlarms == null">
                <td>
                  {{ "no-alarms" | translate }}
                </td>
              </tr>
              <ng-container *ngFor="let alarm of activeAlarms">
                <tr>
                  <td class="cups-alarms-table-date">
                    {{ alarm.initDate | date : "dd/MM/yyyy" || null }}
                  </td>
                  <td class="cups-alarms-table-alarm">
                    {{ alarm.name }}
                  </td>
                  <td class="cups-alarms-table-alarm">
                    <span class="center-block">{{ alarm.nroSerie }}</span>
                  </td>
                </tr>
              </ng-container>
            </table>
          </div>
        </div>

        <!-- Mapa de contador -->
        <div class="cups-detail-map">
          <app-map-controller
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            [initialZoom]="initialZoom"
            [disableCluster]="true"
            [gatewaysData]="mapGateways"
            [metersData]="mapMeter"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>

  <!-- ANCHOR Panel de gráfica -->
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "graph" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body">
      <!-- Gráfica -->
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [data]="chartOptions"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to)"
        [filters]="graphFilters"
        (filterFlag)="graphType = $event.value"
      >
      </app-graph-controller>
    </div>
  </div>

  <!-- ANCHOR Tabla de histórico -->
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "historical" | translate }}</b>
      </h5>
    </div>
    <div class="panel-body">
      <!-- Tabla -->
      <app-table-controller
        [export]="true"
        [exportFileName]="historicTableExportFileName"
        [columns]="historicTableColumns"
        [rowNumbers]="historicTableRowNumbers"
        [maxReg]="historicTableMaxReg"
        [quickFilters]="historicQuickFilters"
        [orderBy]="historicOrderBy"
        [specialFilter]="true"
        [data]="historicTableData"
      >
      </app-table-controller>
    </div>
  </div>

  <!-- ANCHOR Tabla de sectores -->
  <ng-container *ngIf="sectorTableData">
    <app-device-sector-table [sectorTableData]="sectorTableData">
    </app-device-sector-table>
  </ng-container>
</section>
