<main theme>
  <app-maintenance *ngIf="maintenance"></app-maintenance>

  <app-login-controller
    *ngIf="userLogged == false && !maintenance"
    (loginSucceed)="loginSucceed($event)"
  >
  </app-login-controller>

  <app-main-panel
    *ngIf="userLogged && appVersion"
    [startUrl]="startUrl"
    [profileStartUrl]="profileStartUrl"
    [loadSession]="loadSession"
    (logoutFlag)="logout()"
    [appVersion]="appVersion"
  >
  </app-main-panel>
</main>
