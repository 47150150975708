// Timeout stándar
export const STANDARD_TIMEOUT = 30000; // 30 seg

// Timeout pequeño
export const SMALL_TIMEOUT = 60000; // 1 min

// Timeout medio
export const MEDIUM_TIMEOUT = 120000; // 2 min

// Timeout largo
export const LARGE_TIMEOUT = 600000; // 10 min

// Timepo para preguntar por espera a finalización
export const WAIT_TIMEOUT = {
  60000: 30000,
  120000: 60000,
  600000: 120000,
};

// Rutas con pregunta de espera a finalización
export const ROUTES_WAIT_QUESTION = [
  "/sector/hydraulic/readings/",
  "/gwtemperature/graph/",
  "/readings-simple/agrupation/",
  "/readings-multiple/agrupation/",
];

// Rutas sin timeout
export const ROUTES_WITHOUT_TIMEOUT: string[] = [
  "/gestionpuntos/refrescarpuntosfinales/",
  "/gestionpuntos/refrescarpuntofinal/",
  "/devices/import/",
  "/reading-files/export/",
  "/reading-files/atm/transac/",
  "/reading-files/atm/transac-xml/",
  "/reading-files/izfe/pata/",
  "/reading-files/nortegas/",
  "/reading-files/ugena/",
  "/reading-files/poseidonia/",
  "/cm/import/",
  "/cm/update-meter/file",
  "/contador/import-images",
  "/contador/substitution/rf/",
];

// Rutas con timeout pequeño
export const ROUTES_WITH_SMALL_TIMEOUT: string[] = [];

// Rutas con timeout intermedio
export const ROUTES_WITH_MEDIUM_TIMEOUT: string[] = [
  "/meter-conf/",
  "/gateway/replace/",
  "/contador/list-internal/",
  "/gateway/main/table/",
  "/contador/update/puntoFinal",
  "/readings-simple/sector/",
  "/gateway/app-eui/delete-all/",
  "/gestionpuntos/eliminarpuntosfinales/",
  "/gwtemperature/graph/",
  "/readings-simple/agrupation/",
  "/readings-multiple/agrupation/",
  "/gateway/noiselevel-graph/",
  "/readings-multiple/sector/",
  "/contador/disable/",
];

// Rutas con timeout largo
export const ROUTES_WITH_LARGE_TIMEOUT: string[] = [
  "/gateway/import/",
  "/user/import-abonado/",
  "/contador/metrological-conf/import/",
  "/gw-conf/change-ip/",
  "/gw-conf/delete/",
  "/gwevents/datalogger/",
  "/gateway/update-fw/",
  "/gateway/update-fw/stop/",
  "/contador/assign/",
  "/contador/deallocate/",
  "/contador/chgateway/",
  "/contador/ch-group/",
  "/map/byagrupation/",
  "/gwevents/logs/",
  "/contador/delete",
  "/contador/list-all/",
  "/gestion-red/best-signal",
  "/gestion-red/assign-best-and-clear-redundant",
  "/sector/hydraulic/readings/",
  "/sector/hydraulic/main/",
  "/gestion-red/clear-redundant-list",
  "/save-appeui",
  "/contador-frames/get-meters-by-fabricante/",
  "/devices/export/",
  "/query/altoConsumo",
  "/query/nuloConsumo",
  "/query/negativoConsumo",
  "/query/fuga",
  "/query/picosAnomalos",
  "/test-ek",
];

// Rutas sin spinner
export const ROUTES_WITHOUT_SPINNER: string[] = [
  "/notificacion/count/pending/",
  "/gestion-red/best-signal",
  "/gestion-red/assign-best-and-clear-redundant",
  "/gestionpuntos/refrescarpuntofinal/",
  "/contador/satelite/estado/",
  "/alert/map/",
  "/alert/map-by-agrupation/",
  "/alert/map-by-entity/",
  "/alert/map-by-client/",
  "/contador-frames/relaunch-micerror/meter/",
  "/contador-frames/relaunch/meter/",
  "/gateway/app-eui/delete-all/",
  "/entity-appeui/save-appeui",
  "/gateway/update-fw/",
  "/arsonWarnings/getAll",
];

// Rutas con spinner opcional
export const ROUTES_WITH_OPTIONAL_SPINNER: string[] = [];

// Rutas que no interfieren con el spinner
export const ROUTES_OUT_OF_SPINNER: string[] = [
  "/notificacion/count/",
  "/contador/satelite/estado/",
];
