<div class="dialog-container">
  <!-- Título del modal -->
  <div class="dialog-header">
    <h5 class="dialog-title">
      <b>{{ "pressure" | translate }}: {{ data?.nroSerie }}</b>
    </h5>
  </div>

  <div class="dialog-body">
    <div class="leak-dialog-noise-graph">
      <!-- Gráfica -->
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [onlyGraph]="true"
        [data]="chartOptions"
        [chartConstructor]="chartConstructor"
      >
      </app-graph-controller>
    </div>
  </div>

  <div class="dialog-footer control-modal-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
