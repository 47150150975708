<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "replace" | translate }}: {{ replaceData?.nroSerieOld }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Número de serie nuevo -->
    <mat-form-field class="replace-dialog-input-text" appearance="standard">
      <mat-label>{{ "new-device" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="replaceData.nroSerieNew"
        required
      />
    </mat-form-field>
    <!-- Precinto -->
    <mat-form-field class="replace-dialog-input-text" appearance="standard">
      <mat-label>{{ "seal" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="replaceData.precinto" />
    </mat-form-field>
    <!-- Comentarios -->
    <mat-form-field class="replace-dialog-input-area" appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="replaceData.comments"></textarea>
    </mat-form-field>
    <ng-container *ngIf="data.type == 'all'">
      <!-- Módulo RF -->
      <mat-form-field class="replace-dialog-input-text" appearance="standard">
        <mat-label>{{ "rfmodule" | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="replaceData.rfModuleNew" />
      </mat-form-field>
      <!-- Timestamp -->
      <div class="replace-timestamp">
        <label for="date">{{ "date" | translate }}</label>
        <input
          type="datetime-local"
          id="date"
          name="date"
          [(ngModel)]="replaceDate"
        />
      </div>
      <div>
        <!-- Valor viejo -->
        <mat-form-field
          class="replace-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "value-old" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="replaceData.valueOld"
            step="0.001"
          />
        </mat-form-field>
        <!-- Valor nuevo -->
        <mat-form-field
          class="replace-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "value-new" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="replaceData.valueNew"
            step="0.001"
          />
        </mat-form-field>
      </div>
      <div>
        <!-- Latitud -->
        <mat-form-field
          class="replace-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "latitude" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="replaceData.latitude"
            step="0.01"
            min="-90"
            max="90"
          />
        </mat-form-field>
        <!-- Longitud -->
        <mat-form-field
          class="replace-dialog-input-number"
          appearance="standard"
        >
          <mat-label>{{ "longitude" | translate }}</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="replaceData.longitude"
            step="0.01"
            min="-180"
            max="180"
          />
        </mat-form-field></div
    ></ng-container>
  </div>

  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="replaceMeter()"
      [disabled]="
        replaceData.nroSerieNew == null ||
        (data.type == 'all' &&
          (replaceData.latitude == null ||
            replaceData.longitude == null ||
            replaceData.latitude > 90 ||
            replaceData.latitude < -90 ||
            replaceData.longitude > 180 ||
            replaceData.longitude < -180))
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
