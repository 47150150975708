// @angular
import { Component, Input } from "@angular/core";
import { forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { DeviceReadingsMoveData } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-move-readings-dialog",
  templateUrl: "./device-move-readings-dialog.component.html",
  styleUrls: ["./device-move-readings-dialog.component.scss"],
})
export class DeviceMoveReadingsDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  toMeter: string;
  moveCheckData: DeviceReadingsMoveData;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Comprobación de lecturas/consumo a mover
  checkMove(): void {
    this.MeterController.checkReadingsMove({
      fromMeter: this.data,
      toMeter: this.toMeter,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        let moveCheckData = response["body"];
        moveCheckData.minTimestampReadingsParsed =
          moveCheckData?.minTimestampReadings
            ? this.DateParserService.parseDate(
                moveCheckData?.minTimestampReadings,
                "L HH:mm:ss"
              )
            : null;
        moveCheckData.maxTimestampReadingsParsed =
          moveCheckData?.maxTimestampReadings
            ? this.DateParserService.parseDate(
                moveCheckData?.maxTimestampReadings,
                "L HH:mm:ss"
              )
            : null;
        moveCheckData.minTimestampConsumptionsParsed =
          moveCheckData?.minTimestampConsumptions
            ? this.DateParserService.parseDate(
                moveCheckData?.minTimestampConsumptions,
                "L HH:mm:ss"
              )
            : null;
        moveCheckData.maxTimestampConsumptionsParsed =
          moveCheckData?.maxTimestampConsumptions
            ? this.DateParserService.parseDate(
                moveCheckData?.maxTimestampConsumptions,
                "L HH:mm:ss"
              )
            : null;
        this.moveCheckData = response["body"];
      }
    });
  }

  // Mover lecturas/consumos
  moveReadingsConsumptions(): void {
    this.ToastService
      .fireAlertWithCaptcha(
        "question",
        this.translate.instant("move-readings-question")
      )
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let moveReadings = this.MeterController.moveReadings({
            fromMeter: this.data,
            toMeter: this.toMeter,
          });
          let moveConsumptions = this.MeterController.moveConsumptions({
            fromMeter: this.data,
            toMeter: this.toMeter,
          });
          forkJoin([moveReadings, moveConsumptions]).subscribe();
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      });
  }
}
