// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayService } from "../../../GatewayService.service";
// Componentes
import { GatewayLogDialogComponent } from "../gateway-log-dialog/gateway-log-dialog.component";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import { GatewayTask } from "../../../GatewayInterface.type";
import { Gateway } from "../../../../../../interfaces/GatewayGlobalInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-gateway-tasks",
  templateUrl: "./gateway-tasks.component.html",
  styleUrls: ["./gateway-tasks.component.scss"],
})
export class GatewayTasksComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Table
  tableMaxReg: number = 100;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("1");
  gatewayTasks: GatewayTask[];
  gateway: Gateway;
  orderBy: object = { attribute: "t", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-image",
          tooltip: "show-image",
          icon: "fas fa-image",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "gateway",
      data: "uv",
      search: "uv",
      sort: "uv",
      visible: true,
      link: "gatewayLink",
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "t",
      date: true,
      visible: true,
    },
    {
      title: "user",
      data: "u",
      search: "u",
      sort: "u",
      visible: true,
    },
    {
      title: "event",
      data: "ev",
      search: "ev",
      sort: "ev",
      visible: true,
    },
    {
      title: "entity",
      data: "et",
      search: "et",
      sort: "et",
      visible: true,
    },
    {
      title: "gateway-substituted",
      data: "iuv",
      search: "iuv",
      sort: "iuv",
      visible: true,
      link: "substitutedGatewayLink",
    },
    {
      title: "comments",
      data: "c",
      search: "c",
      sort: "c",
      visible: true,
    },
  ];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private GatewayService: GatewayService,
    private MaterialDialogService: MaterialDialogService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.gateway = history.state.data;
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setPanelMenuOptions();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let request = this.gateway?.id
      ? this.GatewayController.getGatewayIdTasksLogs(
          this.gateway.id,
          this.from,
          this.to
        )
      : this.GatewayController.getGatewayTasksLogs(this.from, this.to);
    request.subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let gatewayTasks: GatewayTask[] = response["body"];
          gatewayTasks.forEach((task) => {
            task.timestampParsed = this.DateParserService.parseDate(
              task.t,
              "L HH:mm:ss"
            );
            task.gatewayLink = "gateways/detalle/gateway/" + task.gwId;
            task.substitutedGatewayLink =
              "gateways/detalle/gateway/" + task.igwId;
          });
          this.gatewayTasks = gatewayTasks;
        } else {
          this.gatewayTasks = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, gatewayTask: GatewayTask): void {
    switch (action) {
      case "show-image":
        this.showTaskImages(gatewayTask);
        break;
      default:
        break;
    }
  }

  // Imágenes de tarea
  showTaskImages(gatewayTask: GatewayTask): void {
    this.MaterialDialogService.openDialog(
      GatewayLogDialogComponent,
      gatewayTask
    );
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = this.GatewayService.getLogPanelMenu("tasks-log");
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    this.GatewayService.getLogMenuAction(
      action,
      this.gateway.id,
      this.gateway.unidadVenta
    );
  }
}
