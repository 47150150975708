import { ContazaraDecoderService } from './meter-detail/meter-logs/meter-log-frames/frame-parsers/contazara-decoder.service';
import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { SepemDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sepem-decoder.service";
import { KamstrupDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/kamstrup-decoder.service";
import { ConthidraDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/conthidra-decoder.service";
import { ConthidraERegisterDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/conthidra-eRegister-decoder.service";
import { LoraWanParserService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/lorawan-parser.service";
import { ItronDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/itron-decoder.service";
import { HoneywellDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/honeywell-decoder.service";
import { DhielDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/dhiel-decoder.service";
import { BmeterDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/bmeter-decoder.service";
import { LwMbusDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/lw-mbus-decoder.service";
import { SagemcomDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sagemcom-decoder.service";
import { IntegraDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/integra-decoder.service";
import { ViewshineDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/viewshine-decoder.service";
import { SonicoNanoDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sonico-nano-decoder.service";
import { HoneywellGasDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/honeywell-gas-decoder.service";
import { BetaDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/beta-decoder.service";
import { AimeiDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/aimei-decoder.service";
import { SigfoxDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/sigfox-decoder.service";
import { ZhongYiDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/zhong-yi-decoder.service";
import { ConstratDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/constrat-decoder.service";
import { FlonidanDecoderService } from "./meter-detail/meter-logs/meter-log-frames/frame-parsers/flonidan-decoder.service";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
// Interfaces
import {
  AssignedDevice,
  DetailDevice,
  DetailDeviceAlarm,
  DetailDeviceGateway,
  DeviceFrameLog,
  InternalDevice,
} from "../DeviceInterface.type";
import { AlarmData } from "../../alarms/AlarmInterface.type";
import { TableDataColumn } from "../../../../modules/table-module/TableInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";
import { MANUFACTURER_INDEX } from "../../../../../assets/manufacturers/MANUFACTURER_INDEX";
// Componentes
import { MeterLogFramesDialogComponent } from "./meter-detail/meter-logs/meter-log-frames/meter-log-frames-dialog/meter-log-frames-dialog.component";
import { MeterReviewDialogComponent } from "./meter-list/meter-review/meter-review-dialog/meter-review-dialog.component";
import { MeterBatteryDialogComponent } from "./meter-list/meter-battery/meter-battery-dialog/meter-battery-dialog.component";
import moment from "moment";
import { EK_ERRORS, EkData } from "../../control/ControlInterface.type";
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class MeterService {
  constructor(
    private BmeterDecoderService: BmeterDecoderService,
    private ConthidraDecoderService: ConthidraDecoderService,
    private ConthidraERegisterDecoderService: ConthidraERegisterDecoderService,
    private DateParserService: DateParserService,
    private DhielDecoderService: DhielDecoderService,
    private HoneywellDecoderService: HoneywellDecoderService,
    private IntegraDecoderService: IntegraDecoderService,
    private ItronDecoderService: ItronDecoderService,
    private KamstrupDecoderService: KamstrupDecoderService,
    private LoraWanParserService: LoraWanParserService,
    private LwMbusDecoderService: LwMbusDecoderService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private SagemcomDecoderService: SagemcomDecoderService,
    private SepemDecoderService: SepemDecoderService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private ViewshineDecoderService: ViewshineDecoderService,
    private ZhongYiDecoderService: ZhongYiDecoderService,
    private SonicoNanoDecoderService: SonicoNanoDecoderService,
    private HoneywellGasDecoderService: HoneywellGasDecoderService,
    private BetaDecoderService: BetaDecoderService,
    private AimeiDecoderService: AimeiDecoderService,
    private SigfoxDecoderService: SigfoxDecoderService,
    private ConstratDecoderService: ConstratDecoderService,
    private FlonidanDecoderService: FlonidanDecoderService,
    private ContazaraDecoderService:ContazaraDecoderService,
  ) {}

  // Reseteo de contador
  resetMeter(meter: DetailDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "warning",
      this.translate.instant("device-question-desactivate") +
        " " +
        this.translate.instant("warning-permanent-action")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.SessionDataService.sendComponentData({ deactivation: true });
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  // Añadido del contador a lista de revisión
  toCheck(meter: DetailDevice | any): void {
    this.MaterialDialogService.openDialog(MeterReviewDialogComponent, meter);
  }

  openBatteryGraph(meter: DetailDevice): void {
    let meterId: number = meter.id;
    this.MaterialDialogService.openDialog(MeterBatteryDialogComponent, meterId);
  }
  openBatteryMultiGraph(meters: DetailDevice[]): void {
    this.MaterialDialogService.openDialog(MeterBatteryDialogComponent, meters);
  }

  // Obtención del gráfico de batería para un dispositivo
  getBatteryGraph(meter: AssignedDevice | InternalDevice): void {
    this.MeterController.getBatteryHistoryById(
      meter.contador ? meter.contador : meter.id
    ).subscribe({
      next: (response) => {
        if (response["code"] === 0) {
          meter.loadBatteryGraph = false; // Indicar que la carga del gráfico ha terminado
          // Obtener los datos de la batería
          const readings = response["body"]["contadorBatDtoList"];

          // Extraer los valores y timestamps para el gráfico
          const dataPoints = readings
            .map((reading) => ({
              x: reading.timestamp, // Timestamp para el eje X
              y: reading.value > 100 ? 100 : reading.value, // Si es más de 100 lo redondeamos
            }))
            .reverse(); // Invertir el orden si es necesario

          // Obtener el primer timestamp
          const startDate =
            dataPoints.length > 0 ? moment(dataPoints[0].x) : moment();
          const endDate = moment();

          meter.batteryGraphData = {
            showLeaks: false,
            units: "%",
            title: meter.nroSerie,
            min: 1,
            max: 100,
            reloadFunction: "battery-reload",
            defaultDateRange: {
              startDate: startDate,
              endDate: endDate,
            },
            series: [
              {
                id: "value",
                name: this.translate.instant("battery-charge"),
                type: "line",
                data: dataPoints,
                tooltip: {
                  pointFormat: "{point.y} %",
                },
                color: "#42a5f5",
                navigatorOptions: {
                  type: "line",
                },
              },
            ],
          };
        } else {
          console.error("Error al obtener datos de la batería:", response);
          // Manejar errores, si es necesario
        }
      },
      error: (error) => {
        console.error("Error en la llamada a la API de batería:", error);
        // Manejar errores de la API
      },
    });
  }

  getEkEnergyGraph(meter: EkData, dateRange?: any): void {
    let date = dateRange ? dateRange : this.DateParserService.getLastDays(7);  // Si no se pasa un rango de fechas, usamos los últimos 7 días
    // Llamada al servicio para obtener los datos de la gráfica de energía
    this.MeterController.getEkBatteryStateGraph(meter.id,date.startDate , date.endDate).subscribe({
      next: (response) => {
        meter.loadEnergyGraph = false; // Indicar que la carga del gráfico ha terminado
        meter.loadGraph = false;  // Indicamos que la carga de la gráfica ha terminado
        response=response['body'];
        // Encontrar el valor máximo entre todos los datos (tensionBateriaModem y tiempoRestanteVidaBateria)
        const maxBatteryVoltage = Math.max(...response.map(dataPoint => dataPoint.tensionBateriaModem));

        // Aquí formateamos la información de la gráfica de energía
        meter.graphData = {
          showLeaks: false,
          title: meter.nroSerie,
          navigatorOptions: {
            type: "column",
          },
          yAxis: {
            min: 1,
            max: maxBatteryVoltage,  // Establecemos el valor máximo con un margen adicional
          },
          series: [
            {
              id: "lastModemBatteryVoltage",
              name: this.translate.instant("tension"),  // Nombre de la serie
              type: "area",  // Usamos un gráfico de área
              data: response.map((dataPoint) => ({
                x: dataPoint.timestamp,
                y: dataPoint.tensionBateriaModem,  // Usamos 'tensionBateriaModem' para el consumo de energía
              })),
              tooltip: {
                valueSuffix: "V",  // Sufijo de la unidad para el tooltip
                valueDecimals: 3,  // Decimales para mostrar
              },
              color: "#42a5f5",  // Color para la gráfica
              navigatorOptions: {
                type: "line",  // Tipo de gráfico para el navegador
              },
            },
            // {
            //   id: "remainingBatteryLife",
            //   name: this.translate.instant("ek-remaining-battery-life"),  // Nombre de la serie
            //   type: "line",  // Usamos un gráfico de líneas para mostrar el tiempo restante
            //   data: response.map((dataPoint) => ({
            //     x: dataPoint.timestamp,  // Timestamp para el eje X
            //     y: dataPoint.tiempoRestanteVidaBateria,  // Usamos el tiempo restante de vida de la batería
            //   })),
            //   tooltip: {
            //     valueSuffix: this.translate.instant("months"),  // Sufijo de la unidad para el tooltip
            //     valueDecimals: 0,  // Decimales para mostrar (sin decimales)
            //   },
            //   color: "#ff7043",  // Color para la gráfica de energía
            //   navigatorOptions: {
            //     type: "line",  // Tipo de gráfico para el navegador
            //   },
            // },
          ],
        };
      },
      error: (error) => {
        console.error("Error en la llamada al servicio de gráficos de energía:", error);
      },
    });
  }

  checkEkManualCommunication(meter: any): void {
    this.MeterController.getManualCommunication(meter.id).subscribe((response) => {
      const res = response["code"];
      if (res === 0) {
        // Comunicación correcta
        this.ToastService.fireToast("success", this.translate.instant("ek-communication-success"));
      } else {
        // Si el error no está en EK_ERRORS, traducimos directamente el "body"
        const errorMessage = EK_ERRORS[res] || this.translate.instant(res.toString()); // Traducir el código si no existe en EK_ERRORS
        this.ToastService.fireToast("error", errorMessage);
      }
    });
  }
  // Obtención de la gráfica de dispositivo con alarma
  getAlarmGraph(
    meter: AssignedDevice | AlarmData,
    id?: number,
    metrologyType?: number,
    dateRange?: any
  ): void {
    let date = dateRange ? dateRange : this.DateParserService.getLastDays(7);
    this.MeterController.getGraph(
      id ? id : meter.id,
      metrologyType
        ? metrologyType == METROLOGY_TYPE.GAS
          ? "3"
          : "2"
        : meter.metrologyType == METROLOGY_TYPE.GAS
        ? "3"
        : "2",
      String(date.startDate.valueOf()),
      String(date.endDate.valueOf())
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let numberFormat = this.SessionDataService.getCurrentNumberFormat();
        meter.loadGraph = false;
        let units = meter.metrologyType == METROLOGY_TYPE.GAS ? " Nm³" : " m³";
        meter.graphData = {
          showLeaks: true,
          units: units,
          title: meter.nroSerie,
          html:
            `<div class="dialog-last-consumptions">
                      <span><b>` +
            this.translate.instant("last-consumption-min") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMin, numberFormat) +
            units +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-max") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMax, numberFormat) +
            units +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-total") +
            ":</b> " +
            formatNumber(meter.lastConsumptionTotal, numberFormat) +
            units +
            `</span>
                    </div>`,
          type: "area",
          series: [
            {
              id: "valor",
              name:
                meter.metrologyType == 2
                  ? this.translate.instant("consumption-normalized")
                  : this.translate.instant("consumption"),
              type: "area",
              data: response["body"]?.readings,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: meter.metrologyType == 2 ? " Nm³" : " m³",
                valueDecimals: 3,
              },
              color: "#42a5f5",
              navigatorOptions: {
                type: "area",
              },
            },
          ],
        };
      }
    });
  }

  // Limpieza de alarmas
  cleanAlarms(e: MouseEvent, meter: DetailDevice): void {
    e.stopPropagation();
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("clean-alarms-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.clearAlarms(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
          }
        });
      }
    });
  }

  // Lectura de válvula
  readValve(meter: DetailDevice): void {
    this.ToastService.fireAlertWithOptions(
      "info",
      this.translate.instant("read-valve-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let requestUrl =
          meter.metrologyType != METROLOGY_TYPE.SATELITE
            ? this.MeterController.readValve(meter.id)
            : this.MeterController.readSateliteValve(meter.id);
        requestUrl.subscribe((response) => {
          if (response["code"] == 0) {
            if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
              meter.valveState = response["body"];
              this.SessionDataService.sendComponentData({ updateCards: true });
            } else {
              this.updateValveData(meter);
            }
            this.ToastService.fireToast(
              "success",
              this.translate.instant("read-valve-successful")
            );
          }
        });
      }
    });
  }

  // Cierre de vávula
  closeValve(meter: DetailDevice): void {
    let toastTitle: string =
      meter.valveState == 0
        ? this.translate.instant("close-valve-already-question")
        : this.translate.instant("close-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          let requestUrl =
            meter.metrologyType != METROLOGY_TYPE.SATELITE
              ? this.MeterController.closeValve(meter.id)
              : this.MeterController.closeSateliteValve(meter.id);
          requestUrl.subscribe((response) => {
            if (response["code"] == 0) {
              if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
                meter.valveState = response["body"];
                this.SessionDataService.sendComponentData({
                  updateCards: true,
                });
              } else {
                this.updateValveData(meter);
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("close-valve-sended")
              );
            }
          });
        }
      }
    );
  }

  // Cambio de modo del contador
  changeMode(mode: string, meter: DetailDevice): void {
    let selectedMode: string;
    switch (mode) {
      case "A":
        selectedMode = "1";
        break;
      case "A+":
        selectedMode = "2";
        break;
      case "B":
        selectedMode = "3";
        break;
    }

    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("mode-change-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.changeMode(meter.id, selectedMode).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("mode") +
                  " " +
                  mode +
                  " " +
                  this.translate.instant("mode-changed")
              );
            }
          }
        );
      }
    });
  }

  // Apertura de válvula
  openValve(meter: DetailDevice, value?: number): void {
    let toastTitle: string =
      meter.valveState == 1 || meter.valveState == 2
        ? this.translate.instant("open-valve-already-question")
        : this.translate.instant("open-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          let requestUrl =
            meter.metrologyType != METROLOGY_TYPE.SATELITE
              ? !value
                ? this.MeterController.openValve(meter.id)
                : value == 10
                ? this.MeterController.openValve10(meter.id)
                : value == 50
                ? this.MeterController.openValve50(meter.id)
                : null
              : this.MeterController.openSateliteValve(meter.id);
          requestUrl.subscribe((response) => {
            if (response["code"] == 0) {
              if (meter.metrologyType != METROLOGY_TYPE.SATELITE) {
                meter.valveState = response["body"];
                this.SessionDataService.sendComponentData({
                  updateCards: true,
                });
              } else {
                this.updateValveData(meter);
              }
              this.ToastService.fireToast(
                "success",
                this.translate.instant("open-valve-sucessfull")
              );
            }
          });
        }
      }
    );
  }

  // Actualización de los datos de válvula
  updateValveData(meter: DetailDevice): void {
    this.MeterController.updateValveData(meter.id).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        meter.lastReadedValue = response["body"]?.lastValue;
        meter.lastReadedTimestamp = response["body"]?.lastTimestamp;
        meter.lastStateTimestamp = response["body"]?.lastStateTimestamp;
        meter.valveState = response["body"]?.valveState;
        meter.confirmedConfiguration = response["body"]?.confirmedConfiguration;
        let meterAlarms = response["body"]?.activeAlarms;
        if (meterAlarms != null) {
          meterAlarms.forEach((alarm: DetailDeviceAlarm) => {
            alarm.initDateParsed = this.DateParserService.parseDate(
              alarm.initDate,
              "L HH:mm:ss"
            );
            alarm.code != null
              ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
              : "";
          });
          this.SessionDataService.sendComponentData({
            meterAlarms: meterAlarms,
          });
        }
        this.SessionDataService.sendComponentData({ updateValveCards: true });
      }
    });
  }

  // Ordenamiento del array de gateways
  sortMeterGatewayArray(
    meterGatewayList: DetailDeviceGateway[]
  ): DetailDeviceGateway[] {
    let mainGateway: DetailDeviceGateway;
    let redundantGateways: DetailDeviceGateway[] = [];

    meterGatewayList.forEach((gateway: DetailDeviceGateway) => {
      if (gateway.principal) {
        mainGateway = gateway;
      } else {
        redundantGateways.push(gateway);
      }
    });

    redundantGateways.sort((a, b) => {
      if (!a.rssi && !b.rssi) {
        return 0;
      } else if (!a.rssi) {
        return 1;
      } else if (!b.rssi) {
        return -1;
      } else {
        return b.rssi - a.rssi;
      }
    });

    if (mainGateway) {
      meterGatewayList = [mainGateway].concat(redundantGateways);
    } else {
      meterGatewayList = redundantGateways;
    }

    return meterGatewayList;
  }

  // Parseo de número de serie de MBUS desconocidos para fabricantes SAP(304C) y RAN(2E48)
  parseUnknownMbusSerialNumber(dirMbus: string): string {
    let manufacturers = ["304C", "2E48"];
    if (manufacturers.includes(dirMbus?.substring(0, 4))) {
      let serialNumber: string;
      // Descartar la parte inicial para el cálculo
      let dirMbusArray = dirMbus.substring(4).split("");
      let dirMbusFlippedArray: string[] = [];
      let dirMbusBinary: string;
      let dirMbusAscii: string[] = [];
      // Voltear los pares hexadecimal
      for (let i = dirMbusArray.length - 1; i > 0; i -= 2) {
        dirMbusFlippedArray.push(dirMbusArray[i - 1]);
        dirMbusFlippedArray.push(dirMbusArray[i]);
      }
      dirMbusFlippedArray.shift();
      // Se pasa a binario para obtener letras del número de serie
      dirMbusBinary = dirMbusFlippedArray
        .slice(0, 4)
        .map((data) => {
          let binaryData = parseInt(data, 16).toString(2);
          if (binaryData.length < 4) {
            let binaryDataLength = 4 - binaryData.length;
            for (let i = 0; i < binaryDataLength; i++) {
              binaryData = "0" + binaryData;
            }
          }
          return binaryData;
        })
        .reduce((a, b) => a.concat(b));
      // Se divide en partes de 5 bits y se pasa a caracteres ASCII
      for (let i = 0; i < dirMbusBinary.length - 1; i += 5) {
        dirMbusAscii.push(
          String.fromCharCode(
            64 + parseInt(dirMbusBinary.substring(i, i + 5), 2)
          )
        );
      }
      // Por último, se pasa de hexadecimal a decimal los últimos 7 bytes
      let dirMbusLowBytes = String(
        parseInt(dirMbusFlippedArray.slice(4).join(""), 16)
      );
      if (dirMbusLowBytes.length < 8) {
        dirMbusLowBytes = "0" + dirMbusLowBytes;
      }
      serialNumber =
        dirMbusAscii[0] +
        dirMbusLowBytes.substring(0, 2) +
        dirMbusAscii[1] +
        dirMbusAscii[2] +
        dirMbusLowBytes.substring(2);
      return serialNumber;
    } else {
      return;
    }
  }

  // Comprobación de fabricante para parseo de trama
  checkMeterManufacturerParser(meter: DetailDevice): string {
    if (
      meter.idFabricante == MANUFACTURER_INDEX.DIEHL &&
      meter.idDevType != MANUFACTURER_INDEX.DIEHL_IZAR_R3_4
    ) {
      return "DIEHL";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.HONEYWELL &&
      (meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_MERLIN_HB_G3 ||
        meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_RF_MERLIN_HB_G3 ||
        meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_MERLIN_HB_G3_MBUS_LORA)
    ) {
      return "HONEYWELL";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.ITRON &&
      (meter.idDevType == MANUFACTURER_INDEX.ITRON_CYBLE4IoT ||
        meter.idDevType == MANUFACTURER_INDEX.ITRON_RF_CYBLE4IoT)
    ) {
      return "ITRON";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.CONTHIDRA &&
      (meter.idDevType == MANUFACTURER_INDEX.CONTHIDRA_JANZ_MYWATER_2 ||
        meter.idDevType == MANUFACTURER_INDEX.CONTHIDRA_RF_JANZ_MYWATER_2)
    ) {
      return "CONTHIDRA";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.SAGEMCOM &&
      meter.idDevType == MANUFACTURER_INDEX.SAGEMCOM_EF4EVO
    ) {
      return "SAGEMCOM";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.INTEGRA &&
      meter.idDevType == MANUFACTURER_INDEX.INTEGRA_TOPAS_SONIC
    ) {
      return "INTEGRA";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.CONTHIDRA &&
      meter.idDevType == MANUFACTURER_INDEX.CONTHIDRA_EREGISTER
    ) {
      return "CONTHIDRA_EREGISTER";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.SEWERIN &&
      meter.idDevType == MANUFACTURER_INDEX.SEWERIN_LEAK_DETECTOR
    ) {
      return "SEWERIN";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.KAMSTRUP &&
      meter.idDevType == MANUFACTURER_INDEX.KAMSTRUP_FLOWIQ2200
    ) {
      return "KAMSTRUP";
    } else if (meter.idFabricante == MANUFACTURER_INDEX.BMETER) {
      return "BMETER";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.ARSONMETERING &&
      meter.idDevType == MANUFACTURER_INDEX.ARSONMETERING_LW_MBUS
    ) {
      return "LW_MBUS";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.VIEWSHINE &&
      meter.idDevType == MANUFACTURER_INDEX.VIEWSHINE_U_WR2
    ) {
      return "VIEWSHINE";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.HONEYWELL &&
      meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_BK_G4_E
    ) {
      return "HONEYWELL_GAS";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.GWF &&
      meter.idDevType == MANUFACTURER_INDEX.SONICO_NANO
    ) {
      return "SONICO_NANO";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.BETA &&
      meter.idDevType == MANUFACTURER_INDEX.BETA_WG4
    ) {
      return "BETA";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.AIMEI &&
      (meter.idDevType == MANUFACTURER_INDEX.AIMEI_ULTRASONIC_WATER_868 ||
        meter.idDevType == MANUFACTURER_INDEX.AIMEI_ULTRASONIC_WATER_923)
    ) {
      return "AIMEI";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.HONEYWELL &&
      meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_HB_G3_SIGFOX
    ) {
      return "SIGFOX";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.CONSTRAT &&
      meter.idDevType == MANUFACTURER_INDEX.CONSTRAT_LSV_ZY_SMART
    ) {
      return "ZHONG_YI";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.CONSTRAT &&
      meter.idDevType == MANUFACTURER_INDEX.CONSTRAT_AQUAGUARD ||
      meter.idDevType == MANUFACTURER_INDEX.CONSTRAT_AQUAGUARD_V1 ||
      meter.idDevType == MANUFACTURER_INDEX.CONSTRAT_AQUAGUARD_V2
    ) {
      return "CONSTRAT";
    } else if (
      meter.idFabricante == MANUFACTURER_INDEX.FLONIDAN &&
      meter.idDevType == MANUFACTURER_INDEX.FLONIDAN_GAS
    ) {
      return "FLONIDAN";
    }
    else if (
      meter.idFabricante == MANUFACTURER_INDEX.CONTAZARA    ) {
      return "CONTAZARA";
    }
    return null;
  }

  // Visualizar resultado de parseo
  showParserResult(
    payload: string,
    parsedPayloadResult: object,
    error?: boolean
  ): void {
    this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
      payload: payload,
      payloadParsed: parsedPayloadResult,
      error: error,
      action: "parser",
    });
  }

  // Parseo de payload de trama
  parsePayload(meter: DetailDevice, meterFrame: any, date?: string): void {
    if (!meter.parser) {
      meter.parser = this.checkMeterManufacturerParser(meter);
    }
    let parsedPayloadResult: any;
    switch (meter.parser) {
      case "SEWERIN":
        parsedPayloadResult = this.SepemDecoderService.sepem_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "KAMSTRUP":
        parsedPayloadResult = this.KamstrupDecoderService.kamstrup_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "CONTHIDRA":
        parsedPayloadResult =
          this.ConthidraDecoderService.conthidra_MyWater2_decoder({
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          });
        break;
      case "CONTHIDRA_EREGISTER":
        parsedPayloadResult =
          this.ConthidraERegisterDecoderService.conthidra_eRegister_decoder(
            {
              bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
            },
            meterFrame.fport
          );
        break;
      case "DIEHL":
        parsedPayloadResult = this.DhielDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "HONEYWELL":
        parsedPayloadResult = this.HoneywellDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "ITRON":
        parsedPayloadResult = this.ItronDecoderService.diehl_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          date
        );
        break;
      case "BMETER":
        parsedPayloadResult = this.BmeterDecoderService.bmeter_decoder({
          bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
        });
        break;
      case "LW_MBUS":
        parsedPayloadResult = this.LwMbusDecoderService.LoRaWAN_MBus_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport,
          meterFrame.LORAWAN_HEADER?.FTYPE
        );
        break;
      case "SAGEMCOM":
        parsedPayloadResult = this.SagemcomDecoderService.Sagemcom_Gas_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "INTEGRA":
        parsedPayloadResult = this.IntegraDecoderService.Integra_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "VIEWSHINE":
        parsedPayloadResult = this.ViewshineDecoderService.Viewshine_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "ZHONG_YI":
        parsedPayloadResult = this.ZhongYiDecoderService.zhongyi_valve_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "CONSTRAT":
        parsedPayloadResult =
          this.ConstratDecoderService.constrat_valvula_decoder(
            { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
            meterFrame.fport
          );
        break;
      case "SONICO_NANO":
        parsedPayloadResult =
          this.SonicoNanoDecoderService.gwfSonicoNano_decoder(
            { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
            meterFrame.fport,
            date
          );
        break;
      case "HONEYWELL_GAS":
        parsedPayloadResult =
          this.HoneywellGasDecoderService.Honeywell_Gas_decoder(
            { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
            meterFrame.fport
          );
        break;
      case "BETA":
        parsedPayloadResult = this.BetaDecoderService.Beta_Gas_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "AIMEI":
        parsedPayloadResult = this.AimeiDecoderService.Aimei_decoder(
          { bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload) },
          meterFrame.fport
        );
        break;
      case "SIGFOX":
        parsedPayloadResult =
          this.SigfoxDecoderService.honeywell_sigfox_decoder(
            {
              bytes: this.SepemDecoderService.hexToBytes(
                meterFrame.allFrameData
              ),
            },
            date
          );
        break;
      case "FLONIDAN":
        parsedPayloadResult = this.FlonidanDecoderService.Flonidan_decoder(
          {
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          },
          meterFrame.fport
        );
        break;
      case "CONTAZARA":
        parsedPayloadResult=this.ContazaraDecoderService.contazara_octave_decoder({
            bytes: this.SepemDecoderService.hexToBytes(meterFrame.payload),
          });
      default:
        break;
    }

    if (parsedPayloadResult && parsedPayloadResult.errors) {
      this.showParserResult(
        meterFrame.payload,
        parsedPayloadResult.errors,
        true
      );
    } else if (parsedPayloadResult) {
      this.showParserResult(meterFrame.payload, parsedPayloadResult.data);
    }
  }

  // Parseo de trama LoRaWAN
  parseLorawanFrame(meter: DetailDevice, frame: DeviceFrameLog): void {
    if (!meter.parser) {
      meter.parser = this.checkMeterManufacturerParser(meter);
    }
    if (
      meter.idFabricante == MANUFACTURER_INDEX.HONEYWELL &&
      meter.idDevType == MANUFACTURER_INDEX.HONEYWELL_HB_G3_SIGFOX
    ) {
      frame.payload = frame.allFrameData;
      this.parsePayload(
        meter,
        frame,
        this.DateParserService.parseDate(
          frame.timestamp,
          "DD/MM/YYYY HH:mm:ss",
          LOCAL_TIMEZONE
        )
      );
    } else {
      let parsedFrame = this.LoraWanParserService.LoRaWAN_parser({
        bytes: this.SepemDecoderService.hexToBytes(frame.allFrameData),
      });
      if (
        parsedFrame["LORAWAN_HEADER"] &&
        parsedFrame["payload"] != null &&
        parsedFrame["payload"] != "" &&
        (parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "UNCONFIRMED DATA UP" ||
          parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA UP" ||
          (meter.parser == "LW_MBUS" &&
            (parsedFrame["LORAWAN_HEADER"]["FTYPE"] ==
              "UNCONFIRMED DATA DOWN" ||
              parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA DOWN")))
      ) {
        this.parsePayload(
          meter,
          parsedFrame,
          this.DateParserService.parseDate(
            frame.timestamp,
            "DD/MM/YYYY HH:mm:ss",
            LOCAL_TIMEZONE
          )
        );
      } else {
        if (
          parsedFrame["LORAWAN_HEADER"] &&
          (parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "UNCONFIRMED DATA DOWN" ||
            parsedFrame["LORAWAN_HEADER"]["FTYPE"] == "CONFIRMED DATA DOWN")
        ) {
          this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
            frame: frame.allFrameData,
            payloadParsed: {
              FTYPE: parsedFrame["LORAWAN_HEADER"]["FTYPE"],
              FCtrl: parsedFrame["LORAWAN_HEADER"]["FCtrl"],
            },
            action: "parser",
          });
        } else {
          this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
            frame: frame.allFrameData,
            payloadParsed: {
              FTYPE: parsedFrame["LORAWAN_HEADER"]["FTYPE"],
            },
            action: "parser",
          });
        }
      }
    }
  }

  // Parseo de dispositivos
  parseAssignedMeterList(meterList: any[]): AssignedDevice[] {
    return meterList.map((meter) => {

      return {
        id: meter.id,
        nroSerie: meter.n,
        lastCommunication: meter.lc,
        lastReadedValue: meter.lv,
        iccid: meter.iccidSim,
        clave: meter.c,
        col01: meter.c1,
        col02: meter.c2,
        col03: meter.c3,
        col04: meter.c4,
        col05: meter.c5,
        col06: meter.c6,
        col07: meter.c7,
        col08: meter.c8,
        col09: meter.c9,
        col10: meter.c10,
        col11: meter.c11,
        col12: meter.c12,
        col13: meter.c13,
        col14: meter.c14,
        col15: meter.c15,
        col16: meter.c16,
        col17: meter.c17,
        col18: meter.c18,
        col19: meter.c19,
        col20: meter.c20,
        isAssigned: meter.ia,
        metrologyType: meter.m,
        latitude: meter.lt,
        longitude: meter.lg,
        consumptionCardsLastMonth: meter.clm,
        valveState: meter.v,
        precinto: meter.p,
        agrupation: meter.a,
        entity: meter.entity,
        fabricante: meter.f,
        devType: meter.t,
        rfModule: meter.r,
        comments: meter.co,
        gateway: meter.g,
        lastConsumptionMax: meter.cmx,
        lastConsumptionMin: meter.cmn,
        lastConsumptionTotal: meter.cmt,
        lastBatteryValue: meter.blv,
        fcv: meter.fcv,
        pcs: meter.pcs,
        pc: meter.pc,
        tipo: meter.tp,
        comunica: meter.com,
        valveStateChangedTimestamp: meter.vt,
        lastBatValue: meter.blv > 100 ? 100 : meter.blv, //Limitamos a 100 por si el contador falla y devuelve cantidades superiores de bateria
      };
    });
  }

  // Desasignar contador como permanente a LW MBUS
  unassignLwMbusPermanent(concentratorId: number, childMeters: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        childMeters.length > 1
          ? "lw-mbus-meters-unfix-question"
          : "lw-mbus-meter-unfix-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteLwMbusMainMeters({
          element: concentratorId,
          listElements: childMeters,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
            this.SessionDataService.sendComponentData({
              realoadLwMusTable: true,
            });
          }
        });
      }
    });
  }

  // Asignar contador como permanente a LW MBUS
  assignLwMbusPermanent(concentratorId: number, childMeters: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant(
        childMeters.length > 1
          ? "lw-mbus-meters-fix-question"
          : "lw-mbus-meter-fix-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.addLwMbusMainMeters({
          element: concentratorId,
          listElements: childMeters,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("command-sent")
            );
            this.SessionDataService.sendComponentData({
              realoadLwMusTable: true,
            });
          }
        });
      }
    });
  }

  // Borrado de orden
  deleteMeterOrder(meterOrders: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("question-deleted-selected")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteOrder(meterOrders).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("deleted-success")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  // Cancelación de orden
  cancelMeterOrder(meterOrders: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("question-cancel-selected")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.cancelOrder(meterOrders).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("cancelled-success")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  getEntityCupsData(): {
    entityCups: EntityDefinition;
    entityNroSerie: EntityDefinition;
    entityCupsColumns: TableDataColumn[];
    entityCupsDefinitions: EntityDefinition[];
  } {
    let entityCupsDefinitions = JSON.parse(
      JSON.stringify(this.SessionDataService.getCurrentEntityCupsConf())
    );
    let cupsColums = [];
    let entityCups: EntityDefinition = entityCupsDefinitions?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );
    let entityNroSerie: EntityDefinition = entityCupsDefinitions?.find(
      (column: EntityDefinition) => column.colPosition == 100
    );
    entityCupsDefinitions?.map(
      (column: EntityDefinition) =>
        (column.colPosition =
          column.colPosition <= 9
            ? "col0" + column.colPosition
            : "col" + column.colPosition)
    );

    entityCupsDefinitions?.forEach((cupsDefinition: EntityDefinition) => {
      if (
        cupsDefinition.colPosition != "col00" &&
        cupsDefinition.colPosition != "col100"
      ) {
        let newColumn: TableDataColumn = {
          title: cupsDefinition.name,
          data: cupsDefinition.colPosition.toString(),
          search: cupsDefinition.colPosition.toString(),
          sort: cupsDefinition.colPosition.toString(),
          long: true,
          visible: cupsDefinition.show ? true : null,
        };
        cupsColums.push(newColumn);
      }
    });
    return {
      entityCups: entityCups,
      entityNroSerie: entityNroSerie,
      entityCupsColumns: cupsColums,
      entityCupsDefinitions: entityCupsDefinitions,
    };
  }
}
