// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Observable, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
// Interfaces
import { SfAdrUpdateRequestData } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-sf-update",
  templateUrl: "./device-sf-update.component.html",
  styleUrls: ["./device-sf-update.component.scss"],
})
export class DeviceSfUpdateComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private requestQueue: RequestQueueService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de cambios
  updateAllSfAdr(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("data-update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.requestQueue.setTask("updateSfAdr", this.data.devices, {
          min_sf: this.data.deviceSfMin,
          txPower: this.data.txPower,
          adrPermission: this.data.deviceAdr,
        });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }

  // Guardado de cambios
  updateAllSf(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("data-update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.requestQueue.setTask("updateSf", this.data.devices, {
          sf: this.data.deviceSf,
        });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }

  // Guardado de cambios
  updateSfAdr(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("data-update-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let requestList: Observable<object>[] = [];

        if (this.data.deviceSf != this.data.device.lastDataRate) {
          requestList.push(
            this.MeterController.updateSf(
              this.data.device.id,
              this.data.deviceSf
            )
          );
        }

        let adrPermissionNumber: number = this.data.deviceAdr ? 1 : 0;
        if (
          this.data.deviceSfMin != this.data.device.minSf ||
          adrPermissionNumber != this.data.device.adrPermission ||
          this.data.deviceTxPower != this.data.device.txPower
        ) {
          let data: SfAdrUpdateRequestData = {
            contadorId: this.data.device.id,
            min_sf: this.data.deviceSfMin,
            adrPermission: adrPermissionNumber,
            txPower: this.data.deviceTxPower,
          };
          requestList.push(this.MeterController.updateSfAdr(data));
        }

        if (requestList.length > 0) {
          requestList[0].subscribe((response) => {
            if (response["code"] == 0) {
              if (requestList[1]) {
                requestList[1].subscribe((response) => {
                  if (response["code"] == 0) {
                    this.successFeedback();
                  }
                });
              } else {
                this.successFeedback();
              }
            }
          });
        } else {
          this.ToastService.fireToast(
            "warning",
            this.translate.instant("no-changes-input")
          );
        }
      }
    });
  }

  // Feedback de éxito al guardar cambios
  successFeedback(): void {
    this.ToastService.fireToast(
      "success",
      this.translate.instant("data-updated")
    );
    this.SessionDataService.sendReloadPanelFlag();
    this.SessionDataService.sendDialogAction({ action: "close" });
  }
}
