import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[appCloseDropdownOnScroll]",
})
export class CloseDropdownOnScrollDirective {
  @Input() ngSelect: any;

  constructor() {}

  @HostListener("window:scroll", ["$event"])
  closeDropdownsOnScroll(): void {
    this.ngSelect.close();
  }
}
