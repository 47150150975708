<section class="content-header">
  <div class="container-fluid">
    <!-- Panel Estado de los gateways -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "commands-tail" | translate }}</b>
        </h5>
        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          (action)="getCommandList()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'managementCommandTail'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          (selectedDataUpdateFlag)="selectedCommands = $event"
          [data]="commandList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
