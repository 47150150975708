<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "activate" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <!-- Número de serie -->
    <mat-form-field class="activation-dialog-input-text" appearance="standard">
      <mat-label>{{ "serial-number" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="data.nroSerie" required />
    </mat-form-field>
    <!-- Módulo RF -->
    <mat-form-field class="activation-dialog-input-text" appearance="standard">
      <mat-label>{{ "rfmodule" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="data.rfModule" />
    </mat-form-field>
    <!-- CUPS -->
    <mat-form-field *ngIf="entityCupsData.entityCups" class="activation-dialog-input-text" appearance="standard">
      <mat-label>{{cupsName}}</mat-label>
      <input matInput type="text" [(ngModel)]="data.cups" />
    </mat-form-field>
    <!-- Precinto -->
    <mat-form-field class="activation-dialog-input-text" appearance="standard">
      <mat-label>{{ "seal" | translate }}</mat-label>
      <input matInput type="text" [(ngModel)]="data.precinto" />
    </mat-form-field>
    <div>
      <!-- Latitud -->
      <mat-form-field
        class="activation-dialog-input-number"
        appearance="standard"
      >
        <mat-label>{{ "latitude" | translate }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="data.latitude"
          step="0.01"
          min="-90"
          max="90"
          required
        />
      </mat-form-field>
      <!-- Longitud -->
      <mat-form-field
        class="activation-dialog-input-number"
        appearance="standard"
      >
        <mat-label>{{ "longitude" | translate }}</mat-label>
        <input
          matInput
          type="number"
          [(ngModel)]="data.longitude"
          step="0.01"
          min="-180"
          max="180"
          required
        />
      </mat-form-field>
    </div>
    <!-- Agrupación -->
    <app-material-select
      [title]="'groups' | translate"
      [bindValue]="'value'"
      [bindLabel]="'name'"
      [selected]="initialSelection"
      [data]="agrupationSelectOptions"
      (selectedOption)="assignAgrupation($event?.value)"
      required
    ></app-material-select>

    <!-- Comentarios -->
    <mat-form-field class="activation-dialog-input-area" appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="data.comments"></textarea>
    </mat-form-field>
  </div>

  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="activateMeter()"
      [disabled]="
        data.nroSerie == null ||
        data.agrupation == null ||
        data.latitude == null ||
        data.longitude == null ||
        data.latitude > 90 ||
        data.latitude < -90 ||
        data.longitude > 180 ||
        data.longitude < -180
      "
    >
      <i class="fas fa-redo-alt"></i>
      {{ "activate" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
