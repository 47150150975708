<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="modal-title">{{ data.title }}</h5>
  </div>
  <!-- HTML -->
  <div *ngIf="data.html" class="dialog-body" [innerHtml]="parsedHtml"></div>

  <!-- Gráfica -->
  <div *ngIf="data?.device?.graphData != null" class="dialog-body">
    <div
      *ngIf="data.device.graphData.html"
      [innerHtml]="data.device.graphData.html"
      class="table-dialog-graph-html"
    ></div>
    <div class="table-dialog-graph">
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [noTypeSelector]="true"
        [data]="chartOptions"
        [dateRange]="data.device.graphData.defaultDateRange ?? defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to)"
      >
      </app-graph-controller>
    </div>
    <!-- Gráfica circular -->
    <div
      #graphCircle
      *ngIf="data.device?.graphData?.showLeaks && circleChartOptions"
      class="leak-graph-circle leak-graph-circle-initial"
      draggable="true"
      (dragstart)="DragElementService.getDrapgPoint(graphCircle, $event)"
      (dragend)="
        DragElementService.setPosition(
          graphCircle,
          $event,
          'leak-graph-circle-initial',
          '--graph-circle-y',
          '--graph-circle-x'
        )
      "
    >
      <app-graph-controller
        [highchartsOptions]="circleHighchartOptions"
        [noOptionsParse]="true"
        [onlyGraph]="true"
        [data]="circleChartOptions"
      >
      </app-graph-controller>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
