<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>
          <h5>
            <b> {{ "template" | translate }}</b>
          </h5>

          <!-- Botón guardar -->
          <app-material-panel-menu
            [onlyButton]="true"
            [icon]="'fas fa-save'"
            [title]="'save' | translate"
            (action)="saveTemplate()"
            [disabled]="
              !template?.templateName ||
              !template?.language ||
              template?.tipo == null ||
              (template?.tipo == 0 && template?.diasArray == null)
            "
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body">
        <div *ngIf="template; else elseBlock">
          <div class="output-files-name">
            <mat-form-field appearance="standard">
              <mat-label>{{ "template-name" | translate }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="template.templateName"
                placeholder="{{ 'template-name' | translate }}..."
                required
              />
            </mat-form-field>

            <!-- Idioma -->
            <app-material-select
              [title]="('language' | translate) + ' *'"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [data]="languages"
              [selected]="preselectedLanguage"
              (selectedOption)="template.language = $event.value"
            ></app-material-select>
          </div>
          <div class="output-files-selectors">
            <!-- CUPS -->
            <app-material-select
              [multiple]="true"
              [clearable]="true"
              [fullWidth]="true"
              [filter]="true"
              [hint]="columnsAlreadyIncluded | translate"
              [title]="'file-columns' | translate"
              [bindValue]="'colPosition'"
              [bindLabel]="'name'"
              [data]="cupsOptions"
              [selected]="template.extraData"
              (selectedOption)="selectedCupsColumns = $event"
            ></app-material-select>
          </div>
          <!-- Periodo de tiempo -->
          <div class="output-files-time-selector">
            <div>
              <span>{{ "type" | translate }} *</span>
              <mat-radio-group
                class="output-files-time-selector-inputs"
                [(ngModel)]="template.tipo"
                (change)="inputFocus()"
                required
              >
                <!-- Último valor -->
                <mat-radio-button class="mat-radio" [value]="1">
                  {{ "last-know-value" | translate }}
                </mat-radio-button>
                <!-- Valor más cercano -->
                <mat-radio-button
                  class="mat-radio output-files-closest-value"
                  [value]="0"
                >
                  <mat-form-field
                    appearance="standard"
                    class="output-files-cron-month-input"
                  >
                    <mat-label> {{ "closest value" | translate }}</mat-label>
                    <div
                      *ngIf="template.tipo == 0"
                      class="output-files-cron-days"
                    >
                      <!-- Selector de días -->
                      <div
                        [ngClass]="{
                          disabled: template.tipo != 0
                        }"
                      >
                        <span
                          *ngFor="let day of template.diasArray; index as i"
                          [ngClass]="{
                            'output-files-cron-days-selected':
                              template.diasArray[i],
                            'output-files-cron-days-disabled':
                              !template.diasArray[i]
                          }"
                          (click)="
                            template.diasArray[i] = !template.diasArray[i]
                          "
                          >{{ i + 1 }}
                        </span>
                      </div>
                    </div>
                    <input
                      #closestValue
                      matInput
                      type="text"
                      [disabled]="template.tipo != 0"
                      [ngClass]="{ hidden: template.tipo == 0 }"
                    />
                    <mat-hint *ngIf="template.tipo == 0">
                      {{ "template-days" | translate }}</mat-hint
                    >
                  </mat-form-field>
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <!-- Límite de días -->
            <div class="output-files-time-limit">
              <span>{{ "template-limit" | translate }}</span>
              <mat-form-field appearance="standard">
                <mat-label>{{ "limit" | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  min="0"
                  [max]="sessionProfile != PROFILES.ARSON ? 7 : null"
                  [(ngModel)]="template.limiteDias"
                />
                <mat-hint
                  >({{ "max" | translate }} 7
                  {{ "days" | translate }})</mat-hint
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
