import { Component, OnInit } from "@angular/core";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
})
export class MaintenanceComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit() {
    this.spinner.show("spinner-soft");
  }
}
