// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { MeterService } from "../../MeterService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { InternalDeviceParseService } from "./internal-device-parse.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";
import { DeviceTypeService } from "../../../../../../services/shared/DeviceTypeService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { DeviceAgrupationDialogComponent } from "../../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { DeviceSfUpdateComponent } from "../../../devices-common-components/device-sf-update/device-sf-update.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
} from "../../../../../../modules/table-module/TableInterface.type";
import { DetailDevice, InternalDevice } from "../../../DeviceInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-meterslistinternal",
  templateUrl: "./meter-internal.component.html",
  styleUrls: ["./meter-internal.component.scss"],
})
export class MetersListInternalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Tabla
  tableData: InternalDevice[];
  originalTableData: InternalDevice[];
  tableSelectedData: InternalDevice[];
  exportFileName: string =
    this.translate.instant("meters-internal-export") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  tableGlobalActions: object[];
  meterActivate: object = {
    0: this.translate.instant("device-not-activated"),
    1: this.translate.instant("device-desactivated"),
    2: this.translate.instant("actives").slice(0, -1),
    3: this.translate.instant("AlertMeter0"),
    4: this.translate.instant("response-no-data"),
    5: this.translate.instant("data-up-error-some"),
    6: this.translate.instant("data-up-error-all"),
    7: this.translate.instant("join-not-success"),
    8: this.translate.instant("join-mic-error"),
  };
  meterMetrologyType: object = {
    0: this.translate.instant("meter"),
    1: this.translate.instant("sensor"),
    2: this.translate.instant("gas-meter"),
  };
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  // Sin comunicacióm
  internalList: boolean = false;
  deviceType: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private internalDeviceParse: InternalDeviceParseService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private MeterService: MeterService,

  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.internalList = this.router.url.includes("interno");
    this.deviceType = history.state.data;
    this.setTableGlobalActions();
    this.getData();
  }

  // Seteo de columnas
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "state",
        data: "activateParsed",
        search: "activateParsed",
        sort: "activateParsed",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: "alias",
        data: "alias",
        search: "alias",
        sort: "alias",
        visible: true,
      },
      {
        title: "rfmodule",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "manufacturer",
        data: "fabricanteParsed",
        search: "fabricanteParsed",
        sort: "fabricanteParsed",
        visible: true,
      },
      {
        title: "model",
        data: "devTypeParsed",
        search: "devTypeParsed",
        sort: "devTypeParsed",
        visible: true,
      },
      {
        title: "installation-date",
        data: "installationParsed",
        search: "installationParsed",
        sort: "installation",
        date: true,
        visible: true,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-join",
        data: "lastJoinTimestampParsed",
        search: "lastJoinTimestampParsed",
        sort: "lastJoinTimestamp",
        date: true,
        visible: true,
      },
      {
        title: "last-data",
        data: "lastReadedTimestampParsed",
        search: "lastReadedTimestampParsed",
        sort: "lastReadedTimestamp",
        date: true,
        visible: true,
      },
      {
        title: "readings-percentage",
        data: "pcParsed",
        search: "pcParsed",
        sort: "pc",
        numerical: true,
        visible: this.tableData.some(
          (meter: InternalDevice) =>
            this.DeviceTypeService.getDeviceTypeByMask(meter.tipo) ==
            DEVICE_BY_COMM.MBUS
        )
          ? true
          : null,
      },
      {
        title: "battery-load",
        data: "lastBatValue",
        search: "lastBatValue",
        sort: "lastBatValue",
        numerical: true,
        visible: true,
      },
      {
        title: "battery-graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        batteryGraph: true,
        graph: true,
      },
      {
        title: "meter-photo",
        data: "hasImage",
        search: "hasImage",
        sort: "hasImage",
        boolean: true,
        alter: {
          condition: "hasImage",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "SF",
        data: "sfParsed",
        search: "sfParsed",
        sort: "sf",
        numerical: true,
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "sf-min",
        data: "sfMinParsed",
        search: "sfMinParsed",
        sort: "sfMin",
        numerical: true,
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "ADR",
        data: "adrPermission",
        search: "adrPermission",
        sort: "adrPermission",
        boolean: true,
        alter: {
          condition: "adrPermission",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "RSSI (dBm)",
        data: "rssiParsed",
        search: "rssiParsed",
        sort: "rssi",
        numerical: true,
        visible: true,
      },
      {
        title: "SNR (dB)",
        data: "snrParsed",
        search: "snrParsed",
        sort: "snr",
        numerical: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "mode",
        data: "isBModeParsed",
        search: "isBMode",
        sort: "isBMode",
        visible: true,
      },
      {
        title: "redundants-number",
        data: "nroRedundantGatewaysParsed",
        search: "nroRedundantGatewaysParsed",
        sort: "nroRedundantGateways",
        numerical: true,
        visible: true,
      },
    ];
  }

  // Seteo de las acciones globales de la tabla
  setTableGlobalActions(): void {
    this.tableGlobalActions = [
      {
        title: "deactivate-multiple",
        icon: "fas fa-eraser",
        selectionRequired: true,
        class: "btn-red",
      },
      {
        title: "show-meters-map",
        icon: "fas fa-map-marker-alt",
        selectionRequired: true,
        help: "help-table-map",
      },
      {
        title: "show-battery-multigraph",
        icon: "fa-solid fa-battery-three-quarters",
        selectionRequired: true,
        help: "help-table-battery",
      },
      {
        title: "show-selected-graph",
        icon: "fas fa-chart-area",
        selectionRequired: true,
        help: "help-table-graph",
      },
      {
        title: "change-agrupation",
        icon: "fas fa-map",
        selectionRequired: true,
        help: "help-table-agrupation",
      },
    ];

    if (this.sessionProfile == PROFILES.ARSON) {
      this.tableGlobalActions.push({
        title: "assign-best-gateway",
        icon: "fas fa-check-double",
        selectionRequired: true,
      });
      if (this.internalList) {
        this.tableGlobalActions.push(
          {
            title: "sf-adr-update",
            icon: "fas fa-satellite-dish",
            selectionRequired: true,
            help: "help-table-sf",
          },
          {
            title: "sf-update",
            icon: "fas fa-satellite-dish",
            selectionRequired: true,
            help: "help-table-sf",
          },
          {
            title: "clean-redundant-gateways",
            icon: "fas fa-broom",
            selectionRequired: true,
            class: "btn-red",
            help: "help-table-clean-redundant",
          }
        );
      }
    }
  }

  // Seteo de filtros rápidos
  setQuickfilters(): void {
    this.quickFilters = [
      [
        {
          name: "meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.WATER },
          active: false,
        },
        {
          name: "gas-meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.GAS },
          active: false,
        },
        {
          name: "sensors",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.SENSOR },
          active: false,
        },
        {
          name: "concentrators",
          columnSearch: "isConcentrator",
          condition: { type: "boolean", rule: true },
          active: false,
        },
        {
          name: "satelite",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.SATELITE },
          active: false,
        },
      ],
      [
        {
          name: "LoRaWAN",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.LW },
          active: this.deviceType?.includes(DEVICE_BY_COMM.LW),
        },
        {
          name: "MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.MBUS },
          active: this.deviceType?.includes(DEVICE_BY_COMM.MBUS),
        },
        {
          name: "UNE",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.UNE },
          active: this.deviceType?.includes(DEVICE_BY_COMM.UNE),
        },
        {
          name: "LoRaWAN MBUS",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.LW_MBUS },
          active:
            this.deviceType?.includes(DEVICE_BY_COMM.LW_MBUS) ||
            this.deviceType?.includes(DEVICE_BY_COMM.LW),
        },
        {
          name: this.translate.instant("external"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.EXTERNO },
          active: false,
        },
        {
          name: "ERM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.ERM },
          active: false,
        },
        {
          name: "EK280",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.EK280 },
          active: false,
        },
        {
          name: "PLUM",
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.PLUM },
          active: false,
        },
        {
          name: this.translate.instant("no-lora-no-com"),
          columnSearch: "deviceType",
          condition: { type: "text", rule: DEVICE_BY_COMM.NO_LORA_NO_COM },
          active: false,
        },
      ],
    ];
  }

  // Obtención de los datos de la tabla
  getData(): void {

    this.updating = true;
    this.mapSelectorActive = false;
    let requestUrl = this.internalList
      ? this.MeterController.getInternalList(this.currentAgrupation.id)
      : this.MeterController.getNoCommunicateList(this.currentAgrupation.id);
    requestUrl.subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let tableData: InternalDevice[] = this.internalDeviceParse.parseDevices(
          response["body"]
        );
        tableData.forEach((meter: InternalDevice) => {
          meter.loadBatteryGraph=true;
          // Estado
          meter.activateParsed = this.meterActivate[meter.activate];
          meter.isBModeParsed = meter.isBMode
            ? this.translate.instant("mode") + " B"
            : "-";
          // Metrología
          meter.metrologyTypeParsed =
            meter.metrologyType != null
              ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
              : "-";
          meter.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            meter.metrologyType,
            meter.id
          );
          // Fabricante
          let device: { manufacturerText: string; deviceText: string } =
            this.ManufacturerService.getManufacturer(
              meter?.fabricante?.toString(),
              meter?.devType?.toString(),
              this.sessionLanguage
            );
          meter.fabricanteParsed = device.manufacturerText;
          meter.devTypeParsed = device.deviceText;
          meter.deviceType = this.ManufacturerService.getDeviceType(
            meter.fabricante,
            meter.devType
          );
          // Concentrador
          meter.isConcentrator =
            meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR ||
            meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR;
          // Lora
          meter.isLora = meter.tipo % 2 ? true : false;
        });
        this.tableData = tableData;
        this.originalTableData = tableData;
      } else {
        this.tableData = [];
        this.originalTableData = [];
      }
      this.setQuickfilters();
      this.setColumns();
      this.updating = false;
    });
  }

  // Actualización de los datos
  updateData(): void {
    this.tableData = null;
    this.getData();
  }

  // Acciones de la tabla
  tableActions(action: string, meter: InternalDevice ): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "load-battery-graph":
        this.MeterService.getBatteryGraph(meter);
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "assign-best-gateway":
        this.assignBestGateway();
        break;
      case "clean-redundant-gateways":
        this.cleanRedundantGateways();
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "sf-adr-update":
        this.changeSfAddrModal();
        break;
      case "sf-update":
        this.changeSfModal();
        break;
      case "show-battery-multigraph":
        this.showOnBatteryGraph();
      default:
        break;
    }
  }

  showOnBatteryGraph() {
    let meters: DetailDevice[] = JSON.parse(JSON.stringify(this.tableSelectedData));
    this.MeterService.openBatteryMultiGraph(meters);
  }

  // Modal de cambio de SF min/ADR
  changeSfAddrModal(): void {
    this.MaterialDialogService.openDialog(DeviceSfUpdateComponent, {
      deviceAdr: false,
      deviceSfMin: 12,
      deviceTxPower: null,
      devices: this.tableSelectedData,
    });
  }

  // Modal de cambio de SF
  changeSfModal(): void {
    this.MaterialDialogService.openDialog(DeviceSfUpdateComponent, {
      devices: this.tableSelectedData,
      onlySF: true,
    });
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección a contador
  showMeter(meter: InternalDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Asignación del mejor Gateway
  assignBestGateway(): void {
    let data = this.tableSelectedData.map((device: InternalDevice) => {
      return {
        id: device.id,
        nroSerie: device.nroSerie,
        metrologyType: device.metrologyType,
      };
    });
    this.requestQueue.setTask("assignBest", data);
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((device: InternalDevice) => {
          return device.id;
        }),
        to: null,
      },
    });
  }

  // Limpieza de gateways redundantes
  cleanRedundantGateways(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("clean-redundant-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: InternalDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("cleanGateways", data);
      }
    });
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: InternalDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: this.tableSelectedData,
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }
}
