<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "meters-in-network" | translate }}</b>
          <span
            *ngIf="homeDevicesFilter"
            class="meter-list-filtered-map"
            [ngClass]="{
              'meter-list-filtered-map-inactive': !homeDevicesFilterActive
            }"
            (click)="
              homeDevicesFilterActive = !homeDevicesFilterActive; updateData()
            "
          >
            (<i class="fas fa-filter"></i
            ><span>{{ "filtered-by-map" | translate }}</span
            >)
          </span>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersAssigned'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [quickFilters]="quickFilters"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [associationFilter]="true"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="meterList"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="meter-list-map"
            [dataList]="meterList"
            [originalDataList]="originalMeterList"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'nroSerie'"
            [anchorToScroll]="'table-panel'"
            (updateData)="meterList = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
