// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { MetrologicalControllerService } from "../../../../../services/server/MetrologicalController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
  TableQuickFilter,
} from "../../../../../modules/table-module/TableInterface.type";
import { Metrological, MetrologicalInfo } from "../../DataManagementInterface.type";

@Component({
  selector: "app-data-management-pcs-fcv-list",
  templateUrl: "./data-management-pcs-fcv-list.component.html",
  styleUrls: ["./data-management-pcs-fcv-list.component.scss"],
})
export class DataManagementPcsFcvListComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  entityListSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  sessionProfile: string;
  entityList:Entity[];
  agrupationList: Agrupation[];

  // Table
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  tableMaxReg: number = 25;
  tableData: MetrologicalInfo[];

  columns: (TableActionColumn | TableDataColumn)[];
  tableHighlightRow: TableHighlightRow[];
  quickFilters: TableQuickFilter[][] = [];

  //Leyenda
  CUPSandMeterIgualatedPCSList: MetrologicalInfo[] = [];
  CUPSandMeterDiferentPCSList: MetrologicalInfo[] = [];
  CUPSandMeterIgualatedFCVList: MetrologicalInfo[] = [];
  CUPSandMeterDiferentFCVList: MetrologicalInfo[] = [];
  allEmptyCount:number = 0;
  allEqualCount:number = 0;
  allDifferentCount:number = 0;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MetrologicalController: MetrologicalControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityCupsConf =
    this.SessionDataService.getCurrentEntityCupsConf();
    this.entityList = this.SessionDataService.getCurrentEntityList();
    this.getAgrupationList();
    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let serialNumberLabel: string = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 100
    )?.label;
    this.serialNumberLabel = serialNumberLabel
      ? serialNumberLabel
      : "NRO_SERIE";

    this.getData();
    this.setColumns();
  }

// Obtención del listado de agrupaciones
getAgrupationList(): void {
  // Inicializa una lista vacía de agrupaciones
  let agrupations: Agrupation[] = [];

  // Recorre la lista de entidades
  this.entityList?.forEach((entity: Entity) => {
      // Añade las agrupaciones que no muestran todas las entidades
      agrupations = agrupations.concat(
        entity.agrupations?.filter((agrupation) => !agrupation.showAllEntity) || []
      );
  });

  // Asigna la lista de agrupaciones al atributo agrupationList
  this.agrupationList = agrupations;

  // Ordena la lista de agrupaciones por el nombre
  this.agrupationList?.sort((a, b) => a.name.localeCompare(b.name));
}


  setQuickFilters() {
    this.quickFilters = [
      [
        {
          name: this.translate.instant("without-config") + " (" + this.allEmptyCount + ")",
          columnSearch: "empty",
          condition: { type: "boolean", rule: true },
          active: false,
        },
        {
          name: this.translate.instant("cups-heredated-config") + " (" + this.allEqualCount + ")",
          columnSearch: "equal",
          condition: { type: "boolean", rule: true },
          active: false,
        },
        {
          name: this.translate.instant("own-config") + " (" + this.allDifferentCount + ")",
          columnSearch: "different",
          condition: { type: "boolean", rule: true },
          active: false,
        },
      ],
    ];
  }


// ANCHOR Obtención de los datos
getData(): void {
  this.MetrologicalController.getListByAgrupation(this.currentAgrupation.id)
    .subscribe((response) => {
      if (response["code"] == 0) {
        this.tableData = response["body"];
        this.tableData.forEach((device: MetrologicalInfo) => {
          device.deviceUrl = "/dispositivos/detalle/contador/" + device.contadorId;
          device.claveMaestraUrl = "/cups/detalle/" + device.claveMaestraId;
          device.entityString = this.entityList.find(entity => entity.id == device.entity).entity;
          device.agrupationString = this.agrupationList.find(agrupation => agrupation.id == device.agrupation).name;
          if(device.contadorConversionFactor==null || device.contadorPcsValue==null){
            device.empty = true;
            this.allEmptyCount++;
          }
          else if(device.contadorConversionFactor==device.claveMaestraConversionFactor && device.contadorPcsValue==device.claveMaestraPcsValue){
            device.equal = true;
            this.allEqualCount++;
          }
          else{
            device.different = true;
            this.allDifferentCount++;
          }
        });

        // Llamar a la función para actualizar los contadores
        this.setQuickFilters();

      }
    });
}




  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        link: "deviceUrl",
        visible: true,
      },
      {
        title: "CUPS",
        data: "claveMaestra",
        search: "claveMaestra",
        sort: "claveMaestra",
        link: "claveMaestraUrl",
        visible: true,
      },
      {
        title: "agrupation",
        data: "agrupationString",
        search: "agrupationString",
        sort: "agrupationString",
        visible: this.currentAgrupation.virtual,
      },
      {
        title: this.translate.instant("conversion-factor") + " ("+ this.translate.instant("meter")  + ") ",
        data: "contadorConversionFactor",
        search: "contadorConversionFactor",
        sort: "contadorConversionFactor",
        visible: true,
        numerical: true,
      },
      {
        title: this.translate.instant("pcs") + " ("+ this.translate.instant("meter")  + ") ",
        data: "contadorPcsValue",
        search: "contadorPcsValue",
        sort: "contadorPcsValue",
        visible: true,
      },
      {
        title: this.translate.instant("conversion-factor") + " (CUPS)",
        data: "claveMaestraConversionFactor",
        search: "claveMaestraConversionFactor",
        sort: "claveMaestraConversionFactor",
        visible: true,
        numerical: true,
      },
      {
        title: this.translate.instant("pcs") + " ("+ "CUPS"  + ") ",
        data: "claveMaestraPcsValue",
        search: "claveMaestraPcsValue",
        sort: "claveMaestraPcsValue",
        visible: true,
      },

    ];
  }

  // Acciones de la tabla
  tableActions(action: string, metrological: Metrological): void {}



}
