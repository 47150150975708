import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[appCronInput]",
})
export class CronInputDirective {
  constructor(private el: ElementRef) {}

  // Control de entrada de teclado
  @HostListener("keypress", ["$event"]) cronRegex(e: any): boolean {
    var regex: RegExp = /(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7})/g
    var str: string = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }
    e.preventDefault();
    return false;
  }
}
