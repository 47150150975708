// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
// Componentes
import { DataAnalysisBalanceDialogComponent } from "./data-analysis-balance-dialog/data-analysis-balance-dialog.component";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  Balance,
  BalanceAlarm,
  BalanceAlarmThreshold,
  BalanceMapParentDevice,
  BalanceMapChildDevice,
  BalanceCards,
  BalanceCardsData,
  BalanceGraphData,
} from "../../DataAnalysisInterface.type";

@Component({
  selector: "app-hydraulicBalanceGroup",
  templateUrl: "./data-analysis-balance-group.component.html",
  styleUrls: ["./data-analysis-balance-group.component.scss"],
})
export class HydraulicBalanceGroupComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Stream de datos de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Balance
  balanceId: number;
  balance: Balance;
  intervalDays: number;

  // Mapa
  mapType: string = "balanceDetail";
  metersData: (BalanceMapParentDevice | BalanceMapChildDevice)[];
  mapHeight: number;
  @ViewChild("balanceDetail") balanceDetail: ElementRef;

  // Tarjetas
  cardsData: BalanceCardsData;
  balanceCardsData: BalanceCards;
  balanceThresholds: BalanceAlarmThreshold = new BalanceAlarmThreshold();

  // Gráficas
  graphData: BalanceGraphData;

  // Formato de fecha
  dateFormat: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DataAnalysisController: DataAnalysisControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente  
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(
      () => (this.mapHeight = this.balanceDetail.nativeElement.offsetHeight),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.balanceId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.balance = history.state.data;
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.DataAnalysisController.getMap(this.balanceId).subscribe((response) => {
      if (response["code"] == 0) {
        // Gráficas
        this.graphData = response["body"]["graph"];

        // Tarjetas
        this.cardsData = response["body"]["cards"];
        this.balanceThresholds = { ...this.cardsData.alarmThresholds };
        this.getBalanceCards();

        // Alarmas
        this.cardsData.alarms.forEach((alarm: BalanceAlarm) => {
          alarm.code == 0
            ? (alarm.code = this.translate.instant("balanceTypeAlarm0"))
            : "";
          alarm.code == 1
            ? (alarm.code = this.translate.instant("balanceTypeAlarm1"))
            : "";
          alarm.initDateParsed = this.DateParserService.parseDate(
            alarm.initDate,
            this.dateFormat + " HH:mm"
          );
          alarm.finalDateParsed = this.DateParserService.parseDate(
            alarm.finalDate,
            this.dateFormat + " HH:mm"
          );
        });

        // Mapa
        this.metersData = response["body"]["metersChildList"]
          .map((meter: BalanceMapChildDevice) => {
            meter.meterType = "CHILD";
            return meter;
          })
          .concat(
            response["body"]["metersParentList"].map(
              (meter: BalanceMapParentDevice) => {
                meter.meterType = "FATHER";
                return meter;
              }
            )
          );
      }
    });
  }

  // Creación de las tarjetas de balance
  getBalanceCards(): void {
    let balanceCardsData: BalanceCards = {
      balanceParentsSize: { data: this.cardsData.parentsSize, type: "number" },
      balanceChildsSize: { data: this.cardsData.childsSize, type: "number" },
      balanceUnbilled: {
        data: this.cardsData.anr,
        date: this.cardsData.anrTimestamp,
        type: "%",
      },
      balanceThresholdAlarm: {
        data: " ",
        type: "text",
        buttons: [],
      },
    };

    if (this.cardsData?.alarmThresholds?.observationThreshold != null) {
      balanceCardsData.balanceThresholdAlarm.data +=
        this.translate.instant("observation") +
        ": " +
        this.cardsData.alarmThresholds.observationThreshold +
        "% " +
        this.translate.instant("nrw") +
        "\n";
    }
    if (this.cardsData?.alarmThresholds?.criticalThreshold != null) {
      balanceCardsData.balanceThresholdAlarm.data +=
        this.translate.instant("critical") +
        ": " +
        this.cardsData.alarmThresholds.criticalThreshold +
        "% " +
        this.translate.instant("nrw");
    }

    if (
      this.cardsData?.alarmThresholds?.observationThreshold == null &&
      this.cardsData?.alarmThresholds?.criticalThreshold == null
    ) {
      balanceCardsData.balanceThresholdAlarm.buttons.push({
        action: "create-thresholds",
        icon: "fas fa-plus",
        title: this.translate.instant("create"),
      });
    }
    if (
      this.cardsData?.alarmThresholds?.observationThreshold != null ||
      this.cardsData?.alarmThresholds?.criticalThreshold != null
    ) {
      balanceCardsData.balanceThresholdAlarm.buttons.push({
        action: "edit-thresholds",
        icon: "fas fa-edit",
        title: this.translate.instant("edit"),
      });
    }

    this.balanceCardsData = balanceCardsData;
  }

  // Acciones de las tarjetas
  cardsActions(action: string): void {
    switch (action) {
      case "create-thresholds":
        this.showThresholdDialog();
        break;
      case "edit-thresholds":
        this.showThresholdDialog();
        break;
      default:
        break;
    }
  }

  // Modal de umbral
  showThresholdDialog(): void {
    this.MaterialDialogService.openDialog(DataAnalysisBalanceDialogComponent, {
      balanceId: this.balanceId,
      balanceThresholds: JSON.parse(JSON.stringify(this.balanceThresholds)),
      edit: this.cardsData?.alarmThresholds,
      delete:
        this.cardsData?.alarmThresholds?.observationThreshold != null ||
        this.cardsData?.alarmThresholds?.criticalThreshold != null,
    });
  }

  // Edición de balance
  editGroup(): void {
    this.balance.redirect = "detail";
    this.router.navigate(["/analisis-datos/editar", this.balanceId], {
      state: { data: this.balance },
    });
  }
}
