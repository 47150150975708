// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { DeviceActivationDialogComponent } from "../../../devices-common-components/device-activation-dialog/device-activation-dialog.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
} from "../../../../../../modules/table-module/TableInterface.type";
import { ActivationErrorDevice } from "../../../DeviceInterface.type";

@Component({
  selector: "app-meter-activation-error",
  templateUrl: "./meter-activation-error.component.html",
  styleUrls: ["./meter-activation-error.component.scss"],
})
export class MeterActivationErrorComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Table
  tableData: ActivationErrorDevice[];
  originalTableData: ActivationErrorDevice[];
  tableSelectedData: ActivationErrorDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  from: string;
  to: string;
  tableGlobalActions: object[] = [
    {
      title: "activate-selection",
      icon: "fas fa-plus",
      selectionRequired: true,
      help: "help-table-activate",
    },
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "finish",
      icon: "fas fa-eraser",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
  ];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "unfinished",
        columnSearch: "finished",
        condition: { type: "number", rule: false },
        active: true,
      },
      {
        name: "finished",
        columnSearch: "finished",
        condition: { type: "number", rule: true },
        active: false,
      },
    ],
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "activate",
          tooltip: "activate",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: "finished",
        },
        {
          name: "finish",
          tooltip: "finish",
          icon: "fas fa-eraser",
          visible: { attribute: null, rule: true },
          disabled: "finished",
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "seal",
      data: "precinto",
      search: "precinto",
      sort: "precinto",
      visible: true,
    },
    {
      title: "latitude",
      data: "latitude",
      search: "latitude",
      sort: "latitude",
      visible: true,
    },
    {
      title: "longitude",
      data: "longitude",
      search: "longitude",
      sort: "longitude",
      visible: true,
    },
    {
      title: "rfmodule",
      data: "rfModule",
      search: "rfModule",
      sort: "rfModule",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupation",
      search: "agrupation",
      sort: "agrupation",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestampParsed",
      visible: true,
    },
    {
      title: "end-date",
      data: "processedParsed",
      search: "processedParsed",
      sort: "processedParsed",
      visible: true,
    },
    {
      title: "user",
      data: "processedUsername",
      search: "processedUsername",
      sort: "processedUsername",
      visible: true,
    },
    {
      title: "error-text",
      data: "responseCodeParsed",
      search: "responseCodeParsed",
      sort: "responseCodeParsed",
      visible: true,
    },
    {
      title: "comments",
      data: "comments",
      search: "comments",
      sort: "comments",
      visible: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RequestQueueService: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de la tabla
  getData(from: string, to: string): void {
    this.updating = true;
    this.mapSelectorActive = false;
    this.from = from;
    this.to = to;
    this.MeterController.getActivationErrorList(
      this.currentAgrupation.id,
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let tableData: ActivationErrorDevice[] = response["body"];
        tableData.forEach((meter: ActivationErrorDevice) => {
          meter.responseCodeParsed =
            this.translate.instant("error-text") +
            " " +
            meter.responseCode +
            ": " +
            this.translate.instant("httpError" + meter.responseCode);
          meter.finished = meter.processed != null ? true : false;
        });
        this.tableData = tableData;
        this.originalTableData = tableData;
      } else {
        this.tableData = [];
        this.originalTableData = [];
      }
      this.updating = false;
    });
  }

  // Actualización de los datos
  updateData(): void {
    this.tableData = null;
    this.getData(this.from, this.to);
  }

  // Acciones de la tabla
  tableActions(action: string, error: ActivationErrorDevice): void {
    switch (action) {
      case "finish":
        this.processActivationError([error.id]);
        break;
      case "activate":
        this.MaterialDialogService.openDialog(DeviceActivationDialogComponent, {
          nroSerie: error.nroSerie,
          latitude: error.latitude,
          longitude: error.longitude,
          entity: this.currentEntity.entity,
          agrupation: error.agrupation,
          rfModule: error.rfModule,
          comments: error.comments,
          agrupations: this.currentEntity.agrupations,
        });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "finish":
        this.processActivationError(
          this.tableSelectedData.map((error: ActivationErrorDevice) => {
            return error.id;
          })
        );
        break;
      case "activate-selection":
        let data = this.tableSelectedData.map((meter) => {
          return {
            id: meter.id,
            nroSerie: meter.nroSerie,
            activationData: [{
              nroSerie: meter.nroSerie,
              latitude: meter.latitude,
              longitude: meter.longitude,
              entity: this.currentEntity.entity,
              agrupation: meter.agrupation,
              rfModule: meter.rfModule,
              comments: meter.comments,
              precinto: meter.precinto,
            }],
          };
        });
        this.RequestQueueService.setTask("activate", data);
        break;
      default:
        break;
    }
  }

  // Redirección a contador
  // showMeter(meter: ActivationErrorDevice): void {
  //   this.deviceRouteSelector.getDeviceRoute(meter.metrologyType, meter.id);
  // }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Finalización de error de activación
  processActivationError(errorIds: number[]): void {
    this.MeterController.processActivationError(
      errorIds,
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("activation-error-finished")
        );
        this.ReloadComponentService.reload();
      }
    });
  }
}
