<div
  *ngIf="time"
  class="satelite-schedule-turns-clock"
  (mousewheel)="updateTimer($event)"
>
  <div class="outer-clock-face">
    <div class="marking marking-one"></div>
    <div class="marking marking-two"></div>
    <div class="marking marking-three"></div>
    <div class="marking marking-four"></div>
    <div class="inner-clock-face">
      <div
        #hourArrow
        class="hand hour-hand"
      ></div>
      <div
        #minutesArrow
        class="hand min-hand"
      ></div>
    </div>
  </div>
</div>
