<!-- Modal de dispositivos configurados -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "device" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <table
      class="table table-hover table-bordered table-striped alarms-modal-table"
    >
      <thead>
        <tr>
          <th>{{ "serial-number" | translate }}</th>
          <th>{{ "action" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let device of configuredDevicesFiltered">
          <tr>
            <td>{{ device.nroSerie }}</td>
            <td class="action">
              <i
                class="fas fa-eye pointer"
                (click)="goTo(device)"
                title="{{ 'show' | translate }}"
              ></i>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
