// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import { AgrupationData } from "../../screens/dashboard/agrupations/AgrupationInterface.type";
import { Metrological } from "../../screens/dashboard/data-management/DataManagementInterface.type";

@Injectable({
  providedIn: "root",
})
export class AgrupationControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Datos de una agrupación para el formulario de edición
  getAgrupation(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/agrupation/show/" + agrupationId);
  }

  // Guardar una agrupación (Sector hidráulico)
  createAgrupation(data: AgrupationData): Observable<object> {
    return this.HttpRequestService.post("/agrupation/save/", data);
  }

  // Actualizar una agrupación (Sector hidráulico)
  updateAgrupation(data: AgrupationData): Observable<object> {
    return this.HttpRequestService.post("/agrupation/update/", data);
  }

  // Eliminar una agrupación (Sector hidráulico)
  deleteAgrupation(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/agrupation/delete/" + agrupationId);
  }

  // Listado de agrupaciones por entidad
  getAgrupationList(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/agrupation/list/" + entityId);
  }

  // Eventos de usuario por agrupación
  getAgrupationUserLog(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/user-events/by-agrupation/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Zonas horarias de la entidad
  getEntityTimezone(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity/timezone/" + entityId);
  }

  // Eliminar configuración metrológica asociada a un dispositivo
  deleteMetrological(metrologicalId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/delete/" + metrologicalId
    );
  }

  // Importación de un fichero de configuraciones metrológicas de dispositivos
  import(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/metrological-conf/import/" + entityId,
      data
    );
  }

  // Listado de las configuraciones metrológicas de una agrupación
  getList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/list/" + agrupationId
    );
  }

  // Listado de las configuraciones metrológicas de una agrupación
  getMetrological(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/list/" + agrupationId
    );
  }

  // Configuración de metrológica de un dispositivo
  getDeviceMetrological(deviceId: number) {
    return this.HttpRequestService.get(
      "/contador/metrological-conf/get/" + deviceId
    );
  }

  // Guardar/Actualizar la configuración metrológica de un dispositivo (Contador de gas) desde el formulario
  saveMetrological(data: Metrological): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/metrological-conf/save/",
      data
    );
  }
}
