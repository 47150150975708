import { ReloadComponentService } from './../../../../../services/shared/ReloadComponentService.service';
import { CupsControllerService } from './../../../../../services/server/CupsController.service';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "./../../../../../services/shared/SessionDataService.service";

// Interfaces
import { METER_ORDER } from "../../../devices/DeviceInterface.type";
import { Agrupation } from "./../../../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "./../../../../../interfaces/EntityGlobalInterface.type";
import { ToastService } from './../../../../../services/shared/ToastService.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MaterialDialogComponent } from './../../../../../modules/material-module/material-dialog/material-dialog.component';

@Component({
  selector: "app-cups-detail-order",
  templateUrl: "./cups-detail-order-dialog.component.html",
  styleUrls: ["./cups-detail-order-dialog.component.css"],
})
export class CupsDetailOrderDialogComponent implements OnInit, OnDestroy {


  /***************************************** */
  //ANCHOR - VARIABLES
  /***************************************** */

  // Variables de input
  @Input() data;

  // dropdown
  dropdownAgrupationData: Object[];
  cupsOrdersData: Object[];
  selectedOrder: any;
  selectedAgrupation: any;
  //textarea
  comment: string;

  // Variables de sesión
  entitySub: Subscription;
  currentEntity: Entity;
  currentAgrupation: Agrupation;
  sessionProfile: string;
  meterOrders: any;

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private translate: TranslateService,
    private CupsControllerService: CupsControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private dialogRef: MatDialogRef<MaterialDialogComponent>,
  ) {}

  ngOnInit() {
    // Inicializa las variables con la entidad y agrupación actual
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    // Suscripción a cambios de entidad, si la entidad cambia, actualizamos currentEntity
    this.entitySub = this.SessionDataService.getEntity().subscribe(
      (entity: Entity) => {
        this.currentEntity = entity;
        this.getDropDownData(); // Llamamos a getDropDownData cada vez que se actualiza currentEntity
      }
    );

    // Llamamos a loadComponent si currentEntity está definido
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/
  loadComponent() {
    this.getDropDownData();

  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/
  ngOnDestroy(): void {
    if (this.entitySub) {
      this.entitySub.unsubscribe();
    }
  }

  /***************************************************************************/
  // ANCHOR Obtener los datos de dropdown
  /***************************************************************************/
  getDropDownData() {

    if (this.currentEntity?.agrupations) {
      this.dropdownAgrupationData = this.currentEntity.agrupations.map(
        (agrupation: Agrupation) => {
          return {
            value: agrupation.id,
            label: agrupation.name,
            name: agrupation.name,
          };
        }
      );
    }

    // AQUI SE CARGAN LAS ORDENES QUE PERTENECEN AL CUPS
    this.meterOrders = [METER_ORDER.CLAVE_MAESTRA_INSTALL_METER];
    // Mapear las órdenes a un formato adecuado
    this.cupsOrdersData = this.meterOrders.map((order) => {
      // Obtenemos el nombre de la orden usando el valor de la clave
      const orderTitle = METER_ORDER[order]; // Esto devolverá el string correspondiente al número.
      return {
        value: order,
        label: this.translate.instant(orderTitle), // Usamos el string como el label
        name: this.translate.instant(orderTitle), // Usamos el string como el name también si lo necesitas
      };
    });
    this.selectedAgrupation = this.currentAgrupation.id;
    this.selectedOrder = this.meterOrders[0];

  }

  onAgrupationChange(event: any) {
    this.selectedAgrupation = event?.value;
  }

  onOrderChange(event: any) {
    this.selectedOrder = event?.value;
  }
  save() {
    let savedata = {
      idList: this.data.cupsIdList,
      type: this.data.order,
      comments: this.comment,
      agrupation: this.selectedAgrupation
    };
    this.CupsControllerService.createCupsOrder(savedata).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("saved")
        );
        this.dialogRef.close();
        this.ReloadComponentService.reload()
      }
    });
  }

}
