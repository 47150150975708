<div
  *ngIf="options"
  class="mat-select-container"
  [ngClass]="{ disabled: disabled }"
>
  <mat-form-field
    appearance="standard"
    [ngClass]="{
      'mat-select-full-width': fullWidth,
      'mat-select-multiple-label': multiple && selectedItems?.value?.length == 1
    }"
  >
    <!-- Título -->
    <div class="mat-form-field-label mat-select-label">
      {{ title }}
      {{
        counter
          ? "(" +
            (selectedItems?.value?.length - 1) +
            "/" +
            options?.length +
            ")"
          : ""
      }}
    </div>
    <!-- Selector -->
    <mat-select
      #materialSelect
      [ngStyle]="{ height: height + 'px' }"
      [ngClass]="{ 'material-select-overflow': multiple }"
      [formControl]="selectedItems"
      [multiple]="multiple"
      (selectionChange)="!multiple && !_groups ? updateSelected() : null"
      (openedChange)="
        multiple && clearable ? openChange($event) : null;
        filter && originalOptions.length > 0
          ? materialSelectFilter?.nativeElement?.focus()
          : null
      "
      (closed)="selectFilterInput = null; filterOptions()"
      [required]="required"
    >
      <!-- Listado de selección múltiple -->
      <mat-select-trigger *ngIf="multiple && clearable">
        <ng-container *ngFor="let option of originalOptions">
          <div *ngIf="option.selected && !option.hideSelection">
            <i
              class="fas fa-times"
              (click)="$event.stopPropagation(); updateOption(option)"
            ></i>
            <span>{{ option.name }}</span>
          </div>
        </ng-container>
      </mat-select-trigger>

      <!-- Filtrar -->
      <input
        #materialSelectFilter
        matInput
        *ngIf="filter && originalOptions.length > 0"
        id="material-select-filter"
        class="material-select-filter"
        type="text"
        autocomplete="off"
        placeholder="{{ 'search' | translate }}..."
        [(ngModel)]="selectFilterInput"
        (keyup)="filterOptions()"
      />

      <!-- Opción de seleccionar todos -->
      <div
        class="mat-select-all"
        *ngIf="
          multiple && clearable && !disableAllSelection && options.length > 1
        "
      >
        <mat-checkbox
          class="mat-option"
          [(ngModel)]="selectAll"
          (change)="checkAll()"
        >
          {{ "all" | translate }} ({{ options.length }})
        </mat-checkbox>
      </div>

      <!-- Opciones con grupos -->
      <ng-container *ngIf="_groups">
        <mat-optgroup
          *ngFor="let group of _groups"
          [label]="group.name"
          [disabled]="group.disabled"
        >
          <ng-container *ngFor="let option of options">
            <!-- <mat-autocomplete #auto="matAutocomplete"></mat-autocomplete> -->
            <mat-option
              *ngIf="option.group == group.value"
              [value]="option.value"
              (click)="updateOption(option)"
            >
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </ng-container>

      <!-- Opciones sin grupos con scroll virtual -->
      <ng-container *ngIf="!_groups && multiple">
        <!-- Opción fija para forzar que se visualice el trigger -->
        <mat-option
          *ngIf="clearable"
          class="mat-select-force-trigger"
          [value]="'forceTrigger'"
          >'force'
        </mat-option>
        <!-- Scroll virtual -->
        <cdk-virtual-scroll-viewport
          #cdkVirtualScrollViewPort
          itemSize="5"
          [style.overflow]="options.length < 5 ? 'hidden' : 'auto'"
          [style.height.px]="options.length < 5 ? options.length * 42 : 210"
        >
          <mat-option
            *cdkVirtualFor="let option of options"
            [ngClass]="{ 'mat-select-hide-checkbox': option.hideSelection }"
            [value]="option.value"
            (click)="updateOption(option)"
            >{{ option.name }}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-container>

      <!-- Opciones sin grupos -->
      <ng-container *ngIf="!_groups && !multiple">
        <!-- Sin scroll virtual -->
        <ng-container *ngIf="!virtual">
          <mat-option *ngFor="let option of options" [value]="option.value"
            >{{ option.name }}
          </mat-option>
        </ng-container>
        <!-- Con scroll virtual -->
        <ng-container *ngIf="virtual">
          <cdk-virtual-scroll-viewport
            #cdkVirtualScrollViewPort
            itemSize="5"
            [style.overflow]="options.length < 5 ? 'hidden' : 'auto'"
            [style.height.px]="options.length < 5 ? options.length * 42 : 210"
          >
            <mat-option
              *cdkVirtualFor="let option of options"
              [value]="option.value"
              >{{ option.name }}
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
    </mat-select>

    <!-- Texto extra -->
    <mat-hint *ngIf="hint" [ngClass]="{ 'mat-select-hint-error': hintError }">{{
      hint
    }}</mat-hint>
  </mat-form-field>

  <!-- Botón de borrado de selección -->
  <button
    *ngIf="
      clearable &&
      ((multiple && selectedItems.value?.length > 1) ||
        (!multiple && selectedItems.value != null))
    "
    matSuffix
    mat-button
    [disableRipple]="true"
    class="mat-button-no-hover mat-select-clear"
    (click)="reset($event)"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
