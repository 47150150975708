// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { EkConfigurationControllerService } from "../../../../services/server/EkConfigurationController.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
// Componentes
import { Ek280ConfigurationComponent } from "../../devices/ek-280/ek280-configuration/ek280-configuration.component";
import { Ek280TestComponent } from "../../devices/ek-280/ek280-test/ek280-test.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  DeviceEk280,
  DeviceEk280Sim,
  DeviceSim,
} from "../../devices/DeviceInterface.type";
import { EkData, MinifiedEntityDefinition } from "../ControlInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-control-test",
  templateUrl: "./control-test.component.html",
  styleUrls: ["./control-test.component.scss"],
})
export class ControlTestComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  componentInitiated: boolean = false;

  // Tabla
  tableSelectedData: any[];
  tableMaxReg: number = 40;
  tableData: any[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  entitiesDefinitions: {
    entityId: number;
    definitionList: MinifiedEntityDefinition[];
    definitionListParsed: EntityDefinition[];
  }[];
  exportFileName: string =
    this.translate.instant("test") + " " + this.DateParserService.getDate();

  // CUPS
  cups: string[];
  serialNumber: string[];
  serverConnError: number;

  // Modal de configuración de EK280
  ekConfigData: DeviceEk280;
  simList: DeviceSim[];
  simSelected: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private EkConfigurationController: EkConfigurationControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.ReloadComponentService.reload();
    });

    // Carga del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Obtención de los datos
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    let meterList: EkData[] = [];
    this.MeterController.getPendingEkList().subscribe((response) => {
      if (response["code"] == 0) {
        this.serverConnError = response["body"]?.serverConn;
        meterList = response["body"]?.meterList;
        let entityList: Entity[];
        if (this.sessionProfile == PROFILES.ARSON) {
          let clientList = this.SessionDataService.getCurrentClientList();
          entityList = clientList
            .map((client) => client.entityList)
            .reduce((a, b) => a.concat(b));
        } else {
          entityList = this.SessionDataService.getCurrentEntityList();
        }
        meterList?.forEach((meter: EkData) => {
          meter.meterLink =
            meter.id != null
              ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  meter.metrologyType,
                  meter.id
                )
              : null;
          let meterEntity = entityList.find(
            (entity) => entity.id == meter.entity
          );
          meter.entityName = meterEntity?.entity;
          meter.model = this.ManufacturerService.getDeviceType(
            meter.fabricante,
            meter.devType
          );
        });
        this.setColumns();
      }
      this.tableData = meterList;
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "ek-config",
            tooltip: "configuration",
            icon: "fas fa-gears",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.TELEMEDIDA,
          },
          {
            name: "ek-test",
            tooltip: "test-comm",
            icon: "fas fa-broadcast-tower",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.TELEMEDIDA,
          },
        ],
        visible: true,
      },
      {
        title: "ek-connected",
        data: "connected",
        search: "connected",
        sort: "connected",
        alter: {
          condition: "connected",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
        boolean: true,
      },
      {
        title: "entity",
        data: "entityName",
        search: "entityName",
        sort: "entityName",
        visible: true,
      },
      {
        title:
          this.serialNumber?.length > 0
            ? this.serialNumber[0]
            : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: this.sessionProfile != PROFILES.TELEMEDIDA ? "meterLink" : null,
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupation",
          check: "id",
        },
      },
      {
        title: "serial-number-conversor-owasys",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "model",
        data: "model",
        search: "model",
        sort: "model",
        visible: true,
      },
      {
        title: "code",
        data: "codigo",
        search: "codigo",
        sort: "codigo",
        visible: true,
      },
      {
        title: "ek-file-serial-number",
        data: "fileNroSerie",
        search: "fileNroSerie",
        sort: "fileNroSerie",
        visible: true,
      },
      {
        title: "ek-file-included",
        data: "fileIncluded",
        search: "fileIncluded",
        sort: "fileIncluded",
        alter: {
          condition: "fileIncluded",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: this.sessionProfile == "ARSON" ? true : null,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "ICCID",
        data: "iccid",
        search: "iccid",
        sort: "iccid",
        visible: true,
      },
      {
        title: "IP",
        data: "ip",
        search: "ip",
        sort: "ip",
        visible: true,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastReadedTimestampParsed",
        search: "lastReadedTimestampParsed",
        sort: "lastReadedTimestamp",
        date: true,
        visible: true,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "client",
        data: "cliente",
        search: "cliente",
        sort: "cliente",
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
    ];
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: any): void {
    switch (action) {
      case "ek-config":
        this.getEkData(meter);
        break;
      case "ek-test":
        this.MaterialDialogService.openDialog(Ek280TestComponent, meter);
        break;
      default:
        break;
    }
  }

  // Nuevo EK280
  newEk(): void {
    this.router.navigate(["/dispositivos/nuevo"], {
      state: { data: { type: DEVICE_BY_COMM.OWASYS } },
    });
  }

  // Obtención de los datos
  getEkData(meter): void {
    let dataRequests: Observable<object>[] =
      this.sessionProfile == PROFILES.ARSON
        ? [
            this.EkConfigurationController.getEkConfigData(meter.id),
            this.EkConfigurationController.getSimList(),
          ]
        : [this.EkConfigurationController.getEkConfigData(meter.id)];

    forkJoin(dataRequests).subscribe((responses) => {
      // Respuesta del servicio de datos de EK
      if (responses[0]["code"] == 0) {
        let ekConfigData: DeviceEk280 = responses[0]["body"]?.ek280;
        let simData: DeviceEk280Sim = responses[0]["body"]?.sim;
        ekConfigData.iccid = simData?.iccid;
        ekConfigData.simId = simData?.id;

        let windows: { source: string; parsed: string }[] = [
          { source: "ventana1", parsed: "ventana1Parsed" },
          { source: "ventana2", parsed: "ventana2Parsed" },
          { source: "ventana3", parsed: "ventana3Parsed" },
        ];
        windows.forEach((window: { source: string; parsed: string }) => {
          if (ekConfigData[window.source]) {
            let hour: number = Math.floor(ekConfigData[window.source] / 100);
            let hourParsed: string =
              hour < 10 ? "0" + hour.toString() : hour.toString();
            let minutes: number = ekConfigData.ventana1 % 100;
            let minutesParsed: string =
              minutes < 10 ? "0" + minutes.toString() : minutes.toString();
            ekConfigData[window.parsed] = hourParsed + ":" + minutesParsed;
          }
        });
        this.ekConfigData = ekConfigData;
      }

      // Respuesta de servicio de listado de SIM
      if (responses[1] && responses[1]["code"] == 0) {
        this.simList = responses[1]["body"] ? responses[1]["body"] : [];
        this.simList.unshift({
          id: parseInt(this.ekConfigData?.simId),
          iccid: this.ekConfigData?.iccid,
        });
        this.simSelected = this.simList[0].id;
      }

      if (responses[0]["code"] == 0) {
        this.MaterialDialogService.openDialog(Ek280ConfigurationComponent, {
          sessionProfile: this.sessionProfile,
          editModalData: JSON.parse(JSON.stringify(this.ekConfigData)),
          simList: this.simList,
          meterId: meter.id,
        });
      }
    });
  }
}
