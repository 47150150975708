// @angular
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ManagementControllerService } from "../../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { GatewayManagementDialogComponent } from "./gateway-management-dialog/gateway-management-dialog.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  ExtraTableData,
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  MAINTENANCE_GROUPS,
  Maintenance,
  MaintenanceModalData,
} from "../../../management/ManagementInterface.type";
import { GatewayMaintenace } from "../../GatewayInterface.type";

@Component({
  selector: "app-gatewaymanagement",
  templateUrl: "./gateway-management.component.html",
  styleUrls: ["./gateway-management.component.scss"],
})
export class GatewayManagementComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;

  dialog: Subscription;

  // Gateway
  gateway: string;
  gatewayId: number;

  // Tabla de manteniemnto de los gateways
  rowNumbersMaintenance: boolean = true;
  tableMaxRegMaintenance: number = 10;
  gatewayMaintenanceParentData: Maintenance[];
  gatewayMaintenanceChildsData: Maintenance[];
  from: string;
  to: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  historicalStateText: object = {
    0: this.translate.instant("pending"),
    1: this.translate.instant("finished"),
  };
  columnsMaintenance: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit-maintenance",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "new-maintenance",
          tooltip: "new",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "management",
      data: null,
      search: "childsFlag",
      sort: "childsFlag",
      extraTable: true,
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "text",
      data: "historicalText",
      search: "historicalText",
      sort: "historicalText",
      visible: true,
    },
    {
      title: "group",
      data: "historicalGroup",
      search: "historicalGroup",
      sort: "historicalGroup",
      visible: true,
    },
    {
      title: "state",
      data: "historicalStateParsed",
      search: "historicalStateParsed",
      sort: "historicalState",
      visible: true,
    },
  ];

  // Selector de grupo
  groupOptions: object[] = MAINTENANCE_GROUPS.map((group) => {
    return {
      id: group.id,
      value: this.translate.instant(group.value),
    };
  });
  groupSelected: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.dialog = this.SessionDataService
      .getDialogAction()
      .subscribe((dialogAction: any) => {
        if (dialogAction.action == "reload") {
          this.getGatewayMaintenanceData(this.from, this.to);
        }
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.gateway = history.state.data;
    this.getGatewayMaintenanceData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de estado de los gateways
  getGatewayMaintenanceData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.ManagementController.getGatewayMaintenance(
      this.gatewayId,
      this.groupSelected,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          let gatewayMaintenanceData: GatewayMaintenace[] = response["body"];
          gatewayMaintenanceData.forEach((maintenance: GatewayMaintenace) => {
            maintenance.parent.historicalStateParsed =
              maintenance.parent.historicalState != null
                ? this.historicalStateText[maintenance.parent.historicalState]
                : "-";
          });
          this.gatewayMaintenanceParentData = gatewayMaintenanceData.map(
            (maintenance: GatewayMaintenace) => {
              maintenance.parent["childs"] = maintenance.childs;
              if (maintenance.childs.length > 0) {
                maintenance.parent.extraTableData = this.getExtraTableData(
                  maintenance.childs
                );
                maintenance.parent.childsFlag = true;
              } else {
                maintenance.parent.childsFlag = false;
              }
              return maintenance.parent;
            }
          );
        } else {
          this.gatewayMaintenanceParentData["parent"] = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActionsMaintenance(action: string, maintenance: Maintenance): void {
    switch (action) {
      case "edit-maintenance":
        this.editMaintenance(maintenance);
        break;
      case "new-maintenance":
        this.createMaintenance(maintenance);
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla de gateways
  extraTableActionsMaintenance(
    action: string,
    parentElement: Maintenance,
    childElement: Maintenance
  ): void {
    switch (action) {
      case "edit":
        this.editMaintenance(null, {
          maintenanceChild: childElement,
          maintenanceParent: parentElement,
        });
        break;
      default:
        break;
    }
  }

  // Obtención de los datos para la tabla anidada
  getExtraTableData(childsData: Maintenance[]): ExtraTableData {
    return {
      rowNumbers: true,
      data: childsData,
      columns: [
        {
          title: "action",
          data: [
            {
              name: "edit",
              tooltip: "edit",
              icon: "fas fa-edit",
              visible: { attribute: null, rule: true },
              disabled: false,
            },
          ],
          visible: true,
        },
        {
          title: "text",
          data: "historicalText",
          search: "historicalText",
          sort: "historicalText",
          visible: true,
        },
        {
          title: "date",
          data: "timestampParsed",
          search: "timestampParsed",
          sort: "timestampParsed",
          visible: true,
        },
      ],
    };
  }

  // Activación del modal de edición
  editMaintenance(
    maintenance: Maintenance,
    modalData?: MaintenanceModalData
  ): void {
    let data: Maintenance;
    if (maintenance) {
      data = JSON.parse(JSON.stringify(maintenance));
    } else {
      data = JSON.parse(JSON.stringify(modalData.maintenanceChild));
    }
    data.timestampParsed = this.DateParserService
      .parseDateWithoutFormat(data.timestamp)
      .toISOString(true)
      .split(".")[0]
      .slice(0, -3);
    this.MaterialDialogService.openDialog(GatewayManagementDialogComponent, {
      type: maintenance ? "father" : "child",
      maintenance: data,
      gatewayId: this.gatewayId,
      localTimezone: false,
    });
  }

  // Activación de la creación de mantenimiento
  createMaintenance(maintenance?: Maintenance): void {
    let data = new Maintenance();
    data.timestampParsed = this.DateParserService
      .parseDateWithoutFormat(this.DateParserService.getNow())
      .toISOString(true)
      .split(".")[0]
      .slice(0, -3);
    if (maintenance) {
      data.historicalParent = maintenance.id;
    }
    this.MaterialDialogService.openDialog(GatewayManagementDialogComponent, {
      type: maintenance ? "child" : "father",
      maintenance: data,
      gatewayId: this.gatewayId,
      localTimezone: false,
    });
  }
}
