<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          {{ "flow-map" | translate }}
        </h5>

        <div>
          <!-- Velocidad -->
          <div class="flow-evolution-rate">
            <select
              [(ngModel)]="evolutionRate"
              (change)="updateEvolutionRate()"
            >
              <option *ngFor="let rate of rateOptions" value="{{ rate }}">
                {{ rate }}x
              </option>
            </select>
          </div>

          <!-- Botón de reset -->
          <app-material-panel-menu
            class="flow-evolution-reset"
            [onlyButton]="true"
            [icon]="resetIcon"
            [title]="resetTitle"
            (action)="evolutionIndex = 0"
            [disabled]="dataLoading"
          >
          </app-material-panel-menu>

          <!-- Botón de ver evolución -->
          <app-material-panel-menu
            *ngIf="!evolutionPlaying"
            [onlyButton]="true"
            [icon]="playIcon"
            [title]="playTitle"
            (action)="showEvolution()"
            [disabled]="dataLoading"
          >
          </app-material-panel-menu>

          <!-- Botón de pausar evolución -->
          <app-material-panel-menu
            *ngIf="dataLoaded && evolutionPlaying"
            [onlyButton]="true"
            [icon]="pauseIcon"
            [title]="pauseTitle"
            (action)="stopEvolution()"
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div class="panel-body flow-map-container">
        <div class="flow-evolution-map">
          <!-- Mapa -->
          <app-map-controller
            #sensorMap
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            [disableCluster]="true"
            [hideCluster]="true"
            [gatewaysData]="[]"
            [metersData]="mapData"
          >
          </app-map-controller>
        </div>

        <div *ngIf="mapData" class="flow-evolution-graph">
          <!-- Gráfica -->
          <app-graph-controller
            #sensorGraph
            [highchartsOptions]="highchartsOptions"
            [data]="chartOptions"
            [chartConstructor]="chartConstructor"
            (dataRequest)="loadGraphData($event.from, $event.to)"
            (zoomFlag)="updateEvolutionBar($event)"
          >
          </app-graph-controller>
          <!-- Barra de tiempo -->
          <div class="flow-evolution-timestamp pointer" *ngIf="dataLoaded">
            <input
              name="timestampCounter"
              type="range"
              min="0"
              [max]="evolutionBarMax"
              step="1"
              [(ngModel)]="evolutionIndex"
              (click)="updateMapData(); updateGraphHover()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
