// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  NgZone,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
import { Subscription, forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { HomeControllerService } from "../../../services/server/HomeController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { MapSizeService } from "../../../modules/map-module/map-services/MapSizeService.service";
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";
import { MapDeviceMinimalParseService } from "../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { DomControllerService } from "../../../services/shared/DomControllerService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
import { HomeService } from "./home.service";
import { ManufacturerService } from "../../../services/shared/ManufacturerService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { HomeCards } from "./HomeInterface.type";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../interfaces/DeviceGlobalInterface.type";
import {
  GatewayLocation,
  MapGateway,
} from "../../../interfaces/GatewayGlobalInterface.type";
import { MapSearch } from "../../../modules/map-module/MapInterface.type";
// Componentes
import { MapControllerComponent } from "../../../modules/map-module/map-controller/map-controller.component";
// Variables
import { GRAPH_CONFIG } from "../../../modules/graph-module/GRAPH_CONFIG";
import { PROFILES } from "../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-main",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Stream de datos de sesión
  agrupationSub: Subscription;
  cesiumDataSub: Subscription;
  readonly PROFILES = PROFILES;

  // Arrays de contadores y gateways
  devices: MapDevice[];
  devicesTotal: number = 0;
  gateways: MapGateway[];
  gatewayLocations: GatewayLocation[];

  // Variables de sesión
  sessionProfile: string;

  // Variables de cliente, entidad y agrupación
  currentAgrupation: Agrupation;

  // Variables de las tarjetas
  cardsData: HomeCards;
  cardsOrder: string[] = [
    "gateways",
    "txn",
    "concentrators",
    "satelites",
    "meters",
    "valves",
    "mbus",
    "wavenis",
    "une",
    "external",
    "nbiot",
    "sensors",
    "noComunication",
    "noAssigned",
    "lastAlarms",
    "alerts",
    "monthConsume",
    "dayConsume",
  ];
  cardWidth: number;
  cardsGap: number;
  cardEditable: boolean = false;
  loadingCards: boolean = true;
  nroLwMeters: number = 0;
  nroMetersMbusNotReaded: number = 0;
  nroMetersMbus: number = 0;
  nroOtherNotReaded: number = 0;
  nroLwNotReaded: number = 0;
  nroOthers: number = 0;
  @ViewChild("homeCards") homeCards: ElementRef;
  @ViewChild("homeGraphs") homeGraphs: ElementRef;

  // Mapa
  mapFilter: string = "main";
  mapType: string = "standard";
  @ViewChild("homePanel") homePanel: ElementRef;
  @ViewChild("mapContainer") mapContainer: ElementRef;
  mapHeight: number;
  mapPadding: number = 0;
  mapSearch: MapSearch;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  drawAgrupationOutline: boolean;
  cheatMode: boolean = false;

  // Weather API
  weatherCoords: { latitude: number; longitude: number };

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.setCardSize();
    this.resizeMap();
  }

  // Gráfica
  graphsHidden: boolean = false;
  mainHighchartsOptions: Options;
  mainChartOptions: any;
  secondaryHighchartsOptions: Options;
  secondaryChartOptions: any;

  // Cambiar a verde
  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (
      this.sessionProfile == PROFILES.ARSON &&
      event.altKey &&
      event.key == "v"
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleCheatMode();
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private ManufacturerService: ManufacturerService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private HomeService: HomeService,
    private ngZone: NgZone,
    private MapSizeService: MapSizeService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.drawAgrupationOutline = this.currentAgrupation?.virtual;

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    // Inicialización del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/
  ngAfterViewInit(): void {
    // Ajuste del tamaño del mapa
    if (this.currentAgrupation) {
      this.updateMapSize();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización
  loadComponent(): void {
    this.cardsData = new HomeCards();
    this.devices = null;
    this.gateways = null;
    this.setCardSize();
    this.getCardsData();
    // Sesión especial
    if (
      this.sessionProfile == PROFILES.ARSON &&
      this.SessionDataService.getCurrentUser() == "Presentaciones"
    ) {
      this.cheatMode = true;
      this.getMapData(true);
      // Sesión estándar
    } else {
      this.getMapData();
    }
  }

  // Cheat mode
  toggleCheatMode(): void {
    this.cheatMode = !this.cheatMode;
    this.cardEditable = this.cheatMode;
    this.drawAgrupationOutline = !this.cheatMode;
    this.getMapData(this.cheatMode);
  }

  // Seteo de tamaño de tarjeta
  setCardSize(): void {
    if (window.innerWidth > 1200) {
      this.cardWidth = 45;
      this.cardsGap = 2.5;
    } else {
      this.cardWidth = 90;
      this.cardsGap = 5;
    }
  }

  // Obtención de los datos para las tarjetas
  getCardsData(): void {
    this.HomeController.getCards(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let cardsData: HomeCards = {};
          // Alarmas últimos 5 días
          cardsData.lastAlarms = {
            data: response["body"]?.nroAlarmsLastFiveDays,
            type: "number",
          };
          // Consumo último día
          cardsData.dayConsume = {
            info: response["body"]?.lastDayConsumption,
            date: response["body"]?.lastDayConsumptionTimestamp,
            dateFormat: this.SessionDataService.getCurrentDateFormat(),
          };
          // Consumo último mes
          cardsData.monthConsume = {
            info: response["body"]?.lastMonthConsumption,
            date: response["body"]?.lastMonthConsumptionTimestamp,
            dateFormat: "MM/YYYY",
          };
          this.updateCardsData(cardsData);
        }
      }
    );
  }

  // Asignación de las opciones concretas para la gráfica de dispositivos
  setMainHighchartsOptions(): void {
    let highchartsOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    highchartsOptions.plotOptions.pie = {
      size: "120%",
      innerSize: "60%",
      borderRadius: 0,
      borderWidth: 0.75,
      borderColor: "black",
      shadow: false,
      colorByPoint: true,
      startAngle: 0,
      endAngle: 0,
      center: [],
    };
    highchartsOptions.exporting = { enabled: false };
    highchartsOptions.tooltip = { enabled: false };
    highchartsOptions.title = {
      useHTML: true,
      text:
        `<span class="home-graph-title">` +
        this.translate.instant("devices") +
        `<br><span>` +
        formatNumber(
          this.devicesTotal,
          this.SessionDataService.getCurrentNumberFormat()
        ) +
        `</span></span>`,
      floating: true,
      style: {
        fontWeight: "normal",
      },
      align: "center",
      verticalAlign: "middle",
    };
    this.mainHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica de dispositivos
  setMainChartsOptions(): void {
    const self = this;
    let series =
      this.nroMetersMbus > 0
        ? [
            // ANCHOR Serie de totales por tipo de comunicación
            {
              id: "commType",
              name: this.translate.instant("meters"),
              animation: true,
              size: "75%",
              innerSize: "70%",
              data: [
                {
                  color: "#9e5e2d",
                  id: "other-in-network",
                  name: this.translate.instant("others"),
                  y:
                    (this.nroOthers - this.nroOtherNotReaded) /
                    this.devicesTotal,
                  value: this.nroOthers - this.nroOtherNotReaded,
                },
                {
                  color: "#80bd7f",
                  id: "lw-in-network",
                  name: "LoRa",
                  y: this.nroLwMeters / this.devicesTotal,
                  value: this.nroLwMeters,
                },
                {
                  color: "#7baeaf",
                  id: "mbus-in-network",
                  name: "MBus",
                  y:
                    (this.nroMetersMbus - this.nroMetersMbusNotReaded) /
                    this.devicesTotal,
                  value: this.nroMetersMbus - this.nroMetersMbusNotReaded,
                },
                {
                  color: "#d3d3d3",
                  id: "lw-no-communication",
                  name: "LoRa",
                  y: this.nroLwNotReaded / this.devicesTotal,
                  value: this.nroLwNotReaded,
                },
                {
                  color: "#5a8bda",
                  id: "mbus-no-communication",
                  name: "MBus",
                  y: this.nroMetersMbusNotReaded / this.devicesTotal,
                  value: this.nroMetersMbusNotReaded,
                },
                {
                  color: "#9e5e2d",
                  id: "other-no-communication",
                  name: this.translate.instant("others"),
                  y: this.nroOtherNotReaded / this.devicesTotal,
                  value: this.nroOtherNotReaded,
                },
                {
                  color: "#ffffff",
                  borderColor: "#ffffff",
                  id: "pending",
                  name: "",
                  y: this.cardsData.noAssigned.data / this.devicesTotal,
                  value: this.cardsData.noAssigned.data,
                },
              ],
              dataLabels: {
                formatter: function () {
                  return this.y != 0 && this.point.id != "pending"
                    ? "<b>" + this.point.name + "</b>"
                    : null;
                },
                color: "#ffffff",
                distance: window.innerWidth > 980 ? "2.5%" : "-10%",
                crop: "false",
                overflow: "allow",
                allowOverlap: true,
                style: {
                  fontSize: "1.2rem",
                  textOverflow: "none",
                },
                zIndex: 99,
              },
              dataGrouping: { approximation: "sum" },
              events: {
                click: (e) => {
                  self.redirectTo(e.target?.point?.id);
                },
              },
              point: {
                events: {
                  mouseOver: (e) => {
                    e.target.update({
                      dataLabels: {
                        formatter: function () {
                          return this.y != 0 && this.point.id != "pending"
                            ? "<b>" +
                                this.point.name +
                                "</b><br>" +
                                (this.point.percentage % 1
                                  ? this.point.percentage.toFixed(2)
                                  : this.point.percentage) +
                                "%<br>" +
                                this.point.value
                            : null;
                        },
                        crop: "true",
                        overflow: "allow",
                        allowOverlap: true,
                        distance: window.innerWidth > 980 ? "0%" : "-10%",
                        style: {
                          fontSize: "1.6rem",
                        },
                        zIndex: 99,
                      },
                    });
                  },
                  mouseOut: (e) => {
                    e.target.update({
                      dataLabels: {
                        style: {
                          fontSize: "1rem",
                        },
                      },
                    });
                  },
                },
              },
            },
            // ANCHOR Serie de totales por estado
            {
              id: "totals",
              name: this.translate.instant("meters"),
              animation: true,
              size: "120%",
              innerSize: "80%",
              data: [
                {
                  color: "#59aa5a",
                  id: "in-network",
                  name: this.translate.instant("in-network"),
                  y:
                    (this.nroLwMeters +
                      (this.nroOthers - this.nroOtherNotReaded) +
                      (this.nroMetersMbus - this.nroMetersMbusNotReaded)) /
                    this.devicesTotal,
                  value:
                    this.nroLwMeters +
                    (this.nroOthers - this.nroOtherNotReaded) +
                    (this.nroMetersMbus - this.nroMetersMbusNotReaded),
                },
                {
                  color: "#bdbebd",
                  id: "no-communication",
                  name: this.translate.instant("no-communication"),
                  y:
                    (this.nroLwNotReaded +
                      this.nroMetersMbusNotReaded +
                      this.nroOtherNotReaded) /
                    this.devicesTotal,
                  value:
                    this.nroLwNotReaded +
                    this.nroMetersMbusNotReaded +
                    this.nroOtherNotReaded,
                },
                {
                  color: "#b255c1",
                  id: "pending",
                  name: this.translate.instant("pending"),
                  y: this.cardsData.noAssigned.data / this.devicesTotal,
                  value: this.cardsData.noAssigned.data,
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.y != 0
                    ? "<b>" +
                        this.point.name +
                        "</b><br>" +
                        (this.point.percentage % 1
                          ? this.point.percentage.toFixed(2)
                          : this.point.percentage) +
                        "%<br><span class='home-graph-total'>" +
                        this.point.value +
                        "</span>"
                    : null;
                },
                color: "#000000",
                crop: false,
                allowOverlap: true,
                distance: window.innerWidth > 980 ? "5%" : "-0.1%",
                style: {
                  fontWeight: "normal",
                  fontSize: "1.2rem",
                },
              },
              dataGrouping: { approximation: "sum" },
              cursor: "pointer",
              events: {
                click: (e) => {
                  self.redirectTo(e.target?.point?.id);
                },
              },
              point: {
                events: {
                  mouseOver: (e) => {
                    let chart = e.target.series.chart;
                    if (e.target.id == "in-network") {
                      chart.series[0].points[0].setState("hover");
                      chart.series[0].points[1].setState("hover");
                      chart.series[0].points[2].setState("hover");
                    } else if (e.target.id == "no-communication") {
                      chart.series[0].points[3].setState("hover");
                      chart.series[0].points[4].setState("hover");
                      chart.series[0].points[5].setState("hover");
                    }
                    e.target.update({
                      dataLabels: {
                        style: {
                          fontSize: "1.6rem",
                        },
                      },
                    });
                  },
                  mouseOut: (e) => {
                    e.target.update({
                      dataLabels: {
                        style: {
                          fontSize: "1.2rem",
                        },
                      },
                    });
                  },
                },
              },
            },
          ]
        : [
            // ANCHOR Serie de totales por estado sin tipo de comunicación
            {
              id: "totals",
              name: this.translate.instant("meters"),
              animation: true,
              size: "100%",
              innerSize: "70%",
              data: [
                {
                  color: "#59aa5a",
                  id: "in-network",
                  name: this.translate.instant("in-network"),
                  y:
                    (this.nroLwMeters +
                      (this.nroOthers - this.nroOtherNotReaded) +
                      (this.nroMetersMbus - this.nroMetersMbusNotReaded)) /
                    this.devicesTotal,
                  value:
                    this.nroLwMeters +
                    (this.nroOthers - this.nroOtherNotReaded) +
                    (this.nroMetersMbus - this.nroMetersMbusNotReaded),
                },
                {
                  color: "#bdbebd",
                  id: "no-communication",
                  name: this.translate.instant("no-communication"),
                  y:
                    (this.nroLwNotReaded +
                      this.nroMetersMbusNotReaded +
                      this.nroOtherNotReaded) /
                    this.devicesTotal,
                  value:
                    this.nroLwNotReaded +
                    this.nroMetersMbusNotReaded +
                    this.nroOtherNotReaded,
                },
                {
                  color: "#b255c1",
                  id: "pending",
                  name: this.translate.instant("pending"),
                  y: this.cardsData.noAssigned.data / this.devicesTotal,
                  value: this.cardsData.noAssigned.data,
                },
              ],
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return this.y != 0
                    ? "<b>" +
                        this.point.name +
                        "</b><br>" +
                        (this.point.percentage % 1
                          ? this.point.percentage.toFixed(2)
                          : this.point.percentage) +
                        "%<br><span class='home-graph-total'>" +
                        this.point.value +
                        "</span>"
                    : null;
                },
                color: "#000000",
                crop: false,
                allowOverlap: true,
                distance: "10%",
                style: {
                  fontWeight: "normal",
                  fontSize: "1.5rem",
                },
              },
              dataGrouping: { approximation: "sum" },
              cursor: "pointer",
              events: {
                click: (e) => {
                  self.redirectTo(e.target?.point?.id);
                },
              },
            },
          ];
    let chartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["yAxis"];
    delete chartOptions["rangeSelector"];
    chartOptions["navigator"] = false;
    chartOptions["chart"] = {
      width:
        window.innerHeight > 2000 ? 600 : window.innerHeight > 1200 ? 275 : 250,
      type: "pie",
      style: {
        overflow: "visible",
      },
    };
    chartOptions["series"] = series;
    this.mainChartOptions = chartOptions;
  }

  // Asignación de las opciones concretas para la gráfica de alarmas
  setSecondaryHighchartsOptions(): void {
    const self = this;
    let highchartsOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    highchartsOptions.exporting = { enabled: false };
    highchartsOptions.tooltip = { enabled: false };
    highchartsOptions.title = null;
    highchartsOptions.legend = { enabled: false };
    highchartsOptions.plotOptions.pictorial = {
      pointPadding: 0,
      groupPadding: 0,
      borderColor: "black",
      borderWidth: 1,
      colorByPoint: true,
      type: "pictorial",
      size: "100%",
      dataLabels: {
        enabled: true,
        crop: false,
        formatter: function () {
          return this.y != 0
            ? "<b>" +
                this.point.name +
                "</b><br>" +
                (this.point.percentage % 1
                  ? this.point.percentage.toFixed(2)
                  : this.point.percentage) +
                "%<br>"
            : "";
        },
        overflow: "allow",
        align: "right",
        x: window.innerWidth > 980 ? 50 : 30,
        style: {
          fontWeight: "normal",
          fontSize: "1.5rem",
          color: "black",
        },
        connectorWidth: 0,
      },
      enableMouseTracking: false,
    };
    this.secondaryHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica de alarmas
  setSecondaryChartsOptions(): void {
    let chartOptions: object = {};
    let series = [
      {
        stacking: "percent",
        animation: true,
        data: [
          {
            color: "#59aa5a",
            name: this.translate.instant("ok"),
            id: "ok",
            y:
              ((this.devicesTotal - this.cardsData.alerts.data) /
                this.devicesTotal) *
              100,
            x: 0,
            value: this.devicesTotal - this.cardsData.alerts.data,
          },
          {
            color: "#ffbb45",
            id: "alarms",
            name: this.translate.instant("with-alarms"),
            y: (this.cardsData.alerts.data / this.devicesTotal) * 100,
            x: 0,
            value: this.cardsData.alerts.data,
          },
        ],
        paths: [
          {
            definition:
              "M538.5,386.199L356.5,70.8c-16.4-28.4-46.7-45.9-79.501-45.9c-32.8,0-63.1,17.5-79.5,45.9L12.3,391.6c-16.4,28.4-16.4,63.4,0,91.8C28.7,511.8,59,529.3,91.8,529.3H462.2c0.101,0,0.2,0,0.2,0c50.7,0,91.8-41.101,91.8-91.8C554.2,418.5,548.4,400.8,538.5,386.199z M316.3,416.899c0,21.7-16.7,38.3-39.2,38.3s-39.2-16.6-39.2-38.3V416c0-21.601,16.7-38.301,39.2-38.301S316.3,394.3,316.3,416V416.899z M317.2,158.7L297.8,328.1c-1.3,12.2-9.4,19.8-20.7,19.8s-19.4-7.7-20.7-19.8L237,158.6c-1.3-13.1,5.801-23,18-23H299.1C311.3,135.7,318.5,145.6,317.2,158.7z",
          },
        ],
      },
    ];
    chartOptions["chart"] = {
      width:
        window.innerHeight > 2000 ? 420 : window.innerHeight > 1200 ? 245 : 210,
      height:
        window.innerHeight > 2000 ? 350 : window.innerHeight > 1200 ? 225 : 175,
      type: "pictorial",
      style: {
        overflow: "visible",
      },
    };
    chartOptions["yAxis"] = {
      visible: false,
      stackShadow: {
        enabled: true,
      },
      max: 100,
    };
    chartOptions["xAxis"] = {
      visible: false,
      max: 0,
    };
    chartOptions["navigator"] = false;
    chartOptions["series"] = series;
    this.secondaryChartOptions = chartOptions;
  }

  // Redirección desde gráfica
  redirectTo(target: string): void {
    switch (target) {
      case "in-network":
        this.router.navigate(["/dispositivos/listado/asignados"], {
          state: { data: ["ACTIVE"] },
        });
        break;
      case "pending":
        this.router.navigate(["/dispositivos/listado/no-asignados"]);
        break;
      case "no-communication":
        this.router.navigate(["/dispositivos/listado/sin-comunicacion"]);
        break;
      case "alarms":
        this.router.navigate(["/alarmas/listado"]);
        break;
      default:
        break;
    }
  }

  // Obtención de los datos para el mapa
  getMapData(allGreen?: boolean): void {
    let gateways = [];
    let devices = [];

    let requests = [
      // Mapa
      this.HomeController.getMarkers(this.currentAgrupation.id),
    ];

    if (this.sessionProfile == PROFILES.ARSON) {
      requests.push(
        // Localizaciones
        this.HomeController.getLocations(
          this.SessionDataService.getCurrentEntity()?.id
        )
      );
    }

    forkJoin(requests).subscribe((responses) => {
      // Respuesta de localizaciones
      if (responses[1] && responses[1]["code"] == 0) {
        this.gatewayLocations = responses[1]["body"];
      }

      // Respuesta de mapa
      if (responses[0]["code"] == 0) {
        let cardsData: HomeCards = {};
        let nroConcentradores: number = 0;
        let nroConcentradoresComm: number = 0;
        let nroSatelites: number = 0;
        let nroNoComunican: number = 0;
        let nroSensors: number = 0;
        let nroMetersNotAssigned: number = 0;
        let nroMetersWithAlarm: number = 0;
        let nroMetersMbus: number = 0;
        let nroMetersMbusReaded: number = 0;
        let nroMetersWavenis: number = 0;
        let nroMetersUne: number = 0;
        let nroMetersExternal: number = 0;
        let nroMetersNbiot: number = 0;
        let nroValves: number = 0;
        let nroTxn1: number = 0;
        let nroTxn4: number = 0;

        // Dispositivos
        devices = this.MapDeviceMinimalParse.parseDevices(
          responses[0]["body"]["contadores"]
        );

        // Filtrado de dispositivos de pruebas
        devices = devices.filter((device: MapDevice) => device.fabricante != 7);
        // Filtrado de dispositivos de agrupaciones no visibles en virtual
        if (this.currentAgrupation.showAllEntity) {
          devices = this.HomeService.filterDisabledAgrupations(devices);
        }
        // this.devicesTotal = devices.filter(device => device..length;
        // Gateways
        gateways = responses[0]["body"]["gateways"];
        // Datos de tarjetas
        devices.forEach((device: MapDevice) => {
          let deviceType = this.DeviceTypeService.getDeviceTypeByMask(
            device.tipo,
            device.metrologyType,
            device.fabricante
          );

          // Cheat mode
          if (
            allGreen &&
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS_CON ||
              deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
              deviceType == DEVICE_BY_COMM.LW_MBUS)
          ) {
            device.comunica = true;
            device.unidadVentaGw = device.unidadVentaGw
              ? device.unidadVentaGw
              : "000000000000";
          }

          // ANCHOR TXN
          if (deviceType == DEVICE_BY_COMM.TXN) {
            let txnType = this.ManufacturerService.getManufacturer(
              String(device.fabricante),
              String(device.devType),
              LANGUAGE.ESPANOL
            )?.deviceText;
            if (txnType == "N1") {
              nroTxn1++;
            }
            if (txnType == "N4") {
              nroTxn4++;
            }
          } else {
            this.devicesTotal++;
          }

          // ANCHOR Contadores LW
          if (
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS) &&
            device.unidadVentaGw &&
            device.comunica &&
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS)
          ) {
            this.nroLwMeters++;
          } else if (
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS) &&
            device.unidadVentaGw &&
            !device.comunica &&
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS ||
              device.metrologyType == METROLOGY_TYPE.WATER_VALVE)
          ) {
            this.nroLwNotReaded++;
          }

          // ANCHOR Concentradores
          if (
            (deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
              deviceType == DEVICE_BY_COMM.LW_MBUS_CON) &&
            device.unidadVentaGw
          ) {
            nroConcentradores++;
            if (device.comunica) {
              nroConcentradoresComm++;
            } else {
              this.nroOtherNotReaded++;
            }
          }

          // ANCHOR Sin comunicación
          if (
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS_CON ||
              deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
              deviceType == DEVICE_BY_COMM.LW_MBUS) &&
            !device.comunica &&
            device.unidadVentaGw
          ) {
            nroNoComunican++;
          }

          // ANCHOR No asignados
          if (
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS_CON ||
              deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
              deviceType == DEVICE_BY_COMM.LW_MBUS) &&
            !device.unidadVentaGw
          ) {
            nroMetersNotAssigned++;
          }

          // ANCHOR Válvulas
          if (device.metrologyType == METROLOGY_TYPE.WATER_VALVE) {
            if (device.comunica) {
              nroValves++;
            }
          }

          // ANCHOR Contadores MBus
          if (
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS) &&
            deviceType == DEVICE_BY_COMM.MBUS
          ) {
            nroMetersMbus++;
            if (device.comunica) {
              nroMetersMbusReaded++;
            }
            if (this.cheatMode) {
              device.comunica = true;
            }
          }

          // ANCHOR Contadores Wavenis
          if (
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS) &&
            deviceType == DEVICE_BY_COMM.WAVENIS
          ) {
            nroMetersWavenis++;
            this.nroOtherNotReaded++;
          }

          // ANCHOR Contadores UNE
          if (deviceType == DEVICE_BY_COMM.UNE) {
            nroMetersUne++;
            if (!device.comunica) {
              this.nroOtherNotReaded++;
            }
          }

          // ANCHOR Contadores Externos
          if (
            deviceType == DEVICE_BY_COMM.EK280 ||
            deviceType == DEVICE_BY_COMM.OWASYS ||
            deviceType == DEVICE_BY_COMM.PLUM ||
            deviceType == DEVICE_BY_COMM.EXTERNO ||
            deviceType == DEVICE_BY_COMM.ERM ||
            deviceType == DEVICE_BY_COMM.API ||
            deviceType == DEVICE_BY_COMM.NO_LORA_NO_COM ||
            deviceType == DEVICE_BY_COMM.API_TOKEN
          ) {
            nroMetersExternal++;
            if (!device.comunica) {
              this.nroOtherNotReaded++;
            }
          }

          // ANCHOR Contadores NBIoT
          if (deviceType == DEVICE_BY_COMM.NBIOT) {
            nroMetersNbiot++;
            if (!device.comunica) {
              this.nroOtherNotReaded++;
            }
          }

          // ANCHOR Sensores
          if (
            (device.metrologyType == METROLOGY_TYPE.SENSOR ||
              device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) &&
            device.unidadVentaGw
          ) {
            nroSensors++;
            if (!device.comunica) {
              this.nroOtherNotReaded++;
            }
          }

          // ANCHOR Satélites
          if (
            device.metrologyType == METROLOGY_TYPE.SATELITE &&
            device.unidadVentaGw
          ) {
            nroSatelites++;
            if (!device.comunica) {
              this.nroOtherNotReaded++;
            }
          }

          // Con alarmas
          if (device.alarm) {
            nroMetersWithAlarm++;
          }
        });

        // Total de otros
        this.nroOthers =
          nroMetersWavenis +
          nroMetersUne +
          nroMetersExternal +
          nroSensors +
          nroSatelites +
          nroMetersNbiot +
          nroConcentradores +
          nroValves;

        // ANCHOR Tarjetas opcionales
        // Dispositivos LW
        if (this.nroLwMeters > 0) {
          cardsData.meters = {
            data: this.nroLwMeters,
            type: "number",
          };
        }
        // Dispositivos LW
        if (nroTxn1 > 0 || nroTxn4 > 0) {
          cardsData.txn = {
            data:
              (nroTxn1 ? nroTxn1 + " (N1)" : "") +
              (nroTxn1 && nroTxn4 ? ", " : "") +
              (nroTxn4 ? nroTxn4 + " (N4)" : ""),
            type: "text",
          };
        }
        // Válvulas
        if (nroValves > 0) {
          cardsData.valves = {
            data: nroValves,
            type: "number",
          };
        }
        // Concentradores
        if (nroConcentradoresComm > 0) {
          cardsData.concentrators = {
            data: nroConcentradoresComm,
            type: "number",
          };
        }
        // Satélites
        if (nroSatelites > 0) {
          cardsData.satelites = {
            data: nroSatelites,
            type: "number",
          };
        }
        // Mbus
        if (nroMetersMbus > 0) {
          cardsData.mbus = {
            data:
              nroMetersMbus +
              " (" +
              this.translate.instant("readed") +
              ": " +
              (this.cheatMode ? nroMetersMbus : nroMetersMbusReaded) +
              ")",
            type: "text",
          };
          this.nroMetersMbusNotReaded = this.cheatMode
            ? 0
            : nroMetersMbus - nroMetersMbusReaded;
          this.nroMetersMbus = nroMetersMbus;
        }
        // Wavenis
        if (nroMetersWavenis > 0) {
          cardsData.wavenis = {
            data: nroMetersWavenis,
            type: "number",
          };
        }
        // Une
        if (nroMetersUne > 0) {
          cardsData.une = {
            data: nroMetersUne,
            type: "number",
          };
        }
        // Externos
        if (nroMetersExternal > 0) {
          cardsData.external = {
            data: nroMetersExternal,
            type: "number",
          };
        }
        // Nbiot
        if (nroMetersNbiot > 0) {
          cardsData.nbiot = {
            data: nroMetersNbiot,
            type: "number",
          };
        }
        // Sensores
        if (nroSensors > 0) {
          cardsData.sensors = {
            data: nroSensors,
            type: "number",
          };
        }

        // ANCHOR Tarjetas fijas
        // Gateways
        cardsData.gateways = {
          data: gateways?.length,
          type: "number",
        };
        // Sin comunicación
        cardsData.noComunication = {
          data: nroNoComunican,
          type: "number",
        };
        // No asignados
        cardsData.noAssigned = {
          data: nroMetersNotAssigned,
          type: "number",
        };
        // Con alarmas
        cardsData.alerts = {
          data: nroMetersWithAlarm,
          type: "number",
        };

        // Actualización de tarjetas
        this.updateCardsData(cardsData);
        this.loadingCards = true;
      }

      // Búsqueda en mapa
      this.mapSearch = {
        title: this.translate.instant("search"),
        bindValue: "id",
        bindLabel: "nroSerie",
        data: devices,
      };

      // Datos de mapa
      this.devices = devices;
      this.gateways = gateways;

      // Gráfica
      if (this.devicesTotal != 0) {
        this.setMainHighchartsOptions();
        this.setMainChartsOptions();
        this.setSecondaryHighchartsOptions();
        this.setSecondaryChartsOptions();
      }

      // Coordenadas de clima
      this.getWeatherCoords();
    });
  }

  // Actualización de tarjetas
  updateCardsData(cardsData: HomeCards): void {
    let newCardsData = { ...this.cardsData };
    for (let cardType in cardsData) {
      newCardsData[cardType] = cardsData[cardType];
    }
    this.cardsData = { ...newCardsData };
    this.updateMapSize();
  }

  // Actualización de tamaño del mapa
  updateMapSize(): void {
    this.DomControllerService.elementReady("#home-cards").then(() => {
      setTimeout(() => {
        this.homePanel?.nativeElement?.style?.setProperty(
          "--home-min-height",
          80 +
            this.homeCards?.nativeElement?.offsetHeight +
            this.homeGraphs?.nativeElement?.offsetHeight
        );
        this.resizeMap();
      }, 0);
    });
  }

  // Obtención de las coordenas para la API de clima
  getWeatherCoords(): void {
    // Array de coordenadas
    let devicesCoords: { latitude: string; longitude: string }[] = this.devices
      .filter(
        (device: MapDevice) =>
          device.latitude != null && device.longitude != null
      )
      .map((device: MapDevice) => {
        return { latitude: device.latitude, longitude: device.longitude };
      });

    if (devicesCoords.length > 0) {
      // Latitud media
      let devicesLatitudes: number[] = devicesCoords.map(
        (device: { latitude: string; longitude: string }) => {
          return parseFloat(device.latitude);
        }
      );
      let averageLatitude: number =
        devicesLatitudes?.reduce((a, b) => a + b, 0) / devicesLatitudes?.length;

      // Longitud media
      let devicesLongitudes: number[] = devicesCoords.map(
        (device: { latitude: string; longitude: string }) => {
          return parseFloat(device.longitude);
        }
      );
      let averageLongitude: number =
        devicesLongitudes?.reduce((a, b) => a + b, 0) /
        devicesLongitudes?.length;

      // Actualización de coordenadas de tiempo
      this.SessionDataService.sendWeatherCoords({
        latitude: averageLatitude,
        longitude: averageLongitude,
        agrupation: this.currentAgrupation.name,
      });
    } else {
      this.SessionDataService.sendWeatherCoords(null);
    }
  }

  // Ajuste del tamaño del mapa acorde a la visibilidad de las tarjetas
  resizeMap(): void {
    this.mapHeight = this.MapSizeService.calcMapHeight(
      this.homePanel,
      null,
      this.mapPadding
    );
  }

  // Selección de dispositivos en mapa
  goToTable(selectedDevices: MapDevice[]): void {
    if (selectedDevices.length > 0) {
      this.ToastService.fireAlertWithTripleOptions(
        "question",
        this.translate.instant("select-option"),
        this.translate.instant("cancel"),
        this.translate.instant("filter-table-by-map"),
        this.translate.instant("filter-in-network")
      ).then((userSelection: string) => {
        if (userSelection == "option1") {
          this.ngZone.run(() => {
            this.router.navigate(["/dispositivos/listado/seleccionados"], {
              state: {
                data: { devices: selectedDevices, gateways: this.gateways },
              },
            });
          });
        } else if (userSelection == "option2") {
          this.ngZone.run(() => {
            this.router.navigate(["/dispositivos/listado/asignados"], {
              state: {
                data: { devices: selectedDevices },
              },
            });
          });
        }
      });
    }
  }

  // Activación de mapa 3d
  activate3dMap(): void {
    this.SessionDataService.sendCesiumData({
      active: true,
      devices: this.devices,
      gateways: this.gateways,
      locations: this.gatewayLocations,
      mapType: this.mapType,
      mapHeight: this.homePanel.nativeElement.offsetHeight - 40,
      drawAgrupationOutline: this.currentAgrupation.virtual,
      allowKml: true,
    });
  }
}
