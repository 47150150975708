<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b
            >{{ "alarms" | translate }} {{ "gateway" | translate }}:
            {{ unidadVenta }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewayAlarms'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [orderBy]="orderBy"
          [specialFilter]="true"
          [data]="gatewayAlarms"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
