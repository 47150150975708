// @angular
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { NavbarMenuService } from "./navbar-menu.service";
import { ThemeService } from "../../../themes/theme.service";
// Variables
import { NAVBAR_SHORTCUTS } from "./navbar-shortcuts";
import { CUSTOM_URLS } from "../../../screens/login/CUSTOM_URLS";
import { PROFILES } from "../../../../assets/profiles/profiles";
// Interfaces
import { Theme } from "../../../themes/symbols";
import { MenuItem } from "./navbar-menu";

@Component({
  selector: "app-navbar-side",
  templateUrl: "./navbar-side.component.html",
  styleUrls: ["./navbar-side.component.scss"],
})
export class NavbarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  sessionProfile: string;
  sessionProfileSub: Subscription;
  readonly PROFILES = PROFILES;

  // Logo
  @Input() logoImgUrl: any;
  logoLoading: boolean = true;
  customLogo = CUSTOM_URLS.find((url) =>
    window.location.href.includes(url.urlText)
  );
  sidebarLogo: string = this.customLogo
    ? this.customLogo.sidebarImg
    : "assets/img/logos/arsondata_white.png";

  // Menús
  menuList: MenuItem[];
  menuListSub: Subscription;
  activeTheme: Theme;
  sidebarToggled: boolean = true;
  sidebarLocked: boolean = false;
  @Output() sidebarUpdate = new EventEmitter<boolean>();

  // Estilos
  themeUpdatedSub: Subscription;

  // Submenú
  @ViewChild("sidebar") sidebar: ElementRef;
  activeSubmenu: MenuItem[];
  activeSubmenuSubmenu: MenuItem[];
  menuActive: boolean = false;
  submenuActive: boolean = false;
  menuOpenDelay: number = 500;
  menuCollapseDelay: number = 500;
  menuClosing: ReturnType<typeof setTimeout>;
  submenuClosing: ReturnType<typeof setTimeout>;
  sidebarCollapseTimeout: ReturnType<typeof setTimeout>;
  sidebarOpenTimeout: ReturnType<typeof setTimeout>;

  // Accesos directos
  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.altKey) {
      event.preventDefault();
      event.stopPropagation();
      if (NAVBAR_SHORTCUTS[event.key.toUpperCase()]) {
        this.router.navigate([NAVBAR_SHORTCUTS[event.key.toUpperCase()]]);
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Contructor
  /***************************************************************************/

  constructor(
    private navbarMenu: NavbarMenuService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private theme: ThemeService
  ) {}

  /***************************************************************************/
  // ANCHOR Iniciacilización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.navbarMenu.loadMenu();
    this.menuList = this.SessionDataService.getCurrentNavbarMenu();
    this.activeTheme = this.theme.getActiveTheme();

    this.sessionProfileSub = this.SessionDataService.getProfile().subscribe(
      (sessionProfile) => {
        this.sessionProfile = sessionProfile;
      }
    );

    this.menuListSub = this.SessionDataService.getNavbarMenu().subscribe(
      (navbarMenu) => {
        this.menuList = navbarMenu;
      }
    );

    // Actualización de estilos
    this.themeUpdatedSub = this.theme.themeChange.subscribe(
      (activeTheme: Theme) => (this.activeTheme = activeTheme)
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.sessionProfileSub.unsubscribe();
    this.menuListSub.unsubscribe();
    this.themeUpdatedSub.unsubscribe();
    clearTimeout(this.sidebarOpenTimeout);
    clearTimeout(this.submenuClosing);
    clearTimeout(this.menuClosing);
    clearTimeout(this.sidebarCollapseTimeout);
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado del componente
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.sidebar.nativeElement.classList.remove();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Aviso de carga del logo
  onLogoLoad(): void {
    this.logoLoading = false;
  }

  // Submenú
  openSubmenu(target: HTMLElement, menu: MenuItem): void {
    // Control de desbordamiento vertical
    let submenuTop = this.checkTop(target, menu);
    // Seteo de posicionamiento vertical
    this.sidebar.nativeElement.style.setProperty(
      "--sidebar-submenu-top",
      submenuTop + "px"
    );
    this.activeSubmenu = menu.submenu;
    this.activeSubmenuSubmenu = null;
  }

  // Submenú de submenú
  openSubmenuSubmenu(target: HTMLElement, submenu: MenuItem): void {
    // Control de desbordamiento vertical
    let submenuTop = this.checkTop(target, submenu);
    // Seteo de posicionamiento vertical
    this.sidebar.nativeElement.style.setProperty(
      "--sidebar-submenu-submenu-top",
      submenuTop + "px"
    );
    // Seteo de posicionamiento horizontal
    this.sidebar.nativeElement.style.setProperty(
      "--sidebar-submenu-submenu-left",
      target.parentElement.offsetLeft +
        target.offsetLeft +
        target.offsetWidth +
        "px"
    );
    this.activeSubmenuSubmenu = submenu.submenu;
  }

  // Control de desbordamiento vertical de menú
  checkTop(target: HTMLElement, menu: MenuItem): number {
    let windowHeight = window.innerHeight;
    let profileHeight = menu.submenu.filter(
      (submenu) => submenu.profile
    ).length;
    let menuHeight =
      11 * parseFloat(getComputedStyle(document.documentElement).fontSize);
    let submenuTop = target.parentElement.offsetTop + target.offsetTop - 8;
    if (menuHeight + submenuTop + profileHeight * 48 > windowHeight - 50) {
      submenuTop = windowHeight - (menuHeight + 50 + profileHeight * 48);
    }
    return submenuTop;
  }

  // Apertura de barra lateral
  openSidebar(): void {
    if (!this.sidebarLocked) {
      this.sidebarOpenTimeout = setTimeout(
        () => (this.sidebarToggled = false),
        this.menuOpenDelay
      );
    }
  }

  // Cierre de menús
  collapseSidebar(): void {
    clearTimeout(this.sidebarOpenTimeout);
    if (!this.sidebarLocked) {
      this.sidebarCollapseTimeout = setTimeout(() => {
        this.sidebarToggled = true;
      }, this.menuCollapseDelay);
    }
  }

  // Cierre de menús
  closeMenu(): void {
    this.menuClosing = setTimeout(() => {
      if (!this.menuActive) {
        this.activeSubmenuSubmenu = null;
        this.activeSubmenu = null;
      }
    }, this.menuCollapseDelay);
  }

  // Cierre de submenús
  closeSubmenu(): void {
    this.submenuClosing = setTimeout(() => {
      if (!this.submenuActive) {
        this.activeSubmenuSubmenu = null;
      }
    }, this.menuCollapseDelay);
  }

  // Limpieza de timeout de cierre de menú
  clearMenuTimeout(menu?: MenuItem): void {
    if (this.menuClosing) {
      clearTimeout(this.menuClosing);
    }
    if (
      this.submenuClosing &&
      !this.activeSubmenu?.some((submenu) => submenu.submenu)
    ) {
      clearTimeout(this.submenuClosing);
    }
    if (this.sidebarCollapseTimeout) {
      clearTimeout(this.sidebarCollapseTimeout);
    }
  }

  // Bloqueo de barra lateral
  lockSidebar(): void {
    this.sidebarLocked = !this.sidebarLocked;
    this.sidebarUpdate.emit(this.sidebarLocked);
  }
}
