// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ViewportScroller } from "@angular/common";
import { Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../../services/shared/DeviceRouteSelectorService.service";
import { MeterService } from "../../meter/MeterService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Componentes
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  LWMbusInactiveOrUnkown,
  LWMbusUnknownData,
} from "../../ConcentratorInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-mbus-unknown-list",
  templateUrl: "./mbus-unknown-list.component.html",
  styleUrls: ["./mbus-unknown-list.component.scss"],
})
export class MbusUnknownListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;

  // Tabla
  tableMaxReg: number = 100;
  tableData: LWMbusInactiveOrUnkown[];
  exportFileName: string =
    this.translate.instant("unknown-devices") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "view-on-map",
          tooltip: "view-on-map",
          icon: "fas fa-map",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "new-device",
          tooltip: "new-device",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "mbus-address",
      data: "dirMbus",
      search: "dirMbus",
      sort: "dirMbus",
      visible: true,
    },
    {
      title: "serial-number-calculated",
      data: "nroSerieToShow",
      search: "nroSerieToShow",
      sort: "nroSerieToShow",
      color: "highlightCalculated",
      visible: true,
    },
    {
      title: "decryptable",
      data: "decryptable",
      search: "decryptable",
      sort: "decryptable",
      alter: {
        condition: "decryptable",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "concentrators",
      data: null,
      search: null,
      sort: null,
      html: true,
      extraTable: true,
      extraTableLength: true,
      visible: true,
      noExport: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];
  extraTableColumns: TableDataColumn[] = [
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
      link: "concentratorLink",
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssi",
      search: "rssi",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
  ];

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Mapa
  mapDevice: LWMbusInactiveOrUnkown;
  mapType: string = "unknownDevices";
  mapHeight: number = 600;
  mapData: LWMbusUnknownData[];
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  scrollPosition: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Carga del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.updating = true;
    forkJoin([
      this.MeterController.getEntityLwMbusInactiveDevices(
        this.currentEntity.id
      ),
      this.MeterController.getEntityLwMbusUnkownDevices(this.currentEntity.id),
    ]).subscribe((responses) => {
      let unknownList: LWMbusInactiveOrUnkown[] = [];
      let mapData: LWMbusUnknownData[] = [];
      let lwMbusList: LWMbusInactiveOrUnkown[] = [];
      if (responses[0]["body"]) {
        lwMbusList = responses[0]["body"];
      }
      if (responses[1]["body"]) {
        lwMbusList = lwMbusList.concat(
          responses[1]["body"]?.map((lwMbus: LWMbusUnknownData) => {
            lwMbus.undecryptable = true;
            return lwMbus;
          })
        );
      }

      lwMbusList?.forEach((lwMbus: LWMbusUnknownData, j) => {
        // Datos de mapa
        if (
          !mapData.some(
            (mapLwMbus: LWMbusUnknownData) => mapLwMbus.id == lwMbus.id
          )
        ) {
          lwMbus.concentrator = true;
          mapData.push(lwMbus);
        }
        // Datos de tabla
        lwMbus.lista?.forEach((unkownDevice: LWMbusInactiveOrUnkown, i) => {
          let addedDevice = unknownList.find(
            (device) => device.dirMbus == unkownDevice.dirMbus
          );
          let lwMbusData = {
            id: lwMbus.id,
            latitude: lwMbus.latitude,
            longitude: lwMbus.longitude,
            nroSerie: lwMbus.nroSerie,
            concentratorLink: this.DeviceRouteSelectorService.getDeviceRouteUrl(
              METROLOGY_TYPE.MBUS_CONCENTRATOR,
              lwMbus.id
            ),
            rssi: unkownDevice.rssi,
            timestamp: unkownDevice.timestamp,
            timestampParsed: this.DateParserService.parseDate(
              unkownDevice.timestamp,
              "L HH:mm:ss"
            ),
          };
          if (!addedDevice) {
            // Cálculo de número de serie para fabricantes SAP(304C) y RAN(2E48)
            if (!unkownDevice.nro_serie) {
              unkownDevice.nroSeriecalculated =
                this.MeterService.parseUnknownMbusSerialNumber(
                  unkownDevice.dirMbus
                );
            }
            unkownDevice.nroSerieToShow = unkownDevice.nro_serie
              ? unkownDevice.nro_serie
              : unkownDevice.nroSeriecalculated
              ? unkownDevice.nroSeriecalculated + " (*)"
              : null;
            unkownDevice.highlightCalculated = unkownDevice.nroSeriecalculated
              ? "gray"
              : null;
            unkownDevice.decryptable = !lwMbus.undecryptable;
            unkownDevice.extraTableData = {
              columns: this.extraTableColumns,
              data: [lwMbusData],
            };
            unknownList.push(unkownDevice);
          } else {
            addedDevice.extraTableData.data.push(lwMbusData);
          }
        });
      });
      // Reordenamiento
      unknownList.forEach((unkownDevice: LWMbusInactiveOrUnkown) => {
        unkownDevice.extraTableData.data?.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        unkownDevice.timestamp = unkownDevice.extraTableData.data[0].timestamp;
        unkownDevice.rssi = unkownDevice.extraTableData.data[0].rssi;
      });
      this.tableData = unknownList;
      this.mapData = [...mapData];
      this.updating = false;
    });
  }

  // Acciones de la tabla
  tableActions(action: string, unknown: LWMbusInactiveOrUnkown): void {
    switch (action) {
      case "view-on-map":
        this.mapDevice = unknown;
        this.scrollPosition = this.viewportScroller.getScrollPosition();
        this.showOnMap(unknown);
        break;
      case "new-device":
        let bestConcentrator =
          unknown.extraTableData.data?.length > 1
            ? unknown.extraTableData.data.sort((a, b) => b.rssi - a.rssi)[0]
            : unknown.extraTableData.data[0];
        this.router.navigate(["/dispositivos/nuevo"], {
          state: {
            data: {
              type: DEVICE_BY_COMM.MBUS,
              dirMbus: unknown.dirMbus,
              nroSerie: unknown.nro_serie
                ? unknown.nro_serie
                : unknown.nroSeriecalculated,
              concentrator: bestConcentrator,
            },
          },
        });
        break;
      default:
        break;
    }
  }

  // Visualización de contadores en mapa
  showOnMap(unknown: LWMbusInactiveOrUnkown): void {
    let linkedConcentrators = unknown.extraTableData.data;
    this.mapData.map((data) => {
      data.selected = linkedConcentrators.some(
        (concentrator) => concentrator.id == data.id
      );
      if (data.selected) {
        data.currentDevice = unknown.dirMbus;
      }
    });
    this.mapController.updateSelected();
    this.updateMakersTooltips(linkedConcentrators);
    this.mapController.deletePolygon();
    this.mapController.drawPolygon(
      this.translate.instant("reception-area"),
      this.mapData
        .filter((data) => data.selected)
        .map((data) => {
          return [data.latitude, data.longitude];
        })
    );
    this.viewportScroller.scrollToAnchor("unknown-devices-map");
  }

  // Actualización de popups
  updateMakersTooltips(linkedConcentrators: LWMbusUnknownData[]): void {
    let linkedConcentratorsTooltips = {};
    linkedConcentrators.map((lwMbus: LWMbusUnknownData) => {
      linkedConcentratorsTooltips[lwMbus.id] = {
        id: lwMbus.id,
        nroSerie: lwMbus.nroSerie,
        rssi: lwMbus.rssi,
        date: lwMbus.timestampParsed,
      };
    });
    this.mapController.updateUnknownLwMbusTooltip(linkedConcentratorsTooltips);
  }
}
