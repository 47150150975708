// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Turf
import * as Turf from "@turf/turf";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { PressureSensor } from "../../SensoryInterface";
// Componentes
import { SensoryPressureDialogComponent } from "../sensory-pressure-dialog/sensory-pressure-dialog.component";

@Component({
  selector: "app-sensory-pressure-map",
  templateUrl: "./sensory-pressure-map.component.html",
  styleUrls: ["./sensory-pressure-map.component.scss"],
})
export class SensoryPressureMapComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Mapa
  mapType: string = "pressureDetection";
  mapData: any[];
  mapHeight: number = window.innerHeight - 270;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private DateParserService: DateParserService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de datos
  getData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.mapData = [];
          let mapData = this.MapDeviceMinimalParse.parseDevices(
            response["body"]?.contadores
          );
          mapData.forEach((meter: any) => {
            if (
              !this.mapData.some(
                (mapMeter) =>
                  mapMeter.longitude == meter.longitude &&
                  mapMeter.latitude == meter.latitude
              )
            ) {
              meter.range = Math.floor(Math.random() * 90 + 10);
              if (this.checkMeter(meter)) {
                meter.graph = this.generateMeterGraph();
                meter.warning = meter.graph[meter.graph.length - 1][1] < 2;
                this.mapData.push(meter);
              }
            }
          });
        }
      }
    );
  }

  checkMeter(meter: any): boolean {
    return !this.mapData.some(
      (mapMeter) =>
        Turf.distance(
          Turf.point([mapMeter.longitude, mapMeter.latitude]),
          Turf.point([meter.longitude, meter.latitude]),
          {
            units: "kilometers",
          }
        ) <=
        mapMeter.range / 1000
    );
  }

  generateMeterGraph(): any[] {
    let graph = [];
    let date = this.DateParserService.getLastDays(7);
    let basePressure = Math.random() * 1 + 1.5;
    for (let i = 0; i < 7 * 24; i++) {
      graph.push([
        date.startDate.valueOf() + i * 3600000,
        Math.random() * 0.2 + (basePressure - 0.2),
      ]);
    }
    return graph;
  }

  // Acciones del mapa
  mapActions(action: string, sensor: PressureSensor): void {
    switch (action) {
      case "showGraph":
        this.showModal(sensor);
        break;
      default:
        break;
    }
  }

  // Mostrar modal
  showModal(sensor: PressureSensor): void {
    this.MaterialDialogService.openDialog(SensoryPressureDialogComponent, sensor);
  }
}
