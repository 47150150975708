import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MaterialModule } from "../material-module/material.module";
import { MatCheckboxModule } from "@angular/material/checkbox";

// Directives
import { DirectivesModule } from "../../directives/directives.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

// Graph
import { GraphControllerComponent } from "./graph-controller/graph-controller.component";
import { GraphComponent } from "./graph/graph.component";
import { GraphMiniComponent } from "./graph-mini/graph-mini.component";
import { GraphHeatmapComponent } from "./graph-heatmap/graph-heatmap.component";
import { GraphControllerDialogComponent } from "./graph-controller/graph-controller-dialog/graph-controller-dialog.component";
import { GraphBulletComponent } from "./graph-bullet/graph-bullet.component";

// Highcharts
import { HighchartsChartModule } from "highcharts-angular";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatRadioModule,
    MaterialModule,
    MatCheckboxModule,

    // Directives
    DirectivesModule,

    // Highcharts
    HighchartsChartModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    GraphControllerComponent,
    GraphComponent,
    GraphMiniComponent,
    GraphHeatmapComponent,
    GraphControllerDialogComponent,
    GraphBulletComponent,
  ],
  exports: [
    GraphControllerComponent,
    GraphMiniComponent,
    GraphHeatmapComponent,
    GraphBulletComponent,
  ],
})
export class GraphModule {}
