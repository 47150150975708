// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import { AlarmConfiguredDevice } from "../../AlarmInterface.type";
// Variable jQuery
declare var $: any;

@Component({
  selector: "app-alarms-dialog",
  templateUrl: "./alarms-dialog.component.html",
  styleUrls: ["./alarms-dialog.component.scss"],
})
export class AlarmsDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  configuredDevicesFiltered: AlarmConfiguredDevice[];
  // @Input() configuredCups: any;
  // configuredCupsFiltered: any[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.configuredDevicesFiltered = this.data.configuredDevices.filter(
      (configuredDevice: AlarmConfiguredDevice) =>
        configuredDevice.fabricante == this.data.device.fabricante &&
        configuredDevice.devType == this.data.device.devType
    );
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Redirección a dispositivo
  goTo(data: any): void {
    this.SessionDataService.sendDialogAction({ action: "close" });
    this.router.navigate(
      ["/dispositivos/detalle/alarmas/configuracion/" + data.id],
      { state: { data: data.nroSerie } }
    );
  }
}
