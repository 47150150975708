<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Selector de tipo de dispositivo -->
        <div class="device-config-type-select">
          <app-material-select
            class="material-header-select"
            [title]="'new-device' | translate"
            [bindValue]="'id'"
            [bindLabel]="'title'"
            [selected]="devicePreselect"
            [data]="deviceTypes"
            (selectedOption)="resetData($event.id)"
          ></app-material-select>
        </div>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveIcon"
          [title]="saveTitle"
          (action)="saveDevice()"
        >
        </app-material-panel-menu>
      </div>
      <div class="panel-body device-config-panel">
        <!-- Parámetros requeridos -->
        <div class="device-config-required">
          <h4>{{ "attribute-required" | translate }}</h4>
          <ng-container *ngFor="let attribute of deviceAttributes">
            <div
              *ngIf="
                attribute.name != ATTRIBUTE_NAME.ACTIVATE &&
                attribute.profile.includes(sessionProfile) &&
                attribute.required &&
                selectedType.attributes.includes(attribute.name) &&
                ((attribute.name != ATTRIBUTE_NAME.CYBLE5_ENC_KEY &&
                  attribute.name != ATTRIBUTE_NAME.CYBLE5_AUTH_KEY) ||
                  (deviceAttributes[ATTRIBUTE_INDEX.MANUFACTURER].data ==
                    MANUFACTURER_INDEX.ITRON &&
                    (deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                      MANUFACTURER_INDEX.ITRON_CYBLE5IoT ||
                      deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                        MANUFACTURER_INDEX.ITRON_RF_CYBLE5IoT ||
                      deviceAttributes[ATTRIBUTE_INDEX.DEV_TYPE].data ==
                        MANUFACTURER_INDEX.ITRON_INTELIS_WSOURCE)))
              "
            >
              <!-- Label -->
              <div>
                <label
                  for="{{ attribute.name }}"
                  title="{{ attribute.info | translate }}"
                  >{{ attribute.label | translate }}</label
                >
              </div>
              <!-- Atributo de texto -->
              <input
                *ngIf="
                  attribute.type == 'text' &&
                  (attribute.name != ATTRIBUTE_NAME.RF_MODULE ||
                    (attribute.name == ATTRIBUTE_NAME.RF_MODULE &&
                      selectedType.id != DEVICE_BY_COMM.PLUM))
                "
                type="text"
                title="{{ attribute.info | translate }}"
                [ngClass]="{
                  'device-attribute-required': attribute.highlight,
                  'text-center': attribute.data != null && attribute.data != ''
                }"
                name="{{ attribute.name }}"
                autocomplete="off"
                [(ngModel)]="attribute.data"
                maxlength="{{ attribute.maxlength }}"
                (keyup)="attribute.highlight = false"
                placeholder="{{ attribute.label | translate }}..."
              />
              <!-- Atributo de texto hexadecimal -->
              <input
                *ngIf="attribute.type == 'hex'"
                type="text"
                title="{{ attribute.info | translate }}"
                appHexInput
                [ngClass]="{
                  'device-attribute-required': attribute.highlight,
                  'text-center': attribute.data != null && attribute.data != ''
                }"
                name="{{ attribute.name }}"
                autocomplete="off"
                [(ngModel)]="attribute.data"
                maxlength="{{ attribute.maxlength }}"
                (keyup)="attribute.highlight = false"
                placeholder="{{ attribute.label | translate }}..."
              />
              <!-- Atributo de desplegable -->
              <select
                *ngIf="
                  attribute.type == 'select' ||
                  attribute.name == ATTRIBUTE_NAME.SIM_ICCID ||
                  (attribute.name == ATTRIBUTE_NAME.RF_MODULE &&
                    selectedType.id == DEVICE_BY_COMM.PLUM)
                "
                class="device-config-manufacturer"
                title="{{ attribute.info | translate }}"
                [ngClass]="{ 'device-attribute-required': attribute.highlight }"
                [(ngModel)]="attribute.data"
                (change)="
                  attribute.name == ATTRIBUTE_NAME.MANUFACTURER
                    ? updateDeviceList($event.target.value)
                    : attribute.name == ATTRIBUTE_NAME.DEV_TYPE
                    ? selectedTypeUpdate()
                    : attribute.name == ATTRIBUTE_NAME.API
                    ? apiUpdate()
                    : null;
                  attribute.highlight && attribute.data != null
                    ? (attribute.highlight = false)
                    : null
                "
                [disabled]="
                  !attribute.items ||
                  attribute.items?.length == 0 ||
                  (((attribute.name == ATTRIBUTE_NAME.MANUFACTURER &&
                    manufacturerDisabled) ||
                    (attribute.name == ATTRIBUTE_NAME.DEV_TYPE &&
                      modelDisabled)) &&
                    attribute.name != ATTRIBUTE_NAME.RF_MODULE &&
                    attribute.name != ATTRIBUTE_NAME.SIM_ICCID)
                "
              >
                <option value="">{{ attribute.label | translate }}...</option>
                <ng-container *ngFor="let item of attribute.items">
                  <option
                    *ngIf="
                      attribute.name != ATTRIBUTE_NAME.LORAWAN_VERSION &&
                      attribute.name != ATTRIBUTE_NAME.TIMEZONE &&
                      (attribute.name != ATTRIBUTE_NAME.DEV_TYPE ||
                        (attribute.name == ATTRIBUTE_NAME.DEV_TYPE &&
                          item.type?.split(', ')?.includes(selectedType.id))) &&
                      (attribute.name != ATTRIBUTE_NAME.MANUFACTURER ||
                        (attribute.name == ATTRIBUTE_NAME.MANUFACTURER &&
                          item.type?.split(', ')?.includes(selectedType.id)))
                    "
                    value="{{ item.id }}"
                  >
                    {{ item.text }}
                  </option>
                  <option
                    *ngIf="
                      attribute.name == ATTRIBUTE_NAME.LORAWAN_VERSION ||
                      attribute.name == ATTRIBUTE_NAME.TIMEZONE
                    "
                    value="{{ item }}"
                  >
                    {{ item }}
                  </option>
                </ng-container>
              </select>
              <mat-hint
                *ngIf="attribute.name == ATTRIBUTE_NAME.API && selectedApi"
                class="device-config-api"
                ><span>{{ selectedApi?.authenticateUrl }}</span>
                <span>{{ selectedApi?.historicUrl }}</span></mat-hint
              >
              <!-- Atributo entidad -->
              <select
                *ngIf="attribute.name == ATTRIBUTE_NAME.ENTITY"
                title="{{ attribute.info | translate }}"
                [(ngModel)]="attribute.data"
                (change)="getAgrupationList(attribute.data)"
              >
                <option
                  *ngFor="let entity of currentEntityList"
                  value="{{ entity.id }}"
                >
                  {{ entity.entity }}
                </option>
              </select>
              <!-- Atributo agrupación -->
              <select
                *ngIf="attribute.name == ATTRIBUTE_NAME.AGRUPATION"
                title="{{ attribute.info | translate }}"
                [(ngModel)]="attribute.data"
              >
                <option
                  *ngFor="let agrupation of agrupationList"
                  value="{{ agrupation.name }}"
                >
                  {{ agrupation.name }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
        <!-- Parámetros no requeridos -->
        <div class="device-config-no-required">
          <h4>{{ "attribute-no-required" | translate }}</h4>
          <ng-container *ngFor="let attribute of deviceAttributes">
            <div
              *ngIf="
                attribute.profile.includes(sessionProfile) &&
                attribute.name != ATTRIBUTE_NAME.NRO_CLAVES &&
                attribute.name != ATTRIBUTE_NAME.NETWORK_KEY &&
                !attribute.required &&
                selectedType.attributes.includes(attribute.name)
              "
            >
              <!-- Label -->
              <div>
                <label
                  for="{{ attribute.name }}"
                  title="{{ attribute.info | translate }}"
                  >{{ attribute.label | translate }}</label
                >
              </div>
              <!-- Atributo de texto -->
              <input
                *ngIf="attribute.type == 'text'"
                type="text"
                title="{{ attribute.info | translate }}"
                [ngClass]="{
                  'text-center': attribute.data != null && attribute.data != ''
                }"
                name="{{ attribute.name }}"
                autocomplete="off"
                [(ngModel)]="attribute.data"
                maxlength="{{ attribute.maxlength }}"
                placeholder="{{ attribute.label | translate }}..."
              />
              <!-- Atributo de checkbox -->
              <input
                *ngIf="attribute.type == 'boolean'"
                type="checkbox"
                title="{{ attribute.info | translate }}"
                class="device-config-check"
                name="{{ attribute.name }}"
                [(ngModel)]="attribute.data"
                (change)="
                  attribute.name == ATTRIBUTE_NAME.ACTIVATE
                    ? (deviceActivated =
                        attribute.data != null && attribute.data != '')
                    : null
                "
              />
              <!-- Atributo de texto largo -->
              <textarea
                *ngIf="attribute.type == 'textarea'"
                class="device-config-comments"
                title="{{ attribute.info | translate }}"
                name="{{ attribute.name }}"
                rows="4"
                [(ngModel)]="attribute.data"
                placeholder="{{ attribute.label | translate }}..."
              >
              </textarea>
              <!-- Atributo de desplegable -->
              <select
                class="device-config-dropdown"
                *ngIf="attribute.type == 'select'"
                title="{{ attribute.info | translate }}"
                [(ngModel)]="attribute.data"
              >
                <option value="">{{ attribute.label | translate }}...</option>
                <option
                  *ngFor="let element of attribute.items"
                  value="{{ element }}"
                >
                  {{ element }}
                </option>
              </select>
              <!-- Atributo de desplegable -->
              <select
                *ngIf="
                  attribute.name == ATTRIBUTE_NAME.SIM_ICCID ||
                  attribute.name == ATTRIBUTE_NAME.VALVE_STATE
                "
                class="device-config-manufacturer"
                title="{{ attribute.info | translate }}"
                [ngClass]="{ 'device-attribute-required': attribute.highlight }"
                [(ngModel)]="attribute.data"
                [disabled]="!attribute.items || attribute.items?.length == 0"
              >
                <option value="">{{ attribute.label | translate }}...</option>
                <option
                  *ngFor="let item of attribute.items"
                  value="{{ item.id }}"
                >
                  {{
                    attribute.name == ATTRIBUTE_NAME.VALVE_STATE
                      ? (item.text | translate)
                      : item.text
                  }}
                </option>
              </select>
              <!-- Atributo entidad -->
              <select
                *ngIf="attribute.name == ATTRIBUTE_NAME.ENTITY"
                title="{{ attribute.info | translate }}"
                [(ngModel)]="attribute.data"
                [disabled]="!deviceActivated"
                (change)="getAgrupationList(attribute.data)"
              >
                <option
                  *ngFor="let entity of currentEntityList"
                  value="{{ entity.id }}"
                >
                  {{ entity.entity }}
                </option>
              </select>
              <!-- Atributo de agrupación -->
              <select
                *ngIf="attribute.name == ATTRIBUTE_NAME.AGRUPATION"
                [ngClass]="{ 'device-attribute-required': attribute.highlight }"
                title="{{ attribute.info | translate }}"
                [(ngModel)]="attribute.data"
                [disabled]="!deviceActivated"
                (change)="
                  attribute.highlight &&
                  attribute.data != null &&
                  attribute.data != ''
                    ? (attribute.highlight = false)
                    : null
                "
              >
                <option value="">{{ attribute.label | translate }}...</option>
                <option
                  *ngFor="let agrupation of agrupationList"
                  value="{{ agrupation.name }}"
                >
                  {{ agrupation.name }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
        <!-- Ventanas de EK280 -->
        <div
          *ngIf="
            sessionProfile == PROFILES.ARSON &&
            (selectedType.id == DEVICE_BY_COMM.EK280 ||
              selectedType.id == DEVICE_BY_COMM.OWASYS)
          "
          class="device-config-ek-windows"
        >
          <!-- Ventana 1 -->
          <div class="ek-config-window">
            <h4>{{ "window" | translate }} 1</h4>
            <!-- Hora -->
            <div>
              <label for="firstWindow">{{ "start-time" | translate }}:</label>
              <input
                [(ngModel)]="ekWindows.firstWindow.time"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.firstWindow.highlightTime
                }"
                type="time"
                id="firstWindow"
                name="firstWindow"
                placeholder="HH:mm:ss"
              />
            </div>
            <!-- Duración -->
            <div class="ek-config-duration">
              <label for="firstWindowDuration"
                >{{ "duration" | translate }}:</label
              >
              <input
                [(ngModel)]="ekWindows.firstWindow.duration"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.firstWindow.highlightDuration
                }"
                type="number"
                id="firstWindowDuration"
                name="firstWindowDuration"
                min="0"
                placeholder=""
              />
              <span>{{ "minutes" | translate }}</span>
            </div>
          </div>
          <!-- Ventana 2 -->
          <div class="ek-config-window">
            <h4>{{ "window" | translate }} 2</h4>
            <!-- Hora -->
            <div>
              <label for="secondWindow">{{ "start-time" | translate }}:</label>
              <input
                [(ngModel)]="ekWindows.secondWindow.time"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.secondWindow.highlightTime
                }"
                type="time"
                id="secondWindow"
                name="secondWindow"
                placeholder="HH:mm:ss"
              />
            </div>
            <!-- Duración -->
            <div class="ek-config-duration">
              <label for="secondWindowDuration"
                >{{ "duration" | translate }}:</label
              >
              <input
                [(ngModel)]="ekWindows.secondWindow.duration"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.secondWindow.highlightDuration
                }"
                type="number"
                id="secondWindowDuration"
                name="secondWindowDuration"
                min="0"
                placeholder=""
              />
              <span>{{ "minutes" | translate }}</span>
            </div>
          </div>
          <!-- Ventana 3 -->
          <div class="ek-config-window">
            <h4>{{ "window" | translate }} 3</h4>
            <!-- Hora -->
            <div>
              <label for="thirdWindow">{{ "start-time" | translate }}:</label>
              <input
                [(ngModel)]="ekWindows.thirdWindow.time"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.thirdWindow.highlightTime
                }"
                type="time"
                id="thirdWindow"
                name="thirdWindow"
                placeholder="HH:mm:ss"
              />
            </div>
            <!-- Duración -->
            <div class="ek-config-duration">
              <label for="thirdWindowDuration"
                >{{ "duration" | translate }}:</label
              >
              <input
                [(ngModel)]="ekWindows.thirdWindow.duration"
                [ngClass]="{
                  'device-attribute-required':
                    ekWindows.thirdWindow.highlightDuration
                }"
                type="number"
                id="thirdWindowDuration"
                name="thirdWindowDuration"
                min="0"
                placeholder=""
              />
              <span>{{ "minutes" | translate }}</span>
            </div>
          </div>
        </div>
        <!-- Mapa de localización -->
        <div *ngIf="deviceActivated" class="device-config-map">
          <h4>{{ "location" | translate }}</h4>
          <div class="device-config-map-data">
            <!-- Atributos de coordenadas -->
            <ng-container *ngFor="let coord of deviceLocation">
              <div>
                <div>
                  <label for="{{ coord.name }}" title="{{ coord.info }}">{{
                    coord.label
                  }}</label>
                </div>
                <input
                  type="text"
                  name="{{ coord.name }}"
                  autocomplete="off"
                  placeholder="{{ coord.label }}..."
                  [(ngModel)]="coord.data"
                  (change)="
                    deviceInitialLocation = {
                      latitude: deviceLocation[0].data,
                      longitude: deviceLocation[1].data
                    }
                  "
                />
              </div>
            </ng-container>
          </div>

          <div class="device-config-map-container">
            <!-- Mapa de contador -->
            <app-map-controller
              [mapType]="mapType"
              [mapHeight]="mapHeight"
              [initialZoom]="initialZoom"
              [disableLegend]="true"
              [disableMeasure]="true"
              [hideMenu]="true"
              (meterNewLocation)="setDeviceNewLocation($event)"
              [disableCluster]="true"
              [searchAdress]="true"
              [gatewaysData]="[]"
              [metersData]="[deviceInitialLocation]"
            >
            </app-map-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
