import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
// Interfaces
import { GatewayTask } from "../../../GatewayInterface.type";

@Component({
  selector: "app-gateway-log-dialog",
  templateUrl: "./gateway-log-dialog.component.html",
  styleUrls: ["./gateway-log-dialog.component.scss"],
})
export class GatewayLogDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: GatewayTask;
  taskImage: SafeUrl;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private domSanitizer: DomSanitizer,
    private GatewayController: GatewayControllerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getTaskImages();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de las imágenes de la tarea
  getTaskImages(): void {
    this.GatewayController.getGatewayTaskImage(this.data.id).subscribe((response) => {
      if (response["code"] == 0) {
        this.taskImage = this.domSanitizer.bypassSecurityTrustUrl(
          "data:image/png;base64, " + response["body"]
        );
      }
    });
  }
}
