import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
import {
  ConsumptionDataByTimestamp,
  GraphMeterData,
} from "../DataAnalysisInterface.type";
import { MapDevice } from "../../../../interfaces/DeviceGlobalInterface.type";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class DataAnalysisHeatmapService {
  // Mapa de calor
  heatMapData: ConsumptionDataByTimestamp[][];
  heatMapDataByTimestamp: number[][][];
  originalHeatMapByTimestamp: number[][][];
  consumptionRange: number;
  consumptionRangeLabel: string;
  consumptionMaxValue: number;
  consumptionMaxValueLabel: string;
  timestampArray: number[];
  numberFormat: string;

  constructor(private SessionDataService: SessionDataService) {
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
  }

  // Obtención de los datos del mapa
  getHeatMapData(
    selectedDevices: number[],
    deviceData: MapDevice[],
    readingsData: GraphMeterData[]
  ): void {
    this.heatMapData = [];
    this.timestampArray = [];

    selectedDevices.forEach((deviceId: number) => {
      let mapDevice: MapDevice = deviceData.find(
        (device: MapDevice) => device.id == deviceId
      );
      let mapReadings: GraphMeterData = readingsData.find(
        (readings: GraphMeterData) => readings.meterId == deviceId
      );
      let consumptionArray: ConsumptionDataByTimestamp[] = [];

      mapReadings?.readings.forEach((reading: number[]) => {
        consumptionArray.push({
          timestamp: reading[0],
          latitude: parseFloat(mapDevice.latitude),
          longitude: parseFloat(mapDevice.longitude),
          consumption: reading[1],
        });
        if (!this.timestampArray.includes(reading[0])) {
          this.timestampArray.push(reading[0]);
        }
      });

      if (consumptionArray.length > 0) {
        this.heatMapData.push(consumptionArray);
      }
    });

    this.timestampArray.sort();
    this.fillEmptyTimestamps();
    this.processHeatMapData();
  }

  // Procesado de los datos del mapa de calor
  processHeatMapData(): void {
    this.heatMapDataByTimestamp = [];
    this.consumptionMaxValue = 0;
    let heatMapDataByTimestamp: number[][][] = [];
    let timestampIndex: number = 0;

    // Consumo por hora
    this.timestampArray.forEach((timestamp: number) => {
      let timestampData: number[][] = [];
      let interpolatedTimestampsData: number[][][] = [];

      // Consumo por dispositivo
      this.heatMapData.forEach((deviceData: ConsumptionDataByTimestamp[]) => {
        let data = deviceData.find(
          (data: ConsumptionDataByTimestamp) => data.timestamp == timestamp
        );
        let nextData = deviceData.find(
          (data: ConsumptionDataByTimestamp) =>
            data.timestamp == timestamp + 3600000
        );

        timestampData.push([data.latitude, data.longitude, data.consumption]);

        // Si el valor de consumo es el más alto se actualiza el rango máximo
        if (data.consumption > this.consumptionMaxValue) {
          this.consumptionMaxValue = data.consumption;
        }

        for (let i = 1; i < 60; i++) {
          if (!interpolatedTimestampsData[i - 1]) {
            interpolatedTimestampsData[i - 1] = [];
          }
          interpolatedTimestampsData[i - 1].push([
            data.latitude,
            data.longitude,
            nextData
              ? data.consumption +
                (i / 60) * (nextData.consumption - data.consumption)
              : data.consumption,
          ]);
        }
      });
      heatMapDataByTimestamp.push(timestampData);
      if (
        timestampIndex < this.timestampArray.length - 1 &&
        interpolatedTimestampsData
      ) {
        interpolatedTimestampsData.forEach((interpolatedData: number[][]) =>
          heatMapDataByTimestamp.push(interpolatedData)
        );
      }
      timestampIndex++;
    });
    this.consumptionRange = this.consumptionMaxValue;
    this.consumptionMaxValueLabel = formatNumber(
      this.consumptionMaxValue,
      this.numberFormat
    );
    this.consumptionRangeLabel = formatNumber(
      this.consumptionRange,
      this.numberFormat
    );
    this.getInterpolatedTimestamps();
    this.heatMapDataByTimestamp = heatMapDataByTimestamp;
    this.originalHeatMapByTimestamp = heatMapDataByTimestamp;
    this.SessionDataService.sendComponentData({
      action: "heatmapData",
      heatMapData: this.heatMapData,
      heatMapDataByTimestamp: this.heatMapDataByTimestamp,
      originalHeatMapByTimestamp: this.originalHeatMapByTimestamp,
      consumptionRange: this.consumptionRange,
      consumptionRangeLabel: this.consumptionRangeLabel,
      consumptionMaxValue: this.consumptionMaxValue,
      consumptionMaxValueLabel: this.consumptionMaxValueLabel,
      timestampArray: this.timestampArray,
    });
  }

  // Obtención de los valores interpolados de timestamp
  getInterpolatedTimestamps(): void {
    let interpolatedTimestampArray: number[] = [];
    for (let i = 0; i < this.timestampArray.length - 1; i++) {
      interpolatedTimestampArray.push(this.timestampArray[i]);
      for (let j = 1; j < 60; j++) {
        interpolatedTimestampArray.push(this.timestampArray[i] + 60000 * j);
      }
    }
    interpolatedTimestampArray.push(
      this.timestampArray[this.timestampArray.length - 1]
    );
    this.timestampArray = interpolatedTimestampArray;
  }

  // Rellenado de los timestamp sin datos
  fillEmptyTimestamps(): void {
    this.heatMapData.forEach(
      (consumptionsData: ConsumptionDataByTimestamp[]) => {
        let deviceLongitude = consumptionsData[0].latitude;
        let deviceLatitude = consumptionsData[0].longitude;

        this.timestampArray.forEach((timestamp: number) => {
          if (
            !consumptionsData.some(
              (consumption: ConsumptionDataByTimestamp) =>
                consumption.timestamp == timestamp
            )
          ) {
            consumptionsData.push({
              timestamp: timestamp,
              latitude: deviceLongitude,
              longitude: deviceLatitude,
              consumption: 0,
            });
          }
        });
        consumptionsData.sort((a, b) => a.timestamp - b.timestamp);
      }
    );
  }
}
