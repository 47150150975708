import { ExtraTableData } from "../../../modules/table-module/TableInterface.type";
import { GatewayCleanResponse } from "../devices/DeviceInterface.type";
import { Card } from "../../../modules/card-module/CardInterface.type";

// ANCHOR Mantenimiento
export class Maintenance {
  public id?: number;
  public gateway: number;
  public gwUnidadVenta: string;
  public historicalGroup: number;
  public historicalText: string;
  public userId: number;
  public username: string;
  public timestamp: number;
  public historicalState: number;
  public historicalParent: number;

  // FrontEnd
  public timestampParsed?: string;
  public historicalStateParsed?: string;
  public extraTableData?: ExtraTableData;
  public childsFlag?: boolean;

  constructor() {
    this.id = null;
    this.gateway = null;
    this.gwUnidadVenta = null;
    this.historicalGroup = null;
    this.historicalText = null;
    this.userId = null;
    this.username = null;
    this.timestamp = null;
    this.historicalState = null;
    this.historicalParent = null;
  }
}

// ANCHOR Grupos de mantenimiento
export const MAINTENANCE_GROUPS = [
  {
    id: 1,
    value: "gateway-management-group-1",
  },
  {
    id: 2,
    value: "gateway-management-group-2",
  },
  {
    id: 3,
    value: "gateway-management-group-3",
  },
  {
    id: 4,
    value: "gateway-management-group-4",
  },
  {
    id: 5,
    value: "gateway-management-group-5",
  },
];

// ANCHOR Lista de dispositivos
export interface Device {
  id: number;
  nroSerie: string;
  agrupation: number;
  metrologyType: number;
}

// ANCHOR Gateway de tabla
export interface ManagementGateway {
  // BackEnd
  id: number;
  unidadVenta: string;
  fwVersion: string;
  hwVersion: string;
  micro: number;
  options: number;
  banda: number;
  lastRssi: number;
  lastVbat: number;
  lastFileReceived: number;
  lastFileState: number;
  nroMainContadores: number;
  nroRedundantContadores: number;
  lastCommunication: number;
  lastHello: number;
  entity: string;
  lastChargeReaded: number;
  longitude: string;
  latitude: string;
  comunica: boolean;
  imagePath: string;
  haveImage: boolean;
  installation: number;
  amei: string;
  plmn: string;
  operadora: string;
  inMaintenance: boolean;
  state: number;
  canales: number;

  // FrontEnd
  lastFileStateText?: string;
  lastCommunicationParsed?: string;
  lastFileReceivedParsed?: string;
  lastHelloParsed?: string;
  installationParsed?: string;
  lastVbatParsed?: number | string;
  lastChargeReadedParsed?: number | String;
  highlightNoCommunicate?: boolean;
  highlightNoActive?: boolean;
  activeAndInstalled?: boolean;
  activeAndInstalledAndCommunicate?: boolean;
  disableShowMap?: boolean;
  totalMeters?: number;
  microParsed?: string;
  bandaParsed?: string;
  norMemory?: boolean;
  mramQspi?: boolean;
  mramSpi?: boolean;
  gps?: boolean;
  lte?: boolean;
  localPort?: boolean;
  debug?: boolean;
  gatewayLink?: string;
  stateParsed?: string;
  canalesParsed?: number;
}

// ANCHOR Concentrador
export interface ManagementConcentrator {
  consumo_transmision: number;
  consumo_mbus: number;
  consumo_lorawan: number;
  id: number;
  nro_serie: string;
  entity: string;
  activate: number;
  version_fw: number;
  installation: number;
  lastJoinTimestamp: number;
  last_communication: number;
  min_sf: string;
  lastRssi: number;
  lastSnr: number;
  nroContadores: number;
  totalConsumption: number;
  comunica: boolean;
  vbat: string;
  // Front End
  lastBatValue?: string;
  concentratorLink?: string;
  activateParsed?: string;
  lastJoinTimestampParsed?: string;
}

// ANCHOR Mantenimiento padre
export interface MaintenanceParent {
  // BackEnd
  parent: Maintenance;
  childs?: Maintenance[];
}

// ANCHOR Modal de mantenimiennto
export interface MaintenanceModalData {
  maintenanceChild: Maintenance;
  maintenanceParent: Maintenance;
}

// ANCHOR Dispositivo a limpiar
export interface DeviceForClear {
  // Back End
  id: number;
  nroSerie: string;
  agrupation: string;
  lastJoinTimestamp: number;
  lastClearExecution: number;
  unidadVentaMainGateway: string;
  nroRedundantGateways: number;
  metrologyType: number;
  lastMainChange: number;
  lastRedundantReduce: number;

  // Front End
  lastJoinTimestampParsed?: string;
  lastClearExecutionParsed?: string;
  extraTableData?: any;
  newGatewayUnidadVenta?: string;
  bestSignal?: BestSignalResponse;
  clearRedundant?: GatewayCleanResponse[];
  deviceLink?: string;
  lastMainChangeParsed?: string;
  lastRedundantReduceParsed?: string;
}

// ANCHOR Dispositivo de gateway
export interface DeviceGateway {
  // Back End
  id: number;
  unidadVenta: string;
  longitude: string;
  latitude: string;
  rssi: number;
  rssiTimestamp: number;
  snr: number;
  mainMeters: number;

  // Front End
  rssiTimestampParsed?: string;
  highlightCurrentGateway?: boolean;
  highlightOld?: boolean;
  highlightNew?: boolean;
  highlightRemoved?: boolean;
}

// ANCHOR Respuesta de servicio de mejor señal
export interface BestSignalResponse {
  meter: string;
  code: number;
  newGatewayId: number;
  newGatewayUnidadVenta: string;
  newGatewayAmei: string;
  codeModifyNewGateway: number;
  oldGatewayId: number;
  oldGatewayUnidadVenta: string;
  oldGatewayAmei: string;
  codeModifyOldGateway: number;
}

// ANCHOR Limpieza de redundante
export interface ClearRedundant {
  meter: string;
  responseList: number[];
}

// ANCHOR Datos de sospecha
export interface WarningData {
  // BackEnd
  id: number;
  entity: number;
  entityDescription: string;
  agrupation: number;
  agrupationDescription: string;
  gateway: number;
  unidadVenta: string;
  gwLatitude: number;
  gwLongitude: number;
  mtLatitude: number;
  mtLongitude: number;
  timestamp: number;
  type: number;
  meter: number;
  nroSerie: string;
  metrology: number;
  operadora: number;
  operadoraDescription: string;

  // FrontEnd
  timestampParsed?: string;
  newWarning?: boolean;
  link?: string;
  typeParsed?: string;
  selected?: boolean;
  selectDisabled?: boolean;
}

// ANCHOR Incidencia
export interface WarningType {
  name: string;
  value: string;
  types: number[];
}

// ANCHOR Incidencias
export const WARNINGS = {
  1: "ALL_GATEWAYS",
  2: "ENTITY_GATEWAYS",
  3: "OPERATOR_GATEWAYS",
  4: "METERS_JOIN_NOT_SUCCESS",
  ALL_GATEWAYS: 1,
  ENTITY_GATEWAYS: 2,
  OPERATOR_GATEWAYS: 3,
  METERS_JOIN_NOT_SUCCESS: 4,
};

// ANCHOR Datos de dispositivo de búsqueda global
export interface GlobalSearchDevice {
  // BackEnd
  id: number;
  nroSerie: string;
  activate?: number;
  eventUser?: string;
  agrupationId: number;
  agrupation: string;
  lastReadedTimestamp?: number;
  lastCommunication?: number;
  lastJoinTimestamp?: number;
  installation?: number;
  fabricante: number;
  devType: number;
  entityId: number;
  entity: string;
  metrologyType: number;
  latitude: number;
  longitude: number;

  // FrontEnd
  lastReadedTimestampParsed?: string;
  lastCommunicationParsed?: string;
  lastJoinTimestampParsed?: string;
  manufacturerText?: string;
  deviceTypeText?: string;
  isActivated?: boolean;
  installationDate?: number;
  installationDateParsed?: string;
  activationDate?: number;
  activationDateParsed?: string;
  highlightNewActivation?: boolean;
  highlightNewDeactivation?: boolean;
}

// ANCHOR Datos de búsqueda
export interface GlobalSearchRequestData {
  nroSerie: string;
  devEui: string;
  rfModule: string;
}

// ANCHOR Job
export interface Job {
  //Back End
  createdOnDeployment: boolean;
  description: string;
  existsJob: boolean;
  jobGroup: string;
  jobName: string;
  triggers: TriggerInfo[];

  // Front End
  extraInfoData?: string;
  extraTableData?: any;
  highlightNoExist?: boolean;
  highlightPaused?: boolean;
}

// ANCHOR Trigger
export interface TriggerInfo {
  //Back End
  triggerGroup: string;
  triggerName: string;
  startTimestamp: number;
  previousTimestamp: number;
  nextTimestamp: number;
  cronExpression: string;
  jobData: string;
  misfire: string;
  state: string;

  // Front End
  startTimestampParsed?: string;
  previousTimestampParsed?: string;
  nextTimestampParsed?: string;
  cronExpressionParsed?: any;
  paused?: boolean;
  modalInfo?: boolean;
  modalInfoData?: any;
  runDisabled?: boolean;
}

// ANCHOR Job a ejecutar
export interface JobToRun {
  job: {
    group: string;
    name: string;
  };
  trigger: {
    group: string;
    name: string;
  };
}

// ANCHOR Job a pausar o parar
export interface JobToStop {
  group: string;
  name: string;
}

// ANCHOR Job modificado
export interface ModifiedJob {
  group: string;
  name: string;
  cronExpression: string;
}

// ANCHOR Misfire modificado
export interface ModifiedMisfire {
  group: string;
  name: string;
  misFireInstr: number;
}

// ANCHOR Reporte de comunicaciones
export interface ManagementCommunicationReport {
  // Back end
  day: number;
  hour: number;
  minute: number;
  metersCount: number;
  fabricante?: number;
  devType?: number;

  // Front end
  timestamp?: number;
  month?: number;
  year?: number;
}

// ANCHOR Tarjetas de reporte de comunicaciones
export interface CommunicationReportCards {
  communicationReportDevicesDay: Card;
}

// ANCHOR Comando de gateway
export interface GatewayCommand {
  // Back End
  id: number;
  entity: number;
  entityDes: string;
  agrupation: number;
  agrupationDes: string;
  gatewayId: number;
  gateway: string;
  contadorId: number;
  contador: string;
  timestamp: number;
  command: number;
  commandDes: string;
  subcommand: number;
  subcommandDes: string;
  priority: number;
  type: number;

  //   Front End
  timestampParsed?: string;
}

// ANCHOR Datos de alarma
export interface AlarmData {
  // BackEnd
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;
  fabricante?: number;
  devType?: number;
  lastConsumptionMax?: number;
  lastConsumptionMin?: number;
  lastConsumptionTotal?: number;
  groupCode?: number;
  agrupationId?: number;
  entityId?: number;
  alarmas?: {
    id: number;
    code: number;
    initDate: number;
    finalDate: number;
    state: number;
    comments: string;
    disabled: boolean;
    groupCode: number;
  }[];
  cols?: object[];

  // FrontEnd
  metrologyType?: number;
  initDateParsed?: string;
  finalDateParsed?: string;
  description?: string;
  name?: string;
  enabled?: boolean;
  stateParsed?: string;
  modalInfo?: boolean;
  modalInfoData?: {
    title?: string;
    html?: string;
  };
  newAlarm?: boolean;
  groupCodeParsed?: string;
}

// ANCHOR Datos de alarma
export interface DeviceContinousAlarms {
  claveMaestra: number;
  cupsLink: string;
  nroSerie: string;
  meterLink: string;
  alarm: string;
  repetitions: boolean;
}

// ANCHOR Datos de actualización de alarma
export interface AlarmUpdateData {
  alarmId: number;
  alarmState: number;
  alarmComment: string;
}

// ANCHOR Alarmas de dispositivo
export interface DeviceAlarm {
  // BackEnd
  fabricante: number;
  devType: number;
  alarmList: number[];

  // FrontEnd
  manufacturer?: string;
  configured?: boolean;
  checks?: DeviceAlarmChecks[];
  allAlarmsCheck?: boolean;
  allNotificationsCheck?: boolean;
  allValvesCheck?: boolean;
}

// ANCHOR Estdo de la alarma del dispositivo
export interface DeviceAlarmChecks {
  id: number;
  check: boolean;
  notification?: boolean;
  open?: boolean;
}

// ANCHOR Estado de alarma/notificación
export interface AlarmStateData {
  alarm_code?: number;
  alarmCode?: number;
  dev_type: number;
  fabricante: number;
  isEnabledAlarm?: boolean;
  isCloseValveAlarm?: boolean;
}

// ANCHOR Dispositivos configurados
export interface AlarmConfiguredDevice {
  id: number;
  nroSerie: string;
  fabricante: number;
  devType: number;
}

// ANCHOR Actualización de alarmas
export interface AlarmsUpdate {
  agrupation: number;
  disabledList: AlarmStateData[];
  disabledNotificationList: AlarmStateData[];
}

// ANCHOR Actualización de alertas
export interface AlertsUpdate {
  entity?: number;
  claveMaestra?: number;
  enabledAlarms: AlarmStateData[];
  closeValveAlarms: AlarmStateData[];
}

// ANCHOR Configuración de entidad
export class EntityConf {
  public id: number;
  public entity: number;
  public days: number;
  public readings: boolean;
  public consumptions: boolean;
  public events: boolean;
  public frames: boolean;
  public alarms: boolean;
  public enabled: boolean;
  public clientName?: string;
  public entityDescripcion?: string;
  public disabled?: boolean;

  constructor() {
    this.id = null;
    this.entity = null;
    this.days = null;
    this.readings = false;
    this.consumptions = false;
    this.events = false;
    this.frames = false;
    this.alarms = false;
    this.disabled = false;
    this.enabled = false;
  }
}

// ANCHOR Respuesta de borrado de entidad
export interface EntityDataDeleteResponse {
  entity: string;
  entityId: number;
  agrupationsResponse: AgrupationDataDeleteResponse[];
  pendingMetersTimestampDeleteBefore: number;
  pendingMetersEventsResponse: string;
  pendingMetersFramesResponse: string;
  pendingMetersAlarmsResponse: string;
  gatewayEventsResponse: string;
  message: string;
}

// ANCHOR Respuesta de borrado de agrupación
export interface AgrupationDataDeleteResponse {
  agrupation: string;
  agrupationId: number;
  timezone: string;
  timestampDeleteBefore: number;
  readingsResponse: string;
  consumptionsResponse: string;
  eventsResponse: string;
  framesResponse: string;
  alarmsResponse: string;
}

// ANCHOR Evento de usuario
export interface ManagementUserEvent {
  // Back End
  id: number;
  contador: number;
  meterNroSerie: string;
  metrologyType: number;
  gateway: number;
  gwUnidadVenta: string;
  username: string;
  eventType: number;
  eventDetails: string;
  responseCommand: string;
  timestamp: number;

  // Front End
  meterLink?: string;
  gatewayLink?: string;
  responseCommandParsed?: string;
}
