<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "gateways-assign" | translate }}
    </h5>
  </div>
  <div class="dialog-body" [ngSwitch]="data.action">
    <!-- Asignar gateway -->
    <div class="device-state-panel-allocate" *ngSwitchCase="'allocate'">
      <app-material-select
        [fullWidth]="true"
        [filter]="true"
        [title]="'gateways' | translate"
        [bindValue]="'id'"
        [bindLabel]="'unidadVenta'"
        [data]="data.otherEntityGatewayList"
        (selectedOption)="data.gatewayToAllocate = $event?.id"
        [required]="true"
      ></app-material-select>
    </div>
    <!-- Nonces -->
    <div *ngSwitchCase="'nonces'">
      <!-- Tablas de nonces  -->
      <div class="meter-detail-nonces-comparison">
        <!-- Gateway -->
        <ul>
          <li>
            <b>Gateway</b>
          </li>
          <li>
            Dev Nonce Readed: <b>0x{{ data.nonceTable?.devNonceReaded }}</b>
          </li>
          <li>
            App Nonce Readed: <b>0x{{ data.nonceTable?.appNonceReaded }}</b>
          </li>
        </ul>
        <!-- Servidor -->
        <ul>
          <li>
            <b>{{ "server" | translate }}</b>
          </li>
          <li>
            Dev Nonce BBDD: <b>0x{{ data.nonceTable?.devNonceBbdd }}</b>
          </li>
          <li>
            App Nonce BBDD <b>0x{{ data.nonceTable?.appNonceBbdd }}</b>
          </li>
        </ul>
      </div>

      <!-- Aviso de coincidencia o no de nonces -->
      <div
        [ngClass]="
          data.nonceTable?.devNonceReaded != data.nonceTable?.devNonceBbdd ||
          data.nonceTable?.appNonceReaded != data.nonceTable?.appNonceBbdd
            ? 'meter-detail-nonces-warning'
            : 'meter-detail-nonces-match'
        "
      >
        Nonces
        {{
          data.nonceTable?.devNonceReaded != data.nonceTable?.devNonceBbdd ||
          data.nonceTable?.appNonceReaded != data.nonceTable?.appNonceBbdd
            ? ("do-not-match" | translate)
            : ("match" | translate)
        }}
      </div>

      <!-- Propagación de nonces -->
      <div class="meter-detail-nonces-buttons">
        <button
          name="propagate"
          type="submit"
          class="btn btn-primary"
          (click)="propagateGateway()"
        >
          {{ "propagate" | translate }}
        </button>
        <button
          name="propagate"
          type="submit"
          class="btn btn-primary"
          (click)="propagateServer()"
        >
          {{ "propagate-server" | translate }}
        </button>
        <button
          name="fix"
          type="submit"
          class="btn btn-primary"
          (click)="fixGateway()"
        >
          {{ "fix-nonces" | translate }}
        </button>
      </div>

      <!-- Configuración manual de nonces -->
      <div class="meter-detail-nonces-config">
        <h5>
          <b>{{ "nonce-manul-conf" | translate }}</b>
        </h5>
        <!-- devNonce -->
        <mat-form-field appearance="standard">
          <mat-label>devNonce</mat-label>
          <input
            matInput
            type="text"
            maxlength="4"
            appHexInput
            placeholder="devNonce (hexadecimal)"
            oninput="this.value = this.value.toUpperCase()"
            [(ngModel)]="data.devNonce"
            required
          />
        </mat-form-field>
        <!-- appNonce -->
        <mat-form-field appearance="standard">
          <mat-label>appNonce</mat-label>
          <input
            matInput
            type="text"
            maxlength="6"
            oninput="this.value = this.value.toUpperCase()"
            appHexInput
            placeholder="appNonce (hexadecimal)"
            [(ngModel)]="data.appNonce"
            required
          />
        </mat-form-field>

        <mat-checkbox [(ngModel)]="data.relaunchFrames">
          {{ "relaunch-frames" | translate }}</mat-checkbox
        >
        <!-- Botones -->
        <div class="meter-detail-nonces-update">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="calculateNonces()"
          >
            {{ "calculate-nonces" | translate }}
          </button>
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="updateNonces()"
            [disabled]="
              data.appNonce?.length != 6 || data.devNonce?.length != 4
            "
          >
            {{ "update" | translate }}
          </button>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault></div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      *ngIf="data.action == 'allocate'"
      mat-button
      class="mat-button-icon-text"
      (click)="allocateExternalGateway()"
      [disabled]="data.gatewayToAllocate == null"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
