// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayService } from "../../../gateways/GatewayService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Componentes
import { DeviceStatePanelDialogComponent } from "./device-state-panel-dialog/device-state-panel-dialog.component";
import { DeviceGeolocationDialogComponent } from "./device-geolocation-dialog/device-geolocation-dialog.component";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-device-state-panel",
  templateUrl: "./device-state-panel.component.html",
  styleUrls: ["./device-state-panel.component.scss"],
})
export class DeviceStatePanelComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() meter: any;
  @Input() stateCardsData: any;
  cardsOrder: string[] = [
    "meterDetailMainGateway",
    "meterDetailRedundantGateways",
    "meterDetailFrameReceived",
    "meterDetailSfRssiSnr",
    "meterDetailLastFrame",
    "meterDetailLastJoin",
  ];
  meterState: object = {
    0: this.translate.instant("device-not-activated"),
    1: this.translate.instant("device-desactivated"),
    2: this.translate.instant("activated"),
    3: this.translate.instant("AlertMeter0"),
    4: this.translate.instant("no-recieved-data"),
    5: this.translate.instant("some-data-up-error"),
    6: this.translate.instant("all-data-up-error"),
  };
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  // Tabla de gateways asociados al contador
  meterGatewayRowNumbers: boolean = true;
  meterGatewayTableMaxReg: number = 10;
  @Input() meterGatewayList: any[];
  @Input() otherEntityGatewayList: any[];
  selectedGatewayList: any[] = [];
  tableGlobalActions: any[] = [
    {
      title: "meter-geolocation",
      icon: "fas fa-map-location-dot",
      help: "",
    },
    {
      title: "gateways-select",
      icon: "fas fa-tasks",
      help: "help-table-gateway-select",
    },
    {
      title: "gateways-unassign",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-gateways-unassign",
    },
  ];
  meterGatewayColumns: object[] = [
    {
      title: "action",
      data: [
        {
          name: "assign-meter-gateway-main",
          tooltip: "assign-meter-gateway-main",
          icon: "fas fa-exchange-alt black",
          visible: { attribute: null, rule: true },
          disabled: "asignateDisabled",
        },
        {
          name: "meter-unassign-gateway",
          tooltip: "meter-unassign-gateway",
          icon: "fas fa-exchange-alt",
          visible: { attribute: null, rule: true },
          disabled: "asignateDisabled",
          warning: true,
        },
        {
          name: "show-nonces",
          tooltip: "show-nonces",
          icon: "fas fa-bug black",
          visible: { attribute: null, rule: true },
          disabled: "showNoncesDisabled",
        },
        {
          name: "gateway-refresh-device",
          tooltip: "gateway-refresh-device",
          icon: "fas fa-sync-alt",
          visible: { attribute: null, rule: true },
          disabled: "showNoncesDisabled",
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "main",
      data: "principal",
      search: "principal",
      sort: "principal",
      alter: {
        condition: "principal",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
      link: "gatewayLink",
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "RSSI (dBm)",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "SNR",
      data: "snrParsed",
      search: "snrParsed",
      sort: "snr",
      numerical: true,
      visible: true,
    },
    {
      title: "last-frames-received",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParser: DateParserService,
    private GatewayService: GatewayService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Tabla de gateways
  /***************************************************************************/

  // Acciones de la tabla de gateways asociados a contador
  meterGatewayTableActions(actionData: any): void {
    let action: any = actionData.action;
    let gateway: any = actionData.element;

    switch (action.name) {
      case "assign-meter-gateway-main":
        this.GatewayService.allocateGateway(gateway, this.meter.id);
        break;
      case "meter-unassign-gateway":
        this.GatewayService.deallocateGateway([gateway], this.meter.id);
        break;
      case "show-nonces":
        this.showNonces(gateway);
        break;
      case "gateway-refresh-device":
        this.GatewayService.refreshDevice(gateway.id, this.meter.id);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(actionData: any): void {
    let action: string = actionData.title;

    switch (action) {
      case "gateways-select":
        this.selectGateway();
        break;
      case "gateways-unassign":
        this.GatewayService.deallocateGateway(
          this.selectedGatewayList,
          this.meter.id
        );
        break;
      case "meter-geolocation":
        this.MaterialDialogService.openDialog(
          DeviceGeolocationDialogComponent,
          {
            device: this.meter,
            gateways: this.meterGatewayList.map((gateway) => {
              return {
                id: gateway.id,
                unidadVenta: gateway.unidadVenta,
                longitude: gateway.longitude,
                latitude: gateway.latitude,
                rssi: gateway.rssi,
                rssiTimestamp: gateway.rssiTimestamp,
                rssiTimestampParsed: this.DateParser.parseDate(
                  gateway.rssiTimestamp,
                  "DD/MM/YY HH:mm:ss",
                  "Europe/Madrid"
                ),
              };
            }),
          }
        );
        break;
      default:
        break;
    }
  }

  // Selección de gateway no asignado
  selectGateway(): void {
    this.MaterialDialogService.openDialog(DeviceStatePanelDialogComponent, {
      action: "allocate",
      meter: this.meter,
      otherEntityGatewayList: this.otherEntityGatewayList,
      gatewayToAllocate: null,
    });
  }

  // Actualización de los datos seleccionados en la tabla
  selectedDataUpdate(data: any): void {
    this.selectedGatewayList = [...data];
  }

  // Mostrar nonces
  showNonces(gateway: any): void {
    this.MeterController.nonceTable(gateway.id, gateway.idMeter).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.MaterialDialogService.openDialog(
            DeviceStatePanelDialogComponent,
            {
              action: "nonces",
              meter: this.meter,
              nonceTable: response["body"],
              devNonce: null,
              appNonce: null,
              relaunchFrames: false,
              selectedGateway: gateway.id,
            }
          );
        }
      }
    );
  }
}
