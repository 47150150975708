// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { DeviceCupsDialogComponent } from "./device-cups-dialog/device-cups-dialog.component";
// Interfaces
import {
  TableAction,
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-device-cups-table",
  templateUrl: "./device-cups-table.component.html",
  styleUrls: ["./device-cups-table.component.scss"],
})
export class DeviceCupsTableComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tabla de CUPS asociados al contador
  @Input() cupsData: any;
  @Input() meter: any;
  @Input() sessionProfile: string;
  @Input() source: string;
  tableMaxReg: number = 5;
  tableData: object[];
  tableColumns: (TableActionColumn | TableDataColumn)[];
  meterIsAssigned: boolean = true;
  cupsExist: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.cupsExist = this.SessionDataService.getCurrentEntityCupsConf()?.some(
      (column: EntityDefinition) => column.colPosition == 0
    );
    if (this.cupsData.columns.length > 0) {
      if (this.source === "meter" && this.cupsData.data) {
        this.cupsData.data.cups = this.cupsData.data.isAssigned
          ? this.cupsData.data.clave
          : this.translate.instant("no-assigned");
        this.meterIsAssigned = this.cupsData.data.isAssigned;
      } else if (this.source === "meter") {
        this.cupsData["data"] = { cups: this.translate.instant("no-assigned") };
        this.meterIsAssigned = false;
      }

      if (this.source === "cups") {
        this.cupsData.data.meterLink = this.meter
          ? "/dispositivos/detalle/contador/" + this.meter?.id
          : null;
      }

      this.cupsData.cups = this.cupsData.columns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      this.cupsData.columns.map(
        (column: EntityDefinition) =>
          (column.colPosition =
            column.colPosition <= 9
              ? "col0" + column.colPosition
              : "col" + column.colPosition)
      );
      this.setCupsTable();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  setCupsTable(): void {
    let data: TableAction[] = [];
    if (this.source === "meter" && this.cupsExist) {
      data.push({
        name: "show-cups",
        tooltip: "show-detail",
        icon: "fas fa-eye",
        visible: { attribute: "isAssigned", rule: true },
        disabled: false,
      });
    }
    if (this.source === "cups" || this.meterIsAssigned) {
      data.push({
        name: "edit-cups",
        tooltip: "edit",
        icon: "fas fa-edit",
        visible: { attribute: null, rule: true },
        disabled: false,
      });
    } else if (!this.meterIsAssigned && this.cupsData?.columns.length > 1) {
      if (
        this.meter.metrologyType == METROLOGY_TYPE.WATER ||
        this.meter.metrologyType == METROLOGY_TYPE.GAS
      ) {
        data.push({
          name: "edit-cups",
          tooltip: "new",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: false,
        });
      }
      if (this.cupsExist) {
        data.push({
          name: "assign-cups",
          tooltip: "assign",
          icon: "fas fa-exchange-alt",
          visible: { attribute: null, rule: true },
          disabled: false,
        });
      }
    }

    let tableColumns: (TableActionColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: data,
        search: null,
        sort: null,
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ? true
            : null,
      },
      {
        title: this.cupsData.cups ? this.cupsData.cups.name : "CUPS",
        data: "cups",
        search: null,
        sort: null,
        visible: this.source === "meter" && this.cupsExist ? true : null,
      },
      {
        title: "serial-number",
        data: "meterNroSerie",
        search: null,
        sort: null,
        link: "meterLink",
        visible:
          this.source === "cups" && this.meter?.nroSerie != null ? true : null,
      },
    ];

    this.cupsData.columns.forEach((extraColumn: any) => {
      if (
        extraColumn.colPosition != "col00" &&
        extraColumn.colPosition != "col100"
      ) {
        let newColumn: any = {
          title: extraColumn.name,
          data: extraColumn.colPosition,
          visible: true,
        };
        tableColumns.push(newColumn);
      }
    });

    if (this.source === "cups") {
      this.cupsData.data.meterNroSerie = this.meter?.nroSerie;
    }

    this.tableColumns = tableColumns;
    this.tableData = [this.cupsData.data];
  }

  // Acciones de la tabla de CUPS
  tableActions(action: string, cups: any): void {
    switch (action) {
      case "show-cups":
        this.router.navigate(["/cups/detalle/" + cups.id]);
        break;
      case "edit-cups":
        this.editCupsModal(cups);
        break;
      case "assign-cups":
        this.assignCupsModal();
        break;
      default:
        break;
    }
  }

  // Mostrar modal de edición de CUPS
  editCupsModal(cups): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "edit",
      cupsExist: this.cupsExist,
      cupsData: JSON.parse(JSON.stringify(this.cupsData)),
      newCups: JSON.parse(JSON.stringify(cups)),
      meter: this.meter,
    });
  }

  // Asignación de CUPS existente
  assignCupsModal(): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "assign",
      meterId: this.meter.id,
      cupsName: this.cupsData.cups?.name,
      cupsList: this.SessionDataService.getCurrentCupsList(),
    });
  }
}
