import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { MapDevice } from "../../../interfaces/DeviceGlobalInterface.type";
import { ToastService } from "../../../services/shared/ToastService.service";
import { MapGateway } from "../../../interfaces/GatewayGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(
    private ngZone: NgZone,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  filterDisabledAgrupations(devices: MapDevice[]): MapDevice[] {
    let disabledAgrupations = this.SessionDataService.getCurrentEntity()
      .agrupations.filter((agrupation) => agrupation.hideInVirtualMap)
      .map((agrupation) => agrupation.id);
    return devices.filter(
      (device) => !disabledAgrupations.includes(device.agrupation)
    );
  }

  // Selección de dispositivos en mapa
  goToTable(selectedDevices: MapDevice[], gateways: MapGateway[]): void {
    if (selectedDevices.length > 0) {
      this.ToastService.fireAlertWithTripleOptions(
        "question",
        this.translate.instant("select-option"),
        this.translate.instant("cancel"),
        this.translate.instant("filter-table-by-map"),
        this.translate.instant("filter-in-network")
      ).then((userSelection: string) => {
        if (userSelection == "option1") {
          this.ngZone.run(() => {
            this.router.navigate(["/dispositivos/listado/seleccionados"], {
              state: {
                data: { devices: selectedDevices, gateways: gateways },
              },
            });
          });
        } else if (userSelection == "option2") {
          this.ngZone.run(() => {
            this.router.navigate(["/dispositivos/listado/asignados"], {
              state: {
                data: { devices: selectedDevices },
              },
            });
          });
        }
      });
    }
  }
}
