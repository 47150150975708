import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-cups-filters",
  templateUrl: "./cups-filters.component.html",
  styleUrls: ["./cups-filters.component.scss"],
})
export class CupsFiltersComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() filters: any;
  @Output() filterFlag = new EventEmitter<any>();
  @Output() resetFlag = new EventEmitter<any>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor() {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  filterData(): void {
    this.filterFlag.emit(this.filters);
  }

  resetData(): void {
    this.filters.map((filter: any) => (filter.value = null));
    this.resetFlag.emit();
  }
}
