// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
} from "../../../../modules/table-module/TableInterface.type";
import { GatewayAlarm } from "../../gateways/GatewayInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-management-alarms",
  templateUrl: "./management-alarms.component.html",
  styleUrls: ["./management-alarms.component.scss"],
})
export class ManagementAlarmsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  clientList: Client[];
  clientListSub: Subscription;

  // Table
  searchBy: string = "global";
  filterActive: number = null;
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("5");
  gatewayId: number;
  unidadVenta: string;
  gatewayAlarms: GatewayAlarm[];
  originalGatewayAlarms: GatewayAlarm[];
  orderBy: object = { attribute: "initDate", reverse: true };
  from: string;
  to: string;
  showAgrupationTimezone: boolean = false;
  columns: (TableActionColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "finished",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "unfinished",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: false },
        active: false,
      },
    ],
  ];

  // Select
  alarmSelect: MaterialSelectOption[] = [
    { value: "global", name: this.translate.instant("global") },
    { value: "entity", name: this.translate.instant("entity") },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GatewayController: GatewayControllerService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.clientList = this.SessionDataService.getCurrentClientList();

    // Escucha de cambios en entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    this.clientListSub = this.SessionDataService.getClientList().subscribe(
      (clientList) => {
        this.clientList = clientList;
      }
    );

    // Carga del componente
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.filterActive = null;
    this.from = from;
    this.to = to;
    let alarmsUrl =
      this.searchBy == "global"
        ? this.GatewayController.getGatewayGlobalAlarms(this.from, this.to)
        : this.GatewayController.getGatewayEntityAlarms(
            this.currentEntity.id,
            this.from,
            this.to
          );
    alarmsUrl.subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let gatewayAlarms: GatewayAlarm[] = response["body"];
          gatewayAlarms.forEach((gatewayAlarm: GatewayAlarm) => {
            // Zona horaria
            if (
              gatewayAlarm.timezone &&
              gatewayAlarm.timezone != LOCAL_TIMEZONE
            ) {
              this.showAgrupationTimezone = true;
            }
            // Código
            gatewayAlarm.codeText =
              gatewayAlarm.code != null
                ? this.translate.instant("GatewayAlarm" + gatewayAlarm.code)
                : "-";
            gatewayAlarm.hasDevice = !gatewayAlarm.contador;
          });
          this.gatewayAlarms = gatewayAlarms;
          this.originalGatewayAlarms = gatewayAlarms;
        } else {
          this.gatewayAlarms = [];
          this.originalGatewayAlarms = [];
        }
        this.setColumns();
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-gateway",
            tooltip: "show-detail",
            icon: "gateway-icon gateway-icon-gray",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "show-device",
            tooltip: "show-detail",
            icon: "fas fa-tachometer-alt",
            visible: { attribute: null, rule: true },
            disabled: "hasDevice",
          },
        ],
        visible: true,
      },
      {
        title: "entity",
        data: "entity",
        search: "entity",
        sort: "entity",
        visible: true,
      },
      {
        title: "sales-unit",
        data: "gatewayUnidadVenta",
        search: "gatewayUnidadVenta",
        sort: "gatewayUnidadVenta",
        visible: true,
      },
      {
        title: "serial-number",
        data: "contadorNroSerie",
        search: "contadorNroSerie",
        sort: "contadorNroSerie",
        visible: true,
      },
      {
        title: "start-date-local",
        data: "initLocalDateParsed",
        search: "initLocalDateParsed",
        sort: "initDate",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "end-date-local",
        data: "finalLocalDateParsed",
        search: "finalLocalDateParsed",
        sort: "finalDate",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: this.showAgrupationTimezone ? true : null,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: this.showAgrupationTimezone ? true : null,
      },
      {
        title: "code",
        data: "codeText",
        search: "codeText",
        sort: "codeText",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, gatewayAlarm: GatewayAlarm): void {
    switch (action) {
      case "show-gateway":
        this.DeviceRouteSelectorService.updateEntity(gatewayAlarm.entityId);
        this.showGateway(gatewayAlarm.gateway, gatewayAlarm.entityId);
        break;
      case "show-device":
        this.DeviceRouteSelectorService.updateEntity(
          gatewayAlarm.entityId,
          gatewayAlarm.agrupationId
        );
        this.DeviceRouteSelectorService.getDeviceRoute(
          gatewayAlarm.metrology_type,
          gatewayAlarm.contador
        );
        break;
      default:
        break;
    }
  }

  // Redirección a detalles del gateway
  showGateway(id: number, entityId: number): void {
    this.DeviceRouteSelectorService.updateEntity(entityId);
    // this.router
    //   .navigateByUrl("", { skipLocationChange: true })
    //   .then(() => this.router.navigate(["gateways/detalle/gateway", id]));
    this.router.navigate(["gateways/detalle/gateway", id]);
  }

  // Filtrado de alarmas repetidas
  filterAlarms(codeIncluded: boolean): void {
    let newGatewayAlarms: GatewayAlarm[] = [];
    this.gatewayAlarms.forEach((gatewayAlarm: GatewayAlarm) => {
      if (
        !newGatewayAlarms.some(
          (pushedGatewayAlarm: GatewayAlarm) =>
            (pushedGatewayAlarm.contador == gatewayAlarm.contador ||
              (!pushedGatewayAlarm.contador && !gatewayAlarm.contador)) &&
            pushedGatewayAlarm.gatewayUnidadVenta ==
              gatewayAlarm.gatewayUnidadVenta &&
            ((codeIncluded && pushedGatewayAlarm.code == gatewayAlarm.code) ||
              !codeIncluded)
        )
      ) {
        newGatewayAlarms.push(gatewayAlarm);
      }
    });
    this.gatewayAlarms = newGatewayAlarms;
  }

  // Reseteo de filtros
  resetFilters(): void {
    this.gatewayAlarms = [...this.originalGatewayAlarms];
  }

  // Actualización de filtros activos
  updateFilters(filterActive: number, codeIncluded: boolean): void {
    this.resetFilters();
    if (filterActive == this.filterActive) {
      this.filterActive = null;
    } else {
      this.filterActive = filterActive;
      this.filterAlarms(codeIncluded);
    }
  }
}
