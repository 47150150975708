// @angular/core
import { Component, Input, OnInit } from "@angular/core";
import { MaterialDialogService } from "../../../modules/material-module/material-dialog/material-dialog.service";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Variables
import { LAST_PATCH_NOTES_ARSON } from "../../../LAST_PATCH_NOTES_ARSON";
import { LAST_PATCH_NOTES_USER } from "../../../LAST_PATCH_NOTES_USER";
import { CUSTOM_URLS } from "../../../screens/login/CUSTOM_URLS";
import { PROFILES } from "../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../services/language/LanguageController.service";
// Componentes
import { FooterbarDialogComponent } from "./footerbar-dialog/footerbar-dialog.component";

@Component({
  selector: "app-footerbar",
  templateUrl: "./footerbar.component.html",
  styleUrls: ["./footerbar.component.scss"],
})
export class FooterbarComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() subscriberFooter: boolean;
  @Input() sidebarLocked: boolean;
  @Input() sessionProfile: string;
  readonly PROFILES = PROFILES;
  @Input() appVersion: { module: string; version: string }[];
  frontVersion: string;
  versionTitle: string = "";
  latestPatchInfo: {
    date: string;
    info: { title: string; actions: string[] }[];
    profile: string;
  }[];
  date: number;
  customFooter = CUSTOM_URLS.find((url) =>
    window.location.href.includes(url.urlText)
  )?.footer;
  footerLink: string = this.customFooter?.footerLink
    ? this.customFooter.footerLink
    : "http://arsonmetering.com/";
  footerText: string = this.customFooter?.footerText
    ? this.customFooter.footerText
    : "Arson";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.date = Date.now();
    this.frontVersion = this.appVersion[0].version;
    this.appVersion?.forEach(
      (appModule: { module: string; version: string }) => {
        this.versionTitle += appModule.module + ": " + appModule.version + "\n";
      }
    );
    this.formatLastPatchNotes();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Modal de las notas del parche
  showLastPathNotes(): void {
    this.MaterialDialogService.openDialog(FooterbarDialogComponent, {
      latestPatchInfo: this.latestPatchInfo,
      appVersion: this.appVersion,
    });
  }

  // Forzado de actualización
  forceUpdate(): void {
    document.location.reload();
  }

  // Formateo de la notas del último parche
  formatLastPatchNotes(): void {
    let latestPatchInfo =
      this.sessionProfile == PROFILES.ARSON
        ? LAST_PATCH_NOTES_ARSON
        : LAST_PATCH_NOTES_USER[this.SessionDataService.getCurrentLanguage()]
        ? LAST_PATCH_NOTES_USER[this.SessionDataService.getCurrentLanguage()]
        : LAST_PATCH_NOTES_USER[LANGUAGE.INGLES];
    if (latestPatchInfo) {
      this.latestPatchInfo = [];
      let lastPatchUpdates = latestPatchInfo.split("###");
      lastPatchUpdates.forEach((updateInfo: string) => {
        let patchDate = updateInfo.split("\n")[0];
        if (patchDate && patchDate != "") {
          let latestPatchInfoArray = updateInfo.split("*");
          latestPatchInfoArray.sort((a, b) => a.localeCompare(b));
          let latestPatchInfoData: { title: string; actions: string[] }[] = [];
          latestPatchInfoArray.forEach((note: string) => {
            let noteTitle = note.substring(0, note.indexOf(":") + 1);
            let noteActions = note.replace(noteTitle, "");
            let noteActionsArray = noteActions
              .split("-")
              .filter((action: string) => /[a-z]/i.test(action));
            if (noteTitle && noteActions) {
              latestPatchInfoData.push({
                title: noteTitle,
                actions: noteActionsArray,
              });
            }
          });
          this.latestPatchInfo.push({
            date: patchDate,
            info: latestPatchInfoData,
            profile: this.sessionProfile,
          });
        }
      });
    }
  }

  // Visualización del panel de contacto
  showContact(): void {
    this.ToastService.fireAlertInfoText(
      "info",
      this.translate.instant("contact"),
      ` <div class="contact` +
        (this.customFooter ? ` contact-full-width` : `"`) +
        `">` +
        (this.customFooter
          ? this.customFooter.footerContactPhoto
          : `<img class="contact-ccd" src="../../../../assets/img/ccd.jpg" />`) +
        `<div>
            <div>
              <span><i class="fas fa-at"></i>
                <a href="` +
        (this.customFooter
          ? this.customFooter.footerContactLink
          : "mailto:soporte@arsondata.com") +
        `" target="_blank">` +
        (this.customFooter
          ? this.customFooter.footerContactLink
          : "soporte@arsondata.com") +
        `</a>
              </span>
              <span><i class="fas fa-phone-alt"></i>` +
        (this.customFooter
          ? this.customFooter.footerContactTel
          : "+34 94 683 50 37") +
        `</span>
            </div>` +
        (this.customFooter
          ? ""
          : `<div>
              <span><i class="fab fa-whatsapp"></i><a href="https://wa.me/message/4CZHZ22LNJYQG1">CCD ARSON</a></span>
              <img src="../../../../assets/img/ccd_whatsapp.png" />
            </div>`) +
        ` </div>
        </div>`
    );
  }
}
