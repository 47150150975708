// Tipo de mapa
export class MapLayerData {
  public array?: any[];
  public markers?: object;
  public layer?: object;
  public cesiumLayer?: any[];
  public layerData: { name: string; overlay: boolean };
  public active?: boolean;

  constructor(layerData: { name: string; overlay: boolean }) {
    this.array = [];
    this.markers = {};
    this.layer = {};
    this.cesiumLayer = [];
    this.layerData = layerData;
    this.active = true;
  }
}

export const MAP_TYPES = {
  // Edición de agrupaciones
  agrupationEdition: [],
  // ANCHOR Mapa estándar
  standard: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: true },
    },
    LOCATIONS: {
      layerData: { name: "locations", overlay: true },
    },
    NO_ASIGNADO: {
      layerData: {
        id: "NO_ASIGNADO",
        name: "devices-not-assigned",
        overlay: true,
      },
    },
    CONCENTRATOR_NO_ASIGNADO: {
      layerData: {
        name: "concentrators-not-assigned",
        overlay: true,
      },
    },
    TXN: {
      layerData: { name: "TXN", overlay: true },
    },
    UNE_NO_COMUNICA: {
      layerData: {
        name: "lw-une-meters-fail",
        overlay: true,
      },
    },
    NO_COMUNICA: {
      layerData: {
        name: "devices-not-communicate",
        overlay: true,
      },
    },
    CONCENTRATOR_NO_COMUNICA: {
      layerData: {
        name: "concentrators-without-communication",
        overlay: true,
      },
    },
    UNE_OK: {
      layerData: { name: "lw-une-meters-ok", overlay: true },
    },
    WAVENIS: {
      layerData: { name: "meters-wavenis", overlay: true },
    },
    MBUS_COMUNICA: {
      layerData: {
        name: "mbus-communicate",
        overlay: true,
      },
    },
    MBUS: {
      layerData: { name: "meters-mbus", overlay: true },
    },
    NBIOT: {
      layerData: { name: "NBIoT", overlay: true },
    },
    NO_LORA: {
      layerData: { name: "no-lora-meter", overlay: true },
    },
    OK: {
      layerData: { name: "devices-active", overlay: true },
    },
    CONCENTRATOR_OK: {
      layerData: {
        name: "concentrators-active",
        overlay: true,
      },
    },
    NO_LORA_NO_COM: {
      layerData: {
        name: "no-lora-no-com-meters",
        overlay: true,
      },
    },
    SIGFOX_OK: {
      layerData: { name: "sigfox-ok", overlay: true },
    },
    SIGFOX_NO_COMUNICA: {
      layerData: {
        name: "sigfox-no-comm",
        overlay: true,
      },
    },
  },
  // ANCHOR Mapa de cobertura
  coverage: {
    GATEWAYS_OK: {
      layerData: { name: "with-communication", overlay: false },
    },
    GATEWAYS_ERROR: {
      layerData: { name: "devices-not-communicate", overlay: false },
    },
    LOCATIONS: {
      layerData: { name: "locations", overlay: true },
    },
    COVERAGE_OK: {
      layerData: { name: "test-ok", overlay: true },
    },
    COVERAGE_ERROR: {
      layerData: { name: "test-fail", overlay: true },
    },
  },
  // ANCHOR Mapa de cobertura
  coverageMap: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: false },
    },
  },
  // ANCHOR Mapa de cobertura MBus
  coverageMbusMap: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: false },
    },
    CONCENTRATOR_OK: {
      layerData: { name: "concentrators-active", overlay: true },
    },
    CONCENTRATOR_NO_COMUNICA: {
      layerData: { name: "concentrators-without-communication", overlay: true },
    },
  },
  // ANCHOR Mapa de gateways
  onlyGateways: {
    GATEWAYS_OK: {
      layerData: { name: "with-communication", overlay: true },
    },
    GATEWAYS_ERROR: {
      layerData: { name: "devices-not-communicate", overlay: true },
    },
  },
  // ANCHOR Mapa de telecontrol
  control: {
    CONTROL_ALERT_ASSIGNED: {
      layerData: { name: "assigned", overlay: true },
    },
    CONTROL_ALERT_OPEN: {
      layerData: { name: "opened", overlay: true },
    },
    CONTROL_SENSOR: {
      layerData: { name: "sensor", overlay: true },
    },
    CONTROL_VALVE_METER: {
      layerData: { name: "meters-close-valve", overlay: true },
    },
  },
  // ANCHOR Mapa de detalle de contador
  meterDetail: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: true },
    },
    LOCATIONS: {
      layerData: { name: "locations", overlay: true },
    },
    NO_ASIGNADO: {
      layerData: { name: "devices-not-assigned", overlay: true },
    },
    CONCENTRATOR_NO_ASIGNADO: {
      layerData: { name: "concentrators-not-assigned", overlay: true },
    },
    TXN: {
      layerData: { name: "TXN", overlay: true },
    },
    UNE_NO_COMUNICA: {
      layerData: { name: "lw-une-meters-fail", overlay: true },
    },
    NO_COMUNICA: {
      layerData: { name: "devices-not-communicate", overlay: true },
    },
    CONCENTRATOR_NO_RECIBIDO: {
      layerData: { name: "concentrators-not-received", overlay: true },
    },
    UNE_OK: {
      layerData: { name: "lw-une-meters-ok", overlay: true },
    },
    WAVENIS: {
      layerData: { name: "meters-wavenis", overlay: true },
    },
    MBUS_COMUNICA: {
      layerData: { name: "mbus-communicate", overlay: true },
    },
    MBUS: {
      layerData: { name: "meters-mbus", overlay: true },
    },
    NBIOT: {
      layerData: { name: "NBIoT", overlay: true },
    },
    NO_LORA: {
      layerData: { name: "no-lora-meter", overlay: true },
    },
    OK: {
      layerData: { name: "devices-active", overlay: true },
    },
    CONCENTRATOR_OK: {
      layerData: { name: "concentrators-active", overlay: true },
    },
    NO_LORA_NO_COM: {
      layerData: { name: "no-lora-no-com-meters", overlay: true },
    },
    SIGFOX_OK: {
      layerData: { name: "sigfox-ok", overlay: true },
    },
    SIGFOX_NO_COMUNICA: {
      layerData: { name: "sigfox-no-comm", overlay: true },
    },
  },
  // ANCHOR Mapa de detalle de CUPS
  cupsDetail: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: true },
    },
    LOCATIONS: {
      layerData: { name: "locations", overlay: true },
    },
    NO_ASIGNADO: {
      layerData: { name: "devices-not-assigned", overlay: true },
    },
    CONCENTRATOR_NO_ASIGNADO: {
      layerData: { name: "concentrators-not-assigned", overlay: true },
    },
    TXN: {
      layerData: { name: "TXN", overlay: true },
    },
    UNE_NO_COMUNICA: {
      layerData: { name: "lw-une-meters-fail", overlay: true },
    },
    NO_COMUNICA: {
      layerData: { name: "without-communication", overlay: true },
    },
    CONCENTRATOR_NO_RECIBIDO: {
      layerData: { name: "concentrators-not-received", overlay: true },
    },
    UNE_OK: {
      layerData: { name: "lw-une-meters-ok", overlay: true },
    },
    WAVENIS: {
      layerData: { name: "meters-wavenis", overlay: true },
    },
    MBUS_COMUNICA: {
      layerData: { name: "mbus-communicate", overlay: true },
    },
    MBUS: {
      layerData: { name: "meters-mbus", overlay: true },
    },
    NBIOT: {
      layerData: { name: "NBIoT", overlay: true },
    },
    NO_LORA: {
      layerData: { name: "no-lora-meter", overlay: true },
    },
    OK: {
      layerData: { name: "devices-active", overlay: true },
    },
    CONCENTRATOR_OK: {
      layerData: { name: "concentrators-active", overlay: true },
    },
    NO_LORA_NO_COM: {
      layerData: { name: "no-lora-no-com-meters", overlay: true },
    },
    SIGFOX_OK: {
      layerData: { name: "sigfox-ok", overlay: true },
    },
    SIGFOX_NO_COMUNICA: {
      layerData: { name: "sigfox-no-comm", overlay: true },
    },
  },
  // ANCHOR Mapa de cambio de localización
  changeLocation: {
    METER_LOCATION_METER: {
      layerData: { name: "meter", overlay: false },
    },
  },
  // ANCHOR Mapa de detalle de gateway
  gatewayDetail: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: false },
    },
    GATEWAY_DETAIL_MAIN_METER: {
      layerData: { name: "main-meter", overlay: true },
    },
    GATEWAY_DETAIL_REDUNDANT_METER: {
      layerData: { name: "redundant-meter", overlay: true },
    },
    GATEWAY_DETAIL_ASSIGNABLE_METER: {
      layerData: { name: "assignable-meter", overlay: true },
    },
  },
  // ANCHOR Mapa de detalle de concentrador MBUS
  mbusConcentratorDetail: {
    OK: {
      layerData: { name: "devices-active", overlay: true },
    },
    CONCENTRATOR_OK: {
      layerData: { name: "concentrators-active", overlay: true },
    },
    MBUS_COMUNICA: {
      layerData: { name: "permanent-mbus-meters", overlay: true },
    },
    MBUS_MAIN_NO_COMUNICA: {
      layerData: { name: "devices-main-not-communicate", overlay: true },
    },
    MBUS: {
      layerData: { name: "meters-mbus", overlay: true },
    },
    NO_COMUNICA: {
      layerData: { name: "devices-not-communicate", overlay: true },
    },
    CONCENTRATOR_NO_COMUNICA: {
      layerData: { name: "concentrators-without-communication", overlay: true },
    },
  },
  // ANCHOR Mapa de gráfica de contadores
  meterGraph: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: true },
    },
    LOCATIONS: {
      layerData: { name: "locations", overlay: true },
    },
    NO_ASIGNADO: {
      layerData: { name: "devices-not-assigned", overlay: true },
    },
    CONCENTRATOR_NO_ASIGNADO: {
      layerData: { name: "concentrators-not-assigned", overlay: true },
    },
    UNE_NO_COMUNICA: {
      layerData: { name: "lw-une-meters-fail", overlay: true },
    },
    NO_COMUNICA: {
      layerData: { name: "devices-not-communicate", overlay: true },
    },
    CONCENTRATOR_NO_COMUNICA: {
      layerData: { name: "concentrators-without-communication", overlay: true },
    },
    UNE_OK: {
      layerData: { name: "lw-une-meters-ok", overlay: true },
    },
    WAVENIS: {
      layerData: { name: "meters-wavenis", overlay: true },
    },
    MBUS_COMUNICA: {
      layerData: { name: "mbus-communicate", overlay: true },
    },
    MBUS: {
      layerData: { name: "meters-mbus", overlay: true },
    },
    NBIOT: {
      layerData: { name: "NBIoT", overlay: true },
    },
    NO_LORA: {
      layerData: { name: "no-lora-meter", overlay: true },
    },
    OK: {
      layerData: { name: "devices-active", overlay: true },
    },
    CONCENTRATOR_OK: {
      layerData: { name: "concentrators-active", overlay: true },
    },
    NO_LORA_NO_COM: {
      layerData: { name: "no-lora-no-com-meters", overlay: true },
    },
    SIGFOX_OK: {
      layerData: { name: "sigfox-ok", overlay: true },
    },
    SIGFOX_NO_COMUNICA: {
      layerData: { name: "sigfox-no-comm", overlay: true },
    },
  },
  // ANCHOR Mapa de balance
  balanceDetail: {
    FATHER: {
      layerData: { name: "fathers", overlay: true },
    },
    CHILD: {
      layerData: { name: "childs", overlay: true },
    },
    METER_LIST: {
      layerData: { name: "devices", overlay: true },
    },
  },
  // ANCHOR Mapa de edición de balance
  balanceEdition: {
    METER_LIST: {
      layerData: { name: "devices", overlay: true },
    },
    CHILD: {
      layerData: { name: "childs", overlay: true },
    },
    FATHER: {
      layerData: { name: "fathers", overlay: true },
    },
  },
  // ANCHOR Mapa de listados de contadores
  meterList: {
    METER_LIST: {
      layerData: { name: "devices", overlay: true },
    },
  },
  // ANCHOR Mapa de geolocalización de contadores
  geolocation: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: true },
    },
    METER_LIST: {
      layerData: { name: "devices", overlay: true },
    },
  },
  // ANCHOR Mapa de listados de contadores seleccionables
  meterAssignable: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: false },
    },
    GATEWAY_DETAIL_REDUNDANT_METER: {
      layerData: { name: "redundant-meter", overlay: true },
    },
    GATEWAY_DETAIL_ASSIGNABLE_METER: {
      layerData: { name: "assignable-meter", overlay: true },
    },
  },
  // ANCHOR Mapa de listado de alarmas
  alarmsMeterList: {
    ALARMS_METER_LIST: {
      layerData: { name: "devices", overlay: false },
    },
    ALARMS_METER_LIST_NEW: {
      layerData: { name: "devices", overlay: false },
    },
  },
  // ANCHOR Mapa de listado de alarmas
  warnings: {
    GATEWAYS: {
      layerData: { name: "gateways", overlay: false },
    },
    METER_LIST: {
      layerData: { name: "devices", overlay: true },
    },
  },
  // ANCHOR Mapa de sustituciones
  substitutions: {
    SUBSTITUTIONS_PENDING: {
      layerData: { name: "substitution-pending", overlay: true },
    },
    SUBSTITUTIONS_REPLACED: {
      layerData: { name: "substitution-replaced", overlay: true },
    },
  },
  // ANCHOR Mapa de sustituciones
  unknownDevices: {
    UNKNOWN_DEVICE: {
      layerData: { name: "unknown-devices", overlay: true },
    },
    MBUS_CONCENTRATOR: {
      layerData: { name: "LW MBUS", overlay: true },
    },
  },
  // ANCHOR Mapa de fugas
  leakDetection: {
    LEAK_SENSOR: {
      layerData: { name: "sensors", overlay: true },
    },
    LEAK_SENSOR_OVER: {
      layerData: { name: "sensors-filter-over", overlay: true },
    },
    LEAK_SENSOR_BELOW: {
      layerData: { name: "sensors-filter-below", overlay: true },
    },
    LEAK_SENSOR_NO_DATA: {
      layerData: { name: "sensors-filter-no-data", overlay: true },
    },
    PIPES: {
      layerData: { name: "pipes", overlay: true },
    },
  },
  // ANCHOR Mapa de presión
  pressureDetection: {
    LEAK_SENSOR: {
      layerData: { name: "sensors", overlay: true },
    },
    PIPES: {
      layerData: { name: "pipes", overlay: true },
    },
  },
  // ANCHOR Mapa de caudal
  flowDetection: {
    LEAK_SENSOR: {
      layerData: { name: "sensors", overlay: true },
    },
    PIPES: {
      layerData: { name: "pipes", overlay: true },
    },
  },
};

// Capas disponibles
export enum MAP_LAYERS {
  GATEWAYS = "GATEWAYS",
  LOCATIONS = "LOCATIONS",
  NO_ASIGNADO = "NO_ASIGNADO",
  CONCENTRATOR_NO_ASIGNADO = "CONCENTRATOR_NO_ASIGNADO",
  TXN = "TXN",
  UNE_NO_COMUNICA = "UNE_NO_COMUNICA",
  NO_COMUNICA = "NO_COMUNICA",
  CONCENTRATOR_NO_COMUNICA = "CONCENTRATOR_NO_COMUNICA",
  CONCENTRATOR_NO_RECIBIDO = "CONCENTRATOR_NO_RECIBIDO",
  UNE_OK = "UNE_OK",
  WAVENIS = "WAVENIS",
  MBUS_COMUNICA = "MBUS_COMUNICA",
  MBUS = "MBUS",
  NBIOT = "NBIOT",
  NO_LORA = "NO_LORA",
  OK = "OK",
  CONCENTRATOR_OK = "CONCENTRATOR_OK",
  NO_LORA_NO_COM = "NO_LORA_NO_COM",
  SIGFOX_OK = "SIGFOX_OK",
  SIGFOX_NO_COMUNICA = "SIGFOX_NO_COMUNICA",
  GATEWAYS_OK = "GATEWAYS_OK",
  GATEWAYS_ERROR = "GATEWAYS_ERROR",
  COVERAGE_OK = "COVERAGE_OK",
  COVERAGE_ERROR = "COVERAGE_ERROR",
  GATEWAY_DETAIL_MAIN_METER = "GATEWAY_DETAIL_MAIN_METER",
  GATEWAY_DETAIL_REDUNDANT_METER = "GATEWAY_DETAIL_REDUNDANT_METER",
  GATEWAY_DETAIL_ASSIGNABLE_METER = "GATEWAY_DETAIL_ASSIGNABLE_METER",
  ALARMS_METER_LIST = "ALARMS_METER_LIST",
  ALARMS_METER_LIST_NEW = "ALARMS_METER_LIST_NEW",
  SUBSTITUTIONS_PENDING = "SUBSTITUTIONS_PENDING",
  SUBSTITUTIONS_REPLACED = "SUBSTITUTIONS_REPLACED",
  UNKNOWN_DEVICE = "UNKNOWN_DEVICE",
  MBUS_CONCENTRATOR = "MBUS_CONCENTRATOR",
  LEAK_SENSOR_OVER = "LEAK_SENSOR_OVER",
  LEAK_SENSOR_BELOW = "LEAK_SENSOR_BELOW",
  LEAK_SENSOR_NO_DATA = "LEAK_SENSOR_NO_DATA",
  LEAK_SENSOR = "LEAK_SENSOR",
  PIPES = "PIPES",
  HEAT = "HEAT",
  PATH = "PATH",
}
