<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>
          <!-- Filtro de asociación -->
          <div
            *ngIf="!meterId && associationList?.length > 0"
            class="alarms-custom-config-association"
          >
            <app-material-select
              *ngIf="associationList"
              class="material-header-select"
              [fullWidth]="true"
              [title]="'association-custom-alarms' | translate"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [selected]="preselection"
              [data]="associationList"
              (selectedOption)="
                selectedAssociation = $event?.id; updateAlarms()
              "
            ></app-material-select>
          </div>

          <!-- Botón guardar -->
          <app-material-panel-menu
            [onlyButton]="true"
            [icon]="'fas fa-save'"
            [title]="'save' | translate"
            (action)="saveCustomAlarms()"
          >
          </app-material-panel-menu>
        </div>
      </div>
      <app-alarms-custom-panel
        *ngIf="meterId || associationList"
        [associationList]="associationList"
        [selectedAssociation]="selectedAssociation"
        [meterId]="meterId"
      ></app-alarms-custom-panel>
    </div>
  </div>
</section>
