import { TableQuickFilter } from './../../../../../modules/table-module/TableInterface.type';
import { CupsControllerService } from './../../../../../services/server/CupsController.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CUPS_ORDER, METER_ORDER, METER_ORDER_STATE, MeterOrder } from '../../../devices/DeviceInterface.type';
import { PROFILES } from '../../../../../../assets/profiles/profiles';
import { TableActionColumn, TableDataColumn, TableGlobalAction, TableSelectColumn } from '../../../../../modules/table-module/TableInterface.type';
import { EntityDefinition } from '../../../../../interfaces/CupsGlobalInterface.type';
import { TranslateService } from '@ngx-translate/core';
import { MapControllerComponent } from '../../../../../modules/map-module/map-controller/map-controller.component';
import { MaterialSelectOption } from '../../../../../modules/material-module/MaterialInterface.type';
import { Agrupation } from '../../../../../interfaces/AgrupationGlobalInterface.type';
import { Subscription } from 'rxjs';
import { Entity } from '../../../../../interfaces/EntityGlobalInterface.type';
import { DateParserService } from '../../../../../services/shared/DateParserService.service';
import { MeterService } from '../../../devices/meter/MeterService.service';
import { ReloadComponentService } from '../../../../../services/shared/ReloadComponentService.service';
import { SessionDataService } from '../../../../../services/shared/SessionDataService.service';
import { MetrologicalOrder } from '../../DataManagementInterface.type';

@Component({
  selector: 'app-data-management-cups-orders',
  templateUrl: './data-management-cups-orders.component.html',
  styleUrls: ['./data-management-cups-orders.component.css']
})
export class DataManagementCupsOrdersComponent implements OnInit {

 /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  sessionProfile: string;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  readonly PROFILES = PROFILES;

  // Table
  from: string;
    to: string;
    dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
      this.DateParserService.getLastDays("30");
  searchBy: string = "agrupation";
  tableData: MetrologicalOrder[];
  tableSelectedData: MetrologicalOrder[];
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("meter-orders") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  tableQuickFilters:TableQuickFilter[][];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "cancel-selected-data",
      icon: "fas fa-eraser",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
    {
      title: "remove-selected-data",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
  ];
  extraColumns: EntityDefinition[];

  // Select
  orderSelect: MaterialSelectOption[];

  // Mapa
  @ViewChild(MapControllerComponent) MapController: MapControllerComponent;
  mapType: string = "meterList";
  mapHeight: number = 500;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService,
    private CupsControllerService: CupsControllerService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.getCupsConf();

    // Escucha de cambios en entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.getCupsConf();
          this.setColumns();
        }
      );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData( this.dataInitialDate.startDate.valueOf().toString(),
    this.dataInitialDate.endDate.valueOf().toString());
    this.setQuickFilters();
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
  }


  // Obtención de los datos
  getData(from:any, to:any): void {
    let url = this.CupsControllerService.getCupsEntityOrders(this.currentEntity.id, from, to)
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let metrologicalOrders: MetrologicalOrder[] = response["body"];
        metrologicalOrders?.forEach((metrologicalOrder: MetrologicalOrder) => {
          metrologicalOrder.cupsLink = "/cups/detalle/" + metrologicalOrder.claveMaestra;

          metrologicalOrder.typeParsed = CUPS_ORDER[metrologicalOrder.type]
            ? this.translate.instant(CUPS_ORDER[metrologicalOrder.type])
            : metrologicalOrder.type;

          metrologicalOrder.stateParsed = METER_ORDER_STATE[metrologicalOrder.state]
            ? this.translate.instant(METER_ORDER_STATE[metrologicalOrder.state])
            : null;
          metrologicalOrder.cancelDisabled =
            (this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.ADMIN_AGRUPACION) ||
            metrologicalOrder.closeDate
              ? true
              : false;
        });
        this.tableData = metrologicalOrders;
        this.setColumns();
      }
    });
  }
  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "cancel",
            tooltip: "cancel",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: "cancelDisabled",
            warning: true,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.ADMIN_AGRUPACION,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupationOTDesc",
        search: "agrupationOTDesc",
        sort: "agrupationOTDesc",
        visible:true,
      },
      {
        title: this.cups?.name,
        data: "clave",
        search: "createClave",
        sort: "createClave",
        visible: this.cups != null ? true : null,
        link: "cupsLink",
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
      {
        title: "creation-date",
        data: "createDate",
        search: "createDate",
        sort: "createDate",
        visible: true,
        date: true,
      },
      {
        title: "creation-user",
        data: "createUsername",
        search: "createUsername",
        sort: "createUsername",
        visible: true,
      },
      {
        title: "close-date",
        data: "closeDate",
        search: "closeDate",
        sort: "closeDate",
        visible: true,
        date: true,
      },
      {
        title: "close-user",
        data: "closeUsername",
        search: "closeUsername",
        sort: "closeUsername",
        visible: true,
      },
      {
        title: "type",
        data: "typeParsed",
        search: "typeParsed",
        sort: "typeParsed",
        visible: true,
      },
      {
        title: "comments",
        data: "comment",
        search: "comment",
        sort: "comment",
        visible: true,
        long: true,
      },
    ];
    this.columns = columns;
  }

  setQuickFilters(){
    this.tableQuickFilters = [
      [
        //FILTRO POR PENDIENTE
        {
          name: this.translate.instant(METER_ORDER_STATE[1]),
          columnSearch: "state",
          condition: { type: "number", rule: METER_ORDER_STATE.PENDING},
          active: history.state.data?.includes("ACTIVE"),
        },
      ],
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, meterOrder: MetrologicalOrder): void {
    switch (action) {
      case "delete":
        this.MeterService.deleteMeterOrder([meterOrder.id]);
        break;
      case "cancel":
        this.MeterService.cancelMeterOrder([meterOrder.id]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "remove-selected-data":
        this.MeterService.deleteMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      case "cancel-selected-data":
        this.MeterService.cancelMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      default:
        break;
    }
  }
}
