<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "images-import" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
          </h4>
          <h5 class="readings-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="processFile($event)"
          ></app-file-import>
        </div>

        <app-import-table
          [importColumns]="importColumns"
          [importPreselects]="null"
          (tableActionFlag)="(null)"
        >
        </app-import-table>

        <hr />

        <div>
          <h4>
            <b>{{ "images-import-files" | translate }}</b>
          </h4>
          <h5 class="readings-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "images-import-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [multiple]="true"
            [fileType]="'.jpg, .png'"
            (fileReady)="processImageFiles($event)"
          ></app-file-import>
        </div>

        <div *ngIf="fileData || imagesFiles" class="readings-import-response">
          <div>
            <b
              >{{
                (imagesFiles ? "image-total" : "readings-total-rows")
                  | translate
              }}:</b
            ><br />
            <span>{{ importResponse.total }}</span>
          </div>
          <div>
            <b
              >{{
                (imagesFiles ? "image-total-susccess" : "readings-rows-success")
                  | translate
              }}:</b
            ><br />
            <span>{{
              importResponse.success ? importResponse.success : 0
            }}</span>
          </div>
          <div>
            <b
              >{{
                (imagesFiles ? "image-total-error" : "readings-rows-error")
                  | translate
              }}:</b
            ><br />
            <span>{{ importResponse.fail ? importResponse.fail : 0 }}</span>
          </div>
        </div>

        <div *ngIf="errors?.length > 0" class="images-import-errors">
          <button mat-button class="mat-button-icon-text" (click)="retry()">
            <i class="fas fa-redo-alt"></i>
            {{ "retry-failed" | translate }}
          </button>
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="downloadErrors()"
          >
            <i class="fas fa-file-download"></i>
            {{ "download-file-error" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
