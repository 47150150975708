import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DhielDecoderService {
  constructor() {}

  tipoDeltas = {
    deltasMedio: 0,
    deltasMaximo: 1,
    deltasMinimo: 2,
  };

  METERKEYS = [0, 1, 1, 1, 1, 1, 10, 1, 10, 100, 1000, 100];

  LRWP = [20, 14, 11, 8, 5, 2];
  LRDR = [
    "LoRa SF12/125KHz",
    "LoRa SF11/125KHz",
    "LoRa SF10/125KHz",
    "LoRa SF9/125KHz",
    "LoRa SF8/125KHz",
    "LoRa SF7/125KHz",
    "LoRa SF7/250KHz",
    "FSK 50kbs",
  ];
  BUPW = [0, 7, 13, 14, 17];
  FLOW_PERSISTENCE_CODING = [
    "No persistence",
    "0 min < Duration < 5 min",
    "5 min < Duration < 15 min",
    "15 min < Duration < 60 min",
    "60 min < Duration < 3 h",
    "3 h < Duration < 6 h",
    "6 h < Duration < 12 h",
    "12 h < Duration < 24 h",
    "24 h < Duration < 2 days",
    "2 days < Duration < 4 days",
    "4 days < Duration < 8 daysn",
    "8 days < Duration < 15 days",
    "15 days < Duration < 30 days",
    "30 days < Duration < 90 days",
    "90 days < Duration < 180 days",
    "Duration > 180 days",
  ];
  NET_TYPE = ["public", "private", "hybrid", "NOT VALID"];
  hora;
  fechaTrama;

  diehl_decoder(frmPayload, fecha) {
    if (frmPayload && fecha) {
      if (frmPayload.bytes) {
        if (frmPayload.bytes.length < 52) {
          var outputData = {};
          var outputErrors = [];
          var index = 0;
          var date = fecha.split(/\D/);
          this.hora = date[3];
          this.fechaTrama = new Date(
            date[2],
            date[1] - 1,
            date[0],
            date[3],
            date[4],
            date[5]
          ); /* Los valores son desde la hora en punto anterior a la trama*/
          switch (frmPayload.bytes[0] & 0x0f /*Tipo de trama*/) {
            case 0x01 /* DS51_11 */:
              return this.DS51_11_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x02 /* DS51_12 */:
              return this.DS51_12_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x03 /* DS51_13 */:
              return this.DS51_13_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x04 /* DS51_14 */:
              return this.DS51_14_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x05 /* DS51_15 */:
              return this.DS51_15_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x06 /* DS51_16 */:
              return this.DS51_16_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x07 /* DS51_17 */:
              return this.DS51_17_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x08 /* DS51_18 */:
              return this.DS51_18_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x0a /* DS51_A */:
              return this.DS51_A_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x0b /* DS51_2 */:
              return this.DS51_2_decoder(frmPayload.bytes, this.fechaTrama);
            case 0x00 /* DS51_0E */:
              return this.DS51_0E_decoder(frmPayload.bytes, this.fechaTrama);
            default:
              return {
                errors: [
                  "Tipo de trama no definido: 0x" +
                    (frmPayload.bytes[0] & 0x0f).toString(16).padStart(1, "0"),
                ],
              };
          }
        } else {
          return {
            errors: ["Tamaño del FRMPayload excesivo."],
          };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /*Conversino de IIP24 a Deltas*/
  IIP24aDeltas(DS51_VIPP_24, DS51_VIPN_24, DS51_IIP_24, deltasTipo, pesoPulso) {
    const tablaEC2 = {
      0: [
        /*Medio*/ 0,
        0.0025,
        0.0051,
        0.005304,
        0.00551616,
        0.005736806,
        0.005966279,
        0.00620493,
        0.006453127,
        0.006711252,
        0.006979702,
        0.00725889,
        0.007549246,
        0.007851216,
        0.008165264,
        0.008491875,
        0.00883155,
        0.009184812,
        0.009552204,
        0.009934293,
        0.010331664,
        0.010744931,
        0.011174728,
        0.011621717,
        0.012086586,
        0.012570049,
        0.013072851,
        0.013595765,
        0.014139596,
        0.01470518,
        0.015293387,
        0.015905122,
        0.016541327,
        0.01720298,
        0.0178911,
        0.018606744,
        0.019351013,
        0.020125054,
        0.020930056,
        0.021767258,
        0.022637949,
        0.023543467,
        0.024485205,
        0.025464613,
        0.026483198,
        0.027542526,
        0.028644227,
        0.029789996,
        0.030981596,
        0.03222086,
        0.033509694,
        0.034850082,
        0.036244085,
        0.037693848,
        0.039201602,
        0.040769667,
        0.042400453,
        0.044096471,
        0.04586033,
        0.047694743,
        0.049602533,
        0.051586634,
        0.0536501,
        0.055796104,
        0.058027948,
        0.060349066,
        0.062763028,
        0.06527355,
        0.067884492,
        0.070599871,
        0.073423866,
        0.076360821,
        0.079415254,
        0.082591864,
        0.085895538,
        0.08933136,
        0.092904614,
        0.096620799,
        0.100485631,
        0.104505056,
        0.108685258,
        0.113032669,
        0.117553975,
        0.122256134,
        0.12714638,
        0.132232235,
        0.137521524,
        0.143022385,
        0.148743281,
        0.154693012,
        0.160880732,
        0.167315962,
        0.1740086,
        0.180968944,
        0.188207702,
        0.19573601,
        0.20356545,
        0.211708068,
        0.220176391,
        0.228983447,
        0.238142785,
        0.247668496,
        0.257575236,
        0.267878245,
        0.278593375,
        0.28973711,
        0.301326594,
        0.313379658,
        0.325914844,
        0.338951438,
        0.352509496,
        0.366609876,
        0.381274271,
        0.396525241,
        0.412386251,
        0.428881701,
        0.446036969,
        0.463878448,
        0.482433586,
        0.501730929,
        0.521800167,
        0.542672173,
        0.56437906,
        0.586954223,
        0.610432391,
        0.634849687,
        0.660243675,
        0.686653422,
        0.714119558,
        0.742684341,
        0.772391714,
        0.803287383,
        0.835418878,
        0.868835633,
        0.903589059,
        0.939732621,
        0.977321926,
        1.016414803,
        1.057071395,
        1.099354251,
        1.143328421,
        1.189061558,
        1.23662402,
        1.286088981,
        1.33753254,
        1.391033842,
        1.446675195,
        1.504542203,
        1.564723891,
        1.627312847,
        1.692405361,
        1.760101575,
        1.830505638,
        1.903725864,
        1.979874898,
        2.059069894,
        2.14143269,
        2.227089998,
        2.316173598,
        2.408820541,
        2.505173363,
        2.605380298,
        2.70959551,
        2.81797933,
        2.930698503,
        3.047926443,
        3.169843501,
        3.296637241,
        3.428502731,
        3.56564284,
        3.708268553,
        3.856599296,
        4.010863267,
        4.171297798,
        4.33814971,
        4.511675698,
        4.692142726,
        4.879828435,
        5.075021573,
        5.278022436,
        5.489143333,
        5.708709067,
        5.937057429,
        6.174539726,
        6.421521315,
        6.678382168,
        6.945517455,
        7.223338153,
        7.512271679,
        7.812762546,
        8.125273048,
        8.45028397,
        8.788295329,
        9.139827142,
        9.505420228,
        9.885637037,
        10.281062518,
        10.692305019,
        11.11999722,
        11.564797108,
        12.027388993,
        12.508484552,
        13.008823935,
        13.529176892,
        14.070343968,
        14.633157726,
        15.218484035,
        15.827223397,
        16.460312333,
        17.118724826,
        17.803473819,
        18.515612772,
        19.256237283,
        20.026486774,
        20.827546245,
        21.660648095,
        22.527074019,
        23.428156979,
        24.365283258,
        25.339894589,
        26.353490372,
        27.407629987,
        28.503935187,
        29.644092594,
        30.829856298,
        32.06305055,
        33.345572572,
        34.679395475,
        36.066571294,
        37.509234145,
        39.009603511,
        40.569987652,
        42.192787158,
        43.880498644,
        45.63571859,
        47.461147334,
        49.195879033,
        51,
        -0.25,
        -0.75,
        -1.5,
        -2.5,
        -3.5,
        -4.5,
        -5.5,
        -7.5,
        -10.5,
        -13.5 - 17.5,
        -22.5,
        -27.5,
        -32.5,
        -37.5,
        -42.5,
        -47.5,
        -51,
      ],
      1: [
        /*Máximo*/ 0, 0.005, 0.0052, 0.005408, 0.00562432, 0.0058492928,
        0.006083264512, 0.00632659509248, 0.0065796588961792,
        0.00684284525202637, 0.00711655906210742, 0.00740122142459172,
        0.00769727028157539, 0.00800516109283841, 0.00832536753655194,
        0.00865838223801402, 0.00900471752753458, 0.00936490622863597,
        0.00973950247778141, 0.0101290825768927, 0.0105342458799684,
        0.0109556157151671, 0.0113938403437738, 0.0118495939575247,
        0.0123235777158257, 0.0128165208244588, 0.0133291816574371,
        0.0138623489237346, 0.014416842880684, 0.0149935165959113,
        0.0155932572597478, 0.0162169875501377, 0.0168656670521432,
        0.0175402937342289, 0.0182419054835981, 0.018971581702942,
        0.0197304449710597, 0.0205196627699021, 0.0213404492806982,
        0.0221940672519261, 0.0230818299420032, 0.0240051031396833,
        0.0249653072652706, 0.0259639195558814, 0.0270024763381167,
        0.0280825753916414, 0.029205878407307, 0.0303741135435993,
        0.0315890780853433, 0.032852641208757, 0.0341667468571073,
        0.0355334167313916, 0.0369547534006473, 0.0384329435366731,
        0.0399702612781401, 0.0415690717292657, 0.0432318345984363,
        0.0449611079823738, 0.0467595523016687, 0.0486299343937355,
        0.0505751317694849, 0.0525981370402643, 0.0547020625218748,
        0.0568901450227498, 0.0591657508236598, 0.0615323808566062,
        0.0639936760908705, 0.0665534231345053, 0.0692155600598855,
        0.0719841824622809, 0.0748635497607722, 0.0778580917512031,
        0.0809724154212512, 0.0842113120381012, 0.0875797645196253,
        0.0910829551004103, 0.0947262733044267, 0.0985153242366038,
        0.102455937206068, 0.106554174694311, 0.110816341682083,
        0.115248995349366, 0.119858955163341, 0.124653313369875,
        0.12963944590467, 0.134825023740857, 0.140218024690491,
        0.14582674567811, 0.151659815505235, 0.157726208125444,
        0.164035256450462, 0.170596666708481, 0.17742053337682,
        0.184517354711893, 0.191898048900368, 0.199573970856383,
        0.207556929690638, 0.215859206878264, 0.224493575153394,
        0.23347331815953, 0.242812250885911, 0.252524740921348,
        0.262625730558202, 0.27313075978053, 0.284055990171751,
        0.295418229778621, 0.307234958969766, 0.319524357328557,
        0.332305331621699, 0.345597544886567, 0.35942144668203,
        0.373798304549311, 0.388750236731283, 0.404300246200535,
        0.420472256048556, 0.437291146290498, 0.454782792142118,
        0.472974103827803, 0.491893067980915, 0.511568790700152,
        0.532031542328158, 0.553312804021284, 0.575445316182135,
        0.598463128829421, 0.622401653982598, 0.647297720141902,
        0.673189628947578, 0.700117214105481, 0.7281219026697,
        0.757246778776488, 0.787536649927548, 0.819038115924649,
        0.851799640561635, 0.885871626184101, 0.921306491231465,
        0.958158750880724, 0.996485100915953, 1.03634450495259,
        1.07779828515069, 1.12091021655672, 1.16574662521899, 1.21237649022775,
        1.26087154983686, 1.31130641183034, 1.36375866830355, 1.41830901503569,
        1.47504137563712, 1.5340430306626, 1.59540475188911, 1.65922094196467,
        1.72558977964326, 1.79461337082899, 1.86639790566215, 1.94105382188863,
        2.01869597476418, 2.09944381375475, 2.18342156630494, 2.27075842895714,
        2.36158876611542, 2.45605231676004, 2.55429440943044, 2.65646618580766,
        2.76272483323996, 2.87323382656956, 2.98816317963235, 3.10768970681764,
        3.23199729509034, 3.36127718689396, 3.49572827436972, 3.63555740534451,
        3.78097970155829, 3.93221888962062, 4.08950764520544, 4.25308795101366,
        4.42321146905421, 4.60013992781638, 4.78414552492903, 4.97551134592619,
        5.17453179976324, 5.38151307175377, 5.59677359462392, 5.82064453840888,
        6.05347031994524, 6.29560913274304, 6.54743349805277, 6.80933083797488,
        7.08170407149387, 7.36497223435363, 7.65957112372777, 7.96595396867689,
        8.28459212742396, 8.61597581252092, 8.96061484502176, 9.31903943882263,
        9.69180101637553, 10.0794730570306, 10.4826519793118, 10.9019580584842,
        11.3380363808236, 11.7915578360566, 12.2632201494988, 12.7537489554788,
        13.2638989136979, 13.7944548702459, 14.3462330650557, 14.9200823876579,
        15.5168856831642, 16.1375611104908, 16.7830635549104, 17.4543860971069,
        18.1525615409911, 18.8786640026308, 19.633810562736, 20.4191629852454,
        21.2359295046553, 22.0853666848415, 22.9687813522351, 23.8875326063245,
        24.8430339105775, 25.8367552670006, 26.8702254776806, 27.9450344967879,
        29.0628358766594, 30.2253493117258, 31.4343632841948, 32.6917378155626,
        33.9994073281851, 35.3593836213125, 36.773758966165, 38.2447093248116,
        39.7744976978041, 41.3654776057162, 43.0200967099449, 44.7409005783427,
        46.5305366014764, 48.3917580655354, 50, 51, -0.5, -1, -2, -3, -4, -5,
        -6, -9, -12, -15, -20, -25, -30, -35, -40, -45, -50, -51,
      ],
      2: [
        /*Mínimo*/ 0, 0, 0.005, 0.0052, 0.005408, 0.00562432, 0.0058492928,
        0.006083264512, 0.00632659509248, 0.0065796588961792,
        0.00684284525202637, 0.00711655906210742, 0.00740122142459172,
        0.00769727028157539, 0.00800516109283841, 0.00832536753655194,
        0.00865838223801402, 0.00900471752753458, 0.00936490622863597,
        0.00973950247778141, 0.0101290825768927, 0.0105342458799684,
        0.0109556157151671, 0.0113938403437738, 0.0118495939575247,
        0.0123235777158257, 0.0128165208244588, 0.0133291816574371,
        0.0138623489237346, 0.014416842880684, 0.0149935165959113,
        0.0155932572597478, 0.0162169875501377, 0.0168656670521432,
        0.0175402937342289, 0.0182419054835981, 0.018971581702942,
        0.0197304449710597, 0.0205196627699021, 0.0213404492806982,
        0.0221940672519261, 0.0230818299420032, 0.0240051031396833,
        0.0249653072652706, 0.0259639195558814, 0.0270024763381167,
        0.0280825753916414, 0.029205878407307, 0.0303741135435993,
        0.0315890780853433, 0.032852641208757, 0.0341667468571073,
        0.0355334167313916, 0.0369547534006473, 0.0384329435366731,
        0.0399702612781401, 0.0415690717292657, 0.0432318345984363,
        0.0449611079823738, 0.0467595523016687, 0.0486299343937355,
        0.0505751317694849, 0.0525981370402643, 0.0547020625218748,
        0.0568901450227498, 0.0591657508236598, 0.0615323808566062,
        0.0639936760908705, 0.0665534231345053, 0.0692155600598855,
        0.0719841824622809, 0.0748635497607722, 0.0778580917512031,
        0.0809724154212512, 0.0842113120381012, 0.0875797645196253,
        0.0910829551004103, 0.0947262733044267, 0.0985153242366038,
        0.102455937206068, 0.106554174694311, 0.110816341682083,
        0.115248995349366, 0.119858955163341, 0.124653313369875,
        0.12963944590467, 0.134825023740857, 0.140218024690491,
        0.14582674567811, 0.151659815505235, 0.157726208125444,
        0.164035256450462, 0.170596666708481, 0.17742053337682,
        0.184517354711893, 0.191898048900368, 0.199573970856383,
        0.207556929690638, 0.215859206878264, 0.224493575153394,
        0.23347331815953, 0.242812250885911, 0.252524740921348,
        0.262625730558202, 0.27313075978053, 0.284055990171751,
        0.295418229778621, 0.307234958969766, 0.319524357328557,
        0.332305331621699, 0.345597544886567, 0.35942144668203,
        0.373798304549311, 0.388750236731283, 0.404300246200535,
        0.420472256048556, 0.437291146290498, 0.454782792142118,
        0.472974103827803, 0.491893067980915, 0.511568790700152,
        0.532031542328158, 0.553312804021284, 0.575445316182135,
        0.598463128829421, 0.622401653982598, 0.647297720141902,
        0.673189628947578, 0.700117214105481, 0.7281219026697,
        0.757246778776488, 0.787536649927548, 0.819038115924649,
        0.851799640561635, 0.885871626184101, 0.921306491231465,
        0.958158750880724, 0.996485100915953, 1.03634450495259,
        1.07779828515069, 1.12091021655672, 1.16574662521899, 1.21237649022775,
        1.26087154983686, 1.31130641183034, 1.36375866830355, 1.41830901503569,
        1.47504137563712, 1.5340430306626, 1.59540475188911, 1.65922094196467,
        1.72558977964326, 1.79461337082899, 1.86639790566215, 1.94105382188863,
        2.01869597476418, 2.09944381375475, 2.18342156630494, 2.27075842895714,
        2.36158876611542, 2.45605231676004, 2.55429440943044, 2.65646618580766,
        2.76272483323996, 2.87323382656956, 2.98816317963235, 3.10768970681764,
        3.23199729509034, 3.36127718689396, 3.49572827436972, 3.63555740534451,
        3.78097970155829, 3.93221888962062, 4.08950764520544, 4.25308795101366,
        4.42321146905421, 4.60013992781638, 4.78414552492903, 4.97551134592619,
        5.17453179976324, 5.38151307175377, 5.59677359462392, 5.82064453840888,
        6.05347031994524, 6.29560913274304, 6.54743349805277, 6.80933083797488,
        7.08170407149387, 7.36497223435363, 7.65957112372777, 7.96595396867689,
        8.28459212742396, 8.61597581252092, 8.96061484502176, 9.31903943882263,
        9.69180101637553, 10.0794730570306, 10.4826519793118, 10.9019580584842,
        11.3380363808236, 11.7915578360566, 12.2632201494988, 12.7537489554788,
        13.2638989136979, 13.7944548702459, 14.3462330650557, 14.9200823876579,
        15.5168856831642, 16.1375611104908, 16.7830635549104, 17.4543860971069,
        18.1525615409911, 18.8786640026308, 19.633810562736, 20.4191629852454,
        21.2359295046553, 22.0853666848415, 22.9687813522351, 23.8875326063245,
        24.8430339105775, 25.8367552670006, 26.8702254776806, 27.9450344967879,
        29.0628358766594, 30.2253493117258, 31.4343632841948, 32.6917378155626,
        33.9994073281851, 35.3593836213125, 36.773758966165, 38.2447093248116,
        39.7744976978041, 41.3654776057162, 43.0200967099449, 44.7409005783427,
        46.5305366014764, 48.3917580655354, 51, -0, -0.5, -1, -2, -3, -4, -5,
        -6, -9, -12, -15, -20, -25, -30, -35, -40, -45, -51,
      ],
    };
    var indiceRestoP = -1;
    var indiceRestoN = -1;
    var indiceMaxDeltaP = -1;
    var indiceMaxDeltaN = -1;
    var AcumP = 0;
    var AcumN = 0;
    var maxDeltaPos = 0;
    var maxDeltaNeg = 0;
    var deltas = [];

    /* 
        Al máximo hay que asignarle el complemento a 100 (o al que esté marcado como tal)
        En caso de que no hay un delta igual al complemento a 100, este complemento se aplica al mayor delta.
        En caso de varios iguales se aplica al intervalo más reciente
        */
    for (let i = 0; i < 24; i++) {
      if (tablaEC2[deltasTipo][DS51_IIP_24[i]] == 51) {
        indiceRestoP =
          i; /* Hora cuyo valor es el complemento a 100 del resto del consumo positivo*/
      } else if (tablaEC2[deltasTipo][DS51_IIP_24[i]] == -51) {
        indiceRestoN =
          i; /* Hora cuyo valor es el complemento a 100 del resto del consumo negativo*/
      } else {
        if (indiceRestoP == -1) {
          if (tablaEC2[deltasTipo][DS51_IIP_24[i]] >= maxDeltaPos) {
            maxDeltaPos = tablaEC2[deltasTipo][DS51_IIP_24[i]];
            indiceMaxDeltaP = i;
          }
        }
        if (indiceRestoN == -1) {
          if (tablaEC2[deltasTipo][DS51_IIP_24[i]] <= maxDeltaNeg) {
            maxDeltaNeg = tablaEC2[deltasTipo][DS51_IIP_24[i]];
            indiceMaxDeltaN = i;
          }
        }
      }
    }
    indiceRestoP = indiceRestoP == -1 ? indiceMaxDeltaP : indiceRestoP;
    indiceRestoN = indiceRestoN == -1 ? indiceMaxDeltaN : indiceRestoN;

    for (let i = 0; i < 24; i++) {
      if (i != indiceRestoP && i != indiceRestoN) {
        if (tablaEC2[deltasTipo][DS51_IIP_24[i]] >= 0) {
          deltas[i] = Math.floor(
            (tablaEC2[deltasTipo][DS51_IIP_24[i]] * DS51_VIPP_24) / 100
          );
          AcumP += deltas[i];
          deltas[i] *= pesoPulso;
        } else {
          deltas[i] = Math.floor(
            (tablaEC2[deltasTipo][DS51_IIP_24[i]] * DS51_VIPN_24) / 100
          );
          AcumN -= deltas[i];
          deltas[i] *= pesoPulso;
        }
      }
    }
    if (indiceRestoP != -1) {
      deltas[indiceRestoP] = (DS51_VIPP_24 - AcumP) * pesoPulso;
    }
    if (indiceRestoN != -1) {
      deltas[indiceRestoN] = (0 - (DS51_VIPN_24 - AcumN)) * pesoPulso;
    }
    return deltas;
  }

  /*Conversión de deltas a valores*/
  deltasAValores(
    valor00h,
    hora,
    deltasMedio,
    deltasMaximo,
    deltasMinimo,
    deltasTipo
  ) {
    var valores = [];
    var index00 = 24 - hora;

    valores[index00] = valor00h;

    // Valores posteriores a las 00
    for (let i = index00 + 1; i <= 24; i++) {
      switch (deltasTipo) {
        case this.tipoDeltas.deltasMedio:
          valores[i] = valores[i - 1] + deltasMedio[i - 1];
          break;
        case this.tipoDeltas.deltasMaximo:
          valores[i] = valores[i - 1] + deltasMaximo[i - 1];
          break;
        case this.tipoDeltas.deltasMinimo:
          valores[i] = valores[i - 1] + deltasMinimo[i - 1];
          break;
      }
    }
    // Valores anteriores a las 00
    for (let i = index00 - 1; i >= 0; i--) {
      switch (deltasTipo) {
        case this.tipoDeltas.deltasMedio:
          valores[i] = valores[i + 1] - deltasMedio[i];
          break;
        case this.tipoDeltas.deltasMaximo:
          valores[i] = valores[i + 1] - deltasMinimo[i];
          break;
        case this.tipoDeltas.deltasMinimo:
          valores[i] = valores[i + 1] - deltasMaximo[i];
          break;
      }
    }
    return valores;
  }

  /*Parseo de las microalarmas*/
  DS51_MIAL_parse(MIAL, MAAL) {
    var microalarmas = {};
    if (MAAL & 0x02) {
      /*Meterology alarms*/
      if (MIAL[0] & 0x01) {
        microalarmas["b0"] = "Blocked meter";
      }
      if (MIAL[0] & 0x40) {
        microalarmas["b6"] = "Small size";
      }
      if (MIAL[0] & 0x80) {
        microalarmas["b7"] = "Large size";
      }
    }
    if (MAAL & 0x04) {
      /*System alarms*/
      if (MIAL[1] & 0x80) {
        microalarmas["b15"] = "Battery";
      }
      if (MIAL[2] & 0x01) {
        microalarmas["b15"] = "Clock updated";
      }
      if (MIAL[2] & 0x08) {
        microalarmas["b19"] = "Module reconfigured";
      }
      if (MIAL[2] & 0x40) {
        microalarmas["b22"] = "Noise defense: radio reception suspended";
      }
      if (MIAL[3] & 0x01) {
        microalarmas["b24"] = "Low temepratura (radio reception suspension)";
      }
      if (MIAL[3] & 0x02) {
        microalarmas["b25"] = "Number of alarm cycle authorized reached";
      }
    }
    if (MAAL & 0x08) {
      /*Tamper alarms*/
      if (MIAL[3] & 0x08) {
        microalarmas["b27"] = "Reversed meter";
      }
      if (MIAL[3] & 0x20) {
        microalarmas["b29"] = "Module tampered";
      }
      if (MIAL[2] & 0x40) {
        microalarmas["b30"] = "Acquisition stage failure";
      }
    }
    if (MAAL & 0x10) {
      /*Water quality*/
      if (MIAL[4] & 0x01) {
        microalarmas["b32"] = "Backflow";
      }
    }
    return microalarmas;
  }

  /*Parseo de las macroalarmas*/
  DS51_MAAL_parse(MAAL) {
    var macroalarmas = {};
    if (MAAL & 0x01) {
      macroalarmas["b0"] = "In progress alarm";
    }
    if (MAAL & 0x02) {
      macroalarmas["b1"] = "Metrology";
    }
    if (MAAL & 0x04) {
      macroalarmas["b2"] = "System";
    }
    if (MAAL & 0x08) {
      macroalarmas["b3"] = "Tamper";
    }
    if (MAAL & 0x10) {
      macroalarmas["b4"] = "Water Quality";
    }
    if (MAAL & 0x80) {
      macroalarmas["b7"] = "Stop/Leaks";
    }
    switch ((MAAL >> 5) & 0x03) {
      case 1:
        macroalarmas["Flow persistence"] =
          "Past flow persistence during the period";
        break;
      case 2:
        macroalarmas["Flow persistence"] = "In progress flow persistence";
        break;
      case 3:
        macroalarmas["Flow persistence"] =
          "In progress impacting flow persistence";
        break;
    }
    return macroalarmas;
  }
  /*Devuelve el valor correspondiente a un códugo EC1*/
  /* -1: overflow; -1: anomaly*/
  RestituyeEC1(EC1Code) {
    var valor = 0;

    const EC1 = [
      /*CodeIni,CodeMax,ValIni,ValStep*/
      [0, 10, 0, 1],
      [11, 20, 12, 2],
      [21, 54, 35, 5],
      [55, 74, 210, 10],
      [75, 94, 420, 20],
      [95, 114, 840, 40],
      [115, 134, 1680, 80],
      [135, 154, 3360, 160],
      [155, 174, 6720, 320],
      [175, 194, 13440, 640],
      [195, 214, 26880, 1280],
      [215, 234, 53760, 2560],
      [235, 253, 107520, 5420],
    ];

    if (EC1Code == 254) {
      valor = -1;
    } else if (EC1Code == 255) {
      valor = -2;
    } else {
      for (let i = 0; i < 13; i++) {
        if (EC1Code <= EC1[i][1]) {
          valor = EC1[i][2] + EC1[i][3] * (EC1Code - EC1[i][0]);
          break;
        }
      }
    }
    return valor;
  }

  /*Parte comun de las tramas DS51_1X -> lo devuelve en un objeto*/
  DS51_1X_comun(bytes) {
    var objeto = {};
    var meterKey = (bytes[0] >> 4) & 0x0f;
    var DS51_MIAL = bytes.slice(16, 16 + 5);
    DS51_MIAL[4] &= 0x30;
    objeto["DS51_MIAL"] = DS51_MIAL;
    objeto["DS51_VIPP_24"] =
      this.readU16Lsb(bytes, 21) + ((bytes[23] & 0x0f) << 16);
    objeto["DS51_VIPN_24"] =
      this.readU16Lsb(bytes, 24) + ((bytes[23] & 0xf0) << 12);
    objeto["DS51_IIP_24"] = bytes.slice(26, 26 + 24);
    objeto["DS51_HOR_RES"] = bytes[50] + ((bytes[20] & 0xc0) << 2);
    objeto["pesoPulso"] = this.METERKEYS[meterKey];
    return objeto;
  }

  /*Objetos comunes*/
  objetosComunes(ds51_1x_obj, LR_MAAL) {
    return {
      DS51_HOR:
        this.hora.toString().padStart(2, "0") +
        ":" +
        ((ds51_1x_obj.DS51_HOR_RES & 0x1f) * 2).toString().padStart(2, "0"),
      Fecha_Trama: this.fechaTrama.toLocaleString("es-ES"),
      Meter_Key:
        ds51_1x_obj.pesoPulso.toLocaleString("es-ES") + " litros/pulso",
      MacroAlarmas: this.DS51_MAAL_parse(LR_MAAL),
      MicroAlarmas: this.DS51_MIAL_parse(ds51_1x_obj.DS51_MIAL, LR_MAAL),
    };
  }

  /*Objetos de deltas y valores*/
  objetosConsumosYValores(
    VIPP_24,
    VIPN_24,
    IIP_24,
    valor00h,
    hora,
    pesoPulso,
    fechaTrama
  ) {
    var objeto = {
      Valor_00h: valor00h.toLocaleString("es-ES") + " litros",
      DS51_VIPP_24: (VIPP_24 * pesoPulso).toLocaleString("es-ES") + " litros",
      DS51_VIPN_24: (VIPN_24 * pesoPulso).toLocaleString("es-ES") + " litros",
    };
    var deltasMedio = this.IIP24aDeltas(
      VIPP_24,
      VIPN_24,
      IIP_24,
      this.tipoDeltas.deltasMedio,
      pesoPulso
    );
    var deltasMinimos = this.IIP24aDeltas(
      VIPP_24,
      VIPN_24,
      IIP_24,
      this.tipoDeltas.deltasMinimo,
      pesoPulso
    );
    var deltasMaximos = this.IIP24aDeltas(
      VIPP_24,
      VIPN_24,
      IIP_24,
      this.tipoDeltas.deltasMaximo,
      pesoPulso
    );
    objeto["Consumos"] = {};
    for (let i = 23; i >= 0; i--) {
      objeto["Consumos"]["-" + (24 - i).toString() + "h"] =
        deltasMedio[i].toLocaleString("es-ES") +
        " litros [ " +
        deltasMinimos[i].toLocaleString("es-ES") +
        " - " +
        deltasMaximos[i].toLocaleString("es-ES") +
        " ]";
    }
    var valoresMedio = this.deltasAValores(
      valor00h,
      hora,
      deltasMedio,
      deltasMaximos,
      deltasMinimos,
      this.tipoDeltas.deltasMedio
    );
    var valoresMaximo = this.deltasAValores(
      valor00h,
      hora,
      deltasMedio,
      deltasMaximos,
      deltasMinimos,
      this.tipoDeltas.deltasMaximo
    );
    var valoresMinimo = this.deltasAValores(
      valor00h,
      hora,
      deltasMedio,
      deltasMaximos,
      deltasMinimos,
      this.tipoDeltas.deltasMinimo
    );
    objeto["Valores"] = {};
    fechaTrama.setMinutes(0);
    fechaTrama.setSeconds(0);
    for (let i = 24; i >= 0; i--) {
      objeto["Valores"][fechaTrama.toLocaleString("es-ES")] =
        valoresMedio[i].toLocaleString("es-ES") +
        " litros [ " +
        valoresMinimo[i].toLocaleString("es-ES") +
        " - " +
        valoresMaximo[i].toLocaleString("es-ES") +
        " ]";
      fechaTrama = new Date(fechaTrama - 60 * 60 * 1000);
    }
    return objeto;
  }

  /* Parseado DS51_11*/
  DS51_11_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_11" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 4) +
      (bytes[3] << 12) +
      (bytes[4] << 20) +
      ((bytes[5] & 0xf0) << 24) +
      (bytes[15] & 0x0f);

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_12*/
  DS51_12_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_12" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 8) + (bytes[3] << 16) + (bytes[4] << 24) + bytes[15];

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var LR_MIAL = bytes.slice(5, 5 + 5);
    var LR_CFG1 = this.read16Lsb(bytes, 10);
    var LR_CFG2 = bytes[12];

    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData["MicroAlarmas_no_comunes"] = this.DS51_MIAL_parse(
      LR_MIAL,
      LR_MAAL
    );
    outputData["LR_CFG1"] = {};
    outputData["LR_CFG1"]["Index Offset configured during installation"] =
      (LR_CFG1 & 0x20) == 0x20;
    outputData["LR_CFG1"]["Summer/Winter time management enabled"] =
      (LR_CFG1 & 0x40) == 0x40;
    outputData["LR_CFG2"] = "0x" + LR_CFG2.toString(16).padStart(2, "0");
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_13*/
  DS51_13_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_13" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 8) + (bytes[3] << 16) + (bytes[4] << 24) + bytes[13];

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var LR_QMINS = bytes[5];
    var LR_QMAXS = bytes[6];
    var LR_DFQ = bytes[7];

    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData["Caudal_Minimo_Semana"] =
      (this.RestituyeEC1(LR_QMINS) * ds51_1x.pesoPulso).toLocaleString(
        "es-ES"
      ) + " litros/hora";
    outputData["Caudal_Maximo_Semana"] =
      (this.RestituyeEC1(LR_QMAXS) * ds51_1x.pesoPulso).toLocaleString(
        "es-ES"
      ) + " litros/hora";
    outputData["Flow_Persistence_Ayer"] =
      this.FLOW_PERSISTENCE_CODING[LR_DFQ & 0x0f];
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_14*/
  DS51_14_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_14" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 8) + (bytes[3] << 16) + (bytes[4] << 24) + bytes[9];

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var LR_QMAM = bytes[7];
    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData["Caudal_Maximo_Mes"] =
      (this.RestituyeEC1(LR_QMAM) * ds51_1x.pesoPulso).toLocaleString("es-ES") +
      " litros/hora";
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_15*/
  DS51_15_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_15" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 8) + (bytes[3] << 16) + (bytes[4] << 24) + bytes[15];

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_16*/
  DS51_16_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_16" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ = this.readU32Lsb(bytes, 2);

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var LR_RNAS = bytes[7];
    var LR_RVCS = bytes[8];

    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData["Backflow_Alternation_semana"] = LR_RNAS.toString();
    outputData["Backflow_Volume_semana"] =
      (this.RestituyeEC1(LR_RVCS) * ds51_1x.pesoPulso).toLocaleString("es-ES") +
      " litros";
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_17*/
  DS51_17_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_17" };
    /* partes comunes DS51_1X*/
    var ds51_1x: any = this.DS51_1X_comun(bytes);

    var LR_MAAL = bytes[1];
    var LR_IMJ =
      (bytes[2] << 8) + (bytes[3] << 16) + (bytes[4] << 24) + bytes[14];

    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var LR_DAT = (this.readU16Lsb(bytes, 6) << 4) + ((bytes[8] & 0xf0) >> 4);
    var LR_ERN = bytes[15];
    var d = Math.floor(LR_DAT / (60 * 60 * 24));
    var i = LR_DAT - d * 60 * 60 * 24;
    var h = Math.floor(i / (60 * 60));
    var i = i - h * 60 * 60;
    var m = Math.floor(i / 60);
    var s = i % 60;

    var valor00h = LR_IMJ * ds51_1x.pesoPulso;
    var hora = (ds51_1x.DS51_HOR_RES >> 5) & 0x1f;

    outputData = Object.assign(
      outputData,
      this.objetosComunes(ds51_1x, LR_MAAL)
    );
    outputData["Tiempo_desde_lunes00h"] =
      LR_DAT.toString() +
      " segundos" +
      " ( " +
      d.toString() +
      " días, " +
      h.toString() +
      "horas, " +
      m.toString() +
      " minutos, " +
      s.toString() +
      " segundos)";
    outputData["Energia_consumida"] =
      (LR_ERN * 0.4).toLocaleString("es-ES") + " %";
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        ds51_1x.DS51_VIPP_24,
        ds51_1x.DS51_VIPN_24,
        ds51_1x.DS51_IIP_24,
        valor00h,
        hora,
        ds51_1x.pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_18*/
  DS51_18_decoder(bytes, fechaTrama) {
    var outputData = { Tipo_Trama: "DS51_18" };

    var meterKey = (bytes[0] >> 4) & 0x0f;
    var DS51_CFG4 = bytes[11];
    var DS51_CFG6 = bytes[13];
    var DS51_CFG8 = bytes[17];
    var DS51_CFG9 = bytes[18];
    var DS51_ERN = bytes[25];
    var DS51_LRW_P = (bytes[26] & 0xf0) >> 4;
    var DS51_LRW_DR = bytes[26] & 0x0f;
    var DS51_LRW_TX = (bytes[27] << 4) + ((bytes[28] & 0xf0) >> 4);
    var DS51_LRW_RX = ((bytes[29] & 0xf0) >> 4) + ((bytes[28] & 0x0f) << 4);
    var DS51_LRW_CFG = bytes[29] & 0x0f;
    var DS51_LRW_DC = (bytes[30] & 0xf0) >> 4;
    var DS51_LRW_RP = bytes[30] & 0x0f;
    var DS51_LRW_NET = bytes[31];
    var DS51_LRW_CH = bytes[32];
    var DS51_LRW_DN = bytes[33];
    var DS51_RFHRS = bytes.slice(35, 35 + 6);

    outputData["pesoPulso"] = this.METERKEYS[meterKey];
    outputData["Index_Offset_configured_during_installation"] =
      (DS51_CFG4 & 0x02) == 0x02;
    outputData["Summer_Winter_time_management_enabled"] =
      (DS51_CFG6 & 0x01) == 0x01;
    outputData["DS51_CFG8"] = {
      DS51_1x_DS51_OE_enabled: (DS51_CFG8 & 0x01) == 0x01,
      DS51_15_enabled: (DS51_CFG8 & 0x02) == 0x02,
      WB_DB_backup_mode_enabled: (DS51_CFG8 & 0x08) == 0x08,
      LoRaWAN_protocol_activated: (DS51_CFG8 & 0x10) == 0x10,
      Connection_method_to_network: DS51_CFG8 & 0x20 ? "ABP" : "OTAA",
      Type_of_network: this.NET_TYPE[(DS51_CFG8 & 0xc0) >> 6],
    };
    outputData["DS51_CFG9"] = {
      Adaptative_DataRate: DS51_CFG9 & 0x02 ? "Active" : "Inactive",
      Loss_of_session_management: DS51_CFG9 & 0x08 ? "Active" : "Inactive",
    };
    outputData["Energia_consumida"] =
      (DS51_ERN * 0.4).toLocaleString("es-ES") + " %";
    outputData["Transmission_power"] =
      this.LRWP[DS51_LRW_P].toString() + " dBm";
    outputData["DataRate"] = this.LRDR[DS51_LRW_DR];
    outputData["LoRaWAN_transmissions"] = DS51_LRW_TX;
    outputData["LoRaWAN_recepctions"] = DS51_LRW_RX;
    outputData["Config_changes_month"] = DS51_LRW_CFG;
    outputData["Setting_bandwith_occupation"] = (
      1 / Math.pow(2, DS51_LRW_DC)
    ).toLocaleString("es-ES");
    outputData["Setting_NbRep"] = DS51_LRW_RP;
    outputData["MAC_TXs"] =
      (DS51_LRW_NET * 0.4).toLocaleString("es-ES") + " % del número de TX";
    outputData["Numero_Canales"] = DS51_LRW_CH >> 3;
    outputData["Msk_canales_defecto"] =
      "0x" + (DS51_LRW_CH & 0x07).toString(16).padStart(1, "0");
    outputData["Tiempo_RX_ON"] = DS51_LRW_DN.toString() + " segundos";
    outputData["RF_ID_HR"] = this.bytesToHex(DS51_RFHRS);
    return { data: outputData };
  }

  /* Parseado DS51_A*/
  DS51_A_decoder(bytes, fechaTrama) {
    var seqNumber = (bytes[0] >> 4) & 0x0f;
    var DS51_MAAL = bytes[2];
    var DS51_MIAL = bytes.slice(3, 3 + 5);
    var LR_IMJ = this.readU32Lsb(bytes, 8);
    if (LR_IMJ == 0xc0000000) {
      return {
        errors: ["LR_IMJ: 0xC0000000  Referencia no válida"],
      };
    }
    var DS51_VIPP_24 = this.readU16Lsb(bytes, 12) + ((bytes[14] & 0x0f) << 16);
    var DS51_VIPN_24 = this.readU16Lsb(bytes, 15) + ((bytes[14] & 0xf0) << 12);
    var DS51_IIP_24 = bytes.slice(17, 17 + 24);
    var DS51_QMIN = bytes[41];
    var DS51_QMAX = bytes[42];
    var DS51_RNA = bytes[43];
    var DS51_RVC = bytes[44];
    var DS51_KEY_DFQ_0 = bytes[45];
    var DS51_DFQ_1 = bytes[46];
    var DS51_HOR = this.readU16Lsb(bytes, 49);
    var hora = (DS51_HOR >> 11) & 0x1f;
    var minuto = Math.floor(((DS51_HOR >> 4) & 0x7f) / 2);
    var segundo = (((DS51_HOR >> 4) & 0x7f) % 2) * 30;
    var pesoPulso = this.METERKEYS[bytes[49] & 0x0f];

    var valor00h = LR_IMJ * pesoPulso;

    var outputData = {
      Tipo_Trama: "DS51_A",
      Sequence_Number: seqNumber,
      DS51_HOR:
        hora.toString().padStart(2, "0") +
        ":" +
        minuto.toString().padStart(2, "0") +
        ":" +
        segundo.toString().padStart(2, "0"),
      Fecha_Trama: fechaTrama.toLocaleString("es-ES"),
      Meter_Key: pesoPulso.toLocaleString("es-ES") + " litros/pulso",
      MacroAlarmas: this.DS51_MAAL_parse(DS51_MAAL),
      MicroAlarmas: this.DS51_MIAL_parse(DS51_MIAL, DS51_MAAL),
      Caudal_Minimo_Ayer:
        (this.RestituyeEC1(DS51_QMIN) * pesoPulso).toLocaleString("es-ES") +
        " litros/hora",
      Caudal_Maximo_Ayer:
        (this.RestituyeEC1(DS51_QMAX) * pesoPulso).toLocaleString("es-ES") +
        " litros/hora",
      Backflow_Alternation_Ayer: DS51_RNA.toString(),
      Backflow_Volume_Ayer:
        (this.RestituyeEC1(DS51_RVC) * pesoPulso).toLocaleString("es-ES") +
        " litros",
      No_Flow_Persistence_Ayer:
        this.FLOW_PERSISTENCE_CODING[DS51_KEY_DFQ_0 & 0x0f],
      Flow_Persistence_Duration_Ayer:
        this.FLOW_PERSISTENCE_CODING[(DS51_DFQ_1 >> 4) & 0x0f],
    };
    outputData = Object.assign(
      outputData,
      this.objetosConsumosYValores(
        DS51_VIPP_24,
        DS51_VIPN_24,
        DS51_IIP_24,
        valor00h,
        hora,
        pesoPulso,
        fechaTrama
      )
    );
    return { data: outputData };
  }

  /* Parseado DS51_2*/
  DS51_2_decoder(bytes, fechaTrama) {
    var seqNumber = (bytes[0] >> 4) & 0x0f;
    var DS51_CFG1 = bytes[2];
    var pesoPulso = this.METERKEYS[(DS51_CFG1 >> 4) & 0x0f];
    var DS51_CFG2 = bytes[3];
    var DS51_CFG3 = bytes[4];
    var DS51_CFG4 = bytes[5];
    var DS51_ERN = bytes[14];
    var DS51_QMAM = bytes[32];
    var DS51_LRW_P = (bytes[36] >> 4) & 0x0f;
    var DS51_LRW_DR = bytes[36] & 0x0f;
    var DS51_LRW_TX = ((bytes[37] << 4) & 0xfff) + ((bytes[38] >> 4) & 0x0f);
    var DS51_LRW_RX = ((bytes[38] << 4) & 0xf0) + ((bytes[39] >> 4) & 0x0f);
    var DS51_LRW_CFG = bytes[39] & 0x0f;
    var DS51_LRW_DC = (bytes[40] >> 4) & 0x0f;
    var DS51_LRW_RP = bytes[40] & 0x0f;
    var DS51_LRW_NET = bytes[41];
    var DS51_LRW_CH = bytes[42];
    var DS51_LRW_DN = this.readU16Lsb(bytes, 43) % 0x3ff;
    var DS51_RFHRS = bytes.slice(45, 45 + 6);

    var outputData = {
      Tipo_Trama: "DS51_2",
      Sequence_Number: seqNumber,
      Fecha_Trama: fechaTrama.toLocaleString("es-ES"),
      Meter_Key: pesoPulso.toLocaleString("es-ES") + " litros/pulso",
      Index_Offset_configured_during_installation: (DS51_CFG1 & 0x02) == 0x02,
      Summer_Winter_time_management_enabled: (DS51_CFG2 & 0x01) == 0x01,
      DS51: {
        DS51_1x_DS51_OE_enabled: (DS51_CFG3 & 0x01) == 0x01,
        DS51_15_enabled: (DS51_CFG3 & 0x02) == 0x02,
        LoRaWAN_protocol_activated: (DS51_CFG3 & 0x10) == 0x10,
        Connection_method_to_network: DS51_CFG3 & 0x20 ? "ABP" : "OTAA",
        Type_of_network: this.NET_TYPE[(DS51_CFG3 & 0xc0) >> 6],
        Adaptative_DataRate: DS51_CFG4 & 0x01 ? "Active" : "Inactive",
        Loss_of_session_management: DS51_CFG4 & 0x01 ? "Active" : "Inactive",
      },
      Energia_consumida: (DS51_ERN * 0.4).toLocaleString("es-ES") + " %",
      Caudal_Maximo_Mes:
        (this.RestituyeEC1(DS51_QMAM) * pesoPulso).toLocaleString("es-ES") +
        " litros/hora",
      Transmission_power: this.LRWP[DS51_LRW_P].toString() + " dBm",
      DataRate: this.LRDR[DS51_LRW_DR],
      LoRaWAN_transmissions: DS51_LRW_TX,
      LoRaWAN_recepctions: DS51_LRW_RX,
      Config_changes_month: DS51_LRW_CFG,
      Setting_bandwith_occupation: (
        1 / Math.pow(2, DS51_LRW_DC)
      ).toLocaleString("es-ES"),
      Setting_NbRep: DS51_LRW_RP,
      MAC_TXs:
        (DS51_LRW_NET * 0.4).toLocaleString("es-ES") + " % del número de TX",
      Numero_Canales: DS51_LRW_CH >> 3,
      Msk_canales_defecto:
        "0x" + (DS51_LRW_CH & 0x07).toString(16).padStart(1, "0"),
      Tiempo_RX_ON: DS51_LRW_DN.toString() + " segundos",
      RF_ID_HR: this.bytesToHex(DS51_RFHRS),
    };
    return { data: outputData };
  }

  /* Parseado DS51_0E*/
  DS51_0E_decoder(bytes, fechaTrama) {
    var seqNumber = (bytes[0] >> 4) & 0x0f;
    var DS51_ALC = bytes[2];
    var DS51_MIAS = bytes.slice(3, 3 + 5);
    var DS51_QMIN = bytes[8];
    var DS51_QMAX = bytes[10];
    var DS51_RNA = bytes[12];
    var DS51_RVC = bytes[13];
    var DS51_DFQ_0 = bytes[19] & 0x0f;
    var DS51_DFQ = (bytes[20] >> 4) & 0x0f;
    var DS51_CPT = bytes[31] & 0x03;
    var DS51_DAT = this.readU32Lsb(bytes, 32);
    var DS51_RFHRS = bytes.slice(38, 38 + 6);
    var pesoPulso = this.METERKEYS[bytes[44] & 0x0f];

    var referenceEpoch = Date.UTC(2012, 0, 1);
    var dateTime = new Date(DS51_DAT * 1000 + referenceEpoch);

    var outputData = {
      Tipo_Trama: "DS51_A",
      Sequence_Number: seqNumber,
    };
    if (DS51_ALC == 0x04) {
      outputData["Alarm_Cause"] = "Module tampered";
    } else if (DS51_ALC == 0x08) {
      outputData["Alarm_Cause"] = "BackFlow";
    } else if (DS51_ALC == 0x10) {
      outputData["Alarm_Cause"] = "Flow persistence in progress";
    } else if (DS51_ALC == 0x20) {
      outputData["Alarm_Cause"] = "Stop persistence in progress";
    } else {
      outputData["Alarm_Cause"] = "DESCONOCIDA!!!";
    }
    outputData["MicroAlarmas"] = this.DS51_MIAL_parse(DS51_MIAS, 0xff);
    outputData["Caudal_Minimo_Hoy"] =
      (this.RestituyeEC1(DS51_QMIN) * pesoPulso).toLocaleString("es-ES") +
      " litros/hora";
    outputData["Caudal_Maximo_Hoy"] =
      (this.RestituyeEC1(DS51_QMAX) * pesoPulso).toLocaleString("es-ES") +
      " litros/hora";
    outputData["Backflow_Alternation_Hoy"] = DS51_RNA.toString();
    outputData["Backflow_Volume_Hoy"] =
      (this.RestituyeEC1(DS51_RVC) * pesoPulso).toLocaleString("es-ES") +
      " litros";
    outputData["No_Flow_Persistence_Hoy"] =
      this.FLOW_PERSISTENCE_CODING[DS51_DFQ_0];
    outputData["Flow_Persistence_Duration_Hoy"] =
      this.FLOW_PERSISTENCE_CODING[DS51_DFQ];
    outputData["Repetition_counter"] = DS51_CPT;
    outputData["Date_Time"] = dateTime.toLocaleString("es-ES");
    outputData["RF_ID_HR"] = this.bytesToHex(DS51_RFHRS);
    return { data: outputData };
  }

  /* Helper Methods */
  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  objectAssign(source, target) {
    var _source = [];
    var _target = [];

    if (source) {
      _source = Object(source);
      if (target) {
        _target = Object.keys(target);

        for (var i = 0; i < _target.length; i++) {
          if (Object.prototype.hasOwnProperty.call(target, _target[i])) {
            _source[_target[i]] = target[_target[i]];
          }
        }
      }
    }
    return _source;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = this.readU16Lsb(bytes, start);
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  read24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x800000) {
      res = res - 0xffffff - 1;
    }
    return res;
  }
}
