<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>
          {{ "frames-relaunch" | translate }}:
          {{
            gateway != null ? gateway.unidadVenta : ("no-data" | translate)
          }}</b
        >
      </h5>

      <!-- Botón relanzar -->
      <app-material-panel-menu
        [onlyButton]="true"
        [title]="'frames-relaunch' | translate"
        [disabled]="!deviceList"
        (action)="relaunchFrames()"
      >
      </app-material-panel-menu>
    </div>
    <div class="panel-body">
      <!-- Tabla de gateways -->
      <app-table-controller
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="tableMaxReg"
        [specialFilter]="true"
        (selectedDataUpdateFlag)="tableSelectedData = $event"
        [data]="deviceList"
      >
      </app-table-controller>
    </div>
  </div>
</section>
