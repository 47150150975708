import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Turf
import * as Turf from "@turf/turf";
// Cesium
declare var Cesium: any;
// Servicio propios
import { MapDeviceFilterService } from "../../map-module/map-services/MapDeviceFilterService.service";
import { MapGatewayTooltipService } from "../../map-module/map-services/MapGatewayTooltipService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { CesiumMapGatewayIconService } from "../cesium-map-services/CesiumMapGatewayIconService.service";
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";
import { MapDeviceTooltipService } from "../../map-module/map-services/MapDeviceTooltipService.service";
import { CesiumMapDeviceIconService } from "../cesium-map-services/CesiumMapDeviceIconService.service";
import { HomeControllerService } from "../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../map-module/map-services/MapDeviceMinimalParseService.service";
import { CoverageTestService } from "../../../screens/dashboard/coverage/coverage-test/CoverageTestService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
// Componentes
import { CesiumMapComponent } from "../cesium-map/cesium-map.component";
// Variables
import {
  MAP_TYPES,
  MAP_LAYERS,
  MapLayerData,
} from "../../map-module/map-variables/MAP_TYPES";
import { MAP_CONFIG } from "../../map-module/map-variables/MAP_CONFIG";
import { RANDOM_COLORS } from "../../map-module/map-variables/MAP_COLORS";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { HeatMapDevice } from "../../../screens/dashboard/coverage/CoverageInterface.type";

@Component({
  selector: "app-cesium-map-controller",
  templateUrl: "./cesium-map-controller.component.html",
  styleUrls: ["./cesium-map-controller.component.scss"],
})
export class CesiumMapControllerComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  sessionProfile: string;
  currentAgrupation: Agrupation;
  currentEntity: Entity;

  // Datos
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    if (data) {
      // this.data = null;
      this._data = { ...data };
      this.devicesData = this._data.devices;
      this.gatewaysData = this._data.gateways;
      this.locationsData = this._data.locations;
      this.drawAgrupationOutline = this._data.drawAgrupationOutline;
      this.mapType = this._data.mapType;
      this.mapHeight = this._data.mapHeight;
      this.polygonHeatValues = this._data.polygonHeatValues;
      this.coverageData = this._data.coverageData;
      this.allowKml = this._data.allowKml;
      if (this.coverageData) {
        this.originalCoverageData = [...this.coverageData];
      }
      this.bounds = [];

      if (this.mapReady) {
        this.loadComponent();
      }
    }
  }
  _data: any;
  devicesData: any[];
  gatewaysData: any[];
  locationsData: any[];
  mapType: string;
  bounds: number[][] = [];

  // Tamaño del mapa
  mapHeight: number;
  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--map-height: ${this.mapHeight}`
    );
  }

  // Arrays y capas por tipo de mapa
  mapTypeData: object;
  mapReady: boolean = false;

  // Acción de mapa
  @Output() actionFlag = new EventEmitter<any>();
  updatedEntity: any;

  // Mapa
  @ViewChild(CesiumMapComponent) cesiumMap: CesiumMapComponent;
  @Output() mapReset = new EventEmitter<any>();

  // Capa de agrupaciones
  drawAgrupationOutline: boolean;
  agrupationList: Agrupation[];
  agrupationLayerData: any[];

  // Capa de calor poligonal
  polygonHeatValues: number;

  // Capas de cobertura
  coverageData: HeatMapDevice[];
  originalCoverageData: HeatMapDevice[];
  coverageLayersData: any[];
  filterCoverByGateway: boolean = false;
  filteredByGateway: string;

  // KML
  allowKml: boolean;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CesiumMapGatewayIconService: CesiumMapGatewayIconService,
    private coverageTest: CoverageTestService,
    private HomeController: HomeControllerService,
    private MapDeviceFilterService: MapDeviceFilterService,
    private CesiumMapDeviceIconService: CesiumMapDeviceIconService,
    private MapDeviceMinimalParseService: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private MapGatewayTooltipService: MapGatewayTooltipService,
    private MapDeviceTooltipService: MapDeviceTooltipService,
    private sanitizer: DomSanitizer,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de variables de sesión
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Carga del componente
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización dependiendo del tipo de mapa
  loadComponent(): void {
    this.getMapTypeData();

    // Obtención del array de los contadores
    this.devicesData = this.MapDeviceFilterService.checkMeters(
      this.devicesData,
      this.bounds
    );
    // Obtención de los arrays de cada tipo de dispositivo
    this.getDevicesArrays();

    // Obtención de las capas del mapa
    this.getMapLayers();

    // Polígonos de agrupaciones
    this.setAgrupationLayers();

    // Mapa de cobertura
    if (this.coverageData) {
      this.setCoverageLayer();
    } else {
      this.coverageLayersData = null;
    }

    // Mapa preparado
    this.mapReady = true;
  }

  // Obtención de los datos base del mapa
  getMapTypeData(): void {
    let mapTypeData = JSON.parse(JSON.stringify(MAP_TYPES[this.mapType]));
    for (let layerType in MAP_TYPES[this.mapType]) {
      mapTypeData[layerType] = new MapLayerData(
        MAP_TYPES[this.mapType][layerType].layerData
      );
    }
    this.mapTypeData = mapTypeData;
  }

  // Carga de los arrays de dispositivos de cada tipo
  getDevicesArrays(): void {
    for (let layer in this.mapTypeData) {
      if (layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS)) {
        this.mapTypeData[layer].array = this.gatewayFilter(layer);
      } else if (layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)) {
        this.mapTypeData[layer].array = this.locationsData;
      } else {
        this.mapTypeData[layer].array =
          this.MapDeviceFilterService.deviceFilter(this.devicesData, layer);
      }
    }
  }

  // Filtrado de los gateways para cada capa
  gatewayFilter(gatewayType: string): any[] {
    switch (gatewayType) {
      case "GatewaysOk":
        return this.gatewaysData.filter((gateway: any) => gateway.comunica);
      case "GatewaysError":
        return this.gatewaysData.filter((gateway: any) => !gateway.comunica);
      default:
        return this.gatewaysData;
    }
  }

  // Actualización de imagen de localizaciones de cobertura
  updateLocations(device: any): void {
    this.locationsData.map((location) => {
      location.postInstallation =
        device && location.createTimestamp > device.timestamp;
      let testLocation = device?.locationList?.find(
        (testLocation) => testLocation.id == location.id
      );
      location.rssi = testLocation ? testLocation.rssi : null;
      location.snr = testLocation ? testLocation.snr : null;
      location.distance = testLocation ? testLocation.distance : null;
      location.pertenece = testLocation ? true : false;
      location.showLabel =
        device && !location.postInstallation && location.pertenece;
    });
    this.mapTypeData[MAP_LAYERS.LOCATIONS].cesiumLayer = this.getLocationLayer(
      this.locationsData
    );
    this.cesiumMap.updateLocations();
  }

  // Obtención de las capas del mapa
  getMapLayers(): void {
    for (let layer in this.mapTypeData) {
      if (layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS)) {
        // Capa de gateways
        this.mapTypeData[layer].cesiumLayer = this.getGatewayLayer(
          this.mapTypeData[layer].array
        );
      } else if (layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)) {
        // Capa de localizaciones
        this.mapTypeData[layer].cesiumLayer = this.getLocationLayer(
          this.mapTypeData[layer].array
        );
      } else {
        // Capas de contadores
        this.mapTypeData[layer].cesiumLayer = this.getMeterLayer(
          layer,
          this.mapTypeData[layer].array
        );
      }
    }
  }

  // Creación de los marcadores de las localizaciones
  getLocationLayer(locationArray: any[]): any {
    let locationMarkers: any[] = [];
    // Filtrado de gateways sin coordenadas de posición
    locationArray
      .filter(
        (location: any) =>
          location.latitude != null &&
          location.longitude != null &&
          Math.abs(location.latitude) <= 90 &&
          Math.abs(location.longitude) <= 180
      )
      .forEach((location: any) => {
        // Marcadores de localización
        locationMarkers.push(
          this.CesiumMapGatewayIconService.getLocationIcon(
            this.mapType,
            location,
            this.getLocationTooltip(location)
          )
        );
      });

    return locationMarkers;
  }

  // Creación de los marcadores de los gateways
  getGatewayLayer(gatewayArray: any[]): any {
    let gatewayMarkers: any[] = [];
    let mainGateway: any = gatewayArray.find((gateway: any) => !gateway.other);

    // Filtrado de gateways sin coordenadas de posición
    gatewayArray
      .filter(
        (gateway: any) =>
          gateway.latitude != null &&
          gateway.longitude != null &&
          Math.abs(gateway.latitude) <= 90 &&
          Math.abs(gateway.longitude) <= 180
      )
      .forEach((gateway: any) => {
        // Marcadores de gateway
        gatewayMarkers.push(
          this.CesiumMapGatewayIconService.getGatewayIcon(
            this.mapType,
            gateway,
            this.getGatewayTooltip(gateway, mainGateway)
          )
        );
      });

    return gatewayMarkers;
  }

  // Creación de los marcadores de contadores por tipo
  getMeterLayer(layer: string, meterArray: any[]): any {
    let markerMeterArray: any[] = [];

    meterArray.forEach((meter: any) => {
      // Comprobación de tipo UNE
      let deviceType: string = this.DeviceTypeService.getDeviceTypeByMask(
        meter.tipo,
        meter.metrologyType,
        meter.fabricante ? meter.fabricante : meter.idFabricante
      );

      let isLwUne: boolean = deviceType === DEVICE_BY_COMM.LW_UNE_CON;
      let isLwMbus: boolean = deviceType === DEVICE_BY_COMM.LW_MBUS_CON;

      // Tooltip para cada contador
      let tooltip: string =
        layer != MAP_LAYERS.TXN
          ? this.MapDeviceTooltipService.getDeviceTooltip(
              meter,
              layer,
              this.gatewaysData,
              this.sessionProfile,
              this.currentAgrupation,
              this.mapType
            )
          : `<div class="map-tooltip map-tooltip-control">
              <span class="map-tooltip-close-background"></span>
              <table>
                <tr></tr>
                <tr class="map-tooltip-title noloranocom-gradient">
                  <th colspan="2"><b>TXN</b></th>
                </tr></table></div>`;

      // Marcador para cada contador
      let CesiumMapDeviceIconService =
        this.CesiumMapDeviceIconService.getDeviceIcon(
          this.mapType,
          meter,
          layer,
          deviceType,
          isLwUne,
          isLwMbus,
          tooltip,
          this.gatewaysData.find((gateway: any) =>
            gateway.contadoresPrincipal?.includes(meter.nroSerie)
          ),
          this.mapType == "coverage"
            ? this.locationsData?.filter((location) =>
                meter.locationList?.some(
                  (meterLocation) => meterLocation.id == location.id
                )
              )
            : null
        );

      markerMeterArray.push(CesiumMapDeviceIconService);
    });

    return markerMeterArray;
  }

  // Creación del tooltip para cada localización
  getLocationTooltip(location: any): string {
    return this.MapGatewayTooltipService.getLocationTooltip(
      this.mapType,
      location,
      [],
      this.currentAgrupation
    );
  }

  // Actualización de tooltip de localización
  updateLocationTooltip(entity: any): void {
    let location = this.locationsData.find(
      (location: any) => location.id == entity.acEntity.id
    );
    this.HomeController.getLocationData(location.id).subscribe((response) => {
      if (response["code"] == 0) {
        entity.acEntity.tooltip =
          this.MapGatewayTooltipService.getLocationTooltip(
            this.mapType,
            location,
            response["body"],
            this.currentAgrupation
          );
        this.updatedEntity = entity;
      }
    });
  }

  // Creación del tooltip para cada gateway
  getGatewayTooltip(gateway: any, mainGateway: any): string {
    let meterType: string = this.getMeterType(gateway);
    return this.MapGatewayTooltipService.getGatewayTooltip(
      gateway,
      meterType,
      this.sessionProfile,
      this.currentAgrupation,
      this.mapType,
      mainGateway
    );
  }

  // Búsqueda del número de contadores principales y redundantes con diferentes atributos asociados al dato
  getMeterType(gateway: any): any {
    // Dato por defecto
    let meterType: any = {
      redundantMeter: this.translate.instant("no-data"),
      mainMeter: this.translate.instant("no-data"),
    };

    // Atributos asociados a los tipos de contador
    let gatewayMeterCountAttributes: any[] = [
      {
        meterType: "mainMeter",
        attributes: [
          { name: "contadoresPrincipal", length: true },
          { name: "nroMainContadores", length: false },
          { name: "mainMeters", length: false },
        ],
      },
      {
        meterType: "redundantMeter",
        attributes: [
          { name: "contadoresRedundante", length: true },
          { name: "nroRedundantContadores", length: false },
          { name: "redundantMeters", length: false },
        ],
      },
    ];

    // Comprobación de atributo existente en gateway y actualización de dato asociado
    gatewayMeterCountAttributes.forEach((meterCountAttribute) => {
      let gatewaySearchedAttribute: any = meterCountAttribute.attributes.find(
        (attribute: any) => {
          return gateway[attribute.name] != null;
        }
      );

      if (gatewaySearchedAttribute) {
        meterType[meterCountAttribute.meterType] =
          gatewaySearchedAttribute.length
            ? gateway[gatewaySearchedAttribute.name].length
            : gateway[gatewaySearchedAttribute.name];
      }
    });

    return meterType;
  }

  /***************************************************************************/
  // ANCHOR Capas poligonales de agrupaciones
  /***************************************************************************/

  // Seteo de las capas de polígonos
  setAgrupationLayers(): void {
    let agrupationLayerData: any[] = [];
    if (this.drawAgrupationOutline) {
      this.agrupationList = this.currentEntity.agrupations
        .filter(
          (agrupation: Agrupation) =>
            !agrupation.showAllEntity &&
            this.devicesData.some(
              (meter: any) => meter.agrupation == agrupation.id
            )
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      // Coordenadas de dispositivos para cada agrupación
      this.currentEntity.agrupations.forEach((agrupation: Agrupation, i) => {
        let latLngs: number[][] = [];
        this.devicesData.forEach((device: any) => {
          if (device.agrupation == agrupation.id) {
            latLngs.push([
              parseFloat(device.latitude),
              parseFloat(device.longitude),
            ]);
          }
        });
        if (latLngs.length > 0) {
          let positionsArray: number[][][] = this.getOutlineCoords(latLngs);
          let positions: number[] = [];
          if (positionsArray) {
            positionsArray[0].forEach((position: number[]) => {
              positions = positions.concat([position[1], position[0]]);
            });
          }
          // Coordenadas del contorno
          if (positions.length > 0) {
            // Dispositivos con comunicación
            let okDevices = this.mapTypeData[MAP_LAYERS.OK]?.array?.filter(
              (meter: any) => meter.agrupation == agrupation.id
            );
            // Dispositivos sin comunicación
            let noCommunicationDevices = this.mapTypeData[
              MAP_LAYERS.NO_COMUNICA
            ]?.array?.filter((meter: any) => meter.agrupation == agrupation.id);
            // Dispositivos pendientes
            let pendingDevices = this.mapTypeData[
              MAP_LAYERS.NO_ASIGNADO
            ]?.array?.filter((meter: any) => meter.agrupation == agrupation.id);
            // Dispositivos con alarmas
            let deviceWithAlarms = this.devicesData?.filter(
              (meter: any) => meter.agrupation == agrupation.id && meter.alarm
            );

            // Tooltip
            let agrupationColor = Cesium.Color.fromCssColorString(
              RANDOM_COLORS[i]
            );
            let textColor = "white";
            let tooltip =
              `<div class="cesium-map-agrupation-tooltip">
            <span style="background: ` +
              RANDOM_COLORS[i] +
              `; text-shadow: 0.5px 0 #2c2c2c, -0.5px 0 #2c2c2c, 0 0.5px #2c2c2c, 0 -0.5px #2c2c2c,
            1px 1px #2c2c2c, -1px -1px #2c2c2c, 1px -1px #2c2c2c, -1px 1px #2c2c2c` +
              `; text-align: center; color: ` +
              textColor +
              `"><b>` +
              agrupation.name.toUpperCase() +
              `</b></span><table style="font-weight: bold"><tr><th>` +
              this.translate.instant("with-communication") +
              `:</th> <td style="color: green">` +
              okDevices.length +
              `</td></tr><tr><th>` +
              this.translate.instant("no-communication") +
              `:</th> <td style="color: red">` +
              noCommunicationDevices.length +
              `</td></tr><tr><th>` +
              this.translate.instant("no-assigned") +
              `:</th> <td style="color: purple">` +
              pendingDevices.length +
              `</td></tr><tr><th>` +
              this.translate.instant("with-alarms") +
              `:</th> <td style="color: red">` +
              deviceWithAlarms.length +
              "</td></tr></table>" +
              `</div>`;

            // Inclusión de agrupación en capa
            agrupationLayerData.push({
              id: agrupation.id,
              name: agrupation.name,
              color: Cesium.Color.fromAlpha(agrupationColor, 0.5),
              borderColor: agrupationColor,
              positions: positions,
              tooltip: tooltip,
            });
          }
        }
      });
    }
    this.agrupationLayerData = agrupationLayerData;
  }

  // Obtención de contorno de la agrupación mediante librería Turf
  getOutlineCoords(latLngs: number[][]): any {
    let featureCollection = Turf.featureCollection(
      latLngs.map((latLng: number[]) => {
        return Turf.point([latLng[1], latLng[0]]);
      })
    );
    let options = { concavity: MAP_CONFIG.agrupationPolygon.concavity };
    let polygon = Turf.convex(featureCollection, options);
    let latLngPolygon = polygon?.geometry?.coordinates.map((feature) => {
      return feature.map((latLng) => {
        return [latLng[1], latLng[0]];
      });
    });
    return latLngPolygon;
  }

  // Actualización de tooltip
  updateTooltip(entity: any): void {
    let device = this.devicesData.find(
      (device: any) => device.id == entity.acEntity.id
    );
    this.HomeController.getMarkerTooltip(device.id).subscribe((response) => {
      if (response["code"] == 0) {
        this.MapDeviceMinimalParseService.parseTooltipData(
          device,
          response["body"]
        );
        entity.acEntity.tooltip = this.MapDeviceTooltipService.getDeviceTooltip(
          device,
          entity.acEntity.deviceType,
          this.gatewaysData,
          this.sessionProfile,
          this.currentAgrupation,
          this.mapType
        );
        this.updatedEntity = entity;
      }
    });
  }

  // Reseteo de mapa
  resetMap(): void {
    this._data = null;
    this.devicesData = null;
    this.gatewaysData = null;
    this.drawAgrupationOutline = null;
    this.mapType = null;
    this.mapHeight = null;
    this.polygonHeatValues = null;
    this.coverageData = null;
    this.allowKml = null;
    this.originalCoverageData = null;
    this.coverageLayersData = null;
    this.cesiumMap.resetMap();
  }

  // Acción de elemento de mapa
  mapAction(action: string, deviceData: any): void {
    switch (action) {
      case "filterGateway":
        this.filterCoverByGateway = true;
        this.filteredByGateway = deviceData;
        this.coverageData = this.originalCoverageData.filter(
          (device: HeatMapDevice) => device.unidadVenta == deviceData.name
        );
        this.setCoverageLayer();
        break;
      case "updateMetersCoverage":
        this.updateMetersCoverage(deviceData);
        break;
      case "removeTest":
        this.coverageTest.removeTest(deviceData);
        break;
      default:
        break;
    }
  }

  // Comprobación de si el contador está recibiendo señal de otros gateways
  updateMetersCoverage(location: any): void {
    this.mapTypeData[MAP_LAYERS.COVERAGE_OK]?.array.forEach((meter: any) => {
      if (
        location &&
        meter.locationList?.length > 0 &&
        !meter.locationList?.some(
          (meterLocation: any) => meterLocation.id == location.id
        )
      ) {
        meter.otherGateway = true;
      } else {
        meter.otherGateway = false;
      }
    });

    // Actualización de capa de cobertura Ok
    this.mapTypeData[MAP_LAYERS.COVERAGE_OK].cesiumLayer = this.getMeterLayer(
      MAP_LAYERS.COVERAGE_OK,
      this.mapTypeData[MAP_LAYERS.COVERAGE_OK]?.array
    );
    this.cesiumMap.updateCoverage();
  }

  // Reseteo de filtros
  resetFilter(filter: string): void {
    switch (filter) {
      case "filterCoverByGateway":
        this.filterCoverByGateway = false;
        this.filteredByGateway = null;
        this.coverageData = [...this.originalCoverageData];
        this.setCoverageLayer();
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Mapa de cobertura
  /***************************************************************************/

  // Seteo de las capas del mapa de cobertura
  setCoverageLayer(): void {
    this.coverageLayersData = [];
    this.bounds = this.coverageData.map((device: HeatMapDevice) => [
      device.latitude,
      device.longitude,
    ]);

    // Cobertura total
    this.getValuesLayer(
      "green",
      "coverageTotal",
      this.translate.instant("coverage-total-rssi"),
      this.polygonHeatValues[3],
      null
    );
    // Cobertura alta
    this.getValuesLayer(
      "limeGreen",
      "coverageHigh",
      this.translate.instant("coverage-high-rssi"),
      this.polygonHeatValues[2],
      this.polygonHeatValues[3]
    );
    // Cobertura media
    this.getValuesLayer(
      "yellow",
      "coverageMid",
      this.translate.instant("coverage-mid-rssi"),
      this.polygonHeatValues[1],
      this.polygonHeatValues[2]
    );
    // Cobertura baja
    this.getValuesLayer(
      "orange",
      "coverageLow",
      this.translate.instant("coverage-low-rssi"),
      this.polygonHeatValues[0],
      this.polygonHeatValues[1]
    );
    // Cobertura crítica
    this.getValuesLayer(
      "red",
      "coverageCritical",
      this.translate.instant("coverage-critical-rssi"),
      null,
      this.polygonHeatValues[0]
    );
    // Sin cobertura
    this.getValuesLayer(
      "black",
      "coverageNull",
      this.translate.instant("coverage-null"),
      null,
      null
    );
  }

  // Obtención de los datos de cobertura
  getValuesLayer(
    color: string,
    id: string,
    layerName: string,
    min: number,
    max: number
  ): void {
    // Filtrado por coberturas
    let devicesInCoverage: HeatMapDevice[] =
      min == null && max != null
        ? this.coverageData.filter(
            (device: HeatMapDevice) => device.rssi != null && device.rssi <= max
          )
        : min == null && max == null
        ? this.coverageData.filter(
            (device: HeatMapDevice) => device.rssi == null
          )
        : max == null
        ? this.coverageData.filter(
            (device: HeatMapDevice) => device.rssi != null && device.rssi >= min
          )
        : this.coverageData.filter(
            (device: HeatMapDevice) =>
              device.rssi > min &&
              (max == this.polygonHeatValues[3]
                ? device.rssi < max
                : device.rssi <= max)
          );

    // Seteo de capa
    if (devicesInCoverage.length > 0) {
      // Inclusión de valores de cobertura en capa
      this.coverageLayersData.push({
        id: id,
        name: layerName,
        cssColor: color,
        color: Cesium.Color.fromAlpha(
          Cesium.Color.fromCssColorString(color),
          0.5
        ),
        positions: devicesInCoverage.map((device: HeatMapDevice) => [
          device.latitude,
          device.longitude,
        ]),
        tooltip: `<div class="map-coverage-tooltip">` + layerName + `</div>`,
      });
    }
  }
}
