<!-- Estados de válvula -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "valve-state" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <table class="table table-hover table-bordered table-striped">
      <thead>
        <tr>
          <th>{{ "code" | translate }}</th>
          <th>{{ "state" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let state of VALVE_STATES">
          <tr>
            <td>{{ state.id }}</td>
            <td>{{ state.text | translate }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!-- Cerrar -->
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
