<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Título -->
        <h5>
          <b>{{ "configuration" | translate }}: {{ cups }}</b>
        </h5>
        <!-- Botón guardar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="saveButtonIcon"
          [title]="saveButtonTittle"
          (action)="saveAlarms()"
        >
        </app-material-panel-menu>
      </div>

      <app-alarms-list
        [devicesList]="devicesList"
        [devicesValve]="devicesValve"
        (showModalFlag)="showModal($event)"
        [alarmsType]="alarmsType"
        [alerts]="true"
      >
      </app-alarms-list>
    </div>
  </div>
</section>
