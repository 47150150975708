// @angular
import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { AssociationControllerService } from "../../../../../services/server/AssociationController.service";
// Interfaces
import { Association } from "../../DataManagementInterface.type";
// Variables
import { METER_ORDER } from "../../../devices/DeviceInterface.type";

@Component({
  selector: "app-data-management-association-dialog",
  templateUrl: "./data-management-association-dialog.component.html",
  styleUrls: ["./data-management-association-dialog.component.scss"],
})
export class DataManagementAssociationDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: Association;
  orderType: number;
  orderComments: string;
  associationMeterIds: number[];
  orderTypes: { id: number; order: string }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.SessionDataService.getCurrentProfile() == "ARSON") {
      this.orderTypes = [
        { id: 1, order: METER_ORDER[1] },
        { id: 2, order: METER_ORDER[2] },
        { id: 3, order: METER_ORDER[3] },
      ];
    } else {
      this.orderTypes = [
        { id: 1, order: METER_ORDER[1] },
        { id: 3, order: METER_ORDER[3] },
      ];
    }

    this.getAsssociationDevices();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los dispositivos de la asociación
  getAsssociationDevices(): void {
    this.AssociationController.getAssociationDevices(this.data.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.associationMeterIds = response["body"]?.map((meter) => meter.id);
        }
      }
    );
  }

  // Guardado de orden de sustitución
  saveAssociationOrder(): void {
    this.MeterController.saveOrder({
      idList: this.associationMeterIds,
      type: this.orderType,
      comments: this.orderComments,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
      }
    });
  }
}
