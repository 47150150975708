// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ConsumptionProfile } from "../data-analysis-models-edit/data-analysis-patterns";

@Component({
  selector: "app-data-analysis-models-dialog",
  templateUrl: "./data-analysis-models-dialog.component.html",
  styleUrls: ["./data-analysis-models-dialog.component.scss"],
})
export class DataAnalysisModelsDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  newProfile: ConsumptionProfile;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.newProfile = {
      profileId: this.data.length + 1,
      profileName: this.translate.instant("new"),
    };
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Nuevo perfil
  sendNewProfile(): void {
    this.SessionDataService.sendDialogAction({
      action: "newProfile",
      newProfile: this.newProfile,
    });
  }
}
