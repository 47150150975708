<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "activation-error-list" | translate }}</b>
        </h5>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="updateTitle"
          [disabled]="updating"
          [updating]="updating"
          (action)="updateData()"
        >
        </app-material-panel-menu>
      </div>

      <div id="table-panel" class="panel-body meter-list-buttons">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersActivationError'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [quickFilters]="quickFilters"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          [quickFilters]="quickFilters"
          (dataRequest)="getData($event.from, $event.to)"
          [data]="tableData"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="meter-list-map"
            [dataList]="meterList"
            [originalDataList]="originalMeterList"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'nroSerie'"
            [anchorToScroll]="'table-panel'"
            (updateData)="meterList = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
