<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>
          <h5>
            <b> {{ "download-file" | translate }}</b>
          </h5>
        </div>
      </div>
      <div class="panel-body">
        <div class="output-files-selectors">
          <!-- Agrupaciones -->
          <app-material-select
            [multiple]="true"
            [clearable]="true"
            [fullWidth]="true"
            [filter]="true"
            [title]="'agrupations' | translate"
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [data]="agrupationList"
            [disabled]="disableAgrupation"
            (selectedOption)="selectedAgrupations = $event"
            [required]="true"
          ></app-material-select>
          <!-- CUPS -->
          <app-material-select
            [multiple]="true"
            [clearable]="true"
            [fullWidth]="true"
            [filter]="true"
            [hint]="columnsAlreadyIncluded | translate"
            [title]="'file-columns' | translate"
            [bindValue]="'colPosition'"
            [bindLabel]="'name'"
            [data]="cupsOptions"
            [disabled]="disableCups"
            (selectedOption)="selectedCupsColumns = $event"
          ></app-material-select>
        </div>
        <!-- Periodo de tiempo -->
        <div class="output-files-options">
          <div class="output-files-time-selector">
            <span for="timePeriod">{{ "graph-zoom" | translate }}</span>
            <mat-radio-group
              class="output-files-time-selector-inputs"
              [(ngModel)]="selectedTimePeriod"
              required
            >
              <!-- Último valor -->
              <mat-radio-button
                class="mat-radio"
                [value]="0"
                [disabled]="disableLastValue"
              >
                <span class="output-files-time-option">{{
                  "last-know-value" | translate
                }}</span>
              </mat-radio-button>
              <!-- Valor más cercano -->
              <mat-radio-button
                class="mat-radio output-files-closest-value"
                [value]="1"
                [disabled]="disableClosestValue"
              >
                <span class="output-files-time-option">{{
                  "closest value" | translate
                }}</span>
                <app-material-date-selector
                  [dateRange]="initialDate"
                  (dateRangeUpdated)="dateSelected = $event"
                  [singleDatePicker]="true"
                  [disabled]="selectedTimePeriod != 1"
                ></app-material-date-selector>
                <mat-form-field
                  appearance="standard"
                  [ngClass]="{ 'mat-error': limitOutOfRange }"
                >
                  <mat-label
                    >{{ "limit" | translate }} ({{ "max" | translate }} 7
                    {{ "days" | translate }})</mat-label
                  >
                  <input
                    matInput
                    type="number"
                    min="0"
                    [max]="sessionProfile != PROFILES.ARSON ? 7 : null"
                    [(ngModel)]="closestValueMargin"
                    (change)="limitOutOfRange = closestValueMargin > 7"
                    [disabled]="selectedTimePeriod != 1"
                  />
                  <mat-hint *ngIf="limitOutOfRange">
                    {{ "out-of-range" | translate }}
                  </mat-hint>
                </mat-form-field>
              </mat-radio-button>
              <!-- Intervalo -->
              <mat-radio-button
                class="mat-radio output-files-interval"
                [value]="2"
                [disabled]="disableInterval"
              >
                <span class="output-files-time-option">{{
                  "interval" | translate
                }}</span>

                <app-material-date-selector
                  [dateRange]="intialRange"
                  (dateRangeUpdated)="
                    dateRangeSelected = $event; checkDateRange()
                  "
                  [hint]="('max' | translate) + ' 7 ' + ('days' | translate)"
                  [error]="dateOutOfRange"
                  [disabled]="selectedTimePeriod != 2"
                ></app-material-date-selector>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <!-- Tipo de fichero -->
          <div
            class="output-files-file-type"
            [ngClass]="{ inactive: selectedTimePeriod != 1 }"
          >
            <span for="timePeriod">{{ "file-type" | translate }}</span>
            <app-material-select
              [disabled]="selectedTimePeriod != 1"
              [title]="'type' | translate"
              [bindValue]="'type'"
              [bindLabel]="'type'"
              [noSelection]="true"
              [data]="fileTypes"
              (selectedOption)="
                fileTypeSelected = $event?.type; updateFileType()
              "
            ></app-material-select>
            <!-- Archivo -->
            <div
              *ngIf="
                fileTypeSelected &&
                fileTypeSelected != 'UGENA' &&
                fileTypeSelected != 'POSEIDONIA'
              "
            >
              <app-file-import
                #fileImportInput
                [removeButton]="true"
                [fileType]="fileTypeSelected == 'ATMGTnet' ? '.xml' : '.txt'"
                (fileChange)="errorFile = null; fileToImport = $event"
              ></app-file-import>
            </div>
            <!-- Respuesta ATM -->
            <div *ngIf="atmResponse" class="output-files-atm-response">
              <!-- Contadores -->
              <span
                ><b>{{ "atm-found-meters" | translate }}:</b>
                {{ atmResponse.foundedMeters }}</span
              >
              <!-- Lecturas -->
              <span class="output-files-atm-response-readings"
                ><b>{{ "atm-found-readings" | translate }}:</b>
                {{ atmResponse.foundedReadings }}</span
              >
              <span
                class="output-files-atm-response-readings"
                (click)="showAtmReadings = !showAtmReadings"
                ><b>{{ "atm-not-found-readings" | translate }}:</b>
                {{ atmResponse.unfoundedReadings
                }}<i
                  *ngIf="atmResponse.unfoundedReadingsList?.length > 0"
                  class="pointer"
                  [ngClass]="
                    showAtmReadings ? 'fas fa-eye-slash' : 'fas fa-eye'
                  "
                ></i
              ></span>
              <div *ngIf="showAtmReadings">
                <span
                  *ngFor="let reading of atmResponse.unfoundedReadingsList"
                  >{{ reading }}</span
                >
              </div>
              <span (click)="showAtmMeters = !showAtmMeters"
                ><b>{{ "atm-not-found-meters" | translate }}:</b>
                {{ atmResponse.unfoundedMeters
                }}<i
                  *ngIf="atmResponse.unfoundedMetersList?.length > 0"
                  class="pointer"
                  [ngClass]="showAtmMeters ? 'fas fa-eye-slash' : 'fas fa-eye'"
                ></i
              ></span>
              <div *ngIf="showAtmMeters">
                <span *ngFor="let meter of atmResponse.unfoundedMetersList">{{
                  meter
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Descarga -->
        <div class="output-files-controls">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="fileTypeSelected ? downloadByFile() : download()"
            [disabled]="
              (fileTypeSelected &&
                !fileToImport &&
                fileTypeSelected != 'UGENA' &&
                (fileTypeSelected != 'POSEIDONIA' ||
                  (fileTypeSelected == 'POSEIDONIA' &&
                    selectedAgrupations?.length == 0))) ||
              (!fileTypeSelected &&
                (!selectedAgrupations ||
                  selectedAgrupations?.length == 0 ||
                  (selectedTimePeriod == 1 &&
                    sessionProfile != PROFILES.ARSON &&
                    limitOutOfRange) ||
                  (selectedTimePeriod == 2 &&
                    sessionProfile != PROFILES.ARSON &&
                    dateOutOfRange)))
            "
          >
            <i class="fas fa-download"></i>
            {{ "download" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
