// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { DeviceTypeService } from "../../../../services/shared/DeviceTypeService.service";
import { MapDeviceMinimalParseService } from "../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { DataAnalysisHeatmapService } from "./data-analysis-heatmap.service";
import { HomeService } from "../../home/home.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  MeterGraphDeviceCoord,
  MeterGraphFoundDevice,
  ConsumptionDataByTimestamp,
} from "../DataAnalysisInterface.type";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../../interfaces/DeviceGlobalInterface.type";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
import {
  GraphMeterData,
  GraphRequestData,
} from "../DataAnalysisInterface.type";
import { RecordData } from "../../../../global/screen-recorder/screen-recorder.type";
// Variables
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-data-analysis-consumption-evolution",
  templateUrl: "./data-analysis-consumption-evolution.component.html",
  styleUrls: ["./data-analysis-consumption-evolution.component.scss"],
})
export class DataAnalysisConsumptionEvolutionComponent
  implements OnInit, OnDestroy
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  numberFormat: string;
  agrupationSub: Subscription;
  componentDataSub: Subscription;

  // Mapa de calor
  heatMapData: ConsumptionDataByTimestamp[][];
  heatMapDataByTimestamp: number[][][];
  heatLayerMin: number = 0.001;
  heatLayerMinLabel: string = formatNumber(
    this.heatLayerMin,
    this.SessionDataService.getCurrentNumberFormat()
  );
  originalHeatMapByTimestamp: number[][][];
  heatMapType: string = "heatMap";
  radius: number = 25;
  heatLayerGradient: object = {
    0: "#FFFFFF",
    0.2: "blue",
    0.4: "turquoise",
    0.6: "lime",
    0.8: "yellow",
    1: "red",
  };
  heatMapDevices: MapDevice[];
  consumptionRange: number;
  consumptionRangeLabel: string;
  consumptionMaxValue: number;
  consumptionMaxValueLabel: string;
  consumptionFilter: boolean = true;
  readingsData: GraphMeterData[];
  // daterangePickerLang: any;
  // daterangePickerRanges: any;
  dateRangeSelected: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("1", "days"),
    endDate: moment().endOf("day"),
  };
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("1", "days"),
    endDate: moment().endOf("day"),
  };
  timestampArray: number[];
  timestampIndex: number = 0;
  heatMapInterval: any;
  evolutionPlaying: boolean = false;
  currentTimestampDate: string;
  currentTimestampHour: string;
  mapRate: number = 1;
  rateOptions = [1, 2, 3, 4];
  boundsUpdateDisabled: boolean;

  // Mapa dispositivos
  deviceMapType: string = "meterGraph";
  mapHeight: number;
  deviceData: MapDevice[];
  gatewaysData: [];
  selectedDevicesCoord: MeterGraphDeviceCoord;
  selectedDevices: number[] = history.state.data;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  recordData: RecordData;
  showRanges: boolean = true;

  // Actualizar
  loadDataIcon: string = "fas fa-database";
  loadDataTitle: string = this.translate.instant("data-load");
  playIcon: string = "fas fa-play";
  playTitle: string = this.translate.instant("show-evolution");
  mapIcon: string = "fas fa-map";
  mapTitle: string = this.translate.instant("map-selection");
  pauseIcon: string = "fas fa-stop";
  pauseTitle: string = this.translate.instant("show-evolution-stop");
  dataLoaded: boolean = false;
  dataLoading: boolean = false;

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @ViewChild("advancedAnalyticsPanel") advancedAnalyticsPanel: ElementRef;
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mapHeight =
      this.advancedAnalyticsPanel?.nativeElement?.offsetHeight - 40;
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    public HomeService: HomeService,
    private DateParserService: DateParserService,
    private deviceMinimalParse: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private DataAnalysisController: DataAnalysisControllerService,
    private DataAnalysisHeatmapService: DataAnalysisHeatmapService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.componentDataSub =
      this.SessionDataService.getComponentData().subscribe((data) => {
        this.heatMapData = data.heatMapData;
        this.heatMapDataByTimestamp = data.heatMapDataByTimestamp;
        this.originalHeatMapByTimestamp = data.originalHeatMapByTimestamp;
        this.consumptionRange = data.consumptionRange;
        this.consumptionRangeLabel = data.consumptionRangeLabel;
        this.consumptionMaxValue = data.consumptionMaxValue;
        this.consumptionMaxValueLabel = data.consumptionMaxValueLabel;
        this.timestampArray = data.timestampArray;
        this.boundsUpdateDisabled = true;
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(
      () =>
        (this.mapHeight =
          this.advancedAnalyticsPanel?.nativeElement?.offsetHeight - 40),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.componentDataSub.unsubscribe();
    clearInterval(this.heatMapInterval);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getMapData();
  }

  // Obtención de los datos del mapa
  getHeatMapData(): void {
    this.DataAnalysisHeatmapService.getHeatMapData(
      this.selectedDevices,
      this.deviceData,
      this.readingsData
    );
  }

  // Obtención de los contadores seleccionados
  selectMeters(selectedDevices: MeterGraphFoundDevice[]): void {
    let newSelectedDevices: number[] = [];

    this.deviceData.map((meter: MapDevice) => {
      if (
        selectedDevices.find(
          (selectedMeter: MeterGraphFoundDevice) => selectedMeter.id == meter.id
        )
      ) {
        meter.selected = true;
      }
    });

    newSelectedDevices = selectedDevices
      .filter((meter: MeterGraphFoundDevice) => {
        let meterTypeByMask: string =
          this.DeviceTypeService.getDeviceTypeByMask(
            parseInt(meter?.tipo),
            meter?.metrologyType
          );
        if (
          !this.selectedDevices?.includes(meter.id) &&
          meterTypeByMask != DEVICE_BY_COMM.LW_UNE_CON
        ) {
          return meter;
        }
      })
      .map((meter: MeterGraphFoundDevice) => {
        return meter.id;
      });

    this.selectedDevices = this.selectedDevices
      ? this.selectedDevices.concat(newSelectedDevices)
      : newSelectedDevices;
  }

  // Obtención de los datos del mapa
  getMapData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let deviceData: MapDevice[] = this.deviceMinimalParse.parseDevices(
            response["body"]["contadores"]
          );

          this.deviceData = deviceData.filter((meter: MapDevice) => {
            let meterTypeByMask: string =
              this.DeviceTypeService.getDeviceTypeByMask(
                meter?.tipo,
                meter?.metrologyType,
                meter?.fabricante
              );
            if (meterTypeByMask != DEVICE_BY_COMM.LW_UNE_CON) {
              return meter;
            }
          });
          this.deviceData.map((meter: MapDevice) => {
            if (this.selectedDevices?.includes(meter.id)) {
              meter.selected = true;
            } else {
              meter.selected = false;
            }
          });
          this.gatewaysData = response["body"]["gateways"];
          if (this.selectedDevices?.length > 0) {
            setTimeout(() => this.mapController.updateSelected(), 0);
          }
        }
      }
    );
  }

  // Actualización de la selección en el mapa
  updateMapSelection(): void {
    this.deviceData.map((meter: MapDevice) => {
      if (this.selectedDevices.includes(meter.id)) {
        meter.selected = true;
      } else {
        meter.selected = false;
      }
    });
    this.mapController
      ? this.mapController.resetMap()
      : this.ReloadComponentService.reload();
  }

  // Obtención de los datos
  loadData(): void {
    this.heatMapDevices = this.deviceData
      .filter((device) => this.selectedDevices.includes(device.id))
      .map((device) => {
        device.selected = false;
        return device;
      });
    this.timestampIndex = 0;
    this.dataLoading = true;
    let readingsData: GraphMeterData[] = [];
    if (this.selectedDevices?.length > 0) {
      let data: GraphRequestData = {
        meterList: this.selectedDevices,
        agrupation: this.currentAgrupation.id,
        fromTimestamp: this.dateRangeSelected.startDate.valueOf(),
        toTimestamp: this.dateRangeSelected.endDate.valueOf(),
        graphType: this.deviceData
          ?.filter((device: MapDevice) =>
            this.selectedDevices.includes(device.id)
          )
          ?.some(
            (device: MapDevice) => device.metrologyType == METROLOGY_TYPE.GAS
          )
          ? 3
          : 2,
      };

      this.DataAnalysisController.getGraphGroup(1, data).subscribe(
        (response) => {
          if (response["code"] == 0 && response["body"]) {
            readingsData = response["body"];
          }
          this.readingsData = readingsData;
          this.dataLoading = false;
          if (this.readingsData.length > 0) {
            this.dataLoaded = true;
            this.getHeatMapData();
            this.recordData = {
              fileName: this.SessionDataService.getCurrentAgrupation()?.name,
              startDate: this.DateParserService.parseDate(
                this.dateRangeSelected.startDate.valueOf(),
                "L"
              ),
              endDate: this.DateParserService.parseDate(
                this.dateRangeSelected.endDate.valueOf(),
                "L"
              ),
            };
          } else {
            this.ToastService.fireToast(
              "warning",
              this.translate.instant("gateway-no-data")
            );
          }
        }
      );
    }
  }

  // Evolución de consumo
  showEvolution(): void {
    clearInterval(this.heatMapInterval);
    this.evolutionPlaying = true;
    if (this.timestampIndex == this.timestampArray.length - 1) {
      this.timestampIndex = 0;
    }
    this.heatMapInterval = setInterval(() => {
      this.timestampIndex =
        this.timestampIndex +
        (this.mapRate == 1
          ? 1
          : this.mapRate == 2
          ? 10
          : this.mapRate == 3
          ? 30
          : 60);
      if (this.timestampIndex < this.timestampArray.length - 1) {
        this.calculateTimestampIndexData();
      } else {
        clearInterval(this.heatMapInterval);
        this.timestampIndex = this.timestampArray.length - 1;
        this.calculateTimestampIndexData();
        this.evolutionPlaying = false;
      }
    }, 10);
  }

  // Parar evolución de consumo
  stopEvolution(): void {
    this.evolutionPlaying = false;
    clearInterval(this.heatMapInterval);
  }

  // Cálculo de datos para el timestamp en curso
  calculateTimestampIndexData(): void {
    let currentTimestampParsed = this.DateParserService.parseDate(
      this.timestampArray[this.timestampIndex],
      "L HH:mm"
    );
    this.currentTimestampDate = currentTimestampParsed.substring(
      0,
      currentTimestampParsed.length - 5
    );
    this.currentTimestampHour = currentTimestampParsed.substring(
      currentTimestampParsed.length - 5
    );
  }

  // Volver a selección de dispositivos
  goToSelection(): void {
    this.stopEvolution();
    this.selectedDevices = [];
    this.updateMapSelection();
    this.dataLoaded = null;
    this.timestampIndex = 0;
  }

  // Actualización de los datos del mapa
  updateMapData(): void {
    this.heatMapDataByTimestamp = [];
    if (this.consumptionFilter) {
      this.heatMapDataByTimestamp = this.originalHeatMapByTimestamp.map(
        (timestampSerie: number[][]) => {
          return timestampSerie.filter((consumption: number[]) => {
            if (consumption[2] <= Math.round(this.consumptionRange)) {
              return true;
            }
          });
        }
      );
    } else {
      this.heatMapDataByTimestamp = [...this.originalHeatMapByTimestamp];
    }
    this.consumptionRangeLabel = formatNumber(
      this.consumptionRange,
      this.numberFormat
    );
  }

  // Reseteo del filtro al cambiar de opción
  resetFilter(consumptionFilter: boolean): void {
    this.consumptionFilter = consumptionFilter;
    this.heatMapDataByTimestamp = [...this.originalHeatMapByTimestamp];
    this.consumptionRange = this.consumptionMaxValue;
    this.consumptionRangeLabel = formatNumber(
      this.consumptionRange,
      this.numberFormat
    );
    setTimeout(() => this.updateMapData(), 0);
  }

  // Actualización de la velocidad de refresco del mapa
  updateMapRate(): void {
    if (this.evolutionPlaying) {
      this.stopEvolution();
      this.showEvolution();
    }
  }
}
