<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ data.action | translate }}
    </h5>
  </div>
  <div class="dialog-body cups-detail-dialog">
    <div
      *ngIf="
        data.action == 'assign' ||
        data.action == 'replace' ||
        data.action == 'subscriber'
      "
    >
      <!-- Listado -->
      <app-material-select
        [fullWidth]="true"
        [title]="data.title"
        [bindValue]="data.bindValue"
        [bindLabel]="data.bindLabel"
        [data]="dropdownData"
        (selectedOption)="data.selected = $event?.id"
        [required]="true"
      ></app-material-select>
    </div>
    <div *ngIf="data.action == 'sensor'">
      <app-table-controller
        [tableId]="'cupsDetailSensors'"
        [onlyTable]="true"
        [export]="false"
        [columns]="sensorTableColumns"
        [rowNumbers]="true"
        [maxReg]="10"
        (tableAction)="tableActions($event.action.name, $event.element)"
        [specialFilter]="true"
        [data]="data.tableData"
      >
      </app-table-controller>
    </div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      *ngIf="
        data.action == 'assign' ||
        data.action == 'replace' ||
        data.action == 'subscriber'
      "
      mat-button
      class="mat-button-icon-text"
      (click)="save()"
      [disabled]="data.dropdownData?.length == 0 || data.selected == null"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
