// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { MeterReviewDialogComponent } from "./meter-review-dialog/meter-review-dialog.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
  TableQuickFilter,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  DeviceReviewData,
  REVIEW_STATES,
  ReviewDevice,
} from "../../../DeviceInterface.type";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-meterslistassign",
  templateUrl: "./meter-review.component.html",
  styleUrls: ["./meter-review.component.scss"],
})
export class ReviewListComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Tabla
  meterToSearch: { columnTitle: string; data: string } = {
    columnTitle: "serial-number",
    data: history.state.data,
  };
  highlightRow: object[] = [
    {
      condition: "OPENED",
      color: "yellow",
      title: "opened",
    },
    { condition: "IN_PROCESS", color: "blue", title: "in-process" },
    { condition: "CLOSED", color: "green", title: "closed" },
  ];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "opened",
        columnSearch: "state",
        condition: { type: "number", rule: 0 },
        active: false,
      },
      {
        name: "in-process",
        columnSearch: "state",
        condition: { type: "number", rule: 1 },
        active: false,
      },
      {
        name: "closed",
        columnSearch: "state",
        condition: { type: "number", rule: 2 },
        active: false,
      },
    ],
  ];
  orderBy: object = { attribute: "timestamp", reverse: true };
  tableSelectedData: ReviewDevice[];
  tableMaxReg: number = 100;
  meterList: ReviewDevice[];
  originalMeterList: ReviewDevice[];
  exportFileName: string =
    this.translate.instant("reviewed-list") +
    " " +
    this.DateParserService.getDate();
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment };
  from: string;
  to: string;
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "review-transfer",
      icon: "fas fa-user-tie",
      disabled: true,
      help: "help-table-map",
    },
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete-review",
    },
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DomControllerService: DomControllerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.dataInitialDate = this.DateParserService.getAll();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.mapSelectorActive = false;
    this.from = from;
    this.to = to;
    let data: DeviceReviewData = {
      agrupation: this.currentAgrupation.id,
      fromTimestamp: from,
      toTimestamp: to,
    };
    this.MeterController.reviewMeterList(data).subscribe((response) => {
      if (response["code"] == 0) {
        let meterList: ReviewDevice[] = response["body"];
        meterList.forEach((meter: ReviewDevice) => {
          meter.nroSerie = meter.meterNroSerie;
          meter.modalInfo = true;
          meter.modalInfoData = {
            title: this.translate.instant("comments"),
            html: `<p>` + meter.comments + `</p>`,
          };
          meter.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            meter.metrologyType,
            meter.meterId
          );
          meter.cupsParsed = meter.cups != meter.nroSerie ? meter.cups : null;
          meter.cupsLink = meter.cupsParsed
            ? "/cups/detalle/" + meter.cupsId
            : null;
          if (meter.state == null) {
            meter.state = 0;
          }
          meter.stateParsed = this.translate.instant(
            REVIEW_STATES[meter.state].toLowerCase().replace("_", "-")
          );
          if (REVIEW_STATES[meter.state]) {
            meter[REVIEW_STATES[meter.state]] = true;
          } else {
            meter["OPENED"] = true;
          }
        });
        this.meterList = meterList;
        this.originalMeterList = meterList;
        this.setColumns();
      }
    });
  }

  // Seteo de columnas
  setColumns(): void {
    let cups: EntityDefinition =
      this.SessionDataService.getCurrentEntityCupsConf().find(
        (column: EntityDefinition) => column.colPosition == 0
      );
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit-review",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "review-transfer",
            tooltip: "review-transfer",
            icon: "fas fa-user-tie",
            visible: { attribute: null, rule: true },
            disabled: true,
          },
          {
            name: "delete-review",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "serial-number",
        data: "meterNroSerie",
        search: "meterNroSerie",
        sort: "meterNroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: cups?.name,
        data: "cupsParsed",
        search: "cupsParsed",
        sort: "cupsParsed",
        visible: cups != null ? true : null,
        link: "cupsLink",
      },
      {
        title: "groups",
        data: "agrupationName",
        search: "agrupationName",
        sort: "agrupationName",
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        modalInfo: true,
        visible: true,
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastReadedTimestampParsed",
        search: "lastReadedTimestampParsed",
        sort: "lastReadedTimestamp",
        date: true,
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, meter: ReviewDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "edit-review":
        this.MaterialDialogService.openDialog(
          MeterReviewDialogComponent,
          meter
        );
        break;
      case "delete-review":
        this.deleteFromReview(meter);
        break;
      case "review-transfer":
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "erase-selection":
        this.deleteFromReview();
        break;
      default:
        break;
    }
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección al contador seleccionado
  showMeter(meter: ReviewDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.meterId
    );
  }

  // Borrado de uno o varios contadores de la lista de revisión
  deleteFromReview(meter?: ReviewDevice): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant(
        meter
          ? "remove-from-check-question"
          : "remove-multiple-from-check-question"
      )
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: number[] = !meter
          ? this.tableSelectedData.map((meter: ReviewDevice) => {
              return meter.meterId;
            })
          : null;
        let url: Observable<object> = meter
          ? this.MeterController.deleteFromReviewMeterList(meter.meterId)
          : this.MeterController.deleteMultipleFromReviewMeterList(data);
        url.subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant(
                meter
                  ? "remove-from-check-sucessfull"
                  : "remove-multiple-from-check-sucessfull"
              )
            );
            this.getData(this.from, this.to);
          }
        });
      }
    });
  }
}
