export const CUSTOM_URLS = [
  { urlText: "gascity", img: "assets/img/logos/gascity.png" },
  { urlText: "sefo", img: "assets/img/logos/sefo.png" },
  {
    urlText: "aqualia",
    img: "assets/img/logos/aqualia.png",
    theme: "water",
  },
  {
    urlText: "nortegas",
    img: "assets/img/logos/nortegas.png",
    theme: "nortegas",
  },
  { urlText: "batchline", img: "assets/img/logos/batchline.jpg" },
  {
    urlText: "santamariadeguia",
    img: "assets/img/logos/santamariadeguia.png",
  },
  {
    appTitle: "Metering",
    appIcon: "assets/img/logos/favicon_constrat.ico",
    loginTitle: "Metering",
    urlText: "constrat",
    img: "assets/img/logos/constrat.png",
    sidebarImg: "assets/img/logos/constrat_white.png",
    footer: {
      footerText: "Constrat",
      footerLink: "https://constrat.gr/el/arxiki/",
      footerContactLink: "info@constrat.gr",
      footerContactTel: "+30 210 6621 1135 -6",
      footerContactPhoto: "",
    },
  },
];
