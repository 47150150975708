import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AudioService {
  context = new AudioContext();
  buffer: any;

  constructor() {}

  play(url: string) {
    let audio = new Audio();
    audio.src = url;
    audio.load();
    audio.play();
  }
}
