<div
  class="graph-controller-container"
  *ngIf="highchartsOptions; else elseBlock"
>
  <!-- ANCHOR Filtros -->
  <div *ngIf="!onlyGraph" class="graph-controller-options">
    <div class="graph-controller-inputs">
      <!-- Selector de rango de fechas -->
      <div class="graph-controller-date" *ngIf="!noDateSelector">
        <app-material-date-selector
          #dateSelector
          [dateRange]="dateRange"
          [disabled]="dateDisabled"
          [maxDate]="maxDate"
          [maxInterval]="maxInterval"
          [initialDateNull]="initialDateNull"
          (dateRangeUpdated)="updateDateRange($event)"
          [hint]="dateHint"
        ></app-material-date-selector>
      </div>

      <!-- Filtros de sumatorio/individual y tipo -->
      <div class="graph-filters">
        <ng-container *ngFor="let filter of filters; index as i">
          <app-material-select
            class="graph-filter"
            [fullWidth]="true"
            [title]="filter.title"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="filter.selected"
            [data]="filter.options"
            (selectedOption)="
              filterFlag.emit({ value: $event.value, filterIndex: i })
            "
          ></app-material-select>
        </ng-container>
      </div>

      <!-- Cargar gráfica -->
      <div *ngIf="!noDateSelector || filters" class="graph-search-button">
        <button
          mat-button
          class="mat-button-icon"
          (click)="loadGraphData($event)"
          [disabled]="dateDisabled"
        >
          <i class="fas fa-search"></i>
        </button>
        <div
          *ngIf="pointDeletion && sessionProfile == PROFILES.ARSON"
          class="graph-search-button-tooltip"
        >
          <div>
            ALT<span>+</span> <i class="fas fa-mouse mouse-left-click"></i>:
            {{ "graph-activate-deletion" | translate }}
          </div>
          <div>
            2<span>x</span><i class="fas fa-mouse mouse-left-click"></i>:
            {{ "graph-delete-point" | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="graph-controller-inputs">
      <!-- ANCHOR Leyenda de datos -->
      <div
        *ngIf="graphLegend"
        class="graph-controller-interpolated-data"
        [innerHTML]="sanitizer.bypassSecurityTrustHtml(graphLegend)"
      ></div>

      <!-- ANCHOR Desplazamiento de series -->
      <div *ngIf="seriesDisplace" class="graph-displace-options">
        <app-material-select
          [title]="'displace-serie' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [selected]="displacePreselect"
          [resetSelection]="resetDisplaceSelection"
          [data]="displaceOptions"
          (selectedOption)="
            displaceOptionSelected = $event.value; displaceGraph()
          "
        ></app-material-select>
      </div>

      <!-- Toggle series -->
      <div *ngIf="seriesToggle">
        <button
          *ngFor="let toggle of seriesToggle"
          mat-button
          class="mat-button-icon-text"
          [title]="(toggle.visible ? 'hide-serie' : 'show-serie') | translate"
          (click)="
            toggle.visible = !toggle.visible; serieToggled.emit(seriesToggle)
          "
        >
          <i [ngClass]="toggle.visible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
          {{ toggle.serie | translate }}
        </button>
      </div>

      <!-- Toggle series -->
      <div *ngIf="leaksActive">
        <button
          mat-button
          class="mat-button-icon-text"
          [title]="'possible-leak' | translate"
          (click)="hideLeaks = !hideLeaks; hideLeaksFlag.emit(hideLeaks)"
        >
          <i [ngClass]="hideLeaks ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
          {{ "possible-leak" | translate }}
        </button>
      </div>

      <!-- Botón para mostrar datos interpolados -->
      <div
        *ngIf="
          (sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION) &&
          interpolatedFlag &&
          !accumulatedSerie
        "
      >
        <button
          mat-button
          class="mat-button-icon-text"
          [color]="showInterpolatedZones ? 'warn' : 'null'"
          (click)="
            showInterpolatedZones = !showInterpolatedZones;
            updateInterpolatedZones()
          "
        >
          <i
            [ngClass]="
              showInterpolatedZones ? 'fas fa-eye-slash' : 'fas fa-eye'
            "
          ></i>
          {{ "show-interpolated" | translate }} ({{
            interpolatedZones?.length / 2
          }})
        </button>
      </div>

      <!-- Ver patrones -->
      <div class="graph-patterns" *ngIf="showPrediction">
        <button
          mat-button
          class="mat-button-icon-text"
          (click)="calculatePrediction()"
          [disabled]="!data?.series[0]?.data"
        >
          {{ "month-prediction" | translate }}
        </button>
      </div>

      <!-- Ver acumulados -->
      <div *ngIf="showCumulative">
        <app-material-select
          [title]="'graph-cumulated-comparison' | translate"
          [bindValue]="'type'"
          [bindLabel]="'name'"
          [selected]="'none'"
          [disabled]="!data?.series[0].data"
          [data]="cumulativeComparison"
          (selectedOption)="showCumulativeSum($event?.type)"
        ></app-material-select>
      </div>

      <!-- ANCHOR Tipo de gráfica -->
      <div class="graph-types" *ngIf="!noTypeSelector">
        <app-material-select
          [title]="'graph-type' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [selected]="'default'"
          [data]="graphTypeOptions"
          (selectedOption)="userGraphType = $event.value; updateGraphType()"
        ></app-material-select>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!avoidInitialLoad">
    <!-- ANCHOR Gráfica -->
    <app-graph
      *ngIf="parsedOptions && parsedChartOptions; else elseBlock"
      [options]="parsedOptions"
      [data]="parsedChartOptions"
      [from]="from"
      [to]="to"
      [colorByPoint]="_colorByPoint"
      [showCumulativeTotal]="showCumulativeTotal"
      [disableShowPoints]="disableShowPoints"
      [chartConstructor]="chartConstructor"
      [yAxisMinRange]="yAxisMinRange"
      [yAxisMaxRange]="yAxisMaxRange"
      [seriesToUpdate]="seriesToUpdate"
    >
    </app-graph
  ></ng-container>
</div>
