import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Trello
import { TrelloApiControllerService } from "../../../../services/server/TrelloApiController.service";

@Component({
  selector: "app-footerbar-dialog",
  templateUrl: "./footerbar-dialog.component.html",
  styleUrls: ["./footerbar-dialog.component.scss"],
})
export class FooterbarDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    public SessionDataService: SessionDataService,
    private TrelloApiController: TrelloApiControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Creación de tarjeta de corrección en Trello
  createTrelloCard(type: string): void {
    let desc: string = this.data.appVersion
      .map((appModule: { module: string; version: string }) => {
        return appModule.module + ": " + appModule.version.trim();
      })
      .join(", ");
    this.TrelloApiController.createCard(
      type == "correction"
        ? "5f859030d9e49a20fb566ec5"
        : "62fcd76e1c1fc73b46d408c1",
      (type == "correction" ? "Corrección en " : "Petición en ") +
        this.router.url,
      desc
    ).subscribe((response: any) => {
      window.open("https://trello.com/c/" + response?.shortLink, "_blank");
    });
  }
}
