// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { formatNumber } from "@angular/common";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";
import { SensoryLeakService } from "../../SensoryLeak.service";
import { DomControllerService } from "../../../../../services/shared/DomControllerService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
import { MapSizeService } from "../../../../../modules/map-module/map-services/MapSizeService.service";
import { DataAnalysisHeatmapService } from "../../../data-analysis/data-analysis-consumption-evolution/data-analysis-heatmap.service";
import { DeviceRouteSelectorService } from "../../../../../services/shared/DeviceRouteSelectorService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../../../interfaces/DeviceGlobalInterface.type";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import {
  ConsumptionDataByTimestamp,
  GraphMeterData,
} from "../../../data-analysis/DataAnalysisInterface.type";
// Componentes
import { GraphControllerComponent } from "../../../../../modules/graph-module/graph-controller/graph-controller.component";
// Variables
import { GRAPH_SUMATORY } from "../../../data-analysis/data-analysis-meter-graph/data-analysis-meter-graph.component";

@Component({
  selector: "app-sensory-leaks-map",
  templateUrl: "./sensory-leaks-map.component.html",
  styleUrls: ["./sensory-leaks-map.component.scss"],
})
export class SensoryLeaksMapComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  entitySub: Subscription;
  comenentDataSub: Subscription;
  readonly GRAPH_SUMATORY = GRAPH_SUMATORY;

  // Mapa
  mapType: string = "leakDetection";
  originalMapData: MapDevice[];
  mapData: MapDevice[];
  devices: MapDevice[];
  preselectSensors: number[];
  mapHeight: number;
  agrupationData: MapDevice[];
  sensorExtraRange: number = 100;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  @ViewChild("leaksPanel") leaksPanel: ElementRef;
  @ViewChild("mapContainer") mapContainer: ElementRef;
  showMeterRange: boolean = true;

  // Mapa de calor
  heatLayerGradient: object = {
    0: "#FFFFFF",
    0.2: "blue",
    0.4: "turquoise",
    0.6: "lime",
    0.8: "yellow",
    1: "red",
  };
  radius: number = 25;
  timestampArray: number[];
  boundsUpdateDisabled: boolean = false;
  showHeatmaps: boolean = false;
  meterReadingsData: GraphMeterData[];
  consumptionData: GraphMeterData[];

  // Mapa de calor consumo
  consumptionHeatLayerName: string = "consumptionHeat";
  consumptionHeatMapData: ConsumptionDataByTimestamp[][];
  consumptionHeatmapDataByTimestamp: number[][][];
  consumptionRange: number;
  consumptionMinValue: number = 0.001;
  consumptionMinValueLabel: string = formatNumber(
    this.consumptionMinValue,
    this.SessionDataService.getCurrentNumberFormat()
  );
  consumptionMaxValue: number;
  consumptionMaxValueLabel: number;
  currentConsumptionHeatLayer: any[];
  consumptionMapLocked: boolean = true;
  consumptionMapBounds: any;
  consumptionHeatMapDataPending: boolean = false;

  // Mapa de calor ruido
  noiseHeatLayerName: string = "noiseHeat";
  noiseHeatMapData: ConsumptionDataByTimestamp[][];
  currentNoiseHeatLayer: any[];
  noiseRange: number;
  noiseMinValue: number = 1;
  noiseMinValueLabel: string = "1";
  noiseMaxValue: number;
  noiseMaxValueLabel: number;
  noiseHeatmapDataByTimestamp: number[][][];
  noiseMapLocked: boolean = false;
  noiseMapBounds: any;

  // Filtro por ruido
  filterData: number[][];
  filterSerie: any;
  filterOptions: any;
  minFilterValue: number;
  maxFilterValue: number;
  sensorData: any;
  showSensorsWithoutData: boolean;
  sensorReadings: any;
  @ViewChild("slider") slider: ElementRef;
  maxNoiseLevel: number = 30;
  uniqueSensorsTimestamps: number[];
  filterSerieReady: boolean = false;

  // Gráfica
  selectedSensors: MapDevice[];
  showMeters: boolean = false;
  highchartsOptions: Options;
  chartOptions: any;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  from: string;
  to: string;
  graphLoaded: boolean = false;
  sumatory: number = 2;
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;
  filterGraphWidth: number = window?.innerWidth > 980 ? 320 : 260;

  // Evolución
  evolutionPlaying: boolean = false;
  evolutionMapInterval: any;
  evolutionIndex: number = 0;
  evolutionRate: number = 4;
  rateOptions = [1, 2, 3, 4];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private DataAnalysisHeatmapService: DataAnalysisHeatmapService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DomControllerService: DomControllerService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private MapSizeService: MapSizeService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SensoryLeakService: SensoryLeakService,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.comenentDataSub = this.SessionDataService.getComponentData().subscribe(
      (data) => {
        switch (data?.action) {
          // Actualización de gráfica
          case "metersGraph":
          case "noiseGraph":
            this.graphLoaded = true;
            this.chartOptions = data.chartOptions;
            if (data.sensorReadings) {
              this.sensorReadings = data.sensorReadings;
            }
            this.DomControllerService.goToElement("#meters-graph-anchor");
            break;
          // Actualización de filtro
          case "thresholdData":
            this.setFilterData(data.data);
            break;
          // Actualización de datos de contadores
          case "metersData":
            if (data.sumatory == GRAPH_SUMATORY.INDIVIDUAL) {
              this.meterReadingsData = data.readings;
              this.getHeatMapData(data.metersInRange);
            } else {
              this.consumptionData = data.readings;
              this.SensoryLeakService.getMeterSeries(
                this.selectedSensors,
                this.consumptionData,
                JSON.parse(JSON.stringify(this.chartOptions)),
                this.sumatory
              );
              // Contadores individuales
              this.SensoryLeakService.getMeterReadings(
                this.from,
                this.to,
                this.selectedSensors,
                this.devices,
                1
              );
            }
            break;
          // Capa de calor actual
          case "heatLayerTimestamp":
            if (this.timestampArray && this.showHeatmaps) {
              this.updateCurrentHeatLayer(data.timestamp);
            }
            break;
          // Resalte de sensor
          case "highlightSensor":
            let graphSensor = data.serie.userOptions.id.replace(
              "sensor-noise-",
              ""
            );
            let sensor = this.mapData?.find(
              (sensor) => sensor.nroSerie == graphSensor
            );
            this.mapController.openElementTooltip(sensor);
            break;
          // Actualización de mapa de calor
          case "heatmapData":
            if (this.consumptionHeatMapDataPending) {
              this.consumptionHeatMapData = data.heatMapData;
              this.consumptionHeatmapDataByTimestamp =
                data.heatMapDataByTimestamp;
              this.consumptionRange = data.consumptionRange;
              this.consumptionMaxValue = data.consumptionMaxValue;
              this.consumptionMaxValueLabel = data.consumptionMaxValueLabel;
              this.timestampArray = data.timestampArray;
              this.consumptionHeatMapDataPending = false;
              this.getHeatMapData(null, true);
            } else {
              this.noiseHeatMapData = data.heatMapData;
              this.noiseHeatmapDataByTimestamp = data.heatMapDataByTimestamp;
              this.noiseRange = data.consumptionRange;
              this.noiseMaxValue = data.consumptionMaxValue;
              this.noiseMaxValueLabel = data.consumptionMaxValueLabel;
              this.boundsUpdateDisabled = true;
            }
            break;
          default:
            break;
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.comenentDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/
  ngAfterViewInit(): void {
    // Ajuste del tamaño del mapa
    setTimeout(() => this.resizeMap(), 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
    this.loadGraph();
  }

  // Ajuste del tamaño del mapa acorde a la visibilidad de las tarjetas
  resizeMap(): void {
    this.mapHeight = this.MapSizeService.calcMapHeight(
      this.leaksPanel,
      null,
      0
    );
    this.leaksPanel?.nativeElement.style.setProperty(
      "--map-height",
      this.mapHeight
    );
  }

  // Obtención de datos
  getData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.agrupationData = this.MapDeviceMinimalParse.parseDevices(
            response["body"]?.contadores
          );
          this.mapData = this.agrupationData.filter(
            (device: MapDevice) =>
              device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR ||
              device.hasAs
          );
          this.originalMapData = [...this.mapData];
          this.devices = this.agrupationData.filter(
            (device: MapDevice) =>
              device.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR ||
              device.hasAs
          );
          this.mapData.map((device) => {
            device.extraRange = device.distanciaAcustica;
          });
          if (this.mapData.length > 0) {
            this.getFilterData();
          }
        }
      }
    );
  }

  // Obtención de los datos del mapa de calor
  getHeatMapData(metersInRange: number[], sensors?: boolean): void {
    if (sensors) {
      this.DataAnalysisHeatmapService.getHeatMapData(
        this.selectedSensors.map((sensor) => sensor.id),
        this.selectedSensors,
        this.selectedSensors.map((sensor) => {
          let readings = this.chartOptions["series"].find((serie) =>
            serie.id.includes(sensor.nroSerie)
          )?.data;
          // let hourReadings = [];
          // for (
          //   let i = parseInt(this.from);
          //   i <= parseInt(this.to);
          //   i += 60 * 60 * 1000
          // ) {
          //   let closeReading = readings.find(
          //     (reading) =>
          //       reading[0] >= i && reading[0] < i + 24 * 60 * 60 * 1000
          //   );
          //   if (closeReading) {
          //     hourReadings.push([i, closeReading[1]]);
          //   } else {
          //     hourReadings.push([i, null]);
          //   }
          // }
          return {
            meterId: sensor.id,
            nroSerie: sensor.nroSerie,
            readings: readings,
          };
        })
      );
    } else {
      this.consumptionHeatMapDataPending = true;
      this.DataAnalysisHeatmapService.getHeatMapData(
        metersInRange,
        this.devices,
        this.meterReadingsData
      );
    }
  }

  // Actualización de capa de calor actual
  updateCurrentHeatLayer(
    currentTimestamp: number,
    timestampIndex?: number
  ): void {
    if (timestampIndex == null) {
      timestampIndex = this.timestampArray.findIndex(
        (timestamp) => timestamp == currentTimestamp
      );
    }
    this.currentConsumptionHeatLayer =
      this.consumptionHeatmapDataByTimestamp[timestampIndex];
    this.currentNoiseHeatLayer =
      this.noiseHeatmapDataByTimestamp[timestampIndex];
  }

  // Obtención de los datos para filtrado por umbral
  getFilterData(): void {
    this.SensoryLeakService.getTresholdData(this.mapData);
  }

  setFilterData(
    data: { id: string; contador: number; value: number; timestamp: number }[]
  ): void {
    this.filterData = [];
    let sensorData = data.filter((sensor) => sensor.value != null);
    let counts = {};
    sensorData.forEach((x) => {
      counts[x.value] = (counts[x.value] || 0) + 1;
    });
    for (let value in counts) {
      this.filterData.push([parseFloat(value), counts[value]]);
    }
    this.sensorData = sensorData;

    // Slider
    if (this.filterData?.length > 0) {
      this.minFilterValue = this.filterData[0][0];
      this.maxFilterValue = this.filterData[this.filterData.length - 1][0];
    }

    this.filterOptions = {
      floor: this.minFilterValue ? this.minFilterValue : 0,
      ceil: this.maxFilterValue ? this.maxFilterValue : 0,
    };

    // Serie
    this.filterSerie = {
      series: [
        // Ruido
        {
          id: "noise",
          type: "column",
          fillOpacity: 0.2,
          data: this.filterData,
          tooltip: {
            enabled: false,
          },
          dataLabels: {
            enabled: true,
            align: "center",
            verticalAlign: "top",
          },
          yAxis: 0,
          color: "#ef5350",
        },
      ],
      min: 0,
      xAxisType: "linear",
      xAxisOrdinal: false,
    };
    setTimeout(() => (this.filterSerieReady = true), 0);
  }

  // Actualización de sensores filtrados
  updateFilteredSensors(): void {
    this.selectedSensors = [];
    this.preselectSensors = null;
    this.slider.nativeElement.style.setProperty(
      "--slider-color-break",
      Math.round(
        ((this.maxFilterValue + this.minFilterValue) * 100) /
          (2 * (this.filterOptions.ceil - this.filterOptions.floor))
      ) + "%"
    );
    this.mapData = this.originalMapData.map((sensor) => {
      let sensorReading = this.sensorData.find(
        (reading) => reading.contador == sensor.id
      );
      if (sensorReading) {
        sensor.filter =
          sensorReading.value >= this.minFilterValue &&
          sensorReading.value <= this.maxFilterValue
            ? null
            : sensorReading.value >= this.minFilterValue
            ? "over"
            : "below";
      } else if (this.minFilterValue != this.filterData[0][0]) {
        sensor.filter = "below";
      } else {
        sensor.filter = null;
      }
      return sensor;
    });
    this.forceMapUpdate();
  }

  forceMapUpdate(): void {
    // Forzado de borrado de capas para evitar error de capas fantasma
    this.mapController.mapComponent.eraseLayers();
    // Actualización de mapa
    this.mapController.resetLayers();
    // Activación de todas las capas
    this.mapController.activateAllLayers =
      !this.mapController.activateAllLayers;
  }

  // Acciones del mapa
  mapActions(action: string, sensor: MapDevice): void {
    switch (action) {
      case "select":
        sensor.selected = !sensor.selected;
        this.mapController.updateSelected();
        this.selectedSensors = this.mapData.filter((sensor) => sensor.selected);
        this.preselectSensors = this.selectedSensors.map((sensor) => sensor.id);
        break;
      case "goTo":
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("question-show-meter") +
            " " +
            sensor.nroSerie +
            "?"
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.router.navigate([
              this.DeviceRouteSelectorService.getDeviceRouteUrl(
                sensor.metrologyType,
                sensor.id
              ),
            ]);
          }
        });
        break;
      default:
        break;
    }
  }

  // Actualización de mapa
  updateMapData(): void {
    this.mapData.map(
      (device: MapDevice) =>
        (device.extraRange =
          (device.distanciaAcustica * this.sensorExtraRange) / 100)
    );
    this.mapController.resetLayers(false, true);
  }

  updateMeterRangeVisual(): void {
    setTimeout(() => this.mapController.resetLayers(false, true), 0);
  }

  // Actualización de selección
  updateSelected(sensors: MapDevice[]): void {
    this.selectedSensors = sensors;
    this.preselectSensors = this.selectedSensors.map((sensor) => sensor.id);
    this.mapController.mapComponent.deleteDrawedPolygon();
  }

  // Actualización de selección de sensores
  updateMapSelection(selectedSensors: MapDevice[]): void {
    this.selectedSensors = selectedSensors;
    this.originalMapData.map(
      (sensor) =>
        (sensor.selected = this.selectedSensors.some(
          (selectedSensor) => selectedSensor.id == sensor.id
        ))
    );
    this.mapController.updateSelected();
  }

  // Creación de la gráfica
  loadGraph(): void {
    this.highchartsOptions = this.SensoryLeakService.setHighchartsOptions();
    this.chartOptions = { series: [{ id: "", data: [] }] };
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    if (this.selectedSensors?.length > 0) {
      this.consumptionData = null;
      this.meterReadingsData = null;
      this.sumatory = GRAPH_SUMATORY.SUM;
      this.from = from;
      this.to = to;
      this.SensoryLeakService.loadGraphData(
        this.from,
        this.to,
        this.selectedSensors
      );
    }
  }

  // Visualización de contadores
  showMetersGraph(): void {
    this.SensoryLeakService.getMeterReadings(
      this.from,
      this.to,
      this.selectedSensors,
      this.devices,
      2
    );
  }

  // Alternar gráfica de contadores suma/individual
  toggleMetersGraph(): void {
    this.sumatory =
      this.sumatory == GRAPH_SUMATORY.INDIVIDUAL
        ? GRAPH_SUMATORY.SUM
        : GRAPH_SUMATORY.INDIVIDUAL;
    this.SensoryLeakService.getMeterSeries(
      this.selectedSensors,
      this.sumatory == GRAPH_SUMATORY.INDIVIDUAL
        ? this.meterReadingsData
        : this.consumptionData,
      JSON.parse(JSON.stringify(this.chartOptions)),
      this.sumatory
    );
  }

  // Actualización de hover de gráfica
  updateGraphHover(): void {
    let serieIndex = this.chartOptions.series.findIndex(
      (serie) => serie.id.includes("consumption") || serie.id.includes("meter")
    );
    let pointIndex = this.chartOptions.series[serieIndex].data.findIndex(
      (point: number[]) => point[0] >= this.timestampArray[this.evolutionIndex]
    );
    this.graphController.hoverIndex(pointIndex, serieIndex);
  }

  // Evolución de consumo
  showEvolution(): void {
    clearInterval(this.evolutionMapInterval);
    this.evolutionPlaying = true;
    let maxTimestamp = this.timestampArray.length - 1;
    if (this.evolutionIndex >= maxTimestamp) {
      this.evolutionIndex = 0;
    }
    this.evolutionMapInterval = setInterval(() => {
      this.evolutionIndex =
        this.evolutionIndex +
        (this.evolutionRate == 1
          ? 30
          : this.evolutionRate == 2
          ? 60
          : this.evolutionRate == 3
          ? 90
          : 120);
      if (this.evolutionIndex <= maxTimestamp) {
        this.updateCurrentHeatLayer(this.timestampArray[this.evolutionIndex]);
        this.updateGraphHover();
      } else {
        this.stopEvolution();
      }
    }, 1000 / this.evolutionRate);
  }

  // Actualización de velocidad de evolución
  updateEvolutionRate(): void {
    if (this.evolutionPlaying) {
      this.showEvolution();
    }
  }

  // Parar evolución de consumo
  stopEvolution(): void {
    this.evolutionPlaying = false;
    clearInterval(this.evolutionMapInterval);
  }

  // Actualización de encuadre del mapa
  boundsUpdate(newBounds: any, map: string): void {
    if (map == "consumption" && !this.consumptionMapLocked) {
      this.noiseMapBounds = newBounds;
    } else if (map == "noise" && !this.noiseMapLocked) {
      this.consumptionMapBounds = newBounds;
    }
  }

  // Actualización de mapa de calor
  updateHeatmap(map: MapControllerComponent): void {
    map.resetLayers();
  }

  // Activación de mapas de calor
  activateHeatmaps(): void {
    this.showHeatmaps = true;
    this.mapData = null;
    this.filterSerieReady = false;
    setTimeout(() => this.updateCurrentHeatLayer(null, 0), 100);
  }

  // Desactivación de mapas de calor
  deactivateHeatmaps(): void {
    this.showHeatmaps = false;
    this.currentNoiseHeatLayer = null;
    this.currentConsumptionHeatLayer = null;
    setTimeout(() => {
      this.mapData = [...this.originalMapData];
      setTimeout(() => (this.filterSerieReady = true), 0);
    }, 100);
  }
}
