import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { ATTRIBUTE_NAME } from "../../device-new/device-new-attributes";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";

export const METER_IMPORT_COLUMNS = [
  {
    title: ATTRIBUTE_NAME.NRO_SERIE,
    info: "nro-serie-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.DEV_TYPE,
    info: "dev-type-import",
    required: true,
    profilesList: [],
    preselect: [],
    action: { title: "show-detail", icon: "fas fa-eye", data: "show-detail" },
  },
  {
    title: ATTRIBUTE_NAME.MANUFACTURER,
    info: "manufacturer",
    required: true,
    profilesList: [],
    preselect: [],
    action: { title: "show-detail", icon: "fas fa-eye", data: "show-detail" },
  },
  {
    title: ATTRIBUTE_NAME.ALIAS,
    info: "alias-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.LORAWAN_VERSION,
    info: "lora-version-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.DEV_EUI,
    info: "dev-eui-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.APP_EUI,
    info: "app-eui-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.NETWORK_KEY,
    info: "network-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.APPLICATION_KEY,
    info: "application-key-import",
    extraInfo: "only-lora",
    required: true,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.TX_POWER,
    info: "tx-import",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.MIN_SF,
    info: "sf-import",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.LONGITUDE,
    info: "longitude-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.LATITUDE,
    info: "latitude-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.RF_MODULE,
    info: "rf-module",
    required: false,
    profilesList: [],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
      DEVICE_BY_COMM.MBUS,
      DEVICE_BY_COMM.UNE,
    ],
  },
  {
    title: ATTRIBUTE_NAME.RF_MODULE,
    info: "serial-number-conversor",
    required: true,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.EK280, DEVICE_BY_COMM.PLUM],
  },
  {
    title: ATTRIBUTE_NAME.COMMENTS,
    info: "comments",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.CYBLE5_ENC_KEY,
    info: "encrypt-key",
    extraInfo: "Itron Cyble5IoT",
    required: true,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.CYBLE5],
  },
  {
    title: ATTRIBUTE_NAME.CYBLE5_AUTH_KEY,
    info: "authentication-key",
    extraInfo: "Itron Cyble5IoT",
    required: true,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.CYBLE5],
  },
  {
    title: ATTRIBUTE_NAME.MBUS_ADDRESS,
    info: "mbus-address",
    extraInfo: "only-mbus",
    required: true,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.MBUS, DEVICE_BY_COMM.LW_MBUS],
  },
  {
    title: ATTRIBUTE_NAME.MBUS_KEY,
    info: "mbus-key",
    extraInfo: "only-mbus",
    required: true,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.MBUS, DEVICE_BY_COMM.LW_MBUS],
  },
  {
    title: ATTRIBUTE_NAME.MBUS_ID,
    info: "mbus-id",
    extraInfo: "only-mbus",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.MBUS, DEVICE_BY_COMM.LW_MBUS],
  },
  {
    title: ATTRIBUTE_NAME.MBUS_ADDRESS,
    info: "une-key",
    extraInfo: "une-only",
    required: true,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.UNE],
  },
  {
    title: ATTRIBUTE_NAME.ADR_PERMISSION,
    info: "adr-import",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
    ],
  },
  {
    title: ATTRIBUTE_NAME.AGRUPATION,
    info: "agrupation-import",
    required: false,
    profilesList: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
    ],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
      DEVICE_BY_COMM.MBUS,
      DEVICE_BY_COMM.UNE,
      DEVICE_BY_COMM.NO_LORA_NO_COM,
    ],
  },
  {
    title: ATTRIBUTE_NAME.AGRUPATION,
    info: "agrupation-import",
    required: true,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.EK280, DEVICE_BY_COMM.PLUM],
  },
  {
    title: ATTRIBUTE_NAME.ACTIVATE,
    info: "activate-import-meter",
    required: false,
    profilesList: [
      PROFILES.ARSON,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_ENTIDAD,
    ],
    preselect: [
      DEVICE_BY_COMM.LW,
      DEVICE_BY_COMM.LW_MBUS,
      DEVICE_BY_COMM.CYBLE5,
      DEVICE_BY_COMM.MBUS,
      DEVICE_BY_COMM.UNE,
      DEVICE_BY_COMM.NO_LORA_NO_COM,
    ],
  },
  {
    title: ATTRIBUTE_NAME.CODE,
    info: "ek-code",
    extraInfo: "only-ek",
    required: true,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.SIM_ICCID,
    info: "ek-iccid",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.DESCRIPTION,
    info: "ek-description",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.WINDOW_1,
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.DURATION_1,
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.WINDOW_2,
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.DURATION_2,
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.WINDOW_3,
    info: "window-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },
  {
    title: ATTRIBUTE_NAME.DURATION_3,
    info: "duration-import",
    extraInfo: "only-ek",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },

  {
    title: ATTRIBUTE_NAME.FILE_NRO_SERIE,
    info: "ek-file-serial-number",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },

  {
    title: ATTRIBUTE_NAME.RED,
    info: "ek-connected",
    required: false,
    profilesList: [],
    preselect: [DEVICE_BY_COMM.EK280],
  },

  {
    title: ATTRIBUTE_NAME.EXCLUDE,
    info: "ek-file-exclude",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.EK280],
  },

  {
    title: ATTRIBUTE_NAME.LAST_READED_VALUE,
    info: "last-know-value",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
    info: "date-last-value",
    extraInfo: "date-format",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.SEAL,
    info: "seal",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.TIMEZONE,
    info: "timezone-import",
    required: false,
    profilesList: [],
    preselect: [],
  },
  {
    title: ATTRIBUTE_NAME.VALVE_STATE,
    info: "valve-state",
    required: false,
    profilesList: [PROFILES.ARSON],
    preselect: [DEVICE_BY_COMM.LW],
    action: {
      title: "show-detail",
      icon: "fas fa-eye",
      data: "show-valve-info",
    },
  },
];

export interface ImportResponseDevice {
  // BackEnd
  nroSerie: string;
  idFabricante: number;
  idDevType: number;
  uploadState: boolean;
  codError: number;

  // FrontEnd
  manufacturer?: string;
  devType?: string;
  responseCodeText?: string;
}
