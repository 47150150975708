import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { CoverageControllerService } from "../../../../services/server/CoverageController.service";
// Interfaces
import { CoverDevice, Test } from "../CoverageInterface.type";

@Injectable({
  providedIn: "root",
})
export class CoverageTestService {
  constructor(
    private CoverageController: CoverageControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  // Borrado de test erróneo
  removeTest(test: CoverDevice): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-test-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: Test = {
          longitude: test.longitud,
          latitude: test.latitud,
          device: test.devEui,
        };
        this.CoverageController.deleteTest(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.SessionDataService.sendReloadPanelFlag();
            setTimeout(() => {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("test-deleted")
              );
            }, 0);
          }
        });
      }
    });
  }
}
