import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { MaterialSelectGroup } from "../../../../../modules/material-module/MaterialInterface.type";
import { EntityAppEui } from "../../GatewayInterface.type";

@Component({
  selector: "app-gateways-appeui-dialog",
  templateUrl: "./gateways-appeui-dialog.component.html",
  styleUrls: ["./gateways-appeui-dialog.component.scss"],
})
export class GatewaysAppeuiDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: EntityAppEui[];
  appEuiSelected: EntityAppEui[];
  appEuiInput: string;
  groups: MaterialSelectGroup[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private requestQueue: RequestQueueService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.groups = [];
    this.data.forEach((appEui: EntityAppEui) => {
      if (
        !this.groups.find(
          (group: MaterialSelectGroup) => group.value == appEui.manufacturerText
        )
      ) {
        this.groups.push({
          value: appEui.manufacturerText,
          name: appEui.manufacturerText,
          disabled: false,
        });
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de nuevo App EUI
  saveAppEui(): void {
    let appEuiList: EntityAppEui[] = [];

    // Nuevo App EUI
    if (this.appEuiInput && this.appEuiInput != "") {
      appEuiList.push({ appEui: this.appEuiInput, manufacturerText: null });
    }

    // App EUI seleccionados
    if (this.appEuiSelected && this.appEuiSelected.length > 0) {
      appEuiList = appEuiList.concat(this.appEuiSelected);
    }

    // Envío a tareas en progreso
    if (appEuiList.length > 0) {
      let currentEntity = this.SessionDataService.getCurrentEntity();
      this.GatewayController.getEntityGateways(currentEntity.id).subscribe(
        (response) => {
          if (response["code"] == 0) {
            let entityGateways = response["body"];
            let data = [];
            entityGateways.forEach((gateway: any) => {
              appEuiList.forEach((appEui: any) => {
                data.push({
                  id: gateway.id,
                  unidadVenta: gateway.unidadVenta,
                  appEui: appEui.appEui,
                  manufacturer: appEui.manufacturer,
                  entity: currentEntity.entity,
                  entityId: currentEntity.id,
                });
              });
            });
            if (data?.length > 0) {
              this.requestQueue.setTask("saveAppEui", data);
            } else {
              this.ToastService.fireToast(
                "error",
                this.translate.instant("appeui-no-gateways")
              );
            }
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        }
      );
    }
  }
}
