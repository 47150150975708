<div *ngIf="meters && gateways; else elseBlock" class="map-controller">
  <div class="map-options">
    <!-- ANCHOR Menú de opciones del mapa -->
    <div *ngIf="!hideMenu" class="map-controller-menu">
      <app-material-panel-menu
        [withoutFormat]="true"
        [options]="mapMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>

    <!-- Fijar selección en mapa -->
    <div
      *ngIf="mapType == 'standard' && selectionAvailable"
      class="map-controller-pin-selected"
    >
      <button
        mat-button
        class="mat-button-icon-text"
        (click)="selectedPinned = !selectedPinned; pinSelected(true)"
      >
        <i class="fas fa-thumbtack">
          <i *ngIf="selectedPinned" class="fas fa-slash"></i
        ></i>

        {{
          (selectedPinned ? "unpin-selection-on-map" : "pin-selection-on-map")
            | translate
        }}
      </button>
      <button
        mat-button
        class="mat-button-icon-text"
        (click)="resetSelection()"
        [disabled]="selectedPinned"
      >
        <i class="fas fa-undo-alt"></i>
        {{ "undo-selection" | translate }}
      </button>
    </div>

    <!-- ANCHOR Filtros de mapa principal -->
    <div class="map-controller-filter" *ngIf="filter">
      <app-map-filters-main
        [filterType]="filter"
        [metersOriginalArray]="metersOriginalArray"
        (searchResult)="searchUpdate($event)"
      >
      </app-map-filters-main>
    </div>

    <!-- ANCHOR Búsqueda de asociación -->
    <div
      class="map-controller-option-container"
      *ngIf="searchAssociationActive"
    >
      <app-material-select
        [fullWidth]="true"
        [filter]="true"
        [title]="'association' | translate"
        [virtual]="true"
        [bindValue]="'id'"
        [bindLabel]="'description'"
        [data]="mapSearchAssociationData"
        (selectedOption)="mapSearchAssociationSelected = $event?.id"
      ></app-material-select>
      <i
        class="fas fa-search pointer"
        [title]="'search' | translate"
        (click)="searchForAssociation()"
      ></i>
    </div>

    <!-- ANCHOR Búsqueda de dispositivo -->
    <div class="map-controller-option-container" *ngIf="searchActive">
      <app-material-select
        [fullWidth]="true"
        [filter]="true"
        [multiple]="true"
        [groups]="mapSearch.groups"
        [title]="mapSearch.title"
        [virtual]="true"
        [bindValue]="mapSearch.bindValue"
        [bindLabel]="mapSearch.bindLabel"
        [bindGroup]="mapSearch.bindGroup"
        [data]="mapSearch.data"
        (selectedOption)="mapSearchData = $event"
      ></app-material-select>
      <i
        class="fas fa-paste pointer"
        [title]="'select-by-clipboard' | translate"
        (click)="filterByClipboard()"
      ></i>
      <i
        class="fas fa-search pointer"
        [title]="'search' | translate"
        (click)="searchForDevice()"
      ></i>

      <!-- Coincidencia de columnas -->
      <div *ngIf="mapSearchShowFileColumns" class="map-filter-file-columns">
        <span>{{ "filter-table-columns" | translate }}</span>
        <app-material-select
          [title]="'file-columns' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [data]="mapSearchFileColumnOptions"
          (selectedOption)="mapSearchFilterFileColumnIndex = $event?.value"
        ></app-material-select>
        <app-material-select
          [title]="'table-columns' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [data]="mapSearchMapColumnOptions"
          (selectedOption)="mapSearchFilterMapColumnIndex = $event?.value"
        ></app-material-select>
        <div>
          <button
            mat-button
            class="mat-button-icon"
            (click)="searchForDevice(true)"
            [disabled]="
              mapSearchFilterMapColumnIndex == null ||
              mapSearchFilterFileColumnIndex == null
            "
          >
            {{ "accept" | translate }}
          </button>
          <button
            mat-button
            class="mat-button-icon"
            (click)="mapSearchShowFileColumns = false"
          >
            {{ "cancel" | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- ANCHOR KML -->
    <div class="hidden">
      <input
        #importKmlPointInput
        class="hidden"
        type="file"
        accept=".kml,.kmz"
        id="file"
        (change)="fileChanged($event, 'point')"
      />
      <input
        #importKmlPolygonInput
        class="hidden"
        type="file"
        accept=".kml,.kmz"
        id="file"
        (change)="fileChanged($event, 'polygon')"
      />
      <input
        #importKmlImageInput
        type="file"
        accept=".kml,.kmz,.png"
        id="file"
        (change)="fileChanged($event, 'image')"
        multiple
      />
    </div>

    <!-- ANCHOR Shapefile -->
    <div class="hidden">
      <input
        #importShapefileInput
        type="file"
        accept=".zip"
        id="file"
        (change)="fileChanged($event, 'line')"
      />
    </div>

    <!-- ANCHOR Grabador de pantalla -->
    <div *ngIf="recorderActive" class="map-recorder">
      <app-screen-recorder
        (recording)="fullScreen($event)"
        [recordData]="recordData"
        [recorderType]="'map'"
      >
      </app-screen-recorder>
    </div>
  </div>

  <!-- ANCHOR Activación de selección -->
  <div *ngIf="agrupationEditionActive" class="map-selector-options">
    <div class="map-selector-options-title">
      <i class="fas fa-map"></i>
      <span>{{ "agrupations" | translate }}</span>
    </div>
    <!-- Scroll virtual -->
    <cdk-virtual-scroll-viewport
      #cdkVirtualScrollViewPort
      class="map-selector-virtual"
      itemSize="20"
    >
      <!-- Agrupaciones -->
      <div
        *cdkVirtualFor="let agrupation of agrupationList"
        class="map-selector-agrupation pointer"
        [ngClass]="{
          'map-selected-agrupation': selectedAgrupation == agrupation.id
        }"
        (mouseover)="
          !selectedAgrupation ? updateSelectedAgrupation(agrupation.id) : null
        "
        (click)="
          selectedAgrupation = agrupation.id;
          updateSelectedAgrupation();
          focusAgrupation()
        "
      >
        {{ agrupation.name }}
      </div>
    </cdk-virtual-scroll-viewport>
    <!--  Comprobación de contorno -->
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="selectedAgrupation != null ? editAgrupationPolygon() : null"
      title="{{ 'check-agrupation-outline' | translate }}"
      [disabled]="selectedAgrupation == null"
    >
      <i class="fas fa-draw-polygon"></i>
      <span>{{ "check-agrupation-outline" | translate }}</span>
    </button>
  </div>

  <!-- Aviso de selección por kml -->
  <div *ngIf="kmlAssociationsActive" class="map-kml-selection-help">
    <div>
      <span class="keyboardkey">Shift</span>
      <span>+</span>
      <i class="fas fa-mouse mouse-left-click"></i>
      : {{ "kml-select" | translate }}
    </div>
    <div>
      <span class="keyboardkey">Ctrl</span>
      <span class="keyboardkey">Shift</span>
      <span>+</span>
      <i class="fas fa-mouse mouse-left-click"></i>
      : {{ "kml-select-add" | translate }}
    </div>
  </div>

  <!-- ANCHOR Mapa -->
  <app-map
    *ngIf="mapLayersData"
    [data]="mapLayersData"
    [mapType]="mapType"
    [bounds]="bounds"
    [fitBounds]="fitBounds"
    (boundsUpdate)="moveFlag($event)"
    [mapHeight]="mapHeight"
    [initialZoom]="initialZoom"
    [disableLegend]="disableLegend"
    [disableMeasure]="disableMeasure"
    [disableCirclePane]="agrupationPolygonActive"
    [searchAdress]="searchAdress"
    [updateHeatMapFlag]="updateHeatMapFlag"
    [mapOnly]="mapOnly"
    (resetRequest)="resetMap(true)"
    [boundsUpdateDisabled]="boundsUpdateDisabled"
    (activeLayersFlag)="activeLayersFlag.emit($event)"
    [activeLayers]="activeLayers"
    [activateAllLayers]="activateAllLayers"
    [freeDrawEnabled]="freeDrawEnabled"
    (drawedPolygon)="getDevicesWithin($event)"
    (editedAgrupationPolygon)="updateAgrupationPolygon($event)"
    [agrupationEditionActive]="agrupationEditionActive"
    [drawAgrupationOutline]="_drawAgrupationOutline"
    (editionActiveFlag)="editionActive = $event"
    (updateDeviceLocationFlag)="updateDeviceLocation($event)"
  >
  </app-map>
</div>
