// @angular
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
// Servicios propios
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { NavigationHelperService } from "../../../../services/shared/NavigationHelperService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { GatewayMeter } from "../GatewayInterface.type";
import { Gateway } from "../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { GatewayRelaunchFramesDialogComponent } from "./gateway-relaunch-frames-dialog/gateway-relaunch-frames-dialog.component";

@Component({
  selector: "app-gateway-relaunch-frames",
  templateUrl: "./gateway-relaunch-frames.component.html",
  styleUrls: ["./gateway-relaunch-frames.component.scss"],
})
export class GatewayRelaunchFramesComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  gatewayId: number;
  gateway: Gateway;

  // Table
  tableMaxReg: number = 50;
  deviceList: GatewayMeter[];
  tableSelectedData: GatewayMeter[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupationName",
      search: "agrupationName",
      sort: "agrupationName",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "rf-module",
      data: "rfModule",
      search: "rfModule",
      sort: "rfModule",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private MaterialDialogService: MaterialDialogService,
    private NavigationHelperService: NavigationHelperService,
    private route: ActivatedRoute
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga del componente
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = parseInt(this.route.snapshot.paramMap.get("id"));
    this.gateway = history.state.data;
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.GatewayController.getGatewayAllDevices(this.gatewayId).subscribe(
      (response) => {
        let deviceList: GatewayMeter[] = [];
        if (response["code"] == 0) {
          deviceList = response["body"];
          deviceList.forEach((device: GatewayMeter) => {
            device.agrupationName = device.agrupation
              ? this.NavigationHelperService.findAgrupationInAllClients(
                  "id",
                  device.agrupation
                )?.agrupation?.name
              : null;
          });
        }
        this.deviceList = deviceList.sort((a, b) =>
          a.agrupationName.localeCompare(b.agrupationName)
        );
      }
    );
  }

  // Modal relanzamiento de tramas
  relaunchFrames(): void {
    this.MaterialDialogService.openDialog(GatewayRelaunchFramesDialogComponent, {
      selected: this.tableSelectedData,
      all: this.deviceList,
      gateway: this.gateway,
    });
  }
}
