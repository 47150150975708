import { Component, Input, OnInit } from "@angular/core";
// HighCharts
import * as Highcharts from "highcharts/highstock";
require("highcharts/modules/bullet")(Highcharts);
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
// Variables
import { GRAPH_CONFIG } from "../GRAPH_CONFIG";

@Component({
  selector: "app-graph-bullet",
  templateUrl: "./graph-bullet.component.html",
  styleUrls: ["./graph-bullet.component.scss"],
})
export class GraphBulletComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Gráfica
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    this._data = data;
    if (this._data && this._width) {
      this.chartOptions = null;
      setTimeout(() => this.setChartOptions(), 0);
    }
  }
  _data: any;
  @Input()
  get width(): any {
    return this._width;
  }
  set width(width: any) {
    this._width = width;
    if (this._data && this._width) {
      this.chartOptions = null;
      setTimeout(() => this.setChartOptions(), 0);
    }
  }
  _width: number;
  @Input() height: number;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag: boolean = false;
  chartConstructor: string = "stockChart";
  chartOptions: any;
  options: any = {};

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private translate: TranslateService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Ajuste de opciones de gráfica
    let defaultOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );

    for (let attribute in defaultOptions.lang) {
      if (defaultOptions.lang[attribute].constructor === Array) {
        defaultOptions.lang[attribute] = defaultOptions.lang[attribute].map(
          (data: string) => {
            return this.translate.instant(data);
          }
        );
      } else {
        defaultOptions.lang[attribute] = this.translate.instant(
          defaultOptions.lang[attribute]
        );
      }
    }

    this.options = {
      accessibility: defaultOptions.accessibility,
      lang: defaultOptions.lang,
      tooltip: {
        outside: true,
      },
    };

    Highcharts.setOptions(this.options);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de las opciones de la gráfica
  setChartOptions(): void {
    this.chartOptions = {
      chart: {
        animate: false,
        backgroundColor: "transparent",
        borderWidth: 0,
        width: this._width,
        height: this.height ? this.height : 70,
        style: {
          overflow: "visible",
        },
        inverted: true,
        type: "bullet",
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        title: null,
        gridLineWidth: 0,
        plotBands: this._data.plotBands,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          grouping: false,
          pointPadding: 0,
          borderWidth: 5,
          borderColor: "#666",
          borderRadius: 0,
          color: "#666",
          targetOptions: {
            color: "black",
            width: "200%",
          },
          enableMouseTracking: false,
        },
      },
      series: this._data.series,
    };

    this.updateFlag = true;
  }
}
