import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ViewportScroller } from "@angular/common";
// Componente
import { TableControllerComponent } from "../../../modules/table-module/table-controller/table-controller.component";

@Component({
  selector: "app-map-table-selector",
  templateUrl: "./map-table-selector.component.html",
  styleUrls: ["./map-table-selector.component.scss"],
})
export class MapTableSelectorComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  tableFilteredByMap: boolean = false;
  @Input() dataList: any[];
  @Input() originalDataList: any[];
  @Input() table: TableControllerComponent;
  @Input() selectedData: any[];
  @Input() filterAttribute: string;
  @Input() anchorToScroll: string;
  @Output() updateData = new EventEmitter<any>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private viewportScroller: ViewportScroller) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Filtrado de tabla por contadores seleccionados
  getDataSelected() {
    this.tableFilteredByMap = true;
    this.dataList?.map((data: any) => (data.selected = false));
    this.dataList = this.originalDataList.filter((element: any) =>
      this.selectedData.find(
        (selectedElement: any) =>
          selectedElement[this.filterAttribute] == element[this.filterAttribute]
      )
    );
    this.updateData.emit(this.dataList);
    this.table.resetRowSelection();
    setTimeout(
      () => this.viewportScroller.scrollToAnchor(this.anchorToScroll),
      0
    );
  }

  // Reseteo de los datos de la tabla tras filtrado de mapa
  resetMapTableFilter() {
    this.tableFilteredByMap = false;
    this.dataList = [...this.originalDataList];
    this.updateData.emit(this.dataList);

    setTimeout(() => {
      this.table.resetRowSelection();
      this.viewportScroller.scrollToAnchor(this.anchorToScroll);
    }, 0);
  }
}
