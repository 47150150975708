import { MeterService } from './../../meter/MeterService.service';
import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { MaterialSelectOption } from "../../../../../modules/material-module/MaterialInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { DeviceActivationData } from "../../DeviceInterface.type";

@Component({
  selector: "app-device-activation-dialog",
  templateUrl: "./device-activation-dialog.component.html",
  styleUrls: ["./device-activation-dialog.component.scss"],
})
export class DeviceActivationDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  agrupationSelectOptions: MaterialSelectOption[];
  initialSelection: string;
  cupsName: string = "";
  entityCupsData = null;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private MeterService: MeterService,
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    (this.agrupationSelectOptions = this.data?.agrupations
      .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
      .map((agrupation: Agrupation) => {
        return { value: agrupation.name, name: agrupation.name };
      })
      .sort((a, b) => a.name.localeCompare(b.name))),
      (this.initialSelection = this.data?.agrupation);
      this.loadCupsName();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  loadCupsName(){

    this.entityCupsData = this.MeterService.getEntityCupsData();
    if(this.entityCupsData.entityCups){
      this.cupsName = this.entityCupsData.entityCups.name;
    }
  }

  assignAgrupation(agrupation:number): void{
    this.data.agrupation = agrupation;
  }
  // Activar contador
  activateMeter(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("meter-question-activate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: DeviceActivationData[] = [
          {
            nroSerie: this.data.nroSerie,
            latitude: this.data.latitude,
            longitude: this.data.longitude,
            entity: this.data.entity,
            agrupation: this.data.agrupation,
            rfModule: this.data.rfModule,
            comments: this.data.comments,
            precinto: this.data.precinto,
            cups: this.data.cups
          },
        ];
        this.MeterController.activate(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("success-meter-activate")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          } else if (
            response["body"] &&
            response["body"][0]?.responseCode != 0
          ) {
            this.ToastService.fireToast(
              "error",
              this.translate.instant(
                "httpError" + response["body"][0].responseCode
              )
            );
          }
        });
      }
    });
  }
}
