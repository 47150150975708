<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{ entityCups?.name ? entityCups?.name : "CUPS" }}
            {{ "alarms" | translate }}:
            {{ cups != null ? cups : ("no-data" | translate) }}
          </b>
        </h5>

        <!-- Botón configuración -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="configIcon"
          [title]="configTitle"
          (action)="goToConfig()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de alarmas -->
        <app-table-controller
          [tableId]="'cupsAlarms'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [quickFilters]="quickFilters"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
