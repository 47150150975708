import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Variables
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";

@Injectable({
  providedIn: "root",
})
export class DeviceConsumptionLeaksDetectionService {
  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  // Detección de fugas
  getLeakSeries(
    readings: number[][],
    units: string
  ): {
    serie: any;
    totalLeak: number;
    totalConsumption: number;
  } {
    // Consumo total
    let totalConsumption = readings
      .map((reading) => reading[1])
      .reduce((a, b) => a + b, 0); // Añadimos un valor inicial de 0
    // Lecturas diarias
    let daylyReadings = [];
    for (let i = 0; i <= readings.length - 24; i += 24) {
      daylyReadings.push(readings.slice(i, i + 24));
    }
    // Filtrado de interpolados
    let noInterpolatedReadings = daylyReadings.map((dayReadings) =>
      dayReadings.filter((reading) => !reading[2])
    );

    // Mínimos y totales diarios
    let daylyLeaks = [];
    noInterpolatedReadings.forEach((dayReadings) => {
      if (
        dayReadings?.length > 0 &&
        dayReadings.every((reading) => reading[1])
      ) {
        let minReading = dayReadings
          .filter((reading) => reading[1] > 0)
          .map((reading) => reading[1])
          .reduce((a, b) => Math.min(a, b), Infinity); // Valor inicial de Infinity
        let totalLeak = minReading === Infinity ? 0 : minReading * 24; // Si no hay lecturas válidas, totalLeak será 0
        daylyLeaks.push({ minReading: minReading, totalLeak: totalLeak });
      } else {
        daylyLeaks.push(null);
      }
    });
    // Serie de fugas
    if (daylyLeaks?.some((daylyLeak) => daylyLeak?.minReading)) {
      return {
        serie: {
          id: "leaks",
          name: this.translate.instant("possible-leak"),
          type: "area",
          fillOpacity: 0.4,
          data: readings.map((reading, i) => {
            return [
              reading[0],
              daylyLeaks[Math.floor(i / 24)]
                ? daylyLeaks[Math.floor(i / 24)]?.minReading
                : 0,
            ];
          }),
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix: units,
            color: "#ffbb45",
            showInNavigator: false,
          },
          color: "#ffbb45",
          showInNavigator: false,
        },
        totalLeak: daylyLeaks.reduce((total, data) => {
          return data ? total + data.totalLeak : total;
        }, 0), // Valor inicial 0
        totalConsumption: totalConsumption,
      };
    }
    return null;
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleHighchartsOptions(): any {
    let cirlceHighchartsOptions = {
      scrollbar: { enabled: false },
      plotOptions: {
        series: {
          dataGrouping: {
            forced: true,
            units: [["hour", [1]]],
          },
        },
        pie: {
          animation: false,
          size: 125,
          dataLabels: {
            enabled: true,
            crop: false,
            distance: 40,
            formatter: function () {
              return (
                this.point.name +
                "<br>" +
                (this.point.percentage % 1
                  ? this.point.percentage.toFixed(2)
                  : this.point.percentage) +
                "%<br>" +
                this.point.value
              );
            },
            style: {
              fontWeight: "normal",
              fontSize: "1.2rem",
            },
            connectorWidth: 1,
          },
        },
      },
      tooltip: { enabled: false },
      exporting: { enabled: false },
    };
    return cirlceHighchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setCircleChartsOptions(
    totalLeak: number,
    totalConsumption: number,
    units?: string
  ): any {
    const self = this;
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();
    let circleChartOptions: object = {};
    circleChartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete circleChartOptions["yAxis"];
    delete circleChartOptions["rangeSelector"];
    circleChartOptions["navigator"] = false;
    circleChartOptions["chart"] = {
      width: 200,
      height: 100,
      type: "pie",
      style: {
        overflow: "visible",
      },
      // styledMode: true
    };
    circleChartOptions["series"] = [
      {
        id: "cheese",
        name: this.translate.instant("cheese"),
        animation: false,
        data: [
          {
            color: "#03a9f4",
            name: this.translate.instant("consumption"),
            y: (totalConsumption - totalLeak) / totalConsumption,
            value:
              formatNumber(totalConsumption - totalLeak, numberFormat) + units,
          },
          {
            color: "#ffbb45",
            name: this.translate.instant("possible-leak"),
            y: totalLeak / totalConsumption,
            value: formatNumber(totalLeak, numberFormat) + units,
          },
        ],
        dataGrouping: { approximation: "sum" },
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>" +
              (this.point.percentage % 1
                ? this.point.percentage.toFixed(2)
                : this.point.percentage) +
              "%<br>" +
              this.point.value
            );
          },
          alignTo: "connectors",
          crop: "false",
          overflow: "allow",
          allowOverlap: true,
          distance: "5%",
        },
      },
    ];
    return circleChartOptions;
  }
}
