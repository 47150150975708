// @angular
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { TableDialogComponent } from "../../../../../modules/table-module/table-dialog/table-dialog.component";
// Interfaces
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import { DeviceAlarm, DeviceAlarmChecks } from "../../AlarmInterface.type";
import { AlarmsCustomPanelComponent } from "../../alarms-custom-config/alarms-custom-panel/alarms-custom-panel.component";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-alarms-list",
  templateUrl: "./alarms-table.component.html",
  styleUrls: ["./alarms-table.component.scss"],
})
export class AlarmsListComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Alarmas
  @Input()
  get devicesList(): any {
    return this._devicesList;
  }
  set devicesList(devicesList: any) {
    this._devicesList = devicesList?.map((device: DeviceAlarm) => {
      device.alarmsDisabled = device.checks.filter(
        (alarm: DeviceAlarmChecks) => !alarm.check
      )?.length;
      return device;
    });
  }
  _devicesList: any;
  @Input() devicesValve: boolean;
  @Input() devicesNotification: boolean;
  @Input() alarmsType: string;
  @Input() alerts: boolean;
  @Input() virtualAgrupation: boolean;
  @Input() meterId: number;
  @Output() showModalFlag = new EventEmitter<any>();
  currentEntityCupsConf: EntityDefinition[];
  cupsName: string;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Alarmas personalizadas
  @ViewChild(AlarmsCustomPanelComponent)
  alarmsCustomPanel: AlarmsCustomPanelComponent;
  customAlarmsTotal: object;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();
    this.cupsName = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.name;
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Reseteo de las opciones seleccionadas
  resetDeviceAlarms(device: DeviceAlarm): void {
    device.checks.forEach((alarm: DeviceAlarmChecks) => {
      alarm.check = false;
      alarm.open = false;
      alarm.notification = false;
    });
    device.allAlarmsCheck = false;
    device.allValvesCheck = false;
    device.allNotificationsCheck = false;
  }

  // Selección de todas las alarmas o válvulas
  selectAll(type: string, device: DeviceAlarm): void {
    if (type == "alarms" && !device.allAlarmsCheck) {
      device.allNotificationsCheck = false;
      device.allValvesCheck = false;
    }
    device.checks.forEach((alarm: DeviceAlarmChecks) => {
      switch (type) {
        case "alarms":
          alarm.check = device.allAlarmsCheck;
          if (!device.allAlarmsCheck) {
            alarm.notification = false;
            alarm.open = false;
          }
          break;
        case "valves":
          if (alarm.check || (!alarm.check && !device.allValvesCheck)) {
            alarm.open = device.allValvesCheck;
          }
          break;
        case "notifications":
          if (alarm.check || (!alarm.check && !device.allNotificationsCheck)) {
            alarm.notification = device.allNotificationsCheck;
          }
          break;
        default:
          break;
      }
    });
  }

  // Obtención de la lista de dispositivos actualizada
  getUpdatedDevicesList(): DeviceAlarm[] {
    return this._devicesList;
  }

  // Aviso para mostrar el modal de elementos configurados
  showModal(device: DeviceAlarm): void {
    this.showModalFlag.emit(device);
  }

  // Comprobación de si todas las casillas están activas
  checkAll(type: string, device: DeviceAlarm): void {
    switch (type) {
      case "alarm":
        if (device.checks.find((alarm: DeviceAlarmChecks) => !alarm.check)) {
          device.allAlarmsCheck = false;
        } else {
          device.allAlarmsCheck = true;
        }
        break;
      case "notification":
        if (
          device.checks.find((alarm: DeviceAlarmChecks) => !alarm.notification)
        ) {
          device.allNotificationsCheck = false;
        } else {
          device.allNotificationsCheck = true;
        }
        break;
      case "valve":
        if (device.checks.find((alarm: DeviceAlarmChecks) => !alarm.open)) {
          device.allValvesCheck = false;
        } else {
          device.allValvesCheck = true;
        }
        break;
      default:
        break;
    }
  }

  // Visualización de la descripción de la alarma
  showAlarmDescription(alarm: number): void {
    let alarmDescription = this.translate.instant("AlertDescription" + alarm);
    if (
      alarmDescription == "" ||
      alarmDescription == "AlertDescription" + alarm
    ) {
      alarmDescription = this.translate.instant("no-details");
    }
    this.MaterialDialogService.openDialog(TableDialogComponent, {
      title: this.translate.instant("alarm-info"),
      html:
        `<h4><b> (` +
        this.translate.instant("code") +
        " " +
        alarm +
        ") " +
        this.translate.instant("AlertMeter" + alarm) +
        `: </b></h4>
           <pre class="pre-without-format">` +
        alarmDescription +
        `</pre>`,
    });
  }

  /***************************************************************************/
  // ANCHOR Alarmas personalizadas
  /***************************************************************************/

  resetCustomAlarms(): void {
    this.alarmsCustomPanel.resetAlarms();
  }

  saveCustomAlarms(): void {
    this.alarmsCustomPanel.saveAlarms();
  }
}
