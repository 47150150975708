<!-- Modal de detalle de contador -->
<div *ngIf="deviceDetailModalData" class="dialog-container">
  <div class="dialog-header">
    <h5
      *ngIf="
        data.device.metrologyType == METROLOGY_TYPE.WATER ||
        data.device.metrologyType == METROLOGY_TYPE.GAS
      "
      class="dialog-title"
    >
      {{ ("meter" | translate) + ": " + data.device.nroSerie }}
    </h5>
    <h5
      *ngIf="
        data.device.metrologyType == METROLOGY_TYPE.SENSOR ||
        data.device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      "
      class="dialog-title"
    >
      {{ ("sensor" | translate) + ": " + data.device.nroSerie }}
    </h5>
    <h5
      *ngIf="
        data.device.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR ||
        data.device.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
      "
      class="dialog-title"
    >
      {{ ("concentrator" | translate) + ": " + data.device.nroSerie }}
    </h5>
    <h5
      *ngIf="data.device.metrologyType == METROLOGY_TYPE.WATER_VALVE"
      class="dialog-title"
    >
      {{ ("valve" | translate) + ": " + data.device.nroSerie }}
    </h5>
  </div>
  <div class="dialog-body device-detail-modal">
    <div>
      <table>
        <tr *ngFor="let element of deviceDetailModalData">
          <td>
            <b>{{ element.title }}</b>
          </td>
          <td>{{ element.text != null ? element.text : " " }}</td>
        </tr>
      </table>
    </div>

    <app-device-image-carousel *ngIf="data.device.meterImageUrls && data.device.meterImageUrls?.length > 0"
    [imageList]="data.device.meterImageUrls"
    [addImageActive]="false"
    [deleteImageActive]="false"
    >
    </app-device-image-carousel>

  </div>

  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
