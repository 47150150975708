// @angular
import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import { EditedDeviceData } from "../../DeviceInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { DeviceImageCarouselComponent } from "../device-image-carousel/device-image-carousel.component";

@Component({
  selector: "app-device-edit-modal",
  templateUrl: "./device-edit-modal.component.html",
  styleUrls: ["./device-edit-modal.component.scss"],
})
export class DeviceEditModalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  deviceSerialNumber: string;
  deviceComments: string;
  deviceSeal: string;
  deviceSensorRange: number;
  fileName: string;

  imageList: any;
  finalImage: any[] = [];  // Inicializarlo como un array vacío
  finalImageSent: any;
  updateImage: boolean = false;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  currentIndex = 0;


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private domSanitizer: DomSanitizer,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.reloadImages();
    this.changeSerialNumberModal();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Edición de número de serie del contador
  changeSerialNumberModal(): void {
    this.deviceSerialNumber = this.data.nroSerie;
    this.deviceComments = this.data.comments;
    this.deviceSeal = this.data.precinto;
    this.deviceSensorRange = this.data.distanciaAcustica;
    this.updateImage = false;
  }

  // Edición de los datos del contador
  editSerialNumber(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: EditedDeviceData = {
          meterId: this.data.id,
          newNroSerie: this.deviceSerialNumber,
          newComments: this.deviceComments,
          newPrecinto: this.deviceSeal,
          newDistanciaAcustica: this.deviceSensorRange,
        };

        let updateDataService: Observable<object> =
          this.MeterController.editDevice(data);
        let updateServices: Observable<object>[] = [updateDataService];

        forkJoin(updateServices).subscribe((results) => {
          if (
            results
              .map((result: any) => {
                return result["code"];
              })
              .reduce((a, b) => a + b) === 0
          ) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendUpdateSearchBoxFlag();
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Cambio del archivo de imagen de contador
  fileChangeEvent(event: any): void {
    this.fileName = event.target.files[0].name;
    this.finalImageSent = event.target.files[0];
    let img: File = this.finalImageSent;
    this.addNewImage(img);
  }

  addNewImage(img): void {

    this.MeterController.updateImage(this.data.nroSerie, img).subscribe(
      (response: any) => {
        if (response.code === 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.reloadImages();
        }
      }
    );
  }

  deleteImage(index): void {

    this.MeterController.deleteMeterImage(this.deviceSerialNumber, this.imageList[index].imageId).subscribe(
      (response: any) => {
        if (response.code === 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.reloadImages();
        }
      }
    );
  }

  reloadImages(): void {
    this.MeterController.getImages(this.data.id).subscribe(
      (response: any) => {
        this.imageList = response["body"];

        this.finalImage = this.imageList?.map((img: any) => {
          return this.domSanitizer.bypassSecurityTrustUrl(
            "data:image/png;base64, " + img.image
          );
        });
      });
  }


    // Para mover al siguiente slide
    nextSlide() {
      if (this.currentIndex < this.finalImage.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    }

    // Para mover al slide anterior
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      } else {
        this.currentIndex = this.finalImage.length - 1;
      }
    }
}
