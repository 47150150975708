import { Injectable, TemplateRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  templates = {};

  add(name: string, ref: TemplateRef<any>) {
    this.templates[name] = ref;
  }

  get(name: string) {
    return this.templates[name];
  }
}
