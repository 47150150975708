<div
  *ngIf="cesiumData"
  class="ceisum-map-container"
  [ngClass]="{ hidden: !cesiumData?.active }"
>
  <div class="container-fluid">
    <div class="panel panel-default">
      <div #homePanel class="panel-body">
        <app-cesium-map-controller
          [data]="cesiumData"
          (mapReset)="resetMap()"
        ></app-cesium-map-controller>
      </div>
    </div>
  </div>
</div>
