// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";
// Moment
import moment_timezone from "moment-timezone";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { OutputFilesControllerService } from "../../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { FileCron, FileTemplate, Ftp } from "../../OutputFileInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-output-files-template-export",
  templateUrl: "./output-files-template-export.component.html",
  styleUrls: ["./output-files-template-export.component.scss"],
})
export class OutputFilesTemplateExportComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Botón nuevo
  newIcon = "fas fa-plus";
  newTitle = this.translate.instant("new-template");

  // Table
  frecuency = {
    1: this.translate.instant("cron-daily"),
    2: this.translate.instant("cron-weekly"),
    3: this.translate.instant("cron-monthly"),
  };
  tableData: FileCron[];
  templateList: FileTemplate[];
  ftpList: Ftp[];
  columns = [
    {
      title: "action",
      data: [
        {
          name: "edit",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "cron-frequency-utc",
      data: "frecuenciaParsed",
      search: "frecuenciaParsed",
      sort: "frecuenciaParsed",
      visible: true,
    },
    {
      title: "cron-hour-utc",
      data: "hora",
      search: "hora",
      sort: "hora",
      visible: true,
    },
    {
      title: "cron-frequency-local",
      data: "frecuenciaLocal",
      search: "frecuenciaLocal",
      sort: "frecuenciaLocal",
      visible: true,
    },
    {
      title: "cron-hour-local",
      data: "horaLocal",
      search: "horaLocal",
      sort: "horaLocal",
      visible: true,
    },
    {
      title: "agrupations",
      data: "agrupationsParsed",
      search: "agrupationsParsed",
      sort: "agrupationsParsed",
      modalInfo: true,
      visible: true,
    },
    {
      title: "creation-date",
      data: "fechaCreacionParsed",
      search: "fechaCreacionParsed",
      sort: "fechaCreacion",
      date: true,
      visible: true,
    },
    {
      title: "template",
      data: "templateParsed",
      search: "templateParsed",
      sort: "templateParsed",
      visible: true,
    },
    {
      title: "Cron",
      data: "cron",
      search: "cron",
      sort: "cron",
      visible: true,
    },
    {
      title: "Email",
      data: "emails",
      search: "emails",
      sort: "emails",
      long: true,
      visible: true,
    },
    {
      title: "FTP",
      data: "ftpParsed",
      search: "ftpParsed",
      sort: "ftpParsed",
      long: true,
      visible: true,
    },
  ];
  cronDaysGap: number = 0;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private OutputFilesController: OutputFilesControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentEntity && this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    let urls = [
      this.OutputFilesController.getTemplateList(this.currentEntity.id),
      this.OutputFilesController.getFtpList(this.currentEntity.id),
    ];

    forkJoin(urls).subscribe((responses) => {
      if (responses[0]["code"] == 0) {
        this.templateList = responses[0]["body"];
      }

      if (responses[1]["code"] == 0) {
        let ftpList: Ftp[] = responses[1]["body"];
        ftpList?.forEach((ftp) => {
          ftp.ftpParsed =
            "URL: " +
            ftp.url +
            ", " +
            this.translate.instant("port") +
            ": " +
            ftp.port +
            ", " +
            this.translate.instant("user") +
            ": " +
            ftp.user;
        });
        this.ftpList = ftpList;
      }

      this.getData();
    });
  }

  // Obtención de los datos
  getData(): void {
    this.OutputFilesController.getCronList(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"].length > 0) {
          let tableData: FileCron[] = response["body"];
          tableData.forEach((cron: FileCron) => {
            cron.agrupationsParsed = this.currentEntity.agrupations
              .filter((agrupation) => cron.agrupations?.includes(agrupation.id))
              .map((agrupation) => agrupation.name)
              .join(", ");
            cron.templateParsed = this.templateList.find(
              (template) => template.id == cron.template
            )?.templateName;
            cron.fechaCreacionParsed = this.DateParserService.parseDate(
              cron.fechaCreacion,
              "L HH:mm:ss"
            );
            cron.frecuenciaParsed =
              this.frecuency[cron.frecuencia] +
              " (" +
              (cron.frecuencia != 2
                ? cron.detalleFrecuencia
                : this.getCronDays(cron.detalleFrecuencia)) +
              ")";

            if (cron.agrupationsParsed.length > 100) {
              cron.modalInfoData = {
                title: this.translate.instant("agrupations"),
                html: cron.agrupationsParsed,
              };
            }

            cron.ftpParsed = this.ftpList?.find(
              (ftp) => ftp.id == cron.ftpConfig
            )?.ftpParsed;
            cron.horaLocal = this.getCronLocalHour(cron.hora);
            cron.frecuenciaLocal =
              this.frecuency[cron.frecuencia] +
              " (" +
              (cron.frecuencia == 2
                ? this.getCronDays(cron.detalleFrecuencia, true)
                : cron.frecuencia == 3
                ? cron.detalleFrecuencia
                    ?.split(",")
                    ?.map((day) => parseInt(day) + this.cronDaysGap)
                    ?.join(", ")
                : cron.detalleFrecuencia) +
              ")";
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    );
  }

  // Obtención de la hora local acorde a la UTC
  getCronLocalHour(cronHour: string): string {
    let offset =
      moment_timezone().tz(this.currentAgrupation.timezone).utcOffset() / 60;
    let hour = parseInt(cronHour.split(":")[0]);
    this.cronDaysGap = hour + offset > 23 ? 1 : hour + offset < 0 ? -1 : 0;
    hour =
      hour + offset > 23
        ? hour + offset - 24
        : hour + offset < 0
        ? hour + offset + 24
        : hour + offset;
    return (hour < 10 ? "0" + hour : hour) + ":" + cronHour.split(":")[1];
  }

  // Obtención de los días de cron
  getCronDays(frecuency: string, useGap?: boolean): string {
    let days: string[] = [];
    frecuency.split(",").forEach((day) => {
      switch (useGap ? parseInt(day) + this.cronDaysGap : parseInt(day)) {
        case 1:
          days.push(this.translate.instant("monday"));
          break;
        case 2:
          days.push(this.translate.instant("tuesday"));
          break;
        case 3:
          days.push(this.translate.instant("wednesday"));
          break;
        case 4:
          days.push(this.translate.instant("thursday"));
          break;
        case 5:
          days.push(this.translate.instant("friday"));
          break;
        case 6:
          days.push(this.translate.instant("saturday"));
          break;
        case 7:
          days.push(this.translate.instant("sunday"));
          break;
        default:
          break;
      }
    });
    return days.join(", ");
  }

  // Acciones de la tabla
  tableActions(action: string, cron: any): void {
    switch (action) {
      case "edit":
        this.router.navigate(["/ficheros/plantillas/cron/editar/" + cron.id], {
          state: { data: cron },
        });
        break;
      case "delete":
        this.deleteCron(cron);
        break;
      default:
        break;
    }
  }

  // Borrado de cron
  deleteCron(cron: any): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-cron-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.OutputFilesController.removeCron(cron.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("cron-deleted")
            );
            this.getData();
          }
        });
      }
    });
  }

  // Redirección a creación de plantilla
  newTemplate(): void {
    this.router.navigate(["/ficheros/plantillas/cron/nuevo"]);
  }
}
