// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { CupsControllerService } from "../../../../services/server/CupsController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ControlAlertsControllerService } from "../../../../services/server/ControlAlertsController.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { CupsAlertsDialogComponent } from "./cups-alerts-dialog/cups-alerts-dialog.component";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ControlAlert, ControlAlertEvent } from "../../control/ControlInterface.type";

@Component({
  selector: "app-cups-alerts",
  templateUrl: "./cups-alerts.component.html",
  styleUrls: ["./cups-alerts.component.scss"],
})
export class CupsAlertsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;

  // Cups
  cups: string;
  id: number;
  entityCups: any;

  // Panel menu
  configIcon: string = "fas fa-cogs";
  configTitle: string = this.translate.instant("alerts-config");

  // Tabla
  alertList: ControlAlert[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  exportFileName: string =
    this.translate.instant("alarms-export") + " " + this.DateParserService.getDate();

  alertStates: object = {
    0: this.translate.instant("opened"),
    1: this.translate.instant("assigned"),
    2: this.translate.instant("closed"),
  };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ControlAlertsController: ControlAlertsControllerService,
    private CupsController: CupsControllerService,
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.entityCups = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );
    if (this.entityCups) {
      this.setColumns();
    }

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Configuración de CUPS
    this.entityCupsConfSub = this.SessionDataService
      .getEntityCupsConf()
      .subscribe((currentEntityCupsConf) => {
        this.currentEntityCupsConf = currentEntityCupsConf;
        this.entityCups = this.currentEntityCupsConf?.find(
          (column: EntityDefinition) => column.colPosition == 0
        );
        if (this.entityCups) {
          this.setColumns();
        }
      });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.id = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.cups = history.state.data;
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obención de los datos de la tabla
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let alertList: ControlAlert[] = [];
    this.CupsController.getCupsAlerts(this.id, from, to).subscribe(
      (response) => {
        if (response["code"] == 0) {
          if (response["body"]) {
            alertList = response["body"];
            alertList?.map((alert: ControlAlert) => {
              alert.stateParsed = this.alertStates[alert.state]
                ? this.alertStates[alert.state]
                : "-";
            });
          }
          this.alertList = alertList;
        }
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-events",
            tooltip: "show-events",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: this.entityCups.name ? this.entityCups.name : "CUPS",
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: true,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, alert: any): void {
    switch (action) {
      case "show-events":
        this.showModal(alert);
        break;
      default:
        break;
    }
  }

  // Mostrar modal
  showModal(alert: any): void {
    this.ControlAlertsController.getAlertData(alert.id).subscribe(
      (response) => {
        let alertEventData: ControlAlertEvent[] = [];
        if (response["code"] == 0) {
          alertEventData = response["body"]?.events;
        }
        this.MaterialDialogService.openDialog(
          CupsAlertsDialogComponent,
          alertEventData
        );
      }
    );
  }

  // Redirección a configuración de alertas
  goToConfig(): void {
    this.router.navigate(["/cups/configuracion/" + this.id], {
      state: { data: this.cups },
    });
  }
}
