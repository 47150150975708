export const LAST_PATCH_NOTES_USER = {
  es: `
  ### Versión 5.54 (03/04/2025)
* Dispositivo -> Listado -> Asignados:
  - Añadida opción para exportar solo los dispostivos con comunicación (el documento refleja también el CUPS).
* Fabricantes:
  - Añadido fabricante "Pietro Fiorentini" y modelo "SSM AQUO".
* Dispositivos -> Listado -> Sustituciones -> Editar:
  - Añadida opción para editar latitud y longitud de una sustitución. 
* Mantenimiento -> Global -> CUPS con múltiples dispositivos:
  - Solucionado error por el que no redirigia correctamente a la página de detalle del CUPS al abrirla en nueva pestaña.
* Principal:
  - Ahora se contabilizarán aparte los dispositivos externos sin comunicaciones en la gráfica global.
  - Corrección, cuando los dispositivos están pendientes en la gráfica, ahora se filtran por "activo" en la tabla de "no asignados".
  - Corrección, se redirige a la página de concentradores desde la gráfica principal.
* Dispositivo -> Nuevo:
  - Corregido tipo de modelo Zenner IUW LWMBUS.
* Estado de red -> KPIs:
  - Corregido un error por el que no se mostraban todas las series con la opción de todas las agrupaciones.
* Análisis de datos -> Balances:
  - Corregida gráfica circular para mostrar etiquetas.
`,
  en: `
  ### Version 5.54 (04/03/2025)
* Device -> List -> Assigned:
- Added option to export only devices with communication (the document also reflects the CUPS).
* Manufacturers:
- Added manufacturer "Pietro Fiorentini" and model "SSM AQUO".
* Devices -> List -> Replacements -> Edit:
- Added option to edit the latitude and longitude of a replacement.
* Maintenance -> Global -> CUPS with multiple devices:
- Fixed an issue where it did not correctly redirect to the CUPS detail page when opening it in a new tab.
* Main:
- External devices without communication will now be counted separately in the global graph.
- Fixed: When devices are pending in the graph, they are now filtered by "active" in the "unassigned" table.
- Fixed: Redirects to the hubs page from the main graph.
* Device -> New:
- Fixed Zenner IUW LWMBUS model type.
* Network Status -> KPIs:
- Fixed a bug where not all series were displayed with the "All groups" option.
* Data Analysis -> Balances:
- Fixed pie chart to display data labels.
`,
  "es-ca": null,
  fr: null,
  it: null,
  el: null,
};
