<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "rfmodule-replace" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <!-- Módulo RF -->
    <app-material-select
      [title]="'rf-module' | translate"
      [filter]="true"
      [fullWidth]="true"
      [bindValue]="'id'"
      [bindLabel]="'rfModule'"
      [data]="rfList"
      (selectedOption)="selectedRf = $event"
      required
    ></app-material-select>
  </div>

  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="replaceRfModule()"
      [disabled]="selectedRf == null"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
