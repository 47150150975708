<div class="meter-list-map-buttons">
  <button
    mat-button
    class="mat-button-icon-text"
    (click)="getDataSelected()"
    [disabled]="!selectedData || selectedData?.length == 0"
  >
    <i class="fas fa-table"></i>
    {{ "filter-table-by-map" | translate }}
  </button>
  <button
    mat-button
    class="mat-button-icon-text"
    (click)="resetMapTableFilter()"
    [disabled]="!tableFilteredByMap"
  >
    <i class="fas fa-undo-alt"></i>
    {{ "filter-table-by-map-reset" | translate }}
  </button>
</div>
