import { ToastService } from "./../../../../services/shared/ToastService.service";
import { MeterService } from "./../../devices/meter/MeterService.service";
// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { EkConfigurationControllerService } from "../../../../services/server/EkConfigurationController.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
// Componentes
import { Ek280ConfigurationComponent } from "../../devices/ek-280/ek280-configuration/ek280-configuration.component";
import { Ek280TestComponent } from "../../devices/ek-280/ek280-test/ek280-test.component";
import { ControlTelemetryDialogComponent } from "./control-telemetry-dialog/control-telemetry-dialog.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  DeviceEk280,
  DeviceEk280Sim,
  DeviceSim,
} from "../../devices/DeviceInterface.type";
import {
  EK_ERRORS,
  EkBatteryData,
  EkData,
  MinifiedEntityDefinition,
} from "../ControlInterface.type";
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";
import moment from "moment";
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: "app-control-telemetry",
  templateUrl: "./control-telemetry.component.html",
  styleUrls: ["./control-telemetry.component.scss"],
})
export class ControlTelemetryComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentClientList: Client[];
  clientListSub: Subscription;
  globalEntityList: Entity[];
  selectedEntity: number;
  sessionProfile: string;
  componentInitiated: boolean = false;
  readonly PROFILES = PROFILES;

  // Tabla
  tableSelectedData: any[];
  tableMaxReg: number = 40;
  tableData: EkData[];
  originalTableData: EkData[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  entitiesDefinitions: {
    entityId: number;
    definitionList: MinifiedEntityDefinition[];
    definitionListParsed: EntityDefinition[];
  }[];
  highlightRow: object[] = [
    {
      condition: "communicationError",
      color: "yellow",
      title: "no-communicates",
    },
    {
      condition: "identicalValuesError",
      color: "orange",
      title: "ek-error-identical-values",
    },
    { condition: "noFile", color: "red", title: "file-not-sended-last-day" },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "ek-file-download-billing",
      icon: "fas fa-file-arrow-down",
      selectionRequired: false,
    },
    {
      title: "ek-file-download-values",
      icon: "fas fa-file-arrow-down",
      selectionRequired: true,
    },
  ];
  exportFileName: string =
    this.translate.instant("telemetry") +
    " " +
    this.DateParserService.getDate();

  // CUPS
  cups: string[];
  serialNumber: string[];
  serverConnError: number;

  // Actualización periódica
  telemetryTimer: number = 30;
  telemetryInterval: any;

  // Modal de configuración de EK280
  ekConfigData: DeviceEk280;
  simList: DeviceSim[];
  simSelected: number;

  graphicData: EkBatteryData[];
  highchartsOptions: Options;
  lastSelectedMeter: any;
  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private EkConfigurationController: EkConfigurationControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private MeterService: MeterService,
    private ToastService: ToastService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    let client = this.SessionDataService.getCurrentClient();
    if (!this.currentEntity) {
      // Escucha de cambios en entidad
      this.entitySub = this.SessionDataService.getEntity().subscribe(
        (entity) => {
          this.currentEntity = entity;
          this.ReloadComponentService.reload();
        }
      );
    }

    if (this.sessionProfile == PROFILES.ARSON) {
      this.clientListSub = this.SessionDataService.getClientList().subscribe(
        (clientList) => {
          this.currentClientList = clientList;
          if (this.tableData) {
            this.getGlobalEntityList();
          }
        }
      );
    }

    // Carga del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    if (this.entitySub) {
      this.entitySub.unsubscribe();
    }
    clearInterval(this.telemetryInterval);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Obtención de los datos
    this.getData();
  }

  // Obtención del listado global de entidades
  getGlobalEntityList(): void {
    let globalEntityList = this.currentClientList
      ?.map((client) => client.entityList)
      ?.reduce((a, b) => a.concat(b));
    this.globalEntityList = globalEntityList?.filter((entity) =>
      this.tableData.some((meter) => meter.entity == entity.id)
    );
    this.globalEntityList?.unshift({
      agrupations: null,
      client: null,
      entity: this.translate.instant("global"),
      id: -1,
    });
  }

  // Obtención de los datos
  getData(): void {
    let meterList: EkData[] = [];
    this.MeterController.getEkList().subscribe((response) => {
      if (response["code"] == 0) {
        this.serverConnError = response["body"]?.serverConn;

        // CUPS
        this.entitiesDefinitions = response["body"]?.entitiesDefinitions;
        this.entitiesDefinitions?.forEach((entityDefinition) => {
          entityDefinition.definitionListParsed = [];
          entityDefinition.definitionList.forEach((definition) => {
            entityDefinition.definitionListParsed.push({
              id: definition.id,
              entity: definition.e,
              colPosition: definition.p,
              name: definition.n,
              label: definition.l,
              description: definition.d,
              show: definition.s,
              required: definition.r,
            });
          });
          this.cups = [];
          this.serialNumber = [];
          if (this.entitiesDefinitions?.length == 1) {
            this.entitiesDefinitions[0].definitionListParsed.forEach(
              (column: EntityDefinition) => {
                if (column.colPosition == 0) {
                  this.cups.push(column.label);
                }
                if (column.colPosition == 100) {
                  this.serialNumber.push(column.label);
                }
              }
            );
          }
        });

        // Dispositivos
        meterList = response["body"]?.meterList;
        let entityList: Entity[];
        if (this.sessionProfile == PROFILES.ARSON) {
          let clientList = this.SessionDataService.getCurrentClientList();
          entityList = clientList
            .map((client) => client.entityList)
            .reduce((a, b) => a.concat(b));
        } else {
          entityList = this.SessionDataService.getCurrentEntityList();
        }

        // Timestamp de referencia para envío de fichero
        let referenceDate = (
          this.DateParserService.getNow("Europe/Madrid", "HH") == 6
            ? this.DateParserService.getNow("Europe/Madrid", "mm") >= 30
            : this.DateParserService.getNow("Europe/Madrid", "HH") > 6
        )
          ? // Comienzo del día en curso
            this.DateParserService.getLastDays("0").startDate.valueOf()
          : // Comienzo del día anterior
            this.DateParserService.getLastDays("1").startDate.valueOf();
        meterList?.forEach((meter: EkData) => {
          meter.meterLink =
            meter.id != null
              ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  meter.metrologyType,
                  meter.id
                )
              : null;

          this.setMeterEntityCupsColumns(meter);
          // Gráfica
          meter.loadEnergyGraph = true;
          let meterEntity = entityList.find(
            (entity) => entity.id == meter.entity
          );
          meter.model = this.ManufacturerService.getDeviceType(
            meter.fabricante,
            meter.devType
          );
          meter.entityName = meterEntity?.entity;
          meter.agrupationName = meterEntity?.agrupations.find(
            (agrupation) => agrupation.id == meter.agrupation
          )?.name;
          meter.fileIncluded = !meter.excluded;
          meter.noFile = this.serverConnError
            ? false
            : meter.fileIncluded &&
              (!meter.lastSendTimestamp ||
                referenceDate > meter.lastSendTimestamp);
          meter.errorList?.forEach((error) => {
            error.errorParsed = EK_ERRORS[error.code]
              ? this.translate.instant(EK_ERRORS[error.code])
              : error.code;
          });
          this.parseMeterErrors(meter);
          meter.errorListDisabled =
            !meter.errorList || meter.errorList?.length == 0;
          meter.communicationError = meter.errorList?.some(
            (error) => !error.fechaFin
          );
          meter.identicalValuesError = meter.errorList?.some(
            (error) =>
              error.code == EK_ERRORS.IDENTICAL_VALUES && !error.fechaFin
          );
          meter.showEnergyGraph = false;
          meter.secondsDifference = Math.round(
            meter.milisecondsDifference / 1000
          );
        });
        this.setColumns();
      }
      // Ordenamiento por fechas de envío de fichero (si es nulo primero)
      let meterOrdered = [];
      if (meterList.some((meter) => meter.noFile)) {
        meterOrdered = [
          ...meterList
            .filter((meter) => meter.noFile)
            .sort((a, b) => b.lastCommunication - a.lastCommunication),
          ...meterList
            .filter((meter) => !meter.noFile)
            .sort((a, b) => b.lastCommunication - a.lastCommunication),
        ];
      } else {
        meterOrdered = meterList.sort(
          (a, b) => b.lastCommunication - a.lastCommunication
        );
      }
      this.tableData = meterOrdered ? meterOrdered : [];
      this.originalTableData = [...this.tableData];
      if (!this.componentInitiated) {
        this.componentInitiated = true;
        this.updateTelemetryRefresh();
      }
      if (this.sessionProfile == PROFILES.ARSON) {
        this.getGlobalEntityList();
      }
    });
  }

  parseMeterErrors(meter: EkData) {
    meter.errorList?.forEach((error) => {
      error.errorParsed = EK_ERRORS[error.code]
        ? this.translate.instant(EK_ERRORS[error.code])
        : error.code;
    });
  }
  
  // Seteo de las columnas de CUPS de contador según entidad
  setMeterEntityCupsColumns(meter: EkData): void {
    for (let attribute in meter) {
      if (attribute?.includes("col")) {
        meter[meter.entity + attribute] = meter[attribute];
      }
    }
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "more-info",
            tooltip: "more-info",
            icon: "fas fa-circle-info",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "errors-list",
            tooltip: "errors-list",
            icon: "fas fa-triangle-exclamation",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "ek-file-download-values",
            tooltip: "ek-file-download-values",
            icon: "fas fa-file-arrow-down",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "ek-config",
            tooltip: "configuration",
            icon: "fas fa-gears",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.TELEMEDIDA,
          },
          {
            name: "ek-test",
            tooltip: "test-comm",
            icon: "fas fa-broadcast-tower",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.TELEMEDIDA,
          },
          {
            name: "ek-manual-comm",
            tooltip: "manual-comm",
            icon: "fas fa-wifi",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.TELEMEDIDA,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "ek-connected",
        data: "connected",
        search: "connected",
        sort: "connected",
        alter: {
          condition: "connected",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
        boolean: true,
      },
      {
        title: "entity",
        data: "entityName",
        search: "entityName",
        sort: "entityName",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupationName",
        search: "agrupationName",
        sort: "agrupationName",
        visible: true,
      },
      {
        title:
          this.serialNumber?.length > 0
            ? this.serialNumber[0]
            : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: this.sessionProfile != PROFILES.TELEMEDIDA ? "meterLink" : null,
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupation",
          check: "id",
        },
      },
      {
        title: "serial-number-conversor-owasys",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "model",
        data: "model",
        search: "model",
        sort: "model",
        visible: true,
      },
      {
        title: "code",
        data: "codigo",
        search: "codigo",
        sort: "codigo",
        visible: true,
      },
      {
        title: "ek-file-serial-number",
        data: "fileNroSerie",
        search: "fileNroSerie",
        sort: "fileNroSerie",
        visible: true,
      },
      {
        title: "ek-file-included",
        data: "fileIncluded",
        search: "fileIncluded",
        sort: "fileIncluded",
        alter: {
          condition: "fileIncluded",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
      },
      {
        title: "ek-last-file",
        data: "lastSendTimestampParsed",
        search: "lastSendTimestampParsed",
        sort: "lastSendTimestamp",
        date: true,
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "last-modem-battery-voltage",
        data: "lastModemBatteryVoltage",
        search: "lastModemBatteryVoltage",
        sort: "last-modem-battery-voltage",
        visible: true,
      },
      {
        title: "voltage-graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
        batteryGraph: false,
        energyGraph: true,
      },
      {
        title: "miliseconds-server-defase",
        data: "secondsDifference",
        search: "secondsDifference",
        sort: "secondsDifference",
        visible: true,
      },
      {
        title: "last-remaining-battery-service-life",
        data: "lastRemainingBatteryServiceLife",
        search: "lastRemainingBatteryServiceLife",
        sort: "last-remaining-battery",
        visible: true,
      },
      {
        title: "ICCID",
        data: "iccid",
        search: "iccid",
        sort: "iccid",
        visible: true,
      },
      {
        title: "IP",
        data: "ip",
        search: "ip",
        sort: "ip",
        visible: true,
      },
      {
        title: "FCV",
        data: "fcv",
        search: "fcv",
        sort: "fcv",
        visible: true,
      },
      {
        title: "PCS",
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: true,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastReadedTimestampParsed",
        search: "lastReadedTimestampParsed",
        sort: "lastReadedTimestamp",
        date: true,
        visible: true,
      },
      // {
      //   title: "last-month-consumption",
      //   data: "consumptionCardsLastMonthParsed",
      //   search: "consumptionCardsLastMonthParsed",
      //   sort: "consumptionCardsLastMonth",
      //   numerical: true,
      //   visible: true,
      // },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "client",
        data: "cliente",
        search: "cliente",
        sort: "cliente",
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
      {
        title: this.cups?.length > 0 ? this.cups[0] : "CUPS",
        data: "clave",
        search: "clave",
        sort: "clave",
        visible: true,
      },
    ];

    this.entitiesDefinitions?.forEach((entityDefinitions) => {
      entityDefinitions.definitionListParsed?.forEach(
        (extraColumn: EntityDefinition) => {
          if (extraColumn.show) {
            let attributePosition: string =
              entityDefinitions.entityId +
              (extraColumn.colPosition <= 9
                ? "col0" + extraColumn.colPosition
                : "col" + extraColumn.colPosition);
            if (
              extraColumn.colPosition != 100 &&
              extraColumn.colPosition != 0
            ) {
              let newColumn: TableDataColumn = {
                title: extraColumn.name,
                data: attributePosition,
                search: attributePosition,
                sort: attributePosition,
                long: true,
                visible: true,
              };
              columns.push(newColumn);
            }
          }
        }
      );
    });
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: any): void {
    switch (action) {
      case "ek-config":
        this.getEkData(meter);
        break;
      case "ek-manual-comm":
        this.getManualCommunication(meter);
        break;
      case "ek-test":
        this.MaterialDialogService.openDialog(Ek280TestComponent, meter);
        break;
      case "more-info":
        this.MaterialDialogService.openDialog(ControlTelemetryDialogComponent, {
          action: "info",
          meter: meter,
        });
        break;
      case "errors-list":
        this.MaterialDialogService.openDialog(ControlTelemetryDialogComponent, {
          action: "errors",
          meter: meter,
        });
        break;
      case "ek-file-download-values":
        this.MaterialDialogService.openDialog(ControlTelemetryDialogComponent, {
          action: "file-values",
          meter: meter,
        });
        break;
      case "load-energy-graph":
        this.lastSelectedMeter = meter;
        this.loadGraphData(meter);
        break;
      default:
        break;
    }
  }

  getManualCommunication(meter: any) {
    this.MeterService.checkEkManualCommunication(meter);
  }

  loadGraphData(meter: any, from?: number, to?: number) {
    this.MeterService.getEkEnergyGraph(meter);
  }
  // Nuevo EK280
  newEk(): void {
    this.router.navigate(["/dispositivos/nuevo"], {
      state: { data: { type: DEVICE_BY_COMM.OWASYS } },
    });
  }

  // Obtención de los datos
  getEkData(meter): void {
    let dataRequests: Observable<object>[] =
      this.sessionProfile == PROFILES.ARSON ||
      this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
      this.sessionProfile == PROFILES.TELEMEDIDA ||
      this.sessionProfile == PROFILES.ADMIN_CLIENTE
        ? [
            this.EkConfigurationController.getEkConfigData(meter.id),
            this.EkConfigurationController.getSimList(),
          ]
        : [this.EkConfigurationController.getEkConfigData(meter.id)];

    forkJoin(dataRequests).subscribe((responses) => {
      // Respuesta del servicio de datos de EK
      if (responses[0]["code"] == 0) {
        let ekConfigData: DeviceEk280 = responses[0]["body"]?.ek280;
        let simData: DeviceEk280Sim = responses[0]["body"]?.sim;
        ekConfigData.iccid = simData?.iccid;
        ekConfigData.simId = simData?.id;

        let windows: { source: string; parsed: string }[] = [
          { source: "ventana1", parsed: "ventana1Parsed" },
          { source: "ventana2", parsed: "ventana2Parsed" },
          { source: "ventana3", parsed: "ventana3Parsed" },
        ];
        windows.forEach((window: { source: string; parsed: string }) => {
          if (ekConfigData[window.source]) {
            let hour: number = Math.floor(ekConfigData[window.source] / 100); // Extrae la hora
            let hourParsed: string =
              hour < 10 ? "0" + hour.toString() : hour.toString();

            // Aquí usamos el valor de window.source para obtener los minutos correctamente
            let minutes: number = ekConfigData[window.source] % 100; // Extrae los minutos
            let minutesParsed: string =
              minutes < 10 ? "0" + minutes.toString() : minutes.toString();

            ekConfigData[window.parsed] = hourParsed + ":" + minutesParsed;
          }
        });
        this.ekConfigData = ekConfigData;
      }

      // Respuesta de servicio de listado de SIM
      if (responses[1] && responses[1]["code"] == 0) {
        this.simList = responses[1]["body"] ? responses[1]["body"] : [];
        this.simList.unshift({
          id: parseInt(this.ekConfigData?.simId),
          iccid: this.ekConfigData?.iccid,
        });
        this.simSelected = this.simList[0].id;
      }

      if (responses[0]["code"] == 0) {
        this.MaterialDialogService.openDialog(Ek280ConfigurationComponent, {
          sessionProfile: this.sessionProfile,
          editModalData: JSON.parse(JSON.stringify(this.ekConfigData)),
          simList: this.simList,
          meterId: meter.id,
        });
      }
    });
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "ek-file-download-values":
        this.MaterialDialogService.openDialog(ControlTelemetryDialogComponent, {
          action: "file-values",
          meters: this.tableSelectedData.map((meter) => meter.id),
        });
        break;
      case "ek-file-download-billing":
        this.MaterialDialogService.openDialog(ControlTelemetryDialogComponent, {
          action: "file-billing",
        });
      default:
        break;
    }
  }

  // Obtención de alarmas
  updateTelemetryRefresh(): void {
    clearInterval(this.telemetryInterval);
    this.telemetryInterval = setInterval(
      () => this.getData(),
      this.telemetryTimer * 60 * 1000
    );
  }

  // Refresco de datos
  refreshData(): void {
    this.tableData = [];
    setTimeout(() => {
      this.getData();
      this.updateTelemetryRefresh();
    }, 0);
  }

  // Actualización de datos de tabla por entidad
  updateTableEntityData(): void {
    if (this.selectedEntity >= 0) {
      this.tableData = this.originalTableData.filter(
        (meter) => meter.entity == this.selectedEntity
      );
    } else {
      this.tableData = this.originalTableData;
    }
  }
}
