import { Injectable } from "@angular/core";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class DragElementService {
  dragPoint: { x: number; y: number } = { x: null, y: null };

  constructor(private SessionDataService: SessionDataService) {}

  // Obtención de la posición del panel
  setPosition(
    element: HTMLElement,
    dragData: any,
    initialClass: string,
    y: string,
    x: string
  ): void {
    if (this.checkDragData(element, dragData)) {
      element?.classList.remove(initialClass);
      element?.style.setProperty(y, dragData.y - this.dragPoint.y + "px");
      element?.style.setProperty(x, dragData.x - this.dragPoint.x + "px");
    }
    this.SessionDataService.sendComponentData({ enableDrop: false });
  }

  // Comprobación de coordenadas
  checkDragData(element: HTMLElement, dragData: any): boolean {
    if (
      dragData.y - this.dragPoint.y >= 0 &&
      dragData.y - this.dragPoint.y <=
        window.innerHeight - element?.offsetHeight &&
      dragData.x - this.dragPoint.x >= 0 &&
      dragData.x - this.dragPoint.x <= window.innerWidth - element?.offsetWidth
    ) {
      return true;
    }
    return false;
  }

  // Obtención del punto del panel que se arrastra
  getDrapgPoint(element: HTMLElement, dragData: any) {
    this.SessionDataService.sendComponentData({ enableDrop: true });
    this.dragPoint.x = dragData.clientX - element?.offsetLeft;
    this.dragPoint.y = dragData.clientY - element?.offsetTop;
  }
}
