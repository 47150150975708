export enum PROFILES {
  ARSON = "ARSON",
  ADMIN_CLIENTE = "ADMIN_CLIENTE",
  USUARIO_CLIENTE = "USUARIO_CLIENTE",
  ADMIN_ENTIDAD = "ADMIN_ENTIDAD",
  USUARIO_ENTIDAD = "USUARIO_ENTIDAD",
  ADMIN_AGRUPACION = "ADMIN_AGRUPACION",
  USUARIO_AGRUPACION = "USUARIO_AGRUPACION",
  INSTALADOR = "INSTALADOR",
  AQUACITY = "AQUACITY",
  API = "API",
  ABONADO = "ABONADO",
  ADMIN_INSTALLATION = "ADMIN_INSTALLATION",
  PERMANENT_TOKEN = "PERMANENT_TOKEN",
  TELEMEDIDA = "TELEMEDIDA",
}
