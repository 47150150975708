<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <!-- Título -->
          <div class="totals-summary-client">
            <app-material-select
              class="material-header-select"
              [title]="'total-devices' | translate"
              [bindValue]="'id'"
              [bindLabel]="'value'"
              [selected]="-1"
              [data]="clientDropdownData"
              (selectedOption)="selectedClient = $event?.id"
            ></app-material-select>
            <button
              mat-button
              class="mat-button-icon"
              (click)="getTotalsSummary()"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'totalsSummary'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [highlightRow]="highlightRow"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
