import { Injectable } from "@angular/core";
import { MANUFACTURER_INDEX } from "../../../assets/manufacturers/MANUFACTURER_INDEX";

export enum DEVICE_BY_COMM {
  NO_LORA_NO_COM = "NO_LORA_NO_COM",
  LW_MBUS_CON = "LW_MBUS_CON",
  LW_MBUS = "LW_MBUS",
  LW = "LW",
  MBUS = "MBUS",
  WAVENIS = "WAVENIS",
  LW_UNE_CON = "LW_UNE_CON",
  UNE = "UNE",
  EK280 = "EK280",
  PLUM = "PLUM",
  OWASYS = "OWASYS",
  EXTERNO = "EXTERNO",
  ERM = "ERM",
  NBIOT = "NBIOT",
  TXN = "TXN",
  API = "API",
  API_TOKEN = "API_TOKEN",
  CYBLE5 = "CYBLE5",
}

export enum DEVICE_BY_METROLOGY {
  SENSOR = "SENSOR",
  LW_UNE = "LW_UNE",
  LW_MBUS = "LW_MBUS",
  ACOUSTIC_SENSOR = "ACOUSTIC_SENSOR",
  METER = "METER",
  SATELITE = "SATELITE",
  CONCENTRATOR = "CONCENTRATOR",
}

export enum COMM_BIT_Y_DEVICE {
  LORA, // Bit 0: Lora
  MBUS, // Bit 1: MBus
  WAVENIS, // Bit 2: Wavenis
  UNE, // Bit 3: UNE
  EK, // Bit 4: EK280/PLUM
  EXTERNO, // Bit 5: Externo
  ERM, // Bit 6: ERM
  NBIOT, // Bit 7: NBIoT
  TXN, // Bit 8: TXN
  API, // Bit 9: Externo por API
  API_TOKEN, // Bit 10: Externo por API con token
}

@Injectable({
  providedIn: "root",
})
export class DeviceTypeService {
  constructor() {}

  // Obtención del tipo de dispositivo según su máscara de comunicaciones
  getDeviceTypeByMask(
    typeMask: number,
    metrologyType?: number,
    manufacturer?: number
  ): string {
    // Atributo "tipo": Es una máscara en un integer, que ocupa 32 bits, con lo que puede haber 32 tipos
    // All 0: Dispositivo externo sin comunicación
    // Bit 0: Lora
    // Bit 1: MBus
    // Bit 2: Wavenis
    // Bit 3: UNE
    // Bit 4: EK280/PLUM
    // Bit 5: Externo
    // Bit 6: ERM
    // Bit 7: NBIoT
    // Bit 8: TXN
    // Bit 9: Externo por API
    // Bit 10: Externo por API con token
    let meterTypeBinary: string = typeMask?.toString(2);

    if (typeMask == 0) {
      return DEVICE_BY_COMM.NO_LORA_NO_COM;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "1" &&
      !(meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1")
    ) {
      if (metrologyType == 5) {
        return DEVICE_BY_COMM.LW_MBUS_CON;
      } else {
        if (meterTypeBinary?.charAt(meterTypeBinary.length - 2) == "1") {
          return DEVICE_BY_COMM.LW_MBUS;
        } else {
          return DEVICE_BY_COMM.LW;
        }
      }
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 2) == "1"
    ) {
      return DEVICE_BY_COMM.MBUS;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 3) == "1"
    ) {
      return DEVICE_BY_COMM.WAVENIS;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "1" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1"
    ) {
      return DEVICE_BY_COMM.LW_UNE_CON;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 4) == "1"
    ) {
      return DEVICE_BY_COMM.UNE;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 5) == "1"
    ) {
      if (manufacturer == MANUFACTURER_INDEX.KROMSCHROEDER) {
        return DEVICE_BY_COMM.EK280;
      } else if (manufacturer == MANUFACTURER_INDEX.PLUM) {
        return DEVICE_BY_COMM.PLUM;
      } else if (manufacturer == MANUFACTURER_INDEX.ARSONMETERING) {
        return DEVICE_BY_COMM.OWASYS;
      }
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 6) == "1"
    ) {
      return DEVICE_BY_COMM.EXTERNO;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 7) == "1"
    ) {
      return DEVICE_BY_COMM.ERM;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 8) == "1"
    ) {
      return DEVICE_BY_COMM.NBIOT;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 9) == "1"
    ) {
      return DEVICE_BY_COMM.TXN;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 10) == "1"
    ) {
      return DEVICE_BY_COMM.API;
    } else if (
      meterTypeBinary?.charAt(meterTypeBinary.length - 1) == "0" &&
      meterTypeBinary?.charAt(meterTypeBinary.length - 11) == "1"
    ) {
      return DEVICE_BY_COMM.API_TOKEN;
    }
    return null;
  }

  // Obtención del tipo de dispositivo según su metrología
  getDeviceTypeByMetrology(metrologyType: number): string {
    switch (metrologyType) {
      case 1:
        return DEVICE_BY_METROLOGY.SENSOR;
      case 3:
        return DEVICE_BY_METROLOGY.LW_UNE;
      case 5:
        return DEVICE_BY_METROLOGY.LW_MBUS;
      case 6:
        return DEVICE_BY_METROLOGY.ACOUSTIC_SENSOR;
      default:
        return DEVICE_BY_METROLOGY.METER;
    }
  }

  // Obtención de la máscara tipo por tipo de dispositivo
  getCommTypeMaskByDevice(commTypeBits: number[]): number {
    let typeMask: number;
    let typeMaskArray = new Array(32).fill(0);
    commTypeBits.forEach((bit) => (typeMaskArray[31 - bit] = 1));
    typeMask = parseInt(typeMaskArray.join(""), 2);
    return typeMask;
  }
}
