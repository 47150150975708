// @angular
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import { CustomAlarm } from "../../AlarmInterface.type";
import { Association } from "../../../data-management/DataManagementInterface.type";

@Component({
  selector: "app-alarms-custom-panel",
  templateUrl: "./alarms-custom-panel.component.html",
  styleUrls: ["./alarms-custom-panel.component.scss"],
})
export class AlarmsCustomPanelComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Alarmas
  @Input() associationList: Association[];
  @Input()
  get selectedAssociation(): number {
    return this._selectedAssociation;
  }
  set selectedAssociation(selectedAssociation: number) {
    this._selectedAssociation = selectedAssociation;
    this.updateAlarms();
  }
  _selectedAssociation: number;
  @Input() meterId: number;
  alarmConfig: CustomAlarm[];
  allAlarmsCheck: boolean;
  allNotificationsCheck: boolean;
  allSubscriberCheck: boolean;
  allValidationCheck: boolean;
  meterOverwrite: boolean;
  preselection: number;
  customAlarmsList: any;
  @Output() customAlarmsTotal = new EventEmitter<object>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private router: Router,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.preselection = this._selectedAssociation;
    this.getAlarms();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Selección de todas las alarmas
  selectAll(type: string): void {
    switch (type) {
      case "alarms":
        this.alarmConfig.map((alarm) => {
          alarm.active = this.allAlarmsCheck;
        });
        break;
      case "notification":
        this.alarmConfig.map(
          (alarm) =>
            (alarm.notification = alarm.active && this.allNotificationsCheck)
        );
        break;
      case "subscriber":
        this.alarmConfig.map(
          (alarm) =>
            (alarm.subscriber = alarm.active && this.allSubscriberCheck)
        );
        break;
      case "validation":
        this.alarmConfig.map(
          (alarm) =>
            (alarm.validation = alarm.active && this.allValidationCheck)
        );
        break;
      default:
        break;
    }
  }

  // Comprobación de si todas las casillas están activas
  checkAll(type: string): void {
    switch (type) {
      case "alarm":
        this.allAlarmsCheck = !this.alarmConfig.find(
          (alarm: CustomAlarm) => !alarm.active
        );
        break;
      case "notification":
        this.allNotificationsCheck = !this.alarmConfig.find(
          (alarm: CustomAlarm) => !alarm.notification
        );
        break;
      case "subscriber":
        this.allSubscriberCheck = !this.alarmConfig.find(
          (alarm: CustomAlarm) => !alarm.subscriber
        );
        break;
      case "validation":
        this.allValidationCheck = !this.alarmConfig.find(
          (alarm: CustomAlarm) => !alarm.validation
        );
        break;
      default:
        break;
    }
  }
  // Obtención del listado de alarmas personalizadas
  getAlarms(): void {
    this.AlarmController.getCustomAlarms().subscribe((response) => {
      if (response["code"] == 0) {
        this.customAlarmsList = response["body"]?.filter((alarm: CustomAlarm) =>
          this.meterId ? alarm.meterId != null : alarm.associationId != null
        );
        this.updateAlarms();
      }
    });
  }

  // Actualización de asociacion en curso
  updateAlarms(): void {
    this.alarmConfig = this.customAlarmsList
      ?.filter((alarm) =>
        this.meterId
          ? alarm.meterId == this.meterId
          : alarm.associationId == this._selectedAssociation
      )
      .sort((a, b) => a.type - b.type);
    if (!this.alarmConfig || this.alarmConfig?.length == 0) {
      this.alarmConfig = [];
      for (let i = 0; i < 8; i++) {
        this.alarmConfig.push(
          new CustomAlarm(null, i, this._selectedAssociation, this.meterId)
        );
      }
    }
    this.customAlarmsTotal.emit({
      alarms: this.alarmConfig.length,
      disabled: this.alarmConfig.filter((alarm) => !alarm.active).length,
    });
  }

  // Creación de asociación
  newAssociation(): void {
    this.router.navigate(["/analisis-datos/asociaciones/nuevo"]);
  }
  // Reseteo a limpio
  resetAlarms(): void {
    this.alarmConfig = this.alarmConfig.map(
      (alarm) =>
        new CustomAlarm(
          alarm.id,
          alarm.type,
          alarm.associationId,
          alarm.meterId
        )
    );
    this.allAlarmsCheck = false;
    this.allNotificationsCheck = false;
    this.allSubscriberCheck = false;
    this.allValidationCheck = false;
  }

  // Guardado de alarmas personalizadas
  saveAlarms(): void {
    this.AlarmController.updateCustomAlarms(this.alarmConfig).subscribe(
      (response) => {
        if (response["code"] == 0 && !this.meterId) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
        }
      }
    );
  }
}
