import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material
import { MaterialTreeComponent } from "./material-tree-controller/material-tree/material-tree.component";
import { MaterialTreeControllerComponent } from "./material-tree-controller/material-tree-controller.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRippleModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MaterialSelectComponent } from "./material-select/material-select.component";
import { MaterialDateSelectorComponent } from "./material-date-selector/material-date-selector.component";
import { MaterialPaginatorComponent } from "./material-paginator/material-paginator.component";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import {
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from "@angular/material/checkbox";
import {
  MatRadioModule,
  MAT_RADIO_DEFAULT_OPTIONS,
} from "@angular/material/radio";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MaterialToggleComponent } from "./material-toggle/material-toggle.component";
import { MaterialPanelMenuComponent } from "./material-panel-menu/material-panel-menu.component";
import { MaterialDialogComponent } from "./material-dialog/material-dialog.component";
import { FileImportComponent } from "./file-import/file-import.component";

// Directivas
import { DirectivesModule } from "../../directives/directives.module";
import { MaterialDialogHtmlAnchorDirective } from "./material-dialog/material-dialog-html-anchor.directive";

// Routes
import { RoutesModule } from "../../routes.module";

// Selectors
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

// Theme
import { ThemeModule } from "../../themes/theme.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatTreeModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatPaginatorModule,
    MatMenuModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    ScrollingModule,
    MatSlideToggleModule,

    // Directives
    DirectivesModule,

    // Routes
    RoutesModule,

    // Selectors
    NgxDaterangepickerMd,

    // Themes
    ThemeModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    MaterialTreeComponent,
    MaterialTreeControllerComponent,
    MaterialSelectComponent,
    MaterialDateSelectorComponent,
    MaterialPaginatorComponent,
    MaterialToggleComponent,
    MaterialPanelMenuComponent,
    MaterialDialogComponent,
    MaterialDialogHtmlAnchorDirective,
    FileImportComponent,
  ],
  exports: [
    MaterialTreeComponent,
    MaterialTreeControllerComponent,
    MaterialSelectComponent,
    MaterialDateSelectorComponent,
    MaterialPaginatorComponent,
    MaterialToggleComponent,
    MaterialPanelMenuComponent,
    MaterialDialogComponent,
    MaterialDialogHtmlAnchorDirective,
    FileImportComponent,
  ],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
  ],
})
export class MaterialModule {}
