export const METER_IMPORT_COLUMNS = [
  {
    title: "NRO_SERIE",
    info: "serial-number",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: "LONGITUDE",
    info: "longitude",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: "LATITUDE",
    info: "latitude",
    required: true,
    profilesList: [],
    preselect: [],
  },
];

export interface ImportResponseDevice {
  // BackEnd
  nro_serie: string;
  latitude: number;
  longitude: number;
  code: number;

  // FrontEnd
  responseCodeText?: string;
}
