// @angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { ViewportScroller } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import {
  GlobalSearchDevice,
  GlobalSearchRequestData,
} from "../ManagementInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-management-global-search",
  templateUrl: "./management-global-search.component.html",
  styleUrls: ["./management-global-search.component.scss"],
})
export class ManagementGlobalSearchComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionLanguage: string;

  // Búsqueda
  searchType: string = "nroSerie";
  searchedDevice: string;
  searchPlaceholder: object = {
    nroSerie: this.translate.instant("serial-number") + "...",
    devEui: "DEV EUI...",
    rfModule: this.translate.instant("rf-module") + "...",
  };
  placeholder = this.searchPlaceholder[this.searchType];
  globalSearchSelect: MaterialSelectOption[] = [
    { value: "nroSerie", name: this.translate.instant("serial-number") },
    { value: "devEui", name: "DEV EUI" },
    { value: "rfModule", name: this.translate.instant("rf-module") },
    {
      value: "installation",
      name: this.translate.instant("mix-date"),
    },
  ];
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("0");
  @ViewChild("searchInput") searchInput: ElementRef;

  // Tabla
  tableData: GlobalSearchDevice[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  exportFileName: string =
    this.translate.instant("devices") + " " + this.DateParserService.getDate();
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
  ];
  tableSelectedData: GlobalSearchDevice[];
  tableHighlightRow: object[] = [
    {
      condition: "highlightNewActivation",
      color: "green",
      title: "new-activation",
    },
    {
      condition: "highlightNewDeactivation",
      color: "red",
      title: "new-deactivation",
    },
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-detail",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "activated",
      data: "isActivated",
      search: "isActivated",
      sort: "isActivated",
      alter: {
        condition: "isActivated",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "user",
      data: "eventUser",
      search: "eventUser",
      sort: "eventUser",
      visible: null,
    },
    {
      title: "installation",
      data: "installationDateParsed",
      search: "installationDateParsed",
      sort: "installationDate",
      date: true,
      visible: true,
    },
    {
      title: "activation-deactivation",
      data: "activationDateParsed",
      search: "activationDateParsed",
      sort: "activationDate",
      date: true,
      visible: true,
    },
    {
      title: "DEV EUI",
      data: "devEui",
      search: "devEui",
      sort: "devEui",
      visible: true,
    },
    {
      title: "rf-module",
      data: "rfModule",
      search: "rfModule",
      sort: "rfModule",
      visible: true,
    },
    {
      title: "last-lecture",
      data: "lastReadedTimestampParsed",
      search: "lastReadedTimestampParsed",
      sort: "lastReadedTimestamp",
      date: true,
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      visible: true,
    },
    {
      title: "last-join",
      data: "lastJoinTimestampParsed",
      search: "lastJoinTimestamp",
      sort: "lastJoinTimestamp",
      date: true,
      visible: true,
    },
    {
      title: "manufacturer",
      data: "manufacturerText",
      search: "manufacturerText",
      sort: "manufacturerText",
      visible: true,
    },
    {
      title: "device-type",
      data: "deviceTypeText",
      search: "deviceTypeText",
      sort: "deviceTypeText",
      visible: true,
    },
    {
      title: "groups",
      data: "agrupation",
      search: "agrupation",
      sort: "agrupation",
      visible: true,
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
  ];

  // Mapa
  mapType: string = "meterList";
  mapHeight: number = 600;
  devices: GlobalSearchDevice[];
  gateways: [] = [];
  selectedDevices: GlobalSearchDevice[];
  tableFilteredByMap: boolean = false;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  // Actualizar
  updateIcon: string = "fas fa-sync-alt";
  updateTitle: string = this.translate.instant("update");
  refreshTimer: number = 1;
  refreshInterval: any;
  refreshActivated: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private ManagementController: ManagementControllerService,
    private ManufacturerService: ManufacturerService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService
      .getAgrupation()
      .subscribe((agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.tableData) {
          this.tableData = [];
        }
      });

    if (this.currentAgrupation) {
      this.tableData = [];
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    clearInterval(this.refreshInterval);
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los datos
  getData(): void {
    let url: Observable<object>;
    let data: GlobalSearchRequestData;

    if (this.searchType != "installation") {
      data = {
        nroSerie: this.searchType == "nroSerie" ? this.searchedDevice : null,
        devEui: this.searchType == "devEui" ? this.searchedDevice : null,
        rfModule: this.searchType == "rfModule" ? this.searchedDevice : null,
      };
      url = this.ManagementController.searchDevice(data);
    } else {
      url = this.ManagementController.searchDeviceByDate(this.from, this.to);
    }

    url.subscribe((response) => {
      if (response["code"] == 0) {
        let tableData: GlobalSearchDevice[] = response["body"];
        tableData.forEach((device: GlobalSearchDevice) => {
          let manufacturer: { manufacturerText: string; deviceText: string } =
            this.ManufacturerService.getManufacturer(
              device?.fabricante?.toString(),
              device?.devType?.toString(),
              this.sessionLanguage
            );
          device.manufacturerText = manufacturer.manufacturerText;
          device.deviceTypeText = manufacturer.deviceText;
          device.isActivated = device.activate >= 2 ? true : false;

          // Diferenciación entre fecha de instalación y fecha de activación
          if (device.activate > 0) {
            device.activationDate = device.installation;
          } else {
            device.installationDate = device.installation;
          }

          // Comprobación de nuevo dispositivo
          if (
            this.tableData?.length > 0 &&
            !this.tableData?.some(
              (previousDevice: GlobalSearchDevice) =>
                previousDevice.id == device.id &&
                previousDevice.installation == device.installation
            )
          ) {
            device.isActivated
              ? (device.highlightNewActivation = true)
              : (device.highlightNewDeactivation = true);
          } else {
            device.highlightNewActivation = false;
            device.highlightNewDeactivation = false;
          }
        });
        this.tableData = tableData;
      }
    });
  }

  // Obtención de datos por fecha
  getDataByDate(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.updateIcon = "fas fa-sync-alt";
    this.refreshActivated = false;
  }

  // Acciones de la tabla
  tableActions(action: string, device: GlobalSearchDevice): void {
    switch (action) {
      case "show-detail":
        this.DeviceRouteSelectorService.updateEntity(device.entityId);
        this.DeviceRouteSelectorService.updateAgrupation(device.agrupationId);
        this.DeviceRouteSelectorService.getDeviceRoute(
          device.metrologyType,
          device.id
        );
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      default:
        break;
    }
  }

  // Actualización de búsqueda
  updateSearch(newSearchType: string) {
    this.tableData = [];
    this.devices = null;
    this.searchedDevice = null;
    this.searchType = newSearchType;
    this.placeholder = this.searchPlaceholder[this.searchType];
    this.columns[4].visible = this.searchType == "installation" ? true : null;
    setTimeout(() => this.searchInput?.nativeElement.focus(), 100);
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.devices = [...this.tableSelectedData];
    setTimeout(() => this.viewportScroller.scrollToAnchor("map-panel"), 0);
  }

  // Refresco de datos
  updateRefresh(): void {
    this.updateIcon = "fas fa-sync-alt";
    this.refreshActivated = !this.refreshActivated;
    clearInterval(this.refreshInterval);
    if (this.refreshTimer && this.refreshTimer > 0 && this.refreshActivated) {
      this.updateIcon = "fas fa-sync-alt fa-spin";
      this.refreshInterval = setInterval(() => {
        this.getData();
      }, this.refreshTimer * 60 * 1000);
    }
  }
}
