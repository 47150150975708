<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{
            id != null
              ? ("edit-agrupation" | translate) +
                " : " +
                agrupationData?.descripcion
              : ("new-agrupation" | translate)
          }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <div *ngIf="agrupationData; else elseBlock" class="agrupation-form">
          <!-- Nombre de agrupación -->
          <mat-form-field class="agrupation-form-input" appearance="standard">
            <mat-label>
              <span>{{ "name" | translate }}</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.descripcion"
              placeholder="{{ 'name' | translate }}..."
              required
            />
          </mat-form-field>
          <!-- Alias -->
          <mat-form-field class="agrupation-form-input" appearance="standard">
            <mat-label>
              <span>{{ "alias" | translate }}</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.alias"
              placeholder="{{ 'alias' | translate }}..."
            />
          </mat-form-field>
          <!-- Ruta ftp -->
          <mat-form-field
            class="agrupation-form-input"
            appearance="standard"
            *ngIf="sessionProfile == PROFILES.ARSON"
          >
            <mat-label>
              <span>FTP Path</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.ftpPath"
              placeholder="FTP Path..."
            />
          </mat-form-field>
          <!-- Dominio Aquacity -->
          <mat-form-field
            class="agrupation-form-input"
            appearance="standard"
            *ngIf="sessionProfile == PROFILES.ARSON"
          >
            <mat-label>
              <span>Aquacity Domain</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.aquacityDomain"
              placeholder="Aquacity Domain..."
            />
          </mat-form-field>
          <!-- apiId -->
          <mat-form-field
            class="agrupation-form-input"
            appearance="standard"
            *ngIf="sessionProfile == PROFILES.ARSON"
          >
            <mat-label>
              <span>API Id</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.apiId"
              placeholder="API Id..."
            />
          </mat-form-field>
          <!-- Zona horaria -->
          <div class="agrupation-form-select">
            <app-material-select
              *ngIf="initialTimezone"
              [title]="'timezone' | translate"
              [filter]="true"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="initialTimezone"
              [data]="timezoneOptions"
              (selectedOption)="agrupationData.timezone = $event?.value"
              [required]="true"
            ></app-material-select>
            <div
              *ngIf="entityTimezones?.length > 1"
              class="agrupation-form-timezones"
            >
              <div>
                <i class="fas fa-warning"></i>
                <span>{{ "multiple-timezones" | translate }}</span>
              </div>
              <span *ngFor="let entityTimezone of entityTimezones"
                >{{ entityTimezone.timezone }}: {{ entityTimezone.count }}</span
              >
            </div>
          </div>
          <!-- Hora ftp Aqualia -->
          <mat-form-field
            class="agrupation-form-input"
            appearance="standard"
            *ngIf="currentEntity?.isAqualia && sessionProfile == PROFILES.ARSON"
          >
            <mat-label>
              <span>{{ "ftp-hour" | translate }}</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="agrupationData.aqualiaFtpHour"
              placeholder="{{ 'ftp-hour' | translate }}..."
              type="tel"
              onkeypress="return event.charCode >= 48"
              required
            />
            <b
              *ngIf="aqualiaFtpHour > 23 || aqualiaFtpHour?.length > 2"
              class="red"
              >*{{ "ftp-hour-control" | translate }}</b
            >
          </mat-form-field>
          <!-- Agrupación comodín -->
          <div class="agrupation-form-input-check">
            <div
              *ngIf="sessionProfile == PROFILES.ARSON"
              title="{{ 'all-entity-explanation' | translate }}"
            >
              <mat-checkbox
                *ngIf="id == null"
                class="mat-option"
                [(ngModel)]="agrupationData.showAllEntity"
                (change)="
                  agrupationData.showAllEntity
                    ? (agrupationData.hideInVirtualMap = false)
                    : null
                "
                [disabled]="showAllEntityDisabled"
              >
                {{ "all-entity" | translate }}
              </mat-checkbox>
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="agrupationData.hideInVirtualMap"
                [disabled]="agrupationData.showAllEntity"
              >
                {{ "disable-visualization-virtual" | translate }}
              </mat-checkbox>
            </div>
            <!-- Ignorar joins -->
            <div
              *ngIf="sessionProfile == PROFILES.ARSON"
              title="{{ 'ignore-join-explanation' | translate }}"
            >
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="agrupationData.ignoreLorawanFrames"
              >
                {{ "ignore-frames" | translate }}
              </mat-checkbox>
            </div>
          </div>

          <!-- Acciones -->

          <div class="agrupation-form-buttons">
            <div class="btn-group">
              <!-- Actualizar -->
              <button
                mat-button
                class="mat-button-icon-text"
                *ngIf="id != null"
                [disabled]="
                  agrupationData.descripcion == null ||
                  agrupationData.descripcion == '' ||
                  agrupationData.timezone == null ||
                  agrupationData.aqualiaFtpHour > 23 ||
                  agrupationData.aqualiaFtpHour?.length > 2
                "
                (click)="updateAgrupation()"
              >
                <i class="fas fa-save"></i>
                {{ "update" | translate }}
              </button>
              <!-- Guardar -->
              <button
                mat-button
                class="mat-button-icon-text"
                *ngIf="id == null"
                [disabled]="
                  agrupationData.descripcion == null ||
                  agrupationData.descripcion == '' ||
                  agrupationData.timezone == null ||
                  agrupationData.aqualiaFtpHour > 23 ||
                  agrupationData.aqualiaFtpHour?.length > 2
                "
                (click)="newAgrupation()"
              >
                <i class="fas fa-save"></i>
                {{ "save" | translate }}
              </button>
            </div>
            <div class="btn-group">
              <!-- Reset -->
              <button mat-button (click)="ReloadComponentService.reload()">
                {{ "restart" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>
