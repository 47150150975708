import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ContazaraDecoderService {
  constructor() {}

  contazara_octave_decoder(input) {
    // Trama de test
    let lastReadedVolume;

    if (input && input.bytes) {
      var index = 0;

      //1 BYTES TIPO DE MENSAJE
      var dataFrame = input.bytes[index];
      var isType80: boolean;
      if (dataFrame === hexToSignedInt("0x080")) {
        isType80 = true;
      } else if (dataFrame === hexToSignedInt("0x081")) {
        isType80 = false;
      } else {
        return { data: { error: "Formato de trama desconocido" } };
      }

      index += 1;

      //1 byte de unit type
      index += 1;
      if (isType80) {
        //2 bytes de FW version
        index += 2;
      }

      //1 byte de RSSI
      index += 1;

      //4 byte de UNIX time
      var unixtime1 = input.bytes[index];
      var unixtime2 = input.bytes[index + 1];
      var unixtime3 = input.bytes[index + 2];
      var unixtime4 = input.bytes[index + 3];
      // Combinar los bytes para formar el UNIX time
      var unixtime =
        (unixtime1 << 24) | (unixtime2 << 16) | (unixtime3 << 8) | unixtime4;
      index += 4;
      //4 bytes volumen
      var vol1 = input.bytes[index];
      var vol2 = input.bytes[index + 1];
      var vol3 = input.bytes[index + 2];
      var vol4 = input.bytes[index + 3];
      var vol = (vol1 << 24) | (vol2 << 16) | (vol3 << 8) | vol4;
      vol = vol / 1000;
      index += 4;

      //1 byte factor
      var factorValues = [
        0.0000001, 0.000001, 0.00001, 0.0001, 0.001, 0.01, 0.1, 1, 10, 100,
        1000, 10000, 100000, 1000000, 10000000, 100000000,
      ];
      var factor = input.bytes[index];
      // Obtener el valor mapeado
      var factorValue = factorValues[factor];
      convertToParsedValues();
      index += 1;

      //2 byte alarms common
      var alarmDescriptions = [
        "Tamper detection, e.g. magnet (if supported/available in the main device)",
        "Failure in metrological core",
        "Reverse flow above (x) threshold",
        "Leakage detection (constant flow) per (time slice) over (period)",
        "Communication with the network is not stable",
        "Low battery detection / Low battery calculation (device with the radio interface battery)",
        "Device detects change in factory / production parameters",
        "General system alert (extended system alerts / event register)",
        "Temperature alert (Device detects temperature below threshold)",
        "Tilt alert (if supported/available in the main device)",
        "Device crosses the Qmax threshold for (x) time",
        "Burst",
        "Empty pipe detection",
        "Metrology low battery (Metrological core)",
        "Invalid read from metrology (Metrological core)",
        "Reserved",
      ];
      var alarmsCommon1 = input.bytes[index];
      var alarmsCommon2 = input.bytes[index + 1];
      var alarmsCommon = (alarmsCommon1 << 8) | alarmsCommon2;

      var mappedAlarm = alarmDescriptions[alarmsCommon& 0xF];
      index += 2;

      var backwardTotal = null;
      //backward totalizer (4 byte)
      if (!isType80) {
        backwardTotal = input.bytes[index];
        index += 4;
      }

      if (isType80) {
        // Leer los dos bytes que representan el nivel de batería
        var batteryLevel1 = input.bytes[index];
        var batteryLevel2 = input.bytes[index + 1];

        // Unir los dos bytes para obtener el valor entero de batería en milivoltios
        var batteryRawValue = (batteryLevel1 << 8) | batteryLevel2;

        // Mover el índice para la siguiente lectura
        index += 2;

        // Convertir el valor de batería a voltios (milivoltios a voltios)
        var batteryLevel = batteryRawValue / 1000.0;  // Ejemplo: 3645 milivoltios se convierten a 3.645 V

        // Calcular el porcentaje de batería en función de un valor máximo de 3.6 V
        var batteryPercentage = (batteryLevel / 3.6) * 100;

        // Asegurarse de que el porcentaje esté entre 0% y 100%
        batteryPercentage = Math.min(Math.max(batteryPercentage, 0), 100);

        // Imprimir o almacenar el resultado
    }


      // Obtener los valores de hourlyArchive
      var hourlyErrorDescriptions = {
        0x8000: "Record empty",
        0x8001: "Over-Flow",
        0x8002: "Read error",
        0x8003: "Digit error",
      };

      var hourlyArchive1 = getHourlyArchive(index);
      index += 2;

      var hourlyArchive2 = getHourlyArchive(index);
      index += 2;

      var hourlyArchive3 = getHourlyArchive(index);
      index += 2;

      var hourlyArchive4 = getHourlyArchive(index);
      index += 2;

      var hourlyArchive5 = getHourlyArchive(index);
      index += 2;

      var hourlyArchive6 = getHourlyArchive(index);
      index += 2;

      if (isType80) {
        var weekly1 = getWeeklyArchive(index);
        index += 4;

        var weekly2 = getWeeklyArchive(index);
        index += 4;

        var weekly3 = getWeeklyArchive(index);
        index += 4;

        var weekly4 = getWeeklyArchive(index);
        index += 4;

        var weekly5 = getWeeklyArchive(index);
      }
      return returnResults();
    }

    function returnResults() {
      const fechaTrama = new Date(unixtime * 1000);

      function formatDate(date: Date): string {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;
      }

      // Función que redondea la hora al inicio de la hora anterior
      function roundToPreviousHour(date) {
        date.setMinutes(0);  // Establecer los minutos a 0
        date.setSeconds(0);  // Establecer los segundos a 0
        date.setMilliseconds(0); // Establecer los milisegundos a 0
        return date;
      }

      // Crear el objeto hourlyData con las horas ajustadas a la hora punta anterior
      const hourlyData = {
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 0 * 60 * 60 * 1000)))]: `${hourlyArchive1.toFixed(3)} m³`,
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 1 * 60 * 60 * 1000)))]: `${hourlyArchive2.toFixed(3)} m³`,
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 2 * 60 * 60 * 1000)))]: `${hourlyArchive3.toFixed(3)} m³`,
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 3 * 60 * 60 * 1000)))]: `${hourlyArchive4.toFixed(3)} m³`,
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 4 * 60 * 60 * 1000)))]: `${hourlyArchive5.toFixed(3)} m³`,
        [formatDate(roundToPreviousHour(new Date(fechaTrama.getTime() - 5 * 60 * 60 * 1000)))]: `${hourlyArchive6.toFixed(3)} m³`,
      };


      const adjustedWeeklyData: { [key: string]: string } = {};
      for (let i = 1; i <= 5; i++) {
        const adjustedDate = new Date(
          fechaTrama.getTime() - i * 7 * 24 * 60 * 60 * 1000
        ); // Restamos i semanas (7 días cada una)
        const formattedDate = formatDate(adjustedDate);

        const weeklyValue = eval(`weekly${i}`);
        if (
          weeklyValue !== null &&
          weeklyValue !== undefined &&
          weeklyValue !== "null"
        ) {
          adjustedWeeklyData[formattedDate] = `${weeklyValue.toFixed(
            3
          )} m³ [ ${weeklyValue.toFixed(2)} - ${weeklyValue.toFixed(3)} ]`;
        }
      }

      const result = {
        data: {
          Formato_Trama: isType80 ? "0x80" : "0x81",
          Fecha_Trama: formatDate(fechaTrama),
          Volume: vol.toFixed(3) + " m³", // Formato con dos decimales
          ...(batteryLevel !== undefined
            ? { "Battery level": batteryLevel.toFixed(3) + " V" }
            : {}),
          Alarm: mappedAlarm,
          "Backflow volume":
            backwardTotal !== null
              ? (backwardTotal * factorValue).toFixed(3) + " m³"
              : undefined, // Formato con dos decimales
          Values: hourlyData,
          ...(Object.keys(adjustedWeeklyData).length > 0
            ? { Weekly: adjustedWeeklyData }
            : {}),
        },
      };

      // Filtrar los valores undefined, null o cadenas vacías del objeto
      const filterUndefinedResults = (obj: any) => {
        const filteredObj: any = {};
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          if (
            value !== undefined &&
            value !== null &&
            value !== "" &&
            !(Array.isArray(value) && value.length === 0)
          ) {
            filteredObj[key] = value;
          }
        });
        return filteredObj;
      };

      // Filtrar los resultados y retornar el objeto limpio
      return {
        data: filterUndefinedResults(result.data),
      };
    }

    function convertToParsedValues() {
      vol = vol * factorValue;
      lastReadedVolume = vol;
    }

    function getWeeklyArchive(index) {
      var value1 = input.bytes[index];
      var value2 = input.bytes[index + 1];
      var value3 = input.bytes[index + 2];
      var value4 = input.bytes[index + 3];
      var value = (value1 << 24) | (value2 << 16) | (value3 << 8) | value4;

      var hexValue = "0x80000000";
      if (value === hexToSignedInt(hexValue)) {
        return null;
      }
      return (value * factorValue) / 1000; // Convertir a m³
    }

    function hexToSignedInt(hex) {
      var intValue = parseInt(hex, 16);
      if (intValue > 0x7fffffff) {
        intValue -= 0x100000000;
      }
      return intValue;
    }

    function getHourlyArchive(index) {
      var value1 = input.bytes[index];
      var value2 = input.bytes[index + 1];
      var value = (value1 << 8) | value2;
      if (value in hourlyErrorDescriptions) {
        return hourlyErrorDescriptions[value];
      }
      let res = lastReadedVolume - (value * factorValue) / 1000; // Convertir a m³
      lastReadedVolume = res;
      return res;
    }

    function hexToBytes(hexString: string): Uint8Array {
      const bytes = new Uint8Array(hexString.length / 2);
      for (let i = 0; i < hexString.length; i += 2) {
        bytes[i / 2] = parseInt(hexString.substr(i, 2), 16);
      }
      return bytes;
    }
  }
}
