/**
 * Decodes the given input for the Kamstrup protocol.
 *
 * @param {any} input - The input to be decoded.
 *
 * @returns {Object} An object with the following possible structures:
 * 1. On error:
 *   - { errors: string[]; data?: undefined; }
 *  2. On success:
 *   - { data: {}; errors?: undefined; }
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class KamstrupDecoderService {
  constructor() {}

  tiposDatos = {
    ENTERO_8BITS: 1,
    ENTERO_16BITS: 2,
    ENTERO_24BITS: 3,
    ENTERO_32BITS: 4,
    REAL_32BITS: 5,
    ENTERO_48BITS: 6,
    ENTERO_64BITS: 7,
    BCD_2DIGITOS: 9,
    BCD_4DIGITOS: 10,
    BCD_6DIGITOS: 11,
    BCD_8DIGITOS: 12,
    LONGITUD_VARIABLE: 13,
    BCD_12DIGITOS: 14,
  };

  modosPerfil = {
    VALOR_ABSOLUTO: 0,
    INCREMENTO: 1,
    DECREMENTO: 2,
    DIFERENCIA: 3,
  };

  kamstrup_decoder(input) {
    if (input) {
      if (input.bytes) {
        if (input.bytes.length < 52) {
          var outputData = {};
          var outputErrors = [];
          var index = 0;
          var ci = input.bytes[index++];
          if (ci == 0x72) {
            var A0 = input.bytes[index++].toString(16).padStart(2, "0");
            var A1 = input.bytes[index++].toString(16).padStart(2, "0");
            var A2 = input.bytes[index++].toString(16).padStart(2, "0");
            var A3 = input.bytes[index++].toString(16).padStart(2, "0");
            var M0 = input.bytes[index++].toString(16).padStart(2, "0");
            var M1 = input.bytes[index++].toString(16).padStart(2, "0");
            var V = input.bytes[index++].toString(16).padStart(2, "0");
            var T = input.bytes[index++].toString(16).padStart(2, "0");
            var direccion = M0 + M1 + A0 + A1 + A2 + A3 + V + T;
            var accessNumber = input.bytes[index++];
            var status = input.bytes[index++];
            var configurationField = this.readU16Lsb(input.bytes, index);
            index += 2;
            if (((configurationField >> 8) & 0x1f) != 0) {
              return {
                errors: [
                  "securityMode no implementado (sólo aceptado sin encriptación)",
                ],
              };
            }
          } else if (ci != 0x78) {
            return {
              errors: [
                "CI inválido (0x" + ci.toString(16).padStart(2, "0") + ").",
              ],
            };
          }

          /* Procesa DIB-VIBs*/
          var fechaBase = null;
          var valorBase = null;
          for (var indexObjeto = 0; index < input.bytes.length; indexObjeto++) {
            var dif = input.bytes[index++];
            var difes = [];
            if (dif & 0x80) {
              do {
                var dife = input.bytes[index++];
                difes.push(dife);
              } while (dife & 0x80);
            }
            var vif = input.bytes[index++];
            var vifes = [];
            if (vif & 0x80) {
              do {
                var vife = input.bytes[index++];
                vifes.push(vife);
              } while (vife & 0x80);
            }
            if (dif == 0x0f) {
              /*Comienzo de datos de fabricante hasta el fin de datos*/
              outputData["Datos_Fabricante"] = this.bytesToHex(
                input.bytes.slice(index)
              );
              index = input.bytes.length;
            } else if (dif == 0x1f) {
              /*Comienzo de datos de fabricante hasta el fin de datos y siguen más registros en la siguiente trama*/
              outputData["Datos_Fabricante"] = this.bytesToHex(
                input.bytes.slice(index)
              );
              index = input.bytes.length;
            } else if (dif == 0x2f) {
              /*Relleno desocupado*/
            } else if ((dif & 0x0f) != 0x0f) {
              var difTipoDato = dif & 0x0f;
              var difFunction = (dif >> 4) & 0x03;
              var difStorageNumber = (dif >> 6) & 0x01;
              if (difTipoDato == this.tiposDatos.LONGITUD_VARIABLE) {
                var longitudDato = input.bytes[index++]; // LVAR + 1
                if (
                  difes.length ||
                  !difStorageNumber ||
                  (vif & 0xf8) != 0x90 ||
                  vifes.length != 1 ||
                  vifes[0] != 0x13
                ) {
                  /* El único campo de longitud variable parseado es el perfil compacto inverso*/
                  var objeto = this.objetoDataRaw(
                    dif,
                    difes,
                    vif,
                    vifes,
                    input.bytes,
                    index,
                    longitudDato
                  );
                  objeto["Descripcion"] = "Objeto de longitud variable";
                  objeto["Valor"] =
                    "No Parseado!! Sólo válido para perfil compacto inverso";
                  outputData["DATO_" + indexObjeto.toString()] = objeto;
                } else {
                  var pesoPulso = 10 ** ((vif & 0x07) - 6);
                  if (fechaBase == null || valorBase == null) {
                    return {
                      errors: [
                        "Perfil compacto inverso pero no están el valor y fecha base en los datos previos",
                      ],
                    };
                  } else {
                    var subIndex = index;
                    var sc = input.bytes[subIndex++];
                    var difPerfil = sc & 0x0f;
                    var longitudRegistros = this.longitudDifs(difPerfil);
                    if (longitudRegistros == 0) {
                      return {
                        errors: [
                          "Perfil compacto inverso. ERROR:  DIF del SC no válido!!!: 0x" +
                            difPerfil.toString(16).padStart(2, "0"),
                        ],
                      };
                    } else if (
                      difPerfil == this.tiposDatos.REAL_32BITS ||
                      difPerfil == this.tiposDatos.ENTERO_48BITS ||
                      difPerfil == this.tiposDatos.ENTERO_64BITS
                    ) {
                      return {
                        errors: [
                          "Perfil compacto inverso. ERROR:  TIPO DE DATOS NO VÁLIDO: 0x" +
                            difPerfil.toString(16).padStart(2, "0"),
                        ],
                      };
                    } else {
                      var numeroRegistros =
                        (longitudDato - 2) / longitudRegistros;
                      if (numeroRegistros == 0) {
                        return {
                          errors: [
                            "Perfil compacto inverso. ERROR:  No incluye registros",
                          ],
                        };
                      } else {
                        var sv = input.bytes[subIndex++];
                        switch ((sc >> 4) & 0x03 /* Periodo a milisegundos*/) {
                          case 0: {
                            var periodo = sv * 1000;
                            break;
                          }
                          case 1: {
                            var periodo = 60 * sv * 1000;
                            break;
                          }
                          case 2: {
                            var periodo = 60 * 60 * sv * 1000;
                            break;
                          }
                          case 3: {
                            var periodo = 24 * 60 * 60 * sv * 1000;
                            break;
                          }
                        }
                        var modo = (sc >> 6) & 0x03;
                        if (modo == this.modosPerfil.VALOR_ABSOLUTO) {
                          return {
                            errors: [
                              "Perfil compacto inverso. ERROR:  Modo Valor absoluto : no válido",
                            ],
                          };
                        } else if (modo == this.modosPerfil.DECREMENTO) {
                          return {
                            errors: [
                              "Perfil compacto inverso. ERROR:  Modo Decremento : no válido",
                            ],
                          };
                        } else if (
                          modo == this.modosPerfil.DIFERENCIA &&
                          difPerfil >= this.tiposDatos.BCD_2DIGITOS &&
                          difPerfil <= this.tiposDatos.BCD_12DIGITOS
                        ) {
                          return {
                            errors: [
                              "Perfil compacto inverso. ERROR:  Modo diferencia con datos tipo BCD",
                            ],
                          };
                        } else {
                          outputData[fechaBase.toLocaleString()] =
                            valorBase.toFixed(3) + " m3";
                          var valorAnterior = valorBase;
                          var fechaAnterior = fechaBase;
                          var indiceRegistro = 0;
                          do {
                            let fecha = new Date();
                            fecha = new Date(fechaAnterior.getTime() - periodo);
                            if (modo == this.modosPerfil.INCREMENTO) {
                              var consumo = this.getDatoUnsigned(
                                input.bytes,
                                subIndex,
                                difPerfil
                              );
                            } else {
                              var consumo = this.getDatoSigned(
                                input.bytes,
                                subIndex,
                                difPerfil
                              );
                            }
                            if (consumo == null) {
                              break;
                            }
                            subIndex += this.longitudDifs(difPerfil);
                            var valor = valorAnterior - consumo * pesoPulso;
                            outputData[fecha.toLocaleString()] =
                              valor.toFixed(3) + " m3";
                            valorAnterior = valor;
                            fechaAnterior = fecha;
                          } while (++indiceRegistro < numeroRegistros);
                        }
                      }
                    }
                  }
                }
                index += longitudDato + 1;
              } else {
                var longitudDato: any = this.longitudDifs(difTipoDato);
                if (difes.length) {
                  var objeto = this.objetoDataRaw(
                    dif,
                    difes,
                    vif,
                    vifes,
                    input.bytes,
                    index,
                    longitudDato
                  );
                  objeto["Error"] = "No hay objetos con DIFEs ";
                  outputData["DATO_" + indexObjeto.toString()] = objeto;
                } else {
                  switch (vif) {
                    case 0x6d:
                      if (difTipoDato != this.tiposDatos.ENTERO_32BITS) {
                        var objeto = this.objetoDataRaw(
                          dif,
                          difes,
                          vif,
                          vifes,
                          input.bytes,
                          index,
                          longitudDato
                        );
                        objeto["Fecha"] =
                          "ERROR. FECHA FORMATO NO PARSEADO, SOLO PARSEADO TIPO F";
                        outputData["DATO_" + indexObjeto.toString()] = objeto;
                      } else {
                        var dateF = this.readU32Lsb(input.bytes, index);
                        var minute = dateF & 0x3f;
                        var hour = (dateF >> 8) & 0x1f;
                        var day = (dateF >> 16) & 0x1f;
                        var month = (dateF >> 24) & 0x0f;
                        var year =
                          1900 +
                          100 * ((dateF >> 13) & 0x03) +
                          (((dateF >> 21) & 0x07) + ((dateF >> 25) & 0x78));
                        var date = new Date(
                          Date.UTC(year, month - 1, day, hour, minute) - 3600000
                        );
                        outputData["Fecha"] = date.toLocaleString();
                        if (difStorageNumber) {
                          fechaBase = date;
                        }
                      }
                      break;
                    case 0x10:
                    case 0x11:
                    case 0x12:
                    case 0x13:
                    case 0x14:
                    case 0x15:
                    case 0x16:
                    case 0x17:
                      var pesoPulso = 10 ** ((vif & 0x07) - 6);
                      var valorPulsos = this.getDatoUnsigned(
                        input.bytes,
                        index,
                        difTipoDato
                      );
                      outputData["Valor_del_contador"] =
                        (valorPulsos * pesoPulso).toFixed(3) + " m3";
                      if (difStorageNumber) {
                        valorBase = valorPulsos * pesoPulso;
                      }
                      break;
                    case 0x38:
                    case 0x39:
                    case 0x3a:
                    case 0x3b:
                    case 0x3c:
                    case 0x3d:
                    case 0x3e:
                    case 0x3f:
                      var pesoPulso = 10 ** ((vif & 0x07) - 6);
                      let caudal = this.getDatoSigned(
                        input.bytes,
                        index,
                        difTipoDato
                      );
                      switch (difFunction) {
                        case 0:
                          outputData["Caudal"] =
                            (caudal * pesoPulso).toFixed(3) + " m3/h";
                          break;
                        case 1:
                          outputData["Caudal_Maximo"] =
                            (caudal * pesoPulso).toFixed(3) + " m3/h";
                          break;
                        case 2:
                          outputData["Caudal_Minimo"] =
                            (caudal * pesoPulso).toFixed(3) + " m3/h";
                          break;
                        case 3:
                          outputData["Caudal_en_Error"] =
                            (caudal * pesoPulso).toFixed(3) + " m3/h";
                          break;
                      }
                      break;
                    case 0x58:
                    case 0x59:
                    case 0x5a:
                    case 0x5b:
                      var pesoPulso = 10 ** ((vif & 0x07) - 6);
                      var temperatura = this.getDatoSigned(
                        input.bytes,
                        index,
                        difTipoDato
                      );
                      switch (difFunction) {
                        case 0:
                          outputData["Temperatura"] =
                            temperatura == -128
                              ? "0x80 - Valor no válido"
                              : temperatura.toString() + " ºC";
                          break;
                        case 1:
                          outputData["Temperatura_Maxima"] =
                            temperatura == -128
                              ? "0x80 - Valor no válido"
                              : temperatura.toString() + " ºC";
                          break;
                        case 2:
                          outputData["Temperatura_Minima"] =
                            temperatura == -128
                              ? "0x80 - Valor no válido"
                              : temperatura.toString() + " ºC";
                          break;
                        case 3:
                          outputData["Temperatura_en_Error"] =
                            temperatura == -128
                              ? "0x80 - Valor no válido"
                              : temperatura.toString() + " ºC";
                          break;
                      }
                      break;
                    case 0xff:
                      switch (vifes[0]) {
                        case 0x16:
                          var configNumber = this.getDatoUnsigned(
                            input.bytes,
                            index,
                            difTipoDato
                          );
                          outputData["Module_type__Config_number"] =
                            "0x" +
                            configNumber.toString(16).padStart(8, "0") +
                            " - " +
                            configNumber.toString();
                          break;
                        case 0x1c:
                          var acousticNoise = this.getDatoUnsigned(
                            input.bytes,
                            index,
                            difTipoDato
                          );
                          outputData["Acoustic_Noise"] =
                            acousticNoise == 4095
                              ? "4095 - Valor no válido"
                              : acousticNoise;
                          break;
                        case 0x25:
                          if (difTipoDato != this.tiposDatos.ENTERO_16BITS) {
                            outputData["InfoWaterMeterFull"] =
                              "ERROR, Tipo de datos incorrecto!!!";
                          } else {
                            let infoWaterMeterFull = this.readU16Lsb(
                              input.bytes,
                              index
                            );
                            if (infoWaterMeterFull != 0) {
                              var alarmas;
                              if (infoWaterMeterFull & (1 << 0)) {
                                alarmas = alarmas + " Dry";
                              }
                              if (infoWaterMeterFull & (1 << 1)) {
                                alarmas = alarmas + " Reverse";
                              }
                              if (infoWaterMeterFull & (1 << 2)) {
                                alarmas = alarmas + " Leak";
                              }
                              if (infoWaterMeterFull & (1 << 3)) {
                                alarmas = alarmas + "  Burst";
                              }
                              if (infoWaterMeterFull & (1 << 4)) {
                                alarmas = alarmas + "  Tamper";
                              }
                              if (infoWaterMeterFull & (1 << 5)) {
                                alarmas = alarmas + "  Low_battery";
                              }
                              if (infoWaterMeterFull & (1 << 6)) {
                                alarmas = alarmas + "  Low_Ambient_Temperature";
                              }
                              if (infoWaterMeterFull & (1 << 7)) {
                                alarmas =
                                  alarmas + "  High_Ambient_Temperature";
                              }
                              if (infoWaterMeterFull & (1 << 8)) {
                                alarmas = alarmas + "  Flow_Above_Q4";
                              }
                              if (infoWaterMeterFull & (1 << 11)) {
                                alarmas = alarmas + "  No_Consumption";
                              }
                              outputData["Alarmas"] = alarmas;
                            }
                          }
                          break;
                        case 0x23:
                          outputData["InfoColdWaterMeter"] =
                            "NO DESCRITO EN EL MANUAL";
                          break;
                      }
                      break;
                    default:
                      var objeto = this.objetoDataRaw(
                        dif,
                        difes,
                        vif,
                        vifes,
                        input.bytes,
                        index,
                        longitudDato
                      );
                      outputData["DATO_" + indexObjeto.toString()] = objeto;
                      break;
                  }
                }
                index += longitudDato;
              }
            }
          }
          return {
            data: outputData,
          };
        } else {
          return {
            errors: ["Tamaño del FRMPayload excesivo."],
          };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /* Helper Methods */
  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  objectAssign(source, target) {
    var _source = [];
    var _target = [];

    if (source) {
      _source = Object(source);
      if (target) {
        _target = Object.keys(target);

        for (var i = 0; i < _target.length; i++) {
          if (Object.prototype.hasOwnProperty.call(target, _target[i])) {
            _source[_target[i]] = target[_target[i]];
          }
        }
      }
    }
    return _source;
  }

  longitudDifs(tipoDato) {
    var longitud = 0;
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        longitud = 1;
        break;
      case this.tiposDatos.ENTERO_16BITS:
        longitud = 2;
        break;
      case this.tiposDatos.ENTERO_24BITS:
        longitud = 3;
        break;
      case this.tiposDatos.ENTERO_32BITS:
        longitud = 4;
        break;
      case this.tiposDatos.REAL_32BITS:
        longitud = 4;
        break;
      case this.tiposDatos.ENTERO_48BITS:
        longitud = 6;
        break;
      case this.tiposDatos.ENTERO_64BITS:
        longitud = 8;
        break;
      case this.tiposDatos.BCD_2DIGITOS:
        longitud = 1;
        break;
      case this.tiposDatos.BCD_4DIGITOS:
        longitud = 2;
        break;
      case this.tiposDatos.BCD_6DIGITOS:
        longitud = 3;
        break;
      case this.tiposDatos.BCD_8DIGITOS:
        longitud = 4;
        break;
      case this.tiposDatos.BCD_12DIGITOS:
        longitud = 6;
        break;
      case this.tiposDatos.LONGITUD_VARIABLE:
        longitud = 0;
        break;
      default:
        longitud = -1;
        break;
    }
    return longitud;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  getDatoUnsigned(bytes, start, tipoDato) {
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        if (bytes[start] == 0xff) {
          return null;
        } else {
          return bytes[start];
        }
        break;
      case this.tiposDatos.ENTERO_16BITS:
        if (bytes[start] == 0xffff) {
          return null;
        } else {
          return this.readU16Lsb(bytes, start);
        }
        break;
      case this.tiposDatos.ENTERO_24BITS:
        if (bytes[start] == 0xffffff) {
          return null;
        } else {
          return this.readU24Lsb(bytes, start);
        }
        break;
      case this.tiposDatos.ENTERO_32BITS:
        if (bytes[start] == 0xff) {
          return null;
        } else {
          return this.readU32Lsb(bytes, start);
        }
        break;
      case this.tiposDatos.BCD_2DIGITOS:
      case this.tiposDatos.BCD_4DIGITOS:
      case this.tiposDatos.BCD_6DIGITOS:
      case this.tiposDatos.BCD_8DIGITOS:
        var valor = 0;
        var valorInvalido = 99;
        for (var i = this.longitudDifs(tipoDato) - 1; i >= 0; i--) {
          valor *= 10;
          valor += (bytes[start + i] >> 4) & 0x0f;
          valor *= 10;
          valor += bytes[start + i] & 0x0f;
        }
        switch (tipoDato) {
          case this.tiposDatos.BCD_4DIGITOS:
            valorInvalido = 9999;
            break;
          case this.tiposDatos.BCD_6DIGITOS:
            valorInvalido = 999999;
            break;
          case this.tiposDatos.BCD_8DIGITOS:
            valorInvalido = 99999999;
            break;
        }
        if (valor == valorInvalido) {
          return null;
        } else {
          return valor;
        }
        break;
      default:
        return null;
        break;
    }
  }

  getDatoSigned(bytes, start, tipoDato) {
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        var valor = bytes[start];
        if (valor & 0x80) {
          valor = valor - 0xff - 1;
        }
        if (valor == 0x80) {
          return null;
        } else {
          return valor;
        }
        break;
      case this.tiposDatos.ENTERO_16BITS:
        valor = this.readU16Lsb(bytes, start);
        if (valor & 0x8000) {
          valor = valor - 0xffff - 1;
        }
        if (valor == 0x8000) {
          return null;
        } else {
          return valor;
        }
        break;
      case this.tiposDatos.ENTERO_24BITS:
        valor = this.readU24Lsb(bytes, start);
        if (valor & 0x800000) {
          valor = valor - 0xffffff - 1;
        }
        if (valor == 0x800000) {
          return null;
        } else {
          return valor;
        }
        break;
      case this.tiposDatos.ENTERO_32BITS:
        valor = this.readU32Lsb(bytes, start);
        if (valor & 0x80000000) {
          valor = valor - 0xffffffff - 1;
        }
        if (valor == 0x80000000) {
          return null;
        } else {
          return valor;
        }
        break;
      default:
        return null;
        break;
    }
  }

  objetoDataRaw(dif, difes, vif, vifes, bytes, start, longitud) {
    var difTipoDato = dif & 0x0f;
    var difFunction = (dif >> 4) & 0x03;
    var difStorageNumber = (dif >> 6) & 0x01;

    var objeto = {
      DIF: "0x" + dif.toString(16).padStart(2, "0"),
    };
    if (difes.length) {
      objeto["DIFEs"] = this.bytesToHex(difes);
    }
    objeto["VIF"] = "0x" + vif.toString(16).padStart(2, "0");
    if (vifes.length) {
      objeto["VIFEs"] = this.bytesToHex(vifes);
    }
    objeto["dataRaw"] = this.bytesToHex(bytes.slice(start, start + longitud));
    return objeto;
  }
}
