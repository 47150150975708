<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "metrological-list" | translate }}</b>
        </h5>
        <!-- Botón añadir -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newMetrologic()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div class="col-md-12">
          <h4>
            <b>{{ "file-import" | translate }}</b>
            <i class="fas fa-info-circle pointer" (click)="showInfo()"></i>
          </h4>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="import($event)"
          ></app-file-import>

          <hr />

          <h4>
            <b>{{ "metrological" | translate }}</b>
          </h4>

          <!-- Tabla de alarmas -->
          <app-table-controller
            [tableId]="'dataManagementMetrological'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="tableMaxReg"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [data]="tableData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
