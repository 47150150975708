<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "file-timer" | translate }}</b>
        </h5>

        <div class="output-files-new" *ngIf="clientFiles">
          <label>{{ "files-new-hour" | translate }}:</label>
          <select #outputFilesSelect [(ngModel)]="newHour">
            <option
              *ngFor="let emptyHour of emptyHours"
              value="{{ emptyHour }}"
            >
              {{ emptyHour > 9 ? emptyHour : "0" + emptyHour }}:00
            </option>
          </select>
        </div>

        <!-- Botón añadir -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newFiles()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div
          class="output-files-timer-files"
          *ngIf="clientFiles; else elseBlock"
        >
          <div class="output-files-timer-files-wrap">
            <ng-container *ngFor="let hour of hours; index as i">
              <ng-container *ngIf="clientFiles[i].showHour">
                <div class="panel panel-default output-files-timer-hour">
                  <div class="panel-heading">
                    <!-- Eliminar hora -->
                    <button
                      type="button"
                      (click)="deleteHour(hour)"
                      title="{{ 'delete' | translate }}"
                    >
                      <i class="fas fa-trash"></i>
                      <span>{{ "delete" | translate }}</span>
                    </button>
                    <button
                      type="button"
                      (click)="selectAll(hour)"
                      title="{{ 'select-all' | translate }}"
                    >
                      <i class="fas fa-check-double"></i>
                      <span>{{ "select-all" | translate }}</span>
                    </button>
                    <a
                      class="pointer"
                      (click)="
                        clientFiles[i].showTree = !clientFiles[i].showTree
                      "
                    >
                      <!-- Hora de envío -->
                      <div class="output-files-timer-header">
                        <div>
                          <i class="fas fa-clock"></i>
                          <span class="output-files-timer-title">{{
                            "sent-hour" | translate
                          }}</span>
                        </div>
                        <span class="output-files-timer-data"
                          >{{ hour > 9 ? hour : "0" + hour }}:00</span
                        >
                      </div>
                      <!-- Agrupaciones seleccionadas -->
                      <div class="output-files-timer-header">
                        <div>
                          <i class="fas fa-check-square"></i>
                          <span class="output-files-timer-title">{{
                            "selected" | translate
                          }}</span>
                        </div>
                        <span class="output-files-timer-data">{{
                          clientFiles[i].selected
                        }}</span>
                      </div>
                    </a>
                  </div>
                  <!-- Árbol de agrupaciones -->
                  <div
                    *ngIf="clientFiles[i].showTree"
                    class="output-files-timer-tree"
                  >
                    <app-material-tree-controller
                      [nodes]="hoursTree[i]"
                      (selectedChange)="onSelectedChange(hour, $event)"
                    ></app-material-tree-controller>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div class="output-files-timer-buttons" *ngIf="clientFiles">
          <!-- Actualizar -->
          <button
            mat-button
            class="mat-button-icon"
            (click)="saveFilesConfig()"
          >
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
          <!-- Reiniciar -->
          <button mat-button class="mat-button-icon" (click)="resetData()">
            <i class="fas fa-sync-alt"></i>
            {{ "restart" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
