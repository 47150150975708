// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, forkJoin, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { UserControllerService } from "../../../../services/server/UserController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { ProfileService } from "../../../../services/shared/ProfileService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  User,
  UserEntity,
  UserAgrupation,
  UserAllAgrupation,
  UserCups,
  UserSave,
} from "../UserInterface.type";
import { ClaveMaestra } from "../../../../interfaces/CupsGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
// Componentes
import { MaterialSelectComponent } from "../../../../modules/material-module/material-select/material-select.component";
import { CupsUser } from "../../cups/CupsInterface.type";
// Variables
import { PROD_CLIENT_LIST } from "../../../../interfaces/ClientGlobalInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../../services/language/LanguageController.service";

@Component({
  selector: "app-userform",
  templateUrl: "./users-form.component.html",
  styleUrls: ["./users-form.component.scss"],
})
export class UserFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  clientList: Client[];
  clientListSub: Subscription;
  sessionProfile: string;
  currentUser: string;
  currentUserId: number;
  sessionLanguage: string;
  avoidPassword: boolean;
  readonly PROD_CLIENT_LIST = PROD_CLIENT_LIST;
  readonly PROFILES = PROFILES;

  // Lista de idiomas
  languageSelect: MaterialSelectOption[] = [
    { value: LANGUAGE.ESPANOL, name: "Castellano" },
    { value: LANGUAGE.INGLES, name: "English" },
    { value: LANGUAGE.CATALAN, name: "Catalá" },
    { value: LANGUAGE.FRANCES, name: "Français" },
    { value: LANGUAGE.ITALIANO, name: "Italiano" },
    { value: LANGUAGE.GRIEGO, name: "Ελληνικά" },
  ];
  languageUpdated: boolean = false;
  languageInitialSelection: string;

  // Contraseña
  showPassword: boolean = false;
  showPasswordRequeriments: boolean = false;
  repeatedPassword: string;

  // Usuario
  userId: number;
  userData: User = new User();
  loggedUserData: {
    clientList: Client[];
    clientListIds: number[];
    profileList: { value: number; name: string }[];
    entityList: UserEntity[];
    cupsList: UserCups[];
  };
  userSelfEditing: boolean = false;
  stateData: { profile: string; cups: ClaveMaestra };
  userToken: string;
  isTlm: boolean;

  // Notificaciones
  smsNotifications: boolean = false;
  smsNotificationsDisabled: boolean = false;
  emailNotificationsDisabled: boolean = false;

  // Dropdowns
  agrupationDropdownItems: (UserAgrupation | UserAllAgrupation)[] = [];
  agrupationDropdownItemsOriginal: (UserAgrupation | UserAllAgrupation)[] = [];
  cupsDropdownItems: UserCups[] = [];
  subscriberCups: UserCups[] = [];

  // Selecciones iniciales
  profileInitialSelection: number;
  clientInitialSelection: number;
  agrupationInitialSelection: number[];
  entityInitialSelection: number | number[];
  cupsInitialSelection: number | number[];

  // Selectores
  @ViewChild("clientSelect") clientSelect: MaterialSelectComponent;
  @ViewChild("entitySelect") entitySelect: MaterialSelectComponent;
  @ViewChild("agrupationSelect") agrupationSelect: MaterialSelectComponent;
  @ViewChild("cupsSelect") cupsSelect: MaterialSelectComponent;

  // Botón importar usuario
  importUserIcon: string = "fas fa-file-import";
  importUserTitle: string = this.translate.instant("file-import");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ProfileService: ProfileService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserController: UserControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentUser = this.SessionDataService.getCurrentUser();
    this.currentUserId = this.SessionDataService.getCurrentUserId();
    this.isTlm = this.SessionDataService.getCurrentTelemetryActive();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.clientList = this.SessionDataService.getCurrentClientList();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.cups = this.entityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    );

    // Escucha de cambios en agrupación y cliente
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    this.clientSub = this.SessionDataService.getClient().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    this.clientListSub = this.SessionDataService.getClientList().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    // Configuración de CUPS
    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.cups = this.entityCupsConf?.find(
            (column: EntityDefinition) => column.colPosition == 0
          );
        }
      );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.clientSub.unsubscribe();
    this.clientListSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.userId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.avoidPassword = this.userId ? true : false;
    this.stateData = history.state.data;
    this.getFormDropdowns();
  }

  // Obtención de los desplegables del formulario
  getFormDropdowns(): void {
    // Datos del usuario logeado
    this.loggedUserData = {
      clientList: this.clientList,
      clientListIds: this.clientList?.map((client: Client) => {
        return client.clientId;
      }),
      profileList: [],
      entityList: [],
      cupsList: [],
    };

    this.UserController.getDropdowns().subscribe((response) => {
      if (response["code"] == 0) {
        // Lista de perfiles
        this.loggedUserData.profileList = response["body"]["profileList"]?.map(
          (profile: number) => {
            return {
              value: profile,
              name: this.translate.instant("profiles" + profile),
              code: this.ProfileService.getProfile(String(profile)),
            };
          }
        );
        // Filtrado de perfil Telemedida para no ARSON
        // Opciones de telemedida desbloqueadas ( 13/11/2024 )
         if (this.sessionProfile !== "ARSON" && this.sessionProfile !== "ADMIN_ENTIDAD") {
             this.loggedUserData.profileList =
             this.loggedUserData.profileList.filter(
             (profile) => profile.value != 14
           );
         }
        // Lista de entidades
        this.loggedUserData.entityList = response["body"][
          "userEntityList"
        ].sort((a: UserEntity, b: UserEntity) =>
          a.entityName.localeCompare(b.entityName)
        );

        // Usuario
        this.getUserData();
        if (this.stateData?.profile) {
          let profile = this.loggedUserData.profileList.find(
            (profile: MaterialSelectOption) =>
              profile.name.toUpperCase() == this.stateData.profile.toUpperCase()
          );
          if (profile) {
            // Actualización del selector de perfil
            this.profileInitialSelection = profile.value;
            this.changeProfile(profile);
          }
        }
      }
    });
  }

  // Obtención de los datos de usuario para edición
  getUserData(): void {
    if (this.userId) {
      this.UserController.getUser(this.userId).subscribe((response) => {
        if (response["code"] == 0) {
          let userData: User = response["body"]["user"];

          // Perfil
          userData.profileText = this.ProfileService.getProfile(
            userData?.profile?.toString()
          );
          // Cliente
          userData.clientId = response["body"]["clientId"];
          // CUPS
          userData.cupsList = response["body"]["claveMaestraList"];
          // Entidad
          userData.entityList = response["body"]["userEntityList"]?.sort(
            (a: UserEntity, b: UserEntity) => {
              return a.entityName.localeCompare(b.entityName);
            }
          );

          // Flag de usuario editándose a sí mismo
          if (this.currentUser == userData.username) {
            this.userSelfEditing = true;
          }

          // Actualización de datos de usuario
          this.userData = userData;
          // Actualización de selector de perfil
          this.profileInitialSelection = this.userData.profile;
          // Actualización de selector de idioma
          this.languageInitialSelection = this.userData.language;
          // Obtención de datos seleccionados
          this.getSelectedItems();
          this.getNotifications();
          if (
            this.sessionProfile != PROFILES.ADMIN_INSTALLATION &&
            this.sessionProfile != PROFILES.TELEMEDIDA
          ) {
            this.getCupsDropdown();
          }
        }
      });
    } else {
      if (
        this.sessionProfile != PROFILES.ADMIN_INSTALLATION &&
        this.sessionProfile != PROFILES.TELEMEDIDA
      ) {
        this.getCupsDropdown();
      }
      this.userData.language = this.sessionLanguage.toUpperCase();
      // Actualización de selector de idioma
      this.languageInitialSelection = this.userData.language;
    }
  }

  // Obtención de los elementos seleccionados en los dropdowns
  getSelectedItems(): void {
    let selectedEntities: UserEntity[] = [];
    let selectedAgrupations: UserAgrupation[] = [];

    // Entidades y agrupaciones seleccionadas accesibles por el usuario logueado
    if (this.userData.entityList) {
      this.userData.entityList.forEach((userEntity: UserEntity) => {
        let loggedUserEntity: UserEntity = this.loggedUserData.entityList.find(
          (loggedUserEntity: UserEntity) =>
            loggedUserEntity.entityId == userEntity.entityId
        );
        if (loggedUserEntity) {
          selectedEntities.push(userEntity);

          userEntity.agrupationList.forEach(
            (userAgrupation: UserAgrupation) => {
              if (
                loggedUserEntity.agrupationList.find(
                  (loggedUserAgrupation: UserAgrupation) =>
                    loggedUserAgrupation.id == userAgrupation.id
                )
              ) {
                selectedAgrupations.push(userAgrupation);
              }
            }
          );
        }
      });
    }

    // Cliente seleccionado
    this.userData.selectedClients = this.userData.clientId;
    // Actualización de selector de cliente
    this.clientInitialSelection = this.userData.selectedClients;
    // Entidad/es seleccionadas
    this.userData.profileText == PROFILES.AQUACITY ||
    this.userData.profileText == PROFILES.ADMIN_AGRUPACION ||
    this.userData.profileText == PROFILES.USUARIO_AGRUPACION
      ? (this.userData.selectedEntities = selectedEntities[0])
      : (this.userData.selectedEntities = selectedEntities);
    // Actualización de selector de entidad
    this.entityInitialSelection = Array.isArray(this.userData.selectedEntities)
      ? this.userData.selectedEntities?.map((entity: UserEntity) => {
          return entity.entityId;
        })
      : this.userData.selectedEntities.entityId;
    // Obtención del desplegable de agrupaciones
    this.setAgrupationsDropdown();
    // Agrupación/es seleccionadas
    this.userData.profileText == PROFILES.AQUACITY
      ? (this.userData.selectedAgrupations = selectedAgrupations[0])
      : (this.userData.selectedAgrupations = selectedAgrupations);
    // Actualización de selector de agrupación
    this.agrupationInitialSelection = Array.isArray(
      this.userData.selectedAgrupations
    )
      ? this.userData.selectedAgrupations?.map((agrupation: UserAgrupation) => {
          return agrupation.id;
        })
      : this.userData.selectedAgrupations.id;
  }

  // Cambio de perfil
  changeProfile(profile: any): void {
    this.userData.profile = null;
    this.userData.profileText = null;
    setTimeout(() => {
      this.userData.profile = profile.value;
      this.userData.profileText = profile.code;
      if (
        this.sessionProfile == PROFILES.ARSON &&
        this.userData.profileText == PROFILES.TELEMEDIDA
      ) {
        this.updateSelectedEntities();
      }
    }, 0);
  }

  // Actualización del perfil de los datos de usuario
  resetSelectors(): void {
    // Reseteo de cliente seleccionado
    this.userData.selectedClients = null;
    // Reseteo de selector de cliente
    this.clientInitialSelection = null;
    // Reseteo de entidad seleccionada
    this.userData.selectedEntities = null;
    this.setAgrupationsDropdown();
    // Reseteo de selector de entidad
    this.entityInitialSelection = null;
    // Reseteo de agrupación seleccionada
    this.userData.selectedAgrupations = null;
    // Reseteo de selector de agrupación
    this.agrupationInitialSelection = null;
    // Reseteo de CUPS seleccionado
    this.userData.selectedCups = null;
    // Reseteo de selector de CUPS
    this.cupsInitialSelection = null;
    // Reseteo de telemedida
    this.userData.isTelemedida = false;
  }

  // Reseteo del formulario
  reset(event: any): void {
    event.preventDefault();
    this.ReloadComponentService.reload();
  }

  /***************************************************************************/
  // ANCHOR Guardado
  /***************************************************************************/

  // Guardado de los datos de usuario
  saveUserData(): void {
    // Error de contraseña
    if (
      !this.avoidPassword &&
      this.userData.password &&
      this.userData.password !== this.repeatedPassword
    ) {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("passwords-not-match")
      );
    } else {
      // Datos de usuario
      let data: UserSave = {
        // Datos
        user: {
          username: this.userData.username,
          password: this.userData.password,
          name: this.userData.name,
          surname: this.userData.surname,
          email: this.userData.email,
          phoneNumber: this.userData.phoneNumber,
          phoneNumber2: this.userData.phoneNumber2,
          profile: this.userData.profile,
          language: this.userData.language,
          notifications: this.userData.notifications,
          messageChannels: this.setNotifications(),
          isTlc: this.userData.isTlc,
          isTelemedida: this.userData.isTelemedida,
        },
        // CUPS
        claveMaestraList: Array.isArray(this.userData.selectedCups)
          ? this.userData.selectedCups?.map((cups) => {
              return cups.id;
            })
          : [this.userData.selectedCups?.id],
        // Entidades
        entities:
          this.userData.profileText == PROFILES.TELEMEDIDA ||
          this.userData.isTelemedida
            ? this.sessionProfile == PROFILES.ARSON
              ? this.clientList
                  .find(
                    (client) => client.clientId == this.userData.selectedClients
                  )
                  ?.entityList.map((entity) => entity.id)
              : this.currentClient.entityList.map((entity) => entity.id)
            : Array.isArray(this.userData.selectedEntities)
            ? this.userData.selectedEntities?.map((entity) => {
                return entity.entityId;
              })
            : [this.userData.selectedEntities?.entityId],
        // Cliente
        client:
          this.userData.selectedClients == -1
            ? null
            : this.userData.selectedClients,
        // Agrupaciones
        agrupations: Array.isArray(this.userData.selectedAgrupations)
          ? this.userData.selectedAgrupations?.map((agrupation) => {
              return agrupation.id;
            })
          : [this.userData.selectedAgrupations?.id],
      };

      // Edición de usuario existente
      if (this.userId) {
        data["user"].id = this.userId;
      }

      // Evitar cambios de entidad/agrupación al editarse a uno mismo
      if (this.userSelfEditing) {
        data["entities"] = null;
        data["agrupations"] = null;
      }

      // Servicios de actualización/nuevo usuario
      let userSaveUrl: Observable<object> = this.userId
        ? this.UserController.updateUser(data)
        : this.UserController.createUser(data);

      // Guardado de datos
      userSaveUrl.subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          if (this.userSelfEditing && this.languageUpdated) {
            this.updateLanguage();
          }
          // CUPS ya asignados
        } else if (response["code"] == 1028) {
          let toastHtml: string =
            "<ul style='list-style: none; text-align:center;  padding: 0;'>";
          response["body"]?.alreadyAssignedCups.forEach(
            (cups: {
              username: string;
              claveMaestraId: number;
              claveMastra: string;
            }) => {
              toastHtml +=
                "<li><b>" +
                cups.username +
                "</b> " +
                this.translate.instant("other-user-cups") +
                ": <b>" +
                cups.claveMastra +
                "</b></li>";
            }
          );
          toastHtml += "</ul>";
          // Confirmación de sustitución de CUPS
          this.ToastService.fireAlertWithOptions(
            "warning",
            this.translate.instant("replace-question"),
            toastHtml
          ).then((userConfirmation: boolean) => {
            if (userConfirmation) {
              let newCups: CupsUser = {
                userId: response["body"]["userId"],
                cupsList: this.userData.cupsList,
              };
              this.UserController.replaceCups(newCups).subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("saved")
                  );
                  if (this.userSelfEditing && this.languageUpdated) {
                    this.updateLanguage();
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  // Actualización de idioma
  updateLanguage(): void {
    this.SessionDataService.sendLanguage(this.userData.language);
  }

  /***************************************************************************/
  // ANCHOR Agrupaciones
  /***************************************************************************/

  // Obtención de la lista del desplegable de agrupaciones
  setAgrupationsDropdown(): void {
    let agrupationDropdownItems = [];

    // Si existen entidades seleccionadas
    if (this.userData.selectedEntities) {
      // Entidades seleccionadas
      let selectedEntities = Array.isArray(this.userData.selectedEntities)
        ? this.userData.selectedEntities
        : [this.userData.selectedEntities];
      // Opciones de selección de entidad completa
      let allAgrupationOptions: UserAllAgrupation[] = [];

      // Agrupaciones por entidad
      selectedEntities.forEach((selectedEntity: UserEntity) => {
        // Filtro de agrupaciones del usuario logeado
        agrupationDropdownItems = agrupationDropdownItems.concat(
          this.loggedUserData.entityList.find(
            (entity: UserEntity) => entity.entityId == selectedEntity.entityId
          )?.agrupationList
        );
        // Opciones de selección de entidad
        if (
          this.userData.profileText != PROFILES.AQUACITY &&
          this.userData.profileText != PROFILES.USUARIO_AGRUPACION
        ) {
          allAgrupationOptions.push({
            id: -selectedEntity.entityId, // Negativo para identificar id de entidad
            descripcion:
              this.translate.instant("all-agrupations-from") +
              " " +
              selectedEntity.entityName,
            allAgrupation: true,
          });
        }
      });

      // Ordenamiento por nombre
      agrupationDropdownItems.sort((a, b) =>
        a.descripcion.localeCompare(b.descripcion)
      );

      // Listado de agrupaciones original
      this.agrupationDropdownItemsOriginal = [...agrupationDropdownItems];

      // Listado con opciones de selección por entidad completa
      allAgrupationOptions.map((option: UserAllAgrupation) =>
        agrupationDropdownItems.unshift(option)
      );

      this.agrupationDropdownItems = agrupationDropdownItems;
    }
  }

  // Comprobación de si se han seleccionado todas las agrupaciones de una entidad
  checkAllAgrupations(): void {
    let entitiesSelected: UserAgrupation[];

    // Comprobación de si se ha seleccionado alguna agrupación con id negativo (id de entidad)
    if (Array.isArray(this.userData.selectedAgrupations)) {
      entitiesSelected = this.userData.selectedAgrupations.filter(
        (agrupation: UserAgrupation) => agrupation.id < 0
      );
    } else {
      if (this.userData.selectedAgrupations.id < 0) {
        entitiesSelected = [this.userData.selectedAgrupations];
      }
    }

    // Si hay entidad seleccionada se añaden a los seleccionados las agrupaciones con la misma entidad
    if (entitiesSelected?.length > 0) {
      entitiesSelected.map((entity) => {
        this.agrupationDropdownItems.map((agrupation: UserAgrupation) => {
          if (agrupation.entity == Math.abs(entity.id)) {
            if (Array.isArray(this.userData.selectedAgrupations)) {
              if (
                !this.userData.selectedAgrupations.some(
                  (selectedAgrupation: UserAgrupation) =>
                    selectedAgrupation.id == agrupation.id
                )
              ) {
                this.userData.selectedAgrupations.push(agrupation);
              }
            } else if (this.userData.selectedAgrupations.id != agrupation.id) {
              this.userData.selectedAgrupations = [
                this.userData.selectedAgrupations,
                agrupation,
              ];
            }
          }
        });
      });

      // Eliminación de los id negativos de entidad de la selección
      if (Array.isArray(this.userData.selectedAgrupations)) {
        this.userData.selectedAgrupations =
          this.userData.selectedAgrupations.filter(
            (agrupation: UserAgrupation) => agrupation.id > 0
          );
      }

      // Actualización de selector
      this.agrupationInitialSelection = this.userData.selectedAgrupations.map(
        (agrupation: UserAgrupation) => {
          return agrupation.id;
        }
      );
    }
  }

  // Actualización de las agrupaciones seleccionadas al eliminar una entidad
  updateSelectedAgrupations(multipleSelectionEnabled: boolean): void {
    // Comprobación de que haya múltiples agrupaciones seleccionadas
    if (
      multipleSelectionEnabled &&
      Array.isArray(this.userData.selectedAgrupations) &&
      this.userData.selectedAgrupations.length > 0
    ) {
      // Comprobación de que haya entidades seleccionadas
      if (
        Array.isArray(this.userData.selectedEntities) &&
        this.userData.selectedEntities.length > 0
      ) {
        // Filtrado de agrupaciones que no tengan una entidad incluida en las entidades seleccionadas
        this.userData.selectedAgrupations =
          this.userData.selectedAgrupations.filter(
            (selectedAgrupation: UserAgrupation) => {
              // Entidad de la agrupación seleccionada
              let selectedAgrupationEntity =
                this.loggedUserData.entityList.find((entity: UserEntity) =>
                  entity.agrupationList.find(
                    (agrupation: UserAgrupation) =>
                      agrupation.id == selectedAgrupation.id
                  )
                );
              return this.userData.selectedEntities.some(
                (entity: UserEntity) =>
                  entity.entityId == selectedAgrupationEntity?.entityId
              );
            }
          );
      } else {
        this.userData.selectedAgrupations = null;
      }
    } else {
      this.userData.selectedAgrupations = null;
    }

    // Actualización de selector
    this.agrupationInitialSelection = this.userData.selectedAgrupations;
  }

  /***************************************************************************/
  // ANCHOR Notificaciones
  /***************************************************************************/

  // Obtención de las notificaciones a partir de la máscara
  getNotifications(): void {
    let notifications: string = this.userData?.messageChannels?.toString(2);
    if (notifications?.charAt(notifications.length - 1) == "1") {
      this.smsNotifications = true;
    }
    this.checkSmsNotifications();
    this.checkEmailNotifications();
  }

  // Chequeo de perfil para notificaciones sms
  checkSmsNotifications(): void {
    if (
      !this.smsNotifications &&
      this.sessionProfile != PROFILES.ARSON &&
      this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
      this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
      this.sessionProfile != PROFILES.ADMIN_AGRUPACION &&
      this.sessionProfile != PROFILES.ADMIN_INSTALLATION &&
      this.sessionProfile != PROFILES.TELEMEDIDA
    ) {
      this.smsNotificationsDisabled = true;
    }
  }

  // Chequeo de perfil para notificaciones email
  checkEmailNotifications(): void {
    if (
      !this.userData.notifications &&
      this.sessionProfile != PROFILES.ARSON &&
      this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
      this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
      this.sessionProfile != PROFILES.ADMIN_AGRUPACION &&
      this.sessionProfile != PROFILES.ADMIN_INSTALLATION &&
      this.sessionProfile != PROFILES.TELEMEDIDA
    ) {
      this.emailNotificationsDisabled = true;
    }
  }

  // Seteo de la máscara de notificaciones
  setNotifications(): number {
    let notifications: number[] = [];
    this.smsNotifications ? notifications.unshift(1) : notifications.unshift(0);
    return parseInt(notifications.toString(), 2);
  }

  // Chequeo de existencia de teléfono para SMS
  checkPhoneSMS(): void {
    if (!this.userData.phoneNumber && !this.userData.phoneNumber2) {
      this.smsNotifications = false;
    }
  }

  // Chequeo de existencia de email para notificaciones
  checkEmail(): void {
    if (!this.userData.email) {
      this.userData.notifications = false;
    }
  }

  // Chequeo de perfil para aviso de cambios en notificaciones
  checkUserProfile(
    event: any,
    notificationIsActive: boolean,
    notification: string
  ): void {
    if (
      this.sessionProfile != PROFILES.ARSON &&
      this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
      this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
      this.sessionProfile != PROFILES.ADMIN_AGRUPACION &&
      this.sessionProfile != PROFILES.ADMIN_INSTALLATION &&
      this.sessionProfile != PROFILES.TELEMEDIDA
    ) {
      event.preventDefault();
      if (notificationIsActive) {
        this.ToastService.fireAlertWithOptions(
          "warning",
          this.translate.instant("notifications-question")
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            switch (notification) {
              case "email":
                this.userData.notifications = false;
                this.emailNotificationsDisabled = true;
                break;
              case "sms":
                this.smsNotifications = false;
                this.smsNotificationsDisabled = true;
                break;
              default:
                break;
            }
          }
        });
      } else {
        this.ToastService.fireToast(
          "info",
          this.translate.instant("notifications-profile-advise")
        );
      }
    }
  }

  /***************************************************************************/
  // ANCHOR CUPS
  /***************************************************************************/

  // Obtención de los CUPS accesibles para el usuario logueado
  getCupsDropdown(): void {
    // Servicios
    let cupsRequests: Observable<object>[] = [
      this.UserController.getCUPS(),
      this.UserController.getSubscriberCUPS(this.currentAgrupation.id),
    ];

    forkJoin(cupsRequests).subscribe((responses) => {
      // CUPS accesibles para el usuario logeado
      if (responses[0]["code"] === 0) {
        this.loggedUserData.cupsList = responses[0]["body"];
      }

      // CUPS no asignados
      if (responses[1]["code"] === 0) {
        this.subscriberCups = responses[1]["body"]["listaClavesMaestras"];
      }

      // Dropdown de CUPS asignables
      this.cupsDropdownItems = [...this.subscriberCups];

      // CUPS del usuario editado accesibles por el usuario logeado
      if (this.userId) {
        this.cupsDropdownItems = [
          ...this.cupsDropdownItems,
          ...this.getAllowedCups(),
        ];
        this.userData.selectedCups = this.getSelectedCups();
        this.cupsInitialSelection = this.userData.selectedCups;
        // Nuevo abonado de CUPS
      } else if (
        this.stateData?.cups &&
        this.stateData?.profile === PROFILES.ABONADO
      ) {
        this.userData.selectedCups = this.stateData?.cups;
        this.cupsInitialSelection = [this.userData.selectedCups.id];
      }
    });
  }

  // Obtención de los CUPS asignados al usuario editado accesibles por el usuario logeado
  getAllowedCups(): UserCups[] {
    return this.loggedUserData.cupsList.filter((loggedUserCups: UserCups) =>
      this.userData?.cupsList?.includes(loggedUserCups.id)
    );
  }

  // Obtención de los CUPS seleccionados
  getSelectedCups(): number[] {
    return this.cupsDropdownItems
      .filter((cups: UserCups) => this.userData?.cupsList?.includes(cups.id))
      .map((cups: UserCups) => {
        return cups.id;
      });
  }

  // Actualización de entidades seleccionadas para telemedida con perfil ARSON
  updateSelectedEntities(): void {
    if (
      this.userData.profileText == PROFILES.TELEMEDIDA ||
      this.userData.isTelemedida
    ) {
      this.entityInitialSelection = this.currentClient.entityList.map(
        (entity) => entity.id
      );
    } else {
      this.entityInitialSelection = null;
    }
  }
}
