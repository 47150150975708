// @angular
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { formatNumber } from "@angular/common";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Interfaces
import { HomeCardData } from "../HomeInterface.type";

@Component({
  selector: "app-home-card",
  templateUrl: "./home-card.component.html",
  styleUrls: ["./home-card.component.scss"],
})
export class HomeCardComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() homeCard: HomeCardData;
  @Input() highlightInNetwork: boolean;
  @Input() highlightNoCommunication: boolean;
  @Input() highlightPending: boolean;
  numberFormat = this.SessionDataService.getCurrentNumberFormat();
  formatNumber = formatNumber;
  @Output() updateMapVisibilityFlag = new EventEmitter<object>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getHomeCardIcons();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  getHomeCardIcons(): void {
    switch (this.homeCard.deviceType) {
      case "gateways":
        this.homeCard.comIcon = "gateway-icon-ok";
        this.homeCard.noComIcon = "gateway-icon-error";
        this.homeCard.noAssignedIcon = "gateway-icon-no-device";
        break;
      case "meters":
        this.homeCard.comIcon = "fas fa-circle greenIcon";
        this.homeCard.noComIcon = "fas fa-circle redIcon";
        this.homeCard.noAssignedIcon = "fas fa-circle purpleIcon";
        break;
      case "mbus":
        this.homeCard.comIcon = "fas fa-circle cadetblueIcon";
        this.homeCard.noComIcon = "fas fa-circle blueIcon";
        break;
      case "wavenis":
        this.homeCard.comIcon = "fas fa-circle blueIcon";
        break;
      case "txn":
        this.homeCard.txn1Icon = "fas fa-tint grayIcon";
        this.homeCard.txn4Icon = "fas fa-tint grayIcon";
        break;
      case "external":
        this.homeCard.comIcon = "fas fa-star greenIcon";
        this.homeCard.noComIcon = "fas fa-star redIcon";
        break;
      case "externalWithoutCom":
        this.homeCard.noComIcon = "fas fa-circle grayIcon";
        break;
      case "nbiot":
        this.homeCard.comIcon = "fas fa-circle orangeIcon";
        this.homeCard.noComIcon = "fas fa-circle orangeIcon";
        break;
      case "sensors":
        this.homeCard.comIcon = "fas fa-play rotated90 greenIcon";
        this.homeCard.noComIcon = "fas fa-play rotated90 redIcon";
        this.homeCard.noAssignedIcon = "fas fa-play rotated90 purpleIcon";
        break;
      case "concentrators":
        this.homeCard.comIcon = "fas fa-tint greenIcon";
        this.homeCard.noComIcon = "fas fa-tint redIcon";
        this.homeCard.noAssignedIcon = "fas fa-tint purpleIcon";
        break;
      case "satelites":
        this.homeCard.comIcon = "fas fa-circle greenIcon";
        this.homeCard.noComIcon = "fas fa-circle redIcon";
        break;
      case "une":
        this.homeCard.comIcon = "fas fa-circle greenIcon";
        this.homeCard.noComIcon = "fas fa-circle redIcon";
        break;
      case "valves":
        this.homeCard.comIcon = "fas fa-circle greenIcon";
        this.homeCard.noComIcon = "fas fa-circle redIcon";
        break;
      default:
        break;
    }
  }

  // Redirección a url
  openLink(url: string[], stateData: any, newTab: boolean, event?: any) {
    event?.preventDefault();
    this.SessionDataService.sendNewTab(newTab ? stateData : null);
    setTimeout(
      () =>
        stateData
          ? this.router.navigate(url, { state: { data: stateData } })
          : this.router.navigate(url),
      0
    );
  }

  // Actualización de visibilidad de capas de mapa
  updateMapVisibility(
    event: MouseEvent,
    deviceType: string,
    layerType: string,
    addLayer: boolean
  ): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.updateMapVisibilityFlag.emit({
      deviceType: deviceType,
      layerType: layerType,
      addLayer: addLayer,
    });
  }
}
