import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { GatewayControllerService } from "../../../services/server/GatewayController.service";
import { ManagementControllerService } from "../../../services/server/ManagementController.service";
import { RequestQueueService } from "../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../services/server/MeterController.service";
// Interfaces
import {
  AssignableMeter,
  BestRedundantGateway,
  Gateway,
  GatewayMapMeter,
  GatewayMinimal,
  GatewayTableMeter,
} from "../../../interfaces/GatewayGlobalInterface.type";
import { EntityAppEui } from "./GatewayInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { DeviceDeallocateData } from "../devices/DeviceInterface.type";
import { PanelMenuOption } from "../../../modules/material-module/MaterialInterface.type";

@Injectable({
  providedIn: "root",
})
export class GatewayService {
  constructor(
    private GatewayController: GatewayControllerService,
    private MeterController: MeterControllerService,
    private ManagementController: ManagementControllerService,
    private requestQueue: RequestQueueService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  // Borrado de gateway
  deleteGateway(gateway: Gateway): void {
    this.ToastService.fireAlertWithTripleOptions(
      "question",
      this.translate.instant("gateway-delete-question"),
      this.translate.instant("cancel"),
      this.translate.instant("with-communication"),
      this.translate.instant("without-communication")
    ).then((userSelection: string) => {
      if (userSelection == "option1") {
        this.requestQueue.deleteGatewayTask(gateway, 1);
      } else if (userSelection == "option2") {
        this.requestQueue.deleteGatewayTask(gateway, 0);
      }
    });
  }

  // Borrado de gateway de desarrollo sin comprobar estado
  deleteDevelopmentGateway(gateway: Gateway): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("gateway-delete-question")
    ).then((userConfirmation: string) => {
      if (userConfirmation) {
        this.GatewayController.deleteCommGateway(gateway.id, 0).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("gateway-deleted")
              );
            }
          }
        );
      }
    });
  }

  // Refresco de los puntos finales de un gateway
  refreshGatewayAsking(gateway: Gateway): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("question-gateway-refresh")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.ToastService.fireToastWithConfirmation(
          "info",
          this.translate.instant("deleting-end-points")
        );
        this.refreshGateway(gateway);
      }
    });
  }

  // Refresco de los puntos finales de un gateway
  refreshGateway(gateway: any, deleteGateway?: Gateway): void {
    this.GatewayController.getGatewayAllDevices(gateway.id).subscribe(
      (response) => {
        let deviceList = response["body"];
        if (response["code"] == 0) {
          this.GatewayController.deleteGatewayEndPoints(gateway.id).subscribe(
            (response) => {
              if (response["code"] == 0) {
                if (deleteGateway) {
                  deviceList.unshift({
                    id: deleteGateway.id,
                    unidadVenta: deleteGateway.unidadVenta,
                    eraseGateway: true,
                  });
                }
                this.requestQueue.setTask(
                  "replaceGateway",
                  deviceList,
                  gateway
                );
              }
            }
          );
        }
      }
    );
  }

  // Actualización de APP EUI
  updateAppEui(gateway: Gateway, wait?: boolean): void {
    let currentEntity: Entity = this.SessionDataService.getCurrentEntity();
    this.GatewayController.getAppEui(currentEntity.id).subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let appEuiList: EntityAppEui[] = response["body"];
        let data = [];
        appEuiList.forEach((appEui: any) => {
          data.push({
            id: gateway.id,
            unidadVenta: gateway.unidadVenta,
            appEui: appEui.appEui,
            manufacturer: appEui.manufacturer,
            entity: currentEntity.entity,
            entityId: currentEntity.id,
          });
        });
        this.requestQueue.setTask("saveAppEui", data, null, wait);
      } else {
        this.ToastService.fireToast(
          "info",
          this.translate.instant("entity-without-appeui")
        );
      }
    });
  }

  // Asignar contador como principal
  allocateMeterAsMain(
    gateway: Gateway,
    selectedMeterList: GatewayTableMeter[],
    meterId?: number
  ): void {
    if (selectedMeterList?.some((meter: GatewayTableMeter) => meter.main)) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("meters-main-error")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        meterId ? "warning" : "question",
        meterId
          ? this.translate.instant("meters-change-question")
          : this.translate.instant("assign-multiple-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          // Si solo hay un dispositivo
          if (meterId || selectedMeterList?.length == 1) {
            this.GatewayController.asignate(
              [meterId ? meterId : selectedMeterList[0].contadorId],
              gateway.id
            ).subscribe((response) => {
              let error = response["body"][0]?.responseCode;
              if (error == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("action-success")
                );
                this.SessionDataService.sendReloadPanelFlag();
              } else {
                this.ToastService.fireToastWithConfirmation(
                  "error",
                  "Error (" +
                    error +
                    "): " +
                    this.translate.instant("httpError" + error)
                );
              }
            });
            // Si es una lista
          } else {
            this.requestQueue.setTask(
              "allocateAsMain",
              selectedMeterList.map((meter: GatewayTableMeter) => {
                return meter;
              }),
              gateway
            );
          }
        }
      });
    }
  }

  // Desasignar contadores
  deallocateMeter(
    gateway: Gateway,
    meterArray: GatewayTableMeter[],
    meterId?: number
  ): void {
    if (meterArray?.some((meter: GatewayTableMeter) => meter.main)) {
      this.ToastService.fireToast(
        "warning",
        this.translate.instant("meters-main-error")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        meterId ? "warning" : "question",
        meterId
          ? this.translate.instant("meters-unassigned-question")
          : this.translate.instant("unassign-multiple")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          // Si solo hay un dispositivo
          if (meterId || meterArray?.length == 1) {
            this.GatewayController.unasignate({
              gateway: gateway.id,
              meterList: [meterId ? meterId : meterArray[0].contadorId],
            }).subscribe((response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("action-success")
                );
                this.SessionDataService.sendReloadPanelFlag();
              } else if (response["code"] == 1) {
                let error = response["body"][0]?.responseCode;
                this.ToastService.fireToastWithConfirmation(
                  "error",
                  "Error (" +
                    error +
                    "): " +
                    this.translate.instant("httpError" + error)
                );
              }
            });
            // Si es una lista
          } else {
            this.requestQueue.setTask(
              "allocate/deallocate",
              meterArray.map((meter: GatewayTableMeter) => {
                return meter;
              }),
              gateway
            );
          }
        }
      });
    }
  }

  // Asignación del mejor Gateway
  assignBestGateway(selectedMeterList: GatewayTableMeter[]): void {
    let data = selectedMeterList.map((device: GatewayTableMeter) => {
      return {
        id: device.contadorId,
        nroSerie: device.contadorNroSerie,
        metrologyType: device.metrologyType,
      };
    });
    this.requestQueue.setTask("assignBest", data);
  }

  // Asignación de principales a mejor redundante
  assignBestRedundant(gateway: Gateway, mainMeters: GatewayTableMeter[]): void {
    this.GatewayController.getBestRedundant(gateway.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let assignable = response["body"]?.listaResultadosSi;
          let unassignable = response["body"]?.listaResultadosNo;
          let unassignableActions = [];
          let assignableActions = [];

          // No hay redundante
          unassignable?.forEach((device: BestRedundantGateway) => {
            unassignableActions.push({
              agrupation: device.agrupation,
              nroSerie: device.meterNroSerie,
              id: device.meter,
              metrologyType: device.metrologyType,
              reassignResult: device.result,
            });
          });
          if (unassignable.length > 0) {
            this.requestQueue.setTask(
              "reassignManually",
              unassignableActions,
              gateway
            );
          }

          // Redundante asignable
          assignable.forEach((device) => {
            assignableActions.push(
              {
                nroSerie: device.meterNroSerie,
                id: device.meter,
                metrologyType: device.metrologyType,
                gatewayId: device.gateway,
                gatewayUnidadVenta: device.gatewayUnidadVenta,
                task: "allocate",
              },
              {
                nroSerie: device.meterNroSerie,
                id: device.meter,
                metrologyType: device.metrologyType,
                gatewayId: gateway.id,
                gatewayUnidadVenta: gateway.unidadVenta,
                task: "deallocate",
              }
            );
          });
          if (assignableActions.length > 0) {
            this.requestQueue.setTask(
              "assignBestRedundant",
              assignableActions,
              gateway
            );
          }
        }
      }
    );
  }

  // Asignación de principales a mejor redundante
  assignBestRedundantWithoutRead(gateway: Gateway): void {
    this.GatewayController.getBestRedundant(gateway.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let assignable = response["body"]?.listaResultadosSi;
          let unassignable = response["body"]?.listaResultadosNo;
          let unassignableActions = [];
          let assignableActions = [];

          // No hay redundante
          unassignable?.forEach((device: BestRedundantGateway) => {
            unassignableActions.push({
              agrupation: device.agrupation,
              nroSerie: device.meterNroSerie,
              id: device.meter,
              metrologyType: device.metrologyType,
              reassignResult: device.result,
            });
          });
          if (unassignable.length > 0) {
            this.requestQueue.setTask(
              "reassignManually",
              unassignableActions,
              gateway
            );
          }

          // Redundante asignable
          assignable?.forEach((device: BestRedundantGateway) => {
            assignableActions.push({
              agrupation: device.agrupation,
              nroSerie: device.meterNroSerie,
              id: device.meter,
              metrologyType: device.metrologyType,
              reassignResult: device.result,
              newGatewayId: device.gateway,
              newGatewayUnidadVenta: device.gatewayUnidadVenta,
            });
          });
          if (assignableActions.length > 0) {
            this.requestQueue.setTask("reassign", assignableActions, gateway);
          }
        }
      }
    );
  }

  // Limpieza de gateways redundantes
  cleanRedundantGateways(selectedMeterList: GatewayTableMeter[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("clean-redundant-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = selectedMeterList.map((device: GatewayTableMeter) => {
          return {
            id: device.contadorId,
            nroSerie: device.contadorNroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("cleanGateways", data);
      }
    });
  }

  // Refresco de dispositivo
  refreshDevice(gatewayId: number, meterId: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("refresh-device-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.spinner.show("spinner-hard");
        this.ManagementController.refreshConfiguration(gatewayId, [
          meterId,
        ]).subscribe((response) => {
          let error =
            response["body"]?.contadorResultado[0]?.resultado != 0
              ? response["body"]?.contadorResultado[0]?.resultado
              : response["body"]?.code
              ? response["body"]?.code
              : response["code"];
          if (error == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("refresh-successful")
            );
          } else if (error == null) {
            this.ToastService.fireToastWithConfirmation(
              "error",
              this.translate.instant("httpError1")
            );
          } else {
            this.ToastService.fireToastWithConfirmation(
              "error",
              "Error (" +
                error +
                "): " +
                this.translate.instant("httpError" + error)
            );
          }
          this.spinner.hide("spinner-hard");
        });
      }
    });
  }

  // Sustitución del gateway
  replaceGateway(gateway: Gateway, gatewayReplaceSelected: Gateway): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("replace-device-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        // Sustitución en base de datos
        this.GatewayController.replaceGateway(
          gateway.id,
          String(gatewayReplaceSelected.id)
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("success-gateway-replacement")
            );
            this.updateReplacedAppEui(gatewayReplaceSelected);
          }
          this.SessionDataService.sendDialogAction({ action: "close" });
        });
      }
    });
  }

  // Actualización de APP EUI
  updateReplacedAppEui(
    gatewayReplaceSelected: Gateway,
    gateway?: Gateway
  ): void {
    let currentEntity: Entity = this.SessionDataService.getCurrentEntity();
    this.GatewayController.getAppEui(currentEntity.id).subscribe((response) => {
      if (response["code"] == 0 && response["body"]?.length > 0) {
        let appEuiList: EntityAppEui[] = response["body"];
        let data = [];
        appEuiList.forEach((appEui: any) => {
          data.push({
            id: gatewayReplaceSelected.id,
            unidadVenta: gatewayReplaceSelected.unidadVenta,
            appEui: appEui.appEui,
            manufacturer: appEui.manufacturer,
            entity: currentEntity.entity,
            entityId: currentEntity.id,
          });
        });
        if (gateway) {
          data.unshift({
            id: gateway.id,
            unidadVenta: gateway.unidadVenta,
            eraseGateway: true,
          });
        }
        this.requestQueue.setTask("replaceGateway", data);
      } else {
        this.ToastService.fireToast(
          "info",
          this.translate.instant("entity-without-appeui")
        );
        this.refreshGateway(gatewayReplaceSelected, gateway);
      }
    });
  }

  // Asignar gateway
  allocateGateway(gateway: GatewayMinimal, meterId: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("gateway-change-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.ToastService.fireAlertWithTripleOptions(
          "question",
          this.translate.instant("command-read-question"),
          this.translate.instant("no-read-disable-gateway"),
          this.translate.instant("with-read"),
          this.translate.instant("no-read")
        ).then((userSelection: string) => {
          let request: Observable<object>;
          switch (userSelection) {
            // Asignación con lectura
            case "option1":
              request = this.MeterController.asignateWithCommand(
                [meterId],
                gateway.id
              );
              break;
            // Asignación sin lectura
            case "option2":
              request = this.MeterController.asignateWithoutCommand(
                [meterId],
                gateway.id
              );
              break;
            // Asignación sin lectura ni comunicación
            case "option3":
              request =
                this.MeterController.asignateWithoutCommandDisabledGateway(
                  [meterId],
                  gateway.id
                );
              break;
            default:
              break;
          }

          request.subscribe((response) => {
            let error = response["body"][0]?.responseCode;
            if (error == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("action-success")
              );
              this.SessionDataService.sendReloadPanelFlag();
            } else {
              this.ToastService.fireToastWithConfirmation(
                "error",
                "Error (" +
                  error +
                  "): " +
                  this.translate.instant("httpError" + error)
              );
              this.SessionDataService.sendReloadPanelFlag();
            }
          });
        });
      }
    });
  }

  // Desasignar gateways
  deallocateGateway(
    selectedGatewayList: GatewayMinimal[],
    meterId: number
  ): void {
    let gatewaysList: number[] = selectedGatewayList.map(
      (gateway: GatewayMinimal) => gateway.id
    );
    this.ToastService.fireAlertWithOptions(
      "question",
      selectedGatewayList.length == 1
        ? this.translate.instant("gateway-unassigned-question")
        : this.translate.instant("unassign-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: DeviceDeallocateData = {
          contador: meterId,
          gateways: gatewaysList,
        };
        if (gatewaysList.length > 0) {
          this.MeterController.desasignate(data).subscribe((response) => {
            if (response["code"] == 0) {
              selectedGatewayList.length == 1
                ? this.ToastService.fireToast(
                    "success",
                    this.translate.instant("gateway-unassigned")
                  )
                : this.ToastService.fireToast(
                    "success",
                    this.translate.instant("gateways-unassigned")
                  );
              this.SessionDataService.sendReloadPanelFlag();
            } else if (response["code"] == 1) {
              let errors = response["body"].filter(
                (gateway) => gateway.responseCode != 0
              );
              let errorText = errors
                .map((error) => {
                  return (
                    "Error (" +
                    error.responseCode +
                    "): " +
                    this.translate.instant("httpError" + error.responseCode)
                  );
                })
                .join(", ");
              this.ToastService.fireToastWithConfirmation("error", errorText);
            }
          });
        } else {
          this.ToastService.fireToast(
            "warning",
            this.translate.instant("must-selected")
          );
        }
      }
    });
  }

  // Guardado de dispositivos
  async saveGatewayDevices(
    gateway: Gateway,
    deallocatedMetersSelected: number[],
    deallocatedMetersList: AssignableMeter[],
    deallocateRedundantSelected: number[],
    deallocateRedundantList: GatewayMapMeter[]
  ): Promise<void> {
    return this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("action-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = [
          ...(deallocatedMetersSelected
            ? deallocatedMetersList.filter((meter: AssignableMeter) =>
                deallocatedMetersSelected.includes(meter.id)
              )
            : []),
          ...(deallocateRedundantSelected
            ? deallocateRedundantList.filter((meter: GatewayMapMeter) =>
                deallocateRedundantSelected.includes(meter.id)
              )
            : []),
        ];
        if (data.length > 1) {
          this.requestQueue.setTask("allocate/deallocate", data, gateway);
        } else {
          deallocatedMetersSelected?.length > 0
            ? this.allocateMeters(
                gateway,
                deallocatedMetersSelected,
                deallocatedMetersList
              )
            : this.deallocateMeters(gateway, deallocateRedundantSelected);
        }
      }
    });
  }

  // Asignación de los contadores
  allocateMeters(
    gateway: Gateway,
    deallocatedMetersSelected: number[],
    deallocatedMetersList: AssignableMeter[]
  ): void {
    if (deallocatedMetersSelected?.length > 0) {
      let data: string[] = deallocatedMetersList
        .filter((meter: AssignableMeter) =>
          deallocatedMetersSelected.includes(meter.id)
        )
        .map((meter: AssignableMeter) => {
          return meter.nroSerie;
        });
      this.GatewayController.saveDropdown(gateway.id, data).subscribe(
        (response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("selected-assign")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        }
      );
    }
  }

  // Desasignación de los contadores
  deallocateMeters(
    gateway: Gateway,
    deallocateRedundantSelected: number[]
  ): void {
    if (deallocateRedundantSelected?.length > 0) {
      this.GatewayController.unasignate({
        gateway: gateway.id,
        meterList: deallocateRedundantSelected,
      }).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("meter-unassigned")
          );
          this.SessionDataService.sendReloadPanelFlag();
        }
      });
    }
  }

  // Obtención del menú de log de gateway
  getLogPanelMenu(log: string): PanelMenuOption[] {
    let panelMenuOptions: PanelMenuOption[] = [];
    // Datalogger
    if (log != "datalogger") {
      panelMenuOptions.push({
        action: "datalogger",
        icon: "fas fa-list-alt",
        text: this.translate.instant("Datalogger"),
        visible: true,
      });
    }
    // Log de eventos
    if (log != "event-log") {
      panelMenuOptions.push({
        action: "event-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("event-log"),
        visible: true,
      });
    }
    // Log de tramas
    if (log != "frames-log") {
      panelMenuOptions.push({
        action: "frames-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("frames-logs"),
        visible: true,
      });
    }
    // Log de eventos de usuario
    if (log != "user-log") {
      panelMenuOptions.push({
        action: "user-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("user-log"),
        visible: true,
      });
    }
    // Log de resets
    if (log != "reset-log") {
      panelMenuOptions.push({
        action: "reset-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("reset-log"),
        visible: true,
      });
    }
    // Log de tareas
    if (log != "tasks-log") {
      panelMenuOptions.push({
        action: "tasks-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("tasks-log"),
        visible: true,
      });
    }
    return panelMenuOptions;
  }

  // Acción de menu de log de gateway
  getLogMenuAction(
    action: string,
    gatewayId: number,
    unidadVenta: string
  ): void {
    switch (action) {
      case "event-log":
        this.router.navigate(["/gateways/detalle/log/eventos/" + gatewayId], {
          state: { data: unidadVenta },
        });
        break;
      case "user-log":
        this.router.navigate(["/gateways/detalle/log/usuarios/" + gatewayId], {
          state: { data: unidadVenta },
        });
        break;
      case "datalogger":
        this.router.navigate(
          ["/gateways/detalle/log/datalogger/" + gatewayId],
          { state: { data: unidadVenta } }
        );
        break;
      case "reset-log":
        this.router.navigate(["/gateways/detalle/log/reset/" + gatewayId], {
          state: { data: unidadVenta },
        });
        break;
      case "frames-log":
        this.router.navigate(["/gateways/detalle/log/tramas/" + gatewayId], {
          state: { data: unidadVenta },
        });
        break;
      case "tasks-log":
        this.router.navigate(["/mantenimiento/tareas-gateways"], {
          state: { data: { id: gatewayId, unidadVenta: unidadVenta } },
        });
        break;
      default:
        break;
    }
  }

  // Borrado de localización
  deleteLocation(locationId: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-location-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.deleteLocation(locationId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("deleted-success")
              );
            }
          }
        );
      }
    });
  }
}
