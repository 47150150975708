<!-- Listado de fabricantes -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{
        data.action == "manufacturers"
          ? ("device-manufacturer" | translate)
          : "KEM2"
      }}
    </h5>
  </div>
  <div class="dialog-body" [ngSwitch]="data.action">
    <!-- Archivo KEM2 -->
    <div *ngSwitchCase="'kem2'" class="meter-import-km2">
      <mat-form-field appearance="standard">
        <mat-label>{{ "password" | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="kem2Password" />
      </mat-form-field>
      <app-file-import
        [fileType]="'.kem2'"
        (fileChange)="kem2File = $event"
        (fileReady)="downloadKem2()"
      ></app-file-import>
    </div>

    <!-- Fabricantes -->
    <div *ngSwitchCase="'manufacturers'">
      <div class="meter-import-select">
        <select
          #manufacturerSelect
          name="manufacturerSelect"
          [(ngModel)]="selectedManufacturer"
        >
          <option selected disabled hidden value="null">
            {{ "select-manufacturer" | translate }}
          </option>
          <option
            *ngFor="let manufacturer of data.manufacturersList"
            value="{{ manufacturer.fabricante }}"
          >
            {{ manufacturer.fabricante }}
          </option>
        </select>
      </div>
      <table class="table table-hover table-bordered table-striped">
        <thead>
          <tr>
            <th>{{ "manufacturer" | translate }}</th>
            <th>MANUFACTURER</th>
            <th>DEV_TYPE</th>
            <th>{{ "device" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let device of data.devicesList">
            <tr
              *ngIf="
                selectedManufacturer
                  ? selectedManufacturer == device.fabricante
                  : true
              "
            >
              <td>{{ device.fabricante }}</td>
              <td>{{ device.manufacturer }}</td>
              <td>{{ device.DEV_TYPE }}</td>
              <td>{{ device.dispositivo }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
