// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interface
import {
  AqualiaFile,
  EkFileWindows,
  FileCron,
  FileTemplate,
  OutputFileData,
  UpdatedAgrupationFiles,
} from "../../screens/dashboard/output-files/OutputFileInterface.type";

@Injectable({
  providedIn: "root",
})
export class OutputFilesControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Ficheros
  /***************************************************************************/

  // Obtención de la plantilla de fichero de entidad
  getEntityTemplate(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/fevt/list-by-entity/" + entityId);
  }

  // Actualización de las ventanas de comunicación del EK280
  updateEkFileWindows(data: EkFileWindows): Observable<object> {
    return this.HttpRequestService.post("/extws/ek280/regenerar", data);
  }

  // Obtención de las ventanas de comunicación configuradas del EK280
  getEkFileWindows(): Observable<object> {
    return this.HttpRequestService.get("/extws/ek280/ventanas/ficheros");
  }

  // Fichero de facturación EK
  getEkBillingFile(timestamp: number): Observable<object> {
    return this.HttpRequestService.get("/extws/hour-file/" + timestamp);
  }

  // Fichero de valores EK
  getEkValuesFile(
    data: number[],
    from: number,
    to: number
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/extws/values-file/from/" + from + "/to/" + to,
      data
    );
  }

  // Obtención de los ficheros de Aqualia
  getAqualiaFiles(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/aqualia/job-execution/from/" + from + "/to/" + to
    );
  }

  // Lectura de un fichero de Aqualia
  readAqualiaFile(data: AqualiaFile): Observable<object> {
    return this.HttpRequestService.post("/aqualia/read-file", data);
  }

  // Descarga por último valor
  downloadFileByLastValue(data: OutputFileData): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/export/lastValue/",
      data,
      {
        responseType: "blob" as "json",
      }
    );
  }

  // Descarga por valor más cercano
  downloadFileByClosestValue(
    timestamp: string,
    days: number,
    data: OutputFileData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/export/by-date/" + timestamp + "/" + days,
      data,
      {
        responseType: "blob" as "json",
      }
    );
  }

  // Descarga por intervalo
  downloadFileByInterval(
    from: string,
    to: string,
    data: OutputFileData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/export/from/" + from + "/to/" + to,
      data,
      {
        responseType: "blob" as "json",
      }
    );
  }

  // Descarga fichero ATM
  downloadAtmFile(
    entityId: number,
    date: number,
    days: number,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/atm/transac/" + entityId + "/" + date + "/" + days,
      data
    );
  }

  // Descarga fichero ATM xml
  downloadAtmXmlFile(
    entityId: number,
    date: number,
    days: number,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/atm/transac-xml/" + entityId + "/" + date + "/" + days,
      data
    );
  }

  // Descarga fichero IZFE
  downloadIzfeFile(
    entityId: number,
    date: number,
    days: number,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/izfe/pata/" + entityId + "/" + date + "/" + days,
      data
    );
  }

  // Descarga fichero SADA
  downloadGeiserFile(
    entityId: number,
    date: number,
    days: number,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/nortegas/" + entityId + "/" + date + "/" + days,
      data
    );
  }

  // Descarga fichero UGENA
  downloadUgenaFile(
    entityId: number,
    date: number,
    days: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/reading-files/ugena/" + entityId + "/" + date + "/" + days
    );
  }

  // Descarga fichero UGENA
  downloadPoseidoniaFile(
    date: number,
    days: number,
    data: unknown
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/poseidonia/" + date + "/" + days,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Plantillas
  /***************************************************************************/

  getTemplate(templateId: number): Observable<object> {
    return this.HttpRequestService.get("/fevt/show/" + templateId);
  }

  saveTemplate(data: FileTemplate): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/export/template/save",
      data
    );
  }

  editTemplate(data: FileTemplate): Observable<object> {
    return this.HttpRequestService.post("/fevt/update/", data);
  }

  removeTemplate(templateId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/reading-files/export/template/delete/" + templateId
    );
  }

  getTemplateList(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/reading-files/export/template/list/" + entityId
    );
  }

  saveCron(data: FileCron): Observable<object> {
    return this.HttpRequestService.post(
      "/reading-files/export/templateQuartz/save",
      data
    );
  }

  getCronList(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/reading-files/export/templateQuartz/list/" + entityId
    );
  }

  removeCron(cronId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/reading-files/export/templateQuartz/delete/" + cronId
    );
  }

  getFtpList(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/ftp/list/" + entityId);
  }

  /***************************************************************************/
  // ANCHOR Timer
  /***************************************************************************/

  // Añadir las agrupaciones según el modo (incluir, sustituir) a la hora indicada para el proceso de creacion y envio de fichero ftp a aqualia
  getAqualiaFtp(): Observable<object> {
    return this.HttpRequestService.get("/aqualia/ftp-hourly-file/consult");
  }

  // Envio de ficheros pendientes por agrupación
  updateAqualiaFtp(data: UpdatedAgrupationFiles): Observable<object> {
    return this.HttpRequestService.post("/aqualia/ftp-hourly-file/save", data);
  }
}
