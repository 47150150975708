<div class="dialog-container">
  <div class="dialog-header geolocation-dialog-header">
    <h5 class="dialog-title">
      {{ "meter-geolocation" | translate }}: {{ data?.device.nroSerie }}
    </h5>

    <!-- Selección de agrupación -->
    <div>
      <app-material-select
        class="material-header-select"
        [title]="'reference-agrupation' | translate"
        [bindValue]="'id'"
        [bindLabel]="'name'"
        [selected]="preselectedAgrupation"
        [data]="agrupationOptions"
        (selectedOption)="referenceAgrupationSelected = $event"
      ></app-material-select>
      <button
        mat-button
        class="mat-button-icon-text"
        (click)="getGatewaysData()"
      >
        <i class="fas fa-map-location-dot"></i>
        {{ "locate" | translate }}
      </button>
    </div>
  </div>

  <div class="dialog-body geolocation-dialog-body">
    <div class="geolocation-agrupation">
      <!-- Coordenadas -->
      <div class="geolocation-coords-container">
        <!-- Localización actual -->
        <div class="geolocation-coords">
          <span>{{ "current" | translate }}</span>
          <div>
            <div>
              <label for="latitude">{{ "latitude" | translate }}</label>
              <input
                name="latitude"
                [(ngModel)]="data?.device.latitude"
                disabled
              />
            </div>
            <div>
              <label for="longitude">{{ "longitude" | translate }}</label>
              <input
                name="longitude"
                [(ngModel)]="data?.device.longitude"
                disabled
              />
            </div>
          </div>
        </div>
        <!-- Localización por ponderación -->
        <div class="geolocation-coords">
          <span>{{ "meter-geolocation-ponderation" | translate }}</span>
          <div>
            <div>
              <label for="estimatedLatitude">{{
                "latitude" | translate
              }}</label>
              <input
                name="estimatedLatitude"
                [(ngModel)]="estimatedPonderationLocation[0]"
                disabled
              />
            </div>
            <div>
              <label for="estimatedLongitude">{{
                "longitude" | translate
              }}</label>
              <input
                name="estimatedLongitude"
                [(ngModel)]="estimatedPonderationLocation[1]"
                disabled
              />
            </div>
          </div>
        </div>
        <!-- Localización por triangulación -->
        <div class="geolocation-coords">
          <span>{{ "meter-geolocation-trilateration" | translate }}</span>
          <div>
            <div>
              <label for="estimatedLatitude">{{
                "latitude" | translate
              }}</label>
              <input
                name="estimatedLatitude"
                [(ngModel)]="estimatedTrilaterationLocation[0]"
                disabled
              />
            </div>
            <div>
              <label for="estimatedLongitude">{{
                "longitude" | translate
              }}</label>
              <input
                name="estimatedLongitude"
                [(ngModel)]="estimatedTrilaterationLocation[1]"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div #geolocationData class="geolocation-data-container">
      <div #geolocationMapContainer class="geolocation-map-container">
        <!-- Mapa de contador -->
        <app-map-controller
          [mapType]="'geolocation'"
          [mapHeight]="300"
          [initialZoom]="14"
          [hideCluster]="true"
          [disableLegend]="true"
          [disableCluster]="true"
          [hideMenu]="true"
          [activateAllLayers]="activateAllLayers"
          [gatewaysData]="gatewaysData"
          [metersData]="meterData"
        >
        </app-map-controller>
      </div>

      <!-- Tabla -->
      <app-table-controller
        [tableId]="'meterGeolocation'"
        [export]="false"
        [columns]="gatewayColumns"
        [rowNumbers]="true"
        [maxReg]="20"
        [onlyTable]="true"
        [paginationDisabled]="true"
        [data]="gatewaysData"
      >
      </app-table-controller>
    </div>
  </div>

  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      (click)="changeDevicePosition(estimatedPonderationLocation)"
      [disabled]="estimatedPonderationLocation?.length < 2"
    >
      {{ "change-location" | translate }} ({{
        "meter-geolocation-ponderation" | translate
      }})
    </button>
    <button
      mat-button
      (click)="changeDevicePosition(estimatedTrilaterationLocation)"
      [disabled]="estimatedTrilaterationLocation?.length < 2"
    >
      {{ "change-location" | translate }} ({{
        "meter-geolocation-trilateration" | translate
      }})
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
