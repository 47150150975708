// @angular
import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios porpios
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
// Interfaces
import { GatewayActivate } from "../../../GatewayInterface.type";

@Component({
  selector: "app-gateway-available-dialog",
  templateUrl: "./gateway-available-dialog.component.html",
  styleUrls: ["./gateway-available-dialog.component.scss"],
})
export class GatewayAvailableDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Activación de gateway
  activateGateway(): void {
    let data: GatewayActivate = {
      nroSerie: this.data.unidadVenta,
      latitude: this.data.latitude,
      longitude: this.data.longitude,
      entity: this.SessionDataService.getCurrentEntity()?.entity,
      comments: this.data.comments,
    };
    this.GatewayController.activateGateway([data]).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("activated")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
