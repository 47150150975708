import { Directive, ElementRef, Input, HostListener } from "@angular/core";
import { SessionDataService } from "../../services/shared/SessionDataService.service";

@Directive({
  selector: "[help]",
})
export class HelpDirective {
  @Input() help = "";
  title: string;

  constructor(
    private el: ElementRef,
    private SessionDataService: SessionDataService
  ) {}

  @HostListener("mouseenter") onMouseEnter() {
    if (this.SessionDataService.getCurrentHelpActive()) {
      this.updateTitle();
    }
  }

  @HostListener("mouseleave") onMouseLeave() {
    if (this.SessionDataService.getCurrentHelpActive()) {
      this.clearTitle();
    }
  }

  updateTitle(): void {
    this.title = this.el.nativeElement.title;
    this.el.nativeElement.setAttribute(
      "title",
      this.help?.replace(/\\n/g, "\n")?.replace(/\\t/g, "\t")
    );
  }

  clearTitle(): void {
    this.el.nativeElement.setAttribute("title", this.title);
    this.title = null;
  }
}
