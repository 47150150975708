<div #sidebar class="sidebar-container sidebar-preload">
  <!-- ANCHOR Logo link -->
  <div class="sidebar-logo-link" [routerLink]="['/principal']">
    <a class="sidebar-top-logo">
      <i *ngIf="logoLoading" class="fas fa-spinner fa-pulse"></i>
      <img
        *ngIf="logoImgUrl"
        [hidden]="logoLoading"
        (load)="onLogoLoad()"
        class="sidebar-top-logo-lg"
        [src]="logoImgUrl"
        alt="Logo"
      />
    </a>
  </div>

  <!-- ANCHOR Sidebar -->
  <div
    class="sidebar-wrapper"
    [ngClass]="{
      'sidebar-hide': sidebarToggled,
      'sidebar-show': sidebarToggled == false
    }"
    (mouseenter)="openSidebar()"
    (mouseleave)="collapseSidebar()"
  >
    <ul
      class="sidebar-nav"
      *ngIf="sessionProfile != PROFILES.ABONADO && menuList"
    >
      <!-- ANCHOR Menu -->
      <ng-container *ngFor="let menu of menuList; index as i">
        <li
          *ngIf="!menu.submenu && menu.profile && menu.client"
          class="sidebar-menu"
          [ngClass]="{
            'sidebar-menu-disabled': menu.disabled,
            'sidebar-menu-active': activeMenu == menu.name
          }"
          [help]="menu.help | translate"
          [routerLink]="[menu.link]"
          (mouseenter)="menuActive = false; closeMenu()"
        >
          <a
            class="sidebar-nav-link"
            [ngClass]="{ 'sidebar-nav-link-toggled': sidebarToggled }"
          >
            <div>
              <i class="{{ menu.icon }}"></i>
              <span *ngIf="!sidebarToggled" class="sidebar-menu-title">{{
                menu.name | translate
              }}</span>
            </div>
          </a>
        </li>
        <!-- ANCHOR Menu con submenú -->
        <li
          *ngIf="menu.submenu && menu.profile && menu.client"
          class="sidebar-menu"
          [help]="menu.help | translate"
          [ngClass]="{
            'sidebar-menu-disabled': menu.disabled,
            'sidebar-menu-active': activeMenu == menu.name
          }"
          (mouseenter)="
            menuActive = true;
            clearMenuTimeout();
            openSubmenu($event.target, menu)
          "
          (mouseleave)="menuActive = false; closeMenu()"
        >
          <a
            class="sidebar-nav-link"
            [ngClass]="{ 'sidebar-nav-link-toggled': sidebarToggled }"
          >
            <div>
              <i class="{{ menu.icon }}"></i>
              <span *ngIf="!sidebarToggled" class="sidebar-menu-title">{{
                menu.name | translate
              }}</span>
            </div>
            <i
              *ngIf="!sidebarToggled"
              class="fas fa-caret-right sidebar-menu-caret"
            ></i>
          </a>
        </li>
      </ng-container>
    </ul>

    <!-- ANCHOR Submenú -->
    <div
      *ngIf="activeSubmenu && !sidebarToggled"
      class="sidebar-submenu"
      (mouseenter)="menuActive = true; clearMenuTimeout()"
      (mouseleave)="menuActive = false; closeMenu()"
    >
      <!-- Submenú > Título -->
      <ng-container *ngFor="let submenu of activeSubmenu">
        <!-- ANCHOR Submenú sin submenú -->
        <button
          mat-menu-item
          *ngIf="submenu.profile && submenu.client && !submenu.submenu"
          [help]="submenu.help | translate"
          [routerLink]="[submenu.link]"
          [disabled]="submenu.disabled"
          (mouseenter)="activeSubmenuSubmenu = null"
        >
          <div class="sidebar-submenu-button">
            <div>
              <mat-icon class="material-menu-icon"
                ><i class="{{ submenu.icon }}"></i>
                <i
                  *ngIf="submenu?.subicon"
                  class="{{ submenu.subicon }} sidebar-nav-subicon"
                ></i
              ></mat-icon>

              <span>{{ submenu.name | translate }}</span>
            </div>
            <div *ngIf="submenu.shortcut" class="sidebar-shortcut">
              <span class="keyboardkey">SHIFT</span>
              <span class="keyboardkey">ALT</span>
              <span class="keyboardkey">{{ submenu.shortcut }}</span>
            </div>
          </div>
        </button>
        <!-- ANCHOR Submenú con submenú -->
        <button
          *ngIf="submenu.profile && submenu.client && submenu.submenu"
          mat-menu-item
          [help]="submenu.help | translate"
          (mouseenter)="
            submenuActive = true;
            clearMenuTimeout();
            openSubmenuSubmenu($event.target, submenu)
          "
          (mouseleave)="submenuActive = false; closeSubmenu()"
          [disabled]="submenu.disabled"
        >
          <div class="sidebar-submenu-button">
            <div>
              <mat-icon class="material-menu-icon"
                ><i class="{{ submenu.icon }}"></i>
                <i
                  *ngIf="submenu?.subicon"
                  class="{{ submenu.subicon }} sidebar-nav-subicon"
                ></i
              ></mat-icon>
              <span>{{ submenu.name | translate }} </span>
            </div>
            <i class="fas fa-caret-right sidebar-submenu-caret"></i>
          </div>
        </button>
      </ng-container>
    </div>

    <!-- ANCHOR Submenú de submenú -->
    <div
      *ngIf="activeSubmenuSubmenu"
      class="sidebar-submenu sidebar-submenu-submenu"
      (mouseenter)="menuActive = true; submenuActive = true; clearMenuTimeout()"
      (mouseleave)="menuActive = false; submenuActive = false; closeMenu()"
    >
      <ng-container *ngFor="let submenu of activeSubmenuSubmenu">
        <button
          mat-menu-item
          *ngIf="submenu.profile && submenu.client"
          [help]="submenu.help | translate"
          [routerLink]="[submenu.link]"
          [disabled]="submenu.disabled"
        >
          <div class="sidebar-submenu-button">
            <div>
              <mat-icon class="material-menu-icon"
                ><i class="{{ submenu.icon }}"></i>
                <i
                  *ngIf="submenu?.subicon"
                  class="{{ submenu.subicon }} sidebar-nav-subicon"
                ></i
              ></mat-icon>
              <span>{{ submenu.name | translate }}</span>
            </div>
            <div *ngIf="submenu.shortcut" class="sidebar-shortcut">
              <span class="keyboardkey">SHIFT</span>
              <span class="keyboardkey">ALT</span>
              <span class="keyboardkey">{{ submenu.shortcut }}</span>
            </div>
          </div>
        </button></ng-container
      >
    </div>

    <!-- ANCHOR Contraer menu -->
    <div class="sidebar-footer">
      <!-- Efectos -->
      <div class="sidebar-effect">
        <app-water-effect
          *ngIf="activeTheme?.name == 'water'"
          [ngClass]="{ 'sidebar-water-effect-toggled': sidebarToggled }"
        ></app-water-effect>
        <app-gas-effect
          *ngIf="activeTheme?.name == 'gas' || activeTheme?.name == 'nortegas'"
          [ngClass]="{ 'sidebar-gas-effect-toggled': sidebarToggled }"
        ></app-gas-effect>
        <app-gas-effect
          *ngIf="activeTheme?.name == 'nortegas'"
        ></app-gas-effect>
      </div>

      <!-- ANCHOR Logo Arson -->
      <div class="sidebar-logo">
        <span class="logo">
          <img
            class="img-responsive"
            [ngClass]="{ 'sidebar-logo-shine': activeTheme?.name == 'water' }"
            src="{{ sidebarLogo }}"
          />
        </span>
      </div>

      <!-- Bloqueo de menú -->
      <div
        *ngIf="!sidebarToggled"
        (click)="lockSidebar()"
        class="sidebar-lock pointer"
        [ngStyle]="{ opacity: sidebarLocked ? 1 : 0.5 }"
      >
        <i class="fa-solid fa-thumbtack"></i>
      </div>
    </div>
  </div>
</div>
