// @angular
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
// Moment
import * as moment from "moment";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import * as Highcharts from "highcharts/highstock";
require("moment-timezone")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/boost")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/pictorial")(Highcharts);
// Interfaces
import { GraphColorByPoint } from "../GraphInterface.type";
import { RANDOM_COLORS } from "../../map-module/map-variables/MAP_COLORS";

declare global {
  interface Window {
    moment: any;
  }
}
window.moment = moment;

@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.scss"],
})
export class GraphComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Flag de componente inicializado
  componentInitiated: boolean = false;

  // Inputs
  @Input() options;
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    this.chart?.zoomOut();
    this._data = data;
    // Actualización de gráfica en pantallas pequeñas
    if (this.componentInitiated && this._data) {
      if (window.innerWidth < 980) {
        this._data.yAxis.map((yAxis: any) => (yAxis.title.text = null));
      }
      this.updateFlag = true;
    } else if (!this.componentInitiated && this._data) {
      this.defaultType =
        this._data?.series && this._data?.series[0]?.type
          ? this._data.series[0]?.type
          : this._data.chart?.type;
    }
    // Actualización de color de datos interpolados
    if (this._colorByPoint) {
      setTimeout(
        () =>
          this.updateSeriesInterpolated(
            [this._colorByPoint.serie],
            this._colorByPoint.coloredData
          ),
        0
      );
    }
    // Actualización de rango del eje y
    if (this._yAxisMinRange || this._yAxisMaxRange) {
      this.updateAxisRange();
    }
    this.originalData = JSON.parse(JSON.stringify(data));
  }
  _data: any;
  originalData: any;
  @Input() chartConstructor: string;
  @Input() from: any;
  @Input() to: any;
  @Input()
  get colorByPoint(): GraphColorByPoint {
    return this._colorByPoint;
  }
  set colorByPoint(colorByPoint: GraphColorByPoint) {
    this._colorByPoint = colorByPoint;
    if (this._colorByPoint) {
      setTimeout(
        () =>
          this.updateSeriesInterpolated(
            [this._colorByPoint.serie],
            this._colorByPoint.coloredData
          ),
        0
      );
    }
  }
  _colorByPoint: GraphColorByPoint;
  @Input() seriesToUpdate: number[];
  @Input()
  get yAxisMinRange(): number {
    return this._yAxisMinRange;
  }
  set yAxisMinRange(yAxisMinRange: number) {
    this.chart?.zoomOut();
    this._yAxisMinRange = yAxisMinRange;
    this.updateAxisRange();
  }
  _yAxisMinRange: number;
  @Input()
  get yAxisMaxRange(): number {
    return this._yAxisMaxRange;
  }
  set yAxisMaxRange(yAxisMaxRange: number) {
    this.chart?.zoomOut();
    this._yAxisMaxRange = yAxisMaxRange;
    this.updateAxisRange();
  }
  _yAxisMaxRange: number;
  @Input() disableShowPoints: boolean;
  @Input() showCumulativeTotal: boolean;

  // Gráfica
  chart: any;
  chartCallback: any;
  Highcharts = Highcharts;
  updateFlag: boolean = false;
  defaultType: string;
  showPoints: boolean = false;
  accumulatedTotals: { color: string; text: string }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private translate: TranslateService) {
    const self = this;
    this.chartCallback = (chart: any) => {
      self.chart = chart;
    };
  }

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    Highcharts.setOptions(this.options);
    this.updateFlag = true;
    this.componentInitiated = true;
    this.getCumulativeTotals();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización del tipo de gráfica y los extremos del eje x
  updateGraphType(type: string): void {
    // Tipo original
    if (type == "default") {
      switch (this.chart.series[0]?.currentDataGrouping?.unitName) {
        case "hour":
          type = this.defaultType;
          break;
        case "day":
          type = "column";
          break;
        case "week":
          type = "column";
          break;
        default:
          type = this.defaultType;
          break;
      }
    }

    // Actualización de tipo
    this.chart?.showLoading();
    this.chart?.zoomOut();
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.updateSeriesType(type));
      }, 0);
    }).then(() => {
      this.chart?.hideLoading();
    });
  }

  // Actualización del tipo de serie
  updateSeriesType(type: string): void {
    this.updateXAxis();
    if (this.seriesToUpdate) {
      this.seriesToUpdate.forEach((serie: number) => {
        this._data.series[serie]["type"] = type;
      });
    } else {
      for (let i = 0; i < this._data.series.length; i++) {
        this._data.series[i]["type"] = type;
      }
    }
    this.updateFlag = true;
  }

  // Actualización de visionado de puntos interpolados
  updateSeriesInterpolated(seriesToCheck: number[], zones: any[]): void {
    let zonesParsed = [];
    for (let i = 0; i < zones.length; i++) {
      if (zones[i]?.value == zones[i + 1]?.value - 1) {
        i++;
      } else {
        zonesParsed.push(zones[i]);
      }
    }
    seriesToCheck?.map((serie: any) => {
      if (this._data.series[serie]) {
        this._data.series[serie].zoneAxis = "x";
        this._data.series[serie].zones = zonesParsed;
      }
    });
    this.updateFlag = true;
  }

  // Actualización de visionado de puntos interpolados
  updateAxisRange(): void {
    this.chart?.yAxis[0].setExtremes(this._yAxisMinRange, this._yAxisMaxRange);
  }

  // Visualización de línea entre timestamps
  addTimestampsLine(
    start: number,
    end: number,
    id: string,
    name: string
  ): void {
    this._data.series = this._data.series.filter(
      (serie: any) => !serie.id.includes(id)
    );
    this._data.series.push({
      id: id,
      name: name,
      type: "line",
      data: [
        [start, 0],
        [end, 0],
      ],
      step: true,
      color: "red",
      dataGrouping: { enabled: false },
      showInNavigator: false,
      enableMouseTracking: false,
      marker: {
        lineWidth: 1,
        lineColor: "white",
        radius: 5,
        symbol: "diamond",
      },
      lineWidth: 5,
      states: {
        inactive: {
          opacity: 1,
        },
      },
      yAxis: 1,
    });
    this.updateFlag = true;
  }

  // Actualización de datos de serie
  updateSeriesData(serieId: string, data: number[][]): void {
    let seriesToUpdate = this._data.series.find(
      (serie: any) => serie.id == serieId
    );
    if (seriesToUpdate) {
      seriesToUpdate.data = data;
      this.updateFlag = true;
    }
  }

  // Eliminación de línea entre timestamps
  removeTimestampsLine(id: string): void {
    this._data.series = this._data.series.filter(
      (serie: any) => !serie.id.includes(id)
    );
    this.updateFlag = true;
  }

  // Ocultar serie
  hideSerie(serie: any): void {
    this.chart.series[serie].hide();
  }

  // Visualizar valores de puntos
  showPointsValues(e: MouseEvent): void {
    e.preventDefault();
    const self = this;
    this.showPoints = !this.showPoints;
    this._data.series.map((serie: any) => {
      if (serie.id != "valveState" && serie.id != "dataseriesErrors") {
        serie.dataLabels = {
          enabled: self.showPoints,
          formatter: function () {
            let decimals = this.y % 1;
            return (
              (decimals?.toString()?.split(".")[1]?.length > 3
                ? Highcharts.numberFormat(this.y.toFixed(3), -1)
                : this.y) +
              (serie.id == "lastFiveDaysSerie" ||
              serie.id == "lastDaySerie" ||
              serie.id == "hourlySerie"
                ? "% | " +
                  Highcharts.numberFormat(serie.data[this.point.index][2], 0) +
                  "/" +
                  Highcharts.numberFormat(serie.data[this.point.index][3], 0)
                : "")
            );
          },
        };
      }
    });
    this.updateFlag = true;
  }

  // Redibujado de gráfica
  updateXAxis(): void {
    this.chart?.xAxis.map((xAxis, i) => {
      let serieAxis = this._data.series.find((serie) => serie.xAxis == i);
      if (!serieAxis) {
        serieAxis = this._data.series[0];
      }
      xAxis.setExtremes(
        serieAxis.data[0][0],
        serieAxis.data[serieAxis.data.length - 1][0]
      );
    });
  }

  // Mostrar anomalías
  showAnomalies(anomalies: number[][]): void {
    this._data.series = this._data.series.filter(
      (serie: any) => !serie.id.includes("anomalies")
    );
    this._data.series.push({
      id: "anomalies",
      name: this.translate.instant("anomaly"),
      type: "flags",
      data: anomalies,
      shape: "flag",
      color: "black",
      fillColor: "yellow",
      width: 16,
      title: "<i class='fas fa-exclamation'></i>",
      useHTML: true,
      style: {
        color: "white",
      },
      dataGrouping: { enabled: false },
      showInNavigator: false,
      enableMouseTracking: false,
    });
    this.updateFlag = true;
  }

  // Mostrar valores acumulados
  showCumulativeSum(cumulativeType: string): void {
    let seriesData = this._data.series
      .map((serie: any) => {
        return serie.data;
      })
      .reduce((a, b) => a.concat(b));
    switch (cumulativeType) {
      case "yearly":
        this._data.series = this.getNewAccumulatedSeries(
          seriesData,
          31536000000
        );
        break;
      case "weekly":
        this._data.series = this.getNewAccumulatedSeries(seriesData, 604800000);
        break;
      case "daily":
        this._data.series = this.getNewAccumulatedSeries(seriesData, 86400000);
        break;
      default:
        this.accumulatedTotals = null;
        this._data.series = this.originalData.series;
        break;
    }
    this.updateFlag = true;
    this.getCumulativeTotals();
  }

  // Obtención de series acumuladas por tramos
  getNewAccumulatedSeries(seriesData: number[][], gap: number): any {
    const self = this;
    let newSeries = [];
    let start = 0;
    let pointSuffix = this._data.series[0].tooltip?.valueSuffix;
    do {
      // Timestamp final del subgrupo de datos dependiendo del rango
      let end = seriesData.findIndex(
        (data) => data[0] > seriesData[start][0] + gap
      );
      // Nueva serie
      let newSerie = {
        id: "accumulated" + (newSeries.length - 1),
        name: this.translate.instant("accumulated"),
        color: RANDOM_COLORS[newSeries.length],
        cumulative: true,
        // Troceado de datos
        data: seriesData.slice(start, end > 0 ? end : seriesData.length),
        // Actualización de tooltip
        tooltip: {
          pointFormatter: function () {
            return (
              `<span style=\"color:{point.color}\">●</span> ` +
              self.translate.instant("plus") +
              ":<b> " +
              Highcharts.numberFormat(this.y, 3) +
              " " +
              pointSuffix +
              "</b><br>" +
              `<span style=\"color:{point.color}\">●</span> ` +
              self.translate.instant("accumulated") +
              ":<b> " +
              Highcharts.numberFormat(this.cumulativeSum, 3) +
              " " +
              pointSuffix +
              "</b>"
            );
          },
          valueSuffix: pointSuffix,
        },
      };
      newSeries.push(newSerie);
      // Nuevos límites de timestamps
      start = end;
    } while (start >= 0);
    return newSeries;
  }

  // Totales acumulados
  getCumulativeTotals(): void {
    if (this.showCumulativeTotal) {
      let accumulatedTotals = [];
      this._data.series?.forEach((serie) => {
        if (serie.data) {
          accumulatedTotals.push({
            color: serie.color,
            text:
              Highcharts.numberFormat(
                serie.data.map((data) => data[1]).reduce((a, b) => a + b),
                3
              ) +
              " " +
              serie.tooltip?.valueSuffix,
          });
        }
      });
      this.accumulatedTotals =
        accumulatedTotals?.length > 0 ? accumulatedTotals : null;
    }
  }

  // Hover serie index
  hoverIndex(index: number, serieIndex?: number): void {
    this.chart?.series[serieIndex ? serieIndex : 0]?.points[
      index
    ]?.onMouseOver();
  }
}
