// @angular
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { BrowserStorageLocalService } from "../../../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
// Variable de tarjetas
import { CARD_TYPES } from "../CARD_TYPES";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
// Interfaces
import { UserConfig } from "../../../interfaces/UserGlobalInterface.type";
import { Card } from "../CardInterface.type";

@Component({
  selector: "app-cards-controller",
  templateUrl: "./cards-controller.component.html",
  styleUrls: ["./cards-controller.component.scss"],
})
export class CardsControllerComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Flag de componente ya inicializado
  componentInitiated: boolean = false;

  // Variables de las tarjetas
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    this._data = data;
    if (this._data && this.componentInitiated) {
      this.loadComponent();
    }
  }
  _data: any;
  cards: any[];
  @Input() cardsOrder: string[];
  @Input() cardsFlex: string;
  @Input() cardWidth: number;
  @Input() cardsGap: number;
  @Input() metrologyType: number;
  @Input() consumptionInKg: boolean;
  @Input() cardsId: string;
  originalCardsOrder: string[];
  @Input() cardStretch: boolean;
  @Input() cardEditable: boolean;
  @Input() cardHeightFixed: boolean;
  @Input() loadingCards: boolean;
  @Input() graphVisibility: boolean;
  graphsHidden: boolean;
  @Output() hideGraphsFlag = new EventEmitter<boolean>();
  @ViewChild("cardsContainer") cardsContainer: ElementRef;

  // Variables de sesión
  sessionProfile: string;
  sessionLanguage: string;
  currentUser: string;

  // Formato de los números
  numberFormat: any;
  dateFormat: string;

  // Acciones de la tarjeta
  @Output() cardActionFlag = new EventEmitter<string>();
  @Output() cardClickEventFlag = new EventEmitter<string>();

  // Acciones de menú
  visibilitySelection: boolean = false;
  orderSelection: boolean = false;
  menuHovering: boolean = false;
  @Output() visibilityUpdatedFlag = new EventEmitter<any>();

  // Drag & Drop
  draggedCard: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private element: ElementRef,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentUser = this.SessionDataService.getCurrentUser();

    if (this._data) {
      this.loadComponent();
    }

    this.componentInitiated = true;
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    document.documentElement.style.setProperty(
      "--card-width",
      this.cardWidth ? String(this.cardWidth) : "32"
    );
    document.documentElement.style.setProperty(
      "--cards-flex",
      this.cardsFlex ? this.cardsFlex : "space-start"
    );
    document.documentElement.style.setProperty(
      "--cards-gap",
      this.cardsGap ? this.cardsGap + "%" : "unset"
    );
    if (this.cardHeightFixed) {
      this.fixCardsHeight();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización
  loadComponent(): void {
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();
    this.createCards();
    if (!this.cardsOrder) {
      this.cardsOrder = this.cards.map((card: any) => {
        return card.type;
      });
    }
    this.originalCardsOrder = [...this.cardsOrder];
    this.loadCardsConfig();
    this.sortCards();
  }

  // Creación de las tarjetas
  createCards(): void {
    this.cards = [];
    for (let cardType in this._data) {
      let card: any = {};
      let currentCard: any = CARD_TYPES[cardType];
      card["type"] = cardType;
      card["containerClasses"] = currentCard.containerClasses;
      if (cardType == "gateways" && this.sessionProfile != "ARSON") {
        card["link"] = ["/mantenimiento/gateways-cliente"];
        card["linkActive"] = true;
      } else {
        card["link"] = this._data[cardType]?.link
          ? this._data[cardType].link
          : currentCard.link;
        card["linkActive"] =
          !currentCard.linkProfiles ||
          (currentCard.linkProfiles &&
            currentCard.linkProfiles.includes(this.sessionProfile));
      }
      card["click"] = this._data[cardType]?.click;
      card["graph"] = this._data[cardType]?.graph;
      card["state"] = this._data[cardType]?.state
        ? this._data[cardType].state
        : currentCard.state;
      card["title"] = currentCard.title
        ? this.translate.instant(currentCard.title)
        : null;
      card["tooltip"] = currentCard.tooltip;
      card["classes"] = currentCard.classes;
      card["background"] = this._data[cardType]?.background
        ? this._data[cardType].background
        : currentCard.background;
      card["icon"] = this._data[cardType]?.icon
        ? this._data[cardType].icon
        : currentCard.icon;
      card["linkIcon"] = currentCard.linkIcon;
      card["infoBoxText"] = currentCard.infoBoxText
        ? this.translate.instant(currentCard.infoBoxText)
        : null;
      card["buttons"] = this._data[cardType]?.buttons;
      card["hideCondition"] = this._data[cardType]?.hideCondition;
      card["visibility"] = currentCard.visibility;
      card["hiddenSelection"] = card["visibility"];
      card["img"] = this._data[cardType]?.img;
      this.getCardEspecialAttributes(card, cardType, currentCard);
      card["dragSelection"] = false;
      card["cardHeight"] = this._data[cardType]?.graph
        ? card.infoBoxNumber
          ? 10
          : 6
        : 2.5;
      card["help"] = this._data[cardType]?.help;
      card["editable"] = this.cardEditable ? this.cardEditable : false;
      this.cards.push(card);
    }
  }

  // Ordenamiento de las tarjetas
  sortCards(): void {
    if (this.cardsOrder) {
      let sortedCards: Card[] = [];
      for (let i = 0; i < this.cardsOrder.length; i++) {
        let nextCard: Card = this.cards.find(
          (card: Card) => card.type == this.cardsOrder[i]
        );
        if (nextCard) {
          sortedCards.push(nextCard);
        }
      }

      if (this.cards.length != this.cardsOrder.length) {
        this.cards.forEach((card: Card) => {
          if (
            !sortedCards.find(
              (sortedCard: Card) => sortedCard.type == card.type
            )
          ) {
            this.cardsOrder.push(card.type);
            sortedCards.push(card);
          }
        });
      }
      this.cards = sortedCards;
    }
  }

  // Atributo personalizado para cada tarjeta
  getCardEspecialAttributes(
    card: any,
    cardType: string,
    currentCard: any
  ): void {
    let cardInfo: any;
    let cardDetail: any;
    let cardDate: number;
    let cardDateFormat: string;
    switch (cardType) {
      // Tarjeta de Gateways
      case "gatewaysArson":
        cardInfo = this._data.gateways?.data;
        if (cardInfo != null) {
          card["infoBoxNumber"] = formatNumber(cardInfo, this.numberFormat);
        }
        break;
      case "gatewaysNoArson":
        cardInfo = this._data.gateways?.data;
        if (cardInfo != null) {
          card["infoBoxNumber"] = formatNumber(cardInfo, this.numberFormat);
        }
        break;
      // Tarjeta de consumo día
      case "dayConsume":
        cardDate = this._data.dayConsume?.date;
        cardDateFormat = this._data.dayConsume?.dateFormat;
        cardInfo = this._data.dayConsume?.info;
        if (cardDate != null) {
          card["infoBoxText"] =
            card["infoBoxText"] +
            " " +
            this.DateParserService.parseDate(
              cardDate,
              cardDateFormat ? cardDateFormat : this.dateFormat + " HH:mm:ss"
            );
        }
        if (cardInfo != null) {
          card["infoBoxNumber"] =
            formatNumber(cardInfo, this.numberFormat) + " m³";
        }
        break;
      // Tarjeta de consumo mes
      case "monthConsume":
        cardDate = this._data.monthConsume?.date;
        cardDateFormat = this._data.monthConsume?.dateFormat;
        cardInfo = this._data.monthConsume?.info;
        if (cardDate != null) {
          card["infoBoxText"] =
            card["infoBoxText"] +
            " " +
            this.DateParserService.parseDate(
              cardDate,
              cardDateFormat ? cardDateFormat : this.dateFormat + " HH:mm:ss"
            );
        }
        if (cardInfo != null) {
          card["infoBoxNumber"] =
            formatNumber(cardInfo, this.numberFormat) + " m³";
        }
        break;
      // Tarjeta de último dato recibido
      case "meterDetailLastKnownValue":
        cardInfo = this._data.meterDetailLastKnownValue?.lastReadedValue;
        if (cardInfo != null) {
          card["infoBoxNumber"] =
            formatNumber(cardInfo, this.numberFormat) + " m³";
          card["infoBoxNumberExtra"] =
            "(" +
            this.DateParserService.parseDate(
              this._data.meterDetailLastKnownValue.lastReadedTimestamp,
              this.dateFormat + " HH:mm:ss"
            ) +
            ")";
        }
        break;
      // Tarjeta de RSSI
      case "gatewayDetailRssi":
        cardInfo = this._data.gatewayDetailRssi?.rssi;
        if (cardInfo != null) {
          card["infoBoxNumber"] =
            formatNumber(cardInfo, this.numberFormat) + " dBm";
        } else {
          card["infoBoxNumber"] = "-";
        }
        cardInfo = this._data.gatewayDetailRssi?.battery;
        if (cardInfo != null) {
          card["infoBoxNumber"] +=
            " / " + formatNumber(cardInfo, this.numberFormat) + " V";
        } else {
          card["infoBoxNumber"] += " / -";
        }
        break;
      // Tarjeta de última comunicación
      case "gatewayDetailLastCommunication":
        cardInfo = this._data.gatewayDetailLastCommunication?.lastCommunication;
        if (cardInfo != null) {
          card["infoBoxNumber"] = cardInfo;
        }
        cardInfo = this._data.gatewayDetailLastCommunication?.communicate;
        if (cardInfo != null) {
          card["infoBoxNumberExtra"] =
            "(" +
            (cardInfo
              ? this.translate.instant("communicates")
              : this.translate.instant("AlertMeter0")) +
            ")";
        }
        break;
      // Tarjeta de tiempo de bus UNE
      case "meterDetailTiempoUsoBusUne":
        cardInfo = this._data.meterDetailTiempoUsoBusUne?.data;
        if (cardInfo != null) {
          card["infoBoxNumber"] =
            (cardInfo.hour
              ? cardInfo.hour +
                (cardInfo.minute || cardInfo.seconds ? " h, " : " h")
              : "") +
            (cardInfo.minute
              ? cardInfo.minute + (cardInfo.seconds ? " min, " : "min")
              : "") +
            (cardInfo.seconds ? cardInfo.seconds + " s" : "");
        }
        break;
      // Tarjetas genéricas
      default:
        cardInfo = this._data[cardType]?.data;
        cardDetail = this.data[cardType]?.detail;
        cardDate = this._data[cardType]?.date;
        cardDateFormat = this._data[cardType]?.dateFormat;
        if (cardDate) {
          card["infoBoxNumberExtra"] =
            "(" +
            this.DateParserService.parseDate(
              cardDate,
              cardDateFormat ? cardDateFormat : this.dateFormat + " HH:mm:ss"
            ) +
            ")";
        }
        if (cardInfo != null) {
          switch (this._data[cardType].type) {
            case "number":
              card["infoBoxNumber"] = formatNumber(cardInfo, this.numberFormat);
              break;
            case "%":
              card["infoBoxNumber"] =
                formatNumber(cardInfo, this.numberFormat) + "%";
              break;
            case "consumption":
              card["infoBoxNumber"] =
                formatNumber(cardInfo, this.numberFormat) +
                (currentCard.units
                  ? currentCard.units
                  : this.metrologyType == METROLOGY_TYPE.WATER ||
                    this.metrologyType == METROLOGY_TYPE.SATELITE
                  ? " m³"
                  : this.consumptionInKg
                  ? " Kg"
                  : " Nm³");
              break;
            case "list":
              card["infoBoxList"] = cardInfo;
              card["infoBoxNumber"] = "";
              break;
            default:
              card["infoBoxNumber"] = cardInfo;
              break;
          }
          if (cardDetail) {
            card["infoBoxNumberExtra"] = "(" + cardDetail + ")";
          }
        }
        break;
    }
    if (!this.loadingCards && card["infoBoxNumber"] == null) {
      card["infoBoxNumber"] = this.translate.instant("unknown");
    }
  }

  // Aviso de acción de tarjeta
  cardAction(cardActionFlag: string): void {
    this.cardActionFlag.emit(cardActionFlag);
  }

  // Evento de click en tarjeta
  cardClickEvent(action: string): void {
    if (action) {
      this.cardClickEventFlag.emit(action);
    }
  }

  // Mostrar/ocultar gráficas de principal
  hideGraphs(): void {
    this.graphsHidden = !this.graphsHidden;
    this.hideGraphsFlag.emit(this.graphsHidden);
  }

  // Guardado de la configuración de las tarjetas
  saveCardsConfig(): void {
    let configData: any =
      this.BrowserStorageLocalService.getJsonValue("config");
    let userConfig: any;

    // Comprobación de si existe perfil local de usuario
    if (configData) {
      userConfig = configData.find(
        (config: any) => config.user == this.currentUser
      );
    } else {
      configData = [];
    }
    if (!userConfig) {
      userConfig = new UserConfig(this.currentUser);
      configData.push(userConfig);
    }

    userConfig.homeGraphsHidden = this.graphsHidden;

    // Actualización de la visibilidad de las tarjetas y el orden en el perfil local
    userConfig.cardConf[this.cardsId] = {
      cardsVisibility: this.cards.map((card: any) => {
        return { type: card.type, visibility: card.visibility };
      }),
      cardsOrder: this.cardsOrder,
    };

    // Guardado en local
    this.BrowserStorageLocalService.setJsonValue("config", configData);
    this.ToastService.fireToast(
      "success",
      this.translate.instant("config-save")
    );
  }

  // Borrado de la configuración de las tarjetas
  deleteCardsConfig(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("config-delete-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let configData: any =
          this.BrowserStorageLocalService.getJsonValue("config");
        let userConfig: any;

        // Comprobación de si existe perfil local de usuario
        if (configData) {
          userConfig = configData.find(
            (config: any) => config.user == this.currentUser
          );
        }

        if (this.graphVisibility) {
          userConfig.homeGraphsHidden = null;
        }

        // Eliminación de los datos locales
        if (userConfig && userConfig.cardConf[this.cardsId]) {
          userConfig.cardConf[this.cardsId] = null;
        }

        // Reseteo de componente
        this.cards.map((card: any) => {
          card.visibility = CARD_TYPES[card.type].visibility;
          card.hiddenSelection = card.visibility;
        });
        this.cardsOrder = [...this.originalCardsOrder];
        this.visibilityUpdated();

        // Guardado en local
        this.BrowserStorageLocalService.setJsonValue("config", configData);
        this.ToastService.fireToast(
          "success",
          this.translate.instant("config-delete")
        );
      }
    });
  }

  // Carga de la configuración de las tarjetas
  loadCardsConfig(): void {
    let configData: any =
      this.BrowserStorageLocalService.getJsonValue("config");
    let userConfig: any;

    // Comprobación de si existe perfil local de usuario
    if (configData) {
      userConfig = configData.find(
        (config: any) => config.user == this.currentUser
      );
    }

    this.graphsHidden = this.graphVisibility && userConfig?.homeGraphsHidden;
    setTimeout(() => {
      if (this.graphsHidden) {
        this.hideGraphsFlag.emit(this.graphsHidden);
      }
    }, 0);

    // Actualizción de datos del componente si existen datos guardados
    if (userConfig && userConfig.cardConf[this.cardsId]) {
      // Actualización de visibilidad de las tarjetas
      if (userConfig.cardConf[this.cardsId].cardsVisibility) {
        this.cards.forEach((card: any) => {
          let configuredCard: any = userConfig.cardConf[
            this.cardsId
          ].cardsVisibility.find(
            (configuredCard: any) => configuredCard.type == card.type
          );
          if (configuredCard) {
            card.visibility = configuredCard.visibility;
            card.hiddenSelection = card.visibility;
          }
        });
        setTimeout(() => {
          this.visibilityUpdatedFlag.emit();
        }, 0);
      }

      // Actualización del orden de las tarjetas
      if (userConfig.cardConf[this.cardsId].cardsOrder) {
        this.cardsOrder = userConfig.cardConf[this.cardsId].cardsOrder;
      }
    }
  }

  // Movimiento de tarjeta
  cardDragover(e: any): void {
    e.preventDefault();
  }

  // Finalización del movimiento de la tarjeta
  cardDrop(e: any, draggedOnCard: any): void {
    e.preventDefault();
    let cardPosition: number = this.cards.findIndex(
      (card: any) => card.type == draggedOnCard.type
    );

    if (cardPosition >= 0) {
      let draggedCardIndex: number = this.cards.findIndex(
        (card: any) => card.type == this.draggedCard.type
      );
      this.cards.splice(draggedCardIndex, 1);
      this.cards.splice(cardPosition, 0, this.draggedCard);
      this.draggedCard = null;
      this.updateCardsOrder();
    }
  }

  // Obtención de las coordenadas de cada tarjeta al arrastrar una de ellas
  cardDrag(draggedCard: any): void {
    this.draggedCard = draggedCard;
  }

  // Actualización del orden de las tarjetas
  updateCardsOrder(): void {
    this.cardsOrder = this.cards.map((card: any) => {
      return card.type;
    });
  }

  // Aviso de actualización de visibilidad de tarjetas
  visibilityUpdated() {
    setTimeout(() => this.visibilityUpdatedFlag.emit(), 0);
  }

  // Fijado del alto de las tarjetas a la de mayor tamaño
  fixCardsHeight(): void {
    let cardHeight: number;
    cardHeight = Math.max.apply(
      Math,
      Array.from(
        this.element?.nativeElement?.querySelectorAll(".info-box-content")
      ).map((element: any) => {
        return element.offsetHeight;
      })
    );
    this.cardsContainer.nativeElement.style.setProperty(
      "--card-fixed-height",
      cardHeight
    );
  }
}
