// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  SaveIp,
  UpdatedWhiteIp,
} from "../../screens/dashboard/ip/ip-list/IpLlistInterface.type";

@Injectable({
  providedIn: "root",
})
export class IpControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Eliminar una IP de la lista de IPs bloqueadas
  removeIP(clientIp: string): Observable<object> {
    return this.HttpRequestService.post("/client-ip/delete", clientIp, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    });
  }

  // Eliminar una IP de la lista de IPs permitidas
  removeWhiteIP(ipId: number): Observable<object> {
    return this.HttpRequestService.get("/client-whitelist/delete/" + ipId);
  }

  // Actualizar una IP de la lista de IPs permitidas
  updateWhiteIP(data: UpdatedWhiteIp): Observable<object> {
    return this.HttpRequestService.post("/client-whitelist/update", data);
  }

  // Eliminar una IP de la lista de IPs permitidas
  saveWhiteIP(data: SaveIp): Observable<object> {
    return this.HttpRequestService.post("/client-whitelist/save", data);
  }

  // Listado de IPs bloqueadas
  getBlockedIpList(): Observable<object> {
    return this.HttpRequestService.get("/client-ip/list");
  }
}
