<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <app-material-select
          class="material-header-select"
          [title]="'analytical-models' | translate"
          [bindValue]="'id'"
          [bindLabel]="'name'"
          [selected]="'weekly'"
          [data]="patternSelectOptions"
          (selectedOption)="
            yearlyPattern = $event.id == 'yearly'; updatePatternType()
          "
        ></app-material-select>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div class="panel-body analytical-models-container">
        <!-- Controles -->
        <div
          *ngIf="metersList; else elseBlock"
          class="analytical-models-controls"
        >
          <div class="analytical-models-meters">
            <!-- Selector de dispositivos -->
            <app-material-select
              [multiple]="true"
              [counter]="true"
              [clearable]="true"
              [fullWidth]="true"
              [filter]="true"
              [title]="'selected' | translate"
              [bindValue]="'id'"
              [bindLabel]="'nroSerie'"
              [selected]="preselectedMeters"
              [data]="metersList"
              (selectedOption)="selectedMeters = $event"
            ></app-material-select>
          </div>
          <!-- Perfiles -->
          <div class="analytical-models-patterns">
            <app-material-select
              [fullWidth]="true"
              [title]="'profile' | translate"
              [bindValue]="'profileId'"
              [bindLabel]="'profileName'"
              [selected]="preselectedConsumptionProfile"
              [data]="consumptionProfileList"
              (selectedOption)="
                selectedConsumptionProfile = $event; updateProfileSeries()
              "
            ></app-material-select>
          </div>
        </div>

        <!-- Gráficas -->
        <div *ngIf="metersList" class="analytical-models-graphs-container">
          <div class="analytical-models-graphs">
            <div class="analytical-models-profile-controls">
              <div
                [ngClass]="{
                  disabled:
                    !selectedConsumptionProfile?.profilePatterns ||
                    yearlyPattern
                }"
              >
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="
                    predictions ? (predictions = null) : makePrediction();
                    setPatternChartsOptions()
                  "
                >
                  <i class="fas fa-chart-area"></i>
                  {{
                    (predictions ? "patterns" : "month-prediction") | translate
                  }}
                </button>
              </div>
              <span
                [ngClass]="{
                  disabled: !selectedConsumptionProfile?.meterSimilarity
                }"
              >
                <b>{{ "similarity" | translate }}:</b>
                {{
                  selectedConsumptionProfile?.meterSimilarity
                    ? selectedConsumptionProfile?.meterSimilarity
                    : "0.00"
                }}
              </span>
            </div>
            <!-- Gráfica de consumo -->
            <app-graph-controller
              #meterConsumptionGraph
              [ngClass]="{
                'analytical-models-graph-disabled':
                  !selectedMeters || selectedMeters?.length == 0
              }"
              [highchartsOptions]="highchartsOptions"
              [noTypeSelector]="true"
              [chartConstructor]="chartConstructor"
              [dateRange]="defaultDateRange"
              (dataRequest)="loadGraphData($event.from, $event.to)"
              [avoidInitialLoad]="preselectedMeters ? false : true"
              [data]="chartOptions"
              (calculatePatterns)="calculatePatterns()"
            >
            </app-graph-controller>
            <!-- Gráfica de patrón -->
            <app-graph-controller
              class="analytical-models-pattern-graph"
              *ngIf="patternChartOptions"
              [highchartsOptions]="highchartsOptions"
              [noTypeSelector]="true"
              [noDateSelector]="true"
              [chartConstructor]="chartConstructor"
              [data]="patternChartOptions"
            >
            </app-graph-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
