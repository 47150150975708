<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          {{ "leaks-map" | translate }}
        </h5>
      </div>

      <div
        #leaksPanel
        *ngIf="mapData == null || mapData?.length > 0; else error"
        class="panel-body leaks-map-container"
      >
        <div *ngIf="!showHeatmaps" class="leaks-map">
          <div *ngIf="mapData" class="leaks-map-switch">
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="activateHeatmaps()"
              [disabled]="
                evolutionPlaying || !meterReadingsData || !sensorReadings
              "
            >
              <i class="fas fa-eye"></i>
              {{ "consumption-evolution" | translate }}
            </button>
          </div>
          <!-- Filtro -->
          <div
            *ngIf="mapData?.length > 0 && filterData?.length > 0"
            class="leaks-map-filter"
          >
            <span>{{ "filter-by-noise-level" | translate }}</span>
            <div
              *ngIf="filterSerie; else elseBlock"
              class="leaks-map-filter-graph"
            >
              <app-graph-mini
                [data]="filterSerie"
                [width]="filterGraphWidth"
                [dataLabels]="true"
              >
              </app-graph-mini>
              <div
                #slider
                *ngIf="filterSerieReady"
                class="leaks-map-filter-range"
              >
                <ngx-slider
                  [(value)]="minFilterValue"
                  [(highValue)]="maxFilterValue"
                  [options]="filterOptions"
                  (userChange)="updateFilteredSensors()"
                ></ngx-slider>
              </div>
            </div>
          </div>
          <!-- Selector de rango de contadores -->
          <div
            *ngIf="mapData"
            class="leaks-map-range"
            [ngClass]="{ disabled: selectedSensors?.length == 0 }"
          >
            <div
              class="leaks-map-range-title pointer"
              (click)="
                showMeterRange = !showMeterRange; updateMeterRangeVisual()
              "
            >
              <i
                class="pointer"
                [ngClass]="showMeterRange ? 'fas fa-eye-slash' : 'fas fa-eye'"
              ></i
              ><span
                ><b>{{ "sensor-range-extra" | translate }}</b></span
              >
            </div>
            <input
              type="range"
              class="pointer"
              max="200"
              min="100"
              step="1"
              [(ngModel)]="sensorExtraRange"
              (change)="updateMapData()"
            />
            <div class="leaks-map-range-limits">
              <span>100%</span><span>200%</span>
            </div>
          </div>
          <!-- Mapa de sensores -->
          <app-map-controller
            [mapOnly]="false"
            [disableLegend]="false"
            [hideMenu]="false"
            [mapType]="mapType"
            [mapHeight]="mapHeight"
            [freeDrawEnabled]="true"
            [allowKml]="true"
            (actionFlag)="mapActions($event.action, $event.sensor)"
            (selectedDevices)="updateSelected($event)"
            [gatewaysData]="[]"
            [metersData]="mapData"
            [showMeterRange]="showMeterRange"
            [boundsUpdateDisabled]="false"
          >
          </app-map-controller>
        </div>

        <div *ngIf="showHeatmaps" class="leaks-heat-maps">
          <div class="leaks-map-switch">
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="deactivateHeatmaps()"
              [disabled]="evolutionPlaying"
            >
              <i class="fas fa-eye"></i>
              {{ "map-selection" | translate }}
            </button>
          </div>
          <!-- Botón de desbloqueo de movimiento de mapa -->
          <div
            *ngIf="currentConsumptionHeatLayer"
            class="leaks-heat-map leaks-heat-map-consumption"
            [ngClass]="{ 'map-locked': consumptionMapLocked }"
          >
            <div
              class="leaks-heat-map-title leaks-heat-map-title-consumption"
              (click)="
                consumptionMapLocked = !consumptionMapLocked;
                noiseMapLocked = !consumptionMapLocked
              "
            >
              <span>
                <i
                  [ngClass]="
                    consumptionMapLocked
                      ? 'fas fa-thumbtack'
                      : 'fas fa-up-down-left-right'
                  "
                ></i>
              </span>
              {{ "consumption" | translate }}
            </div>
            <!-- Valor de consumo máximo -->
            <div class="leaks-map-range leaks-heat-map-range">
              <span class="leaks-map-range-title">
                <b>{{ "satelite-max-value" | translate }}</b>
              </span>
              <input
                type="range"
                class="pointer"
                max="{{ consumptionMaxValue }}"
                min="{{ consumptionMinValue }}"
                step="0.001"
                [(ngModel)]="consumptionRange"
                (change)="updateHeatmap(consumptionHeatmap)"
              />
              <div class="leaks-map-range-limits">
                <span>{{ consumptionMinValueLabel }}</span
                ><span>{{
                  consumptionMaxValue != null
                    ? consumptionMaxValueLabel
                    : consumptionMinValueLabel
                }}</span>
              </div>
            </div>
            <!-- Mapa de consumo -->
            <app-map-controller
              #consumptionHeatmap
              [mapOnly]="true"
              [disableLegend]="true"
              [hideMenu]="true"
              [mapType]="'heatMap'"
              [mapHeight]="mapHeight / 2"
              [fitBounds]="consumptionMapBounds"
              (boundsUpdate)="boundsUpdate($event, 'consumption')"
              [gatewaysData]="[]"
              [metersData]="[]"
              [heatLayerName]="consumptionHeatLayerName"
              [heatLayerData]="currentConsumptionHeatLayer"
              [heatLayerRadius]="radius"
              [heatLayerRange]="consumptionRange"
              [heatLayerMin]="consumptionMinValue"
              [heatLayerGradient]="heatLayerGradient"
              [boundsUpdateDisabled]="boundsUpdateDisabled"
            >
            </app-map-controller>
          </div>
          <!-- Botón de desbloqueo de movimiento de mapa -->
          <div
            *ngIf="currentNoiseHeatLayer"
            class="leaks-heat-map leaks-heat-map-noise"
            [ngClass]="{ 'map-locked': noiseMapLocked }"
          >
            <div
              class="leaks-heat-map-title leaks-heat-map-title-noise"
              (click)="
                noiseMapLocked = !noiseMapLocked;
                consumptionMapLocked = !noiseMapLocked
              "
            >
              <span>
                <i
                  [ngClass]="
                    noiseMapLocked
                      ? 'fas fa-thumbtack'
                      : 'fas fa-up-down-left-right'
                  "
                >
                </i
              ></span>
              {{ "noise" | translate }}
            </div>
            <!-- Valor de ruido máximo -->
            <div class="leaks-map-range leaks-heat-map-range">
              <span class="leaks-map-range-title">
                <b>{{ "satelite-max-value" | translate }}</b>
              </span>
              <input
                type="range"
                class="pointer"
                max="{{ noiseMaxValue }}"
                min="{{ noiseMinValue }}"
                step="1"
                [(ngModel)]="noiseRange"
                (change)="updateHeatmap(noiseHeatmap)"
              />
              <div class="leaks-map-range-limits">
                <span>{{ noiseMinValueLabel }}</span
                ><span>{{
                  noiseMaxValue != null
                    ? noiseMaxValueLabel
                    : noiseMinValueLabel
                }}</span>
              </div>
            </div>
            <!-- Mapa de ruido -->
            <app-map-controller
              #noiseHeatmap
              [mapOnly]="true"
              [disableLegend]="true"
              [hideMenu]="true"
              [mapType]="'heatMap'"
              [mapHeight]="mapHeight / 2"
              [fitBounds]="noiseMapBounds"
              (boundsUpdate)="boundsUpdate($event, 'noise')"
              [heatLayerName]="noiseHeatLayerName"
              [heatLayerRadius]="radius"
              [heatLayerRange]="noiseRange"
              [heatLayerMin]="noiseMinValue"
              [heatLayerGradient]="heatLayerGradient"
              [boundsUpdateDisabled]="boundsUpdateDisabled"
              [heatLayerData]="currentNoiseHeatLayer"
              [gatewaysData]="[]"
              [metersData]="[]"
            >
            </app-map-controller>
          </div>
        </div>

        <div class="leaks-map-controls">
          <!-- Selector de dispositivos -->
          <div *ngIf="originalMapData?.length > 0" class="leaks-map-select">
            <app-material-select
              [multiple]="true"
              [counter]="true"
              [clearable]="true"
              [fullWidth]="true"
              [filter]="true"
              [title]="'selected' | translate"
              [bindValue]="'id'"
              [bindLabel]="'nroSerie'"
              [selected]="preselectSensors"
              [data]="originalMapData"
              (selectedOption)="updateMapSelection($event)"
            ></app-material-select>
          </div>

          <!-- Gráfica -->
          <div
            *ngIf="originalMapData?.length > 0"
            class="leaks-map-noise-graph"
          >
            <!-- Evolución -->
            <div class="leaks-map-toggle-meters">
              <button
                mat-button
                class="mat-button-icon-text"
                [disabled]="!meterReadingsData || !showHeatmaps"
                (click)="evolutionPlaying ? stopEvolution() : showEvolution()"
              >
                <i
                  [ngClass]="evolutionPlaying ? 'fas fa-stop' : 'fas fa-play'"
                ></i>
                {{
                  (evolutionPlaying ? "show-evolution-stop" : "show-evolution")
                    | translate
                }}
              </button>
              <select
                [(ngModel)]="evolutionRate"
                [disabled]="!meterReadingsData || !showHeatmaps"
              >
                <option *ngFor="let rate of rateOptions" value="{{ rate }}">
                  {{ rate }}x
                </option>
              </select>
              <!-- Mostrar contadores -->
              <button
                mat-button
                class="mat-button-icon-text leaks-map-toggle-meters-button"
                (click)="sumatory = GRAPH_SUMATORY.SUM; showMetersGraph()"
                [disabled]="!graphLoaded"
              >
                <i class="fas fa-tachometer-alt"></i>
                {{ "load-consumption" | translate }}
              </button>
              <!-- Mostrar sumatorio/individuales -->
              <button
                *ngIf="consumptionData"
                mat-button
                class="mat-button-icon-text leaks-map-toggle-meters-button"
                (click)="toggleMetersGraph()"
                [disabled]="!meterReadingsData"
              >
                <i class="fas fa-eye"></i>
                {{ "consumption" | translate }}:
                {{
                  (sumatory == GRAPH_SUMATORY.INDIVIDUAL
                    ? "plus"
                    : "individual"
                  ) | translate
                }}
              </button>
            </div>

            <!-- Visibilidad de series -->
            <div
              class="leaks-map-series-visibility"
              *ngIf="graphLoaded && graphVisibilityAvailable"
            >
              <span (click)="showFreq = !showFreq; updateSerieVisibility()">
                <i
                  class="fas"
                  [ngClass]="showFreq ? 'fa-eye' : 'fa-eye-slash'"
                ></i>
                {{ "frequency" | translate }}
              </span>
              <span (click)="showAmp = !showAmp; updateSerieVisibility()">
                <i
                  class="fas"
                  [ngClass]="showAmp ? 'fa-eye' : 'fa-eye-slash'"
                ></i>
                {{ "amplitude" | translate }}
              </span>
              <span (click)="showTemp = !showTemp; updateSerieVisibility()">
                <i
                  class="fas"
                  [ngClass]="showTemp ? 'fa-eye' : 'fa-eye-slash'"
                ></i>
                {{ "temperature" | translate }}
              </span>
            </div>

            <app-graph-controller
              id="meters-graph-anchor"
              [highchartsOptions]="highchartsOptions"
              [noTypeSelector]="true"
              [initialDateNull]="true"
              [data]="chartOptions"
              [chartConstructor]="chartConstructor"
              [dateRange]="defaultDateRange"
              (dataRequest)="loadGraphData($event.from, $event.to)"
              [dateDisabled]="!(selectedSensors?.length > 0)"
            >
            </app-graph-controller>
          </div>
        </div>
      </div>

      <!-- Sin sensores -->
      <ng-template #error>
        <div class="text-center">
          <i class="fas fa-exclamation-triangle"></i>
          <h3>{{ "sensors-undetected" | translate }}</h3>
        </div>
      </ng-template>
    </div>
  </div>
</section>
