// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class NotificationControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Listado de todas las notificaciones de un abonado
  getUserNotifications(): Observable<object> {
    return this.HttpRequestService.get("/notificacion/list");
  }

  // Listado de todas las notificaciones por cups
  getCupsNotifications(cupsId: number): Observable<object> {
    return this.HttpRequestService.get("/notificacion/list/cm/" + cupsId);
  }

  // Mandar notificación a varios abonados mediante fichero
  sendNotifications(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/notificacion/send/from/file", data);
  }

  // Número de notificaciones pendientes de leer
  getUserPendingNotifications(): Observable<object> {
    return this.HttpRequestService.get("/notificacion/count/pending/by/user");
  }

  // Envío de notificación a usuario
  sendUserNotification(userId: number): Observable<object> {
    return this.HttpRequestService.get("/notificacion/send/to/user" + userId);
  }
}
