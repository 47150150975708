import { Injectable } from "@angular/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableSearchFilter,
} from "./TableInterface.type";

@Injectable({
  providedIn: "root",
})
export class TableFilterService {
  constructor() {}

  // Filtrado de los datos dependiendo de su tipo
  filterData(
    inputData: string,
    data: any,
    columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[],
    selectedFilter: string,
    selectedFilterRaw: string,
    espComparison: boolean,
    dateComparison: boolean,
    booleanComparison: boolean,
    numericalComparison: boolean,
    timezone: string,
    showNull?: boolean,
    inverse?: boolean
  ): any[] {
    let newData: any[] = [];
    if (inputData != null && inputData != "") {
      data.forEach((element: any) => {
        let elementAlreadyAdded: boolean = false;
        // Si hay filtro seleccionado
        if (selectedFilter) {
          if (espComparison && element[selectedFilterRaw] == null) {
            if (showNull) {
              newData.push(element);
            }
          } else {
            // Si el atributo acepta comparación numérica
            if (
              espComparison &&
              numericalComparison &&
              this.getNumericalComparison(element[selectedFilterRaw], inputData)
            ) {
              if (!inverse) {
                newData.push(element);
              }
              // Si el atributo acepta comparación por fecha
            } else if (
              espComparison &&
              dateComparison &&
              this.getDateComparison(
                element[selectedFilterRaw],
                inputData.split(";"),
                timezone
              )
            ) {
              if (!inverse) {
                newData.push(element);
              }
              // Si el atributo acepta comparación booleana
            } else if (
              espComparison &&
              booleanComparison &&
              this.getBooleanComparison(element[selectedFilterRaw], inputData)
            ) {
              if (!inverse) {
                newData.push(element);
              }
              // Si el atributo no acepta comparación concreta
            } else if (
              String(element[selectedFilter])
                .toUpperCase()
                .includes(inputData.toUpperCase())
            ) {
              if (!inverse) {
                newData.push(element);
              }
            } else if (inverse) {
              newData.push(element);
            }
          }
          // Si no hay filtro seleccionado
        } else {
          columns?.forEach((column: any) => {
            if (
              column.search &&
              element[column.search] &&
              !elementAlreadyAdded
            ) {
              if (
                String(element[column.search])
                  .toUpperCase()
                  .includes(inputData.toUpperCase())
              ) {
                elementAlreadyAdded = true;
                if (!inverse) {
                  newData.push(element);
                }
              }
            }
          });
          if (!elementAlreadyAdded && inverse) {
            elementAlreadyAdded = true;
            newData.push(element);
          }
        }
      });
      // Si filtro nulo
    } else if (showNull) {
      data.forEach((element: any) => {
        let elementAlreadyAdded: boolean = false;
        // Si hay filtro seleccionado
        if (selectedFilter) {
          if (
            element[selectedFilter] == null ||
            element[selectedFilter] == ""
          ) {
            if (!inverse) {
              newData.push(element);
            }
          } else if (inverse) {
            newData.push(element);
          }

          // Si no hay filtro seleccionado
        } else {
          columns?.forEach((column: any) => {
            if (
              column.search &&
              element[column.search] &&
              !elementAlreadyAdded
            ) {
              if (
                element[column.search] == null ||
                element[column.search] == ""
              ) {
                elementAlreadyAdded = true;
                if (!inverse) {
                  newData.push(element);
                }
              }
            }
          });
          if (!elementAlreadyAdded && inverse) {
            elementAlreadyAdded = true;
            newData.push(element);
          }
        }
      });
    } else {
      newData = [...data];
    }
    return newData;
  }

  // Comparación booleana
  getBooleanComparison(data: any, inputData: string): boolean {
    if (inputData == "t") {
      return data ? true : false;
    } else if (inputData == "f") {
      return data ? false : true;
    }
  }

  // Comparación numérica
  getNumericalComparison(data: number, inputData: string): boolean {
    let inputDataArray: any[] = inputData.split(",");
    let validNumber: boolean = true;
    let i: number = 0;

    do {
      validNumber = this.getComparison(data, inputDataArray[i]);
      i++;
    } while (i < inputDataArray.length && validNumber);

    return validNumber;
  }

  // Comparación de condición numérica
  getComparison(data: number, inputData: string): boolean {
    if (inputData) {
      if (inputData.includes(">=")) {
        return data >= parseFloat(inputData.substring(2));
      } else if (inputData.includes(">")) {
        return data > parseFloat(inputData.substring(1));
      } else if (inputData.includes("<=")) {
        return data <= parseFloat(inputData.substring(2));
      } else if (inputData.includes("<")) {
        return data < parseFloat(inputData.substring(1));
      } else {
        return data == parseFloat(inputData.substring(2));
      }
    }
  }

  // Comparación de fechas
  getDateComparison(
    data: number,
    inputDate: string[],
    timezone: string
  ): boolean {
    if (data != null) {
      return this.dateCheck(data, inputDate, timezone);
    } else {
      return false;
    }
  }

  // Comparación de fecha con el dato de búsqueda
  dateCheck(elementDate: any, inputDate: string[], timezone: string): boolean {
    let dateChecked: boolean;
    let i: number = 0;

    do {
      let comparison: string = inputDate[i].slice(0, 2);
      let inputTimestamp: number = parseInt(inputDate[i].substring(2));

      switch (comparison) {
        case ">":
          dateChecked = elementDate > inputTimestamp;
          break;
        case ">=":
          dateChecked = elementDate >= inputTimestamp;
          break;
        case "<":
          dateChecked = elementDate < inputTimestamp;
          break;
        case "<=":
          dateChecked = elementDate <= inputTimestamp;
          break;
        default:
          let elementDateParsed: string = moment_timezone(elementDate)
            .tz(timezone)
            .format("L");
          let noTimeDate: number = moment(elementDateParsed, "L").valueOf();
          dateChecked = noTimeDate == inputTimestamp;
          break;
      }
      i++;
    } while (i < inputDate.length && dateChecked);

    return dateChecked;
  }
}
