import { TooltipOptions } from "highcharts";
import { Card } from "../../../modules/card-module/CardInterface.type";

// ANCHOR Datos de petición de gráfica
export interface GraphRequestData {
  meterList: number[];
  agrupation: number;
  fromTimestamp: number;
  toTimestamp: number;
  graphType: number;
}

// ANCHOR Datos de gráfica por contadores
export interface GraphMeterData {
  meterId?: number;
  nroSerie?: string;
  readings: (number | boolean)[][];
}

// ANCHOR Datos de gráfica por sumatorio
export interface GraphSumatoryData {
  alarms: GraphAlarms[];
  readings: (number | boolean)[][];
}

// ANCHOR Datos de alarmas de la gráfica
export interface GraphAlarms {
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;
}

// ANCHOR Serie de contador
export interface GraphMeterSerie {
  id: string;
  name: string;
  data: (number | boolean)[][];
  dataGrouping?: object;
  tooltip?: TooltipOptions;
  events?: any;
  type?: string;
  marker?: object;
  yAxis?: number;
}

// ANCHOR Serie de sumatorio
export interface GraphSumatorySerie {
  id: string;
  name: string;
  data: (number | boolean)[][];
  dataGrouping?: object;
  color?: string;
  tooltip?: TooltipOptions;
  type?: string;
  marker?: object;
  yAxis?: number;
}

// ANCHOR Coordenadas de dispositivo
export interface MeterGraphDeviceCoord {
  agrupationId: number;
  northLatitude: number;
  northLongitude: number;
  southLatitude: number;
  southLongitude: number;
}

// ANCHOR Dispositivo encontrado en las coordenadas
export interface MeterGraphFoundDevice {
  id: number;
  nroSerie: string;
  tipo: string;
  metrologyType: number;
}

// ANCHOR Datos de consumo por timestamp
export interface ConsumptionDataByTimestamp {
  timestamp: number;
  latitude: number;
  longitude: number;
  consumption: number;
}

// ANCHOR Balance
export class Balance {
  // BackEnd
  public id: number;
  public name: string;
  public description: string;
  public type: number;
  public agrupation: number;
  public metrologyType: number;

  // FrontEnd
  public redirect?: string;
  public meterId?: number;

  constructor() {
    this.id = null;
    this.name = null;
    this.description = null;
    this.type = null;
    this.agrupation = null;
    this.metrologyType = null;
  }
}

// ANCHOR Edición de balance
export interface BalanceEdition {
  name: string;
  description: string;
  parentList: number[];
  childList: number[];
  id?: number;
}

// ANCHOR Datos de mapa de balance
export interface BalanceMapData {
  id: number;
  name: string;
  description: string;
  metersParentList: BalanceMapParentDevice[];
  metersChildList: BalanceMapChildDevice[];
}

// ANCHOR Dispositivo padre de balance
export interface BalanceMapParentDevice {
  // BackEnd
  meterId: number;
  meterNroSerie: string;
  claveMaestraId: number;
  claveMaestraKey: string;
  claveMaestraIsAssigned: boolean;
  longitude: number;
  latitude: number;
  parentChild: number;
  comunica: boolean;
  fabricante: number;
  lastReadedTimestamp: number;
  metrologyType: number;

  // FrontEnd
  meterType?: string;
}

// ANCHOR Dispositivo padre de balance
export interface BalanceMapChildDevice {
  // BackEnd
  meterId: number;
  meterNroSerie: string;
  claveMaestraId: number;
  claveMaestraKey: string;
  claveMaestraIsAssigned: boolean;
  longitude: number;
  latitude: number;
  parentChild: number;
  comunica: boolean;
  fabricante: number;
  lastReadedTimestamp: number;
  metrologyType: number;

  // FrontEnd
  meterType?: string;
}

// ANCHOR Datos de tarjetas de balance
export interface BalanceCardsData {
  // BakcEnd
  parentsSize: number;
  childsSize: number;
  anr: number;
  anrTimestamp: number;
  alarmThresholds: BalanceAlarmThreshold;
  alarms: BalanceAlarm[];

  // FrontEnd
  anrTimestampParsed?: string;
}

// ANCHOR Umbral de alarma
export class BalanceAlarmThreshold {
  public id?: number;
  public sector: number;
  public observationThreshold: number;
  public criticalThreshold: number;
  public intervalDays: number;

  constructor() {
    this.sector = null;
    this.observationThreshold = null;
    this.criticalThreshold = null;
    this.intervalDays = null;
  }
}

// ANCHOR Alarma de balance
export interface BalanceAlarm {
  // BackEnd
  id: number;
  sector: number;
  code: number;
  initDate: number;
  finalDate: number;

  // FrontEnd
  initDateParsed: string;
  finalDateParsed: string;
}

// ANCHOR Tarjetas de balance
export interface BalanceCards {
  balanceParentsSize: Card;
  balanceChildsSize: Card;
  balanceUnbilled: Card;
  balanceThresholdAlarm: Card;
}

// ANCHOR Datos de gráfica de balance
export interface BalanceGraphData {
  initDate: number;
  finalDate: number;
  parentReadings: number[][];
  childReadings: number[][];
  differenceReadings: number[][];
  loadBalancingContainer: {
    childsPercentaje: number;
    differencePercentaje: number;
    childAcumulatedConsumption: number;
    parentAcumulatedConsumption: number;
  };
  totalHours: number;
  childTotalHours: number;
  childPercentage: number;
  parentTotalHours: number;
  parentPercentage: number;
}

// ANCHOR Datos de petición de gráfica de balance
export interface BalanceGraphRequestData {
  meterList: number[];
  sector: number;
  fromTimestamp: string;
  toTimestamp: string;
  graphType: number;
}

// ANCHOR Serie padre de la gráfica de balance
export interface BalanceGraphSerie {
  id: string;
  name: string;
  data: any[];
  dataGrouping: object;
  tooltip?: TooltipOptions;
  color?: string;
  opacity?: number;
  type?: string;
  navigatorOptions?: {
    type: string;
  };
  yAxis?: number;
  animation?: boolean;
  displace?: boolean;
}

// ANCHOR Datos de serie de gráfica circular
export interface BalanceGraphCircleData {
  color: string;
  name: string;
  y: number;
}

// ANCHOR Datos de mapa de calor
export interface HeatMapData {
  consumptionList: HeatMapConsumption[];
  consumptionCalculatedTime: number;
  consumptionMaxValue: number;
  consumptionMinValue: number;
}

// ANCHOR Dato de consumo del mapa de calor
export interface HeatMapConsumption {
  longitude: number;
  latitude: number;
  consumption: number;
}

// ANCHOR Selección para comparación de gráficas
export interface GraphComparisonSelection {
  selected: any;
  dateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
}

// ANCHOR Grupo de selección para comparación de gráficas
export interface GraphComparisonSelectedGroup {
  name: string;
  agrupation: number;
  dateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
  meters: { id: number; nroSerie: string; metrologyType: number }[];
}

// ANCHOR Selección guardada para comparación de gráficas
export interface SavedSelection {
  name: string;
  agrupation: number;
  id?: string;
  group?: string;
  meters: { id: number; nroSerie: string; metrologyType: number }[];
}
