import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MaterialTreeNode } from "../MaterialInterface.type";

@Component({
  selector: "app-material-tree-controller",
  templateUrl: "./material-tree-controller.component.html",
  styleUrls: ["./material-tree-controller.component.scss"],
})
export class MaterialTreeControllerComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input()
  get nodes(): MaterialTreeNode[] {
    return this._nodes;
  }
  set nodes(nodes: MaterialTreeNode[]) {
    this._nodes = nodes;
    this.originalNodes = [...this._nodes];
    this.updateSelectedNodes(true);
  }
  _nodes: MaterialTreeNode[];
  originalNodes: MaterialTreeNode[];
  expanded: boolean = false;
  searchedNode: string;
  showScroll: boolean;
  maxSroll: boolean = false;
  minScroll: boolean = true;
  @Output() selectedChange = new EventEmitter<(number | string)[]>();
  @ViewChild("matTreeContainer") matTreeContainer: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor() {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.checkScroll();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Filtrado de nodos
  filterTree(): void {
    if (this.searchedNode != null && this.searchedNode != "") {
      let filteredData = this.originalNodes.filter(
        (node: MaterialTreeNode) =>
          node.text.toLowerCase().includes(this.searchedNode.toLowerCase()) ||
          node.children?.some((node: MaterialTreeNode) =>
            node.text.toLowerCase().includes(this.searchedNode.toLowerCase())
          )
      );
      this._nodes = filteredData;
    } else {
      this._nodes = [...this.originalNodes];
    }
  }

  // Actualización de apertura/cierre de nodos
  updateExpanded(): void {
    this._nodes.map(
      (node: MaterialTreeNode) => (node.expanded = this.expanded)
    );
  }

  // Actualización de scroll
  updateScroll(): void {
    this.maxSroll =
      this.matTreeContainer?.nativeElement?.scrollTop ==
      this.matTreeContainer?.nativeElement?.scrollHeight -
        this.matTreeContainer?.nativeElement?.offsetHeight;
    this.minScroll = this.matTreeContainer?.nativeElement?.scrollTop == 0;
  }

  // Comprobación de si existe barra de scroll
  checkScroll() {
    setTimeout(() => {
      this.showScroll =
        this.matTreeContainer.nativeElement.scrollHeight >
        this.matTreeContainer.nativeElement.offsetHeight;
      if (this.showScroll) {
        this.updateScroll();
      }
    }, 0);
  }

  // Actualización de los nodos seleccionados
  updateSelectedNodes(avoidEmit?: boolean): void {
    let selectedNodes: (number | string)[] = [];
    this.originalNodes.forEach((node: MaterialTreeNode) =>
      node.children.forEach((node: MaterialTreeNode) => {
        if (node.checked) {
          selectedNodes.push(node.value);
        }
      })
    );

    if (!avoidEmit) {
      this.selectedChange.emit(selectedNodes);
    }
  }
}
