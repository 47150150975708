import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import {
  MatCheckboxModule,
  MAT_CHECKBOX_DEFAULT_OPTIONS,
} from "@angular/material/checkbox";
import { MaterialModule } from "../material-module/material.module";

// Directives
import { DirectivesModule } from "../../directives/directives.module";

// Graph
import { GraphModule } from "../graph-module/graph.module";

// Table
import { TableControllerComponent } from "./table-controller/table-controller.component";
import { TableComponent } from "./table/table.component";
import { TableDialogComponent } from "./table-dialog/table-dialog.component";
import { ImportTableComponent } from "./import-table/import-table.component";
import { TableSpecialFilterDialogComponent } from "./table-special-filter-dialog/table-special-filter-dialog.component";
import { CupsFiltersComponent } from "./cups-filters/cups-filters.component";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatCheckboxModule,
    MaterialModule,
    
    // Directives
    DirectivesModule,
    
    // Graph
    GraphModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    TableControllerComponent,
    TableComponent,
    TableDialogComponent,
    ImportTableComponent,
    TableSpecialFilterDialogComponent,

    // CUPS
    CupsFiltersComponent,
  ],
  exports: [TableControllerComponent, ImportTableComponent],
})
export class TableModule {}
