import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
// Servicio propios
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Componentes
import { MapModalComponent } from "../../../../modules/map-module/map-modal/map-modal.component";
// Intefaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Variable Jquery
declare var $: any;
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-cesium-map-tooltip",
  templateUrl: "./cesium-map-tooltip.component.html",
  styleUrls: ["./cesium-map-tooltip.component.scss"],
})
export class CesiumMapTooltipComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;
  currentAgrupation: Agrupation;

  // Mapa
  @Input() mapType: string;
  @Input()
  get device(): any {
    return this._device;
  }
  set device(device: any) {
    this._device = device;
    if (this._device?.acEntity) {
      this.tooltip = this.sanitizer.bypassSecurityTrustHtml(
        this._device.acEntity.tooltip
      );
      setTimeout(() => this.bindTooltipActions(), 0);
    } else {
      this.tooltip = null;
    }
  }
  _device: any;
  tooltip: SafeHtml;

  // Centrado en gateway
  @Output() goToGatewayFlag = new EventEmitter<any>();

  // Acción
  @Output() action = new EventEmitter<any>();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private MaterialDialogService: MaterialDialogService,
    private SessionDataService: SessionDataService,
    private sanitizer: DomSanitizer
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de variables de sesión
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    $(".findGateway")?.off("click");
    $(".photoModal")?.off("click");
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Bindeo de acciones de tooltip
  bindTooltipActions(): void {
    // Opción de ir al gateway enlazado con el contador
    if (this.sessionProfile == PROFILES.ARSON && this.mapType != "coverage") {
      $(".findGateway")
        .off("click")
        .on("click", () => {
          this.goToGatewayFlag.emit(this._device);
        });
    }
    // Llamada al modal con la foto del contador
    $(".photoModal")
      .off("click")
      .on("click", () => {
        this.photoModal(this._device.acEntity.id, this._device.acEntity.type);
      });
    // Filtrado de cobertura por gateway
    $(".filterGateway")
      .off("click")
      .on("click", () => {
        this.action.emit({
          action: "filterGateway",
          data: this._device.acEntity,
        });
      });
    // Filtrado de cobertura por gateway
    $(".removeTest")
      .off("click")
      .on("click", () => {
        this.action.emit({
          action: "removeTest",
          data: this._device.acEntity,
        });
      });
  }

  // Obtención de la imagen del modal de contadores y gateways
  photoModal(id: any, type: string): void {
    this.HomeController.getElementImg(
      id,
      this.currentAgrupation.id,
      type == "device" ? "meter" : type
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.MaterialDialogService.openDialog(MapModalComponent, {
          show: "photo",
          deviceType: type == "device" ? "meter" : type,
          elementPhoto: response["body"],
        });
      }
    });
  }
}
