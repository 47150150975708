// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  HostListener,
  ViewChild,
  NgZone,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
// Moment
import * as moment from "moment";
import * as moment_timezone from "moment-timezone";
// Servicios propios
import { SessionDataService } from "./services/shared/SessionDataService.service";
import { TokenCheckService } from "./services/shared/TokenCheckService.service";
import { BrowserStorageLocalService } from "./services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { ToastService } from "./services/shared/ToastService.service";
import { ThemeService } from "./themes/theme.service";
import { LoginControllerService } from "./services/server/LoginController.service";
import { UserControllerService } from "./services/server/UserController.service";
import { RedirectToService } from "./services/shared/RedirectToService.service";
import { CheckForUpdateService } from "./services/shared/CheckForUpdateService.service";
import { MainPanelComponent } from "./modules/panel-module/main-panel/main-panel.component";
import { CustomUrlService } from "./services/shared/CustomUrl.service";
// Interfaces
import { SessionData } from "./interfaces/SessionGlobalInterface.type";
// Versión
import packageJson from "../../package.json";
// Variables
import { PROFILES } from "../assets/profiles/profiles";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  userLogged: boolean;
  startUrl: string;
  profileStartUrl: string;
  maintenance: boolean = false;
  maintenananceSub: Subscription;

  // Stream de datos de sesión
  userSub: Subscription;
  profileSub: Subscription;
  languageSub: Subscription;
  sessionLoadingSub: Subscription;
  routerEventsStart: Subscription;
  routerEventsNewTab: Subscription;
  routerEventsEnd: Subscription;

  // Datos de sesión
  sessionProfile: string;
  sessionData: SessionData =
    this.BrowserStorageLocalService.getJsonValue("session");
  loadSession: boolean;
  appVersion: { module: string; version: string }[];
  frontVersion = {
    module: "Web Front",
    version: packageJson.version,
  };
  helpActiveSub: Subscription;
  helpActive: boolean = false;

  // Body
  body = document.body;

  // Guardado del tiempo de sesión restante al cerrar pestaña o navegador
  @HostListener("window:beforeunload", ["$event"])
  beforeUnloadHandler() {
    let updatedSessionData =
      this.BrowserStorageLocalService.getJsonValue("session");
    updatedSessionData["sessionNotFinished"] = true;
    this.updateSessionVariable(updatedSessionData);
    this.BrowserStorageLocalService.setJsonValue("session", updatedSessionData);
  }

  // Comunicación entre pestañas del navegador
  channel4Broadcast = new BroadcastChannel("arsonChannel");
  @ViewChild(MainPanelComponent) mainPanel: MainPanelComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CheckForUpdateService: CheckForUpdateService,
    private CustomUrlService: CustomUrlService,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private LoginController: LoginControllerService,
    private RedirectToService: RedirectToService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private theme: ThemeService,
    private ToastService: ToastService,
    private TokenCheckService: TokenCheckService,
    private translate: TranslateService,
    private UserController: UserControllerService,
    private zone: NgZone
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit() {
    // Actualización del título y favicon dependiendo de la url
    this.CustomUrlService.checkCustomUrl();

    // Captura de la url inicial por si se trata de una recarga o pestaña nueva
    this.startUrl = window.location.href.substring(
      window.location.href.indexOf("#") + 1
    );

    // Chequeo del token guardado
    if (this.sessionData) {
      this.checkToken();
    } else {
      this.userLogged = false;
      this.loadSession = false;
    }

    // Mantenimiento
    this.maintenananceSub = this.SessionDataService.getMaintenance().subscribe(
      (maintenance) => {
        this.logout();
        this.maintenance = maintenance;
      }
    );

    // Suscripciones al stream de datos
    this.userSub = this.SessionDataService.getUser().subscribe((user) => {
      // Carga de estilos
      this.theme.loadThemeConfig(user);
    });

    this.profileSub = this.SessionDataService.getProfile().subscribe(
      (profile) => {
        this.sessionProfile = profile;
        // Comprobación de versiones
        if (this.sessionProfile == PROFILES.ARSON) {
          this.getBackVersion();
        } else {
          this.appVersion = [this.frontVersion];
        }
      }
    );

    // Escucha de carga de sesión
    this.SessionDataService.getSessionLoadingFlag().subscribe(
      (sessionLoadingFlag) => {
        this.loadSession = sessionLoadingFlag;
      }
    );

    // Escucha de sesión caducada
    this.SessionDataService.getSessionExpiredFlag().subscribe(() => {
      if (this.userLogged) {
        this.sessionExpired();
      }
    });

    // Http Error 401
    this.SessionDataService.getHttpError401Flag().subscribe(() => {
      this.checkTokenForHttpError401();
    });

    // Cierre de sesión
    this.SessionDataService.getLogoutFlag().subscribe(() => {
      this.logout();
    });

    // Escucha de cambio de idioma
    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.translate.use(language);
        moment_timezone.locale(language);
        moment.locale(language);
      }
    );

    // Escucha de activación de ayuda
    this.helpActiveSub = this.SessionDataService.getHelpActive().subscribe(
      (helpActive) => {
        this.helpActive = helpActive;
      }
    );

    // Escucha de los cambios de url al iniciar la navegación
    this.routerEventsStart = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.SessionDataService.sendCesiumData({ active: false });
      });

    // Comprobación de nueva pestaña
    this.routerEventsNewTab = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        let newTabData = this.SessionDataService.getCurrentNewTab();
        if (newTabData) {
          this.RedirectToService.openNewTab(event.url, newTabData);
        }
      });

    // Escucha de los cambios de url al finalizar la navegación
    this.routerEventsEnd = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Actualización de los datos de sesión al cambiar de url
        this.updateSessionData(event);
      });

    // Escucha de mensajes en Service Worker
    this.SessionDataService.sendChannel4Broadcast(this.channel4Broadcast);
    this.channel4Broadcast.onmessage = (event) => {
      this.processMessage(event.data);
    };

    // Timeout de sesión
    this.TokenCheckService.tokenTimeout();

    // Chequeo de versión
    setTimeout(() => this.CheckForUpdateService.startChecking(), 31000);
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.profileSub.unsubscribe();
    this.languageSub.unsubscribe();
    this.helpActiveSub.unsubscribe();
    this.channel4Broadcast.close();
    this.maintenananceSub.unsubscribe();
    this.routerEventsStart.unsubscribe();
    this.routerEventsNewTab.unsubscribe();
    this.routerEventsEnd.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Logueo con éxito
  loginSucceed(startUrl: string): void {
    this.userLogged = true;
    this.SessionDataService.sendUserLogged(this.userLogged);
    this.startUrl = startUrl;
    this.profileStartUrl = startUrl;
    if (this.sessionProfile == PROFILES.ARSON) {
      this.UserController.updateRestrictions(true).subscribe();
    }
  }

  // Salir
  logout(): void {
    let userConfig = this.BrowserStorageLocalService.getJsonValue("config");
    localStorage.clear();
    sessionStorage.clear();
    this.BrowserStorageLocalService.setJsonValue("config", userConfig);
    this.userLogged = false;
    this.loadSession = false;
    this.SessionDataService.sendUserLogged(this.userLogged);
    this.router.navigate(["/"]);
  }

  // Comprobación del token de usuario activo
  checkToken(): void {
    this.TokenCheckService.checkAlive().subscribe((response) => {
      let alive = response["body"]?.tokenValid;
      this.SessionDataService.sendOriginalProfile(
        response["body"]?.isArson ? PROFILES.ARSON : null
      );
      if (alive) {
        this.loadSession = true;
        this.SessionDataService.sendSessionLoadingFlag(true);
        this.userLogged = true;
        this.SessionDataService.sendUserLogged(this.userLogged);
        this.SessionDataService.sendProfile(this.sessionData.profile);
        this.SessionDataService.sendTelemetryActive(this.sessionData.isTlm);
        this.SessionDataService.sendUser(this.sessionData.user);
        this.SessionDataService.sendLanguage(this.sessionData.language);
        this.SessionDataService.sendUserId(this.sessionData.userId);
        if (this.sessionData.profile == PROFILES.ARSON) {
          this.UserController.updateRestrictions(true).subscribe();
        }
      } else {
        this.userLogged = false;
        this.loadSession = false;
      }
    });
  }

  // Comprobación de token tras error http 401 (No autorizado)
  checkTokenForHttpError401(): void {
    this.TokenCheckService.checkAlive().subscribe((response) => {
      if (!response["body"]?.tokenValid) {
        this.sessionExpired();
      }
    });
  }

  // Actualización de la variable sesión de localStorage al cambiar de ruta
  updateSessionData(event: NavigationEnd): void {
    if (
      this.userLogged &&
      this.SessionDataService.getCurrentUser() &&
      !this.loadSession
    ) {
      let updatedSessionData =
        this.BrowserStorageLocalService.getJsonValue("session");
      if (updatedSessionData) {
        this.updateSessionVariable(updatedSessionData, event);
        this.BrowserStorageLocalService.setJsonValue(
          "session",
          updatedSessionData
        );
      }
    }

    // Vuelve a activar las barras de scroll en la interfaz de abonado
    if (this.sessionProfile == PROFILES.ABONADO) {
      setTimeout(() => (this.body.style.overflow = "visible"), 500);
    }
  }

  // Sesión caducad
  sessionExpired(): void {
    this.SessionDataService.sendNotificationLoop(false);
    this.ToastService.fireToastWithConfirmation(
      "warning",
      this.translate.instant("session-expired"),
      this.translate.instant("renew-session"),
      this.translate.instant("cancel")
    ).then((renewSession) => {
      if (renewSession) {
        this.ToastService.firePasswordInput().then((password: string) => {
          if (password) {
            this.TokenCheckService.renewSession(password);
            this.SessionDataService.sendNotificationLoop(true);
          } else {
            this.logout();
          }
        });
      } else {
        this.logout();
      }
    });
  }

  // Actualización de datos de variable de sesión
  updateSessionVariable(sessionData: any, event?: NavigationEnd): void {
    sessionData["user"] = this.SessionDataService.getCurrentUser();
    sessionData["profile"] = this.SessionDataService.getCurrentProfile();
    sessionData["isTlm"] = this.SessionDataService.getCurrentTelemetryActive();
    sessionData["language"] = this.SessionDataService.getCurrentLanguage();
    sessionData["numberFormat"] =
      this.SessionDataService.getCurrentNumberFormat();
    sessionData["dateFormat"] = this.SessionDataService.getCurrentDateFormat();
    sessionData["current"] = {};
    sessionData["current"]["client"] =
      this.SessionDataService.getCurrentClient();
    sessionData["current"]["agrupation"] =
      this.SessionDataService.getCurrentAgrupation();
    sessionData["current"]["entity"] =
      this.SessionDataService.getCurrentEntity();
    sessionData["urlData"] = history.state.data;
    sessionData["url"] = event ? event.url : this.router.url;
    sessionData["profileStartUrl"] = this.profileStartUrl;
  }

  // Obtención de las versiones
  getBackVersion(): void {
    this.LoginController.getVersions().subscribe((response) => {
      let appVersion = [
        this.frontVersion,
        {
          module: "Web",
          version: response["body"]?.web
            ? response["body"]?.web
            : this.translate.instant("unknown"),
        },
        {
          module: "Data Processor Web",
          version: response["body"]?.dataProcessorWeb
            ? response["body"]?.dataProcessorWeb
            : this.translate.instant("unknown"),
        },
        {
          module: "Communication Web",
          version: response["body"]?.communicationWeb
            ? response["body"]?.communicationWeb
            : this.translate.instant("unknown"),
        },
        {
          module: "Data Processor 1",
          version: response["body"]?.dataProcessor1
            ? response["body"]?.dataProcessor1
            : this.translate.instant("unknown"),
        },
        {
          module: "Data Processor 2",
          version: response["body"]?.dataProcessor2
            ? response["body"]?.dataProcessor2
            : this.translate.instant("unknown"),
        },
        {
          module: "File Manager",
          version: response["body"]?.gestorFicheros
            ? response["body"]?.gestorFicheros
            : this.translate.instant("unknown"),
        },
        {
          module: "Communication",
          version: response["body"]?.communicationGestiorFicheros
            ? response["body"]?.communicationGestiorFicheros
            : this.translate.instant("unknown"),
        },
      ];
      this.appVersion = appVersion;
    });
  }

  // Procesamiento del mensaje
  processMessage(message: { action: string; data?: any }): void {
    // Ejemplo de envío de mensaje
    // this.SessionDataService
    // .getCurrentChannel4Broadcast()
    // ?.postMessage({ action: "goTo", data: "/principal" });

    switch (message.action) {
      case "reloadPanel":
        this.zone.run(() => this.mainPanel.reloadPanel());
        break;
      case "reloadTab":
        window.location.reload();
        break;
      case "goTo":
        this.router.navigate([message.data]);
        break;
      default:
        break;
    }
  }
}
