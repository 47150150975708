// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { ImportColumn } from "../../../../../modules/table-module/TableInterface.type";
import { AssignedDevice } from "../../DeviceInterface.type";
// Variables
import { METER_DEACTIVATE_COLUMNS } from "./import-columns";

@Component({
  selector: "app-device-deactivate",
  templateUrl: "./device-deactivate.component.html",
  styleUrls: ["./device-deactivate.component.scss"],
})
export class DeviceDeactivateComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Importación
  importColumns: ImportColumn[];
  file: any;
  fileData: string[];
  fileFilteredData: any[];
  devicesNotFound: string[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getImportColumns();
  }

  // Obtención de las columnas a importar
  getImportColumns(): void {
    let importColumns: ImportColumn[] = JSON.parse(
      JSON.stringify(METER_DEACTIVATE_COLUMNS)
    );
    importColumns.map((column: ImportColumn) => {
      column.profile =
        column.profilesList.length == 0
          ? true
          : column.profilesList.includes(this.sessionProfile);
      column.info = this.translate.instant(column.info);
      if (column.extraInfo) {
        column.extraInfo = this.translate.instant(column.extraInfo);
      }
    });
    this.importColumns = importColumns;
  }

  // Lectura de archivo
  readFile(file: any): void {
    this.fileFilteredData = null;

    if (!file) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      let reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.file = reader.result;
          this.getFileData();
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    }
  }

  // Obtención de las filas del archivo de filtrado
  getFileData(): void {
    let filterRows = this.file.split("\n");
    filterRows = filterRows.map((row: string) => {
      return row.split(";").map((element: any) => element.replace("\r", ""));
    });
    let fileColumns = [...filterRows[0]];
    let nroSerieColumn = fileColumns.findIndex(
      (column) => column.toUpperCase() == "NRO_SERIE"
    );
    this.fileData = [
      ...filterRows.slice(1).map((data) => data[nroSerieColumn]),
    ];
    this.getDevices();
  }

  // Obtención de los dispositivos a desactivar
  getDevices(): void {
    this.MeterController.table(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let devices: AssignedDevice[] = response["body"]["meterList"];
          let fileDataFiltered: any[] = [];
          let devicesNotFound: string[] = [];

          // Comprobación de si los dispositivos pertenecen a la agrupación
          this.fileData.forEach((deviceNroSerie: string) => {
            let deviceFound = devices.find(
              (device: AssignedDevice) => device.nroSerie == deviceNroSerie
            );
            if (deviceFound) {
              fileDataFiltered.push({
                id: deviceFound.id,
                nroSerie: deviceFound.nroSerie,
                metrologyType: deviceFound.metrologyType,
              });
            } else {
              devicesNotFound.push(deviceNroSerie);
            }
          });

          // Dispositivos no encontrados en la agrupación
          if (devicesNotFound.length > 0) {
            if (fileDataFiltered.length > 0) {
              this.ToastService.fireToastWithConfirmation(
                "warning",
                this.translate.instant("readings-device-error") +
                  ", " +
                  this.translate.instant("deactivation-not-found") +
                  " " +
                  this.currentAgrupation.name
              );
            } else {
              this.ToastService.fireToastWithConfirmation(
                "warning",
                this.translate.instant("readings-device-error")
              );
            }
            this.devicesNotFound = devicesNotFound;
          }

          this.fileFilteredData = fileDataFiltered;
        }
      }
    );
  }

  // Desactivación
  deactivateFile(): void {
    if (this.fileFilteredData.length > 0) {
      this.ToastService
        .fireAlertWithOptions(
          "question",
          this.translate.instant("device-question-desactivate-file")
        )
        .then((userConfirmation: boolean) => {
          if (userConfirmation) {
            this.requestQueue.setTask("deactivate", this.fileFilteredData);
          }
        });
    }
  }
}
