<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Título -->
        <h5>
          <b
            >{{ "alarms-template" | translate }}: {{ currentEntity?.entity }}</b
          >
        </h5>
        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <app-alarms-list
        [devicesList]="devicesList"
        [devicesNotification]="devicesNotification"
        [alarmsType]="'entity'"
      >
      </app-alarms-list>
    </div>
  </div>
</section>
