// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Turf
import * as Turf from "@turf/turf";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import { MapDevice } from "../../../../../interfaces/DeviceGlobalInterface.type";
// Componentes
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";
import { GraphControllerComponent } from "../../../../../modules/graph-module/graph-controller/graph-controller.component";

@Component({
  selector: "app-sensory-flow-map",
  templateUrl: "./sensory-flow-map.component.html",
  styleUrls: ["./sensory-flow-map.component.scss"],
})
export class SensoryFlowMapComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Mapa
  mapType: string = "flowDetection";
  mapData: MapDevice[];
  mapHeight: number = window.innerHeight - 270;

  // Gráfica
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  from: string;
  to: string;
  graphSeries: any;
  graphData: any;

  // Actualizar
  playIcon: string = "fas fa-play";
  playTitle: string = this.translate.instant("show-evolution");
  pauseIcon: string = "fas fa-stop";
  pauseTitle: string = this.translate.instant("show-evolution-stop");
  resetIcon: string = "fas fa-undo-alt";
  resetTitle: string = this.translate.instant("reset");
  dataLoaded: boolean = false;
  dataLoading: boolean = true;
  @ViewChild("sensorMap") MapController: MapControllerComponent;
  @ViewChild("sensorGraph") GraphController: GraphControllerComponent;
  evolutionPlaying: boolean = false;
  evolutionMapInterval: any;
  evolutionIndex: number = 0;
  evolutionRate: number = 4;
  evolutionMaxTimestamp: number;
  evolutionMaxData: number;
  rateOptions: number[] = [1, 2, 3, 4];
  evolutionBarMax: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private DataAnalysisController: DataAnalysisControllerService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private GraphOptionsService: GraphOptionsService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
    this.setHighchartsOptions();
  }

  // Obtención de datos
  getData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.mapData = [];
          let mapData = this.MapDeviceMinimalParse.parseDevices(
            response["body"]?.contadores
          );
          mapData.forEach((meter: any) => {
            if (
              !this.mapData.some(
                (mapMeter) =>
                  mapMeter.longitude == meter.longitude &&
                  mapMeter.latitude == meter.latitude
              )
            ) {
              if (this.checkMeter(meter)) {
                this.mapData.push(meter);
              }
            }
          });
        }
      }
    );
  }

  checkMeter(meter: any): boolean {
    return !this.mapData.some(
      (mapMeter) =>
        Turf.distance(
          Turf.point([
            parseFloat(mapMeter.longitude),
            parseFloat(mapMeter.latitude),
          ]),
          Turf.point([meter.longitude, meter.latitude]),
          {
            units: "kilometers",
          }
        ) <=
        mapMeter.distanciaAcustica / 100
    );
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    const self = this;
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("flow-map")
      );
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.dataLoading = true;
    this.dataLoaded = false;
    this.from = from;
    this.to = to;
    let graphData = [];
    this.DataAnalysisController.getGraphGroup(1, {
      meterList: this.mapData.map((meter) => meter.id),
      agrupation: this.currentAgrupation.id,
      fromTimestamp: parseInt(from),
      toTimestamp: parseInt(to),
      graphType: 2,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        graphData = response["body"];
      }
      this.graphData = graphData;
      this.evolutionMaxTimestamp = Math.max(
        ...this.graphData
          .map((meter) => meter.readings)
          .map((readings) => readings.length)
      );
      this.evolutionMaxData = Math.max(
        ...this.graphData
          .map((meter) => meter.readings)
          .map((readings) => Math.max(...readings.map((reading) => reading[1])))
      );
      this.evolutionBarMax = this.evolutionMaxTimestamp;
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    const self = this;
    let graphSeries = [];
    this.graphData.forEach((sensor) => {
      graphSeries.push({
        id: sensor.id,
        name: sensor.nroSerie,
        type: "line",
        data: sensor.readings,
        dataGrouping: { approximation: "sum" },
        tooltip: {
          valueSuffix: " m³",
          valueDecimals: 3,
        },
      });
    });
    this.graphSeries = graphSeries;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    const self = this;
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] = "{value}" + " m³";
    chartOptions["yAxis"][0]["title"]["text"] = "";
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;

    setTimeout(() => {
      this.dataLoading = false;
      this.dataLoaded = true;
      this.updateMapData();
      this.updateGraphHover();
    });
  }

  // Actualización de datos del mapa
  updateMapData(): void {
    this.mapData.map((meter) => {
      let meterReadings = this.graphData.find(
        (meterReadings) => meterReadings.meterId == meter.id
      )?.readings;
      if (meterReadings && meterReadings[this.evolutionIndex]) {
        meter.flow = meterReadings[this.evolutionIndex][1];
        meter.flowPercentage = (
          (meterReadings[this.evolutionIndex][1] * 100) /
          this.evolutionMaxData
        ).toFixed(0);
      } else {
        meter.flow = null;
        meter.flowPercentage = 0;
      }
    });
    this.MapController.resetLayers();
  }

  // Actualización de hover de gráfica
  updateGraphHover(): void {
    this.GraphController.hoverIndex(this.evolutionIndex);
  }

  // Evolución de consumo
  showEvolution(): void {
    clearInterval(this.evolutionMapInterval);
    this.evolutionPlaying = true;
    this.evolutionMapInterval = setInterval(() => {
      this.evolutionIndex++;
      if (this.evolutionIndex < this.evolutionBarMax) {
        this.updateMapData();
        this.updateGraphHover();
      } else {
        this.stopEvolution();
      }
    }, 1000 / this.evolutionRate);
  }

  // Actualización de velocidad de evolución
  updateEvolutionRate(): void {
    if (this.evolutionPlaying) {
      this.showEvolution();
    }
  }

  // Parar evolución de consumo
  stopEvolution(): void {
    this.evolutionPlaying = false;
    clearInterval(this.evolutionMapInterval);
  }

  // Actualización de la barra de evolución al hacer zoom
  updateEvolutionBar(xAxis: any): void {
    let xAxisMin = Math.trunc(xAxis.min / 3600000) * 3600000;
    let xAxisMax = Math.trunc(xAxis.max / 3600000) * 3600000;
    this.evolutionBarMax =
      (xAxisMax - xAxis.dataMin) / 3600000 -
      (xAxisMin - xAxis.dataMin) / 3600000;
  }
}
