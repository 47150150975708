import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "app-navbar-top-dropdown",
  templateUrl: "./navbar-top-dropdown.component.html",
  styleUrls: ["./navbar-top-dropdown.component.scss"],
})
export class NavbarTopDropdownComponent implements OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() classes: string;
  @Input() selectorClasses: string;
  @Input() title: string;
  @Input() type: string;
  @Input() listLink: string;
  @Input() newLink: string;
  @Input() currentElement: any;
  @Input() elementList: any[];
  @Input() attributeShowed: string;
  @Input() allowList: boolean;
  @Input() allowNew: boolean;
  @Input() highlight: string;
  closeTimeout: ReturnType<typeof setTimeout>;
  openTimeout: ReturnType<typeof setTimeout>;
  dropdownOpenDelay: number = 500;
  dropdownCloseDelay: number = 500;

  @Output() dropdownFilterFlag = new EventEmitter<any>();
  @Output() updateCurrentElementFlag = new EventEmitter<any>();
  @Output() resetSearchBoxFlag = new EventEmitter<any>();
  @Output() updateSearchActiveFlag = new EventEmitter<string>();

  @ViewChild("elementFilter") elementFilter: ElementRef;
  @ViewChild("navbarTopDropdown") navbarTopDropdown: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor() {}

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearTimeout(this.openTimeout);
    clearTimeout(this.closeTimeout);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Flag de filtrado
  dropdownFilter(filterValue: any, type: string): void {
    clearTimeout(this.closeTimeout);
    this.dropdownFilterFlag.emit({
      filterValue: filterValue,
      type: type,
    });
  }

  // Flag de actualización de elemento en curso
  updateCurrentElement(element: any): void {
    this.updateCurrentElementFlag.emit(element);
  }

  // Flag de reset de caja de búsqueda
  resetSearchBox(elementFilter: any, type: string): void {
    if (elementFilter) {
      elementFilter.nativeElement.value = "";
    }
    this.resetSearchBoxFlag.emit(type);
  }

  // Focus en caja de búsqueda
  focusInput(elementFilter: any, type: string) {
    elementFilter.nativeElement.focus();
  }

  // Visibilidad de menú
  toggleVisibility(visible: boolean): void {
    if (visible) {
      clearTimeout(this.closeTimeout);
      this.openTimeout = setTimeout(
        () =>
          this.navbarTopDropdown.nativeElement.classList.add(
            "navbar-top-dropdown-container-hover"
          ),
        this.dropdownOpenDelay
      );
    } else {
      clearTimeout(this.openTimeout);
      this.closeTimeout = setTimeout(
        () =>
          this.navbarTopDropdown.nativeElement.classList.remove(
            "navbar-top-dropdown-container-hover"
          ),
        this.dropdownCloseDelay
      );
    }
  }
}
