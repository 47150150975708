// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  AlarmUpdateData,
  AlarmsUpdate,
  AlertsUpdate,
  CustomAlarm,
  DeviceAlarmUpdate,
} from "../../screens/dashboard/alarms/AlarmInterface.type";

@Injectable({
  providedIn: "root",
})
export class AlarmControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Actualizar el estado de una alarma
  update(data: AlarmUpdateData): Observable<object> {
    return this.HttpRequestService.post("/alarms/update-state/", data);
  }

  // Actualizar el estado de varias alarmas
  updateMultiple(data: AlarmUpdateData[]): Observable<object> {
    return this.HttpRequestService.post("/alarms/list/update-state/", data);
  }

  // Listado de la configuración de alarmas de la agrupación
  getAlarms(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms-conf/agrupation/list/" + agrupationId
    );
  }

  // Listado de la configuración de alarmas del CUPS
  getCUPSAlarms(cupsId: number): Observable<object> {
    return this.HttpRequestService.get("/alert-conf/cm/list/" + cupsId);
  }

  // Listado de la configuración disponible
  getAlarmsEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/alert-conf/list/" + entityId);
  }

  // Listado de dispositivos que tienen una configuración aplicada en la agrupación
  getConfigured(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms-conf/contador/configured-devices/" + agrupationId
    );
  }

  // Actualizar configuración de alarmas de la agrupación
  updateAlarms(data: AlarmsUpdate): Observable<object> {
    return this.HttpRequestService.post("/alarms-conf/agrupation/update", data);
  }

  // Guardar la configuración de alertas de la entidad
  updateEntityAlarms(data: AlertsUpdate): Observable<object> {
    return this.HttpRequestService.post("/alert-conf/save", data);
  }

  updateCUPSAlarms(data: AlertsUpdate): Observable<object> {
    return this.HttpRequestService.post("/alert-conf/cm/save", data);
  }

  // Guardar la configuración de alarmas de un CUPS
  updateAlarmsMeter(data: DeviceAlarmUpdate): Observable<object> {
    return this.HttpRequestService.post("/alarms-conf/contador/update", data);
  }

  // Deshabilitar una alarma
  disableAlarms(alarmId: number): Observable<object> {
    return this.HttpRequestService.get("/alarms/disable/" + alarmId);
  }

  // Habilitar una alarma deshabilitada
  enableAlarms(alarmId: number): Observable<object> {
    return this.HttpRequestService.get("/alarms/enable/" + alarmId);
  }

  // Configuración de alarmas actual para pre-cargar el formulario de actualización
  getMeterAlarms(deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms-conf/contador/show/" + deviceId
    );
  }

  // Listado de las alarmas de una agrupación
  getAlarmsFromTo(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms/agrupation/" + agrupationId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de las alarmas activas y habilitadas de una agrupación
  getActiveEnabledAlarmsFromTo(
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms/active-enable/agrupation/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Listado de alarmas de un dispositivo
  getMeterAlarmsFromTo(
    deviceId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms/contador/" + deviceId + "/from/" + from + "/to/" + to
    );
  }

  // Listado global de alarmas
  getGlobalAlarmsFromTo(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alarms/global-list/from/" + from + "/to/" + to
    );
  }

  // Listado global de sospechas
  getGlobalWarnings(): Observable<object> {
    return this.HttpRequestService.get("/arsonWarnings/getAll");
  }

  // Plantilla de alarmas de entidad
  getEntityAlarmsTemplate(enitityId: number): Observable<object> {
    return this.HttpRequestService.get("/alarms-conf/template/" + enitityId);
  }

  // Guardar la configuración de alarmas de un CUPS
  updateEntityAlarmsTemplate(data: AlarmsUpdate): Observable<object> {
    return this.HttpRequestService.post("/alarms-conf/template/update", data);
  }

  // Guardar la configuración de alarmas de un CUPS
  partialUpdateEntityAlarmsTemplate(data: AlarmsUpdate): Observable<object> {
    return this.HttpRequestService.post(
      "/alarms-conf/template/partial-update",
      data
    );
  }

  // Guardar la configuración de alarmas de un CUPS
  applyEntityAlarmsTemplate(
    data: number[],
    enitityId: number
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/alarms-conf/template/apply/" + enitityId,
      data
    );
  }

  // Alarmas personalizadas
  getCustomAlarms(): Observable<object> {
    return this.HttpRequestService.get("/custom-alarm/list");
  }

  // Actualizar alarmas personalizadas
  updateCustomAlarms(data: CustomAlarm[]): Observable<object> {
    return this.HttpRequestService.post("/custom-alarm/save", data);
  }

  // Borrrar alarmas personalizadas
  deleteCustomAlarms(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/custom-alarm/delete", data);
  }
}
