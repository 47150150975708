<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b
            >{{ "gateway-load" | translate }}:
            {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
          >
        </h5>

        <!-- Botón reset -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="resetIcon"
          [title]="resetTitle"
          (action)="resetLoad()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Opciones de rango -->
        <div class="gateway-load-options">
          <app-material-select
            [fullWidth]="true"
            [title]="'initial-option' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="0"
            [data]="initialOptionSelect"
            (selectedOption)="
              initialOptionSelected = $event?.value; initialOptionChange()
            "
          ></app-material-select>
          <app-material-select
            [fullWidth]="true"
            [title]="'final-option' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="2"
            [data]="finalOptionSelect"
            (selectedOption)="finalOptionSelected = $event?.value"
          ></app-material-select>

          <button
            mat-button
            class="mat-button-icon-text"
            (click)="loadGraphData()"
          >
            <i class="fas fa-search"></i>
            {{ "search" | translate }}
          </button>
        </div>

        <div class="gateway-load-info">
          <div *ngIf="gatewayCharge">
            <table>
              <tr class="table-bg-blue-gradient">
                <th colspan="2">{{ "acumulate-load" | translate }}</th>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "acumulate-load" | translate }}
                </td>
                <td>{{ gatewayCharge.acumulatedCharge }} mA</td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "load-minutes" | translate }}
                </td>
                <td>
                  {{ gatewayCharge.lightMinutes }}
                </td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "initial-load" | translate }}
                </td>
                <td>
                  {{ gatewayCharge.initChargerDay }}/{{
                    gatewayCharge.initChargerMonth
                  }}/{{ gatewayCharge.initChargerYear }}
                </td>
              </tr>
            </table>
          </div>
          <div *ngIf="gatewayState">
            <table>
              <tr class="table-bg-blue-gradient">
                <th colspan="2">{{ "load-state" | translate }}</th>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "load-state" | translate }}
                </td>
                <td>
                  {{ gatewayState.chargeStateParsed }} ({{
                    gatewayState.chargerState
                  }})
                </td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "panel-tension" | translate }}
                </td>
                <td>{{ gatewayState.tensionPanel }} V</td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "battery-tension" | translate }}
                </td>
                <td>{{ gatewayState.tensionBateria }} V</td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "converter-state" | translate }}
                </td>
                <td>{{ gatewayState.tensionConvertidor }} V</td>
              </tr>
              <tr>
                <td class="gateway-load-info-title">
                  {{ "load-charger" | translate }}
                </td>
                <td>{{ gatewayState.corrienteCarga }} mAh</td>
              </tr>
            </table>
          </div>
        </div>

        <div
          class="gateway-load-graph"
          [ngClass]="
            graphData ? 'gateway-load-graph-show' : 'gateway-load-graph-hide'
          "
        >
          <div>
            <!-- Gráfica -->
            <app-graph-controller
              *ngIf="highchartsOptions"
              [onlyGraph]="true"
              [columns]="true"
              [highchartsOptions]="highchartsOptions"
              [data]="chartOptions"
              [chartConstructor]="chartConstructor"
            >
            </app-graph-controller>
          </div>

          <ul>
            <li>
              {{ "temperature" | translate }}:
              <b>{{ gatewayWeekData?.temperatura }} °C</b>
            </li>
            <li>
              {{ "load-intensity" | translate }}:
              <b>{{ gatewayWeekData?.intensityAcumulated }} mA</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
