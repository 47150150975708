<div class="home-card">
  <!-- ANCHOR Información de la tarjeta -->
  <div>
    <div class="home-card-title">
      <span class="home-card-total"
        >{{ formatNumber(homeCard.total, numberFormat) }}
      </span>
      <div
        class="home-card-device-type"
        [ngClass]="{
          'home-card-device-type-small':
            homeCard.deviceType == 'externalWithoutCom'
        }"
      >
        {{
          (homeCard.deviceType == "externalWithoutCom"
            ? "no-lora-no-com"
            : homeCard.deviceType
          ) | translate
        }}
        <i [ngClass]="homeCard.icon"></i>
      </div>
    </div>
    <!-- Datos -->
    <div class="home-card-data">
      <table>
        <!-- En red -->
        <tr
          *ngIf="homeCard.com > 0"
          class="pointer"
          [ngClass]="{
            'home-card-transparency':
              highlightNoCommunication || highlightPending
          }"
          (click)="
            openLink(
              homeCard.deviceType == 'gateways'
                ? ['/gateways/listado/asignados']
                : ['/dispositivos/listado/asignados'],
              ['ACTIVE'].concat(homeCard.linkDeviceType),
              false,
              $event
            )
          "
          (contextmenu)="
            openLink(
              homeCard.deviceType == 'gateways'
                ? ['/gateways/listado/asignados']
                : ['/dispositivos/listado/asignados'],
              ['ACTIVE'].concat(homeCard.linkDeviceType),
              true,
              $event
            )
          "
        >
          <th>
            <span
              [ngClass]="{
                'home-card-gateway': homeCard.deviceType == 'gateways'
              }"
            >
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.comIcon"
              ></i> </span
            >{{ "in-network" | translate }}
          </th>
          <td>
            {{ formatNumber(homeCard.com, numberFormat)
            }}<i class="fas fa-link"></i>
            <span
              *ngIf="homeCard.deviceType != 'gateways'"
              class="home-card-map-link"
              (click)="updateMapVisibility($event, homeCard.deviceType, 'com')"
              (contextmenu)="
                updateMapVisibility($event, homeCard.deviceType, 'com', true)
              "
            >
              <i class="fas fa-map-location-dot"></i>
            </span>
          </td>
        </tr>
        <!-- Sin comunicación -->
        <tr
          *ngIf="homeCard.noCom > 0"
          class="pointer"
          [ngClass]="{
            'home-card-transparency': highlightInNetwork || highlightPending
          }"
          (click)="
            openLink(
              homeCard.deviceType == 'externalWithoutCom'
                ? ['/dispositivos/listado/asignados']
                : ['/dispositivos/listado/sin-comunicacion'],
              homeCard.linkDeviceType,
              false,
              $event
            )
          "
          (contextmenu)="
            openLink(
              homeCard.deviceType == 'externalWithoutCom'
                ? ['/dispositivos/listado/asignados']
                : ['/dispositivos/listado/sin-comunicacion'],
              homeCard.linkDeviceType,
              true,
              $event
            )
          "
        >
          <th>
            <span
              [ngClass]="{
                'home-card-gateway': homeCard.deviceType == 'gateways'
              }"
            >
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.noComIcon"
              ></i> </span
            >{{ "no-communicates" | translate }}
          </th>
          <td>
            {{ formatNumber(homeCard.noCom, numberFormat)
            }}<i class="fas fa-link"></i>
            <span
              *ngIf="homeCard.deviceType != 'gateways'"
              class="home-card-map-link"
              (click)="
                updateMapVisibility($event, homeCard.deviceType, 'noCom')
              "
              (contextmenu)="
                updateMapVisibility($event, homeCard.deviceType, 'noCom', true)
              "
            >
              <i class="fas fa-map-location-dot"></i>
            </span>
          </td>
        </tr>
        <!-- Pendientes -->
        <tr
          *ngIf="homeCard.noAssigned > 0"
          class="pointer"
          [ngClass]="{
            'home-card-transparency':
              highlightInNetwork || highlightNoCommunication
          }"
          (click)="
            openLink(
              ['/dispositivos/listado/no-asignados'],
              homeCard.linkDeviceType,
              false,
              $event
            )
          "
          (contextmenu)="
            openLink(
              ['/dispositivos/listado/no-asignados'],
              homeCard.linkDeviceType,
              true,
              $event
            )
          "
        >
          <th>
            <span>
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.noAssignedIcon"
              ></i> </span
            >{{ "pending" | translate }}
          </th>
          <td>
            {{ formatNumber(homeCard.noAssigned, numberFormat)
            }}<i class="fas fa-link"></i>
            <span
              class="home-card-map-link"
              (click)="
                updateMapVisibility($event, homeCard.deviceType, 'pending')
              "
              (contextmenu)="
                updateMapVisibility($event, homeCard.deviceType, 'pending', true)
              "
            >
              <i class="fas fa-map-location-dot"></i>
            </span>
          </td>
        </tr>
        <!-- txn1 -->
        <tr *ngIf="homeCard.txn1 > 0">
          <th>
            <span>
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.txn1Icon"
              ></i> </span
            >TXN1
          </th>
          <td>
            {{ formatNumber(homeCard.txn1, numberFormat) }}
          </td>
        </tr>
        <!-- txn4 -->
        <tr *ngIf="homeCard.txn4 > 0">
          <th>
            <span>
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.txn4Icon"
              ></i> </span
            >TXN4
          </th>
          <td>
            {{ formatNumber(homeCard.txn4, numberFormat) }}
          </td>
        </tr>
        <!-- Sin dispositivo -->
        <tr *ngIf="homeCard.noDevice > 0">
          <th>
            <span
              [ngClass]="{
                'home-card-gateway': homeCard.deviceType == 'gateways'
              }"
            >
              <i
                class="home-card-data-icon"
                [ngClass]="homeCard.noDevice.icon"
              ></i>
            </span>
            {{ "without-devices" | translate }}
          </th>
          <td>
            {{ formatNumber(homeCard.noDevice, numberFormat) }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
