// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { GatewayService } from "../../../GatewayService.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  GatewayDatalogger,
  GatewayDataloggerFilters,
  GatewayDataloggerRequest,
} from "../../../GatewayInterface.type";
import { MaterialSelectOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";

export enum DATALOG_GROUP {
  ALL = 255,
  SYSTEM = 0,
  SUPPLY = 1,
  CHARGE = 2,
  GPRS_EVENT = 3,
  GPRS_ERROR = 4,
  FILES = 5,
  LORA = 6,
}

@Component({
  selector: "app-gatewaydatalogger",
  templateUrl: "./gateway-datalogger.component.html",
  styleUrls: ["./gateway-datalogger.component.scss"],
})
export class GatewayDataLogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  readonly DATALOG_GROUP = DATALOG_GROUP;

  // Table
  tableMaxReg: number = 100;
  highlightRow: TableHighlightRow[] = [
    { condition: "highlightGroupId0", color: "red", title: "GatewayLogGroup0" },
  ];
  exporFileName: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  gatewayId: number;
  unidadVenta: string;
  gatewayDatalogger: GatewayDatalogger[] = [];
  waitForData: boolean = true;
  orderBy: object = { attribute: "timestamp", reverse: true };
  from: string;
  to: string;
  groupList: MaterialSelectOption[] = [
    { value: DATALOG_GROUP.ALL, name: this.translate.instant("all") },
    { value: DATALOG_GROUP.SYSTEM, name: this.translate.instant("system") },
    { value: DATALOG_GROUP.SUPPLY, name: this.translate.instant("supply") },
    {
      value: DATALOG_GROUP.CHARGE,
      name: this.translate.instant("charge-regulator"),
    },
    {
      value: DATALOG_GROUP.GPRS_EVENT,
      name: this.translate.instant("gprs-event"),
    },
    {
      value: DATALOG_GROUP.GPRS_ERROR,
      name: this.translate.instant("gprs-error"),
    },
    { value: DATALOG_GROUP.FILES, name: this.translate.instant("files") },
    {
      value: DATALOG_GROUP.LORA,
      name: this.translate.instant("lora-transmission"),
    },
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "group",
      data: "group",
      search: "group",
      sort: "group",
      visible: true,
    },
    {
      title: "event",
      data: "event",
      search: "event",
      sort: "event",
      visible: true,
    },
  ];

  // Condiciones
  conditions: GatewayDataloggerFilters[] = [
    {
      group: DATALOG_GROUP.ALL,
      initialEvent: 0,
      finalEvent: 255,
    },
  ];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private GatewayService: GatewayService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.setPanelMenuOptions();
    this.exporFileName =
      this.translate.instant("gateway-log-export") +
      " " +
      this.unidadVenta +
      " " +
      this.translate.instant("gateway-file") +
      " " +
      this.DateParserService.getDate();
  }

  // Borrado de datalogger
  deleteDatalogger(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("log-remove-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.GatewayController.removeDatalogger(this.gatewayId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("log-remove-sucessful")
              );
            }
          }
        );
      }
    });
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.waitForData = false;
    this.from = from;
    this.to = to;
    let data: GatewayDataloggerRequest = {
      initialDate: this.from,
      finalDate: this.to,
      filters: this.conditions,
    };

    this.GatewayController.dataloggerLog(this.gatewayId, data).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let gatewayDatalogger: GatewayDatalogger[] =
            response["body"]["readDataloggerList"];
          gatewayDatalogger.forEach((datalogger: GatewayDatalogger) => {
            datalogger.groupId != null
              ? (datalogger.group = this.translate.instant(
                  "GatewayLogGroup" + datalogger.groupId
                ))
              : "-";
            datalogger.eventId != null
              ? (datalogger.event = this.translate.instant(
                  "GatewayLogEvent" + datalogger.groupId + datalogger.eventId
                ))
              : "-";
            if (datalogger.group == "GatewayLogGroup" + datalogger.groupId) {
              datalogger.group =
                this.translate.instant("unknown") +
                " " +
                datalogger.groupId +
                datalogger.eventId;
            }
            if (
              datalogger.event ==
              "GatewayLogEvent" + datalogger.groupId + datalogger.eventId
            ) {
              datalogger.event =
                this.translate.instant("unknown") + " " + datalogger.eventId;
            }
            datalogger.highlightGroupId0 =
              datalogger.groupId == DATALOG_GROUP.ALL ? true : false;
          });
          this.gatewayDatalogger = gatewayDatalogger;
        } else {
          this.gatewayDatalogger = [];
        }
      }
    );
  }

  // Añadir condición
  addCondition(): void {
    this.conditions.push({
      group: DATALOG_GROUP.ALL,
      initialEvent: 0,
      finalEvent: 255,
    });
  }

  // Borrar condición
  deleteCondition(): void {
    this.conditions.pop();
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = this.GatewayService.getLogPanelMenu("datalogger");
    this.panelMenuOptions.push({
      action: "delete-datalogger",
      icon: "fas fa-trash",
      text: this.translate.instant("delete-log"),
      visible: true,
      highlight: true,
      bottom: true,
    });
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    if (action == "delete-datalogger") {
      this.deleteDatalogger();
    } else {
      this.GatewayService.getLogMenuAction(
        action,
        this.gatewayId,
        this.unidadVenta
      );
    }
  }
}
