import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";

export enum ATTRIBUTE_NAME {
  NRO_SERIE = "NRO_SERIE",
  MANUFACTURER = "MANUFACTURER",
  DEV_TYPE = "DEV_TYPE",
  ALIAS = "ALIAS",
  LORAWAN_VERSION = "LORAWAN_VERSION",
  DEV_EUI = "DEV_EUI",
  APP_EUI = "APP_EUI",
  NETWORK_KEY = "NETWORK_KEY",
  APPLICATION_KEY = "APPLICATION_KEY",
  TX_POWER = "TX_POWER",
  MIN_SF = "MIN_SF",
  RF_MODULE = "RF_MODULE",
  COMMENTS = "COMMENTS",
  DESCRIPTION = "DESCRIPTION",
  ADR_PERMISSION = "ADR_PERMISSION",
  ACTIVATE = "ACTIVATE",
  ENTITY = "ENTITY",
  AGRUPATION = "AGRUPATION",
  CYBLE5_ENC_KEY = "CYBLE5_ENC_KEY",
  CYBLE5_AUTH_KEY = "CYBLE5_AUTH_KEY",
  MBUS_ADDRESS = "MBUS_ADDRESS",
  MBUS_KEY = "MBUS_KEY",
  MBUS_ID = "MBUS_ID",
  CODE = "CODE",
  SIM_ICCID = "SIM_ICCID",
  LAST_READED_VALUE = "LAST_READED_VALUE",
  LAST_READED_YYYY_MM_DD = "LAST_READED_YYYY_MM_DD",
  SEAL = "SEAL",
  TIMEZONE = "TIMEZONE",
  API = "API",
  USER = "USER",
  PASSWORD = "PASSWORD",
  EXTERNAL_ID = "EXTERNAL_ID",
  VALVE_STATE = "VALVE_STATE",
  FILE_NRO_SERIE = "FILE_NRO_SERIE",
  RED = "RED",
  EXCLUDE = "EXCLUDE",
  LATITUDE = "LATITUDE",
  LONGITUDE = "LONGITUDE",
  NRO_CLAVES = "NRO_CLAVES",
  WINDOW_1 = "WINDOW_1",
  DURATION_1 = "DURATION_1",
  WINDOW_2 = "WINDOW_2",
  DURATION_2 = "DURATION_2",
  WINDOW_3 = "WINDOW_3",
  DURATION_3 = "DURATION_3",
  IMPORT_FILE_ID = "IMPORT_FILE_ID",
}

// ANCHOR Índices de atributos de dispositivo
export enum ATTRIBUTE_INDEX {
  NRO_SERIE,
  MANUFACTURER,
  DEV_TYPE,
  ALIAS,
  LORAWAN_VERSION,
  DEV_EUI,
  APP_EUI,
  NETWORK_KEY,
  APPLICATION_KEY,
  TX_POWER,
  MIN_SF,
  RF_MODULE,
  COMMENTS,
  DESCRIPTION,
  ADR_PERMISSION,
  ACTIVATE,
  ENTITY,
  AGRUPATION,
  CYBLE5_ENC_KEY,
  CYBLE5_AUTH_KEY,
  MBUS_ADDRESS,
  MBUS_KEY,
  MBUS_ID,
  CODE,
  SIM_ICCID,
  LAST_READED_VALUE,
  LAST_READED_YYYY_MM_DD,
  SEAL,
  TIMEZONE,
  API,
  USER,
  PASSWORD,
  EXTERNAL_ID,
  VALVE_STATE,
  FILE_NRO_SERIE,
  RED,
  EXCLUDE,
  IMPORT_FILE_ID,
}

// ANCHOR Versiones LoRa
export const LORA_VERSION = ["1.0.1", "1.0.2", "1.0.3", "1.0.4"];

// ANCHOR SF
export const SF_LIST = [7, 8, 9, 10, 11, 12];

// ANCHOR TX POWER
export const TX_POWER_LIST = [0, 1, 2, 3, 4, 5, 6, 7];

// ANCHOR Estados de válvula
export const VALVE_STATES = [
  { id: 0, text: "valve-close" },
  {
    id: 1,
    text: "valve-open",
  },
  // { id: 2, text: "valve-pending" },
  // { id: 3, text: "close-command" },
  // { id: 4, text: "open-command" },
  // { id: 5, text: "read-command" },
  // { id: 6, text: "valve-disconnected" },
  // { id: 7, text: "valve-no-trigger" },
  // { id: 8, text: "valve-10-sent" },
  // { id: 9, text: "valve-50-sent" },
  // { id: 10, text: "valve-open-10" },
  // { id: 11, text: "valve-open-50" },
  // { id: 12, text: "valve-command-close-lost" },
  // { id: 13, text: "valve-command-open-lost" },
];

export enum METER_TYPE {
  GAS = "GAS",
  WATER = "WATER",
}

// ANCHOR Atributos genéricos
export const DEVICE_ATTRIBUTES = [
  // 0: NRO_SERIE
  {
    name: ATTRIBUTE_NAME.NRO_SERIE,
    label: "serial-number",
    data: null,
    required: true,
    info: "nro-serie-import",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "text",
  },
  // 1: MANUFACTURER
  {
    name: ATTRIBUTE_NAME.MANUFACTURER,
    label: "manufacturer",
    data: "",
    required: true,
    info: "manufacturer",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "select",
  },
  // 2: DEV_TYPE
  {
    name: ATTRIBUTE_NAME.DEV_TYPE,
    label: "model",
    data: "",
    required: true,
    info: "dev-type-import",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "select",
  },
  // 3: ALIAS
  {
    name: ATTRIBUTE_NAME.ALIAS,
    label: "alias",
    data: null,
    required: false,
    info: "alias-import",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "text",
  },
  // 4: LORAWAN_VERSION
  {
    name: ATTRIBUTE_NAME.LORAWAN_VERSION,
    label: "lora-version",
    data: "",
    required: true,
    info: "lora-version-import",
    items: LORA_VERSION,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "select",
  },
  // 5: DEV_EUI
  {
    name: ATTRIBUTE_NAME.DEV_EUI,
    label: "DEV EUI",
    data: null,
    required: true,
    info: "dev-eui-import",
    maxlength: 16,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 6: APP_EUI
  {
    name: ATTRIBUTE_NAME.APP_EUI,
    label: "APP EUI",
    data: null,
    required: true,
    info: "app-eui-import",
    maxlength: 16,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 7: NETWORK_KEY
  {
    name: ATTRIBUTE_NAME.NETWORK_KEY,
    label: "lora-version-import",
    data: null,
    required: false,
    info: "network-import",
    maxlength: 32,
    profile: [PROFILES.ARSON],
    type: "hex",
  },
  // 8: APPLICATION_KEY
  {
    name: ATTRIBUTE_NAME.APPLICATION_KEY,
    label: "application-key-import",
    data: null,
    required: true,
    info: "application-key-import",
    maxlength: 32,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 9: TX_POWER
  {
    name: ATTRIBUTE_NAME.TX_POWER,
    label: "max-power",
    data: "",
    required: false,
    info: "tx-import",
    items: TX_POWER_LIST,
    profile: [PROFILES.ARSON],
    type: "select",
  },
  // 10: MIN_SF
  {
    name: ATTRIBUTE_NAME.MIN_SF,
    label: "sf-min",
    data: "",
    required: false,
    info: "sf-import",
    items: SF_LIST,
    profile: [PROFILES.ARSON],
    type: "select",
  },
  // 11: RF_MODULE
  {
    name: ATTRIBUTE_NAME.RF_MODULE,
    label: "rf-module",
    data: null,
    required: false,
    info: "rf-module",
    maxlength: null,
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "text",
  },
  // 12: COMMENTS
  {
    name: ATTRIBUTE_NAME.COMMENTS,
    label: "comments",
    data: null,
    required: false,
    info: "comments",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "textarea",
  },
  // 13: DESCRIPTION
  {
    name: ATTRIBUTE_NAME.DESCRIPTION,
    label: "ek-description",
    data: null,
    required: false,
    info: "ek-description",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "textarea",
  },
  // 14: ADR_PERMISSION
  {
    name: ATTRIBUTE_NAME.ADR_PERMISSION,
    label: "adr-permission",
    data: null,
    required: false,
    info: "adr-import",
    maxlength: 1,
    profile: [PROFILES.ARSON],
    type: "boolean",
  },
  // 15: ACTIVATE
  {
    name: ATTRIBUTE_NAME.ACTIVATE,
    label: "activate",
    data: null,
    required: false,
    info: "activate-import-meter",
    maxlength: 1,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "boolean",
  },
  // 16: ENTITY
  {
    name: ATTRIBUTE_NAME.ENTITY,
    label: "entity",
    data: "",
    required: false,
    info: "entity",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.TELEMEDIDA,
    ],
    type: null,
  },
  // 17: AGRUPATION
  {
    name: ATTRIBUTE_NAME.AGRUPATION,
    label: "groups",
    data: "",
    required: false,
    info: "groups",
    maxlength: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: null,
  },
  // 18: CYBLE5_ENC_KEY
  {
    name: ATTRIBUTE_NAME.CYBLE5_ENC_KEY,
    label: "encrypt-key",
    data: null,
    required: true,
    info: "encrypt-key",
    maxlength: 32,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 19: CYBLE5_AUTH_KEY
  {
    name: ATTRIBUTE_NAME.CYBLE5_AUTH_KEY,
    label: "authentication-key",
    data: null,
    required: true,
    info: "authentication-key",
    maxlength: 32,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 20: MBUS_ADDRESS
  {
    name: ATTRIBUTE_NAME.MBUS_ADDRESS,
    label: "mbus-address",
    data: null,
    required: true,
    info: "mbus-address",
    maxlength: 16,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
  // 21: MBUS_KEY
  {
    name: ATTRIBUTE_NAME.MBUS_KEY,
    label: "mbus-key",
    data: null,
    required: true,
    info: "mbus-key",
    maxlength: 32,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 22: MBUS_ID
  {
    name: ATTRIBUTE_NAME.MBUS_ID,
    label: "mbus-id",
    data: null,
    required: true,
    info: "mbus-id",
    maxlength: 50,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
  // 23: CODE
  {
    name: ATTRIBUTE_NAME.CODE,
    label: "ek-code",
    data: null,
    required: true,
    info: "ek-code",
    maxlength: 8,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "text",
  },
  // 24: SIM_ICCID
  {
    name: ATTRIBUTE_NAME.SIM_ICCID,
    label: "sim-card",
    data: "",
    required: true,
    info: "sim-card",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: null,
  },
  // 25: LAST_READED_VALUE
  {
    name: ATTRIBUTE_NAME.LAST_READED_VALUE,
    label: "last-know-value",
    data: "",
    required: false,
    info: "last-know-value",
    items: null,
    profile: [PROFILES.ARSON],
    type: "text",
  },
  // 26: LAST_READED_YYYY_MM_DD
  {
    name: ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
    label: "date-last-value",
    data: "",
    required: false,
    info: "date-last-value",
    items: null,
    profile: [PROFILES.ARSON],
    type: "text",
  },
  // 27: SEAL
  {
    name: ATTRIBUTE_NAME.SEAL,
    label: "seal",
    data: "",
    required: false,
    info: "seal",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
  // 28: TIMEZONE
  {
    name: ATTRIBUTE_NAME.TIMEZONE,
    label: "timezone",
    data: "",
    required: false,
    info: "timezone",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "select",
  },
  // 29: API
  {
    name: ATTRIBUTE_NAME.API,
    label: "API",
    data: "",
    required: true,
    info: "API",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "select",
  },
  // 30: USER
  {
    name: ATTRIBUTE_NAME.USER,
    label: "user",
    data: "",
    required: true,
    info: "user",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
  // 31: PASSWORD
  {
    name: ATTRIBUTE_NAME.PASSWORD,
    label: "password",
    data: "",
    required: true,
    info: "password",
    items: null,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
  // 32: EXTERNAL ID
  {
    name: ATTRIBUTE_NAME.EXTERNAL_ID,
    label: "external-id",
    data: null,
    required: true,
    info: "external-id",
    maxlength: 8,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "hex",
  },
  // 33: VALVE_STATE
  {
    name: ATTRIBUTE_NAME.VALVE_STATE,
    label: "valve-state",
    data: "",
    required: false,
    info: "vale-state",
    items: VALVE_STATES,
    profile: [PROFILES.ARSON],
    type: null,
  },
  // 34: FILE_NRO_SERIE
  {
    name: ATTRIBUTE_NAME.FILE_NRO_SERIE,
    label: "ek-file-serial-number",
    data: null,
    required: true,
    info: "ek-file-serial-number",
    maxlength: 16,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "text",
  },
  // 35: RED
  {
    name: ATTRIBUTE_NAME.RED,
    label: "ek-connected",
    data: null,
    required: false,
    info: "ek-connected",
    maxlength: 1,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
      PROFILES.TELEMEDIDA,
    ],
    type: "boolean",
  },
  // 36: EXCLUDE
  {
    name: ATTRIBUTE_NAME.EXCLUDE,
    label: "ek-file-exclude",
    data: null,
    required: false,
    info: "ek-file-exclude",
    maxlength: 1,
    profile: [PROFILES.ARSON],
    type: "boolean",
  },
  // 37: IMPORT_FILE_ID
  {
    name: ATTRIBUTE_NAME.IMPORT_FILE_ID,
    label: "import-file-id",
    data: null,
    required: true,
    info: "import-file-id",
    maxlength: 250,
    profile: [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ],
    type: "text",
  },
];

// ANCHOR Atributos para cada tipo de dispositivo
export const DEVICE_TYPES = [
  {
    id: DEVICE_BY_COMM.LW,
    title: "LoRaWAN",
    types: [METER_TYPE.WATER, METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.LORAWAN_VERSION,
      ATTRIBUTE_NAME.DEV_EUI,
      ATTRIBUTE_NAME.APP_EUI,
      ATTRIBUTE_NAME.NETWORK_KEY,
      ATTRIBUTE_NAME.APPLICATION_KEY,
      ATTRIBUTE_NAME.TX_POWER,
      ATTRIBUTE_NAME.MIN_SF,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.ADR_PERMISSION,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.CYBLE5_ENC_KEY,
      ATTRIBUTE_NAME.CYBLE5_AUTH_KEY,
      ATTRIBUTE_NAME.LAST_READED_VALUE,
      ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
      ATTRIBUTE_NAME.SEAL,
      ATTRIBUTE_NAME.TIMEZONE,
      ATTRIBUTE_NAME.VALVE_STATE,
    ],
  },
  {
    id: DEVICE_BY_COMM.MBUS,
    title: "WMBus",
    types: [METER_TYPE.WATER, METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.MBUS_ADDRESS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.MBUS_KEY,
      ATTRIBUTE_NAME.MBUS_ID,
      ATTRIBUTE_NAME.LAST_READED_VALUE,
      ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
      ATTRIBUTE_NAME.SEAL,
      ATTRIBUTE_NAME.TIMEZONE,
    ],
  },
  {
    id: DEVICE_BY_COMM.LW_MBUS,
    title: "LoRaWAN + wMBus",
    types: [METER_TYPE.WATER, METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.LORAWAN_VERSION,
      ATTRIBUTE_NAME.DEV_EUI,
      ATTRIBUTE_NAME.APP_EUI,
      ATTRIBUTE_NAME.NETWORK_KEY,
      ATTRIBUTE_NAME.APPLICATION_KEY,
      ATTRIBUTE_NAME.TX_POWER,
      ATTRIBUTE_NAME.MIN_SF,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.ADR_PERMISSION,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.MBUS_ADDRESS,
      ATTRIBUTE_NAME.MBUS_ID,
      ATTRIBUTE_NAME.MBUS_KEY,
      ATTRIBUTE_NAME.LAST_READED_VALUE,
      ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
      ATTRIBUTE_NAME.SEAL,
      ATTRIBUTE_NAME.TIMEZONE,
    ],
  },
  {
    id: DEVICE_BY_COMM.UNE,
    title: "UNE82326",
    types: [METER_TYPE.WATER],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.MBUS_ADDRESS,
      ATTRIBUTE_NAME.LAST_READED_VALUE,
      ATTRIBUTE_NAME.LAST_READED_YYYY_MM_DD,
      ATTRIBUTE_NAME.SEAL,
      ATTRIBUTE_NAME.TIMEZONE,
    ],
  },
  {
    id: DEVICE_BY_COMM.EK280,
    title: "EK280 (MODBUS TCP)",
    types: [METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.DESCRIPTION,
      ATTRIBUTE_NAME.ENTITY,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.CODE,
      ATTRIBUTE_NAME.SIM_ICCID,
      ATTRIBUTE_NAME.EXCLUDE,
      ATTRIBUTE_NAME.RED,
      ATTRIBUTE_NAME.FILE_NRO_SERIE,
    ],
  },
  {
    id: DEVICE_BY_COMM.OWASYS,
    title: "OWASYS (MODBUS 4G)",
    types: [METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.DESCRIPTION,
      ATTRIBUTE_NAME.ENTITY,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.CODE,
      ATTRIBUTE_NAME.SIM_ICCID,
      ATTRIBUTE_NAME.EXCLUDE,
      ATTRIBUTE_NAME.RED,
      ATTRIBUTE_NAME.FILE_NRO_SERIE,
    ],
  },
  {
    id: DEVICE_BY_COMM.PLUM,
    title: "PLUM",
    types: [METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
    ],
  },
  {
    id: DEVICE_BY_COMM.NBIOT,
    title: "NBIoT",
    types: [METER_TYPE.WATER, METER_TYPE.GAS],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
    ],
  },
  {
    id: DEVICE_BY_COMM.NO_LORA_NO_COM,
    title: "no-lora-no-com",
    types: [METER_TYPE.WATER],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
    ],
  },
  {
    id: DEVICE_BY_COMM.API,
    title: "api-external-device",
    types: [METER_TYPE.WATER],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.API,
      ATTRIBUTE_NAME.USER,
      ATTRIBUTE_NAME.PASSWORD,
    ],
  },
  {
    id: DEVICE_BY_COMM.API_TOKEN,
    title: "api-external-token-device",
    types: [METER_TYPE.WATER],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.RF_MODULE,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
      ATTRIBUTE_NAME.USER,
      ATTRIBUTE_NAME.PASSWORD,
      ATTRIBUTE_NAME.EXTERNAL_ID,
    ],
  },
  {
    id: DEVICE_BY_COMM.EXTERNO,
    title: "external",
    types: [METER_TYPE.WATER],
    attributes: [
      ATTRIBUTE_NAME.NRO_SERIE,
      ATTRIBUTE_NAME.DEV_TYPE,
      ATTRIBUTE_NAME.MANUFACTURER,
      ATTRIBUTE_NAME.ALIAS,
      ATTRIBUTE_NAME.COMMENTS,
      ATTRIBUTE_NAME.AGRUPATION,
      ATTRIBUTE_NAME.ACTIVATE,
    ],
  },
];
