// @angular
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Translate
import { TranslateService } from "@ngx-translate/core";

// Servicios propios
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";

// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { MeterBatteryDialogComponent } from "../meter-battery/meter-battery-dialog/meter-battery-dialog.component";

// Interfaces
import {
  TableSelectColumn,
  TableDataColumn,
  TableActionColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  AssignedDevice,
  DetailDevice,
  IgnoredDevice,
  LowBatteryDevice,
} from "../../../DeviceInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import { Client } from "../../../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";

// Variables
import { LOCAL_TIMEZONE } from "../../../../../../global/LOCAL_TIMEZONE";
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";

// Servicios específicos
import { MeterService } from "../../MeterService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";

@Component({
  selector: "app-meter-ignored",
  templateUrl: "./meter-ignored.component.html",
  styleUrls: ["./meter-ignored.component.scss"],
})
export class MeterIgnoredComponent {
  /***************************************************************************/
  // ANCHOR: Variables generales
  /***************************************************************************/
  quickFilters: any;
  meterList: any;
  title:string =this.translate.instant("meters-ignored")
  /***************************************************************************/
  // ANCHOR: Variables para la UI (botón de actualización, sesión)
  /***************************************************************************/
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Variables de sesión
  currentClient: Client;
  currentAgrupation: Agrupation;
  clientSub: Subscription;
  clientList: Client[] = [];
  entityList: Entity[] = [];
  agrupationList: Agrupation[] = [];
  globalAgrupationList: Agrupation[] = [];
  sessionProfile: string;

  // Selectores
  selectedClient: number;
  selectedEntity: number = -1;
  selectedAgrupation: number = null;
  batteryLoad: number = 60;

  /***************************************************************************/
  // ANCHOR: Variables para la tabla
  /***************************************************************************/
  tableData: LowBatteryDevice[] = [];
  originalTableData: LowBatteryDevice[] = [];
  tableSelectedData: LowBatteryDevice[] = [];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  

  /***************************************************************************/
  // ANCHOR: Definición de columnas de la tabla
  /***************************************************************************/
  columns: (TableActionColumn | TableDataColumn)[];

  /***************************************************************************/
  // ANCHOR: Componentes hijos
  /***************************************************************************/
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;
  @ViewChild(MeterBatteryDialogComponent)
  meterBatteryDialogComponent: MeterBatteryDialogComponent;
  agrupationSub: Subscription;
  currentEntity: Entity;

  /***************************************************************************/
  // ANCHOR: Métodos para la tabla
  /***************************************************************************/
  tableActions(action: string, meter: IgnoredDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      // case "delete-meter":
      //   this.deleteMeter(meter);
      //   break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR: Constructor
  /***************************************************************************/
  constructor(
    private DateParserService: DateParserService,
    private DomControllerService: DomControllerService,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService,
    private MeterService: MeterService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService
  ) {}

  /***************************************************************************/
  // ANCHOR: Inicialización del componente
  /***************************************************************************/
  ngOnInit(): void {
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.loadComponent();
  }

  loadComponent() {
    this.setColumns();
    this.getData();
  }

  setColumns() {
    this.columns=
    [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "disable",
            tooltip: "disable",
            icon: "fas fa-eraser disable",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
            warning: true,
          },
          {
            name: "enable",
            tooltip: "enable",
            icon: "fas fa-plus enable",
            visible: { attribute: "disabled", rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: "groups",
        data: "agrupacion",
        search: "agrupacion",
        sort: "agrupacion",
        visible: this.currentAgrupation?.virtual ?? false,
      },
      {
        title: "date",
        data: "dateParsed",
        search: "dateParsed",
        sort: "dateParsed",
        visible: true,
      },

    ];
  }


  // Obtención de los datos
  getData(): void {
    this.updating = true;

    let entidad = this.currentAgrupation?.virtual
      ? this.currentEntity.id
      : null;
    this.MeterController.getIgnoredMeterList(
      this.currentAgrupation?.id,
      entidad
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let meterList: IgnoredDevice[] = response["body"];

        // Primero parseamos las fechas
        meterList.forEach((meter: IgnoredDevice) => {
          meter.dateParsed = this.formatDate(new Date(meter.timestamp));
        });

        // Ordenamos por fecha en orden descendente (más nuevo a más antiguo)
        meterList.sort((a: IgnoredDevice, b: IgnoredDevice) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
        });

        // Asignamos la lista ordenada
        this.meterList = meterList;
      }
      this.updating = false;
    });
  }



  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2); // Asegura que el día tiene 2 dígitos
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Los meses van de 0 a 11, así que sumamos 1
    const year = date.getFullYear().toString().slice(-2); // Obtener solo los dos últimos dígitos del año
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  /***************************************************************************/
  // ANCHOR: Métodos adicionales
  /***************************************************************************/
  showMeter(meter: IgnoredDevice): void {
      this.router.navigate([
        "/dispositivos/detalle/contador/" + meter.id,
      ]);
  }

  /***************************************************************************/
  // ANCHOR: Métodos para acciones globales
  /***************************************************************************/
  tableGlobalAction(arg0: any) {
    throw new Error("Method not implemented.");
  }

  updateData() {
    this.getData();
  }
}
