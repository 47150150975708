// @angular
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  GatewayActivate,
  GatewayAppEuiSave,
  GatewayAssignList,
  GatewayConfig,
  GatewayDataloggerRequest,
  GatewayEcho,
  GatewayVoltage,
  GatewayWinterMode,
  LoadSettings,
} from "../../screens/dashboard/gateways/GatewayInterface.type";
import { Gateway } from "../../interfaces/GatewayGlobalInterface.type";
import { DeviceCoords } from "../../screens/dashboard/devices/DeviceInterface.type";

@Injectable({
  providedIn: "root",
})
export class GatewayControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Detalle
  /***************************************************************************/

  // Pantalla principal de un Gateway
  getMarkers(gatewayId: number, agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/main/" + gatewayId + "/agrupation/" + agrupationId
    );
  }

  //Obtener el listado de sniffer de un gateway
  getGatewaySniffer(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" + gatewayId + "/sniffer/list"
    );
  }

  //Eliminar registro del sniffer por su id
  deleteGatewaySnifferById(
    gatewayId: number,
    snifferId: string[]
  ): Observable<object> {
    return this.HttpRequestService.delete(
      "/gateway/" + gatewayId + "/sniffer/delete/",
      snifferId
    );
  }

  //Eliminar registros del sniffer ya asignados
  deleteGatewaySniffer(
    gatewayId: number,
    onlyAssigned: boolean
  ): Observable<object> {
    let urlLastValue: string = onlyAssigned ? "clearAssigned" : "clear";
    return this.HttpRequestService.delete(
      "/gateway/" + gatewayId + "/sniffer/" + urlLastValue
    );
  }

  assignMeterAsRedundant(gatewayId: any, meterIdList: any) {
    return this.HttpRequestService.post(
      "/gateway/" + gatewayId + "/addRedundant",
      meterIdList
    );
  }

  // Envío de Echo
  resetEcho(data: GatewayEcho): Observable<object> {
    return this.HttpRequestService.post("/send-radio-command", data);
  }

  // Gráfica de tensión y carga del Gateway
  getGraph(
    gatewayId: number,
    agrupationId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" +
        gatewayId +
        "/graph/" +
        agrupationId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Sustitución del Gateway principal de un listado de dispositivos con lectura
  asignate(
    contador: unknown,
    gatewayId: number,
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/chgateway/" + gatewayId + (queue ? "/queue" : ""),
      contador
    );
  }

  // Desasignar un listado de dispositivos de un Gateway
  unasignate(data: GatewayAssignList, queue?: boolean): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/deallocate/list" + (queue ? "/queue" : ""),
      data
    );
  }

  // Leer memoria de gateway
  getGraphLoad(
    gatewayId: number,
    from: string | number,
    to: string | number,
    agrupationId: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/readmemory/ram/send/" +
        gatewayId +
        "/from/" +
        from +
        "/to/" +
        to +
        "/agrupation/" +
        agrupationId
    );
  }

  // Gráfica de nivel de ruido
  getGraphNoise(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/noiselevel-graph/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Servicio para obtener los dispositivos que se pueden asignar a un Gateway
  meterDropdown(gatewayId: number, agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/assignable-dropdown/" +
        gatewayId +
        "/agrupation/" +
        agrupationId
    );
  }

  // Asignar dispositivos a un Gateway
  saveDropdown(
    gatewayId: number,
    data: string[],
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/assign/" + gatewayId + (queue ? "/queue" : ""),
      data
    );
  }

  // Actualizar estado de mantenimiento del Gateway
  markWithTasks(gatewayId: number, flag: boolean): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" + gatewayId + "/maintenance/" + flag
    );
  }

  // Resetear Gateway
  resetGateway(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/reset/" + gatewayId);
  }

  // Borrar Gateway
  deleteGateway(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/delete/" + gatewayId);
  }

  // Borrar Gateway
  deleteGatewayImage(imageId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/delete-image/" + imageId);
  }

  // Subir imagen de gateway
  updateImage(unidadVenta: string, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/upload-image/" + unidadVenta,
      data
    );
  }

  // Sustitución de un Gateway
  replaceGateway(gatewayId: number, newGateway: string): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/replace/" + gatewayId + "/to/" + newGateway
    );
  }

  // Actualizar geolocalización del Gateway
  newCoords(data: DeviceCoords): Observable<object> {
    return this.HttpRequestService.post("/gateway/update-coords/", data);
  }

  // Obtención de los mejores redundantes de los contadores principales de un gateway
  getBestRedundant(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/get-redundant-to-assign/" + gatewayId
    );
  }

  // Datos de temperatura
  getTemperature(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gwtemperature/graph/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Datos de temperatura
  getGatewayTemperature(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" + gatewayId + "/temperature/from/" + from + "/to/" + to
    );
  }

  // Tabla de dispositivos para la pantalla del Gateway
  getGatewayMeters(
    gatewayId: number,
    agrupationId: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/main/table/" + gatewayId + "/agrupation/" + agrupationId
    );
  }

  // Borrado y reenvío de los puntos finales de un gateway
  refreshGatewayEndPoints(
    gatewayId: number,
    wipe: boolean
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gestionpuntos/refrescarpuntosfinales/" +
        gatewayId +
        "/borradoPrevio/" +
        wipe
    );
  }

  // Borrado y reenvío de los puntos finales de un gateway (individual)
  refreshGatewayEndPointsByDevice(
    gatewayId: number,
    data: number[],
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/gestionpuntos/refrescarpuntofinal/" +
        gatewayId +
        (queue ? "/queue" : ""),
      data
    );
  }

  // Gráfica de tensión y carga del Gateway
  getMongoGraph(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" + gatewayId + "/status/from/" + from + "/to/" + to
    );
  }

  // Borrado de puntos finales de un gateway
  deleteGatewayEndPoints(
    gatewayId: number,
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gestionpuntos/eliminarpuntosfinales/" +
        gatewayId +
        (queue ? "/queue" : "")
    );
  }

  // Obtención de todos los contadores de un gateway
  getGatewayAllDevices(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/structure/gateway/" + gatewayId
    );
  }

  // Reseteo del regulador de carga del gateway
  resetLoad(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/reset-battery-charge-regulator/" + gatewayId
    );
  }

  // Petición del número de puntos finales
  getEndPointsTotal(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/read-npfs/" + gatewayId);
  }

  // Cambio de estado: De en almacén a disponible
  changeStateFromInStocklToAvailable(
    entityId: number,
    data: number[]
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/instock/to/available/entity/" + entityId,
      data
    );
  }

  // Cambio de estado: De disponible a disponible (cambio de entidad)
  changeEntity(entityId: number, data: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/available/move/" + entityId,
      data
    );
  }

  // Cambio de estado: De disponible a disponible (cambio de entidad)
  oldChangeEntity(entityId: number, data: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/pending/move/" + entityId,
      data
    );
  }

  // Cambio de estado: De disponible a en almacén
  changeStateFromAvailableToInStock(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/available/to/instock", data);
  }

  // Cambio de estado: De activado a disponible
  // Si devuelve el código 1828 (GW_PRINCIPALS_NOT_REASIGNED)
  // -> quitar contadores principales desde el front
  changeStateFromActivatedToAvailable(data: object[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/disable", data);
  }

  // Cambio de estado: De activado a estropeado
  // El front tiene que quitar los principales,
  // usar /contador/chgateway/{gateway}/withoutread/1 para que lo haga sin
  // comunicar con el gateway que ya hemos borrado los puntos finales
  changeStateFromActivatedToDamaged(data: object[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/activated/to/damaged", data);
  }

  // Cambio de estado: De estropeado a en reparación
  changeStateFromDamagedToRepair(data: object[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/damaged/to/repair", data);
  }

  // Cambio de estado: De estropeado a descartado
  changeStateToDiscarded(data: object[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/discarded", data);
  }

  // Cambio de estado: De en reparación a disponible/activado
  changeStateFromRepairToAvailable(data: object[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/repaired", data);
  }

  /***************************************************************************/
  // ANCHOR APP EUI
  /***************************************************************************/

  deleteItems(entityId: number, items: string[]): Observable<object> {
    return this.HttpRequestService.post(
      "/entity-appeui/delete/" + entityId,
      items
    );
  }

  // Añadir listado de APP EUIs permitidos a una entidad
  saveItems(entityId: number, items: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/entity-appeui/save/" + entityId,
      items
    );
  }

  // Listado de APP EUIs existentes en una entidad
  appDropdown(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity-appeui/list/" + entityId);
  }

  // Eliminar todos los APP EUIs configurados en una entidad
  deleteAll(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity-appeui/delete-all/" + entityId);
  }

  // Listado de APP EUIs configurados de una entidad
  getAppEui(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity-appeui/read/" + entityId);
  }

  // Guardado de App EUI
  saveAppEui(data: GatewayAppEuiSave): Observable<object> {
    return this.HttpRequestService.post("/entity-appeui/save-appeui", data);
  }

  // Borrado de todos los APP EUIs de un gateway
  deleteAllFromGateway(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/app-eui/delete-all/" + gatewayId
    );
  }

  /***************************************************************************/
  // ANCHOR Configuración
  /***************************************************************************/

  // Configurar gateway desarrollo/producción
  configureGateway(gatewayId: number, data: GatewayConfig): Observable<object> {
    let type: unknown;
    let endings: unknown;

    data["generateGwFile"] == true ? (type = "blob") : (type = "");
    data["generateGwFile"] == true ? (endings = "native") : (endings = "");

    return this.HttpRequestService.post(
      "/gw-conf/change-ip/" + gatewayId,
      data,
      {
        responseType: type,
        headers: new HttpHeaders({
          endings: "native",
        }),
      }
    );
  }

  // Calibrar voltaje de gateway
  voltaje(data: GatewayVoltage): Observable<object> {
    return this.HttpRequestService.post("/gw-conf/calibrate/", data);
  }

  // Leer tensión de gateway
  showTension(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/read-tension/" + gatewayId);
  }

  // Pedir listado de AppEUIs
  showAppEUI(gatewayId: number) {
    return this.HttpRequestService.get("/gw-conf/read-AppEUI/" + gatewayId);
  }
  deleteAppEUI(gatewayId: number) {
    return this.HttpRequestService.get("/blacklist/removeall/" + gatewayId);
  }
  // Leer ip de gateway
  showIp(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/read-server-ip/" + gatewayId);
  }

  // Leer frames de gateway
  winterRead(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/read-frames-up/" + gatewayId);
  }

  // Modificar frames de gateway
  winterUpdate(gatewayId: number, data: GatewayWinterMode): Observable<object> {
    return this.HttpRequestService.post(
      "/gw-conf/modify-frames-up/" + gatewayId,
      data
    );
  }

  // Leer carga de gateway
  chargeRead(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/read-chg/flash/" + gatewayId);
  }

  // Actualizar carga de gateway
  chargeUpdate(gatewayId: number, data: LoadSettings): Observable<object> {
    return this.HttpRequestService.post(
      "/writememory/flash/" + gatewayId,
      data
    );
  }

  // Formatear memoria del gateway
  formatMemory(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-conf/format-memory/" + gatewayId);
  }

  // Borrar gateway de base de datos
  deleteCommGateway(
    gatewayId: number,
    communication: number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-conf/delete/" + gatewayId + "/comunica/" + communication
    );
  }

  // Listado de operadores de Backup
  getBackupOperators(): Observable<object> {
    return this.HttpRequestService.get("/gateway/operadoras/countries");
  }

  // Guardado de operadores de Backup
  saveBackupOperators(gatewayId: number, operator: string): Observable<object> {
    return this.HttpRequestService.post(
      "/gw-conf/modify-operadoras-backup/" + gatewayId,
      operator
    );
  }

  // Listado de operadores de Backup
  readBackupOperators(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-conf/read-operadoras-backup/" + gatewayId
    );
  }

  // Listado de operadores de Backup
  updateGatewayChannels(
    gatewayId: number,
    channels: number,
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/configure/" +
        gatewayId +
        "/channels/" +
        channels +
        (queue ? "/queue" : "")
    );
  }

  /***************************************************************************/
  // ANCHOR Listados
  /***************************************************************************/

  // Importación de un fichero de Gateways
  import(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/gateway/import/", data);
  }

  // Exportación del fichero de Gateways
  download(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/export/" + agrupationId, {
      responseType: "blob",
      headers: new HttpHeaders({
        endings: "native",
      }),
    });
  }

  // Guardar un Gateway desde el formulario de la interfaz
  newGateway(data: Gateway): Observable<object> {
    return this.HttpRequestService.post("/gateway/save/", data);
  }

  // Listado de Gateways no asignados
  getPendingGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/pending/list/" + entityId);
  }

  // Listado de Gateways inactivos
  getInactiveGateways(): Observable<object> {
    return this.HttpRequestService.get("/gateway/inactive/list");
  }

  // Listado de los Gateways de una agrupación
  getInstalledGateways(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/list/" + agrupationId);
  }

  // Listado de los Gateways de una entidad para un cliente
  getClientEntityInstalledGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/list/admin/entity/" + entityId
    );
  }

  // Listado de alarmas de un Gateway
  getGatewayAlarms(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway-alarms/list/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de alarmas de Gateway por entidad
  getGatewayEntityAlarms(
    entityId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway-alarms/list/entity/" + entityId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de alarmas de Gateway global
  getGatewayGlobalAlarms(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway-alarms/global-list/from/" + from + "/to/" + to
    );
  }

  // Listado de ficheros recibidos por el Gateway, indica la fecha del fichero recibido y el estado, si se ha podido leer bien o no
  getGatewayFiles(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-files/list/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de los Gateways de una entidad
  getEntityGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/all/by-entity/" + entityId);
  }

  // Listado de los Gateways de una entidad
  getEntityAvailableGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/available/list/" + entityId);
  }

  // Listado de los Gateways en almacén
  getInStockGateways(): Observable<object> {
    return this.HttpRequestService.get("/gateway/instock/list");
  }

  // Listado de los Gateways dañados de una entidad
  getDamagedGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/damaged/list/" + entityId);
  }

  // Listado de los Gateways en reparación de una entidad
  getRepairGateways(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/repair/list/" + entityId);
  }

  // Histórico de operadoras
  geGatewayOperatorHistorical(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gw-op/history/" + gatewayId);
  }

  // Nueva entidad
  setEntity(entityId: number, data: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/set/available/" + entityId,
      data
    );
  }

  // Nueva entidad
  oldSetEntity(entityId: number, data: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/set/pending/" + entityId,
      data
    );
  }

  // Quitar entidad
  removeEntity(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/available/to/instock", data);
  }

  // Quitar entidad
  oldRemoveEntity(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/gateway/pending/to/inactive", data);
  }

  // Activar gateway
  activateGateway(data: GatewayActivate[]): Observable<object> {
    return this.HttpRequestService.post("/app/activate/gateway", data);
  }

  /***************************************************************************/
  // ANCHOR Log
  /***************************************************************************/

  // Lectura del Datalogger de eventos de un Gateway
  dataloggerLog(
    gatewayId: number,
    data: GatewayDataloggerRequest
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/gwevents/datalogger/" + gatewayId,
      data
    );
  }

  // Eliminar Datalogger de eventos
  removeDatalogger(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/datalogger/delete/" + gatewayId);
  }

  // Eliminar Datalogger de datos
  removeFrames(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/datalogger-data/delete/" + gatewayId);
  }

  // Obtener eventos de un Gateway
  getGatewayLogs(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gwevents/logs/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Log de eventos de usuarios
  getGatewayUserLogs(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/user-events/by-gateway/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  // Log de tareas de gateway
  getGatewayTasksLogs(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/task/events/" + from + "/" + to
    );
  }

  // Log de tareas de gateway
  getGatewayIdTasksLogs(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/" + gatewayId + "/task/events/" + from + "/" + to
    );
  }

  // Log de tramas de gateway
  getGatewayFrameLog(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/full/gateway/" +
        gatewayId +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Obtención de la imágen de tarea de gateway
  getGatewayTaskImage(id: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/task/events/" + id + "/get-image"
    );
  }

  // Obtener imagen del gateway
  getGatewayImg(gatewayId: number, agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/get-image/" + gatewayId + "/agrupation/" + agrupationId
    );
  }
  // Guardado de la imágen de tarea de gateway
  saveGatewayTaskImage(taskId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/upload-image/task/" + taskId,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Reset
  /***************************************************************************/

  // Lectura de Datalogger de resets
  getReset(
    gatewayId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gwevents/datalogger/resets/" + gatewayId + "/from/" + from + "/to/" + to
    );
  }

  /***************************************************************************/
  // ANCHOR Actualización
  /***************************************************************************/

  // Actualización de Firmware (Importación de ficheros)
  updateFirmware(
    gatewayId: number,
    data: unknown,
    toggle: number
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/gateway/update-fw/" + gatewayId + "/settings/" + toggle,
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "text/plain",
        }),
      }
    );
  }

  // Actualización de Firmware (Consultar progreso)
  updateFirmwareTimer(gatewayId: number, value: unknown): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/update-fw/" + gatewayId + "/nroframes/" + value,
      {
        headers: new HttpHeaders({
          "Content-Type": "text/plain",
        }),
      }
    );
  }

  // Actualización de Firmware (Cancelar actualización en progreso)
  updateFirmwareCancel(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gateway/update-fw/stop/" + gatewayId, {
      headers: new HttpHeaders({
        "Content-Type": "text/plain",
      }),
    });
  }

  // Borrar localización
  deleteLocation(locationId: number): Observable<object> {
    return this.HttpRequestService.get("/location/delete/" + locationId);
  }
}
