// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { MeterService } from "../../devices/meter/MeterService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableCupsFilters,
} from "../../../../modules/table-module/TableInterface.type";
import {
  ClaveMaestra,
  EntityDefinition,
} from "../../../../interfaces/CupsGlobalInterface.type";

@Component({
  selector: "app-info",
  templateUrl: "./data-management-info.component.html",
  styleUrls: ["./data-management-info.component.scss"],
})
export class ManagementInfoComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Tabla
  serialNumberLabel: string;
  rowNumbers: boolean = true;
  maxReg: number = 100;
  tableData: ClaveMaestra[];
  tableDataOriginal: ClaveMaestra[];
  exportFileName: string =
    this.translate.instant("export-info") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn)[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  filters: TableCupsFilters[];
  showFilters: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Redirección a configuración de metrológica
  editMetrological(): void {
    this.router.navigate(["/gestion-datos/configuracion"]);
  }

  // Obtención de los datos
  getData(): void {
    this.AssociationController.cups(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let tableData: ClaveMaestra[] = response["body"]["claveMaestraList"];
          tableData.forEach((cups) => {
            cups.claveParsed = cups.clave != null ? cups.clave : null;
            cups.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
              cups.metrologyType,
              cups.meterId
            );
            cups.cupsLink = cups.claveParsed
              ? "/cups/detalle/" + cups.id
              : null;
          });
          // CUPS
          let entityCupsData = this.MeterService.getEntityCupsData();
          this.tableCupsColumns = entityCupsData.entityCupsColumns;
          this.cups = entityCupsData.entityCups;
          this.entityNroSerie = entityCupsData.entityNroSerie;
          this.setColumns();
          this.tableDataOriginal = tableData;
          this.tableData = tableData;
        }
      }
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableDataColumn)[] = [
      {
        title: this.entityNroSerie?.label
          ? this.entityNroSerie.label
          : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
        linkCheck: this.currentAgrupation.virtual
          ? {
              condition: "agrupation",
              attribute: "agrupation",
              check: "id",
            }
          : null,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-know-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: this.cups ? this.cups.name : "CUPS",
        data: "claveParsed",
        search: "claveParsed",
        sort: "claveParsed",
        visible: this.cups ? true : null,
        link: "cupsLink",
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
    this.getFilters();
  }

  // Obtención de los filtros del cups
  getFilters(): void {
    let filters: TableCupsFilters[] = [];
    this.columns
      .filter(
        (column: TableActionColumn | TableDataColumn) =>
          column.title != "action"
      )
      .forEach((column: TableDataColumn) => {
        filters.push({
          name: column.title,
          data: column.data,
          value: null,
        });
      });
    this.filters = filters;
  }
}
