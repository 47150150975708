import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ManagementControllerService } from "../../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Maintenance } from "../../ManagementInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../../../global/LOCAL_TIMEZONE";

@Component({
  selector: "app-management-entity-gateways-dialog",
  templateUrl: "./management-entity-gateways-dialog.component.html",
  styleUrls: ["./management-entity-gateways-dialog.component.scss"],
})
export class ManagementEntityGatewaysDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Edición del mantenimiento
  editMaintenance(): void {
    let data: Maintenance = {
      id: this.data.id,
      gateway: this.data.gateway,
      gwUnidadVenta: this.data.gwUnidadVenta,
      historicalGroup: this.data.historicalGroup,
      historicalText: this.data.historicalText,
      userId: this.data.userId,
      username: this.data.username,
      timestamp: parseInt(
        this.DateParserService.toTimestamp(
          this.data.timestampParsed,
          "YYYY/MM/DD HH:mm:ss",
          this.data.localTimezone ? LOCAL_TIMEZONE : null
        )
      ),
      historicalState: this.data.historicalState,
      historicalParent: this.data.historicalParent,
    };

    this.ManagementController.update(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("action-edited-success")
        );
        this.SessionDataService.sendDialogAction({ action: "reload" });
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
