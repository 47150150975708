<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>
          {{ "meter-orders" | translate }}:
          {{ cupsName != null ? cupsName : ("no-data" | translate) }}</b
        >
      </h5>

      <!-- Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>
    <div class="panel-body">
      <!-- Tabla de gateways -->
      <app-table-controller
        [export]="true"
        [exportFileName]="exportFileName"
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="tableMaxReg"
        (tableAction)="tableActions($event.action.name, $event.element)"
        [tableGlobalActions]="tableGlobalActions"
        (tableGlobalAction)="tableGlobalAction($event.title)"
        [specialFilter]="true"
        (selectedDataUpdateFlag)="tableSelectedData = $event"
        [data]="tableData"
      >
      </app-table-controller>
    </div>
  </div>
</section>
