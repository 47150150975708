// @angular
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { DeviceCupsDialogComponent } from "./device-cups-dialog/device-cups-dialog.component";
// Interfaces
import {
  TableAction,
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
} from "../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-device-cups-table",
  templateUrl: "./device-cups-table.component.html",
  styleUrls: ["./device-cups-table.component.scss"],
})
export class DeviceCupsTableComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Tabla de CUPS asociados al contador
  @Input() cupsData: any;
  @Input() meter: any;
  @Input() sessionProfile: string;
  @Input() source: string;
  @Input() actionsEnabled: boolean;
  @Input() isGas: boolean;
  tableMaxReg: number = 5;
  tableData: object[];
  tableColumns: (TableActionColumn | TableDataColumn)[];
  meterIsAssigned: boolean = true;
  cupsExist: boolean;
  tableHighlightRow: TableHighlightRow[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MaterialDialogService: MaterialDialogService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.cupsExist = this.SessionDataService.getCurrentEntityCupsConf()?.some(
      (column: EntityDefinition) => column.colPosition == 0
    );
    if (this.cupsData.columns.length > 0) {
      if (this.source === "meter" && this.cupsData.data) {
        this.cupsData.data.cups = this.cupsData.data.isAssigned
          ? this.cupsData.data.clave
          : this.translate.instant("no-assigned");
        this.meterIsAssigned = this.cupsData.data.isAssigned;
        if (this.cupsExist && this.meter.activate >= 2) {
          this.tableHighlightRow = [
            { condition: "highLightRow", color: "red" },
          ];
        }
      } else if (this.source === "meter") {
        this.cupsData["data"] = { cups: this.translate.instant("no-assigned") };
        this.meterIsAssigned = false;
      }

      if (this.source === "cups") {
        this.cupsData.data.meterLink = this.meter
          ? "/dispositivos/detalle/contador/" + this.meter?.id
          : null;
      }

      this.cupsData.cups = this.cupsData.columns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      this.cupsData.columns.map(
        (column: EntityDefinition) =>
          (column.colPosition =
            column.colPosition <= 9
              ? "col0" + column.colPosition
              : "col" + column.colPosition)
      );
      this.setCupsTable();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  setCupsTable(): void {
    const {
      source,
      cupsExist,
      meter,
      meterIsAssigned,
      actionsEnabled,
      sessionProfile,
      cupsData,
    } = this;

    // Función para determinar si una acción debe estar deshabilitada
    const isDisabled = (): boolean => {
      return (
        actionsEnabled === false ||
        (this.SessionDataService.getCurrentProfile() ===
          PROFILES.ADMIN_AGRUPACION)
      );
    };

    // Construcción de las acciones de la tabla
    const data: TableAction[] = [];

    if (source === "meter" && cupsExist) {
      data.push({
        name: "show-cups",
        tooltip: "show-detail",
        icon: "fas fa-eye",
        visible: { attribute: "isAssigned", rule: true },
        disabled: false,
      });
    }

    if (source === "cups" || meterIsAssigned) {
      data.push({
        name: "edit-cups",
        tooltip: "edit",
        icon: "fas fa-edit",
        visible: { attribute: null, rule: true },
        disabled: isDisabled(),
      });
    } else if (!meterIsAssigned && cupsData?.columns.length > 1) {
      if (
        [METROLOGY_TYPE.WATER, METROLOGY_TYPE.GAS].includes(
          meter?.metrologyType
        )
      ) {
        data.push({
          name: "edit-cups",
          tooltip: "new",
          icon: "fas fa-plus",
          visible: { attribute: null, rule: true },
          disabled: isDisabled(),
        });
      }
      if (cupsExist) {
        data.push({
          name: "assign-cups",
          tooltip: "assign",
          icon: "fas fa-exchange-alt",
          visible: { attribute: null, rule: true },
          disabled: isDisabled(),
        });
      }
    }

    // Definir los perfiles visibles
    const visibleProfiles = [
      PROFILES.ARSON,
      PROFILES.ADMIN_ENTIDAD,
      PROFILES.ADMIN_CLIENTE,
      PROFILES.ADMIN_AGRUPACION,
    ];

    // Función para determinar la visibilidad de una columna según el perfil y otras condiciones
    const isVisible = (condition: boolean, fallback: boolean = true): boolean =>
      condition ? fallback : null;

    // Construcción de las columnas de la tabla
    const tableColumns: (TableActionColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: data,
        search: null,
        sort: null,
        visible: isVisible(
          visibleProfiles.includes(sessionProfile as PROFILES)
        ),
      },
      {
        title: this.translate.instant("latitude"),
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: isVisible(source == "cups"),
      },
      {
        title: this.translate.instant("longitude"),
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: isVisible(source == "cups"),
      },
      {
        title: cupsData.cups?.name || "CUPS",
        data: "cups",
        search: null,
        sort: null,
        visible: isVisible(source === "meter" && cupsExist),
      },
      {
        title: "serial-number",
        data: "meterNroSerie",
        search: null,
        sort: null,
        link: "meterLink",
        visible: isVisible(source === "cups" && meter?.nroSerie != null),
      },
      {
        title: this.translate.instant("fcv"),
        data: "conversionFactor",
        search: null,
        sort: null,
        visible: isVisible(source === "cups" && cupsExist && this.isGas),
      },
      {
        title:  this.translate.instant("pcs"),
        data: "pcsCups",
        search: null,
        sort: null,
        visible: isVisible(source === "cups" && cupsExist && this.isGas),
      },
    ];

    // Agregar las columnas adicionales de manera más eficiente
    const additionalColumns = cupsData.columns
      .filter(
        (extraColumn) => !["col00", "col100"].includes(extraColumn.colPosition)
      )
      .map((extraColumn) => ({
        title: extraColumn.name,
        data: extraColumn.colPosition,
        visible: true,
      }));

    // Agregar las columnas adicionales a la tabla
    tableColumns.push(...additionalColumns);

    // Lógica específica para CUPS
    if (source === "cups") {
      // Asignar PCS y FCV si es necesario
      cupsData.data.pcsValue = cupsData.data.pcsCups || null;

      cupsData.columns.push({
        colPosition: "latitude",
        label: "latitude",
        name: this.translate.instant("latitude"),
        text: "latitude",
        required: false,
      });
      cupsData.columns.push({
        colPosition: "longitude",
        label: "longitude",
        name: this.translate.instant("longitude"),
        text: "longitude",
        required: false
      });
      if (this.isGas) {
        // Agregar columnas FCV y PCS a cupsData.columns si es gas
        cupsData.columns.push({
          colPosition: "conversionFactor",
          label: "FCV",
          name: "FCV",
          text: "FCV",
          required: false,
        });
        cupsData.columns.push({
          colPosition: "pcsValue",
          label: "PCS",
          name: "PCS",
          text: "PCS",
          required: false,
        });
      }

      // Asignar el número de serie del medidor
      cupsData.data.meterNroSerie = meter?.nroSerie;
    }

    // Actualizar las variables de la tabla
    this.tableColumns = tableColumns;
    this.tableData = [cupsData.data];
  }

  // Acciones de la tabla de CUPS
  tableActions(action: string, cups: any): void {
    switch (action) {
      case "show-cups":
        this.router.navigate(["/cups/detalle/" + cups.id]);
        break;
      case "edit-cups":
        this.editCupsModal(cups);
        break;
      case "assign-cups":
        this.assignCupsModal();
        break;
      default:
        break;
    }
  }

  // Mostrar modal de edición de CUPS
  editCupsModal(cups): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "edit",
      cupsExist: this.cupsExist,
      cupsData: JSON.parse(JSON.stringify(this.cupsData)),
      newCups: JSON.parse(JSON.stringify(cups)),
      meter: this.meter,
    });
  }

  // Asignación de CUPS existente
  assignCupsModal(): void {
    this.MaterialDialogService.openDialog(DeviceCupsDialogComponent, {
      action: "assign",
      meterId: this.meter.id,
      cupsName: this.cupsData.cups?.name,
      cupsList: this.SessionDataService.getCurrentCupsList(),
    });
  }
}
