// @angular
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  NgZone,
  ElementRef,
  HostListener,
  TemplateRef,
} from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Leaflet
import * as L from "leaflet";
import { marker } from "leaflet";
import { icon } from "leaflet";
import { antPath } from "leaflet-ant-path";
import "leaflet.heat";
import "leaflet-edgebuffer/src/leaflet.edgebuffer.js";
import "leaflet.utm";
import "proj4";
import "proj4leaflet";
// Turf
import * as Turf from "@turf/turf";
// Servicios propios
import { HomeControllerService } from "../../../services/server/HomeController.service";
import { MapDeviceTooltipService } from "../map-services/MapDeviceTooltipService.service";
import { MapGatewayTooltipService } from "../map-services/MapGatewayTooltipService.service";
import { MapDeviceIconService } from "../map-services/MapDeviceIconService.service";
import { MapGatewayIconService } from "../map-services/MapGatewayIconService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";
import { MapDeviceFilterService } from "../map-services/MapDeviceFilterService.service";
import { MapDeviceMinimalParseService } from "../map-services/MapDeviceMinimalParseService.service";
import { MaterialDialogService } from "../../material-module/material-dialog/material-dialog.service";
import { MapKmlService } from "../map-services/MapKmlService.service";
import { RedirectToService } from "../../../services/shared/RedirectToService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
import { ColorService } from "../../../services/shared/ColorService.service";
import { MeterControllerService } from "../../../services/server/MeterController.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { GatewayService } from "../../../screens/dashboard/gateways/GatewayService.service";
import { AssociationControllerService } from "../../../services/server/AssociationController.service";
// Componentes propios
import { MapModalComponent } from "../map-modal/map-modal.component";
import { MapComponent } from "../map/map.component";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { ImageOverlay } from "../MapInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { Client } from "../../../interfaces/ClientGlobalInterface.type";
import { AgrupationChanges, MapKmlAssociation } from "../MapInterface.type";
import {
  MaterialSelectOption,
  PanelMenuOption,
} from "../../material-module/MaterialInterface.type";
import { GatewayLocation } from "../../../interfaces/GatewayGlobalInterface.type";
import { MbusMeter } from "../../../screens/dashboard/devices/ConcentratorInterface.type";
import {
  Association,
  SectorCups,
} from "../../../screens/dashboard/data-management/DataManagementInterface.type";
// Variables de mapa
import { MAP_CONFIG } from "../map-variables/MAP_CONFIG";
import {
  MAP_COLORS,
  RANDOM_COLORS,
  SHAPE_COLORS,
} from "../map-variables/MAP_COLORS";
import { MAP_CRS } from "../map-variables/MAP_CRS";
import {
  MAP_TYPES,
  MAP_LAYERS,
  MapLayerData,
} from "../map-variables/MAP_TYPES";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
import { RSSI_MBUS_THRESHOLDS } from "../../../screens/dashboard/coverage/CoverageInterface.type";
// Variable Jquery
declare var $: any;
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-map-controller",
  templateUrl: "./map-controller.component.html",
  styleUrls: ["./map-controller.component.scss"],
})
export class MapControllerComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Flag de componente ya inicializado
  componentInitiated: boolean = false;

  // Variables de sesión
  sessionProfile: string;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  clientList: Client[];
  routerSub: Subscription;

  // Filtros de mapa
  @Input() filter: string;

  // Arrays de contadores
  @Input()
  get metersData(): any[] {
    return this.meters;
  }
  set metersData(metersData: any[]) {
    this.meters = metersData;
    this.bounds = [];
    this.outlineCoordsSave = [];
    if (
      this.componentInitiated ||
      (!this.componentInitiated && this.meters && this.gateways)
    ) {
      this.loadComponent();
    }
  }
  meters: any[];
  metersDataOriginal: any[];
  metersOriginalArray: any[];

  // Array de gateways
  @Input()
  get gatewaysData(): any[] {
    return this.gateways;
  }
  set gatewaysData(gatewaysData: any[]) {
    this.gateways = gatewaysData;
    if (
      this.componentInitiated ||
      (!this.componentInitiated && this.meters && this.gateways)
    ) {
      this.loadComponent();
    }
  }
  gateways: any[];
  @Input() boundsWithGateways: boolean;

  // Array de localizaciones
  @Input()
  get locationsData(): any[] {
    return this.locations;
  }
  set locationsData(locationsData: any[]) {
    this.locations = locationsData;
    if (
      this.componentInitiated ||
      (!this.componentInitiated &&
        this.meters &&
        this.gateways &&
        this.locations)
    ) {
      this.loadComponent();
    }
  }
  locations: any[];

  // Variables del mapa
  @Input() mapType: string;
  @Input() mapHeight: number;
  @Input() initialZoom: number;
  @Input() fitBounds: any;
  @Output() boundsUpdate = new EventEmitter<number>();
  @Input() disableLegend: boolean;
  @Input() disableMeasure: boolean;
  @Input() disableCluster: boolean;
  @Input() hideCluster: boolean;
  @Input() hideMenu: boolean;
  @Input() mapOnly: boolean;
  @Input() boundsUpdateDisabled: boolean;
  @Input() recorderActive: boolean;
  @Output() recording = new EventEmitter<boolean>();
  @Input() recordData: string;
  @ViewChild(MapComponent) mapComponent: MapComponent;
  map: any;
  mapLayersControl: any;
  mapControl: any;
  mapLayersData: any[] = [];
  fullscreenOptions: any;
  bounds: any[] = [];
  markerClusterOptions: any;
  path: any;
  pathOptions: object = {
    delay: 500,
    dashArray: [25, 25],
    weight: 5,
    color: "#000",
    pulseColor: "#FFF",
  };
  clusterIsActive: boolean;
  clusterReady: boolean = false;
  loadingMap: boolean;
  filtersActive: boolean = false;
  selectorOption: string = "new";
  @Input() freeDrawEnabled: boolean;
  @Input() mapSearch: any;
  mapSearchData: any[];
  mapSearchAssociationData: any;
  mapSearchAssociationSelected: number;
  mapSearchShowFileColumns: boolean = false;
  mapSearchFileColumnOptions: MaterialSelectOption[];
  mapSearchMapColumnOptions: MaterialSelectOption[];
  mapSearchFileData: string[][];
  mapSearchFilterFileColumnIndex: number;
  mapSearchFilterMapColumnIndex: number;
  mapSearchFileToFilter: any;
  selectedPinned: boolean = false;
  selectionAvailable: boolean = false;
  @Input() searchAdress: boolean;
  @Input() map3d: boolean;
  searchActive: boolean = false;
  searchAssociationActive: boolean = false;
  @Output() filterUpdateFlag = new EventEmitter<any>();

  // Variables de las capas del mapa
  gatewayLayer: any = {};
  onlyMeters: boolean = false;
  iconStandardSize: number = 1;
  currentIconSize: number;

  // Variables para el mapa de solo gateways
  gatewaysOk: any[] = [];
  gatewaysError: any[] = [];
  gatewaysOkLayer: any[];
  gatewaysErrorLayer: any[];

  // Variables para el mapa de reposicionamiento de contador
  @Output() meterNewLocation = new EventEmitter<any>();

  // Aviso de acción para el componente padre
  @Output() actionFlag = new EventEmitter<any>();
  @Output() selectedDevices = new EventEmitter<any[]>();
  @Output() selectionLayers = new EventEmitter<any[]>();

  // Variables del mapa de detección de fugas
  @Input() showMeterRange: boolean;

  // Capas activas
  @Output() activeLayersFlag = new EventEmitter<string[]>();
  @Input() activateAllLayers: boolean;
  activeLayers: string[];

  // Capa de calor
  @Input()
  get heatLayerData(): any[] {
    return this._heatLayerData;
  }
  set heatLayerData(heatLayerData: any[]) {
    this._heatLayerData = heatLayerData;
    if (this.componentInitiated) {
      this.resetLayers();
    }
  }
  _heatLayerData: any[];
  @Input()
  get heatLayerRadius(): number {
    return this._heatLayerRadius;
  }
  set heatLayerRadius(heatLayerRadius: number) {
    this._heatLayerRadius = heatLayerRadius;
    if (this.componentInitiated) {
      this.resetLayers();
    }
  }
  _heatLayerRadius: number;
  @Input() heatLayerRange: number;
  @Input() heatLayerName: string;
  @Input() heatLayerMin: number;
  @Input() heatLayerGradient: any;
  updateHeatMapFlag: boolean = false;

  // Capa de calor poligonal
  @Input() polygonHeatValues: number[];
  @Input() polygonHeatWeight: number;

  // Imagen
  @Input() imageOverlayArray: ImageOverlay[];
  @Input()
  get imageOverlayOpacity(): number {
    return this._imageOverlayOpacity;
  }
  set imageOverlayOpacity(imageOverlayOpacity: number) {
    this._imageOverlayOpacity = imageOverlayOpacity;
    if (this._imageOverlayOpacity >= 0 && this._imageOverlayOpacity <= 1) {
      this.imageOverlayArray?.map(
        (imageOverlay: ImageOverlay) =>
          (imageOverlay.opacity = this._imageOverlayOpacity)
      );
      this.resetLayers();
    }
  }
  _imageOverlayOpacity: number;

  // Arrays y capas por tipo de mapa
  mapTypeData: object;

  // Polígonos de agrupaciones
  @Input()
  get drawAgrupationOutline(): boolean {
    return this._drawAgrupationOutline;
  }
  set drawAgrupationOutline(drawAgrupationOutline: boolean) {
    this._drawAgrupationOutline = drawAgrupationOutline;
    this.agrupationPolygonActive = drawAgrupationOutline;
  }
  _drawAgrupationOutline: boolean;
  agrupationPolygonActive: boolean;
  @Input() agrupationEditionActive: boolean;
  filterAgrupation: number;
  outlineCoordsSave: number[][][] = [];
  selectedAgrupation: number = null;
  agrupationList: Agrupation[];
  // agrupationFlagsActive: boolean = false;
  @Output() agrupationChanges = new EventEmitter<AgrupationChanges>();
  preventAgrupationReset: boolean = false;
  editionActive: boolean;

  // Multiple
  @ViewChild("cdkVirtualScrollViewPort")
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  // KML
  kmlAssociationsActive: boolean = false;
  @Input() allowKml: boolean;
  kmlAssociations: Subscription;
  kmlImage: Subscription;
  kmlPoints: Subscription;
  currentKmlAssociations: MapKmlAssociation[];
  currentKmlPoints: MapKmlAssociation[];
  currentShapefile: any;
  @ViewChild("importKmlPointInput") importKmlPointInput: ElementRef;
  @ViewChild("importKmlPolygonInput") importKmlPolygonInput: ElementRef;
  @ViewChild("importKmlImageInput") importKmlImageInput: ElementRef;
  @ViewChild("importShapefileInput") importShapefileInput: ElementRef;
  ctrlActive: boolean;
  shiftActive: boolean;
  @Input() exportKml: boolean;

  // Opciones del panel
  mapMenuOptions: PanelMenuOption[];
  @Output() activate3dMapFlag = new EventEmitter<any>();

  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    // ESCAPE
    if (event.key === "Escape") {
      // Reseteo de KML
      this.kmlAssociationsActive = false;
      this.currentKmlAssociations = null;
      this.currentKmlPoints = null;
      this.currentShapefile = null;
      this.imageOverlayArray = null;

      // Reseteo de dibujo
      if (this.mapType != "agrupationEdition") {
        this.mapComponent.deleteDrawedPolygon();
      }

      // Reseteo de Geoman
      this.mapComponent.map?.pm?.disableGlobalEditMode();

      if (this.agrupationPolygonActive && this.mapType != "agrupationEdition") {
        // Ocultar polígonos de agrupación
        this.agrupationPolygonActive = false;
        this.mapComponent.updateMarkersPointerEvents();
        this.setMapMenuOptions();
        this.resetLayers();
      } else {
        // Reseteo de selección
        this.resetSelection();
      }
    }

    // CONTROL
    if (event.key === "Control") {
      this.ctrlActive = true;
    }

    // SHIFT
    if (event.key === "Shift") {
      this.shiftActive = true;
      this.mapComponent.updateMarkersPointerEvents(true);
    }
  }

  @HostListener("document:keyup", ["$event"])
  handleKeyboardUpEvent(event: KeyboardEvent) {
    // CONTROL
    if (event.key === "Control") {
      this.ctrlActive = false;
    }

    //SHIFT
    if (event.key === "Shift") {
      this.shiftActive = false;
      this.mapComponent.updateMarkersPointerEvents(false);
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private ColorService: ColorService,
    private GatewayService: GatewayService,
    private HomeController: HomeControllerService,
    private DeviceTypeService: DeviceTypeService,
    private MapDeviceFilterService: MapDeviceFilterService,
    private MapDeviceIconService: MapDeviceIconService,
    private MapDeviceMinimalParseService: MapDeviceMinimalParseService,
    private MapGatewayIconService: MapGatewayIconService,
    private MapDeviceTooltipService: MapDeviceTooltipService,
    private MapGatewayTooltipService: MapGatewayTooltipService,
    private MapKmlService: MapKmlService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private RedirectToService: RedirectToService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private zone: NgZone
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Tamaño de iconos
    document.documentElement.style.setProperty(
      "--map-icon-size",
      String(this.iconStandardSize)
    );
    // Carga de variables de sesión
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.clientList = this.SessionDataService.getCurrentClientList();
    // Inicialización del toggle de los cluster
    this.clusterIsActive =
      this.mapType != "unknownDevices" ? !this.disableCluster : false;
    // Inicialización del toggle de los polígonos de agrupaciones
    // this.agrupationPolygonActive = this.drawAgrupationOutline;
    // Inicialización de las opciones de cluster
    if (!this.disableCluster) {
      this.clusterOptions();
    }
    // Inicialización
    if (this.meters && this.gateways) {
      this.loadComponent();
    }

    // KML por polígonos
    this.kmlAssociations = this.MapKmlService.getMapKmlAssociations().subscribe(
      (associations) => {
        this.currentKmlAssociations = associations;
        this.setAssociationLayer();
      }
    );

    // KML por imagen
    this.kmlImage = this.MapKmlService.getMapKmlImage().subscribe(
      (kmlImage) => {
        this.imageOverlayArray = kmlImage;
        this.resetLayers();
      }
    );

    // KML por puntos
    this.kmlPoints = this.MapKmlService.getMapKmlPoints().subscribe(
      (kmlPoints) => {
        this.currentKmlPoints = kmlPoints;
        this.setPointsLayers();
      }
    );

    // Shapefile
    this.kmlPoints = this.MapKmlService.getMapShapefile().subscribe(
      (shapefile) => {
        this.currentShapefile = shapefile;
        this.ToastService.fireAlertWithOptions(
          "question",
          this.translate.instant("projected-coordinates-question")
        ).then((userConfirmation: boolean) => {
          this.setShapefileLayers(userConfirmation);
        });
      }
    );

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    // Escucha de los cambios de url al iniciar la navegación
    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (this.mapComponent?.map) {
          let mapData = this.SessionDataService.getCurrentMapData();
          if (!mapData) {
            mapData = {};
          }
          mapData[this.mapType] = {
            bounds: this.mapComponent.map.getBounds(),
            iconSize: this.currentIconSize
              ? this.currentIconSize
              : mapData[this.mapType]?.iconSize,
            clusterIsActive: this.clusterIsActive,
            agrupationPolygonActive: this.agrupationPolygonActive,
            mapType: this.mapType,
            agrupationId: this.currentAgrupation?.id,
            entityId: this.currentEntity?.id,
            url: this.router.url,
          };
          this.SessionDataService.sendMapData(mapData);
        }
      });
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.kmlAssociations.unsubscribe();
    this.kmlImage.unsubscribe();
    this.kmlPoints.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.routerSub.unsubscribe();
    $(".resetGateway")?.off("click");
    $(".assignGateway")?.off("click");
    $(".echoGateway")?.off("click");
    $(".removeTest")?.off("click");
    $(".showInfo")?.off("click");
    $(".findGateway")?.off("click");
    $(".photoModal")?.off("click");
    $(".map-link")?.off("contextmenu");
    $(".lwMbusFixDevice")?.off("click");
    $(".removeLocation")?.off("click");
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización dependiendo del tipo de mapa
  loadComponent(): void {
    this.getMapTypeData();
    this.mapLayersData = [];
    this.setMapMenuOptions();

    switch (this.mapType) {
      case "control":
        if (this.meters) {
          // Obtención de los arrays de cada tipo de marcador
          this.mapTypeData["CONTROL_ALERT_ASSIGNED"].array =
            this.MapDeviceFilterService.checkMeters(
              this.meters[0].controlAlertsAssigned,
              this.meters[0].controlAlertsOpened.length == 0
                ? this.bounds
                : null
            );
          this.mapTypeData["CONTROL_ALERT_OPEN"].array =
            this.MapDeviceFilterService.checkMeters(
              this.meters[0].controlAlertsOpened,
              this.bounds
            );
          this.mapTypeData["CONTROL_SENSOR"].array =
            this.MapDeviceFilterService.checkMeters(
              this.meters[0].controlSensors,
              this.meters[0].controlAlertsOpened.length == 0
                ? this.bounds
                : null
            );
          this.mapTypeData["CONTROL_VALVE_METER"].array =
            this.MapDeviceFilterService.checkMeters(
              this.meters[0].controlValveMeters,
              this.meters[0].controlAlertsOpened.length == 0
                ? this.bounds
                : null
            );
        }
        break;
      case "coverageMbusMap":
      case "coverageMap":
        // Seteo de la capa poligonal del mapa de cobertura
        this.setCoverageLayer();
        // Obtención de los arrays de cada tipo de dispositivo
        this.getDevicesArrays();
        break;
      default:
        // Obtención del array de los contadores
        this.metersOriginalArray = this.MapDeviceFilterService.checkMeters(
          this.meters,
          this.bounds
        );
        this.meters = [...this.metersOriginalArray];
        // Obtención de los arrays de cada tipo de dispositivo
        this.getDevicesArrays();
        break;
    }
    // Obtención de las capas del mapa
    this.getMapLayers();
    // Guardado de las capas en la variable para el componente mapa
    this.setMapLayers();
    // Seteo de la capa de calor
    this.setHeatLayer();
    // Seteo de la capa de imagen
    this.setImageLayer();
    // Seteo de las capas de agrupaciones para virtual
    this.setAgrupationLayers();
    setTimeout(() => (this.clusterReady = true), 0);
    this.componentInitiated = true;

    // Carga de datos guardados
    setTimeout(() => this.mapLoadSavedData(), 0);
  }

  // Obtención de los datos base del mapa
  getMapTypeData(): void {
    if (MAP_TYPES[this.mapType]) {
      let mapTypeData = JSON.parse(JSON.stringify(MAP_TYPES[this.mapType]));
      for (let layerType in MAP_TYPES[this.mapType]) {
        mapTypeData[layerType] = new MapLayerData(
          MAP_TYPES[this.mapType][layerType].layerData
        );
      }
      this.mapTypeData = mapTypeData;
    }
  }

  // Seteo de las opciones del menú del mapa
  setMapMenuOptions(): void {
    this.mapMenuOptions = [
      {
        action: "3d",
        icon: "fas fa-globe-europe",
        text: "3D",
        visible:
          this.map3d &&
          this.meters &&
          this.gateways &&
          this.mapHeight &&
          this.sessionProfile == PROFILES.ARSON,
      },
      {
        action: null,
        icon: "fas fa-location-dot",
        text: this.translate.instant("map-icon-size"),
        submenu: [
          {
            action: "icon-standard",
            icon: "fas fa-location-dot",
            text: "100%",
            visible: true,
          },
          {
            action: "icon-small",
            icon: "fas fa-location-dot fa-sm",
            text: "75%",
            visible: true,
          },
          {
            action: "icon-very-small",
            icon: "fas fa-location-dot fa-xs",
            text: "50%",
            visible: true,
          },
        ],
        visible: this.allowKml,
      },
      {
        action: "search",
        icon: "fas fa-magnifying-glass-location",
        text: this.translate.instant("search"),
        disabled: this.agrupationPolygonActive,
        visible: this.mapSearch,
      },
      {
        action: "search-association",
        icon: "fas fa-layer-group",
        text: this.translate.instant("search-association"),
        disabled: this.agrupationPolygonActive,
        visible: this.mapSearch,
      },
      {
        action: "agrupation-toggle",
        icon: "fas fa-map",
        text: this.translate.instant(
          this.agrupationPolygonActive ? "agrupations-hide" : "agrupations-show"
        ),
        disabled: this.kmlAssociationsActive,
        visible:
          this.meters != null &&
          this._drawAgrupationOutline &&
          !this.agrupationEditionActive,
      },
      {
        action: "cluster-toggle",
        icon: "fa-regular fa-circle-dot",
        text: this.translate.instant(
          this.clusterIsActive ? "hide-cluster" : "show-cluster"
        ),
        disabled: this.disableCluster,
        visible: this.meters != null && !this.hideCluster,
      },
      {
        action: null,
        icon: "fas fa-upload",
        text: this.translate.instant("kml-import"),
        submenu: [
          {
            action: "kml-point",
            icon: "fas fa-circle",
            text: this.translate.instant("kml-point"),
            visible: true,
          },
          {
            action: "kml-polygonal",
            icon: "fas fa-draw-polygon",
            text: this.translate.instant("kml-polygonal"),
            visible: true,
          },
          {
            action: "kml-image",
            icon: "fas fa-image",
            text: this.translate.instant("kml-image"),
            visible: true,
          },
        ],
        visible: this.allowKml,
      },
      {
        action: "shapefile",
        icon: "fas fa-upload",
        text: this.translate.instant("import") + " Shapefile",
        visible: true,
      },
      {
        action: "kml-export",
        icon: "fas fa-download",
        text: this.translate.instant("kml-export"),
        visible: this.exportKml,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "3d":
        this.activate3dMapFlag.emit();
        break;
      case "search":
        this.searchAssociationActive = false;
        this.searchActive = !this.searchActive;
        break;
      case "search-association":
        this.searchActive = false;
        this.getAssociationData();
        break;
      case "icon-standard":
        this.setMapIconSize(this.iconStandardSize);
        this.currentIconSize = 1;
        break;
      case "icon-small":
        this.setMapIconSize(this.iconStandardSize * 0.75);
        this.currentIconSize = 0.75;
        break;
      case "icon-very-small":
        this.setMapIconSize(this.iconStandardSize * 0.5);
        this.currentIconSize = 0.5;
        break;
      case "agrupation-toggle":
        this.toggleAgrupationPolygons();
        this.setMapMenuOptions();
        break;
      case "cluster-toggle":
        this.toggleCluster();
        this.setMapMenuOptions();
        break;
      case "kml-polygonal":
        this.toggleAssociationKml();
        break;
      case "kml-image":
        this.toggleImageKml();
        break;
      case "kml-point":
        this.togglePointKml();
        break;
      case "shapefile":
        this.importShapefileInput.nativeElement.value = null;
        this.importShapefileInput?.nativeElement?.click();
        break;
      case "kml-export":
        this.MaterialDialogService.openDialog(MapModalComponent, {
          show: "kml",
          gateways: this.gateways,
          devices: this.meters,
        });
        break;
      default:
        break;
    }
  }

  // Seteo de tamaño de iconos
  setMapIconSize(size: number): void {
    document.documentElement.style.setProperty("--map-icon-size", String(size));
    for (let layer in this.mapTypeData) {
      if (
        !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
        !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
        !layer.toUpperCase().includes("PATH") &&
        !layer.toUpperCase().includes("CONCENTRATOR") &&
        !layer.toUpperCase().includes(MAP_LAYERS.TXN) &&
        !layer.toUpperCase().includes(MAP_LAYERS.NO_LORA) &&
        this.mapTypeData[layer].array?.length > 0 &&
        !this.checkMapMarkersType(layer)
      ) {
        for (let id in this.mapTypeData[layer].markers) {
          if (!this.mapTypeData[layer].markers[id]._icon) {
            this.mapTypeData[layer].markers[id].setRadius(8 * size);
          }
        }
      }
    }
  }

  // Flag de reencuadre de mapa
  moveFlag(bounds): void {
    setTimeout(() => this.boundsUpdate.emit(bounds), 0);
  }

  // Guardado de estado de mapa
  mapLoadSavedData(): void {
    let mapSavedData = this.SessionDataService.getCurrentMapData();
    if (
      this.mapType != "meterDetail" &&
      mapSavedData &&
      mapSavedData[this.mapType] &&
      mapSavedData[this.mapType].agrupationId == this.currentAgrupation.id &&
      mapSavedData[this.mapType].entityId == this.currentEntity.id &&
      mapSavedData[this.mapType].url == this.router.url
    ) {
      if (mapSavedData[this.mapType].iconSize) {
        this.setMapIconSize(
          this.iconStandardSize * mapSavedData[this.mapType].iconSize
        );
      }
      if (this.clusterIsActive != mapSavedData[this.mapType].clusterIsActive) {
        this.toggleCluster();
      }
      if (
        this.agrupationPolygonActive !=
        mapSavedData[this.mapType].agrupationPolygonActive
      ) {
        this.toggleAgrupationPolygons();
      }
      this.setMapMenuOptions();
      this.mapComponent.map.fitBounds(mapSavedData[this.mapType].bounds);
    }
  }

  /***************************************************************************/
  // ANCHOR Clúster
  /***************************************************************************/

  // Control del toggle de los cluster
  toggleCluster(): void {
    this.clusterIsActive = !this.clusterIsActive;
    this.resetLayers(null, true);
  }

  // Opciones básicas de los cluster
  clusterOptions(): void {
    const self = this;

    this.markerClusterOptions = {
      chunkedLoading: MAP_CONFIG.clusterOptions.chunkedLoading,
      chunkInterval: MAP_CONFIG.clusterOptions.chunkInterval,
      chunkDelay: MAP_CONFIG.clusterOptions.chunkDelay,
      chunkProgress: MAP_CONFIG.clusterOptions.chunkProgress,
      maxClusterRadius: MAP_CONFIG.clusterOptions.maxClusterRadius,
      polygonOptions: MAP_CONFIG.clusterOptions.polygonOptions,

      // Creación de iconos para los marcadores
      iconCreateFunction: function (cluster: any) {
        let clusterMarkers = cluster.getAllChildMarkers();
        let clusterData: any[] = [];
        let isConcentrator: boolean = false;

        // Identificar marcadores mediante clase
        clusterMarkers.forEach((marker: any) => {
          let markerCondition: string = marker.options?.className?.includes(
            "selected"
          )
            ? "deviceSelected"
            : marker.options?.className?.includes("deactivated")
            ? "deviceDeactivated"
            : marker.options?.icon
            ? marker.options?.icon.options[
                MAP_CONFIG.clusterOptions.markers[self.mapType].attribute
              ]
            : marker.options[
                MAP_CONFIG.clusterOptions.markers[self.mapType].attribute
              ];
          let markerFound: any = MAP_CONFIG.clusterOptions.markers[
            self.mapType
          ].options.find((option: any) => {
            return markerCondition?.includes(option.condition);
          });
          if (markerFound) {
            clusterData.push({
              condition: markerFound.condition,
              color: markerFound.color,
              tooltip: self.translate.instant(markerFound.tooltip),
            });
          }
          if (
            marker?.options?.icon?.options?.className?.includes("LW-UNE") ||
            marker?.options?.icon?.options?.className?.includes("LW-MBUS") ||
            marker?.options?.className?.includes("LW-UNE") ||
            marker?.options?.className?.includes("LW-MBUS")
          ) {
            isConcentrator = true;
          }
        });

        let markerConditionAmount: any[] = self.getConditionAmount(clusterData);
        let background = self.setClusterBackground(markerConditionAmount);

        // Asignación de comentario para cada marcador
        cluster.bindTooltip(
          markerConditionAmount?.length > 1
            ? self.translate.instant("devices")
            : clusterData[0]?.tooltip,
          {
            direction: "right",
            className: "map-title",
          }
        );

        // Asignación de icono y clases dependiendo del tipo de marcador
        let html: string =
          `<div style="background: ` +
          background +
          `;"` +
          (markerConditionAmount.find(
            (marker: any) =>
              marker.condition.includes("Ek") ||
              marker.condition.includes("Plum") ||
              marker.condition.includes("Externo")
          )
            ? "class='clip-star'"
            : "") +
          `>`;
        if (isConcentrator) {
          html += `<i class='fas fa-tint'></i>`;
        }
        html +=
          `<span>` +
          clusterMarkers.length +
          `</span>
                </div>`;
        let className: string =
          "marker-cluster-default marker-cluster-" +
          (markerConditionAmount?.length > 1 ? "mix" : clusterData[0]?.color);
        if (isConcentrator) {
          className += "-concentrator";
        }

        let iconSize =
          parseFloat(
            document.documentElement.style.getPropertyValue("--map-icon-size")
          ) * 40;
        // Icono de clúster
        return new L.DivIcon({
          html: html,
          className: className,
          iconSize: new L.Point(iconSize, iconSize),
        });
      },
    };
  }

  // Obtención de la cantidad de marcadores de cada tipo en el clúster
  getConditionAmount(clusterData: any[]): any[] {
    let markerConditionAmount: any[] = [];
    MAP_CONFIG.clusterOptions.markers[this.mapType].options.forEach(
      (option: any) => {
        let amount: number = clusterData.filter(
          (data: any) => data.condition == option.condition
        )?.length;
        if (amount > 0) {
          markerConditionAmount.push({
            condition: option.condition,
            color: option.color,
            amount: amount,
          });
        }
      }
    );
    return markerConditionAmount;
  }

  // Obtención del fondo del clúster acorde a los tipos
  setClusterBackground(markerConditionAmount: any[]): string {
    let totalMarkers: number = markerConditionAmount
      .map((condition: any) => {
        return condition.amount;
      })
      .reduce((partialSum, a) => partialSum + a, 0);
    let background: string = `conic-gradient(`;
    let startDeg: number = 0;

    markerConditionAmount.map((condition: any) => {
      let endDeg: number = startDeg + (condition.amount * 360) / totalMarkers;
      background +=
        MAP_COLORS["marker-" + condition.color] +
        ` ` +
        startDeg +
        `deg, ` +
        MAP_COLORS["marker-" + condition.color] +
        ` ` +
        endDeg +
        `deg, `;
      startDeg = endDeg;
    });

    background = background.slice(0, -2);
    background += `)`;
    return background;
  }

  /***************************************************************************/
  // ANCHOR Generación de las capas del mapa
  /***************************************************************************/

  // Carga de los arrays de dispositivos de cada tipo
  getDevicesArrays(): void {
    for (let layer in this.mapTypeData) {
      if (layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS)) {
        this.mapTypeData[layer].array = this.gatewayFilter(layer);
      } else if (layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)) {
        this.mapTypeData[layer].array = this.locationsData;
      } else {
        this.mapTypeData[layer].array =
          this.MapDeviceFilterService.deviceFilter(this.meters, layer);
      }
    }
  }

  // Obtención de las capas del mapa
  getMapLayers(): void {
    for (let layer in this.mapTypeData) {
      let layerData: { markers: object; layer: L.Layer };
      // Capas de contadores
      if (
        !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
        !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)
      ) {
        layerData = this.getMeterLayer(layer, this.mapTypeData[layer].array);
        // Capa de gateways
      } else if (
        !this.onlyMeters &&
        layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS)
      ) {
        layerData = this.getGatewayLayer(this.mapTypeData[layer].array);
        // Capa de localizaciones
      } else if (
        !this.onlyMeters &&
        layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)
      ) {
        layerData = this.getLocationLayer(this.mapTypeData[layer].array);
      }
      // Seteo de datos de capa
      if (layerData) {
        this.mapTypeData[layer].layer = layerData.layer;
        this.mapTypeData[layer].markers = layerData.markers;
      }
    }
  }

  // Creación de las capas del mapa
  setMapLayers(): void {
    let devicesCount: number = 0;
    for (let layer in this.mapTypeData) {
      devicesCount += this.mapTypeData[layer].array?.length;
      if (this.mapTypeData[layer].array?.length > 0) {
        this.newMapLayer(
          layer,
          this.mapTypeData[layer].layerData.name,
          this.mapTypeData[layer].layer,
          this.mapTypeData[layer].layerData.overlay
        );
      }
    }
    // Apertura de tooltips de presión
    this.openPressureTooltips();
  }

  // Apertura de tooltips de presión
  openPressureTooltips(): void {
    if (
      this.mapType == "pressureDetection" ||
      this.mapType == "flowDetection"
    ) {
      setTimeout(() => {
        for (let marker in this.mapTypeData[MAP_LAYERS.LEAK_SENSOR].markers) {
          this.mapTypeData[MAP_LAYERS.LEAK_SENSOR].markers[
            marker
          ].openTooltip();
        }
      }, 0);
    }
  }

  // Inserción de los datos de cada capa del mapa en la variable de capas para el componente mapa
  newMapLayer(
    layer: string,
    layerName: string,
    layerData: any,
    layerOverlay: boolean
  ): void {
    let newLayer: any = {
      id: layer,
      name: this.translate.instant(layerName),
      data: layerData,
      overlay: layerOverlay,
    };
    this.mapLayersData.push(newLayer);
  }

  // Reseteo de la capa de gateways
  resetGateways(): void {
    this.mapTypeData["gateways"].layer = this.getGatewayLayer(
      this.mapTypeData["gateways"].array
    );
    // Seteo de capa
    let gatewayLayerIndex = this.mapLayersData.findIndex(
      (layer) => layer.id == this.mapTypeData["gateways"].layerData.name
    );

    if (gatewayLayerIndex >= 0) {
      this.mapLayersData[gatewayLayerIndex] = {
        id: this.mapTypeData["gateways"].layerData.name,
        name: this.translate.instant(
          this.mapTypeData["gateways"].layerData.name
        ),
        data: this.mapTypeData["gateways"].layer,
        overlay: this.mapTypeData["gateways"].layerData.overlay,
      };
    }

    this.mapComponent.resetLayers();
  }

  // Reseteo de la capa de localizaciones
  resetLocations(): void {
    let locationLayerData = this.getLocationLayer(
      this.mapTypeData[MAP_LAYERS.LOCATIONS].array
    );
    this.mapTypeData[MAP_LAYERS.LOCATIONS].layer = locationLayerData.layer;
    this.mapTypeData[MAP_LAYERS.LOCATIONS].markers = locationLayerData.markers;
    // Seteo de capa
    let locationLayerIndex = this.mapLayersData.findIndex(
      (layer) => layer.id == MAP_LAYERS.LOCATIONS
    );
    if (locationLayerIndex >= 0) {
      this.mapLayersData[locationLayerIndex] = {
        id: this.mapTypeData[MAP_LAYERS.LOCATIONS].layerData.name,
        name: this.translate.instant(
          this.mapTypeData[MAP_LAYERS.LOCATIONS].layerData.name
        ),
        data: this.mapTypeData[MAP_LAYERS.LOCATIONS].layer,
        overlay: this.mapTypeData[MAP_LAYERS.LOCATIONS].layerData.overlay,
      };
    }
  }

  // Reseteo de las capas de contadores
  resetLayers(
    resetByClick?: boolean,
    clusterToggled?: boolean,
    avoidFindMetersLinked?: boolean
  ): void {
    // Reseteo de capas de path
    this.mapLayersData = this.mapLayersData.filter(
      (layer) => !layer.id?.toUpperCase().includes("PATH")
    );

    // Reseteo de contadores asociados
    if (
      this.mapType == "standard" &&
      !this.shiftActive &&
      !clusterToggled &&
      !avoidFindMetersLinked
    ) {
      this.findMetersLinked(null);
    }

    // Reseteo de los datos de la selección
    if (!resetByClick && !clusterToggled && this.mapType != "coverage") {
      this.resetSelection();
    }

    if (
      this.agrupationPolygonActive ||
      !resetByClick ||
      this.mapType == "coverage"
    ) {
      // Comprobación de si solo se deben resetear capas de contadores
      if (this.onlyMeters) {
        this.mapLayersData = this.mapLayersData.filter(
          (layer: any) =>
            layer.id.toUpperCase().includes(MAP_LAYERS.GATEWAYS) ||
            layer.id.toUpperCase().includes(MAP_LAYERS.LOCATIONS) ||
            layer.id.toUpperCase().includes("GEOJSON")
        );
      } else {
        this.mapLayersData = this.mapLayersData.filter((layer: any) =>
          layer?.id?.toUpperCase().includes("GEOJSON")
        );
      }

      // Obtención de capas
      this.getMapLayers();
      // Seteo de capas
      this.setMapLayers();
      // Seteo de capa de cobertura
      if (this.mapType == "coverageMap" || this.mapType == "coverageMbusMap") {
        this.setCoverageLayer();
      }
      // Seteo de capa de calor
      if (this.mapType == "heatMap") {
        this.setHeatLayer();
      }
      // Seteo de capa de imagen
      this.setImageLayer();
      this.setAgrupationLayers();
      if (this.currentKmlAssociations) {
        this.setAssociationLayer();
      }
      if (this.currentKmlPoints) {
        this.setPointsLayers();
      }
    }
    this.onlyMeters = false;
  }

  // Reseteo de dispositivos seleccionados
  resetSelection(): void {
    this.meters.map((meter: any) => (meter.selected = false));
    setTimeout(() => this.updateSelected(), 0);
    this.selectedDevices.emit([]);
  }

  // Reseteo de todas las capas del mapa
  resetMap(resetByClick?: boolean): void {
    // Activación/Desactivación de panel de marcadores circulares
    if (this.agrupationPolygonActive) {
      this.mapComponent.updateMarkersPointerEvents(
        this.agrupationPolygonActive && !this.preventAgrupationReset
      );
    }

    // Reseteo de selección fijada
    if (resetByClick && this.selectedPinned) {
      this.selectedPinned = false;
      this.pinSelected(true);
    }

    // Comprobación de reseteo de agrupación seleccionada
    if (!this.preventAgrupationReset) {
      this.selectedAgrupation = null;
      this.filterAgrupation = null;
    }

    // Reseteo de agrupación seleccionada si la edición no está activa
    if (!this.editionActive && this.mapType != "agrupationEdition") {
      this.preventAgrupationReset = false;
    }

    // Reseteo de arrays de coordenadas y marcadores
    if (this.mapType != "control") {
      this.resetMarkersVariables();
      this.getDevicesArrays();
    }

    // Reseteo de paneles
    this.searchActive = false;

    // Reseteo de capas
    if (this.mapType != "agrupationEdition") {
      this.resetLayers(resetByClick);
    }

    // Edición de agrupación si está activa y existe agrupación seleccionada
    if (this.agrupationEditionActive && this.selectedAgrupation) {
      setTimeout(() => {
        this.updateSelectedAgrupation();
        this.scrollAgrupationSelector();
        this.focusAgrupation();
      }, 0);
    }
  }

  // Reseteo de las variables de contadores y gateways
  resetMarkersVariables(): void {
    // Reseteo de gateways
    this.gateways = this.gateways?.map((gateway: any) => {
      gateway.pertenece = false;
      gateway.postInstallation = false;
      return gateway;
    });
    // Reseteo de localizaciones
    this.locations = this.locations?.map((location: any) => {
      location.pertenece = false;
      location.postInstallation = false;
      return location;
    });
    // Reseteo de array actual de contadores
    this.meters = this.meters?.map((meter: any) => {
      meter.deactivate = false;
      meter.otherGateway = false;
      return meter;
    });
    // Reseteo de array original de contadores
    this.metersOriginalArray = this.metersOriginalArray?.map((meter: any) => {
      meter.deactivate = false;
      meter.otherGateway = false;
      return meter;
    });
  }

  // Actualización del array de contadores según los filtros de búsqueda
  searchUpdate(metersFiltered: any[]): void {
    this.filterUpdateFlag.emit(metersFiltered);
    this.meters = metersFiltered;
    this.activateAllLayers = !this.activateAllLayers;
    this.getDevicesArrays();
    this.resetLayers(false, this.clusterIsActive, true);
  }

  // Obtención de la imagen del modal de contadores y gateways
  photoModal(id: any, type: string): void {
    this.HomeController.getElementImg(
      id,
      this.currentAgrupation.id,
      type
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.MaterialDialogService.openDialog(MapModalComponent, {
          show: "photo",
          deviceType: type,
          elementPhoto: response["body"],
        });
      }
    });
  }

  // Centrado del mapa en las coordenadas
  flyTo(coordinates: any, zoom?: number, options?: any): void {
    this.mapComponent.flyTo(coordinates, zoom, options);
  }

  // Recarga del componente
  reloadMapComponent(): void {
    this.ReloadComponentService.reload();
  }

  // Obtención de los dispositivos dentro del polígono dibujado
  getDevicesWithin(selectionLayers: any): void {
    if (selectionLayers?.length > 0) {
      let devices =
        this.mapType == "onlyGateways" ? this.gateways : this.meters;

      if (!this.kmlAssociations) {
        devices = devices.map((device: any) => {
          device.selected = false;
          return device;
        });
      }

      // Comprobación de capas activas
      let devicesInActiveLayers = [];
      this.mapComponent.getMapActiveLayers().forEach((layer: string) => {
        let layerType = this.mapTypeData[layer];
        if (layerType && layerType.type != "selected") {
          devicesInActiveLayers = [
            ...devicesInActiveLayers,
            ...layerType.array,
          ];
        }
      });
      // Comprobación de dispositivos dentro de capa de selección
      devicesInActiveLayers.forEach((device: any) => {
        device.selected =
          device.longitude != null &&
          device.latitude != null &&
          selectionLayers.some((layer) => {
            let feature = layer.toGeoJSON();
            if (feature.geometry.type == "Point") {
              let layerRadius = layer.getRadius() / 1000;
              return (
                Turf.distance(
                  Turf.point([device.longitude, device.latitude]),
                  feature,
                  { units: "kilometers" }
                ) <= layerRadius
              );
            } else {
              return Turf.booleanWithin(
                Turf.point([device.longitude, device.latitude]),
                feature
              );
            }
          });
      });
      this.updateSelected();
      this.selectedDevices.emit(
        devices.filter((device: any) => device.selected)
      );
      this.selectionLayers.emit(selectionLayers);
    } else {
      this.resetSelection();
    }
  }

  // Fijado de seleccionados en mapa
  pinSelected(removeFirst?: boolean): void {
    this.meters.forEach((device) => {
      for (let layer in this.mapTypeData) {
        if (
          !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
          !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
          !layer.toUpperCase().includes("PATH") &&
          !layer.toUpperCase().includes(MAP_LAYERS.TXN)
        ) {
          // Seleccionados
          if (
            this.mapTypeData[layer].markers &&
            this.mapTypeData[layer].markers[device.id] &&
            device.selected
          ) {
            // Fijar
            if (this.selectedPinned) {
              this.mapTypeData[layer].markers[device.id].removeFrom(
                this.mapTypeData[layer].layer
              );
              this.mapTypeData[layer].markers[device.id].addTo(
                this.mapComponent.map
              );
              // Desfijar
            } else {
              if (removeFirst) {
                this.mapTypeData[layer].markers[device.id].removeFrom(
                  this.mapComponent.map
                );
              }
              this.mapTypeData[layer].markers[device.id].addTo(
                this.mapTypeData[layer].layer
              );
            }
          }
        }
      }
    });
  }

  // Actualización de dispositivos seleccionados
  updateSelected(): void {
    this.selectionAvailable = false;
    this.meters.forEach((device) => {
      this.selectionAvailable = this.selectionAvailable || device.selected;
      for (let layer in this.mapTypeData) {
        if (
          !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
          !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
          !layer.toUpperCase().includes("PATH") &&
          !layer.toUpperCase().includes(MAP_LAYERS.TXN)
        ) {
          // Tipo de dispositivo
          let deviceTypeByMask: string =
            this.DeviceTypeService.getDeviceTypeByMask(
              device.tipo,
              device.metrologyType,
              device.fabricante ? device.fabricante : device.idFabricante
            );
          let htmlMarker =
            this.checkMapMarkersType(deviceTypeByMask) ||
            device.metrologyType == METROLOGY_TYPE.SENSOR ||
            device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR;
          // Seleccionados
          if (
            !htmlMarker &&
            this.mapTypeData[layer].markers &&
            this.mapTypeData[layer].markers[device.id] &&
            device.selected &&
            !this.mapTypeData[layer].markers[
              device.id
            ].options.className?.includes("selected")
          ) {
            this.mapTypeData[layer].markers[device.id].setStyle({
              fillColor: MAP_CONFIG.markerVariables["SELECTED"].iconColor,
              className:
                this.mapTypeData[layer].markers[device.id].options.className +
                " selected",
            });
            // No seleccionados con clase de selección activa
          } else if (
            !htmlMarker &&
            this.mapTypeData[layer].markers &&
            this.mapTypeData[layer].markers[device.id] &&
            !device.selected &&
            this.mapTypeData[layer].markers[
              device.id
            ].options.className?.includes("selected")
          ) {
            this.mapTypeData[layer].markers[device.id].setStyle({
              fillColor: MAP_CONFIG.markerVariables[layer].iconColor,
              className: this.mapTypeData[layer].markers[
                device.id
              ].options.className.replace(" selected", ""),
            });
          }
          // Marcadores HTML
          if (
            htmlMarker &&
            this.mapTypeData[layer].markers &&
            this.mapTypeData[layer].markers[device.id]
          ) {
            let deviceType: string = this.DeviceTypeService.getDeviceTypeByMask(
              device.tipo,
              device.metrologyType,
              device.fabricante ? device.fabricante : device.idFabricante
            );
            let options = this.MapDeviceIconService.getDeviceIcon(
              this.mapType,
              device,
              layer,
              deviceType,
              device.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR,
              device.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
            );
            this.mapTypeData[layer].markers[device.id].setIcon(
              L.divIcon(options.icon)
            );
          }
        }
      }
    });

    // Refresco de clústeres
    for (let layer in this.mapTypeData) {
      if (
        this.clusterIsActive &&
        this.mapComponent.activeLayers.includes(layer) &&
        !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
        !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
        !layer.toUpperCase().includes("PATH") &&
        this.mapTypeData[layer].array?.length > 0
      ) {
        this.mapTypeData[layer]?.layer?.refreshClusters();
      }
    }
  }

  // Apertura de tooltip de elemento
  openElementTooltip(element: any): void {
    for (let layer in this.mapTypeData) {
      for (let marker in this.mapTypeData[layer]?.markers) {
        if (element && marker == String(element.id)) {
          this.mapTypeData[layer]?.markers[marker].openTooltip();
        } else {
          this.mapTypeData[layer]?.markers[marker].closeTooltip();
        }
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Capas poligonales de agrupaciones
  /***************************************************************************/

  // Control del toggle de los polígonos de agrupaciones
  toggleAgrupationPolygons(): void {
    this.filterAgrupation = null;
    this.agrupationPolygonActive = !this.agrupationPolygonActive;
    this.mapComponent.updateMarkersPointerEvents(this.agrupationPolygonActive);
    this.resetLayers();
  }

  // Seteo de las capas de polígonos del mapa de cobertura
  setAgrupationLayers(): void {
    if (this.agrupationPolygonActive && this._drawAgrupationOutline) {
      this.agrupationList = this.currentEntity.agrupations
        .filter(
          (agrupation: Agrupation) =>
            !agrupation.showAllEntity &&
            this.meters.some((meter: any) => meter.agrupation == agrupation.id)
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      // Coordenadas de dispositivos para cada agrupación
      this.currentEntity.agrupations.forEach((agrupation: Agrupation, i) => {
        let latLngs: number[][] = [];
        this.meters.forEach((meter: any) => {
          if (meter.agrupation == agrupation.id) {
            latLngs.push([
              parseFloat(meter.latitude),
              parseFloat(meter.longitude),
            ]);
          }
        });
        if (latLngs.length > 0) {
          // Coordenadas del contorno
          let outlineCoords: any;
          if (this.outlineCoordsSave[i]) {
            outlineCoords = this.outlineCoordsSave[i];
          } else {
            outlineCoords = this.getOutlineCoords(latLngs);
            this.outlineCoordsSave[i] = outlineCoords;
          }
          // Dispositivos con comunicación
          let okDevices = this.mapTypeData[MAP_LAYERS.OK]?.array?.filter(
            (meter: any) => meter.agrupation == agrupation.id
          );
          // Dispositivos sin comunicación
          let noCommunicationDevices = this.mapTypeData[
            MAP_LAYERS.NO_COMUNICA
          ]?.array?.filter((meter: any) => meter.agrupation == agrupation.id);
          // Dispositivos pendientes
          let pendingDevices = this.mapTypeData[
            MAP_LAYERS.NO_ASIGNADO
          ]?.array?.filter((meter: any) => meter.agrupation == agrupation.id);
          // Dispositivos con alarmas
          let deviceWithAlarms = this.meters?.filter(
            (meter: any) => meter.agrupation == agrupation.id && meter.alarm
          );
          // Creación de la capa poligonal de agrupación
          // Colores de las agrupaciones
          let agrupationColor: string =
            this.currentAgrupation.virtual || this.agrupationEditionActive
              ? RANDOM_COLORS[i]
              : RANDOM_COLORS[0];
          let textColor: string = "white";
          // Capa de polígono de agrupación
          this.newMapLayer(
            "agrupation" + agrupation.id,
            "agrupation " + agrupation.name,
            L.polygon(outlineCoords, {
              className: "agrupation" + agrupation.id,
              color: agrupationColor,
              weight: 5.0,
              fill: true,
              fillColor: agrupationColor,
              fillOpacity: this.agrupationEditionActive
                ? 0.2
                : this.selectedAgrupation == null
                ? 0.5
                : this.selectedAgrupation >= 0 &&
                  agrupation.id == this.selectedAgrupation
                ? 0.5
                : 0.2,
              opacity: this.agrupationEditionActive
                ? 0.4
                : this.selectedAgrupation == null
                ? 1
                : this.selectedAgrupation >= 0 &&
                  agrupation.id == this.selectedAgrupation
                ? 1
                : 0.4,
            })
              // Tooltip de información al pasar el ratón
              .off("mouseover")
              .on("mouseover", (e: any) => {
                e.target.unbindTooltip();
                e.target.bindTooltip(
                  this.agrupationEditionActive
                    ? `<div class="map-agrupation-tooltip">` +
                        agrupation.name +
                        `</div>`
                    : `<div class="map-agrupation-tooltip">
                      <span style="background: ` +
                        agrupationColor +
                        `; text-shadow: 0.5px 0 #2c2c2c, -0.5px 0 #2c2c2c, 0 0.5px #2c2c2c, 0 -0.5px #2c2c2c,
                      1px 1px #2c2c2c, -1px -1px #2c2c2c, 1px -1px #2c2c2c, -1px 1px #2c2c2c` +
                        `; text-align: center; color: ` +
                        textColor +
                        `"><b>` +
                        agrupation.name.toUpperCase() +
                        `</b></span><table style="font-weight: bold"><tr><th>` +
                        this.translate.instant("with-communication") +
                        `:</th> <td style="color: green">` +
                        okDevices?.length +
                        `</td></tr><tr><th>` +
                        this.translate.instant("no-communication") +
                        `:</th> <td style="color: red">` +
                        noCommunicationDevices?.length +
                        `</td></tr><tr><th>` +
                        this.translate.instant("no-assigned") +
                        `:</th> <td style="color: purple">` +
                        pendingDevices?.length +
                        `</td></tr><tr><th>` +
                        this.translate.instant("with-alarms") +
                        `:</th> <td style="color: red">` +
                        deviceWithAlarms?.length +
                        "</td></tr></table>" +
                        `<div class="map-tooltip-mouse-info">
                          <div><i class="fas fa-mouse mouse-left-click"></i>
                          <span>` +
                        this.translate.instant("agrupation-zoom") +
                        `</span></div>
                          <div><i class="fas fa-mouse mouse-right-click"></i>
                          <span>` +
                        this.translate.instant("agrupation-redirection") +
                        `</span></div>
                        </div>` +
                        `</div>`,
                  {
                    sticky: true,
                  }
                );
                e.target.openTooltip();
              })
              // Mostrar contadores al hacer click
              .off("click")
              .on("click", (e: any) => {
                this.mapComponent.updateMarkersPointerEvents();
                this.selectedAgrupation = agrupation.id;
                this.preventAgrupationReset = true;
                this.filterAgrupation = agrupation.id;
                this.bounds = outlineCoords;
              })
              // Ir a agrupación con click derecho
              .off("contextmenu")
              .on("contextmenu", (e: any) => {
                // Uso de zone para evitar warning por redirección desde fuera de Angular
                this.zone.run(() =>
                  this.SessionDataService.sendAgrupation(agrupation)
                );
              }),
            false
          );
        }
      });
    }
  }

  // Actualización de agrupación seleccionada
  updateSelectedAgrupation(agrupation?: number): void {
    this.mapComponent.updateSelectedAgrupation(
      agrupation ? agrupation : this.selectedAgrupation
    );
  }

  // Centrado en agrupación
  focusAgrupation(agrupation?: number): void {
    this.mapComponent.focusAgrupation(
      agrupation ? agrupation : this.selectedAgrupation
    );
  }

  // Comprobación de selector de agrupación
  scrollAgrupationSelector(): void {
    let selectedAgrupationIndex = this.agrupationList.findIndex(
      (agrupation: Agrupation) => agrupation.id == this.selectedAgrupation
    );
    this.cdkVirtualScrollViewPort.scrollToIndex(selectedAgrupationIndex);
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }

  // Obtención de contorno de la agrupación mediante librería Turf
  getOutlineCoords(latLngs: number[][]): any {
    let featureCollection = Turf.featureCollection(
      latLngs.map((latLng: number[]) => {
        return Turf.point([latLng[1], latLng[0]]);
      })
    );
    let options = { concavity: MAP_CONFIG.agrupationPolygon.concavity };
    let polygon = Turf.convex(featureCollection, options);
    let latLngPolygon = polygon?.geometry?.coordinates.map((feature) => {
      return feature.map((latLng) => {
        return [latLng[1], latLng[0]];
      });
    });
    return latLngPolygon ? latLngPolygon : latLngs;
  }

  // Actualización de polígonos de agrupación
  updateAgrupationPolygon(layer: any): void {
    this.updateAgrupationDevices(layer);
    this.outlineCoordsSave = [];
    this.freeDrawEnabled = false;
  }

  // Actualización de dispositivos en la agrupación
  updateAgrupationDevices(layer: any): void {
    let newMetersInAgrupation: AgrupationChanges = {
      newAgrupationId: this.selectedAgrupation,
      newAgrupationName: this.agrupationList.find(
        (agrupation: Agrupation) => agrupation.id == this.selectedAgrupation
      )?.name,
      newDevices: [],
    };
    let feature = layer.toGeoJSON();
    // Comprobación de dispositivos en el polígono
    this.meters.forEach((meter: any) => {
      if (
        Turf.booleanPointInPolygon(
          Turf.point([meter.longitude, meter.latitude]),
          feature
        )
      ) {
        if (meter.agrupation != this.selectedAgrupation) {
          let oldAgrupationIndex = newMetersInAgrupation.newDevices.findIndex(
            (oldAgrupation: any) =>
              oldAgrupation.agrupationId == meter.agrupation
          );
          if (oldAgrupationIndex >= 0) {
            newMetersInAgrupation.newDevices[oldAgrupationIndex].devices.push(
              meter
            );
          } else {
            newMetersInAgrupation.newDevices.push({
              agrupationId: meter.agrupation,
              agrupationName: this.agrupationList.find(
                (agrupation: Agrupation) => agrupation.id == meter.agrupation
              )?.name,
              devices: [meter],
            });
          }
          meter.agrupation = this.selectedAgrupation;
          meter.selected = true;
        }
      }
    });
    this.agrupationChanges.emit(newMetersInAgrupation);
  }

  // Edición de polígono de agrupación
  editAgrupationPolygon(): void {
    let agrupationLayer = this.mapComponent.getEditedAgrupation(
      this.selectedAgrupation
    );
    this.updateAgrupationPolygon(agrupationLayer);
  }

  // Dibujado de polígono por coordenadas
  drawPolygon(layerName: string, coords: number[][]): void {
    let outlineCoords = this.getOutlineCoords(coords);
    this.newMapLayer(
      "polygon",
      layerName,
      L.polygon(outlineCoords, {
        className: "polygon",
        color: "yellow",
        weight: 5.0,
        fill: true,
        fillColor: "yellow",
        fillOpacity: 0.5,
        opacity: 0.4,
      }),
      true
    );
    this.activateAllLayers = !this.activateAllLayers;
  }

  // Borrado de polígono
  deletePolygon(): void {
    this.mapLayersData = this.mapLayersData.filter(
      (layer) => layer.id != "polygon"
    );
  }

  /***************************************************************************/
  // ANCHOR KML de asociaciones
  /***************************************************************************/

  // Visualizar KML de asociaciones
  toggleAssociationKml(): void {
    this.filterAgrupation = null;
    this.agrupationPolygonActive = false;
    this.importKmlPolygonInput.nativeElement.value = null;
    this.importKmlPolygonInput?.nativeElement?.click();
  }

  // Visualizar KML de imagen
  toggleImageKml(): void {
    this.filterAgrupation = null;
    this.agrupationPolygonActive = false;
    this.importKmlImageInput.nativeElement.value = null;
    this.importKmlImageInput?.nativeElement?.click();
  }

  // Visualizar KML de puntos
  togglePointKml(): void {
    this.filterAgrupation = null;
    this.agrupationPolygonActive = false;
    this.importKmlPointInput.nativeElement.value = null;
    this.importKmlPointInput?.nativeElement?.click();
  }

  // Carga de archivo XML
  fileChanged(e: any, type: string): void {
    if (e.target.files) {
      if (type == "image") {
        this.MapKmlService.parseImageKmlFiles(e.target.files);
      } else if (type == "polygon") {
        if (e.target.files[0]?.name) {
          this.MapKmlService.parsePolygonKml(e.target.files[0]);
        }
      } else if (type == "point") {
        if (e.target.files[0]?.name) {
          this.MapKmlService.parsePointsKml(e.target.files[0]);
        }
      } else if (type == "line") {
        if (e.target.files[0]?.name) {
          this.MapKmlService.parseShapefile(e.target.files[0]);
        }
      }
    }
  }

  // Creación de la capa de asociaciones
  setAssociationLayer(): void {
    this.currentKmlAssociations.forEach((association: MapKmlAssociation, i) => {
      // Capa de polígono de agrupación
      this.newMapLayer(
        "association" + i,
        "association " + i,
        L.polygon(association.coords, {
          className: "association " + i,
          color: RANDOM_COLORS[i],
          weight: 5.0,
          fill: true,
          fillColor: RANDOM_COLORS[i],
          fillOpacity: 0.5,
          opacity: 1,
        })
          // Seleccionar contadores al hacer click
          .off("click")
          .on("click", (e: L.LeafletMouseEvent) => {
            this.meters.map((meter) => {
              if (
                Turf.booleanPointInPolygon(
                  Turf.point([meter.longitude, meter.latitude]),
                  e.target.toGeoJSON()
                )
              ) {
                meter.selected = true;
              } else {
                if (!this.ctrlActive) {
                  meter.selected = false;
                }
              }
            });
            this.updateSelected();
            this.selectedDevices.emit(
              this.meters.filter((device: any) => device.selected)
            );
          }),
        false
      );
    });
    this.kmlAssociationsActive = true;
    this.mapComponent.resetLayers();
  }

  // Creación de la capa de puntos
  setPointsLayers(): void {
    const self = this;
    this.currentKmlPoints.forEach((points: MapKmlAssociation, i) => {
      let pointMarkers = [];
      points.coords.forEach((coord) => {
        pointMarkers.push(
          L.circle(coord, {
            radius: 5,
            weight: 1.0,
            color: "black",
            fillColor: RANDOM_COLORS[i],
            fillOpacity: 1,
          })
        );
      });
      let newLayerGroup = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return L.divIcon({
            html:
              `<div style="background-color: ` +
              self.ColorService.hexToRgbA(
                self.ColorService.lightenDarkenColor(RANDOM_COLORS[i], 100),
                0.5
              ) +
              `"><div style="background-color: ` +
              RANDOM_COLORS[i] +
              `"><span>` +
              cluster.getChildCount() +
              `</span></div></div>`,
            className: "marker-cluster-point",
            iconSize: new L.Point(40, 40),
          });
        },
      });
      let polygon = this.getOutlineCoords(points.coords);
      newLayerGroup.addLayer(
        L.polygon(polygon, {
          className: "points " + i,
          color: RANDOM_COLORS[i],
          weight: 5.0,
          fill: true,
          fillColor: RANDOM_COLORS[i],
          fillOpacity: 0.5,
          opacity: 1,
        })
      );
      newLayerGroup.addLayers(pointMarkers);
      this.newMapLayer("points" + i, points.name, newLayerGroup, true);
    });
    this.clusterIsActive = true;
    this.activateAllLayers = !this.activateAllLayers;
    this.mapComponent.resetLayers();
  }

  /***************************************************************************/
  // ANCHOR Shapefile
  /***************************************************************************/

  // Creación de la capa de líneas
  setShapefileLayers(projectedCoords: boolean): void {
    if (!(this.currentShapefile instanceof Array)) {
      this.currentShapefile = [this.currentShapefile];
    }
    let crs = this.getUtmZoneCrs();
    let featureIndex = 0;
    // Creación de capas con parseo de coordenadas UTM
    this.currentShapefile.forEach((shapeLayer: any, i) => {
      this.newMapLayer(
        "geoJSON" + i,
        shapeLayer.fileName,
        L.Proj.geoJson(shapeLayer.features, {
          coordsToLatLng: projectedCoords
            ? function (coords) {
                var point = L.point(coords[0], coords[1]);
                var latLng = crs.unproject(point);
                return latLng;
              }
            : null,
          // Estilos por forma
          style: (feature) => {
            return this.setFeatureStyle(feature.geometry.type, featureIndex++);
          },
          // Marcador de punto
          pointToLayer: function (feature, latlng) {
            return L.circleMarker(latlng);
          },
        }),
        true
      );
    });
    this.activateAllLayers = !this.activateAllLayers;
  }

  // Obtención del CRS del mapa según zon UTM
  getUtmZoneCrs(): L.Proj.CRS {
    // Cálculo de zona UTM
    let line = Turf.lineString(
      this.meters.map((meter) => {
        return [meter.longitude, meter.latitude];
      })
    );
    let bbox = Turf.bbox(line);
    let area = Turf.bboxPolygon(bbox);
    let center = Turf.centerOfMass(area);
    let utmZone = L.latLng(
      center.geometry.coordinates[1],
      center.geometry.coordinates[0]
    ).utm();
    // CRS acorde a zona UTM
    return new L.Proj.CRS(
      "EPSG:" + MAP_CRS[utmZone.southHemi ? "south" : "north"][utmZone.zone],
      "+proj=utm +zone=" +
        utmZone.zone +
        "+ellps=WGS84 +datum=WGS84 +units=m +no_defs"
    );
  }

  // Seteo de los estilos por forma
  setFeatureStyle(type: string, featureIndex?: number): object {
    if (type == "LineString" || type == "MultiLineString") {
      return {
        color: SHAPE_COLORS.line,
        weight: 2,
      };
    } else if (type == "Polygon" || type == "MultiPolygon") {
      return {
        // fillColor: SHAPE_COLORS.polygon,
        // color: SHAPE_COLORS.polygonLine,
        fillColor: RANDOM_COLORS[featureIndex],
        color: RANDOM_COLORS[featureIndex],
        weight: 1,
        opacity: 1,
        fillOpacity: 0.4,
      };
    } else if (type == "Point" || type == "MultiPoint") {
      return {
        radius: 8,
        fillColor: SHAPE_COLORS.point,
        color: "black",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    }
  }

  /***************************************************************************/
  // ANCHOR Capa de mapa de calor
  /***************************************************************************/

  // Seteo de la capa de calor
  setHeatLayer(layerToReplace?: string): void {
    if (this._heatLayerData) {
      let newLayer: any = {
        name: this.heatLayerName,
        data: L.heatLayer(this._heatLayerData, {
          minOpacity: 0,
          max: this.heatLayerRange ? this.heatLayerRange : this.heatLayerMin,
          maxZoom: 0,
          radius: this._heatLayerRadius,
          gradient: this.heatLayerGradient,
        }),
        overlay: false,
      };
      this.bounds = [];
      this._heatLayerData.forEach((data: any) =>
        this.bounds.push([data[0], data[1]])
      );
      if (layerToReplace) {
        let heatLayerIndex = this.mapLayersData.findIndex(
          (layer) => layer.name == layerToReplace
        );
        if (heatLayerIndex >= 0) {
          this.mapLayersData.splice(heatLayerIndex, 1, newLayer);
        } else {
          this.mapLayersData.push(newLayer);
        }
      } else {
        this.mapLayersData.push(newLayer);
      }
      this.updateHeatMapFlag = !this.updateHeatMapFlag;
    }
  }

  /***************************************************************************/
  // ANCHOR Mapa poligonal de cobertura
  /***************************************************************************/

  // Seteo de las capas del mapa de cobertura
  setCoverageLayer(): void {
    let redValues: any[] = this.meters?.filter(
      (value: any) =>
        !value.ignoreCoverValue &&
        value.averageValue != null &&
        value.averageValue <= this.polygonHeatValues[0]
    );
    let orangeValues: any[] = this.meters?.filter(
      (value: any) =>
        !value.ignoreCoverValue &&
        value.averageValue > this.polygonHeatValues[0] &&
        value.averageValue <= this.polygonHeatValues[1]
    );
    let yellowValues: any[] = this.meters?.filter(
      (value: any) =>
        !value.ignoreCoverValue &&
        value.averageValue > this.polygonHeatValues[1] &&
        value.averageValue <= this.polygonHeatValues[2]
    );
    let limeValues: any[] = this.meters?.filter(
      (value: any) =>
        !value.ignoreCoverValue &&
        value.averageValue > this.polygonHeatValues[2] &&
        value.averageValue < this.polygonHeatValues[3]
    );
    let greenValues: any[] = this.meters?.filter(
      (value: any) =>
        !value.ignoreCoverValue &&
        value.averageValue != null &&
        value.averageValue >= this.polygonHeatValues[3]
    );
    let blackValues: any[] = this.meters?.filter(
      (value: any) => !value.ignoreCoverValue && value.averageValue == null
    );

    this.setValuesLayer(
      blackValues,
      "black",
      this.translate.instant("coverage-null"),
      this.translate.instant("coverage-null"),
      Math.round(this.polygonHeatWeight / 2)
    );
    this.setValuesLayer(
      redValues,
      "red",
      this.translate.instant("coverage-critical"),
      this.translate.instant("coverage-critical-rssi")
    );
    this.setValuesLayer(
      orangeValues,
      "orange",
      this.translate.instant("coverage-low"),
      this.translate.instant("coverage-low-rssi")
    );
    this.setValuesLayer(
      yellowValues,
      "yellow",
      this.translate.instant("coverage-mid"),
      this.translate.instant("coverage-mid-rssi")
    );
    this.setValuesLayer(
      limeValues,
      "limeGreen",
      this.translate.instant("coverage-high"),
      this.translate.instant("coverage-high-rssi")
    );
    this.setValuesLayer(
      greenValues,
      "green",
      this.translate.instant("coverage-total"),
      this.translate.instant("coverage-total-rssi")
    );
  }

  // Seteo de las capas de polígonos del mapa de cobertura
  setValuesLayer(
    values: any[],
    color: string,
    layerName: string,
    tooltipTitle: string,
    polygonWeight?: number
  ): void {
    let latLngs: any[] = [];
    let latitudes: any[] = [];
    let longitudes: any[] = [];
    values?.forEach((value: any) => {
      latLngs.push([value.latLngs]);
      value.latLngs.map((latLng: any) => {
        latitudes.push(latLng[0]);
        longitudes.push(latLng[1]);
      });
      this.bounds.push(value.latLngs);
    });
    if (latLngs.length > 0) {
      this.newMapLayer(
        layerName,
        layerName,
        L.polygon(latLngs, {
          color: color,
          weight: polygonWeight ? polygonWeight : this.polygonHeatWeight,
          fill: true,
          fillColor: color,
          fillOpacity: 1.0,
        }).bindTooltip(
          `<div class="map-coverage-tooltip">` +
            tooltipTitle +
            `
          </div>`,
          {
            sticky: true,
          }
        ),
        true
      );
    }
  }

  /***************************************************************************/
  // ANCHOR Capas de imagen
  /***************************************************************************/

  setImageLayer(): void {
    if (this.imageOverlayArray?.length > 0) {
      this.imageOverlayArray.forEach((imageOverlay: ImageOverlay) => {
        this.newMapLayer(
          imageOverlay.name,
          imageOverlay.name,
          L.imageOverlay(imageOverlay.image, imageOverlay.coords, {
            opacity: imageOverlay.opacity ? imageOverlay.opacity : 0.5,
          }),
          true
        );
      });
      this.activateAllLayers = !this.activateAllLayers;
    }
  }

  /***************************************************************************/
  // ANCHOR Capas de dispositivos
  /***************************************************************************/

  // Creación de los marcadores de contadores por tipo
  getMeterLayer(layer: string, meterArray: any[]): any {
    let markerMeterArray: any[] = [];
    let markerMeter = {};
    let newLayerGroup: any;

    // Marcadores
    meterArray?.forEach((meter: any) => {
      if (
        !this.agrupationPolygonActive ||
        (this.agrupationPolygonActive &&
          meter.agrupation == this.filterAgrupation) ||
        (this.agrupationPolygonActive && meter.selected)
      ) {
        // Comprobación de tipo UNE
        let deviceType: string = this.DeviceTypeService.getDeviceTypeByMask(
          meter.tipo,
          meter.metrologyType,
          meter.fabricante ? meter.fabricante : meter.idFabricante
        );

        // Tooltip para cada contador
        let tooltip: string = this.MapDeviceTooltipService.getDeviceTooltip(
          meter,
          layer,
          this.gateways,
          this.sessionProfile,
          this.currentAgrupation,
          this.mapType
        );
        let title: string = this.MapDeviceTooltipService.getDeviceTitle(
          meter,
          this.mapType,
          layer
        );

        // Obtención de opciones dependiendo del tipo de mapa
        let options: any = this.MapDeviceIconService.getDeviceIcon(
          this.mapType,
          meter,
          layer,
          deviceType,
          meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR,
          meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
        );

        // Marcador de dispositivo
        let marker = this.getMeterMarker(
          meter,
          layer,
          deviceType,
          options,
          tooltip,
          title
        );
        markerMeter[meter.id] = marker;

        // Área para marcadores de sensor de ruido
        if (
          this.showMeterRange &&
          this.mapType == "leakDetection" &&
          !this.clusterIsActive &&
          meter.distanciaAcustica &&
          meter.selected
        ) {
          markerMeterArray.push(
            L.circle([meter.latitude, meter.longitude], {
              radius: meter.extraRange
                ? meter.extraRange
                : meter.distanciaAcustica,
              fillOpacity: 0.2,
              color: "cadetblue",
            }),
            L.circle([meter.latitude, meter.longitude], {
              radius: meter.distanciaAcustica,
              fillOpacity: 0.5,
            })
          );
        }
        markerMeterArray.push(marker);
      }
    });
    // Creación del grupo de capas dependiendo de si el cluster está activo o no
    if (this.clusterIsActive) {
      newLayerGroup = L.markerClusterGroup(this.markerClusterOptions);
      newLayerGroup.addLayers(markerMeterArray);
    } else {
      newLayerGroup = L.layerGroup(markerMeterArray);
    }

    return { markers: markerMeter, layer: newLayerGroup };
  }

  // Obtención del marcador de dispositivo
  getMeterMarker(
    meter: any,
    mapMeterType: string,
    deviceType: string,
    options: any,
    tooltip: string,
    title: string
  ): any {
    // Marcador de abonado
    if (this.sessionProfile == PROFILES.ABONADO) {
      return L.marker([meter.latitude, meter.longitude], {
        icon: L.divIcon(options.icon),
        title: options.title,
        riseOnHover: true,
        autoPan: true,
      }).on("click", (e: any) => {
        this.actionFlag.emit(meter);
      });
      // Marcador para cambio de localización
    } else if (this.mapType == "changeLocation") {
      return L.marker([meter.latitude, meter.longitude], {
        icon: L.divIcon(options.icon),
        riseOnHover: true,
        autoPan: true,
        draggable: true,
      }).on("dragend", (event: any) => {
        this.meterNewLocation.emit(event.target.getLatLng());
      });
      // Marcador para TXN
    } else if (deviceType == DEVICE_BY_COMM.TXN) {
      return L.marker([meter.latitude, meter.longitude], {
        icon: L.divIcon(options.icon),
        riseOnHover: true,
        autoPan: true,
      })
        .bindTooltip(title)
        .setZIndexOffset(MAP_CONFIG.markerVariables[mapMeterType].markerZindex);
    } else {
      // Sensores
      if (
        this.checkMapMarkersType(deviceType) ||
        meter.metrologyType == METROLOGY_TYPE.SENSOR ||
        meter.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      ) {
        let tooltipOptions: L.TooltipOptions =
          this.mapType == "pressureDetection" || this.mapType == "flowDetection"
            ? {
                content: title,
                permanent: true,
                offset: [0, 8],
                direction: "bottom",
              }
            : {
                content: title,
              };
        // Sensor de ruido
        if (this.mapType == "leakDetection") {
          return L.marker([meter.latitude, meter.longitude], {
            icon: L.divIcon(options.icon),
            riseOnHover: true,
            autoPan: true,
          })
            .setZIndexOffset(
              MAP_CONFIG.markerVariables[mapMeterType].markerZindex
            )
            .off("click")
            .on("click", (e: any) =>
              this.setClickDefaultActions(e, meter, mapMeterType, deviceType)
            )
            .off("contextmenu")
            .on("contextmenu", (e: any) => {
              this.actionFlag.emit({ action: "goTo", sensor: meter });
            })
            .bindTooltip(L.tooltip(tooltipOptions));
          // Sensor de caudal
        } else if (this.mapType == "flowDetection") {
          return L.marker([meter.latitude, meter.longitude], {
            icon: L.divIcon(options.icon),
            riseOnHover: true,
            autoPan: true,
          })
            .bindTooltip(L.tooltip(tooltipOptions))
            .setZIndexOffset(
              MAP_CONFIG.markerVariables[mapMeterType].markerZindex
            );
        } else {
          // Marcadores HTML
          return L.marker([meter.latitude, meter.longitude], {
            icon: L.divIcon(options.icon),
            riseOnHover: true,
            autoPan: true,
          })
            .bindPopup(tooltip)
            .bindTooltip(L.tooltip(tooltipOptions))
            .on("dragend", (event: any) => {
              this.meterNewLocation.emit(event.target.getLatLng());
            })
            .on("click", (e: any) =>
              this.setClickDefaultActions(e, meter, mapMeterType, deviceType)
            )
            .setZIndexOffset(
              MAP_CONFIG.markerVariables[mapMeterType].markerZindex
            );
        }
        // Marcadores Canvas
      } else {
        let iconSize =
          parseFloat(
            document.documentElement.style.getPropertyValue("--map-icon-size")
          ) * 8;
        return L.circleMarker([meter.latitude, meter.longitude], {
          className: options.icon.className,
          radius: iconSize,
          color: "black",
          weight: 1,
          fillColor: options.icon.color,
          fillOpacity: 1,
          pane: "circlePane",
        })
          .bindPopup(tooltip)
          .bindTooltip(title)
          .on("dragend", (event: any) => {
            this.meterNewLocation.emit(event.target.getLatLng());
          })
          .on("click", (e: any) =>
            this.setClickDefaultActions(e, meter, mapMeterType)
          );
      }
    }
  }

  // Comprobación de tipo de marcadores a dibujar
  checkMapMarkersType(deviceType: string): boolean {
    return (
      this.mapType == "coverage" ||
      this.mapType == "control" ||
      this.mapType == "leakDetection" ||
      this.mapType == "pressureDetection" ||
      this.mapType == "flowDetection" ||
      deviceType == DEVICE_BY_COMM.LW_MBUS_CON ||
      deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
      deviceType == DEVICE_BY_COMM.EK280 ||
      deviceType == DEVICE_BY_COMM.OWASYS ||
      deviceType == DEVICE_BY_COMM.PLUM ||
      deviceType == DEVICE_BY_COMM.EXTERNO ||
      deviceType == DEVICE_BY_COMM.API ||
      deviceType == DEVICE_BY_COMM.ERM ||
      deviceType == DEVICE_BY_COMM.TXN
    );
  }

  // Seteo de acciones de click en contador
  setClickDefaultActions(
    e: any,
    meter: any,
    mapMeterType?: string,
    deviceType?: string
  ): void {
    // Borrado de paths
    if (
      this.mapLayersData.some((layer) =>
        layer.id?.toUpperCase().includes("PATH")
      )
    ) {
      this.mapLayersData = this.mapLayersData.filter(
        (layer) => !layer.id?.toUpperCase().includes("PATH")
      );
      this.mapComponent.resetLayers();
    }
    // Control de nueva pestaña
    $(".map-link")
      .off("contextmenu")
      .on("contextmenu", (e) => {
        e.preventDefault();
        this.RedirectToService.openNewTab(
          e.target.href.substring(e.target.href.indexOf("#") + 2)
        );
      });
    // Contadores Mbus recibidos por concentrador LW MBUS
    if (
      this.mapType == "standard" &&
      deviceType == DEVICE_BY_COMM.LW_MBUS_CON
    ) {
      this.findMbusLinked(meter);
    }
    // Acciones por tipo de mapa
    switch (this.mapType) {
      case "coverage":
        $(".removeTest")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ action: "removeTest", data: meter });
          });
        this.actionFlag.emit({
          action: "updateLocations",
          data: meter,
        });
        break;
      case "control":
        $(".showInfo")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit(meter);
          });
        break;
      case "alarmsMeterList":
        $(".alarmLink")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit(meter);
          });
        break;
      case "balanceEdition":
        $(".father")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ meter: meter, type: "FATHER" });
          });
        $(".child")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ meter: meter, type: "CHILD" });
          });
        break;
      case "gatewayDetail":
        $(".allocateDevice")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ action: "allocateDevice", data: meter });
          });
        $(".allocateDeviceAsMain")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({
              action: "allocateDeviceAsMain",
              data: meter,
            });
          });
        $(".deallocateDevice")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ action: "deallocateDevice", data: meter });
          });
        break;
      case "meterAssignable":
        this.actionFlag.emit(meter);
        break;
      case "meterList":
        this.actionFlag.emit(meter);
        break;
      case "leakDetection":
        this.actionFlag.emit({ action: "select", sensor: meter });
        break;
      case "pressureDetection":
        $(".leakSensorGraph")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ action: "showGraph", data: meter });
          });
        break;
      case "mbusConcentratorDetail":
        $(".lwMbusFixDevice")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit({ action: "lwMbusFixDevice", data: meter });
          });
        break;
      // Acción para el mapa de cobertura
      case "coverageMbusMap":
        $(".filterConcentrator")
          .off("click")
          .on("click", () => {
            this.actionFlag.emit(meter);
          });
        break;
      default:
        // Actualización de popup
        if (this.mapType == "standard" || this.mapType == "meterGraph") {
          this.updateTooltip(e, meter, mapMeterType);
        } else {
          this.bindTooltipActions(meter);
        }
        // Posicionamiento del mapa sobre el contador
        if (!this.clusterIsActive) {
          this.mapComponent.flyTo(e.target.getLatLng());
        }
        // Reseteo y creación de la capa de path del contador al gateway correspondiente
        if (this.mapTypeData[MAP_LAYERS.GATEWAYS]) {
          this.showPath(meter);
        }
        break;
    }
  }

  // Actualización del tooltip
  updateTooltip(e: any, device: any, deviceType: string): void {
    // this.mapComponent.cleanPopups();
    // e.target.unbindTooltip();
    this.HomeController.getMarkerTooltip(device.id).subscribe((response) => {
      if (response["code"] == 0) {
        this.MapDeviceMinimalParseService.parseTooltipData(
          device,
          response["body"]
        );
        e.target.bindPopup(
          this.MapDeviceTooltipService.getDeviceTooltip(
            device,
            deviceType,
            this.gateways,
            this.sessionProfile,
            this.currentAgrupation,
            this.mapType
          )
        );
        this.bindTooltipActions(device);

        // Link de contador MBUS con concentrador
        if (device.listaConcentradores?.length > 0) {
          this.showConcentratorPath(
            device,
            device.listaConcentradores.map(
              (concentrator) => concentrator.idConcentrador
            )
          );
        }
        // e.target.openPopup();
      }
    });
  }

  // Bindeo de acciones del tooltip
  bindTooltipActions(meter: any): void {
    // Opción de ir al gateway enlazado con el contador
    $(".findGateway")
      .off("click")
      .on("click", () => {
        this.goToGateway(meter);
      });
    // Llamada al modal con la foto del contador
    $(".photoModal")
      .off("click")
      .on("click", () => {
        this.photoModal(meter.id, "meter");
      });
    // Control de nueva pestaña
    $(".map-link")
      .off("contextmenu")
      .on("contextmenu", (e) => {
        e.preventDefault();
        this.RedirectToService.openNewTab(
          e.target.href.substring(e.target.href.indexOf("#") + 2)
        );
      });
  }

  /***************************************************************************/
  // ANCHOR Capas de gateways
  /***************************************************************************/

  // Filtrado de los gateways para cada capa
  gatewayFilter(gatewayType: string): any[] {
    switch (gatewayType) {
      case "GatewaysOk":
        return this.gateways.filter((gateway: any) => gateway.comunica);
      case "GatewaysError":
        return this.gateways.filter((gateway: any) => !gateway.comunica);
      default:
        return this.gateways;
    }
  }

  // Creación de los marcadores de los gateways
  getLocationLayer(locationArray: any[]): any {
    let markerLocationArray: any[] = [];
    let locationMarkers: object = {};

    // Filtrado de localizaciones sin coordenadas de posición
    locationArray
      ?.filter(
        (location: any) =>
          location.latitude != null &&
          location.longitude != null &&
          Math.abs(location.latitude) <= 90 &&
          Math.abs(location.longitude) <= 180
      )
      .forEach((location: any) => {
        // Centrado de mapa si es del tipo solo gateways o detalle de gateway
        if (
          (this.boundsWithGateways &&
            (this.mapType != "gatewayDetail" ||
              (this.mapType === "gatewayDetail" && !location.other)) &&
            (this.mapType != "coverageMap" ||
              (this.mapType === "coverageMap" && location.pertenece))) ||
          this.meters?.length === 0
        ) {
          this.bounds.push([location.latitude, location.longitude]);
        }
        if (location.pertenece) {
          let tooltip =
            this.MapGatewayTooltipService.getLocationPerteneceTooltip(location);
          let tooltipOptions: L.TooltipOptions = {
            permanent: true,
            direction: "top",
            opacity: 1,
            offset: L.point({ x: 0, y: -10 }),
          };
          // Creación del marcador para cada localización
          let options = this.MapGatewayIconService.getLocationIcon(
            this.mapType,
            location
          );
          let markerLocation = L.marker(
            [location.latitude, location.longitude],
            {
              icon: L.divIcon(options.icon),
              riseOnHover: true,
              autoPan: true,
            }
          )
            .bindTooltip(tooltip, tooltipOptions)
            .openTooltip()
            .setZIndexOffset(
              MAP_CONFIG.markerVariables["LOCATION"].markerZindex
            );
          locationMarkers[location.id] = markerLocation;
          markerLocationArray.push(markerLocation);
        } else {
          let tooltip = this.MapGatewayTooltipService.getLocationTooltip(
            this.mapType,
            location,
            [],
            this.currentAgrupation
          );
          let title = this.MapGatewayTooltipService.getLocationTitle(location);
          // Creación del marcador para cada localización
          let options = this.MapGatewayIconService.getLocationIcon(
            this.mapType,
            location
          );
          let markerLocation = L.marker(
            [location.latitude, location.longitude],
            {
              icon: L.divIcon(options.icon),
              riseOnHover: true,
              autoPan: true,
            }
          )
            .bindPopup(tooltip)
            .bindTooltip(title)
            .on("click", (e: any) => {
              switch (this.mapType) {
                case "coverage":
                  this.findMetersLinked(location);
                  break;
                default:
                  this.updateLocationTooltip(e, location);
                  break;
              }
              setTimeout(
                () =>
                  $(".removeLocation")
                    .off("click")
                    .on("click", () => {
                      this.GatewayService.deleteLocation(location.id);
                    }),
                500
              );
            })
            .setZIndexOffset(
              MAP_CONFIG.markerVariables["LOCATION"].markerZindex
            );
          locationMarkers[location.id] = markerLocation;
          markerLocationArray.push(markerLocation);
        }
      });

    let newLayerGroup: any;
    let mapsWithtGatewayCluster: string[] = ["onlyGateways", "coverageMap"];

    // Creación del grupo de capas dependiendo de si el cluster está activo o no
    if (
      this.clusterIsActive &&
      mapsWithtGatewayCluster.includes(this.mapType)
    ) {
      newLayerGroup = L.markerClusterGroup(this.markerClusterOptions);
      newLayerGroup.addLayers(markerLocationArray);
    } else {
      newLayerGroup = L.layerGroup(markerLocationArray);
    }
    return { markers: locationMarkers, layer: newLayerGroup };
  }

  // Actualización del tooltip
  updateLocationTooltip(e: any, location: any): void {
    this.HomeController.getLocationData(location.id).subscribe((response) => {
      if (response["code"] == 0) {
        e.target.bindPopup(
          this.MapGatewayTooltipService.getLocationTooltip(
            this.mapType,
            location,
            response["body"],
            this.currentAgrupation
          )
        );
      }
    });
  }

  // Creación de los marcadores de los gateways
  getGatewayLayer(gatewayArray: any[]): any {
    let markerGatewayArray: any[] = [];
    let mainGateway: any = gatewayArray?.find((gateway: any) => !gateway.other);
    let gatewayMarkers: object = {};

    // Filtrado de gateways sin coordenadas de posición
    gatewayArray
      ?.filter(
        (gateway: any) =>
          gateway.latitude != null &&
          gateway.longitude != null &&
          Math.abs(gateway.latitude) <= 90 &&
          Math.abs(gateway.longitude) <= 180
      )
      .forEach((gateway: any) => {
        // Centrado de mapa si es del tipo solo gateways o detalle de gateway
        if (
          (this.boundsWithGateways &&
            (this.mapType != "gatewayDetail" ||
              (this.mapType === "gatewayDetail" && !gateway.other)) &&
            (this.mapType != "coverageMap" ||
              (this.mapType === "coverageMap" && gateway.pertenece))) ||
          this.meters?.length === 0
        ) {
          this.bounds.push([gateway.latitude, gateway.longitude]);
        }
        // Creación del tooltip para cada gateway
        let tooltip: string = this.getGatewayTooltip(gateway, mainGateway);
        let title: string =
          this.MapGatewayTooltipService.getGatewayTitle(gateway);

        // Creación del marcador para cada gateway
        let markerGateway: L.Marker;
        if (this.mapType != "meterGraph") {
          markerGateway = marker(
            [gateway.latitude, gateway.longitude],
            this.MapGatewayIconService.getGatewayIcon(this.mapType, gateway)
          )
            .bindPopup(tooltip)
            .bindTooltip(title)
            .on("click", (e: any) => {
              // Posicionamiento del mapa sobre el gateway
              if (!this.clusterIsActive) {
                this.mapComponent.flyTo(e.target.getLatLng());
              }

              // Control de nueva pestaña
              $(".map-link")
                .off("contextmenu")
                .on("contextmenu", (e) => {
                  e.preventDefault();
                  this.RedirectToService.openNewTab(
                    e.target.href.substring(e.target.href.indexOf("#") + 2)
                  );
                });

              // Acciones del mapa de detalle de gateway
              if (this.mapType === "meterDetail") {
                $(".assignGateway")
                  .off("click")
                  .on("click", () => {
                    this.actionFlag.emit({ action: "assign", data: gateway });
                  });
              }

              // Acciones del mapa de detalle de gateway
              if (this.mapType === "gatewayDetail") {
                $(".resetGateway")
                  .off("click")
                  .on("click", () => {
                    this.actionFlag.emit({ action: "reset", data: gateway });
                  });
                $(".echoGateway")
                  .off("click")
                  .on("click", () => {
                    this.actionFlag.emit({ action: "echo", data: gateway });
                  });
              }

              // Acción para el mapa de cobertura
              if (this.mapType == "coverageMap") {
                $(".filterGateway")
                  .off("click")
                  .on("click", () => {
                    this.gateways.map(
                      (gateway: any) => (gateway.pertenece = false)
                    );
                    gateway.pertenece = true;
                    this.actionFlag.emit(gateway);
                  });
              }

              // Filtro del mapa de solo gateways
              if (this.mapType == "standard") {
                // Búsqueda de contadores enlazados al gateway
                this.meters = this.meters.map((meter: any) => {
                  meter.deactivate = false;
                  meter.otherGateway = false;
                  return meter;
                });

                this.findMetersLinked(gateway);
              }

              // Llamada al modal con la foto del gateway
              $(".photoModal")
                .off("click")
                .on("click", () => {
                  this.photoModal(gateway.id, "gateway");
                });

              // Actualización de entidad al redirigir a gateway en mapa global de gateways
              if (this.mapType == "onlyGateways") {
                $(".gateway-map-link")
                  .off("click")
                  .on("click", () => {
                    this.getGatewayEntity(gateway.entity);
                  });
              }
            })
            .setZIndexOffset(
              MAP_CONFIG.markerVariables["GATEWAY"].markerZindex
            );
        } else {
          markerGateway = marker(
            [gateway.latitude, gateway.longitude],
            this.MapGatewayIconService.getGatewayIcon(this.mapType, gateway)
          ).on("click", (e: any) => {
            // Acción para el mapa de gráfica de contadores
            if (this.mapType == "meterGraph") {
              this.actionFlag.emit(gateway);
              this.resetGatewayIcons("grayIconUrl");
              this.updateGatewayIcon(gateway.id, "nodeviceIconUrl");
            }
          });
        }
        gatewayMarkers[gateway.id] = markerGateway;
        markerGatewayArray.push(markerGateway);
      });

    let newLayerGroup: any;
    let mapsWithtGatewayCluster: string[] = ["onlyGateways", "coverageMap"];

    // Creación del grupo de capas dependiendo de si el cluster está activo o no
    if (
      this.clusterIsActive &&
      mapsWithtGatewayCluster.includes(this.mapType)
    ) {
      newLayerGroup = L.markerClusterGroup(this.markerClusterOptions);
      newLayerGroup.addLayers(markerGatewayArray);
    } else {
      newLayerGroup = L.layerGroup(markerGatewayArray);
    }
    return { markers: gatewayMarkers, layer: newLayerGroup };
  }

  // Actualización de icono de gateway
  updateGatewayIcon(gatewayId: number, iconName: string): void {
    this.mapTypeData[MAP_LAYERS.GATEWAYS].markers[gatewayId].setIcon(
      icon({
        iconUrl: MAP_CONFIG.markerVariables["GATEWAY"][iconName],
        className: "arson",
        iconAnchor: [16, 32],
        popupAnchor: [4, -23],
      })
    );
  }

  // Reseteo de iconos de gateway
  resetGatewayIcons(iconName: string): void {
    for (let marker in this.mapTypeData[MAP_LAYERS.GATEWAYS].markers) {
      this.mapTypeData[MAP_LAYERS.GATEWAYS].markers[marker].setIcon(
        icon({
          iconUrl: MAP_CONFIG.markerVariables["GATEWAY"][iconName],
          className: "arson",
          iconAnchor: [16, 32],
          popupAnchor: [4, -23],
        })
      );
    }
  }

  // Obtención de la entidad asociada a un gateway
  getGatewayEntity(entityName: string): void {
    if (this.currentEntity.entity != entityName) {
      let newEntity: Entity;
      this.clientList.forEach((client: Client) => {
        let entityFound: Entity = client.entityList.find(
          (entity: Entity) =>
            entity.entity?.toUpperCase() == entityName?.toUpperCase()
        );
        if (entityFound) {
          newEntity = entityFound;
        }
      });
      this.SessionDataService.sendEntity(newEntity);
    }
  }

  // Creación del tooltip para cada gateway
  getGatewayTooltip(gateway: any, mainGateway: any): string {
    let gatewayDeviceType: string = this.getGatewayDeviceType(gateway);
    return this.MapGatewayTooltipService.getGatewayTooltip(
      gateway,
      gatewayDeviceType,
      this.sessionProfile,
      this.currentAgrupation,
      this.mapType,
      mainGateway
    );
  }

  // Movimiento del mapa al gateway seleccionado
  goToGateway(meter: any): void {
    let findGateway: any = this.gateways.find(
      (gateway: any) => gateway.unidadVenta == meter.unidadVentaGw
    );
    this.mapComponent.flyTo([findGateway.latitude, findGateway.longitude]);
  }

  // Búsqueda del número de contadores principales y redundantes con diferentes atributos asociados al dato
  getGatewayDeviceType(gateway: any): any {
    let gatewayDeviceType: any = {
      redundantMeter: this.translate.instant("no-data"),
      mainMeter: this.translate.instant("no-data"),
    };

    let gatewayMeterCountAttributes: any[] = [
      {
        meterType: "mainMeter",
        attributes: [
          { name: "contadoresPrincipal", length: true },
          { name: "nroMainContadores", length: false },
          { name: "mainMeters", length: false },
        ],
      },
      {
        meterType: "redundantMeter",
        attributes: [
          { name: "contadoresRedundante", length: true },
          { name: "nroRedundantContadores", length: false },
          { name: "redundantMeters", length: false },
        ],
      },
    ];

    gatewayMeterCountAttributes.forEach((meterCountAttribute) => {
      let gatewaySearchedAttribute: any = meterCountAttribute.attributes.find(
        (attribute: any) => {
          return gateway[attribute.name] != null;
        }
      );

      if (gatewaySearchedAttribute) {
        gatewayDeviceType[meterCountAttribute.meterType] =
          gatewaySearchedAttribute.length
            ? gateway[gatewaySearchedAttribute.name].length
            : gateway[gatewaySearchedAttribute.name];
      }
    });

    return gatewayDeviceType;
  }

  /***************************************************************************/
  // ANCHOR Capas de relación gateways - contadores
  /***************************************************************************/

  // Cambio de color de los contadores secundarios de un gateway
  findMetersLinked(gateway: any): void {
    // Comprobación de los contadores no asociados al gateway
    if (this.mapType != "coverage") {
      this.meters.forEach((meter) => {
        // Pertenece al gateway
        meter.deactivate = gateway
          ? meter.unidadVentaGw != gateway?.unidadVenta
          : false;
        // Tipo de dispositivo
        let deviceTypeByMask: string =
          this.DeviceTypeService.getDeviceTypeByMask(
            meter.tipo,
            meter.metrologyType,
            meter.fabricante ? meter.fabricante : meter.idFabricante
          );
        // Actualización de marcador circular
        if (!this.checkMapMarkersType(deviceTypeByMask)) {
          for (let layer in this.mapTypeData) {
            if (
              this.mapTypeData[layer].array?.length > 0 &&
              !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
              !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
              !layer.toUpperCase().includes("PATH")
            ) {
              if (
                this.mapTypeData[layer].markers[meter.id] &&
                meter.metrologyType != METROLOGY_TYPE.SENSOR &&
                meter.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR
              ) {
                this.mapTypeData[layer].markers[meter.id].setStyle({
                  fillColor: meter.deactivate
                    ? "rgba(158, 158, 158, 1)"
                    : meter.selected
                    ? MAP_CONFIG.markerVariables["SELECTED"].iconColor
                    : MAP_CONFIG.markerVariables[
                        layer == MAP_LAYERS.NO_LORA
                          ? this.MapDeviceIconService.getDeviceType(
                              deviceTypeByMask,
                              meter
                            )
                          : layer
                      ].iconColor,
                  className: meter.deactivate
                    ? this.mapTypeData[layer].markers[
                        meter.id
                      ].options.className?.includes("deactivated")
                      ? this.mapTypeData[layer].markers[meter.id].options
                          .className
                      : this.mapTypeData[layer].markers[meter.id].options
                          .className + " deactivated"
                    : this.mapTypeData[layer].markers[
                        meter.id
                      ].options.className?.replace(" deactivated", ""),
                });
              }
            }
          }
        }
      });
      this.resetClusters();
    }

    // Comprobación si el contador está recibiendo señal de otros gateways
    if (this.mapType == "coverage") {
      this.mapTypeData[MAP_LAYERS.COVERAGE_OK]?.array.forEach((meter: any) => {
        if (
          meter.locationList?.length > 0 &&
          !meter.locationList?.some(
            (location: any) => location.id == gateway.id
          )
        ) {
          meter.otherGateway = true;
        } else {
          meter.otherGateway = false;
        }
      });
    }

    this.onlyMeters = true;
    // this.resetLayers(false, this.clusterIsActive, true);
  }

  // Dibujado de la unión entre contador y gateway
  showPath(meter: any): void {
    this.path = null;
    let pathLayerIndex: number = this.mapLayersData.findIndex(
      (layer: any) => layer.name == "path"
    );
    if (pathLayerIndex >= 0) {
      this.mapLayersData.splice(pathLayerIndex, 1);
    }
    let findGateway: any = this.gateways?.find(
      (gateway: any) => gateway.unidadVenta == meter.unidadVentaGw
    );
    let latLngs: any[];
    if (
      findGateway != null &&
      findGateway.latitude != null &&
      findGateway.longitude != null
    ) {
      latLngs = [
        [meter.latitude, meter.longitude],
        [findGateway.latitude, findGateway.longitude],
      ];
      this.path = antPath(latLngs, this.pathOptions);

      // Actualización del atributo pertenece de los gateways
      this.gateways.forEach((gateway: any) => {
        if (gateway.id == findGateway.id) {
          gateway.pertenece = true;
        } else {
          gateway.pertenece = false;
        }
      });
    }

    if (this.path) {
      this.newMapLayer("path", "path", this.path, false);
    }
    this.mapComponent.resetLayers();
  }

  // Dibujado de la unión entre test y ubicación
  showCoverageTestPath(test: any): void {
    // Limpieza de paths
    this.mapLayersData = this.mapLayersData.filter(
      (layer) => !layer.name.includes("locationPath")
    );
    let locationsFound: GatewayLocation[] = this.locations?.filter(
      (location: any) =>
        test.locationList?.some(
          (testLocation) => testLocation.id == location.id
        )
    );
    if (locationsFound?.length > 0) {
      locationsFound.forEach((location, i) => {
        this.newMapLayer(
          "locationPath" + i,
          "locationPath" + i,
          antPath(
            [
              [test.latitude, test.longitude],
              [location.latitude, location.longitude],
            ],
            this.pathOptions
          ),
          false
        );
      });
    }
    this.resetLocations();
  }

  // Pantalla completa
  fullScreen(recording: boolean): void {
    // if(recording) { this.element.nativeElement.querySelector(".leaflet-control-fullscreen-button")?.click(); }
    this.recording.emit(recording);
  }

  // Buscador de dispositivo
  searchForDevice(clipboard?: boolean): void {
    if (clipboard) {
      this.mapSearchData = this.meters.filter((meter) =>
        this.mapSearchFileData.some(
          (fileMeter) =>
            meter[this.mapSearchFilterMapColumnIndex] ==
            fileMeter[this.mapSearchFilterFileColumnIndex]
        )
      );
    }
    let newBounds = [];
    this.meters.map((meter: any) => {
      meter.selected = this.mapSearchData.some(
        (searchedMeter) => searchedMeter.id == meter.id
      );
      if (meter.selected) {
        newBounds.push([meter.latitude, meter.longitude]);
      }
    });
    this.updateSelected();
    this.mapComponent.flyToBounds(newBounds, 18, false);
    this.mapSearchShowFileColumns = false;
    this.searchActive = false;
  }

  // Filtrado por portapapeles
  filterByClipboard(): void {
    this.mapSearchFileToFilter = navigator.clipboard.readText().then((file) => {
      this.mapSearchFileToFilter = file;
      this.getFileColumns();
    });
  }

  // Obtención de columnas del archivo de filtrado
  getFileColumns(): void {
    let filterRows = this.mapSearchFileToFilter.split("\n");
    filterRows = filterRows.map((row: string) => {
      return row.split(";").map((element: any) => element.replace("\r", ""));
    });
    let fileColumns = [...filterRows[0]];
    this.mapSearchFileData = [...filterRows.slice(1)];
    if (fileColumns.length > 0) {
      this.mapSearchFileColumnOptions = fileColumns.map((column: any, i) => {
        return { value: i, name: column };
      });
      let meterKeys = Object.keys(this.meters[0]);
      this.mapSearchMapColumnOptions = meterKeys.map((key) => {
        return { name: key, value: key };
      });
      this.mapSearchShowFileColumns = true;
    }
  }

  // Obtención de los datos de asociaciones
  getAssociationData(): void {
    this.AssociationController.getAssociationList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.mapSearchAssociationData = response["body"];
        this.searchAssociationActive = !this.searchAssociationActive;
      }
    });
  }

  // Búsqueda por asociación
  searchForAssociation(): void {
    this.searchAssociationActive = false;
    this.AssociationController.show(
      this.mapSearchAssociationSelected
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let associationCups: SectorCups[] = response[
          "body"
        ]?.sectorDeviceList?.filter((cups) => cups.isSelected);
        this.mapSearchData = [];
        associationCups?.forEach((cups) => {
          this.mapSearchData = [
            ...this.mapSearchData,
            ...cups.meters.map((meter) => {
              return { id: meter.meterId };
            }),
          ];
        });
        this.searchForDevice();
      }
    });
  }

  // Actualización de la posición del dispositivo
  updateDeviceLocation(newLocation: { lat: number; lng: number }): void {
    this.meterNewLocation.emit(newLocation);
    this.resetLayers();
  }

  // Actualización de tooltips de mapa de desconocidos de LW MBUS
  updateUnknownLwMbusTooltip(tooltipData: object): void {
    let tooltipOptions: L.TooltipOptions = {
      permanent: true,
      direction: "top",
      opacity: 1,
      offset: L.point({ x: 0, y: -10 }),
    };

    for (let marker in this.mapTypeData[MAP_LAYERS.MBUS_CONCENTRATOR].markers) {
      if (tooltipData[marker]) {
        let tooltip = `<div class="location-tooltip">
        <table>
          <tr></tr>`;
        tooltip +=
          `<tr><td><b>` +
          `<a class="map-link" href="` +
          "#/dispositivos/detalle/lw-mbus/" +
          tooltipData[marker].id +
          `">` +
          (tooltipData[marker].nroSerie != null
            ? tooltipData[marker].nroSerie
            : this.translate.instant("no-data")) +
          `</b><i class="fas fa-link"></i></a></td><td></td></tr>`;
        // Fecha
        tooltip +=
          `<tr><td><b>` +
          this.translate.instant("date") +
          `:</b>
                </td><td>` +
          (tooltipData[marker].date != null
            ? tooltipData[marker].date
            : this.translate.instant("no-data")) +
          `
                </td>
              </tr>`;
        // RSSI
        tooltip +=
          `<tr><td>
                <b>RSSI: </b>
              </td><td>` +
          (tooltipData[marker].rssi != null
            ? tooltipData[marker].rssi + " dBm"
            : this.translate.instant("no-data")) +
          `
              </td>
          </tr><tr>`;
        tooltip += "</table></div>";
        this.mapTypeData[MAP_LAYERS.MBUS_CONCENTRATOR].markers[marker]
          .bindTooltip(tooltip, tooltipOptions)
          .openTooltip()
          .unbindPopup()
          .on("mouseover", (e: any) => e.preventDefault());
        // Control de nueva pestaña
        $(".map-link")
          .off("contextmenu")
          .on("contextmenu", (e) => {
            e.preventDefault();
            this.RedirectToService.openNewTab(
              e.target.href.substring(e.target.href.indexOf("#") + 2)
            );
          });
      } else {
        this.mapTypeData[MAP_LAYERS.MBUS_CONCENTRATOR].markers[
          marker
        ].unbindTooltip();
      }
      this.mapTypeData[MAP_LAYERS.MBUS_CONCENTRATOR].markers[marker].setStyle({
        fillColor:
          tooltipData[marker]?.rssi <= RSSI_MBUS_THRESHOLDS[0]
            ? "red"
            : tooltipData[marker]?.rssi <= RSSI_MBUS_THRESHOLDS[1]
            ? "orange"
            : tooltipData[marker]?.rssi <= RSSI_MBUS_THRESHOLDS[2]
            ? "yellow"
            : tooltipData[marker]?.rssi < RSSI_MBUS_THRESHOLDS[3]
            ? "limegreen"
            : tooltipData[marker]?.rssi >= RSSI_MBUS_THRESHOLDS[3]
            ? "green"
            : "gray",
      });
    }
  }

  // Cambio de color de los contadores no recibidos por un concentrador LW MBUS
  findMbusLinked(lwMbus: any): void {
    this.MeterController.getLwMbusReceivedDevices(lwMbus.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let metersLinked = response["body"]?.contadorInfoList;
          // Comprobación de los contadores recibidos por el concentrador LW MBUS
          this.meters.forEach((meter) => {
            // No recibido por el concentrador LW MBUS
            meter.deactivate = !metersLinked?.some(
              (meterLinked: MbusMeter) => meterLinked.elementoId == meter.id
            );
            // Tipo de dispositivo
            let deviceTypeByMask: string =
              this.DeviceTypeService.getDeviceTypeByMask(
                meter.tipo,
                meter.metrologyType,
                meter.fabricante ? meter.fabricante : meter.idFabricante
              );
            // Actualización de marcador circular
            for (let layer in this.mapTypeData) {
              if (
                this.mapTypeData[layer].array?.length > 0 &&
                !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
                !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
                !layer.toUpperCase().includes("PATH") &&
                !layer.toUpperCase().includes("CONCENTRATOR") &&
                !layer.toUpperCase().includes(MAP_LAYERS.TXN) &&
                !layer.toUpperCase().includes(MAP_LAYERS.NO_LORA)
              ) {
                if (this.mapTypeData[layer].markers[meter.id]) {
                  this.mapTypeData[layer].markers[meter.id]?.setStyle({
                    fillColor: meter.deactivate
                      ? "rgba(158, 158, 158, 1)"
                      : meter.selected
                      ? MAP_CONFIG.markerVariables["SELECTED"].iconColor
                      : MAP_CONFIG.markerVariables[
                          layer == MAP_LAYERS.NO_LORA
                            ? this.MapDeviceIconService.getDeviceType(
                                deviceTypeByMask,
                                meter
                              )
                            : layer
                        ].iconColor,
                    className: meter.deactivate
                      ? this.mapTypeData[layer].markers[
                          meter.id
                        ].options.className?.includes("deactivated")
                        ? this.mapTypeData[layer].markers[meter.id].options
                            .className
                        : this.mapTypeData[layer].markers[meter.id].options
                            .className + " deactivated"
                      : this.mapTypeData[layer].markers[
                          meter.id
                        ].options.className?.replace(" deactivated", ""),
                  });
                }
              }
            }
          });
          this.resetClusters(true);
        }
      }
    );
  }

  // Dibujado de la unión entre contador y gateway
  showConcentratorPath(meter: any, concentratorsLinked: number[]): void {
    if (concentratorsLinked.length > 0) {
      let concentrators: any = this.meters?.filter((concentrator: any) =>
        concentratorsLinked.includes(concentrator.id)
      );
      concentrators.map((concentrator, i) => {
        let latLngs = [
          [meter.latitude, meter.longitude],
          [concentrator.latitude, concentrator.longitude],
        ];
        this.newMapLayer(
          "path" + i,
          "path" + i,
          antPath(latLngs, this.pathOptions),
          false
        );
      });
    }
    this.mapComponent.resetLayers();
  }

  // Reseteo de clústeres
  resetClusters(excludeConcentrators?: boolean): void {
    for (let layer in this.mapTypeData) {
      if (
        this.clusterIsActive &&
        this.mapComponent.activeLayers.includes(layer) &&
        !layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS) &&
        !layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS) &&
        !layer.toUpperCase().includes("PATH") &&
        (!excludeConcentrators ||
          (excludeConcentrators &&
            !layer.toUpperCase().includes("CONCENTRATOR") &&
            !layer.toUpperCase().includes(MAP_LAYERS.TXN))) &&
        this.mapTypeData[layer].array?.length > 0
      ) {
        this.mapTypeData[layer].layer?.refreshClusters();
      }
    }
  }
}
