import { Injectable } from "@angular/core";
import { ThemeService } from "../../themes/theme.service";
import { Title } from "@angular/platform-browser";
import { CUSTOM_URLS } from "../../screens/login/CUSTOM_URLS";

@Injectable({
  providedIn: "root",
})
export class CustomUrlService {
  constructor(private theme: ThemeService, private titleService: Title) {}

  checkCustomUrl(): void {
    // Actualización del título
    let customUrl = CUSTOM_URLS.find((url: any) =>
      window.location.href.includes(url.urlText)
    );
    if (customUrl?.appTitle) {
      this.titleService.setTitle(customUrl.appTitle);
    }
    // Actualización del favicon
    if (customUrl?.appIcon) {
      document
        .getElementById("appFavicon")
        .setAttribute("href", customUrl.appIcon);
    }
    // Actualización de tema
    if (customUrl?.theme) {
      this.theme.setTheme(customUrl?.theme);
    }
  }
}
