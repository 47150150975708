import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class SagemcomDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  tiposDatos = {
    ENTERO_8BITS: 1,
    ENTERO_16BITS: 2,
    ENTERO_24BITS: 3,
    ENTERO_32BITS: 4,
    REAL_32BITS: 5,
    ENTERO_48BITS: 6,
    ENTERO_64BITS: 7,
    BCD_2DIGITOS: 9,
    BCD_4DIGITOS: 10,
    BCD_6DIGITOS: 11,
    BCD_8DIGITOS: 12,
    LONGITUD_VARIABLE: 13,
    BCD_12DIGITOS: 14,
  };

  modosPerfil = {
    VALOR_ABSOLUTO: 0,
    INCREMENTO: 1,
    DECREMENTO: 2,
    DIFERENCIA: 3,
  };

  VALVE_STATUS = ["Cerrada", "Abierta", "Apertura autorizada"];
  LORA_TYPES = [
    "DESCONOCIDA",
    "DESCONOCIDA",
    "DESCONOCIDA",
    "Clase A",
    "Clase A+",
    "Clase B",
    "Clase C",
  ];

  Sagemcom_Gas_decoder(frmPayload, fport) {
    if (frmPayload && fport) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        switch (fport) {
          case 0x10 /* BEACON */:
            var ci = frmPayload.bytes[0];
            if (ci != 0x79) {
              return {
                errors: ["CI erróneo, debe ser 0x79"],
              };
            }
            var fos = this.readU16Lsb(frmPayload.bytes, 1);
            var ffc = this.readU16Lsb(frmPayload.bytes, 3);
            if (fos != 0x0428) {
              return {
                errors: ["Puerto de Beacons (0x10) pero el FOS no es correcto"],
              };
            } else {
              return this.beaconDecoder(frmPayload.bytes);
            }
            break;
          case 0x20 /* DATA FRAMES */:
            var ci = frmPayload.bytes[0];
            if (ci != 0x79) {
              return {
                errors: ["CI erróneo, debe ser 0x79"],
              };
            }
            var fos = this.readU16Lsb(frmPayload.bytes, 1);
            var ffc = this.readU16Lsb(frmPayload.bytes, 3);
            switch (fos) {
              case 0x60ea /*DATA FRAME*/:
                return this.dataFrameDecoder(frmPayload.bytes);
              case 0x5e82 /*INSTALL FRAME*/:
                return this.installFrameDecoder(frmPayload.bytes);
              case 0xfa65 /*UNINSTALL FRAME*/:
                return this.uninstallFrameDecoder(frmPayload.bytes);
              case 0x0bd9 /*ALARM FRAME*/:
                return this.alarmFrameDecoder(frmPayload.bytes);
              case 0x0811 /*STATUS FRAME*/:
                return this.statusFrameDecoder(frmPayload.bytes);
              default:
                return {
                  errors: [
                    "fos no definido: 0x" + fos.toString(16).padStart(4, "0"),
                  ],
                };
            }
            break;
          case 0x30 /* FIRMWARE FRAMES */:
            break;
          default:
            return {
              errors: [
                "Puerto no definido: 0x" + fport.toString(16).padStart(2, "0"),
              ],
            };
            break;
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Faltan parámetros de entrada."],
      };
    }
  }

  /* PARSEO DE ALARMAS */
  parseaAlarmas(alarmas) {
    var objAlarmas = {};
    if (alarmas & (1 << 0)) {
      objAlarmas["Mechanical Tamper"] = true;
    }
    if (alarmas & (1 << 1)) {
      objAlarmas["Battery Low"] = true;
    }
    if (alarmas & (1 << 4)) {
      objAlarmas["Hardware or software error"] = true;
    }
    if (alarmas & (1 << 6)) {
      objAlarmas["Magnetic tamper"] = true;
    }
    if (alarmas & (1 << 8)) {
      objAlarmas["Unauthorised access attempt"] = true;
    }
    if (alarmas & (1 << 10)) {
      objAlarmas["Leakage"] = true;
    }
    if (alarmas & (1 << 11)) {
      objAlarmas["No flow"] = true;
    }
    if (alarmas & (1 << 12)) {
      objAlarmas["Consumption out of range"] = true;
    }
    if (alarmas & (1 << 13)) {
      objAlarmas["Sensor out of range"] = true;
    }
    if (alarmas & (1 << 14)) {
      objAlarmas["MClock Sync error"] = true;
    }
    if (alarmas & (1 << 16)) {
      objAlarmas["Historical mechanical tamper"] = true;
    }
    if (alarmas & (1 << 17)) {
      objAlarmas["Historical magnetic tamper"] = true;
    }
    if (alarmas & (1 << 20)) {
      objAlarmas["Historical leakage"] = true;
    }
    if (alarmas & (1 << 21)) {
      objAlarmas["Historical no flow"] = true;
    }
    if (alarmas & (1 << 22)) {
      objAlarmas["Historical sensor out of range"] = true;
    }
    if (alarmas & (1 << 24)) {
      objAlarmas["Meter set to Vb."] = true;
    }
    if (alarmas & (1 << 25)) {
      objAlarmas["Local operator menu accessed"] = true;
    }
    if (alarmas & (1 << 26)) {
      objAlarmas["Prepayment active."] = true;
    }
    if (alarmas & (1 << 27)) {
      objAlarmas["Prepayment display active"] = true;
    }
    return objAlarmas;
  }

  /* BEACON FRAME */
  beaconDecoder(bytes) {
    if (bytes.length != 9) {
      return {
        errors: ["BEACON FRAME: Longitud errónea, debe ser 9 bytes"],
      };
    } else {
      var alarmas = this.readU32Lsb(bytes, 5);
      return {
        data: {
          Tipo_Trama: "BEACON",
          Alarmas: this.parseaAlarmas(alarmas),
        },
      };
    }
  }

  /* INSTALL FRAME */
  installFrameDecoder(bytes) {
    var dateF = this.readU32Lsb(bytes, 5);
    var volume = this.getDatoUnsigned(bytes, 9, this.tiposDatos.BCD_8DIGITOS);
    var fabricationNo = this.getDatoUnsigned(
      bytes,
      13,
      this.tiposDatos.BCD_8DIGITOS
    );
    var minute = dateF & 0x3f;
    var hour = (dateF >> 8) & 0x1f;
    var day = (dateF >> 16) & 0x1f;
    var month = (dateF >> 24) & 0x0f;
    var year =
      1900 +
      100 * ((dateF >> 13) & 0x03) +
      (((dateF >> 21) & 0x07) + ((dateF >> 25) & 0x78));
    var date = new Date(Date.UTC(year, month - 1, day, hour, minute));

    return {
      data: {
        Tipo_Trama: "INSTALL",
        Fecha: date.toLocaleString(),
        Volumen: (volume / 1000).toFixed(3) + " m3",
        Fabrication: fabricationNo.toString().padStart(8, "0"),
      },
    };
  }

  /* UNINSTALL FRAME */
  uninstallFrameDecoder(bytes) {
    var dateF = this.readU32Lsb(bytes, 5);
    var volume = this.getDatoUnsigned(bytes, 9, this.tiposDatos.BCD_8DIGITOS);
    var fabricationNo = this.getDatoUnsigned(
      bytes,
      13,
      this.tiposDatos.BCD_8DIGITOS
    );
    var minute = dateF & 0x3f;
    var hour = (dateF >> 8) & 0x1f;
    var day = (dateF >> 16) & 0x1f;
    var month = (dateF >> 24) & 0x0f;
    var year =
      1900 +
      100 * ((dateF >> 13) & 0x03) +
      (((dateF >> 21) & 0x07) + ((dateF >> 25) & 0x78));
    var date = new Date(Date.UTC(year, month - 1, day, hour, minute));

    return {
      data: {
        Tipo_Trama: "UNINSTALL",
        Fecha: date.toLocaleString(),
        Volumen: (volume / 1000).toFixed(3) + " m3",
        Fabrication: fabricationNo.toString().padStart(8, "0"),
      },
    };
  }

  /* ALARM FRAME */
  alarmFrameDecoder(bytes) {
    var dateF = this.readU32Lsb(bytes, 5);
    var volume = this.getDatoUnsigned(bytes, 11, this.tiposDatos.BCD_8DIGITOS);
    var alarmas = this.readU32Lsb(bytes, 15);

    var second = bytes[5 + 0] & 0x3f;
    var minute = bytes[5 + 1] & 0x3f;
    var hour = bytes[5 + 2] & 0x1f;
    var day = bytes[5 + 3] & 0x1f;
    var month = bytes[5 + 4] & 0x0f;
    var year =
      2000 + (((bytes[5 + 3] >> 5) & 0x07) + ((bytes[5 + 4] >> 1) & 0x78));
    var date = new Date(Date.UTC(year, month - 1, day, hour, minute));

    return {
      data: {
        Tipo_Trama: "ALARMAS",
        Fecha: date.toLocaleString(),
        Volumen: (volume / 1000).toFixed(3) + " m3",
        Alarmas: this.parseaAlarmas(alarmas),
      },
    };
  }

  /* STATUS FRAME */
  statusFrameDecoder(bytes) {
    var volume = this.getDatoUnsigned(bytes, 5, this.tiposDatos.BCD_8DIGITOS);
    var alarmas = this.readU32Lsb(bytes, 9);
    var remainingDays = this.getDatoUnsigned(
      bytes,
      13,
      this.tiposDatos.BCD_4DIGITOS
    );
    var valveControl = bytes[15];
    var valveFisical = bytes[16];
    var loraType = bytes[17];
    var version = "";
    for (let indice = 18; indice < bytes.length; indice++) {
      if (bytes[indice] != 0) {
        version += String.fromCharCode(parseInt(bytes[indice], 10));
      }
    }

    return {
      data: {
        Tipo_Trama: "STATUS",
        Volumen: (volume / 1000).toFixed(3) + " m3",
        Alarmas: this.parseaAlarmas(alarmas),
        DiasRestantes: remainingDays,
        ControlValvula: this.VALVE_STATUS[valveControl],
        Valvula: this.VALVE_STATUS[valveFisical],
        ClaseLora: this.LORA_TYPES[loraType],
        Version: version,
      },
    };
  }

  dataFrameDecoder(bytes) {
    var data = {
      Tipo_Trama: "DATOS",
    };
    data["Alarmas"] = this.parseaAlarmas(this.readU32Lsb(bytes, 5));
    data["ControlValvula"] = this.VALVE_STATUS[bytes[9]];
    data["Valvula"] = this.VALVE_STATUS[bytes[10]];

    var baseDateF = this.readU32Lsb(bytes, 11);
    var minute = baseDateF & 0x3f;
    var hour = (baseDateF >> 8) & 0x1f;
    var day = (baseDateF >> 16) & 0x1f;
    var month = (baseDateF >> 24) & 0x0f;
    var year =
      1900 +
      100 * ((baseDateF >> 13) & 0x03) +
      (((baseDateF >> 21) & 0x07) + ((baseDateF >> 25) & 0x78));
    var fechaBase = new Date(Date.UTC(year, month - 1, day, hour, minute));
    var valorBase = this.readU32Lsb(bytes, 15) * 0.001;
    var lvar = bytes[19];
    var index = 20;

    var sc = bytes[index++];
    var difPerfil = sc & 0x0f;
    var longitudRegistros = this.longitudDifs(difPerfil);
    if (longitudRegistros == 0) {
      return {
        errors: [
          "Perfil compacto inverso. ERROR:  DIF del SC no válido!!!: 0x" +
            difPerfil.toString(16).padStart(2, "0"),
        ],
      };
    } else if (
      difPerfil == this.tiposDatos.REAL_32BITS ||
      difPerfil == this.tiposDatos.ENTERO_48BITS ||
      difPerfil == this.tiposDatos.ENTERO_64BITS
    ) {
      return {
        errors: [
          "Perfil compacto inverso. ERROR:  TIPO DE DATOS NO VÁLIDO: 0x" +
            difPerfil.toString(16).padStart(2, "0"),
        ],
      };
    } else {
      var numeroRegistros = (lvar - 2) / longitudRegistros;
      if (numeroRegistros == 0) {
        return {
          errors: ["Perfil compacto inverso. ERROR:  No incluye registros"],
        };
      } else {
        var sv = bytes[index++];
        switch ((sc >> 4) & 0x03 /* Periodo a milisegundos*/) {
          case 0: {
            var periodo = sv * 1000;
            break;
          }
          case 1: {
            var periodo = 60 * sv * 1000;
            break;
          }
          case 2: {
            var periodo = 60 * 60 * sv * 1000;
            break;
          }
          case 3: {
            var periodo = 24 * 60 * 60 * sv * 1000;
            break;
          }
        }
        var modo = (sc >> 6) & 0x03;
        if (modo == this.modosPerfil.VALOR_ABSOLUTO) {
          return {
            errors: [
              "Perfil compacto inverso. ERROR:  Modo Valor absoluto : no válido",
            ],
          };
        } else if (modo == this.modosPerfil.DECREMENTO) {
          return {
            errors: [
              "Perfil compacto inverso. ERROR:  Modo Decremento : no válido",
            ],
          };
        } else if (
          modo == this.modosPerfil.DIFERENCIA &&
          difPerfil >= this.tiposDatos.BCD_2DIGITOS &&
          difPerfil <= this.tiposDatos.BCD_12DIGITOS
        ) {
          return {
            errors: [
              "Perfil compacto inverso. ERROR:  Modo diferencia con datos tipo BCD",
            ],
          };
        } else {
          data[fechaBase.toLocaleString()] = valorBase.toFixed(3) + " m3";
          var valorAnterior = valorBase;
          var fechaAnterior = fechaBase;
          var indiceRegistro = 0;
          var valoresValidos = true;
          do {
            let fecha = new Date();
            fecha = new Date(fechaAnterior.getTime() - periodo);
            if (modo == this.modosPerfil.INCREMENTO) {
              var consumo = this.getDatoUnsigned(bytes, index, difPerfil);
            } else {
              var consumo = this.getDatoSigned(bytes, index, difPerfil);
            }
            if (consumo == 0xffffffff) {
              valoresValidos = false;
            }
            index += this.longitudDifs(difPerfil);
            if (valoresValidos) {
              var valor = valorAnterior - consumo * 0.001;
              data[fecha.toLocaleString()] = valor.toFixed(3) + " m3";
            } else {
              data[fecha.toLocaleString()] = "Valor no válido";
            }
            valorAnterior = valor;
            fechaAnterior = fecha;
          } while (++indiceRegistro < numeroRegistros && valoresValidos);
        }
      }
    }
    return { data: data };
  }

  /* Helper Methods */
  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  objectAssign(source, target) {
    var _source = [];
    var _target = [];

    if (source) {
      _source = Object(source);
      if (target) {
        _target = Object.keys(target);

        for (var i = 0; i < _target.length; i++) {
          if (Object.prototype.hasOwnProperty.call(target, _target[i])) {
            _source[_target[i]] = target[_target[i]];
          }
        }
      }
    }
    return _source;
  }

  longitudDifs(tipoDato) {
    var longitud = 0;
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        longitud = 1;
        break;
      case this.tiposDatos.ENTERO_16BITS:
        longitud = 2;
        break;
      case this.tiposDatos.ENTERO_24BITS:
        longitud = 3;
        break;
      case this.tiposDatos.ENTERO_32BITS:
        longitud = 4;
        break;
      case this.tiposDatos.REAL_32BITS:
        longitud = 4;
        break;
      case this.tiposDatos.ENTERO_48BITS:
        longitud = 6;
        break;
      case this.tiposDatos.ENTERO_64BITS:
        longitud = 8;
        break;
      case this.tiposDatos.BCD_2DIGITOS:
        longitud = 1;
        break;
      case this.tiposDatos.BCD_4DIGITOS:
        longitud = 2;
        break;
      case this.tiposDatos.BCD_6DIGITOS:
        longitud = 3;
        break;
      case this.tiposDatos.BCD_8DIGITOS:
        longitud = 4;
        break;
      case this.tiposDatos.BCD_12DIGITOS:
        longitud = 6;
        break;
      case this.tiposDatos.LONGITUD_VARIABLE:
        longitud = 0;
        break;
      default:
        longitud = -1;
        break;
    }
    return longitud;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  getDatoUnsigned(bytes, start, tipoDato) {
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        return bytes[start];
        break;
      case this.tiposDatos.ENTERO_16BITS:
        return this.readU16Lsb(bytes, start);
        break;
      case this.tiposDatos.ENTERO_24BITS:
        return this.readU24Lsb(bytes, start);
        break;
      case this.tiposDatos.ENTERO_32BITS:
        return this.readU32Lsb(bytes, start);
        break;
      case this.tiposDatos.BCD_2DIGITOS:
      case this.tiposDatos.BCD_4DIGITOS:
      case this.tiposDatos.BCD_6DIGITOS:
      case this.tiposDatos.BCD_8DIGITOS:
        var valor = 0;
        for (var i = this.longitudDifs(tipoDato) - 1; i >= 0; i--) {
          valor *= 10;
          valor += (bytes[start + i] >> 4) & 0x0f;
          valor *= 10;
          valor += bytes[start + i] & 0x0f;
        }
        return valor;
        break;
      default:
        return null;
        break;
    }
  }

  getDatoSigned(bytes, start, tipoDato) {
    switch (tipoDato) {
      case this.tiposDatos.ENTERO_8BITS:
        var valor = bytes[start];
        if (valor & 0x80) {
          valor = valor - 0xff - 1;
        }
        return valor;
        break;
      case this.tiposDatos.ENTERO_16BITS:
        valor = this.readU16Lsb(bytes, start);
        if (valor & 0x8000) {
          valor = valor - 0xffff - 1;
        }
        return valor;
        break;
      case this.tiposDatos.ENTERO_24BITS:
        valor = this.readU24Lsb(bytes, start);
        if (valor & 0x800000) {
          valor = valor - 0xffffff - 1;
        }
        return valor;
        break;
      case this.tiposDatos.ENTERO_32BITS:
        valor = this.readU32Lsb(bytes, start);
        if (valor & 0x80000000) {
          valor = valor - 0xffffffff - 1;
        }
        return valor;
        break;
      default:
        return null;
        break;
    }
  }

  objetoDataRaw(dif, difes, vif, vifes, bytes, start, longitud) {
    var difTipoDato = dif & 0x0f;
    var difFunction = (dif >> 4) & 0x03;
    var difStorageNumber = (dif >> 6) & 0x01;

    var objeto = {
      DIF: "0x" + dif.toString(16).padStart(2, "0"),
    };
    if (difes.length) {
      objeto["DIFEs"] = this.bytesToHex(difes);
    }
    objeto["VIF"] = "0x" + vif.toString(16).padStart(2, "0");
    if (vifes.length) {
      objeto["VIFEs"] = this.bytesToHex(vifes);
    }
    objeto["dataRaw"] = this.bytesToHex(bytes.slice(start, start + longitud));
    return objeto;
  }

  Sagemcom_Gas_Descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);
      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x10:
                return "BEACON";
              case 0x20:
                var fos = this.readU16Lsb(frameBytes, 9 + fOptsLen + 1);
                switch (fos) {
                  case 0x60ea:
                    return "DATA FRAME";
                  case 0x5e82:
                    return "INSTALL FRAME";
                  case 0xfa65:
                    return "UNINSTALL FRAME";
                  case 0x0bd9:
                    return "ALARM FRAME";
                  case 0x0811:
                    return "STATUS FRAME";
                  default:
                    return "";
                }
              case 0x30:
                return "FIRMWARE";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          return "";
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
