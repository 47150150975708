import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class BmeterDecoderService {
  constructor() {}

  tiposDatos = {
    ENTERO_8BITS: 1,
    ENTERO_16BITS: 2,
    ENTERO_24BITS: 3,
    ENTERO_32BITS: 4,
    REAL_32BITS: 5,
    ENTERO_48BITS: 6,
    ENTERO_64BITS: 7,
    BCD_2DIGITOS: 9,
    BCD_4DIGITOS: 10,
    BCD_6DIGITOS: 11,
    BCD_8DIGITOS: 12,
    LONGITUD_VARIABLE: 13,
    BCD_12DIGITOS: 14,
  };

  bmeter_decoder(input) {
    if (input) {
      if (input.bytes) {
        if (input.bytes.length < 52) {
          var outputData = {};
          var outputErrors = [];
          var applicationCode = input.bytes[0];
          if (applicationCode == 0x44) {
            if (input.bytes.length != 13 && input.bytes.length != 15) {
              return {
                errors: ["Longitud errónea"],
              };
            }
            var absoluteValue = this.getBCD8DigitosLsb(input.bytes, 1);
            var reverseFlow = this.getBCD8DigitosLsb(input.bytes, 5);
            var indexK = input.bytes[9];
            var medium = input.bytes[10];
            var vif = input.bytes[11];
            var pesoPulso = 10 ** ((vif & 0x07) - 6); // m3/pulso
            var alarms = input.bytes[12];
            var temperature;
            if (input.bytes.length == 15) {
              temperature = this.read16Msb(input.bytes, 13) / 10;
            }
            outputData["Application_Code"] = "0x44";
            outputData["Valor"] =
              (absoluteValue * pesoPulso).toLocaleString("es-ES") + " m3";
            outputData["Flujo_Inverso"] =
              (reverseFlow * pesoPulso).toLocaleString("es-ES") + " m3";
            outputData["Index_K"] = indexK;
            outputData["Medium"] = medium;
            outputData["VIF"] =
              "0x" +
              vif.toString(16).padStart(2, "0") +
              " - " +
              pesoPulso.toLocaleString("es-ES") +
              " m3/pulso";
            outputData["Alarmas"] = {};
            if (alarms & (1 << 0)) {
              outputData["Alarmas"]["Magnetic_Tampered"] = true;
            }
            if (alarms & (1 << 1)) {
              outputData["Alarmas"]["Modulo_desmontado"] = true;
            }
            if (alarms & (1 << 2)) {
              outputData["Alarmas"]["Sensor_fraud"] = true;
            }
            if (alarms & (1 << 3)) {
              outputData["Alarmas"]["Fuga"] = true;
            }
            if (alarms & (1 << 4)) {
              outputData["Alarmas"]["Flujo_inverso"] = true;
            }
            if (alarms & (1 << 5)) {
              outputData["Alarmas"]["Bateria_baja"] = true;
            }
            if (input.bytes.length == 15) {
              outputData["Temperatura"] =
                temperature.toLocaleString("es-ES") + " ºC";
            }
          } else if (applicationCode == 0x45) {
            if (input.bytes.length == 9 || input.bytes.length == 11) {
              // STANDARD FRAME
              var absoluteValue =
                this.readU24Lsb(input.bytes, 1) | ((input.bytes[4] >> 4) << 24);
              var reverseFlow =
                this.readU24Lsb(input.bytes, 5) |
                ((input.bytes[4] & 0x0f) << 24);
              alarms = input.bytes[8] & 0x3f;
              var diameter = (input.bytes[8] >> 6) & 0x01;
              medium = (input.bytes[8] >> 7) & 0x01;
              if (input.bytes.length == 11) {
                temperature = this.read16Msb(input.bytes, 9) / 10;
              }
              outputData["Application_Code"] = "0x45 - Standar Frame";
              outputData["Valor"] =
                (absoluteValue * 0.001).toLocaleString("es-ES") + " m3";
              outputData["Flujo_Inverso"] =
                (reverseFlow * 0.001).toLocaleString("es-ES") + " m3";
              outputData["Diametro"] = diameter;
              outputData["Medium"] = medium;
              outputData["Alarmas"] = {};
              if (alarms & (1 << 0)) {
                outputData["Alarmas"]["Fuga"] = true;
              }
              if (alarms & (1 << 1)) {
                outputData["Alarmas"]["Instalacion_erronea"] = true;
              }
              if (alarms & (1 << 2)) {
                outputData["Alarmas"]["Overflow"] = true;
              }
              if (alarms & (1 << 3)) {
                outputData["Alarmas"]["Burst"] = true;
              }
              if (alarms & (1 << 4)) {
                outputData["Alarmas"]["Flujo_inverso"] = true;
              }
              if (alarms & (1 << 5)) {
                outputData["Alarmas"]["Bateria_baja"] = true;
              }
              if (input.bytes.length == 11) {
                outputData["Temperatura"] =
                  temperature.toLocaleString("es-ES") + "ºC";
              }
            } else if (input.bytes.length == 46 || input.bytes.length == 48) {
              // DATALOGGER FRAME
              var referenceEpoch = new Date(
                2000,
                0,
                1
              ); /* El timestamp del contador está referido a 1-1-2000 */
              absoluteValue = this.readU32Lsb(input.bytes, 1);
              var rawTimestamp = this.readU32Lsb(input.bytes, 5);
              var lastValue = this.readU32Lsb(input.bytes, 9);
              var lastTimestamp = this.readU32Lsb(input.bytes, 13);
              var deltas = [];
              for (
                let nDelta = 0, indice = 17;
                nDelta < 14;
                nDelta++, indice += 2
              ) {
                deltas[nDelta] = this.read16Lsb(input.bytes, indice);
              }
              alarms = input.bytes[45] & 0x3f;
              if (input.bytes.length == 48) {
                temperature = this.read16Msb(input.bytes, 46) / 10;
              }
              outputData["Application_Code"] = "0x45 - Datalogger Frame";
              var txTimestamp = new Date(
                rawTimestamp * 1000 + referenceEpoch.getTime()
              );
              outputData["Current_Timestamp"] =
                rawTimestamp.toString() +
                "  " +
                txTimestamp.toLocaleString("es-ES");
              outputData["Valor"] =
                (absoluteValue * 0.001).toLocaleString("es-ES") + " m3";
              txTimestamp = new Date(
                lastTimestamp * 1000 + referenceEpoch.getTime()
              );
              outputData["Timestamp_Referencia"] =
                lastTimestamp.toString() +
                "  " +
                txTimestamp.toLocaleString("es-ES");
              outputData["Valor_Referencia"] =
                (lastValue * 0.001).toLocaleString("es-ES") + " m3";

              outputData["Alarmas"] = {};
              if (alarms & (1 << 0)) {
                outputData["Alarmas"]["Fuga"] = true;
              }
              if (alarms & (1 << 1)) {
                outputData["Alarmas"]["Instalacion_erronea"] = true;
              }
              if (alarms & (1 << 2)) {
                outputData["Alarmas"]["Overflow"] = true;
              }
              if (alarms & (1 << 3)) {
                outputData["Alarmas"]["Burst"] = true;
              }
              if (alarms & (1 << 4)) {
                outputData["Alarmas"]["Flujo_inverso"] = true;
              }
              if (alarms & (1 << 5)) {
                outputData["Alarmas"]["Bateria_baja"] = true;
              }
              if (input.bytes.length == 48) {
                outputData["Temperatura"] =
                  temperature.toLocaleString("es-ES") + "ºC";
              }
              outputData["Deltas"] = {};
              for (let ndxDelta = 0; ndxDelta < 11; ndxDelta++) {
                outputData["Deltas"][
                  "Delta" + ndxDelta.toString().padStart(2, "0")
                ] = (deltas[ndxDelta] * 0.001).toLocaleString("es-ES") + " m3";
              }

              var valor = lastValue;
              var valueDate: any = new Date(
                lastTimestamp * 1000 + referenceEpoch.getTime()
              );
              outputData["VALORES"] = {
                [valueDate.toLocaleString("es-ES")]:
                  (valor * 0.001).toLocaleString("es-ES") + " m3",
              };
              for (let ndxDelta = 0; ndxDelta < 14; ndxDelta++) {
                valueDate = new Date(valueDate - 60 * 60 * 1000);
                valor -= deltas[ndxDelta];
                outputData["VALORES"][valueDate.toLocaleString("es-ES")] =
                  (valor * 0.001).toLocaleString("es-ES") + " m3";
              }
            } else {
              return {
                errors: ["Longitud errónea"],
              };
            }
          } else {
            return {
              errors: [
                "Application Code inválido: 0x" +
                  applicationCode.toString(16).padStart(2, "0"),
              ],
            };
          }
          return {
            data: outputData,
          };
        } else {
          return {
            errors: ["Tamaño del FRMPayload excesivo."],
          };
        }
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown Input."],
      };
    }
  }

  /* Helper Methods */
  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  objectAssign(source, target) {
    var _source = [];
    var _target = [];

    if (source) {
      _source = Object(source);
      if (target) {
        _target = Object.keys(target);

        for (var i = 0; i < _target.length; i++) {
          if (Object.prototype.hasOwnProperty.call(target, _target[i])) {
            _source[_target[i]] = target[_target[i]];
          }
        }
      }
    }
    return _source;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  readU16Msb(bytes, start) {
    var res = (bytes[start] << 8) + bytes[start + 1];
    return res;
  }

  read16Msb(bytes, start) {
    var res = (bytes[start] << 8) + bytes[start + 1];
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  getBCD8DigitosLsb(bytes, start) {
    var valor = 0;
    for (var i = 3; i >= 0; i--) {
      valor *= 10;
      valor += (bytes[start + i] >> 4) & 0x0f;
      valor *= 10;
      valor += bytes[start + i] & 0x0f;
    }
    return valor;
  }
}
