import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Variables
import * as data from "../../../assets/profiles/profiles.json";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  profiles: any = (data as any).default;

  constructor(private translate: TranslateService) {}

  getProfile(profile: string, isTlm?: boolean): string {
    return profile != null
      ? isTlm && profile == this.profiles.ADMIN_ENTIDAD
        ? "ADMIN_TELEMEDIDA"
        : this.profiles[profile]
      : "-";
  }

  getProfileText(profile: string, isTlm?: boolean): string {
    return profile != null
      ? isTlm && profile == this.profiles.ADMIN_ENTIDAD
        ? this.translate.instant("profilesTelemetryAdmin")
        : this.translate.instant("profiles" + profile)
      : "-";
  }

  getProfileList(): { id: number; name: string }[] {
    let profiles = [];
    for (let profile in this.profiles) {
      profiles.push({ id: profile, name: this.profiles[profile] });
    }
    return profiles.concat({
      id: 4,
      name: "ADMIN_TELEMEDIDA",
    });
  }
}
