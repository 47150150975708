// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ControlAlertsControllerService } from "../../../../services/server/ControlAlertsController.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Componentes
import { ControlHistoricDialogComponent } from "./control-historic-dialog/control-historic-dialog.component";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ControlAlert, ControlAlertEvent } from "../ControlInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-control-historic",
  templateUrl: "./control-historic.component.html",
  styleUrls: ["./control-historic.component.scss"],
})
export class ControlHistoricComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 100;
  alertList: ControlAlert[];
  exportFileName: string =
    this.translate.instant("telecontrol-historic") +
    " " +
    this.DateParserService.getDate();
  from: string;
  to: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  columns: (TableActionColumn | TableDataColumn)[];
  cupsName: string;
  alertStates: object = {
    0: this.translate.instant("opened"),
    1: this.translate.instant("assigned"),
    2: this.translate.instant("closed"),
  };

  // Menú de niveles de alerta
  dataSource: string;
  alertLevels: object[];
  initialAlertLevel: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ControlAlertsController: ControlAlertsControllerService,
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.cupsName = this.entityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.name;
    this.setColumns();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      this.currentClient = client;
      if (!this.RouteCheckService.stayOnRoute("client")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.entityCupsConfSub = this.SessionDataService
      .getEntityCupsConf()
      .subscribe((entityCupsConf) => {
        this.entityCupsConf = entityCupsConf;
        this.cupsName = this.entityCupsConf?.find(
          (column: EntityDefinition) => column.colPosition == 0
        )?.name;
        this.setColumns();
      });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.clientSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getAlertLevels();
    this.dataSource = "global";
    this.initialAlertLevel = this.dataSource;

    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los niveles de alerta
  getAlertLevels(): void {
    this.alertLevels = [
      {
        value: "global",
        name: this.translate.instant("global"),
      },
    ];
    if (this.sessionProfile == PROFILES.ARSON) {
      this.alertLevels.push({
        value: "client",
        name:
          this.translate.instant("client") +
          ": " +
          this.currentClient.clientName,
      });
    }
    if (
      this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
      this.sessionProfile == PROFILES.USUARIO_ENTIDAD ||
      this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
      this.sessionProfile == PROFILES.USUARIO_CLIENTE ||
      this.sessionProfile == PROFILES.ARSON
    ) {
      this.alertLevels.push({
        value: "entity",
        name:
          this.translate.instant("entity") + ": " + this.currentEntity.entity,
      });
    }
    this.alertLevels.push({
      value: "agrupation",
      name:
        this.translate.instant("groups") + ": " + this.currentAgrupation.name,
    });
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    let httpRequest: Observable<object>;
    let alertList: ControlAlert[] = [];
    this.from = from;
    this.to = to;

    // Petición http dependiendo del selector elegido
    switch (this.dataSource) {
      case "client":
        httpRequest = this.ControlAlertsController.getHistoricAlertsByClient(
          this.currentClient.clientId,
          this.from,
          this.to
        );
        break;
      case "entity":
        httpRequest = this.ControlAlertsController.getHistoricAlertsByEntity(
          this.currentEntity.id,
          this.from,
          this.to
        );
        break;
      case "agrupation":
        httpRequest =
          this.ControlAlertsController.getHistoricAlertsByAgrupation(
            this.currentAgrupation.id,
            this.from,
            this.to
          );
        break;
      default:
        httpRequest = this.ControlAlertsController.getHistoricAlertsByUser(
          this.from,
          this.to
        );
        break;
    }

    // Petición de los datos
    httpRequest.subscribe((response: any) => {
      if (response["code"] == 0) {
        alertList = response["body"];
        alertList?.map((alert: ControlAlert) => {
          alert.stateParsed = this.alertStates[alert.state]
            ? this.alertStates[alert.state]
            : "-";
        });
        this.alertList = alertList;
      }
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-events",
            tooltip: "show-events",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: this.cupsName ? this.cupsName : "CUPS",
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      // {
      //   title: "serial-number",
      //   data: "meterNroSerie",
      //   search: "meterNroSerie",
      //   sort: "meterNroSerie",
      //   visible: true
      // },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: true,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, alert: any): void {
    switch (action) {
      case "show-events":
        this.showModal(alert);
        break;
      default:
        break;
    }
  }

  // Mostrar modal
  showModal(alert: any): void {
    this.ControlAlertsController.getAlertData(alert.id).subscribe(
      (response) => {
        let alertEventData: ControlAlertEvent[] = [];
        if (response["code"] == 0) {
          alertEventData = response["body"]?.events;
        }
        this.MaterialDialogService.openDialog(
          ControlHistoricDialogComponent,
          alertEventData
        );
      }
    );
  }
}
