// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { CoverageControllerService } from "../../../../services/server/CoverageController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { MapDeviceMinimalParseService } from "../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { CoordMeasureService } from "../../../../services/shared/CoordMeasureService.service";
import { DeviceTypeService } from "../../../../services/shared/DeviceTypeService.service";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  MapDevice,
  METROLOGY_TYPE,
} from "../../../../interfaces/DeviceGlobalInterface.type";
import {
  HeatMapDevice,
  HeatMapData,
  HeatMapDeviceData,
  MBusCoverage,
  RSSI_MBUS_THRESHOLDS,
} from "../CoverageInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-coverage-heatmap-mbus",
  templateUrl: "./coverage-heatmap-mbus.component.html",
  styleUrls: ["./coverage-heatmap-mbus.component.scss"],
})
export class CoverageHeatmapMbusComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Mapa
  deviceList: HeatMapDevice[];
  mbusConcentrators: MapDevice[];
  mapData: any[];
  deviceRange: number = 40;
  maxRange: number = 100;
  mapType: string = "coverageMbusMap";
  mapHeight: number;
  polygonWeight: number = 10;
  showRanges: boolean = true;
  activateAllLayers: boolean = false;
  @ViewChild("coverageHeatmapPanel") coverageHeatmapPanel: ElementRef;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  filteredDeviceList: HeatMapDevice[];
  filterMbusConcentrator: MapDevice;
  mbusCoverageData: MBusCoverage[];
  readonly RSSI_MBUS_THRESHOLDS = RSSI_MBUS_THRESHOLDS;
  readonly PROFILES = PROFILES;

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mapHeight =
      this.coverageHeatmapPanel?.nativeElement?.offsetHeight - 40;
  }

  // Contraste
  contrastOpacity: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CoordMeasureService: CoordMeasureService,
    private CoverageController: CoverageControllerService,
    private DeviceMinimalParse: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private HomeController: HomeControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(
      () =>
        (this.mapHeight =
          this.coverageHeatmapPanel?.nativeElement?.offsetHeight - 40),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getInstalledData();
  }

  // Obtención de los datos de dispositivos instalados del mapa
  getInstalledData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let devices = this.DeviceMinimalParse.parseDevices(
            response["body"]["contadores"]
          );
          this.mbusConcentrators = devices.filter(
            (device) => device.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR
          );
          this.mbusConcentrators.map(
            (device) => (device.ignoreCoverValue = true)
          );
          // Cobertura
          this.deviceList = devices
            .filter(
              (device: MapDevice) =>
                device.metrologyType != METROLOGY_TYPE.MBUS_CONCENTRATOR &&
                this.DeviceTypeService.getDeviceTypeByMask(device.tipo) ==
                  DEVICE_BY_COMM.MBUS
            )
            .map((device: MapDevice) => {
              return {
                rssi: device.lastRssi,
                latitude: parseFloat(device.latitude),
                longitude: parseFloat(device.longitude),
                id: device.id,
                nroSerie: device.nroSerie,
                unidadVenta: device.unidadVentaGw,
              };
            });
          if (this.deviceList.length > 0) {
            this.getMbusData();
          } else {
            this.mapData = [];
            this.ToastService.fireToast(
              "info",
              this.translate.instant("no-values")
            );
          }
        }
      }
    );
  }

  // Obtención de los datos MBus
  getMbusData(): void {
    this.CoverageController.getMbusCoverage(
      this.currentAgrupation.id,
      this.deviceList.map((device) => device.id)
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.mbusCoverageData = response["body"];
        this.deviceList.map(
          (device) =>
            (device.rssi = this.mbusCoverageData.find(
              (mbus) => mbus.contador == device.id
            )?.rssi)
        );
        this.getLowSignalMarkers([...this.deviceList]);
        this.activateAllLayers = !this.activateAllLayers;
      }
    });
  }

  // Obtención de los marcadores de baja señal
  getLowSignalMarkers(deviceList: HeatMapDevice[]): void {
    // Priorización de contadores con mala señal
    let lowSignalDevices: HeatMapDevice[] = deviceList.filter(
      (device: HeatMapDevice) => device.rssi <= RSSI_MBUS_THRESHOLDS[0]
    );
    let mapData: HeatMapData[] = [];

    // Creación del array de valores medios asociados a las agrupaciones de contadores
    while (deviceList.length > 0) {
      let newDeviceList: HeatMapDevice[] = [];

      // Agrupación de los contadores en rango del contador en curso
      let deviceInProcess: HeatMapDevice =
        lowSignalDevices.length > 0 ? lowSignalDevices.pop() : deviceList.pop();
      let devicesInRange: HeatMapDevice[] = deviceList.filter(
        (device: HeatMapDevice) => {
          if (
            this.CoordMeasureService.measure(
              deviceInProcess.latitude,
              deviceInProcess.longitude,
              device.latitude,
              device.longitude
            ) <= this.deviceRange &&
            (device.rssi != null ||
              (device.rssi == null && deviceInProcess.rssi == null))
          ) {
            return true;
          } else {
            newDeviceList.push(device);
            return false;
          }
        }
      );
      if (deviceInProcess) {
        devicesInRange.push(deviceInProcess);
      }

      // Cálculo de valor medio de los contadores en rango
      let latLngs: number[][] = [];
      let rssiValues = devicesInRange.map((value: HeatMapDevice) => {
        latLngs.push([value.latitude, value.longitude]);
        return value.rssi;
      });
      let averageValue: number = rssiValues.every(
        (rssi: number) => rssi == null
      )
        ? null
        : rssiValues.reduce((a, b) => a + b) / devicesInRange.length;
      let devicesData: HeatMapDeviceData[] = devicesInRange.map(
        (device: HeatMapDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            rssi: device.rssi,
          };
        }
      );

      // Actualización de los datos del mapa y el array de contadores restantes
      mapData.push({
        latLngs: latLngs,
        averageValue: averageValue,
        devicesData: devicesData,
      });
      deviceList = [...newDeviceList];
    }

    this.mapData = [...mapData, ...this.mbusConcentrators];
  }

  // Actualización del mapa
  updateMapData(reloadMapData: boolean): void {
    if (reloadMapData) {
      this.mapData = null;
      setTimeout(
        () =>
          this.filteredDeviceList
            ? this.getLowSignalMarkers([...this.filteredDeviceList])
            : this.getLowSignalMarkers([...this.deviceList]),
        0
      );
    } else {
      this.mapData = [...this.mapData];
    }
  }

  // Filtrado por gateway
  filterByConcentrator(concentrator: any, update: boolean): void {
    this.filterMbusConcentrator = concentrator;
    let concentratorLinkedDevices = this.mbusCoverageData.filter(
      (data) => data.concentrador == concentrator.id
    );
    this.filteredDeviceList = this.deviceList.filter((device: HeatMapDevice) =>
      concentratorLinkedDevices.some((data) => data.contador == device.id)
    );
    if (update) {
      this.updateMapData(true);
    }
  }

  // Reseteo del filtrado por gateway
  resetFilter(): void {
    this.filterMbusConcentrator = null;
    this.filteredDeviceList = null;
    this.updateMapData(true);
  }

  // Activación de mapa 3d
  activate3dMap(): void {
    this.SessionDataService.sendCesiumData({
      active: true,
      polygonHeatValues: RSSI_MBUS_THRESHOLDS,
      polygonHeatWeight: this.polygonWeight,
      mapType: this.mapType,
      mapHeight: this.coverageHeatmapPanel.nativeElement.offsetHeight - 40,
      drawAgrupationOutline: false,
      allowKml: true,
      devices: [],
      gateways: [],
      coverageData: this.deviceList,
    });
  }
}
