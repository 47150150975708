import { Injectable } from "@angular/core";
import { formatNumber } from "@angular/common";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Parseador de fechas
import * as moment_timezone from "moment-timezone";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { DeviceRouteSelectorService } from "../../../services/shared/DeviceRouteSelectorService.service";
import { DeviceTypeService } from "../../../services/shared/DeviceTypeService.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { CoordMeasureService } from "../../../services/shared/CoordMeasureService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
// Variables de mapa
import { MAP_CONFIG } from "../map-variables/MAP_CONFIG";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
import { MAP_LAYERS } from "../map-variables/MAP_TYPES";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";

@Injectable({
  providedIn: "root",
})
export class MapDeviceTooltipService {
  constructor(
    private CoordMeasureService: CoordMeasureService,
    private DateParserService: DateParserService,
    private DeviceTypeService: DeviceTypeService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Tooltip de contadores
  /***************************************************************************/

  // Tipos de mapa (mapType):
  //   coverage: Mapa de cobertura
  //   control: Mapa de telecontrol
  //   meterDetail: Mapa de detalle de dispositivo
  //   cupsDetail: Mapa de detalle de CUPS
  //   balanceDetail: Mapa de detalle de balance
  //   meterList: Mapa de listas de dispositivos
  //   gatewayDetail: Mapa de detalle de gateway
  //   mbusConcentratorDetail: Mapa de detalle de concentrador MBUS
  //   default: Mapa estándar

  // Generación del panel de texto asociado a cada contador
  getDeviceTooltip(
    device: any,
    layer: string,
    gateways: any,
    sessionProfile: string,
    currentAgrupation: Agrupation,
    mapType: string
  ): string {
    // Tooltip
    let tooltip: string;
    let layerGradient = layer.toLowerCase().replace(/_/g, "");
    // Tooltip de MBUS que comunica igual que el estándar
    if (layer == MAP_LAYERS.MBUS_COMUNICA) {
      layer = MAP_LAYERS.MBUS;
    }

    // Configuración de CUPS
    let entityCups: any = this.SessionDataService.getCurrentEntityCupsConf();
    let entityCupsName: string = entityCups?.find(
      (column: any) => column.colPosition == 0
    )?.name;
    if (!entityCupsName) {
      entityCupsName = "CUPS";
    }

    // Formato de fecha
    let dateFormat = this.SessionDataService.getCurrentDateFormat();

    // Url a dispositivo dependiendo del tipo
    let deviceData: any =
      this.DeviceRouteSelectorService.getMapTooltipDeviceUrl(
        device.metrologyType
      );

    // Nombre del dispositivo dependiendo del tipo
    let deviceTypeByMask: string = this.DeviceTypeService.getDeviceTypeByMask(
      device.tipo,
      device.metrologyType,
      device.fabricante ? device.fabricante : device.idFabricante
    );
    let deviceTypeByMetrology: string =
      this.DeviceTypeService.getDeviceTypeByMetrology(device.metrologyType);

    let cupsExist = this.SessionDataService.getCurrentEntityCupsConf()?.some(
      (column: EntityDefinition) => column.colPosition == 0
    );

    // Formato numérico
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();

    // Tooltip dependiendo del tipo de mapa
    switch (mapType) {
      /***************************************************************************/
      // ANCHOR Mapa cobertura
      /***************************************************************************/
      case "coverage":
        let titleClass: string;
        if (layer === MAP_LAYERS.COVERAGE_OK) {
          titleClass = "coverageok-gradient";
        } else if (layer === MAP_LAYERS.COVERAGE_ERROR) {
          titleClass = "coverageerror-gradient";
        }

        tooltip = `<div class="map-tooltip map-tooltip-control">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title ` +
          titleClass +
          `">
                      <th colspan="2">
                        <b>` +
          this.translate.instant(
            MAP_CONFIG.markerVariables[layer].tooltipTitle
          ) +
          `</b>`;
        // Botón borrar test
        tooltip +=
          `    <i class="fas fa-trash removeTest pointer" title="` +
          this.translate.instant("remove-test") +
          `"></i>
                      </th>
                    </tr>
                    <tr class="map-separator-tr"></tr>`;
        // Datos del contador
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("test-date") +
          `: </b>
                      </td>
                      <td>` +
          (device.timestamp != null
            ? moment_timezone(device.timestamp)
                ?.tz(currentAgrupation?.timezone)
                ?.format(dateFormat + " HH:mm:ss")
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>DevEui: </b>
                      </td>
                      <td>` +
          (device.devEui != null
            ? device.devEui
            : this.translate.instant("no-data")) +
          `</td>
                    </tr>`;
        // Total de localizaciones
        if (device.locationList?.length > 0) {
          tooltip +=
            `<tr>
            <td>
              <b>` +
            this.translate.instant("locations") +
            `: </b>
            </td>
            <td>` +
            device.locationList.length +
            `</td>
          </tr>`;
        }
        // Comentarios
        if (device.comment) {
          tooltip +=
            `<tr><td><b>` +
            this.translate.instant("comments") +
            `: </b></td></tr><tr><td colspan=2>` +
            device.comment +
            `</td></tr>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa telecontrol
      /***************************************************************************/
      case "control":
        tooltip = `<div class="map-tooltip map-tooltip-control">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // Tipo de dispositivo
        switch (layer) {
          // Válvula
          case "CONTROL_VALVE_METER":
            tooltip +=
              `<tr class="map-tooltip-title controlvalvemeter-gradient">
                        <th colspan="2">
                            <b>` +
              entityCupsName +
              `:</b> 
                            <a class="map-link" href="#/cups/detalle/` +
              device.idCups +
              `">` +
              (device.cups != null
                ? device.cups
                : this.translate.instant("no-data")) +
              `
                              <i class="fas fa-link"></i>
                            </a>
                        </th>
                      </tr>
                      <tr class="map-separator-tr"></tr>
                      <tr>
                        <td>
                          <b>` +
              this.translate.instant("serial-number") +
              `:</b> 
                        </td>
                        <td>
                          <a class="map-link" href="#/dispositivos/detalle/contador/` +
              device.id +
              `">` +
              (device.nroSerie != null
                ? device.nroSerie
                : this.translate.instant("no-data")) +
              ` <i class="fas fa-link"></i>
                </a>`;
            if (device.haveImage == true) {
              tooltip += `<button class="btn btn-default photoModal">
                    <i class="fas fa-image"></i>
                </button>`;
            }
            tooltip += `</td>
                      </tr>`;
            break;
          // Alerta asignada
          case "CONTROL_ALERT_ASSIGNED":
            tooltip +=
              `<tr class="map-tooltip-title controlalertassigned-gradient">
                          <th colspan="2">
                              <b>` +
              entityCupsName +
              `:</b> 
                              <a class="map-link" href="#/cups/detalle/` +
              device.cupsId +
              `">` +
              (device.cups != null
                ? device.cups
                : this.translate.instant("no-data")) +
              `
                                <i class="fas fa-link"></i>
                              </a>
                          </th>
                        </tr>
                        <tr class="map-separator-tr"></tr>  
                        <tr>
                          <td>
                            <b>` +
              this.translate.instant("serial-number-device") +
              `:</b>
                          </td>
                          <td> ` +
              (device.meterNroSerie != null
                ? device.meterNroSerie
                : this.translate.instant("no-data")) +
              ` 
                          </td>
                        </tr>
                      <tr>
                      <tr class="pointer showInfo">
                        <td colspan="2">
                          <button class="btn btn-default map-show-alert"">
                            <i class="fas fa-info"></i>
                            <span>` +
              this.translate.instant("show-alert") +
              `</span>
                          </button>
                        </td>
                      </tr>`;
            break;
          // Alerta abierta
          case "CONTROL_ALERT_OPEN":
            tooltip +=
              `<tr class="map-tooltip-title controlalertopen-gradient">
                          <th colspan="2">
                              <b>` +
              entityCupsName +
              `:</b> 
                              <a class="map-link" href="#/cups/detalle/` +
              device.cupsId +
              `">` +
              (device.cups != null
                ? device.cups
                : this.translate.instant("no-data")) +
              `
                                <i class="fas fa-link"></i>
                              </a>
                          </th>
                        </tr>
                        <tr class="map-separator-tr"></tr>  
                        <tr>         
                          <td>
                            <b>` +
              this.translate.instant("serial-number-device") +
              `: </b>
                          </td>
                          <td>` +
              (device.meterNroSerie != null
                ? device.meterNroSerie
                : this.translate.instant("no-data")) +
              ` 
                          </td>
                        </tr>
                        <tr class="pointer showInfo">
                          <td colspan="2">
                            <button class="btn btn-default map-show-alert"">
                              <i class="fas fa-info"></i>
                              <span>` +
              this.translate.instant("show-alert") +
              `</span>
                            </button>
                          </td>
                        </tr>`;
            break;
          // Sensor
          case "CONTROL_SENSOR":
            tooltip +=
              `<tr class="map-tooltip-title controlsensor-gradient">
                          <th colspan="2">
                              <b>` +
              entityCupsName +
              `:</b> 
                              <a class="map-link" href="#/cups/detalle/` +
              device.idCups +
              `">` +
              (device.cups != null
                ? device.cups
                : this.translate.instant("no-data")) +
              `
                                <i class="fas fa-link"></i>
                              </a>
                          </th>
                        </tr>
                        <tr class="map-separator-tr"></tr>  
                        <tr>   
                          <td>          
                            <b>` +
              this.translate.instant("serial-number") +
              `: </b>
                          </td>
                          <td> 
                            <a class="map-link"  href="#/dispositivos/detalle/sensor/fidegas/` +
              device.id +
              `">` +
              (device.nroSerie != null
                ? device.nroSerie
                : this.translate.instant("no-data")) +
              ` 
                              <i class="fas fa-link"></i>
                            </a>`;
            // Imagen
            if (device.haveImage == true) {
              tooltip +=
                `<i class="fas fa-image photoModal pointer" title="` +
                this.translate.instant("show-image") +
                `"></i>`;
            }
            tooltip += `</td>
                      </tr>`;
            break;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de detector de presión
      /***************************************************************************/
      case "pressureDetection":
        tooltip = `<div class="map-tooltip map-tooltip-meterDetail">
        <span class="map-tooltip-close-background"></span>
        <table>
          <tr></tr>`;
        tooltip += `<tr class="map-tooltip-title `;

        tooltip += device.warning ? `selected-gradient">` : `erm-gradient">`;
        tooltip +=
          `<th colspan="2">
            <b>` +
          this.translate.instant(
            deviceTypeByMetrology.replace("_", "-").toLowerCase()
          ) +
          `: </b>` +
          `<a class="map-link"  href="` +
          deviceData.url +
          device.id +
          `">` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          ` <i class="fas fa-link"></i>
          </a>
            </th>
          </tr>
          <tr class="map-separator-tr"></tr>`;
        tooltip +=
          `<tr><td>
              <button class="btn btn-default leakSensorGraph">
                <span>` +
          this.translate.instant("show-graph") +
          `</span>
                  </button>
                </td>
              </tr>`;
        break;

      /***************************************************************************/
      // ANCHOR Mapa de detalle de contador
      /***************************************************************************/
      case "meterDetail":
        tooltip = `<div class="map-tooltip map-tooltip-meterDetail">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        tooltip += `<tr class="map-tooltip-title `;

        tooltip +=
          (device.selected
            ? "selected"
            : layer == MAP_LAYERS.NO_LORA
            ? device.comunica
              ? "nolora-ok"
              : "nolora-fail"
            : layerGradient) + `-gradient">`;

        if (device.concentratorLink && deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON) {
          tooltip +=
            `<th colspan="2">
                            <b>` +
            deviceData.name +
            `: </b>` +
            `<a class="map-link" title="` +
            this.translate.instant("show-detail") +
            `" href="` +
            deviceData.url +
            device.id +
            `">` +
            (device.nroSerie != null
              ? device.nroSerie + ` <i class="fas fa-link"></i> `
              : this.translate.instant("no-data")) +
            `</a>
                        </th>
                      </tr>
                      <tr class="map-separator-tr"></tr>`;
        } else {
          tooltip +=
            `<th colspan="2">
                                      <b>` +
            deviceData.name +
            `: </b>` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `
                                  </th>
                                </tr>
                                <tr class="map-separator-tr"></tr>`;
        }

        //Contadores No Lora
        if (
          deviceTypeByMask == DEVICE_BY_COMM.EK280 ||
          deviceTypeByMask == DEVICE_BY_COMM.OWASYS ||
          deviceTypeByMask == DEVICE_BY_COMM.PLUM ||
          deviceTypeByMask == DEVICE_BY_COMM.ERM ||
          deviceTypeByMask == DEVICE_BY_COMM.API
        ) {
          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("last-communication") +
            `: </b>
                        </td>
                        <td>` +
            (device.lastCommunication != null
              ? moment_timezone(device.lastCommunication)
                  ?.tz(currentAgrupation?.timezone)
                  ?.format(dateFormat + " HH:mm:ss")
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>`;
          // Contadores UNE y MBUS
        } else if (deviceTypeByMask == DEVICE_BY_COMM.UNE || deviceTypeByMask == DEVICE_BY_COMM.MBUS) {
          if (device.listaConcentradores?.length > 0) {
            tooltip +=
              `<tr>
                  <td class="map-list-title"><b>` +
              this.translate.instant(
                deviceTypeByMask == DEVICE_BY_COMM.UNE
                  ? "associated-lw-une"
                  : "associated-lw-mbus"
              ) +
              `: </b></td><td><ul>`;

            device.listaConcentradores.forEach((concentrator) => {
              tooltip +=
                `<li><a href="#/dispositivos/detalle/` +
                (deviceTypeByMask == DEVICE_BY_COMM.UNE ? `lw-une/` : `lw-mbus/`) +
                concentrator.idConcentrador +
                `">` +
                concentrator.nroSerieConcentrador +
                ` <i class="fas fa-link"></i>
                                </a></li>`;
            });
            tooltip += `</ul></td></tr>`;
          } else {
            tooltip +=
              `<tr>
                          <td> 
                            <b>` +
              this.translate.instant(
                deviceTypeByMask == DEVICE_BY_COMM.UNE
                  ? "associated-lw-une"
                  : "associated-lw-mbus"
              ) +
              `: </b> 
                              ` +
              this.translate.instant("unknown") +
              `
                          </td>
                        </tr>`;
          }
          // Contador estándar
        } else if (
          device.concentratorLink &&
          deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON
        ) {
          tooltip +=
            `<tr>
                      <td>
                        <b>` +
            this.translate.instant("last-rssi") +
            `:</b> 
                      </td> 
                      <td>` +
            (device.rssi != null ? device.rssi + "dBm" : "-") +
            `
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>` +
            this.translate.instant("total-communications") +
            `: </b>
                                        </td>
                                        <td>` +
            (device.totalComms != null
              ? device.totalComms
              : this.translate.instant("unknown")) +
            `
                      </td>
                    </tr><tr>
                      <td>
                        <b>` +
            this.translate.instant("last-communication") +
            `: </b>
                      </td>
                      <td>` +
            (device.lastComm != null
              ? this.DateParserService.parseDate(
                  device.lastComm,
                  dateFormat + " HH:mm"
                )
              : this.translate.instant("unknown")) +
            `
                    </td>
                  </tr>`;
        } else {
          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("last-rssi") +
            `:</b> 
                        </td> 
                        <td>` +
            (device.lastRssi != null ? device.lastRssi + "dBm" : "-") +
            `
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>` +
            this.translate.instant("expansion-factor") +
            `: </b>
                        </td>
                        <td>` +
            (device.lastDataRate != null
              ? device.lastDataRate
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>`;
          // Datos de gateway principal
          if (sessionProfile == PROFILES.ARSON) {
            tooltip +=
              device.gateway != null
                ? `<tr>
                <td>
                  <b>` +
                  this.translate.instant("main-gateway") +
                  `</b>: 
                </td>
                <td>
                    <a href="#/gateways/detalle/gateway/` +
                  gateways.find((gateway: any) => gateway.mainGateway)?.id +
                  `"> ` +
                  device.gateway +
                  ` <i class='fas fa-link'></i>
                  </a>
                </td>
              </tr>`
                : `<tr>
                <td>
                  <b>` +
                  this.translate.instant("main-gateway") +
                  "</b>: " +
                  this.translate.instant("no-data") +
                  `
                </td>
              </tr>`;
          } else {
            tooltip +=
              `<tr>
                          <td>
                            <b>` +
              this.translate.instant("main-gateway") +
              `</b>: 
                          </td>
                          <td>` +
              (device.gateway != null
                ? device.gateway
                : this.translate.instant("no-data")) +
              `>
                          </td>
                        </tr>`;
          }
          // Datos de gateways redundantes
          if (
            gateways.filter((gateway: any) => !gateway.mainGateway).length > 0
          ) {
            tooltip +=
              `<tr>
                          <td colspan="2">
                            <b>` +
              this.translate.instant("redundant-gateway") +
              `:</b>
                            <ul>`;
            gateways
              .filter(
                (gateway: any) => !gateway.mainGateway && !gateway.otherEntity
              )
              .forEach((gateway: any) => {
                if (sessionProfile == PROFILES.ARSON) {
                  tooltip +=
                    `<li title="` +
                    this.translate.instant("show-detail") +
                    `">
                              <a href="#/gateways/detalle/gateway/` +
                    gateway.id +
                    `">` +
                    this.translate.instant("gateway") +
                    ": " +
                    (gateway.unidadVenta != null
                      ? gateway.unidadVenta
                      : this.translate.instant("no-data")) +
                    ` <i class="fas fa-link"></i>
                              </a>
                            </li>`;
                } else {
                  tooltip +=
                    `<li>
                              <b>` +
                    this.translate.instant("gateway") +
                    ": " +
                    (gateway.unidadVenta != null
                      ? gateway.unidadVenta
                      : this.translate.instant("no-data")) +
                    `</b>
                            </li>`;
                }
              });
            tooltip += `</ul></td></tr>`;
          }
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de detalle de CUPS
      /***************************************************************************/
      case "cupsDetail":
        tooltip = `<div class="map-tooltip map-tooltip-cupsDetail">
                        <span class="map-tooltip-close-background"></span>
                        <table>
                          <tr></tr>`;
        // Datos de contador
        if (sessionProfile == PROFILES.ARSON) {
          tooltip += `<tr class="map-tooltip-title `;

          tooltip +=
            (device.selected
              ? "selected"
              : layer == MAP_LAYERS.NO_LORA
              ? device.comunica
                ? "nolora-ok"
                : "nolora-fail"
              : layerGradient) + `-gradient">`;

          tooltip +=
            `<th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `"><b>` +
            deviceData.name +
            ": " +
            `</b>
                          <a class="map-link" href="` +
            deviceData.url +
            device.id +
            `">` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            ` <i class="fas fa-link"></i>
                          </a>`;
          // Imagen
          if (device.haveImage == true) {
            tooltip +=
              `<i class="fas fa-image photoModal pointer" title="` +
              this.translate.instant("show-image") +
              `"></i>`;
          }
          tooltip += `</th>
                      </tr>
                      <tr class="map-separator-tr"></tr>`;
        } else {
          tooltip +=
            `<tr class="map-tooltip-title cupsdetailmeter-gradient">
                        <th colspan="2">
                          <b>` +
            this.translate.instant("device") +
            ": </b>" +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `
                        </th>
                      </tr>
                      <tr class="map-separator-tr"></tr>`;
        }
        //Contadores No Lora
        if (
          deviceTypeByMask == DEVICE_BY_COMM.EK280 ||
          deviceTypeByMask == DEVICE_BY_COMM.OWASYS ||
          deviceTypeByMask == DEVICE_BY_COMM.PLUM ||
          deviceTypeByMask == DEVICE_BY_COMM.ERM ||
          deviceTypeByMask == DEVICE_BY_COMM.API
        ) {
          tooltip +=
            `<tr>
                          <td>
                            <b>` +
            this.translate.instant("last-communication") +
            `: </b>
                          </td>
                          <td>` +
            (device.lastCommunication != null
              ? moment_timezone(device.lastCommunication)
                  ?.tz(currentAgrupation?.timezone)
                  ?.format(dateFormat + " HH:mm:ss")
              : this.translate.instant("no-data")) +
            `
                          </td>
                        </tr>`;
        } else {
          tooltip +=
            `<tr>
                          <td>
                            <b>` +
            this.translate.instant("last-rssi") +
            `: </b> 
                          </td>
                          <td> ` +
            (device.lastRssi != null ? device.lastRssi + "dBm" : "-") +
            `
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>` +
            this.translate.instant("expansion-factor") +
            `: </b>
                          </td>
                          <td> ` +
            (device.lastDataRate != null
              ? device.lastDataRate
              : this.translate.instant("no-data")) +
            `</b>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>` +
            this.translate.instant("main-gateway") +
            `:</b> 
                          </td>
                          <td> `;
          if (
            device.metrologyType != METROLOGY_TYPE.SENSOR &&
            device.metrologyType != METROLOGY_TYPE.ACOUSTIC_SENSOR
          ) {
            tooltip +=
              device.gateway != null
                ? device.gateway
                : this.translate.instant("no-data");
          } else if (
            device.metrologyType == METROLOGY_TYPE.SENSOR ||
            device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
          ) {
            tooltip +=
              device.gateway_name != null
                ? device.gateway_name
                : this.translate.instant("no-data");
          }
          tooltip += `</td>
                          </tr>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de balances
      /***************************************************************************/
      case "balanceDetail":
        tooltip = `<div class="map-tooltip map-tooltip-control">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // CUPS
        if (device.claveMaestraKey && cupsExist) {
          tooltip +=
            `<tr class="map-tooltip-title cups-gradient">
                        <th colspan="2">
                          <b>` +
            entityCupsName +
            `: </b>
                          <a class="map-link" href="#/cups/detalle/` +
            device.claveMaestraId +
            `">
                            ` +
            device.claveMaestraKey +
            ` 
                            <i class="fas fa-link"></i>
                          </a>
                        </th>
                      </tr>
                      <tr class="map-tooltip-title-withCUPS ` +
            layerGradient +
            `-gradient">`;
        } else {
          tooltip +=
            `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;
        }

        tooltip +=
          `<th colspan="2">
                      <b>` +
          deviceData.name +
          `: </b>
                      <a class="map-link" title="` +
          this.translate.instant("show-detail") +
          `" href="` +
          deviceData.url +
          device.meterId +
          `">` +
          (device.meterNroSerie != null
            ? device.meterNroSerie + ` <i class="fas fa-link"></i> `
            : this.translate.instant("no-data")) +
          `
                      </a>`;
        // Imagen
        if (device.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                      </tr>`;

        if (device.claveMaestraKey) {
          tooltip += `<tr class="map-separator-tr-withCUPS"></tr>`;
        } else {
          tooltip += `<tr class="map-separator-tr"></tr>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de edición de balances
      /***************************************************************************/
      case "balanceEdition":
        tooltip = `<div class="map-tooltip map-tooltip-control">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // CUPS
        if (device.claveMaestraKey && cupsExist) {
          tooltip +=
            `<tr class="map-tooltip-title cups-gradient">
                        <th colspan="2">
                          <b>` +
            entityCupsName +
            `: </b>
                          <a class="map-link" href="#/cups/detalle/` +
            device.claveMaestraId +
            `">
                          ` +
            device.claveMaestraKey +
            ` 
                            <i class="fas fa-link"></i>
                          </a>
                        </th>
                      </tr>
                      <tr class="map-tooltip-title-withCUPS ` +
            layerGradient +
            `-gradient">`;
        } else {
          tooltip +=
            `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;
        }
        tooltip +=
          `<th colspan="2">
                      <b>` +
          deviceData.name +
          `: </b>
                      <a class="map-link" title="` +
          this.translate.instant("show-detail") +
          `" href="` +
          deviceData.url +
          device.meterId +
          `">` +
          (device.meterNroSerie != null
            ? device.meterNroSerie + ` <i class="fas fa-link"></i> `
            : this.translate.instant("no-data")) +
          `
                      </a>`;
        // Imagen
        if (device.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                      </tr>`;

        if (device.claveMaestraKey) {
          tooltip += `<tr class="map-separator-tr-withCUPS"></tr>`;
        } else {
          tooltip += `<tr class="map-separator-tr"></tr>`;
        }

        // Opciones de balance
        tooltip +=
          `<tr>
              <td>
                <button class="btn btn-default father">
                  <span>` +
          (device.meterType == "FATHER"
            ? this.translate.instant("balance-father-quit")
            : this.translate.instant("balance-father-add")) +
          `</span>
                </button>
              </td>`;
        tooltip +=
          `<td>
              <button class="btn btn-default child">
                <span>` +
          (device.meterType == "CHILD"
            ? this.translate.instant("balance-child-quit")
            : this.translate.instant("balance-child-add")) +
          `</span>
                  </button>
                </td>
              </tr>`;
        break;

      /***************************************************************************/
      // ANCHOR Mapa de sospechas
      /***************************************************************************/
      case "warnings":
        tooltip = `<div class="map-tooltip map-tooltip-default">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // Dispositivos
        tooltip +=
          `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;

        tooltip +=
          `  <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                          <b>` +
          deviceData.name +
          `: </b>
                          <a class="map-link" href="` +
          deviceData.url +
          device.id +
          `">` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          `
                          <i class="fas fa-link"></i></a>`;
        // Imagen
        if (device.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                      </tr>`;

        tooltip += `<tr class="map-separator-tr"></tr>`;
        break;

      /***************************************************************************/
      // ANCHOR Mapa lista de dispositivos
      /***************************************************************************/
      case "meterList":
        tooltip = `<div class="map-tooltip map-tooltip-default">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // Dispositivos
        tooltip +=
          `<tr class="map-tooltip-title ` +
          (device.selected ? "selected" : layerGradient) +
          `-gradient">`;

        // Abonado
        if (sessionProfile === PROFILES.ABONADO) {
          tooltip +=
            `  <th colspan="2">
                          <b>` +
            deviceData.name +
            `: </b>` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `
                        </th>
                      </tr>`;

          tooltip += `<tr class="map-separator-tr"></tr>`;

          tooltip += `<tr>
                        <td>
                          <b> Dirección o consumos o dato a mostrar </b>
                        </td>
                        <td>
                        </td>
                      </tr>`;
        } else {
          tooltip +=
            `  <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            deviceData.name +
            `: </b>
                          <a class="map-link" href="` +
            deviceData.url +
            device.id +
            `">` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `
                          <i class="fas fa-link"></i></a>`;
          // Imagen
          if (device.haveImage == true) {
            tooltip +=
              `<i class="fas fa-image photoModal pointer" title="` +
              this.translate.instant("show-image") +
              `"></i>`;
          }
          tooltip += `</th>
                      </tr>`;

          tooltip += `<tr class="map-separator-tr"></tr>`;

          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("last-communication") +
            `: </b>
                        </td>
                        <td>` +
            (device.lastCommunication != null
              ? moment_timezone(device.lastCommunication)
                  ?.tz(currentAgrupation?.timezone)
                  ?.format(dateFormat + " HH:mm:ss")
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de detalle de concentrador MBUS
      /***************************************************************************/
      case "mbusConcentratorDetail":
        tooltip = `<div class="map-tooltip map-tooltip-meterDetail">
        <span class="map-tooltip-close-background"></span>
        <table>
          <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;

        tooltip +=
          `  <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                      <b>` +
          deviceData.name +
          `: </b>`;

        // Concentrador MBUS
        if (deviceTypeByMask != DEVICE_BY_COMM.LW_MBUS_CON) {
          tooltip +=
            `<a class="map-link" href="` + deviceData.url + device.id + `">`;
        }
        tooltip +=
          device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data");

        // Concentrador MBUS
        if (deviceTypeByMask != DEVICE_BY_COMM.LW_MBUS_CON) {
          tooltip += `<i class="fas fa-link"></i></a>`;
        }
        tooltip += `</th>
                  </tr>
                  <tr class="map-separator-tr"></tr>`;
        // Dispositivos
        if (deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON) {
          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("total-devices") +
            `: </b>
                        </td>
                        <td>` +
            device.totalMeters +
            `           </td>
                      </tr>`;
        } else {
          tooltip +=
            `<tr>
                        <td>
                          <b>` +
            this.translate.instant("last-communication") +
            `: </b>
                        </td>
                        <td>` +
            (device.lastComm != null
              ? moment_timezone(device.lastComm)
                  ?.tz(currentAgrupation?.timezone)
                  ?.format(dateFormat + " HH:mm:ss")
              : this.translate.instant("no-data")) +
            `
                        </td>
                      </tr>`;
          tooltip +=
            `<tr>
                                    <td>
                                      <b>` +
            this.translate.instant("total-communications") +
            `: </b>
                                    </td>
                                    <td>` +
            (device.totalComms
              ? device.totalComms
              : this.translate.instant("no-data")) +
            `
                                    </td>
                                  </tr>`;
          tooltip +=
            `<tr>
                                    <td>
                                      <b>` +
            this.translate.instant("RSSI") +
            `: </b>
                                    </td>
                                    <td>` +
            (device.rssi ? device.rssi : this.translate.instant("no-data")) +
            `
                                    </td>
                                  </tr>`;
          // Asignar/Desasignar como fijo
          tooltip +=
            `<tr><td><button class="btn btn-default lwMbusFixDevice">` +
            this.translate.instant(
              device.comunica ? "unassign-permanent" : "assign-permanent"
            ) +
            `</button></tr></td>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa lista de dispositivos con alarmas
      /***************************************************************************/
      case "alarmsMeterList":
        tooltip = `<div class="map-tooltip map-tooltip-default">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // Contadores asignados a CUPS
        if (device.claveMaestra && cupsExist) {
          tooltip +=
            `<tr class="map-tooltip-title cups-gradient">
                      <th colspan="2">
                        <b>` +
            entityCupsName +
            `: </b>
                        <a class="map-link alarmLink" href="#/cups/detalle/` +
            device.claveMaestra +
            `">
                          ` +
            (device.claveMaestraKey != null
              ? device.claveMaestraKey
              : this.translate.instant("no-data")) +
            `
                          <i class="fas fa-link"></i>
                        </a>
                      </th>
                    </tr>`;
        }

        if (device.claveMaestra) {
          tooltip += `<tr class="map-tooltip-title-withCUPS `;
        } else {
          tooltip += `<tr class="map-tooltip-title `;
        }

        // Dispositivos
        tooltip += layerGradient + `-gradient">`;

        tooltip +=
          `  <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                        <b>` +
          deviceData.name +
          `: </b>
                        <a class="map-link alarmLink" href="` +
          deviceData.url +
          device.contador +
          `">` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          `
                        <i class="fas fa-link"></i></a>`;
        // Imagen
        if (device.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                    </tr>`;

        tooltip += `<tr class="map-separator-tr"></tr>`;

        // Alarma
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("last-alarm") +
          `: </b>
                      </td>
                      <td>` +
          (device.code != null
            ? this.translate.instant("AlertMeter" + device.code)
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>`;

        // Fecha de inicio
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("start-date") +
          `: </b>
                      </td>
                      <td>` +
          (device.initDate != null
            ? moment_timezone(device.initDate)
                ?.tz(currentAgrupation?.timezone)
                ?.format(dateFormat + " HH:mm:ss")
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>`;

        // Fecha de fin
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("end-date") +
          `: </b>
                      </td>
                      <td>` +
          (device.finalDate != null
            ? moment_timezone(device.finalDate)
                ?.tz(currentAgrupation?.timezone)
                ?.format(dateFormat + " HH:mm:ss")
            : this.translate.instant("no-data")) +
          `
                      </td>
                    </tr>`;
        break;

      /***************************************************************************/
      // ANCHOR Mapa de detalle de gateway
      /***************************************************************************/
      case "gatewayDetail":
        tooltip = `<div class="map-tooltip map-tooltip-default">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        tooltip +=
          `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;

        tooltip +=
          `  <th colspan="2" title="` +
          this.translate.instant("show-detail") +
          `">
                        <b>` +
          deviceData.name +
          `: </b>
                        <a class="map-link" href="` +
          deviceData.url +
          device.id +
          `">` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          `
                        <i class="fas fa-link"></i></a>`;
        // Imagen
        if (device.haveImage == true) {
          tooltip +=
            `<i class="fas fa-image photoModal pointer" title="` +
            this.translate.instant("show-image") +
            `"></i>`;
        }
        tooltip += `</th>
                    </tr>`;

        tooltip += `<tr class="map-separator-tr"></tr>`;

        // Datos de comunicación
        tooltip +=
          `<tr>
                      <td>
                        <b>` +
          this.translate.instant("last-rssi") +
          `: </b>
                      </td>
                      <td>` +
          (device.lastRssi != null ? device.lastRssi + " dBm" : "-") +
          `
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>` +
          this.translate.instant("SF") +
          `: </b>
                      </td>
                      <td> ` +
          (device.percentajeReadedFrames != null
            ? device.percentajeReadedFrames + "%"
            : "-") +
          `
                      </td>
                    </tr>
                    <tr>
                      <td> 
                        <b> ` +
          this.translate.instant("gateway") +
          `: </b>
                      </td>
                      <td> ` +
          (device.gwUnidadVenta != null ? device.gwUnidadVenta : "-") +
          `
                      </td>
                    </tr>`;
        // Distancia a gateway principal
        let mainGateway = gateways.find((gateway: any) => gateway.amei != null);
        tooltip +=
          `<tr>
              <td>
                <b>` +
          this.translate.instant("distance-to-gateway") +
          " " +
          mainGateway?.unidadVenta +
          `: </b>
              </td>
              <td>` +
          this.getMainGatewayDistance(device, mainGateway) +
          `
            </td>
          </tr>`;

        if (device.assignable) {
          // Asignar
          tooltip +=
            `<tr><td><button class="btn btn-default allocateDevice">` +
            this.translate.instant("assign") +
            `</button></tr></td>`;
        } else if (!device.main) {
          // Asignar como principal
          tooltip +=
            `<tr><td><button class="btn btn-default allocateDeviceAsMain">` +
            this.translate.instant("assign-as-main-meter") +
            `</button></tr></td>`;
          // Desasignar
          tooltip +=
            `<tr><td><button class="btn btn-default deallocateDevice">` +
            this.translate.instant("unassign") +
            `</button></tr></td>`;
        }
        break;

      /***************************************************************************/
      // ANCHOR Mapa de sustituciones
      /***************************************************************************/
      case "substitutions":
        tooltip = `<div class="map-tooltip map-tooltip-default">
                            <span class="map-tooltip-close-background"></span>
                            <table>
                              <tr></tr>`;
        // Dispositivos
        tooltip +=
          `<tr class="map-tooltip-title ` + layerGradient + `-gradient">`;

        tooltip +=
          `  <th colspan="2">
                                  <b>` +
          deviceData.name +
          `: </b>
                                  <span class="map-link">` +
          (device.nroSerie != null
            ? device.nroSerie
            : device.nroSerieNew != null
            ? device.nroSerieNew
            : this.translate.instant("no-data")) +
          `
                                  </span>
                                </th>
                              </tr>`;

        tooltip += `<tr class="map-separator-tr"></tr>`;
        if (device.nroSerieOld) {
          tooltip +=
            `<tr>
                    <td colspan="2">
                      <b>` +
            this.translate.instant("previous") +
            `: </b>` +
            device.nroSerieOld +
            `
                  </td>
                </tr>`;
        }

        break;

      /***************************************************************************/
      // ANCHOR Mapa desconocidos LW MBUS
      /***************************************************************************/
      case "unknownDevices":
        tooltip = `<div class="map-tooltip map-tooltip-meterDetail">
        <span class="map-tooltip-close-background"></span>
        <table>
          <tr></tr>`;
        tooltip += `<tr class="map-tooltip-title `;
        tooltip += layerGradient + `-gradient">`;
        tooltip +=
          `<th colspan="2">
            <b>` +
          this.translate.instant(
            deviceTypeByMetrology.replace("_", "-").toLowerCase()
          ) +
          `: </b>` +
          `<a class="map-link" href="` +
          "#/dispositivos/detalle/lw-mbus/" +
          device.id +
          `">` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          `
                        <i class="fas fa-link"></i>
                      </a>` +
          `
            </th>
          </tr>
          <tr class="map-separator-tr"></tr>`;
        break;

      /***************************************************************************/
      // ANCHOR Mapa estándar
      /***************************************************************************/
      default:
        tooltip = `<div class="map-tooltip map-tooltip-default">
                    <span class="map-tooltip-close-background"></span>
                    <table>
                      <tr></tr>`;
        // Contadores asignados a CUPS
        if (device.isAssignedCups && cupsExist) {
          tooltip +=
            `<tr class="map-tooltip-title cups-gradient">
                      <th colspan="2">
                        <b>` +
            entityCupsName +
            `: 
                        <a class="map-link" href="#/cups/detalle/` +
            device.claveMaestraId +
            `">
                          ` +
            (device.claveMaestraKey != null
              ? device.claveMaestraKey
              : this.translate.instant("no-data")) +
            ` </b>
                          <i class="fas fa-link"></i>
                        </a>
                      </th>
                    </tr>`;
        }

        // Contadores sin datos de comunicación
        if (
          (layer != MAP_LAYERS.OK && layer != MAP_LAYERS.CONCENTRATOR_OK) ||
          device.unidadVentaGw != null
        ) {
          if (device.isAssignedCups && cupsExist) {
            tooltip += `<tr class="map-tooltip-title-withCUPS `;
          } else {
            tooltip += `<tr class="map-tooltip-title `;
          }

          tooltip +=
            (device.selected
              ? "selected"
              : layer == MAP_LAYERS.NO_LORA
              ? device.comunica
                ? "nolora-ok"
                : "nolora-fail"
              : layerGradient) + `-gradient">`;
          tooltip +=
            `  <th colspan="2" title="` +
            this.translate.instant("show-detail") +
            `">
                          <b>` +
            deviceData.name +
            `: </b>`;

          if (
            deviceTypeByMask != DEVICE_BY_COMM.LW_UNE_CON ||
            (deviceTypeByMask == DEVICE_BY_COMM.LW_UNE_CON &&
              sessionProfile == PROFILES.ARSON)
          ) {
            tooltip +=
              `<a class="map-link" href="` +
              deviceData.url +
              device.id +
              `">` +
              (device.nroSerie != null
                ? device.nroSerie
                : this.translate.instant("no-data")) +
              `<i class="fas fa-link"></i></a>`;
          } else {
            tooltip +=
              device.nroSerie != null
                ? device.nroSerie
                : this.translate.instant("no-data");
          }
          // Imagen
          if (device.haveImage == true) {
            tooltip +=
              `<i class="fas fa-image photoModal pointer" title="` +
              this.translate.instant("show-image") +
              `"></i>`;
          }
          tooltip += `  </th>
                      </tr>`;

          // CUPS
          if (device.isAssignedCups && cupsExist) {
            tooltip += `<tr class="map-separator-tr-withCUPS"></tr>`;
          } else {
            tooltip += `<tr class="map-separator-tr"></tr>`;
          }
          // Contadores por tipo
          switch (layer) {
            // Pendiente
            case MAP_LAYERS.NO_ASIGNADO:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("device-not-asigned") +
                "</b></td></tr>";
              tooltip +=
                `<tr><td><b>` +
                this.translate.instant("installation-date") +
                `:</b> ` +
                (device.installation
                  ? this.DateParserService.parseDate(
                      device.installation,
                      dateFormat + " HH:mm"
                    )
                  : this.translate.instant("unknown")) +
                `</td></tr>`;
              break;
            // Concentrador pendiente
            case MAP_LAYERS.CONCENTRATOR_NO_ASIGNADO:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("concentrator-not-assigned") +
                "</b></td></tr>";
              tooltip +=
                `<tr><td><b>` +
                this.translate.instant("installation-date") +
                `:</b> ` +
                (device.installation
                  ? this.DateParserService.parseDate(
                      device.installation,
                      dateFormat + " HH:mm"
                    )
                  : this.translate.instant("unknown")) +
                `</td></tr>`;
              break;
            // No LoRaWAN
            case MAP_LAYERS.NO_LORA:
              switch (deviceTypeByMask) {
                case DEVICE_BY_COMM.ERM:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("meter") +
                    " ERM</b></td></tr>";
                  break;
                case DEVICE_BY_COMM.EK280:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("meter") +
                    " EK280</b></td></tr>";
                  break;
                case DEVICE_BY_COMM.OWASYS:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("meter") +
                    " OWASYS</b></td></tr>";
                  break;
                case DEVICE_BY_COMM.PLUM:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("meter") +
                    " PLUM</b></td></tr>";
                  break;
                case DEVICE_BY_COMM.API:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("meter") +
                    " API</b></td></tr>";
                  break;
                default:
                  tooltip +=
                    "<tr><td><b>" +
                    this.translate.instant("no-lora-meter") +
                    "</b></td></tr>";
                  break;
              }
              break;
            // Sin comunicaciones
            case MAP_LAYERS.NO_LORA_NO_COM:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("no-lora-no-com-meter") +
                "</b></td></tr>";
              break;
            // MBUS
            case DEVICE_BY_COMM.MBUS:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("meter") +
                " MBus</b></td></tr>";
              // LW-MBUS
              if (device.listaConcentradores?.length > 0) {
                tooltip +=
                  `<tr>
                       <td class="map-list-title"><b>` +
                  this.translate.instant("associated-lw-mbus") +
                  `: </b> </td><td><ul>`;

                device.listaConcentradores.forEach((concentrator) => {
                  tooltip +=
                    `<li><a href="#/dispositivos/detalle/lw-mbus/` +
                    concentrator.idConcentrador +
                    `">` +
                    concentrator.nroSerieConcentrador +
                    ` <i class='fas fa-link'></i>
                        </a></li>`;
                });
                tooltip += `</ul></td></tr>`;
              }
              break;
            // Wavenis
            case MAP_LAYERS.WAVENIS:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("meter") +
                " Wavenis</b></td></tr>";
              break;
            // UNE
            case MAP_LAYERS.UNE_OK:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("meter") +
                " UNE 82326</b></td></tr>";
              if (device.listaConcentradores?.length > 0) {
                tooltip +=
                  `<tr>
                      <td class="map-list-title"><b>` +
                  this.translate.instant("associated-lw-une") +
                  `: </b> </td><td><ul>`;

                device.listaConcentradores.forEach((concentrator) => {
                  tooltip +=
                    `<li><a href="#/dispositivos/detalle/lw-une/` +
                    concentrator.idConcentrador +
                    `">` +
                    concentrator.nroSerieConcentrador +
                    ` <i class='fas fa-link'></i>
                      </a></li>`;
                });
                tooltip += `</ul></td></tr>`;
              } else {
                tooltip +=
                  `<tr>
                              <td>
                                  <b>` +
                  this.translate.instant("associated-lw-une") +
                  ": </b>" +
                  this.translate.instant("unknown") +
                  ` 
                              </td>
                            </tr>`;
              }
              break;
            case MAP_LAYERS.UNE_NO_COMUNICA:
              tooltip +=
                "<tr><td><b>" +
                this.translate.instant("meter") +
                " UNE 82326</b></td></tr>";
              if (device.listaConcentradores?.length > 0) {
                tooltip +=
                  `<tr>
                      <td class="map-list-title"><b>` +
                  this.translate.instant("associated-lw-une") +
                  `: </b></td><td><ul>`;
                device.listaConcentradores.forEach((concentrator) => {
                  tooltip +=
                    `<li><a href="#/dispositivos/detalle/lw-une/` +
                    concentrator.idConcentrador +
                    `">` +
                    concentrator.nroSerieConcentrador +
                    ` <i class='fas fa-link'></i>
                        </a></li>`;
                });
                tooltip += `</ul></td></tr>`;
              } else {
                tooltip +=
                  `<tr>
                              <td>
                                  <b>` +
                  this.translate.instant("associated-lw-une") +
                  ": </b>" +
                  this.translate.instant("unknown") +
                  ` 
                              </td>
                            </tr>`;
              }
              break;
            default:
              break;
          }
          //Contadores No Lora
          if (
            deviceTypeByMask == DEVICE_BY_COMM.EK280 ||
            deviceTypeByMask == DEVICE_BY_COMM.OWASYS ||
            deviceTypeByMask == DEVICE_BY_COMM.PLUM ||
            deviceTypeByMask == DEVICE_BY_COMM.ERM ||
            deviceTypeByMask == DEVICE_BY_COMM.API ||
            deviceTypeByMask == DEVICE_BY_COMM.API_TOKEN
          ) {
            tooltip +=
              `<tr>
                          <td>
                            <b>` +
              this.translate.instant("last-communication") +
              `: </b>
                          </td>
                          <td>` +
              (device.lastCommunication != null
                ? moment_timezone(device.lastCommunication)
                    ?.tz(currentAgrupation?.timezone)
                    ?.format(dateFormat + " HH:mm:ss")
                : this.translate.instant("no-data")) +
              `
                          </td>
                        </tr>`;
          }
          // Contadores que comunican o no comunican
          if (
            layer == MAP_LAYERS.OK ||
            layer == MAP_LAYERS.NO_COMUNICA ||
            layer == MAP_LAYERS.CONCENTRATOR_OK ||
            layer == MAP_LAYERS.CONCENTRATOR_NO_COMUNICA
          ) {
            tooltip +=
              `<tr>
                          <td colspan="2">
                            <b>` +
              this.translate.instant("last-rssi") +
              `</b> ` +
              (device.lastRssi != null ? device.lastRssi + " dBm" : "-") +
              ` | <b>SF </b>` +
              (device.lastDataRate != null ? device.lastDataRate : "-") +
              ` | <b>SNR </b>` +
              (device.lastSnr != null ? device.lastSnr : "-") +
              `</td>
                        </tr>`;
            if (sessionProfile != PROFILES.ARSON) {
              tooltip +=
                `<tr>
                            <td>
                              <b>` +
                this.translate.instant("main-gateway") +
                `: </b>
                            </td>
                            <td class="pointer to findGateway" data-id="` +
                device.id +
                `">` +
                device.unidadVentaGw +
                `
                              <i class="fas fa-search-location"></i>
                            </td>
                          </tr>`;
            } else if (sessionProfile == PROFILES.ARSON) {
              let gateway = gateways.find(
                (gateway: any) => gateway.unidadVenta == device.unidadVentaGw
              );
              if (gateway) {
                tooltip +=
                  `<tr title="` +
                  this.translate.instant("show-detail") +
                  `">
                            <td>
                              <b>` +
                  this.translate.instant("main-gateway") +
                  `: </b>
                            </td>
                            <td><a href="#/gateways/detalle/gateway/` +
                  gateway.id +
                  `">` +
                  device.unidadVentaGw +
                  ` <i class="fas fa-link"></i>
                    </a> 
                    </td>
                  </tr>`;
              }
            } else {
              tooltip +=
                `<tr>
                            <td>
                              <b>` +
                this.translate.instant("main-gateway") +
                ` : </b>
                            </td>
                            <td>` +
                device.unidadVentaGw +
                `
                            </td>
                          </tr>`;
            }
            // Distancia a gateway principal
            let distance = this.getMainGatewayDistance(
              device,
              gateways.find(
                (gateway: any) => gateway.unidadVenta == device.unidadVentaGw
              )
            );
            if (distance) {
              tooltip +=
                `<tr>
                        <td>
                          <b>` +
                this.translate.instant("distance-to-main-gateway") +
                `: </b>
                        </td>
                        <td>` +
                distance +
                `
                        </td>
                      </tr>`;
            }
            tooltip +=
              `<tr>
                          <td>
                            <b>` +
              this.translate.instant("last-communication") +
              `: </b>
                          </td>
                          <td>` +
              (device.lastCommunication != null
                ? moment_timezone(device.lastCommunication)
                    ?.tz(currentAgrupation?.timezone)
                    ?.format(dateFormat + " HH:mm:ss")
                : this.translate.instant("no-data")) +
              `
                          </td>
                        </tr>`;
            // Alarmas del contador
            if (device.alarmList?.length > 0) {
              tooltip +=
                `<tr>
                            <td colspan="2">
                              <b>` +
                this.translate.instant("active-alarms-list") +
                `:</b>
                              <ul>`;
              device.alarmList.forEach((alarm: any, index: number) => {
                tooltip +=
                  `<li>` +
                  (index + 1) +
                  `.
                              ` +
                  this.translate.instant("AlertMeter" + alarm.code) +
                  ` (` +
                  moment_timezone(alarm.initDate)
                    ?.tz(currentAgrupation?.timezone)
                    ?.format(dateFormat + " HH:mm:ss") +
                  `)
                            </li>`;
              });
              tooltip += `</ul>
                        </td>
                      </tr>`;
            }
          }

          // LW MBUS mapa de cobertura
          if (
            mapType == "coverageMbusMap" &&
            deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON
          ) {
            tooltip +=
              `<tr class="pointer filterConcentrator">
                          <td>
                            <button type="button" class="btn btn-default"> 
                              <i class="fas fa-filter"></i>` +
              this.translate.instant("filtered-by-concentrator") +
              `</button></td></tr>`;
          }
        }
        break;
    }
    tooltip += "</table></div>";
    return tooltip;
  }

  /***************************************************************************/
  // ANCHOR Cáculo de la distancia al gateway principal
  /***************************************************************************/
  getMainGatewayDistance(device: any, gateway: any): string {
    if (
      device.latitude &&
      device.longitude &&
      gateway?.latitude &&
      gateway?.longitude
    ) {
      let distance = this.CoordMeasureService.measure(
        device.latitude,
        device.longitude,
        gateway?.latitude,
        gateway?.longitude
      );
      let numberFormat = this.SessionDataService.getCurrentNumberFormat();
      return (
        formatNumber(
          Math.round(distance),
          numberFormat
            ? numberFormat
            : this.SessionDataService.getCurrentLanguage()
        ) + " m"
      );
    }
    return null;
  }

  /***************************************************************************/
  // ANCHOR Obtención del título de marcador
  /***************************************************************************/
  getDeviceTitle(device: any, mapType: string, layer: string): string {
    switch (mapType) {
      // Mapa de cobertura
      case "coverage":
        return (
          `<div class="map-title"><div>` +
          this.translate.instant(
            MAP_CONFIG.markerVariables[layer].tooltipTitle
          ) +
          `</div>` +
          `<div class="map-tooltip-mouse-info">
        <i class="fas fa-mouse mouse-left-click"></i>
        <span>` +
          this.translate.instant("more-info") +
          `</span></div></div>`
        );
      // Mapa de presión
      case "pressureDetection":
        let warning =
          device.graph && device.graph[device.graph.length - 1][1] < 2;
        return (
          `<div class="map-title"><div class="pressure-tooltip ` +
          (warning ? "pressure-warning-tooltip" : "") +
          `">` +
          (device.graph
            ? device.graph[device.graph.length - 1][1]?.toFixed(1)
            : this.translate.instant("unknown")) +
          ` bar</div></div>`
        );
      // Mapa de caudal
      case "flowDetection":
        return (
          `<div class="map-title"><div>` +
          (device.flow != null
            ? device.flow?.toFixed(3)
            : this.translate.instant("unknown")) +
          ` m³</div></div>`
        );
      // Mapa de caudal
      case "leakDetection":
        return (
          `<div class="map-title"><div>` +
          (device.nroSerie != null
            ? device.nroSerie
            : this.translate.instant("no-data")) +
          `</div>` +
          `<div class="map-tooltip-mouse-info">
            <i class="fas fa-mouse mouse-left-click"></i>
            <span>` +
          this.translate.instant("selection") +
          `</span></div><div class="map-tooltip-mouse-info">
            <i class="fas fa-mouse mouse-right-click"></i>
            <span>` +
          this.translate.instant("show-detail") +
          `</span></div></div>`
        );
      default:
        if (layer == MAP_LAYERS.TXN) {
          return (
            `<div class="map-title"><div>` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `</div>`
          );
        } else {
          return (
            `<div class="map-title"><div>` +
            (device.nroSerie != null
              ? device.nroSerie
              : this.translate.instant("no-data")) +
            `</div>` +
            `<div class="map-tooltip-mouse-info">
          <i class="fas fa-mouse mouse-left-click"></i>
          <span>` +
            this.translate.instant("more-info") +
            `</span></div></div>`
          );
        }
    }
  }
}
