import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicio propios
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
// Interfaces
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { MANUFACTURER_ALARMS } from "../../../../../assets/manufacturers/MANUFACTURER_ALARMS";
import { LANGUAGE } from "../../../../services/language/LanguageController.service";

@Component({
  selector: "app-management-language-labels",
  templateUrl: "./management-language-labels.component.html",
  styleUrls: ["./management-language-labels.component.scss"],
})
export class ManagementLanguageLabelsComponent implements OnInit {
  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  languages: object;
  readonly LANGUAGE = LANGUAGE;
  labels: string[];
  alarmLabels: object = {};
  langs: string[];
  filterLabel: string = "help-";
  labelActive: string = "help-";
  languagesReady: boolean = false;
  hideLanguages: boolean[] = [];
  quickFilters: object[] = [
    {
      filter: "AlertMeter",
      text:
        this.translate.instant("alarm") +
        " (" +
        this.translate.instant("meter") +
        ")",
      icon: "fas fa-exclamation-triangle",
    },
    {
      filter: "AlertDescription",
      text: this.translate.instant("alarm-description"),
      icon: "fas fa-list-alt",
    },
    {
      filter: "GatewayAlarm",
      text:
        this.translate.instant("alarm") +
        " (" +
        this.translate.instant("gateway") +
        ")",
      icon: "fas fa-exclamation-triangle",
    },
    {
      filter: "gateway-management-group",
      text: this.translate.instant("gateway-management"),
      icon: "fas fa-wrench",
    },
    {
      filter: "GatewayLogEvent",
      text: this.translate.instant("entity-erase-response-gateway-events"),
      icon: "fas fa-calendar",
    },
    {
      filter: "UserEvent",
      text: this.translate.instant("user-events"),
      icon: "fas fa-calendar",
    },
    {
      filter: "httpError",
      text: this.translate.instant("http-error"),
      icon: "fas fa-server",
    },
    {
      filter: "help-",
      text: this.translate.instant("help"),
      icon: "fas fa-question-circle",
    },
  ];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "preview",
      icon: "fas fa-sync-alt",
      text: this.translate.instant("preview-changes"),
      visible: true,
    },
    {
      action: "download",
      icon: "fas fa-download",
      text: this.translate.instant("download"),
      visible: true,
    },
    {
      action: "save",
      icon: "fas fa-save",
      text: this.translate.instant("save"),
      visible: true,
    },
    {
      action: "merge-files",
      icon: "fas fa-exchange-alt",
      text: this.translate.instant("merge-files"),
      visible: true,
    },
  ];
  @ViewChild("mergeInput") mergeInput: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private http: HttpClient,
    private ManagementController: ManagementControllerService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    let headers = {
      headers: new HttpHeaders({
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      }),
    };
    let languagesUrls = [
      this.http.get("../../../../../assets/i18n/es.json", headers),
      this.http.get("../../../../../assets/i18n/en.json", headers),
      this.http.get("../../../../../assets/i18n/es-ca.json", headers),
      this.http.get("../../../../../assets/i18n/fr.json", headers),
      this.http.get("../../../../../assets/i18n/it.json", headers),
      this.http.get("../../../../../assets/i18n/el.json", headers),
    ];
    forkJoin(languagesUrls).subscribe((responses) => {
      this.languages = {
        es: JSON.parse(JSON.stringify(responses[0])),
        en: JSON.parse(JSON.stringify(responses[1])),
        "es-ca": JSON.parse(JSON.stringify(responses[2])),
        fr: JSON.parse(JSON.stringify(responses[3])),
        it: JSON.parse(JSON.stringify(responses[4])),
        el: JSON.parse(JSON.stringify(responses[5])),
      };
      this.langs = Object.keys(this.languages);
      this.labels = Object.keys(this.languages[LANGUAGE.ESPANOL]);
      this.getAlarmsLabels();
      for (let i = 0; i < this.labels.length; i++) {
        this.hideLanguages.push(false);
      }
      this.languagesReady = true;
    });
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Parseo de etiqueta de alarmas
  getAlarmsLabels(): void {
    this.labels.forEach((label) => {
      if (label.includes("AlertMeter") || label.includes("AlertDescription")) {
        let alarmParsed = MANUFACTURER_ALARMS[label.replace(/\D/g, "")];
        if (alarmParsed) {
          this.alarmLabels[label] =
            MANUFACTURER_ALARMS[label.replace(/\D/g, "")];
        }
      }
    });
  }

  // Descarga de ficheros de idiomas actualizados
  downloadLanguages(languages: object): void {
    for (let language in languages) {
      saveAs(
        new Blob([JSON.stringify(this.languages[language])], {
          type: "application/json",
        }),
        language + ".json"
      );
    }
  }

  // Actualización de idiomas
  updateLanguages(): void {
    for (let language in this.languages) {
      this.translate.setTranslation(language, this.languages[language]);
      this.translate.reloadLang(language);
    }
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "download":
        this.downloadLanguages(this.languages);
        break;
      case "preview":
        this.updateLanguages();
        break;
      case "save":
        this.saveLanguages();
        break;
      case "merge-files":
        this.mergeInput.nativeElement.click();
        break;
      default:
        break;
    }
  }

  // Guardar idiomas
  saveLanguages(): void {
    let formData: FormData = new FormData();
    for (let language in this.languages) {
      let blob = new Blob([JSON.stringify(this.languages[language])], {
        type: "application/json",
      });
      let file = new File([blob], language + ".json");
      formData.append("files", file);
    }
    this.ManagementController.saveLanguages(formData).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("languages-updated")
        );
        this.updateLanguages();
      }
    });
  }

  // Fusión de archivos de idiomas
  mergeFiles(files: any): void {
    for (let file in files) {
      if (files[file]?.name?.includes(".json")) {
        this.readFile(files[file], files[file]?.name?.split(".")[0]);
      }
    }
  }

  // Lectura de fichero
  readFile(file: File, language: string): void {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      let languageJSON = JSON.parse(e.target.result);
      for (let label in languageJSON) {
        if (this.languages[language][label]) {
          languageJSON[label] = this.languages[language][label];
        }
      }
      saveAs(
        new Blob([JSON.stringify(languageJSON)], {
          type: "application/json",
        }),
        language + ".json"
      );
    };
    if (file) {
      reader.readAsText(new Blob([file], { type: file.type }));
    }
  }

  // Reseteo del fichero seleccionado
  resetFile(): void {
    this.mergeInput.nativeElement.value = null;
  }
}
