// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../../MeterService.service";
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  DetailDevice,
  METER_ORDER,
  METER_ORDER_STATE,
  MeterOrder,
} from "../../../DeviceInterface.type";
// Componentes
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import { MeterNewOrderDialogComponent } from "./meter-new-order-dialog/meter-new-order-dialog.component";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meter-pending-orders",
  templateUrl: "./meter-pending-orders.component.html",
  styleUrls: ["./meter-pending-orders.component.scss"],
})
export class MeterPendingOrdersComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 50;
  meter: DetailDevice;
  tableData: MeterOrder[];
  exportFileName: string =
    this.translate.instant("meter-orders") +
    " " +
    this.DateParserService.getDate();
  tableSelectedData: MeterOrder[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "cancel-selected-data",
      icon: "fas fa-eraser",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
    {
      title: "remove-selected-data",
      icon: "fas fa-trash",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-delete",
    },
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  extraColumns: EntityDefinition[];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.getCupsConf();
    this.setPanelMenuOptions();

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.getCupsConf();
          this.setColumns();
        }
      );

    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entityCupsConfSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meter = history.state.data;
    this.getCupsConf();
    this.getData();
  }

  // Seteo de las opciones de panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "update",
        icon: "fas fa-sync-alt",
        text: this.translate.instant("update"),
        visible: true,
      },
      {
        action: "new",
        icon: "fas fa-plus",
        text: this.translate.instant("new"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
      },
    ];
  }

  // Obtención de los datos
  getData(): void {
    this.MeterController.getDevicesOrders(this.meter.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let meterOrders: MeterOrder[] = response["body"];
          meterOrders?.forEach((meterOrder: MeterOrder) => {
            meterOrder.typeParsed = METER_ORDER[meterOrder.type]
              ? this.translate.instant(METER_ORDER[meterOrder.type])
              : meterOrder.type;
            meterOrder.cupsLink = meterOrder.createClaveMaestra
              ? "/cups/detalle/" + meterOrder.createClaveMaestra
              : null;
            meterOrder.stateParsed = METER_ORDER_STATE[meterOrder.state]
              ? this.translate.instant(METER_ORDER_STATE[meterOrder.state])
              : null;
            meterOrder.cancelDisabled =
              (this.sessionProfile != PROFILES.ARSON &&
                this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
                this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
                this.sessionProfile != PROFILES.ADMIN_AGRUPACION) ||
              meterOrder.closeDate
                ? true
                : false;
          });
          this.tableData = meterOrders;
        }
      }
    );
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
    this.setColumns();
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "cancel",
            tooltip: "cancel",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: "cancelDisabled",
            warning: true,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled:
              this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.ADMIN_AGRUPACION,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: this.cups?.name,
        data: "createClave",
        search: "createClave",
        sort: "createClave",
        visible: this.cups != null ? true : null,
        link: "cupsLink",
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "stateParsed",
        visible: true,
      },
      {
        title: "creation-date",
        data: "createDate",
        search: "createDate",
        sort: "createDate",
        visible: true,
        date: true,
      },
      {
        title: "creation-user",
        data: "createUsername",
        search: "createUsername",
        sort: "createUsername",
        visible: true,
      },
      {
        title: "end-date",
        data: "closeDate",
        search: "closeDate",
        sort: "closeDate",
        visible: true,
        date: true,
      },
      {
        title: "close-user",
        data: "closeUsername",
        search: "closeUsername",
        sort: "closeUsername",
        visible: true,
      },
      {
        title: "type",
        data: "typeParsed",
        search: "typeParsed",
        sort: "typeParsed",
        visible: true,
      },
      {
        title: "comments",
        data: "comment",
        search: "comment",
        sort: "comment",
        visible: true,
        long: true,
      },
    ];
    this.columns = columns;
  }

  // Actualización del componente
  updateData(): void {
    this.tableData = [];
    this.getData();
  }

  // Acciones de la tabla
  tableActions(action: string, meterOrder: MeterOrder): void {
    switch (action) {
      case "delete":
        this.MeterService.deleteMeterOrder([meterOrder.id]);
        break;
      case "cancel":
        this.MeterService.cancelMeterOrder([meterOrder.id]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "remove-selected-data":
        this.MeterService.deleteMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      case "cancel-selected-data":
        this.MeterService.cancelMeterOrder(
          this.tableSelectedData.map((order) => order.id)
        );
        break;
      default:
        break;
    }
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update":
        this.updateData();
        break;
      case "new":
        this.MaterialDialogService.openDialog(
          MeterNewOrderDialogComponent,
          this.meter
        );
        break;
      default:
        break;
    }
  }
}
