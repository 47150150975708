<div #cesiumMap class="cesium-container" *ngIf="bounds?.length > 0">
  <div *ngIf="loading" class="cesium-container-loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <!-- ANCHOR Controles -->
  <div *ngIf="!loading">
    <div *ngIf="!loading" class="cesium-controller-menu">
      <app-material-panel-menu
        [withoutFormat]="true"
        [options]="mapMenuOptions"
        (action)="menuAction($event)"
        (range)="updateClusterRange($event)"
      ></app-material-panel-menu>
    </div>
    <!-- KML -->
    <div *ngIf="allowKml" class="cesium-kml">
      <input
        #kmlImportInput
        type="file"
        accept=".kml,.kmz,.png"
        id="file"
        (click)="resetKml()"
        (change)="loadKmlFile($event)"
        multiple
      />
    </div>

    <!-- ANCHOR Capas del mapa -->
    <div class="cesium-layers">
      <button type="button">
        <i class="fas fa-layer-group"></i>
      </button>
      <div class="cesium-layer-select">
        <ng-container *ngFor="let layer of layerList">
          <div *ngIf="activeLayers[layer.type] != null">
            <input
              type="checkbox"
              name="{{ layer.name }}"
              id="{{ layer.name }}"
              [(ngModel)]="activeLayers[layer.type]"
            />
            <label for="{{ layer.name }}">{{ layer.name | translate }}</label>
          </div>
        </ng-container>
        <ng-container *ngFor="let layer of _coverageLayersData">
          <div *ngIf="layer.positions?.length > 0">
            <input
              type="checkbox"
              name="{{ layer.id }}"
              id="{{ layer.id }}"
              [(ngModel)]="activeLayers[layer.id]"
            />
            <label for="{{ layer.id }}">{{ layer.name | translate }}</label>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- ANCHOR Leyenda del mapa -->
    <div class="cesium-legend-container">
      <button type="button" class="cesium-control-button">
        <i class="fas fa-question"></i>
      </button>
      <div class="cesium-legend">
        <ng-container *ngFor="let legend of mapLegend">
          <div>
            <i
              class="fas fa-circle"
              [ngStyle]="{
                color: legend.color
              }"
            ></i>
            <span>{{ legend.deviceType | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- ANCHOR Mapa -->
  <ac-map *ngIf="cesiumOptionsReady">
    <!-- ANCHOR Capa de gateways -->
    <ac-layer
      #gatewayLayer
      *ngIf="!hideLayers && gatewayLayer"
      acFor="let gateway of gatewayLayer"
      [show]="activeLayers['GATEWAYS']"
      [context]="this"
    >
      <ac-billboard-desc
        props="{
            height: 40,
            width: 40,
            scale: 1.0,
            verticalOrigin: verticalOrigin,
            image: gateway.image,
            position: gateway.position,
            heightReference: heightReference,
            name: gateway.name,
            show: gateway.show,
          }"
      >
      </ac-billboard-desc>
    </ac-layer>

    <!-- ANCHOR Capa de localizaciones -->
    <ac-layer
      #locationLayer
      *ngIf="!hideLayers && locationLayer"
      acFor="let location of locationLayer"
      [show]="activeLayers['LOCATIONS']"
      [context]="this"
    >
      <ac-billboard-desc
        props="{
              height: 20,
              width: 20,
              scale: 1.0,
              verticalOrigin: verticalOrigin,
              image: location.image,
              position: location.position,
              heightReference: heightReference,
              name: location.name,
              color: location.color,
              show: location.show,
            }"
      >
      </ac-billboard-desc>
      <ac-label-desc
        props="{
          position: location.position,
          text: location.label.text,
          showBackground : true,
          pixelOffset: [0,-20] | pixelOffset,
          verticalOrigin: verticalOrigin,
          heightReference: heightReference,
          font: '15px sans-serif'
          show: location.showLabel
        }"
      >
      </ac-label-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Ok -->
    <ac-layer
      #okLayer
      *ngIf="!hideLayers && okLayer"
      acFor="let device of okLayer"
      [show]="activeLayers['OK']"
      [context]="this"
    >
      <ac-point-desc
        props="{
            pixelSize: 12,
            position: device.position,
            heightReference: heightReference,
            verticalOrigin: verticalOrigin,
            name: device.name,
            color: device.color,
            show: device.show,
            outlineWidth: 1,
          }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de No comunica -->
    <ac-layer
      #noComunicaLayer
      *ngIf="!hideLayers && noComunicaLayer"
      acFor="let device of noComunicaLayer"
      [show]="activeLayers['NO_COMUNICA']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 12,
              position: device.position,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: device.name,
              color: device.color,
              show: device.show,
              outlineWidth: 1,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de No asignado -->
    <ac-layer
      #noAsignadoLayer
      *ngIf="!hideLayers && noAsignadoLayer"
      acFor="let device of noAsignadoLayer"
      [show]="activeLayers['NO_ASIGNADO']"
      [context]="this"
    >
      <ac-point-desc
        props="{
          pixelSize: 12,
          position: device.position,
          heightReference: heightReference,
          verticalOrigin: verticalOrigin,
          name: device.name,
          color: device.color,
          show: device.show,
          outlineWidth: 1,
        }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Concentrador Ok -->
    <ac-layer
      #concentratorOkLayer
      *ngIf="!hideLayers && concentratorOkLayer"
      acFor="let device of concentratorOkLayer"
      [show]="activeLayers['CONCENTRATOR_OK']"
      [context]="this"
    >
      <ac-point-desc
        props="{
           pixelSize: 12,
           position: device.position,
           heightReference: heightReference,
           verticalOrigin: verticalOrigin,
           name: device.name,
           color: device.color,
           show: device.show,
           outlineWidth: 1,
         }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Concentrador No comunica -->
    <ac-layer
      #concentratorNoComunicaLayer
      *ngIf="!hideLayers && concentratorNoComunicaLayer"
      acFor="let device of concentratorNoComunicaLayer"
      [show]="activeLayers['CONCENTRATOR_NO_COMUNICA']"
      [context]="this"
    >
      <ac-point-desc
        props="{
             pixelSize: 12,
             position: device.position,
             heightReference: heightReference,
             verticalOrigin: verticalOrigin,
             name: device.name,
             color: device.color,
             show: device.show,
             outlineWidth: 1,
           }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Concentrador No asignado -->
    <ac-layer
      #concentratorNoAsignadoLayer
      *ngIf="!hideLayers && concentratorNoAsignadoLayer"
      acFor="let device of concentratorNoAsignadoLayer"
      [show]="activeLayers['CONCENTRATOR_NO_ASIGNADO']"
      [context]="this"
    >
      <ac-point-desc
        props="{
         pixelSize: 12,
         position: device.position,
         heightReference: heightReference,
         verticalOrigin: verticalOrigin,
         name: device.name,
         color: device.color,
         show: device.show,
         outlineWidth: 1,
       }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de No Lora -->
    <ac-layer
      #noLoraLayer
      *ngIf="!hideLayers && noLoraLayer"
      acFor="let device of noLoraLayer"
      [show]="activeLayers['NO_LORA']"
      [context]="this"
    >
      <ac-point-desc
        props="{
            pixelSize: 12,
            position: device.position,
            heightReference: heightReference,
            verticalOrigin: verticalOrigin,
            name: device.name,
            color: device.color,
            show: device.show,
            outlineWidth: 1,
          }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de externo sin comunicación -->
    <ac-layer
      #noLoraLayerNoCom
      *ngIf="!hideLayers && noLoraLayerNoCom"
      acFor="let device of noLoraLayerNoCom"
      [show]="activeLayers['NO_LORA_NO_COM']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 12,
              position: device.position,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: device.name,
              color: device.color,
              show: device.show,
              outlineWidth: 1,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Mbus -->
    <ac-layer
      #mbusLayer
      *ngIf="!hideLayers && mbusLayer"
      acFor="let device of mbusLayer"
      [show]="activeLayers['MBUS']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 12,
              position: device.position,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: device.name,
              color: device.color,
              show: device.show,
              outlineWidth: 1,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Mbus con comunicación -->
    <ac-layer
      #mbusLayer
      *ngIf="!hideLayers && mbusComLayer"
      acFor="let device of mbusComLayer"
      [show]="activeLayers['MBUS_COMUNICA']"
      [context]="this"
    >
      <ac-point-desc
        props="{
                pixelSize: 12,
                position: device.position,
                heightReference: heightReference,
                verticalOrigin: verticalOrigin,
                name: device.name,
                color: device.color,
                show: device.show,
                outlineWidth: 1,
              }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Wavenis -->
    <ac-layer
      #wavenisLayer
      *ngIf="!hideLayers && wavenisLayer"
      acFor="let device of wavenisLayer"
      [show]="activeLayers['WAVENIS']"
      [context]="this"
    >
      <ac-point-desc
        props="{
          pixelSize: 12,
          position: device.position,
          heightReference: heightReference,
          verticalOrigin: verticalOrigin,
          name: device.name,
          color: device.color,
          show: device.show,
          outlineWidth: 1,
        }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Une ok -->
    <ac-layer
      #uneOkLayer
      *ngIf="!hideLayers && uneOkLayer"
      acFor="let device of uneOkLayer"
      [show]="activeLayers['UNE_OK']"
      [context]="this"
    >
      <ac-point-desc
        props="{
            pixelSize: 12,
            position: device.position,
            heightReference: heightReference,
            verticalOrigin: verticalOrigin,
            name: device.name,
            color: device.color,
            show: device.show,
            outlineWidth: 1,
          }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de Une no comunica -->
    <ac-layer
      #uneNoComunicaLayer
      *ngIf="!hideLayers && uneNoComunicaLayer"
      acFor="let device of uneNoComunicaLayer"
      [show]="activeLayers['UNE_NO_COMUNICA']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 12,
              position: device.position,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: device.name,
              color: device.color,
              show: device.show,
              outlineWidth: 1,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de TXN -->
    <ac-layer
      #txnLayer
      *ngIf="!hideLayers && txnLayer"
      acFor="let device of txnLayer"
      [show]="activeLayers['TXN']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 12,
              position: device.position,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: device.name,
              color: device.color,
              show: device.show,
              outlineWidth: 1,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Link con gateway -->
    <ac-layer
      #linkAcLayer
      *ngIf="!hideLayers && linkLayer"
      acFor="let link of linkLayer"
      [show]="true"
      [context]="this"
    >
      <ac-polyline-desc
        props="{
             width: 5,
             positions: link.linkPositions,
             show: false,
             clampToGround: true,
             heightReference: heightReference,
             material: linkMaterial,
           }"
      >
      </ac-polyline-desc>
    </ac-layer>

    <!-- ANCHOR Link con localización -->
    <ac-layer
      #locationLinkAcLayer
      *ngIf="!hideLayers && mapType == 'coverage' && locationLinkLayer"
      acFor="let location of locationLinkLayer"
      [show]="true"
      [context]="this"
    >
      <ac-polyline-desc
        props="{
               width: 5,
               positions: location,
               show: true,
               clampToGround: true,
               heightReference: heightReference,
               material: linkMaterial,
             }"
      >
      </ac-polyline-desc>
    </ac-layer>

    <!-- ANCHOR Capa de agrupaciones -->
    <ac-layer
      #agrupationAcLayer
      *ngIf="!hideLayers && drawAgrupationOutline && agrupationLayer"
      acFor="let agrupation of agrupationLayer"
      [show]="agrupationPolygonActive"
      [context]="this"
    >
      <ac-polygon-desc
        props="{
              show: agrupation.show,
              hierarchy: agrupation.hierarchy,
              material: agrupation.color,
              clampToGround: true,
            }"
      >
      </ac-polygon-desc>
    </ac-layer>

    <!-- ANCHOR Capas de cobertura -->
    <!-- Cobertura total -->
    <ac-layer
      #coverageTotal
      *ngIf="!hideLayers && showCoverage && coverageTotalLayer"
      acFor="let coverage of coverageTotalLayer"
      [show]="activeLayers['coverageTotal']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 15,
              position: coverage.point,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: coverage.name,
              color: coverage.color,
              show: coverage.show,
              outlineWidth: 0,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Cobertura alta -->
    <ac-layer
      #coverageHigh
      *ngIf="!hideLayers && showCoverage && coverageHighLayer"
      acFor="let coverage of coverageHighLayer"
      [show]="activeLayers['coverageHigh']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 15,
              position: coverage.point,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: coverage.name,
              color: coverage.color,
              show: coverage.show,
              outlineWidth: 0,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Cobertura media -->
    <ac-layer
      #coverageMid
      *ngIf="!hideLayers && showCoverage && coverageMidLayer"
      acFor="let coverage of coverageMidLayer"
      [show]="activeLayers['coverageMid']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 15,
              position: coverage.point,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: coverage.name,
              color: coverage.color,
              show: coverage.show,
              outlineWidth: 0,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Cobertura baja -->
    <ac-layer
      #coverageLow
      *ngIf="!hideLayers && showCoverage && coverageLowLayer"
      acFor="let coverage of coverageLowLayer"
      [show]="activeLayers['coverageLow']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 15,
              position: coverage.point,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: coverage.name,
              color: coverage.color,
              show: coverage.show,
              outlineWidth: 0,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Cobertura crítica -->
    <ac-layer
      #coverageCritical
      *ngIf="!hideLayers && showCoverage && coverageCriticalLayer"
      acFor="let coverage of coverageCriticalLayer"
      [show]="activeLayers['coverageCritical']"
      [context]="this"
    >
      <ac-point-desc
        props="{
                pixelSize: 15,
                position: coverage.point,
                heightReference: heightReference,
                verticalOrigin: verticalOrigin,
                name: coverage.name,
                color: coverage.color,
                show: coverage.show,
                outlineWidth: 0,
              }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Cobertura nula -->
    <ac-layer
      #coverageNull
      *ngIf="!hideLayers && showCoverage && coverageNullLayer"
      acFor="let coverage of coverageNullLayer"
      [show]="activeLayers['coverageNull']"
      [context]="this"
    >
      <ac-point-desc
        props="{
              pixelSize: 15,
              position: coverage.point,
              heightReference: heightReference,
              verticalOrigin: verticalOrigin,
              name: coverage.name,
              color: coverage.color,
              show: coverage.show,
              outlineWidth: 0,
            }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Capa de cobertura: Ok -->
    <ac-layer
      #coverageOkLayer
      *ngIf="!hideLayers && coverageOkLayer"
      acFor="let device of coverageOkLayer"
      [show]="activeLayers['COVERAGE_OK']"
      [context]="this"
    >
      <ac-point-desc
        props="{
          pixelSize: 12,
          position: device.position,
          heightReference: heightReference,
          verticalOrigin: verticalOrigin,
          name: device.name,
          color: device.color,
          show: device.show,
          outlineWidth: 1,
        }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- Capa de cobertura: Error -->
    <ac-layer
      #coverageErrorLayer
      *ngIf="!hideLayers && coverageErrorLayer"
      acFor="let device of coverageErrorLayer"
      [show]="activeLayers['COVERAGE_ERROR']"
      [context]="this"
    >
      <ac-point-desc
        props="{
            pixelSize: 12,
            position: device.position,
            heightReference: heightReference,
            verticalOrigin: verticalOrigin,
            name: device.name,
            color: device.color,
            show: device.show,
            outlineWidth: 1,
          }"
      >
      </ac-point-desc>
    </ac-layer>

    <!-- ANCHOR Capa de terreno -->
    <ac-3d-tile-layer *ngIf="buildings" [options]="cesiumBuildingsOptions">
    </ac-3d-tile-layer>

    <!-- ANCHOR Control de teclado -->
    <app-ceisum-keyboard-control></app-ceisum-keyboard-control>
  </ac-map>

  <!-- ANCHOR Tooltip -->
  <app-cesium-map-tooltip
    *ngIf="selectedEntity"
    [device]="selectedEntity"
    [mapType]="mapType"
    (goToGatewayFlag)="goToGateway($event)"
    (action)="mapAction($event)"
  ></app-cesium-map-tooltip>
</div>
