<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{ "tasks-log" | translate
            }}{{ gateway?.id ? ": " + gateway.unidadVenta : "" }}
          </b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          *ngIf="gateway"
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewayTasks'"
          [columns]="columns"
          [export]="true"
          [exportFileName]="'gateway-tasks' | translate"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [specialFilter]="true"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="gatewayTasks"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
