<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "data-load" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <div *ngIf="cupsColumns || loadingCups; else error">
          <div>
            <h4>
              <b>{{ "file-import" | translate }}</b>
            </h4>
            <h5 class="data-management-import-warning">
              <i class="fas fa-exclamation-triangle"></i>
              <b>{{ "file-colums-explanation" | translate }}</b>
            </h5>
            <app-file-import
              [fileType]="'.csv'"
              (fileReady)="importFile($event)"
            ></app-file-import>
          </div>

          <app-import-table
            *ngIf="cupsColumns && importColumns?.length > 0"
            [importColumns]="importColumns"
          >
          </app-import-table>

          <!-- Tabla -->
          <ng-container *ngIf="showTable">
            <hr />
            <app-table-controller
              id="import-errors"
              [tableId]="'dataManagementImport'"
              [export]="true"
              [exportFileName]="exportFileName"
              [columns]="columns"
              [rowNumbers]="true"
              [data]="tableData"
            >
            </app-table-controller>
          </ng-container>
        </div>

        <!-- Error de CUPS -->
        <ng-template #error>
          <div class="text-center">
            <i class="fas fa-exclamation-triangle"></i>
            <h3>{{ "cups-or-extra" | translate }}</h3>
            <a class="btn btn-primary btn-block" (click)="goToCupsConfig()">{{
              "create" | translate
            }}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
