<!-- Menú -->
<div class="panel-menu">
  <div *ngIf="!onlyButton">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      [ngClass]="{ 'panel-menu-button': !withoutFormat }"
    >
      <mat-icon><i class="fas fa-ellipsis-v"></i></mat-icon>
    </button>
    <!-- Opciones -->
    <mat-menu #menu="matMenu" xPosition="before">
      <ng-container *ngFor="let option of _options">
        <!-- Opción sin submenú -->
        <hr *ngIf="option.action == 'separator'" />
        <button
          *ngIf="
            option.action != 'separator' && option.visible && !option.submenu
          "
          mat-menu-item
          [ngClass]="{
            'option-highlighted': option.highlight,
            'option-highlighted-disabled':  option.highlight && option.disabled
          }"
          [disabled]="option.disabled"
          (click)="action.emit(option.action)"
        >
          <mat-icon
            ><i *ngIf="option.icon" class="{{ option.icon }}"></i
          ></mat-icon>
          <span>{{ option.text }}</span>
        </button>
        <!-- Opción con submenú -->
        <button
          *ngIf="
            option.action != 'separator' && option.visible && option.submenu
          "
          mat-menu-item
          [ngClass]="{ 'option-highlighted': option.highlight }"
          [disabled]="option.disabled"
          (mouseover)="submenuOptions = option.submenu"
          [matMenuTriggerFor]="submenu"
        >
          <mat-icon
            ><i *ngIf="option.icon" class="{{ option.icon }}"></i
          ></mat-icon>
          <span>{{ option.text }}</span>
        </button>
        <!-- Rango -->
        <div
          *ngIf="!option.disabled && option.range"
          class="panel-menu-option-range"
        >
          <div>
            <i *ngIf="option.range.icon" class="{{ option.range.icon }}"></i>
            <span>{{ option.range.text }}</span>
          </div>
          <input
            [ngClass]="{ pointer: !option.disabled && !option.range.disabled }"
            type="range"
            min="option.range.min"
            max="option.range.max"
            id="option-range"
            [(ngModel)]="option.range.value"
            (change)="range.emit(option.range.value)"
            [disabled]="option.disabled || option.range.disabled"
          />
        </div>
      </ng-container>
    </mat-menu>
    <!-- Submenú -->
    <mat-menu #submenu="matMenu">
      <ng-container *ngFor="let submenuOption of submenuOptions">
        <button
          *ngIf="submenuOption.visible"
          mat-menu-item
          [disabled]="submenuOption.disabled"
          (click)="action.emit(submenuOption.action)"
        >
          <mat-icon *ngIf="submenuOption.icon"
            ><i class="{{ submenuOption.icon }}"></i
          ></mat-icon>
          <span>{{ submenuOption.text }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <!-- Menú de botón -->
  <div *ngIf="onlyButton">
    <button
      mat-icon-button
      (click)="action.emit()"
      title="{{ title }}"
      [disabled]="disabled"
      [ngClass]="{ 'panel-menu-button': !withoutFormat && !disabled }"
    >
      <i
        [ngClass]="
          icon
            ? icon
            : updating
            ? 'fas fa-sync-alt fa-pulse'
            : 'fas fa-sync-alt'
        "
      ></i>
    </button>
  </div>
</div>
