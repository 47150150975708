/**
 * Decodes the given frmPayload for the LoRaWAN-MBus protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 * @param {any} fecha - Fecha de la trama en formato dd/mm/yyyy hh:mm:ss
 * @param {any} frameType - tipo de trama tal y como lo devuelve el LoRaWAN_parser ("UNCONFIRMED DATA DOWN","CONFIRMED DATA DOWN",...)
 *
 * @returns {Object}
 *
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LwMbusDecoderService {
  constructor() {}

  LoRaWAN_MBus_decoder(frmPayload, fport, frameType) {
    if (frmPayload && fport) {
      if (frmPayload.bytes) {
        if (frameType == null) {
          frameType = "UNCONFIRMED DATA UP";
        }
        var outputData = {};
        var outputErrors = [];
        var index = 0;
        var isDataDown =
          frameType == "UNCONFIRMED DATA DOWN" ||
          frameType == "CONFIRMED DATA DOWN";
        var isDataUp =
          frameType == "UNCONFIRMED DATA UP" ||
          frameType == "CONFIRMED DATA UP";
        if (!isDataDown && !isDataUp) {
          return {
            errors: ["Tipo de trama LoRaWAN no parseable"],
          };
        }
        switch (fport /*Tipo de trama*/) {
          case 0x01 /* PING */:
            return this.pingDecoder(frmPayload.bytes, isDataUp);
          case 0x4d /* Borrado de acumuldores de consumo */:
            return this.AcuClearDecoder(frmPayload.bytes, isDataUp);
          case 0x4e /* Borrado de desconocidos */:
            return this.DesconocidosClearDecoder(frmPayload.bytes, isDataUp);
          case 0x4f /* Listado de ajustes */:
            return this.AjustesDecoder(frmPayload.bytes, isDataUp);
          case 0x50:
            return this.DispositivosDecoder(frmPayload.bytes, isDataUp);
          case 0x51:
            return this.FabricantesDecoder(frmPayload.bytes, isDataUp);
          case 0x52:
            return this.CandidatosDecoder(frmPayload.bytes, isDataUp);
          case 0x53:
            return this.ResetDecoder(frmPayload.bytes, isDataUp);
          case 0x54:
            return this.HelloDecoder(frmPayload.bytes, isDataUp);
          case 0x55:
            return this.ValoresDecoder(frmPayload.bytes, isDataUp);
          case 0x56:
            return this.DesconocidosDecoder(frmPayload.bytes, isDataUp);
          case 0x57:
            return this.FijosDecoder(frmPayload.bytes, isDataUp);
          case 0x58 /* Reenvío de tramas de contadores */:
            return this.reenvioDecoder(frmPayload.bytes, isDataUp);
          default:
            return {
              errors: [
                "Puerto no definido: 0x" + fport.toString(16).padStart(2, "0"),
              ],
            };
        }
        return {
          data: outputData,
        };
      } else {
        return {
          errors: ["Unknown Input.bytes."],
        };
      }
    } else {
      return {
        errors: ["Faltan parámetros de entrada."],
      };
    }
  }

  /* Parseo de Ping Frame */
  pingDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        errors: ["PING DATA_DOWN: El Ping sólo puede ser DATA_UP"],
      };
    } else {
      if (bytes.length != 1) {
        return {
          errors: ["PING FRAME: Longitud errónea, debe ser 1 byte"],
        };
      } else {
        return {
          data: {
            Tipo_Trama: "PING",
            ID_Trama: bytes[0],
          },
        };
      }
    }
  }

  /* Parseo de comando de reenvío de tramas de contadores */
  reenvioDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "PETICIÓN DE REENVIO DE TRAMAS",
          ID_Trama: bytes[0],
          Longitud_Mascara: bytes.length - 1,
        },
      };
    } else {
      if (bytes.length != 2) {
        return {
          errors: [
            "RESPUESTA A REENVIO DE TRAMAS: Longitud errónea, debe ser 2 byte",
          ],
        };
      } else {
        return {
          data: {
            Tipo_Trama: "RESPUESTA A REENVIO DE TRAMAS",
            ID_Trama: bytes[0],
            Codigo_Respuesta: bytes[1] == 0 ? "OK" : "ERROR",
          },
        };
      }
    }
  }

  /* Parseo de comando de Borrado de acumuldores de consumo */
  AcuClearDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "PETICIÓN DE BORRADO DE ACUMULADORES DE CONSUMO",
          ID_Trama: bytes[0],
        },
      };
    } else {
      if (bytes.length != 2) {
        return {
          errors: [
            "RESPUESTA A PETICIÓN DE BORRADO DE ACUMULADORES DE CONSUMO: Longitud errónea, debe ser 2 byte",
          ],
        };
      } else {
        return {
          data: {
            Tipo_Trama:
              "RESPUESTA A PETICIÓN DE BORRADO DE ACUMULADORES DE CONSUMO",
            ID_Trama: bytes[0],
            Codigo_Respuesta: bytes[1] == 0 ? "OK" : "ERROR",
          },
        };
      }
    }
  }

  /* Parseo de comando de Borrado de dispositivos desconocidos */
  DesconocidosClearDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "PETICIÓN DE BORRADO DE DESCONOCIDOS",
          ID_Trama: bytes[0],
        },
      };
    } else {
      if (bytes.length != 2) {
        return {
          errors: [
            "RESPUESTA A PETICIÓN DE BORRADO DE DESCONOCIDOS: Longitud errónea, debe ser 2 byte",
          ],
        };
      } else {
        return {
          data: {
            Tipo_Trama: "RESPUESTA A PETICIÓN DE BORRADO DE DESCONOCIDOS",
            ID_Trama: bytes[0],
            Codigo_Respuesta: bytes[1] == 0 ? "OK" : "ERROR",
          },
        };
      }
    }
  }

  /* Parseo de comando de gestión de ajustes */
  AjustesDecoder(bytes, isDataUp) {
    const ListadoAjustes = {
      0: {
        descripcion: "Tiempo RX MBus",
        longitud: 1,
        parseo: function (bytes) {
          return bytes[0].toString() + " segundos";
        },
      },
      1: {
        descripcion: "Filtro contadores",
        longitud: 1,
        parseo: function (bytes) {
          if (bytes[0] == 0) {
            return "Los primeros que se reciben";
          } else if (bytes[0] == -1) {
            return "Los de peor RSSI";
          } else {
            return "Los de mejor RSSI";
          }
        },
      },
      2: {
        descripcion: "RSSI Max",
        longitud: 1,
        parseo: function (bytes) {
          return (0 - bytes[0]).toString() + " dBm";
        },
      },
      3: {
        descripcion: "RSSI Min",
        longitud: 1,
        parseo: function (bytes) {
          return (0 - bytes[0]).toString() + " dBm";
        },
      },
      4: {
        descripcion: "Calibracion",
        longitud: 2,
        parseo: function (bytes) {
          return this.bytesToHex(bytes);
        },
      },
    };

    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "COMANDO DE GESTIÓN DE AJUSTES",
          ID_Trama: bytes[0],
          Longitud_Datos: bytes.length - 1,
        },
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESPUESTA A GESTIÓN DE AJUSTES",
        ID_Trama: bytes[0],
      };
      var subcomando = bytes[1] & 0xf0;
      switch (subcomando) {
        case 0x00:
          outputData["Subcomando"] = "Lectura de ajustes";
          var indice = 2;
          while (indice < bytes.length) {
            var ajuste = bytes[indice++];
            if (indice + ListadoAjustes[ajuste].longitud > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A LECTURA DE AJUSTES - Longitud insuficiente",
                ],
              };
            }
            outputData[ListadoAjustes[ajuste].descripcion] = ListadoAjustes[
              ajuste
            ].parseo(
              bytes.slice(indice, indice + ListadoAjustes[ajuste].longitud)
            );
            indice += ListadoAjustes[ajuste].longitud;
          }
          break;
        case 0x10:
          outputData["Subcomando"] = "Escritura de ajustes";
          var indice = 2;
          while (indice < bytes.length) {
            var ajuste = bytes[indice++];
            if (indice + 1 > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A ESCRITURA DE AJUSTES - Longitud insuficiente",
                ],
              };
            }
            outputData[ListadoAjustes[ajuste].descripcion] =
              bytes[indice++] == 0 ? "OK" : "ERROR";
          }
          break;
        default:
          return {
            errors: [
              "RESPUESTA A ESCRITURA DE AJUSTES: Subcomando no válido: 0x" +
                subcomando.toString(16).padStart(2, "0"),
            ],
          };
          break;
      }
      return { data: outputData };
    }
  }

  /* Parseo de comando de gestión de la lista de dispositivos */
  DispositivosDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "COMANDO DE GESTIÓN DE LA LISTA DE DISPOSITIVOS",
          ID_Trama: bytes[0],
          Longitud_Datos: bytes.length - 1,
        },
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESPUESTA A GESTIÓN DE LA LISTA DE DISPOSITIVOS",
        ID_Trama: bytes[0],
      };
      var codigoRespuesta = bytes[1];
      var subcomando = bytes[2] & 0xf0;
      var indicePrimero = bytes[3];
      switch (subcomando) {
        case 0x00:
          outputData["Subcomando"] = "Lectura de dispositivos";
          var numeroPedidos = bytes[2] & 0x0f;
          outputData["N_Pedidos"] =
            numeroPedidos == 0x0f ? "TODOS" : numeroPedidos;
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          if (codigoRespuesta == 0) {
            outputData["Indice_primero"] = indicePrimero;
            outputData["Numero_total"] = bytes[4];
            outputData["Numero_trama"] = bytes[5];
            outputData["Contadores"] = [];
            var indiceContador = 0;
            var indice = 6;
            while (indice < bytes.length) {
              if (indice + 24 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A LECTURA DE DISPOSITIVOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Contadores"][indiceContador++] = {
                Direccion: this.bytesToHex(bytes.slice(indice, indice + 8)),
                Clave: this.bytesToHex(
                  bytes.slice(indice + 8, indice + 8 + 16)
                ),
              };
              indice += 24;
            }
          }
          break;
        case 0x80:
          if (
            (!codigoRespuesta && bytes.length != 6) ||
            (codigoRespuesta && bytes.length != 30)
          ) {
            return {
              errors: [
                "RESPUESTA A LECTURA DE DISPOSITIVOS POR DIRECCION - Longitud errónea",
              ],
            };
          }
          outputData["Subcomando"] = "Lectura de dispositivo por dirección";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Numero_total"] = bytes[4];
          outputData["Numero_trama"] = bytes[5];
          if (codigoRespuesta == 0) {
            outputData["Direccion"] = this.bytesToHex(bytes.slice(6, 6 + 8));
            outputData["Clave"] = this.bytesToHex(
              bytes.slice(6 + 8, 6 + 8 + 16)
            );
          }
          break;
        case 0x10:
        case 0x90:
          outputData["Subcomando"] = "Escritura de dispositivos";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Contadores"] = [];
          var indice = 4;
          var indiceContador = 0;
          while (indice < bytes.length) {
            if (indice + 8 > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A ESCRITURA DE DISPOSITIVOS - Longitud insuficiente",
                ],
              };
            }
            outputData["Contadores"][indiceContador++] = this.bytesToHex(
              bytes.slice(indice, indice + 8)
            );
            indice += 8;
          }
          break;
        case 0x20:
        case 0xa0:
          var numeroBorrados = bytes[2] & 0x0f;
          outputData["Subcomando"] =
            numeroBorrados == 0x0f
              ? "Borrado de todos los dispositivos"
              : "Borrado de dispositivos por dirección";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Contadores"] = [];
          if (numeroBorrados != 0x0f) {
            var indiceContador = 0;
            var indice = 4;
            while (indice < bytes.length) {
              if (indice + 8 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A BORRADO DE DISPOSITIVOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Contadores"][indiceContador++] = this.bytesToHex(
                bytes.slice(indice, indice + 8)
              );
              indice += 8;
            }
          }
          break;
        default:
          return {
            errors: [
              "RESPUESTA A GESTIÓN DE DISPOSITIVOS: Subcomando no válido: 0x" +
                subcomando.toString(16).padStart(2, "0"),
            ],
          };
          break;
      }
      return { data: outputData };
    }
  }

  /* Parseo de comando de gestión de la lista de fabricantes */
  FabricantesDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "COMANDO DE GESTIÓN DE LA LISTA DE FABRICANTES",
          ID_Trama: bytes[0],
          Longitud_Datos: bytes.length - 1,
        },
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESPUESTA A GESTIÓN DE LA LISTA DE FABRICANTES",
        ID_Trama: bytes[0],
      };
      var codigoRespuesta = bytes[1];
      var subcomando = bytes[2] & 0xf0;
      var indicePrimero = bytes[3];
      switch (subcomando) {
        case 0x00:
          outputData["Subcomando"] = "Lectura de fabricantes";
          var numeroPedidos = bytes[2] & 0x0f;
          outputData["N_Pedidos"] =
            numeroPedidos == 0x0f ? "TODOS" : numeroPedidos;
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          if (codigoRespuesta == 0) {
            outputData["Indice_primero"] = indicePrimero;
            outputData["Numero_total"] = bytes[4];
            outputData["Numero_trama"] = bytes[5];
            outputData["Fabricantes"] = [];
            var indiceFabricante = 0;
            var indice = 6;
            while (indice < bytes.length) {
              if (indice + 18 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A LECTURA DE FABRICANTES - Longitud insuficiente",
                  ],
                };
              }
              outputData["Fabricantes"][indiceFabricante++] = {
                Fabricante:
                  "0x" +
                  this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
                  " - " +
                  this.mbusManHexToAsc(bytes, indice),
                Clave: this.bytesToHex(
                  bytes.slice(indice + 2, indice + 2 + 16)
                ),
              };
              indice += 18;
            }
          }
          break;
        case 0x80:
          if (
            (!codigoRespuesta && bytes.length != 6) ||
            (codigoRespuesta && bytes.length != 24)
          ) {
            return {
              errors: [
                "RESPUESTA A LECTURA DE FABRICANTE POR ID - Longitud errónea",
              ],
            };
          }
          outputData["Subcomando"] = "Lectura de fabricante por id";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Numero_total"] = bytes[4];
          outputData["Numero_trama"] = bytes[5];
          if (codigoRespuesta == 0) {
            outputData["Fabricante"] =
              "0x" +
              this.readU16Lsb(bytes, 6).toString(16).padStart(4, "0") +
              " - " +
              this.mbusManHexToAsc(bytes, 6);
            outputData["Clave"] = this.bytesToHex(
              bytes.slice(6 + 2, 6 + 2 + 16)
            );
          }
          break;
        case 0x10:
        case 0x90:
          outputData["Subcomando"] = "Escritura de fabricantes";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Fabricantes"] = [];
          var indice = 4;
          var indiceFabricante = 0;
          while (indice < bytes.length) {
            if (indice + 2 > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A ESCRITURA DE FABRICANTES - Longitud insuficiente",
                ],
              };
            }
            outputData["Fabricantes"][indiceFabricante++] =
              "0x" +
              this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
              " - " +
              this.mbusManHexToAsc(bytes, indice);
            indice += 2;
          }
          break;
        case 0x20:
        case 0xa0:
          var numeroBorrados = bytes[2] & 0x0f;
          outputData["Subcomando"] =
            numeroBorrados == 0x0f
              ? "Borrado de todos los fabricantes"
              : "Borrado de fabricantes por id";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Fabricantes"] = [];
          if (numeroBorrados != 0x0f) {
            var indiceFabricante = 0;
            var indice = 4;
            while (indice < bytes.length) {
              if (indice + 2 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A BORRADO DE FABRICANTES - Longitud insuficiente",
                  ],
                };
              }
              outputData["Fabricantes"][indiceFabricante++] =
                "0x" +
                this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
                " - " +
                this.mbusManHexToAsc(bytes, indice);
              indice += 2;
            }
          }
          break;
        default:
          return {
            errors: [
              "RESPUESTA A GESTIÓN DE FABRICANTES: Subcomando no válido: 0x" +
                subcomando.toString(16).padStart(2, "0"),
            ],
          };
          break;
      }
      return { data: outputData };
      //return outputData;
    }
  }

  /* Parseo de comando de gestión de la lista de candidatos */
  CandidatosDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "COMANDO DE GESTIÓN DE LA LISTA DE CANDIDATOS",
          ID_Trama: bytes[0],
          Longitud_Datos: bytes.length - 1,
        },
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESPUESTA A GESTIÓN DE LA LISTA DE CANDIDATOS",
        ID_Trama: bytes[0],
      };
      var codigoRespuesta = bytes[1];
      var subcomando = bytes[2] & 0xf0;
      var indicePrimero = bytes[3];
      switch (subcomando) {
        case 0x00:
          outputData["Subcomando"] = "Lectura de candidatos";
          var numeroPedidos = bytes[2] & 0x0f;
          outputData["N_Pedidos"] =
            numeroPedidos == 0x0f ? "TODOS" : numeroPedidos;
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          if (codigoRespuesta == 0) {
            outputData["Indice_primero"] = indicePrimero;
            outputData["Numero_total"] = bytes[4];
            outputData["Numero_trama"] = bytes[5];
            outputData["Candidatos"] = [];
            var indiceCandidato = 0;
            var indice = 6;
            while (indice < bytes.length) {
              if (indice + 2 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A LECTURA DE CANDIDATOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Candidatos"][indiceCandidato++] =
                "0x" +
                this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
                " - " +
                this.mbusManHexToAsc(bytes, indice);
              indice += 2;
            }
          }
          break;
        case 0x80:
          if (
            (!codigoRespuesta && bytes.length != 6) ||
            (codigoRespuesta && bytes.length != 8)
          ) {
            return {
              errors: [
                "RESPUESTA A LECTURA DE CANDIDATOS POR ID - Longitud errónea",
              ],
            };
          }
          outputData["Subcomando"] = "Lectura de candidato por id";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Numero_total"] = bytes[4];
          outputData["Numero_trama"] = bytes[5];
          if (codigoRespuesta == 0) {
            outputData["Candidato"] =
              "0x" +
              this.readU16Lsb(bytes, 6).toString(16).padStart(4, "0") +
              " - " +
              this.mbusManHexToAsc(bytes, 6);
          }
          break;
        case 0x10:
        case 0x90:
          outputData["Subcomando"] = "Escritura de candidatos";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Candidatos"] = [];
          var indice = 4;
          var indiceCandidato = 0;
          while (indice < bytes.length) {
            if (indice + 2 > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A ESCRITURA DE CANDIDATOS - Longitud insuficiente",
                ],
              };
            }
            outputData["Fabricantes"][indiceCandidato++] =
              "0x" +
              this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
              " - " +
              this.mbusManHexToAsc(bytes, indice);
            indice += 2;
          }
          break;
        case 0x20:
        case 0xa0:
          var numeroBorrados = bytes[2] & 0x0f;
          outputData["Subcomando"] =
            numeroBorrados == 0x0f
              ? "Borrado de todos los candidatos"
              : "Borrado de candidatos por id";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Candidatos"] = [];
          if (numeroBorrados != 0x0f) {
            var indiceCandidato = 0;
            var indice = 4;
            while (indice < bytes.length) {
              if (indice + 2 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A BORRADO DE CANDIDATOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Candidatos"][indiceCandidato++] =
                "0x" +
                this.readU16Lsb(bytes, indice).toString(16).padStart(4, "0") +
                " - " +
                this.mbusManHexToAsc(bytes, indice);
              indice += 2;
            }
          }
          break;
        default:
          return {
            errors: [
              "RESPUESTA A GESTIÓN DE CANDIDATOS: Subcomando no válido: 0x" +
                subcomando.toString(16).padStart(2, "0"),
            ],
          };
          break;
      }
      return { data: outputData };
      //return outputData;
    }
  }

  /* Parseo de comando de gestión de la lista de fijos */
  FijosDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        data: {
          Tipo_Trama: "COMANDO DE GESTIÓN DE LA LISTA DE FIJOS",
          ID_Trama: bytes[0],
          Longitud_Datos: bytes.length - 1,
        },
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESPUESTA A GESTIÓN DE LA LISTA DE FIJOS",
        ID_Trama: bytes[0],
      };
      var codigoRespuesta = bytes[1];
      var subcomando = bytes[2] & 0xf0;
      var indicePrimero = bytes[3];
      switch (subcomando) {
        case 0x00:
          outputData["Subcomando"] = "Lectura de fijos";
          var numeroPedidos = bytes[2] & 0x0f;
          outputData["N_Pedidos"] =
            numeroPedidos == 0x0f ? "TODOS" : numeroPedidos;
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          if (codigoRespuesta == 0) {
            outputData["Indice_primero"] = indicePrimero;
            outputData["Numero_total"] = bytes[4];
            outputData["Numero_trama"] = bytes[5];
            outputData["Contadores"] = [];
            var indiceContador = 0;
            var indice = 6;
            while (indice < bytes.length) {
              if (indice + 8 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A LECTURA DE FIJOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Contadores"][indiceContador++] = this.bytesToHex(
                bytes.slice(indice, indice + 8)
              );
              indice += 8;
            }
          }
          break;
        case 0x80:
          if (
            (!codigoRespuesta && bytes.length != 6) ||
            (codigoRespuesta && bytes.length != 30)
          ) {
            return {
              errors: [
                "RESPUESTA A LECTURA DE FIJOS POR DIRECCION - Longitud errónea",
              ],
            };
          }
          outputData["Subcomando"] = "Lectura de fijos por dirección";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Numero_total"] = bytes[4];
          outputData["Numero_trama"] = bytes[5];
          if (codigoRespuesta == 0) {
            outputData["Direccion"] = this.bytesToHex(bytes.slice(6, 6 + 8));
          }
          break;
        case 0x10:
        case 0x90:
          outputData["Subcomando"] = "Escritura de fijos";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Indice_primero"] = indicePrimero;
          outputData["Contadores"] = [];
          var indice = 4;
          var indiceContador = 0;
          while (indice < bytes.length) {
            if (indice + 8 > bytes.length) {
              return {
                errors: [
                  "RESPUESTA A ESCRITURA DE FIJOS - Longitud insuficiente",
                ],
              };
            }
            outputData["Contadores"][indiceContador++] = this.bytesToHex(
              bytes.slice(indice, indice + 8)
            );
            indice += 8;
          }
          break;
        case 0x20:
        case 0xa0:
          var numeroBorrados = bytes[2] & 0x0f;
          outputData["Subcomando"] =
            numeroBorrados == 0x0f
              ? "Borrado de todos los fijos"
              : "Borrado de fijos por dirección";
          outputData["Codigo_respuesta"] = codigoRespuesta ? "ERROR" : "OK";
          outputData["Contadores"] = [];
          if (numeroBorrados != 0x0f) {
            var indiceContador = 0;
            var indice = 4;
            while (indice < bytes.length) {
              if (indice + 8 > bytes.length) {
                return {
                  errors: [
                    "RESPUESTA A BORRADO DE FIJOS - Longitud insuficiente",
                  ],
                };
              }
              outputData["Contadores"][indiceContador++] = this.bytesToHex(
                bytes.slice(indice, indice + 8)
              );
              indice += 8;
            }
          }
          break;
        default:
          return {
            errors: [
              "RESPUESTA A GESTIÓN DE FIJOS: Subcomando no válido: 0x" +
                subcomando.toString(16).padStart(2, "0"),
            ],
          };
          break;
      }
      return { data: outputData };
    }
  }

  ResetDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        errors: ["ERROR: Comando downlink de reset"],
      };
    } else {
      var outputData = {
        Tipo_Trama: "RESET",
        ID_Trama: bytes[0],
        Firmware: String.fromCharCode(bytes[1], bytes[2], bytes[3], bytes[4]),
        Causa_Reset: bytes[11],
        Bateria:
          (this.readU16Lsb(bytes, 5) / 10).toLocaleString("es-ES") + " V",
        Tramas_Validas:
          this.readU16Lsb(bytes, 9).toString() +
          " / " +
          this.readU16Lsb(bytes, 7).toString(),
        Filtro:
          bytes[12] == 0
            ? "Los primeros que se reciban"
            : bytes[12] == -1
            ? "Los de peor RSSI"
            : "Los de mejor RSSI",
        Filtro_RSSI:
          (0 - bytes[14]).toLocaleString("es-ES") +
          " ... " +
          (0 - bytes[13]).toLocaleString("es-ES") +
          " dBm",
        Numero_Fabricantes: bytes[16],
        Numero_Dispositivos: bytes[15],
        Numero_Candidatos: bytes[17],
        Numero_Fijos: bytes[18],
      };
      return { data: outputData };
    }
  }

  HelloDecoder(bytes, isDataUp) {
    var outputData;
    if (!isDataUp) {
      outputData = { Tipo_Trama: "COMANDO DE PETICIÓN DE HELLO" };
    } else {
      var tiempoRxMbus = this.readU32Lsb(bytes, 5);
      var tiempoRxLoRa = this.readU32Lsb(bytes, 9);
      var tiempoTxLoRa = this.readU32Lsb(bytes, 13);
      outputData = {
        Tipo_Trama: "HELLO",
        ID_Trama: bytes[0],
        Firmware: String.fromCharCode(bytes[1], bytes[2], bytes[3], bytes[4]),
        Tiempo_RX_Mbus: tiempoRxMbus.toString() + " segundos",
        Tiempo_RX_LoRa: tiempoRxLoRa.toString() + " segundos",
        Tiempo_TX_LoRa: tiempoTxLoRa.toString() + " segundos",
        Bateria:
          (this.readU16Lsb(bytes, 17) / 10).toLocaleString("es-ES") + " V",
        Filtro:
          bytes[19] == 0
            ? "Los primeros que se reciban"
            : bytes[12] == -1
            ? "Los de peor RSSI"
            : "Los de mejor RSSI",
        Filtro_RSSI:
          (0 - bytes[21]).toLocaleString("es-ES") +
          " ... " +
          (0 - bytes[20]).toLocaleString("es-ES") +
          " dBm",
        Tramas_Validas: bytes[22].toString() + " %",
        RSSI:
          this.readByteSigned(bytes[24]).toString() +
          " dBm / SNR: " +
          this.readByteSigned(bytes[23]).toString() +
          " dB",
        Numero_Contadores: bytes[25],
        Periodo_TX:
          this.readU16Lsb(bytes, 26).toString() +
          " segundos ( " +
          Math.floor(this.readU16Lsb(bytes, 26) / 60).toString() +
          " minutos)",
        Random: this.readU16Lsb(bytes, 28).toString() + " segundos",
        Numero_Desconocidos: bytes[30],
        Numero_Dispositivos: bytes[31],
        Numero_Fabricantes: bytes[32],
        Numero_Candidatos: bytes[33],
        Numero_Fijos: bytes[34],
        Consumo_Acumulado:
          this.CalculaConsumo(
            tiempoRxMbus,
            tiempoRxLoRa,
            tiempoTxLoRa,
            0
          ).toLocaleString("es-ES") + " mAh",
      };
    }
    return { data: outputData };
  }

  DesconocidosDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      return {
        errors: [
          "DISPOSITIVOS DESCONOCIDOS - ERROR: Comando downlink de Desconocidos",
        ],
      };
    } else {
      if ((bytes.length - 2) % 9 != 0) {
        return {
          errors: ["DISPOSITIVOS DESCONOCIDOS - ERROR: Longitud no válida"],
        };
      }
      var numeroDesconocidos = (bytes.length - 2) / 9;
      var outputData = {
        Tipo_Trama: "DISPOSITIVOS DESCONOCIDOS",
        ID_Trama: bytes[0],
        Numero_Trama: bytes[1],
        Numero_desconocidos: numeroDesconocidos,
      };

      if (numeroDesconocidos) {
        outputData["Desconocidos"] = [];
        let indice = 2;
        for (
          let indiceDesconocido = 0;
          indiceDesconocido < numeroDesconocidos;
          indiceDesconocido++
        ) {
          outputData["Desconocidos"][indiceDesconocido] = {
            Direccion: this.bytesToHex(bytes.slice(indice + 1, indice + 1 + 8)),
            RSSI: (0 - bytes[indice]).toString() + " dBm",
          };
          indice += 9;
        }
      }
      return { data: outputData };
    }
  }

  ValoresDecoder(bytes, isDataUp) {
    if (!isDataUp) {
      if (bytes.length != 2) {
        return {
          errors: [
            "COMANDO DE PETICIÓN DE VALORES DE CONTADOR - ERROR: Longitud no válida",
          ],
        };
      }
      return {
        data: {
          Tipo_Trama: "COMANDO DE PETICIÓN DE VALORES DE CONTADOR",
          ID_Trama: bytes[0],
          Indice_Contador: bytes[1],
        },
      };
    } else {
      var vif = bytes[14] & 0x0f;
      var valor64bits = (bytes[14] >> 4) & 0x01;
      var longitudAlarmas = (bytes[14] >> 5) & 0x07;
      var pesoPulso = vif == 0x0f ? 0.001 : 10 ** (vif - 6); // m3/pulso
      var mascaraHoras = this.readU24Lsb(bytes, 10);
      var outputData = {
        Tipo_Trama: "VALORES DE CONTADOR",
        ID_Trama: bytes[0],
        Indice_Contador: bytes[1],
        Direccion: this.bytesToHex(bytes.slice(2, 2 + 8)),
        Mascara_Horas: "0x" + mascaraHoras.toString(16).padStart(6, "0"),
        RSSI: (0 - bytes[13]).toString() + " dBm",
        VIF:
          "0x" +
          vif.toString(16).padStart(2, "0") +
          " - " +
          pesoPulso.toLocaleString("es-ES") +
          " m3/pulso",
        Tamanio_Valor: valor64bits ? "8 bytes" : "4 bytes",
      };
      var indice = 15;
      if (longitudAlarmas) {
        outputData["Alarmas"] = this.bytesToHex(
          bytes.slice(indice, indice + longitudAlarmas)
        );
        indice += longitudAlarmas;
      }
      var valorReferencia = this.readU32Lsb(bytes, indice);
      indice += 4;
      if (valor64bits) {
        valorReferencia += this.readU32Lsb(bytes, indice) << 32;
        indice += 4;
      }
      outputData["Valor_Referencia"] =
        (valorReferencia * pesoPulso).toFixed(3) + " m3";
      var acumuladorPositivo = this.read24Lsb(bytes, indice);
      indice += 3;
      outputData["Acumulador_Positivo"] =
        (acumuladorPositivo * pesoPulso).toFixed(3) + " m3";
      var acumuladorNegativo = this.read24Lsb(bytes, indice);
      indice += 3;
      outputData["Acumulador_Negativo"] =
        (acumuladorNegativo * pesoPulso).toFixed(3) + " m3";
      outputData["Consumos"] = "NO PARSEADO";

      return { data: outputData };
    }
  }

  /* Helper Methods */

  /* Cálculo del consumo acumulado de un LoRaWAN-MBUS en base a los datos del hello */
  CalculaConsumo(
    segundosMBus,
    segundosRxLoRa,
    segundosTxLoRa,
    horasFuncionamiento
  ) {
    const ConsumoMbus = 8.25;
    const ConsumoRxLoRa = 6.75;
    const ConsumoTxLoRa = 60.0;
    const ConsumoStby = 0.012;

    var mAhMBus = (segundosMBus * ConsumoMbus) / 3600;
    var mAhRxLoRa = (segundosRxLoRa * ConsumoRxLoRa) / 3600;
    var mAhTxLoRa = (segundosTxLoRa * ConsumoTxLoRa) / 3600;
    var mAhStandBy = horasFuncionamiento * ConsumoStby;

    return mAhMBus + mAhRxLoRa + mAhTxLoRa + mAhStandBy;
  }

  /* Convierte un fabricante (MAN de la dirección)hexadecimal a ASCII en 3 caracteres */
  mbusManHexToAsc(bytes, start) {
    var M = this.readU16Lsb(bytes, start);
    var fabricanteAscii = String.fromCharCode(
      ((M >> 10) & 0x01f) + 64,
      ((M >> 5) & 0x01f) + 64,
      (M & 0x01f) + 64
    );
    return fabricanteAscii;
  }

  cogeBits(bytes, start, numeroBits) {
    var res = 0;
    var indice = 0;
    while (numeroBits > 8) {
      res <<= 8;
      res |= bytes[start + indice++];
      numeroBits -= 8;
    }
    if (numeroBits) {
      res <<= numeroBits;
      res |= bytes[start + indice] >> (8 - numeroBits);
    }
    return res;
  }

  vifPesoPulso(vif) {
    switch (vif) {
      case 0x10:
        return 0.001;
      case 0x11:
        return 0.01;
      case 0x12:
        return 0.1;
      case 0x13:
        return 1;
      case 0x14:
        return 10;
      case 0x15:
        return 100;
      case 0x16:
        return 1000;
      case 0x17:
        return 10000;
      default:
        return -1;
    }
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  read16Lsb(bytes, start) {
    var res = this.readU16Lsb(bytes, start);
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  read24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    if (res & 0x800000) {
      res = res - 0xffffff - 1;
    }
    return res;
  }

  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  readByteSigned(byte) {
    if (byte & 0x80) {
      byte = byte - 0xff - 1;
    }
    return byte;
  }
}
