<!-- Modal de edición de IP -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ data.updating ? ("update" | translate) : ("new" | translate) }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Cliente -->
    <app-material-select
      [title]="'client' | translate"
      [bindValue]="'clientId'"
      [bindLabel]="'clientName'"
      [selected]="initialSelection"
      [data]="clientList"
      (selectedOption)="data.ip.clientId = $event?.clientId"
    ></app-material-select>
    <mat-form-field class="activation-dialog-input-text" appearance="standard">
      <!-- IP -->
      <mat-label>{{ "IP" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="data.ip.clientIp"
        placeholder="{{ 'IP' | translate }}..."
      />
    </mat-form-field>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="
        data.ip.clientId == null ||
        data.ip.clientIp == null ||
        data.ip.clientIp == ''
      "
      (click)="data.updating ? updateIp() : saveIp()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
