<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          {{ "pressure-map" | translate }}
        </h5>
      </div>

      <div class="panel-body">
        <!-- Mapa de alertas -->
        <app-map-controller
          [mapType]="mapType"
          [mapHeight]="mapHeight"
          [disableCluster]="true"
          [hideCluster]="true"
          [allowKml]="true"
          (actionFlag)="mapActions($event.action, $event.data)"
          [gatewaysData]="[]"
          [metersData]="mapData"
        >
        </app-map-controller>
      </div>
    </div>
  </div>
</section>
