import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// File saver
import saveAs from "file-saver";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MeterService } from "../../devices/meter/MeterService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { FileImportService } from "../../../../services/shared/FileImportService.service";
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
// Interfaces
import {
  PIPE_ROTATION,
  SUPPLY_BOARD_PIECE_TYPES,
  SUPPLY_BOARD_PIECES,
  SupplyNetworkCell,
  SupplyNetworkData,
  SupplyNetworkPiece,
} from "./supply-network-interface";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  AssignedDevice,
  VALVE_STATES,
} from "../../devices/DeviceInterface.type";
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Balance } from "../../data-analysis/DataAnalysisInterface.type";
import { GRAPH_TYPES } from "../../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";

@Component({
  selector: "app-supply-network-constructor",
  templateUrl: "./supply-network-constructor.component.html",
  styleUrls: ["./supply-network-constructor.component.scss"],
})
export class SupplyNetworkConstructorComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  currentEntity: Entity;
  entitySub: Subscription;
  numberFormat: string;
  entityVirtual: Agrupation;

  readonly SUPPLY_BOARD_PIECE_TYPES = SUPPLY_BOARD_PIECE_TYPES;
  readonly SUPPLY_BOARD_PIECES = SUPPLY_BOARD_PIECES.filter(
    (piece) => piece.id < 90
  );
  readonly SUPPLY_BOARD_PIPES = SUPPLY_BOARD_PIECES.filter(
    (piece) => piece.id >= 90
  );
  formatNumber = formatNumber;

  // Panel de construcción
  @ViewChild("supplyBoard") supplyBoard: ElementRef;
  supplyBoardWidth: number = 64;
  supplyBoardHeight: number = 32;
  supplyBoardCells: SupplyNetworkCell[][];
  draggedPiece: SupplyNetworkPiece;
  selectedPiece: SupplyNetworkPiece;
  cellSize: number;
  gridZoom: number = 1;
  maxGridZoom: number = 3;
  minGridZoom: number = 1;
  gridLoading: boolean = true;
  pipeConstructorActive: boolean = false;
  pipesBuilt: SupplyNetworkCell[] = [];
  networkName: string;
  networkId: string;
  networkList: SupplyNetworkData[];
  preselectedNetwork = "new";
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("pieceContainer") pieceContainer: ElementRef;
  cellBlockFocus: boolean = false;
  gridHidden: boolean = false;

  // Selector de fechas
  dateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");

  // Contadores de agua
  waterMeterList: AssignedDevice[];
  valveList: AssignedDevice[];
  balanceList: Balance[];

  // Menú de opciones del componente
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "save",
      icon: "fas fa-save",
      text: this.translate.instant("save"),
      visible: true,
    },
    {
      action: "import",
      icon: "fas fa-file-arrow-up",
      text: this.translate.instant("import"),
      visible: true,
    },
    {
      action: "download",
      icon: "fas fa-file-arrow-down",
      text: this.translate.instant("download"),
      visible: true,
    },
    {
      action: "delete",
      icon: "fas fa-trash",
      text: this.translate.instant("delete"),
      disabled: true,
      bottom: true,
      highlight: true,
      visible: true,
    },
  ];

  // Scroll de ratón
  mouseDown = false;
  startX: any;
  startY: any;
  scrollLeft: any;
  scrollTop: any;

  @HostListener("document:keydown", ["$event"])
  handleKeyDown(e: KeyboardEvent) {
    switch (e.key) {
      case "Delete":
        if (this.selectedPiece) {
          this.deletePiece(
            this.supplyBoardCells[this.selectedPiece.row][
              this.selectedPiece.column
            ]
          );
        }
        break;
      case "ArrowRight":
        this.moveSelectedPiece(0, 1);
        break;
      case "ArrowLeft":
        this.moveSelectedPiece(0, -1);
        break;
      case "ArrowUp":
        this.moveSelectedPiece(-1, 0);
        break;
      case "ArrowDown":
        this.moveSelectedPiece(1, 0);
        break;
      case "Alt":
        this.pipeConstructorActive = true;
        break;
      case "Escape":
        this.pipeConstructorActive = false;
        this.pipesBuilt = [];
        this.selectedPiece = null;
        this.resetCellFocus();
        break;
      default:
        break;
    }
  }

  @HostListener("document:keyup", ["$event"])
  handleKeyUp(e: KeyboardEvent) {
    switch (e.key) {
      case "Alt":
        this.pipeConstructorActive = false;
        this.pipesBuilt = [];
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    public FileImportService: FileImportService,
    private ManagementeController: ManagementControllerService,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
    this.entityVirtual = this.currentEntity?.agrupations.find(
      (agrupation: Agrupation) => agrupation.showAllEntity
    );

    if (
      !this.entityVirtual &&
      this.SessionDataService.getCurrentAgrupation()?.name == "RED DE ALTA"
    ) {
      this.entityVirtual = this.SessionDataService.getCurrentAgrupation();
    }

    this.entitySub = this.SessionDataService.getEntity().subscribe(() =>
      this.ReloadComponentService.reload()
    );

    if (this.currentEntity && this.entityVirtual) {
      this.getMeterList();
      this.getBalanceList();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del listado de contadores de la agrupación
  getMeterList(): void {
    this.MeterController.table(
      this.entityVirtual
        ? this.entityVirtual.id
        : this.SessionDataService.getCurrentAgrupation().id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let meterList = this.MeterService.parseAssignedMeterList(
          response["body"]["meterList"]
        );
        meterList.forEach((meter: AssignedDevice) => {
          let manufacturer = this.ManufacturerService.getManufacturer(
            `${meter.fabricante}`, // Interpolación que convierte a string el fabricante
            `${meter.devType}`, // Interpolación que convierte a string el devtype
            this.SessionDataService.getCurrentLanguage()
          );
          meter.fabricanteParsed = manufacturer?.manufacturerText;
          meter.devTypeParsed = manufacturer?.deviceText;
          // CUPS
          meter.cups = meter.isAssigned ? meter.clave : null;
          // En red
          meter.inNetwork = meter.comunica;
          // Válvula
          meter.valveStateParsed =
            meter.valveState != null &&
            meter.valveState != -1 &&
            VALVE_STATES[meter.valveState]
              ? this.translate.instant(VALVE_STATES[meter.valveState].text)
              : this.translate.instant("unknown");
        });
        this.waterMeterList = meterList.filter(
          (meter) => meter.metrologyType == METROLOGY_TYPE.WATER
        );
        this.valveList = meterList.filter(
          (meter) => meter.metrologyType == METROLOGY_TYPE.WATER_VALVE
        );
        this.loadDataFromServer();
      }
    });
  }

  // Obtención del listado de contadores de la agrupación
  getBalanceList(): void {
    this.DataAnalysisController.getBalanceList(
      this.entityVirtual
        ? this.entityVirtual.id
        : this.SessionDataService.getCurrentAgrupation().id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let balanceList = response["body"];
        this.balanceList = balanceList;
      }
    });
  }

  // Obtención de las lecturas de balance
  getBalanceReadings(piece: SupplyNetworkPiece): void {
    let balance = piece.balance;
    piece.tooltip = {
      title: balance.name,
      link: "/analisis-datos/grupo/" + balance.id,
      linkState: {
        state: { data: balance },
      },
      active: true,
      position: 0,
    };

    this.DataAnalysisController.getBalanceReadings({
      meterList: null,
      sector: balance.id,
      fromTimestamp: this.dateRange.startDate.valueOf().toString(),
      toTimestamp: this.dateRange.endDate.valueOf().toString(),
      graphType: GRAPH_TYPES.CONSUMPTION,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        let balanceData = response["body"];
        piece.balance.totalHours = balanceData.totalHours;
        piece.balance.parentPercentage = balanceData.parentPercentage;
        piece.balance.parentTotalHours = balanceData.parentTotalHours;
        piece.balance.childPercentage = balanceData.childPercentage;
        piece.balance.childTotalHours = balanceData.childTotalHours;
        piece.balance.loadBalancingContainer =
          balanceData.loadBalancingContainer;
        let differenceSerie = balanceData.parentReadings?.map(
          (reading: number[]) => {
            let childReading: number[] = balanceData.childReadings.find(
              (childReading: number[]) => childReading[0] == reading[0]
            );
            if (childReading) {
              return [reading[0], reading[1] - childReading[1]];
            } else {
              return reading;
            }
          }
        );
        if (differenceSerie?.length > 0) {
          let totalHours = Math.round(
            (differenceSerie[differenceSerie.length - 1][0] -
              differenceSerie[0][0]) /
              3600000
          );
          piece.balance.vnr = formatNumber(
            (balanceData.loadBalancingContainer?.parentAcumulatedConsumption -
              balanceData.loadBalancingContainer?.childAcumulatedConsumption) /
              totalHours,
            this.numberFormat
          );
        }

        // Datos de gráfica para tooltip
        piece.tooltip.graphData = {
          type: "area",
          series: [
            {
              id: "parent",
              name: this.translate.instant("fathers"),
              data: balanceData.parentReadings,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: "m³",
                valueDecimals: 3,
              },
              color: "#03a9f4",
              opacity: 0.35,
              type: "area",
            },
            {
              id: "child",
              name: this.translate.instant("childs"),
              data: balanceData.childReadings,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: " m³",
                valueDecimals: 3,
              },
              color: "#ff9800",
              opacity: 0.75,
              type: "area",
            },
            {
              id: "difference",
              name: this.translate.instant("difference"),
              data: differenceSerie,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: " m³",
                valueDecimals: 3,
              },
              color: "#4caf50",
              yAxis: 1,
              type: "column",
            },
          ],
          yAxis: [
            {
              height: "60%",
              labels: {
                enabled: false,
              },
              endOnTick: false,
              startOnTick: false,
              title: { text: "" },
            },
            {
              height: "35%",
              top: "65%",
              offset: 0,
              labels: {
                enabled: false,
              },
              endOnTick: false,
              startOnTick: false,
              title: { text: "" },
            },
          ],
        };
      }
    });
  }

  // Reseteo de datos de balance
  resetBalanceData(piece: SupplyNetworkPiece): void {
    piece.balance.totalHours = null;
    piece.balance.parentPercentage = null;
    piece.balance.parentTotalHours = null;
    piece.balance.childPercentage = null;
    piece.balance.childTotalHours = null;
    piece.balance.loadBalancingContainer = null;
    piece.balance.vnr = null;
  }

  // Actualización de fecha de referencia
  updateDateRange(date: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    this.dateRange = date;
    let meters = [];
    let balance = [];

    // Comprobación de piezas con contador/balance
    this.supplyBoardCells?.map((row) =>
      row.map((cell) => {
        if (cell.piece?.meter) {
          meters.push(cell.piece);
        }
        if (cell.piece?.balance) {
          balance.push(cell.piece);
        }
      })
    );

    // Actualización de contadores
    if (meters.length > 0) {
      meters.forEach((piece) => this.getMeterReadings(piece));
    }

    // Actualización de balances
    if (balance.length > 0) {
      balance.forEach((piece) => {
        piece.tooltip = { active: false, position: 0 };
        this.resetBalanceData(piece);
      });
    }
  }

  // Obtención de las lecturas de contador
  getMeterReadings(piece: SupplyNetworkPiece): void {
    let meter = piece.meter;
    piece.tooltip = {
      title: meter.nroSerie,
      link: "/dispositivos/detalle/contador/" + meter.id,
      active: true,
      position: 0,
    };

    this.MeterController.getGraph(
      meter.id,
      "2",
      this.dateRange.startDate.valueOf(),
      this.dateRange.endDate.valueOf()
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let numberFormat = this.SessionDataService.getCurrentNumberFormat();
        let units = " m³";
        piece.tooltip.graphData = {
          showLeaks: true,
          units: units,
          title: meter.nroSerie,
          html:
            `<div class="dialog-last-consumptions">
                      <span><b>` +
            this.translate.instant("last-consumption-min") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMin, numberFormat) +
            units +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-max") +
            ":</b> " +
            formatNumber(meter.lastConsumptionMax, numberFormat) +
            units +
            `</span>
                <span><b>` +
            this.translate.instant("last-consumption-total") +
            ":</b> " +
            formatNumber(meter.lastConsumptionTotal, numberFormat) +
            units +
            `</span>
                    </div>`,
          type: "area",
          series: [
            {
              id: "valor",
              name:
                meter.metrologyType == 2
                  ? this.translate.instant("consumption-normalized")
                  : this.translate.instant("consumption"),
              type: "area",
              data: response["body"]?.readings,
              dataGrouping: { approximation: "sum" },
              tooltip: {
                valueSuffix: meter.metrologyType == 2 ? " Nm³" : " m³",
                valueDecimals: 3,
              },
              color: "#42a5f5",
              navigatorOptions: {
                type: "area",
              },
            },
          ],
        };
      }
    });
  }

  // Redirección a dispositivo
  goToDevice(deviceLink: string, linkState?: object): void {
    this.router.navigate([deviceLink], linkState);
  }

  // Centrado de cuadrícula
  centerGrid(resetZoom?: boolean): void {
    setTimeout(() => {
      if (resetZoom) {
        this.gridZoom = 1;
        this.updateCellSize(this.supplyBoardWidth);
      }
      let verticalScroll =
        (this.cellSize * this.supplyBoardHeight -
          this.supplyBoard.nativeElement.offsetHeight) /
        2;
      this.supplyBoard.nativeElement.scrollLeft = 40;
      this.supplyBoard.nativeElement.scrollTop = verticalScroll + 40;
    }, 0);
  }

  // Actualización de número de celdas en panel
  updateCellSize(width: number): void {
    this.cellSize =
      (this.supplyBoard.nativeElement.offsetWidth / width) * this.gridZoom;
    this.supplyBoard.nativeElement.style.setProperty(
      "--supply-board-cell-width",
      this.cellSize
    );
    this.supplyBoard.nativeElement.style.setProperty(
      "--supply-board-cell-height",
      this.cellSize
    );
    this.gridLoading = false;
  }

  // Creación de celdas
  createBoardCells(width: number, height: number): SupplyNetworkCell[][] {
    let supplyBoardCells = [];
    for (let i = 0; i < height; i++) {
      supplyBoardCells.push([]);
      for (let j = 0; j < width; j++) {
        supplyBoardCells[i].push({ piece: null, row: i, column: j });
      }
    }
    return supplyBoardCells;
  }

  // Pieza soltada
  dropPiece(e: DragEvent, cell: SupplyNetworkCell): void {
    e?.preventDefault();
    if (this.draggedPiece.row != null && this.draggedPiece.column != null) {
      let previousCell =
        this.supplyBoardCells[this.draggedPiece.row][this.draggedPiece.column];
      if (this.checkCollisions(previousCell, this.draggedPiece.size)) {
        this.removeReferenceCells(previousCell, 1);
        previousCell.piece = null;
        this.draggedPiece.row = parseInt(e.target["id"].split("-")[0]);
        this.draggedPiece.column = parseInt(e.target["id"].split("-")[1]);
        cell.piece = this.draggedPiece;
        this.addReferenceCells(cell, this.draggedPiece.size);
        this.pieceClick(cell);
      }
    } else {
      cell.piece = new SupplyNetworkPiece(
        this.draggedPiece.name,
        this.translate.instant(this.draggedPiece.name),
        this.draggedPiece.id,
        this.draggedPiece.icon,
        parseInt(e.target["id"].split("-")[0]),
        parseInt(e.target["id"].split("-")[1])
      );
      this.pieceClick(cell);
    }
    this.draggedPiece = null;
  }

  // Actualización de pieza en movimiento desde cajón de piezas
  updateDraggedPiece(piece: SupplyNetworkPiece): void {
    this.draggedPiece = piece;
    this.selectedPiece = null;
  }

  // Actualización de pieza en movimiento desde celda
  updateDraggedCellPiece(e: DragEvent, piece: SupplyNetworkPiece): void {
    e.stopImmediatePropagation();
    this.draggedPiece = piece;
  }

  // Permiso para arrastrar a celda
  pieceDragover(e: DragEvent, cell: SupplyNetworkCell): void {
    if (
      !cell.piece &&
      (!cell.cellReference ||
        (cell.cellReference &&
          cell.cellReference?.row == this.selectedPiece?.row &&
          cell.cellReference?.column == this.selectedPiece?.column))
    ) {
      e.preventDefault();
      e.stopImmediatePropagation();
    } else {
      return null;
    }
  }

  // Borrado de pieza con botón derecho de ratón
  pieceContextmenu(e: MouseEvent, cell: SupplyNetworkCell): void {
    e.preventDefault();
    if (cell.piece) {
      this.rotatePiece(cell);
    } else {
      this.resetCellFocus();
      cell.pipesMenu = true;
    }
  }

  // Mover pieza
  moveSelectedPiece(rowModifier: number, columnModifier: number): void {
    let previousCell =
      this.supplyBoardCells[this.selectedPiece.row][this.selectedPiece.column];
    let newCell =
      this.supplyBoardCells[this.selectedPiece.row + rowModifier][
        this.selectedPiece.column + columnModifier
      ];

    if (
      this.checkCollisions(
        previousCell,
        this.selectedPiece.size + 1,
        columnModifier != 0,
        rowModifier != 0
      ) &&
      this.checkCellsMove(rowModifier, columnModifier, [previousCell])
    ) {
      this.removeReferenceCells(previousCell, 1);
      previousCell.piece = null;
      this.selectedPiece.row += rowModifier;
      this.selectedPiece.column += columnModifier;
      newCell.piece = this.selectedPiece;
      this.addReferenceCells(newCell, this.selectedPiece.size);
      this.pieceClick(newCell);
    }
  }

  // Rotación de pieza
  rotatePiece(cell: SupplyNetworkCell): void {
    cell.piece.rotation < 270
      ? (cell.piece.rotation += 90)
      : (cell.piece.rotation = 0);
  }

  // Rotación de tubería
  rotatePipe(cell: SupplyNetworkCell): void {
    cell.pipeRotation == null
      ? (cell.pipeRotation = 90)
      : cell.pipeRotation < 270
      ? (cell.pipeRotation += 90)
      : (cell.pipeRotation = 0);
  }

  // Borrado de pieza de celda
  deletePiece(cell?: SupplyNetworkCell): void {
    if (!cell) {
      cell =
        this.supplyBoardCells[this.selectedPiece.row][
          this.selectedPiece.column
        ];
    }
    this.removeReferenceCells(cell, 1);
    cell.piece = null;
    cell.focus = false;
    this.selectedPiece = null;
  }

  // Click en pieza de celda
  pieceClick(clickedCell?: SupplyNetworkCell): void {
    this.resetCellFocus();
    if (clickedCell) {
      this.selectedPiece = clickedCell.piece;
      clickedCell.focus = true;
    } else {
      this.selectedPiece = null;
    }
  }

  // Reseteo de focus en celda
  resetCellFocus(): void {
    this.supplyBoardCells.map((row) =>
      row.map((cell) => {
        cell.focus = false;
        cell.pipesMenu = false;
      })
    );
  }

  // Actualización del tamaño de la pieza en el array de piezas
  updatePieceSize(sizeModifier: number): void {
    let pieceCell =
      this.supplyBoardCells[this.selectedPiece.row][this.selectedPiece.column];
    let newPieceSize = this.selectedPiece.size + sizeModifier;

    if (newPieceSize > this.selectedPiece.size) {
      if (this.checkCollisions(pieceCell, newPieceSize)) {
        this.addReferenceCells(pieceCell, newPieceSize);
        this.selectedPiece.size = newPieceSize;
      } else {
        newPieceSize = this.selectedPiece.size;
      }
    } else if (newPieceSize < this.selectedPiece.size) {
      this.removeReferenceCells(pieceCell, newPieceSize);
      this.selectedPiece.size = newPieceSize;
    }
  }

  // Chequeo de celdas colindantes para comprobar que no tenga piezas colocadas
  checkCollisions(
    cell: SupplyNetworkCell,
    newPieceSize: number,
    onlyRow?: boolean,
    onlyColumn?: boolean
  ): boolean {
    let collision = false;
    let i = cell.row + (onlyRow ? 0 : 1);
    do {
      let j = cell.column + (onlyColumn ? 0 : 1);
      do {
        collision =
          this.supplyBoardCells[i][j].piece != null ||
          this.supplyBoardCells[i][j].cellReference != null;
        j++;
      } while (!collision && j < cell.column + newPieceSize - 1 && !onlyRow);
      i++;
    } while (!collision && i < cell.row + newPieceSize - 1 && !onlyColumn);
    return !collision;
  }

  // Añadir referencia a celdas asociadas
  addReferenceCells(cell: SupplyNetworkCell, size: number): void {
    for (let i = cell.row; i < cell.row + size; i++) {
      for (let j = cell.column; j < cell.column + size; j++) {
        if (
          !(cell.row == i && cell.column == j) &&
          !this.supplyBoardCells[i][j].piece
        ) {
          this.supplyBoardCells[i][j].cellReference = cell;
        }
      }
    }
  }

  // Eliminar referencia de celdas asociadas
  removeReferenceCells(cell: SupplyNetworkCell, size?: number): void {
    for (
      let i = cell.row + this.selectedPiece.size - 1;
      i >= cell.row + size - 1;
      i--
    ) {
      for (
        let j = cell.column + this.selectedPiece.size - 1;
        j >= cell.column + size - 1;
        j--
      ) {
        this.supplyBoardCells[i][j].cellReference = null;
      }
    }
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "import":
        this.fileInput.nativeElement.click();
        break;
      case "save":
        this.networkId != null ? this.updateNetwork() : this.saveNetwork();
        break;
      case "download":
        this.downloadNetwork();
        break;
      case "delete":
        this.deleteNetwork();
        break;
      default:
        break;
    }
  }

  // Scroll en contenedor de piezas con rueda de ratón
  pieceContainerScroll(e: WheelEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.pieceContainer.nativeElement.scrollLeft += e.deltaY;
  }

  // Actualización de zoom por ratón
  updateWheelZoom(e: WheelEvent): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (
      (e.deltaY < 0 && this.gridZoom <= this.maxGridZoom) ||
      (e.deltaY > 0 && this.gridZoom > this.minGridZoom)
    ) {
      this.updateZoom(e.deltaY < 0 ? 1 : -1);
    }
  }

  // Actualización de zoom
  updateZoom(zoom: number): void {
    this.gridZoom += zoom / 10;
    this.updateCellSize(this.supplyBoardWidth);
  }

  // Reseteo de focus
  resetFocus(): void {
    if (this.cellBlockFocus) {
      this.cellBlockFocus = false;
    } else {
      this.selectedPiece = null;
      this.resetCellFocus();
    }
  }

  // Scroll con ratón en tabla
  gridStartDragging(e) {
    if (!e.target.classList.contains("supply-network-board-cell-data")) {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.mouseDown = true;
      this.startX = e.pageX - this.supplyBoard.nativeElement.offsetLeft;
      this.startY = e.pageY - this.supplyBoard.nativeElement.offsetTop;
      this.scrollLeft = this.supplyBoard.nativeElement.scrollLeft;
      this.scrollTop = this.supplyBoard.nativeElement.scrollTop;
    }
  }
  gridStopDragging(e) {
    this.mouseDown = false;
  }
  gridMoveEvent(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - this.supplyBoard.nativeElement.offsetLeft;
    const scrollX = x - this.startX;
    const y = e.pageY - this.supplyBoard.nativeElement.offsetTop;
    const scrollY = y - this.startY;
    this.supplyBoard.nativeElement.scrollLeft = this.scrollLeft - scrollX;
    this.supplyBoard.nativeElement.scrollTop = this.scrollTop - scrollY;
  }

  // Mover red en cuadrícula
  moveNetwork(rowModifier: number, columnModifier: number): void {
    this.gridLoading = true;
    setTimeout(() => {
      // Celdas con datos
      let cellsWithData: SupplyNetworkCell[] = [];
      this.supplyBoardCells.map((row) => {
        cellsWithData = [
          ...cellsWithData,
          ...JSON.parse(
            JSON.stringify(
              row.filter(
                (cell) => cell.piece || cell.cellReference || cell.pipe
              )
            )
          ),
        ];
      });

      // Comprobación de datos en límites de cuadrícula
      if (
        cellsWithData.length > 0 &&
        this.checkCellsMove(rowModifier, columnModifier, cellsWithData)
      ) {
        if (rowModifier > 0) {
          cellsWithData.sort((a, b) => b.row - a.row);
        }
        if (columnModifier > 0) {
          cellsWithData.sort((a, b) => b.column - a.column);
        }
        // Actualización de posición de celda
        cellsWithData.map((cell) => {
          let newRow = cell.row + rowModifier;
          let newColumn = cell.column + columnModifier;
          // Actualización de posición de pieza
          if (cell.piece) {
            cell.piece.row = newRow;
            cell.piece.column = newColumn;
            this.supplyBoardCells[newRow][newColumn].piece = cell.piece;
            this.supplyBoardCells[cell.row][cell.column].piece = null;
          }
          // Actualización de posición de celda de referencia
          if (cell.cellReference) {
            cell.cellReference.row = newRow;
            cell.cellReference.column = newColumn;
            this.supplyBoardCells[newRow][newColumn].cellReference =
              cell.cellReference;
            this.supplyBoardCells[cell.row][cell.column].cellReference = null;
          }
          // Actualización de posición de celda con tubería
          if (cell.pipe) {
            this.supplyBoardCells[newRow][newColumn].pipe = cell.pipe;
            this.supplyBoardCells[newRow][newColumn].pipeRotation =
              cell.pipeRotation;
            this.supplyBoardCells[cell.row][cell.column].pipe = null;
            this.supplyBoardCells[cell.row][cell.column].pipeRotation = null;
          }
        });
      }
      this.gridLoading = false;
    }, 0);
  }

  // Chequeo de movimiento de celdas en los límites de la cuadrícula
  checkCellsMove(
    rowModifier: number,
    columnModifier: number,
    cellsWithData: SupplyNetworkCell[]
  ): boolean {
    let dataRowMin = cellsWithData.some((cell) => cell.row == 0);
    let dataRowMax = cellsWithData.some(
      (cell) => cell.row == this.supplyBoardHeight - 1
    );
    let dataColumnMin = cellsWithData.some((cell) => cell.column == 0);
    let dataColumnMax = cellsWithData.some(
      (cell) => cell.column == this.supplyBoardWidth - 1
    );
    return (
      (!dataRowMin || (dataRowMin && rowModifier >= 0)) &&
      (!dataRowMax || (dataRowMax && rowModifier <= 0)) &&
      (!dataColumnMin || (dataColumnMin && columnModifier >= 0)) &&
      (!dataColumnMax || (dataColumnMax && columnModifier <= 0))
    );
  }

  // Seteo de tubería en celda
  setPipe(cell: SupplyNetworkCell): void {
    if (cell && !cell.piece && !cell.cellReference && !cell.pipe) {
      let pipe = this.getPipe(cell);
      cell.pipe = pipe.type;
      cell.pipeRotation = pipe.rotation;
      this.pipesBuilt.push(cell);
    }
  }

  // Obtención del tipo de tubería a colocar
  getPipe(cell: SupplyNetworkCell): {
    type: number;
    rotation: number;
    cell: SupplyNetworkCell;
  } {
    let type: number;
    let rotation: number;

    if (this.pipesBuilt.length > 1) {
      let lastCell = this.pipesBuilt[this.pipesBuilt.length - 1];
      let lastSecondCell = this.pipesBuilt[this.pipesBuilt.length - 2];

      // Comprobación de movimiento en diagonal
      if (lastCell.row != cell.row && lastCell.column != cell.column) {
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        if (lastCell.row == lastSecondCell.row) {
          rotation = PIPE_ROTATION.HORIZONTAL;
          let intermediateCell =
            this.supplyBoardCells[cell.row][lastCell.column];
          intermediateCell.pipe = SUPPLY_BOARD_PIECE_TYPES.PIPE_CURVE;
          intermediateCell.pipeRotation =
            cell.column > lastCell.column && cell.row > lastCell.row
              ? PIPE_ROTATION.DOWN_RIGHT
              : cell.column > lastCell.column && cell.row < lastCell.row
              ? PIPE_ROTATION.UP_RIGHT
              : cell.column < lastCell.column && cell.row > lastCell.row
              ? PIPE_ROTATION.DOWN_LEFT
              : PIPE_ROTATION.DOWN_RIGHT;
        }

        if (lastCell.column == lastSecondCell.column) {
          rotation = PIPE_ROTATION.VERTICAL;
          let intermediateCell =
            this.supplyBoardCells[lastCell.row][cell.column];
          intermediateCell.pipe = SUPPLY_BOARD_PIECE_TYPES.PIPE_CURVE;
          intermediateCell.pipeRotation =
            cell.column > lastCell.column && cell.row > lastCell.row
              ? PIPE_ROTATION.RIGHT_DOWN
              : cell.column > lastCell.column && cell.row < lastCell.row
              ? PIPE_ROTATION.RIGHT_UP
              : cell.column < lastCell.column && cell.row > lastCell.row
              ? PIPE_ROTATION.LEFT_DOWN
              : PIPE_ROTATION.LEFT_UP;
        }
      }

      // Últimas celdas en misma fila con cambio de columna
      if (
        lastCell.row == lastSecondCell.row &&
        lastCell.column != cell.column
      ) {
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = PIPE_ROTATION.HORIZONTAL;
      }

      // Últimas celdas en misma fila con cambio de fila
      if (lastCell.row == lastSecondCell.row && lastCell.row != cell.row) {
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = PIPE_ROTATION.VERTICAL;
        lastCell.pipe = SUPPLY_BOARD_PIECE_TYPES.PIPE_CURVE;
        lastCell.pipeRotation =
          lastSecondCell.column < lastCell.column
            ? lastCell.row < cell.row
              ? PIPE_ROTATION.RIGHT_DOWN
              : PIPE_ROTATION.RIGHT_UP
            : lastCell.row < cell.row
            ? PIPE_ROTATION.LEFT_DOWN
            : PIPE_ROTATION.LEFT_UP;
      }

      // Últimas celdas en misma columna con cambio de fila
      if (
        lastCell.column == lastSecondCell.column &&
        lastCell.row != cell.row
      ) {
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = PIPE_ROTATION.VERTICAL;
      }

      // Últimas celdas en misma columna con cambio de columna
      if (
        lastCell.column == lastSecondCell.column &&
        lastCell.column != cell.column
      ) {
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = 0;
        lastCell.pipe = SUPPLY_BOARD_PIECE_TYPES.PIPE_CURVE;
        lastCell.pipeRotation =
          lastSecondCell.row < lastCell.row
            ? lastCell.column < cell.column
              ? PIPE_ROTATION.DOWN_RIGHT
              : PIPE_ROTATION.DOWN_LEFT
            : lastCell.column < cell.column
            ? PIPE_ROTATION.UP_RIGHT
            : PIPE_ROTATION.UP_LEFT;
      }
    } else if (this.pipesBuilt.length == 1) {
      let lastCell = this.pipesBuilt[this.pipesBuilt.length - 1];

      // Última celda en distinta fila
      if (lastCell.row != cell.row) {
        lastCell.pipeRotation = PIPE_ROTATION.VERTICAL;
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = PIPE_ROTATION.VERTICAL;
      }

      // Última celda en distinta columna
      if (lastCell.column != cell.column) {
        lastCell.pipeRotation = PIPE_ROTATION.HORIZONTAL;
        type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
        rotation = PIPE_ROTATION.HORIZONTAL;
      }
    } else {
      type = SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE;
      rotation = PIPE_ROTATION.HORIZONTAL;
    }

    return { type: type, rotation: rotation, cell: cell };
  }

  // Guardado de red
  saveNetwork(): void {
    let data = this.getNetworkJSON();
    this.ManagementeController.saveSupplyNetwork(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.loadDataFromServer(response["body"]);
      }
    });
  }

  // Guardado de red
  updateNetwork(): void {
    let data = this.getNetworkJSON();
    this.ManagementeController.updateSupplyNetwork(data).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
        }
      }
    );
  }

  // Borrado de red
  deleteNetwork(): void {
    this.ManagementeController.deleteSupplyNetwork(this.networkId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("deleted-success")
          );
          this.ReloadComponentService.reload();
        }
      }
    );
  }

  // Carga de datos de servidor
  loadDataFromServer(networkId?: string): void {
    this.ManagementeController.getSupplyNetworkList(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let networkList: SupplyNetworkData[] = response["body"];
        networkList.unshift({
          elements: [],
          pipes: [],
          referenceDate: {
            from: this.dateRange.startDate.valueOf(),
            to: this.dateRange.endDate.valueOf(),
          },
          entityId: this.currentEntity.id,
          name: this.translate.instant("supply-network-new"),
          id: "new",
        });
        this.networkList = networkList;

        // Red inicial
        if (networkId) {
          this.preselectedNetwork = networkId;
        } else {
          this.updateCurrentNetwork(this.networkList[0]);
        }

        // Creación de tablero
        setTimeout(() => {
          this.updateCellSize(this.supplyBoardWidth);
          this.supplyBoardCells = this.createBoardCells(
            this.supplyBoardWidth,
            this.supplyBoardHeight
          );
          this.centerGrid();
        }, 0);
      }
    });
  }

  // Selección de red en curso
  updateCurrentNetwork(network: SupplyNetworkData): void {
    if (network.id != "new") {
      this.panelMenuOptions[this.panelMenuOptions.length - 1].disabled = false;
      this.loadPieces(network);
    } else {
      this.panelMenuOptions[this.panelMenuOptions.length - 1].disabled = true;
      this.resetNetworkGrid();
    }
  }

  // Reseteo de cuadrícula y red
  resetNetworkGrid(): void {
    this.draggedPiece = null;
    this.selectedPiece = null;
    this.networkId = null;
    this.networkName = this.currentEntity.entity;
    this.supplyBoardCells = this.createBoardCells(
      this.supplyBoardWidth,
      this.supplyBoardHeight
    );
  }

  // Descargar red
  downloadNetwork(): void {
    let data = this.getNetworkJSON();
    saveAs(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      }),
      this.networkName + ".txt"
    );
  }

  // Obtención del fichero de red
  getNetworkJSON(): SupplyNetworkData {
    let cellsWithData = [];
    let cellsWithPipe = [];
    this.supplyBoardCells.forEach((row) =>
      row.forEach((cell) => {
        if (cell.piece) {
          cellsWithData.push(cell);
        }
        if (cell.pipe) {
          cellsWithPipe.push(cell);
        }
      })
    );
    let data: SupplyNetworkData = {
      elements: cellsWithData.map((cell) => {
        return {
          label: cell.piece.label,
          id: cell.piece.id,
          size: cell.piece.size,
          rotation: cell.piece.rotation,
          row: cell.piece.row,
          column: cell.piece.column,
          meterId: cell.piece.meter?.id,
          isValve:
            cell.piece.meter?.metrologyType == METROLOGY_TYPE.WATER_VALVE,
          balanceId: cell.piece.balance?.id,
        };
      }),
      pipes: cellsWithPipe.map((cell) => {
        return [cell.row, cell.column, cell.pipe, cell.pipeRotation];
      }),
      referenceDate: {
        from: this.dateRange.startDate.valueOf(),
        to: this.dateRange.endDate.valueOf(),
      },
      entityId: this.currentEntity.id,
      name: this.networkName,
      id: this.networkId,
    };
    return data;
  }

  // Carga de red
  loadNetworkFile(file: File): void {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        let readedFile: any = reader.result;
        this.networkName = file.name.replace(".txt", "");
        this.loadPieces(JSON.parse(readedFile));
      },
      false
    );
    if (file) {
      reader.readAsText(file);
    }
  }

  // Carga de piezas en cuadrícula
  loadPieces(data: SupplyNetworkData): void {
    this.dateRange = {
      startDate: this.DateParserService.parseDateWithoutFormat(
        data.referenceDate.from
      ),
      endDate: this.DateParserService.parseDateWithoutFormat(
        data.referenceDate.to
      ),
    };
    this.networkId = data.id;
    this.networkName = data.name;

    data.elements.forEach((element) => {
      let pieceType = SUPPLY_BOARD_PIECES.find((type) => type.id == element.id);
      let meter = element.meterId
        ? this.waterMeterList.find((meter) => meter.id == element.meterId)
        : null;
      this.supplyBoardCells[element.row][element.column].piece = {
        label: element.label,
        name: pieceType.name,
        id: element.id,
        icon: pieceType.icon,
        row: element.row,
        column: element.column,
        rotation: element.rotation,
        meter: meter,
        valve: meter?.metrologyType == METROLOGY_TYPE.WATER_VALVE,
        balance: element.balanceId
          ? this.balanceList.find((balance) => balance.id == element.balanceId)
          : null,
        size: 1,
        tooltip: { active: false, position: 0 },
      };

      // Tamaño
      if (element.size > 1) {
        this.selectedPiece =
          this.supplyBoardCells[element.row][element.column].piece;
        this.updatePieceSize(element.size - 1);
      }

      // Datos de contador
      if (element.meterId) {
        this.getMeterReadings(
          this.supplyBoardCells[element.row][element.column].piece
        );
      }
    });

    data.pipes.forEach((pipe) => {
      this.supplyBoardCells[pipe[0]][pipe[1]].pipe = pipe[2];
      this.supplyBoardCells[pipe[0]][pipe[1]].pipeRotation = pipe[3];
    });

    this.selectedPiece = null;
  }
}
