import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import { Task } from "../../request-progress-panel/request-progress-task-interface";
import { GatewayCleanResponse } from "../../../../screens/dashboard/devices/DeviceInterface.type";

@Injectable({
  providedIn: "root",
})
export class RequestQueueResponseManagementService {
  constructor(private translate: TranslateService) {}

  // Obtención del texto de error
  getErrorText(errorCode: number): string {
    let errorText: string = "";
    if (errorCode) {
      errorText = "Error " + errorCode;
      errorText += ": " + this.translate.instant("httpError" + errorCode);
    } else {
      errorText = "Error " + this.translate.instant("unknown").toLowerCase();
    }
    return errorText;
  }

  // Gestión de respuesta de Asignar/Desasignar dispositivo
  allocateDeallocateResponseManagement(response: any, device: any): any {
    if (response["code"] == 0 || (response["code"] == 1 && response["body"])) {
      device.responseCode = response["body"][0]?.responseCode;
      device.highlightCurrent = false;
      // Si todo va bien
      if (device.responseCode == 0) {
        device.commandResponse = "success";
        device.highlightSuccess = true;
        // Si hay errores
      } else {
        device.commandResponse = "error";
        device.highlightError = true;
        device.errorText = this.getErrorText(device.responseCode);
      }
      // Errores de servicio
    } else {
      device.commandResponse = "warning";
      device.highlightCurrent = false;
      device.highlightWarning = true;
      device.errorText = this.getErrorText(device.responseCode);
    }
    return device;
  }

  // Comprobación de código individual de refresco
  refreshResponseManagement(response: any, device: any): any {
    if (response["body"]["contadorResultado"]) {
      if (response["body"]["contadorResultado"][0]?.resultado == 0) {
        device.responseCode = 0;
        device.commandResponse = "success";
        device.highlightSuccess = true;
      } else {
        device.commandResponse = "error";
        device.highlightError = true;
        device.highlightSuccess = false;
        let deviceError = response["body"]["contadorResultado"][0]?.resultado;
        if (device.errorText) {
          device.errorText += ", ";
        } else {
          device.errorText = "";
        }
        device.errorText += this.getErrorText(deviceError);
      }
    } else {
      device.responseCode = response["code"];
      device.commandResponse = "warning";
      device.highlightWarning = true;
      device.errorText = this.getErrorText(device.responseCode);
    }
    return device;
  }

  // Gestión de la respuesta por defecto
  defaultResponseManagement(response: any, device: any, task: Task): any {
    device.highlightCurrent = false;
    // Comprobación de código y refresco
    if (response["code"] == 0) {
      device.responseCode = 0;
      device.commandResponse = "success";
      device.highlightSuccess = true;
    } else if (response["code"] != 0) {
      device.responseCode = response["code"];
      device.commandResponse = "warning";
      device.highlightWarning = true;
      device.errorText = this.getErrorText(device.responseCode);
    }

    // Comprobación de código individual de limpieza de gateways
    if (
      (task.action == "cleanGateways" || task.action == "optimize") &&
      response["body"] &&
      response["body"][0]?.responseList?.some(
        (gatewayResponse: GatewayCleanResponse) =>
          gatewayResponse.responseCode > 0
      )
    ) {
      device.commandResponse = "error";
      device.highlightError = true;
      device.highlightSuccess = false;
      response["body"][0].responseList.forEach(
        (gatewayResponse: GatewayCleanResponse) => {
          if (
            gatewayResponse.responseCode > 0 &&
            gatewayResponse.responseCode != 255
          ) {
            let deviceError = gatewayResponse.responseCode;
            if (device.errorText) {
              device.errorText += ", ";
            } else {
              device.errorText = "";
            }
            device.errorText +=
              gatewayResponse.unidadVenta +
              " - " +
              this.getErrorText(deviceError);
          }
        }
      );
    }

    // Comprobación de código individual de reasignación
    if (
      task.action == "reassign" &&
      response["body"] &&
      response["body"][0]?.responseCode != 0
    ) {
      device.commandResponse = "error";
      device.highlightError = true;
      device.highlightSuccess = false;
      let deviceError = response["body"][0]?.responseCode;
      if (device.errorText) {
        device.errorText += ", ";
      } else {
        device.errorText = "";
      }
      device.errorText += this.getErrorText(deviceError);
    }

    return device;
  }

  // Gestión de la respuesta para servicios individuales
  singleResponseManagement(response: any, device: any, task?: Task): any {
    device.highlightCurrent = false;
    if (response["code"] == 0) {
      device.responseCode = 0;
      device.commandResponse = "success";
      device.highlightSuccess = true;
    } else {
      device.responseCode = response["code"];
      device.commandResponse = "error";
      device.highlightError = true;
      device.errorText = this.getErrorText(device.responseCode);
      if (device.deleteAppEuis) {
        this.jumpToNextGateway(device, task);
      }
    }
    return device;
  }

  // Salto al siguiente gateway de la cola si no se pueden borrar los APP EUIs
  jumpToNextGateway(currentGateway: any, task: Task): void {
    let nextGateway = task.taskData.findIndex(
      (gateway, i) =>
        i > task.progressIndex && gateway.deviceId != currentGateway.deviceId
    );
    let cancelUntil = nextGateway > 0 ? nextGateway : task.taskData.length;
    for (let i = task.progressIndex + 1; i < cancelUntil; i++) {
      task.taskData[i].commandResponse = "warning";
      task.taskData[i].highlightWarning = true;
      task.taskData[i].requestState = "";
      task.taskData[i].errorText = this.translate.instant("cancelled");
    }
    task.progressIndex =
      nextGateway > 0 ? nextGateway - 1 : task.taskData.length - 1;
  }

  // Gestión de la respuesta de Asignar mejor
  assignBestResponseManagement(response: any, device: any, task: Task): any {
    device.highlightCurrent = false;
    // Respuesta si se está reconfigurando el dispositivo
    if (task.reconfigure) {
      if (response["code"] == 0) {
        device.requestState = "";
        device.errorText = this.translate.instant(
          "gateway-reconfiguration-success"
        );
        device.commandResponse = "undo";
        device.codeModifyNewGateway = 0;
      } else {
        device.requestState = "";
        device.errorText = this.translate.instant(
          "gateway-reconfiguration-fail"
        );
      }
      // Respuesta estándar
    } else {
      if (response["code"] == 0 && response["body"]?.responseList[0]) {
        device.responseCode = response["body"]?.responseList[0].code;
        // Gateway nuevo
        device.newGatewayId = response["body"]?.responseList[0].newGatewayId;
        device.newGatewayUnidadVenta =
          response["body"]?.responseList[0].newGatewayUnidadVenta;
        device.newGatewayAmei =
          response["body"]?.responseList[0].newGatewayAmei;
        device.codeModifyNewGateway =
          response["body"]?.responseList[0].codeModifyNewGateway;
        device.newGatewayLink =
          "/gateways/detalle/gateway/" + device.newGatewayId;
        // Gateway viejo
        device.oldGatewayId = response["body"]?.responseList[0].oldGatewayId;
        device.oldGatewayUnidadVenta =
          response["body"]?.responseList[0].oldGatewayUnidadVenta;
        device.oldGatewayAmei =
          response["body"]?.responseList[0].oldGatewayAmei;
        device.codeModifyOldGateway =
          response["body"]?.responseList[0].codeModifyOldGateway;
        device.oldGatewayLink =
          "/gateways/detalle/gateway/" + device.oldGatewayId;

        // Si todo va bien
        if (
          device.responseCode == 0 &&
          device.codeModifyNewGateway == 0 &&
          (device.codeModifyOldGateway == 0 ||
            device.codeModifyOldGateway == null)
        ) {
          device.commandResponse = "success";
          device.highlightSuccess = true;
          // Si hay errores
        } else {
          // Si existe error de nuevo gateway
          if (device.codeModifyNewGateway) {
            device.errorText = this.translate.instant(
              "AssignBestError" + device.codeModifyNewGateway
            );
            if (device.errorText.includes("AssignBestError")) {
              device.errorText =
                this.translate.instant("error-text") +
                " " +
                device.codeModifyNewGateway;
            }
            // Si hay error y no se pueden revertir cambios
            if (device.codeModifyNewGateway == 4203) {
              device.commandResponse = "warning";
              device.highlightWarning = true;
              // Si hay error y se pueden revertir cambios
            } else {
              device.commandResponse = "error";
              device.highlightError = true;
            }
            // Si el error es de servicio
          } else {
            device.errorText = this.translate.instant(
              "AssignBestError" + device.code
            );
            if (device.errorText.includes("AssignBestError")) {
              device.errorText =
                this.translate.instant("error-text") +
                " " +
                device.responseCode;
            }
            // Si el error es porque el gateway asignado ya es el mejor
            if (device.responseCode == 4200) {
              device.commandResponse = "success";
              device.highlightSuccess = true;
              device.errorText = this.translate.instant("best-gateway-already");
              // Si el error es porque no hay datos de señal
            } else if (device.responseCode == 4205) {
              device.commandResponse = "warning";
              device.highlightWarning = true;
              device.errorText +=
                ": " + this.translate.instant("no-signal-data");
              // Cualquier otro error
            } else {
              device.commandResponse = "error";
              device.highlightError = true;
            }
          }
        }
      } else {
        device.responseCode = response["code"];
        device.commandResponse = "warning";
        device.highlightWarning = true;
        device.errorText = this.getErrorText(device.responseCode);
      }
    }
    return device;
  }

  relaunchGatewayResponseManagement(response: any, device: any, task: Task) {
    if (Array.isArray(response)) {
      device.highlightCurrent = false;
      // Comprobación de código y refresco
      if (response.every((res: any) => res["code"] == 0)) {
        device.responseCode = 0;
        device.commandResponse = "success";
        device.highlightSuccess = true;
      } else {
        device.responseCode = response.find((res: any) => res["code"] != 0);
        device.commandResponse = "warning";
        device.highlightWarning = true;
        device.errorText = this.getErrorText(device.responseCode);
      }
    } else {
      this.defaultResponseManagement(response, device, task);
    }
  }
}
