<div class="subscriber-main-container">
  <div class="subscriber-cups">
    <app-subscriber-cups
      *ngIf="currentSubscriberCupsList"
      [cupsList]="currentSubscriberCupsList"
      [cupsData]="currentSubscriberCupsData"
    >
    </app-subscriber-cups>
  </div>
</div>
