import { Component, Input, OnInit } from "@angular/core";
// Servicios propios
import { EkConfigurationControllerService } from "../../../../../services/server/EkConfigurationController.service";
import { ekTestData } from "../../DeviceInterface.type";
import { TableDataColumn } from "../../../../../modules/table-module/TableInterface.type";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { DeviceTypeService } from "../../../../../services/shared/DeviceTypeService.service";
// Variables
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-ek280-test",
  templateUrl: "./ek280-test.component.html",
  styleUrls: ["./ek280-test.component.scss"],
})
export class Ek280TestComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  meterTypeByMask: string;
  testData: ekTestData[];
  readonly DEVICE_BY_COMM = DEVICE_BY_COMM;

// Table
columns: TableDataColumn[] = [
  {
    title: "date",
    data: "fecha_hora_parsed",
    search: "fecha_hora_parsed",
    sort: "fecha_hora",
    date: true,
    visible: true,
  },
  {
    title: "serial-number",
    data: "numero_serie",
    search: "numero_serie",
    sort: "numero_serie",
    visible: true,
  },
  {
    title: "ek-total-uncorrected-volume",
    data: "volumen_sin_corregir_total",
    search: "volumen_sin_corregir_total",
    sort: "volumen_sin_corregir_total",
    visible: true,
  },
  {
    title: "ek-total-corrected-volume",
    data: "volumen_corregido_total",
    search: "volumen_corregido_total",
    sort: "volumen_corregido_total",
    visible: true,
  },
  {
    title: "ek-total-energy",
    data: "energia_total",
    search: "energia_total",
    sort: "energia_total",
    visible: true,
  },
  {
    title: "ek-volume-uncorrected-error",
    data: "volumen_sin_corregir_error",
    search: "volumen_sin_corregir_error",
    sort: "volumen_sin_corregir_error",
    visible: true,
  },
  {
    title: "ek-total-corrected-volume-error",
    data: "volumen_corregido_total_error",
    search: "volumen_corregido_total_error",
    sort: "volumen_corregido_total_error",
    visible: true,
  },
  {
    title: "ek-total-energy-error",
    data: "energia_error_total_error",
    search: "energia_error_total_error",
    sort: "energia_error_total_error",
    visible: true,
  },
  {
    title: "ek-pressure",
    data: "presion",
    search: "presion",
    sort: "presion",
    visible: true,
  },
  {
    title: "ek-temperature",
    data: "temperatura",
    search: "temperatura",
    sort: "temperatura",
    visible: true,
  },
  {
    title: "ek-factor-correction",
    data: "factor_correccion",
    search: "factor_correccion",
    sort: "factor_correccion",
    visible: true,
  },
  {
    title: "ek-compressibility-factor-measurement",
    data: "factor_compresibilidad_medicion",
    search: "factor_compresibilidad_medicion",
    sort: "factor_compresibilidad_medicion",
    visible: true,
  },
  {
    title: "ek-base-compressibility-factor",
    data: "factor_compresibilidad_base",
    search: "factor_compresibilidad_base",
    sort: "factor_compresibilidad_base",
    visible: true,
  },
  {
    title: "ek-not-corrected-flow-total",
    data: "caudal_sin_corregir_total",
    search: "caudal_sin_corregir_total",
    sort: "caudal_sin_corregir_total",
    visible: true,
  },
  {
    title: "ek-corrected-flow-total",
    data: "caudal_corregido_total",
    search: "caudal_corregido_total",
    sort: "caudal_corregido_total",
    visible: true,
  },
  {
    title: "ek-status",
    data: "estado",
    search: "estado",
    sort: "estado",
    visible: true,
  },
  {
    title: "ek-gsm-reception-level",
    data: "nivel_recepcion_GSM",
    search: "nivel_recepcion_GSM",
    sort: "nivel_recepcion_GSM",
    visible: true,
  },
  {
    title: "ek-tension-battery-modem",
    data: "tension_bateria_modem",
    search: "tension_bateria_modem",
    sort: "tension_bateria_modem",
    visible: true,
  },
  {
    title: "ek-remaining-battery-life",
    data: "tiempo_restante_vida_bateria",
    search: "tiempo_restante_vida_bateria",
    sort: "tiempo_restante_vida_bateria",
    visible: true,
  },
];


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EkConfigurationController: EkConfigurationControllerService,
    private DeviceTypeService: DeviceTypeService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.meterTypeByMask = this.DeviceTypeService.getDeviceTypeByMask(
      this.data.tipo,
      this.data.metrologyType,
      this.data.idFabricante ? this.data.idFabricante : this.data.fabricante
    );
    this.getTestData();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Guardado de la configuración
  getTestData(): void {
    this.EkConfigurationController.ekTest(this.data.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let testData = response["body"];
          this.testData = [testData];
        } else {
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      }
    );
  }
}
