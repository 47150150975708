<!-- Spinner de carga -->
<ng-template #noVirtual>
  <div class="supply-network-no-virtual">
    <i class="fas fa-exclamation-triangle"></i>
    {{ "supply-network-constructor-no-virtual" | translate }}
    <i class="fas fa-exclamation-triangle"></i>
  </div>
</ng-template>

<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Título -->
        <div *ngIf="!this.entityVirtual" class="panel-nav">
          <h5>{{ "supply-network-constructor" | translate }}</h5>
        </div>

        <!-- Selector de red -->
        <app-material-select
          class="material-header-select"
          [ngClass]="{ 'supply-network-select-new': networkId == null }"
          [title]="'supply-network-constructor' | translate"
          [bindValue]="'id'"
          [bindLabel]="'name'"
          [selected]="preselectedNetwork"
          [disabled]="gridLoading"
          [data]="networkList"
          (selectedOption)="updateCurrentNetwork($event)"
        ></app-material-select>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>

        <div class="supply-network-info">
          <!-- Importar red -->
          <input
            class="supply-network-file-import"
            type="file"
            #fileInput
            (change)="
              loadNetworkFile(
                FileImportService.handleFileInputKeys(
                  $event.target.files,
                  '.txt'
                )
              )
            "
            accept="txt"
          />
        </div>
      </div>

      <div class="panel-body supply-network-panel">
        <ng-container *ngIf="entityVirtual; else noVirtual">
          <ng-container *ngIf="networkList; else elseBlock">
            <!-- Mover red -->
            <div class="supply-network-grid-move">
              <div>
                <button
                  (click)="moveNetwork(-1, 0)"
                  title="{{ 'supply-network-move-up' | translate }}"
                >
                  <i class="fas fa-arrow-up"></i>
                </button>
              </div>
              <div>
                <button
                  (click)="moveNetwork(0, -1)"
                  title="{{ 'supply-network-move-left' | translate }}"
                >
                  <i class="fas fa-arrow-left"></i>
                </button>
                <button
                  (click)="moveNetwork(1, 0)"
                  title="{{ 'supply-network-move-down' | translate }}"
                >
                  <i class="fas fa-arrow-down"></i>
                </button>
                <button
                  (click)="moveNetwork(0, 1)"
                  title="{{ 'supply-network-move-right' | translate }}"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>

            <!-- Zoom de cuadrícula -->
            <div class="supply-network-grid">
              <button
                title="{{ 'supply-network-zoom-plus' | translate }}"
                (click)="updateZoom(1)"
                [disabled]="gridZoom >= maxGridZoom"
              >
                +
              </button>
              <button
                title="{{ 'supply-network-zoom-minus' | translate }}"
                (click)="updateZoom(-1)"
                [disabled]="gridZoom <= minGridZoom"
              >
                -
              </button>
              <button
                title="{{ 'supply-network-center-grid' | translate }}"
                (click)="centerGrid(true)"
              >
                <i class="fa-solid fa-arrows-to-dot"></i>
              </button>
              <button
                title="{{ 'supply-network-hide-grid' | translate }}"
                (click)="gridHidden = !gridHidden"
              >
                <i class="fa-solid fa-border-none"></i>
              </button>
            </div>

            <div class="supply-network-control">
              <!-- Datos globales de red -->
              <div class="supply-network-global-data">
                <!-- Nombre de red -->
                <mat-form-field
                  appearance="standard"
                  class="supply-network-name"
                >
                  <mat-label>{{ "supply-network-name" | translate }}</mat-label>
                  <input
                    matInput
                    type="text"
                    [(ngModel)]="networkName"
                    placeholder="{{ 'supply-network-name' | translate }}"
                  />
                </mat-form-field>

                <!-- Fechas de referencia -->
                <app-material-date-selector
                  [dateRangeLabel]="'supply-network-reference-date' | translate"
                  [dateRange]="dateRange"
                  (dateRangeUpdated)="updateDateRange($event)"
                ></app-material-date-selector>
              </div>

              <div class="supply-network-pieces-container">
                <!-- Atribución de imágenes -->
                <div class="supply-network-pieces-attribution">
                  <img
                    src="../../../../../assets/img/supply_constructor_icons/flaticon.png"
                  />FLATICON
                </div>
                <!-- Piezas -->
                <div
                  #pieceContainer
                  class="supply-network-pieces"
                  (wheel)="pieceContainerScroll($event)"
                >
                  <div
                    *ngFor="let piece of SUPPLY_BOARD_PIECES"
                    id="{{ piece.id }}"
                    class="supply-network-piece"
                  >
                    <img
                      draggable="true"
                      (dragstart)="updateDraggedPiece(piece)"
                      src="{{ piece.icon }}"
                    />
                    <span>{{ piece.name | translate }}</span>
                  </div>
                </div>
              </div>

              <!-- Datos -->
              <div class="supply-network-data-container">
                <!-- Datos de elemento -->
                <div class="supply-network-data">
                  <div *ngIf="selectedPiece">
                    <!-- Label -->
                    <mat-form-field
                      appearance="standard"
                      class="supply-network-name"
                    >
                      <mat-label>{{ "label" | translate }}</mat-label>
                      <input
                        matInput
                        type="text"
                        [(ngModel)]="selectedPiece.label"
                        placeholder="{{ selectedPiece.name | translate }}"
                      />
                    </mat-form-field>
                    <!-- Contadores de agua y energía -->
                    <div
                      *ngIf="
                        selectedPiece?.id ==
                          SUPPLY_BOARD_PIECE_TYPES.WATER_METER ||
                        selectedPiece?.id == SUPPLY_BOARD_PIECE_TYPES.VALVE ||
                        selectedPiece?.id == SUPPLY_BOARD_PIECE_TYPES.BALANCE
                      "
                    >
                      <!-- Selector de balance -->
                      <app-material-select
                        *ngIf="
                          selectedPiece?.id == SUPPLY_BOARD_PIECE_TYPES.BALANCE
                        "
                        [title]="selectedPiece.name | translate"
                        [bindValue]="'id'"
                        [bindLabel]="'name'"
                        [filter]="true"
                        [selected]="selectedPiece.balance?.id"
                        [data]="balanceList"
                        (selectedOption)="selectedPiece.balance = $event"
                      ></app-material-select>
                      <!-- Selector de contador -->
                      <app-material-select
                        *ngIf="
                          selectedPiece?.id ==
                          SUPPLY_BOARD_PIECE_TYPES.WATER_METER
                        "
                        [title]="selectedPiece.name | translate"
                        [bindValue]="'id'"
                        [bindLabel]="'nroSerie'"
                        [filter]="true"
                        [selected]="selectedPiece.meter?.id"
                        [data]="waterMeterList"
                        (selectedOption)="
                          selectedPiece.meter = $event;
                          getMeterReadings(selectedPiece)
                        "
                      ></app-material-select>
                      <!-- Selector de válvula -->
                      <app-material-select
                        *ngIf="
                          selectedPiece?.id == SUPPLY_BOARD_PIECE_TYPES.VALVE
                        "
                        [title]="selectedPiece.name | translate"
                        [bindValue]="'id'"
                        [bindLabel]="'nroSerie'"
                        [filter]="true"
                        [selected]="selectedPiece.meter?.id"
                        [data]="valveList"
                        (selectedOption)="selectedPiece.meter = $event"
                      ></app-material-select>
                    </div>
                  </div>

                  <!-- Datos de contador -->
                  <div
                    class="supply-network-meter-data"
                    *ngIf="selectedPiece?.meter"
                  >
                    <div class="supply-network-meter-data-row">
                      <div *ngIf="selectedPiece.meter.cups">
                        <span>{{ "CUPS" | translate }}: {{ meter.cups }}</span>
                      </div>
                      <div>
                        <span>{{ "in-network" | translate }}: </span>
                        <i
                          class="fas"
                          [ngClass]="
                            selectedPiece.meter.inNetwork
                              ? 'fa-circle-check'
                              : 'fa-circle-xmark'
                          "
                        ></i>
                      </div>
                      <div>
                        <span>{{ "manufacturer" | translate }}:</span>
                        {{ selectedPiece.meter.fabricanteParsed }}
                      </div>
                      <div>
                        <span>{{ "model" | translate }}:</span>
                        {{ selectedPiece.meter.devTypeParsed }}
                      </div>
                      <div *ngIf="selectedPiece.meter.valveState">
                        <span>{{ "valve-state" | translate }}:</span>
                        {{ selectedPiece.meter.valveStateParsed }}
                      </div>
                    </div>
                  </div>
                  <!-- Datos de balance -->
                  <div
                    class="supply-network-meter-data"
                    *ngIf="selectedPiece?.balance"
                  >
                    <div class="supply-network-meter-data-column">
                      <div>
                        <span>{{ "fathers" | translate }}: </span
                        >{{ selectedPiece.balance.parents }}
                      </div>
                      <div>
                        <span>{{ "readings" | translate }}: </span
                        >{{
                          selectedPiece.balance.parentPercentage != null
                            ? selectedPiece.balance.parentPercentage + "%"
                            : ("unknown" | translate)
                        }}
                        <span
                          *ngIf="selectedPiece.balance.parentPercentage != null"
                          class="supply-network-balance-readings"
                        >
                          ({{ selectedPiece.balance.parentTotalHours }}/{{
                            selectedPiece.balance.totalHours
                          }})</span
                        >
                      </div>
                      <div>
                        <span>{{ "accumulated" | translate }}: </span>
                        {{
                          selectedPiece.balance.loadBalancingContainer
                            ?.parentAcumulatedConsumption != null
                            ? formatNumber(
                                selectedPiece.balance.loadBalancingContainer
                                  .parentAcumulatedConsumption,
                                numberFormat
                              ) + " m³"
                            : ("unknown" | translate)
                        }}
                      </div>
                    </div>
                    <div class="supply-network-meter-data-column">
                      <div>
                        <span>{{ "childs" | translate }}: </span
                        >{{ selectedPiece.balance.childs }}
                      </div>
                      <div>
                        <span>{{ "readings" | translate }}: </span>
                        {{
                          selectedPiece.balance.childPercentage != null
                            ? selectedPiece.balance.childPercentage + "%"
                            : ("unknown" | translate)
                        }}
                        <span
                          *ngIf="selectedPiece.balance.childPercentage != null"
                          class="supply-network-balance-readings"
                        >
                          ({{ selectedPiece.balance.childTotalHours }}/{{
                            selectedPiece.balance.totalHours
                          }})</span
                        >
                      </div>
                      <div>
                        <span>{{ "accumulated" | translate }}: </span>
                        {{
                          selectedPiece.balance.loadBalancingContainer
                            ?.childAcumulatedConsumption != null
                            ? formatNumber(
                                selectedPiece.balance.loadBalancingContainer
                                  .childAcumulatedConsumption,
                                numberFormat
                              ) + " m³"
                            : ("unknown" | translate)
                        }}
                      </div>
                    </div>
                  </div>
                  <!-- Datos de elemento -->
                  <div
                    class="supply-network-meter-data"
                    *ngIf="!selectedPiece?.meter && !selectedPiece?.balance"
                  >
                    {{
                      (selectedPiece
                        ? "supply-network-element-data-unknown"
                        : "supply-network-element-data"
                      ) | translate
                    }}
                  </div>
                  <!-- Localización -->
                  <div
                    class="supply-network-meter-location"
                    *ngIf="selectedPiece?.meter"
                  >
                    <div class="supply-network-meter-location-block"></div>
                    <app-map-controller
                      [mapType]="'meterList'"
                      [mapHeight]="100"
                      [initialZoom]="14"
                      [hideCluster]="true"
                      [disableLegend]="true"
                      [disableMeasure]="true"
                      [hideMenu]="true"
                      [mapOnly]="true"
                      [metersData]="[selectedPiece.meter]"
                      [gatewaysData]="[]"
                    >
                    </app-map-controller>
                  </div>
                </div>
              </div>
            </div>

            <!-- Contenedor de cuadrícula -->
            <div
              #supplyBoard
              class="supply-network-board-container"
              [ngClass]="{
                'supply-network-board-pipe-constructor': pipeConstructorActive
              }"
              (wheel)="
                !gridLoading && !pipeConstructorActive
                  ? updateWheelZoom($event)
                  : null
              "
              (mousedown)="
                !gridLoading && !pipeConstructorActive
                  ? gridStartDragging($event)
                  : null
              "
              (mouseup)="
                !gridLoading && !pipeConstructorActive
                  ? gridStopDragging($event)
                  : null
              "
              (mouseleave)="
                !gridLoading && !pipeConstructorActive
                  ? gridStopDragging($event)
                  : null
              "
              (mousemove)="
                !gridLoading && !pipeConstructorActive
                  ? gridMoveEvent($event)
                  : null
              "
            >
              <!-- Cargando -->
              <div
                class="supply-network-board-container-loading"
                *ngIf="gridLoading"
              >
                <i class="fas fa-spinner fa-pulse"></i>
              </div>
              <!-- Cuadrícula -->
              <table
                *ngIf="supplyBoardCells && cellSize"
                class="supply-network-board"
              >
                <tr
                  *ngFor="let row of supplyBoardCells"
                  class="supply-network-board-cells"
                >
                  <!-- Celda -->
                  <td
                    *ngFor="let cell of row"
                    id="{{ cell.row + '-' + cell.column }}"
                    class="supply-network-board-cell"
                    [ngClass]="{
                      'supply-network-board-cell-without-line': gridHidden
                    }"
                    [ngClass]="{
                      grab: cell.piece,
                      pointer: cell.cellReference
                    }"
                    [draggable]="cell.piece"
                    (drop)="
                      draggedPiece && !cell.piece
                        ? dropPiece($event, cell)
                        : null
                    "
                    (dragstart)="
                      cell.piece
                        ? updateDraggedCellPiece($event, cell.piece)
                        : null
                    "
                    (dragover)="pieceDragover($event, cell)"
                    (contextmenu)="
                      pieceContextmenu(
                        $event,
                        cell.cellReference ? cell.cellReference : cell
                      )
                    "
                    (click)="
                      cell.piece || cell.cellReference
                        ? pieceClick(cell.piece ? cell : cell.cellReference)
                        : resetFocus()
                    "
                    (mouseover)="pipeConstructorActive ? setPipe(cell) : null"
                  >
                    <!-- Menú de pieza -->
                    <div *ngIf="cell.focus" class="supply-network-piece-menu">
                      <div>
                        <!-- Mover arriba -->
                        <button
                          title="{{
                            'supply-network-move-piece-up' | translate
                          }}"
                          (click)="
                            cellBlockFocus = true; moveSelectedPiece(-1, 0)
                          "
                        >
                          <i class="fa-solid fa-arrow-up"></i>
                        </button>
                        <!-- Mover abajo -->
                        <button
                          title="{{
                            'supply-network-move-piece-down' | translate
                          }}"
                          (click)="
                            cellBlockFocus = true; moveSelectedPiece(1, 0)
                          "
                        >
                          <i class="fa-solid fa-arrow-down"></i>
                        </button>
                        <!-- Mover izquierda -->
                        <button
                          title="{{
                            'supply-network-move-piece-left' | translate
                          }}"
                          (click)="
                            cellBlockFocus = true; moveSelectedPiece(0, -1)
                          "
                        >
                          <i class="fa-solid fa-arrow-left"></i>
                        </button>
                        <!-- Mover derecha -->
                        <button
                          title="{{
                            'supply-network-move-piece-right' | translate
                          }}"
                          (click)="
                            cellBlockFocus = true; moveSelectedPiece(0, 1)
                          "
                        >
                          <i class="fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                      <div>
                        <!-- Aumentar tamaño -->
                        <button
                          title="{{ 'supply-network-size-plus' | translate }}"
                          (click)="updatePieceSize(1)"
                          [disabled]="cell.piece.size == 6"
                        >
                          +
                        </button>
                        <!-- Reducir tamaño -->
                        <button
                          title="{{ 'supply-network-size-minus' | translate }}"
                          (click)="updatePieceSize(-1)"
                          [disabled]="cell.piece.size == 1"
                        >
                          -
                        </button>
                        <!-- Mostrar/ocultar tooltip -->
                        <button
                          [title]="
                            (cell.piece.tooltip?.active
                              ? 'table-graph-hide'
                              : 'show-graph'
                            ) | translate
                          "
                          (click)="
                            cell.piece.tooltip.active =
                              !cell.piece.tooltip.active;
                            cell.piece.balance && !cell.piece.tooltip?.graphData
                              ? getBalanceReadings(cell.piece)
                              : null
                          "
                          [disabled]="!cell.piece.tooltip"
                        >
                          <i class="fas fa-chart-line"></i>
                        </button>
                        <!-- Borrar -->
                        <button
                          class="supply-network-piece-menu-warning"
                          title="{{ 'delete' | translate }}"
                          (click)="deletePiece()"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <!-- Tubería -->
                    <div
                      class="supply-network-grid-pipe"
                      *ngIf="cell.pipe"
                      [ngClass]="{
                    'supply-network-grid-pipe-horizontal':
                      cell.pipe == SUPPLY_BOARD_PIECE_TYPES.PIPE_LINE,
                    'supply-network-grid-pipe-curve':
                      cell.pipe == SUPPLY_BOARD_PIECE_TYPES.PIPE_CURVE,
                    'supply-network-grid-pipe-cross-3':
                      cell.pipe == SUPPLY_BOARD_PIECE_TYPES.PIPE_3_CROSS,
                    'supply-network-grid-pipe-cross-4':
                      cell.pipe == SUPPLY_BOARD_PIECE_TYPES.PIPE_4_CROSS,
                    'piece-rotate-90': cell.pipeRotation == 90,
                    'piece-rotate-180': cell.pipeRotation == 180,
                    'piece-rotate-270': cell.pipeRotation == 270,
                  }"
                      (dblclick)="rotatePipe(cell)"
                    ></div>
                    <!-- Menú de tuberías -->
                    <div
                      *ngIf="cell.pipesMenu"
                      class="supply-network-grid-pipes-menu"
                      (mouseleave)="cell.pipesMenu = false"
                    >
                      <div
                        class="pointer"
                        (click)="
                          pipeConstructorActive = !pipeConstructorActive;
                          cell.pipesMenu = false
                        "
                      >
                        <img
                          src="../../../../../assets/img/supply_constructor_icons/pipe_constructor.png"
                        />
                      </div>
                      <div
                        *ngFor="let pipe of SUPPLY_BOARD_PIPES"
                        class="pointer"
                        (click)="cell.pipesMenu = false; cell.pipe = pipe.id"
                      >
                        <img src="{{ pipe.icon }}" />
                      </div>
                      <div
                        class="pointer"
                        (click)="cell.pipe = null; cell.pipesMenu = false"
                      >
                        <i class="fas fa-xmark"></i>
                      </div>
                    </div>
                    <!-- Datos de celda -->
                    <div
                      *ngIf="cell.piece"
                      class="supply-network-board-cell-data"
                      [ngClass]="{
                        'supply-network-board-cell-focus': cell.focus
                      }"
                      [style]="{
                    'width': cellSize * cell.piece.size + 'px',
                    'height': cellSize * cell.piece.size  + 'px',
                  }"
                    >
                      <!-- Signo de movimiento -->
                      <span
                        *ngIf="cell.piece.size > 1 && cell.focus"
                        class="supply-network-board-cell-drag"
                        ><i class="fa-solid fa-up-down-left-right"></i
                      ></span>
                      <!-- Imagen de pieza -->
                      <img
                        src="{{ cell.piece.icon }}"
                        [ngClass]="{
                    'piece-rotate-90': cell.piece.rotation == 90,
                    'piece-rotate-180': cell.piece.rotation == 180,
                    'piece-rotate-270': cell.piece.rotation == 270,
                  }"
                      />
                      <!-- Tooltip -->
                      <div
                        class="supply-network-board-cell-tooltip"
                        [ngClass]="{
                          'supply-network-board-cell-tooltip-expanded':
                            cell.piece.balance,
                          'supply-network-board-cell-tooltip-right':
                            cell.piece.tooltip.position == 1,
                          'supply-network-board-cell-tooltip-expanded-right ':
                            cell.piece.balance &&
                            cell.piece.tooltip.position == 1
                        }"
                        *ngIf="cell.piece.tooltip?.active"
                      >
                        <div class="supply-network-board-cell-tooltip-data">
                          <!-- Rotación de tooltip -->
                          <div
                            class="supply-network-board-cell-tooltip-rotate"
                            (click)="
                              cell.piece.tooltip.position =
                                cell.piece.tooltip.position > 0 ? 0 : 1
                            "
                          >
                            <i class="fa-solid fa-rotate-right"></i>
                          </div>
                          <!-- Label -->
                          <div class="supply-network-board-cell-tooltip-label">
                            {{ cell.piece.label }}
                          </div>
                          <!-- Datos de rendimiento balance -->
                          <div
                            class="supply-network-tooltip-balance-data"
                            *ngIf="cell.piece.balance?.vnr"
                          >
                            <div>
                              <span>{{ "performance" | translate }}: </span>
                              {{
                                cell.piece.balance.loadBalancingContainer
                                  .childsPercentaje
                              }}%
                            </div>
                            <div>
                              <span>{{ "unbilled" | translate }}: </span>
                              {{
                                cell.piece.balance.loadBalancingContainer
                                  .differencePercentaje
                              }}%
                            </div>
                            <div>
                              <span>{{ "vnr" | translate }}: </span>
                              {{ cell.piece.balance.vnr }} m³/h
                            </div>
                          </div>
                          <!-- Gráfica de consumo -->
                          <div *ngIf="cell.piece.tooltip.graphData">
                            <app-graph-mini
                              class="card-graph"
                              [data]="cell.piece.tooltip.graphData"
                              [width]="cell.piece.balance ? 300 : 200"
                              [height]="cell.piece.balance ? 200 : null"
                            >
                            </app-graph-mini>
                          </div>
                          <!-- Título de tooltip -->
                          <div
                            class="supply-network-board-cell-tooltip-title"
                            [ngClass]="{
                              'supply-network-board-cell-tooltip-link':
                                cell.piece.tooltip.link
                            }"
                            (click)="
                              cell.piece.tooltip.link
                                ? goToDevice(
                                    cell.piece.tooltip.link,
                                    cell.piece.tooltip.linkState
                                  )
                                : null
                            "
                          >
                            {{ cell.piece.tooltip.title }}
                            <i
                              *ngIf="cell.piece.tooltip.link"
                              class="fas fa-link"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table></div></ng-container
        ></ng-container>
      </div>
    </div>
  </div>
</section>
