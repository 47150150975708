<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "sim-cards" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Panel de importación -->
        <div class="sim-import">
          <h4>
            <b>{{ "file-import" | translate }} </b>
          </h4>
          <h5 class="sim-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="importSimCards($event)"
          ></app-file-import>

          <app-import-table [importColumns]="importColumns"> </app-import-table>

          <!-- Tabla -->
          <ng-container *ngIf="showTable">
            <hr />
            <app-table-controller
              [tableId]="'simImport'"
              [onlyTable]="true"
              [columns]="importResponseDataColumns"
              [rowNumbers]="true"
              [data]="importResponseData"
            >
            </app-table-controller>
          </ng-container>
        </div>

        <hr />

        <!-- Alta de SIM -->
        <div class="sim-register">
          <h4>
            <b>{{ "sim-register" | translate }} </b>
          </h4>

          <div *ngIf="simList" class="sim-cards-register">
            <div>
              <!-- <label for="iccid">{{ "ICCID" | translate }}</label> -->
              <input
                type="text"
                id="iccid"
                name="iccid"
                [(ngModel)]="newSim"
                placeholder="ICCID..."
              />
            </div>
            <div>
              <label for="api" class="pointer">{{
                "operator" | translate
              }}</label>
              <select name="select" [(ngModel)]="newSimOperator">
                <option value="MATOOMA">Matooma</option>
                <option value="POST">Post</option>
              </select>
            </div>
            <div>
              <input
                class="pointer"
                type="checkbox"
                id="api"
                name="api"
                [(ngModel)]="newSimWithoutApi"
              />
              <label for="api" class="pointer">{{
                "sim-activate-without-api" | translate
              }}</label>
            </div>
            <div>
              <!-- <label for="ip">{{ "IP" | translate }}</label> -->
              <input
                type="text"
                id="ip"
                name="ip"
                [(ngModel)]="newSimIp"
                placeholder="IP..."
                [disabled]="!newSimWithoutApi"
              />
            </div>
            <div>
              <button
                mat-button
                type="button"
                class="mat-button-icon-text"
                (click)="registerSim()"
                title="{{ 'sim-register' | translate }}"
                [disabled]="newSimWithoutApi && !newSimIp"
              >
                <i class="fas fa-save"></i>
                {{ "save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "sim-list" | translate }} </b>
        </h5>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="updateTitle"
          [disabled]="updating"
          [updating]="updating"
          (action)="refreshSimCards()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div class="sim-list">
          <!-- Tabla -->
          <app-table-controller
            [tableId]="'simCards'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="tableMaxReg"
            (tableAction)="tableActions($event.action.name, $event.element)"
            (selectedDataUpdateFlag)="selectedSims = $event"
            [highlightRow]="highlightRow"
            [data]="simList"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
