<div class="panel panel-default">
  <div class="panel-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>
    <h5>
      <b
        >{{ "state" | translate }}:
        {{ meterState[meter?.activate] ? meterState[meter?.activate] : "-" }}
      </b>
    </h5>
  </div>

  <div class="panel-body">
    <!-- Tarjetas de datos -->
    <app-cards-controller
      [cardsId]="'deviceState'"
      [data]="stateCardsData"
      [cardsOrder]="cardsOrder"
      [cardWidth]="30"
      [cardsGap]="1.5"
    >
    </app-cards-controller>

    <!-- Tabla de gateways -->
    <div *ngIf="meterGatewayList" class="meter-detail-gateways">
      <h4
        *ngIf="
          meter?.metrologyType == METROLOGY_TYPE.WATER ||
          meter?.metrologyType == METROLOGY_TYPE.GAS
        "
      >
        <b>{{ "meters-gateways" | translate }}</b>
      </h4>
      <h4
        *ngIf="
          meter?.metrologyType == METROLOGY_TYPE.SENSOR ||
          meter?.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
        "
      >
        <b>{{ "sensors-gateways" | translate }}</b>
      </h4>
      <h4 *ngIf="meter?.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR">
        <b>{{ "une-gateways" | translate }}</b>
      </h4>

      <!-- Tabla -->
      <app-table-controller
        [tableId]="'deviceStateGateways'"
        [export]="false"
        [columns]="meterGatewayColumns"
        [rowNumbers]="meterGatewayRowNumbers"
        [maxReg]="meterGatewayTableMaxReg"
        (tableAction)="meterGatewayTableActions($event)"
        (selectedDataUpdateFlag)="selectedDataUpdate($event)"
        [tableGlobalActions]="tableGlobalActions"
        (tableGlobalAction)="tableGlobalAction($event)"
        [specialFilter]="true"
        [data]="meterGatewayList"
      >
      </app-table-controller>
    </div>
  </div>
</div>
