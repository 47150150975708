<section class="content-header">
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b> {{ "commands-tail" | translate }}</b>
      </h5>

      <!-- Botón actualizar -->
      <app-material-panel-menu
        [onlyButton]="true"
        [title]="'update' | translate"
        [disabled]="loadingCommands"
        [updating]="loadingCommands"
        (action)="getLwMbusCommands()"
      >
      </app-material-panel-menu>
    </div>
    <div class="panel-body">
      <!-- Tabla de comandos -->
      <app-table-controller
        [tableId]="'lwMbusDetailCommand'"
        [columns]="columns"
        [rowNumbers]="true"
        [maxReg]="20"
        (tableAction)="mbusCommandTableActions($event)"
        [tableGlobalActions]="tableGlobalActions"
        (tableGlobalAction)="mbusCommandTableGlobalAction($event.title)"
        (selectedDataUpdateFlag)="tableSelectedData = $event"
        [specialFilter]="true"
        [data]="tableData"
      >
      </app-table-controller>
    </div>
  </div>
</section>
