import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// Servicio propios
import { DomControllerService } from "../../../services/shared/DomControllerService.service";
// Componentes
import { MapControllerComponent } from "../map-controller/map-controller.component";
import { TableControllerComponent } from "../../../modules/table-module/table-controller/table-controller.component";

@Component({
  selector: "app-map-selector",
  templateUrl: "./map-selector.component.html",
  styleUrls: ["./map-selector.component.scss"],
})
export class MapSelectorComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Mapa
  mapType: string;
  @Input() originalDataList: any[];
  @Input() dataList: any[];
  @Input() tableSelectedData: any[];
  @Input() table: TableControllerComponent;
  @Input() filterAttribute: string;
  @Input() anchorToScroll: string;
  @Output() updateData = new EventEmitter<any[]>();
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  gateways: any[] = [];
  meters: any[] = [];
  selectedDevices: any[];


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private DomControllerService: DomControllerService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Visualización de contadores en mapa
  showOnMap(devicetype?:string): void {
    if(devicetype=="gateway"){
      this.mapType = "onlyGateways"
      let gateways = JSON.parse(JSON.stringify(this.tableSelectedData));
      this.gateways = gateways.map((gateway: any) => {
        gateway.selected = false;
        return gateway;
      });
    }
    else{
      this.mapType = "meterList"
      let meters = JSON.parse(JSON.stringify(this.tableSelectedData));
      this.meters = meters.map((meter: any) => {
        meter.selected = false;
        return meter;
      });
    }
    this.DomControllerService.goToElement("#map-panel-anchor");
  }

  // Selección de dispositivos en mapa
  selectDevices(mapSelectedDevices: any): void {
    let selectedDevices: any[] = [];

    let devices = this.mapType=="onlyGateways"?this.gateways:this.meters

    devices.map((device: any) => {
      if (
        mapSelectedDevices.some(
          (selectedDevice: any) => device.id == selectedDevice.id
        )
      ) {
        device.selected = true;
        selectedDevices.push(device);
      } else {
        device.selected = false;
      }
    });


    this.selectedDevices = selectedDevices;
  }

  // Selección individual de dispositivo en mapa
  selectSingleDevice(mapSelectedDevice: any): void {
    let selectedDevice = this.meters.find(
      (meter: any) => meter.id == mapSelectedDevice.id
    );
    selectedDevice.selected = !selectedDevice.selected;
    if (selectedDevice.selected) {
      this.selectedDevices
        ? this.selectedDevices.push(selectedDevice)
        : (this.selectedDevices = [selectedDevice]);
    } else {
      this.selectedDevices.splice(
        this.selectedDevices.findIndex(
          (device: any) => device.id == selectedDevice.id
        ),
        1
      );
    }
    this.mapController.resetMap();
  }
}
