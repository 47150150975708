import { Injectable } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Leaflet
import { icon } from "leaflet";
// Servicio propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Variables de mapa
import { MAP_CONFIG } from "../map-variables/MAP_CONFIG";
import { GATEWAY_STATES } from "../../../interfaces/GatewayGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";

@Injectable({
  providedIn: "root",
})
export class MapGatewayIconService {
  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  // Obtención del icono para los gateways
  getGatewayIcon(mapType: string, gateway: any): any {
    switch (mapType) {
      // ANCHOR Detalle de contador
      case "meterDetail":
        return {
          icon: icon({
            iconUrl: MAP_CONFIG.markerVariables["GATEWAY"].okIconUrl,
            className: gateway.mainGateway
              ? "arsonMax"
              : gateway.otherEntity
              ? "opacityIcon arson"
              : "arson",
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
      // ANCHOR Detalle de CUPS
      case "cupsDetail":
        return {
          icon: icon({
            iconUrl: MAP_CONFIG.markerVariables["GATEWAY"].okIconUrl,
            className: "arson",
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
      // ANCHOR Detalle de gateway
      case "gatewayDetail":
        return {
          icon: icon({
            iconUrl: MAP_CONFIG.markerVariables["GATEWAY"].okIconUrl,
            className:
              gateway.other == true ? "arson opacityIcon " : "arsonMax",
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
      // ANCHOR Sospechas
      case "warnings":
        return {
          icon: icon({
            iconUrl: MAP_CONFIG.markerVariables["GATEWAY"].nodeviceIconUrl,
            className: "arson",
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
      // ANCHOR Gráfica de contadores
      case "meterGraph":
        return {
          icon: icon({
            iconUrl: MAP_CONFIG.markerVariables["GATEWAY"].grayIconUrl,
            className: "arson",
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
      // ANCHOR Por defecto
      default:
        let noDevice =
          mapType == "onlyGateways"
            ? (!gateway.nroMainContadores && !gateway.nroRedundantContadores) ||
              (gateway.nroMainContadores == 0 &&
                gateway.nroRedundantContadores == 0)
            : (!gateway.contadoresPrincipal && !gateway.contadoresRedundante) ||
              (gateway.contadoresPrincipal?.length == 0 &&
                gateway.contadoresRedundante?.length == 0);
        return {
          icon: icon({
            iconUrl: noDevice
              ? MAP_CONFIG.markerVariables["GATEWAY"].nodeviceIconUrl
              : gateway.comunica ||
                (mapType == "coverage" && !gateway.postInstallation)
              ? MAP_CONFIG.markerVariables["GATEWAY"].okIconUrl
              : mapType == "coverage" && gateway.postInstallation
              ? MAP_CONFIG.markerVariables["GATEWAY"].grayIconUrl
              : MAP_CONFIG.markerVariables["GATEWAY"].errorIconUrl,
            className:
              (gateway.pertenece ? "arsonMax" : "arson") +
              " " +
              (gateway.comunica || mapType == "coverage"
                ? "gatewayOk"
                : "gatewayError") +
              " " +
              // Tareas pendientes
              (gateway.inMaintenance &&
              this.SessionDataService.getCurrentProfile() == PROFILES.ARSON
                ? "map-gateway-maintenance"
                : "") +
              // Dañado
              (gateway.state == GATEWAY_STATES.DAMAGED &&
              this.SessionDataService.getCurrentProfile() == PROFILES.ARSON
                ? "map-gateway-broken"
                : "") +
              // En reparación
              (gateway.state == GATEWAY_STATES.REPAIR &&
              this.SessionDataService.getCurrentProfile() == PROFILES.ARSON
                ? "map-gateway-repair"
                : ""),
            iconAnchor: [16, 32],
            popupAnchor: [4, -23],
          }),
          riseOnHover: true,
          autoPan: true,
        };
    }
  }

  // ANCHOR Localizaciones
  getLocationIcon(mapType: string, location: any): any {
    switch (mapType) {
      default:
        return {
          icon: {
            html: MAP_CONFIG.markerVariables["GATEWAY"].locationUrl,
            className:
              "locationIcon" + (location.postInstallation ? " disabled" : ""),
          },
          title: this.translate.instant("location"),
        };
    }
  }
}
