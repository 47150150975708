// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { TableDataColumn } from "../../../../modules/table-module/TableInterface.type";
import { Gateway } from "../../../../interfaces/GatewayGlobalInterface.type";
// Variables
import { GATEWAY_STATES } from "../../../../interfaces/GatewayGlobalInterface.type";

@Component({
  selector: "app-client-entity-gateways",
  templateUrl: "./client-entity-gateways.component.html",
  styleUrls: ["./client-entity-gateways.component.scss"],
})
export class ClientEntityGatewaysComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;

  // Table
  tableMaxReg: number = 100;
  tableData: Gateway[];
  tableDataOriginal: Gateway[];
  orderBy: object = { attribute: "unidadVenta", reverse: false };
  columns: TableDataColumn[] = [
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      visible: true,
    },
    {
      title: "installation",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "totalMetersParsed",
      search: "totalMetersParsed",
      sort: "totalMeters",
      numerical: true,
      visible: true,
    },
    {
      title: "latitude",
      data: "latitudeParsed",
      search: "latitudeParsed",
      sort: "latitude",
      numerical: true,
      visible: true,
    },
    {
      title: "longitude",
      data: "longitudeParsed",
      search: "longitudeParsed",
      sort: "longitude",
      numerical: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.ReloadComponentService.reload();
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    let tableData: Gateway[] = [];
    this.GatewayController.getClientEntityInstalledGateways(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]?.length > 0) {
        tableData = response["body"];
        tableData.forEach((gateway: Gateway) => {
          gateway.totalMeters =
            gateway.nroMainContadores + gateway.nroRedundantContadores;
          gateway.stateParsed = gateway.state
            ? this.translate.instant(GATEWAY_STATES[gateway.state])
            : null;
        });
      }
      this.tableData = tableData;
    });
  }
}
