// @angular
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Subscription } from "rxjs";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { NotificationModalService } from "../../../../modules/notification-module/notification-service/notification-modal.service";
import { ThemeService } from "../../../../themes/theme.service";
import { MatMenuTrigger } from "@angular/material/menu";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../../services/language/LanguageController.service";

@Component({
  selector: "app-navbar-user-dropdown",
  templateUrl: "./navbar-user-dropdown.component.html",
  styleUrls: ["./navbar-user-dropdown.component.scss"],
})
export class NavbarUserDropdownComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() sessionUserName: { name: string; surname: string };
  @Input() sessionProfile: string;
  @Input() languageName: string;
  @Input() allowList: boolean;
  @Input() disableLog: boolean;
  @Input() pendingNotifications: number;
  currentUserId: number;
  originalProfile: string;
  originalProfileSub: Subscription;
  numberFormat: string;
  numberFormatSub: Subscription;
  dateFormat: string;
  recordData: object;
  userRestrictionsActive: boolean = true;
  userSuspicionActive: boolean = false;
  readonly PROFILES = PROFILES;
  readonly LANGUAGE = LANGUAGE;

  @Output() switchLanguageFlag = new EventEmitter<string>();
  @Output() switchNumberFormatFlag = new EventEmitter<string>();
  @Output() switchDateFormatFlag = new EventEmitter<string>();
  @Output() logoutFlag = new EventEmitter<any>();
  @Output() reloginFlag = new EventEmitter<any>();
  @Output() deleteUserLocalSettingsFlag = new EventEmitter<any>();
  @Output() updateUserRestrictionsFlag = new EventEmitter<boolean>();
  @Output() updateUserSuspicionFlag = new EventEmitter<boolean>();

  @ViewChild("arsonUserMenuTrigger") arsonUserMenuTrigger: MatMenuTrigger;
  arsonMenuActive: boolean = false;
  @ViewChild("localUserMenuTrigger") localUserMenuTrigger: MatMenuTrigger;
  localUserMenuActive: boolean = false;
  @ViewChild("themeMenuTrigger") themeMenuTrigger: MatMenuTrigger;
  @ViewChild("userMenuTrigger") userMenuTrigger: MatMenuTrigger;
  userMenuActive: boolean = false;
  cameraMenuActive: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public notificationModal: NotificationModalService,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private theme: ThemeService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentUserId = this.SessionDataService.getCurrentUserId();
    this.originalProfile = this.SessionDataService.getCurrentOriginalProfile();
    this.originalProfileSub =
      this.SessionDataService.getOriginalProfile().subscribe(
        (originalProfile) => (this.originalProfile = originalProfile)
      );
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
    this.numberFormatSub = this.SessionDataService.getNumberFormat().subscribe(
      (numberFormat) => (this.numberFormat = numberFormat)
    );
    this.recordData = {
      fileName: this.SessionDataService.getCurrentAgrupation()?.name,
    };
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.originalProfileSub.unsubscribe();
    this.numberFormatSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Cambio de idioma
  switchLanguage(language: string): void {
    this.switchLanguageFlag.emit(language);
  }

  // Cambio de formato numérico
  switchFormatNumber(format: string): void {
    this.switchNumberFormatFlag.emit(format);
  }

  // Cambio de formato de fecha
  switchFormatDate(format: string): void {
    this.switchDateFormatFlag.emit(format);
  }

  // Logout de usuario
  logout(): void {
    this.logoutFlag.emit();
  }

  relogin(): void {
    this.reloginFlag.emit();
  }

  // Borrado de la configuración local
  deleteUserLocalSettings(): void {
    this.deleteUserLocalSettingsFlag.emit();
  }

  // Actualización del tema de estilos
  updateTheme(theme: string): void {
    this.theme.setTheme(theme);
    this.theme.saveThemeConfig(theme);
  }

  // Actualización de las restricciones de usuario
  updateUserRestrictions(): void {
    this.updateUserRestrictionsFlag.emit(this.userRestrictionsActive);
  }

  // Actualización de visualización de las sospechas
  updateUserSuspicionActive(): void {
    this.updateUserSuspicionFlag.emit(this.userSuspicionActive);
  }

  // Actualización de perfil
  updateProfile(newProfile: string): void {
    this.userRestrictionsActive = true;
    this.updateUserRestrictions();
    this.SessionDataService.sendProfile(newProfile);
  }

  // Apertura de juego
  openGame(): void {
    this.SessionDataService.sendGameActive(true);
    setTimeout(() => this.spinner.show("spinner-hard"), 0);
  }

  // Menús
  closeUserMenu(): void {
    setTimeout(() => {
      if (
        !this.userMenuActive &&
        !this.arsonMenuActive &&
        !this.localUserMenuActive &&
        !this.cameraMenuActive
      ) {
        this.userMenuTrigger?.closeMenu();
      }
    }, 200);
  }
}
