import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
// Interfaces
import { NonceData } from "../../../DeviceInterface.type";

@Component({
  selector: "app-device-state-panel-dialog",
  templateUrl: "./device-state-panel-dialog.component.html",
  styleUrls: ["./device-state-panel-dialog.component.scss"],
})
export class DeviceStatePanelDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de gateway de otra entidad
  allocateExternalGateway(): void {
    this.spinner.show("spinner-hard");
    this.MeterController.saveDropdown(this.data.gatewayToAllocate, [
      this.data.meter.nroSerie,
    ]).subscribe((response) => {
      if (response["code"] == 0) {
        this.data.gatewayToAllocate = null;
        this.ToastService.fireToast(
          "success",
          this.translate.instant("selected-assign")
        );
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
      this.spinner.hide("spinner-hard");
    });
  }

  /***************************************************************************/
  // ANCHOR Nonces
  /***************************************************************************/

  // Propagación de nonces del gateway
  propagateGateway(): void {
    this.ToastService.fireAlertWithCheckbox(
      "warning",
      this.translate.instant("propagate-gateway-question"),
      this.translate.instant("relaunch-frames")
    ).then((userInput) => {
      if (userInput != null) {
        let data: NonceData = {
          relaunchFrames: userInput == 1,
          devNonce: this.data.nonceTable.devNonceReaded,
          appNonce: this.data.nonceTable.appNonceReaded,
          fcnt: 0,
          gatewayList: [],
        };
        this.MeterController.noncePropagate(this.data.meter.id, data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("propagate-sucessfull")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Corrección de nonces del gateway
  fixGateway(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("fix-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: any = {
          devNonce: this.data.nonceTable.devNonceBbdd,
          appNonce: this.data.nonceTable.appNonceBbdd,
          fcnt: 0,
          gatewayList: [this.data.selectedGateway],
        };
        this.MeterController.noncePropagate(this.data.meter.id, data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("fix-sucessful")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Propagación de nonces del servidor
  propagateServer(): void {
    this.ToastService.fireAlertWithCheckbox(
      "warning",
      this.translate.instant("propagate-server-question"),
      this.translate.instant("relaunch-frames")
    ).then((userInput) => {
      if (userInput != null) {
        let data: any = {
          relaunchFrames: userInput == 1,
          devNonce: this.data.nonceTable.devNonceBbdd,
          appNonce: this.data.nonceTable.appNonceBbdd,
          fcnt: 0,
          gatewayList: [],
        };
        this.MeterController.noncePropagate(this.data.meter.id, data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("propagate-sucessfull")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Propagación de nonces del gateway
  updateNonces(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("nonce-save-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: NonceData = {
          relaunchFrames: this.data.relaunchFrames,
          devNonce: this.data.devNonce,
          appNonce: this.data.appNonce,
          fcnt: 0,
        };
        this.MeterController.nonceSave(this.data.meter.id, data).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("nonce-save-sucessfull")
              );
              this.SessionDataService.sendReloadPanelFlag();
              this.SessionDataService.sendDialogAction({ action: "close" });
            }
          }
        );
      }
    });
  }

  // Calcular nonces de dispositivo
  calculateNonces(): void {
    let data: any = {
      meterId: this.data.meter.id,
    };
    this.MeterController.findNonces(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("calculate-nonces-success")
        );
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
