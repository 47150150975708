<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "list" | translate }}: {{ cups?.name }}</b>
        </h5>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          *ngIf="tableData"
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newCupsModal()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div class="col-md-12">
          <!-- Tabla -->
          <app-table-controller
            [tableId]="'dataManagementCups'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [maxReg]="maxReg"
            [cupsFilters]="filters"
            [specialFilter]="true"
            [quickFilters]="quickFilters"
            [data]="tableData"
            (selectedDataUpdateFlag)="tableSelectedData = $event"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [tableGlobalActions]="tableGlobalActions"
            (tableGlobalAction)="tableGlobalAction($event.title)"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
