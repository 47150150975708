<section
  class="content-header"
  [ngClass]="{
    'subscriber-content-header': sessionProfile == PROFILES.ABONADO
  }"
>
  <div class="container-fluid">
    <div
      class="panel panel-default"
      [ngClass]="{
        'subscriber-config-panel': sessionProfile == PROFILES.ABONADO
      }"
    >
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5 *ngIf="sessionProfile != PROFILES.ABONADO">
          <b
            ><span *ngIf="userData">{{
              userId != null
                ? ("edit-user" | translate) + ": " + userData.username
                : ("new-user" | translate)
            }}</span></b
          >
        </h5>

        <h5 *ngIf="sessionProfile == PROFILES.ABONADO">
          <b>{{ "configuration" | translate }}</b>
        </h5>
      </div>

      <div
        *ngIf="userData; else elseBlock"
        class="panel-body user-form-body"
        [ngClass]="{
          'subscriber-config-form': sessionProfile == PROFILES.ABONADO
        }"
      >
        <!-- Nombre de usuario -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "user" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.username"
              autocomplete="off"
              placeholder="{{ 'user' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Nombre -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.name"
              placeholder="{{ 'name' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Apellido -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "surname" | translate }}</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="userData.surname"
              placeholder="{{ 'surname' | translate }}..."
              required
            />
          </mat-form-field>
        </div>

        <!-- Teléfono -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "phone" | translate }}</mat-label>
            <input
              matInput
              type="tel"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [(ngModel)]="userData.phoneNumber"
              placeholder="{{ 'phone' | translate }}..."
              (change)="checkPhoneSMS()"
            />
          </mat-form-field>
        </div>

        <!-- Teléfono2 -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>{{ "phone" | translate }} 2</mat-label>
            <input
              matInput
              type="tel"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              [(ngModel)]="userData.phoneNumber2"
              placeholder="{{ 'phone' | translate }}..."
              (change)="checkPhoneSMS()"
            />
          </mat-form-field>
        </div>

        <!-- Email -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              [(ngModel)]="userData.email"
              placeholder="Email..."
              (change)="checkEmail()"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <!-- Contraseña -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>
              {{
                userId == null
                  ? ("password" | translate)
                  : ("new-password" | translate)
              }}</mat-label
            >
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              [(ngModel)]="userData.password"
              [maxLength]="20"
              [minLength]="10"
              autocomplete="new-password"
              placeholder="{{
                userId == null
                  ? ('password' | translate) + '...'
                  : ('new-password' | translate) + '...'
              }}"
              title="{{
                userId != null
                  ? ('password-info' | translate)
                  : ('password-requeriment' | translate)
              }}"
              [disabled]="userData.profileText == PROFILES.PERMANENT_TOKEN"
              (keyup)="avoidPassword = false"
              required
            />
          </mat-form-field>
          <!-- Aclaración de contraseña -->
          <div class="user-form-password-icons">
            <i
              class="fas fa-info-circle pointer"
              (click)="showPasswordRequeriments = !showPasswordRequeriments"
            ></i>
            <span
              class="user-form-password-requeriments"
              *ngIf="showPasswordRequeriments"
              >{{ "password-requeriment" | translate }}</span
            >
            <i
              class="fas fa-eye pointer"
              (click)="showPassword = !showPassword"
            ></i>
          </div>
        </div>

        <!-- Repetir contraseña -->
        <div class="user-form-attribute">
          <mat-form-field appearance="standard">
            <mat-label>
              {{
                userId == null
                  ? ("password" | translate)
                  : ("password-repeat" | translate)
              }}</mat-label
            >
            <input
              matInput
              [type]="showPassword ? 'text' : 'password'"
              [(ngModel)]="repeatedPassword"
              [maxLength]="20"
              [minLength]="10"
              autocomplete="off"
              placeholder="{{
                userId == null
                  ? ('password' | translate) + '...'
                  : ('password-repeat' | translate) + '...'
              }}"
              [disabled]="
                avoidPassword ||
                userData.profileText == PROFILES.PERMANENT_TOKEN
              "
              required
            />
          </mat-form-field>
        </div>

        <!-- Idioma -->
        <div class="user-form-attribute">
          <app-material-select
            [fullWidth]="true"
            [title]="'language' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="languageInitialSelection"
            [data]="languageSelect"
            (selectedOption)="
              userData.language = $event?.value; languageUpdated = true
            "
          ></app-material-select>
        </div>

        <!-- Perfil -->
        <div
          class="user-form-attribute"
          *ngIf="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION ||
            sessionProfile == PROFILES.ADMIN_INSTALLATION
          "
        >
          <app-material-select
            #profileSelect
            [fullWidth]="true"
            [title]="'profile' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="profileInitialSelection"
            [data]="loggedUserData?.profileList"
            (selectedOption)="resetSelectors(); changeProfile($event)"
            [required]="true"
          ></app-material-select>
        </div>

        <!-- Token -->
        <div
          *ngIf="userId && userData.profileText == PROFILES.PERMANENT_TOKEN"
          class="user-form-attribute"
        >
          <mat-form-field appearance="standard">
            <mat-label>Token</mat-label>
            <input
              matInput
              [type]="'text'"
              [(ngModel)]="userData.permanent_token"
              placeholder="Token"
              title="Token"
              disabled
            />
          </mat-form-field>
        </div>

        <div class="user-form-attribute"></div>

        <!-- Controles -->
        <div class="user-form-control">
          <!-- Checkbox de notificaciones -->
          <div class="user-form-checkbox">
            <!-- Notificaciones email -->
            <div
              class="user-form-notifications"
              *ngIf="sessionProfile != PROFILES.ABONADO"
            >
              <mat-checkbox
                [(ngModel)]="userData.notifications"
                (change)="checkEmailNotifications()"
                [disabled]="!userData.email || emailNotificationsDisabled"
                (click)="
                  checkUserProfile($event, userData.notifications, 'email')
                "
                >{{ "notifications-on" | translate }}</mat-checkbox
              >
            </div>
            <!-- Notificaciones sms -->
            <div
              class="user-form-notifications"
              *ngIf="sessionProfile != PROFILES.ABONADO"
            >
              <mat-checkbox
                [checked]="smsNotifications"
                [(ngModel)]="smsNotifications"
                (click)="checkUserProfile($event, smsNotifications, 'sms')"
                (change)="checkSmsNotifications()"
                [disabled]="
                  (!userData.phoneNumber && !userData.phoneNumber2) ||
                  smsNotificationsDisabled
                "
                >{{ "notifications-sms" | translate }}</mat-checkbox
              >
            </div>
            <!-- Telecontrol -->
            <div
              *ngIf="sessionProfile != PROFILES.ABONADO"
              class="user-form-notifications"
            >
              <mat-checkbox
                [(ngModel)]="userData.isTlc"
                [disabled]="
                  userData.profileText == PROFILES.ABONADO ||
                  userData.profileText == PROFILES.TELEMEDIDA ||
                  userData.isTelemedida
                "
                (change)="
                  userData.isTlc ? (userData.isTelemedida = false) : null
                "
                >{{ "telecontrol" | translate }}</mat-checkbox
              >
            </div>
            <!-- Telemedida -->
            <!-- <div
              *ngIf="
                userData.profileText == PROFILES.ADMIN_ENTIDAD &&
                (sessionProfile == PROFILES.ARSON ||
                  (currentEntity?.client == PROD_CLIENT_LIST.NORTEGAS &&
                    (sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      (sessionProfile == PROFILES.ADMIN_ENTIDAD && isTlm))))
              "
              class="user-form-notifications"
            > -->
            <div
              *ngIf="
                userData.profileText == PROFILES.ADMIN_ENTIDAD &&
                sessionProfile == PROFILES.ARSON
              "
              class="user-form-notifications"
            >
              <mat-checkbox
                [(ngModel)]="userData.isTelemedida"
                [disabled]="userData.isTlc"
                (change)="
                  userData.isTelemedida ? (userData.isTlc = false) : null;
                  sessionProfile == PROFILES.ARSON
                    ? updateSelectedEntities()
                    : null
                "
                >{{ "telemetry" | translate }}</mat-checkbox
              >
            </div>
          </div>
          <!-- Botones -->
          <div class="user-form-buttons">
            <!-- Actualizar -->
            <button
              mat-button
              class="mat-button-icon-text"
              [disabled]="
                !userData.name ||
                !userData.surname ||
                !userData.username ||
                (!avoidPassword &&
                  (!userData.password ||
                    userData.password?.length < 10 ||
                    userData.password?.length > 20 ||
                    userData.password != repeatedPassword))
              "
              (click)="saveUserData()"
            >
              <i class="fas fa-save"></i>
              {{ "save" | translate }}
            </button>
            <!-- Reiniciar -->
            <button
              mat-button
              class="mat-button-icon-text"
              (click)="reset($event)"
            >
              <i class="fas fa-sync-alt"></i>
              {{ "restart" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Gestión de permisos -->
    <div
      class="panel panel-default"
      *ngIf="
        userData.profileText &&
        (sessionProfile == PROFILES.ARSON ||
          sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          sessionProfile == PROFILES.ADMIN_CLIENTE ||
          sessionProfile == PROFILES.ADMIN_AGRUPACION ||
          sessionProfile == PROFILES.ADMIN_INSTALLATION)
      "
    >
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "profile-management" | translate }}</b>
        </h5>
      </div>

      <!-- Lista de entidades -->
      <div class="panel-body user-form-body">
        <div
          class="user-form-select"
          *ngIf="
            userData.profileText != PROFILES.ARSON &&
            userData.profileText != PROFILES.ABONADO &&
            userData.profileText != PROFILES.ADMIN_CLIENTE &&
            userData.profileText != PROFILES.USUARIO_CLIENTE &&
            userData.profileText != PROFILES.TELEMEDIDA &&
            !userData.isTelemedida
          "
        >
          <app-material-select
            #entitySelect
            [multiple]="
              userData.profileText == PROFILES.AQUACITY ||
              userData.profileText == 'ADMIN_AGRUPACION' ||
              userData.profileText == PROFILES.USUARIO_AGRUPACION
                ? false
                : true
            "
            [clearable]="
              userData.profileText == PROFILES.AQUACITY ||
              userData.profileText == 'ADMIN_AGRUPACION' ||
              userData.profileText == PROFILES.USUARIO_AGRUPACION
                ? false
                : true
            "
            [filter]="true"
            [virtual]="true"
            [fullWidth]="true"
            [title]="'entities' | translate"
            [bindValue]="'entityId'"
            [bindLabel]="'entityName'"
            [selected]="entityInitialSelection"
            [data]="loggedUserData?.entityList"
            (selectedOption)="
              userData.selectedEntities = $event;
              setAgrupationsDropdown();
              userData.profileText != PROFILES.AQUACITY &&
              userData.profileText != 'ADMIN_AGRUPACION' &&
              userData.profileText != PROFILES.USUARIO_AGRUPACION
                ? updateSelectedAgrupations(true)
                : updateSelectedAgrupations(false)
            "
            [disabled]="
              userData.profileText == PROFILES.TELEMEDIDA ||
              userData.isTelemedida
            "
            [required]="userId == null"
          ></app-material-select>
        </div>

        <!-- Lista de agrupaciones -->
        <div
          class="user-form-select"
          *ngIf="
            userData.profileText != PROFILES.ARSON &&
            userData.profileText != PROFILES.ADMIN_ENTIDAD &&
            userData.profileText != PROFILES.USUARIO_ENTIDAD &&
            userData.profileText != PROFILES.API &&
            userData.profileText != PROFILES.ADMIN_CLIENTE &&
            userData.profileText != PROFILES.USUARIO_CLIENTE &&
            userData.profileText != PROFILES.ABONADO &&
            userData.profileText != PROFILES.ADMIN_INSTALLATION &&
            userData.profileText != PROFILES.TELEMEDIDA &&
            !userData.isTelemedida
          "
        >
          <app-material-select
            #agrupationSelect
            [multiple]="
              userData.profileText == PROFILES.AQUACITY ||
              userData.profileText == PROFILES.USUARIO_AGRUPACION
                ? false
                : true
            "
            [clearable]="
              userData.profileText == PROFILES.AQUACITY ||
              userData.profileText == PROFILES.USUARIO_AGRUPACION
                ? false
                : true
            "
            [filter]="true"
            [fullWidth]="true"
            [disableAllSelection]="true"
            [title]="'agrupations' | translate"
            [bindValue]="'id'"
            [bindLabel]="'descripcion'"
            [bindHideSelection]="'allAgrupation'"
            [selected]="agrupationInitialSelection"
            [data]="agrupationDropdownItems"
            (selectedOption)="
              userData.selectedAgrupations = $event;
              userData.profileText != PROFILES.AQUACITY &&
              userData.profileText != PROFILES.USUARIO_AGRUPACION
                ? checkAllAgrupations()
                : null
            "
            [required]="userId == null"
          ></app-material-select>
        </div>

        <!-- Lista de clientes -->
        <div
          class="user-form-select"
          *ngIf="
            userData.profileText == PROFILES.ADMIN_CLIENTE ||
            userData.profileText == PROFILES.USUARIO_CLIENTE ||
            (sessionProfile == PROFILES.ARSON &&
              (userData.profileText == PROFILES.TELEMEDIDA ||
                userData.isTelemedida))
          "
        >
          <app-material-select
            #clientSelect
            [filter]="true"
            [fullWidth]="true"
            [title]="'clients' | translate"
            [bindValue]="'clientId'"
            [bindLabel]="'clientName'"
            [selected]="clientInitialSelection"
            [data]="loggedUserData?.clientList"
            (selectedOption)="userData.selectedClients = $event?.clientId"
            [required]="userId == null"
          ></app-material-select>
        </div>

        <!-- Lista de cups -->
        <div
          class="user-form-select"
          *ngIf="userData.profileText == PROFILES.ABONADO"
        >
          <app-material-select
            #cupsSelect
            [multiple]="true"
            [clearable]="true"
            [filter]="true"
            [fullWidth]="true"
            [title]="cups ? cups.name : 'CUPS'"
            [bindValue]="'id'"
            [bindLabel]="'clave'"
            [selected]="cupsInitialSelection"
            [data]="cupsDropdownItems"
            (selectedOption)="userData.selectedCups = $event"
            [required]="userId == null"
          ></app-material-select>
        </div>

        <!-- Alertas -->
        <div
          class="alert alert-info user-form-alert"
          *ngIf="userData.profileText == PROFILES.ARSON"
        >
          <b>{{ "information" | translate }}:</b>
          <span> </span>
          {{ "agrupation-entity-included" | translate }}
        </div>
        <div
          class="alert alert-info user-form-alert"
          *ngIf="
            (userData.profileText == PROFILES.ADMIN_ENTIDAD ||
              userData.profileText == PROFILES.USUARIO_ENTIDAD ||
              userData.profileText == PROFILES.API ||
              userData.profileText == PROFILES.ADMIN_INSTALLATION) &&
            userData.profileText != PROFILES.TELEMEDIDA &&
            !userData.isTelemedida
          "
        >
          <b>{{ "information" | translate }}:</b>
          <span> </span>
          {{ "agrupation-included" | translate }}
        </div>
        <div
          class="alert alert-info user-form-alert"
          *ngIf="
            userData.profileText == PROFILES.TELEMEDIDA || userData.isTelemedida
          "
        >
          <b>{{ "information" | translate }}:</b>
          <span> </span>
          {{ "entity-included" | translate }}
        </div>
      </div>
    </div>
  </div>
</section>
