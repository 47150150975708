// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { AgrupationControllerService } from "../../../../../services/server/AgrupationController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { AgrupationLog } from "../../AgrupationInterface.type";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meteruserlog",
  templateUrl: "./agrupation-log-user.component.html",
  styleUrls: ["./agrupation-log-user.component.scss"],
})
export class AgrupationUserLogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Tabla
  tableData: AgrupationLog[] = [];
  tableSelectedData: AgrupationLog[];
  from: string;
  to: string;
  tableMaxReg: number = 20;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  columns: (TableActionColumn | TableDataColumn)[];
  exportFileName: string =
    this.translate.instant("user-log") + " " + moment().format("ll");
  loading: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AgrupationController: AgrupationControllerService,
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setColumns();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obención de los datos de la tabla
  getData(from: string, to: string): void {
    this.loading = true;
    this.from = from;
    this.to = to;
    this.AgrupationController.getAgrupationUserLog(
      this.currentAgrupation.id,
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          let userLogList: AgrupationLog[] = response["body"];
          userLogList.forEach((log: AgrupationLog) => {
            log.eventTypeParsed =
              log.eventType != null
                ? this.translate.instant("UserEvent" + log.eventType)
                : "-";
            log.responseCommandParsed =
              log.responseCommand != null
                ? this.translate.instant("httpError" + log.responseCommand)
                : "-";
          });
          this.tableData = userLogList;
        } else {
          this.tableData = [];
        }
        this.loading = false;
      }
    });
  }

  // Seteo de las columnas
  setColumns(): void {
    this.columns = [
      {
        title: "Gateway",
        data: "gwUnidadVenta",
        search: "gwUnidadVenta",
        sort: "gwUnidadVenta",
        visible: this.sessionProfile == PROFILES.ARSON,
      },
      {
        title: "device",
        data: "meterNroSerie",
        search: "meterNroSerie",
        sort: "meterNroSerie",
        visible: true,
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "event",
        data: "eventTypeParsed",
        search: "eventTypeParsed",
        sort: "eventTypeParsed",
        visible: true,
      },
      {
        title: "details",
        data: "eventDetails",
        search: "eventDetails",
        sort: "eventDetails",
        visible: true,
      },
      {
        title: "command-response",
        data: "responseCommandParsed",
        search: "responseCommandParsed",
        sort: "responseCommandParsed",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
    ];
  }
}
