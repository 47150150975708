<!-- Modal de cambio de entidad -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 *ngIf="data?.action == 'saveSelection'" class="dialog-title">
      {{ "save-selection" | translate }}
    </h5>
    <h5 *ngIf="data?.action == 'changeLocation'" class="dialog-title">
      {{ "change-location" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <mat-form-field
      *ngIf="data?.action == 'saveSelection'"
      appearance="standard"
    >
      <mat-label>{{ "name" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="selectionName"
        placeholder="{{ 'name' | translate }}..."
        (keyup.enter)="saveSelection()"
        required
      />
    </mat-form-field>

    <div
      *ngIf="data?.action == 'changeLocation' && mapLocation"
      class="meter-assigned-change-location"
    >
      <!-- Mapa de contador -->
      <app-map-controller
        [mapType]="mapType"
        [mapHeight]="mapHeight"
        [initialZoom]="initialZoom"
        [disableLegend]="true"
        [disableMeasure]="true"
        (meterNewLocation)="setDeviceNewLocation($event)"
        [disableCluster]="true"
        [searchAdress]="true"
        [hideMenu]="true"
        [gatewaysData]="[]"
        [metersData]="[mapLocation]"
      >
      </app-map-controller>

      <div class="device-detail-location-buttons">
        <div>
          <!-- Latitud -->
          <mat-form-field
            class="activation-dialog-input-number"
            appearance="standard"
          >
            <mat-label>{{ "latitude" | translate }}</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="mapLocation.latitude"
              step="0.01"
              min="-90"
              max="90"
              placeholder="{{ 'latitude' | translate }}..."
              (change)="updateMap()"
            />
          </mat-form-field>

          <!-- Longitud -->
          <mat-form-field
            class="activation-dialog-input-number"
            appearance="standard"
          >
            <mat-label>{{ "longitude" | translate }}</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="mapLocation.longitude"
              step="0.01"
              min="-180"
              max="180"
              placeholder="{{ 'longitude' | translate }}..."
              (change)="updateMap()"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="dialog-footer">
        <button
          mat-button
          class="mat-button-icon-text"
          [disabled]="
            (data?.action == 'saveSelection' &&
              (!selectionName || selectionName == '')) ||
            (data?.action == 'changeLocation' &&
              (mapLocation.latitude == null ||
                mapLocation.longitude == null ||
                mapLocation.latitude > 90 ||
                mapLocation.latitude < -90 ||
                mapLocation.longitude > 180 ||
                mapLocation.longitude < -180))
          "
          (click)="
            data?.action == 'saveSelection'
              ? saveSelection()
              : saveNewLocation()
          "
        >
          <i class="fas fa-save"></i>
          {{ "save" | translate }}
        </button>
        <button
          mat-button
          (click)="SessionDataService.sendDialogAction({ action: 'close' })"
        >
          {{ "close" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
