// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { AlarmControllerService } from "../../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componente
import { AlarmsListComponent } from "../../../alarms/alarms-configuration/alarms-table/alarms-table.component";
import { AlarmsDialogComponent } from "../../../alarms/alarms-configuration/alarms-dialog/alarms-dialog.component";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  AlarmConfiguredDevice,
  AlarmStateData,
  AlertsUpdate,
  DeviceAlarmChecks,
  DeviceAlarm,
} from "../../../alarms/AlarmInterface.type";

@Component({
  selector: "app-alarmconfiguration",
  templateUrl: "./cups-alarms-configuration.component.html",
  styleUrls: ["./cups-alarms-configuration.component.scss"],
})
export class AlarmsCUPSConfigurationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionLanguage: string;

  // Alarmas
  cups: string;
  cupsId: number;
  devicesList: DeviceAlarm[];
  devicesValve: boolean = true;
  configuredDevices: AlarmConfiguredDevice[];
  // configuredCups: any[];
  alarmsType: string = "cups";
  @ViewChild(AlarmsListComponent) alarmsList: AlarmsListComponent;
  saveButtonTittle: string = this.translate.instant("save");
  saveButtonIcon: string = "fas fa-save";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/
  constructor(
    private AlarmController: AlarmControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/
  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.cups = history.state.data;
    this.cupsId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getData();
  }

  getData(): void {
    // Búsqueda de dispositivos configurados
    this.AlarmController.getConfigured(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.configuredDevices = response["body"];

          // Obtención de las alarmas del cups
          this.AlarmController.getCUPSAlarms(this.cupsId).subscribe(
            (response) => {
              if (response["code"] == 0) {
                let devicesList: DeviceAlarm[] = response["body"]["allAlarms"];
                this.devicesValve = response["body"]["haveValveDevices"];

                devicesList.forEach((device: DeviceAlarm) => {
                  let manufacturer: {
                    manufacturerText: string;
                    deviceText: string;
                  } = this.ManufacturerService.getManufacturer(
                    device?.fabricante?.toString(),
                    device?.devType?.toString(),
                    this.sessionLanguage
                  );
                  device.manufacturer =
                    manufacturer.manufacturerText +
                    (manufacturer.deviceText
                      ? " - " + manufacturer.deviceText
                      : "");
                  device.configured = false;

                  // Creación del campo checks asociado a los códigos de alarma del dispositivo
                  device.checks = Object.keys(device.alarmList).map((key) => {
                    return {
                      id: device.alarmList[key],
                      check: false,
                      open: false,
                    };
                  });

                  // Comprobación de las alarmas de entidad
                  device.checks.forEach((alarm: DeviceAlarmChecks) => {
                    response["body"]["entityAlarms"].forEach(
                      (entityAlarm: AlarmStateData) => {
                        if (
                          alarm.id == entityAlarm.alarmCode &&
                          device.devType == entityAlarm.dev_type &&
                          device.fabricante == entityAlarm.fabricante
                        ) {
                          alarm.check = entityAlarm.isEnabledAlarm;
                          alarm.open = entityAlarm.isCloseValveAlarm;
                        }
                      }
                    );
                  });

                  // Comprobación de los dispositivos configurados
                  this.configuredDevices.forEach(
                    (configuredDevice: AlarmConfiguredDevice) => {
                      if (
                        device.fabricante == configuredDevice.fabricante &&
                        device.devType == configuredDevice.devType
                      ) {
                        device["configured"] = true;
                      }
                    }
                  );

                  device.allAlarmsCheck = !device.checks.find(
                    (alarm: DeviceAlarmChecks) => !alarm.check
                  );
                  device.allValvesCheck = !device.checks.find(
                    (valve: DeviceAlarmChecks) => !valve.open
                  );
                });

                this.devicesList = [
                  ...devicesList.sort((a, b) =>
                    a.manufacturer.localeCompare(b.manufacturer)
                  ),
                ];
              }
            }
          );
        }
      }
    );
  }

  // Mostrar modal de elementos configurados
  showModal(device: DeviceAlarm): void {
    this.MaterialDialogService.openDialog(AlarmsDialogComponent, {
      configuredDevices: this.configuredDevices,
      device: device,
    });
  }

  // Guardar cambios
  saveAlarms(): void {
    this.ToastService
      .fireAlertWithOptions("warning", this.translate.instant("change-alarms"))
      .then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let updatedDevicesList: DeviceAlarm[] =
            this.alarmsList.getUpdatedDevicesList();
          let enabledAlarms: AlarmStateData[] = [];
          let closeValveAlarms: AlarmStateData[] = [];

          updatedDevicesList.map((device: DeviceAlarm) =>
            device.checks.map((alarm: DeviceAlarmChecks) => {
              if (alarm.check) {
                enabledAlarms.push({
                  alarm_code: alarm.id,
                  dev_type: device.devType,
                  fabricante: device.fabricante,
                });
              }
              if (alarm.open) {
                closeValveAlarms.push({
                  alarm_code: alarm.id,
                  dev_type: device.devType,
                  fabricante: device.fabricante,
                });
              }
            })
          );

          let data: AlertsUpdate = {
            claveMaestra: this.cupsId,
            enabledAlarms: enabledAlarms,
            closeValveAlarms: closeValveAlarms,
          };

          this.AlarmController.updateCUPSAlarms(data).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("change-alarms-sucessfull")
              );
            }
          });
        }
      });
  }
}
