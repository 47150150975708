<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "consumption-evolution" | translate }}</b>
        </h5>

        <!-- Selector de rango de fechas -->
        <div class="graph-controller-date-control">
          <app-material-date-selector
            class="material-header-select graph-controller-date-selector"
            [dateRange]="dataInitialDate"
            (dateRangeUpdated)="
              dateRangeSelected = $event; dataLoaded ? loadData() : null
            "
          ></app-material-date-selector>
        </div>

        <!-- Botón de carga de datos -->
        <app-material-panel-menu
          *ngIf="!dataLoaded"
          [onlyButton]="true"
          [icon]="loadDataIcon"
          [title]="loadDataTitle"
          (action)="loadData()"
          [disabled]="dataLoading || !selectedDevices?.length > 0"
        >
        </app-material-panel-menu>

        <!-- Botón de ver evolución -->
        <app-material-panel-menu
          *ngIf="dataLoaded && !evolutionPlaying"
          [onlyButton]="true"
          [icon]="playIcon"
          [title]="playTitle"
          (action)="showEvolution()"
        >
        </app-material-panel-menu>

        <!-- Botón de pausar evolución -->
        <app-material-panel-menu
          *ngIf="dataLoaded && evolutionPlaying"
          [onlyButton]="true"
          [icon]="pauseIcon"
          [title]="pauseTitle"
          (action)="stopEvolution()"
        >
        </app-material-panel-menu>

        <!-- Botón de mapa de selección -->
        <div class="consumption-evolution-selection">
          <app-material-panel-menu
            [onlyButton]="true"
            [icon]="mapIcon"
            [title]="mapTitle"
            (action)="goToSelection()"
            [disabled]="!dataLoaded"
          >
          </app-material-panel-menu>
        </div>
      </div>

      <div #advancedAnalyticsPanel class="panel-body advanced-analytics-panel">
        <!-- Mapa de contadores -->
        <div *ngIf="!dataLoaded" class="consumption-evolution-selection-map">
          <app-map-controller
            *ngIf="mapHeight"
            [freeDrawEnabled]="true"
            [disableMeasure]="true"
            [mapType]="deviceMapType"
            [mapHeight]="mapHeight"
            (selectedDevices)="selectMeters($event)"
            [gatewaysData]="[]"
            [metersData]="deviceData"
          >
          </app-map-controller>
        </div>

        <!-- Mapa de calor -->
        <div #mapContainer *ngIf="dataLoaded" class="consumption-evolution-map">
          <!-- Controles -->
          <div
            *ngIf="
              dataLoaded &&
              heatMapDataByTimestamp &&
              heatMapDataByTimestamp[timestampIndex]
            "
            class="consumption-evolution-controls"
          >
            <!-- Tiempo -->
            <div class="consumption-evolution-timestamp">
              <div>
                <!-- Contador de tiempo -->
                <div class="consumption-evolution-counter">
                  <label for="timestampCounter">
                    <i class="fas fa-calendar"></i>
                    {{ currentTimestampDate }}
                    <i class="fas fa-clock"></i>
                    {{ currentTimestampHour }}
                  </label>
                </div>

                <!-- Velocidad -->
                <div class="consumption-evolution-rate">
                  <label for="radio">
                    {{ "consumption-evolution-rate" | translate }}
                  </label>
                  <select [(ngModel)]="mapRate" (change)="updateMapRate()">
                    <option *ngFor="let rate of rateOptions" value="{{ rate }}">
                      {{ rate }}x
                    </option>
                  </select>
                </div>
              </div>

              <!-- Barra de tiempo -->
              <input
                name="timestampCounter"
                type="range"
                class="pointer"
                min="0"
                [max]="timestampArray?.length - 1"
                step="1"
                [(ngModel)]="timestampIndex"
                (change)="calculateTimestampIndexData()"
              />
            </div>

            <div *ngIf="showRanges" class="advanced-analytics-filters">
              <!-- Valor de consumo máximo -->
              <div class="form-group advanced-analytics-range">
                <label for="consumptionMaxValue">
                  {{ "consumption" | translate }} <=
                  {{ consumptionRangeLabel }} m³
                </label>
                <div class="advanced-analytics-range-limits">
                  <span>{{ heatLayerMinLabel }}</span
                  ><span>{{
                    consumptionMaxValueLabel != null
                      ? consumptionMaxValueLabel
                      : heatLayerMinLabel
                  }}</span>
                </div>
                <input
                  type="range"
                  class="pointer"
                  max="{{ consumptionMaxValue }}"
                  min="{{ heatLayerMin }}"
                  step="0.001"
                  [(ngModel)]="consumptionRange"
                  (change)="updateMapData()"
                  [disabled]="
                    heatMapDataByTimestamp[timestampIndex].length == 0
                  "
                />
                <div class="advanced-analytics-filters-options">
                  <div>
                    <input
                      type="radio"
                      id="filter"
                      name="dataFilter"
                      value="filter"
                      (click)="resetFilter(true)"
                      [disabled]="
                        heatMapDataByTimestamp[timestampIndex].length > 0
                          ? false
                          : true
                      "
                      checked
                    />
                    <label for="filter">{{ "filter" | translate }}</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="reduce"
                      name="dataFilter"
                      value="reduce"
                      (click)="resetFilter(false)"
                      [disabled]="
                        heatMapDataByTimestamp[timestampIndex].length > 0
                          ? false
                          : true
                      "
                    />
                    <label for="reduce">{{ "reduce" | translate }}</label>
                  </div>
                </div>
              </div>

              <!-- Radio -->
              <div class="form-group advanced-analytics-range">
                <label for="radio">
                  Radio {{ radio | number : "1.0-0" }}
                </label>
                <div class="advanced-analytics-range-limits">
                  <span>{{ "min" | translate }}</span
                  ><span>{{ "max" | translate }}</span>
                </div>
                <input
                  type="range"
                  class="pointer"
                  max="25"
                  min="0.000000001"
                  step="any"
                  [(ngModel)]="radius"
                  (change)="updateMapData()"
                  [disabled]="
                    heatMapDataByTimestamp[timestampIndex].length == 0
                  "
                />
                <div class="advanced-analytics-filters-options"></div>
              </div>
            </div>

            <button
              type="button"
              class="consumption-evolution-controls-toggler"
              (click)="showRanges = !showRanges"
            >
              <i *ngIf="showRanges" class="fas fa-caret-down"></i>
              <i *ngIf="!showRanges" class="fas fa-caret-up"></i>
            </button>
          </div>

          <app-map-controller
            *ngIf="
              heatMapDevices &&
                heatMapDataByTimestamp &&
                heatMapDataByTimestamp[timestampIndex];
              else elseBlock
            "
            [metersData]="heatMapDevices"
            [gatewaysData]="[]"
            [mapType]="'standard'"
            [heatLayerData]="heatMapDataByTimestamp[timestampIndex]"
            [mapHeight]="mapHeight"
            [freeDrawEnabled]="true"
            [heatLayerRadius]="radius"
            [heatLayerRange]="consumptionRange"
            [heatLayerMin]="heatLayerMin"
            [heatLayerGradient]="heatLayerGradient"
            [disableCluster]="false"
            [disableLegend]="true"
            [disableMeasure]="true"
            [boundsUpdateDisabled]="boundsUpdateDisabled"
            [recorderActive]="true"
            [recordData]="recordData"
            (recording)="$event ? showEvolution() : stopEvolution()"
            (selectedDevices)="HomeService.goToTable($event, [])"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
