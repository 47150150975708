import { Injectable } from "@angular/core";
import { Clipboard } from "@angular/cdk/clipboard";
import * as FileSaver from "file-saver";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { ToastService } from "./ToastService.service";
// Variables
const CSV_EXTENSION = ".csv";
const CSV_TYPE = "{type: 'text/csv;charset=utf-8'}";

@Injectable({
  providedIn: "root",
})
export class ExportTableService {
  constructor(
    private clipboard: Clipboard,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  // /**
  //  * Creates excel from the table element reference.
  //  *
  //  * @param element DOM table element reference.
  //  * @param fileName filename to save as.
  //  */

  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    let data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  exportToCsv(
    rows: object[],
    fileName: string,
    columns?: string[],
    clipboard?: boolean
  ): string {
    if (!rows || !rows.length) {
      return;
    }
    let separator = ";";
    let keys = Object.keys(rows[0]).filter((k) => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    let csvContent =
      keys.join(separator) +
      "\n" +
      rows
        .map((row) => {
          return keys
            .map((k) => {
              let cell = row[k] === null || row[k] === undefined ? "" : row[k];
              cell =
                cell instanceof Date
                  ? cell.toLocaleString()
                  : cell.toString().replace(/"/g, "''");
              if (cell.search(/("|,|\n)/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join("\n");

    if (clipboard) {
      this.clipboard.copy(csvContent);
      this.ToastService.fireToast(
        "info",
        this.translate.instant("exported-to-clipboard")
      );
    } else {
      this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
    }
  }
}
