// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import { CupsUser } from "../../screens/dashboard/cups/CupsInterface.type";

@Injectable({
  providedIn: "root",
})
export class CupsControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Pantalla principal del CUPS
  main(
    cupsId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/main/" + cupsId + "/from/" + from + "/to/" + to
    );
  }

  // Datos de lecturas de CUPS
  getGraph(
    cupsId: number,
    type: string,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/readings/cups/" +
        cupsId +
        "/type/" +
        type +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Sustitución del contador asociado al CUPS
  replaceMeter(originalDeviceId: number, deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/update-meter/from/" + originalDeviceId + "/to/" + deviceId
    );
  }

  // Obtener el desplegable de los dispositivos que pueden ser asignados al CUPS
  meterDropdown(agrupationId: number, type: string): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/main-assignable/" + agrupationId + "/type/" + type
    );
  }

  // Asignación de un dispositivo a un CUPS según el tipo de dispositivo
  meterAsignate(cupsId: number, deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/assign-device/" + cupsId + "/to/" + deviceId
    );
  }

  // Desasignar un dispositivo de un CUPS
  meterRemove(cupsId: number, deviceId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/deallocate-device/" + deviceId + "/from/" + cupsId
    );
  }

  // Eventos de usuario por cups
  getCupsUserLog(
    cupsId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/user-events/by-cups/" + cupsId + "/from/" + from + "/to/" + to
    );
  }

  // Listado de alarmas de un CUPS
  getCupsAlarms(
    cupsId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/cm/alarms/" + cupsId + "/from/" + from + "/to/" + to
    );
  }

    /***************************************************************************/
  // ANCHOR Ordenes de trabajo
  /***************************************************************************/

  // Guardar orden de trabajo

  createCupsOrder(data: {
    idList: number[];
    type: number;
    comments: string;
    agrupation:number
  }): Observable<object> {
    return this.HttpRequestService.post("/orden/cm/save", data);
  }

  // Listado de órdenes por dispositivo
  getCupsOrders(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/orden/list/cm/" + deviceId);
  }

  // Listado de órdenes por entidad
  getCupsEntityOrders(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/orden/cm/list/entity/" + entityId);
  }

  // Alertas en un cups seleccionado
  getCupsAlerts(
    cupsId: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/alert/history-by-cups/" + cupsId + "/from/" + from + "/to/" + to
    );
  }

  // Sustitución de cups de usuario
  updateSubscriber(data: CupsUser) {
    return this.HttpRequestService.post("/user/replace-cups", data);
  }

  // Listado de CUPS de la entidad
  getEntityCups(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/cm/list/entity/" + entityId);
  }

  // Crear CUPS en la entidad
  createEntityCups(data): Observable<object> {
    return this.HttpRequestService.post("/cm/create", data);
  }

  // Borrar CUPS de la entidad
  deleteEntityCups(cupsIds: number[]): Observable<object> {
    return this.HttpRequestService.post("/cm/delete/", cupsIds);
  }
}
