<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ task?.name }} </b>
        </h5>

        <!-- Botón start -->
        <app-material-panel-menu
          *ngIf="task?.status == 'stop' && task?.progress != 100"
          [onlyButton]="true"
          [icon]="startIcon"
          [title]="startTitle"
          (action)="
            SessionDataService.sendTaskAction({ index: task?.taskIndex, action: 'run' })
          "
        >
        </app-material-panel-menu>

        <!-- Botón stop -->
        <app-material-panel-menu
          *ngIf="task?.status == 'run' && task?.progress != 100"
          [onlyButton]="true"
          [icon]="stopIcon"
          [title]="stopTitle"
          (action)="
            SessionDataService.sendTaskAction({ index: task?.taskIndex, action: 'stop' })
          "
        >
        </app-material-panel-menu>

        <!-- Botón retry -->
        <app-material-panel-menu
          *ngIf="task?.progress == 100"
          [onlyButton]="true"
          [icon]="retryIcon"
          [title]="retryTitle"
          (action)="
            SessionDataService.sendTaskAction({ index: task?.taskIndex, action: 'retry' })
          "
          [disabled]="task.error == 0"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body request-queue-table">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'requestQueue'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [avoidRepaint]="true"
          [highlightRow]="tableHighlightRow"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
