<!-- Desplegable usuario -->
<div class="navbar-top-dropdown-container">
  <!-- Menú -->
  <button
    #userMenuTrigger="matMenuTrigger"
    class="navbar-top-dropdown-button-user pointer"
    [matMenuTriggerFor]="userMenu"
  >
    <div>
      <i class="fa fa-user-circle fa-fw"></i>
      <i
        class="fa fa-bell user-notification"
        [ngClass]="{
          'user-notification-active': pendingNotifications > 0,
          hidden: !pendingNotifications
        }"
      ></i>
    </div>
    <div>
      <span
        [ngClass]="
          sessionUserName?.name?.length > 10
            ? 'navbar-top-dropdown-user-long'
            : 'navbar-top-dropdown-user'
        "
        >{{ sessionUserName?.name }}</span
      >
      <span
        [ngClass]="
          sessionUserName?.surname?.length > 10
            ? 'navbar-top-dropdown-user-long'
            : 'navbar-top-dropdown-user'
        "
        >{{ sessionUserName?.surname }}</span
      >
    </div>
    <span class="caret"></span>
  </button>

  <!-- Menú de usuario -->
  <mat-menu #userMenu="matMenu">
    <span
      (mouseleave)="userMenuActive = false; closeUserMenu()"
      (mouseenter)="userMenuActive = true"
    >
      <!-- ARSON -->
      <button
        *ngIf="originalProfile == 'ARSON' || sessionProfile == PROFILES.ARSON"
        #arsonUserMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="arsonUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-user-lock"></i
        ></mat-icon>
        <span>ARSON</span>
      </button>
      <!-- Capturar pantalla -->
      <button
        #cameraUserMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="cameraUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-video"></i
        ></mat-icon>
        <span>{{ "screen-capture" | translate }}</span>
      </button>
      <!-- Notificaciones -->
      <button
        *ngIf="
          sessionProfile != PROFILES.ADMIN_INSTALLATION &&
          sessionProfile != PROFILES.TELEMEDIDA
        "
        mat-menu-item
        (click)="notificationModal.showNotifications()"
      >
        <mat-icon
          class="material-menu-icon"
          [ngClass]="{ 'user-notification-active': pendingNotifications > 0 }"
          ><i class="fas fa-bell"></i
        ></mat-icon>
        <span>{{ "notifications" | translate }} </span
        ><span *ngIf="pendingNotifications > 0"
          >({{ pendingNotifications }})</span
        >
      </button>
      <!-- Listado de usuarios -->
      <button
        *ngIf="
          allowList &&
          sessionProfile != PROFILES.TELEMEDIDA
        "
        mat-menu-item
        [routerLink]="['/usuarios/listado']"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fa fa-users"></i
        ></mat-icon>
        <span>{{ "user-list" | translate }}</span>
      </button>
      <!-- Log de usuario -->
      <button
        *ngIf="
          !disableLog &&
          sessionProfile != PROFILES.ADMIN_INSTALLATION &&
          sessionProfile != PROFILES.TELEMEDIDA
        "
        mat-menu-item
        [routerLink]="['/agrupacion/log/usuarios']"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-list-alt"></i
        ></mat-icon>
        <span>{{ "user-log" | translate }}</span>
      </button>
      <!-- Configuración de cuenta -->
      <button
        mat-menu-item
        [routerLink]="['/usuarios/formulario/editar/', currentUserId]"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-user-cog"></i
        ></mat-icon>
        <span>{{ "user-configuration" | translate }}</span>
      </button>
      <!-- Configuración local -->
      <button
        #localUserMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="localUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fas fa-cog"></i
        ></mat-icon>
        <span>{{ "user-local-configuration" | translate }}</span>
      </button>
      <hr class="material-menu-separator" />
      <!-- Relogin -->
      <button
        *ngIf="sessionProfile == PROFILES.ARSON"
        mat-menu-item
        (click)="relogin()"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-sign-in-alt"></i
        ></mat-icon>
        <span>{{ "relogin" | translate }}</span>
      </button>
      <!-- Logout -->
      <button mat-menu-item (click)="logout()" class="option-highlighted-red">
        <mat-icon class="material-menu-icon"
          ><i class="fa fa-sign-out-alt"></i
        ></mat-icon>
        <span>{{ "logout" | translate }}</span>
      </button></span
    >
  </mat-menu>

  <!-- Menú de cámara -->
  <mat-menu #cameraUserMenu>
    <span
      (mouseleave)="
        cameraMenuActive = false;
        cameraUserMenuTrigger.closeMenu();
        closeUserMenu()
      "
      (mouseenter)="cameraMenuActive = true"
    >
      <!-- Controles -->
      <app-screen-recorder [recorderType]="'menu'" [recordData]="recordData">
      </app-screen-recorder
    ></span>
  </mat-menu>

  <!-- Menú configuración local -->
  <mat-menu #localUserMenu="matMenu">
    <span
      (mouseenter)="localUserMenuActive = true"
      (mouseleave)="localUserMenuActive = false; closeUserMenu()"
    >
      <!-- Idioma -->
      <button
        #languageMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="languageUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-language"></i
        ></mat-icon>
        <span>{{ "change-language" | translate }}</span>
      </button>
      <!-- Temas -->
      <button
        *ngIf="sessionProfile == PROFILES.ARSON"
        #themeMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="themeUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-palette"></i
        ></mat-icon>
        <span>{{ "themes" | translate }}</span>
      </button>
      <!-- Formato numérico -->
      <button
        #numberFormatMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="numberFormatUserMenu"
      >
        <mat-icon class="material-menu-icon">{{
          numberFormat == LANGUAGE.ESPANOL ? "X,x" : "X.x"
        }}</mat-icon>
        <span>{{ "change-number-format" | translate }}</span>
      </button>
      <!-- Formato de fechas -->
      <button
        #dateFormatMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="dateFormatUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-calendar-day"></i
        ></mat-icon>
        <span>{{ "change-date-format" | translate }}</span>
      </button>
      <hr class="material-menu-separator" />
      <!-- Eliminar perfil local -->
      <button
        mat-menu-item
        (click)="deleteUserLocalSettings()"
        class="option-highlighted-red"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-eraser"></i
        ></mat-icon>
        <span>{{ "user-local-configuration-delete" | translate }}</span>
      </button></span
    >
  </mat-menu>

  <!-- Selector de formato numérico -->
  <mat-menu #numberFormatUserMenu="matMenu">
    <span
      (mouseenter)="localUserMenuActive = true"
      (mouseleave)="
        localUserMenuActive = false;
        numberFormatMenuTrigger.closeMenu();
        closeUserMenu()
      "
    >
      <!-- Europeo -->
      <button mat-menu-item (click)="switchFormatNumber(LANGUAGE.ESPANOL)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/eu-flag.png' }}"
        /></mat-icon>
        <!-- <span
        >{{ "decimal-separator" | translate }}:
        <span class="navbar-decimal-separator">,</span></span
      > -->
        <span
          >XX<span class="navbar-decimal-separator">.</span>XXX<span
            class="navbar-decimal-separator"
            >,</span
          >xx</span
        >
      </button>
      <!-- UK -->
      <button mat-menu-item (click)="switchFormatNumber(LANGUAGE.INGLES)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/uk-flag.png' }}"
        /></mat-icon>
        <!-- <span
        >{{ "decimal-separator" | translate }}:
        <span class="navbar-decimal-separator">.</span></span
      > -->
        <span
          >XX<span class="navbar-decimal-separator">,</span>XXX<span
            class="navbar-decimal-separator"
            >.</span
          >xx</span
        >
      </button></span
    >
  </mat-menu>

  <!-- Selector de formato fecha -->
  <mat-menu #dateFormatUserMenu="matMenu">
    <span
      (mouseenter)="localUserMenuActive = true"
      (mouseleave)="
        localUserMenuActive = false;
        dateFormatMenuTrigger.closeMenu();
        closeUserMenu()
      "
    >
      <!-- Europeo -->
      <button mat-menu-item (click)="switchFormatDate('DD/MM/YYYY')">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/eu-flag.png' }}"
        /></mat-icon>
        {{ "date-europe" | translate }}
      </button>
      <!-- UK -->
      <button mat-menu-item (click)="switchFormatDate('MM/DD/YYYY')">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/usa-flag.png' }}"
        /></mat-icon>
        {{ "date-usa" | translate }}
      </button></span
    >
  </mat-menu>

  <!-- Selector de temas -->
  <mat-menu #themeUserMenu="matMenu">
    <span
      (mouseenter)="localUserMenuActive = true"
      (mouseleave)="
        localUserMenuActive = false; closeUserMenu(); closeUserMenu()
      "
    >
      <!-- Por defecto -->
      <button mat-menu-item (click)="updateTheme('default')">
        <span>{{ "theme-default" | translate }}</span>
      </button>
      <!-- Agua -->
      <button mat-menu-item (click)="updateTheme('water')">
        <span>{{ "theme-water" | translate }}</span>
      </button>
      <!-- Gas -->
      <button mat-menu-item (click)="updateTheme('gas')">
        <span>{{ "theme-gas" | translate }}</span>
      </button>
      <!-- Nortegas -->
      <button mat-menu-item (click)="updateTheme('nortegas')">
        <span>Nortegas</span>
      </button>
      <!-- Oscuro -->
      <!-- <button mat-menu-item (click)="updateTheme('dark')">
  <mat-icon class="material-menu-icon"></mat-icon>
  <span>{{ "theme-dark" | translate }}</span>
</button> --></span
    >
  </mat-menu>

  <!-- Selector de idioma -->
  <mat-menu #languageUserMenu="matMenu">
    <span
      (mouseenter)="localUserMenuActive = true"
      (mouseleave)="
        localUserMenuActive = false;
        languageMenuTrigger.closeMenu();
        closeUserMenu()
      "
    >
      <!-- Castellano -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.ESPANOL)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/spain-flag.png' }}"
        /></mat-icon>
        <span>Castellano</span>
      </button>
      <!-- Inglés -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.INGLES)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/uk-flag.png' }}"
        /></mat-icon>
        <span>English</span>
      </button>
      <!-- Catalán -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.CATALAN)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/cat-flag.png' }}"
        /></mat-icon>
        <span>Catalá</span>
      </button>
      <!-- Francés -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.FRANCES)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/france-flag.png' }}"
        /></mat-icon>
        <span>Français</span>
      </button>
      <!-- Italiano -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.ITALIANO)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/it-flag.png' }}"
        /></mat-icon>
        <span>Italiano</span>
      </button>
      <!-- Griego -->
      <button mat-menu-item (click)="switchLanguage(LANGUAGE.GRIEGO)">
        <mat-icon class="material-menu-icon"
          ><img
            class="user-mat-menu-img"
            src="{{ 'assets/img/languages/el-flag.png' }}"
        /></mat-icon>
        <span>Ελληνικά</span>
      </button></span
    >
  </mat-menu>

  <!-- Menú ARSON -->
  <mat-menu #arsonUserMenu="matMenu">
    <span
      (mouseenter)="arsonMenuActive = true"
      (mouseleave)="arsonMenuActive = false; closeUserMenu()"
    >
      <!-- Cambiar perfil -->
      <button
        #profilesUserMenuTrigger="matMenuTrigger"
        mat-menu-item
        [matMenuTriggerFor]="profilesUserMenu"
      >
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-id-badge"></i
        ></mat-icon>
        <span>{{ "change-profile" | translate }}</span>
      </button>
      <!-- Deshabilitar restricciones de datos -->
      <button
        mat-menu-item
        (click)="
          userRestrictionsActive = !userRestrictionsActive;
          updateUserRestrictions()
        "
      >
        <mat-icon class="material-menu-icon"
          ><i
            [ngClass]="
              userRestrictionsActive ? 'fas fa-lock-open' : 'fas fa-lock'
            "
          ></i
        ></mat-icon>
        <span>
          {{
            userRestrictionsActive
              ? ("disable-restrictions" | translate)
              : ("activate-restrictions" | translate)
          }}</span
        >
      </button>
      <!-- Deshabilitar sospechas -->
      <button
        mat-menu-item
        (click)="
          userSuspicionActive = !userSuspicionActive;
          updateUserSuspicionActive()
        "
      >
        <mat-icon class="material-menu-icon"
          ><i
            [ngClass]="userSuspicionActive ? 'fas fa-eye-slash' : 'fas fa-eye'"
          ></i
        ></mat-icon>
        <span>
          {{
            userSuspicionActive
              ? ("disable-suspicions" | translate)
              : ("activate-suspicions" | translate)
          }}</span
        >
      </button>
      <!-- Juegos -->
      <button mat-menu-item (click)="openGame()">
        <mat-icon class="material-menu-icon"
          ><i class="fas fa-gamepad"></i
        ></mat-icon>
        <span>Arson Games</span>
      </button></span
    >
  </mat-menu>

  <!-- Selector de perfil -->
  <mat-menu #profilesUserMenu="matMenu">
    <span
      (mouseenter)="arsonMenuActive = true"
      (mouseleave)="
        arsonMenuActive = false;
        profilesUserMenuTrigger.closeMenu();
        closeUserMenu()
      "
    >
      <!-- ARSON -->
      <button
        mat-menu-item
        [ngClass]="{ 'navbar-current-profile': sessionProfile == PROFILES.ARSON }"
        (click)="updateProfile('ARSON')"
      >
        <span>{{ "profiles1" | translate }}</span>
      </button>
      <!-- ADMIN_CLIENTE -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.ADMIN_CLIENTE
        }"
        (click)="updateProfile('ADMIN_CLIENTE')"
      >
        <span>{{ "profiles2" | translate }}</span>
      </button>
      <!-- USUARIO_CLIENTE -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.USUARIO_CLIENTE
        }"
        (click)="updateProfile('USUARIO_CLIENTE')"
      >
        <span>{{ "profiles3" | translate }}</span>
      </button>
      <!-- ADMIN_ENTIDAD -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.ADMIN_ENTIDAD
        }"
        (click)="updateProfile('ADMIN_ENTIDAD')"
      >
        <span>{{ "profiles4" | translate }}</span>
      </button>
      <!-- USUARIO_ENTIDAD -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.USUARIO_ENTIDAD
        }"
        (click)="updateProfile('USUARIO_ENTIDAD')"
      >
        <span>{{ "profiles5" | translate }}</span>
      </button>
      <!-- ADMIN_AGRUPACION -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.ADMIN_AGRUPACION
        }"
        (click)="updateProfile('ADMIN_AGRUPACION')"
      >
        <span>{{ "profiles6" | translate }}</span>
      </button>
      <!-- USUARIO_AGRUPACION -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.USUARIO_AGRUPACION
        }"
        (click)="updateProfile('USUARIO_AGRUPACION')"
      >
        <span>{{ "profiles7" | translate }}</span>
      </button>
      <!-- ADMIN_INSTALLATION -->
      <button
        mat-menu-item
        [ngClass]="{
          'navbar-current-profile': sessionProfile == PROFILES.ADMIN_INSTALLATION
        }"
        (click)="updateProfile('ADMIN_INSTALLATION')"
      >
        <span>{{ "profiles12" | translate }}</span>
      </button></span
    >
  </mat-menu>
</div>
