<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="modal-title">{{"pcs-fcv-list" | translate}}</h5>
  </div>
  <!-- Tabla -->
  <div *ngIf="data != null" class="dialog-body">
    <div class="fcvpcsfaults">
      <app-table-controller
      id="pcs-fcv"
      [tableId]="'pcs-fcv'"
      [topPaginationDisabled]="true"
      [columns]="columns"
      [rowNumbers]="rowNumbers"
      [maxReg]="10"
      [data]="tableData"
      [specialFilter]="true"
      >
      </app-table-controller>
    </div>
    <!-- Gráfica circular -->
    <div
      #graphCircle
      *ngIf="data.device?.graphData?.showLeaks && circleChartOptions"
      class="leak-graph-circle leak-graph-circle-initial"
      draggable="true"
      (dragstart)="DragElementService.getDrapgPoint(graphCircle, $event)"
      (dragend)="
        DragElementService.setPosition(
          graphCircle,
          $event,
          'leak-graph-circle-initial',
          '--graph-circle-y',
          '--graph-circle-x'
        )
      "
    >
      <app-graph-controller
        [highchartsOptions]="circleHighchartOptions"
        [noOptionsParse]="true"
        [onlyGraph]="true"
        [data]="circleChartOptions"
      >
      </app-graph-controller>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
