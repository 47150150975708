<!-- Datos de versión -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "last-path-notes" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <ng-container *ngFor="let patch of data.latestPatchInfo">
      <span class="patch-date">{{ patch.date }}</span>
      <div *ngFor="let note of patch.info" class="patch-note">
        <span class="patch-note-title">{{ note.title }}</span>
        <ul class="patch-note-actions">
          <li *ngFor="let action of note.actions">
            {{ action }}
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <!-- Crear tarjeta de petición en Trello -->
    <button
      *ngIf="data.latestPatchInfo.profile == 'ARSON'"
      mat-button
      (click)="createTrelloCard('petition')"
    >
      {{ "trello-petition" | translate }}
    </button>
    <!-- Crear tarjeta de corrección en Trello -->
    <button
      *ngIf="data.latestPatchInfo.profile == 'ARSON'"
      mat-button
      (click)="createTrelloCard('correction')"
    >
      {{ "trello-correction" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
