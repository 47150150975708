import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { EkConfigurationControllerService } from "../../../../../services/server/EkConfigurationController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import { DeviceSim } from "../../DeviceInterface.type";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-ek280-configuration",
  templateUrl: "./ek280-configuration.component.html",
  styleUrls: ["./ek280-configuration.component.scss"],
})
export class Ek280ConfigurationComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  initialSimSelection: number;
  updatedSimList: DeviceSim[];
  readonly PROFILES = PROFILES;
  readonly DEVICE_BY_COMM = DEVICE_BY_COMM;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EkConfigurationController: EkConfigurationControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.showConfigModal();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Visualización del modal de configuración de EK280
  showConfigModal(): void {
    if(this.data.simList){
      this.updatedSimList = this.data.simList.concat({
        id: this.data.editModalData?.simId,
        iccid: this.data.editModalData?.iccid,
      });
      this.initialSimSelection = this.data.editModalData.simId;
    }
  }

  // Guardado de la configuración
  saveConfig(): void {
    this.data.editModalData.ventana1 = parseInt(
      this.data.editModalData.ventana1Parsed.replace(":", "")
    );
    this.data.editModalData.ventana2 = parseInt(
      this.data.editModalData.ventana2Parsed.replace(":", "")
    );
    this.data.editModalData.ventana3 = parseInt(
      this.data.editModalData.ventana3Parsed.replace(":", "")
    );
    let data: any = { ...this.data.editModalData };
    delete data.iccid;
    delete data.ventana1Parsed;
    delete data.ventana2Parsed;
    delete data.ventana3Parsed;

    this.EkConfigurationController.saveEkConfigData(
      this.data.meterId,
      data
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
