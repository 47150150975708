import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { CupsControllerService } from "../../../../../../services/server/CupsController.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";

@Component({
  selector: "app-device-cups-dialog",
  templateUrl: "./device-cups-dialog.component.html",
  styleUrls: ["./device-cups-dialog.component.scss"],
})
export class DeviceCupsDialogComponent implements OnInit{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  //Variables de entrada
  @Input() data: any;
  //-----------------------
  //         VISTA
  //-----------------------
  //Variables para ajustes en la vista
  assignedCups: number;
  saveDisabled: boolean;

  // Variables para mostrar en la vista
  cupsTitleColumn = null;
  cupsDeffinitionColumns = [];
  cupsExtraColumns = [];


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/
  // Punto de inicio de la aplicacion
  ngOnInit(){
    this.separateDataColumns();
  }

  separateDataColumns() {
    // Si 'fcv' existe y es verdadero, asignamos su valor a 'conversionFactor'
    if (this.data.newCups.fcv) {
      this.data.newCups.conversionFactor = this.data.newCups.fcv;
    }

    // Sucesión de nombres comunes
    const columnNames = ["latitude", "longitude"];
    const gasColumnNames = ["conversionFactor", "pcsValue"];

    // Filtrar las columnas 'latitude' y 'longitude', excluyendo null o false
    let latitudeLongitudeColumns = this.data.cupsData.columns.filter(column =>
      columnNames.includes(column.colPosition) &&
      column.colValue !== null && column.colValue !== false
    );

    // Filtrar las columnas 'conversionFactor' y 'pcsValue' si el cliente tiene gas
    let gasColumns = [];
    if (this.SessionDataService.getCurrentClient().gas === true) {
      gasColumns = this.data.cupsData.columns.filter(
        column =>
          gasColumnNames.includes(column.colPosition) &&
          column.colValue !== null && column.colValue !== false
      );
    }

    this.cupsDeffinitionColumns = [...latitudeLongitudeColumns,...gasColumns] // Luego las columnas de gas si es necesario

    // Filtrar la columna de título 'col00'
    this.cupsTitleColumn = this.data.cupsData.columns.find(column => column.colPosition === "col00");

    // Filtrar las columnas extra (excluyendo 'col00', 'col100' y las que están en 'columnNames')
    this.cupsExtraColumns = this.data.cupsData.columns.filter(column =>
      column.colPosition !== "col00" && column.colPosition !== "col100" && !columnNames.includes(column.colPosition) && !gasColumnNames.includes(column.colPosition)
    );

    this.cupsDeffinitionColumns.forEach(column => {
      column.name = this.translate.instant(column.name.toLowerCase());
    });
    // Reemplazar las columnas originales por la nueva ordenada
    this.data.cupsData.columns = [
      this.cupsTitleColumn,
      ...this.cupsDeffinitionColumns,
      ...this.cupsExtraColumns // Y finalmente las columnas extra
    ];
  }

  // Asignación de CUPS existente
  assignCups(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("assign-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.meterAsignate(
          this.assignedCups,
          this.data.meterId
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Edición de CUPS asociado al contador
  editCups(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("edit-extra-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (!this.data.newCups.clave) {
          this.data.newCups.clave = this.data.meter.nroSerie;
        }
        let cleanCups: any = { ...this.data.newCups };
        delete cleanCups.selected;
        delete cleanCups.selectedBis;
        delete cleanCups.cups;
        delete cleanCups.showExtraInfo;
        delete cleanCups.meterLink;
        delete cleanCups.meterNroSerie;
        delete cleanCups.highlightClass;

        this.MeterController.editExtra(cleanCups).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("edit-extra-sucessfull")
            );
            this.SessionDataService.sendUpdateSearchBoxFlag();
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Nuevo CUPS
  newCups(): void {
    let data = {};
    data["clave"] = this.data.newCups.clave;
    data["entity"] = this.SessionDataService.getCurrentEntity()?.id;
    for (let element in this.data.newCups) {
      if (element.includes("col")) {
        data[element] = this.data.newCups[element];
      }
    }
    this.CupsController.createEntityCups(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendUpdateSearchBoxFlag();
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
