import { Component, Input, OnInit } from "@angular/core";
import { SessionDataService } from "../../../../../../../../services/shared/SessionDataService.service";
import { MaterialDialogService } from "../../../../../../../../modules/material-module/material-dialog/material-dialog.service";

@Component({
  selector: "app-meter-log-frames-dialog",
  templateUrl: "./meter-log-frames-dialog.component.html",
  styleUrls: ["./meter-log-frames-dialog.component.scss"],
})
export class MeterLogFramesDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  relaunchOnlyMic: boolean = false;
  relaunchWithAlarms: boolean = false;
  relaunchInitDate: string;
  relaunchInitHour: string;
  relaunchFinalDate: string;
  relaunchFinalHour: string;
  payloadParsed: object;

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data.action == "parser") {
      this.checkParserResult();
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    public dialog: MaterialDialogService,
    public SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Chequeo de resultado de parseador
  checkParserResult(): void {
    let payloadParsed = {};
    for (let key in this.data.payloadParsed) {
      if (typeof this.data.payloadParsed[key] === "object") {
        payloadParsed[key] = "";
        this.getObjectData(payloadParsed, this.data.payloadParsed[key]);
      } else {
        payloadParsed[key] = this.data.payloadParsed[key];
      }
    }
    this.payloadParsed = {
      data: payloadParsed,
      keys: Object.keys(payloadParsed),
    };
  }

  getObjectData(payloadParsed: Object, object: Object): void {
    for (let key in object) {
      if (typeof object[key] === "object") {
        this.getObjectData(payloadParsed, object[key]);
      } else {
        payloadParsed["tab" + key] = object[key];
      }
    }
  }
}
