<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading global-search-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <div class="panel-nav">
          <!-- Filtro -->
          <app-material-select
            class="material-header-select"
            [fullWidth]="true"
            [title]="'user-events' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="eventSelect[0]?.value"
            [data]="eventSelect"
            [filter]="true"
            (selectedOption)="selectedEvent = $event?.value"
          ></app-material-select>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla de usuarios -->
        <div>
          <app-table-controller
            [tableId]="'globalUserEvents'"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [maxReg]="tableMaxReg"
            [specialFilter]="true"
            [dateRangeSelector]="true"
            (dataRequest)="getData($event.from, $event.to)"
            [data]="tableData"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
