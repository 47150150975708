<div
  class="dialog-container"
  [ngClass]="{ 'dialog-container-overflow': data.action.includes('file') }"
>
  <!-- Título del modal -->
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{
        data.action == "file-billing"
          ? ("ek-file-download-billing" | translate)
          : data.action == "file-values"
          ? ("ek-file-download-values" | translate) +
            (data.meter ? ": " + data.meter.nroSerie : "")
          : data.meter.nroSerie +
            (data.meter.rfModule ? " (" + data.meter.rfModule + ")" : "")
      }}
    </h5>
  </div>

  <div class="dialog-body control-telemetry-data" [ngSwitch]="data.action">
    <!-- Información adicional -->
    <div *ngSwitchCase="'info'">
      <app-table-controller
        [tableId]="'controlTelemetryInfo'"
        [export]="true"
        [exportFileName]="exportFileName"
        [columns]="infoColumns"
        [rowNumbers]="true"
        [maxReg]="20"
        [specialFilter]="true"
        [dateRangeSelector]="true"
        [initialDate]="dataInitialDate"
        (dataRequest)="getData($event.from, $event.to)"
        [data]="tableData"
      >
      </app-table-controller>
    </div>

    <!-- Errores -->
    <div *ngSwitchCase="'errors'">
      <app-table-controller
        [tableId]="'controlTelemetryErrors'"
        [columns]="errorColumns"
        [rowNumbers]="true"
        [maxReg]="20"
        [specialFilter]="true"
        [data]="data.meter.errorList"
        [dateRange]="dataInitialDate"
        [dateRangeSelector]="true"
        (dataRequest)="getErrors($event.from, $event.to)"
      >
      </app-table-controller>
    </div>

    <!-- Fichero de facturación  -->
    <div *ngSwitchCase="'file-billing'">
      <input type="radio" name="hour" [(ngModel)]="selectedHour" value="6:00" />
      6 AM
      <input
        type="radio"
        name="hour"
        [(ngModel)]="selectedHour"
        value="11:00"
      />
      11 AM
      <input
        type="radio"
        name="hour"
        [(ngModel)]="selectedHour"
        value="18:00"
      />
      6 PM
      <app-material-date-selector
        (dateRangeUpdated)="dateSelected = $event"
        [singleDatePicker]="true"
      ></app-material-date-selector>
    </div>

    <!-- Fichero de valores -->
    <div *ngSwitchCase="'file-values'">
      <app-material-date-selector
        (dateRangeUpdated)="dateSelected = $event"
      ></app-material-date-selector>
    </div>
  </div>

  <div class="dialog-footer control-modal-footer">
    <button
      *ngIf="data.action.includes('file')"
      mat-button
      (click)="downloadFile()"
    >
      {{ "download" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
