<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "pending-gateways-list" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewaysUnnassigned'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
