import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserStorageLocalService } from "./BrowserStorageServices/BrowserStorageLocalService.service";
import { SessionDataService } from "./SessionDataService.service";
import { SessionData } from "../../interfaces/SessionGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class RedirectToService {
  constructor(
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  // Nueva pestaña
  openNewTab(url: string, urlData?: any): void {
    let updatedSessionData: SessionData = this.BrowserStorageLocalService.getJsonValue("session");
    updatedSessionData["current"]["client"] = this.SessionDataService.getCurrentClient();
    updatedSessionData["current"]["agrupation"] =
      this.SessionDataService.getCurrentAgrupation();
    updatedSessionData["current"]["entity"] = this.SessionDataService.getCurrentEntity();
    updatedSessionData["url"] = url;
    updatedSessionData["urlData"] = urlData;
    this.BrowserStorageLocalService.setJsonValue("session", updatedSessionData);
    window.open(window.location.origin + "/#/" + url, "_blank");
  }
}
