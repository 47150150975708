<section class="content-header">
  <div class="container-fluid">
    <!-- ANCHOR Mapa de cambio de localización -->
    <app-device-change-location
      *ngIf="gateway"
      [device]="gateway"
      [isGateway]="true"
      [changeLocationActive]="changeLocationActive"
      (changeLocationDisableFlag)="changeLocationActive = false"
      (reloadFlag)="ReloadComponentService.reload()"
    >
    </app-device-change-location>
  </div>

  <!-- ANCHOR Panel de gateway -->
  <div class="panel panel-default">
    <!-- Encabezado del panel -->
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>

      <div class="gateway-detail-heading-info">
        <i
          class="fas fa-eye pointer"
          title="{{ 'more-info' | translate }}"
          (click)="showGateway(gateway)"
        ></i>
        <i
          class="fas fa-edit pointer"
          title="{{ 'edit' | translate }}"
          (click)="editGatewayData()"
        ></i>
      </div>

      <h5>
        <div>
          <b
            >{{ "gateway-detail" | translate }}:
            {{
              gateway != null ? gateway.unidadVenta : ("no-data" | translate)
            }}</b
          >
        </div>
        <div>
          {{ gateway?.stateParsed | translate }}
          <span *ngIf="gateway?.inMaintenance" class="gateway-detail-review">
            ({{ "gateway-reset-pending" | translate }})
          </span>
        </div>
      </h5>

      <!-- ANCHOR Menú de opciones del panel -->
      <app-material-panel-menu
        [options]="panelMenuOptions"
        (action)="menuAction($event)"
      ></app-material-panel-menu>
    </div>

    <!-- ANCHOR Panel de tarjetas y mapa -->
    <div class="panel-body">
      <div class="gateway-detail-cards-box">
        <!-- Tarjetas de datos -->
        <app-cards-controller
          [cardsId]="'gatewayDetail'"
          [data]="gatewayCardsData"
          [cardsOrder]="gatewayCardsOrder"
          [cardWidth]="30"
          [cardsGap]="1.5"
        >
        </app-cards-controller>
      </div>

      <!-- Mapa de gateway -->
      <div class="gateway-detail-map">
        <app-map-controller
          [mapType]="mapType"
          [mapHeight]="mapHeight"
          (actionFlag)="mapAction($event.action, $event.data)"
          [boundsWithGateways]="true"
          [activateAllLayers]="activateAllLayers"
          [gatewaysData]="mapGateways"
          [metersData]="mapMeters"
        >
        </app-map-controller>
      </div>
    </div>
  </div>

  <!-- ANCHOR Gráfica -->
  <div class="panel panel-default">
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "graph" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body gateway-detail-graph">
      <div class="gateway-detail-graph-controls">
        <!-- Temperatura y ruido -->
        <div *ngIf="highchartsOptions" class="gateway-detail-graph-buttons">
          <button
            mat-button
            class="mat-button-icon"
            (click)="showTemperature()"
            [disabled]="graphNoiseActive"
          >
            <i
              [ngClass]="
                !graphTemperatureActive
                  ? 'fas fa-thermometer-empty'
                  : 'fas fa-thermometer-half'
              "
            ></i>
            {{
              graphTemperatureActive
                ? ("hide-temperature" | translate)
                : ("show-temperature" | translate)
            }}
          </button>
          <button
            mat-button
            class="mat-button-icon"
            (click)="showNoise()"
            [disabled]="!graphData?.noise"
          >
            <i [ngClass]="graphNoiseActive ? 'fas fa-bolt' : 'fas fa-rss'"></i>
            {{
              graphNoiseActive
                ? ("show-consumption" | translate)
                : ("show-noise" | translate)
            }}
          </button>
        </div>
      </div>

      <!-- Gráfica -->
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [data]="chartOptions"
        [chartConstructor]="chartConstructor"
        [columns]="true"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to)"
        [colorByPoint]="colorByPoint"
      >
      </app-graph-controller>
    </div>
  </div>

  <!-- ANCHOR Panel de selección de contadores -->
  <div
    *ngIf="metersSelectionActive"
    id="gateway-detail-selection-panel"
    class="panel panel-default"
  >
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "select-meters" | translate }} </b>
      </h5>
    </div>
    <div class="panel-body">
      <!-- Contadores -->
      <div class="gateway-detail-selection-control">
        <!-- Contadores en radio -->
        <div class="gateway-detail-selection-option">
          <app-material-select
            [clearable]="true"
            [fullWidth]="true"
            [title]="'meters-radio' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [data]="metersInRangeSelect"
            (selectedOption)="metersRange = $event?.value; getMetersInRange()"
          ></app-material-select>
        </div>
        <!-- Contadores asignables -->
        <div class="gateway-detail-selection-option">
          <app-material-select
            [multiple]="true"
            [clearable]="true"
            [filter]="true"
            [counter]="true"
            [fullWidth]="true"
            [title]="'devices-to-assign' | translate"
            [bindValue]="'id'"
            [bindLabel]="'nroSerie'"
            [selected]="deallocatedMetersSelected"
            [data]="deallocatedMetersList"
            (selectedOption)="updateSelectedFromSelects($event, true)"
          ></app-material-select>
        </div>
        <hr />
        <!-- Contadores redundantes -->
        <div class="gateway-detail-selection-option">
          <app-material-select
            [multiple]="true"
            [clearable]="true"
            [filter]="true"
            [counter]="true"
            [fullWidth]="true"
            [title]="'devices-to-unassign' | translate"
            [bindValue]="'id'"
            [bindLabel]="'nroSerie'"
            [selected]="deallocateRedundantSelected"
            [data]="deallocateRedundantList"
            (selectedOption)="updateSelectedFromSelects($event)"
          ></app-material-select>
        </div>
      </div>

      <div class="gateway-detail-meter-selection">
        <div>
          <!-- Selección por portapapeles -->
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="selectByFile()"
            [disabled]="
              deallocatedMetersList?.length == 0 || showMeterSelectFileColumns
            "
          >
            <i class="fas fa-clipboard"></i
            >{{ "select-by-clipboard" | translate }}
          </button>
          <!-- Coincidencia de columnas -->
          <div
            *ngIf="showMeterSelectFileColumns"
            class="gateway-detail-select-file-columns"
          >
            <span>{{ "select-by-clipboard" | translate }}</span>
            <app-material-select
              [title]="'file-columns' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [data]="meterSelectFileColumns"
              (selectedOption)="meterSelectFileColumnIndex = $event?.value"
            ></app-material-select>
            <app-material-select
              [title]="'table-columns' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [data]="meterSelectColumns"
              (selectedOption)="meterSelectColumnIndex = $event?.value"
            ></app-material-select>
            <mat-checkbox class="mat-option" [(ngModel)]="fileFilterInverted">
              {{ "filter-inverted" | translate }}
            </mat-checkbox>
            <div>
              <button
                mat-button
                class="mat-button-icon"
                (click)="filterSelectByFile()"
                [disabled]="
                  meterSelectColumnIndex == null ||
                  meterSelectFileColumnIndex == null
                "
              >
                {{ "accept" | translate }}
              </button>
              <button
                mat-button
                class="mat-button-icon"
                (click)="showMeterSelectFileColumns = false"
              >
                {{ "cancel" | translate }}
              </button>
            </div>
          </div>

          <!-- Ver mapa -->
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="showSelectionMap(meterSelectionMap)"
          >
            <i class="fas fa-map-marker-alt"></i>
            {{
              selectionMapActive
                ? ("map-hide" | translate)
                : ("map-show" | translate)
            }}
          </button>
          <!-- Guardar -->
          <button
            mat-button
            class="mat-button-icon-text"
            [disabled]="
              (!deallocatedMetersSelected ||
                deallocatedMetersSelected?.length == 0) &&
              (!deallocateRedundantSelected ||
                deallocateRedundantSelected?.length == 0)
            "
            (click)="saveGatewayDevices()"
          >
            <i class="fas fa-save"></i>
            {{ "save" | translate }}
          </button>
          <!-- Cerrar -->
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="metersSelectionActive = false; selectionMapActive = false"
          >
            {{ "close" | translate }}
          </button>
        </div>
      </div>

      <!-- Mapa de contadores -->
      <app-map-controller
        #meterSelectionMap
        *ngIf="selectionMapActive"
        [ngClass]="{ hidden: hideSelectionMap }"
        [mapHeight]="allocateMapHeight"
        [mapType]="allocateMapType"
        [freeDrawEnabled]="true"
        (activeLayersFlag)="mapActiveLayers = $event"
        (selectedDevices)="updateSelectedFromMap()"
        (actionFlag)="updateMeterSingleSelection($event)"
        [gatewaysData]="[gateway]"
        [metersData]="selectionMapMeters"
      >
      </app-map-controller>
    </div>
  </div>

  <!-- ANCHOR Tabla de contadores de gateway -->
  <div
    *ngIf="gateway?.latitude != null && gateway?.longitude != null"
    class="panel panel-default"
  >
    <div class="panel-heading">
      <app-panel-back class="panel-back-link"></app-panel-back>
      <h5>
        <b>{{ "meters-gateway" | translate }}</b>
      </h5>
    </div>

    <div class="panel-body">
      <!-- Tabla de contadores -->
      <div class="gateway-detail-meters">
        <!-- Tabla -->
        <app-table-controller
          [export]="true"
          [exportFileName]="exportFileName"
          [tableId]="'gatewayDetailMetersList'"
          [columns]="gatewayMetersColumns"
          [rowNumbers]="gatewayMetersRowNumbers"
          [maxReg]="gatewayMetersTableMaxReg"
          [quickFilters]="gatewayMetersQuickFilters"
          [quickFiltersExclusion]="gatewayMetersQuickFiltersExclusion"
          (tableAction)="
            gatewayMetersListTableActions($event.action.name, $event.element)
          "
          (extraTableAction)="
            meterGatewaysListTableActions(
              $event.action.name,
              $event.childElement,
              $event.parentElement
            )
          "
          (selectedDataUpdateFlag)="selectedDataUpdate($event)"
          (extraSelectedDataUpdateFlag)="
            extraSelectedDataUpdate($event.parentElement, $event.childsSelected)
          "
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="gatewayMetersList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
