<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>
            {{ "datalogger-logs" | translate }}:
            {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
          >
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>

      <div class="panel-body">
        <div class="gateway-datalogger-data">
          <h4>{{ "group" | translate }}</h4>
          <div *ngFor="let condition of conditions; index as i">
            <div class="gateway-datalogger-data-conditions">
              <div class="gateway-datalogger-data-condition">
                <app-material-select
                  [title]="('group' | translate) + ' ' + (i + 1)"
                  [bindValue]="'value'"
                  [bindLabel]="'name'"
                  [data]="groupList"
                  [selected]="DATALOG_GROUP.ALL"
                  (selectedOption)="condition.group = $event?.value"
                  required
                ></app-material-select>
              </div>
              <div class="gateway-datalogger-data-condition">
                <mat-form-field appearance="standard">
                  <mat-label>
                    <span>{{ "initial-event" | translate }} {{ i + 1 }}</span>
                  </mat-label>
                  <input
                    matInput
                    type="number"
                    [(ngModel)]="condition.initialEvent"
                    min="0"
                    required
                  />
                </mat-form-field>
              </div>
              <div class="gateway-datalogger-data-condition">
                <mat-form-field appearance="standard">
                  <mat-label>
                    <span>{{ "final-event" | translate }} {{ i + 1 }}</span>
                  </mat-label>

                  <input
                    matInput
                    type="number"
                    [(ngModel)]="condition.finalEvent"
                    min="0"
                    required
                  />
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="gateway-datalogger-data-buttons">
              <button
                mat-button
                class="mat-button-icon"
                (click)="addCondition()"
              >
                <i class="fas fa-plus-circle"></i>
              </button>
              <button
                mat-button
                class="mat-button-icon"
                [disabled]="conditions?.length == 1"
                (click)="deleteCondition()"
              >
                <i class="fas fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewayDatalogger'"
          [columns]="columns"
          [export]="true"
          [exportFileName]="'datalogger-logs' | translate"
          [maxReg]="tableMaxReg"
          [rowNumbers]="true"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [orderBy]="orderBy"
          [waitForData]="waitForData"
          [highlightRow]="highlightRow"
          [specialFilter]="true"
          [data]="gatewayDatalogger"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
