<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <app-material-select
          class="material-header-select"
          [title]="'telecontrol-historic' | translate"
          [bindValue]="'value'"
          [bindLabel]="'name'"
          [selected]="initialAlertLevel"
          [data]="alertLevels"
          (selectedOption)="dataSource = $event?.value; getData(from, to)"
        ></app-material-select>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'alertHistoric'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [specialFilter]="true"
          [data]="alertList"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
