<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "images-import" | translate }} </b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Sección para importar lecturas -->
        <div>
          <h4>
            <b>{{ "file-import" | translate }}</b>
          </h4>
          <h5 class="readings-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import [fileType]="'.csv'" (fileReady)="processFile($event)"></app-file-import>
        </div>

        <app-import-table [importColumns]="importColumns" [importPreselects]="null" (tableActionFlag)="(null)">
        </app-import-table>

        <hr />

        <!-- Sección para importar imágenes -->
        <div>
          <h4>
            <b>{{ "images-import-files" | translate }}</b>
          </h4>
          <h5 class="readings-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "images-import-explanation" | translate }}</b>
          </h5>
          <app-file-import [multiple]="true" [fileType]="'.jpg, .png'"
            (fileReady)="processImageFiles($event)"></app-file-import>
        </div>

        <!-- Resumen de la importación (total, éxitos, fallos) -->
        <div *ngIf="fileData || imagesFiles" class="readings-import-response">
          <div>
            <b>
              {{
              (imagesFiles ? "image-total" : "readings-total-rows")
              | translate
              }}:
            </b>
            <br />
            <span>{{ importResponse.total }}</span>
          </div>
          <div>
            <b>
              {{
              (imagesFiles ? "image-total-susccess" : "readings-rows-success")
              | translate
              }}:
            </b>
            <br />
            <span>{{ importResponse.success ? importResponse.success : 0 }}</span>
          </div>
          <div>
            <b>
              {{
              (imagesFiles ? "image-total-error" : "readings-rows-error")
              | translate
              }}:
            </b>
            <br />
            <span>{{ importResponse.fail ? importResponse.fail : 0 }}</span>
          </div>

          <!-- Si hay errores, mostrar los detalles de cada uno en una tabla -->
          <div *ngIf="errors?.length > 0" class="errors-list">
            <h3>{{ 'import-errors' | translate }}</h3>

            <!-- Tabla para mostrar los errores -->
            <app-table-controller id="import-errors" [tableId]="'userImport'" [onlyTable]="true" [columns]="columns"
              [rowNumbers]="true" [data]="tableData" [maxReg]="10"></app-table-controller>
          </div>
        </div>
        <!-- Mostrar detalles de los fallos si existen -->
        <div *ngIf="errors?.length > 0" class="images-import-errors">
          <button mat-button class="mat-button-icon-text" (click)="retry()">
            <i class="fas fa-redo-alt"></i>
            {{ "retry-failed" | translate }}
          </button>
          <button mat-button class="mat-button-icon-text" (click)="downloadErrors()">
            <i class="fas fa-file-download"></i>
            {{ "download-file-error" | translate }}
          </button>

          <!-- Aquí mostramos los detalles de los fallos -->

        </div>
      </div>
    </div>
  </div>
</section>
