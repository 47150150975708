import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ALARM_STATES, AlarmUpdateData } from "../AlarmInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-alarm-edit-dialog",
  templateUrl: "./alarm-edit-dialog.component.html",
  styleUrls: ["./alarm-edit-dialog.component.scss"],
})
export class AlarmEditDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  state: number;
  comments: string;
  alarmStateOptions: MaterialSelectOption[] = [
    { value: ALARM_STATES.PENDING, name: this.translate.instant("pending") },
    { value: ALARM_STATES.CHECKED, name: this.translate.instant("checked") },
    { value: ALARM_STATES.NOTIFIED, name: this.translate.instant("notified") },
    { value: ALARM_STATES.FIXED, name: this.translate.instant("fixed") },
    { value: ALARM_STATES.CRITICAL, name: this.translate.instant("critical") },
  ];
  initialSelection: number;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data?.length == 1) {
      this.initialSelection = this.data[0].state;
      this.state = this.data[0].state;
      this.comments = this.data[0].comments;
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Salvar cambios en alarma
  editAlarmSave(): void {
    let data: AlarmUpdateData = {
      alarmId: this.data[0].id,
      alarmState: this.state,
      alarmComment: this.comments,
    };
    this.AlarmController.update(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.data[0].state = this.state;
        this.data[0].stateParsed = this.alarmStateOptions.find(
          (option) => option.value == this.state
        )?.name;
        this.data[0].comments = this.comments;
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }

  // Salvar cambios en alarma
  editMultipleAlarmsSave(): void {
    let data: AlarmUpdateData[] = this.data.map((alarm) => {
      return {
        alarmId: alarm.id,
        alarmState: this.state,
        alarmComment: this.comments,
      };
    });
    this.AlarmController.updateMultiple(data).subscribe((response) => {
      if (response["code"] == 0) {
        let stateParsed = this.alarmStateOptions.find(
          (option) => option.value == this.state
        )?.name;
        this.data.map((alarm) => {
          alarm.state = this.state;
          alarm.stateParsed = stateParsed;
          alarm.comments = this.comments;
        });
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
