import { Injectable } from "@angular/core";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { MapDevice } from "../../../interfaces/DeviceGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  constructor(private SessionDataService: SessionDataService) {}

  filterDisabledAgrupations(devices: MapDevice[]): MapDevice[] {
    let disabledAgrupations = this.SessionDataService.getCurrentEntity()
      .agrupations.filter((agrupation) => agrupation.hideInVirtualMap)
      .map((agrupation) => agrupation.id);
    return devices.filter(
      (device) => !disabledAgrupations.includes(device.agrupation)
    );
  }
}
