// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { OutputFilesControllerService } from "../../../../../services/server/OutputFilesController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import { FileTemplate } from "../../OutputFileInterface.type";
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../../../services/language/LanguageController.service";

@Component({
  selector: "app-templateForm",
  templateUrl: "./output-files-templates-form.component.html",
  styleUrls: ["./output-files-templates-form.component.scss"],
})
export class TemplateFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Fichero
  cupsOptions: any[];
  selectedCupsColumns: EntityDefinition[];
  columnsAlreadyIncluded: string = "(";
  template: FileTemplate;
  languages = [
    { name: "Castellano", value: LANGUAGE.ESPANOL },
    { name: "English", value: LANGUAGE.INGLES },
  ];
  preselectedLanguage: string;
  @ViewChild("closestValue") closestValue: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private OutputFilesController: OutputFilesControllerService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.entityCupsConf.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    if (history.state.data) {
      this.template = history.state.data;
      this.preselectedLanguage = this.template.language.toLowerCase();
      this.template.diasArray = [].constructor(28);
      this.template.dias?.forEach((day) => {
        this.template.diasArray[day] = true;
      });
    } else {
      this.template = new FileTemplate();
      this.template.diasArray = [].constructor(28);
    }

    // Columnas de CUPS
    this.cupsOptions = this.currentEntityCupsConf?.filter(
      (column: EntityDefinition) =>
        column.colPosition != 0 && column.colPosition != 100
    );

    // Texto aclaratorio columnas
    let nroSerieLabel = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 100
    )?.label;
    let cups = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.label;

    this.columnsAlreadyIncluded +=
      (nroSerieLabel ? nroSerieLabel : "NRO_SERIE") +
      ", " +
      (cups ? cups : "CUPS") +
      ", Timestamp, " +
      this.translate.instant("date") +
      ", " +
      this.translate.instant("groups") +
      " " +
      this.translate.instant("y") +
      " " +
      this.translate.instant("value") +
      " " +
      this.translate.instant("already-included") +
      ")";
  }

  // Descarga de fichero
  saveTemplate(): void {
    let data: FileTemplate = {
      id: this.template.id,
      templateName: this.template.templateName,
      extraData: this.selectedCupsColumns
        ? this.selectedCupsColumns.map(
            (column: EntityDefinition) => column.colPosition
          )
        : [],
      limiteDias: this.template.limiteDias,
      dias:
        this.template.tipo == 0
          ? this.getCronDays(this.template.diasArray)
          : null,
      tipo: this.template.tipo,
      language: this.template.language,
      entity: this.currentEntity.id,
    };

    this.OutputFilesController.saveTemplate(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.router.navigate(["/ficheros/plantillas/listado"]);
      }
    });
  }

  // Focus en inputs de tipo al seleccionar
  inputFocus(): void {
    if (this.template.tipo == 0) {
      setTimeout(() => {
        this.closestValue.nativeElement.focus();
      }, 0);
    }
  }

  // Parseo de días de la semana de cron
  getCronDays(daysArray: boolean[]): number[] {
    let days: number[] = [];
    daysArray.forEach((day, i) => {
      if (day) {
        days.push(i + 1);
      }
    });
    return days;
  }
}
