// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { GatewayService } from "../../../GatewayService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableHighlightRow,
  TableSelectColumn,
} from "../../../../../../modules/table-module/TableInterface.type";
import { GatewayFrameLog } from "../../../GatewayInterface.type";
import { DialogAction } from "../../../../devices/DeviceInterface.type";
// Componentes
import { MeterLogFramesDialogComponent } from "../../../../devices/meter/meter-detail/meter-logs/meter-log-frames/meter-log-frames-dialog/meter-log-frames-dialog.component";
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-gateway-frames",
  templateUrl: "./gateway-frames.component.html",
  styleUrls: ["./gateway-frames.component.scss"],
})
export class GatewayFramesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;

  // Table
  tableMaxReg: number = 50;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("1");
  gatewayId: number;
  unidadVenta: string;
  gatewayFrames: GatewayFrameLog[];
  originalGatewayFrames: GatewayFrameLog[];
  framesFiltered: boolean = false;
  framesHighlighted: boolean = false;
  orderBy: object = { attribute: "timestamp", reverse: true };
  exportFileName: string =
    this.translate.instant("frames-export") +
    " " +
    this.DateParserService.getDate();
  from: string;
  to: string;
  tableSelectedData: GatewayFrameLog[];
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightRepeated",
      color: "yellow",
      title: "frames-repeated",
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      selectionRequired: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];

  // Modal
  dialog: Subscription;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private GatewayController: GatewayControllerService,
    private GatewayService: GatewayService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Acción de modal
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: DialogAction) => {
        this.getDialogAction(dialogAction);
      }
    );

    // Carga del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.setPanelMenuOptions();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.framesHighlighted = false;
    this.framesFiltered = false;
    this.GatewayController.getGatewayFrameLog(
      this.gatewayId,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let gatewayFrames: GatewayFrameLog[] = response["body"];
          gatewayFrames.forEach((gatewayFrame) => {
            if (gatewayFrame.hijoNroSerie) {
              gatewayFrame.nroSerie = gatewayFrame.hijoNroSerie;
              gatewayFrame.concentrator = gatewayFrame.contadorNroSerie;
            } else {
              gatewayFrame.nroSerie = gatewayFrame.contadorNroSerie;
              gatewayFrame.concentrator = null;
            }
          });
          this.gatewayFrames = gatewayFrames;
          this.originalGatewayFrames = [...gatewayFrames];
        } else {
          this.gatewayFrames = [];
          this.originalGatewayFrames = [];
        }
        this.setColumns();
      }
    });
  }

  // Seteo de columnas de la tabla
  setColumns(): void {
    let concentrators = this.gatewayFrames.some((frame) => frame.hijoNroSerie);
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "concentrator",
        data: "concentrator",
        search: "concentrator",
        sort: "concentrator",
        visible: concentrators ? true : null,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "sent-hour",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "channel",
        data: "channelParsed",
        search: "channelParsed",
        sort: "channel",
        numerical: true,
        visible: true,
      },
      {
        title: "Mic error",
        data: "micError",
        search: "micError",
        sort: "micError",
        alter: {
          condition: "micError",
          skins: [
            { rule: true, class: "fas fa-exclamation-triangle red" },
            { rule: false, class: "" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "SF",
        data: "sfParsed",
        search: "sfParsed",
        sort: "sf",
        numerical: true,
        visible: true,
      },
      {
        title: "RSSI",
        data: "rssiParsed",
        search: "rssiParsed",
        sort: "rssi",
        numerical: true,
        visible: true,
      },
      {
        title: "SNR",
        data: "snrParsed",
        search: "snrParsed",
        sort: "snr",
        numerical: true,
        visible: true,
      },
      {
        title: "frame",
        data: "allFrameData",
        search: "allFrameData",
        sort: "allFrameData",
        long: true,
        visible: true,
      },
    ];
  }

  // Actualización del componente
  updateData(): void {
    this.gatewayFrames = [];
    this.getData(this.from, this.to);
  }

  // Filtrado de tramas
  updateFramesFilter(highlightRepeated?: boolean): void {
    if (this.framesFiltered || highlightRepeated) {
      let filteredGatewayFrames = [];
      this.originalGatewayFrames.forEach((gatewayFrame: GatewayFrameLog) => {
        let frameFound = filteredGatewayFrames.findIndex(
          (filteredGatewayFrame) =>
            ((!gatewayFrame.hijo &&
              filteredGatewayFrame.contador == gatewayFrame.contador) ||
              (gatewayFrame.hijo &&
                filteredGatewayFrame.hijo == gatewayFrame.hijo)) &&
            filteredGatewayFrame.allFrameData == gatewayFrame.allFrameData
        );

        if (frameFound >= 0) {
          if (
            filteredGatewayFrames[frameFound].timestamp >=
            gatewayFrame.timestamp
          ) {
            filteredGatewayFrames[frameFound].highlightRepeated =
              highlightRepeated;
            filteredGatewayFrames[frameFound] = gatewayFrame;
          }
        } else {
          filteredGatewayFrames.push(gatewayFrame);
        }
      });
      this.framesFiltered ? (this.gatewayFrames = filteredGatewayFrames) : null;
      this.framesHighlighted ? this.tableController.setHighlightedRows() : null;
    } else {
      this.originalGatewayFrames.map(
        (gateway) => (gateway.highlightRepeated = false)
      );
      this.framesHighlighted = false;
      this.framesFiltered = false;
      this.gatewayFrames = [...this.originalGatewayFrames];
    }
  }

  // Relanza las tramas seleccionadas
  relaunchFramesSelected(actionData: DialogAction): void {
    let framesWithMicError: GatewayFrameLog[] = [];
    let framesWithoutMicError: GatewayFrameLog[] = [];
    let relaunchRequests = [];

    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("frames-relaunch-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.tableSelectedData?.forEach((frame: GatewayFrameLog) => {
          frame.micError
            ? framesWithMicError.push(frame)
            : framesWithoutMicError.push(frame);
        });

        // Petición para dispositivos con Mic error
        if (framesWithMicError.length > 0) {
          while (framesWithMicError.length > 0) {
            let meterFrames = framesWithMicError.filter(
              (frame) => frame.contador == framesWithMicError[0].contador
            );
            relaunchRequests.push(
              this.MeterController.relaunchMeterIdMicFrames(
                framesWithMicError[0].contador,
                actionData.relaunchWithAlarms,
                meterFrames.map((frame) => frame.id)
              )
            );
            framesWithMicError = framesWithMicError.filter(
              (frame) => frame.contador != framesWithMicError[0].contador
            );
          }
        }

        // Petición para dispositivos sin Mic error
        if (framesWithoutMicError.length > 0 && !actionData.relaunchOnlyMic) {
          while (framesWithoutMicError.length > 0) {
            let meterFrames = framesWithoutMicError.filter(
              (frame) => frame.contador == framesWithoutMicError[0].contador
            );
            relaunchRequests.push(
              this.MeterController.relaunchMeterIdFrames(
                framesWithoutMicError[0].contador,
                actionData.relaunchWithAlarms,
                meterFrames.map((frame) => frame.id)
              )
            );
            framesWithoutMicError = framesWithoutMicError.filter(
              (frame) => frame.contador != framesWithoutMicError[0].contador
            );
          }
        }

        // Peticiones secuenciales para evitar errores de procesamiento de tramas
        if (relaunchRequests.length > 0) {
          this.SessionDataService.sendLockSpinner(true);
          this.requestLoop(
            relaunchRequests,
            this.translate.instant("frames-relaunch-success"),
            0,
            relaunchRequests.length - 1
          );
        }
      }
    });
  }

  // Bucle de servicios
  requestLoop(
    requests: Observable<object>[],
    toastText: string,
    index: number,
    maxIndex: number
  ): void {
    requests[index].subscribe((response) => {
      if (index < maxIndex) {
        this.requestLoop(requests, toastText, ++index, maxIndex);
      } else {
        this.ToastService.fireToast("success", toastText);
        this.gatewayFrames = [];
        this.SessionDataService.clearLockSpinner();
        this.getData(this.from, this.to);
      }
    });
  }

  // Modal relanzamiento de tramas
  relaunchFrames(): void {
    this.MaterialDialogService.openDialog(MeterLogFramesDialogComponent, {
      meters: this.tableSelectedData,
      avoidDate: true,
      action: "relaunch",
    });
  }

  // Borrado de tramas
  deleteFrames(frame?: GatewayFrameLog): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-frames-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (frame) {
          this.MeterController.deleteMeterFrames(frame.contador, [
            frame.id,
          ]).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("frames-deleted")
              );
              this.getData(this.from, this.to);
            }
          });
        } else {
          let deleteRequests = [];
          let selectedFrames = this.tableSelectedData;

          while (selectedFrames.length > 0) {
            let meterFrames = selectedFrames.filter(
              (frame) => frame.contador == selectedFrames[0].contador
            );
            deleteRequests.push(
              this.MeterController.deleteMeterFrames(
                selectedFrames[0].contador,
                meterFrames.map((frame) => frame.id)
              )
            );
            selectedFrames = selectedFrames.filter(
              (frame) => frame.contador != selectedFrames[0].contador
            );
          }

          // Peticiones secuenciales para evitar errores de procesamiento de tramas
          if (deleteRequests.length > 0) {
            this.SessionDataService.sendLockSpinner(true);
            this.requestLoop(
              deleteRequests,
              this.translate.instant("frames-deleted"),
              0,
              deleteRequests.length
            );
          }
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, frame: GatewayFrameLog): void {
    switch (action) {
      case "delete":
        this.deleteFrames(frame);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "erase-selection":
        this.deleteFrames();
        break;
      default:
        break;
    }
  }

  // Acción de modal
  getDialogAction(dialogAction: DialogAction): void {
    switch (dialogAction.action) {
      case "relaunchFramesSelected":
        this.relaunchFramesSelected(dialogAction);
        break;
      default:
        break;
    }
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = this.GatewayService.getLogPanelMenu("frames-log");
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    this.GatewayService.getLogMenuAction(
      action,
      this.gatewayId,
      this.unidadVenta
    );
  }
}
