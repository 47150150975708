<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default network-state-panel">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>

          <!-- Título -->
          <h5>KPIs: {{ currentEntity?.entity }}</h5>

          <!-- Filtro de agrupación -->
          <div *ngIf="highchartsOptions" class="network-state-kpi-agrupations">
            <app-material-select
              class="material-header-select"
              [title]="'kpis-show' | translate"
              [bindValue]="'id'"
              [bindLabel]="'name'"
              [selected]="-1"
              [data]="agrupationList"
              (selectedOption)="selectedAgrupation = $event?.id; updateGraph()"
            ></app-material-select>
          </div>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tarjetas de datos -->
        <app-cards-controller
          [cardsId]="'networkStateKpis'"
          [data]="cardsData"
          [cardWidth]="30"
          [cardsGap]="1.5"
        >
        </app-cards-controller>

        <div class="network-state-kpi-filter">
          <!-- Filtros de fabricante/modelo -->
          <app-device-type-filter
            [linkFilters]="true"
            [allowedDevices]="allowedDevices"
            (selectionUpdate)="
              selectedManufacturer = $event.manufacturer;
              selectedModel = $event.model;
              updateGraph()
            "
          >
          </app-device-type-filter>

          <!-- Opciones de gráfica -->
          <div class="network-state-kpi-radio">
            <div>
              <input
                class="pointer"
                type="checkbox"
                id="lastFiveDays"
                name="lastFiveDays"
                [(ngModel)]="lastFiveDaysActive"
                (click)="
                  lastFiveDaysActive && !lastDayActive && !hourlyActive
                    ? $event.preventDefault()
                    : null
                "
                (change)="
                  lastDayActive = false; hourlyActive = false; updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  lastFiveDaysActive = !lastFiveDaysActive;
                  updateGraph()
                "
              />
              <label
                for="lastFiveDays"
                class="pointer"
                (click)="
                  $event.preventDefault();
                  lastFiveDaysActive && !lastDayActive && !hourlyActive
                    ? null
                    : updateSeriesActive(true, false, false);
                  updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  lastDayActive || hourlyActive
                    ? (lastFiveDaysActive = !lastFiveDaysActive)
                    : null;
                  updateGraph()
                "
              >
                {{ "kpis-last-five-days" | translate }}
              </label>
            </div>
            <div>
              <input
                class="pointer"
                type="checkbox"
                id="lastDay"
                name="lastDay"
                [(ngModel)]="lastDayActive"
                (click)="
                  lastDayActive && !lastFiveDaysActive && !hourlyActive
                    ? $event.preventDefault()
                    : null
                "
                (change)="
                  lastFiveDaysActive = false;
                  hourlyActive = false;
                  updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  lastDayActive = !lastDayActive;
                  updateGraph()
                "
              />
              <label
                class="pointer"
                for="lastDay"
                (click)="
                  $event.preventDefault();
                  lastDayActive && !lastFiveDaysActive && !hourlyActive
                    ? null
                    : updateSeriesActive(false, true, false);
                  updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  lastFiveDaysActive || hourlyActive
                    ? (lastDayActive = !lastDayActive)
                    : null;
                  updateGraph()
                "
              >
                {{ "kpis-day" | translate }}
              </label>
            </div>
            <div>
              <input
                class="pointer"
                type="checkbox"
                id="hourly"
                name="hourly"
                [(ngModel)]="hourlyActive"
                (click)="
                  hourlyActive && !lastFiveDaysActive && !lastDayActive
                    ? $event.preventDefault()
                    : null
                "
                (change)="
                  lastFiveDaysActive = false;
                  lastDayActive = false;
                  updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  hourlyActive = !hourlyActive;
                  updateGraph()
                "
              />
              <label
                class="pointer"
                for="hourly"
                (click)="
                  $event.preventDefault();
                  hourlyActive && !lastFiveDaysActive && !lastDayActive
                    ? null
                    : updateSeriesActive(false, false, true);
                  updateGraph()
                "
                (contextmenu)="
                  $event.preventDefault();
                  lastFiveDaysActive || lastDayActive
                    ? (hourlyActive = !hourlyActive)
                    : null;
                  updateGraph()
                "
              >
                {{ "kpis-hourly" | translate }}
              </label>
            </div>
          </div>
        </div>

        <div class="network-state-kpi-graph">
          <div>
            <!-- Gráfica -->
            <app-graph-controller
              [highchartsOptions]="highchartsOptions"
              [data]="chartOptions"
              [chartConstructor]="chartConstructor"
              [dateRange]="defaultDateRange"
              (dataRequest)="loadGraphData($event.from, $event.to)"
              [yAxisMinRange]="yAxisMinRange"
            >
            </app-graph-controller>
          </div>

          <div>
            <input type="range" min="0" max="100" [(ngModel)]="yAxisMinRange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
