<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit" | translate }}
    </h5>
  </div>
  <div class="dialog-body management-agrupation-dialog">
    <!-- Comentarios -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "text" | translate }}</mat-label>
      <textarea
        matInput
        rows="5"
        [(ngModel)]="data.historicalText"
        placeholder="{{ 'text' | translate }}..."
        required
      ></textarea>
    </mat-form-field>
    <!-- Timestamp -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "date" | translate }}</mat-label>
      <input
        matInput
        type="datetime-local"
        [(ngModel)]="data.timestampParsed"
        placeholder="{{ 'date' | translate }}..."
        required
      />
    </mat-form-field>
    <!-- Timezone -->
    <mat-checkbox class="mat-option" [(ngModel)]="data.localTimezone">
      Europe/Madrid
    </mat-checkbox>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="editMaintenance()"
      [disabled]="data.historicalText == null || data.timestampParsed == null"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
