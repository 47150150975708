<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "displace-serie" | translate }}:
      {{
        (data.option == 0 ? "displace-axis-x" : "displace-axis-y") | translate
      }}
    </h5>
  </div>
  <div class="dialog-body graph-displace">
    <!-- Series -->
    <app-material-select
      [fullWidth]="true"
      [title]="'graph-series' | translate"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [data]="data.series"
      (selectedOption)="seriesId = $event?.id"
      [required]="true"
    ></app-material-select>
    <!-- Desplazamiento eje X-->
    <mat-form-field
      *ngIf="data.option == 0"
      class="graph-displacement"
      appearance="standard"
    >
      <mat-label
        >{{ "displacement" | translate }} ({{ "hours" | translate }})</mat-label
      >
      <input
        matInput
        type="number"
        step="1"
        [(ngModel)]="displacement"
        required
      />
    </mat-form-field>
    <!-- Desplazamiento eje Y -->
    <mat-radio-group *ngIf="data.option == 1" [(ngModel)]="displacementSign">
      <mat-radio-button value="add">{{ "add" | translate }}</mat-radio-button>
      <mat-radio-button value="multiply">{{
        "multiply" | translate
      }}</mat-radio-button>
    </mat-radio-group>
    <mat-form-field
      *ngIf="data.option == 1"
      class="graph-displacement"
      appearance="standard"
    >
      <mat-label>{{ "displacement" | translate }}</mat-label>
      <input matInput type="number" [(ngModel)]="displacement" required />
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="
        SessionDataService.sendDialogAction({
          action: 'displace',
          seriesId: seriesId,
          displacement: displacement,
          axis: data.option,
          displacementSign: displacementSign
        });
        SessionDataService.sendDialogAction({ action: 'close' })
      "
      [disabled]="
        seriesId == null || displacement == null || displacement % 1 > 0
      "
    >
      <i class="fas fa-chart-line"></i>
      {{ "displace" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
