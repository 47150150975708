// @angular
import { Component, Input, OnInit } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
// Interfaces
import { EditedDeviceData } from "../../DeviceInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-device-edit-modal",
  templateUrl: "./device-edit-modal.component.html",
  styleUrls: ["./device-edit-modal.component.scss"],
})
export class DeviceEditModalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  deviceSerialNumber: string;
  deviceComments: string;
  deviceSeal: string;
  deviceSensorRange: number;
  fileName: string;
  finalImage: any;
  finalImageSent: any;
  updateImage: boolean = false;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private domSanitizer: DomSanitizer,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.finalImage = this.data.meterImage
      ? this.domSanitizer.bypassSecurityTrustUrl(
          "data:image/png;base64, " + this.data.meterImage
        )
      : null;
    this.changeSerialNumberModal();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Edición de número de serie del contador
  changeSerialNumberModal(): void {
    this.deviceSerialNumber = this.data.nroSerie;
    this.deviceComments = this.data.comments;
    this.deviceSeal = this.data.precinto;
    this.deviceSensorRange = this.data.distanciaAcustica;
    this.updateImage = false;
  }

  // Edición de los datos del contador
  editSerialNumber(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: EditedDeviceData = {
          meterId: this.data.id,
          newNroSerie: this.deviceSerialNumber,
          newComments: this.deviceComments,
          newPrecinto: this.deviceSeal,
          newDistanciaAcustica: this.deviceSensorRange,
        };

        let updateDataService: Observable<object> =
          this.MeterController.editDevice(data);
        let updateServices: Observable<object>[] = [updateDataService];
        if (this.updateImage) {
          let byteArray: Int8Array = new Int8Array(1);
          byteArray.fill(0);
          let img: File = this.finalImageSent
            ? this.finalImageSent
            : new File([byteArray], "img", { type: "image/png" });
          let updateImageService: Observable<object> =
            this.MeterController.updateImage(this.data.nroSerie, img);
          updateServices.push(updateImageService);
        }
        forkJoin(updateServices).subscribe((results) => {
          if (
            results
              .map((result: any) => {
                return result["code"];
              })
              .reduce((a, b) => a + b) === 0
          ) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendUpdateSearchBoxFlag();
            this.SessionDataService.sendReloadPanelFlag();
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Eliminación de la imagen de contador
  removeIcon(): void {
    this.finalImageSent = null;
    this.finalImage = null;
    this.updateImage = true;
  }

  // Cambio del archivo de imagen de contador
  fileChangeEvent(event: any): void {
    this.fileName = event.target.files[0].name;
    this.finalImage = null;
    this.finalImageSent = event.target.files[0];
    this.updateImage = true;
  }
}
