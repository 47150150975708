<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default network-state-panel">
      <div class="panel-heading">
        <div class="panel-nav">
          <app-panel-back class="panel-back-link"></app-panel-back>
          <h5>
            {{ "communication-report" | translate }} ({{
              "timezone" | translate
            }}: Europe/Madrid)
          </h5>
        </div>
      </div>

      <div class="panel-body">
        <div class="management-comunnication-controls">
          <div class="management-communication-filter">
            <app-device-type-filter
              *ngIf="deviceList"
              [linkFilters]="true"
              [deviceList]="deviceList"
              (selectionUpdate)="
                selectedManufacturer = $event.manufacturer;
                selectedModel = $event.model;
                updateGraph()
              "
            >
            </app-device-type-filter>
          </div>
          <div class="management-communication-graph-info">
            <!-- Tarjetas de datos -->
            <app-cards-controller
              *ngIf="cardsData"
              [cardsId]="'communicationReport'"
              [data]="cardsData"
              [cardWidth]="25"
            >
            </app-cards-controller>
            <mat-checkbox
              *ngIf="selectedManufacturer != null && graphData"
              [(ngModel)]="showGlobalSerie"
              (change)="setGraphSeries()"
            >
              {{ "global-serie-show" | translate }}
            </mat-checkbox>
          </div>
        </div>

        <!-- Gráfica -->
        <div class="management-comunnication-graph">
          <!-- Filtros de fabricante/modelo -->
          <app-graph-controller
            [highchartsOptions]="highchartsOptions"
            [data]="chartOptions"
            [chartConstructor]="chartConstructor"
            [initialDateNull]="true"
            [maxInterval]="7"
            [dateHint]="('max' | translate) + ' 7 ' + ('days' | translate)"
            [forceLocalTime]="true"
            (dataRequest)="loadGraphData($event.from, $event.to)"
          >
          </app-graph-controller>
        </div>

        <div class="management-comunnication-info">
          <!-- Heatmap -->
          <app-graph-heatmap
            *ngIf="heatMapData"
            [xAxis]="heatMapXAxis"
            [yAxis]="heatMapYAxis"
            [colors]="colors"
            [height]="150"
            [tooltip]="'communicationReport'"
            [data]="heatMapData"
          >
          </app-graph-heatmap>
        </div>
      </div>
    </div>
  </div>
</section>
