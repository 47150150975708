<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <app-material-select
          class="material-header-select"
          [title]="'analytical-models' | translate"
          [bindValue]="'id'"
          [bindLabel]="'name'"
          [selected]="'weekly'"
          [data]="patternSelectOptions"
          (selectedOption)="
            yearlyPattern = $event.id == 'yearly'; updatePatternType()
          "
        ></app-material-select>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="'fas fa-plus'"
          [title]="'new' | translate"
          (action)="newProfile()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body analytical-models-container">
        <!-- Gráficas -->
        <div class="analytical-models-graphs-container">
          <div
            *ngFor="let chart of profileCharts; index as i"
            class="analytical-models-graph"
          >
            <div class="analytical-models-profile">
              {{
                "consumptionProfile" + consumptionProfileList[i].profileId
                  | translate
              }}
            </div>
            <!-- Gráfica de patrón -->
            <app-graph-controller
              [highchartsOptions]="highchartsOptions"
              [noTypeSelector]="true"
              [noDateSelector]="true"
              [chartConstructor]="chartConstructor"
              [data]="chart"
            >
            </app-graph-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
