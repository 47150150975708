<div
  #progressPanel
  *ngIf="wip?.length > 0"
  class="request-progress-panel request-progress-panel-initial"
  [ngClass]="{ 'request-progress-panel-collapsed': !panelExpanded }"
  draggable="true"
  (dragstart)="DragElementService.getDrapgPoint(progressPanel, $event)"
  (dragend)="
    DragElementService.setPosition(
      progressPanel,
      $event,
      'request-progress-panel-initial',
      '--progress-panel-y',
      '--progress-panel-x'
    )
  "
>
  <div
    class="request-progress-panel-title"
    [ngClass]="{ 'request-progress-panel-title-collapsed': !panelExpanded }"
  >
    <!-- Desplazamiento y título -->
    <span *ngIf="panelExpanded">{{ "tasks-progress" | translate }}</span>
    <!-- Expandir/Contraer -->
    <div>
      <i
        [ngClass]="panelExpanded ? 'fas fa-compress-alt' : 'fas fa-expand-alt'"
        class="pointer"
        (click)="panelExpanded = !panelExpanded"
      ></i>
      <i
        [ngClass]="'fas fa-times'"
        class="pointer"
        (click)="
          SessionDataService.sendTaskAction({
            index: null,
            action: 'cancelAll'
          })
        "
        title="{{ 'cancel-all-task' | translate }}"
      ></i>
    </div>
  </div>
  <!-- Tareas en progreso -->
  <div class="request-progress-panel-tasks">
    <ng-container *ngFor="let task of wip; index as i">
      <!-- Panel expandido -->
      <div *ngIf="panelExpanded" class="request-progress-panel-task">
        <div class="request-progress-task-actions">
          <div
            class="request-progress-task-show"
            title="{{ 'show-detail' | translate }}"
            (click)="goToProgressTable(task)"
          >
            <i class="fas fa-eye"></i>
          </div>
          <div
            class="request-progress-task-show"
            title="{{ 'cancel-task' | translate }}"
            (click)="
              SessionDataService.sendTaskAction({
                index: task.taskIndex,
                action: 'cancel'
              })
            "
          >
            <i class="fas fa-times"></i>
          </div>
        </div>

        <div class="request-progress-task-title">{{ task.name }}</div>
        <div *ngIf="task.action != 'reassignManually'">
          <!-- Barra de progreso -->
          <div class="request-progress-task-bar">
            <div
              class="progress-bar"
              [ngClass]="
                task.progress == 100
                  ? 'progress-bar-success progress-bar-striped '
                  : errorProgress
                  ? 'progress-bar-danger progress-bar-striped '
                  : 'progress-bar-striped active'
              "
              role="progressbar"
              attr.aria-valuenow="{{ task.progress | number : '1.0-0' }}"
              [style.width]="task.progress + '%'"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <b *ngIf="task.progress == 100">{{ "finished" | translate }}</b>
              <b *ngIf="task.progress != 100" class="progress-bar-text"
                >{{ task.progress | number : "1.0-0" }}%<span
                  >({{ task.progressIndex }}/{{ task.taskData?.length }})</span
                ></b
              >
            </div>
          </div>
          <div>
            <!-- Parar -->
            <button
              *ngIf="task.status == 'run' && task?.progress != 100"
              type="button"
              class="request-progress-task-button"
              title="{{ 'request-stop' | translate }}"
              (click)="
                SessionDataService.sendTaskAction({
                  index: task.taskIndex,
                  action: 'stop'
                })
              "
            >
              <i class="fas fa-stop"></i>
            </button>
            <!-- Reanudar -->
            <button
              *ngIf="task.status == 'stop' && task?.progress != 100"
              type="button"
              class="request-progress-task-button"
              title="{{ 'request-start' | translate }}"
              (click)="
                SessionDataService.sendTaskAction({ index: task.taskIndex, action: 'run' })
              "
            >
              <i class="fas fa-play"></i>
            </button>
            <!-- Reintentar -->
            <button
              *ngIf="task.progress == 100"
              type="button"
              class="request-progress-task-button"
              title="{{ 'retry-failed' | translate }}"
              (click)="
                SessionDataService.sendTaskAction({
                  index: task.taskIndex,
                  action: 'retry'
                })
              "
              [disabled]="task.error == 0"
            >
              <i class="fas fa-redo-alt"></i>
            </button>
          </div>
          <div>
            <!-- Correctos -->
            <span>
              <i class="fas fa-check-circle"></i>
              {{ task.success }}
            </span>
            <!-- Errores -->
            <span>
              <i class="fas fa-times-circle"></i>
              {{ task.error }}
            </span>
          </div>
        </div>
      </div>
      <!-- Panel contraido -->
      <div *ngIf="!panelExpanded" class="request-progress-panel-task-collapsed">
        <!-- Barra de progreso -->
        <div class="request-progress-task-bar">
          <div
            class="progress-bar"
            [ngClass]="
              task.progress == 100
                ? 'progress-bar-success progress-bar-striped '
                : errorProgress
                ? 'progress-bar-danger progress-bar-striped '
                : 'progress-bar-striped active'
            "
            role="progressbar"
            attr.aria-valuenow="{{ task.progress | number : '1.0-0' }}"
            [style.width]="task.progress + '%'"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <b>{{ task.progress | number : "1.0-0" }}%</b>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
