// @angular
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableHighlightRow,
  TableGlobalAction,
} from "../../table-module/TableInterface.type";
import { Task } from "../request-progress-panel/request-progress-task-interface";

@Component({
  selector: "app-request-queue-table",
  templateUrl: "./request-queue-table.component.html",
  styleUrls: ["./request-queue-table.component.scss"],
})
export class RequestQueueTableComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Botón
  startIcon: string = "fas fa-play";
  startTitle: string = this.translate.instant("request-start");
  stopIcon: string = "fas fa-stop";
  stopTitle: string = this.translate.instant("request-stop");
  retryIcon: string = "fas fa-redo-alt";
  retryTitle: string = this.translate.instant("retry-failed");

  // Reconfiguración
  reconfigurationAction: TableGlobalAction = {
    title: "refresh-assign-errors",
    icon: "fas fa-sync-alt",
  };
  reconfigurationStopAction: TableGlobalAction = {
    title: "refresh-assign-errors-stop",
    icon: "fas fa-stop",
  };

  // Table
  wipUpdate: Subscription;
  task: Task;
  tableData: any[];
  tableMaxReg: number = 5000;
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "highlightCurrent", color: "blue", title: "current-device" },
    {
      condition: "highlightWarning",
      color: "yellow",
      title: "service-warning",
    },
    { condition: "highlightError", color: "red", title: "service-error" },
    { condition: "highlightSuccess", color: "green", title: "success" },
  ];
  tableGlobalActions: TableGlobalAction[];
  columns: (TableActionColumn | TableDataColumn)[];

  // Parámetro de ruta
  taskIndex: string = this.route.snapshot.paramMap.get("id");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    let wip = this.SessionDataService.getCurrentWip();
    this.updateTask(wip);

    if (wip && wip[this.taskIndex].taskAction == "saveAppEui") {
      this.setAppEuiColumns();
    } else {
      this.setColumns();
    }

    // Escucha de cambios en los valores de entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Actualización de tareas en curso
    this.wipUpdate = this.SessionDataService.getWip().subscribe((wip) => {
      this.updateTask(wip);
    });

    // Escucha de sesión caducada
    this.SessionDataService.getSessionExpiredFlag().subscribe(() => {
      this.SessionDataService.sendTaskAction({
        index: this.task?.taskIndex,
        action: "stop",
      });
    });
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.wipUpdate.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización de la tarea en progreso
  updateTask(wip: Task[]): void {
    if (wip && wip[this.taskIndex]) {
      this.task = wip[this.taskIndex];
      if (wip[this.taskIndex].taskAction == "saveAppEui") {
        this.setAppEuiColumns();
      } else {
        this.setColumns();
      }
      this.tableData = JSON.parse(JSON.stringify(wip[this.taskIndex].taskData));
    } else {
      this.router.navigate(["/principal"]);
    }
  }

  // Acciones globales de la tabla
  tableActions(action: string, device: any): void {
    switch (action) {
      case "checked":
        device.checked = !device.checked;
        device.commandResponse = device.checked ? "success" : null;
        device.requestState = "";
        device.highlightSuccess = device.checked ? "success" : null;
        this.task.taskData = this.tableData;
        this.SessionDataService.sendTaskAction({
          index: this.task?.taskIndex,
          action: "update",
          task: this.task,
        });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "refresh-assign-errors":
        this.tableGlobalActions = [this.reconfigurationStopAction];
        this.SessionDataService.sendTaskAction({
          index: this.task?.taskIndex,
          action: "reconfigure",
        });
        break;
      case "refresh-assign-errors-stop":
        this.tableGlobalActions = [this.reconfigurationAction];
        this.SessionDataService.sendTaskAction({
          index: this.task?.taskIndex,
          action: "reconfigureStop",
        });
        break;
      default:
        break;
    }
  }

  // Seteo de columnas
  setColumns() {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "checked",
            tooltip: "checked",
            icon: "fas fa-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
        ],
        visible: this.task?.action == "reassignManually" ? true : null,
      },
      {
        title: "state",
        data: "requestState",
        search: "requestState",
        sort: "commandResponse",
        alter: {
          text: "requestState",
          condition: "commandResponse",
          skins: [
            { rule: "success", class: "fas fa-check-circle" },
            { rule: "error", class: "fas fa-times-circle" },
            { rule: "warning", class: "fas fa-exclamation-triangle" },
            {
              rule: "waiting",
              class: "fas fa-spinner fa-pulse request-table-spinner",
            },
            { rule: "undo", class: "fas fa-undo-alt" },
            { rule: "unknown", class: "fas fa-question-circle" },
          ],
        },
        visible: true,
      },
      {
        title: "error-text",
        data: "errorText",
        search: "errorText",
        sort: "errorText",
        long: true,
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        link: "deviceLink",
        visible:
          this.task?.action != "deallocateDevice" &&
          this.task?.action != "configureGatewayChannels"
            ? true
            : null,
      },
      {
        title: "sales-unit",
        data: "unidadVenta",
        search: "unidadVenta",
        sort: "unidadVenta",
        link: "deviceLink",
        visible:
          this.task?.action == "deallocateDevice" ||
          this.task?.action == "configureGatewayChannels"
            ? true
            : null,
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible:
          this.task?.action == "reassign" ||
          this.task?.action == "reassignManually"
            ? true
            : null,
      },
      {
        title: "gateway",
        data: "gatewayUnidadVenta",
        search: "gatewayUnidadVenta",
        sort: "gatewayUnidadVenta",
        link: "gatewayLink",
        visible: this.task?.action == "assignBestRedundant" ? true : null,
      },
      {
        title: "action",
        data: "action",
        search: "action",
        sort: "action",
        visible:
          this.task?.action == "allocate/deallocate" ||
          this.task?.action == "assignBestRedundant"
            ? true
            : null,
      },
      {
        title: "sales-unit-old",
        data: "oldGatewayUnidadVenta",
        search: "oldGatewayUnidadVenta",
        sort: "oldGatewayUnidadVenta",
        link: "oldGatewayLink",
        visible:
          this.task?.action == "assignBest" ||
          this.task?.action == "agrupationAssignBest" ||
          this.task?.action == "optimize"
            ? true
            : null,
      },
      {
        title: "sales-unit-new",
        data: "newGatewayUnidadVenta",
        search: "newGatewayUnidadVenta",
        sort: "newGatewayUnidadVenta",
        link: "newGatewayLink",
        visible:
          this.task?.action == "assignBest" ||
          this.task?.action == "agrupationAssignBest" ||
          this.task?.action == "reassign" ||
          this.task?.action == "optimize"
            ? true
            : null,
      },
      {
        title: "redundant-gateway",
        data: "nroRedundantGatewaysLeft",
        search: "nroRedundantGatewaysLeft",
        sort: "nroRedundantGatewaysLeft",
        visible: this.task?.action == "optimize" ? true : null,
      },
    ];

    if (
      this.task?.action == "assignBest" ||
      this.task?.action == "agrupationAssignBest"
    ) {
      this.tableGlobalActions = [this.reconfigurationAction];
      this.tableGlobalActions[0].selectionRequired = true;
    } else {
      this.tableGlobalActions = null;
    }
  }

  // Seteo de columnas para guardado de App Eui
  setAppEuiColumns() {
    this.columns = [
      {
        title: "state",
        data: "requestState",
        search: "requestState",
        sort: "commandResponse",
        alter: {
          text: "requestState",
          condition: "commandResponse",
          skins: [
            { rule: "success", class: "fas fa-check-circle" },
            { rule: "error", class: "fas fa-times-circle" },
            { rule: "warning", class: "fas fa-exclamation-triangle" },
            {
              rule: "waiting",
              class: "fas fa-spinner fa-pulse request-table-spinner",
            },
            { rule: "undo", class: "fas fa-undo-alt" },
            { rule: "unknown", class: "fas fa-question-circle" },
          ],
        },
        visible: true,
      },
      {
        title: "error-text",
        data: "errorText",
        search: "errorText",
        sort: "errorText",
        long: true,
        visible: true,
      },
      {
        title: "sales-unit",
        data: "unidadVenta",
        search: "unidadVenta",
        sort: "unidadVenta",
        link: "deviceLink",
        visible: true,
      },
      {
        title: "App EUI",
        data: "appEui",
        search: "appEui",
        sort: "appEui",
        visible: true,
      },
      {
        title: "manufacturer",
        data: "manufacturer",
        search: "manufacturer",
        sort: "manufacturer",
        visible: true,
      },
      {
        title: "action",
        data: "action",
        search: "action",
        sort: "action",
        visible: true,
      },
    ];
  }
}
