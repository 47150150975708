// @angular
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
// Interfaces
import { DeviceCoords } from "../../DeviceInterface.type";
// Componentes
import { MapControllerComponent } from "../../../../../modules/map-module/map-controller/map-controller.component";

@Component({
  selector: "app-device-change-location",
  templateUrl: "./device-change-location.component.html",
  styleUrls: ["./device-change-location.component.scss"],
})
export class DeviceChangeLocationComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() device: any;
  mapDevice: any;
  @Input()
  get changeLocationActive(): boolean {
    return this._changeLocationActive;
  }
  set changeLocationActive(changeLocationActive: boolean) {
    this._changeLocationActive = changeLocationActive;
    this.mapDevice = [JSON.parse(JSON.stringify(this.device))];
  }
  _changeLocationActive: any;
  @Input() isGateway: boolean;
  mapType: string = "changeLocation";
  mapHeight: number = 400;
  initialZoom: number = 17;
  deviceNewLocation: { lat: number; lng: number };
  @Output() reloadFlag = new EventEmitter<any>();
  @Output() changeLocationDisableFlag = new EventEmitter<any>();
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GatewayController: GatewayControllerService,
    private MeterController: MeterControllerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización de la posición del contador
  changeDevicePosition(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("change-location-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: DeviceCoords = {
          id: this.device.id,
          latitude: this.deviceNewLocation.lat,
          longitude: this.deviceNewLocation.lng,
        };
        let requestUrl = this.isGateway
          ? this.GatewayController.newCoords(data)
          : this.MeterController.newCoords(data);
        requestUrl.subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-location-sucessfull")
            );
            this.reloadFlag.emit();
          }
        });
      }
    });
  }

  // Seteo de la nueva posición del contador
  setDeviceNewLocation(newLocation: { lat: number; lng: number }): void {
    this.mapDevice[0].latitude = newLocation.lat;
    this.mapDevice[0].longitude = newLocation.lng;
    this.deviceNewLocation = newLocation;
  }

  // Cierre de la ventana
  closeChangeLocation(): void {
    this.changeLocationDisableFlag.emit();
  }

  // Actualización de mapa
  updateMap(): void {
    if (
      this.mapDevice[0].latitude != null &&
      this.mapDevice[0].longitude != null &&
      Math.abs(this.mapDevice[0].latitude) <= 90 &&
      Math.abs(this.mapDevice[0].longitude) <= 180
    ) {
      this.deviceNewLocation = {
        lat: this.mapDevice[0].latitude,
        lng: this.mapDevice[0].longitude,
      };
      this.mapController.resetLayers();
      this.mapController.flyTo(
        [this.mapDevice[0].latitude, this.mapDevice[0].longitude],
        17,
        { animate: false }
      );
    }
  }
}
