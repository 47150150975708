import { Component } from "@angular/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { VALVE_STATES } from "../../../device-new/device-new-attributes";

@Component({
  selector: "app-valve-states-dialog",
  templateUrl: "./valve-states-dialog.component.html",
  styleUrls: ["./valve-states-dialog.component.scss"],
})
export class ValveStatesDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  readonly VALVE_STATES = VALVE_STATES;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(public SessionDataService: SessionDataService) {}
}
