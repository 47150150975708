// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import { TableDataColumn } from "../../../../../modules/table-module/TableInterface.type";
import { GatewayOperator } from "../../GatewayInterface.type";

@Component({
  selector: "app-gateway-operator-historical",
  templateUrl: "./gateway-operator-historical.component.html",
  styleUrls: ["./gateway-operator-historical.component.scss"],
})
export class GatewayOperatorHistoricalComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("gateway-operator-historical") +
    " " +
    this.DateParserService.getDate();
  gatewayId: number;
  unidadVenta: string;
  gatewayOperators: GatewayOperator[];
  columns: TableDataColumn[] = [
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "operator",
      data: "operadora",
      search: "operadora",
      sort: "operadora",
      visible: true,
    },
    {
      title: "PLMN",
      data: "plmn",
      search: "plmn",
      sort: "plmn",
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en entidad y agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(() => {
      this.RouteCheckService.stayOnRoute("agrupation")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.gatewayId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.unidadVenta = history.state.data;
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.GatewayController.geGatewayOperatorHistorical(
      this.gatewayId
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let gatewayOperators = response["body"];
        this.gatewayOperators = gatewayOperators;
      }
    });
  }
}
