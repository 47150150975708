<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <div class="panel-nav">
          <!-- Opciones de búsqueda -->
          <div class="global-search-options">
            <app-material-select
              class="material-header-select"
              [title]="'search-global' | translate"
              [bindValue]="'value'"
              [bindLabel]="'name'"
              [selected]="'nroSerie'"
              [data]="globalSearchSelect"
              (selectedOption)="updateSearch($event?.value)"
            ></app-material-select>
            <!-- Búsqueda por string -->
            <mat-form-field
              *ngIf="searchType != 'installation'"
              appearance="standard"
            >
              <input
                #searchInput
                matInput
                [(ngModel)]="searchedDevice"
                placeholder="{{ placeholder }}"
                [disabled]="searchType == 'installation'"
                (keyup.enter)="getData()"
              />
            </mat-form-field>
            <!-- Búsqueda por fecha -->
            <app-material-date-selector
              class="material-header-select"
              *ngIf="searchType == 'installation'"
              [dateRange]="dataInitialDate"
              (dateRangeUpdated)="
                getDataByDate($event?.startDate, $event?.endDate)
              "
            ></app-material-date-selector>
            <!-- Buscar -->
            <button mat-button class="mat-button-icon" (click)="getData()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>

        <!-- Refresco -->
        <div class="global-search-refresh">
          <mat-form-field class="material-header-input" appearance="standard">
            <mat-label
              ><span>{{ "show-alarms-refresh" | translate }}</span>
              <i class="fas fa-sync-alt"></i
            ></mat-label>
            <input matInput type="number" [(ngModel)]="refreshTimer" min="1" />
            <span matSuffix>{{ "minutes" | translate }}</span>
          </mat-form-field>
        </div>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="updateIcon"
          [title]="updateTitle"
          (action)="updateRefresh()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de dispositivos -->
        <app-table-controller
          [tableId]="'globalSearch'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [specialFilter]="true"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [highlightRow]="tableHighlightRow"
          [data]="tableData"
        >
        </app-table-controller>

        <!-- Mapa de contadores -->
        <div id="map-panel">
          <app-map-controller
            *ngIf="devices && gateways"
            id="management-search-map"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [freeDrawEnabled]="true"
            (selectedDevices)="selectedDevices = $event"
            [gatewaysData]="gateways"
            (actionFlag)="updateAgrupation($event)"
            [metersData]="devices"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
