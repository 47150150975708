// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
import { MachineLearningService } from "../../../../../../services/shared/MachineLearning.service";
import { NgxSpinnerService } from "ngx-spinner";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { DeviceAgrupationDialogComponent } from "../../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import { MapDevice } from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { MapGateway } from "../../../../../../interfaces/GatewayGlobalInterface.type";
import { OutliersData } from "../../../../../../modules/graph-module/GraphInterface.type";
// Variables
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../../../assets/profiles/profiles";
// Componentes
import { MeterAssignedDialogComponent } from "../meter-assigned/meter-assigned-dialog/meter-assigned-dialog.component";
import { MeterSelectionDialogComponent } from "./meter-selection-dialog/meter-selection-dialog.component";

@Component({
  selector: "app-meter-selection",
  templateUrl: "./meter-selection.component.html",
  styleUrls: ["./meter-selection.component.scss"],
})
export class MeterSelectionComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  deviceType: string;
  deviceSelected: number[];
  dialogActionSub: Subscription;

  // Tabla
  tableSelectedData: MapDevice[];
  tableMaxReg: number = 100;
  tableData: MapDevice[];
  originalTableData: MapDevice[];
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 0 },
        active: false,
      },
      {
        name: "gas-meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 2 },
        active: false,
      },
      {
        name: "sensors",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 1 },
        active: false,
      },
      {
        name: "concentrators",
        columnSearch: "isConcentrator",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "satelite",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 4 },
        active: false,
      },
    ],
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "save-selection",
      icon: "fas fa-save",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "show-consumption-evolution",
      icon: "fas fa-faucet",
      selectionRequired: true,
      help: "help-table-evolution",
    },
    {
      title: "association-new",
      icon: "fas fa-layer-group",
      selectionRequired: true,
      help: "help-table-asoc",
    },
    {
      title: "balance-new",
      icon: "fas fa-balance-scale-left",
      selectionRequired: true,
      help: "help-table-balance",
    },
    {
      title: "change-agrupation",
      icon: "fas fa-map",
      selectionRequired: true,
      help: "help-table-agrupation",
    },
    {
      title: "change-location",
      icon: "fas fa-map-marked-alt",
      selectionRequired: true,
      help: "help-table-evolution",
    },
    {
      title: "deactivate-multiple",
      icon: "fas fa-eraser",
      selectionRequired: true,
      class: "btn-red",
      help: "help-table-deactivate",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  stayOnRoute: boolean = false;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  // Valores atípicos
  outliersData: OutliersData[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DomControllerService: DomControllerService,
    private MachineLearningService: MachineLearningService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private NgxSpinnerService: NgxSpinnerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    this.dialogActionSub = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "outliers") {
          if (!this.outliersData) {
            this.setColumns(true);
          }
          this.getOutliers(
            dialogAction.data.method,
            dialogAction.data.from,
            dialogAction.data.to,
            dialogAction.data.dateRange,
            dialogAction.data.threshold
          );
        } else if (dialogAction.action == "show-all-outliers") {
          this.NgxSpinnerService.hide("spinner-hard");
          this.outliersData = dialogAction.data;
          this.showOutliers();
        }
        if (dialogAction.action == "show-outlier") {
          this.outliersData.push(dialogAction.data);
          this.showOutliers(dialogAction.data);
        }
      }
    );

    if (this.sessionProfile == PROFILES.ARSON) {
      this.tableGlobalActions.splice(
        4,
        0,
        {
          title: "detect-outliers",
          icon: "fas fa-chart-bar",
          selectionRequired: true,
          help: "help-table-outliers",
        },
        {
          title: "analytical-models",
          icon: "fas fa-database",
          selectionRequired: true,
          help: "help-table-analytical-models",
        }
      );
    }

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.dialogActionSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData() {
    let tableData = history.state.data.devices;
    let gateways = history.state.data.gateways;
    tableData.forEach((meter: MapDevice) => {
      meter.metrologyTypeParsed =
        meter.metrologyType != null
          ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
          : "-";
      meter.selected = false;
      meter.deviceLink = "/dispositivos/detalle/contador/" + meter.id;
      meter.gatewayLink =
        this.sessionProfile == PROFILES.ARSON
          ? "/gateways/detalle/gateway/" +
            gateways.find(
              (gateway: MapGateway) =>
                gateway.unidadVenta == meter.unidadVentaGw
            )?.id
          : null;
      meter.isConcentrator =
        meter.metrologyType == 3 || meter.metrologyType == 5;
    });
    this.setColumns();
    this.tableData = tableData;
    this.originalTableData = tableData;
  }

  // Seteo de columnas
  setColumns(outliersData?: boolean): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "deactivate",
            tooltip: "deactivate",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        link: "deviceLink",
        visible: true,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "communicates",
        data: "comunica",
        search: "comunica",
        sort: "comunica",
        alter: {
          condition: "comunica",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "gateway",
        data: "unidadVentaGw",
        search: "unidadVentaGw",
        sort: "unidadVentaGw",
        link: "gatewayLink",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "outliers",
        data: "outliers",
        search: "outliers",
        sort: "outliers",
        graph: true,
        visible: this.sessionProfile == PROFILES.ARSON && outliersData ? true : null,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, meter: MapDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "deactivate":
        this.resetMeter(meter);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "show-consumption-evolution":
        this.router.navigate(["/analisis-datos/evolucion-consumo"], {
          state: {
            data: this.tableSelectedData.map((device: MapDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "analytical-models":
        this.router.navigate(["/analisis-datos/modelos-analiticos/editar"], {
          state: {
            data: this.tableSelectedData.map((device: MapDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "change-location":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "changeLocation",
          meters: this.tableSelectedData,
        });
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      case "association-new":
        this.router.navigate(["/analisis-datos/asociaciones/nuevo"], {
          state: {
            data: this.tableSelectedData.map((device: MapDevice) => device.id),
          },
        });
        break;
      case "balance-new":
        this.router.navigate(["/analisis-datos/nuevo"], {
          state: {
            data: this.tableSelectedData.map((device: MapDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "save-selection":
        this.MaterialDialogService.openDialog(
          MeterAssignedDialogComponent,
          this.tableSelectedData.map((meter) => {
            return {
              id: meter.id,
              nroSerie: meter.nroSerie,
              metrologyType: meter.metrologyType,
            };
          })
        );
        break;
      case "detect-outliers":
        this.MaterialDialogService.openDialog(MeterSelectionDialogComponent);
        break;
      default:
        break;
    }
  }

  // Redirección a contador
  showMeter(meter: MapDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Reseteo de contador
  resetMeter(meter: MapDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: MapDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((meter: MapDevice) => {
          return meter.id;
        }),
        to: null,
      },
    });
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: this.tableSelectedData,
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }

  // Obtención de los valores atípicos
  getOutliers(
    method: string,
    from: string,
    to: string,
    dateRange: { startDate: moment.Moment; endDate: moment.Moment },
    threshold?: string
  ): void {
    this.tableData.map((meter) => {
      meter.graphData = null;
      meter.outliers = "";
    });
    if (
      this.outliersData &&
      this.outliersData[0]?.dateRange?.startDate.isSame(dateRange.startDate) &&
      this.outliersData[0]?.dateRange?.endDate.isSame(dateRange.endDate)
    ) {
      this.MachineLearningService.detectSelectionOutliers(
        this.tableSelectedData,
        method,
        null,
        null,
        dateRange,
        this.outliersData,
        threshold
      );
    } else {
      this.outliersData = [];
      this.MachineLearningService.detectSelectionOutliers(
        this.tableSelectedData,
        method,
        from,
        to,
        dateRange,
        null,
        threshold
      );
    }
  }

  // Mostrar valores atípicos
  showOutliers(meterOutliers?: OutliersData): void {
    if (meterOutliers) {
      let meter = this.tableSelectedData.find(
        (meter) => meter.id == meterOutliers.meterId
      );
      this.getOutlierSerie(meter, meterOutliers);
    } else {
      this.tableSelectedData.forEach((meter: MapDevice) => {
        let meterOutliers = this.outliersData.find(
          (meterOutlier) => meterOutlier.meterId == meter.id
        );
        this.getOutlierSerie(meter, meterOutliers);
      });
    }
  }

  // Obtención de la serie de valores atípicos
  getOutlierSerie(meter: MapDevice, meterOutliers: OutliersData): void {
    const self = this;
    // if (meterOutliers.outliers?.length > 0) {
    if (meterOutliers.outliers) {
      meter.graphData = {
        series: [
          {
            id: "consumption",
            name: this.translate.instant("consumption"),
            data: meterOutliers.meterSeries,
            tooltip: {
              pointFormatter: function () {
                if (
                  meterOutliers.method == "average-hourly" ||
                  meterOutliers.method == "average-daily"
                ) {
                  return (
                    `<span>` +
                    self.translate.instant("value") +
                    "</span>: <b>" +
                    Highcharts.numberFormat(this.y, 3) +
                    `</b><br>` +
                    `<span>` +
                    self.translate.instant("average") +
                    "</span>: <b>" +
                    this.series.options.data[this.index][2] +
                    `</b><br>` +
                    `<span>` +
                    self.translate.instant("max-deviation") +
                    "</span>: <b>" +
                    this.series.options.data[this.index][3] +
                    `</b><br>` +
                    self.translate.instant("zScore") +
                    "</span>: <b>" +
                    this.series.options.data[this.index][4] +
                    `</b><br>`
                  );
                } else {
                  return (
                    `<span>` +
                    self.translate.instant("value") +
                    "</span>: <b>" +
                    Highcharts.numberFormat(this.y, 3) +
                    `</b>`
                  );
                }
              },
            },
          },
          {
            id: "outlier",
            name: this.translate.instant("outliers"),
            data: meterOutliers.outliers,
            type: "flags",
            shape: "flag",
            color: "black",
            fillColor: "yellow",
            width: 16,
            title: "<i class='fas fa-exclamation'></i>",
            useHTML: true,
            style: {
              color: "white",
            },
            dataGrouping: { enabled: false },
            enableMouseTracking: false,
            showInNavigator: false,
            allowOverlapX: true,
          },
        ],
      };
      meter.showGraph = true;
      meter.outliers = "";
      // } else if (meterOutliers.outliers?.length == 0) {
      //   meter.outliers = this.translate.instant("outliers-undetected");
    } else if (!meterOutliers.outliers) {
      meter.outliers = this.translate.instant("insufficient-data");
    }
  }
}
