import { Card } from "../../../modules/card-module/CardInterface.type";
import { TooltipOptions } from "highcharts";

// ANCHOR Datos de la tabla de entidad
export interface EntityTableData {
  // Back End
  agrupationId: number;
  agrupationName: string;
  contadoresAsignados: number;
  contadoresLeidosDia: number;
  contadoresLeidosMes: number;
  projectMeters: number;
  licenseMeters: number;
  installedGateways: number;
  availableGateways: number;

  // Front End
  readedPercentage?: number;
  lastMonthPercentage?: number;
  readedPercentageString?: string;
  lastMonthPercentageString?: string;
  graphData?: { series: any[]; min: number; max: number; type?: string };
  agrupationLastDay?: string;
}

// ANCHOR Datos de la gráfica de estado de red
export interface NetworkStateGraphData {
  name: string;
  data: number[][];
}

// ANCHOR Serie de la gráfica de estado de red
export interface NetworkStateGraphSerie {
  id: string;
  name: string;
  data: number[][];
  tooltip: TooltipOptions;
  color?: string;
  dataGrouping?: object;
  type?: string;
  yAxis?: number;
}

// Datos de la tabla de todas las entidades
export interface AllEntityTableData {
  // Back End
  id: number;
  descripcion: string;
  contadoresAsignados: number;
  contadoresLeidosDia: number;
  contadoresLeidosMes: number;
  agrupations: EntityTableData[];

  // Front End
  extraTableData?: any;
  readedPercentage?: number;
  lastMonthPercentage?: number;
  readedPercentageString?: string;
  lastMonthPercentageString?: string;
  agrupationSeries?: object;
  graphData?: { series: any[]; min: number; max: number; type?: string };
  entityLastDay?: number;
  entityLastDayPercentage?: string;
  lastReadingsAverage?: number;
}

// Tarjetas
export class NetworkStateCards {
  public metersInNetwork: Card;
  public metersValidRead: Card;
  public metersReadLastMonth: Card;
  public metersDetected: Card;

  constructor() {
    this.metersInNetwork = null;
    this.metersValidRead = null;
    this.metersReadLastMonth = null;
  }
}

// Información de tarjetas
export interface KpisCardsData {
  lastFiveDaysAverage: number;
  lastDayAverage: number;
  readingsLastDayAverage: number;
  timestamp: number;
}

// Tarjetas de KPIs
export interface KpisCards {
  kpisLastFiveDaysAverage: Card;
  kpisDayAverage: Card;
  kpisHourlyAverage: Card;
}

// Datos de gráfica de KPIs
export interface KpisGraphData {
  agrupation: number;
  fabricante: number;
  devType: number;
  timestamp: number;
  metersReadedLastFiveDays: number;
  totalMetersLastFiveDays: number;
  lastFiveDaysAverage: number;
  metersReadedLastDay: number;
  totalMetersLastDay: number;
  lastDayAverage: number;
  readingsLastDay: number;
  totalReadingsLastDayExpected: number;
  readingsLastDayAverage: number;
}

// Serie de gráfica de KPIs
export interface KpisGraphSerie {
  lastFiveDaysSerie: number[][];
  lastDaySerie: number[][];
  hourlySerie: number[][];
}

// Total de dispositivos
export class TotalDevices {
  public client: string;
  public entity: string;
  public agrupation: string;
  public devices: number;
  public mbus: number;
  public wavenis: number;
  public une: number;
  public external: number;
  public sensors: number;
  public satelites: number;
  public concentrators: number;
  public withoutCom: number;
  public noCom: number;
  public noAssign: number;
  public withAlarms: number;
  public clientTotal?: boolean;
  public entityTotal?: boolean;

  constructor() {
    this.client = null;
    this.entity = null;
    this.agrupation = null;
    this.devices = 0;
    this.mbus = 0;
    this.wavenis = 0;
    this.une = 0;
    this.external = 0;
    this.sensors = 0;
    this.satelites = 0;
    this.concentrators = 0;
    this.withoutCom = 0;
    this.noCom = 0;
    this.noAssign = 0;
    this.withAlarms = 0;
  }
}
