import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "./ToastService.service";

@Injectable({
  providedIn: "root",
})
export class FileImportService {
  constructor(
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  // Reseteo del fichero seleccionado
  resetFile(element: HTMLInputElement): void {
    element.value = null;
  }

  // Comprobación de si el archivo elegido tiene la extensión correcta
  handleFileInputKeys(event: any, format?: string): File {
    let formatCheck = true;
    if (format) {
      let formats = format.split(", ");
      formatCheck = formats.some((format) =>
        event.item(0).name.toLowerCase().includes(format)
      );
    } else {
      formatCheck = !event.item(0).name.toLowerCase().includes(".csv");
    }

    if (!formatCheck) {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("wrong-file")
      );
      return null;
    }
    return event.item(0);
  }

  // Comprobación de si el archivo elegido tiene la extensión correcta
  handleMultipleFileInputKeys(event: any, format?: string): File[] {
    let formatCheck: boolean;
    let filesToImport: File[] = [];
    for (let file in event) {
      if (event[file].name && event[file].name != "item") {
        if (format) {
          let formats = format.split(", ");
          formatCheck = formats.some((format) =>
            event[file].name.includes(format)
          );
        } else {
          formatCheck = event[file].name.includes(".csv");
        }

        if (!formatCheck) {
          this.ToastService.fireToast(
            "error",
            this.translate.instant("wrong-file")
          );
        }
        filesToImport.push(event[file]);
      }
    }
    return filesToImport;
  }
}
