import { Injectable } from "@angular/core";
import * as CryptoJS from "crypto-js";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";

const SecureStorage = require("secure-web-storage");
const SECRET_KEY = PROFILES.ARSON;

@Injectable({
  providedIn: "root",
})
export class BrowserStorageSecureService {
  constructor() {}

  public secureLocalStorage = new SecureStorage(localStorage, {
    hash: function hash(key: any) {
      key = CryptoJS.SHA256(key);

      return key.toString();
    },
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);

      data = data.toString();

      return data;
    },
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return data;
    },
  });

  public secureSessionStorage = new SecureStorage(sessionStorage, {
    hash: function hash(key: any) {
      key = CryptoJS.SHA256(key);

      return key.toString();
    },
    encrypt: function encrypt(data: any) {
      data = CryptoJS.AES.encrypt(data, SECRET_KEY);

      data = data.toString();

      return data;
    },
    decrypt: function decrypt(data: any) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return data;
    },
  });
}
