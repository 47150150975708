<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "sniffer-list" | translate }} : {{ unidadVenta }}</b>
        </h5>
      </div>
      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'gatewaysSniffer'"
          [columns]="columns"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [tableGlobalActions]="tableGlobalActions"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          (tableAction)="tableActions($event)"
          (tableGlobalAction)="tableGlobalAction($event)"
          [data]="tableData"
          [maxReg]="20"
          [quickFilters]="tableQuickFilters"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
