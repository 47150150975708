export const METER_IMPORT_COLUMNS = [
  {
    title: "CLAVE_MAESTRA",
    info: "cups-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: "NRO_SERIE_ACTUAL",
    info: "nro-serie-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
  {
    title: "NRO_SERIE_NUEVO",
    info: "nro-serie-import",
    required: true,
    profilesList: [],
    preselect: [],
  },
];

export interface ImportResponseDevice {
  // BackEnd
  claveMaestra: string;
  deviceActual: number;
  deviceNuevo: number;
  uploadState: boolean;
  codError: number;

  // FrontEnd
  responseCodeText?: string;
}
