export const CONSUMPTION_PROFILES = {
  0: "DWELLINGS",
  1: "HOUSE_WITHOUT_POOL",
  2: "HOUSE_WITH_POOL",
  3: "WEEK_HOUSE",
  4: "WEEKEND_HOUSE",
  5: "SEASONAL_HOUSE",
  6: "TERTIARY",
  7: "INDUSTRIAL",
  9: "RIEGO",
  DWELLINGS: 0,
  HOUSE_WITHOUT_POOL: 1,
  HOUSE_WITH_POOL: 2,
  WEEK_HOUSE: 3,
  WEEKEND_HOUSE: 4,
  SEASONAL_HOUSE: 5,
  TERTIARY: 6,
  INDUSTRIAL: 7,
  RIEGO: 9,
};

export interface ConsumptionProfile {
  id?: number;
  profileId: number;
  profileName?: string;
  profilePatterns?: {
    hourly?: number[];
    sdHourly?: number[];
    yearly?: number[];
    sdYearly?: number[];
  };
  profileSimilarity?: number;
  meterId?: number;
  meterPattern?: number[];
  meterSimilarity?: number;
}
