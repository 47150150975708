import { Injectable } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";
import { LANGUAGE } from "../language/LanguageController.service";

@Injectable({
  providedIn: "root",
})
export class DatePickerConfigService {
  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  // Opciones del selector de rango de fechas
  setDateRangePickerOptions(): any {
    let daterangePickerRanges: any;
    let daterangePickerLang = {
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      applyLabel: this.translate.instant("accept"),
      customRangeLabel: this.translate.instant("custom-range"),
    };

    switch (this.SessionDataService.getCurrentLanguage()) {
      case LANGUAGE.ESPANOL:
        daterangePickerRanges = {
          Hoy: [moment(), moment()],
          Ayer: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          "Últimos 7 días": [moment().subtract(6, "days"), moment()],
          "Últimos 30 días": [moment().subtract(29, "days"), moment()],
          "Este mes": [moment().startOf("month"), moment().endOf("month")],
          "Mes pasado": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        };
        break;
      case LANGUAGE.CATALAN:
        daterangePickerRanges = {
          Avui: [moment(), moment()],
          Ahir: [moment().subtract(1, "days"), moment().subtract(1, "days")],
          "Últims 7 dies": [moment().subtract(6, "days"), moment()],
          "Últims 30 dies": [moment().subtract(29, "days"), moment()],
          "Aquest mes": [moment().startOf("month"), moment().endOf("month")],
          "Últim mes": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        };
        break;
      default:
        daterangePickerRanges = {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days"),
          ],
          "Last 7 days": [moment().subtract(6, "days"), moment()],
          "Last 30 days": [moment().subtract(29, "days"), moment()],
          "This month": [moment().startOf("month"), moment().endOf("month")],
          "Last month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
          ],
        };
        break;
    }

    return {
      daterangePickerLang: daterangePickerLang,
      daterangePickerRanges: daterangePickerRanges,
    };
  }
}
