import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { from } from "rxjs";
import { map } from "rxjs/operators";
// Translata
import { TranslateService } from "@ngx-translate/core";
// Turf
import * as Turf from "@turf/turf";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Cesium
import {
  ViewerConfiguration,
  ActionType,
  MapsManagerService,
  AcEntity,
  AcLayerComponent,
} from "angular-cesium";
declare var Cesium: any;
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
// Variables
import { CESIUM_MAP_CONSTS } from "../CESIUM_MAP_CONSTS";
import { MAP_LAYERS } from "../../map-module/map-variables/MAP_TYPES";
// Interfaces
import { PanelMenuOption } from "../../material-module/MaterialInterface.type";

@Component({
  selector: "app-cesium-map",
  templateUrl: "./cesium-map.component.html",
  styleUrls: ["./cesium-map.component.scss"],
  providers: [ViewerConfiguration],
})
export class CesiumMapComponent implements OnInit, AfterViewInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Encuadre
  @Input()
  get bounds(): [] {
    return this._bounds;
  }
  set bounds(bounds: []) {
    if (bounds?.length > 0) {
      this._bounds = this.getBounds(bounds);
    }
  }
  _bounds: any;

  // Cesium
  cesiumBuildingsOptions: object = {
    url: Cesium.IonResource.fromAssetId(96188),
  };
  buildings: boolean = true;
  map: any;
  viewer: any;
  camera: any;
  @ViewChild("cesiumMap") cesiumMap: ElementRef;
  cesiumOptionsReady: boolean = false;

  // Capas
  @Input()
  get mapData(): any {
    return this._mapData;
  }
  set mapData(mapData: any) {
    if (mapData) {
      // Ocultar capas
      this.hideLayers = true;
      this.loading = true;
      this.disableCluster = false;
      // Actualización de datos del mapa
      this._mapData = mapData;
      // Capas de dispositivos
      this.getLayers();
      // Leyenda
      this.getLegend();
      // Menú
      this.setMapMenuOptions();
      // Capas de agrupaciones
      if (this.drawAgrupationOutline && this._agrupationLayerData) {
        this.agrupationLayer = this.setPolygonalLayer(
          this.agrupationLayerData,
          "agrupation"
        );
      }
      // Visualización de capas
      setTimeout(() => {
        this.hideLayers = false;
        setTimeout(() => {
          if (this.viewer) {
            this.setCluster(this.cluster);
            this.viewer.camera.setView({
              destination: Cesium.Rectangle.fromDegrees(
                this._bounds[0],
                this._bounds[1],
                this._bounds[2],
                this._bounds[3]
              ),
              orientation: {
                heading: Cesium.Math.toRadians(0.0),
                pitch: Cesium.Math.toRadians(-90),
                roll: 0.0,
              },
            });
            this.camera.zoomOut(1000);
            this.resetKml();
            setTimeout(() => (this.loading = false), 0);
          }
        }, 0);
      }, 0);
    }
  }
  _mapData: any;
  @Input() mapType: string;
  devices: any[];
  gateways: any[];
  locations: any[];
  hideLayers: boolean;
  loading: boolean = false;

  // Agrupaciones
  @Input() drawAgrupationOutline: boolean;
  @Input()
  get agrupationLayerData(): any[] {
    return this._agrupationLayerData;
  }
  set agrupationLayerData(agrupationLayerData: any[]) {
    this._agrupationLayerData = agrupationLayerData;
    if (this.drawAgrupationOutline) {
      this.agrupationLayer = this.setPolygonalLayer(
        this.agrupationLayerData,
        "agrupation"
      );
    }
  }
  _agrupationLayerData: any;
  agrupationPolygonActive: boolean = true;

  // Cobertura
  @Input()
  get coverageLayersData(): any[] {
    return this._coverageLayersData;
  }
  set coverageLayersData(coverageLayersData: any[]) {
    if (coverageLayersData?.length > 0) {
      this.resetCoverageLayers();
      setTimeout(() => {
        this._coverageLayersData = coverageLayersData;
        this.setCoverageLayers();
        this.getCoverageLegend();
        this.showCoverage = true;
        this.disableCluster = true;
        this.cluster = false;
        this.setMapMenuOptions();
      }, 0);
    }
  }
  _coverageLayersData: any;
  showCoverage: boolean;
  @Input() allowKml: boolean;
  @ViewChild("kmlImportInput") kmlImportInput: ElementRef;
  kmlFiles: any[];
  kmlActive: boolean = false;
  kmlDataSources: any[];
  @Output() updateLocationsFlag = new EventEmitter<any>();

  // Capas
  layerList: any[];
  linkLayer: any;
  locationLinkLayer: any;
  gatewayLayer: any;
  locationLayer: any;
  okLayer: any;
  noComunicaLayer: any;
  noAsignadoLayer: any;
  concentratorOkLayer: any;
  concentratorNoComunicaLayer: any;
  concentratorNoAsignadoLayer: any;
  noLoraLayer: any;
  noLoraLayerNoCom: any;
  mbusLayer: any;
  mbusComLayer: any;
  wavenisLayer: any;
  uneOkLayer: any;
  uneNoComunicaLayer: any;
  txnLayer: any;
  selectedLayer: any;
  activeLayers: object = {};
  agrupationLayer: any;
  coverageTotalLayer: any;
  coverageHighLayer: any;
  coverageMidLayer: any;
  coverageLowLayer: any;
  coverageCriticalLayer: any;
  coverageNullLayer: any;
  coverageOkLayer: any;
  coverageErrorLayer: any;

  // Ac Layers
  @ViewChild("gatewayLayer") gatewayAcLayer: AcLayerComponent;
  @ViewChild("locationLayer") locationAcLayer: AcLayerComponent;
  @ViewChild("okLayer") okAcLayer: AcLayerComponent;
  @ViewChild("noComunicaLayer") noComunicaAcLayer: AcLayerComponent;
  @ViewChild("noAsignadoLayer") noAsignadoAcLayer: AcLayerComponent;
  @ViewChild("concentratorOkLayer") concentratorOkAcLayer: AcLayerComponent;
  @ViewChild("concentratorNoComunicaLayer")
  concentratorNoComunicaAcLayer: AcLayerComponent;
  @ViewChild("concentratorNoAsignadoLayer")
  concentratorNoAsignadoAcLayer: AcLayerComponent;
  @ViewChild("noLoraLayer") noLoraAcLayer: AcLayerComponent;
  @ViewChild("noLoraLayerNoCom") noLoraNoComAcLayer: AcLayerComponent;
  @ViewChild("mbusLayer") mbusAcLayer: AcLayerComponent;
  @ViewChild("mbusComLayer") mbusComAcLayer: AcLayerComponent;
  @ViewChild("wavenisLayer") wavenisAcLayer: AcLayerComponent;
  @ViewChild("uneOkLayer") uneOkAcLayer: AcLayerComponent;
  @ViewChild("uneNoComunicaLayer") uneNoComunicaAcLayer: AcLayerComponent;
  @ViewChild("txnLayer") txnAcLayer: AcLayerComponent;
  @ViewChild("selectedLayer") selectedAcLayer: AcLayerComponent;
  @ViewChild("agrupationAcLayer") agrupationAcLayer: AcLayerComponent;
  @ViewChild("linkAcLayer") linkAcLayer: AcLayerComponent;
  @ViewChild("locationLinkAcLayer") locationLinkAcLayer: AcLayerComponent;
  @ViewChild("coverageTotal") coverageTotalAcLayer: AcLayerComponent;
  @ViewChild("coverageHigh") coverageHighAcLayer: AcLayerComponent;
  @ViewChild("coverageMid") coverageMidAcLayer: AcLayerComponent;
  @ViewChild("coverageLow") coverageLowAcLayer: AcLayerComponent;
  @ViewChild("coverageCritical") coverageCriticalAcLayer: AcLayerComponent;
  @ViewChild("coverageNull") coverageNullAcLayer: AcLayerComponent;
  @ViewChild("coverageOkLayer") coverageOkAcLayer: AcLayerComponent;
  @ViewChild("coverageErrorLayer") coverageErrorAcLayer: AcLayerComponent;

  // Entidades
  heightReference = Cesium.HeightReference.RELATIVE_TO_GROUND;
  verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
  linkMaterial = new Cesium.PolylineDashMaterialProperty({
    color: Cesium.Color.CYAN,
  });
  selectedEntity: any;

  // Eventos
  mapEventManager: any;
  clickEvent: any;

  // Cluster
  cluster: boolean = true;
  disableCluster: boolean;
  deviceLimitReached: boolean = false;
  clusterPixelRange: number = 50;
  controlsToggled: boolean = false;

  // Tooltip
  @Output() updateTooltip = new EventEmitter<any>();
  @Output() updateLocationTooltip = new EventEmitter<any>();
  @Input()
  get updatedEntity(): any {
    return this._updatedEntity;
  }
  set updatedEntity(updatedEntity: any) {
    this._updatedEntity = updatedEntity;
    this.selectedEntity = this._updatedEntity;
  }
  _updatedEntity: any;

  // Reset
  @Output() mapReset = new EventEmitter<any>();

  // Leyenda
  mapLegend: { deviceType: string; color: string }[] = [];

  // Canvas
  canvas: any;
  ctx: any;

  // Acción de mapa
  @Output() action = new EventEmitter<any>();

  // Opciones del panel
  mapMenuOptions: PanelMenuOption[];

  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this.kmlDataSources?.length > 0) {
        this.removeKml();
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MapsManagerService: MapsManagerService,
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewerConf: ViewerConfiguration
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.setMapMenuOptions();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.setMapViewerOptions();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clickEvent();
    this.viewer?.entities?.removeAll();
    this.viewer?.scene?.primitives?.removeAll();
    this.viewer?.destroy();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de las opciones del menú del mapa
  setMapMenuOptions(): void {
    this.mapMenuOptions = [
      {
        action: "2d",
        icon: "fas fa-map",
        text: "2D",
        visible: true,
      },
      {
        action: "cesium-buildings-deactivate",
        icon: "fas fa-building",
        text: this.translate.instant("cesium-buildings-deactivate"),
        visible: true,
      },
      {
        action: "agrupation-toggle",
        icon: "fas fa-map",
        text: this.translate.instant(
          this.agrupationPolygonActive ? "agrupations-hide" : "agrupations-show"
        ),
        visible: this.drawAgrupationOutline,
      },
      {
        action: "cluster-toggle",
        icon: "fa-regular fa-circle-dot",
        text: this.translate.instant(
          this.cluster ? "hide-cluster" : "show-cluster"
        ),
        disabled: this.disableCluster,
        range: {
          min: 1,
          max: 100,
          value: 50,
          text: this.translate.instant("cluster-range"),
          icon: "fas fa-object-group",
          disabled: !this.cluster,
        },
        visible: true,
      },
      {
        action: "kml",
        icon: "fas fa-layer-group",
        text: "KML",
        visible: this.allowKml,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "2d":
        this.deactivate3dMap();
        break;
      case "cesium-buildings-deactivate":
        this.buildings = !this.buildings;
        break;
      case "kml":
        this.kmlImportInput.nativeElement.click();
        break;
      case "agrupation-toggle":
        this.agrupationPolygonActive = !this.agrupationPolygonActive;
        this.setMapMenuOptions();
        break;
      case "cluster-toggle":
        this.cluster = !this.cluster;
        this.toggleCluster(this.cluster);
        this.setMapMenuOptions();
        break;
      default:
        break;
    }
  }

  // Obtención de la capa con todos los dispositivos
  getLayers(): void {
    this.layerList = [];
    for (let layer in this._mapData) {
      this.layerList.push({
        type: layer,
        name: this._mapData[layer].layerData.name,
      });

      // Gestión de capas activas
      if (this._mapData[layer].cesiumLayer.length > 0) {
        this.activeLayers[layer] = true;
      } else {
        this.activeLayers[layer] = null;
      }

      // Tipos de capas
      switch (layer) {
        case MAP_LAYERS.GATEWAYS:
          this.gatewayLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.GATEWAYS_OK:
          this.gatewayLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.GATEWAYS_ERROR:
          this.gatewayLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.LOCATIONS:
          this.locationLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.OK:
          this.okLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.NO_COMUNICA:
          this.noComunicaLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.NO_ASIGNADO:
          this.noAsignadoLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.CONCENTRATOR_OK:
          this.concentratorOkLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.CONCENTRATOR_NO_COMUNICA:
          this.concentratorNoComunicaLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.CONCENTRATOR_NO_RECIBIDO:
          this.concentratorNoComunicaLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.CONCENTRATOR_NO_ASIGNADO:
          this.concentratorNoAsignadoLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.NO_LORA:
          this.noLoraLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.NO_LORA_NO_COM:
          this.noLoraLayerNoCom = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.MBUS:
          this.mbusLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.MBUS_COMUNICA:
          this.mbusComLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.WAVENIS:
          this.wavenisLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.UNE_OK:
          this.uneOkLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.UNE_NO_COMUNICA:
          this.uneNoComunicaLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.TXN:
          this.txnLayer = this.setLayer(this._mapData[layer].cesiumLayer);
          break;
        case MAP_LAYERS.COVERAGE_OK:
          this.coverageOkLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
        case MAP_LAYERS.COVERAGE_ERROR:
          this.coverageErrorLayer = this.setLayer(
            this._mapData[layer].cesiumLayer
          );
          break;
      }
    }

    if (this.mapType != "coverage") {
      // Capa de links
      this.getDevices();
      this.linkLayer = this.getObservable(this.devices).pipe(
        map((device: any) => ({
          id: device.id,
          actionType: ActionType.ADD_UPDATE,
          entity: device,
        }))
      );
    }
  }

  // Seteo de la capa de agrupaciones
  setPolygonalLayer(data: any, type: string): void {
    return this.getObservable(
      data.filter((element: any) => element.positions.length > 0)
    ).pipe(
      map((element: any) => ({
        id: element.id,
        actionType: ActionType.ADD_UPDATE,
        entity: new AcEntity({
          id: element.id,
          name: element.name,
          type: type,
          tooltip: element.tooltip,
          hierarchy: Cesium.Cartesian3.fromDegreesArray(element.positions),
          color: element.color,
          show: true,
        }),
      }))
    );
  }

  // Seteo de las capas de cobertura
  setCoverageLayers(): void {
    this._coverageLayersData.forEach((coverage: any, i) => {
      let coverageLayerData = [];

      // Gestión de capas activas
      if (coverage.positions.length > 0) {
        this.activeLayers[coverage.id] = true;
      } else {
        this.activeLayers[coverage.id] = null;
      }

      // Datos de cada grupo de posiciones
      coverage.positions.forEach((position: number[], j) => {
        // Entidad por coordenada
        if (position?.length > 0) {
          coverageLayerData.push(
            new AcEntity({
              id: i + j,
              name: coverage.name,
              color: coverage.color,
              point: Cesium.Cartesian3.fromDegrees(position[1], position[0], 1),
              tooltip: coverage.tooltip,
              type: "coverage",
              show: true,
            })
          );
        }
      });

      // Creación de capa
      this.setCoverageLayer(this.setLayer(coverageLayerData), coverage.id);
    });
  }

  // Obtención de capa de cobertura
  setCoverageLayer(layer: any, layerId: string): any {
    switch (layerId) {
      case "coverageTotal":
        this.coverageTotalLayer = layer;
        break;
      case "coverageHigh":
        this.coverageHighLayer = layer;
        break;
      case "coverageMid":
        this.coverageMidLayer = layer;
        break;
      case "coverageLow":
        this.coverageLowLayer = layer;
        break;
      case "coverageCritical":
        this.coverageCriticalLayer = layer;
        break;
      case "coverageNull":
        this.coverageNullLayer = layer;
        break;
    }
  }

  // Seteo de capa por tipo
  setLayer(cesiumLayer: any): any {
    return this.getObservable(cesiumLayer).pipe(
      map((element: any, i) => ({
        id: element.id ? element.id : i,
        actionType: ActionType.ADD_UPDATE,
        entity: element,
      }))
    );
  }

  // Actualización de cobertura de gateway
  updateCoverage(): void {
    this.coverageOkLayer = null;
    setTimeout(() => {
      this.coverageOkLayer = this.setLayer(
        this._mapData[MAP_LAYERS.COVERAGE_OK].cesiumLayer
      );
    }, 0);
  }

  // Obtención de todos los dispositivos
  getDevices(): void {
    this.gateways = [];
    this.locations = [];
    this.devices = [];

    for (let layer in this._mapData) {
      // Gateways
      if (layer.toUpperCase().includes(MAP_LAYERS.GATEWAYS)) {
        this.gateways = this.gateways.concat(
          this._mapData[layer].cesiumLayer.map((gateway: any) => {
            return gateway;
          })
        );
        // Localizaciones
      } else if (layer.toUpperCase().includes(MAP_LAYERS.LOCATIONS)) {
        this.locations = this.locations.concat(
          this._mapData[layer].cesiumLayer.map((location: any) => {
            return location;
          })
        );
      } else {
        this.devices = this.devices.concat(
          this._mapData[layer].cesiumLayer.map((device: any) => {
            return device;
          })
        );
      }
    }
  }

  // Obtención del observable de capa
  getObservable(data: any[]): any {
    return from(data);
  }

  // Obtención del encuadre del mapa
  getBounds(bounds: any[][]): any {
    if (bounds?.length > 1) {
      let line = Turf.lineString(
        bounds.map((coord: any) => {
          return [parseFloat(coord[1]), parseFloat(coord[0])];
        })
      );
      return Turf.bbox(line);
    } else {
      return [bounds[0][1], bounds[0][0], bounds[0][1], bounds[0][0]];
    }
  }

  // Seteo de las opciones del mapa
  setMapViewerOptions(): void {
    // viewerOptions will be passed the Cesium.Viewer contstuctor
    this.viewerConf.viewerOptions = {
      selectionIndicator: false,
      timeline: false,
      infoBox: false,
      fullscreenButton: true,
      fullscreenElement: this.cesiumMap.nativeElement,
      terrainProvider: Cesium.createWorldTerrain(),
      imageryProvider: Cesium.IonImageryProvider({ assetId: 3954 }),
      baseLayerPicker: false,
      animation: false,
      shouldAnimate: false,
      homeButton: false,
      geocoder: false,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: false,
      sceneModePicker: false,
      mapMode2D: Cesium.MapMode2D.ROTATE,
    };

    setTimeout(() => (this.cesiumOptionsReady = true), 0);
    setTimeout(() => {
      this.viewer = this.MapsManagerService.getMap().getCesiumViewer();
      // this.viewer.canvas.getContext("2d", { willReadFrequently: true });
      this.camera = this.MapsManagerService.getMap().getCameraService();
      this.setEventHandlers();
    }, 0);
  }

  // Escucha de eventos de mapa
  setEventHandlers(): void {
    this.clickEvent = this.viewer.selectedEntityChanged.addEventListener(
      (selectedEntity: any) => this.clickEventAction(selectedEntity)
    );
  }

  // Acción de click
  clickEventAction(selectedEntity: any): void {
    if (this.selectedEntity) {
      this.removeEntitySelected();
    }
    this.checkEntity(selectedEntity);

    // if (this.selectedEntity) {
    //   this.removeEntitySelected();
    // } else {
    //   this.checkEntity(selectedEntity);
    // }
  }

  // Actualización de nueva selección
  checkEntity(selectedEntity: any): void {
    if (Cesium.defined(selectedEntity)) {
      // this.goToEntity(selectedEntity);
      this.setEntitySelected(selectedEntity);
    }
  }

  // Movimiento del mapa a entidad
  goToEntity(entity: any): void {
    this.camera.flyTo(entity, {
      duration: 1.0,
      offset: new Cesium.HeadingPitchRange(
        this.viewer.camera.heading,
        this.viewer.camera.pitch,
        10000
      ),
    });
  }

  // Movimiento del mapa al gateway seleccionado
  goToGateway(device: any): void {
    let gateway: any = this.gateways.find(
      (gateway: any) => gateway.name == device.acEntity.gateway
    );
    let coords = Cesium.Cartographic.fromCartesian(gateway.position);
    this.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromRadians(
        coords.longitude,
        coords.latitude,
        1000
      ),
      duration: 1.0,
    });
  }

  // Seteo de entidad seleccionada
  setEntitySelected(entity: any) {
    if (entity?.acEntity?.type == "gateway") {
      entity.billboard.scale = 1.5;
      this.selectedEntity = entity;
    } else if (entity?.acEntity?.type == "location") {
      if (this.mapType == "coverage") {
        this.action.emit({
          action: "updateMetersCoverage",
          data: entity.acEntity,
        });
      }
      this.selectedEntity = entity;
      this.updateLocationTooltip.emit(entity);
    } else if (entity?.acEntity?.type == "device") {
      entity.point.pixelSize = 20;
      entity.point.outlineColor = Cesium.Color.WHITE;
      entity.point.outlineWidth = 2;
      this.selectedEntity = entity;
      if (this.mapType != "coverage") {
        this.setGatewayLink(entity, true);
        this.updateTooltip.emit(entity);
      } else {
        this.getLocationLinkLayer(entity.acEntity);
        if (entity.acEntity.timestamp) {
          this.updateLocationsFlag.emit(entity.acEntity);
        }
      }
    } else if (entity?.acEntity?.type == "agrupation") {
      this.selectedEntity = entity;
    }
  }

  // Actualización de imagen de los gateways
  updateLocations(): void {
    this.locationLayer = null;
    setTimeout(() => {
      this.locationLayer = this.setLayer(
        this._mapData[MAP_LAYERS.LOCATIONS].cesiumLayer
      );
    }, 0);
  }

  // Reseteo de entidad seleccionada
  removeEntitySelected() {
    if (this.selectedEntity?.acEntity?.type == "gateway") {
      this.selectedEntity.billboard.scale = 1;
    } else if (this.selectedEntity?.acEntity?.type == "location") {
      this.selectedEntity.billboard.scale = 1;
      if (this.mapType == "coverage") {
        this.action.emit({
          action: "updateMetersCoverage",
          data: null,
        });
      }
    } else if (this.selectedEntity?.acEntity.type == "device") {
      this.selectedEntity.point.pixelSize = 12;
      this.selectedEntity.point.outlineColor = Cesium.Color.BLACK;
      this.selectedEntity.point.outlineWidth = 1;
      if (this.mapType != "coverage") {
        this.setGatewayLink(this.selectedEntity, false);
      } else {
        this.updateLocationsFlag.emit(null);
        this.destroyLocationLinkLayer();
      }
    }
    this.selectedEntity = null;
  }

  // Creación de capa de links a localizaciones
  getLocationLinkLayer(entity: any): void {
    this.locationLinkLayer = this.getObservable(entity.locations).pipe(
      map((location: any, i) => ({
        id: location.id,
        actionType: ActionType.ADD_UPDATE,
        entity: [entity.position, location.position],
      }))
    );
  }

  // Destrucción de capa de links a localizaciones
  destroyLocationLinkLayer(): void {
    this.locationLinkLayer = null;
    this.locationLinkAcLayer?.removeAll();
  }

  // Visualización del link entre dispositivo y gateway
  setGatewayLink(entity: any, show: boolean): void {
    this.viewer.dataSources._dataSources.forEach((dataSource: any) => {
      if (dataSource.name == "polyline") {
        let linkEntity = dataSource.entities?.values.find(
          (dataEntity: any) => dataEntity.acEntity.id == entity.acEntity.id
        );
        if (linkEntity && linkEntity.polyline) {
          linkEntity.polyline.show = show;
        }
      }
    });
  }

  // Seteo de cluster
  setCluster(show: boolean): void {
    this.viewer.dataSources?._dataSources.forEach((dataSource: any) => {
      if (dataSource.name == "point") {
        dataSource.clustering.enabled = show;
        dataSource.clustering.pixelRange = this.clusterPixelRange;
        dataSource.clustering.clusterEvent.addEventListener(
          (entities: any, cluster: any) => {
            cluster.label.show = false;
            cluster.billboard.show = true;
            cluster.billboard.id = cluster.label.id;
            cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.BOTTOM;
            cluster.billboard.heightReference = this.heightReference;
            cluster.billboard.image = new Cesium.PinBuilder()
              .fromText(
                entities.length.toLocaleString(),
                entities[0]?.acEntity?.color
                  ? entities[0]?.acEntity?.color
                  : Cesium.Color.WHITE,
                48
              )
              .toDataURL();
          }
        );
      }
    });
  }

  // Activación/Desactivación de cluster
  toggleCluster(show: boolean): void {
    this.viewer.dataSources?._dataSources.forEach((dataSource: any) => {
      if (dataSource.name == "point") {
        dataSource.clustering.enabled = show;
      }
    });
  }

  // Actualización del rango de cluster
  updateClusterRange(range: number): void {
    this.clusterPixelRange = range;
    this.viewer.dataSources?._dataSources.forEach((dataSource: any) => {
      if (dataSource.name == "point") {
        dataSource.clustering.pixelRange = this.clusterPixelRange;
      }
    });
  }

  // Desactivación de mapa
  deactivate3dMap(): void {
    this.SessionDataService.sendCesiumData({ active: false });
  }

  // Limpieza de mapa
  resetMap(): void {
    this.SessionDataService.sendSpinnerText(
      this.translate.instant("cleaning-memory")
    );
    this.spinner.show("spinner-hard");
    setTimeout(() => {
      // Reseteo de capas
      this.resetLayers();
      // Eliminar entidad seleccionada
      if (this.selectedEntity) {
        this.removeEntitySelected();
      }
      // Activación de clústeres
      this.cluster = true;
      this.clusterPixelRange = 50;
      // Activación de agrupaciones
      this.agrupationPolygonActive = true;
      // Reseteo de leyenda
      this.mapLegend = [];

      setTimeout(() => {
        this.spinner.hide("spinner-hard");
        this.SessionDataService.clearSpinnerText();
        this.mapReset.emit();
      }, 0);
    }, 100);
  }

  // Reseteo de capas
  resetLayers(): void {
    // Limpieza de capas
    this.gatewayAcLayer?.removeAll();
    this.locationAcLayer?.removeAll();
    this.okAcLayer?.removeAll();
    this.noComunicaAcLayer?.removeAll();
    this.noAsignadoAcLayer?.removeAll();
    this.concentratorOkAcLayer?.removeAll();
    this.concentratorNoComunicaAcLayer?.removeAll();
    this.concentratorNoAsignadoAcLayer?.removeAll();
    this.noLoraAcLayer?.removeAll();
    this.mbusAcLayer?.removeAll();
    this.mbusComAcLayer?.removeAll();
    this.wavenisAcLayer?.removeAll();
    this.uneOkAcLayer?.removeAll();
    this.uneNoComunicaAcLayer?.removeAll();
    this.txnAcLayer?.removeAll();
    this.selectedAcLayer?.removeAll();
    this.agrupationAcLayer?.removeAll();
    this.linkAcLayer?.removeAll();
    this.locationLinkAcLayer?.removeAll();
    this.coverageOkAcLayer?.removeAll();
    this.coverageErrorAcLayer?.removeAll();

    // Limpieza de datos
    this.linkLayer = null;
    this.locationLinkLayer = null;
    this.gatewayLayer = null;
    this.locationLayer = null;
    this.okLayer = null;
    this.noComunicaLayer = null;
    this.noAsignadoLayer = null;
    this.concentratorOkLayer = null;
    this.concentratorNoComunicaLayer = null;
    this.concentratorNoAsignadoLayer = null;
    this.noLoraLayer = null;
    this.mbusLayer = null;
    this.mbusComLayer = null;
    this.wavenisLayer = null;
    this.uneOkLayer = null;
    this.uneNoComunicaLayer = null;
    this.txnLayer = null;
    this.selectedLayer = null;
    this.agrupationLayer = null;
    this.allowKml = false;

    if (this._coverageLayersData?.length > 0) {
      this.resetCoverageLayers();
    }
  }

  // Reseteo de capas de cobertura
  resetCoverageLayers(): void {
    // Limpieza de capas
    this.coverageTotalAcLayer?.removeAll();
    this.coverageHighAcLayer?.removeAll();
    this.coverageMidAcLayer?.removeAll();
    this.coverageLowAcLayer?.removeAll();
    this.coverageCriticalAcLayer?.removeAll();
    this.coverageNullAcLayer?.removeAll();

    // Limpieza de datos
    this.coverageTotalLayer = null;
    this.coverageHighLayer = null;
    this.coverageMidLayer = null;
    this.coverageLowLayer = null;
    this.coverageCriticalLayer = null;
    this.coverageNullLayer = null;
    this.showCoverage = false;

    // Leyenda
    this.mapLegend = [];
  }

  // Obtención de leyenda del mapa
  getLegend(): void {
    for (let layer in this._mapData) {
      // Capas de dispositivos
      if (this.activeLayers[layer] != null) {
        let color = CESIUM_MAP_CONSTS.markerVariables[layer]?.color;
        if (color) {
          this.mapLegend.push({
            deviceType: this._mapData[layer].layerData.name,
            color: color.toLowerCase(),
          });
        }
      }
    }
    if (this.mapType == "coverage") {
      this.mapLegend.unshift({
        deviceType: this.translate.instant("other"),
        color: "yellow",
      });
    }
  }

  // Obtención de la leyenda del mapa de coberturas
  getCoverageLegend(): void {
    this._coverageLayersData.forEach((coverage: any) => {
      this.mapLegend.push({
        deviceType: coverage.name,
        color: coverage.cssColor,
      });
    });
  }

  // Acción de elemento de mapa
  mapAction(actionData: any): void {
    this.action.emit(actionData);
  }

  /***************************************************************************/
  // KML
  /***************************************************************************/

  // Carga de archivo KML
  loadKmlFile(e: any): void {
    this.kmlDataSources = [];
    this.kmlFiles = [];
    if (e.target.files?.length >= 2 && e.target.files?.length % 2 == 0) {
      let files = Object.keys(e.target.files);
      files.forEach((file, i) => {
        if (e.target.files[file]?.name) {
          this.parseDocument(e.target.files[file], i == files.length - 1);
        }
      });
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("cesium-kml-files-error")
      );
    }
  }

  // Parseo de archivo XML
  parseDocument(file: any, end: boolean): void {
    let fileReader = new FileReader();

    if (file?.name.includes("kml")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".kml", "");
        let fileFound = this.kmlFiles.find(
          (kmlFile: any) => kmlFile.id == fileId
        );
        if (fileFound) {
          fileFound.kml = e.target.result;
        } else {
          this.kmlFiles.push({
            id: fileId,
            kml: e.target.result,
          });
        }

        if (end) {
          this.showKml();
        }
      };
      fileReader.readAsText(file);
    } else if (file?.name.includes("png")) {
      fileReader.onload = (e: any) => {
        let fileId = file.name.replace(".png", "");
        let fileFound = this.kmlFiles.find(
          (kmlFile: any) => kmlFile.id == fileId
        );
        if (fileFound) {
          fileFound.image = e.target.result;
        } else {
          this.kmlFiles.push({
            id: fileId,
            image: e.target.result,
          });
        }

        if (end) {
          this.showKml();
        }
      };
      fileReader.readAsDataURL(file);
    }
  }

  // Visualizar KML
  showKml(): void {
    this.kmlFiles.forEach((kmlFile: any, i) => {
      this.kmlDataSources.push(new Cesium.KmlDataSource());
      this.kmlDataSources[this.kmlDataSources.length - 1].load(
        this.setKmlImage(kmlFile),
        {
          camera: this.viewer.scene.camera,
          canvas: this.viewer.scene.canvas,
        }
      );
      this.viewer.dataSources.add(this.kmlDataSources[i]);
    });
  }

  // Eliminar KML
  removeKml(): void {
    this.kmlDataSources.forEach((kmlDataSource: any) =>
      this.viewer.dataSources.remove(kmlDataSource)
    );
  }

  // Extracción de coordenadas
  setKmlImage(kmlFile: any): XMLDocument {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(kmlFile.kml, "text/xml");
    xmlDoc.getElementsByTagName("href")[0].innerHTML = kmlFile.image;
    return xmlDoc;
  }

  // Reseteo de capa KML
  resetKml(): void {
    if (this.kmlDataSources?.length > 0) {
      this.kmlDataSources.forEach((kmlDataSource: any) =>
        this.viewer.dataSources.remove(kmlDataSource)
      );
    }
    setTimeout(() => {
      this.kmlDataSources = null;
      this.kmlFiles = null;
      this.kmlActive = false;
    }, 0);
  }
}
