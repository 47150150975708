// @angular
import { Component, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { AlarmControllerService } from "../../../../../../services/server/AlarmController.service";
// Interfaces
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-alarms-entity-template-dialog",
  templateUrl: "./alarms-entity-template-dialog.component.html",
  styleUrls: ["./alarms-entity-template-dialog.component.scss"],
})
export class AlarmsEntityTemplateDialogComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga de plantilla en agrupaciones
  updateAgrupations(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("change-alarms")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.applyEntityAlarmsTemplate(
          this.data.selected.map((agrupation: Agrupation) => {
            return agrupation.id;
          }),
          this.data.entity
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-alarms-sucessfull")
            );
          }
          this.SessionDataService.sendDialogAction({ action: "close" });
        });
      }
    });
  }
}
