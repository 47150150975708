<div *ngIf="filters" class="cups-filters-container">
  <div class="cups-filters-title">
    <h4>{{ "filter-cups" | translate }}</h4>
  </div>
  <div class="cups-filters">
    <div *ngFor="let filter of filters" class="cups-filter">
      <label for="{{ filter.name | translate }}">{{
        filter.name | translate
      }}</label>
      <input
        type="text"
        name="{{ filter.name }}"
        placeholder="{{ filter.name | translate }}"
        [value]="filter.value"
        (change)="filter.value = $event.target.value"
        (keyup.enter)="filterData()"
      />
    </div>
  </div>
  <div class="cups-filters-buttons">
    <button type="button" class="btn btn-primary" (click)="filterData()">
      <i class="fas fa-filter"></i>
    </button>
    <button type="button" class="btn btn-primary" (click)="resetData()">
      <i class="fas fa-redo-alt"></i>
    </button>
  </div>
</div>
