<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b
            >{{ "configure" | translate }} {{ "gateway" | translate }}:
            {{ unidadVenta != null ? unidadVenta : ("no-data" | translate) }}</b
          >
        </h5>
      </div>

      <!-- Panel lateral -->
      <div class="general-panel">
        <div class="panel-sidebar">
          <div class="panel-sidebar-header">
            <i class="fas fa-cog"></i>
          </div>
          <!-- Datos de gateway -->
          <div
            class="icon-container"
            (click)="setActive('gateway')"
            [ngClass]="{ active: activeItem === 'gateway' }"
          >
            <i class="gateway-icon-gray"></i>
            <h5>{{ "gateway" | translate }}</h5>
          </div>
          <!-- Alimentación -->
          <div
            class="icon-container"
            (click)="setActive('supply')"
            [ngClass]="{ active: activeItem === 'supply' }"
          >
            <i class="fas fa-bolt"></i>
            <h5>{{ "supply" | translate }}</h5>
          </div>
          <!-- Red -->
          <div
            class="icon-container"
            (click)="setActive('network')"
            [ngClass]="{ active: activeItem === 'network' }"
          >
            <i class="fas fa-wifi"></i>
            <h5>{{ "network" | translate }}</h5>
          </div>
          <!-- Backup -->
          <div
            class="icon-container"
            (click)="setActive('backup')"
            [ngClass]="{ active: activeItem === 'backup' }"
          >
            <i class="fas fa-server"></i>
            <h5>Backup</h5>
          </div>
        </div>

        <div class="panel-body">
          <div>
            <!-- ********** CONFIGURACIÓN DE GW ************ -->
            <div *ngIf="activeItem == 'gateway'">
              <!-- BORRAR -->
              <h5>
                <b>{{ "erase" | translate }}</b>
              </h5>
              <div class="gateway-configuration-option-buttons">
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="deleteDatalogger()"
                >
                  <i class="fas fa-trash"></i>
                  {{ "delete-log" | translate }}
                </button>
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="deleteFrames()"
                >
                  <i class="fas fa-trash"></i>
                  {{ "delete-frames" | translate }}
                </button>
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="formatMemory()"
                >
                  <i class="fas fa-eraser"></i>
                  {{ "gateway-format-memory" | translate }}
                </button>
              </div>

              <hr />

              <!--APP EUI-->
              <div class="gateway-configuration-option">
                <h5>
                  <b>{{ "App EUI" | translate }} {{ "gateway" | translate }}</b>
                </h5>

                <div class="gateway-configuration-option-buttons">
                  <!-- PEDIR LISTADO DE APP-EUI -->
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="showAppEUI()"
                  >
                    <i class="fas fa-book-open"></i>
                    {{ "show-App-EUI" | translate }}
                  </button>
                  <!-- ELIMINAR LISTADO DE APP EUI -->
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="deleteAppEUI()"
                  >
                    <i class="fas fa-trash"></i>
                    {{ "delete-app-eui-list" | translate }}
                  </button>
                </div>
                <app-table-controller
                  *ngIf="appEUITableData"
                  [onlyTable]="true"
                  [rowNumbers]="true"
                  [data]="appEUITableData"
                  [columns]="appEUIListColumns"
                  [maxReg]="10"
                ></app-table-controller>
              </div>

              <hr />

              <!-- MODO INVIERNO -->
              <div class="gateway-configuration-option">
                <h5>
                  <b
                    >{{ "winter-mode" | translate }}
                    {{ "gateway" | translate }}</b
                  >
                </h5>
                <div class="gateway-configuration-option-form">
                  <!-- dataUp conocidos -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="dataUpKnown"
                      [(ngModel)]="dataUpKnown"
                      title="{{ 'dataup-known-description' | translate }}"
                    >
                      {{ "dataup-known" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- dataUp desconocidos -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="dataUpUnknown"
                      [(ngModel)]="dataUpUnknown"
                      title="{{ 'dataup-unknown-description' | translate }}"
                    >
                      {{ "dataup-unknown" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- JR main conocidos -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="jr_main_known"
                      [(ngModel)]="jr_main_known"
                      title="{{ 'jrMainUnknownDescription' | translate }}"
                    >
                      {{ "jr_main-known" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- JR red conocidos -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="jr_network_known"
                      [(ngModel)]="jr_network_known"
                      title="{{ 'jrNetworkKnownDescription' | translate }}"
                    >
                      {{ "jr_network-known" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- JR desconocidos -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="jr_unknown"
                      [(ngModel)]="jr_unknown"
                      title="{{ 'jrUnknownDescription' | translate }}"
                    >
                      {{ "jr-unknown" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- Propietario -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="propietary"
                      [(ngModel)]="propietary"
                      title="{{ 'propietaryDescription' | translate }}"
                    >
                      {{ "propietary" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- Alarmas -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="alarms"
                      [(ngModel)]="alarms"
                      title="{{ 'alarms-description' | translate }}"
                    >
                      {{ "alarms" | translate }}
                    </mat-checkbox>
                  </div>
                  <!-- Fichero -->
                  <div class="gateway-configuration-option-checkbox">
                    <mat-checkbox
                      type="checkbox"
                      [checked]="file"
                      [(ngModel)]="file"
                      title="{{ 'file-description' | translate }}"
                    >
                      {{ "file" | translate }}
                    </mat-checkbox>
                  </div>
                </div>
                <!-- Acciones -->
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="readWinter()"
                  >
                    <i class="fas fa-book-open"></i>
                    {{ "read" | translate }}
                  </button>
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="updateWinter()"
                  >
                    <i class="fas fa-save"></i>
                    {{ "save" | translate }}
                  </button>
                </div>
              </div>
              <hr />
            </div>

            <!-- ********** CONFIGURACIÓN DE BATERÍA ************ -->
            <div *ngIf="activeItem == 'supply'">
              <!-- Leer alimentación -->
              <div class="gateway-configuration-option">
                <h5>
                  <b
                    >{{ "show-tension" | translate }}
                    {{ "gateway" | translate }}</b
                  >
                </h5>
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="showTension()"
                  >
                    <i class="fas fa-bolt pointer"></i>
                    {{ "show-tension" | translate }}
                  </button>
                </div>
                <ul *ngIf="tension != null">
                  <li>
                    <b>Tension12v:</b>
                    {{
                      tension.tension12v != null
                        ? tension.tension12v + " v"
                        : null
                    }}
                  </li>
                  <li>
                    <b>Tension3v3:</b>
                    {{
                      tension.tension3v3 != null
                        ? tension.tension3v3 + " v"
                        : null
                    }}
                  </li>
                </ul>
              </div>

              <hr />

              <!-- Modificar carga -->
              <div class="gateway-configuration-option">
                <h5>
                  <b
                    >{{ "adjust-charge" | translate }}
                    {{ "gateway" | translate }}</b
                  >
                </h5>

                <!-- Tensión máxima de carga -->
                <div class="gateway-configuration-option-input">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "tension-charge" | translate }} (v)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="loadSetings.tensionMaximaCarga"
                      name="tensionMaximaCarga"
                      placeholder="{{ 'tension-charge' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <!-- Tensión de mantenimiento -->
                <div class="gateway-configuration-option-input">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "tension-management" | translate }} (v)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="loadSetings.tensionMantenimiento"
                      name="tensionMantenimiento"
                      placeholder="{{ 'tension-management' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <!-- Corriente máxima de mantenimiento -->
                <div class="gateway-configuration-option-input">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "stream-management" | translate }} (mA)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="loadSetings.corrienteMaximaCarga"
                      name="corrienteMaximaCarga"
                      placeholder="{{ 'stream-management' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <!-- Umbral de mantenimiento -->
                <div class="gateway-configuration-option-input">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "treshold-management" | translate }} (mA)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="loadSetings.umbralMantenimiento"
                      name="umbralMantenimiento"
                      placeholder="{{ 'treshold-management' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <!-- Umbral de carga rápida -->
                <div class="gateway-configuration-option-input">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "treshold-fastLoad" | translate }} (mA)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="loadSetings.umbralCargaRapida"
                      name="umbralCargaRapida"
                      placeholder="{{ 'treshold-fastLoad' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <!-- Controles -->
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="readChage()"
                  >
                    <i class="fas fa-book-open"></i>
                    {{ "read" | translate }}
                  </button>
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    [disabled]="
                      loadSetings?.tensionMaximaCarga == null ||
                      loadSetings?.tensionMaximaCarga == '' ||
                      loadSetings?.tensionMantenimiento == null ||
                      loadSetings?.tensionMantenimiento == '' ||
                      loadSetings?.corrienteMaximaCarga == null ||
                      loadSetings?.corrienteMaximaCarga == '' ||
                      loadSetings?.umbralMantenimiento == null ||
                      loadSetings?.umbralMantenimiento == '' ||
                      loadSetings?.umbralCargaRapida == null ||
                      loadSetings?.umbralCargaRapida == ''
                    "
                    (click)="updateChage()"
                  >
                    <i class="fas fa-save"></i>
                    {{ "save" | translate }}
                  </button>
                </div>
              </div>

              <hr />

              <!-- Caibración -->
              <h5>
                <b
                  >{{ "calibration" | translate }}
                  {{ "gateway" | translate }}</b
                >
              </h5>
              <div>
                <div class="gateway-configuration-option-tension">
                  <mat-form-field
                    class="agrupation-form-input"
                    appearance="standard"
                    *ngIf="sessionProfile == PROFILES.ARSON"
                  >
                    <mat-label>
                      <span>{{ "tension" | translate }} (v)</span>
                    </mat-label>
                    <input
                      matInput
                      type="number"
                      step="any"
                      min="0"
                      [(ngModel)]="voltage"
                      placeholder="{{ 'tension' | translate }}"
                      required
                    />
                  </mat-form-field>
                </div>
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    [disabled]="voltage == null"
                    (click)="calibration()"
                  >
                    <i class="fas fa-cogs"></i>
                    {{ "calibrate" | translate }}
                  </button>
                </div>
              </div>
              <hr />
            </div>

            <!-- ********** CONFIGURACIÓN DE RED ************ -->
            <div *ngIf="activeItem == 'network'">
              <div class="gateway-configuration-option">
                <!-- Dirección del servidor -->
                <h5>
                  <b>{{ "change-environment" | translate }}</b>
                </h5>
                <div class="gateway-configuration-option-select">
                  <app-material-select
                    [title]="'server-direction' | translate"
                    [bindValue]="'id'"
                    [bindLabel]="'descripcion'"
                    [data]="environtmentList"
                    (selectedOption)="environtment = $event?.id"
                  ></app-material-select>
                  <div class="gateway-configuration-select-checks">
                    <!-- Formatear memoria -->
                    <mat-checkbox
                      type="checkbox"
                      [checked]="formatGw"
                      [(ngModel)]="formatGw"
                    >
                      {{ "format-memory" | translate }}
                    </mat-checkbox>
                    <!-- Generar fichero -->
                    <mat-checkbox
                      type="checkbox"
                      [checked]="generateGwFile"
                      [(ngModel)]="generateGwFile"
                    >
                      {{ "generate-file" | translate }}
                    </mat-checkbox>
                  </div>
                </div>
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    [disabled]="environtment == null"
                    (click)="save()"
                  >
                    <i class="fas fa-save"></i>
                    {{ "save" | translate }}
                  </button>
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="showIp()"
                  >
                    <i class="fas fa-server"></i>
                    {{ "read-ip" | translate }}
                  </button>
                </div>
                <ul *ngIf="gatewayIp">
                  <li>
                    <b>{{ "server" | translate }}:</b> {{ gatewayIp.server }}
                  </li>
                  <li>
                    <b>{{ "IP" | translate }}:</b> {{ gatewayIp.ip }}
                  </li>
                  <li>
                    <b>{{ "port" | translate }}:</b> {{ gatewayIp.port }}
                  </li>
                </ul>
              </div>

              <hr />

              <!-- Cambio de dirección del servidor -->
              <div class="gateway-configuration-option">
                <h5>
                  <b>{{ "change-ip" | translate }}</b>
                </h5>
                <div class="gateway-configuration-option-select">
                  <app-material-select
                    [title]="'server-direction' | translate"
                    [bindValue]="'id'"
                    [bindLabel]="'descripcion'"
                    [data]="environtmentList"
                    (selectedOption)="environtment = $event?.id"
                  ></app-material-select>
                </div>
                <div class="gateway-configuration-option-buttons">
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    [disabled]="environtment == null"
                    (click)="save(true)"
                  >
                    <i class="fas fa-save"></i>
                    {{ "save" | translate }}
                  </button>
                  <button
                    mat-button
                    class="mat-button-icon-text"
                    (click)="showIp()"
                  >
                    <i class="fas fa-server"></i>
                    {{ "read-ip" | translate }}
                  </button>
                </div>
                <ul *ngIf="gatewayIp">
                  <li>
                    <b>{{ "server" | translate }}:</b> {{ gatewayIp.server }}
                  </li>
                  <li>
                    <b>{{ "IP" | translate }}:</b> {{ gatewayIp.ip }}
                  </li>
                  <li>
                    <b>{{ "port" | translate }}:</b> {{ gatewayIp.port }}
                  </li>
                </ul>
              </div>
              <hr />
            </div>
          </div>

          <!-- ********** CONFIGURACIÓN DE BACKUP ************ -->
          <div *ngIf="activeItem == 'backup'">
            <!-- Configurar operador -->
            <div
              class="gateway-configuration-option gateway-configuration-backups"
            >
              <h5>
                <b>{{ "gateway-configure-operator" | translate }}</b>
              </h5>
              <!-- Selector -->
              <mat-form-field appearance="fill">
                <mat-label>{{ "operator" | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedOperator">
                  <mat-option
                    *ngFor="let operator of operatorList"
                    [value]="operator"
                  >
                    {{ operator }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="gateway-configuration-option-buttons">
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="readOperator()"
                >
                  <i class="fas fa-book-open"></i>
                  {{ "read" | translate }}
                </button>
                <button
                  mat-button
                  class="mat-button-icon-text"
                  (click)="saveOperatorConfig()"
                  [disabled]="!selectedOperator"
                >
                  <i class="fas fa-save"></i>
                  {{ "save" | translate }}
                </button>
              </div>
              <div
                class="gateway-configuration-current-operators"
                *ngIf="currentOperators"
              >
                <div *ngFor="let operator of currentOperators">
                  <span
                    ><b>{{ "country" | translate }}: </b
                    >{{ operator.country }}</span
                  >
                  <span><b>PLMNS: </b>{{ operator.plmns }}</span>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</section>
