import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { GatewayService } from "../../GatewayService.service";
import { HomeControllerService } from "../../../../../services/server/HomeController.service";
import {
  GatewayLocationData,
  GATEWAY_STATES,
} from "../../../../../interfaces/GatewayGlobalInterface.type";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";

@Component({
  selector: "app-gateway-detail-dialog",
  templateUrl: "./gateway-detail-dialog.component.html",
  styleUrls: ["./gateway-detail-dialog.component.scss"],
})
export class GatewayDetailDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  locationData: GatewayLocationData[];
  currentEntity: Entity = this.SessionDataService.getCurrentEntity();
  currentEntityList: Entity[] = this.SessionDataService.getCurrentEntityList();
  readonly GATEWAY_STATES = GATEWAY_STATES;
  gatewayStates: object;
  newState: number;
  newChannels: number;
  newEntity: Entity;
  comments: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private DateParserService: DateParserService,
    private domSanitizer: DomSanitizer,
    public GatewayService: GatewayService,
    private GatewayController: GatewayControllerService,
    private RequestQueueService: RequestQueueService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Modal de detalle/edición
    if (this.data.action == "detail" || this.data.action == "edit") {
      this.data.sanitazedGatewayImage = this.data.gatewayImage
        ? this.domSanitizer.bypassSecurityTrustUrl(
            "data:image/png;base64, " + this.data.gatewayImage
          )
        : null;
    }

    // Modal de histórico de localizaciones
    if (this.data.action == "location") {
      this.getLocationData();
    }

    // Modal de cambio de estado
    if (this.data.action == "changeState") {
      this.setGatewayStates();
    }

    // Modal de actualización de canales
    if (this.data.action == "updateChannels" && this.data.gateway) {
      this.newChannels = this.data.gateway.canalesParsed;
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Estados
  setGatewayStates(): void {
    this.gatewayStates = {
      0: [
        {
          request: this.GatewayController.changeStateFromInStocklToAvailable(
            this.currentEntity.id,
            [this.data.gateway.id]
          ),
          state: this.translate.instant(GATEWAY_STATES[1]),
        },
      ],
      1: [
        {
          request: this.GatewayController.changeStateFromAvailableToInStock([
            this.data.gateway.id,
          ]),
          state: this.translate.instant(GATEWAY_STATES[0]),
        },
      ],
      2: [
        {
          request: this.GatewayController.changeStateFromActivatedToAvailable([
            { gateway: this.data.gateway.id, comments: this.comments },
          ]),
          state: this.translate.instant("deactivate"),
        },
        {
          request: this.GatewayController.changeStateFromActivatedToDamaged([
            { gateway: this.data.gateway.id, comments: this.comments },
          ]),
          state: this.translate.instant(GATEWAY_STATES[3]),
        },
      ],
      3: [
        {
          request: this.GatewayController.changeStateFromDamagedToRepair([
            { gateway: this.data.gateway.id, comments: this.comments },
          ]),
          state: this.translate.instant(GATEWAY_STATES[4]),
        },
        {
          request: this.GatewayController.changeStateToDiscarded([
            { gateway: this.data.gateway.id, comments: this.comments },
          ]),
          state: this.translate.instant(GATEWAY_STATES[5]),
        },
      ],
      4: [
        {
          request: this.GatewayController.changeStateFromRepairToAvailable([
            { gateway: this.data.gateway.id, comments: this.comments },
          ]),
          state: this.translate.instant("repaired"),
        },
        {
          request: this.GatewayController.changeStateToDiscarded([
            this.data.gateway.id,
          ]),
          state: this.translate.instant(GATEWAY_STATES[5]),
        },
      ],
    };
  }

  // Obtención de los datos de la localización
  getLocationData(): void {
    this.HomeController.getGatewayLocationData(this.data.gatewayId).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let locationData: GatewayLocationData[] = response["body"];
          locationData.forEach((location) => {
            location.timestampParsed = this.DateParserService.parseDate(
              location.timestamp,
              "L HH:mm:ss"
            );
          });
          this.locationData = locationData;
        }
      }
    );
  }

  // Edición de gateway
  editGateway(): void {
    if (this.data.updateImage) {
      let byteArray: Int8Array = new Int8Array(1);
      byteArray.fill(0);
      let img: File = this.data.finalImageSent
        ? this.data.finalImageSent
        : new File([byteArray], "img", { type: "image/png" });
      this.GatewayController.updateImage(
        this.data.gateway.unidadVenta,
        img
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.SessionDataService.sendReloadPanelFlag();
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      });
    }
  }

  // Eliminación de la imagen de gateway
  removeIcon(): void {
    this.data.finalImageSent = null;
    this.data.gatewayImage = null;
    this.data.sanitazedGatewayImage = null;
    this.data.updateImage = true;
  }

  // Cambio del archivo de imagen de gateway
  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.data.fileName = event.target.files[0].name;
      this.data.gatewayImage = null;
      this.data.finalImageSent = event.target.files[0];
      this.data.updateImage = true;

      // Actualización de imagen
      const READER = new FileReader();
      READER.onload = (e: any) => {
        this.data.sanitazedGatewayImage = e.target.result;
      };
      READER.readAsDataURL(event.target.files[0]);
    }
  }

  // Sustituir gateway
  replaceGateway(): void {
    this.GatewayService.replaceGateway(
      this.data.gateway,
      this.data.gatewayReplaceSelected
    );
  }

  // Actualización de estado
  updateState(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("gateway-change-state-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.setGatewayStates();
        this.gatewayStates[this.data.gateway.state][
          this.newState
        ]?.request.subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            let activated = response["body"][0]?.activated;
            // Actualización de imagen
            if (response["body"][0] && this.data.finalImageSent) {
              this.GatewayController.saveGatewayTaskImage(
                response["body"][0].eventId,
                this.data.finalImageSent
              ).subscribe((response) => {
                if (response["code"] == 0) {
                  this.ToastService.fireToast(
                    "success",
                    this.translate.instant("saved")
                  );
                }
                this.finishStateUpdate(activated);
              });
            } else {
              this.finishStateUpdate(activated);
            }
          }
        });
      }
    });
  }

  // Finalización de actualización de estado
  finishStateUpdate(activated: boolean): void {
    // Si está activado se quitan principales
    if (
      this.data.gateway.state == GATEWAY_STATES.ACTIVATED &&
      this.newState > GATEWAY_STATES.IN_STOCK &&
      this.data.gateway.mainMeters > 0
    ) {
      this.GatewayService.assignBestRedundantWithoutRead(this.data.gateway);
    }
    // Si está en reparación y se activa se refrescan puntos finales y APP EUI
    if (this.data.gateway.state == GATEWAY_STATES.REPAIR && activated) {
      this.GatewayService.refreshGateway(this.data.gateway);
      this.GatewayService.updateAppEui(this.data.gateway, true);
    }
    this.SessionDataService.sendDialogAction({ action: "close" });
    this.SessionDataService.sendReloadPanelFlag();
  }

  // Reasignación de dispositivos principales
  reassignMainDevices(): void {
    this.GatewayService.assignBestRedundantWithoutRead(this.data.gateway);
    this.SessionDataService.sendDialogAction({ action: "close" });
  }

  // Actualización de canales del gateway
  updateGatewayChannels(): void {
    if (this.data.gateway) {
      this.GatewayController.updateGatewayChannels(
        this.data.gateway.id,
        this.newChannels
      ).subscribe((response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast(
            "success",
            this.translate.instant("saved")
          );
          this.SessionDataService.sendDialogAction({ action: "close" });
          this.SessionDataService.sendReloadPanelFlag();
        }
      });
    } else if (this.data.gateways) {
      this.RequestQueueService.setTask(
        "configureGatewayChannels",
        this.data.gateways,
        { channels: this.newChannels }
      );
      this.SessionDataService.sendDialogAction({ action: "close" });
    }
  }
}
