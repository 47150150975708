// @angular
import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Datos de la tarjeta
  @Input() card: any;
  @Input() visibilitySelection: boolean;
  @Input() orderSelection: boolean;
  @Input() cardHeightFixed: boolean;

  // Tamaño de la tarjeta
  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--card-height: ${this.card.cardHeight}`
    );
  }

  // Acciones de la tarjeta
  @Output() cardActionFlag = new EventEmitter<string>();
  @Output() cardClickEventFlag = new EventEmitter<string>();

  // Mini gráfica
  graphWidth: number;
  @ViewChild("cardDataBox") cardDataBox: ElementRef;
  @ViewChild("cardDataBoxContainer") cardDataBoxContainer: ElementRef;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.graphWidth =
      this.cardDataBoxContainer.nativeElement.offsetWidth * 0.9;
    }
  , 0);  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(() => {
        this.graphWidth =
        this.cardDataBoxContainer.nativeElement.offsetWidth * 0.9;
    }, 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Aviso de acción de tarjeta
  cardButton(cardActionFlag: string): void {
    this.cardActionFlag.emit(cardActionFlag);
  }

  // Evento de click en tarjeta
  cardClickEvent(action: string): void {
    if (action) {
      this.cardClickEventFlag.emit(action);
    }
  }

  // Redirección a url
  openLink(url: string[], state: any, newTab: boolean, event?: any) {
    event?.preventDefault();
    this.SessionDataService.sendNewTab(newTab ? state?.data : null);
    setTimeout(
      () =>
        state
          ? this.router.navigate(url, { state: state })
          : this.router.navigate(url),
      0
    );
  }

  // Redirección en nueva pestaña desde datos de tarjeta
  contextMenuLink(link: string, event: MouseEvent): void {
    event?.preventDefault();
    this.openLink([link.replace("#", "")], { data: "null" }, true);
  }
}
