// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  ModifiedJob,
  ModifiedMisfire,
  JobToRun,
  JobToStop,
} from "../../screens/dashboard/management/ManagementInterface.type";

@Injectable({
  providedIn: "root",
})
export class CronManagementControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Obtener la información referente a los jobs y sus triggers creados
  getJobs(): Observable<object> {
    return this.HttpRequestService.get("/quartz-manager/getAllInfo");
  }

  // Ejecuta el jopb inmediatamente
  runJob(data: JobToRun): Observable<object> {
    return this.HttpRequestService.post("/quartz-manager/execute/", data);
  }

  // Modifica la expresión cron y cambia la planificación de ejecución
  modifyCron(data: ModifiedJob): Observable<object> {
    return this.HttpRequestService.post("/quartz-manager/change-cron", data);
  }

  // Pausa un trigger asociado a un job
  pauseJob(data: JobToStop): Observable<object> {
    return this.HttpRequestService.post("/quartz-manager/pause-trigger/", data);
  }

  // Reanuda un trigger pausado, según el valor de la propiedad misfire y el tier
  resumeJob(data: JobToStop): Observable<object> {
    return this.HttpRequestService.post(
      "/quartz-manager/resume-trigger/",
      data
    );
  }

  // Modifica la propiedad misfire
  changeMisfire(data: ModifiedMisfire): Observable<object> {
    return this.HttpRequestService.post(
      "/quartz-manager/change-misfire/",
      data
    );
  }
}
