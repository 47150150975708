// @angular
import { Injectable } from "@angular/core";
// Servicio de plugin de popup
import swal from "sweetalert2";
// Servicio propios
import { TranslateService } from "@ngx-translate/core";
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  toastTimer: number = 5000;

  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  // Cerrar ventanas
  closeToast(): void {
    swal.close();
  }

  // Mostrar toast de error
  fireToast(icon: any, title: any, timer?: number): void {
    let toast: typeof swal = swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: this.translate.instant("close"),
      timer: timer ? timer : this.toastTimer,
      customClass: {
        container: "swal2-container-scale-origin",
      },
    });

    toast.fire({
      timerProgressBar: true,
      icon: icon,
      title: title,
    });
  }

  // Mostrar toast de texto
  fireToastWithText(icon: any, title: any, text?: any, timer?: number): void {
    let toast: typeof swal = swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: timer ? timer : this.toastTimer,
      customClass: {
        container: "swal2-container-scale-origin",
      },
    });

    toast.fire({
      timerProgressBar: true,
      icon: icon,
      title: title,
      text: text,
    });
  }

  // Mostrar toast con botón de confirmación
  fireToastWithConfirmation(
    icon: any,
    title: any,
    buttonText?: string,
    buttonCancelText?: string,
    timer?: number,
    html?: string
  ): Promise<any> {
    let toast: typeof swal = swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: true,
      timer: timer ? timer : null,
      confirmButtonColor: "#1976d2",
      confirmButtonText: buttonText
        ? buttonText
        : this.translate.instant("close"),
      showCancelButton: buttonCancelText ? true : false,
      cancelButtonText: buttonCancelText
        ? buttonCancelText
        : this.translate.instant("close"),
      html: html,
      customClass: {
        container: "swal2-container-scale-origin",
      },
    });

    let userConfirmation: Promise<any> = toast
      .fire({
        timerProgressBar: timer ? true : false,
        title: title,
        icon: icon,
      })
      .then((result) => {
        return result.value;
      });

    return userConfirmation;
  }

  // Mostrar modal con opciones seleccionables
  fireAlertWithOptions(
    icon: any,
    title: any,
    html?: string,
    buttonCancelText?: string,
    buttonAcceptText?: string,
    timer?: number
  ): Promise<any> {
    let modal = swal.mixin({
      showCancelButton: true,
      showConfirmButton: true,
      timer: timer ? timer : null,
      confirmButtonColor: "#1976d2",
      cancelButtonText: buttonCancelText
        ? buttonCancelText
        : this.translate.instant("no"),
      confirmButtonText: buttonAcceptText
        ? buttonAcceptText
        : this.translate.instant("yes"),
    });

    let userConfirmation: Promise<any> = modal
      .fire({
        timerProgressBar: timer ? true : false,
        title: title,
        html: html,
        icon: icon,
      })
      .then((result) => {
        return result.value;
      });

    return userConfirmation;
  }

  // Mostrar modal con triple opción
  fireAlertWithTripleOptions(
    icon: any,
    title: any,
    buttonCancelText?: string,
    buttonAcceptText?: string,
    buttonDenyText?: string,
    html?: string,
    timer?: number
  ): Promise<any> {
    let modal: typeof swal = swal.mixin({
      showCancelButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      showCloseButton: true,
      timer: timer ? timer : null,
      confirmButtonColor: "#1976d2",
      denyButtonColor: "#1976d2",
      cancelButtonColor: "#1976d2",
      cancelButtonText: buttonCancelText,
      confirmButtonText: buttonAcceptText,
      denyButtonText: buttonDenyText,
    });

    let userSelection: Promise<any> = modal
      .fire({
        timerProgressBar: timer ? true : false,
        title: title,
        html: html,
        icon: icon,
      })
      .then((result) => {
        if (result.isConfirmed) {
          return "option1";
        } else if (result.isDenied) {
          return "option2";
        } else if (result.dismiss.toString() == "cancel") {
          return "option3";
        } else {
          return "option0";
        }
      });

    return userSelection;
  }

  // Toast de contraseña para regeneración de token
  firePasswordInput(sessionExpired?: boolean): Promise<any> {
    let sessionExpiredText: string = sessionExpired
      ? this.translate.instant("session-expired") + "\n"
      : "";
    let toast: typeof swal = swal.mixin({
      toast: true,
      position: "top-end",
      html:
        `<input type="text" id="relogin-user" class="swal2-input disabled" value="` +
        this.SessionDataService.getCurrentUser() +
        `">`,
      input: "password",
      inputPlaceholder: this.translate.instant("password"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#1976d2",
      confirmButtonText: this.translate.instant("accept"),
      cancelButtonColor: "#1976d2",
      cancelButtonText: this.translate.instant("cancel"),
      customClass: {
        container: "swal2-container-scale-origin swal2-html-avoid-margin",
      },
    });

    let userInput: Promise<any> = toast
      .fire({
        title: sessionExpiredText + this.translate.instant("relogin"),
      })
      .then((result) => {
        return result.value;
      });
    return userInput;
  }

  // Modal de captcha
  fireAlertWithCaptcha(
    icon: any,
    title: any,
    html?: string,
    buttonCancelText?: string,
    buttonAcceptText?: string
  ): Promise<any> {
    let randomNumber = String(
      Math.floor(Math.random() * (10000 - 1000) + 1000)
    );
    let modal = swal.mixin({
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#1976d2",
      cancelButtonText: buttonCancelText
        ? buttonCancelText
        : this.translate.instant("no"),
      confirmButtonText: buttonAcceptText
        ? buttonAcceptText
        : this.translate.instant("yes"),
      input: "text",
      inputLabel: this.translate.instant("captcha-code") + ": " + randomNumber,
      inputPlaceholder: randomNumber,
      inputAttributes: {
        maxlength: "4",
        autocapitalize: "off",
        autocorrect: "off",
      },
      customClass: { input: "swal2-alert-with-captcha" },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === randomNumber) {
            resolve("");
          } else {
            resolve(this.translate.instant("captcha-code-error"));
          }
        });
      },
    });

    let userConfirmation: Promise<any> = modal
      .fire({
        title: title,
        html: html,
        icon: icon,
      })
      .then((result) => {
        return result.value;
      });

    return userConfirmation;
  }

  // Mostrar modal de confirmación con texto
  fireAlertInfoText(icon: any, title: any, html?: any): Promise<any> {
    let modal: typeof swal = swal.mixin({
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: this.translate.instant("close"),
    });

    let userInput: Promise<any> = modal
      .fire({
        timerProgressBar: true,
        icon: icon,
        title: title,
        html: html,
      })
      .then((result) => {
        return result.value;
      });
    return userInput;
  }

  // Mostrar modal con entrada de comentarios
  fireAlertWithTextarea(
    icon: any,
    title: any,
    inputLabel: string,
    inputValue?: string,
    confirmButtonText?: string,
    cancelButtonText?: string
  ): Promise<any> {
    let modal: typeof swal = swal.mixin({
      input: "textarea",
      inputLabel: inputLabel,
      inputValue: inputValue ? inputValue : "",
      showCancelButton: true,
      confirmButtonColor: "#1976d2",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === "") {
            resolve(this.translate.instant("comments-required"));
          } else {
            resolve("");
          }
        });
      },
      cancelButtonText: cancelButtonText
        ? cancelButtonText
        : this.translate.instant("no"),
      confirmButtonText: confirmButtonText
        ? confirmButtonText
        : this.translate.instant("yes"),
    });

    let userInput: Promise<any> = modal
      .fire({
        title: title,
        icon: icon,
      })
      .then((result) => {
        return result.value;
      });
    return userInput;
  }

  // Mostrar modal con entrada de comentarios
  fireAlertWithCheckbox(
    icon: any,
    title: any,
    inputLabel: string
  ): Promise<any> {
    let modal: typeof swal = swal.mixin({
      input: "checkbox",
      inputPlaceholder: inputLabel,
      showCancelButton: true,
      confirmButtonColor: "#1976d2",
      cancelButtonText: this.translate.instant("no"),
      confirmButtonText: this.translate.instant("yes"),
    });

    let userInput: Promise<any> = modal
      .fire({
        title: title,
        icon: icon,
      })
      .then((result) => {
        return result.value;
      });
    return userInput;
  }
}
