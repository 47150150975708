import { MaterialDialogService } from "./../../../../../modules/material-module/material-dialog/material-dialog.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../../../../../app/services/shared/ToastService.service";
import { CommonModule, NgFor, NgIf } from "@angular/common";
import { MaterialModule } from "../../../../../modules/material-module/material.module";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";

@Component({
  selector: "app-device-image-carousel",
  templateUrl: "./device-image-carousel.component.html",
  styleUrls: ["./device-image-carousel.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    NgIf,
    NgFor,
    BrowserModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class DeviceImageCarouselComponent implements OnInit {
  //******************************************************** */
  //ANCHOR  Variables
  //******************************************************** */

  // @Input() deviceData: any ;
  @Input() imageList: any[] = [];
  @Input() addImageActive: boolean = true;
  @Input() deleteImageActive: boolean = true;
  @Input() fullScreenActive: boolean = true;

  // Recepción de las funciones a ejecutar en el hijo
  @Input() onAddImage: (image: any) => void = () => {}; // Función que recibe una imagen
  @Input() onDeleteImage: (index: number) => void = () => {}; // Función para borrar una imagen

  // Referencia al input de archivo
  @ViewChild("fileInput") fileInput: ElementRef;

  currentIndex: number = 0;
  fullScreenImage: string;
  isFullScreenActive: boolean;

  constructor(
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  ngOnInit() {}

  onFileSelected(event: any): void {
    let finalImageSent = event.target.files[0];
    let img: File = finalImageSent;
    this.addImage(img);
  }

  addImage(image: any) {
    if (this.addImageActive) {
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("image-add-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.onAddImage(image); // Llamamos a la función para agregar la imagen
        }
      });
    }
  }

  openFullScreen(image: string): void {
    this.fullScreenImage = image;
    this.isFullScreenActive = true;
  }

  closeFullScreen(): void {
    this.isFullScreenActive = false;
    this.fullScreenImage = null;
  }

  deleteImage(index: number) {
    if (this.deleteImageActive) {
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("image-delete-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          // Llamar a la función onDeleteImage antes de eliminar la imagen de la lista
          this.onDeleteImage(index);
          // Eliminar la imagen de la lista (usamos splice para eliminar en el índice especificado)
          this.imageList.splice(index, 1);
          // Ajustar el índice actual si se borra la imagen en la última posición
          if (index === this.imageList.length && this.imageList.length > 0) {
            this.currentIndex = this.imageList.length - 1; // Si eliminamos la última imagen, ajustamos el índice al último
          }
        }
      });
    }
  }
  // Función que activa el input de archivo al hacer clic en el botón
  triggerFileInput() {
    this.fileInput.nativeElement.click(); // Activa el input de archivo
  }

  // Para mover al siguiente slide
  nextSlide() {
    if (this.currentIndex < this.imageList.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

  // Para mover al slide anterior
  previousSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.imageList.length - 1;
    }
  }
}
