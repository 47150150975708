import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicio propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Interfaces
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
// Variables
import { MENU, MenuItem } from "./navbar-menu";
import { NAVBAR_SHORTCUTS } from "./navbar-shortcuts";
import { PROFILES } from "../../../../assets/profiles/profiles";

@Injectable({
  providedIn: "root",
})
export class NavbarMenuService implements OnDestroy {
  sessionProfile: string;
  sessionProfileSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  cupsDependentMenus = ["cups-info"];

  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();

    // Configuración de CUPS
    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.currentEntityCupsConf = entityCupsConf;
          this.cups = this.currentEntityCupsConf?.find(
            (col: EntityDefinition) => col.colPosition == 0
          );
          this.SessionDataService.sendNavbarMenu(
            this.setMenu(
              this.cups,
              this.currentEntityCupsConf,
              this.sessionProfile,
              this.currentAgrupation,
              this.currentEntity
            )
          );
        }
      );

    // Perfil de usuario
    this.sessionProfileSub = this.SessionDataService.getProfile().subscribe(
      (sessionProfile) => {
        this.sessionProfile = sessionProfile;
        this.SessionDataService.sendNavbarMenu(
          this.setMenu(
            this.cups,
            this.currentEntityCupsConf,
            this.sessionProfile,
            this.currentAgrupation,
            this.currentEntity
          )
        );
      }
    );

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        this.SessionDataService.sendNavbarMenu(
          this.setMenu(
            this.cups,
            this.currentEntityCupsConf,
            this.sessionProfile,
            this.currentAgrupation,
            this.currentEntity
          )
        );
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      this.SessionDataService.sendNavbarMenu(
        this.setMenu(
          this.cups,
          this.currentEntityCupsConf,
          this.sessionProfile,
          this.currentAgrupation,
          this.currentEntity
        )
      );
    });
  }

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.sessionProfileSub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
  }

  // Inicialización de menú
  loadMenu() {
    this.SessionDataService.sendNavbarMenu(
      this.setMenu(
        this.cups,
        this.currentEntityCupsConf,
        this.sessionProfile,
        this.currentAgrupation,
        this.currentEntity
      )
    );
  }

  // Seteo del menú
  setMenu(
    cups: EntityDefinition,
    entityCupsConf: EntityDefinition[],
    sessionProfile: string,
    agrupation: Agrupation,
    entity: Entity
  ): MenuItem[] {
    let menuList: MenuItem[] = [];
    // Ordenamiento alfabético de menú
    // let orderedMenu = MENU.sort((a, b) => {
    //   if (a.name == "main") {
    //     return -1;
    //   } else if (b.name == "main") {
    //     return 1;
    //   } else {
    //     return this.translate
    //       .instant(a.name)
    //       .localeCompare(this.translate.instant(b.name));
    //   }
    // });
    // orderedMenu.forEach((menuItem) => {
    MENU.forEach((menuItem) => {
      this.setMenuItem(
        menuList,
        menuItem,
        cups,
        entityCupsConf,
        sessionProfile,
        agrupation,
        entity
      );
    });
    return menuList;
  }

  // Seteo de opción de menú
  setMenuItem(
    menuList: any[],
    menuItem: any,
    cups: EntityDefinition,
    entityCupsConf: EntityDefinition[],
    sessionProfile: string,
    agrupation: Agrupation,
    entity: Entity
  ): void {
    if (
      !this.cupsDependentMenus.includes(menuItem.name) ||
      (this.cupsDependentMenus.includes(menuItem.name) && cups)
    ) {
      // Nueva opción de menú
      let newMenuItem: MenuItem = new MenuItem();

      // Atributos de opción
      newMenuItem.name = menuItem.name;
      if (newMenuItem.name == "cups-info") {
        newMenuItem.name = cups?.name;
      }
      if (
        newMenuItem.name == "gateways-client" &&
        this.sessionProfile == PROFILES.ARSON
      ) {
        newMenuItem.name =
          this.translate.instant("gateways") +
          " (" +
          this.translate.instant("client") +
          ")";
      }
      newMenuItem.link = menuItem.link;
      newMenuItem.icon = menuItem.icon;
      newMenuItem.subicon = menuItem.subicon;
      newMenuItem.profile =
        menuItem.profile?.length > 0
          ? menuItem.profile.includes(sessionProfile)
          : true;
      newMenuItem.client =
        menuItem.client?.length > 0
          ? menuItem.client.includes(entity?.client)
          : true;
      newMenuItem.submenu = menuItem.submenu?.length > 0 ? [] : null;
      newMenuItem.shortcut = NAVBAR_SHORTCUTS[menuItem.link]
        ? NAVBAR_SHORTCUTS[menuItem.link]
        : null;

      // Opción deshabilitada
      switch (menuItem.disabled) {
        case "cups":
          newMenuItem.disabled = cups == null;
          break;
        case "entityCupsConf":
          newMenuItem.disabled = entityCupsConf?.length > 1 ? false : true;
          break;
        case "virtual":
          newMenuItem.disabled = agrupation?.showAllEntity;
          break;
        case "isTlm":
          newMenuItem.disabled =
            this.sessionProfile != PROFILES.ARSON &&
            this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
            !this.SessionDataService.getCurrentTelemetryActive();
          break;
        case "disabled":
          newMenuItem.disabled = true;
          break;
        default:
          break;
      }

      // Submenú
      if (menuItem.submenu) {
        // Ordenamiento alfabético de submenú
        // let orderedSubmenu = menuItem.submenu.sort((a, b) =>
        //   this.translate
        //     .instant(a.name)
        //     .localeCompare(this.translate.instant(b.name))
        // );
        // orderedSubmenu.forEach((submenuItem) => {
        menuItem.submenu.forEach((submenuItem) => {
          this.setMenuItem(
            newMenuItem.submenu,
            submenuItem,
            cups,
            entityCupsConf,
            sessionProfile,
            agrupation,
            entity
          );
        });
      }

      menuList.push(newMenuItem);
    }
  }
}
