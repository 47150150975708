// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
import { MeterService } from "../../../MeterService.service";
// Interfaces
import { Entity } from "../../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../../../modules/table-module/TableInterface.type";
import {
  DetailDevice,
  DeviceCommunicationLog,
} from "../../../../DeviceInterface.type";
import { PanelMenuOption } from "../../../../../../../modules/material-module/MaterialInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../../../../../global/LOCAL_TIMEZONE";
import { PROFILES } from "../../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meterlogcommunication",
  templateUrl: "./meter-log-communications.component.html",
  styleUrls: ["./meter-log-communications.component.scss"],
})
export class MeterLogCommunicationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;

  // Table
  tableMaxReg: number = 50;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  meter: DetailDevice;
  meterFrames: DeviceCommunicationLog[];
  orderBy: object = { attribute: "timestamp", reverse: true };
  exportFileName: string =
    this.translate.instant("communication-export") +
    " " +
    this.DateParserService.getDate();
  from: string;
  to: string;
  columns: (TableActionColumn | TableDataColumn)[];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de componente
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meter = history.state.data;
    this.meter.parser = this.MeterService.checkMeterManufacturerParser(
      this.meter
    );
    this.setPanelMenuOptions();
    this.setColumns();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.MeterController.getMeterFramesPartial(
      this.meter.id,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let meterFrames: DeviceCommunicationLog[] = response["body"];
          meterFrames.map((meterFrame) => {
          // Si empieza por 30, 40, 60, 80 -> Cabecera de 8 bytes (16 caracteres)
          if (meterFrame.allFrameData.startsWith("30") || meterFrame.allFrameData.startsWith("40") ||meterFrame.allFrameData.startsWith("60") ||meterFrame.allFrameData.startsWith("80")) {
          // Cabecera: Primeros 16 caracteres
          meterFrame.loraHeader = meterFrame.allFrameData.substring(0, 16);
          // Payload: Desde el carácter 16 hasta 10 caracteres antes del final
          meterFrame.payload = meterFrame.allFrameData.substring(16, meterFrame.allFrameData.length - 10);
          }
          else{
            meterFrame.payload=meterFrame.allFrameData;
          }
          if(meterFrame.payload.startsWith("20")){
            meterFrame.payload=meterFrame.payload.substring(2,meterFrame.payload.length)
          }
            meterFrame.parseDisabled =
              meterFrame.payload?.substring(0, 2) == "40";
          });
          this.meterFrames = meterFrames;
        } else {
          this.meterFrames = [];
        }
      }
    });
  }

  // Actualización del componente
  updateData(): void {
    this.meterFrames = [];
    this.getData(this.from, this.to);
  }

  // Obtención de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "frame-parse",
            tooltip: "frame-parse",
            icon: "fas fa-language",
            visible: { attribute: null, rule: true },
            disabled: "micError",
          },
        ],
        visible: this.meter.parser ? true : null,
      },
      {
        title: "gateway",
        data: "gwUnidadVenta",
        search: "gwUnidadVenta",
        sort: "gwUnidadVenta",
        visible: true,
      },
      {
        title: "reception-hour",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "channel",
        data: "channelParsed",
        search: "channelParsed",
        sort: "channel",
        numerical: true,
        visible: true,
      },
      {
        title: "Mic error",
        data: "micError",
        search: "micError",
        sort: "micError",
        alter: {
          condition: "micError",
          skins: [
            { rule: true, class: "fas fa-exclamation-triangle red" },
            { rule: false, class: "" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "RSSI",
        data: "rssiParsed",
        search: "rssiParsed",
        sort: "rssi",
        numerical: true,
        visible: true,
      },
      {
        title: "SF",
        data: "sfParsed",
        search: "sfParsed",
        sort: "sf",
        numerical: true,
        visible: true,
      },
      {
        title: "header",
        data: "loraHeader",
        search: "loraHeader",
        sort: "loraHeader",
        visible: true,
      },
      {
        title: "port",
        data: "fportParsed",
        search: "fportParsed",
        sort: "fport",
        numerical: true,
        visible: true,
      },
      {
        title: "Payload",
        data: "payload",
        search: "payload",
        sort: "payload",
        long: true,
        visible: true,
      },
    ];
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "event-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("event-log"),
        visible: this.sessionProfile == PROFILES.ARSON,
      },
      {
        action: "user-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("user-log"),
        visible: true,
      },
      {
        action: "frame-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("frame-log"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.currentAgrupation.id == 60,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "event-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/eventos/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "user-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/usuarios/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "frame-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/tramas/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla
  tableActions(action: string, meterFrame: DeviceCommunicationLog): void {
    switch (action) {
      case "frame-parse":
        this.MeterService.parsePayload(
          this.meter,
          meterFrame,
          this.DateParserService.parseDate(
            meterFrame.timestamp,
            "DD/MM/YYYY HH:mm:ss",
            LOCAL_TIMEZONE
          )
        );
        break;
      default:
        break;
    }
  }
}
