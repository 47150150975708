import { Component, OnInit, Input } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { MeterService } from "../../meter/MeterService.service";
// Interfaces
import { AssignedDevice } from "../../DeviceInterface.type";

@Component({
  selector: "app-concentrator-dialog",
  templateUrl: "./concentrator-dialog.component.html",
  styleUrls: ["./concentrator-dialog.component.scss"],
})
export class ConcentratorDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  devices: AssignedDevice[];
  selectedDevices: AssignedDevice[];
  hint: string;
  hintError: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.getDevices();
    this.updateHint();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Activar contador
  getDevices(): void {
    this.MeterController.table(
      this.SessionDataService.getCurrentAgrupation().id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.devices = response["body"]?.meterList?.filter(
          (device) =>
            !this.data.mainMeters.some(
              (mainDevice) => mainDevice.id == device.id
            )
        );
      }
    });
  }

  // Asignar contador como permanente a LW MBUS
  assignLwMbusPermanent(): void {
    this.MeterService.assignLwMbusPermanent(
      this.data.id,
      this.selectedDevices.map((device) => device.id)
    );
    this.SessionDataService.sendDialogAction({ action: "close" });
  }

  // Actualización de aviso de máximos dispositivos
  updateHint(): void {
    this.hint =
      this.translate.instant("mbus-max-fixed") +
      ": " +
      (this.data.mainMeters?.length +
        (this.selectedDevices ? this.selectedDevices.length : 0)) +
      "/16";
    this.hintError =
      this.data.mainMeters?.length +
        (this.selectedDevices ? this.selectedDevices.length : 0) >
      16;
  }
}
