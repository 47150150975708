import { Component, Input, OnInit } from "@angular/core";
// HighCharts
import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
// Variables
import { GRAPH_CONFIG } from "../GRAPH_CONFIG";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { format } from "path";

@Component({
  selector: "app-graph-mini",
  templateUrl: "./graph-mini.component.html",
  styleUrls: ["./graph-mini.component.scss"],
})
export class GraphMiniComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;

  // Gráfica
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    this._data = data;
    if (this._data && this._width) {
      this.chartOptions = null;
      setTimeout(() => this.setChartOptions(), 0);
    }
  }
  _data: any;
  @Input()
  get width(): any {
    return this._width;
  }
  set width(width: any) {
    this._width = width;
    if (this._data && this._width) {
      this.chartOptions = null;
      setTimeout(() => this.setChartOptions(), 0);
    }
  }
  _width: number;
  Highcharts: typeof Highcharts = Highcharts;
  updateFlag: boolean = false;
  chartConstructor: string = "stockChart";
  chartOptions: Options;
  options: any = {};

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Ajuste de opciones de gráfica
    let defaultOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );

    for (let attribute in defaultOptions.lang) {
      if (defaultOptions.lang[attribute].constructor === Array) {
        defaultOptions.lang[attribute] = defaultOptions.lang[attribute].map(
          (data: string) => {
            return this.translate.instant(data);
          }
        );
      } else {
        defaultOptions.lang[attribute] = this.translate.instant(
          defaultOptions.lang[attribute]
        );
      }
    }

    this.options = {
      accessibility: defaultOptions.accessibility,
      lang: defaultOptions.lang,
      time: {
        timezone: this.currentAgrupation.timezone.toString(),
      },
      tooltip: {
        outside: true,
      },
    };

    Highcharts.setOptions(this.options);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de las opciones de la gráfica
  setChartOptions(): void {
    this.chartOptions = {
      chart: {
        backgroundColor: "transparent",
        borderWidth: 0,
        type: this._data.type ? this._data.type : "line",
        width: this._width,
        height: 70,
        style: {
          overflow: "visible",
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      xAxis: {
        type: this._data.xAxisType ? this._data.xAxisType : "datetime",
        ordinal: this._data.xAxisOrdinal ? this._data.xAxisOrdinal : false,
        crosshair: {
          width: 1,
          color: "red",
        },
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: this._data.yAxisTitle,
        },
        tickPositions: [0],
        min: this._data.min,
        max: this._data.max,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          lineWidth: 1,
          shadow: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            radius: 1,
            states: {
              hover: {
                radius: 2,
              },
            },
          },
          //fillOpacity: 0.25
        },
        column: {
          negativeColor: "#910000",
          borderColor: "silver",
        },
      },
      series: this._data.series,
    };

    this.updateFlag = true;
  }
}
