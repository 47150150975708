// @angular
import { Component, Input, OnInit } from "@angular/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-mbus-manufacturers-dialog",
  templateUrl: "./mbus-manufacturers-dialog.component.html",
  styleUrls: ["./mbus-manufacturers-dialog.component.scss"],
})
export class MbusManufacturersDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private SessionDataService: SessionDataService) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Cierre de modal
  save(): void {
    this.SessionDataService.sendDialogAction({
      action: "save",
      data: { identificador: this.data.identificador, clave: this.data.clave },
    });
    this.SessionDataService.sendDialogAction({ action: "close" });
  }
}
