import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
import {
  MaterialSelectGroup,
  MaterialSelectOption,
} from "../MaterialInterface.type";
import { FormControl } from "@angular/forms";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

@Component({
  selector: "app-material-select",
  templateUrl: "./material-select.component.html",
  styleUrls: ["./material-select.component.scss"],
})
export class MaterialSelectComponent implements AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  selectedItems = new FormControl();
  componentInitiated: boolean = false;
  @Input() multiple: boolean;
  @Input()
  get groups(): MaterialSelectGroup[] {
    return this._groups;
  }
  set groups(groups: MaterialSelectGroup[]) {
    this._groups = groups;
    if (this._groups) {
      this.originalGroups = [...this._groups];
      if (this.options) {
        this.checkGroups();
      }
    }
  }
  _groups: MaterialSelectGroup[];
  @Input() counter: boolean;
  @Input() clearable: boolean;
  @Input() disableAllSelection: boolean;
  @Input() fullWidth: boolean;
  @Input() height: number;
  @Input() hint: string;
  @Input() filter: boolean;
  @Input() disabled: boolean;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() bindGroup: string;
  @Input() bindHideSelection: string;
  @Input() title: string;
  @Input() noSelection: boolean;
  @Input() autofocus: boolean;
  @Input() required: boolean;
  @Input() virtual: boolean;
  @Input() hintError: boolean;
  @ViewChild("materialSelect") materialSelect: any;
  @ViewChild("materialSelectFilter") materialSelectFilter: ElementRef;
  @Input()
  get data(): any {
    return this._data;
  }
  set data(data: any) {
    this._data = data;
    if (this._data) {
      this.options = this._data.map((element: any) => {
        return {
          value: element[this.bindValue],
          name: element[this.bindLabel],
          group: element[this.bindGroup],
          hideSelection: element[this.bindHideSelection],
        };
      });
      if (this.noSelection) {
        this.options.unshift({
          value: null,
          name: this.translate.instant("filter-free"),
        });
      }
      this.originalOptions = [...this.options];
      this.setSelected();
      if (this._groups) {
        this.checkGroups();
      }
      this.componentInitiated = true;
    }
  }
  _data: any;
  @Input()
  get selected(): any {
    return this._selected;
  }
  set selected(selected: any) {
    // Reseteo de selección
    this._selected = selected;
    // Actualización si el componente ha sido iniciado
    if (this.componentInitiated) {
      this.setSelected();
    }
  }
  _selected: any;
  @Input()
  get resetSelection(): boolean {
    return this._resetSelection;
  }
  set resetSelection(resetSelection: boolean) {
    if (this.componentInitiated) {
      this.reset();
    }
  }
  _resetSelection: boolean;
  options: MaterialSelectOption[];
  originalOptions: MaterialSelectOption[];
  originalGroups: MaterialSelectGroup[];
  stopPropagation: boolean = false;

  // Selección
  @Output() selectedOption = new EventEmitter<any>();
  selectAll: boolean = false;
  selectedOptions: MaterialSelectOption[];

  // Multiple
  @ViewChild("cdkVirtualScrollViewPort")
  cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

  // Filtro
  selectFilterInput: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(private translate: TranslateService) {}

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.materialSelect?.open(), 100);
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Selección/Deselección de todas las opciones
  checkAll(): void {
    let previousSelection: any[] = [];
    if (this.selectFilterInput != null && this.selectFilterInput != "") {
      previousSelection = this.selectedItems.value.filter(
        (value: any) =>
          !this.options.some(
            (option: MaterialSelectOption) => option.value == value
          )
      );
    }
    if (this.selectAll) {
      this.options.map(
        (option: MaterialSelectOption) => (option.selected = true)
      );
      this.updateSelected();
    } else {
      this.options.map(
        (option: MaterialSelectOption) => (option.selected = false)
      );
      this.updateSelected(previousSelection);
    }
  }

  // Seteo de la preselección
  setSelected(): void {
    if (this._selected != null) {
      this.stopPropagation = true;
    }
    // Selector múltiple
    if (this.multiple) {
      this.originalOptions.map(
        (option: MaterialSelectOption) =>
          (option.selected = this._selected?.includes(option.value))
      );
      setTimeout(() => this.updateSelected(), 0);
      // Selector individual
    } else if (!this.multiple && this._selected != null) {
      setTimeout(() => this.updateSelected(this._selected), 0);
    }
  }

  // Actualización de seleccionados
  updateSelected(preselect?: any[]): void {
    if (this.multiple) {
      let selectedOptions = this.originalOptions
        .filter((option: MaterialSelectOption) => option.selected)
        .map((option: MaterialSelectOption) => {
          return option.value;
        });
      if (preselect != null) {
        this.selectedItems.setValue(
          ["forceTrigger"].concat(preselect.concat(selectedOptions))
        );
      } else {
        this.selectedItems.setValue(["forceTrigger"].concat(selectedOptions));
      }
    } else if (preselect != null) {
      this.selectedItems.setValue(preselect);
    }

    if (!this.stopPropagation) {
      this.emitSelected();
    } else {
      this.stopPropagation = false;
    }
  }

  // Filtrado de opciones
  filterOptions(): void {
    if (this.selectFilterInput != null && this.selectFilterInput != "") {
      this.options = this.originalOptions.filter(
        (option: MaterialSelectOption) =>
          option.name
            .toLowerCase()
            .includes(this.selectFilterInput.toLowerCase())
      );
    } else {
      this.options = [...this.originalOptions];
    }

    // Filtrado de grupos de opciones
    if (this._groups) {
      this.checkGroups();
    }

    // Comprobación de selección total
    if (this.multiple && !this.disableAllSelection) {
      this.checkAllSelected();
    }
  }

  // Comprobación de selección total
  checkAllSelected(): void {
    this.selectAll = this.options.every((option: MaterialSelectOption) =>
      this.selectedItems?.value?.includes(option.value)
    );
  }

  // Evento de cambio en opción
  updateOption(selectedOption: MaterialSelectOption): void {
    selectedOption.selected = !selectedOption.selected;
    if (
      !selectedOption.selected &&
      this.options.some(
        (option: MaterialSelectOption) => option.value == selectedOption.value
      )
    ) {
      this.selectAll = false;
    }
    this.updateSelected();
  }

  // Reseteo del scroll al abrir desplegable
  openChange($event: boolean) {
    if ($event && this.cdkVirtualScrollViewPort) {
      this.cdkVirtualScrollViewPort.scrollToIndex(0);
      this.cdkVirtualScrollViewPort.checkViewportSize();
    }
  }

  // Reseteo de selección
  reset(event?: any): void {
    if (this.multiple) {
      this.selectAll = false;
      this.originalOptions.map(
        (option: MaterialSelectOption) => (option.selected = false)
      );
    } else {
      this.selectedItems.setValue(["forceTrigger"]);
    }
    this.updateSelected();
    event?.stopPropagation();
  }

  // Actualización de la selección en el componente padre
  emitSelected(): void {
    if (this.multiple) {
      this.selectedOption.emit(
        this._data?.filter((element: any) =>
          this.selectedItems.value.includes(element[this.bindValue])
        )
      );
    } else {
      this.selectedOption.emit(
        this._data?.find(
          (element: any) => this.selectedItems.value == element[this.bindValue]
        )
      );
    }
  }

  // Comprobación de grupos vacíos
  checkGroups(): void {
    this._groups = [...this.originalGroups];
    this._groups = this._groups.filter((group: MaterialSelectGroup) =>
      this.options.some(
        (option: MaterialSelectOption) => option.group == group.value
      )
    );
  }
}
