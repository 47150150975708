/**
 * Decodes the given frmPayload for the Viewshine protocol.
 *
 * @param {any} frmPayload - The frmPayload to be decoded.
 * @param {any} fport - The FPort of the frame.
 *
 * @returns {Object}
 *
 * @note
 * - Make sure that the payload is entered as a byte array.
 * - The function is designed to provide detailed feedback through its return object,
 *   including any decoding errors.
 */

import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class ViewshineDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  Viewshine_decoder(frmPayload, fport) {
    if (frmPayload) {
      if (frmPayload.bytes) {
        var outputData = {};
        var outputErrors = [];
        switch (fport) {
          case 0x0b:
            if (frmPayload.bytes.length != 50) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  50 bytes)",
                ],
              };
            } else {
              return {
                data: this.reportFrameDecoder(frmPayload.bytes, fport),
              };
            }
            break;
          case 0x0c:
            if (frmPayload.bytes.length != 10) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  10 bytes)",
                ],
              };
            } else {
              return {
                data: this.alarmFrameDecoder(frmPayload.bytes, fport),
              };
            }
          case 0x0d:
            if (frmPayload.bytes.length != 12) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  12 bytes)",
                ],
              };
            } else {
              return {
                data: this.bateriaFrameDecoder(frmPayload.bytes, fport),
              };
            }
          default:
            return {
              errors: ["TIPO DE TRAMA DESCONOCIDO. Puerto " + fport.toString()],
            };
            break;
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }

  parseaAlarmas(alarmas) {
    var objetoAlarmas = {};
    if (alarmas != 0) {
      if (alarmas & (1 << 0)) {
        objetoAlarmas["Flujo inverso"] = true;
      }
      if (alarmas & (1 << 1)) {
        objetoAlarmas["Aire en la tubería"] = true;
      }
      if (alarmas & (1 << 2)) {
        objetoAlarmas["Goteo"] = true;
      }
      if (alarmas & (1 << 3)) {
        objetoAlarmas["Temperatura anormal"] = true;
      }
      if (alarmas & (1 << 4)) {
        objetoAlarmas["Caudal anormal"] = true;
      }
      if (alarmas & (1 << 5)) {
        objetoAlarmas["Batería baja"] = true;
      }
      if (alarmas & (1 << 6)) {
        objetoAlarmas["GP30 FALLO"] = true;
      }
      if (alarmas & (1 << 7)) {
        objetoAlarmas["EEPROM FALLO"] = true;
      }
    }
    return objetoAlarmas;
  }

  /* Decoder de Report Frame*/
  reportFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "REPORT FRAME" };

    var index = 0;
    var timestamp = this.readU32Msb(bytes, 0); // Timestamp UTC
    outputData["Fecha"] = new Date(timestamp * 1000).toLocaleString("es-ES");
    var referenceDate = new Date(timestamp * 1000 - 23 * 60 * 60 * 1000);
    var alarmas = bytes[4];
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    var referenceValue = this.getBCD8DigitosMSB(bytes, 5);
    outputData["VALORES"] = {
      [referenceDate.toLocaleString("es-ES")]:
        (referenceValue / 1000).toFixed(3) + " m3",
    };

    var arrayConsumos = bytes.slice(9, 50);
    var bytesRestantes = 41;
    for (let ndxDelta = 0; ndxDelta < 23; ndxDelta++) {
      var delta = this.cogeBits(arrayConsumos, 14);
      arrayConsumos = this.desplazaIzquierda(arrayConsumos, bytesRestantes, 14);
      bytesRestantes = Math.ceil((bytesRestantes * 8 - 14) / 8);
      referenceValue += delta;
      referenceDate = new Date(referenceDate.getTime() + 60 * 60 * 1000);
      outputData["VALORES"][referenceDate.toLocaleString("es-ES")] =
        (referenceValue / 1000).toFixed(3) +
        " m3 (+" +
        delta.toString() +
        " litros)";
    }
    return outputData;
  }

  /* Decoder de Alarm Frame*/
  alarmFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "ALARM FRAME" };

    var index = 0;
    var timestamp = this.readU32Msb(bytes, 0); // Timestamp UTC
    outputData["Fecha"] = new Date(timestamp * 1000).toLocaleString("es-ES");
    var alarmas = bytes[4];
    if (alarmas != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(alarmas);
    }
    var caudal = this.getBCD6DigitosMSB(bytes, 5);
    outputData["Caudal"] = (caudal / 1000).toFixed(3) + " m3/h";
    var temperatura = this.readS16Msb(bytes, 8);
    outputData["Temperatura"] = (temperatura / 100).toFixed(2) + " ºC";
    return outputData;
  }

  /* Decoder de Battery Frame*/
  bateriaFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "BATTERY FRAME" };

    var index = 0;
    var timestamp = this.readU32Msb(bytes, 0); // Timestamp UTC
    outputData["Fecha"] = new Date(timestamp * 1000).toLocaleString("es-ES");
    var bateriaMedicion = this.readU32Msb(bytes, 4) / 10;
    outputData["Bateria_Medicion"] = bateriaMedicion.toFixed(1) + " %";
    var bateriaRadio = this.readU32Msb(bytes, 8) / 10;
    outputData["Bateria_Radio"] = bateriaRadio.toFixed(1) + " %";
    return outputData;
  }

  readS16Msb(bytes, start) {
    var res = (bytes[start] << 8) + bytes[start + 1];
    if (res & 0x8000) {
      res = res - 0xffff - 1;
    }
    return res;
  }

  readU32Msb(bytes, start) {
    var res =
      (bytes[start] << 24) +
      (bytes[start + 1] << 16) +
      (bytes[start + 2] << 8) +
      bytes[start + 3];
    return res;
  }

  getBCD8DigitosMSB(bytes, start) {
    var valor = 0;
    for (var i = 0; i <= 3; i++) {
      valor *= 10;
      valor += (bytes[start + i] >> 4) & 0x0f;
      valor *= 10;
      valor += bytes[start + i] & 0x0f;
    }
    return valor;
  }

  getBCD6DigitosMSB(bytes, start) {
    var valor = 0;
    for (var i = 0; i <= 2; i++) {
      valor *= 10;
      valor += (bytes[start + i] >> 4) & 0x0f;
      valor *= 10;
      valor += bytes[start + i] & 0x0f;
    }
    return valor;
  }

  desplazaIzquierda(bytes, lenArray, nBits) {
    var nBytes = Math.floor(nBits / 8);

    if (nBits > 8) {
      for (let ndx = 0; ndx < lenArray - nBytes; ndx++) {
        bytes[ndx] = bytes[ndx + nBytes] & 0xff;
      }
      nBits -= nBytes * 8;
    }
    for (let ndx = 0; ndx <= lenArray - nBytes; ndx++) {
      bytes[ndx] <<= nBits;
      if (ndx <= lenArray - nBytes - 1) {
        bytes[ndx] |= bytes[ndx + 1] >> (8 - nBits);
        bytes[ndx] &= 0xff;
      }
    }
    return bytes;
  }

  bytesToHex(bytes) {
    bytes = Array.prototype.slice.call(bytes);
    for (var i = 0; i < bytes.length; i++) {
      bytes[i] = ("0" + (bytes[i] & 0xff).toString(16)).slice(-2);
    }
    return bytes.join("");
  }

  cogeBits(bytes, nBits) {
    var ndx = 0;
    var valor = 0;
    while (nBits > 8) {
      valor <<= 8;
      valor |= bytes[ndx++];
      nBits -= 8;
    }
    if (nBits) {
      valor <<= nBits;
      valor |= bytes[ndx] >> (8 - nBits);
    }
    return valor;
  }

  Viewshine_Descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);
      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x0b:
                return "CONSUMOS HORARIOS";
              case 0x0c:
                return "ALARMAS";
              case 0x0d:
                return "BATERIA";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frameBytes[5] & 0x0f;
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
