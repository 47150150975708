import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";
import { ScrollingModule } from "@angular/cdk/scrolling";

// Directives
import { DirectivesModule } from "../../directives/directives.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

// Leaflet
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletMarkerClusterModule } from "@asymmetrik/ngx-leaflet-markercluster";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { NgxLeafletFullscreenModule } from "@runette/ngx-leaflet-fullscreen";

// Maps
import { MapControllerComponent } from "./map-controller/map-controller.component";
import { MapComponent } from "./map/map.component";
import { MapFiltersMainComponent } from "./map-filters-main/map-filters-main.component";
import { MapModalComponent } from "./map-modal/map-modal.component";
import { MapCoverageContrastComponent } from "./map-coverage-contrast/map-coverage-contrast.component";
import { MapTableSelectorComponent } from "./map-table-selector/map-table-selector.component";
import { MapSelectorComponent } from "./map-selector/map-selector.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,
    ScrollingModule,

    // Directives
    DirectivesModule,

    // Leaflet
    LeafletModule,
    LeafletMarkerClusterModule,
    LeafletDrawModule,
    NgxLeafletFullscreenModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [
    MapControllerComponent,
    MapComponent,
    MapFiltersMainComponent,
    MapModalComponent,
    MapCoverageContrastComponent,
    MapTableSelectorComponent,
    MapSelectorComponent,
  ],
  exports: [
    MapControllerComponent,
    MapModalComponent,
    MapCoverageContrastComponent,
    MapSelectorComponent,
  ],
})
export class MapModule {}
