// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  AssociationEditionData,
  AssociationGraphRequestData,
} from "../../screens/dashboard/data-management/DataManagementInterface.type";
import { EntityDefinition } from "../../interfaces/CupsGlobalInterface.type";

@Injectable({
  providedIn: "root",
})
export class AssociationControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Asociación
  /***************************************************************************/

  // Borrado de sector
  deleteAssociation(associationId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/delete/" + associationId);
  }

  // Datos de lecturas individuales/grupal de sector
  getGraphGroup(
    type: number,
    data: AssociationGraphRequestData
  ): Observable<object> {
    let typeURL: string;

    if (type == 1) {
      typeURL = "readings-multiple";
    } else if (type == 2) {
      typeURL = "readings-simple";
    }

    return this.HttpRequestService.post("/" + typeURL + "/sector/", data);
  }

  // Listado de CUPS de la agrupación
  cups(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/cm/list/" + agrupationId);
  }

  noCups(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/cm/no-cm/list/" + agrupationId);
  }

  // Listado de asociaciones de dispositivos
  getAssociationList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/list/" + agrupationId);
  }

  // Listado de dispositivos de asociación
  getAssociationDevices(associationId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/devices/" + associationId);
  }

  /***************************************************************************/
  // ANCHOR Editar
  /***************************************************************************/

  // Datos de sector
  show(associationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/sector/update-simple/show/" + associationId
    );
  }

  // Actualización de sector
  edit(agrupationId: number, data: AssociationEditionData): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/update-simple/" + agrupationId,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Nuevo
  /***************************************************************************/

  // Lista de dispositivos de sector
  deviceList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get("/sector/list-devices/" + agrupationId);
  }

  // Guardado de sector
  saveAssociation(
    agrupationId: number,
    data: AssociationEditionData
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/sector/save-simple/" + agrupationId,
      data
    );
  }

  /***************************************************************************/
  // ANCHOR Importar
  /***************************************************************************/

  // Importación del fichero con los datos extra de los CUPS
  import(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post(
      "/cm/assigned/import/" + entityId,
      data
    );
  }

  // Listar configuración de los campos de los datos extra de una entidad
  getColumns(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/cm-def/list/" + entityId);
  }

  // Importación del fichero con los datos extra de los CUPS
  importOnlyCups(entityId: number, data: unknown): Observable<object> {
    return this.HttpRequestService.post("/cm/file/import/" + entityId, data);
  }

  /***************************************************************************/
  // ANCHOR Info
  /***************************************************************************/

  // Guardar una configuración de un dato extra en la entidad
  saveExtraData(entityId: number, data: EntityDefinition): Observable<object> {
    return this.HttpRequestService.post("/cm-def/save/" + entityId, data);
  }

  // Listar configuración de los campos de los datos extra de una entidad
  listEntityConfig(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/cm-def/list/" + entityId);
  }

  // Actualizar una configuración de un dato extra en la entidad
  updateExtraData(
    entityId: number,
    data: EntityDefinition
  ): Observable<object> {
    return this.HttpRequestService.post("/cm-def/update/" + entityId, data);
  }

  // Eliminar un campo de datos extra de una entidad
  deleteExtraData(extraDataId: number, entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/cm-def/delete/" + extraDataId + "/entity/" + entityId
    );
  }

  // Listar configuración de los campos de los datos extra de una entidad
  getCupsExtraData(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/cm-def/list/" + entityId);
  }
}
