import { TooltipOptions } from "highcharts";

// ANCHOR Datos de metrológica
export class Metrological {
  public id: number;
  public contador: number;
  public contadorNroSerie: string;
  public conversionFactor: number;
  public pcs: string;

  constructor() {
    this.id = null;
    this.contador = null;
    this.contadorNroSerie = null;
    this.conversionFactor = null;
    this.pcs = null;
  }
}

// ANCHOR Datos de contador
export interface MetrologicalDevice {
  id: number;
  nroSerie: string;
  agrupation: number;
  metrologyType: number;
}

// ANCHOR Respuesta de importación de CUPS
export interface ImportResponseCups {
  // BackEnd
  id: number;
  contador: number;
  nroSerie: string;
  agrupation: number;
  clave: string;
  col01: string;
  col02: string;
  col03: string;
  col04: string;
  col05: string;
  col06: string;
  col07: string;
  col08: string;
  col09: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
  col19: string;
  col20: string;
  installation: number;
  isAssigned: boolean;
  abonado: number;
  updateState: boolean;
  responseCode: number;

  // FrontEnd
  responseCodeText: string;
  state: boolean;
}

// ANCHOR Datos de tabla de importación
export interface TableImportResponse {
  nroSerie: string;
  state: boolean;
  responseCodeText: string;
  errorCode: number;
}

// ANCHOR Datos de asociación
export interface Association {
  id: number;
  name: string;
  description: string;
  type: number;
  agrupation: number;
  metrologyType?: number;
  totalMeters?: number;
}

// ANCHOR Datos de edición de asociación
export interface AssociationEditionData {
  id?: number;
  name: string;
  description: string;
  cmList: number[];
}

// ANCHOR Datos de petición de gráfica de asociación
export interface AssociationGraphRequestData {
  meterList: number[];
  sector: number;
  fromTimestamp: number;
  toTimestamp: number;
  graphType: number;
}

// ANCHOR Datos de gráfica de asociación por contadores
export interface AssociationGraphMeterData {
  meterId: number;
  nroSerie: string;
  readings: (number | boolean)[][];
}

// ANCHOR Datos de gráfica de asociación por sumatorio
export interface AssociationGraphSumatoryData {
  alarms: AssociationGraphAlarms[];
  readings: (number | boolean)[][];
}

// ANCHOR Datos de alarmas de la gráfica
export interface AssociationGraphAlarms {
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;
}

// ANCHOR Serie de contador
export interface AssociationGraphMeterSerie {
  id: string;
  name: string;
  data: (number | boolean)[][];
  dataGrouping: object;
  tooltip: TooltipOptions;
  events: any;
}

// ANCHOR Serie de sumatorio
export interface AssociationGraphSumatorySerie {
  id: string;
  name: string;
  data: (number | boolean)[][];
  dataGrouping: object;
  color: string;
  tooltip: TooltipOptions;
}

// ANCHOR Dispositivo de sector
export interface SectorCups {
  // BackEnd
  id: number;
  clave: string;
  col01: string;
  col02: string;
  col03: string;
  col04: string;
  col05: string;
  col06: string;
  col07: string;
  col08: string;
  col09: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
  col19: string;
  col20: string;
  isSelected: boolean;
  isChildParent: number;
  meters: {
    meterId: number;
    nroSerie: string;
    metrologyType: number;
    latitude: number;
    longitude: number;
  }[];

  // FrontEnd
  selected?: boolean;
  selectedBis?: boolean;
  meterType?: string;
  meterNroSerie?: string;
  meterId?: number;
  agrupation?: number;
  agrupationName?: string;
  nroSerieList?: string;
  nroSerieListExport?: any;
  claveParsed?: string;
  cupsLink?: string;
}

// ANCHOR Dispositivo de sector
export interface SectorDevice {
  claveMaestraId: number;
  claveMaestraKey: string;
  meterId?: number;
  meterNroSerie?: string;
  id?: number;
  nroSerie?: string;
  metrologyType: number;
  latitude: number;
  longitude: number;
  selected?: boolean;
  selectedBis?: boolean;
  meterType?: string;
}
