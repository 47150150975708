<div class="dialog-container">
  <div class="dialog-header">
    <h5>
      <b>{{ "sniffer-list" | translate }} : {{ unidadVenta }}</b>
    </h5>
  </div>

  <div class="dialog-body">
    <!-- Tabla de gateways -->
    <app-table-controller
      [tableId]="'gatewaysSniffer'"
      [columns]="columns"
      [rowNumbers]="true"
      [orderBy]="orderBy"
      [tableGlobalActions]="tableGlobalActions"
      (selectedDataUpdateFlag)="tableSelectedData = $event"
      (tableAction)="tableActions($event)"
      (tableGlobalAction)="tableGlobalAction($event)"
      [data]="tableData"
      [maxReg]="20"
      [quickFilters]="tableQuickFilters"
    >
    </app-table-controller>
  </div>
</div>
