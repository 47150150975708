// @angular
import { Component, OnInit, TemplateRef } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Sesion
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
//Reload
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";

// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ManagementConcentrator } from "../ManagementInterface.type";
import { GATEWAY_ACTIVATE_STATES } from "../../../../interfaces/GatewayGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { dateFormat } from "highcharts";
import { formatNumber } from "@angular/common";
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";

@Component({
  selector: 'app-management-entity-concentrators',
  templateUrl: './management-entity-concentrators.component.html',
  styleUrls: ['./management-entity-concentrators.component.scss']
})
export class ManagementEntityConcentratorsComponent implements OnInit {
/***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  // Variables de sesión
  currentEntity: Entity;
  currentClient:Client;

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Gateways
  concentratorList: ManagementConcentrator[];

  readonly LW_MBUS_BATTERY: number = 19000;
  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("concentrators") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableDataColumn )[]

  tableQuickFilters: TableQuickFilter[][] ;


  entitySub: any;
  clientSub: any;
  dialog: any;
  numberFormat: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    private TemplateService: TemplateService,
    private translate: TranslateService,
    private SessionDataService: SessionDataService,
    private ReloadComponentService: ReloadComponentService,
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentClient = this.SessionDataService.getCurrentClient();
    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.ReloadComponentService.reload();
    });
    this.clientSub = this.SessionDataService.getClient().subscribe(()=>{
      this.ReloadComponentService.reload();
    });
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: any) => {
        if (dialogAction.action == "reload") {
          this.getConcentratorList();
        }
      }
    );
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();

      // Inicialización
      if (this.currentEntity) {
        this.loadComponent();
      }
  }

    /***************************************************************************/
  // ANCHOR Finalización del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getConcentratorList();
    this.columns= [
      {
        title: "serial-number",
        data: "nro_serie",
        search: "nro_serie",
        sort: "nro_serie",
        visible: true,
        link: "concentratorLink",
      },
      {
        title: "version-firmware",
        data: "version_fw",
        search: "version_fw",
        sort: "version_fw",
        visible: true,
      },
      {
        title: "state",
        data: "activateParsed",
        search: "activateParsed",
        sort: "activateParsed",
        visible: true,
      },

      {
        title: "installation-date-local",
        data: "installationParsed",
        search: "installationParsed",
        sort: "installation",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "last-communication-local",
        data: "last_communicationParsed",
        search: "last_communicationParsed",
        sort: "last_communication",
        date: true,
        dateLocal: true,
        visible: true,
      },
      {
        title: "last-join-date-local",
        data: "last_join_timestampParsed",
        search: "last_join_timestampParsed",
        sort: "last_join_timestamp",
        date: true,
        visible: true,
      },
      {
        title: "battery-only",
        data: "lastBatValue",
        search: "lastBatValue",
        sort: "lastBatValue",
        numerical: false,
        visible: true,
      },
      {
        title: "sf-min",
        data: "min_sfParsed",
        search: "min_sfParsed",
        sort: "min_sf",
        numerical: true,
        visible: true,
      },
      {
        title: "RSSI",
        data: "last_rssi",
        search: "last_rssi",
        sort: "last_rssi",
        numerical: true,
        visible: true,
      },
      {
        title: "SNR",
        data: "last_snr",
        search: "last_snr",
        sort: "last_snr",
        numerical: true,
        visible: true,
      },
      {
        title: "meters",
        data: "nrocontadores",
        search: "nrocontadores",
        sort: "nrocontadores",
        numerical: false,
        visible: true,
      },
    ];
    this.tableQuickFilters= [
      [
        {
          name: "in-network-with-comm",
          columnSearch: "comunica",
          condition: { type: "boolean", rule: true },
          active: history.state.data?.includes("ACTIVE")
        },
      ]
    ];
  }


  getConcentratorList(): void {
    // Llamada a getConcentratorsByEntity, pasando el id de la entidad actual
    this.ManagementController.getConcentratorsByEntity(this.currentEntity.id).subscribe((response) => {
      let data: ManagementConcentrator[] = [];
      if (response["code"] == 0) {
        data = response["body"] || [];
      // Iterar sobre la lista de concentradores y aplicar formateo
        data.forEach((concentrator: ManagementConcentrator) => {
          let totalConsumption =
          concentrator.consumo_transmision +
          concentrator.consumo_mbus +
          concentrator.consumo_lorawan;


          // Variables que necesitan formateo
            let remainingBatteryPercentage = (((this.LW_MBUS_BATTERY - totalConsumption)) / this.LW_MBUS_BATTERY) * 100;
            concentrator.lastBatValue = formatNumber(remainingBatteryPercentage, this.numberFormat) + "%";

          concentrator.activateParsed =
            concentrator.activate != null
              ? this.translate.instant(GATEWAY_ACTIVATE_STATES[concentrator.activate])
              : null;

          // Crear el enlace al concentrador
          concentrator.concentratorLink = "/dispositivos/detalle/lw-mbus/" + concentrator.id;

          // Formatear el lastJoinTimestamp a formato europeo (Madrid)
          if (concentrator.lastJoinTimestamp != null) {
            const date = new Date(concentrator.lastJoinTimestamp);

            // Ajustar la zona horaria a Madrid y formatear a dd/MM/yyyy HH:mm:ss
            const options: Intl.DateTimeFormatOptions = {
              timeZone: 'Europe/Madrid',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false, // Usamos formato 24 horas
            };

            concentrator.lastJoinTimestampParsed = new Intl.DateTimeFormat('es-ES', options).format(date);
          }
        });


        // Asignar la lista de concentradores formateados a la propiedad
        this.concentratorList = data.length > 0 ? data : []; // Si está vacío, asignar un arreglo vacío
      } else {
        // Si la respuesta no tiene el código esperado, asignar un arreglo vacío
        this.concentratorList = [];
      }
    });
  }

}
