// @angular
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
// Servicios propios
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayVersionParserService } from "../../gateways/GatewaVersionParserService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { DomControllerService } from "../../../../services/shared/DomControllerService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
// Componentes
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
// Interfaces
import { GraphColorByPoint } from "../../../../modules/graph-module/GraphInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
  TableHighlightRow,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { ManagementGateway } from "../ManagementInterface.type";
import {
  Gateway,
  GATEWAY_STATES,
  GatewayGraphData,
  GatewayMongoGraphData,
} from "../../../../interfaces/GatewayGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";

// Componentes
import { ManagementGlobalDialogComponent } from "./management-global-dialog/management-global-dialog.component";
import { MapSelectorComponent } from "../../../../modules/map-module/map-selector/map-selector.component";
import { forkJoin, Observable } from "rxjs";
import Highcharts from "highcharts";

//Config
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-globalmanagement",
  templateUrl: "./management-global.component.html",
  styleUrls: ["./management-global.component.scss"],
})
export class ManagementGlobalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  graphData: GatewayGraphData[];
  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");
  tableSelectedData: any;
  // Gateways
  gatewayList: ManagementGateway[];

  // Table
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("gateways-state") +
    " " +
    this.DateParserService.getDate();
  tableHighlightRow: TableHighlightRow[] = [
    {
      condition: "highlightNoCommunicate",
      color: "red",
      title: "no-communicates",
    },
    { condition: "highlightNoActive", color: "yellow", title: "no-actives" },
  ];
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "actives",
        columnSearch: "activeAndInstalled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "no-actives",
        columnSearch: "activeAndInstalled",
        condition: { type: "boolean", rule: false },
        active: false,
      },
      {
        name: "communicates",
        columnSearch: "activeAndInstalledAndCommunicate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "no-communicates",
        columnSearch: "highlightNoCommunicate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "in-stock",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.IN_STOCK },
        active: false,
      },
      {
        name: "available",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.AVAILABLE },
        active: false,
      },
      {
        name: "activated",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.ACTIVATED },
        active: false,
      },
      {
        name: "damaged",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.DAMAGED },
        active: false,
      },
      {
        name: "repair",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.REPAIR },
        active: false,
      },
      {
        name: "discarded",
        columnSearch: "state",
        condition: { type: "number", rule: GATEWAY_STATES.DISCARDED },
        active: false,
      },
    ],
    [
      {
        name: "bbdd-final-points-error",
        columnSearch: "bbddFinalPointsError",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        // {
        //   name: "show-gateway-onmap",
        //   tooltip: "show-gateway-onmap",
        //   icon: "fas fa-map-marker-alt",
        //   visible: { attribute: null, rule: true },
        //   disabled: "disableShowMap",
        // },
        {
          name: "delete-gateway",
          tooltip: "gateway-delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "id",
      data: "id",
      search: null,
      sort: null,
      visible: null,
    },
    {
      title: "entity",
      data: "entity",
      search: "entity",
      sort: "entity",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "AMEI",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "version-hardware",
      data: "hwVersion",
      search: "hwVersion",
      sort: "hwVersion",
      visible: true,
    },
    {
      title: "band",
      data: "bandaParsed",
      search: "bandaParsed",
      sort: "bandaParsed",
      visible: true,
    },
    {
      title: "micro",
      data: "microParsed",
      search: "microParsed",
      sort: "microParsed",
      visible: true,
    },
    {
      title: "LTE",
      data: "lte",
      search: "lte",
      sort: "lte",
      boolean: true,
      alter: {
        condition: "lte",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramqspi",
      data: "mramQspi",
      search: "mramQspi",
      sort: "mramQspi",
      boolean: true,
      alter: {
        condition: "mramQspi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramspi",
      data: "mramSpi",
      search: "mramSpi",
      sort: "mramSpi",
      boolean: true,
      alter: {
        condition: "mramSpi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-gps",
      data: "gps",
      search: "gps",
      sort: "gps",
      boolean: true,
      alter: {
        condition: "gps",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-port",
      data: "localPort",
      search: "localPort",
      sort: "localPort",
      boolean: true,
      alter: {
        condition: "localPort",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-debug",
      data: "debug",
      search: "debug",
      sort: "debug",
      boolean: true,
      alter: {
        condition: "debug",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "channels",
      data: "canalesParsed",
      search: "canalesParsed",
      sort: "canalesParsed",
      numerical: true,
      visible: true,
    },
    {
      title: "last-communication-local",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "last-hello-local",
      data: "lastHelloParsed",
      search: "lastHelloParsed",
      sort: "lastHello",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
    {
      title: "last-load",
      data: "lastChargeReadedParsed",
      search: "lastChargeReadedParsed",
      sort: "lastChargeReaded",
      numerical: true,
      visible: true,
    },
    {
      title: "installed-gateway-local",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "main-meters",
      data: "nroMainContadoresParsed",
      search: "nroMainContadoresParsed",
      sort: "nroMainContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "redundant-meter-number",
      data: "nroRedundantContadoresParsed",
      search: "nroRedundantContadoresParsed",
      sort: "nroRedundantContadores",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "totalMetersParsed",
      search: "totalMetersParsed",
      sort: "totalMeters",
      numerical: true,
      visible: true,
    },
    {
      title: "operator",
      data: "operadora",
      search: "operadora",
      sort: "operadora",
      visible: true,
    },
    {
      title: "PLMN",
      data: "plmn",
      search: "plmn",
      sort: "plmn",
      visible: true,
    },
    {
      title: "end-points-total",
      data: "puntosFinalesTotales",
      search: "puntosFinalesTotales",
      sort: "puntosFinalesTotales",
      numerical: true,
      visible: true,
    },
    {
      title: "bbdd-final-points-error",
      data: "bbddFinalPointsError",
      search: "bbddFinalPointsError",
      sort: "bbddFinalPointsError",
      alter: {
        condition: "bbddFinalPointsError",
        skins: [{ rule: true, class: "fas fa-times-circle" }],
      },
      boolean: true,
      visible: true,
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  tableGlobalActions: TableGlobalAction[];

  // Mapa
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  mapType: string = "onlyGateways";
  mapHeight: number = 350;
  mapGateways: ManagementGateway[];

  // Menú
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "update",
      icon: "fas fa-sync-alt",
      text: this.translate.instant("update"),
      visible: true,
    },
    {
      action: "tasks-log",
      icon: "fas fa-list-alt",
      text: this.translate.instant("tasks-log"),
      visible: true,
    },
  ];
  mapSelectorActive: boolean;

  // GRAPH
  highchartsOptions: object;
  mongoGraphData: GatewayMongoGraphData[][];
  from: string;
  to: string;
  graphSeries: object[];
  sateliteShowVcc: boolean = true;
  colorByPoint: GraphColorByPoint;
  chartOptions: object;


  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private ManagementController: ManagementControllerService,
    private MaterialDialogService: MaterialDialogService,
    private requestQueue: RequestQueueService,
    private router: Router,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private GraphOptionsService: GraphOptionsService,
    private viewportScroller: ViewportScroller,
    private DomControllerService: DomControllerService,
    private GatewayController: GatewayControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#gateway-list-map").then(() =>
      this.mapSelector.showOnMap("gateway")
    );
  }

  ngOnInit(): void {
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getGatewayList();
  }

  // Obtención de la lista de gateways
  getGatewayList(): void {
    this.ManagementController.getAllGateways().subscribe((response) => {
      if (response["code"] == 0) {
        let gatewayList: ManagementGateway[] = response["body"];
        gatewayList.forEach((gateway: ManagementGateway) => {
          // Variables que necesitan formateo
          gateway.stateParsed =
            gateway.state != null
              ? this.translate.instant(GATEWAY_STATES[gateway.state])
              : null;
          gateway.activeAndInstalled =
            gateway.installation && gateway.state == GATEWAY_STATES.ACTIVATED;
          gateway.highlightNoActive = !gateway.activeAndInstalled;
          gateway.highlightNoCommunicate =
            gateway.state == GATEWAY_STATES.ACTIVATED && !gateway.comunica;
          gateway.activeAndInstalledAndCommunicate =
            gateway.state == GATEWAY_STATES.ACTIVATED && gateway.comunica;
          gateway.disableShowMap = !(gateway.latitude && gateway.longitude);
          gateway.totalMeters =
            gateway.nroMainContadores + gateway.nroRedundantContadores;
          // Canales
          let canalesBinary = gateway.canales?.toString(2);
          gateway.canalesParsed = canalesBinary
            ?.split("")
            ?.filter((value) => value).length;
          // Versión
          gateway.bandaParsed = this.GatewayVersionParserService.getBand(
            gateway.banda
          );
          gateway.microParsed = this.GatewayVersionParserService.getMicro(
            gateway.micro
          );
          // Opciones
          if (gateway.options != null) {
            this.GatewayVersionParserService.setGatewayOptions(gateway);
          }
          this.gatewayList = gatewayList;
          this.mapGateways = [...this.gatewayList];

          this.setTableGlobalActions();
        });
      }
    });
  }

  // Seteo de las acciones globales de la tabla
  setTableGlobalActions() {
    this.tableGlobalActions = [
      {
        title: "show-meters-map",
        icon: "fas fa-map-marker-alt",
        selectionRequired: true,
        help: "help-table-map",
      },
      {
        title: "end-points-total",
        icon: "fas fa-tachometer-alt",
        selectionRequired: true,
        help: "help-table-end-points",
      },
      {
        title: "show-selected-graph",
        icon: "fas fa-chart-area",
        selectionRequired: true,
        help: "help-table-map",
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: ManagementGateway): void {
    switch (action) {
      case "show-gateway":
        this.showGateway(gateway.id, gateway.entity);
        break;
      //Lo hemos añadido en las globales
      // case "show-gateway-onmap":
      //   this.showGatewayOnMap(gateway.latitude, gateway.longitude);
      //   break;
      case "delete-gateway":
        this.deleteGateway(gateway);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "clean-tables":
        this.MaterialDialogService.openDialog(
          ManagementGlobalDialogComponent,
          null
        );
        break;
      case "show-meters-map":
        this.showOnMap();
        break;
      case "end-points-total":
        this.getEndPoints(this.tableSelectedData);
        break;
      case "show-selected-graph":
        this.loadGraph();
        this.DomControllerService.goToElement("#gateways-assigned-graph");
        break;
      default:
        break;
    }
  }

  loadGraph(): void {
    this.highchartsOptions
      ? this.loadGraphData(this.from, this.to)
      : this.setHighchartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("gateways-export")
      );
    highchartsOptions.scrollbar = { enabled: false };
    highchartsOptions.plotOptions.series.marker.enabled = false;
    highchartsOptions.tooltip.split = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let mongoGraphData: GatewayMongoGraphData[][] = [];
    let requestArray: Observable<object>[] = this.tableSelectedData.map(
      (gateway: Gateway) => {
        return this.GatewayController.getMongoGraph(
          gateway.id,
          this.from,
          this.to
        );
      }
    );
    forkJoin(requestArray).subscribe((results) => {
      results.forEach((result) => {
        if (result["code"] === 0) {
          mongoGraphData.push(result["body"]["statusGwList"]);
        }
      });
      this.mongoGraphData = mongoGraphData;
      this.getSeries();
    });
  }

  getSeries(): void {
    const self = this;
    let colors: string[] = [
      "#7cb5ec",
      "#434348",
      "#90ed7d",
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
    ];
    let vbatsSeries: object[] = [];
    let chargeSeries: object[] = [];
    let graphSeries: object[][] = [];
    this.graphData = [];
    this.graphSeries = [];

    for (let i = 0; i < this.mongoGraphData?.length; i++) {
      if (this.mongoGraphData[i]?.length > 0) {
        this.graphData[i] = {
          gatewayId: null,
          vbats: [],
          charge: [],
          temperature: [],
        };

        this.graphData[i].gatewayId = this.mongoGraphData[i][0].gw;
        this.mongoGraphData[i]
          .sort((a, b) => a.tm - b.tm)
          .map((data: GatewayMongoGraphData) => {
            this.graphData[i].vbats.push([data.tm, data.vbat]);
            this.graphData[i].charge.push([
              data.tm - 3600000,
              data.carga,
              data.luz,
              data.parcial,
            ]);
            this.graphData[i].temperature.push([data.tm, data.temp]);
          });
      }
    }

    // Series
    for (let i = 0; i < this.graphData?.length; i++) {
      if (this.graphData[i]?.gatewayId) {
        let gateway = this.gatewayList.find(
          (gateway: ManagementGateway) =>
            gateway.id == this.graphData[i]?.gatewayId
        )?.unidadVenta;

        // Serie de valores de tensión
        if (this.sateliteShowVcc) {
          vbatsSeries = [
            {
              id: gateway + " tension",
              name: gateway + ": " + this.translate.instant("tension"),
              type: "line",
              navigatorOptions: {
                type: "line",
              },
              tooltip: {
                pointFormat:
                  '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
                valueSuffix: " v",
                valueDecimals: 3,
              },
              color: colors[i % 10],
              data: this.graphData[i].vbats,
              dataGrouping: { approximation: "average" },
              zIndex: 1,
              yAxis: 1,
            },
          ];
        }

        // Serie de valores de carga
        this.colorByPoint = {
          serie: 1,
          color: "#f9d71c",
          conditionIndex: 3,
          conditionValue: 1,
          legend:
            `<div>
                  <i class="fas fa-circle" style="color:#f9d71c; margin-right: 0.5rem;"></i>
                  <span>` +
            this.translate.instant("partial-values") +
            `</span>
                </div>`,
        };
        chargeSeries = [
          {
            id: gateway + " carga",
            name: gateway + ": " + this.translate.instant("load"),
            type: "line",
            navigatorOptions: {
              type: "line",
            },
            tooltip: {
              valueDecimals: 3,
              pointFormatter: function () {
                return (
                  `<span style="color:` +
                  this.color +
                  `">` +
                  this.series.name +
                  "</span>: <b>" +
                  Highcharts.numberFormat(this.y, 3) +
                  ` mAh</b> |
                          <span style="color:` +
                  this.color +
                  `"> ` +
                  self.translate.instant("time-light") +
                  "</span>: <b>" +
                  Math.floor(this.series.options.data[this.index][2] / 60)
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  (this.series.options.data[this.index][2] % 60)
                    .toString()
                    .padStart(2, "0") +
                  "</b><br>"
                );
              },
            },
            color: colors[i % 10],
            data: this.graphData[i].charge,
            dataGrouping: { approximation: "sum" },
          },
        ];

        graphSeries[i] = vbatsSeries.concat(chargeSeries);
      }
    }

    graphSeries.forEach((gatewaySeries: any) =>
      gatewaySeries.forEach((serie: any) => this.graphSeries.push(serie))
    );

    this.setChartsOptions();
  }

    setChartsOptions(): void {
      let chartOptions: object = JSON.parse(
        JSON.stringify(GRAPH_CONFIG.default.chartOptions)
      );
      delete chartOptions["chart"]["navigatorOptions"];
      delete chartOptions["yAxis"];
      chartOptions["navigator"]["enabled"] = false;
      chartOptions["chart"]["height"] = "35%";
      chartOptions["yAxis"] = [
        // Batería
        {
          title: {
            text: this.translate.instant("battery-load"),
            style: {
              color: "#42a5f5",
              fontWeight: "bold",
            },
          },
          labels: {
            format: "{value} mAh ",
            style: {
              color: "#42a5f5",
            },
          },
          min: 0,
          visible: true,
          opposite: false,
        },
      ];
      if (this.sateliteShowVcc) {
        chartOptions["yAxis"].push(
          // Tensión
          {
            title: {
              text: this.translate.instant("battery-tension"),
              style: {
                color: "#ef5350",
                fontWeight: "bold",
              },
            },
            labels: {
              format: "{value} v",
              style: {
                color: "#ef5350",
              },
            },
            min: 0,
            // max: 20,
            visible: true,
            opposite: true,
          }
        );
      }
      chartOptions["series"] = this.graphSeries;
      this.chartOptions = chartOptions;
    }

  // Obtención de los puntos finales de los gateways seleccionados
  getEndPoints(data: any): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("final-points-reload-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        data.map(
          (gateway) =>
            (gateway.puntosFinalesTotales =
              this.translate.instant("waiting-response") + "...")
        );
        data.forEach((gateway) => {
          this.GatewayController.getEndPointsTotal(gateway.id).subscribe(
            (response) => {
              if (response["code"] == 0) {
                gateway.puntosFinalesTotales =
                  response["body"] != null
                    ? response["body"]
                    : this.translate.instant("unknown");
              } else {
                gateway.puntosFinalesTotales =
                  this.translate.instant("unknown");
              }
            }
          );
        });
      }
    });
  }

  // Redirección a detalles del gateway
  showGateway(id: number, entityName: string): void {
    this.DeviceRouteSelectorService.updateEntityByName(entityName);
    this.router.navigate(["gateways/detalle/gateway", id]);
  }

  // Centra el mapa en el gateway seleccionado
  showGatewayOnMap(gatewayLatitude: string, gatewayLongitude: string): void {
    if (gatewayLatitude != null && gatewayLongitude != null) {
      this.mapController.flyTo([gatewayLatitude, gatewayLongitude], 20, {
        animate: false,
      });
      this.viewportScroller.scrollToAnchor("map-panel");
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("show-gateway-onmap-error")
      );
    }
  }

  // Recarga del componente
  reload(): void {
    this.gatewayList = null;
    this.mapGateways = null;
    setTimeout(() => this.loadComponent(), 1000);
  }

  // Borrado de gateway
  deleteGateway(gateway: any): void {
    this.ToastService.fireAlertWithTripleOptions(
      "question",
      this.translate.instant("gateway-delete-question"),
      this.translate.instant("cancel"),
      this.translate.instant("with-communication"),
      this.translate.instant("without-communication")
    ).then((userSelection: string) => {
      if (userSelection == "option1") {
        this.requestQueue.deleteGatewayTask(gateway, 1);
      } else if (userSelection == "option2") {
        this.requestQueue.deleteGatewayTask(gateway, 0);
      }
    });
  }

  // // Actualización de datos del mapa
  // updateMap(data: ManagementGateway[]): void {
  //   // this.mapGateways = null;
  //   setTimeout(() => {
  //     this.mapGateways = [...data];
  //   }, 0);
  // }

  // Acción de menu
  menuAction(action: string): void {
    switch (action) {
      case "update":
        this.reload();
        break;
      case "tasks-log":
        this.router.navigate(["/mantenimiento/tareas-gateways"]);
        break;
      default:
        break;
    }
  }
}
