<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "notifications" | translate }}
    </h5>
  </div>
  <div class="dialog-body notifications-modal-body">
    <div
      class="notifications-modal-table"
      [ngClass]="{
        hidden: !notificationData || notificationData?.length == 0
      }"
    >
      <!-- Tabla de notificaciones -->
      <app-table-controller
        *ngIf="notificationData"
        [columns]="columns"
        [maxReg]="tableMaxReg"
        (tableAction)="tableAction($event)"
        [orderBy]="orderBy"
        [onlyTable]="true"
        [noPinnedColumns]="true"
        [highlightRow]="highlightRow"
        [topPaginationDisabled]="true"
        [data]="notificationData"
      >
      </app-table-controller>
    </div>
    <p *ngIf="!notificationData || notificationData.length == 0">
      {{ "notifications-empty" | translate }}
    </p>
  </div>
  <div class="dialog-footer">
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
