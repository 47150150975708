import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class ReloadComponentService {
  constructor(
    private router: Router,
    private SessionDataService: SessionDataService
  ) {}

  // Recarga de componente
  reload(): void {
    this.SessionDataService.sendUrlReloadFlag(true);
    let currentUrl: string = this.router.url;
    this.router.navigate([currentUrl], { state: { data: history.state.data } });
  }
}
