// @angular
import { Component, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { NgxSpinnerService } from "ngx-spinner";
// Variables
import {
  OUTLIERS_METHODS,
  TWITTER_THRESHOLDS,
} from "../../../../../../../modules/graph-module/GraphInterface.type";

@Component({
  selector: "app-meter-selection-dialog",
  templateUrl: "./meter-selection-dialog.component.html",
  styleUrls: ["./meter-selection-dialog.component.scss"],
})
export class MeterSelectionDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  date = { from: null, to: null };
  dateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("30", "days"),
    endDate: moment().endOf("day"),
  };
  method: string = "twitter";
  defaultMethod: string = "twitter";
  methodList = JSON.parse(JSON.stringify(OUTLIERS_METHODS)).map((method) => {
    method.name = this.translate.instant(method.name);
    return method;
  });
  threshold: string | number;
  defaultThreshold: string | number;
  thresholdList = JSON.parse(JSON.stringify(TWITTER_THRESHOLDS)).map(
    (threshold) => {
      threshold.name = this.translate.instant(threshold.name);
      return threshold;
    }
  );

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private NgxSpinnerService: NgxSpinnerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Actualización del rango seleccionado
  updateDateRange(dateRangeSelected: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }): void {
    if (dateRangeSelected?.startDate) {
      this.date.from = moment_timezone(dateRangeSelected?.startDate)
        .tz(this.SessionDataService.getCurrentAgrupation().timezone, true)
        .startOf("day")
        .valueOf()
        .toString();
    } else {
      this.date.from = null;
    }
    if (dateRangeSelected?.endDate) {
      this.date.to = moment_timezone(dateRangeSelected?.endDate)
        .tz(this.SessionDataService.getCurrentAgrupation().timezone, true)
        .endOf("day")
        .valueOf()
        .toString();
    } else {
      this.date.to = null;
    }
    setTimeout(() => {
      this.dateRange = dateRangeSelected;
    });
  }

  // Detección de valores atípicos
  detectSelectionOutliers(): void {
    this.NgxSpinnerService.show("spinner-hard");
    this.SessionDataService.sendDialogAction({ action: "close" });
    setTimeout(() => {
      this.SessionDataService.sendDialogAction({
        action: "outliers",
        data: {
          method: this.method,
          from: this.date.from,
          to: this.date.to,
          dateRange: this.dateRange,
          threshold: this.threshold,
        },
      });
    }, 100);
  }
}
