import { Injectable } from "@angular/core";
import { AlarmData } from "../../screens/dashboard/alarms/AlarmInterface.type";
import { DetailDeviceAlarm } from "../../screens/dashboard/devices/DeviceInterface.type";

@Injectable({
  providedIn: "root",
})
export class SuspicionsService {
  constructor() {}

  // Filtrado de sospechas para perfiles no ARSON
  filterSuspicions(
    alarmList: (DetailDeviceAlarm | AlarmData)[]
  ): (DetailDeviceAlarm | AlarmData)[] {
    return alarmList?.filter(
      (alarm: DetailDeviceAlarm | AlarmData) =>
        !alarm.name?.toUpperCase().includes("SOSPECHA") &&
        !alarm.name?.toUpperCase().includes("SOSPECHOSA") &&
        !alarm.name?.toUpperCase().includes("SUSPICION") &&
        !alarm.name?.toUpperCase().includes("SUSPECTED") &&
        !alarm.name?.toUpperCase().includes("SOSPITA") &&
        !alarm.name?.toUpperCase().includes("SOSPETTO") &&
        !alarm.name?.toUpperCase().includes("SOSPETTA") &&
        !alarm.name?.toUpperCase().includes("SOUPÇON") &&
        !alarm.name?.toLowerCase().includes("υποψία") &&
        !alarm.name?.toLowerCase().includes("ύποπτος")
    );
  }

  // Comprobación de si una alarma es sospecha
  isSuspicion(alarm: DetailDeviceAlarm | AlarmData): boolean {
    return (
      alarm.name?.toUpperCase().includes("SOSPECHA") ||
      alarm.name?.toUpperCase().includes("SOSPECHOSA") ||
      alarm.name?.toUpperCase().includes("SUSPICION") ||
      alarm.name?.toUpperCase().includes("SUSPECTED") ||
      alarm.name?.toUpperCase().includes("SOSPITA") ||
      alarm.name?.toUpperCase().includes("SOSPETTO") ||
      alarm.name?.toUpperCase().includes("SOSPETTA") ||
      alarm.name?.toUpperCase().includes("SOUPÇON") ||
      alarm.name?.toLowerCase().includes("υποψία") ||
      alarm.name?.toLowerCase().includes("ύποπτος")
    );
  }
}
