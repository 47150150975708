<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Filtrado por entidad -->
        <div class="alarm-select">
          <app-material-select
            class="material-header-select"
            [title]="'meters-with-alarms-continued' | translate"
            [fullWidth]="true"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="true"
            [data]="alarmSelectOptions"
            (selectedOption)="alarmSelect = $event?.value"
          ></app-material-select>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla de alarmas -->
        <app-table-controller
          [tableId]="'alarmsDevicesList'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getAlarms($event.from, $event.to)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
