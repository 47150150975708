// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { AlarmControllerService } from "../../../../../../services/server/AlarmController.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { SuspicionsService } from "../../../../../../services/shared/SuspicionsService.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  ALARM_STATES,
  AlarmData,
} from "../../../../alarms/AlarmInterface.type";
// Componentes
import { AlarmEditDialogComponent } from "../../../../alarms/alarm-edit-dialog/alarm-edit-dialog.component";
// Variables
import { PROFILES } from "../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meteralarm",
  templateUrl: "./meter-alarms.component.html",
  styleUrls: ["./meter-alarms.component.scss"],
})
export class MeterAlarmsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentEntity: Entity;
  agrupationSub: Subscription;
  entitySub: Subscription;
  sessionProfile: string;
  dialog: Subscription;

  // Contador
  meterNroSerie: string;
  meterId: number;

  // Tabla
  tableData: AlarmData[];
  selectedTableData: AlarmData[];
  from: string;
  to: string;
  orderBy: object = { attribute: "initDate", reverse: true };
  tableMaxReg: number = 100;
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  quickFiltersExclusion: boolean[] = [true];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "edit-alarms",
      icon: "fas fa-edit",
      selectionRequired: true,
    },
  ];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "active",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: false },
        active: true,
      },
      {
        name: "inactive",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "disabled",
        columnSearch: "disabled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "enabled",
        columnSearch: "disabled",
        condition: { type: "boolean", rule: false },
        active: true,
      },
    ],
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-detail",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "edit",
          tooltip: "edit",
          icon: "fas fa-edit edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "disable",
          tooltip: "disable",
          icon: "fas fa-eraser disable",
          visible: { attribute: "disabled", rule: false },
          disabled: false,
          warning: true,
        },
        {
          name: "enable",
          tooltip: "enable",
          icon: "fas fa-plus enable",
          visible: { attribute: "disabled", rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      data: null,
      search: null,
      sort: null,
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "enabled",
      data: "disabled",
      search: "disabled",
      sort: "disabled",
      alter: {
        condition: "disabled",
        skins: [
          { rule: false, class: "fas fa-check-circle" },
          { rule: true, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
    {
      title: "start-date",
      data: "initDateParsed",
      search: "initDateParsed",
      sort: "initDate",
      date: true,
      visible: true,
    },
    {
      title: "end-date",
      data: "finalDateParsed",
      search: "finalDateParsed",
      sort: "finalDate",
      date: true,
      visible: true,
    },
    {
      title: "alarms",
      data: "name",
      search: "name",
      sort: "name",
      modalInfo: true,
      visible: true,
    },
    {
      title: "comments",
      data: "comments",
      search: "comments",
      sort: "comments",
      visible: true,
    },
  ];
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays();

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private DateParserService: DateParserService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getAlarms(this.from, this.to);
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meterNroSerie = history.state.data;
    this.meterId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getAlarms(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de alarmas
  getAlarms(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.AlarmController.getMeterAlarmsFromTo(this.meterId, from, to).subscribe(
      (response) => {
        let tableData: AlarmData[];
        if (response["code"] == 0) {
          if (response["body"].length > 0) {
            tableData = response["body"];
            tableData.forEach((alarm: AlarmData) => {
              alarm.code != null
                ? (alarm.name = this.translate.instant(
                    "AlertMeter" + alarm.code
                  ))
                : "";
              alarm.code != null
                ? (alarm.description = this.translate.instant(
                    "AlertDescription" + alarm.code
                  ))
                : "";
              if (
                alarm.description == "" ||
                alarm.description == "AlertDescription" + alarm.code
              ) {
                alarm.description = this.translate.instant("no-details");
              }
              alarm.stateParsed = ALARM_STATES[alarm.state]
                ? this.translate.instant(ALARM_STATES[alarm.state])
                : null;
              // Información extra
              alarm.modalInfo = true;
              alarm.modalInfoData = {
                title: this.translate.instant("alarm-info"),
                html:
                  `<h4><b> (` +
                  this.translate.instant("code") +
                  " " +
                  alarm.code +
                  ") " +
                  alarm.name +
                  `: </b></h4>
                    <pre class="pre-without-format">` +
                  alarm.description +
                  `</pre>`,
              };
            });
            this.tableData =
              this.SessionDataService.getCurrentProfile() == PROFILES.ARSON &&
              this.SessionDataService.getCurrentSuspicionActive()
                ? tableData
                : this.SuspicionsService.filterSuspicions(tableData);
          } else {
            this.tableData = [];
          }
        }
      }
    );
  }

  // Acciones de la tabla
  tableActions(action: string, alarm: AlarmData): void {
    switch (action) {
      case "show-detail":
        this.router.navigate([
          "/dispositivos/detalle/contador/" + alarm.contador,
        ]);
        break;
      case "edit":
        this.MaterialDialogService.openDialog(AlarmEditDialogComponent, [
          alarm,
        ]);
        break;
      case "enable":
        this.enableAlarm(alarm);
        break;
      case "disable":
        this.disableAlarm(alarm);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "edit-alarms":
        this.MaterialDialogService.openDialog(
          AlarmEditDialogComponent,
          this.selectedTableData
        );
        break;
      default:
        break;
    }
  }

  // Habilitación de alarma
  enableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-enabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.enableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-enabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }

  // Deshabilitación de alarmas
  disableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-disabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.disableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-disabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }
}
