import { LoadComponent } from './../../../gateways/gateway-detail/gateway-load/gateway-load.component';
import { EntityControllerService } from './../../../../../services/server/EntityController.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { MetrologicalControllerService } from './../../../../../services/server/MetrologicalController.service';
import { Component, Input } from '@angular/core';
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { Options } from 'highcharts';
import { TableActionColumn, TableDataColumn } from '../../../../../modules/table-module/TableInterface.type';
import { GasMeterWithoutMetrological } from '../../AlarmInterface.type';
import { Entity } from '../../../../../interfaces/EntityGlobalInterface.type';
import { Agrupation } from '../../../../../interfaces/AgrupationGlobalInterface.type';
import { Client } from '../../../../../interfaces/ClientGlobalInterface.type';

@Component({
  selector: 'app-devices-without-pcsfcv-dialog',
  templateUrl: './devices-without-pcsfcv-dialog.component.html',
  styleUrls: ['./devices-without-pcsfcv-dialog.component.scss']
})

export class DevicesWithoutPcsfcvDialogComponent {

  // ANCHOR Variables
  // Variables de entrada
  @Input()
  data: GasMeterWithoutMetrological[]; // Lista de dispositivos sin PCS/FCV
  agrupationIdList: number[] = []; // Lista de ID de agrupaciones
  // ANCHOR Table Data
  tableData: GasMeterWithoutMetrological[]; // Datos de la tabla
  highchartsOptions: Options; // Opciones para los gráficos
  rowNumbers: true; // Definición de si se deben mostrar los números de fila
  chartOptions: any; // Opciones de configuración del gráfico

  // ANCHOR Columnas de la tabla
  columns: (TableDataColumn)[];
  clientList: Client[]; // Lista de clientes
  entityList:Entity[]; // Lista de entidades
  agrupationList: Agrupation[];

  // ANCHOR Constructor
  constructor(
    public SessionDataService: SessionDataService, // Servicio de sesión
    private MetrologicalControllerService: MetrologicalControllerService, // Servicio del controlador metrológico
    private EntityControllerService: EntityControllerService, // Servicio del controlador de entidades
    private translate:TranslateService // Servicio de traducción
  ) {}

  // ANCHOR ngOnInit
  ngOnInit(): void {
    // Inicialización de los dispositivos y agrupaciones
    this.data.forEach(device => {
      this.agrupationIdList.push(device.agrupationId);
    });
    this.loadComponent();
  }
  // ANCHOR load component
  loadComponent():void{
    this.clientList = this.SessionDataService.getCurrentClientList();
    this.entityList = this.getEntityList();
    this.agrupationList = this.getAgrupationList();
    this.loadData(); // Cargar los datos
    this.setColumns(); // Establecer las columnas
  }

  // ANCHOR loadData
  loadData(): void {
    // Llamada al servicio para obtener la lista de configuraciones incompletas de dispositivos
    this.MetrologicalControllerService.getIncompleteList(this.agrupationIdList).subscribe((response: any[]) => {
      let meters:GasMeterWithoutMetrological[] = response["body"];
      //Completamos los valores que le damos en el front
      meters.forEach(meter => {
        meter.deviceUrl = "dispositivos/detalle/contador/" + meter.contadorId;
        meter.claveMaestraUrl = "cups/detalle/" + meter.claveMaestraId;
        meter.entityString = this.entityList?.find(entity => entity.id == meter.entity)?.entity || '';
        meter.client = this.entityList?.find(entity => entity.id == meter.entity)?.client || null;
        meter.clientString = this.clientList?.find(client => client.clientId == meter.client)?.clientName || '';
        meter.agrupationString = this.agrupationList?.find(agrupation => agrupation.id == meter.agrupation)?.name || '';
      });
      // Asignamos los datos a la tabla
      this.tableData = meters; // Asignar los datos a la tabla
    });
  }

  // Obtención del listado de agrupaciones
  getAgrupationList(): Agrupation[] {
    // Inicializa una lista vacía de agrupaciones
    let agrupations: Agrupation[] = [];

    // Recorre la lista de entidades
    this.entityList?.forEach((entity: Entity) => {
        // Añade las agrupaciones que no muestran todas las entidades
        agrupations = agrupations.concat(
          entity.agrupations?.filter((agrupation) => !agrupation.showAllEntity) || []
        );
    });
    // Ordena la lista de agrupaciones por el nombre
    agrupations.sort((a, b) => a.name.localeCompare(b.name));
    return agrupations; // Devuelve la lista de agrupaciones
  }

  getEntityList() {
    let list : Entity[] = [];  // Inicializa una lista vacía de tipo Entity[]
    this.clientList.forEach(client => {
      list = list.concat(client.entityList);  // Aplana el array 'entityList' y lo agrega a 'list'
    });
    return list;  // Devuelve la lista final
  }


  // ANCHOR Configuración de las columnas
  setColumns(): void {
    this.columns = [
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        link: "deviceUrl",
        visible: true,
      },
      {
        title: "CUPS",
        data: "claveMaestra",
        search: "claveMaestra",
        sort: "claveMaestra",
        link: "claveMaestraUrl",
        visible: true,
      },
      {
        title: "client",
        data: "clientString",
        search: "clientString",
        sort: "clientString",
        visible: true,
      },
      {
        title: "entity",
        data: "entityString",
        search: "entityString",
        sort: "entityString",
        visible: true,
      },
      {
        title: "agrupation",
        data: "agrupationString",
        search: "agrupationString",
        sort: "agrupationString",
        visible: true,
      },
      {
        title: this.translate.instant("pcs") + " ("+ this.translate.instant("meter")  + ") ",
        data: "contadorPcsValue",
        search: "contadorPcsValue",
        sort: "contadorPcsValue",
        visible: true,
      },
      {
        title: this.translate.instant("conversion-factor") + " ("+ this.translate.instant("meter")  + ") ",
        data: "contadorConversionFactor",
        search: "contadorConversionFactor",
        sort: "contadorConversionFactor",
        visible: true,
        numerical: true,
      },
      {
        title: this.translate.instant("conversion-factor") + " (CUPS)",
        data: "claveMaestraConversionFactor",
        search: "claveMaestraConversionFactor",
        sort: "claveMaestraConversionFactor",
        visible: true,
        numerical: true,
      },
      {
        title: this.translate.instant("pcs") + " ("+ "CUPS"  + ") ",
        data: "claveMaestraPcsValue",
        search: "claveMaestraPcsValue",
        sort: "claveMaestraPcsValue",
        visible: true,
      },
    ];
  }
}
