export const QUERIES = [
  // Contadores por consumo y hora
  {
    id: 1,
    name: "query-meters-by-consumption",
    // Parámetros
    params: {
      // Selects
      select: [],
      // Inputs
      input: [
        {
          key: "consumo",
          label: "consumption-greater-than",
          type: "number",
          units: null,
          placeholder: null,
          value: null,
        },
      ],
      // Fechas
      date: [
        {
          key: "fechaDesde",
          label: "start-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
        {
          key: "fechaHasta",
          label: "end-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
      ],
    },
    columns: [
      { key: "meter", label: "meter" },
      { key: "total", label: "total" },
    ],
  },
  // Contadores parados
  {
    id: 2,
    name: "query-stopped-meters",
    // Parámetros
    params: {
      // Selects
      select: [],
      // Inputs
      input: [],
      // Fechas
      date: [
        {
          key: "fechaDesde",
          label: "start-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
        {
          key: "fechaHasta",
          label: "end-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
      ],
    },
    columns: [
      { key: "meter", label: "meter" },
      { key: "total", label: "total" },
    ],
  },
  // Contadores con consumo negativo
  {
    id: 3,
    name: "query-negative-consumption",
    // Parámetros
    params: {
      // Selects
      select: [],
      // Inputs
      input: [],
      // Fechas
      date: [
        {
          key: "fechaDesde",
          label: "start-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
        {
          key: "fechaHasta",
          label: "end-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
      ],
    },
    columns: [
      { key: "meter", label: "meter" },
      { key: "minimun", label: "minimun-consumption" },
    ],
  },
  // Contadores con fugas
  {
    id: 4,
    name: "query-leaks",
    // Parámetros
    params: {
      // Selects
      select: [],
      // Inputs
      input: [
        {
          key: "consumo",
          label: "consumption-always-greater",
          type: "number",
          units: null,
          placeholder: null,
          value: null,
        },
      ],
      // Fechas
      date: [
        {
          key: "fechaDesde",
          label: "start-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
        {
          key: "fechaHasta",
          label: "end-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
      ],
    },
    columns: [
      { key: "meter", label: "meter" },
      { key: "minimun", label: "minimun-consumption" },
    ],
  },
  // Contadores con picos anómalos
  {
    id: 5,
    name: "query-abnormal-peak",
    // Parámetros
    params: {
      // Selects
      select: [],
      // Inputs
      input: [
        {
          key: "factorConsumoMedio",
          label: "consumption-average-factor",
          type: "number",
          units: "%",
          placeholder: null,
          value: null,
        },
      ],
      // Fechas
      date: [
        {
          key: "fechaDesde",
          label: "start-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
        {
          key: "fechaHasta",
          label: "end-date",
          range: false,
          hour: true,
          minute: true,
          value: null,
        },
      ],
    },
    columns: [
      { key: "meter", label: "meter" },
      { key: "average", label: "balance-average-value" },
      { key: "anomaly", label: "anomaly" },
      { key: "%", label: "difference-%" },
    ],
  },
  // Comparativa de periodos
  // {
  //   id: 6,
  //   name: "query-consumption-compare",
  //   // Parámetros
  //   params: {
  //     // Selects
  //     select: [],
  //     // Inputs
  //     input: [],
  //     // Fechas
  //     date: [
  //       {
  //         keyStart: "fechaDesde1",
  //         keyEnd: "fechaHasta1",
  //         label: "graph-zoom",
  //         labelIndex: true,
  //         range: true,
  //         value: null,
  //       },
  //       {
  //         keyStart: "fechaDesde2",
  //         keyEnd: "fechaHasta2",
  //         label: "graph-zoom",
  //         labelIndex: true,
  //         range: true,
  //         value: null,
  //       },
  //     ],
  //   },
  //   columns: [
  //     { key: "meter", label: "meter" },
  //     { key: "total", label: "total" },
  //   ],
  // },
  // Contadores con sospechas de llenados de piscinas
  // {
  //   id: 7,
  //   name: "query-pool",
  //   // Parámetros
  //   params: {
  //     // Selects
  //     select: [],
  //     // Inputs
  //     input: [],
  //     // Fechas
  //     date: [
  //       {
  //         key: "fechaDesde",
  //         label: "start-date",
  //         range: false,
  //         hour: true,
  //         minute: true,
  //         value: null,
  //       },
  //       {
  //         key: "fechaHasta",
  //         label: "end-date",
  //         range: false,
  //         hour: true,
  //         minute: true,
  //         value: null,
  //       },
  //     ],
  //   },
  //   columns: [
  //     { key: "meter", label: "meter" },
  //     { key: "total", label: "total" },
  //   ],
  // },
  // Contadores con consumo diario mayor a
  // {
  //   id: 8,
  //   name: "query-daily-consumption",
  //   // Parámetros
  //   params: {
  //     // Selects
  //     select: [],
  //     // Inputs
  //     input: [
  //       {
  //         key: "consumo",
  //         label: "consumption-greater-than",
  //         type: "number",
  //         units: null,
  //         placeholder: null,
  //         value: null,
  //       },
  //     ],
  //     // Fechas
  //     date: [
  //       {
  //         key: "fechaDesde",
  //         label: "start-date",
  //         range: false,
  //         hour: true,
  //         minute: true,
  //         value: null,
  //       },
  //       {
  //         key: "fechaHasta",
  //         label: "end-date",
  //         range: false,
  //         hour: true,
  //         minute: true,
  //         value: null,
  //       },
  //     ],
  //   },
  //   columns: [
  //     { key: "meter", label: "meter" },
  //     { key: "total", label: "total" },
  //   ],
  // },

// Contadores con comparación de consumos entre dos periodos
{
  id: 9,
  name: "query-consumption-compare",
  // Parámetros
  params: {
    // Selects
     select: [],
    // Inputs
      input: [],
    // Fechas
    date: [
      {
        key: "fechaDesde",
        label: "start-date-period-1",  // Fecha de inicio para el primer periodo
        range: false,
        hour: true,
        minute: true,
        value: null,  // Valor por defecto
      },
      {
        key: "fechaHasta",
        label: "end-date-period-1",  // Fecha de fin para el primer periodo
        range: false,
        hour: true,
        minute: true,
        value: null,  // Valor por defecto
      },
      {
        key: "fechaDesde2",
        label: "start-date-period-2", // Fecha de inicio para el segundo periodo
        range: false,
        hour: true,
        minute: true,
        value: null,  // Valor por defecto
      },
      {
        key: "fechaHasta2",
        label: "end-date-period-2", // Fecha de fin para el segundo periodo
        range: false,
        hour: true,
        minute: true,
        value: null,  // Valor por defecto
      },
    ],
  },
  // Columnas de resultado
  columns: [
    { key: "meter", label: "meter" }, //contador
    { key: "firstPeriodConsumption", label: "average-period-1" }, // Consumo promedio en el primer periodo
    { key: "secondPeriodConsumption", label: "average-period-2" }, // Consumo promedio en el segundo periodo
    { key: "variation", label: "difference-%" },         // Diferencia porcentual
  ],
}
];
