<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <!-- Título -->
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b> {{ "notifications" | translate }}</b>
        </h5>
      </div>

      <!-- Panel de importación -->
      <div class="panel-body">
        <div>
          <h4>
            <b>{{ "notifications-send" | translate }} </b>
          </h4>
          <h5 class="user-import-warning">
            <i class="fas fa-exclamation-triangle"></i>
            <b>{{ "file-colums-explanation" | translate }}</b>
          </h5>
          <app-file-import
            [fileType]="'.csv'"
            (fileReady)="sendNotifications($event)"
          ></app-file-import>
          <hr />
          <app-import-table [importColumns]="importColumns"> </app-import-table>
        </div>

        <!-- Tabla -->
        <ng-container *ngIf="showTable">
          <hr />
          <app-table-controller
            id="import-errors"
            [tableId]="'userImport'"
            [onlyTable]="true"
            [columns]="columns"
            [rowNumbers]="rowNumbers"
            [data]="tableData"
          >
          </app-table-controller>
        </ng-container>
      </div>
    </div>
  </div>
</section>
