<div class="panel-body">
  <div
    *ngIf="_devicesList?.length > 0 && !virtualAgrupation"
    class="panel-group"
  >
    <!-- Lista de dispositivos -->
    <div
      *ngFor="let device of _devicesList; index as i"
      class="panel panel-default"
    >
      <div
        class="panel-heading"
        [ngClass]="device.configured ? 'config' : null"
      >
        <!-- Desplegable de alarmas -->
        <div class="alarm-config-dropdown">
          <a
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapse{{ i }}"
          >
            <h4 class="panel-title">
              {{ device.manufacturer }}
              ({{ "alarms" | translate }}: {{ device?.checks?.length }} /
              {{ "disabled-multiple" | translate }}: {{ device?.alarmsDisabled }})
            </h4>
          </a>
          <div
            *ngIf="device.configured"
            class="pointer"
            title="{{ 'show' | translate }}"
            (click)="showModal(device)"
          >
            <i class="fas fa-eye"></i>
          </div>
          <div
            class="pointer"
            title="{{ 'reset' | translate }}"
            (click)="resetDeviceAlarms(device)"
          >
            <i class="fas fa-eraser"></i>
          </div>
        </div>
      </div>

      <!-- Tabla de alarmas -->
      <div
        id="collapse{{ i }}"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <div class="panel-body alarm-config-checkbox-panel">
          <table
            class="table table-hover table-bordered table-striped alarm-config-table"
          >
            <!-- Encabezados -->
            <thead>
              <tr>
                <th class="alarm-config-th">
                  <div class="alarm-config-th-description">
                    {{ "description" | translate }}
                  </div>
                </th>
                <th class="alarm-config-th alarm-config-small-column">
                  <div class="alarm-config-th-input">
                    <span *ngIf="!alerts">{{ "alarms" | translate }} </span>
                    <span *ngIf="alerts">{{ "alerts" | translate }}</span>
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="device.allAlarmsCheck"
                      (change)="
                        device.allAlarmsCheck = !device.allAlarmsCheck;
                        selectAll('alarms', device)
                      "
                    />
                  </div>
                </th>
                <th
                  *ngIf="devicesNotification"
                  class="alarm-config-th alarm-config-small-column"
                >
                  <div class="alarm-config-th-input">
                    {{ "notifications" | translate }}
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="device.allNotificationsCheck"
                      (change)="
                        device.allNotificationsCheck =
                          !device.allNotificationsCheck;
                        selectAll('notifications', device)
                      "
                    />
                  </div>
                </th>
                <th
                  *ngIf="devicesValve"
                  class="alarm-config-th alarm-config-small-column"
                >
                  <div class="alarm-config-th-input">
                    {{ "close" | translate }} {{ "valve" | translate }}
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="device.allValvesCheck"
                      (change)="
                        device.allValvesCheck = !device.allValvesCheck;
                        selectAll('valves', device)
                      "
                    />
                  </div>
                </th>
              </tr>
            </thead>

            <!-- Lista de alarmas -->
            <tbody>
              <tr
                *ngFor="let alarm of device.checks"
                class="alarm-config-table-tr"
              >
                <td class="alarm-config-table-alarm">
                  <i
                    class="fas fa-info-circle pointer"
                    (click)="showAlarmDescription(alarm.id)"
                  ></i>
                  {{ "AlertMeter" + alarm.id | translate }}
                </td>
                <td class="alarm-config-small-column">
                  <div class="alarm-config-table-checkbox">
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="alarm.check"
                      (change)="
                        alarm.check = !alarm.check;
                        alarm.notification = false;
                        alarm.open = false;
                        checkAll('alarm', device)
                      "
                    />
                  </div>
                </td>
                <td
                  *ngIf="devicesNotification"
                  class="alarm-config-small-column"
                >
                  <div class="alarm-config-table-checkbox">
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="alarm.notification"
                      (change)="
                        alarm.notification = !alarm.notification;
                        checkAll('notification', device)
                      "
                      [disabled]="!alarm.check"
                    />
                  </div>
                </td>
                <td *ngIf="devicesValve" class="alarm-config-small-column">
                  <div class="alarm-config-table-checkbox">
                    <input
                      class="alarm-config-input"
                      type="checkbox"
                      [checked]="alarm.open"
                      (change)="
                        alarm.open = !alarm.open; checkAll('valve', device)
                      "
                      [disabled]="!alarm.check"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Alarmas personalizadas -->
    <div
      *ngIf="sessionProfile == PROFILES.ARSON && meterId"
      class="panel panel-default"
    >
      <div class="panel-heading">
        <!-- Desplegable de alarmas -->
        <div class="alarm-config-dropdown disabled">
          <a
            data-toggle="collapse"
            data-parent="#accordion"
            href="#customAlarms"
          >
            <h4 class="panel-title">
              {{ "alarms-custom" | translate }}
              ({{ "alarms" | translate }}: {{ customAlarmsTotal?.alarms }} /
              {{ "disabled-multiple" | translate }}: {{ customAlarmsTotal?.disabled }})
            </h4>
          </a>
          <div
            class="pointer"
            title="{{ 'reset' | translate }}"
            (click)="resetCustomAlarms()"
          >
            <i class="fas fa-eraser"></i>
          </div>
        </div>
      </div>

      <!-- Tabla de alarmas -->
      <div
        id="customAlarms"
        class="panel-collapse collapse"
        role="tabpanel"
        aria-labelledby="headingOne"
      >
        <app-alarms-custom-panel
          [meterId]="meterId"
          (customAlarmsTotal)="customAlarmsTotal = $event"
        ></app-alarms-custom-panel>
      </div>
    </div>

    <!-- Sin alarmas -->
    <div
      *ngIf="_devicesList?.length == 0"
      class="text-center alarm-config-warning"
    >
      <i class="fas fa-info-circle"></i>
      <h3 *ngIf="alarmsType == 'agrupation' && !virtualAgrupation">
        {{ "no-active-meters-agrupation" | translate }}
      </h3>
      <h3 *ngIf="alarmsType == 'agrupation' && virtualAgrupation">
        {{ "alarms-agrupation-virtual" | translate }}
      </h3>
      <h3 *ngIf="alarmsType == 'entity'">
        {{ "no-active-meters-entity" | translate }}
      </h3>
      <h3 *ngIf="alarmsType == 'cups'">
        {{ "no-active-meters-cups" | translate }}
        {{ cupsName ? cupsName : "CUPS" }}
      </h3>
    </div>
  </div>
</div>
