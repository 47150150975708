// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { AlarmControllerService } from "../../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
// Componente
import { AlarmsListComponent } from "../alarms-table/alarms-table.component";
import { AlarmsEntityTemplateDialogComponent } from "./alarms-entity-template-dialog/alarms-entity-template-dialog.component";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  DeviceAlarm,
  AlarmStateData,
  DeviceAlarmChecks,
  AlarmsUpdate,
} from "../../AlarmInterface.type";

@Component({
  selector: "app-alarms-entity-template",
  templateUrl: "./alarms-entity-template.component.html",
  styleUrls: ["./alarms-entity-template.component.scss"],
})
export class AlarmsEntityTemplateComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionLanguage: string;

  // Alarmas
  devicesList: DeviceAlarm[];
  devicesNotification: boolean = true;
  @ViewChild(AlarmsListComponent) alarmsList: AlarmsListComponent;

  // Menú
  panelMenuOptions = [
    {
      action: "save",
      icon: "fas fa-save fa-fw",
      text: this.translate.instant("save"),
      visible: true,
    },
    {
      action: "update-agrupations",
      icon: "fas fa-sync-alt fa-fw",
      text: this.translate.instant("update-agrupations"),
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Construtor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de la lista de alarmas
  getData(): void {
    this.AlarmController.getEntityAlarmsTemplate(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let devicesList: DeviceAlarm[] = response["body"]["allMeterAlarms"];

        devicesList.forEach((device: DeviceAlarm) => {
          let manufacturer: { manufacturerText: string; deviceText: string } =
            this.ManufacturerService.getManufacturer(
              device?.fabricante?.toString(),
              device?.devType?.toString(),
              this.sessionLanguage
            );
          device.manufacturer =
            manufacturer.manufacturerText +
            (manufacturer.deviceText ? " - " + manufacturer.deviceText : "");
          device.configured = false;

          // Creación del campo checks asociado a los códigos de alarma del dispositivo
          device.checks = Object.keys(device.alarmList).map((key) => {
            return {
              id: device.alarmList[key],
              check: true,
              notification: true,
            };
          });

          // Comprobación de las alarmas de entidad
          device.checks.forEach((alarm: DeviceAlarmChecks) => {
            response["body"]["disabledList"].forEach(
              (alarmDisabled: AlarmStateData) => {
                if (
                  alarm.id == alarmDisabled.alarm_code &&
                  device.devType == alarmDisabled.dev_type &&
                  device.fabricante == alarmDisabled.fabricante
                ) {
                  alarm.check = false;
                }
              }
            );
          });

          // Comprobación de las notificaciones de entidad
          device.checks.forEach((alarm: DeviceAlarmChecks) => {
            response["body"]["disabledNotificationList"].forEach(
              (notification: AlarmStateData) => {
                if (
                  alarm.id == notification.alarm_code &&
                  device.devType == notification.dev_type &&
                  device.fabricante == notification.fabricante
                ) {
                  alarm.notification = false;
                }
              }
            );
          });

          device.allAlarmsCheck = !device.checks.find(
            (alarm: DeviceAlarmChecks) => !alarm.check
          );
          device.allNotificationsCheck = !device.checks.find(
            (alarm: DeviceAlarmChecks) => !alarm.notification
          );
        });

        this.devicesList = [
          ...devicesList.sort((a, b) =>
            a.manufacturer.localeCompare(b.manufacturer)
          ),
        ];
      }
    });
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "save":
        this.saveTemplate();
        break;
      case "update-agrupations":
        this.MaterialDialogService.openDialog(AlarmsEntityTemplateDialogComponent, {
          entity: this.currentEntity.id,
          agrupations: this.currentEntity.agrupations?.filter(
            (agrupation: Agrupation) => !agrupation.showAllEntity
          ),
          selected: null,
        });
        break;
      default:
        break;
    }
  }

  // Guardado de plantilla
  saveTemplate(): void {
    let updatedDevicesList: DeviceAlarm[] =
      this.alarmsList.getUpdatedDevicesList();
    let disabledAlarms: AlarmStateData[] = [];
    let disabledNotifications: AlarmStateData[] = [];

    updatedDevicesList.map((device: DeviceAlarm) =>
      device.checks.map((alarm: DeviceAlarmChecks) => {
        if (!alarm.check) {
          disabledAlarms.push({
            alarm_code: alarm.id,
            dev_type: device.devType,
            fabricante: device.fabricante,
          });
        }
        if (!alarm.notification) {
          disabledNotifications.push({
            alarm_code: alarm.id,
            dev_type: device.devType,
            fabricante: device.fabricante,
          });
        }
      })
    );

    let data: AlarmsUpdate = {
      entity: this.currentEntity.id,
      disabledList: disabledAlarms,
      disabledNotificationList: disabledNotifications,
    };

    this.AlarmController.updateEntityAlarmsTemplate(data).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast("success", this.translate.instant("saved"));
          this.ReloadComponentService.reload();
        }
      }
    );
  }
}
