// @angular
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Clipboard } from "@angular/cdk/clipboard";
// Moment
import * as moment from "moment";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../services/shared/RouteCheckService.service";
import { QueriesRequestsService } from "./QueriesRequestsService.service";
import { DomControllerService } from "../../../services/shared/DomControllerService.service";
import { DeviceRouteSelectorService } from "../../../services/shared/DeviceRouteSelectorService.service";
// Componentes
import { TableControllerComponent } from "../../../modules/table-module/table-controller/table-controller.component";
import { MapSelectorComponent } from "../../../modules/map-module/map-selector/map-selector.component";
// Interfaces
import {
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
} from "../../../modules/table-module/TableInterface.type";
import { QueryData } from "./QueryInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
// Variables
import { QUERIES } from "./QUERIES";
import { MatMenuTrigger } from "@angular/material/menu";
import { PROFILES } from "../../../../assets/profiles/profiles";

@Component({
  selector: "app-queries",
  templateUrl: "./queries.component.html",
  styleUrls: ["./queries.component.scss"],
})
export class QueriesComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentLanguage: string;

  // Queries
  queries: QueryData[];
  selectedQuerie: QueryData;

  // Table
  tableData: any[];
  originalTableData: any[];
  tableSelectedData: any[];
  exportFileName: string =
    this.translate.instant("query-export") +
    " " +
    this.DateParserService.getDate();
  tableMaxReg: number = 100;
  columns: (TableSelectColumn | TableDataColumn)[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "association-new",
      icon: "fas fa-layer-group",
      selectionRequired: true,
      help: "help-table-asoc",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  // Fecha
  @ViewChild("dateMenuTrigger") dateMenuTrigger: MatMenuTrigger;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private clipboard: Clipboard,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DomControllerService: DomControllerService,
    private QueriesRequestsService: QueriesRequestsService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.tableData = null;
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.queries = QUERIES.map((querie: QueryData) => {
      let querieParsed = JSON.parse(JSON.stringify(querie));
      querieParsed.name = this.translate.instant(querie.name);
      return querieParsed;
    });
    this.selectedQuerie = this.queries[0];
  }

  // Obtención de los datos de la tabla
  getData(): void {
    if (this.SessionDataService.getCurrentProfile() == PROFILES.ARSON) {
      this.SessionDataService.sendGameActive(true);
    }
    this.QueriesRequestsService.getQueryRequest(
      this.selectedQuerie,
      this.currentAgrupation.showAllEntity ? null : this.currentAgrupation.id,
      this.SessionDataService.getCurrentEntity()?.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let tableData = [];
        let data = response["body"]?.resultado;
        let structure = response["body"]?.estructura;
        let columnKeys = response["body"]?.cabecera;
        let idIndex = columnKeys.findIndex((key: string) => key == "id");
        let metrologyTypeIndex = columnKeys.findIndex(
          (key: string) => key == "metrology_type"
        );

        data.forEach((data: any) => {
          let dataParsed: object = {};
          columnKeys.forEach((columnKey: string, i) => {
            dataParsed[columnKey] = data[i];
            // Parseo de fechas
            if (structure[i].toUpperCase() != "STRING") {
              dataParsed[columnKey] = parseFloat(data[i]);
            }
            // Enlace a contador
            if (idIndex != null) {
              dataParsed["link"] =
                this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  data[metrologyTypeIndex],
                  data[idIndex]
                );
            }
          });
          tableData.push(dataParsed);
        });
        this.setColumns(columnKeys, structure);
        this.originalTableData = tableData;
        this.tableData = tableData;
      }
      this.SessionDataService.clearGameActive();
    });
  }

  // Seteo de columnas dinámicas
  setColumns(columnKeys: string[], structure: string[]): void {
    let columns: (TableSelectColumn | TableDataColumn)[] = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
    ];
    this.selectedQuerie.columns.forEach(
      (column: { key: string; label: string }) => {
        let columnIndex = columnKeys.findIndex(
          (columnKey: string) =>
            columnKey.toUpperCase() == column.key.toUpperCase()
        );
        let parsedData =
          structure[columnIndex].toUpperCase() != "STRING"
            ? column.key + "Parsed"
            : column.key;
        columns.push({
          title: column.label,
          data: parsedData,
          search: parsedData,
          sort: column.key,
          link: column.key == "meter" ? "link" : null,
          date: structure[columnIndex].toUpperCase() == "DATE",
          numerical:
            structure[columnIndex].toUpperCase() != "DATE" &&
            structure[columnIndex].toUpperCase() != "STRING",
          visible: true,
        });
      }
    );
    this.columns = columns;
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "association-new":
        this.newAssociation();
      default:
        break;
    }
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#queries-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((element: any) => {
          return parseInt(element.id);
        }),
        to: null,
      },
    });
  }

  // Nueva asociación de contadores
  newAssociation(): void {
    this.router.navigate(["/analisis-datos/asociaciones/nuevo"], {
      state: {
        data: this.tableSelectedData.map((element: any) => {
          return parseInt(element.id);
        }),
      },
    });
  }

  // Menú de fecha
  openDateMenu(e: MouseEvent): void {
    e.preventDefault();
    this.dateMenuTrigger.openMenu();
  }

  // Copiar fecha
  copyDate(): void {
    this.clipboard.copy(
      JSON.stringify({
        startDate: this.selectedQuerie.params.date[0]?.value,
        endDate: this.selectedQuerie.params.date[1]?.value,
      })
    );
  }

  // Pegar fecha
  pasteDate(): void {
    navigator.clipboard.readText().then((date) => {
      let dateParsed = JSON.parse(date);
      if (dateParsed.startDate && dateParsed.endDate) {
        this.selectedQuerie.params.date[0].value = moment(dateParsed.startDate)
          .format("YYYY-MM-DDTHH:mm")
          .toString();
        this.selectedQuerie.params.date[1].value = moment(dateParsed.endDate)
          .format("YYYY-MM-DDTHH:mm")
          .toString();
      }
    });
  }
}
