export interface Cups {
  claveMaestraList: ClaveMaestra[];
  entityDefinitions: EntityDefinition[];
  imageFile: string[];
  highLightRow?: boolean;
  isAssigned?: boolean;
  highLightRowUnassigned?: boolean;
}

export interface ClaveMaestra {
  // BackEnd
  id: number;
  nroSerie: string;
  metrologyType?: number;
  lastCommunication?: number;
  lastReadedValue?: number;
  agrupation: number;
  clave: string;
  col01: string;
  col02: string;
  col03: string;
  col04: string;
  col05: string;
  col06: string;
  col07: string;
  col08: string;
  col09: string;
  col10: string;
  col11: string;
  col12: string;
  col13: string;
  col14: string;
  col15: string;
  col16: string;
  col17: string;
  col18: string;
  col19: string;
  col20: string;
  name?: string;
  surname?: string;
  username?: string;
  meters?: number;
  sensors?: number;
  conversionFactor?: string;
  pcsCups?: string;
  contador?: number;
  installation?: number;
  isAssigned?: boolean;
  abonado?: number;
  updateState?: boolean;
  responseCode?: number;
  meterId?: number;
  subscriber?: string;
  deletionDisabled?: boolean;
  pcs?: string;
  fcv?: string;
  latitude?: number;
  longitude?: number;
  hasOT?: boolean;
  entity?: number;

  // FrontEnd
  withoutDevices?: boolean;
  lastCommunicationParsed?: string;
  lastReadedValueParsed?: string;
  claveParsed?: string;
  deviceLink?: string;
  cupsLink?: string;
  pcsValue?: string;
  entityParsed?: string;
}

export class EntityDefinition {
  // BackEnd
  public id?: number;
  public entity?: number;
  public colPosition?: any;
  public name?: string;
  public label: string;
  public description?: string;
  public show?: boolean;
  public required: boolean;
  public fieldType?: number;

  // FrontEnd
  public text?: string;
  public highlightRow?: boolean;

  constructor() {
    this.id = null;
    this.entity = null;
    this.colPosition = null;
    this.name = null;
    this.label = null;
    this.description = null;
    this.show = null;
    this.required = null;
  }
}
