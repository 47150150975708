import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicio propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { DragElementService } from "../../../services/shared/DragElementService.service";
import { DeviceConsumptionLeaksDetectionService } from "../../../screens/dashboard/devices/devices-common-components/device-consumption-graph/device-consumption-leaks-detection.service";
import { MeterService } from "../../../screens/dashboard/devices/meter/MeterService.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { MeterControllerService } from "../../../services/server/MeterController.service";
// Variables
import { GRAPH_CONFIG } from "../../graph-module/GRAPH_CONFIG";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
import moment from "moment";

@Component({
  selector: "app-table-dialog",
  templateUrl: "./table-dialog.component.html",
  styleUrls: ["./table-dialog.component.scss"],
})
export class TableDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  highchartsOptions: Options;
  chartOptions: any;
  parsedHtml: string;
  defaultDateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } = this.DateParserService.getLastDays(7);
  graphInitiated: boolean = false;
  graphData: any;
  isBatteryGraph:boolean = false;

  // Fugas
  circleHighchartOptions: Options;
  circleChartOptions: any;
  @ViewChild("graphCircle") graphCircle: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceConsumptionLeaksDetectionService: DeviceConsumptionLeaksDetectionService,
    public DragElementService: DragElementService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Si 'data.device.graphData' existe, procedemos con la lógica de gráficos
    if (this.data?.device?.graphData) {
      this.graphData = { ...this.data.device.graphData };

      // Aseguramos que 'graphData.series' existe antes de manipularla
      if (this.graphData.series) {
        this.graphData.series.forEach(
          (serie) => (serie.type = serie.type || this.graphData.type)
        );
      }

      // Verificamos si la gráfica es de batería
      this.isBatteryGraph = this.graphData.reloadFunction === "battery-reload";

      // Configuramos las opciones de Highcharts y de la gráfica
      this.setHighchartsOptions();
      this.setChartsOptions();
    } else if (this.data?.html) {
      // Si 'data' no tiene 'graphData', asignamos el contenido HTML
      this.parsedHtml = this.data.html;
      this.graphData = null;  // No usamos gráficos, por lo tanto 'graphData' es null
    }

    // Si 'graphData.html' existe, formateamos el HTML
    if (this.graphData?.html || this.parsedHtml) {
      this.parsedHtml = (this.graphData?.html || this.parsedHtml).replace(/\n/g, "<br />");
    }
  }



  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions: any = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.options)
    );
    highchartsOptions.plotOptions.series.marker.enabled = false;
    highchartsOptions["tooltip"] = {
      shared: true,
      enabled: true,
      outside: false,
    };

    if(this.isBatteryGraph==true){
      highchartsOptions["xAxis"] = {
        type: "datetime",
        ordinal: false,
        crosshair: {
          width: 1,
          color: "red",
        },
        dateTimeLabelFormats: {
          day: '%e, %b %Y',  // Formato: día, mes (nombre mid) y año
        }
      };
    }


    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["rangeSelector"];
    chartOptions["chart"].height = null;
    chartOptions["series"] = this.graphData.series;

    //En caso de que el graphdata ya tenga un min y max asignado, la grafica pequeña se ajusta
    chartOptions["yAxis"] = [
      {
        title: { text: "" },
        min: this.graphData?.min || null,
        max: this.graphData?.max || null,
      },
    ];

    chartOptions["legend"] = {
      enabled: false,
    };
    chartOptions["navigator"] = {
      enabled: false,
    };

    this.chartOptions = chartOptions;

    // Fugas
    if (this.graphData.showLeaks && this.data.device.metrologyType!=METROLOGY_TYPE.GAS) {
      let readings = this.graphData.series[0].data;
      let leaks: {
        serie: any;
        totalLeak: number;
        totalConsumption: number;
      } = this.DeviceConsumptionLeaksDetectionService.getLeakSeries(
        readings,
        this.graphData.units
      );

      // Fugas
      if (leaks && !this.graphData.series.some(existingSerie => existingSerie.name === leaks.serie.name)) {
        this.graphData.series.push(leaks.serie);
        this.circleHighchartOptions =
          this.DeviceConsumptionLeaksDetectionService.setCircleHighchartsOptions();
        this.circleChartOptions =
          this.DeviceConsumptionLeaksDetectionService.setCircleChartsOptions(
            leaks.totalLeak,
            leaks.totalConsumption,
            this.graphData.units
          );
      }
    }
  }


  // Actualización del gráfico
  loadGraphData(from: string, to: string): void {
    if (!this.graphInitiated) {
      this.chartOptions = { ...this.chartOptions };
      this.graphInitiated = true;
    } else {

        this.MeterController.getGraph(
          this.data.device.contador
            ? this.data.device.contador
            : this.data.device.id,
          this.data.device.metrologyType == METROLOGY_TYPE.GAS ? "3" : "2",
          from,
          to
        ).subscribe((response) => {
          if (response["code"] == 0) {
            if (this.circleChartOptions) {
              this.graphData.series.pop();
              this.circleChartOptions = null;
            }
            this.graphData.series[0].data = response["body"]?.readings;
            this.setChartsOptions();
          }
        });

      }
  }
}
