// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { ManufacturerService } from "../../../../../../services/shared/ManufacturerService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { DeviceTypeService } from "../../../../../../services/shared/DeviceTypeService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../../../services/shared/DomControllerService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { DeviceInfoModalComponent } from "../../../devices-common-components/device-info-modal/device-info-modal.component";
import { DeviceTypeFilterComponent } from "../../../devices-common-components/device-type-filter/device-type-filter.component";
import { DeviceAgrupationDialogComponent } from "../../../devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { DeviceEntityDialogComponent } from "../../../devices-common-components/device-entity-dialog/device-entity-dialog.component";
import { MapSelectorComponent } from "../../../../../../modules/map-module/map-selector/map-selector.component";
import { DeviceActivationDialogComponent } from "../../../devices-common-components/device-activation-dialog/device-activation-dialog.component";
import { MeterUnassignedDialogComponent } from "./meter-unassigned-dialog/meter-unassigned-dialog.component";
import { EkConfigurationControllerService } from "../../../../../../services/server/EkConfigurationController.service";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../../../modules/table-module/TableInterface.type";
import {
  DeviceRemoveData,
  UnassignedDevice,
} from "../../../DeviceInterface.type";
import { DeviceActivation } from "../../../devices-common-components/device-activation-dialog/device-activation-dialog-interface";
// Variables
import { METROLOGY_TYPE } from "../../../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../../services/shared/DeviceTypeService.service";

@Component({
  selector: "app-meterslistnoassign",
  templateUrl: "./meter-unassigned.component.html",
  styleUrls: ["./meter-unassigned.component.scss"],
})
export class MetersListNoAssignComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;

  // Tabla
  tableSelectedData: UnassignedDevice[];
  tableMaxReg: number = 100;
  meterList: UnassignedDevice[];
  originalMeterList: UnassignedDevice[];
  exportFileName: string =
    this.translate.instant("meters-unassigned-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][];
  quickFiltersExclusion: boolean[] = [true, true];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "change-agrupation",
      icon: "fas fa-map",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-agrupation",
    },
    {
      title: "change-entity",
      icon: "fas fa-sitemap",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-entity",
    },
    {
      title: "divorce-selection",
      icon: "fas fa-expand-alt",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      class: "btn-red",
      help: "help-table-divorce",
    },
    {
      title: "deactivate-multiple",
      icon: "fas fa-eraser",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      class: "btn-red",
      help: "help-table-deactivate",
    },
    {
      title: "erase-selection",
      icon: "fas fa-trash",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      class: "btn-red",
      help: "help-table-delete",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  metersToRelocate: { id: number; nroSerie: string }[];
  stayOnRoute: boolean = false;

  // Filtros
  selectedManufacturer: number;
  selectedModel: { manufacturer: number; device: number };
  deviceTypeFilters: { data: string; value: string }[];
  initialSearch: string = history.state.data;
  @ViewChild(DeviceTypeFilterComponent)
  deviceTypeFilterComp: DeviceTypeFilterComponent;

  // Modal de activación
  activateModalData: DeviceActivation;
  dialog: Subscription;

  // Modal de información
  meterToShow: UnassignedDevice;
  meterToShowType: string;
  @ViewChild(DeviceInfoModalComponent)
  deviceInfoModal: DeviceInfoModalComponent;

  // Botón actualizar
  updateTitle: string = this.translate.instant("update");
  updating: boolean = false;

  // Mapa
  mapSelectorActive: boolean = false;
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private EkConfigurationController: EkConfigurationControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation")
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.updateData();
        }
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setColumns();
    this.setQuickFilters();
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.updating = true;
    this.mapSelectorActive = false;
    this.MeterController.getNoAssignedMeterList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"].length > 0) {
        let meterList: UnassignedDevice[] = response["body"];

        meterList.forEach((meter: UnassignedDevice) => {
          // Máscara de tipo
          meter.meterType = this.DeviceTypeService.getDeviceTypeByMask(
            meter.tipo,
            meter.metrologyType,
            meter.idFabricante
          );
          // Fabricante
          let device: { manufacturerText: string; deviceText: string } =
            this.ManufacturerService.getManufacturer(
              meter?.idFabricante?.toString(),
              meter?.idDevType?.toString(),
              this.sessionLanguage
            );
          meter.fabricante = device.manufacturerText;
          meter.devType = device.deviceText;
          // Estado
          meter.isActiveText = meter.isActive
            ? this.translate.instant("active")
            : this.translate.instant("inactive");
          meter.canBeDeactivated =
            !meter.isActive &&
            meter.rfModule != null &&
            meter.rfModule != meter.nroSerie.replace("_RF_MDL", "") &&
            meter.meterType != DEVICE_BY_COMM.EK280 &&
            meter.meterType != DEVICE_BY_COMM.OWASYS;
          if (meter.isActive == null) {
            meter.isActive = false;
          }
          if (meter.comunica == null) {
            meter.comunica = false;
          }
          // Metrología
          meter.metrologyTypeParsed =
            meter.metrologyType != null
              ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
              : "-";
          meter.deviceLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
            meter.metrologyType,
            meter.id
          );
          // Deshabilitar edición
          meter.editDisabled =
            !meter.meterType ||
            meter.meterType == DEVICE_BY_COMM.UNE ||
            meter.meterType == DEVICE_BY_COMM.EK280 ||
            meter.meterType == DEVICE_BY_COMM.OWASYS;
          // Concentrador
          meter.isConcentrator =
            meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR ||
            meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR;
          // Nonces
          meter.nonces = meter.devNonce != null && meter.appNonce != null;
          // Agrupación
          meter.agrupationParsed = this.currentEntity.agrupations.find(
            (agrupation) => agrupation.id == meter.agrupation
          )?.name;
        });

        this.meterList = meterList;
        this.originalMeterList = meterList;
        this.resetDeviceTypeFilter();
      } else {
        this.meterList = [];
        this.originalMeterList = [];
      }
      this.updating = false;
    });
  }

  // Seteo de los filtros extra
  setQuickFilters(): void {
    this.quickFilters = [
      [
        {
          name: "meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.WATER },
          active: false,
        },
        {
          name: "gas-meters",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.GAS },
          active: false,
        },
        {
          name: "sensors",
          columnSearch: "metrologyType",
          condition: { type: "number", rule: METROLOGY_TYPE.SENSOR },
          active: false,
        },
        {
          name: "concentrators",
          columnSearch: "isConcentrator",
          condition: { type: "boolean", rule: true },
          active: false,
        },
      ],
      [
        {
          name: "actives",
          columnSearch: "isActive",
          condition: { type: "boolean", rule: true },
          active: history.state.data ? true : false,
        },
        {
          name: "no-actives",
          columnSearch: "isActive",
          condition: { type: "boolean", rule: false },
          active: false,
        },
      ],
      [
        {
          name: "agrupation-null",
          columnSearch: "agrupation",
          condition: { type: "number", rule: null },
          active: false,
        },
      ],
    ];
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: {
              attribute: null,
              rule: this.sessionProfile == PROFILES.ARSON,
            },
            disabled: "editDisabled",
          },
          {
            name: "meter-orders",
            tooltip: "meter-orders",
            icon: "fas fa-list-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "activate",
            tooltip: "activate",
            icon: "fas fa-plus",
            visible: { attribute: "isActive", rule: false },
            disabled: false,
          },
          {
            name: "deactivate",
            tooltip: "deactivate",
            icon: "fas fa-eraser",
            visible: { attribute: "isActive", rule: true },
            disabled: false,
            warning: true,
          },
          {
            name: "deactivateInactive",
            tooltip: "divorce",
            icon: "fas fa-expand-alt",
            visible: { attribute: "canBeDeactivated", rule: true },
            disabled: false,
            warning: true,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: { attribute: null, rule: true },
            disabled: false,
            warning: true,
          },
        ],
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ? true
            : null,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "actives",
        data: "isActiveText",
        search: "isActive",
        sort: "isActive",
        alter: {
          condition: "isActive",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
        boolean: true,
      },
      {
        title: "communicates",
        data: "comunica",
        search: "comunica",
        sort: "comunica",
        alter: {
          condition: "comunica",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: true,
        boolean: true,
      },
      {
        title: "nonces",
        data: "nonces",
        search: "nonces",
        sort: "nonces",
        alter: {
          condition: "nonces",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
        boolean: true,
      },
      {
        title: "type",
        data: "metrologyTypeParsed",
        search: "metrologyTypeParsed",
        sort: "metrologyTypeParsed",
        visible: true,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "deviceLink",
      },
      {
        title: "groups",
        data: "agrupationParsed",
        search: "agrupationParsed",
        sort: "agrupationParsed",
        visible: this.currentAgrupation.showAllEntity,
      },
      {
        title: "rf-module",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "alias",
        data: "alias",
        search: "alias",
        sort: "alias",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "profiles8",
        data: "activationUser",
        search: "activationUser",
        sort: "activationUser",
        visible: true,
      },
      {
        title: "date",
        data: "fechaAltaParsed",
        search: "fechaAltaParsed",
        sort: "fechaAlta",
        visible: true,
        date: true,
      },
      {
        title: "last-communication",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        visible: true,
        date: true,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: true,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: true,
      },
      {
        title: "manufacturer",
        data: "fabricante",
        search: "fabricante",
        sort: "fabricante",
        visible: true,
      },
      {
        title: "model",
        data: "devType",
        search: "devType",
        sort: "devType",
        visible: true,
      },
      {
        title: "APP EUI",
        data: "appEui",
        search: "appEui",
        sort: "appEui",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "DEV EUI",
        data: "devEui",
        search: "devEui",
        sort: "devEui",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "Network Key",
        data: "networkKey",
        search: "networkKey",
        sort: "networkKey",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "Application Key",
        data: "applicationKey",
        search: "applicationKey",
        sort: "applicationKey",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "APP Nonce",
        data: "appNonce",
        search: "appNonce",
        sort: "appNonce",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "DEV Nonce",
        data: "devNonce",
        search: "devNonce",
        sort: "devNonce",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
    ];
  }

  // Filtrado de la tabla por fabricante o modelo
  resetDeviceTypeFilter(): void {
    this.deviceTypeFilterComp?.resetFilter();
    this.deviceTypeFilters = null;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: UnassignedDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeterData(meter);
        break;
      case "edit":
        this.MaterialDialogService.openDialog(
          MeterUnassignedDialogComponent,
          JSON.parse(JSON.stringify(meter))
        );
        break;
      case "activate":
        this.getActivateModalData(meter);
        break;
      case "delete":
        this.deleteMeterAction(meter);
        break;
      case "deactivate":
        this.resetMeter(meter);
        break;
      case "deactivateInactive":
        this.resetMeter(meter);
        break;
      case "meter-orders":
        this.router.navigate(["/dispositivos/detalle/ordenes/" + meter.id], {
          state: { data: meter },
        });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "erase-selection":
        this.deleteMeterAction();
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "change-entity":
        this.showChangeEntityModal();
        break;
      case "divorce-selection":
        this.divorceMultiple();
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      default:
        break;
    }
  }

  // Asignación del mejor Gateway
  divorceMultiple(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("device-question-divorce-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: UnassignedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("divorce", data);
      }
    });
  }

  // Actualización de los datos
  updateData(): void {
    this.meterList = null;
    this.getData();
  }

  // Mostrar datos de contador
  showMeterData(meter: UnassignedDevice): void {
    this.meterToShow = meter;
    this.meterToShowType = this.DeviceTypeService.getDeviceTypeByMask(
      this.meterToShow.tipo,
      this.meterToShow.metrologyType,
      this.meterToShow.idFabricante
    );
    this.MaterialDialogService.openDialog(DeviceInfoModalComponent, {
      device: this.meterToShow,
      deviceTypeByMask: this.meterToShowType,
    });
  }

  // Borrar contador
  deleteMeterAction(meter?: UnassignedDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("delete-meter-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        // Individual
        if (
          meter &&
          this.sessionProfile == PROFILES.ARSON &&
          (meter?.meterType == DEVICE_BY_COMM.EK280 ||
            meter?.meterType == DEVICE_BY_COMM.OWASYS)
        ) {
          this.deleteEkMeterAsking(meter);
        } else if (
          meter &&
          (meter?.meterType == DEVICE_BY_COMM.EK280 ||
            meter?.meterType == DEVICE_BY_COMM.OWASYS)
        ) {
          this.deleteEkMeter(meter);
        } else if (meter) {
          this.deleteMeter(meter);
          // Múltiples contadores
        } else {
          if (
            this.sessionProfile == PROFILES.ARSON &&
            this.tableSelectedData.some(
              (meter: UnassignedDevice) =>
                meter?.meterType == DEVICE_BY_COMM.EK280 ||
                meter?.meterType == DEVICE_BY_COMM.OWASYS
            )
          ) {
            this.deleteEkMeterAsking();
          } else if (
            this.tableSelectedData.some(
              (meter: UnassignedDevice) =>
                meter?.meterType == DEVICE_BY_COMM.EK280 ||
                meter?.meterType == DEVICE_BY_COMM.OWASYS
            )
          ) {
            this.deleteMultipleEkMeters();
          } else {
            this.deleteMultipleMeters();
          }
        }
      }
    });
  }

  // Borrado contador individual
  deleteMeter(meter: UnassignedDevice): void {
    this.MeterController.removeMeter(
      meter.id,
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("meter-deleted")
        );
        this.updateData();
      }
    });
  }

  // Borrado de contador múltiple
  deleteMultipleMeters(): void {
    let data: DeviceRemoveData = {
      listaContadores: this.tableSelectedData.map(
        (meter: UnassignedDevice) => meter.id
      ),
      agrupacion: this.currentAgrupation.id,
    };
    this.MeterController.removeMultipleMeter(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("meters-deleted")
        );
        this.updateData();
      }
    });
  }

  // Borrado de EK280/OWASYS
  deleteEkMeter(meter: UnassignedDevice): void {
    this.EkConfigurationController.deleteInEkServer(meter.id).subscribe(
      (response) => {
        this.deleteMeter(meter);
      }
    );
  }

  // Borrado de múltiples EK280/OWASYS
  deleteMultipleEkMeters(): void {
    let data: number[] = this.tableSelectedData
      .filter(
        (meter: UnassignedDevice) =>
          meter?.meterType == DEVICE_BY_COMM.EK280 ||
          meter?.meterType == DEVICE_BY_COMM.OWASYS
      )
      .map((meter: UnassignedDevice) => meter.id);
    this.EkConfigurationController.deleteMultipleInEkServer(data).subscribe(
      (response) => {
        this.deleteMultipleMeters();
      }
    );
  }

  // Borrado de EK280/OWASYS para ARSON
  deleteEkMeterAsking(meter?: UnassignedDevice): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("delete-meter-ek-question"),
      null,
      this.translate.instant("ek-delete-only-lora"),
      this.translate.instant("ek-delete-all")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (meter) {
          this.deleteEkMeter(meter);
        } else {
          this.deleteMultipleEkMeters();
        }
      } else {
        if (meter) {
          this.deleteMeter(meter);
        } else {
          this.deleteMultipleMeters();
        }
      }
    });
  }

  // Desactivar contador
  resetMeter(meter: UnassignedDevice): void {
    let url: Observable<object> = meter.isActive
      ? this.MeterController.resetMeter(meter.id)
      : this.MeterController.resetInactiveMeter(meter.id);
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        url.subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              meter.isActive
                ? this.translate.instant("device-meter-desactivated")
                : this.translate.instant("device-meter-divorced")
            );
            this.updateData();
          }
        });
      }
    });
  }

  // Obtención de los datos del modal de activación
  getActivateModalData(meter: UnassignedDevice): void {
    this.MeterController.showMeter(meter.id).subscribe((response) => {
      if (response["code"] == 0) {
        this.activateModalData = {
          nroSerie: meter.nroSerie,
          latitude: meter.latitude,
          longitude: meter.longitude,
          entity: this.currentEntity.entity,
          agrupation: this.currentAgrupation.name,
          rfModule: response["body"]["rfModule"],
          comments: null,
          agrupations: this.currentEntity.agrupations,
        };
        this.MaterialDialogService.openDialog(
          DeviceActivationDialogComponent,
          this.activateModalData
        );
      }
    });
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Reseteo de datos
  resetData(): void {
    this.meterList = null;
    this.originalMeterList = null;
    this.getData();
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    let deactivatedMeters: UnassignedDevice[] = this.tableSelectedData.filter(
      (device: UnassignedDevice) => !device.isActive
    );
    if (deactivatedMeters.length > 0) {
      this.ToastService.fireAlertInfoText(
        "info",
        this.translate.instant("change-agrupation-active-warning"),
        this.translate.instant("meters-deactivated") +
          ": " +
          deactivatedMeters
            .map((device: UnassignedDevice) => {
              return device.nroSerie;
            })
            .join(", ")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MaterialDialogService.openDialog(
            DeviceAgrupationDialogComponent,
            {
              devices: this.tableSelectedData.filter(
                (device: UnassignedDevice) => device.isActive
              ),
              agrupationList: this.currentEntity.agrupations
                .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
                .sort((a, b) => a.name.localeCompare(b.name)),
              selectedAgrupation: null,
            }
          );
        } else {
          this.ToastService.fireAlertInfoText(
            "warning",
            this.translate.instant("no-active-meters-selection")
          );
        }
      });
    } else {
      this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
        devices: this.tableSelectedData,
        agrupationList: this.currentEntity.agrupations
          .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
          .sort((a, b) => a.name.localeCompare(b.name)),
        selectedAgrupation: null,
      });
    }
  }

  // Cambio de entidad
  showChangeEntityModal(): void {
    let invalidMeters: UnassignedDevice[] = this.tableSelectedData.filter(
      (device: UnassignedDevice) => device.isActive || device.agrupation
    );
    this.metersToRelocate = this.tableSelectedData
      .filter(
        (device: UnassignedDevice) => !device.isActive && !device.agrupation
      )
      .map((device: UnassignedDevice) => {
        return { id: device.id, nroSerie: device.nroSerie };
      });
    if (invalidMeters.length > 0) {
      this.ToastService.fireAlertInfoText(
        "info",
        this.translate.instant("change-entity-active-warning"),
        this.translate.instant("meters-not-valid") +
          ": " +
          invalidMeters
            .map((device: UnassignedDevice) => {
              return device.nroSerie;
            })
            .join(", ")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation && this.metersToRelocate.length > 0) {
          this.MaterialDialogService.openDialog(DeviceEntityDialogComponent, {
            devices: this.tableSelectedData,
            entityList: this.SessionDataService.getCurrentEntityList(),
            selectedEntity: null,
          });
        } else {
          this.ToastService.fireAlertInfoText(
            "warning",
            this.translate.instant("no-inactive-meters-selection")
          );
        }
      });
    } else {
      this.MaterialDialogService.openDialog(DeviceEntityDialogComponent, {
        devices: this.tableSelectedData,
        entityList: this.SessionDataService.getCurrentEntityList(),
        selectedEntity: null,
      });
    }
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: UnassignedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }
}
