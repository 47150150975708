<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <!-- Título -->
        <h5>
          <b> {{ "repair" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [quickFilters]="quickFilters"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [tableId]="'gatewaysRepair'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
