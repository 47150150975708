<div
  *ngIf="meterId || (!meterId && associationList?.length > 0)"
  class="panel-body"
>
  <div class="alarm-config-checkbox-panel">
    <table
      class="table table-hover table-bordered table-striped alarm-config-table"
    >
      <!-- Encabezados -->
      <thead>
        <tr>
          <th class="alarm-config-th">
            <div class="alarm-config-th-description">
              {{ "description" | translate }}
            </div>
          </th>
          <th class="alarm-config-th alarm-config-small-column">
            <div class="alarm-config-th-input">
              {{ "value" | translate }}
            </div>
          </th>
          <th class="alarm-config-th alarm-config-small-column">
            <div class="alarm-config-th-input">
              {{ "active" | translate }}
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="allAlarmsCheck"
                (change)="selectAll('alarms')"
              />
            </div>
          </th>
          <th class="alarm-config-th alarm-config-small-column">
            <div class="alarm-config-th-input">
              {{ "notification-internal" | translate }}
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="allNotificationsCheck"
                (change)="selectAll('notification')"
              />
            </div>
          </th>
          <th class="alarm-config-th alarm-config-small-column">
            <div class="alarm-config-th-input">
              {{ "subscriber-only" | translate }}
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="allSubscriberCheck"
                (change)="selectAll('subscriber')"
              />
            </div>
          </th>
          <th class="alarm-config-th alarm-config-small-column">
            <div class="alarm-config-th-input">
              {{ "validation-required" | translate }}
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="allValidationCheck"
                (change)="selectAll('validation')"
              />
            </div>
          </th>
        </tr>
      </thead>

      <!-- Lista de alarmas -->
      <tbody>
        <tr
          *ngFor="let alarm of alarmConfig; index as i"
          class="alarm-config-table-tr"
        >
          <td class="alarm-config-table-alarm">
            {{ "customAlarm" + alarm.type | translate }}
          </td>
          <td class="alarm-config-small-column">
            <div class="alarm-config-table-input">
              <input
                *ngIf="alarm.type < 5"
                class="alarm-config-input"
                type="number"
                [(ngModel)]="alarm.value"
                [disabled]="!alarm.active"
              /><span style="margin-left: 10px">{{
                i == 0 ? ("days" | translate) : i < 5 ? "m³" : ""
              }}</span>
            </div>
          </td>
          <td
            class="alarm-config-small-column"
            (click)="alarm.active = !alarm.active"
          >
            <div class="alarm-config-table-checkbox">
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="alarm.active"
                (change)="checkAll('alarm')"
              />
            </div>
          </td>
          <td
            class="alarm-config-small-column"
            (click)="
              alarm.active ? (alarm.notification = !alarm.notification) : null
            "
          >
            <div class="alarm-config-table-checkbox">
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="alarm.notification"
                [disabled]="!alarm.active"
              />
            </div>
          </td>
          <td
            class="alarm-config-small-column"
            (click)="
              alarm.active ? (alarm.subscriber = !alarm.subscriber) : null
            "
          >
            <div class="alarm-config-table-checkbox">
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="alarm.subscriber"
                [disabled]="!alarm.active"
              />
            </div>
          </td>
          <td
            class="alarm-config-small-column"
            (click)="
              alarm.active ? (alarm.validation = !alarm.validation) : null
            "
          >
            <div class="alarm-config-table-checkbox">
              <input
                class="alarm-config-input"
                type="checkbox"
                [(ngModel)]="alarm.validation"
                [disabled]="!alarm.active"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Botones -->
  <div *ngIf="!meterId" class="alarms-custom-buttons">
    <div>
      <mat-checkbox [(ngModel)]="meterOverwrite">
        {{ "alarms-custom-overwrite" | translate }}
      </mat-checkbox>
    </div>
  </div>
</div>

<!-- Sin alarmas -->
<div *ngIf="!meterId && associationList?.length == 0" class="panel-body">
  <div class="text-center alarm-config-warning">
    <i class="fas fa-info-circle"></i>
    <h3>
      {{ "agrupation-without-associations" | translate }}
    </h3>
    <button mat-button class="mat-button-icon-text" (click)="newAssociation()">
      {{ "meters-groups-add" | translate }}
    </button>
  </div>
</div>
