<section class="content-header">
  <div #homePanel class="container-fluid home-panel">
    <div class="panel panel-default home-container">
      <div #homeDataPanel class="home-data-panel">
        <!-- Sin dispositivos -->
        <div class="home-no-devices" *ngIf="!devices || devices?.length == 0">
          <div *ngIf="devices?.length == 0" class="panel-body">
            <i class="fas fa-info-circle"></i>
            <h3>
              {{ "without-devices" | translate }}
            </h3>
          </div>

          <div *ngIf="devices == null" class="panel-body">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
        </div>

        <!-- Dispositivos -->
        <div
          class="home-devices-panel"
          [ngClass]="{
            'home-devices-panel-reverse': sessionProfile != PROFILES.ARSON
          }"
        >
          <div class="home-data-row home-graphs-data" *ngIf="devicesTotal > 0">
            <div class="home-data-container">
              <!-- Gráfica de totales -->
              <div class="home-total-graph-container">
                <div class="home-cards-title">
                  {{ "global" | translate }}
                </div>
                <div class="home-total-graph home-data-odd-row">
                  <div class="home-graph-title">
                    {{ "total" | translate }}
                    <span>
                      {{
                        formatNumber(
                          devicesTotal,
                          SessionDataService.getCurrentNumberFormat()
                        )
                      }}
                    </span>
                  </div>
                  <div class="home-graph-chart-container">
                    <div class="home-graph-chart">
                      <app-graph-controller
                        *ngIf="deviceTotalsHighchartsOptions"
                        [highchartsOptions]="deviceTotalsHighchartsOptions"
                        [noOptionsParse]="true"
                        [onlyGraph]="true"
                        [disableShowPoints]="true"
                        [data]="deviceTotalsChartOptions"
                        (chartObject)="globalChartObject = $event"
                      >
                      </app-graph-controller>
                    </div>
                    <div class="home-total-graph-legend">
                      <!-- Comunican -->
                      <div
                        *ngIf="totalInNetwork > 0"
                        class="home-graph-data-label pointer"
                        [ngStyle]="{
                          opacity: totalGraphHiddenSeries?.includes(
                            'in-network'
                          )
                            ? '0.5'
                            : '1'
                        }"
                        [routerLink]="['/dispositivos/listado/asignados']"
                        [state]="{ data: ['ACTIVE'] }"
                        (mouseover)="
                          highlightLinkedSeries('in-network');
                          highlightLinkedGlobalSeries('in-network')
                        "
                        (mouseout)="
                          totalGraphHiddenSeries = null;
                          highlightLinkedSeries();
                          highlightLinkedGlobalSeries()
                        "
                      >
                        {{ "in-network" | translate }}
                        <span class="home-graph-total-percent"
                          >{{
                            formatNumber(
                              ((totalInNetwork / devicesTotal) * 100).toFixed(
                                2
                              ),
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}%</span
                        ><span class="home-graph-total greenIcon"
                          >{{
                            formatNumber(
                              totalInNetwork,
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}
                        </span>
                      </div>
                      <!-- No comunican -->
                      <div
                        *ngIf="totalNoCommunication > 0"
                        class="home-graph-data-label pointer"
                        [ngStyle]="{
                          opacity: totalGraphHiddenSeries?.includes(
                            'no-communication'
                          )
                            ? '0.5'
                            : '1'
                        }"
                        [routerLink]="[
                          '/dispositivos/listado/sin-comunicacion'
                        ]"
                        (mouseover)="
                          highlightLinkedSeries('no-communication');
                          highlightLinkedGlobalSeries('no-communication')
                        "
                        (mouseout)="
                          totalGraphHiddenSeries = null;
                          highlightLinkedSeries();
                          highlightLinkedGlobalSeries()
                        "
                      >
                        {{ "no-communicates" | translate }}
                        <span class="home-graph-total-percent"
                          >{{
                            formatNumber(
                              (
                                (totalNoCommunication / devicesTotal) *
                                100
                              ).toFixed(2),
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}%</span
                        ><span class="home-graph-total grayIcon"
                          >{{
                            formatNumber(
                              totalNoCommunication,
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}
                        </span>
                      </div>
                      <!-- Sin comunicaciones -->
                      <div
                        *ngIf="
                          devicesCountByType?.externalWithoutCom?.noCom > 0
                        "
                        class="home-graph-data-label pointer"
                        [ngStyle]="{
                          opacity: totalGraphHiddenSeries?.includes(
                            'without-communication'
                          )
                            ? '0.5'
                            : '1'
                        }"
                        [routerLink]="[
                          '/dispositivos/listado/sin-comunicacion'
                        ]"
                        (mouseover)="
                          highlightLinkedSeries('without-communication');
                          highlightLinkedGlobalSeries('without-communication')
                        "
                        (mouseout)="
                          totalGraphHiddenSeries = null;
                          highlightLinkedSeries();
                          highlightLinkedGlobalSeries()
                        "
                      >
                        {{ "without-communications" | translate }}
                        <span class="home-graph-total-percent"
                          >{{
                            formatNumber(
                              (
                                (devicesCountByType.externalWithoutCom.noCom /
                                  devicesTotal) *
                                100
                              ).toFixed(2),
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}%</span
                        ><span class="home-graph-total darkgrayIcon"
                          >{{
                            formatNumber(
                              devicesCountByType.externalWithoutCom.noCom,
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}
                        </span>
                      </div>
                      <!-- Pendientes -->
                      <div
                        *ngIf="totalPending > 0"
                        class="home-graph-data-label pointer"
                        [ngStyle]="{
                          opacity: totalGraphHiddenSeries?.includes('pending')
                            ? '0.5'
                            : '1'
                        }"
                        [routerLink]="['/dispositivos/listado/no-asignados']"
                        (mouseover)="
                          highlightLinkedSeries('pending');
                          highlightLinkedGlobalSeries('pending')
                        "
                        (mouseout)="
                          totalGraphHiddenSeries = null;
                          highlightLinkedSeries();
                          highlightLinkedGlobalSeries()
                        "
                      >
                        {{ "pending" | translate }}
                        <span class="home-graph-total-percent"
                          >{{
                            formatNumber(
                              ((totalPending / devicesTotal) * 100).toFixed(2),
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}%</span
                        ><span class="home-graph-total purpleIcon"
                          >{{
                            formatNumber(
                              totalPending,
                              SessionDataService.getCurrentNumberFormat()
                            )
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- KPIs -->
              <div *ngIf="devicesTotal > 0" class="home-kpis-panel">
                <div
                  class="home-cards-title pointer"
                  [ngClass]="{
                    pointer:
                      sessionProfile == PROFILES.ARSON ||
                      sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      sessionProfile == PROFILES.ADMIN_ENTIDAD
                  }"
                  (click)="
                    sessionProfile == PROFILES.ARSON ||
                    sessionProfile == PROFILES.ADMIN_CLIENTE ||
                    sessionProfile == PROFILES.ADMIN_ENTIDAD
                      ? goToKpis()
                      : null
                  "
                >
                  KPIs
                  <i
                    class="fas fa-link"
                    *ngIf="
                      sessionProfile == PROFILES.ARSON ||
                      sessionProfile == PROFILES.ADMIN_CLIENTE ||
                      sessionProfile == PROFILES.ADMIN_ENTIDAD
                    "
                  ></i>
                </div>
                <div class="home-kpis-data-container">
                  <!-- Media últimos 5 días -->
                  <div class="home-kpis-readings">
                    <span class="home-kpis-value"
                      ><i class="fa fa-tachometer-alt"></i
                      >{{ KpisCardsData?.lastFiveDaysAverage }}%
                    </span>
                    <span
                      ><span class="home-kpis-readings-separator">(</span>
                      {{ KpisCardsData?.metersReadedLastFiveDays }}
                      <span class="home-kpis-readings-separator">/</span>
                      {{ KpisCardsData?.totalMetersLastFiveDays }}
                      <span class="home-kpis-readings-separator">)</span>
                    </span>
                    <span class="home-alarms-title"
                      >{{ "meters" | translate }}
                    </span>
                    <span
                      ><i class="fa fa-calendar-week"></i
                      >{{ "kpis-last-five-days" | translate }}</span
                    >
                  </div>
                  <!-- Media último día -->
                  <div class="home-kpis-readings">
                    <span class="home-kpis-value"
                      ><i class="fa fa-tachometer-alt"></i
                      >{{ KpisCardsData?.lastDayAverage }}%
                    </span>
                    <span>
                      <span class="home-kpis-readings-separator">(</span>
                      {{ KpisCardsData?.metersReadedLastDay }}
                      <span class="home-kpis-readings-separator">/</span>
                      {{ KpisCardsData?.totalMetersLastDay }}
                      <span class="home-kpis-readings-separator">)</span>
                    </span>
                    <span class="home-alarms-title"
                      >{{ "meters" | translate }}
                    </span>
                    <span
                      ><i class="fa fa-calendar-day"></i
                      >{{ "kpis-day" | translate }}</span
                    >
                  </div>
                  <!-- Lecturas -->
                  <div class="home-kpis-readings">
                    <span class="home-kpis-value">
                      <i class="fa fa-database"></i
                      >{{ KpisCardsData?.readingsLastDayAverage }}%
                    </span>
                    <span>
                      <span class="home-kpis-readings-separator">(</span>
                      {{ KpisCardsData?.readingsLastDay }}
                      <span class="home-kpis-readings-separator">/</span>
                      {{ KpisCardsData?.totalReadingsLastDayExpected }}
                      <span class="home-kpis-readings-separator">)</span>
                    </span>
                    <span class="home-alarms-title"
                      >{{ "readings" | translate }}
                    </span>
                    <span
                      ><i class="fa fa-calendar-day"></i
                      >{{ KpisCardsData?.timestampParsed }}</span
                    >
                  </div>
                  <div class="home-kpis-advise">
                    {{ "kpis-data-advise" | translate }}
                  </div>
                </div>
              </div>

              <!-- Gráficos por tipo de dispositivo -->
              <div class="home-graphs">
                <!-- Red -->
                <div
                  class="home-network-graphs"
                  *ngIf="networkChartOptions?.length > 0"
                >
                  <div class="home-cards-title">
                    {{ "network" | translate }}
                  </div>
                  <div class="home-device-graphs-container home-data-odd-row">
                    <div
                      class="home-device-graph"
                      *ngFor="let device of networkChartOptions; index as i"
                    >
                      <app-graph-controller
                        *ngIf="networkHighchartsOptions"
                        [highchartsOptions]="networkHighchartsOptions"
                        [noOptionsParse]="true"
                        [onlyGraph]="true"
                        [disableShowPoints]="true"
                        [data]="networkChartOptions[i]"
                        (chartObject)="networkChartObjects[i] = $event"
                      >
                      </app-graph-controller>
                    </div>
                  </div>
                </div>
                <!-- Dispositivos -->
                <div
                  class="home-devices-graphs"
                  *ngIf="devicesChartOptions?.length > 0"
                >
                  <div class="home-cards-title">
                    {{ "devices" | translate }}
                  </div>
                  <div
                    class="home-device-graphs-container home-device-graphs-container-min-height"
                  >
                    <div
                      class="home-device-graph"
                      *ngFor="let device of devicesChartOptions; index as i"
                    >
                      <app-graph-controller
                        *ngIf="deviceTypeHighchartsOptions"
                        [highchartsOptions]="deviceTypeHighchartsOptions"
                        [noOptionsParse]="true"
                        [onlyGraph]="true"
                        [disableShowPoints]="true"
                        [data]="devicesChartOptions[i]"
                        (chartObject)="devicesChartObjects[i] = $event"
                      >
                      </app-graph-controller>
                    </div>

                    <!-- Leyenda -->
                    <div class="home-graph-legend">
                      <div>
                        <span
                          ><i class="fas fa-mouse mouse-left-click"></i>:</span
                        >
                        <span>
                          {{ "show-table" | translate }}
                        </span>
                      </div>
                      <div>
                        <span>
                          ALT +
                          <i class="fas fa-mouse mouse-left-click"></i>:</span
                        >
                        <span>
                          {{ "map-layer-isolate" | translate }}
                        </span>
                      </div>
                      <div>
                        <span
                          >CTRL +
                          <i class="fas fa-mouse mouse-left-click"></i>:</span
                        >
                        <span>
                          {{ "map-layer-add-remove" | translate }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="home-client-panel"
          [ngClass]="{
            'home-client-panel-reverse': sessionProfile != PROFILES.ARSON
          }"
        >
          <!-- Datos alarmas -->
          <div class="home-alarms-panel">
            <div class="home-data-row" *ngIf="devicesTotal > 0">
              <div
                class="home-cards-title pointer"
                [routerLink]="['/alarmas/listado']"
              >
                {{ "alarms" | translate }} <i class="fas fa-link"></i>
              </div>

              <div class="home-alarms-container home-data-odd-row">
                <div
                  class="home-alarms"
                  [ngClass]="{
                    'home-alarms-full-width':
                      !alarmCodeList || alarmCodeList?.length == 0
                  }"
                >
                  <!-- Gráfica pictograma de alarmas -->
                  <app-graph-controller
                    class="home-alarms-graph"
                    [highchartsOptions]="alarmsHighchartsOptions"
                    [noOptionsParse]="true"
                    [onlyGraph]="true"
                    [disableShowPoints]="true"
                    [data]="alarmsChartOptions"
                  >
                  </app-graph-controller>

                  <div class="home-alarms-data">
                    <div
                      class="pointer"
                      [routerLink]="['/alarmas/listado']"
                      [state]="{ dateRange: 'full', showInactives: false }"
                    >
                      <span class="home-alarms-value"
                        ><i class="fas fa-link"></i> {{ currentAlarms
                        }}<i class="fa fa-tachometer-alt"></i>
                      </span>
                      <span class="home-alarms-title"
                        >{{ "active-alarms" | translate }}
                      </span>
                    </div>

                    <div
                      class="pointer"
                      [routerLink]="['/alarmas/listado']"
                      [state]="{ dateRange: 'fiveDays', showInactives: true }"
                    >
                      <span class="home-alarms-value"
                        ><i class="fas fa-link"></i> {{ last5DaysAlarms
                        }}<i class="fa fa-exclamation-triangle"></i>
                      </span>
                      <span class="home-alarms-title"
                        >{{ "kpis-last-five-days" | translate }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Gráfica nube de alarmas -->
                <div
                  *ngIf="alarmCodeList?.length > 0"
                  class="home-alarms-cloud-graph"
                >
                  <app-graph-controller
                    [highchartsOptions]="alarmsCloudHighchartsOptions"
                    [noOptionsParse]="true"
                    [onlyGraph]="true"
                    [disableShowPoints]="true"
                    [data]="alarmsCloudChartOptions"
                  >
                  </app-graph-controller>
                  <!-- Leyenda -->
                  <div class="home-graph-legend">
                    <div>
                      <span
                        ><i class="fas fa-mouse mouse-left-click"></i>:</span
                      >
                      <span>
                        {{ "show-table" | translate }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Consumos -->
          <div class="home-consumptions-panel">
            <div
              class="home-data-row home-consumption-data"
              *ngIf="devicesTotal > 0"
            >
              <div class="home-cards-title">
                {{ "consumption" | translate }}
              </div>
              <div class="home-consumptions-container">
                <!-- Consumos -->
                <div class="home-consumptions">
                  <div>
                    <span class="home-consumptions-data">
                      {{ lastMonthConsumption?.valueParsed }}</span
                    >
                    <span class="home-consumptions-title">{{
                      "last-month" | translate
                    }}</span>
                    <span
                      ><i class="fa fa-calendar-alt"></i
                      >{{ lastMonthConsumption?.date }}</span
                    >
                  </div>
                  <div>
                    <span class="home-consumptions-data">
                      {{ lastDayConsumption?.valueParsed }}
                    </span>
                    <span class="home-consumptions-title"
                      >{{ "kpis-day" | translate }}
                    </span>
                    <span
                      ><i class="fa fa-calendar-day"></i
                      >{{ lastDayConsumption?.date }}</span
                    >
                  </div>
                </div>

                <div class="home-consumptions-graph">
                  <div
                    class="home-consumptions-graph-load"
                    *ngIf="avoidConsumptionLoad"
                  >
                    <button
                      (click)="
                        avoidConsumptionLoad = false;
                        setConsumptionHighchartsOptions();
                        getConsumptionGraph()
                      "
                    >
                      {{ "load-graph" | translate }} ({{
                        "consumption-last-week" | translate
                      }})
                    </button>
                  </div>
                  <!-- Gráfica -->
                  <app-graph-controller
                    *ngIf="!avoidConsumptionLoad"
                    [highchartsOptions]="consumptionHighchartsOptions"
                    [data]="consumptionChartOptions"
                    [onlyGraph]="true"
                  >
                  </app-graph-controller>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mapa de contadores -->
      <div class="home-map-panel">
        <div class="home-map-container" #mapContainer>
          <app-map-controller
            *ngIf="mapHeight"
            [mapHeight]="mapHeight"
            [filter]="mapFilter"
            [deviceModelsFilter]="deviceModelsFilter"
            [map3d]="sessionProfile == PROFILES.ARSON"
            [mapType]="mapType"
            [drawAgrupationOutline]="drawAgrupationOutline"
            [freeDrawEnabled]="true"
            [allowKml]="true"
            [exportKml]="true"
            [mapSearch]="mapSearch"
            (selectedDevices)="HomeService.goToTable($event, gateways)"
            (activate3dMapFlag)="activate3dMap()"
            [newActiveLayers]="mapActiveLayers"
            [gatewaysData]="gateways"
            [metersData]="devices"
            [locationsData]="gatewayLocations"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
