<div class="firepit">
  <div class="fire">
    <div class="flame"></div>
    <div class="flame"></div>
    <div class="flame"></div>
    <div class="flame"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
    <div class="spark"></div>
  </div>
</div>
