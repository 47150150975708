<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "telemetry" | translate }}</b>
        </h5>

        <!-- Refresco -->
        <div class="telemetry-timer">
          <mat-form-field class="material-header-input" appearance="standard">
            <mat-label
              ><span>{{ "show-alarms-refresh" | translate }}</span>
            </mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="telemetryTimer"
              min="1"
            />
            <span matSuffix>{{ "minutes" | translate }}</span>
          </mat-form-field>
          <!-- Actualizar -->
          <button
            mat-button
            class="mat-icon-button"
            title="{{ 'update' | translate }}"
            (click)="refreshData()"
          >
            <i class="fas fa-sync-alt fa-spin"></i>
          </button>
        </div>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="'fas fa-plus'"
          [title]="'new' | translate"
          (action)="newEk()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Error de conexión con servidor EK -->
        <div *ngIf="serverConnError" class="telemetry-server-error">
          <i class="fas fa-exclamation-triangle"></i>
          <h3>{{ "ek-server-connection-error" | translate }}</h3>
          <i class="fas fa-exclamation-triangle"></i>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'telemetry'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [highlightRow]="highlightRow"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
