// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
// Variables
import { METER_ORDER } from "../../../../DeviceInterface.type";

@Component({
  selector: "app-meter-new-order-dialog",
  templateUrl: "./meter-new-order-dialog.component.html",
  styleUrls: ["./meter-new-order-dialog.component.scss"],
})
export class MeterNewOrderDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  orderType: number;
  orderComments: string;
  orderTypeList: { id: number; value: string }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/
  ngOnInit(): void {

    const { rfModule, idDevType, idFabricante } = this.data; // Desestructuramos data para facilitar el acceso
    const orderTypeList = [];
    // Iteramos sobre las claves y valores de METER_ORDER (suponiendo que es un objeto)
    Object.entries(METER_ORDER).forEach(([key, value], index) => {
      const orderId = parseInt(key);

      // Comprobamos si la orden puede ser añadida a la lista
      const isValidOrder =
        orderId >= METER_ORDER.SUSTITUTION_DEVICE_COMPLETE ||
        (orderId < METER_ORDER.SUSTITUTION_DEVICE_COMPLETE && rfModule);

      if (!isValidOrder) return; // Si la orden no es válida, la saltamos

      // Comprobación especial para MOUNT_RF_MODULE, solo para dispositivos GENERICO / Externo sin comunicaciones
      if (value === METER_ORDER[METER_ORDER.MOUNT_RF_MODULE]) {
        if (idDevType === 2 && idFabricante === 0) {
          orderTypeList.push({
            id: key,
            value: this.translate.instant(String(value)), // Convertimos value a string
          });
        }
      } else {
        orderTypeList.push({
          id: key,
          value: this.translate.instant(String(value)), // Convertimos value a string
        });
      }
    });
    this.orderTypeList = orderTypeList;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Petición
  saveOrder(): void {
    this.MeterController.saveOrder({
      idList: [this.data.id],
      type: this.orderType,
      comments: this.orderComments,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
