import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FileImportService } from "../../../services/shared/FileImportService.service";

@Component({
  selector: "app-file-import",
  templateUrl: "./file-import.component.html",
  styleUrls: ["./file-import.component.scss"],
})
export class FileImportComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() fileType: string;
  @Input() multiple: boolean;
  @Input() removeButton: boolean;
  fileToImport: File;
  filesToImport: File[];
  filesToImportNames: string;
  @Output() fileReady = new EventEmitter<File | File[]>();
  @Output() fileChange = new EventEmitter<File | File[]>();
  @ViewChild("importInput") importInput: HTMLInputElement;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(public FileImportService: FileImportService) {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención de los nombres de archivos
  getFilesNames(): void {
    if (this.multiple) {
      this.filesToImportNames = this.filesToImport
        .map((file: File) => file?.name)
        .join(", ");
    } else {
      this.filesToImportNames = this.fileToImport?.name;
    }
  }


  clear(): void {
    this.fileToImport = null;
    this.filesToImport = null;
    this.filesToImportNames = null;
    this.FileImportService.resetFile(this.importInput);
  }
}
