import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
// File saver
import saveAs from "file-saver";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
import { DataConverterService } from "../../../../../../../services/shared/DataConverterService.service";
import { ManufacturerService } from "../../../../../../../services/shared/ManufacturerService.service";
import { EntityControllerService } from "../../../../../../../services/server/EntityController.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { PdfCreatorService } from "../../../../../../../services/shared/PdfCreatorService.service";
// Interfaces
import {
  SubstitutionDevice,
  SubstitutionUpdateData,
} from "../../../../DeviceInterface.type";
import { EntityDefinition } from "../../../../../../../interfaces/CupsGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../../../../../services/language/LanguageController.service";

@Component({
  selector: "app-meter-substitution-dialog",
  templateUrl: "./meter-substitution-dialog.component.html",
  styleUrls: ["./meter-substitution-dialog.component.scss"],
})
export class MeterSubstitutionDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  fileImages: boolean = true;
  fileAudios: boolean = true;
  fileData: boolean = true;
  dateFormat: string;
  reportList: any[];
  originalReportList: any[];
  reportHtmlList: HTMLElement[];
  reportReady: boolean;
  @ViewChild("pdf") pdf: ElementRef;
  entityLogo: any;
  entityCupsConf: EntityDefinition[];
  fromCups: boolean = true;
  sessionProfile: string;
  readonly PROFILES = PROFILES;
  nroSerieOld: string;
  nroSerieNew: string;

  // Archivo csv
  fileToImportParsed: any;

  // Edición de sustituciones
  substitutionEditionOptions = [
    {
      value: "fix-substitution-old",
      name: this.translate.instant("fix-substitution-old"),
    },
    {
      value: "fix-substitution-new",
      name: this.translate.instant("fix-substitution-new"),
    },
    {
      value: "update-substitution",
      name: this.translate.instant("update-substitution"),
    },
  ];

  substitutionReasonOptions: any[] = [];
  substitutionReasonSelected: any;
  substitutionEditionSelected: string = "update-substitution";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataConverterService: DataConverterService,
    private DateParserService: DateParserService,
    private domSanitizer: DomSanitizer,
    private EntityController: EntityControllerService,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private PdfCreatorService: PdfCreatorService,
    public SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();
    this.data.oldImages = this.data.oldImageObject?.map((arsonImage) => {
      return this.domSanitizer.bypassSecurityTrustUrl(
        "data:image/png;base64, " + arsonImage["image"]
      );
    });

    this.data.newImages = this.data.newImageObject?.map((arsonImage) => {
      return this.domSanitizer.bypassSecurityTrustUrl(
        "data:image/png;base64, " + arsonImage["image"]
      );
    });

    if (this.data.show == "report") {
      this.getEntityImg();
      this.generateReportFromCups();
    }

    // Edición de sustitución
    if (this.data.show == "edit") {
      this.nroSerieOld = this.data.substitution.nroSerieOld;
      this.nroSerieNew = this.data.substitution.nroSerieNew;
      this.obtainSubstitutionReasonList();
      this.parseSubstitutionTimestamp();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención del logo de la entidad
  getEntityImg(): void {
    this.EntityController.getImg(
      this.SessionDataService.getCurrentEntity().id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        this.entityLogo = this.domSanitizer.bypassSecurityTrustUrl(
          "data:image/png;base64, " + response["body"]
        );
      }
    });
  }

  // Parseo de timestamp de sustitución
  parseSubstitutionTimestamp(): void {
    let timestampParsed = this.DateParserService.parseDate(
      this.data.substitution.timestamp,
      "YYYY-MM-DD HH:mm:ss"
    ).split(" ");
    this.data.substitution.date = timestampParsed[0];
    this.data.substitution.time = timestampParsed[1];
  }

  // Corrección de sustitución: Contador anterior
  fixSubstitutionOld(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.fixSubstitutionOld(
          this.data.substitution.id,
          this.SessionDataService.getCurrentAgrupation()?.id,
          {
            nroSerie: this.data.substitution.nroSerieOld,
            value: this.data.substitution.valueOld,
          }
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendDialogAction({ action: "reload" });
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Corrección de sustitución: Contador nuevo
  fixSubstitutionNew(): void {
    let newTimestamp = this.DateParserService.toTimestamp(
      this.data.substitution.date + " " + this.data.substitution.time,
      "YYYY-MM-DD HH:mm:ss"
    );
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.fixSubstitutionNew(this.data.substitution.id, {
          nroSerieNew: this.data.substitution.nroSerieNew,
          valueNew: this.data.substitution.valueNew,
          nroSerieOld: this.data.substitution.nroSerieOld,
          valueOld: this.data.substitution.valueOld,
          rfModuleNew: this.data.substitution.rfModuleNew,
          precinto: this.data.substitution.precinto,
          precintoNuevo: this.data.substitution.precinto,
          latitude: this.data.substitution.latitude,
          longitude: this.data.substitution.longitude,
          entity: this.SessionDataService.getCurrentEntity().entity,
          agrupation: this.SessionDataService.getCurrentAgrupation().name,
          comments: this.data.substitution.comments,
          timestamp: parseInt(newTimestamp) / 1000,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendDialogAction({ action: "reload" });
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  // Actualización de imagen de sustitución
  updateImage(nroSerie: string, event: any): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        if (event.target.files[0]) {
          // Crear un objeto FormData
          const formData = new FormData();
          // Agregar el archivo al FormData
          formData.append("files", event.target.files[0]); // 'file' es el nombre del campo en el backend

          // Llamar al método de actualización de la imagen
          this.MeterController.updateSubstitutionImage(
            this.data.substitution.id,
            nroSerie,
            formData // Enviar el FormData con el archivo
          ).subscribe((response) => {
            if (response["code"] == 0) {
              this.updateShowedImage(nroSerie);
              this.ToastService.fireToast(
                "success",
                this.translate.instant("saved")
              );
            }
          });
        }
      }
    });
  }

  // Actualización de imagen visualizada
  updateShowedImage(nroSerie: string): void {
    this.MeterController.getSubstitutionImage({
      substitutionId: this.data.substitution.id,
      nroSerie: nroSerie,
    }).subscribe((response) => {
      if (nroSerie == this.data.substitution.nroSerieOld) {
        this.data.oldImageObject = response["body"];
        this.data.oldImages = this.data.oldImageObject?.map((arsonImage) => {
          return this.domSanitizer.bypassSecurityTrustUrl(
            "data:image/png;base64, " + arsonImage["image"]
          );
        });
      } else {
        this.data.newImageObject = response["body"];
        this.data.newImages = this.data.newImageObject?.map((arsonImage) => {
          return this.domSanitizer.bypassSecurityTrustUrl(
            "data:image/png;base64, " + arsonImage["image"]
          );
        });
      }
    });
  }

  deleteNewImage(i: number) {
    this.deleteImage(i, "new");
  }

  deleteOldImage(i: number) {
    this.deleteImage(i, "old");
  }

  deleteImage(i: number, imageType: string) {
    //imagetype nos dice si es old o new, y el i es la posicion
    let imageObject: any;

    imageType == "old"
      ? (imageObject = this.data.oldImageObject[i])
      : (imageObject = this.data.newImageObject[i]);

    this.MeterController.deleteSubstitutionImage(
      imageObject["substitutionImageId"]
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast(
          "success",
          this.translate.instant("deleted-success")
        );
        let nroSerie;
        imageType == "old"
          ? (nroSerie = this.data.substitution.nroSerieOld)
          : (nroSerie = this.data.substitution.nroSerieNew);
        this.updateShowedImage(nroSerie);
      }
    });
  }

  // Descarga de informe de sustituciones
  downloadSubstitutionFile(): void {
    let urls: Observable<any>[] = [];
    let urlsActive: string[] = [];

    // Fichero
    if (this.fileData) {
      urls.push(
        this.MeterController.downloadSubstitutionsFile(
          this.data.agrupation,
          this.data.from,
          this.data.to
        )
      );
      urlsActive.push("file");
    }

    // Imágenes
    if (this.fileImages) {
      urls.push(
        this.MeterController.downloadSubstitutionsImagesZip(
          this.data.agrupation,
          this.data.from,
          this.data.to
        )
      );
      urlsActive.push("images");
    }

    // Audios
    if (this.fileAudios) {
      urls.push(
        this.MeterController.downloadSubstitutionsAudiosZip(
          this.data.agrupation,
          this.data.from,
          this.data.to
        )
      );
      urlsActive.push("audios");
    }

    if (urls.length > 0) {
      forkJoin(urls).subscribe((responses) => {
        responses.forEach((response, i) => {
          if (urlsActive[i] == "file") {
            if (response) {
              saveAs(
                response,
                this.translate.instant("substitutions") +
                  " (" +
                  this.DateParserService.parseDate(
                    parseInt(this.data.from),
                    this.dateFormat
                  ) +
                  " - " +
                  this.DateParserService.parseDate(
                    parseInt(this.data.to),
                    this.dateFormat
                  ) +
                  ").csv"
              );
            }
          } else if (response["code"] == 0) {
            saveAs(
              this.DataConverterService.getDownloadBlobFromByteFile(
                response["body"],
                "application/zip"
              ),
              this.translate.instant("substitutions") +
                " " +
                this.translate.instant("file-" + urlsActive[i]) +
                " (" +
                this.DateParserService.parseDate(
                  parseInt(this.data.from),
                  this.dateFormat
                ) +
                " - " +
                this.DateParserService.parseDate(
                  parseInt(this.data.to),
                  this.dateFormat
                ) +
                ").zip"
            );
          }
        });
        this.SessionDataService.sendDialogAction({ action: "close" });
      });
    }
  }

  // Procesado de fichero de datos de sustitución
  processFile(file: File): void {
    let reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.fileToImportParsed = reader.result;
        this.generateReport();
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }

  // Generación de informe pdf desde CUPS
  generateReportFromCups(): void {
    this.reportReady = false;
    this.reportList = [];
    this.reportHtmlList = null;

    let columnsPosition: object = {
      contratoColumn: this.entityCupsConf.find(
        (column) => column.label == "CONTRATO"
      )?.colPosition,
      domicilioColumn: this.entityCupsConf.find(
        (column) => column.label == "DOMICILIO"
      )?.colPosition,
      clienteColumn: this.entityCupsConf.find(
        (column) => column.label == "CLIENTE"
      )?.colPosition,
      zonaColumn: this.entityCupsConf.find((column) => column.label == "ZONA")
        ?.colPosition,
      emplaztoColumn: this.entityCupsConf.find(
        (column) => column.label == "EMPLAZTO"
      )?.colPosition,
      telefonoColumn: this.entityCupsConf.find(
        (column) => column.label == "TELEFONO"
      )?.colPosition,
      calibreColumn: this.entityCupsConf.find(
        (column) => column.label == "CALIBRE"
      )?.colPosition,
      otColumn: this.entityCupsConf.find((column) => column.label == "OT")
        ?.colPosition,
      motivoColumn: this.entityCupsConf.find(
        (column) => column.label == "MOTIVO"
      )?.colPosition,
      lectura0Column: this.entityCupsConf.find(
        (column) => column.label == "LINEA0"
      )?.colPosition,
      lectura1Column: this.entityCupsConf.find(
        (column) => column.label == "LINEA1"
      )?.colPosition,
      lectura2Column: this.entityCupsConf.find(
        (column) => column.label == "LINEA2"
      )?.colPosition,
      lectura3Column: this.entityCupsConf.find(
        (column) => column.label == "LINEA3"
      )?.colPosition,
      lectura4Column: this.entityCupsConf.find(
        (column) => column.label == "LINEA4"
      )?.colPosition,
    };

    this.data.data.forEach((substitution, i) => {
      this.getReportData(substitution, columnsPosition);
    });
  }

  // Generación de informe pdf
  generateReport(): void {
    this.reportReady = false;
    this.reportList = [];
    this.reportHtmlList = null;
    let filterRows = this.fileToImportParsed.split("\n");
    filterRows = filterRows.map((row: string) => {
      return row.split(";").map((element: any) => element.replace("\r", ""));
    });
    let fileColumns = [...filterRows[0]];
    let fileData = [...filterRows.slice(1)];
    let columnsPosition: object = {
      nroSerieOldColumn: fileColumns.indexOf("Numero de serie anterior"),
      contratoColumn: fileColumns.indexOf("CONTRATO"),
      domicilioColumn: fileColumns.indexOf("DOMICILIO"),
      clienteColumn: fileColumns.indexOf("CLIENTE"),
      zonaColumn: fileColumns.indexOf("ZONA"),
      emplaztoColumn: fileColumns.indexOf("EMPLAZTO"),
      telefonoColumn: fileColumns.indexOf("TELEFONO"),
      calibreColumn: fileColumns.indexOf("CALIBRE"),
      otColumn: fileColumns.indexOf("OT"),
      motivoColumn: fileColumns.indexOf("MOTIVO"),
      lectura1Column: fileColumns.indexOf("LINEA1"),
      lectura2Column: fileColumns.indexOf("LINEA2"),
      lectura3Column: fileColumns.indexOf("LINEA3"),
      lectura4Column: fileColumns.indexOf("LINEA4"),
    };

    this.data.data.forEach((substitution, i) => {
      this.getReportData(substitution, columnsPosition, fileData);
    });
  }

  // Obtención de las lecturas parseadas
  getLecture(lecture: string): string[] {
    if (lecture) {
      let parsedLecture = lecture
        ?.split("   ")
        ?.map((lectura) => lectura?.trim());
      return parsedLecture.filter((element) => element != "");
    }
    return null;
  }

  // Obtención de datos adicionales del informe
  getReportData(
    substitution: SubstitutionDevice,
    columnsPosition: object,
    substitutionData?: string[][]
  ): void {
    let urls = [
      this.MeterController.getSubstitutionImage({
        substitutionId: substitution.id,
        nroSerie: substitution.nroSerieOld,
      }),
      this.MeterController.getSubstitutionImage({
        substitutionId: substitution.id,
        nroSerie: substitution.nroSerieNew,
      }),
    ];

    forkJoin(urls).subscribe((responses) => {
      let oldImages: any;
      let newImages: any;

      if (responses[0]["code"] == 0) {
        this.data.oldImageObject = responses[0]["body"];
        oldImages = this.data.oldImageObject.map((arsonImage) => {
          return arsonImage["image"];
        });
      }
      if (responses[1]["code"] == 0) {
        this.data.newImageObject = responses[1]["body"];
        newImages = this.data.newImageObject.map((arsonImage) => {
          return arsonImage["image"];
        });
      }

      if (substitutionData) {
        this.getReportObject(
          substitution,
          columnsPosition,
          substitutionData,
          oldImages,
          newImages
        );
      } else {
        this.getReportObjectFromCups(
          substitution,
          columnsPosition,
          oldImages,
          newImages
        );
      }
    });
  }

  // Creación del objeto informe desde CUPS
  getReportObjectFromCups(
    substitution: SubstitutionDevice,
    columnsPosition: any,
    oldImages: any,
    newImages: any
  ): void {
    // Datos extra de sustitución
    let substitutionExtraData = this.data.data.find(
      (element) => element.nroSerieOld == substitution.nroSerieOld
    );
    // Modelo nuevo
    let newModel = this.ManufacturerService.getManufacturer(
      String(substitution.fabricanteNew),
      String(substitution.devTypeNew),
      LANGUAGE.ESPANOL
    );
    // Modelo RF nuevo
    let newRfModel = this.ManufacturerService.getManufacturer(
      String(substitution.fabricanteNew),
      String(substitution.devTypeModuleNew),
      LANGUAGE.ESPANOL
    );
    // Informe
    this.reportList.push({
      contrato: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.contratoColumn > 9
                ? columnsPosition.contratoColumn
                : "0" + columnsPosition.contratoColumn)
          ]
        : null,
      cliente: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.clienteColumn > 9
                ? columnsPosition.clienteColumn
                : "0" + columnsPosition.clienteColumn)
          ]
        : null,
      domicilio: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.domicilioColumn > 9
                ? columnsPosition.domicilioColumn
                : "0" + columnsPosition.domicilioColumn)
          ]
        : null,
      localidad: this.data.entity,
      zona: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.zonaColumn > 9
                ? columnsPosition.zonaColumn
                : "0" + columnsPosition.zonaColumn)
          ]
        : null,
      motivo: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.motivoColumn > 9
                ? columnsPosition.motivoColumn
                : "0" + columnsPosition.motivoColumn)
          ]
        : null,
      emplazto: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.emplaztoColumn > 9
                ? columnsPosition.emplaztoColumn
                : "0" + columnsPosition.emplaztoColumn)
          ]
        : null,
      telefono: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.telefonoColumn > 9
                ? columnsPosition.telefonoColumn
                : "0" + columnsPosition.telefonoColumn)
          ]
        : null,
      nroSerieOld: substitution.nroSerieOld,
      nroSerieNew: substitution.nroSerieNew,
      newCalibre: substitutionExtraData
        ? substitutionExtraData.cm[
            "col" +
              (columnsPosition.calibreColumn > 9
                ? columnsPosition.calibreColumn
                : "0" + columnsPosition.calibreColumn)
          ]
        : null,
      lectura0: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData.cm[
              "col" +
                (columnsPosition.lectura0Column > 9
                  ? columnsPosition.lectura0Column
                  : "0" + columnsPosition.lectura0Column)
            ]
          : null
      ),
      lectura1: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData.cm[
              "col" +
                (columnsPosition.lectura1Column > 9
                  ? columnsPosition.lectura1Column
                  : "0" + columnsPosition.lectura1Column)
            ]
          : null
      ),
      lectura2: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData.cm[
              "col" +
                (columnsPosition.lectura2Column > 9
                  ? columnsPosition.lectura2Column
                  : "0" + columnsPosition.lectura2Column)
            ]
          : null
      ),
      lectura3: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData.cm[
              "col" +
                (columnsPosition.lectura3Column > 9
                  ? columnsPosition.lectura3Column
                  : "0" + columnsPosition.lectura3Column)
            ]
          : null
      ),
      lectura4: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData.cm[
              "col" +
                (columnsPosition.lectura4Column > 9
                  ? columnsPosition.lectura4Column
                  : "0" + columnsPosition.lectura4Column)
            ]
          : null
      ),
      user: substitution.user,
      replaceDate: this.DateParserService.parseDate(
        substitution.timestamp,
        "DD/MM/YYYY HH:mm:ss",
        "Atlantic/Canary"
      ),
      rfModule: substitution.rfModuleNew,
      comments: substitution.comments,
      valueNew: substitution.valueNew,
      valueOld: substitution.valueOld,
      oldImages: oldImages,
      newImages: newImages,
      userName: substitution.name + " " + substitution.surname,
      precinto: substitution.precinto,
      newFabricacion: "20" + substitution.nroSerieNew.substring(1, 3),
      // newModel: newModel.manufacturerText + " / " + newModel.deviceText,
      newModel: "Diehl / Altair V4",
      rfModuleModel:
        newRfModel.manufacturerText + " / " + newRfModel.deviceText,
    });

    this.reportReady = this.reportList.length == this.data.data.length;
  }

  // Creación del objeto informe
  getReportObject(
    substitution: SubstitutionDevice,
    columnsPosition: any,
    substitutionData: string[][],
    oldImages: any,
    newImages: any
  ): void {
    // Datos extra de sustitución
    let substitutionExtraData = substitutionData.find(
      (element) =>
        element[columnsPosition.nroSerieOldColumn] == substitution.nroSerieOld
    );
    // Modelo nuevo
    let newModel = this.ManufacturerService.getManufacturer(
      String(substitution.fabricanteNew),
      String(substitution.devTypeNew),
      LANGUAGE.ESPANOL
    );
    // Modelo RF nuevo
    let newRfModel = this.ManufacturerService.getManufacturer(
      String(substitution.fabricanteNew),
      String(substitution.devTypeModuleNew),
      LANGUAGE.ESPANOL
    );

    // Informe
    this.reportList.push({
      contrato: substitutionExtraData
        ? substitutionExtraData[columnsPosition.contratoColumn]
        : null,
      cliente: substitutionExtraData
        ? substitutionExtraData[columnsPosition.clienteColumn]
        : null,
      domicilio: substitutionExtraData
        ? substitutionExtraData[columnsPosition.domicilioColumn]
        : null,
      localidad: this.data.entity,
      zona: substitutionExtraData
        ? substitutionExtraData[columnsPosition.zonaColumn]
        : null,
      motivo: substitutionExtraData
        ? substitutionExtraData[columnsPosition.motivoColumn]
        : null,
      emplazto: substitutionExtraData
        ? substitutionExtraData[columnsPosition.emplaztoColumn]
        : null,
      telefono: substitutionExtraData
        ? substitutionExtraData[columnsPosition.telefonoColumn]
        : null,
      nroSerieOld: substitution.nroSerieOld,
      nroSerieNew: substitution.nroSerieNew,
      newCalibre: substitutionExtraData
        ? substitutionExtraData[columnsPosition.calibreColumn]
        : null,
      lectura1: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData[columnsPosition.lectura1Column]
          : null
      ),
      lectura2: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData[columnsPosition.lectura2Column]
          : null
      ),
      lectura3: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData[columnsPosition.lectura3Column]
          : null
      ),
      lectura4: this.getLecture(
        substitutionExtraData
          ? substitutionExtraData[columnsPosition.lectura4Column]
          : null
      ),
      user: substitution.user,
      replaceDate: this.DateParserService.parseDate(
        substitution.timestamp,
        "DD/MM/YYYY HH:mm:ss",
        "Atlantic/Canary"
      ),
      rfModule: substitution.rfModuleNew,
      comments: substitution.comments,
      valueNew: substitution.valueNew,
      valueOld: substitution.valueOld,
      oldImages: oldImages,
      newImages: newImages,
      userName: substitution.name + " " + substitution.surname,
      precinto: substitution.precinto,
      newFabricacion: "20" + substitution.nroSerieNew.substring(1, 3),
      newModel: newModel.manufacturerText + " / " + newModel.deviceText,
      rfModuleModel:
        newRfModel.manufacturerText + " / " + newRfModel.deviceText,
    });

    this.reportReady = this.reportList.length == this.data.data.length;
  }

  // Descarga de informes en PDF
  downloadPdf(): void {
    this.originalReportList = [...this.reportList];
    this.spinner.show("spinner-hard");
    this.pdfDomUpdate(0);
  }

  // Bucle de creación de PDFs
  pdfDomUpdate(index: number): void {
    this.reportList = [this.originalReportList[index]];
    // Actualización de html para evitar colapso de memoria
    setTimeout(() => {
      let currentReport = document.querySelector(
        ".report-container"
      ) as HTMLElement;
      this.PdfCreatorService.createPdf(
        currentReport,
        this.originalReportList[index].contrato,
        index == 0
      ).then(() => {
        if (index < this.originalReportList.length - 1) {
          this.pdfDomUpdate(++index);
        } else {
          this.PdfCreatorService.downloadZip(
            this.translate.instant("substitutions"),
            this.translate.instant("substitution")
          );
        }
      });
    }, 0);
  }

  // Actualización de datos de sustitución
  updatesubstitutionData(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("serial-number-edit-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: SubstitutionUpdateData = {
          valueNew: this.data.substitution.valueNew,
          valueOld: this.data.substitution.valueOld,
          precinto: this.data.substitution.precinto,
          comments: this.data.substitution.comments,
          latitude: this.data.substitution.latitude,
          longitude: this.data.substitution.longitude,
          reasonId: this.substitutionReasonSelected.reasonId,
        };
        this.MeterController.updateSubstitutionData(
          this.data.substitution.agrupationId,
          this.data.substitution.id,
          data
        ).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
            this.SessionDataService.sendDialogAction({ action: "reload" });
            this.SessionDataService.sendDialogAction({ action: "close" });
          }
        });
      }
    });
  }

  obtainSubstitutionReasonList() {
    this.MeterController.obtainSubstitutionReasonList().subscribe(
      (response) => {
        this.substitutionReasonOptions = response["body"];
        this.substitutionReasonSelected = this.substitutionReasonOptions.find(
          (option) => option.reasonDesc === this.data.substitution.reason
        );
        this.substitutionReasonOptions.forEach((substitutionReason) => {
          substitutionReason.reasonDesc = this.translate.instant(
            substitutionReason.reasonDesc
          );
        });
      }
    );
  }
}
