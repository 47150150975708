// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { OutputFilesControllerService } from "../../../../services/server/OutputFilesController.service";
// Interfaces
import { EkFileWindows } from "../OutputFileInterface.type";

@Component({
  selector: "app-output-file-ek280",
  templateUrl: "./output-file-ek280.component.html",
  styleUrls: ["./output-file-ek280.component.scss"],
})
export class OutputFileEk280Component implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionLanguage: string;
  languageSub: Subscription;

  // Ventanas
  windows: EkFileWindows = {
    hora1: null,
    hora2: null,
    hora3: null,
  };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private OutputFilesController: OutputFilesControllerService,
    private ReloadComponentService: ReloadComponentService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();

    // Escucha de cambios en idioma
    this.languageSub = this.SessionDataService.getLanguage().subscribe((language) => {
      this.sessionLanguage = language;
      this.ReloadComponentService.reload();
    });

    // Carga del componente
    this.loadComponent();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getWindows();
  }

  // Obtención de las ventanas
  getWindows(): void {
    this.OutputFilesController.getEkFileWindows().subscribe((response) => {
      if (response["code"] == 0) {
        this.windows = response["body"];
      }
    });
  }

  // Actualización de horarios de envío
  updateWindows(): void {
    this.OutputFilesController.updateEkFileWindows(this.windows).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.ToastService.fireToast("success", this.translate.instant("saved"));
          this.ReloadComponentService.reload();
        }
      }
    );
  }
}
