<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "frames-relaunch" | translate }}
    </h5>
  </div>
  <!-- Relanzar seleccionados -->
  <div class="dialog-body">
    <!-- Checks -->
    <mat-checkbox
      class="dialog-checkbox"
      [color]="'primary'"
      [(ngModel)]="relaunchOnlyMic"
      >{{ "frames-relaunch-only-mic" | translate }}</mat-checkbox
    >
    <mat-checkbox
      class="dialog-checkbox"
      [color]="'primary'"
      [(ngModel)]="relaunchWithAlarms"
      >{{ "frames-relaunch-alarms" | translate }}</mat-checkbox
    >

    <!-- Relanzar rango de fecha -->
    <div class="frames-log-relaunch-date">
      <!-- Hora inicial -->
      <div>
        <label for="relaunch-init-date">{{ "start-date" | translate }}:</label>
        <div>
          <input
            type="date"
            name="relaunch-init-date"
            id="relaunch-init-date"
            [(ngModel)]="relaunchInitDate"
          />
          <input
            type="time"
            name="relaunch-init-hour"
            id="relaunch-init-hour"
            [(ngModel)]="relaunchInitHour"
          />
        </div>
      </div>
      <!-- Hora final -->
      <div>
        <label for="relaunch-init-date">{{ "end-date" | translate }}:</label>
        <div>
          <input
            type="date"
            name="relaunch-final-date"
            id="relaunch-final-date"
            [min]="relaunchInitDate"
            [(ngModel)]="relaunchFinalDate"
            [disabled]="!relaunchInitDate"
          />
          <input
            type="time"
            name="relaunch-final-hour"
            id="relaunch-final-hour"
            [(ngModel)]="relaunchFinalHour"
            [disabled]="!relaunchInitDate"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-raised-button
      color="primary"
      [disabled]="
        !data.selected ||
        data.selected?.length == 0 ||
        !relaunchInitDate ||
        !relaunchFinalDate
      "
      (click)="
        relaunchFrames(data.selected);
        SessionDataService.sendDialogAction({ action: 'close' })
      "
    >
      {{ "relaunch-selection" | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="!relaunchInitDate || !relaunchFinalDate"
      (click)="
        relaunchFrames(data.all); SessionDataService.sendDialogAction({ action: 'close' })
      "
    >
      {{ "relaunch-all" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
