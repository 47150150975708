// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import * as Highcharts from "highcharts/highstock";
import { Options } from "highcharts";
// Estadísticas
import * as ss from "simple-statistics";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { MeterService } from "../../../devices/meter/MeterService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { TemplateService } from "../../../../../services/shared/TemplateService.service";
import { MachineLearningService } from "../../../../../services/shared/MachineLearning.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import {
  CONSUMPTION_PROFILES,
  ConsumptionProfile,
} from "./data-analysis-patterns";
import { AssignedDevice } from "../../../devices/DeviceInterface.type";
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  GraphMeterData,
  GraphMeterSerie,
  GraphRequestData,
} from "../../DataAnalysisInterface.type";
import { PanelMenuOption } from "../../../../../modules/material-module/MaterialInterface.type";
// Componentes
import { GraphControllerComponent } from "../../../../../modules/graph-module/graph-controller/graph-controller.component";
import { DataAnalysisModelsDialogComponent } from "../data-analysis-models-dialog/data-analysis-models-dialog.component";

@Component({
  selector: "app-data-analysis-models",
  templateUrl: "./data-analysis-models.component.html",
  styleUrls: ["./data-analysis-models.component.scss"],
})
export class DataAnalysisModelsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  dialogSub: Subscription;

  preselectedConsumptionProfile: number;
  selectedConsumptionProfile: ConsumptionProfile;
  consumptionProfileList: ConsumptionProfile[];
  meterProfiles: ConsumptionProfile[];
  metersList: AssignedDevice[];
  selectedMeters: AssignedDevice[];
  meterSimilarity: number;
  preselectedMeters: number[];

  // Gráfica
  graphData: any;
  graphSeries: any[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastNaturalWeeks("8");
  from: number;
  to: number;
  pattern: {
    patternSerie: number[][];
    normalizedPattern: number[];
    consumptionPattern: number[];
    consumptionSdPattern: number[];
  };
  patternChartOptions: object;
  patternHighchartsOptions: Options;
  yearlyPattern: boolean;
  patternSelectOptions = [
    { id: "weekly", name: this.translate.instant("weekly-pattern") },
    { id: "yearly", name: this.translate.instant("yearly-pattern") },
  ];
  @ViewChild("meterConsumptionGraph")
  meterConsumptionGraph: GraphControllerComponent;

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];
  predictions: number[][];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    private GraphOptionsService: GraphOptionsService,
    private MachineLearningService: MachineLearningService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    public SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.dialogSub = this.SessionDataService.getDialogAction().subscribe(
      (action) => {
        if (action.action == "newProfile") {
          this.preselectedConsumptionProfile = action.newProfile.profileId;
          this.selectedConsumptionProfile = action.newProfile;
          this.consumptionProfileList = [
            ...this.consumptionProfileList,
            action.newProfile,
          ];
          // this.saveConsumptionProfile(this.consumptionProfileList);
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.dialogSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "new-profile",
        icon: "fas fa-plus",
        text: this.translate.instant("new-profile"),
        disabled: false,
        visible: true,
      },
      {
        action: "consumption-profile-assign",
        icon: "fas fa-tachometer-alt",
        text: this.translate.instant("consumption-profile-assign"),
        disabled:
          !this.selectedMeters ||
          !this.selectedConsumptionProfile?.profilePatterns,
        visible: true,
      },
      {
        action: "consumption-profile-save",
        icon: "fas fa-share",
        text: this.translate.instant("consumption-profile-save"),
        disabled:
          !this.pattern?.patternSerie || !this.selectedConsumptionProfile,
        visible: true,
      },
      {
        action: "consumption-profile-update",
        icon: "fas fa-sync-alt",
        text: this.translate.instant("consumption-profile-update"),
        disabled:
          !this.pattern?.patternSerie ||
          !this.selectedConsumptionProfile?.profilePatterns,
        visible: true,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "new-profile":
        this.MaterialDialogService.openDialog(
          DataAnalysisModelsDialogComponent,
          [...this.consumptionProfileList]
        );
        break;
      case "consumption-profile-assign":
        this.assignProfileToMeters();
        break;
      case "consumption-profile-save":
        this.overwriteConsumptionProfile();
        break;
      case "consumption-profile-update":
        this.updateConsumptionProfile();
        break;
      default:
        break;
    }
  }

  // Carga del componente
  loadComponent(): void {
    this.setPanelMenuOptions();
    this.getConsumptionProfiles();
    this.getMeters();
    this.preselectedMeters = history.state.data;
    this.setHighchartsOptions();
  }

  // Obtención de perfiles de consumo
  getConsumptionProfiles(): void {
    let consumptionProfileList = [];
    this.DataAnalysisController.getConsumptionProfiles().subscribe(
      (response) => {
        if (response["code"] == 0) {
          let consumptionProfileData = response["body"];
          consumptionProfileData.forEach((consumptionProfile) => {
            if (consumptionProfile.profilePatterns) {
              consumptionProfile.profilePatterns = JSON.parse(
                consumptionProfile.profilePatterns
              );
            } else {
              consumptionProfile.profilePatterns = {};
            }
            consumptionProfile.meterPattern = consumptionProfile.meterPattern
              ?.split(",")
              .map((data) => parseFloat(data));
          });
          consumptionProfileList = consumptionProfileData.filter(
            (consumptionProfile: ConsumptionProfile) =>
              !consumptionProfile.meterId
          );
          this.meterProfiles = consumptionProfileData.filter(
            (consumptionProfile: ConsumptionProfile) =>
              consumptionProfile.meterId != null
          );
        }
        let profiles = Object.keys(CONSUMPTION_PROFILES);
        for (let i = 0; i < profiles.length / 2; i++) {
          if (i == 8) {
            i++;
          }
          let profile = consumptionProfileList.find(
            (consumptionProfile) => consumptionProfile.profileId == i
          );
          if (profile) {
            profile.profileName = this.translate.instant(
              "consumptionProfile" + i
            );
          } else {
            consumptionProfileList.push({
              profileId: i,
              profileName: this.translate.instant("consumptionProfile" + i),
              profileSimilarity: 0.95,
            });
          }
        }
        this.consumptionProfileList = consumptionProfileList.sort(
          (a, b) => a.profileId - b.profileId
        );
      }
    );
  }

  // Obtención de los datos del mapa
  getMeters(): void {
    this.MeterController.table(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          // Contadores
          let meterList: AssignedDevice[] =
            this.MeterService.parseAssignedMeterList(
              response["body"]["meterList"]
            );
          this.metersList = meterList;
          if (this.preselectedMeters) {
            this.selectedMeters = this.metersList.filter((meter) =>
              this.preselectedMeters.includes(meter.id)
            );
          }
        }
      }
    );
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: number, to: number): void {
    this.from = from;
    this.to = to;

    if (this.selectedMeters?.length > 0) {
      let data: GraphRequestData = {
        meterList: this.selectedMeters.map((meter) => meter.id),
        agrupation: this.currentAgrupation.id,
        fromTimestamp: from,
        toTimestamp: to,
        graphType: 2,
      };

      this.DataAnalysisController.getGraphGroup(1, data).subscribe(
        (response) => {
          if (response["code"] == 0) {
            this.graphData = response["body"];
            this.getSeries();
            if (this.graphData?.length > 0) {
              this.calculatePatterns();
            }
          }
        }
      );
    } else {
      this.graphData = [];
      this.getSeries();
    }
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let series: GraphMeterSerie[] = [];
    // Ordenamiento por número de serie
    this.graphData.sort((a, b) => {
      return a.nroSerie.localeCompare(b.nroSerie);
    });

    this.graphData.forEach((element: GraphMeterData, i) => {
      series.push({
        id: element.nroSerie,
        name: element.nroSerie,
        type: this.yearlyPattern ? "column" : "line",
        dataGrouping: {
          forced: true,
          units: this.yearlyPattern ? [["month", [1]]] : [["hour", [1]]],
        },
        data: element.readings,
        tooltip: {
          valueSuffix: " m³",
          valueDecimals: 3,
        },
        yAxis: 0,
      });
    });
    this.graphSeries = series;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    highchartsOptions["navigation"] = { buttonOptions: { enabled: false } };
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["legend"]["enabled"] = false;
    // Consumo
    chartOptions["yAxis"] = [
      {
        title: {
          text: this.translate.instant("consumption"),
          style: {
            color: "#42a5f5",
            fontWeight: "bold",
          },
        },
        labels: {
          format: "{value} m³",
          style: {
            color: "#42a5f5",
          },
        },
        opposite: false,
      },
    ];
    if (this.yearlyPattern) {
      chartOptions["rangeSelector"]["buttons"] = [];
    } else {
      chartOptions["rangeSelector"]["buttons"].unshift({
        type: "year",
        text: this.translate.instant("hour-selector"),
        dataGrouping: {
          approximation: "sum",
          forced: true,
          units: [["hour", [1]]],
        },
        events: null,
      });
    }
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setPatternChartsOptions(): void {
    const self = this;
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["legend"]["enabled"] = false;
    chartOptions["rangeSelector"]["buttons"] = [];
    // Patrón
    if (!this.predictions) {
      chartOptions["yAxis"] = [
        {
          title: {
            text: this.translate.instant("consumption-pattern"),
            style: {
              color: "#ef5350",
              fontWeight: "bold",
            },
          },
          labels: {
            style: {
              color: "#ef5350",
            },
          },
          opposite: false,
        },
      ];
      chartOptions["xAxis"] = this.yearlyPattern
        ? [
            {
              dateTimeLabelFormats: {
                millisecond: "%b",
                second: "%b",
                minute: "%b",
                hour: "%b",
                day: "%b",
                week: "%b",
                month: "%b",
                year: "%b",
              },
              startOnTick: true,
              showFirstLabel: true,
              tickInterval: 1,
              units: [["month", [1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12]]],
            },
          ]
        : [
            {
              dateTimeLabelFormats: {
                millisecond: "%A",
                second: "%A",
                minute: "%A",
                hour: "%A",
                day: "%A",
                week: "%A",
                month: "%A",
                year: "%A",
              },
              startOnTick: true,
              showFirstLabel: true,
            },
          ];
      chartOptions["tooltip"] = {
        split: false,
        shared: true,
        valueDecimals: 3,
        formatter: function () {
          if (this.points.length > 1) {
            return (
              Highcharts.dateFormat(
                self.yearlyPattern ? "%B" : "%a %H:%M",
                this.point.x
              ) +
              "<br/>" +
              `<b><span style="color:` +
              this.points[1].series.color +
              `">` +
              this.points[1].series.name +
              "</span></b>: " +
              self.translate.instant("min") +
              " " +
              (self.yearlyPattern
                ? Highcharts.numberFormat(this.points[1].y, 3)
                : Highcharts.numberFormat(this.points[1].point.low, 3)) +
              ", " +
              self.translate.instant("max") +
              " " +
              (self.yearlyPattern
                ? Highcharts.numberFormat(this.points[2].y, 3)
                : Highcharts.numberFormat(this.points[1].point.high, 3)) +
              "<br/>" +
              `<b><span style="color:` +
              this.points[0].series.color +
              `">` +
              this.points[0].series.name +
              "</span></b>: " +
              Highcharts.numberFormat(this.points[0]?.y, 3)
            );
          } else {
            return (
              Highcharts.dateFormat(
                self.yearlyPattern ? "%B" : "%a %H:%M",
                this.point.x
              ) +
              "<br/>" +
              `<b><span style="color:` +
              this.points[0]?.series.color +
              `">` +
              this.points[0]?.series.name +
              "</span></b>: " +
              Highcharts.numberFormat(this.points[0]?.y, 3)
            );
          }
        },
      };

      // Patrón de consumo
      chartOptions["series"] = [
        {
          id: "pattern",
          name: this.translate.instant("consumption-pattern"),
          type: this.yearlyPattern ? "column" : "line",
          data: this.pattern?.patternSerie?.map((data, i) => {
            return [
              data[0],
              this.pattern.consumptionPattern[i] > 0
                ? this.pattern.consumptionPattern[i]
                : 0,
            ];
          }),
          dataGrouping: {
            forced: true,
            units: this.yearlyPattern ? [["month", [1]]] : [["hour", [1]]],
          },
          color: "#ef5350",
          zIndex: 99,
        },
      ];
    }
    // Patrón de perfil
    if (!this.predictions && this.selectedConsumptionProfile?.profilePatterns) {
      chartOptions["yAxis"].push({
        title: {
          text: this.translate.instant(
            "consumptionProfile" + this.selectedConsumptionProfile.profileId
          ),
          style: {
            color: "#4caf50",
            fontWeight: "bold",
          },
        },
        labels: {
          enabled: false,
        },
        opposite: false,
      });
      chartOptions["series"].push({
        id: "profilePatternRange",
        name: this.translate.instant(
          "consumptionProfile" + this.selectedConsumptionProfile.profileId
        ),
        data: this.selectedConsumptionProfile.profilePatterns[
          this.yearlyPattern ? "yearly" : "hourly"
        ]?.map((data, i) => {
          let min =
            data * ss.standardDeviation(this.pattern.consumptionPattern) +
            ss.mean(this.pattern.consumptionPattern) -
            ss.standardDeviation(this.pattern.consumptionPattern) *
              (this.yearlyPattern ? 0.07 : 1);
          let max =
            data * ss.standardDeviation(this.pattern.consumptionPattern) +
            ss.mean(this.pattern.consumptionPattern) +
            ss.standardDeviation(this.pattern.consumptionPattern) *
              (this.yearlyPattern ? 0.07 : 1);
          return [
            this.pattern.patternSerie[i][0],
            min > 0 ? min : 0,
            max > 0 ? max : 0,
          ];
        }),
        dataGrouping: {
          forced: true,
          units: this.yearlyPattern ? [["month", [1]]] : [["hour", [1]]],
          approximation: this.yearlyPattern ? "sum" : null,
        },
        type: this.yearlyPattern ? "area" : "arearange",
        color: "#4caf50",
        fillColor: this.yearlyPattern ? "white" : "rgba(76, 175, 80, 0.3)",
        zIndex: 9,
      });
      if (this.yearlyPattern) {
        let maxLine = { ...chartOptions["series"][1] };
        maxLine.id = "profilePatternMaxRange";
        maxLine.data = maxLine.data?.map((data) => [data[0], data[2]]);
        maxLine.fillColor = "rgba(76, 175, 80, 0.3)";
        maxLine.zIndex = 0;
        chartOptions["series"].push(maxLine);
      }
    }
    // Predicción
    if (this.predictions) {
      chartOptions["rangeSelector"]["buttons"] = [
        {
          type: "year",
          text: this.translate.instant("hour-selector"),
          dataGrouping: {
            approximation: "sum",
            forced: true,
            units: [["hour", [1]]],
          },
          events: null,
        },
        {
          type: "year",
          text: this.translate.instant("day-selector"),
          dataGrouping: {
            approximation: "sum",
            forced: true,
            units: [["day", [1]]],
          },
          events: null,
        },
        {
          type: "year",
          text: this.translate.instant("week-selector"),
          dataGrouping: {
            approximation: "sum",
            forced: true,
            units: [["week", [1]]],
          },
          events: null,
        },
        {
          type: "year",
          text: this.translate.instant("month-selector"),
          dataGrouping: {
            approximation: "sum",
            forced: true,
            units: [["month", [1]]],
          },
          events: null,
        },
      ];
      chartOptions["yAxis"] = [
        {
          title: {
            text: self.translate.instant("sarima-predict"),
            style: {
              color: "#ff9800",
              fontWeight: "bold",
            },
          },
          labels: {
            enabled: true,
            style: { color: "#ff9800" },
          },
          opposite: false,
          startOnTick: false,
          showFirstLabel: false,
        },
      ];
      chartOptions["xAxis"] = [
        {
          dateTimeLabelFormats: {
            millisecond: "%H:%M:%S.%L",
            second: "%H:%M:%S",
            minute: "%H:%M",
            hour: "%H:%M",
            day: "%e. %b",
            week: "%e. %b",
            month: "%b '%y",
            year: "%Y",
          },
          startOnTick: false,
          showFirstLabel: true,
          tickInterval: null,
          units: [["day"]],
        },
      ];
      chartOptions["tooltip"] = {
        split: false,
        shared: false,
        formatter: function () {
          return (
            "<span style='font-size: 10px'>" +
            Highcharts.dateFormat(
              self.yearlyPattern ? "%b %d" : "%A, %b %d, %H:%M",
              this.point.x
            ) +
            "</span><br/>" +
            this.series.name +
            ": <b>" +
            Highcharts.numberFormat(this.point.y, 3) +
            " m³</b>"
          );
        },
      };
      chartOptions["series"] = this.predictions.map((prediction, i) => {
        return {
          id: "prediction" + i,
          name: this.graphData[i].nroSerie,
          type: this.yearlyPattern ? "column" : "line",
          data: prediction.map((data, i) => [
            this.DateParserService.getNowTimestamp() + i * 60 * 60 * 1000,
            data,
          ]),
          dataGrouping: {
            forced: true,
            units: this.yearlyPattern ? [["month", [1]]] : [["hour", [1]]],
          },
          color: this.graphData.length == 1 ? "#ff9800" : null,
        };
      });
    }
    this.patternChartOptions = chartOptions;
  }

  // Actualización de serie con perfil seleccionado
  updateProfileSeries(): void {
    this.setPatternChartsOptions();
    this.setPanelMenuOptions();
  }

  // Cálculo de patrón de los contadores seleccionados
  calculatePatterns(): void {
    this.pattern = this.MachineLearningService.calculatePatterns(
      this.graphData.map((meter) => meter.readings),
      this.yearlyPattern
    );
    if (this.consumptionProfileList) {
      this.checkSimilarities();
    }
    this.setPatternChartsOptions();
    this.setPanelMenuOptions();
  }

  // Guardado de perfil
  saveConsumptionProfile(profiles: ConsumptionProfile[]): void {
    let profilesToSave: any = profiles.map((profile) => {
      return {
        id: profile.id,
        profileId: profile.profileId,
        profilePatterns: JSON.stringify({
          hourly: profile.profilePatterns?.hourly,
          yearly: profile.profilePatterns?.yearly,
        }),
        profileSimilarity: profile.profileSimilarity,
        meterId: profile.meterId,
        meterPattern: profile.meterPattern?.toString(),
      };
    });
    this.DataAnalysisController.saveConsumptionProfiles(
      profilesToSave
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
      }
    });
  }

  // Sobreescritura de perfil guardado con nuevo patrón
  overwriteConsumptionProfile(): void {
    if (!this.selectedConsumptionProfile.profilePatterns) {
      this.selectedConsumptionProfile.profilePatterns = {};
    }
    this.selectedConsumptionProfile.profilePatterns[
      this.yearlyPattern ? "yearly" : "hourly"
    ] = [...this.pattern.normalizedPattern];
    this.saveConsumptionProfile([this.selectedConsumptionProfile]);
  }

  // Actualización de perfil guardado con la media con el nuevo patrón
  updateConsumptionProfile(): void {
    this.selectedConsumptionProfile.profilePatterns[
      this.yearlyPattern ? "yearly" : "hourly"
    ] = this.selectedConsumptionProfile.profilePatterns[
      this.yearlyPattern ? "yearly" : "hourly"
    ].map((data, i) => (data + this.pattern.normalizedPattern[i]) / 2);
    this.saveConsumptionProfile([this.selectedConsumptionProfile]);
  }

  // Asignación de perfil a contadores seleccionados
  assignProfileToMeters(): void {
    let meterPatterns = this.selectedMeters.map((meter) => {
      let meterProfile = this.meterProfiles.find(
        (meterProfile) => meterProfile.meterId == meter.id
      );
      if (meterProfile) {
        meterProfile.profileId = this.selectedConsumptionProfile.profileId;
        meterProfile.meterPattern = this.pattern.consumptionPattern;
        meterProfile.profileSimilarity = this.meterSimilarity;
        return meterProfile;
      } else {
        return {
          profileId: this.selectedConsumptionProfile.profileId,
          meterId: meter.id,
          meterPattern: this.pattern.consumptionPattern,
          profileSimilarity: this.meterSimilarity,
        };
      }
    });
    this.saveConsumptionProfile(meterPatterns);
  }

  // Cálculo de similaridad por perfiles
  checkSimilarities(): void {
    this.consumptionProfileList.forEach((profile, i) => {
      if (
        (this.yearlyPattern && profile.profilePatterns?.yearly) ||
        (!this.yearlyPattern && profile.profilePatterns?.hourly)
      ) {
        profile.meterSimilarity = this.MachineLearningService.checkSimilarity(
          profile.profilePatterns[this.yearlyPattern ? "yearly" : "hourly"],
          this.pattern.patternSerie
        );
        profile.profileName =
          this.translate.instant("consumptionProfile" + (i == 8 ? i + 1 : i)) +
          " (" +
          profile.meterSimilarity +
          ")";
      }
    });
    this.consumptionProfileList = [
      ...this.consumptionProfileList.sort(
        (a, b) => b.meterSimilarity - a.meterSimilarity
      ),
    ];
    this.selectedConsumptionProfile = this.consumptionProfileList[0];
    this.preselectedConsumptionProfile =
      this.selectedConsumptionProfile.profileId;
  }

  // Patrón semanal/anual
  updatePatternType(): void {
    this.defaultDateRange = this.yearlyPattern
      ? this.DateParserService.getLastNaturalYears("2")
      : this.DateParserService.getLastNaturalWeeks("8");
    this.meterConsumptionGraph.updateSelectorDateRange(this.defaultDateRange);
  }

  // Predicción de consumo
  makePrediction(): void {
    let weekDay = moment().isoWeekday();
    let series = this.graphData.map((meter) => meter.readings);
    this.predictions = series.map((serie) => {
      let readings = serie.map((data) => data[1]);
      let means = this.MachineLearningService.getHourlyMean(readings);
      let sds = this.MachineLearningService.getHourlySd(readings);
      let weekPredict =
        this.selectedConsumptionProfile.profilePatterns.hourly.map((data, i) =>
          data * sds[i] + means[i] > 0 ? data * sds[i] + means[i] : 0
        );
      weekPredict = [
        ...weekPredict.slice((weekDay - 1) * 24),
        ...weekPredict.slice(0, (weekDay - 1) * 24),
      ];
      return [...weekPredict, ...weekPredict, ...weekPredict, ...weekPredict];
    });
  }
}
