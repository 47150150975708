<!-- Modal de edición de contador -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ (data.onlySF ? "sf-update" : "sf-adr-update") | translate }}
    </h5>
  </div>

  <div class="dialog-body">
    <div class="device-internal-sf-update">
      <!-- SF -->
      <mat-form-field
        *ngIf="(data.device && !data.onlySF) || (data.devices && data.onlySF)"
        appearance="standard"
      >
        <mat-label> SF </mat-label>
        <input
          matInput
          type="number"
          max-length="2"
          min="6"
          max="12"
          step="1"
          [(ngModel)]="data.deviceSf"
        />
      </mat-form-field>
      <!-- SF min -->
      <mat-form-field appearance="standard" *ngIf="!data.onlySF">
        <mat-label> SF min </mat-label>
        <input
          matInput
          type="number"
          max-length="2"
          min="6"
          max="12"
          step="1"
          [(ngModel)]="data.deviceSfMin"
        />
      </mat-form-field>
      <!-- Tx power -->
      <mat-form-field appearance="standard" *ngIf="!data.onlySF">
        <mat-label> {{ "max-power" | translate }} </mat-label>
        <input
          matInput
          type="number"
          max-length="2"
          [min]="
            data.device?.flags == '1.0.1' || data.device?.flags == '1.0.2' ? 1 : 0
          "
          max="12"
          step="1"
          [(ngModel)]="data.deviceTxPower"
        />
      </mat-form-field>
      <!-- ADR -->
      <mat-checkbox
        *ngIf="!data.onlySF"
        class="dialog-checkbox"
        [color]="'primary'"
        [(ngModel)]="data.deviceAdr"
        >ADR</mat-checkbox
      >
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      (click)="
        data.device
          ? updateSfAdr()
          : data.onlySF
          ? updateAllSf()
          : updateAllSfAdr()
      "
      class="mat-button-icon-text"
      [disabled]="
        (data.onlySF &&
          (data.deviceSf == null || data.deviceSf < 6 || data.deviceSf > 12)) ||
        (!data.onlySF &&
          ((data.device &&
            (data.deviceSf == null ||
              data.deviceSf < 6 ||
              data.deviceSf > 12)) ||
            data.deviceSfMin == null ||
            data.deviceSfMin < 6 ||
            data.deviceSfMin > 12 ||
            data.deviceTxPower == null ||
            data.deviceTxPower < 0 ||
            data.deviceTxPower > 12))
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
