<div
  *ngIf="sateliteScheduler; else elseBlock"
  class="panel panel-default satelite-scheduler-panel"
>
  <div class="panel-heading satelite-scheduler-heading">
    <app-panel-back class="panel-back-link"></app-panel-back>

    <div class="satelite-scheduler-tabs">
      <div
        *ngFor="let scheduler of sateliteScheduler; index as i"
        class="satelite-schedule-tab pointer"
        [ngClass]="{ 'satelite-schedule-tab-selected': currentScheduler == i }"
        (click)="updateCurrentScheduler(i)"
      >
        {{ "satelite-schedule" | translate }} {{ i + 1 }}
      </div>
      <div
        *ngIf="sateliteScheduler.length < 2"
        class="satelite-schedule-tab-plus pointer"
        title="{{ 'new' | translate }}"
        (click)="addScheduler()"
        [ngClass]="{ disabled: sateliteScheduler.length == 2 }"
      >
        <i class="fas fa-plus"></i>
      </div>
    </div>

    <!-- Botón actualizar -->
    <app-material-panel-menu
      [onlyButton]="true"
      [icon]="saveIcon"
      [title]="saveTitle"
      [disabled]="
        sateliteScheduler?.length == 0 ||
        schedulerDaysEmpty ||
        schedulerIntervalEmpty
      "
      (action)="saveData()"
    >
    </app-material-panel-menu>
  </div>

  <div class="panel-body">
    <div *ngIf="sateliteScheduler[currentScheduler]" class="satelite-scheduler">
      <!-- Controles de actividad -->
      <div class="satelite-scheduler-active">
        <div class="satelite-scheduler-active-titles">
          <div>
            <!-- Toggle de activación -->
            <app-slider-button
              [status]="sateliteScheduler[currentScheduler].active.enabled"
              [width]="activeSliderWidth"
              [height]="activeSliderHeight"
              [round]="true"
              (statusUpdate)="
                sateliteScheduler[currentScheduler].active.enabled = $event
              "
            ></app-slider-button>
          </div>
          <div
            [ngClass]="{
              'satelite-scheduler-interval-selected':
                sateliteScheduler[currentScheduler].interval.selected != 'days',
              'satelite-scheduler-interval-disabled':
                sateliteScheduler[currentScheduler].interval.selected == 'days'
            }"
            (click)="
              sateliteScheduler[currentScheduler].interval.selected =
                'interval';
              schedulerDaysEmpty = false;
              checkInterval()
            "
          >
            {{ "interval" | translate }}
          </div>
          <div
            [ngClass]="{
              'satelite-scheduler-interval-selected':
                sateliteScheduler[currentScheduler].interval.selected == 'days',
              'satelite-scheduler-interval-disabled':
                sateliteScheduler[currentScheduler].interval.selected != 'days'
            }"
            (click)="
              sateliteScheduler[currentScheduler].interval.selected = 'days';
              schedulerIntervalEmpty = false;
              checkDays()
            "
          >
            {{ "days" | translate }}
          </div>
          <div class="satelite-schedule-change">
            {{ "satelite-schedule-change" | translate }}
          </div>
        </div>
        <div class="satelite-scheduler-active-controls">
          <!-- Activación -->
          <div class="satelite-scheduler-active-date">
            <!-- Selector de rango de fechas -->
            <input
              type="text"
              class="satelite-scheduler-date-selector"
              name="dateRangeSelected"
              placeholder="{{ 'date-range' | translate }}..."
              ngxDaterangepickerMd
              [locale]="daterangePickerLang"
              [(ngModel)]="sateliteScheduler[currentScheduler].active.date"
              [ranges]="daterangePickerRanges"
              [showCustomRangeLabel]="true"
              [alwaysShowCalendars]="true"
              [maxDate]="maxDate"
              [closeOnAutoApply]="true"
              [autoApply]="true"
              appDateRangeInput
              (dateRangeUpdated)="dateRangeSelected = $event"
            />
          </div>

          <!-- Selector de intervalo -->

          <!-- Intervalo numérico -->
          <div
            class="satelite-scheduler-interval"
            [ngClass]="{
              disabled:
                sateliteScheduler[currentScheduler].interval.selected == 'days'
            }"
          >
            <!-- Selector numérico -->
            <div>
              <input
                type="number"
                min="0"
                [(ngModel)]="
                  sateliteScheduler[currentScheduler].interval.byInterval
                "
                (change)="checkIntervalEmpty()"
              />
            </div>
          </div>
          <!-- Intervalo por días -->
          <div class="satelite-scheduler-days">
            <!-- Selector de días -->
            <div
              class="satelite-scheduler-days-selector"
              [ngClass]="{
                disabled:
                  sateliteScheduler[currentScheduler].interval.selected !=
                  'days'
              }"
            >
              <span
                *ngFor="let day of week; index as i"
                [ngClass]="{
                  'satelite-scheduler-day-selected':
                    sateliteScheduler[currentScheduler]?.interval?.byDay &&
                    sateliteScheduler[currentScheduler]?.interval?.byDay[i] ==
                      1,
                  'satelite-scheduler-days-disabled':
                    !sateliteScheduler[currentScheduler]?.interval?.byDay ||
                    !sateliteScheduler[currentScheduler]?.interval?.byDay[i] ==
                      1
                }"
                (click)="
                  sateliteScheduler[currentScheduler]?.interval?.byDay[i] =
                    sateliteScheduler[currentScheduler]?.interval?.byDay[i] == 0
                      ? 1
                      : 0;
                  checkDaysEmpty()
                "
                >{{ day | translate }}
              </span>
            </div>
          </div>
          <!-- Cambio de horario Verano Invierno -->
          <div class="satelite-scheduler-schedule-change">
            <!-- Selector de días -->
            <div
              class="pointer"
              [ngClass]="{
                'satelite-scheduler-schedule-change-selected':
                  sateliteScheduler[currentScheduler].opcionCambioVI == 0,
                'satelite-scheduler-schedule-change-no-selected':
                  sateliteScheduler[currentScheduler].opcionCambioVI == 1
              }"
              (click)="sateliteScheduler[currentScheduler].opcionCambioVI = 0"
            >
              {{ "satelite-schedule-change-hour" | translate }}
            </div>
            <div
              class="pointer"
              [ngClass]="{
                'satelite-scheduler-schedule-change-selected':
                  sateliteScheduler[currentScheduler].opcionCambioVI == 1,
                'satelite-scheduler-schedule-change-no-selected':
                  sateliteScheduler[currentScheduler].opcionCambioVI == 0
              }"
              (click)="sateliteScheduler[currentScheduler].opcionCambioVI = 1"
            >
              {{ "satelite-schedule-change-turn" | translate }}
            </div>
          </div>
        </div>
      </div>

      <!-- Turnos -->
      <div class="satelite-scheduler-controls" (resized)="drawTurns()">
        <!-- Turn inputs -->
        <div class="satelite-schedule-turns-inputs">
          <ng-container
            *ngFor="let item of [].constructor(maxTurns); index as i"
          >
            <!-- Turno -->
            <div
              *ngIf="sateliteScheduler[currentScheduler].turns[i]"
              class="satelite-schedule-turns-input"
            >
              <!-- Título -->
              <label
                for="{{ 'turn' + (i + 1) }}"
                [ngClass]="'turn-color-' + (i + 1)"
                >{{ ("turn" | translate) + " " + (i + 1) }}
                <div class="satelite-schedule-turns-controls">
                  <i
                    class="fas fa-undo-alt pointer"
                    (click)="undoTurnChanges(i)"
                    title="{{ 'undo' | translate }}"
                  ></i>
                  <i
                    class="fas fa-trash pointer"
                    (click)="
                      deleteTurn(sateliteScheduler[currentScheduler].turns, i)
                    "
                    title="{{ 'delete' | translate }}"
                  ></i>
                </div>
              </label>
              <!-- Hora de comienzo -->
              <div class="satelite-schedule-time">
                <div class="satelite-schedule-time-clock">
                  <input
                    type="time"
                    id="{{ 'turnStart' + (i + 1) }}"
                    name="{{ 'turnStart' + (i + 1) }}"
                    [(ngModel)]="
                      sateliteScheduler[currentScheduler].turns[i].date
                        .startTime
                    "
                    (change)="
                      inputUpdatedTimeout(
                        true,
                        sateliteScheduler[currentScheduler].turns[i],
                        i
                      )
                    "
                  />
                  <app-satelite-turns-clock
                    [time]="
                      sateliteScheduler[currentScheduler].turns[i].date
                        .startTime
                    "
                    (timeUpdate)="
                      updateTurnTime(
                        true,
                        sateliteScheduler[currentScheduler].turns[i],
                        i,
                        $event.value,
                        $event.speed
                      )
                    "
                  ></app-satelite-turns-clock>
                </div>
                <!-- Hora de fin -->
                <div class="satelite-schedule-time-clock">
                  <input
                    type="time"
                    id="{{ 'turnEnd' + (i + 1) }}"
                    name="{{ 'turnEnd' + (i + 1) }}"
                    [(ngModel)]="
                      sateliteScheduler[currentScheduler].turns[i].date.endTime
                    "
                    (change)="
                      inputUpdatedTimeout(
                        false,
                        sateliteScheduler[currentScheduler].turns[i],
                        i
                      )
                    "
                  />
                  <app-satelite-turns-clock
                    [time]="
                      sateliteScheduler[currentScheduler].turns[i].date.endTime
                    "
                    (timeUpdate)="
                      updateTurnTime(
                        false,
                        sateliteScheduler[currentScheduler].turns[i],
                        i,
                        $event.value,
                        $event.speed
                      )
                    "
                  ></app-satelite-turns-clock>
                </div>
              </div>
              <!-- Pulsos -->
              <div class="satelite-schedule-pulses">
                <i class="fas fa-wave-square"></i>
                <input
                  type="number"
                  id="{{ 'pulses' + (i + 1) }}"
                  name="{{ 'pulses' + (i + 1) }}"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler].turns[i].pulses
                  "
                  min="0"
                  placeholder="{{ 'pulses' | translate }}"
                  class="satelite-schedule-input-pulses"
                />
              </div>
            </div>
          </ng-container>

          <!-- Añadir turno -->
          <div class="satelite-schedule-add-turn">
            <button
              *ngIf="sateliteScheduler[currentScheduler].turns.length < 6"
              mat-button
              (click)="addTurn(sateliteScheduler[currentScheduler].turns)"
              [disabled]="
                sateliteScheduler[currentScheduler].turns.length != 0 &&
                (sateliteScheduler[currentScheduler].turns[
                  sateliteScheduler[currentScheduler].turns.length - 1
                ]?.date.startTime == null ||
                  sateliteScheduler[currentScheduler].turns[
                    sateliteScheduler[currentScheduler].turns.length - 1
                  ]?.date.endTime == null ||
                  sateliteScheduler[currentScheduler].turns[
                    sateliteScheduler[currentScheduler].turns.length - 1
                  ]?.date.endTime == '23:59')
              "
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>

        <!-- Offset -->
        <div class="scheduler-offset">
          <button
            mat-button
            class="mat-button-icon-text"
            (click)="showOffset()"
            [disabled]="sateliteScheduler[currentScheduler].turns.length == 0"
          >
            <i class="fas fa-history"></i>
            {{ "Offset" | translate }}
          </button>
        </div>

        <!-- Barra de tiempos -->
        <div
          #scheduleTurns
          class="satelite-schedule-turns"
          (mousemove)="editTurn($event)"
          (mouseup)="clearMousePosition($event)"
        >
          <!-- Turnos seleccionados -->
          <ng-container
            *ngFor="let item of [].constructor(maxTurns); index as i"
          >
            <!-- Turno -->
            <div
              *ngIf="sateliteScheduler[currentScheduler].turns[i]"
              class="satelite-schedule-turn-slider hidden"
              [ngClass]="'turn-box-color-' + (i + 1)"
              (mousedown)="setMousePosition($event, i)"
              id="{{ 'turn' + i }}"
            >
              <!-- Redimiensionado -->
              <div
                id="{{ 'resizeStartTurn' + i }}"
                class="schedule-turn-slider-left-resize"
                (mousedown)="setMousePosition($event, i)"
              ></div>
              <div
                id="{{ 'resizeEndTurn' + i }}"
                class="schedule-turn-slider-right-resize"
                (mousedown)="setMousePosition($event, i)"
              ></div>
              <!-- Hora de comienzo -->
              <div class="schedule-turn-slider-start">
                <input
                  type="time"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler].turns[i].date.startTime
                  "
                  (change)="
                    updateTurnTime(
                      true,
                      sateliteScheduler[currentScheduler].turns[i],
                      i
                    )
                  "
                />
              </div>
              <!-- Hora final -->
              <div class="schedule-turn-slider-end">
                <input
                  type="time"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler]?.turns[i].date.endTime
                  "
                  (change)="
                    updateTurnTime(
                      false,
                      sateliteScheduler[currentScheduler].turns[i],
                      i
                    )
                  "
                />
              </div>
              <!-- Pulsos -->
              <div class="schedule-turn-slider-pulses">
                <label for="sliderPulses">
                  <i class="fas fa-wave-square"></i>
                  <!-- {{ "pulses" | translate }} -->
                </label>
                <input
                  type="number"
                  min="0"
                  name="sliderPulses"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler].turns[i].pulses
                  "
                  (mousedown)="preventTurnMove = true"
                  (mouseleave)="preventTurnMove = false"
                />
              </div>
            </div>
          </ng-container>
          <!-- Turnos seleccionados bis -->
          <ng-container
            *ngFor="let item of [].constructor(maxTurns); index as i"
          >
            <!-- Turno secundario para salto de línea -->
            <div
              *ngIf="sateliteScheduler[currentScheduler].turns[i]"
              class="satelite-schedule-turn-slider satelite-schedule-turn-slider-second hidden"
              [ngClass]="'turn-box-color-' + (i + 1)"
              (mousedown)="setMousePosition($event, i)"
              id="{{ 'turnBis' + i }}"
            >
              <!-- Redimiensionado -->
              <div
                id="{{ 'resizeStartTurnBis' + i }}"
                class="schedule-turn-slider-left-resize"
                (mousedown)="setMousePosition($event, i)"
              ></div>
              <div
                id="{{ 'resizeStartTurnBis' + i }}"
                class="schedule-turn-slider-right-resize"
                (mousedown)="setMousePosition($event, i)"
              ></div>
              <!-- Hora final -->
              <div class="schedule-turn-slider-end">
                <input
                  type="time"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler]?.turns[i].date.endTime
                  "
                  (change)="
                    updateTurnTime(
                      true,
                      sateliteScheduler[currentScheduler].turns[i],
                      i
                    )
                  "
                />
              </div>
              <!-- Pulsos -->
              <div class="schedule-turn-slider-pulses">
                <label for="sliderPulses">{{ "pulses" | translate }}</label>
                <input
                  type="number"
                  min="0"
                  name="sliderPulses"
                  [(ngModel)]="
                    sateliteScheduler[currentScheduler].turns[i].pulses
                  "
                />
              </div>
            </div>
          </ng-container>

          <!-- Turnos de otro programa -->
          <ng-container
            *ngFor="let item of [].constructor(maxTurns); index as i"
          >
            <!-- Turno -->
            <div
              *ngIf="sateliteScheduler[otherScheduler]?.turns[i]"
              class="satelite-schedule-turn-slider satelite-schedule-turn-slider-other"
              [ngClass]="'turn-box-color-other'"
              id="{{ 'otherTurn' + i }}"
            ></div>
          </ng-container>

          <!-- Contenedores de horas -->
          <div
            *ngFor="let item of [].constructor(maxHour); index as i"
            class="satelite-schedule-turn-hour"
          >
            <span class="schedule-hour-full">{{
              i < 10
                ? "0" + i + ":00"
                : i < 24
                ? i + ":00"
                : i % 24 < 10
                ? "0" + (i % 24) + ":00"
                : (i % 24) + ":00"
            }}</span>
            <span class="schedule-hour-short">{{
              i < 10
                ? "0" + i
                : i < 24
                ? i
                : i % 24 < 10
                ? "0" + (i % 24)
                : i % 24
            }}</span>
            <!-- Barras de minutos -->
            <div
              *ngFor="let item of [].constructor(60); index as j"
              class="vertical-bar"
              [ngClass]="{ 'vertical-line': j == 0 || j % 15 == 0 }"
              id="{{ i < 24 ? 't' : 'o' }}{{
                i < 10
                  ? '0' + i
                  : i < 24
                  ? i
                  : i % 24 < 10
                  ? '0' + (i % 24)
                  : i % 24
              }}{{ j < 10 ? '0' + j : j }}"
              title="{{
                i < 10
                  ? '0' + i
                  : i < 24
                  ? i
                  : i % 24 < 10
                  ? '0' + (i % 24)
                  : i % 24
              }}{{ j < 10 ? ':0' + j : ':' + j }}"
            ></div>
          </div>
          <div
            *ngIf="sateliteScheduler.length > 1"
            class="satelite-other-scheduler"
          >
            <i class="fas fa-square-full"></i>
            <span
              >{{ "satelite-schedule" | translate }}
              {{ currentScheduler == 0 ? 2 : 1 }}</span
            >
          </div>
        </div>

        <div class="satelite-schedule-totals">
          <span>
            <b>{{ "satelite-time-open" | translate }}:</b> {{ timeOpen }}
          </span>
          <span>
            <b>{{ "satelite-total-flow" | translate }}:</b> {{ flow }}
          </span>
        </div>
      </div>
    </div>

    <!-- Sin programas -->
    <div
      *ngIf="sateliteScheduler?.length == 0"
      class="satelite-scheduler-empty"
    >
      <i class="fas fa-exclamation-triangle"></i>
      {{ "satelite-scheduler-empty" | translate }}
      <i class="fas fa-exclamation-triangle"></i>
    </div>
  </div>
</div>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>
