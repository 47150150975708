<section class="content-header">
  <div class="container-fluid">
    <!-- Panel Estado de los gateways -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "gateways-management-all" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [options]="panelMenuOptions"
          (action)="menuAction($event)"
        ></app-material-panel-menu>
      </div>
      <div class="panel-body management-panel">
        <!-- Tabla de gateways -->
        <div *ngIf="mapGateways; else elseBlock">
          <app-table-controller
            id="management-global-table"
            [tableId]="'managementGlobal'"
            [specialFilter]="true"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [maxReg]="tableMaxReg"
            [highlightRow]="tableHighlightRow"
            (tableAction)="tableActions($event.action.name, $event.element)"
            [tableGlobalActions]="tableGlobalActions"
            (tableGlobalAction)="tableGlobalAction($event.title)"
            [quickFilters]="quickFilters"
            [quickFiltersExclusion]="quickFiltersExclusion"
            (selectedDataUpdateFlag)="tableSelectedData = $event"
            [data]="mapGateways"
          >
          </app-table-controller>

          <!-- Mapa de gateways -->
          <!-- <div id="map-panel"> -->
            <!-- <app-map-controller
              [mapType]="mapType"
              [mapHeight]="mapHeight"
              [boundsWithGateways]="true"
              [gatewaysData]="mapGateways"
              [metersData]="[]"
            >
            </app-map-controller> -->
          <!-- </div> -->
          <div class="gateway-list-map">
            <app-map-selector
            *ngIf="mapSelectorActive"
              id="gateway-list-map"
              [dataList]="mapGateways"
              [originalDataList]="gatewayList"
              [tableSelectedData]="tableSelectedData"
              [table]="tableController"
              [filterAttribute]="'unidadVenta'"
              [anchorToScroll]="'table-panel'"
              (updateData)="mapGateways = $event;"
            >
            </app-map-selector>
        </div>
         <!-- Gráfica -->
         <div id="load-graph">
          <app-graph-controller
            *ngIf="highchartsOptions"
            id="gateways-assigned-graph"
            [highchartsOptions]="highchartsOptions"
            [data]="chartOptions"
            [chartConstructor]="chartConstructor"
            [columns]="true"
            [dateRange]="defaultDateRange"
            (dataRequest)="loadGraphData($event.from, $event.to)"
            [colorByPoint]="colorByPoint"
            [seriesToggle]="seriesToggle"
            (serieToggled)="sateliteShowVcc = $event[0].visible; getSeries()"
          >
          </app-graph-controller>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
