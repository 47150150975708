<!-- Modal de edición de contador -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "edit" | translate }}</h5>
  </div>
  <div class="dialog-body device-detail-modal">
    <!-- Número de serie -->
    <mat-form-field appearance="standard">
      <mat-label>
        <span>{{ "serial-number" | translate }}</span>
      </mat-label>
      <input
        matInput
        [(ngModel)]="deviceSerialNumber"
        placeholder="{{ 'serial-number' | translate }}..."
        required
      />
    </mat-form-field>
    <!-- Rango del sensor -->
    <mat-form-field
      appearance="standard"
      *ngIf="
        this.data.hasAs ||
        this.data.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
      "
    >
      <mat-label>
        <span>{{ "sensor-range" | translate }}</span> (m)
      </mat-label>
      <input
        matInput
        [(ngModel)]="deviceSensorRange"
        placeholder="{{ 'sensor-range' | translate }}..."
      />
    </mat-form-field>
    <!-- Precinto -->
    <mat-form-field appearance="standard">
      <mat-label>
        <span>{{ "seal" | translate }}</span>
      </mat-label>
      <input
        matInput
        [(ngModel)]="deviceSeal"
        placeholder="{{ 'seal' | translate }}..."
      />
    </mat-form-field>
    <!-- Comentarios -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea
        matInput
        [(ngModel)]="deviceComments"
        placeholder="{{ 'comments' | translate }}..."
      ></textarea>
    </mat-form-field>
    <!-- Imagen de contador -->
    <span class="btn btn-block btn-default btn-file">
      {{ fileName != null ? fileName : ("image-search" | translate) }}
      <input
        type="file"
        id="file"
        (change)="fileChangeEvent($event)"
        accept="image/x-png,image/jpeg,image/ico"
      />
    </span>
    <img
      *ngIf="finalImage"
      [src]="finalImage"
      class="img-responsive img-rounded logo"
    />
  </div>
  <div class="dialog-footer">
    <!-- Quitar imagen -->
    <button
      *ngIf="finalImage"
      mat-button
      class="mat-button-icon-text"
      (click)="removeIcon()"
    >
      <i class="fas fa-images"></i>
      {{ "remove-image" | translate }}
    </button>
    <!-- Salvar cambios -->
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="deviceSerialNumber == null"
      (click)="editSerialNumber()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
