<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5
          *ngIf="
            sessionProfile != PROFILES.ARSON &&
            sessionProfile != PROFILES.ADMIN_CLIENTE &&
            sessionProfile != PROFILES.ADMIN_ENTIDAD
          "
        >
          <b>{{ "meter-orders" | translate }}</b>
        </h5>

        <!-- Selector de Búsqueda Global/Agrupación -->
        <div
          *ngIf="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD
          "
          class="meter-orders-select"
        >
          <app-material-select
            class="material-header-select"
            [title]="'meter-orders' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="'agrupation'"
            [data]="orderSelect"
            (selectedOption)="searchBy = $event?.value; setColumns(); getData(from, to)"
          ></app-material-select>
        </div>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'meterOrders'"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          (dataRequest)="getData($event.from, $event.to)"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          (selectedDataUpdateFlag)="
            tableSelectedData = $event; MapController.updateSelected()
          "
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="tableData"
        >
        </app-table-controller>

        <!-- Mapa -->
        <app-map-controller
          [mapType]="mapType"
          [mapHeight]="mapHeight"
          [gatewaysData]="[]"
          [metersData]="tableData"
        >
        </app-map-controller>
      </div>
    </div>
  </div>
</section>
