// @angular
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
// Interfaces
import { DeviceCheckData } from "../../../../DeviceInterface.type";

@Component({
  selector: "app-meter-review-dialog",
  templateUrl: "./meter-review-dialog.component.html",
  styleUrls: ["./meter-review-dialog.component.scss"],
})
export class MeterReviewDialogComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  comments: string;
  state: number;
  stateList: { id: number; name: string }[] = [
    {
      id: 0,
      name: this.translate.instant("opened"),
    },
    {
      id: 1,
      name: this.translate.instant("in-process"),
    },
    {
      id: 2,
      name: this.translate.instant("closed"),
    },
  ];
  @ViewChild("commentsArea") commentsArea: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.comments =
      (this.data.revisionComments
        ? this.data.revisionComments + "\n\n"
        : this.data.comments
        ? this.data.comments + "\n\n"
        : "") +
      this.DateParserService.getDate("L HH:mm") +
      " (" +
      this.SessionDataService.getCurrentUser() +
      "): " +
      "\n";
    this.state =
      this.data.state != null
        ? this.data.state
        : this.data.revisionState != null
        ? this.data.revisionState
        : 0;
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(() => this.commentsArea.nativeElement.focus(), 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Edición de la revisión del contador
  editReview(): void {
    let data: DeviceCheckData = {
      contador: this.data.meterId ? this.data.meterId : this.data.id,
      comments: this.comments,
      state: this.state,
    };
    this.MeterController.moveToCheck(data).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
      }
    });
  }
}
