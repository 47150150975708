// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import { Ek280Config } from "../../screens/dashboard/devices/DeviceInterface.type";

@Injectable({
  providedIn: "root",
})
export class EkConfigurationControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Lista de ICCID disponibles
  getSimList(): Observable<object> {
    return this.HttpRequestService.get("/extws/sim/list/selector");
  }

  // Asigna una sim a un ek280
  saveEkSim(meterId: number, simId: number): Observable<object> {
    return this.HttpRequestService.post(
      "/extws/assign/sim/" + simId + "/contador/" + meterId,
      null
    );
  }

  // Obetener datos de un EK280
  getEkConfigData(meterId: number): Observable<object> {
    return this.HttpRequestService.get("/extws/contador/" + meterId + "/ek");
  }

  // Actualizar un ek280
  saveEkConfigData(meterId: number, data: Ek280Config): Observable<object> {
    return this.HttpRequestService.post(
      "/extws/contador/" + meterId + "/update/ek",
      data
    );
  }

  // Consulta EK280
  ekTest(meterId: number) {
    return this.HttpRequestService.get(
      "/extws/contador/" + meterId + "/test-ek"
    );
  }

  // Borrado de SIM
  unassignSim(meterId: number) {
    return this.HttpRequestService.get(
      "/extws/unassign/sim/contador/" + meterId
    );
  }

  // Borrado de EK en servidor de EK
  deleteInEkServer(meterId: number) {
    return this.HttpRequestService.get(
      "/extws/contador/" + meterId + "/delete/ek"
    );
  }

  // Borrado de múltiples EK en servidor de EK
  deleteMultipleInEkServer(data: number[]) {
    return this.HttpRequestService.post("/extws/contador/delete/ek-list", data);
  }
}
