<div *ngIf="customAlarmsList" class="panel-body">
  <!-- Lista de alarmas -->
  <div *ngFor="let alarm of alarmConfig; index as i">
    <div class="alarm-config-row">
      <div class="alarm-config-checkbox">
        <input
          class="alarm-config-input"
          type="checkbox"
          [(ngModel)]="alarm.active"
          (click)="alarm.subscriber = alarm.value"
        />
      </div>
      <div
        class="alarm-config-text"
        (click)="alarm.active = !alarm.active; alarm.subscriber = alarm.value"
      >
        {{ "customAlarm" + alarm.type | translate }}
      </div>
      <div class="alarm-config-input">
        <input
          *ngIf="alarm.type < 5"
          type="number"
          [(ngModel)]="alarm.value"
          [disabled]="!alarm.active"
        /><span style="margin-left: 10px">{{
          i == 0 ? ("days" | translate) : i < 5 ? "m³" : ""
        }}</span>
      </div>
    </div>
  </div>

  <!-- Botones -->
  <div class="alarms-custom-buttons">
    <div>
      <button mat-button class="mat-button-icon-text" (click)="saveAlarms()">
        <i class="fas fa-save"></i>{{ "save" | translate }}
      </button>
    </div>
  </div>
</div>
