// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  GlobalSearchRequestData,
  Maintenance,
} from "../../screens/dashboard/management/ManagementInterface.type";
import { SupplyNetworkData } from "../../screens/dashboard/management/supply-network-constructor/supply-network-interface";

@Injectable({
  providedIn: "root",
})
export class ManagementControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  /***************************************************************************/
  // ANCHOR Gateway
  /***************************************************************************/

  // Listado de Gateways para la pantalla de mantenimiento global
  getAllGateways(): Observable<object> {
    return this.HttpRequestService.get("/gateway/list/maintenance/all");
  }

  // Creación de histórico de gateway
  save(data: Maintenance): Observable<object> {
    return this.HttpRequestService.post("/gw-historical/save/", data);
  }

  // Actualización de histórico de gateway
  update(data: Maintenance): Observable<object> {
    return this.HttpRequestService.post("/gw-historical/update", data);
  }

  // Listado de Gateways para la pantalla de mantenimiento por agrupación
  getGatewaysState(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/list/maintenance/" + agrupationId
    );
  }

  // Búsqueda de mantenimiento por agrupación
  getGatewaysMaintenance(
    agrupationId: number,
    group: number,
    from: string,
    to: string
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-historical/findbyagrupation/" +
        agrupationId +
        "/group/" +
        (group != null ? group : -1) +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Listado de Gateways para la pantalla de mantenimiento por entidad
  getEntityGatewaysState(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gateway/list/maintenance/entity/" + entityId
    );
  }

  // Búsqueda de mantenimiento por entidad
  getEntityGatewaysMaintenance(
    entityId: number,
    group: number,
    from: string,
    to: string
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-historical/findbyentity/" +
        entityId +
        "/group/" +
        (group != null ? group : -1) +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Búsqueda de mantenimiento por id
  getGatewayMaintenance(
    gatewayId: number,
    group: number,
    from: string,
    to: string
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/gw-historical/find/" +
        gatewayId +
        "/group/" +
        (group != null ? group : -1) +
        "/from/" +
        from +
        "/to/" +
        to
    );
  }

  // Limpieza de tablas de tramas en base de datos
  cleanTables(timestamp: string): Observable<object> {
    return this.HttpRequestService.get("/tramas/limpieza/" + timestamp);
  }

  // Cola de comandos de gateway
  getCommandsTail(): Observable<object> {
    return this.HttpRequestService.get("/gatewayTail/list");
  }

  // Borrar comando
  deleteCommands(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/gatewayTail/delete-commands", data);
  }

  // Relanzar cola
  relaunchTail(gatewayId: number): Observable<object> {
    return this.HttpRequestService.get("/gatewayTail/launch/" + gatewayId);
  }

  /***************************************************************************/
  // ANCHOR Búsqueda global
  /***************************************************************************/

  // Listado global de contadores
  searchDevice(data: GlobalSearchRequestData): Observable<object> {
    return this.HttpRequestService.post("/contador/listado/global", data);
  }

  // Listar por fecha de activacion
  searchDeviceByDate(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/listado/fechaActivacion/" + from + "/to/" + to
    );
  }

  // Listar usuarios
  getUsers(): Observable<object> {
    return this.HttpRequestService.get("/user/global-list");
  }

  // Listar usuarios
  getUsersEvents(
    eventCode: number,
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/user-events/by-code/" + eventCode + "/from/" + from + "/to/" + to
    );
  }

  // Guardar idiomas
  saveLanguages(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/save-file/front/i18n", data);
  }

  // Guardar fabricantes
  saveManufacturers(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/save-file/front/manufacturers", data);
  }

  /***************************************************************************/
  // ANCHOR Red
  /***************************************************************************/

  // Cambio al gateway con mejor señal
  best(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gestion-red/best-signal/" + agrupationId
    );
  }

  // Cambio al gateway con mejor señal
  selectBest(data: number[], queue?: boolean): Observable<object> {
    return this.HttpRequestService.post(
      "/gestion-red/best-signal" + (queue ? "/queue" : ""),
      data
    );
  }

  // Borrado y reenvío de los puntos finales de un gateway
  refreshConfiguration(
    gatewayId: number,
    data: number[],
    queue?: boolean
  ): Observable<object> {
    return this.HttpRequestService.post(
      "/gestionpuntos/refrescarpuntofinal/" +
        gatewayId +
        (queue ? "/queue" : ""),
      data
    );
  }

  // Limpieza de gateways redundantes
  cleanRedundants(data: number[], queue?: boolean): Observable<object> {
    return this.HttpRequestService.post(
      "/gestion-red/clear-redundant-list" + (queue ? "/queue" : ""),
      data
    );
  }

  // Reporte de comunicaciones
  getCommunicationReport(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/get-meters-by-minute/from/" + from + "/to/" + to
    );
  }

  // Reporte de comunicaciones por fabricante
  getCommunicationReportByManufacturer(
    from: string | number,
    to: string | number
  ): Observable<object> {
    return this.HttpRequestService.get(
      "/contador-frames/get-meters-by-fabricante/from/" + from + "/to/" + to
    );
  }

  // Asignación de mejor gateway y limpieza de redundantes por dispositivo
  assignBestAndClearRedundantByDevice(
    data: number[],
    simulate?: boolean
  ): Observable<object> {
    if (simulate) {
      return this.HttpRequestService.post(
        "/gestion-red/simulation-best-main-and-clear-redundant",
        data
      );
    } else {
      return this.HttpRequestService.post(
        "/gestion-red/assign-best-and-clear-redundant",
        data
      );
    }
  }

  // Asignación de mejor gateway y limpieza de redundantes por entidad
  assignBestAndClearRedundantByEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/gestion-red/assign-best-and-clear-redundant/entity/" + entityId
    );
  }

  // Listado con todos los contadores de la entidad activos y con agrupacion asignada
  getClearEntityDeviceList(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/list-for-clear/entity/" + entityId
    );
  }

  // Listado con todos los contadores de la entidad activos y con agrupacion asignada
  getClearAgrupationDeviceList(agrupationId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/contador/list-for-clear/agrupation/" + agrupationId
    );
  }

  // Listado con los gateways asignados en un contador
  getDeviceGatewayList(deviceId: number): Observable<object> {
    return this.HttpRequestService.get("/contador/gateway-list/" + deviceId);
  }

  // Guardado de tarea de limpieza de gateways
  saveClearAssignGatewayTask(data: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/quartz/clear-assigned-gateways/save",
      data
    );
  }

  // Borrado de la tarea de limpieza de gateways
  deleteClearAssignGatewayTask(): Observable<object> {
    return this.HttpRequestService.get(
      "/quartz/clear-assigned-gateways/delete"
    );
  }

  // Obtención de la tarea de limpieza de gateways
  getClearAssignGatewayTask(): Observable<object> {
    return this.HttpRequestService.get(
      "/quartz/clear-assigned-gateways/get-datamap"
    );
  }

  /***************************************************************************/
  // ANCHOR Concentradores
  /***************************************************************************/

  // Listado de concentradores para la pantalla de mantenimiento global
  getAllConcentrators(): Observable<object> {
    return this.HttpRequestService.get("/lorawanMbus/concentrador/list-all");
  }

  // Listado de concentradores por entidad
  getConcentratorsByEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/lorawanMbus/concentrador/entidad/" + entityId
    );
  }

  /***************************************************************************/
  // ANCHOR Network constructor
  /***************************************************************************/

  // Redes construidas por entidad
  getSupplyNetworkList(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/supplyNetworkData/" + entityId);
  }

  // Guardado de red construida
  saveSupplyNetwork(data: SupplyNetworkData): Observable<object> {
    return this.HttpRequestService.post("/supplyNetworkData/save", data);
  }

  // Actualización de red construida
  updateSupplyNetwork(data: SupplyNetworkData): Observable<object> {
    return this.HttpRequestService.post("/supplyNetworkData/update", data);
  }

  // Borrado de red construida
  deleteSupplyNetwork(networkId: string): Observable<object> {
    return this.HttpRequestService.delete(
      "/supplyNetworkData/delete/" + networkId
    );
  }
}
