// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { MbusManufacturer } from "../../DeviceInterface.type";
// Componentes
import { MbusManufacturersDialogComponent } from "../mbus-manufacturers/mbus-manufacturers-dialog/mbus-manufacturers-dialog.component";

@Component({
  selector: "app-mbus-candidates",
  templateUrl: "./mbus-candidates.component.html",
  styleUrls: ["./mbus-candidates.component.scss"],
})
export class MbusCandidatesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  dialog: Subscription;

  // Table
  tableMaxReg: number = 100;
  tableData: MbusManufacturer[];
  tableSelectedData: MbusManufacturer[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-eraser",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "manufacturer-id",
      data: "identificadorParsed",
      search: "identificadorParsed",
      sort: "identificadorParsed",
      visible: true,
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "erase-selection",
      icon: "fas fa-eraser",
      selectionRequired: true,
      help: "help-table-delete",
    },
  ];
  failedManufacturers: string[][];
  showResult: boolean = false;

  // Menú de opciones del componente
  newIcon: string = "fa fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getData();
        } else if (dialogAction.action == "save") {
          this.saveManufacturers([dialogAction.data]);
        }
      }
    );

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    let tableData = [];
    this.MeterController.getMbusCandidates(this.currentEntity.id).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          tableData = response["body"];
          tableData?.forEach((manufacturer: MbusManufacturer) => {
            let binaryId = (
              "0000000000000000" +
              parseInt(manufacturer.identificador, 16).toString(2)
            ).slice(-16);
            manufacturer.identificadorParsed =
              String.fromCharCode(64 + parseInt(binaryId.substring(1, 6), 2)) +
              String.fromCharCode(64 + parseInt(binaryId.substring(6, 11), 2)) +
              String.fromCharCode(64 + parseInt(binaryId.substring(11), 2)) +
              " (0x" +
              manufacturer.identificador +
              ")";
          });
        }
        this.tableData = tableData;
      }
    );
  }

  // Acciones de la tabla
  tableActions(action: string, mbusManufacturer: MbusManufacturer): void {
    switch (action) {
      case "delete":
        this.deleteCandidates([mbusManufacturer.id]);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "erase-selection":
        this.deleteCandidates(
          this.tableSelectedData.map(
            (manufacturer: MbusManufacturer) => manufacturer.id
          )
        );
        break;
      default:
        break;
    }
  }

  // Información del archivo de importación de gateways
  showInfo(): void {
    let html: string =
      `<table class="gateways-file-table">
                  <tr class="gateways-file-table-required">
                    <th>
                    codFabricante (*): 
                    </th>
                    <td>` +
      this.translate.instant("manufacturer-id") +
      `             </td>
                  </tr>
                </table>`;
    this.ToastService.fireAlertInfoText(
      "info",
      this.translate.instant("file-format"),
      html
    );
  }

  // Borrado de fabricante
  deleteCandidates(manufacturers: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("manufacturer-delete-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.deleteMbusCandidates({
          id: this.currentEntity.id,
          listaIds: manufacturers,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("deleted-success")
            );
            this.getData();
          }
        });
      }
    });
  }

  // Guardado de fabricante
  saveManufacturers(manufacturers: MbusManufacturer[]): void {
    this.MeterController.insertMbusManufacturers({
      entidad: this.currentEntity.id,
      lista: manufacturers,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.getData();
      }
    });
  }

  // Nuevo fabricante
  newCandidate(): void {
    this.MaterialDialogService.openDialog(MbusManufacturersDialogComponent, {
      identificador: null,
      clave: null,
      candidate: true,
    });
  }

  // Importación de archivo de fabricantes
  importCandidates(file: any): void {
    if (file?.length == 0) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.translate.instant("question-import")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.failedManufacturers = null;
          this.showResult = false;
          let formData: FormData = new FormData();
          formData.set("file", file);
          this.MeterController.insertMbusCandidatesByFile(
            this.currentEntity.id,
            formData
          ).subscribe((response) => {
            if (response["code"] == 0) {
              let errors = response["body"];
              if (errors?.length > 0) {
                this.ToastService.fireToast(
                  "warning",
                  this.translate.instant("file-import-with-errors")
                );
                this.failedManufacturers = errors.map((error) =>
                  error.split("-")
                );
              } else {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("success-import")
                );
              }
              this.getData();
            }
          });
        }
      });
    }
  }

  // Obtención del archivo de errores
  getErrorFile(): string {
    return this.failedManufacturers
      .map((data: any) => data.join(";"))
      .join("\r\n");
  }

  // Descarga de fichero de errores
  downloadErrorFile(): void {
    saveAs(
      new Blob([this.getErrorFile()], {
        type: "text/csv",
      })
    );
  }

  // Reintentar fallidos
  retry(): void {
    this.importCandidates(
      new File([this.getErrorFile()], "errors.csv", { type: "text/csv" })
    );
  }
}
