<div
  class="card"
  [ngClass]="{
    'card-hidden': !card.visibility || (orderSelection && card.dragSelection),
    'card-visible':
      (visibilitySelection && card.visibility) ||
      (orderSelection && !card.dragSelection)
  }"
  (click)="cardClickEvent(card.click)"
>
  <!-- ANCHOR Link -->
  <div
    *ngIf="card.link && card.linkActive && !card.editable"
    class="card-link"
    [attr.title]="card.title"
    [help]="card.help | translate"
    data-placement="bottom"
    (click)="openLink(card.link, card.state, false)"
    (contextmenu)="openLink(card.link, card.state, true, $event)"
  ></div>

  <!-- ANCHOR Información de la tarjeta -->
  <div
    #infoBox
    [class]="'info-box ' + card.background"
    [ngClass]="{ 'card-empty': card.type == 'empty' }"
  >
    <div [ngClass]="card.classes">
      <i [ngClass]="card.icon"></i>
    </div>
    <div
      class="info-box-content"
      [ngClass]="{
        'card-buttons-padding': card.buttons && card.infoBoxNumber,
        'info-box-content-fixed-height': cardHeightFixed
      }"
    >
      <div #cardDataBoxContainer class="info-box-data-container">
        <!-- ANCHOR Texto de tarjeta -->
        <div class="info-box-text">
          <i *ngIf="card.linkIcon && card.linkActive" class="fas fa-link"></i>
          {{ card.infoBoxText }}
        </div>
        <!--  ANCHOR Dato de tarjeta -->
        <div
          #cardDataBox
          id="info-box-number"
          [contentEditable]="card.editable"
          class="info-box-number"
        >
          <!-- Dato -->
          <i
            *ngIf="card.infoBoxNumber == null"
            class="fas fa-spinner fa-pulse card-spinner"
          ></i>
          {{ card.infoBoxNumber }}
          <!-- Dato extra -->
          <span *ngIf="card.infoBoxNumberExtra" class="info-box-number-extra">{{
            card.infoBoxNumberExtra
          }}</span>
          <!-- Lista de datos -->
          <div *ngIf="card.infoBoxList" class="info-box-list">
            <table>
              <tr
                *ngFor="let element of card.infoBoxList"
                [ngClass]="element.class ? element.class : null"
              >
                <th>{{ element.title }}</th>
                <td *ngIf="!element.link">{{ element.data }}</td>
                <td
                  *ngIf="element.link"
                  (contextmenu)="contextMenuLink(element.link, $event)"
                >
                  <a [href]="element.link"
                    >{{ element.data }}<i class="fas fa-link"></i>
                  </a>
                </td>
              </tr>
            </table>
          </div>
          <!-- ANCHOR Botones -->
          <div *ngIf="card.buttons" class="card-buttons">
            <ng-container *ngFor="let button of card.buttons">
              <button
                *ngIf="!button.hide"
                type="button"
                (click)="cardButton(button.action)"
                title="{{ button.title }}"
              >
                <i
                  *ngIf="button.icon"
                  class="{{ button.icon }}"
                  [ngClass]="{ 'card-button-text-icon': button.text }"
                ></i>
                <span
                  *ngIf="button.text"
                  [ngClass]="{ 'card-button-icon-text': button.icon }"
                  >{{ button.text }}</span
                >
              </button>
              <!-- <span class="card-button-separator"> | </span> -->
            </ng-container>
          </div>
          <!-- ANCHOR Gráfica -->
          <div
            *ngIf="card.graph"
            class="card-graph-container"
            [ngClass]="{
              'card-graph-container-full-height':
                !card.infoBoxNumber &&
                !card.infoBoxNumberExtra &&
                !card.infoBoxList &&
                !card.buttons
            }"
          >
            <app-graph-mini
              class="card-graph"
              [data]="card.graph"
              [width]="graphWidth"
            >
            </app-graph-mini>
          </div>
        </div>
      </div>
      <!-- ANCHOR Imagen de tarjeta -->
      <div *ngIf="card.img" class="info-box-img {{ card.img }}"></div>
    </div>
  </div>
</div>
