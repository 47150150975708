// @angular
import { Component, Input, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { ToastService } from "../../../../../../../services/shared/ToastService.service";
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
// Variables
import { METER_ORDER } from "../../../../DeviceInterface.type";

@Component({
  selector: "app-meter-new-order-dialog",
  templateUrl: "./meter-new-order-dialog.component.html",
  styleUrls: ["./meter-new-order-dialog.component.scss"],
})
export class MeterNewOrderDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  orderType: number;
  orderComments: string;
  orderTypeList: { id: number; value: string }[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private MeterController: MeterControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    let orderTypeList = [];
    let orders = Object.keys(METER_ORDER);
    for (let i = 0; i < orders.length / 2; i++) {
      if (
        parseInt(orders[i]) >= METER_ORDER.SUSTITUTION_DEVICE_COMPLETE ||
        (parseInt(orders[i]) < METER_ORDER.SUSTITUTION_DEVICE_COMPLETE &&
          this.data.rfModule)
      ) {
        orderTypeList.push({
          id: orders[i],
          value: this.translate.instant(METER_ORDER[orders[i]]),
        });
      }
    }
    this.orderTypeList = orderTypeList;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Petición
  saveOrder(): void {
    this.MeterController.saveOrder({
      meterList: [this.data.id],
      type: this.orderType,
      comments: this.orderComments,
    }).subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.SessionDataService.sendReloadPanelFlag();
        this.SessionDataService.sendDialogAction({ action: "close" });
      }
    });
  }
}
