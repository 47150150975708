<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <!-- Selector de Búsqueda Global/Entidad -->
        <div *ngIf="gatewayAlarms" class="management-alarms-control">
          <app-material-select
            class="material-header-select"
            [title]="'alarms' | translate"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="'global'"
            [data]="alarmSelect"
            (selectedOption)="searchBy = $event?.value; loadComponent()"
          ></app-material-select>
        </div>
      </div>

      <div class="panel-body">
        <div *ngIf="gatewayAlarms" class="management-alarms-filter">
          <span>{{ "filter-repeated" | translate }}:</span>
          <button
            mat-button
            class="mat-button-icon-text"
            [ngClass]="{ inactive: filterActive != 1 }"
            (click)="updateFilters(1, false)"
          >
            <div class="table-quick-filters-inactive-icon">
              <i *ngIf="filterActive != 1" class="fas fa-slash"></i>
              <i class="fas fa-filter"></i>
            </div>
            {{ "sales-unit" | translate }} - {{ "serial-number" | translate }}
          </button>
          <button
            mat-button
            class="mat-button-icon-text"
            [ngClass]="{ inactive: filterActive != 2 }"
            (click)="updateFilters(2, true)"
          >
            <div class="table-quick-filters-inactive-icon">
              <i *ngIf="filterActive != 2" class="fas fa-slash"></i>
              <i class="fas fa-filter"></i>
            </div>
            {{ "sales-unit" | translate }} - {{ "serial-number" | translate }} -
            {{ "code" | translate }}
          </button>
        </div>

        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'managementAlarms'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          [quickFilters]="quickFilters"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [dateRangeSelector]="true"
          [initialDate]="dataInitialDate"
          [forceLocalTime]="true"
          (dataRequest)="getData($event.from, $event.to)"
          [specialFilter]="true"
          [data]="gatewayAlarms"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
