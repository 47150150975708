<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "meter-orders" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Tipo de orden -->
    <mat-form-field>
      <mat-label>{{ "type" | translate }}</mat-label>
      <mat-select [(ngModel)]="orderType">
        <mat-option *ngFor="let order of orderTypes" [value]="order.id">
          {{ order.order | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Comentarios -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="orderComments"></textarea>
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="saveAssociationOrder()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
