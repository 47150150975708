// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { ProfileService } from "../../../../services/shared/ProfileService.service";
import { UserService } from "../../users/UserService.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
// Interfaces
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import {
  User,
  UserClient,
  UserEntity,
  UserAgrupation,
} from "../../../../interfaces/UserGlobalInterface.type";
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";

@Component({
  selector: "app-management-user",
  templateUrl: "./management-user.component.html",
  styleUrls: ["./management-user.component.scss"],
})
export class ManagementUserComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Búsqueda
  clientData: Client[];
  clientDataSub: Subscription;
  clientList: string[];
  entityList: string[];
  agrupationList: string[];
  profileList: string[];
  filterList: string[];
  selectedItem: string;
  selectedProfile: string;
  filterSelected: string = "client";
  profileSelect: MaterialSelectOption[];
  filterSelect: MaterialSelectOption[];

  // Menú de opciones del componente
  panelMenuOptions: PanelMenuOption[] = [
    {
      action: "update",
      icon: "fas fa-sync-alt",
      text: this.translate.instant("update"),
      visible: true,
    },
    {
      action: "new-user",
      icon: "fas fa-plus",
      text: this.translate.instant("new-user"),
      visible: true,
    },
    {
      action: "import-user",
      icon: "fas fa-file-import",
      text: this.translate.instant("file-import-subscribers"),
      visible: true,
    },
  ];

  // Tabla
  tableData: User[];
  originalTableData: User[];
  tableMaxReg: number = 20;
  exportFileName: string =
    this.translate.instant("users") + " " + this.DateParserService.getDate();
  tableSelectedData: User[];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "edit",
          tooltip: "edit",
          icon: "fas fa-edit",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "disable",
          tooltip: "disable",
          icon: "fas fa-eraser",
          visible: { attribute: null, rule: true },
          disabled: "disableDisable",
          warning: true,
        },
        {
          name: "delete",
          tooltip: "delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: "disableErase",
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "active",
      data: "active",
      search: "active",
      sort: "active",
      alter: {
        condition: "active",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "name",
      data: "name",
      search: "name",
      sort: "name",
      visible: true,
    },
    {
      title: "surname",
      data: "surname",
      search: "surname",
      sort: "surname",
      visible: true,
    },
    {
      title: "last-login",
      data: "lastLoginParsed",
      search: "lastLoginParsed",
      sort: "lastLogin",
      date: true,
      visible: true,
    },
    {
      title: "profile",
      data: "profileText",
      search: "profileText",
      sort: "profileText",
      visible: true,
    },
    {
      title: "email",
      data: "email",
      search: "email",
      sort: "email",
      visible: true,
    },
    {
      title: "phone",
      data: "phoneNumber",
      search: "phoneNumber",
      sort: "phoneNumber",
      visible: true,
    },
    {
      title: "last-login-ip",
      data: "lastLoginIp",
      search: "lastLoginIp",
      sort: "lastLoginIp",
      visible: true,
    },
    {
      title: "access",
      data: "access",
      search: null,
      sort: "access",
      html: true,
      long: true,
      visible: true,
      noExport: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private ManagementController: ManagementControllerService,
    private ProfileService: ProfileService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserService: UserService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.profileList = this.ProfileService.getProfileList()?.map(
      (profile: any) => {
        return profile.name;
      }
    );
    this.profileSelect = this.profileList.map((profile: any) => {
      return { value: profile, name: profile };
    });

    this.clientData = this.SessionDataService.getCurrentClientList();
    if (this.clientData) {
      this.getFilterList();
      this.updateSelectedFilter(this.clientList);
    }

    this.clientDataSub = this.SessionDataService.getClientList().subscribe(
      (clientList) => {
        this.clientData = clientList;
        if (this.clientData) {
          this.getFilterList();
          this.updateSelectedFilter(this.clientList);
        }
      }
    );
    if (this.clientList) {
      this.getFilterList();
    }

    this.getData();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientDataSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  getFilterList(): void {
    this.clientList = [];
    this.entityList = [];
    this.agrupationList = [];
    this.clientData.forEach((client: Client) => {
      this.clientList.push(client.clientName);
      client.entityList.forEach((entity: Entity) => {
        this.entityList.push(entity.entity);
        entity.agrupations.forEach((agrupation: Agrupation) => {
          this.agrupationList.push(agrupation.name);
        });
      });
    });
    this.clientList.sort((a, b) => a.localeCompare(b));
    this.entityList.sort((a, b) => a.localeCompare(b));
    this.agrupationList.sort((a, b) => a.localeCompare(b));
  }

  // Obtención de los datos
  getData(): void {
    this.ManagementController.getUsers().subscribe((response) => {
      let tableData: User[] = [];
      if (response["code"] == 0) {
        tableData = response["body"];
        tableData.forEach((user: User) => {
          user.profileText = this.ProfileService.getProfile(
            user.profile.toString(),
            user.isTelemedida
          );
          user.disableErase = user.active;
          user.disableDisable = !user.active;
          user.access = this.getUserAccess(user.clients);
        });
      }
      this.tableData = tableData;
      this.originalTableData = [...tableData];
    });
  }

  // Obtención de la lista anidada de accesos
  getUserAccess(clients: UserClient[]) {
    let access = `<ul class="nested-list">`;
    // Clientes
    clients.forEach((client: UserClient) => {
      // Entidades
      if (client.entities?.length > 0) {
        access +=
          `<li><i class="fas fa-caret-right list-caret"></i><i class="fas fa-user-tie list-icon"></i><span class="list-title">` +
          client.client +
          `</span>`;
        access += `<ul class="nested">`;
        client.entities.forEach((entity: UserEntity) => {
          // CUPS
          if (entity.clave?.length > 0) {
            access +=
              `<li><i class="fas fa-caret-right list-caret"></i><i class="fas fa-sitemap list-icon"></i><span class="list-title">` +
              entity.entity +
              `</span>`;
            access += `<ul class="nested">`;
            entity.clave.forEach((cups: string) => {
              access +=
                `<li><i class="fas fa-user list-icon"></i>` + cups + `</li>`;
            });
            access += `</ul></li>`;
          }
          // Agrupaciones
          else if (entity.agrupations?.length > 0) {
            access +=
              `<li><i class="fas fa-caret-right list-caret"></i><i class="fas fa-sitemap list-icon"></i><span class="list-title">` +
              entity.entity +
              `</span>`;
            access += `<ul class="nested">`;
            entity.agrupations.forEach((agrupation: UserAgrupation) => {
              access +=
                `<li><i class="fas fa-map list-icon"></i>` +
                agrupation.agrupation +
                `</li>`;
            });
            access += `</ul></li>`;
          } else {
            access +=
              `<li><i class="fas fa-sitemap list-icon"></i>` +
              entity.entity +
              `</li>`;
          }
        });
        access += `</ul></li>`;
      } else {
        access +=
          `<li><i class="fas fa-user-tie list-icon"></i>` +
          client.client +
          `</li>`;
      }
    });
    access += `</ul>`;

    return this.sanitizer.bypassSecurityTrustHtml(access);
  }

  // Acciones de la tabla
  tableActions(action: string, user: User): void {
    switch (action) {
      case "edit":
        this.router.navigate(["/usuarios/formulario/editar/" + user.id]);
        break;
      case "disable":
        this.UserService.disableUser(user);
        break;
      case "delete":
        this.UserService.deleteUser(user);
        break;
      default:
        break;
    }
  }

  // Actualización de filtro seleccionado
  updateSelectedFilter(selectedtFilter: any[]): void {
    this.selectedItem = null;
    this.filterList = [...selectedtFilter];
    this.filterSelect = selectedtFilter.map((filter) => {
      return { value: filter, name: filter };
    });
  }

  // Actualización de filtros
  updateFilter(): void {
    this.tableData = [...this.originalTableData];

    this.filterByProfile();

    if (this.selectedItem?.length > 0) {
      switch (this.filterSelected) {
        case "client":
          this.filterByClient();
          break;
        case "entity":
          this.filterByEntity();
          break;
        case "agrupation":
          this.filterByAgrupation();
          break;
        default:
          break;
      }
    }
  }

  // Filtrado por perfil
  filterByProfile(): void {
    if (this.selectedProfile?.length > 0) {
      this.tableData = this.tableData.filter((user: User) =>
        this.selectedProfile.includes(user.profileText)
      );
    }
  }

  // Filtrado por cliente
  filterByClient(): void {
    this.tableData = this.tableData.filter((user: User) =>
      user.clients.some((client: UserClient) =>
        this.selectedItem.includes(client.client)
      )
    );
  }

  // Filtrado por entidad
  filterByEntity(): void {
    this.tableData = this.tableData.filter((user: User) =>
      user.clients.some((client: UserClient) => {
        // Comprobación de si la entidad está incluida en el cliente para usuarios de nivel cliente
        if (
          (!client.entities || client.entities?.length == 0) &&
          this.clientData.some(
            (clientData: Client) =>
              clientData.clientName == client.client &&
              clientData.entityList.some((entity: Entity) =>
                this.selectedItem.includes(entity.entity)
              )
          )
        ) {
          return true;
        } else {
          return client.entities.some((entity: UserEntity) =>
            this.selectedItem.includes(entity.entity)
          );
        }
      })
    );
  }

  // Filtrado por agrupación
  filterByAgrupation(): void {
    this.tableData = this.tableData.filter((user: User) =>
      user.clients.some((client: UserClient) => {
        // Comprobación de si la agrupación está incluida en el cliente para usuarios de nivel cliente
        if (
          (!client.entities || client.entities?.length == 0) &&
          this.clientData.some(
            (clientData: Client) =>
              clientData.clientName == client.client &&
              clientData.entityList.some((entity: Entity) =>
                entity.agrupations.some((agrupation: Agrupation) =>
                  this.selectedItem.includes(agrupation.name)
                )
              )
          )
        ) {
          return true;
        } else {
          return client.entities.some((entity: UserEntity) => {
            // Comprobación de si la agrupación está incluida en la entidad para usuarios de nivel entidad
            if (
              (!entity.agrupations || entity.agrupations?.length == 0) &&
              this.clientData.some((clientData: Client) =>
                clientData.entityList.some(
                  (entityData: Entity) =>
                    entityData.entity == entity.entity &&
                    entityData.agrupations.some((agrupation: Agrupation) =>
                      this.selectedItem.includes(agrupation.name)
                    )
                )
              )
            ) {
              return true;
            } else {
              return entity.agrupations.some((agrupation: UserAgrupation) =>
                this.selectedItem.includes(agrupation.agrupation)
              );
            }
          });
        }
      })
    );
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update":
        this.ReloadComponentService.reload();
        break;
      case "new-user":
        this.router.navigate(["/usuarios/formulario/nuevo"]);
        break;
      case "import-user":
        this.router.navigate(["/usuarios/importar"]);
        break;
      default:
        break;
    }
  }
}
