// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  TemplateRef,
} from "@angular/core";
import { Subscription, forkJoin } from "rxjs";
import { Router } from "@angular/router";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { HomeControllerService } from "../../../services/server/HomeController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { MapSizeService } from "../../../modules/map-module/map-services/MapSizeService.service";
import {
  DEVICE_BY_METROLOGY,
  DeviceTypeService,
} from "../../../services/shared/DeviceTypeService.service";
import { MapDeviceMinimalParseService } from "../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
import { HomeService } from "./home.service";
import { ManufacturerService } from "../../../services/shared/ManufacturerService.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { DataAnalysisControllerService } from "../../../services/server/DataAnalysisController.service";
import { NetworkStateControllerService } from "../../../services/server/NetworkStateController.service";
import { SuspicionsService } from "../../../services/shared/SuspicionsService.service";
import { GraphOptionsService } from "../../../modules/graph-module/GraphOptionsService.service";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { DeviceTypes } from "./HomeInterface.type";
import {
  METROLOGY_TYPE,
  MapDevice,
} from "../../../interfaces/DeviceGlobalInterface.type";
import {
  GatewayLocation,
  MapGateway,
} from "../../../interfaces/GatewayGlobalInterface.type";
import { MapSearch } from "../../../modules/map-module/MapInterface.type";
// Componentes
import { MapControllerComponent } from "../../../modules/map-module/map-controller/map-controller.component";
// Variables
import { GRAPH_CONFIG } from "../../../modules/graph-module/GRAPH_CONFIG";
import { PROFILES } from "../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";
import { LANGUAGE } from "../../../services/language/LanguageController.service";
import { GRAPH_SUMATORY } from "../data-analysis/data-analysis-meter-graph/data-analysis-meter-graph.component";
import { GraphRequestData } from "../data-analysis/DataAnalysisInterface.type";
import { GRAPH_TYPES } from "../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
import { MAP_LAYERS } from "../../../modules/map-module/map-variables/MAP_TYPES";
import { KpisCardsData } from "../network-state/NetworkStateInterface.type";

@Component({
  selector: "app-main",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Stream de datos de sesión
  agrupationSub: Subscription;
  cesiumDataSub: Subscription;
  sessionProfile: string;
  currentAgrupation: Agrupation;
  readonly PROFILES = PROFILES;
  formatNumber = formatNumber;

  // Arrays de contadores y gateways
  devices: MapDevice[];
  devicesTotal: number = 0;
  gateways: MapGateway[];
  gatewayLocations: GatewayLocation[];
  deviceModels: { manufacturer: number; model: number }[] = [];
  deviceModelsFilter: {
    modelList: { manufacturer: number; model: number }[];
    manufacturerAttribute: string;
    modelAttribute: string;
  };
  totalInNetwork: number;
  totalPending: number;
  totalNoCommunication: number;
  totalGraphHiddenSeries: string[];

  // Columnas html
  @ViewChild("homeData") homeData: ElementRef;

  // Consumo
  lastMonthConsumption: {
    value: number;
    timestamp: number;
    valueParsed: string;
    date: string;
  };
  lastDayConsumption: {
    value: number;
    timestamp: number;
    valueParsed: string;
    date: string;
  };
  avoidConsumptionLoad: boolean = true;

  // KPIs
  kpisChartOptions: any;
  KpisCardsData: KpisCardsData;

  // Alarmas
  last5DaysAlarms: number;
  currentAlarms: number;
  alarmCodeList: { alarmCode: number | string; total: number; name: string }[];

  // Mapa
  mapFilter: string = "main";
  mapType: string = "standard";
  @ViewChild("homePanel") homePanel: ElementRef;
  @ViewChild("mapContainer") mapContainer: ElementRef;
  mapHeight: number;
  mapPadding: number = 0;
  mapSearch: MapSearch;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  drawAgrupationOutline: boolean;
  cheatMode: boolean = false;
  mapActiveLayers: string[];

  // Tipos de dispositivo
  devicesCountByType: DeviceTypes = new DeviceTypes();

  // Weather API
  weatherCoords: { latitude: number; longitude: number };

  // Escucha del cambio de tamaño de la ventana para redimensionar el mapa
  @HostListener("window:resize", ["$event"])
  onResize() {
    this.resizeMap();
  }

  // Gráfica
  graphsHidden: boolean = false;
  deviceTotalsHighchartsOptions: any;
  deviceTotalsChartOptions: any;
  alarmsHighchartsOptions: Options;
  alarmsChartOptions: any;
  alarmsCloudHighchartsOptions: Options;
  alarmsCloudChartOptions: any;
  consumptionHighchartsOptions: Options;
  consumptionChartOptions: any;
  consumptionSeries: any;
  @ViewChild("homeDataPanel") homeDataPanel: ElementRef;
  deviceTypeHighchartsOptions: Options;
  networkHighchartsOptions: Options;
  devicesChartOptions: any[];
  devicesChartObjects: any[] = [];
  networkChartOptions: any[];
  networkChartObjects: any[] = [];
  globalChartObject: any;

  // Cambiar a verde
  @HostListener("document:keydown", ["$event"])
  handleKeyboardDownEvent(event: KeyboardEvent) {
    if (
      this.sessionProfile == PROFILES.ARSON &&
      event.altKey &&
      event.key == "v"
    ) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleCheatMode();
    }
  }

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private ManufacturerService: ManufacturerService,
    private MapDeviceMinimalParse: MapDeviceMinimalParseService,
    private DeviceTypeService: DeviceTypeService,
    private GraphOptionsService: GraphOptionsService,
    public HomeService: HomeService,
    private MapSizeService: MapSizeService,
    private NetworkStateController: NetworkStateControllerService,
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParser: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private TemplateService: TemplateService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.drawAgrupationOutline = this.currentAgrupation?.virtual;

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    // Inicialización del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/
  ngAfterViewInit(): void {
    if (this.currentAgrupation) {
      // Ajuste del tamaño del mapa
      this.updateMapSize();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización
  loadComponent(): void {
    this.devices = null;
    this.gateways = null;
    this.getCardsData();
    // Sesión especial
    if (
      this.sessionProfile == PROFILES.ARSON &&
      this.SessionDataService.getCurrentUser() == "Presentaciones"
    ) {
      this.cheatMode = true;
      this.getMapData(true);
      // Sesión estándar
    } else {
      this.getMapData();
    }
  }

  // Cheat mode
  toggleCheatMode(): void {
    this.cheatMode = !this.cheatMode;
    this.drawAgrupationOutline = !this.cheatMode;
    this.getMapData(this.cheatMode);
  }

  // Obtención de los datos para las tarjetas
  getCardsData(): void {
    this.HomeController.getCards(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.last5DaysAlarms = response["body"]?.nroAlarmsLastFiveDays;
          this.lastMonthConsumption = {
            value: response["body"]?.lastMonthConsumption,
            timestamp: response["body"]?.lastMonthConsumptionTimestamp,
            valueParsed:
              formatNumber(
                response["body"]?.lastMonthConsumption,
                this.SessionDataService.getCurrentNumberFormat()
              ) + " m³",
            date: this.DateParser.parseDate(
              response["body"]?.lastMonthConsumptionTimestamp,
              this.SessionDataService.getCurrentDateFormat()
            ),
          };
          this.lastDayConsumption = {
            value: response["body"]?.lastDayConsumption,
            timestamp: response["body"]?.lastDayConsumptionTimestamp,
            valueParsed:
              formatNumber(
                response["body"]?.lastDayConsumption,
                this.SessionDataService.getCurrentNumberFormat()
              ) + " m³",
            date: this.DateParser.parseDate(
              response["body"]?.lastDayConsumptionTimestamp,
              this.SessionDataService.getCurrentDateFormat()
            ),
          };
        }
      }
    );
  }

  // Asignación de las opciones concretas para la gráfica de dispositivos
  setDeviceTotalsHighchartsOptions(): void {
    const self = this;
    let highchartsOptions = {
      exporting: { enabled: false },
      tooltip: { enabled: false },
      credits: { enabled: false },
      accessibility: { enabled: false },
      title: { text: "" },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
        bar: {
          stacking: "percent",
          borderWidth: 0.5,
          borderColor: "black",
          animation: false,
          pointWidth: 20,
          dataLabels: { enabled: false },
          point: {
            events: {
              mouseOver: (e) => {
                let serie = e.target.series.userOptions;
                if (
                  serie.device != "gateways" &&
                  serie.device != "concentrators" &&
                  serie.device != "txn"
                ) {
                  this.highlightLinkedSeries(serie.id, serie.device);
                }
              },
              mouseOut: (e) => {
                let serie = e.target.series.userOptions;
                if (
                  serie.device != "gateways" &&
                  serie.device != "concentrators" &&
                  serie.device != "txn"
                ) {
                  this.highlightLinkedSeries();
                }
              },
              click: function (e) {
                if (e.altKey) {
                  self.updateMapVisibility(
                    this.series.userOptions.device,
                    this.series.userOptions.id
                  );
                } else if (e.ctrlKey) {
                  self.updateMapVisibility(
                    this.series.userOptions.device,
                    this.series.userOptions.id,
                    true
                  );
                } else {
                  self.getLinkDeviceType(
                    e.point.series.userOptions.device,
                    e.point.series.userOptions.id
                  );
                }
              },
            },
          },
        },
      },
    };
    this.deviceTotalsHighchartsOptions = highchartsOptions;
  }

  highlightLinkedSeries(serie?: string, device?: string): void {
    if (serie == "no-communication" && device == "externalWithoutCom") {
      serie = "without-communication";
    }
    switch (serie) {
      case "in-network":
        this.totalGraphHiddenSeries = [
          "no-communication",
          "pending",
          "without-communication",
        ];
        this.updateSerieState("in-network");
        break;
      case "no-communication":
        this.totalGraphHiddenSeries = [
          "pending",
          "in-network",
          "without-communication",
        ];
        this.updateSerieState("no-communication");
        break;
      case "pending":
        this.totalGraphHiddenSeries = [
          "no-communication",
          "in-network",
          "without-communication",
        ];
        this.updateSerieState("pending");
        break;
      case "without-communication":
        this.totalGraphHiddenSeries = [
          "no-communication",
          "pending",
          "in-network",
        ];
        this.updateSerieState("without-communication");
        break;
      default:
        this.totalGraphHiddenSeries = [];
        this.updateSerieState();
        break;
    }
  }

  highlightLinkedGlobalSeries(serie?: string): void {
    this.globalChartObject?.series.forEach((chartSerie) => {
      if (
        (chartSerie.userOptions.id == serie &&
          chartSerie.userOptions.device != "externalWithoutCom") ||
        (serie == "without-communication" &&
          chartSerie.userOptions.device == "externalWithoutCom")
      ) {
        chartSerie.setState("normal");
      } else if (serie) {
        chartSerie.setState("inactive");
      } else {
        chartSerie.setState("normal");
      }
    });
  }

  updateSerieState(serie?: string): void {
    // Dispositivos
    this.devicesChartObjects?.forEach((chart) => {
      chart.series.forEach((chartSerie) => {
        if (
          (chartSerie.userOptions.id == serie &&
            chartSerie.userOptions.device != "externalWithoutCom") ||
          (serie == "without-communication" &&
            chartSerie.userOptions.device == "externalWithoutCom")
        ) {
          chartSerie.setState("normal");
        } else if (serie) {
          chartSerie.setState("inactive");
        } else {
          chartSerie.setState("normal");
        }
      });
    });

    // Red
    this.networkChartObjects?.forEach((chart) => {
      chart.series.forEach((chartSerie) => {
        if (serie) {
          chartSerie.setState("inactive");
        } else {
          chartSerie.setState("normal");
        }
      });
    });

    // Global
    this.highlightLinkedGlobalSeries(serie);
  }

  // Asignación de las opciones concretas para la gráfica de dispositivos
  setDeviceTotalsChartsOptions(): void {
    const self = this;
    let series: any[] = [];
    // ANCHOR Serie de totales por estado
    // Pendientes
    this.totalPending = this.devicesCountByType.meters.noAssigned;
    if (this.totalPending) {
      series.push({
        color: "#b255c1",
        id: "pending",
        name: this.translate.instant("pending"),
        data: [this.totalPending],
        cursor: "pointer",
      });
    }
    // Externos sin comunicación
    if (this.devicesCountByType.externalWithoutCom.noCom) {
      series.push({
        color: "#707070",
        id: "without-communication",
        name: this.translate.instant("without-communication"),
        data: [this.devicesCountByType.externalWithoutCom.noCom],
        cursor: "pointer",
      });
    }
    // Sin comunicación
    this.totalNoCommunication =
      this.devicesCountByType.meters.noCom +
      this.devicesCountByType.mbus.noCom +
      this.devicesCountByType.wavenis.noCom +
      this.devicesCountByType.une.noCom +
      this.devicesCountByType.external.noCom +
      this.devicesCountByType.sensors.noCom +
      this.devicesCountByType.satelites.noCom +
      this.devicesCountByType.nbiot.noCom +
      this.devicesCountByType.valves.noCom;
    if (this.totalNoCommunication) {
      series.push({
        color: "#bdbebd",
        id: "no-communication",
        name: this.translate.instant("no-communicates"),
        data: [this.totalNoCommunication],
        cursor: "pointer",
      });
    }
    // En red
    this.totalInNetwork =
      this.devicesCountByType.meters.com +
      this.devicesCountByType.mbus.com +
      this.devicesCountByType.wavenis.com +
      this.devicesCountByType.une.com +
      this.devicesCountByType.external.com +
      this.devicesCountByType.sensors.com +
      this.devicesCountByType.satelites.com +
      this.devicesCountByType.nbiot.com +
      this.devicesCountByType.valves.com;
    if (this.totalInNetwork) {
      series.push({
        color: "#59aa5a",
        id: "in-network",
        name: this.translate.instant("in-network"),
        data: [this.totalInNetwork],
        cursor: "pointer",
      });
    }

    let chartOptions = {};
    chartOptions["yAxis"] = {
      min: -0.5,
      max: 105,
      visible: false,
      startOnTick: false,
      endOnTick: false,
    };
    chartOptions["xAxis"] = {
      categories: ["totals"],
      visible: false,
      crosshair: { color: "rgba(0,0,0,0)" },
    };
    chartOptions["navigator"] = false;
    chartOptions["legend"] = { enabled: false };
    chartOptions["chart"] = {
      width: this.homeDataPanel?.nativeElement.offsetWidth - 150,
      type: "bar",
      style: {
        overflow: "visible",
      },
    };
    chartOptions["series"] = series;
    this.deviceTotalsChartOptions = chartOptions;
  }

  setDeviceTypesHighchartsOptions(): void {
    const self = this;
    let highchartsOptions = {
      exporting: { enabled: false },
      tooltip: { enabled: false },
      title: { text: "" },
      credits: { enabled: false },
      accessibility: { enabled: false },
      plotOptions: {
        bar: {
          stacking: null,
          borderWidth: 0.5,
          borderColor: "black",
          animation: false,
          pointWidth: 10,
          groupPadding: 1,
          minPointLength: 10,
          dataLabels: { enabled: true },
        },
      },
    };
    this.deviceTypeHighchartsOptions = JSON.parse(
      JSON.stringify(highchartsOptions)
    );
    highchartsOptions.plotOptions.bar.minPointLength = null;
    this.networkHighchartsOptions = JSON.parse(
      JSON.stringify(highchartsOptions)
    );
  }

  // ANCHOR Serie de dispositivos por tipo
  setDevicesChartsOptions(network?: boolean): void {
    const self = this;
    if (network) {
      this.networkChartOptions = [];
    } else {
      this.devicesChartOptions = [];
    }
    let devices = network
      ? ["gateways", "txn", "concentrators"]
      : [
          "meters",
          "mbus",
          "wavenis",
          "external",
          "externalWithoutCom",
          "nbiot",
          "sensors",
          "satelites",
          "une",
          "valves",
        ];
    devices.forEach((device) => {
      let series: any[] = [];
      // Si hay dispositivos
      if (this.devicesCountByType[device]?.total > 0) {
        let dataLabels = {
          enabled: true,
          formatter: function () {
            return (
              `<div>` +
              this.series.userOptions.name +
              ": <span style='color:gray'>" +
              formatNumber(
                this.y,
                self.SessionDataService.getCurrentNumberFormat()
              ) +
              `</span></div>`
            );
          },
          crop: false,
          overflow: "allow",
          allowOverlap: true,
        };

        // Sin dispositivos
        if (this.devicesCountByType[device].noDevice > 0) {
          series.push({
            color: "#ffbb45",
            id: "without-devices",
            device: device,
            name: this.translate.instant("without-devices"),
            data: [this.devicesCountByType[device].noDevice],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
        // Pendientes
        if (this.devicesCountByType[device].noAssigned > 0) {
          series.push({
            color: "#b255c1",
            id: "pending",
            device: device,
            name: this.translate.instant("pending"),
            data: [this.devicesCountByType[device].noAssigned],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
        // No comunica
        if (this.devicesCountByType[device].noCom > 0) {
          series.push({
            color: "#bdbebd",
            id: "no-communication",
            device: device,
            name: this.translate.instant("no-communicates"),
            data: [this.devicesCountByType[device].noCom],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
        // TXN1
        if (this.devicesCountByType[device].txn1 > 0) {
          series.push({
            color: "#808080",
            id: "txn1",
            device: device,
            name: "TXN1",
            data: [this.devicesCountByType[device].txn1],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
        // TXN4
        if (this.devicesCountByType[device].txn4 > 0) {
          series.push({
            color: "#808080",
            id: "txn4",
            device: device,
            name: "TXN4",
            data: [this.devicesCountByType[device].txn4],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
        // Comunica
        if (this.devicesCountByType[device].com > 0) {
          series.push({
            color: "#59aa5a",
            id: "in-network",
            device: device,
            name: this.translate.instant("in-network"),
            data: [this.devicesCountByType[device].com],
            cursor: "pointer",
            dataLabels: dataLabels,
          });
        }
      }

      if (series.length > 0) {
        let chartOptions = {};
        chartOptions["yAxis"] = {
          visible: false,
        };
        chartOptions["xAxis"] = {
          type: "category",
          categories: [device],
          labels: {
            formatter: function () {
              return (
                `<div class="home-graph-device-xaxis-label">` +
                (this.value == "externalWithoutCom"
                  ? self.translate.instant("no-lora-no-com")
                  : this.value == "meters"
                  ? self.translate.instant("meters-lw")
                  : this.value == "mbus"
                  ? self.translate.instant("meters-mbus")
                  : self.translate.instant(this.value)) +
                "<span class='home-graph-device-xaxis-label-data'>" +
                formatNumber(
                  self.devicesCountByType[this.value].total,
                  self.SessionDataService.getCurrentNumberFormat()
                ) +
                "</span></div>"
              );
            },
            useHTML: true,
          },
          visible: true,
          crosshair: { color: "#FFFFFF" },
          overflow: "justify",
        };
        chartOptions["navigator"] = false;
        chartOptions["legend"] = { enabled: false };
        chartOptions["chart"] = {
          width: 200,
          height: 100,
          type: "bar",
          style: {
            overflow: "visible",
          },
        };
        chartOptions["series"] = series;
        if (network) {
          this.networkChartOptions.push(chartOptions);
        } else {
          this.devicesChartOptions.push(chartOptions);
        }
      }
    });
  }

  // ANCHOR Gráfica de alarmas pictórica
  setAlarmsHighchartsOptions(): void {
    let highchartsOptions = {
      exporting: { enabled: false },
      tooltip: { enabled: false },
      legend: { enabled: false },
      plotOptions: {
        pictorial: {
          animation: false,
          pointPadding: 0.1,
          groupPadding: 0.2,
          pointWidth: 150,
          borderWidth: 1,
          borderColor: "black",
          enableMouseTracking: false,
        },
      },
    };
    this.alarmsHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica de alarmas
  setAlarmsChartsOptions(): void {
    let chartOptions: object = {};
    let series = [
      {
        stacking: "percent",
        animation: true,
        data: [
          {
            color: "#59aa5a",
            name: this.translate.instant("ok"),
            id: "ok",
            y:
              ((this.devicesTotal - this.currentAlarms) / this.devicesTotal) *
              100,
            x: 0,
            value: this.devicesTotal - this.currentAlarms,
            dataLabels: { enabled: false },
          },
          {
            color: "#ffbb45",
            id: "alarms",
            name: this.translate.instant("with-alarms"),
            y: (this.currentAlarms / this.devicesTotal) * 100,
            x: 0,
            value: this.currentAlarms,
            dataLabels: { enabled: false },
          },
        ],
        paths: [
          {
            definition:
              "M538.5,386.199L356.5,70.8c-16.4-28.4-46.7-45.9-79.501-45.9c-32.8,0-63.1,17.5-79.5,45.9L12.3,391.6c-16.4,28.4-16.4,63.4,0,91.8C28.7,511.8,59,529.3,91.8,529.3H462.2c0.101,0,0.2,0,0.2,0c50.7,0,91.8-41.101,91.8-91.8C554.2,418.5,548.4,400.8,538.5,386.199z M316.3,416.899c0,21.7-16.7,38.3-39.2,38.3s-39.2-16.6-39.2-38.3V416c0-21.601,16.7-38.301,39.2-38.301S316.3,394.3,316.3,416V416.899z M317.2,158.7L297.8,328.1c-1.3,12.2-9.4,19.8-20.7,19.8s-19.4-7.7-20.7-19.8L237,158.6c-1.3-13.1,5.801-23,18-23H299.1C311.3,135.7,318.5,145.6,317.2,158.7z",
          },
        ],
      },
    ];
    chartOptions["chart"] = {
      width: 150,
      height: 120,
      type: "pictorial",
      style: {
        overflow: "visible",
      },
    };
    chartOptions["yAxis"] = {
      visible: false,
      stackShadow: {
        enabled: true,
      },
      max: 100,
    };
    chartOptions["xAxis"] = {
      visible: false,
      max: 0,
      crosshair: { color: "#FFFFFF" },
    };
    chartOptions["navigator"] = false;
    chartOptions["series"] = series;
    this.alarmsChartOptions = chartOptions;
  }

  // ANCHOR Gráfica de alarmas rosco
  setAlarmsCloudHighchartsOptions(): void {
    let highchartsOptions = {
      exporting: { enabled: false },
      tooltip: {
        enabled: true,
        formatter: function () {
          return this.point.name;
        },
      },
      legend: { enabled: false },
      navigation: {
        breadcrumbs: {
          position: {
            y: 30,
            x: -100,
            floating: true,
          },
        },
      },
      plotOptions: {
        pie: {
          animation: false,
          size: "100%",
          borderColor: "black",
          borderWidth: 0.5,
          dataLabels: {
            enabled: true,
            formatter: null,
            crop: false,
            distance: 10,
            style: {
              fontWeight: "normal",
              fontSize: "1.2rem",
            },
            connectorWidth: 1,
          },
        },
      },
    };
    this.alarmsCloudHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica de alarmas
  setAlarmsCloudChartsOptions(): void {
    const self = this;
    let chartOptions: object = {};
    chartOptions["chart"] = {
      type: "pie",
      width: 200,
      style: {
        overflow: "visible",
      },
    };

    // Total de alarmas
    let totalAlarms = this.alarmCodeList
      .map((alarm) => alarm.total)
      .reduce((a, b) => a + b);
    // Alarmas no coincidentes en código pero sí en texto
    let alarmList = [];
    this.alarmCodeList.forEach((alarm) => {
      let currentAlarm = alarmList.find(
        (addedAlarm) =>
          addedAlarm.alarmCode == alarm.alarmCode ||
          addedAlarm.name == alarm.name
      );
      if (currentAlarm) {
        currentAlarm.total += alarm.total;
      } else {
        alarmList.push(JSON.parse(JSON.stringify(alarm)));
      }
    });
    // Principales >= 5%
    let mainAlarmList = alarmList.filter(
      (alarm) => alarm.total / totalAlarms >= 0.05
    );
    // Otros < 5%
    let secondaryAlarmList = alarmList.filter(
      (alarm) => alarm.total / totalAlarms < 0.05
    );
    if (secondaryAlarmList.length > 0) {
      mainAlarmList.push({
        alarmCode: "others",
        total: secondaryAlarmList
          .map((alarm) => alarm.total)
          .reduce((a, b) => a + b),
      });
    }
    let alarmRedirectionEvent = {
      click: function (e) {
        if (e?.point?.id) {
          self.router.navigate(["/alarmas/listado"], {
            state: {
              dateRange: "full",
              showInactives: false,
              alarmCodes: [
                {
                  id: e.point.id,
                  name: self.translate.instant("AlertMeter" + e.point.id),
                },
              ],
            },
          });
        }
      },
    };
    let dataLabels = {
      enabled: true,
      format: "{point.name}",
      distance: 10,
      allowOverlap: true,
      overflow: "allow",
      crop: false,
    };

    chartOptions["series"] = [
      {
        id: "alarms",
        name: this.translate.instant("alarms"),
        colorByPoint: true,
        data: mainAlarmList.map((alarm) => {
          return {
            id: alarm.alarmCode,
            name:
              alarm.alarmCode == "others"
                ? this.translate.instant("others")
                : this.translate.instant("AlertMeter" + alarm.alarmCode) +
                  " (" +
                  alarm.total +
                  ")",
            y: alarm.total,
            drilldown: alarm.alarmCode == "others" ? "others" : null,
            events: alarmRedirectionEvent,
            cursor: "pointer",
          };
        }),
        dataLabels: dataLabels,
      },
    ];
    chartOptions["drilldown"] = {
      series: [
        {
          name: "others",
          id: "others",
          data: secondaryAlarmList.map((alarm) => {
            return {
              id: alarm.alarmCode,
              name:
                alarm.alarmCode == "others"
                  ? this.translate.instant("others")
                  : this.translate.instant("AlertMeter" + alarm.alarmCode) +
                    " (" +
                    alarm.total +
                    ")",
              y: alarm.total,
            };
          }),
          events: alarmRedirectionEvent,
          dataLabels: dataLabels,
        },
      ],
    };
    this.alarmsCloudChartOptions = chartOptions;
  }

  // Redirección desde gráfica
  redirectTo(target: string): void {
    switch (target) {
      case "in-network":
        this.router.navigate(["/dispositivos/listado/asignados"], {
          state: { data: ["ACTIVE"] },
        });
        break;
      case "pending":
        this.router.navigate(["/dispositivos/listado/no-asignados"]);
        break;
      case "no-communication":
        this.router.navigate(["/dispositivos/listado/sin-comunicacion"]);
        break;
      case "alarms":
        this.router.navigate(["/alarmas/listado"]);
        break;
      default:
        break;
    }
  }

  // Obtención de los datos para el mapa
  getMapData(allGreen?: boolean): void {
    let gateways = [];
    let devices = [];

    let requests = [
      // Mapa
      this.HomeController.getMarkers(this.currentAgrupation.id),
    ];

    if (this.sessionProfile == PROFILES.ARSON) {
      requests.push(
        // Localizaciones
        this.HomeController.getLocations(
          this.SessionDataService.getCurrentEntity()?.id
        )
      );
    }

    forkJoin(requests).subscribe((responses) => {
      // Respuesta de localizaciones
      if (responses[1] && responses[1]["code"] == 0) {
        this.gatewayLocations = responses[1]["body"];
      }

      // Respuesta de mapa
      if (responses[0]["code"] == 0) {
        let nroMetersWithAlarm: number = 0;
        let nroTxn1: number = 0;
        let nroTxn4: number = 0;
        let alarmCodeList = [];

        // Dispositivos
        devices = this.MapDeviceMinimalParse.parseDevices(
          responses[0]["body"]["contadores"]
        );

        // Filtrado de dispositivos de pruebas
        devices = devices.filter((device: MapDevice) => device.fabricante != 7);
        // Filtrado de dispositivos de agrupaciones no visibles en virtual
        if (this.currentAgrupation.showAllEntity) {
          devices = this.HomeService.filterDisabledAgrupations(devices);
        }
        // Gateways
        gateways = responses[0]["body"]["gateways"];
        // Datos de tarjetas
        devices.forEach((device: MapDevice) => {
          // Fabricantes/modelos
          if (
            !this.deviceModels.some(
              (deviceModel) =>
                deviceModel.manufacturer == device.fabricante &&
                deviceModel.model == device.devType
            )
          ) {
            this.deviceModels.push({
              manufacturer: device.fabricante,
              model: device.devType,
            });
          }

          let deviceType = this.DeviceTypeService.getDeviceTypeByMask(
            device.tipo,
            device.metrologyType,
            device.fabricante
          );

          // Cheat mode
          if (
            allGreen &&
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS_CON ||
              deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
              deviceType == DEVICE_BY_COMM.LW_MBUS)
          ) {
            device.comunica = true;
            device.unidadVentaGw = device.unidadVentaGw
              ? device.unidadVentaGw
              : "000000000000";
          }

          // ANCHOR TXN
          if (deviceType == DEVICE_BY_COMM.TXN) {
            let txnType = this.ManufacturerService.getManufacturer(
              String(device.fabricante),
              String(device.devType),
              LANGUAGE.ESPANOL
            )?.deviceText;
            if (txnType == "N1") {
              nroTxn1++;
            }
            if (txnType == "N4") {
              nroTxn4++;
            }
          } else if (
            deviceType != DEVICE_BY_COMM.LW_MBUS_CON &&
            deviceType != DEVICE_BY_COMM.LW_UNE_CON
          ) {
            this.devicesTotal++;
          }

          // ANCHOR Contadores LW
          if (
            (deviceType == DEVICE_BY_COMM.LW ||
              deviceType == DEVICE_BY_COMM.LW_MBUS) &&
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS)
          ) {
            this.devicesCountByType.meters.total++;
            if (device.unidadVentaGw) {
              if (device.comunica) {
                this.devicesCountByType.meters.com++;
              } else {
                this.devicesCountByType.meters.noCom++;
              }
            } else if (!device.unidadVentaGw) {
              this.devicesCountByType.meters.noAssigned++;
            }
          }

          // ANCHOR Concentradores
          if (
            deviceType == DEVICE_BY_COMM.LW_UNE_CON ||
            deviceType == DEVICE_BY_COMM.LW_MBUS_CON
          ) {
            this.devicesCountByType.concentrators.total++;

            if (device.unidadVentaGw) {
              if (device.comunica) {
                this.devicesCountByType.concentrators.com++;
              } else {
                this.devicesCountByType.concentrators.noCom++;
              }
            } else if (!device.unidadVentaGw) {
              this.devicesCountByType.concentrators.noAssigned++;
            }
          }

          // ANCHOR Válvulas
          if (device.metrologyType == METROLOGY_TYPE.WATER_VALVE) {
            this.devicesCountByType.valves.total++;
            if (device.comunica) {
              this.devicesCountByType.valves.com++;
            } else {
              this.devicesCountByType.valves.noCom++;
            }
          }

          // ANCHOR Contadores MBus
          if (
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS) &&
            deviceType == DEVICE_BY_COMM.MBUS
          ) {
            this.devicesCountByType.mbus.total++;
            if (device.comunica) {
              this.devicesCountByType.mbus.com++;
            } else {
              this.devicesCountByType.mbus.noCom++;
            }
            if (this.cheatMode) {
              device.comunica = true;
            }
          }

          // ANCHOR Contadores Wavenis
          if (
            (device.metrologyType == METROLOGY_TYPE.WATER ||
              device.metrologyType == METROLOGY_TYPE.GAS) &&
            deviceType == DEVICE_BY_COMM.WAVENIS
          ) {
            this.devicesCountByType.wavenis.total++;
            this.devicesCountByType.wavenis.noCom++;
          }

          // ANCHOR Contadores UNE
          if (deviceType == DEVICE_BY_COMM.UNE) {
            this.devicesCountByType.une.total++;
            if (!device.comunica) {
              this.devicesCountByType.une.noCom++;
            }
          }

          // ANCHOR Contadores Externos
          if (
            deviceType == DEVICE_BY_COMM.EK280 ||
            deviceType == DEVICE_BY_COMM.OWASYS ||
            deviceType == DEVICE_BY_COMM.PLUM ||
            deviceType == DEVICE_BY_COMM.EXTERNO ||
            deviceType == DEVICE_BY_COMM.ERM ||
            deviceType == DEVICE_BY_COMM.API ||
            deviceType == DEVICE_BY_COMM.API_TOKEN
          ) {
            this.devicesCountByType.external.total++;
            if (device.comunica) {
              this.devicesCountByType.external.com++;
            } else {
              this.devicesCountByType.external.noCom++;
            }
          }

          // ANCHOR Contadores Externos sin comunicaciones
          if (deviceType == DEVICE_BY_COMM.NO_LORA_NO_COM) {
            this.devicesCountByType.externalWithoutCom.total++;
            this.devicesCountByType.externalWithoutCom.noCom++;
          }

          // ANCHOR Contadores NBIoT
          if (deviceType == DEVICE_BY_COMM.NBIOT) {
            this.devicesCountByType.nbiot.total++;
            if (device.comunica) {
              this.devicesCountByType.nbiot.com++;
            } else {
              this.devicesCountByType.nbiot.noCom++;
            }
          }

          // ANCHOR Sensores
          if (
            (device.metrologyType == METROLOGY_TYPE.SENSOR ||
              device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR) &&
            device.unidadVentaGw
          ) {
            this.devicesCountByType.sensors.total++;
            if (device.comunica) {
              this.devicesCountByType.sensors.com++;
            } else {
              this.devicesCountByType.sensors.noCom++;
            }
          }

          // ANCHOR Satélites
          if (
            device.metrologyType == METROLOGY_TYPE.SATELITE &&
            device.unidadVentaGw
          ) {
            this.devicesCountByType.satelites.total++;
            if (device.comunica) {
              this.devicesCountByType.satelites.com++;
            } else {
              this.devicesCountByType.satelites.noCom++;
            }
          }

          // Con alarmas
          if (device.alarm) {
            nroMetersWithAlarm++;

            if (device.alarmCodes) {
              device.alarmCodes.forEach((alarmCode) => {
                let addedAlarm = alarmCodeList.find(
                  (alarm) => alarm.alarmCode == alarmCode
                );
                if (addedAlarm) {
                  addedAlarm.total++;
                } else {
                  alarmCodeList.push({
                    alarmCode: alarmCode,
                    total: 1,
                    name: this.translate.instant("AlertMeter" + alarmCode),
                  });
                }
              });
            }
          }
        });

        // ANCHOR Dispositivos TXN
        if (nroTxn1 > 0 || nroTxn4 > 0) {
          this.devicesCountByType.txn.total = nroTxn1 + nroTxn4;
          this.devicesCountByType.txn.txn1 = nroTxn1;
          this.devicesCountByType.txn.txn4 = nroTxn4;
        }

        // ANCHOR Gateways
        gateways.map((gateway) => {
          this.devicesCountByType.gateways.total++;
          if (!gateway.contadoresPrincipal && !gateway.contadoresRedundante) {
            this.devicesCountByType.gateways.noDevice++;
          } else if (gateway.comunica) {
            this.devicesCountByType.gateways.com++;
          } else {
            this.devicesCountByType.gateways.noCom++;
          }
        });

        // Con alarmas
        this.currentAlarms = nroMetersWithAlarm;
        this.alarmCodeList = this.SessionDataService.getCurrentSuspicionActive()
          ? alarmCodeList
          : this.SuspicionsService.filterSuspicions(alarmCodeList);
      }

      // Búsqueda en mapa
      this.mapSearch = {
        title: this.translate.instant("search"),
        bindValue: "id",
        bindLabel: "nroSerie",
        data: devices,
      };

      // Datos de mapa
      this.devices = devices;
      this.gateways = gateways;

      // Gráfica
      if (this.devicesTotal != 0) {
        // Gráfica de totales
        this.setDeviceTotalsHighchartsOptions();
        this.setDeviceTotalsChartsOptions();
        // Gráficas de dispositivos/red
        this.setDeviceTypesHighchartsOptions();
        this.setDevicesChartsOptions();
        this.setDevicesChartsOptions(true);
        // Gráfica de alarmas
        this.setAlarmsHighchartsOptions();
        this.setAlarmsChartsOptions();
        if (this.alarmCodeList?.length > 0) {
          this.setAlarmsCloudHighchartsOptions();
          this.setAlarmsCloudChartsOptions();
        }
        // Gráfica de consumo
        this.avoidConsumptionLoad = this.devices.length > 2000;
        if (!this.avoidConsumptionLoad) {
          this.setConsumptionHighchartsOptions();
          this.getConsumptionGraph();
        }
        // KPIs
        this.loadKpisData();
      }

      // Coordenadas de clima
      this.getWeatherCoords();

      // Filtro de modelo de dispositivo para mapa
      this.deviceModelsFilter = {
        modelList: this.deviceModels,
        manufacturerAttribute: "fabricante",
        modelAttribute: "devType",
      };
    });
  }

  // Actualización de tamaño del mapa
  updateMapSize(): void {
    setTimeout(() => {
      this.homePanel?.nativeElement?.style?.setProperty(
        "--home-min-height",
        80 + this.homeData?.nativeElement?.offsetHeight
      );
      this.resizeMap();
    }, 0);
  }

  // Obtención de las coordenas para la API de clima
  getWeatherCoords(): void {
    // Array de coordenadas
    let devicesCoords: { latitude: string; longitude: string }[] = this.devices
      .filter(
        (device: MapDevice) =>
          device.latitude != null && device.longitude != null
      )
      .map((device: MapDevice) => {
        return { latitude: device.latitude, longitude: device.longitude };
      });

    if (devicesCoords.length > 0) {
      // Latitud media
      let devicesLatitudes: number[] = devicesCoords.map(
        (device: { latitude: string; longitude: string }) => {
          return parseFloat(device.latitude);
        }
      );
      let averageLatitude: number =
        devicesLatitudes?.reduce((a, b) => a + b, 0) / devicesLatitudes?.length;

      // Longitud media
      let devicesLongitudes: number[] = devicesCoords.map(
        (device: { latitude: string; longitude: string }) => {
          return parseFloat(device.longitude);
        }
      );
      let averageLongitude: number =
        devicesLongitudes?.reduce((a, b) => a + b, 0) /
        devicesLongitudes?.length;

      // Actualización de coordenadas de tiempo
      this.SessionDataService.sendWeatherCoords({
        latitude: averageLatitude,
        longitude: averageLongitude,
        agrupation: this.currentAgrupation.name,
      });
    } else {
      this.SessionDataService.sendWeatherCoords(null);
    }
  }

  // Ajuste del tamaño del mapa acorde a la visibilidad de las tarjetas
  resizeMap(): void {
    this.mapHeight =
      window.innerWidth > 980
        ? this.MapSizeService.calcMapHeight(
            this.homePanel,
            null,
            this.mapPadding
          )
        : 600;
  }

  // Activación de mapa 3d
  activate3dMap(): void {
    this.SessionDataService.sendCesiumData({
      active: true,
      devices: this.devices,
      gateways: this.gateways,
      locations: this.gatewayLocations,
      mapType: this.mapType,
      mapHeight: this.homePanel.nativeElement.offsetHeight - 40,
      drawAgrupationOutline: this.currentAgrupation.virtual,
      allowKml: true,
    });
  }

  // Obtención del tipo de dispositivo para dato de filtrado en los enlaces
  getLinkDeviceType(deviceType: string, seriesType: string): void {
    if (seriesType == "without-communication") {
      deviceType = "externalWithoutCom";
    }
    let urls = {
      "in-network": "/dispositivos/listado/asignados",
      "no-communication": "/dispositivos/listado/sin-comunicacion",
      pending: "/dispositivos/listado/no-asignados",
    };
    switch (deviceType) {
      case "gateways":
        this.router.navigate(["/gateways/listado/asignados"]);
        break;
      case "meters":
        this.router.navigate([urls[seriesType]], {
          state: {
            data: [DEVICE_BY_METROLOGY.METER, DEVICE_BY_COMM.LW, "ACTIVE"],
          },
        });
        break;
      case "mbus":
        this.router.navigate([urls[seriesType]], {
          state: {
            data: [DEVICE_BY_METROLOGY.METER, DEVICE_BY_COMM.MBUS, "ACTIVE"],
          },
        });
        break;
      case "wavenis":
        this.router.navigate([urls[seriesType]], {
          state: {
            data: [DEVICE_BY_METROLOGY.METER, DEVICE_BY_COMM.MBUS, "ACTIVE"],
          },
        });
        break;
      case "txn":
        break;
      case "external":
        this.router.navigate([urls[seriesType]], {
          state: {
            data: [
              DEVICE_BY_COMM.EK280,
              DEVICE_BY_COMM.ERM,
              DEVICE_BY_COMM.PLUM,
              DEVICE_BY_COMM.EXTERNO,
              "ACTIVE",
            ],
          },
        });
        break;
      case "externalWithoutCom":
        this.router.navigate(["/dispositivos/listado/asignados"], {
          state: { data: [DEVICE_BY_COMM.NO_LORA_NO_COM] },
        });
        break;
      case "nbiot":
        break;
      case "sensors":
        this.router.navigate([urls[seriesType]], {
          state: { data: [DEVICE_BY_METROLOGY.SENSOR, "ACTIVE"] },
        });
        break;
      case "concentrators":
        if (seriesType == "in-network") {
          this.router.navigate(["/mantenimiento/concentradores-entidad"], {
            state: { data: [DEVICE_BY_METROLOGY.CONCENTRATOR, "ACTIVE"] },
          });
        } else {
          this.router.navigate(["/mantenimiento/concentradores-entidad"], {
            state: { data: [DEVICE_BY_METROLOGY.CONCENTRATOR, "INACTIVE"] },
          });
        }
        break;
      case "satelites":
        this.router.navigate([urls[seriesType]], {
          state: { data: [DEVICE_BY_METROLOGY.SATELITE, "ACTIVE"] },
        });
        break;
      case "une":
        this.router.navigate([urls[seriesType]], {
          state: { data: [DEVICE_BY_COMM.UNE, "ACTIVE"] },
        });
        break;
      case "valves":
        this.router.navigate([urls[seriesType]], {
          state: { data: [DEVICE_BY_METROLOGY.VALVE, "ACTIVE"] },
        });
        break;
      default:
        this.router.navigate([urls[seriesType]], {
          state: {
            data: seriesType == "in-network" || "pending" ? ["ACTIVE"] : null,
          },
        });
        break;
    }
  }

  // Gráfica de consumo
  getConsumptionGraph(): void {
    let data: GraphRequestData = {
      meterList: this.devices.map((device) => device.id),
      agrupation: this.currentAgrupation.id,
      fromTimestamp: this.DateParser.getLastDays(8).startDate.valueOf(),
      toTimestamp: this.DateParser.getLastDays(1).startDate.valueOf(),
      graphType: GRAPH_TYPES.CONSUMPTION,
    };
    this.SessionDataService.sendDisableSpinner(true);
    this.DataAnalysisController.getGraphGroup(
      GRAPH_SUMATORY.SUM,
      data
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.consumptionSeries = [
          {
            id: "consumption",
            name: this.translate.instant("total-consumption"),
            data: response["body"]["readings"],
            dataGrouping: { approximation: "sum" },
            tooltip: { valueSuffix: " m³", valueDecimals: 3 },
            marker: { enabled: false },
            color: "#42a5f5",
          },
        ];
        this.setConsumptionChartsOptions();
      }
      this.SessionDataService.sendDisableSpinner(false);
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setConsumptionHighchartsOptions(): void {
    let highchartsOptions: any =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("consumption")
      );
    highchartsOptions.exporting.enabled = false;
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.consumptionHighchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setConsumptionChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["chart"]["height"] = window.innerWidth > 980 ? 180 : 50;
    chartOptions["chart"]["type"] = "area";
    chartOptions["rangeSelector"]["enabled"] = false;
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["legend"]["enabled"] = false;
    chartOptions["yAxis"][0]["visible"] = false;
    chartOptions["yAxis"][0]["startOnTick"] = false;
    chartOptions["yAxis"][0]["endOnTick"] = false;
    chartOptions["series"] = this.consumptionSeries;
    this.consumptionChartOptions = chartOptions;
  }

  checkMapLayerVisibility(
    mapActiveLayers: string[],
    layerType: string,
    addLayer: boolean,
    okLayer: string,
    noComLayer: string,
    pendingLayer?: string
  ): string[] {
    if (layerType == "in-network") {
      if (!mapActiveLayers.includes(okLayer)) {
        mapActiveLayers.push(okLayer);
      } else if (mapActiveLayers.includes(okLayer) && addLayer) {
        mapActiveLayers.splice(
          mapActiveLayers.findIndex((layer) => layer == okLayer),
          1
        );
      }
    } else if (layerType == "no-communication") {
      if (!mapActiveLayers.includes(noComLayer)) {
        mapActiveLayers.push(noComLayer);
      } else if (mapActiveLayers.includes(noComLayer) && addLayer) {
        mapActiveLayers.splice(
          mapActiveLayers.findIndex((layer) => layer == noComLayer),
          1
        );
      }
    } else if (layerType == "pending") {
      if (!mapActiveLayers.includes(pendingLayer)) {
        mapActiveLayers.push(pendingLayer);
      } else if (mapActiveLayers.includes(pendingLayer) && addLayer) {
        mapActiveLayers.splice(
          mapActiveLayers.findIndex((layer) => layer == pendingLayer),
          1
        );
      }
    }
    return mapActiveLayers;
  }

  // Actualización de visibilidad de capas en el mapa
  updateMapVisibility(
    deviceType: string,
    layerType: string,
    addLayer?: boolean
  ): void {
    let mapActiveLayers = addLayer
      ? [...this.mapController.mapComponent.getMapActiveLayers()]
      : [];
    switch (deviceType) {
      case "meters":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.OK,
          MAP_LAYERS.NO_COMUNICA,
          MAP_LAYERS.NO_ASIGNADO
        );
        break;
      case "mbus":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.MBUS_COMUNICA,
          MAP_LAYERS.MBUS
        );
        break;
      case "wavenis":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.WAVENIS,
          MAP_LAYERS.WAVENIS
        );
        break;
      case "external":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.OK,
          MAP_LAYERS.NO_COMUNICA
        );
        break;
      case "externalWithoutCom":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.NO_LORA_NO_COM,
          MAP_LAYERS.NO_LORA_NO_COM
        );
        break;
      case "nbiot":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.NBIOT,
          MAP_LAYERS.NBIOT
        );
        break;
      case "concentrators":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.CONCENTRATOR_OK,
          MAP_LAYERS.CONCENTRATOR_NO_COMUNICA,
          MAP_LAYERS.CONCENTRATOR_NO_ASIGNADO
        );
        break;
      case "une":
        mapActiveLayers = this.checkMapLayerVisibility(
          mapActiveLayers,
          layerType,
          addLayer,
          MAP_LAYERS.UNE_OK,
          MAP_LAYERS.UNE_NO_COMUNICA
        );
        break;
      case "txn":
        if (!mapActiveLayers.includes(MAP_LAYERS.TXN)) {
          mapActiveLayers.push(MAP_LAYERS.TXN);
        } else if (mapActiveLayers.includes(MAP_LAYERS.TXN) && addLayer) {
          mapActiveLayers.splice(
            mapActiveLayers.findIndex((layer) => layer == MAP_LAYERS.TXN),
            1
          );
        }
        break;
      case "gateways":
        if (!mapActiveLayers.includes(MAP_LAYERS.GATEWAYS)) {
          mapActiveLayers.push(MAP_LAYERS.GATEWAYS);
        } else if (mapActiveLayers.includes(MAP_LAYERS.GATEWAYS) && addLayer) {
          mapActiveLayers.splice(
            mapActiveLayers.findIndex((layer) => layer == MAP_LAYERS.GATEWAYS),
            1
          );
        }
        break;
      default:
        if (layerType == "in-network") {
          mapActiveLayers = [
            MAP_LAYERS.OK,
            MAP_LAYERS.MBUS_COMUNICA,
            MAP_LAYERS.UNE_OK,
            MAP_LAYERS.CONCENTRATOR_OK,
            MAP_LAYERS.NBIOT,
          ];
        } else if (layerType == "no-communication") {
          mapActiveLayers = [
            MAP_LAYERS.UNE_NO_COMUNICA,
            MAP_LAYERS.CONCENTRATOR_NO_COMUNICA,
            MAP_LAYERS.NO_LORA_NO_COM,
            MAP_LAYERS.NO_COMUNICA,
            MAP_LAYERS.WAVENIS,
            MAP_LAYERS.MBUS,
          ];
        } else if (layerType == "pending") {
          mapActiveLayers = [
            MAP_LAYERS.NO_ASIGNADO,
            MAP_LAYERS.CONCENTRATOR_NO_ASIGNADO,
          ];
        }
        break;
    }

    // Actualización de la visualización
    if (this.mapController.agrupationPolygonActive) {
      this.mapController.hideAgrupationPolygon();
    }
    setTimeout(() => (this.mapActiveLayers = mapActiveLayers), 0);
  }

  // Obtención de los datos del gráfico
  loadKpisData(): void {
    this.NetworkStateController.getKpisCards(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let KpisCardsData = response["body"];
        KpisCardsData.timestampParsed = this.DateParser.parseDate(
          KpisCardsData.timestamp,
          this.SessionDataService.getCurrentDateFormat()
        );
        this.KpisCardsData = KpisCardsData;
      }
    });
  }

  // Redirección a pantalla de KPIs
  goToKpis(): void {
    if (this.currentAgrupation.virtual) {
      this.router.navigate(["/estado-red/kpis"]);
    } else {
      this.router.navigate(["/estado-red/kpis"], {
        state: { data: { agrupationId: this.currentAgrupation.id } },
      });
    }
  }
}
