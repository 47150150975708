import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
// Interfaces
import { GlobalSearchDevice } from "../../../../screens/dashboard/management/ManagementInterface.type";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-navbar-top-search-box",
  templateUrl: "./navbar-top-search-box.component.html",
  styleUrls: ["./navbar-top-search-box.component.scss"],
})
export class NavbarTopSearchBoxComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  cupsSelected: boolean = false;
  searchOptionSelected: string = this.translate.instant("device");
  componentInitiated: boolean = false;
  searchActive: boolean;
  entitySub: Subscription;
  loading: boolean = true;

  @Input() sessionProfile: string;
  @Input() currentCupsName: string;
  @Input() type: string;
  @Input()
  get meterList(): any[] {
    return this._meterList;
  }
  set meterList(meterList: any[]) {
    this._meterList = meterList;
    if (!this.cupsSelected && !this._meterList) {
      this.loading = true;
      this.searchOptionSelected = this.translate.instant("loading");
    } else if (!this.cupsSelected && this._meterList) {
      this.loading = false;
      this.searchOptionSelected = this.translate.instant("device");
    }
    if (!this.cupsSelected && this.componentInitiated) {
      this.setSearchDropdownHeight();
    }
  }
  _meterList: any[];
  @Input()
  get cupsList(): any[] {
    return this._cupsList;
  }
  set cupsList(cupsList: any[]) {
    this._cupsList = cupsList;
    if (this.cupsSelected && !this._cupsList) {
      this.loading = true;
      this.searchOptionSelected = this.translate.instant("loading");
    } else if (this.cupsSelected && this._cupsList) {
      this.loading = false;
      this.searchOptionSelected = this.currentCupsName;
    }
    if (this.cupsSelected && this.componentInitiated) {
      this.setSearchDropdownHeight();
    }
  }
  _cupsList: any[];

  @Output() dropdownFilterFlag = new EventEmitter<any>();
  @Output() goToSelectionFlag = new EventEmitter<any>();
  @Output() resetSearchBoxFlag = new EventEmitter<any>();
  @Output() updateSearchActiveFlag = new EventEmitter<string>();

  @ViewChild("searchDropdown") searchDropdown: ElementRef;
  @ViewChild("searchFilter") searchFilter: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private ManagementController: ManagementControllerService,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Escucha de cambio de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.cupsSelected = false;
      this.searchActive = false;
      this.searchOptionSelected = this.translate.instant("device");
    });

    this.componentInitiated = true;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Flag de filtrado
  dropdownFilter(filterValue: any, type: string, cupsSelected: boolean): void {
    this.dropdownFilterFlag.emit({
      filterValue: filterValue?.trim(),
      type: type,
      cupsSelected: cupsSelected,
    });
  }

  // Flag de ir a selección
  goToSelection(cupsSelected: boolean, selection: any): void {
    this.goToSelectionFlag.emit({
      cupsSelected: cupsSelected,
      selection: selection,
    });
  }

  // Flag de reset de la caja de búsqueda
  resetSearchBox(type: string): void {
    this.resetSearchBoxText();
    this.resetSearchBoxFlag.emit(type);
    this.searchActive = false;
  }

  // Reseteo del texto de la caja de búsqueda
  resetSearchBoxText(): void {
    if (this.searchFilter) {
      this.searchFilter.nativeElement.value = "";
    }
  }

  // Flag de búsqueda activa
  updateSearchActive(type: string): void {
    this.updateSearchActiveFlag.emit(type);
  }

  // Seteo de altura de la caja de búsqueda
  setSearchDropdownHeight(): void {
    let searchList: any[] = this.cupsSelected ? this.cupsList : this.meterList;
    if (searchList?.length < 10) {
      this.searchDropdown.nativeElement.style.setProperty(
        "--search-height",
        searchList.length
      );
    } else {
      this.searchDropdown.nativeElement.style.setProperty(
        "--search-height",
        10
      );
    }
  }

  // Comprobación de dispositivo al pulsar enter en el buscador
  checkDeviceToGo(selected: string, cupsSelected: boolean): void {
    // Selección de dispositivo
    if (!cupsSelected) {
      let deviceChecked = this._meterList.find(
        (device: any) => device.descripcion == selected
      );

      if (deviceChecked) {
        this.goToSelectionFlag.emit({
          cupsSelected: cupsSelected,
          selection: deviceChecked,
        });
        // Búsqueda global si es ARSON
      } else if (this.sessionProfile == PROFILES.ARSON) {
        this.ManagementController.searchDevice({
          nroSerie: selected,
          devEui: null,
          rfModule: null,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            let devicesFound: GlobalSearchDevice[] = response["body"];
            if (devicesFound.length > 0) {
              this.DeviceRouteSelectorService.updateEntity(
                devicesFound[0].entityId
              );
              this.DeviceRouteSelectorService.updateAgrupation(
                devicesFound[0].agrupationId
              );
              this.DeviceRouteSelectorService.getDeviceRoute(
                devicesFound[0].metrologyType,
                devicesFound[0].id
              );
            }
          }
        });
      }
      // Selección de CUPS
    } else {
      let cupsChecked = this._cupsList.find(
        (cups: any) => cups.descripcion == selected
      );

      if (cupsChecked) {
        this.goToSelectionFlag.emit({
          cupsSelected: cupsSelected,
          selection: cupsChecked,
        });
      }
    }
  }
}
