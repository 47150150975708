<div class="login">
  <div class="error-page">
    <div class="panel panel-default">
      <!-- Título de login -->
      <div class="panel-heading">
        <h5 class="login-title">
          <!-- <b>{{"login-title" | translate}}</b> -->
          <b>ArsonData Metering</b>
        </h5>
      </div>
      <div class="panel-body text-center">
        <i class="fas fa-tools"></i>
        <h3>{{ "server-maintenance" | translate }}</h3>
        <h3>{{ "server-apologies" | translate }}</h3>
      </div>
    </div>
  </div>
</div>
