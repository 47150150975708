// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";

@Injectable({
  providedIn: "root",
})
export class SimControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Lista de SIM
  getSimCards(): Observable<object> {
    return this.HttpRequestService.get("/extws/sim/listAll");
  }

  // Grabar una sim
  saveSimCard(iccid: number): Observable<object> {
    return this.HttpRequestService.post("/extws/save/sim/" + iccid, null);
  }

  // Borrar una sim
  deleteSimCard(iccidId: number): Observable<object> {
    return this.HttpRequestService.post("/extws/delete/sim/" + iccidId, null);
  }

  // Actualizar una lista de SIM con Matooma
  refreshSimCards(data: number[]): Observable<object> {
    return this.HttpRequestService.post("/extws/sim/refresh", data);
  }

  // Importar CSV con SIM
  importSimCards(data: unknown): Observable<object> {
    return this.HttpRequestService.post("/extws/sim/import", data);
  }
}
