<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "meter-graph" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body meters-graph-panel">
        <div>
          <!-- Mapa de contadores -->
          <div class="meters-graph-map">
            <app-map-controller
              [freeDrawEnabled]="true"
              [mapType]="mapType"
              [mapHeight]="mapHeight"
              (selectedDevices)="selectMeters($event)"
              (actionFlag)="updateSelectedGateway($event, true)"
              [gatewaysData]="gatewaysData"
              [metersData]="metersData"
            >
            </app-map-controller>
          </div>
        </div>

        <div class="meters-graph-selectors" *ngIf="metersData && gatewaysData">
          <div class="meter-graphs-meters">
            <!-- Selector de dispositivos -->
            <app-material-select
              class="meters-graph-select"
              [multiple]="true"
              [counter]="true"
              [clearable]="true"
              [fullWidth]="true"
              [filter]="true"
              [title]="'selected' | translate"
              [bindValue]="'id'"
              [bindLabel]="'nroSerie'"
              [selected]="preselectedMeters"
              [data]="metersList"
              (selectedOption)="updateMapSelection($event)"
            ></app-material-select>
            <!-- Filtrado por calibre -->
            <app-material-select
              class="meters-graph-gauge"
              [multiple]="true"
              [disableAllSelection]="true"
              [clearable]="true"
              [fullWidth]="true"
              [title]="'filter-gauge' | translate"
              [bindValue]="'value'"
              [bindLabel]="'value'"
              [disabled]="gaugeList?.length == 0"
              [data]="gaugeList"
              (selectedOption)="filterGauge($event)"
            ></app-material-select>
          </div>

          <!-- Selector de gateway -->
          <div class="meters-graph-gateway">
            <div>
              <app-material-select
                [fullWidth]="true"
                [title]="'temperature-reference' | translate"
                [noSelection]="true"
                [bindValue]="'id'"
                [bindLabel]="'unidadVenta'"
                [selected]="preselectedGateway"
                [data]="gatewaysData"
                (selectedOption)="updateSelectedGateway($event)"
              ></app-material-select>
            </div>
            <button
              mat-button
              class="mat-button-icon"
              (click)="loadGraphData(from, to, true)"
              [disabled]="graphData?.length == 0"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>

        <!-- Gráfica -->
        <app-graph-controller
          id="meters-graph-anchor"
          [highchartsOptions]="highchartsOptions"
          [data]="chartOptions"
          [showCumulative]="showCumulative"
          [showCumulativeTotal]="showCumulative"
          [showPrediction]="
            sumatory == GRAPH_SUMATORY.SUM && sessionProfile == PROFILES.ARSON
          "
          (prediction)="calculatePrediction()"
          [chartConstructor]="chartConstructor"
          [dateRange]="defaultDateRange"
          (dataRequest)="loadGraphData($event.from, $event.to)"
          [filters]="graphFilters"
          (filterFlag)="sumatory = $event.value"
          [seriesToCheck]="[0]"
        >
        </app-graph-controller>
      </div>
    </div>
  </div>
</section>
