import { Component, Input } from "@angular/core";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-output-files-aqualia-dialog",
  templateUrl: "./output-files-aqualia-dialog.component.html",
  styleUrls: ["./output-files-aqualia-dialog.component.scss"],
})
export class OutputFilesAqualiaDialogComponent {
  @Input() data: any;

  constructor(public SessionDataService: SessionDataService) {}
}
