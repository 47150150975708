// ANCHOR Sensor
export interface ControlSensor {
  id: number;
  nroSerie: string;
  longitude: number;
  latitude: number;
  cups: string;
  idCups: string;
}

// ANCHOR Válvula cerrada
export interface ControlCloseValve {
  id: number;
  nroSerie: string;
  longitude: number;
  latitude: number;
  cups: string;
  idCups: string;
  valveState: number;
}

// ANCHOR Alerta
export interface ControlAlert {
  // BackEnd
  id: number;
  longitude: number;
  latitude: number;
  state: number;
  cupsId: number;
  cups: string;
  meterNroSerie: string[];
  initDate?: number;
  finalDate?: number;
  userId?: number;
  username?: string;

  // FrontEnd
  initDateParsed?: string;
  finalDateParsed?: string;
  stateParsed?: string;
  extraTableData?: any;
  meterNroSerieList?: string;
}

// ANCHOR Contador de telecontrol
export interface ControlMeter {
  meterId: number;
  meterNroSerie: string;
  meterValveState: number;
}

// ANCHOR Datos de evento de alerta
export interface ControlAlertEvent {
  // BackEnd
  id: number;
  alert: number;
  userId: number;
  username: string;
  contador: number;
  contadorNroSerie: string;
  eventType: number;
  eventDetails: string;
  initDate: number;

  // FrontEnd
  initDateParsed?: string;
}

// Datos de listado EK280/OWASYS
export interface EkData {
  // BackEnd
  id: number;
  nroSerie: string;
  lastReadedTimestamp: number;
  lastReadedValue: number;
  clave?: string;
  col01?: string;
  col02?: string;
  col03?: string;
  col04?: string;
  col05?: string;
  col06?: string;
  col07?: string;
  col08?: string;
  col09?: string;
  col10?: string;
  col11?: string;
  col12?: string;
  col13?: string;
  col14?: string;
  col15?: string;
  col16?: string;
  col17?: string;
  col18?: string;
  col19?: string;
  col20?: string;
  isAssigned?: boolean;
  metrologyType: number;
  latitude: number;
  longitude: number;
  consumptionCardsLastMonth?: number;
  precinto: string;
  rfModule: string;
  comments: string;
  agrupation?: number;
  entity: number;
  fabricante: number;
  devType: number;
  fcv?: number;
  pcs?: string;
  comunica?: boolean;
  // INICIO Campos obtenidos del servidor ek
  codigo: string;
  cliente: string;
  // Número de serie que se envía en los ficheros de facturación
  fileNroSerie: string;
  iccid: string;
  ip: string;
  // Flag para indicar si el ek280/owasys tiene un error de comunicación
  comunicationError?: boolean;
  // Flag para saber si el contador está conectado a la red eléctrica
  connected: boolean;
  // Fecha del última dato envíado en el fichero de facturación (6:00am)
  lastSendTimestamp?: number;
  lastCommunication?: number; // fecha de última comunicación
  excluded: boolean; // true si está excluido del fichero de facturación, false cc
  errorList: {
    code: number;
    detalle: string;
    fechaFin: number;
    fechaInicio: number;
    errorParsed?: string;
  }[];

  // Fron End
  meterLink?: string;
  agrupationName?: string;
  entityName?: string;
  noFile?: boolean;
  communicationError?: boolean;
  identicalValuesError?: boolean;
  testDisabled?: boolean;
  fileIncluded?: boolean;
  errorListDisabled?: boolean;
  model?: string;
}

export interface MinifiedEntityDefinition {
  id: number;
  e: number; // entity
  p: number; // colPosition
  n: string; // name
  l: string; // label
  d: string; // description
  s: boolean; // show
  r: boolean; // required
}

export interface EkExtraData {
  fecha: number; // 1. Fecha y hora del archivo
  valor: number; // 2. VmT Volumen sin corregir total (enteros)
  vc: number; // 3. VbT Volumen corregido total (enteros)
  wg: number; // 4. WG energia total
  vmd: number; // 5. Vmd Volumen sin corregir en error (enteros)
  vbd: number; // 6. Vbd Volumen corregido total en error (enteros)
  wst: number; // 7. WSt Energía en error total en error (enteros)
  p: number; // 8. Presión (valor medio de la última hora)
  t: number; // 9. Temperatura (valor medio de la última hora)
  s: number; // 10. statMomentary status
  sr: number; // 11. sreg  Status register
  qm: number; // 12. qm Caudal sin corregir total
  qb: number; // 13. Qb Caudal corregido total
}

export const EK_ERRORS = {
  1: "ek-error-conection",
  2: "ek-error-comm",
  3: "ek-error-unexpected",
  9: "ek-error-comm-by",
  10: "ek-error-historic",
  4: "ek-error-ip",
  5: "ek-error-host",
  6: "ek-error-no-comm",
  7: "ek-error-serial-number",
  8: "ek-error-empty",
  11: "ek-error-hour",
  13: "ek-error-identical-values",
  ERROR_CONEXION: 1,
  ERROR_COMUNICACION: 2,
  ERROR_INESPERADO: 3,
  ERROR_DURANTE_COMUNICACION: 9,
  ERROR_INESPERADO_PETICION_HISTORICO: 10,
  NO_IP: 4,
  UNKNOWN_HOST: 5,
  NO_COMMUNICATION: 6,
  DIFFERENT_SERIAL_NUMBER: 7,
  NO_RESULTS: 8,
  ERROR_HORA_EK_CERO: 11,
  IDENTICAL_VALUES: 13,
};
