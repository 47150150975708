<section class="content-header">
  <div class="container-fluid">
    <!-- Panel Estado de los concentradores -->
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "concentrators" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [onlyButton]="true"
          (action)="concentratorList = null; getConcentratorList()"
        ></app-material-panel-menu>
      </div>
      <div class="panel-body management-panel">
        <!-- Tabla de gateways -->
        <div *ngIf="concentratorList; else elseBlock">
          <app-table-controller
            id="management-global-concentrators"
            [tableId]="'managementConcentrators'"
            [specialFilter]="true"
            [export]="true"
            [exportFileName]="exportFileName"
            [columns]="columns"
            [rowNumbers]="true"
            [maxReg]="tableMaxReg"
            [data]="concentratorList"
          >
          </app-table-controller>
        </div>
      </div>
    </div>
  </div>
</section>
