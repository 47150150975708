<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "hydraulic-balance" | translate }}</b>
        </h5>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          *ngIf="
            sessionProfile == PROFILES.ARSON ||
            sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            sessionProfile == PROFILES.ADMIN_CLIENTE ||
            sessionProfile == PROFILES.ADMIN_AGRUPACION
          "
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newBalance()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'dataAnalysisBalance'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="rowNumbers"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>

    <div *ngIf="graphBalance" class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ graphBalance.name }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <app-balance-graphs
          [currentAgrupation]="currentAgrupation"
          [data]="graphBalance"
        >
        </app-balance-graphs>
      </div>
    </div>
  </div>
</section>
