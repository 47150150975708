import { Injectable } from "@angular/core";
// File saver
import saveAs from "file-saver";
// jsPDF
import { jsPDF } from "jspdf";
import * as JSZip from "jszip";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "./SessionDataService.service";

@Injectable({
  providedIn: "root",
})
export class PdfCreatorService {
  pdfReports: { doc: jsPDF; id: string }[];

  constructor(
    private SessionDataService: SessionDataService,
    private spinner: NgxSpinnerService
  ) {}

  // Descarga de archivo zip
  downloadZip(zipName: string, zipFileName?: string): void {
    let zip = new JSZip();
    for (let i = 0; i < this.pdfReports.length; i++) {
      zip.file(
        (zipFileName ? zipFileName + "_" : "") + this.pdfReports[i].id + ".pdf",
        this.pdfReports[i].doc.output("blob")
      );
    }
    zip
      .generateAsync({
        type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9,
        },
      })
      .then((content) => {
        if (content) {
          saveAs(content, zipName + ".zip");
        }
        this.SessionDataService.sendDialogAction({ action: "close" });
        this.spinner.hide("spinner-hard");
      });
  }

  // Creación del pdf
  createPdf(element: HTMLElement, id: string, start: boolean): any {
    const self = this;
    if (start) {
      this.pdfReports = [];
    }
    let doc = new jsPDF("p", "mm", "a4", true);
    return new Promise((resolve) => {
      resolve(
        doc.html(element, {
          callback: function (doc) {
            self.pdfReports.push({ doc: doc, id: id });
          },
          width: 180,
          windowWidth: element.offsetWidth,
          x: 15,
          y: 15,
        })
      );
    });
  }
}
