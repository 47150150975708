export const SUPPLY_BOARD_PIECE_TYPES = {
  0: "WATER_METER",
  1: "ENERGY_METER",
  2: "PRESSURE_SENSOR",
  3: "PUMP",
  4: "OVERGROUND_TANK",
  5: "UNDERGROUND_TANK",
  6: "BASIN",
  7: "VALVE",
  8: "ETAP",
  9: "EDAR",
  10: "BALANCE",
  90: "PIPE_LINE",
  91: "PIPE_CURVE",
  92: "PIPE_3_CROSS",
  93: "PIPE_4_CROSS",
  WATER_METER: 0,
  ENERGY_METER: 1,
  PRESSURE_SENSOR: 2,
  PUMP: 3,
  OVERGROUND_TANK: 4,
  UNDERGROUND_TANK: 5,
  BASIN: 6,
  VALVE: 7,
  ETAP: 8,
  EDAR: 9,
  BALANCE: 10,
  PIPE_LINE: 90,
  PIPE_CURVE: 91,
  PIPE_3_CROSS: 92,
  PIPE_4_CROSS: 93,
};

export const SUPPLY_BOARD_PIECES = [
  {
    id: 90,
    name: "supply-network-pipe",
    icon: "../../../../../assets/img/supply_constructor_icons/pipe_line.png",
  },
  {
    id: 91,
    name: "supply-network-pipe",
    icon: "../../../../../assets/img/supply_constructor_icons/pipe_curve.png",
  },
  {
    id: 92,
    name: "supply-network-pipe",
    icon: "../../../../../assets/img/supply_constructor_icons/pipe_3_cross.png",
  },
  {
    id: 93,
    name: "supply-network-pipe",
    icon: "../../../../../assets/img/supply_constructor_icons/pipe_4_cross.png",
  },
  {
    id: 0,
    name: "supply-network-water-meter",
    icon: "../../../../../assets/img/supply_constructor_icons/water_meter.png",
  },
  {
    id: 1,
    name: "supply-network-energy-meter",
    icon: "../../../../../assets/img/supply_constructor_icons/energy_meter.png",
  },
  {
    id: 2,
    name: "supply-network-pressure-sensor",
    icon: "../../../../../assets/img/supply_constructor_icons/pressure_sensor.png",
  },
  {
    id: 3,
    name: "supply-network-pump",
    icon: "../../../../../assets/img/supply_constructor_icons/water_pump.png",
  },
  {
    id: 4,
    name: "supply-network-overground-tank",
    icon: "../../../../../assets/img/supply_constructor_icons/water_overground_tank.png",
  },
  {
    id: 5,
    name: "supply-network-earth-tank",
    icon: "../../../../../assets/img/supply_constructor_icons/water_underground_tank.png",
  },
  {
    id: 6,
    name: "supply-network-basin",
    icon: "../../../../../assets/img/supply_constructor_icons/water_basin.png",
  },
  {
    id: 7,
    name: "supply-network-valve",
    icon: "../../../../../assets/img/supply_constructor_icons/water_valve.png",
  },
  {
    id: 8,
    name: "supply-network-etap",
    icon: "../../../../../assets/img/supply_constructor_icons/etap.png",
  },
  {
    id: 9,
    name: "supply-network-edar",
    icon: "../../../../../assets/img/supply_constructor_icons/edar.png",
  },
  {
    id: 10,
    name: "balance",
    icon: "../../../../../assets/img/supply_constructor_icons/balance.png",
  },
];

export interface SupplyNetworkCell {
  piece: SupplyNetworkPiece;
  cellReference: SupplyNetworkCell;
  focus: boolean;
  row: number;
  column: number;
  pipesMenu: boolean;
  pipe: number;
  pipeRotation: number;
}

export class SupplyNetworkPiece {
  public label?: string;
  public name?: string;
  public id: number;
  public icon: string;
  public row?: number;
  public column?: number;
  public rotation?: number;
  public meter?: any;
  public valve?: boolean;
  public balance?: any;
  public tooltip?: any;
  public size?: number;

  constructor(
    name: string,
    label: string,
    id: number,
    icon: string,
    row?: number,
    column?: number
  ) {
    this.id = id;
    this.name = name;
    this.label = label;
    this.icon = icon;
    this.row = row;
    this.column = column;
    this.rotation = 0;
    this.size = 1;
    this.tooltip = { active: false, position: 0 };
  }
}

export const PIPE_ROTATION = {
  RIGHT_UP: 0,
  RIGHT_DOWN: 270,
  LEFT_UP: 90,
  LEFT_DOWN: 180,
  UP_RIGHT: 180,
  UP_LEFT: 270,
  DOWN_RIGHT: 90,
  DOWN_LEFT: 0,
  VERTICAL: 90,
  HORIZONTAL: 0,
};

export interface SupplyNetworkData {
  elements: {
    label: string;
    id: number;
    size: number;
    rotation: number;
    row: number;
    column: number;
    meterId: number;
    isValve: boolean;
    balanceId: number;
  }[];
  pipes: number[][];
  referenceDate: { from: number; to: number };
  entityId: number;
  name: string;
  id: string;
}
