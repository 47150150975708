export interface User {
  // BackEnd
  active: boolean;
  creationDate: number;
  email: string;
  firebaseToken: string;
  id: number;
  isTlc: boolean;
  language: string;
  lastLogin: number;
  lastLoginIp: string;
  messageChannels: number;
  name: string;
  notifications: number;
  password: string;
  phoneNumber: string;
  profile: number;
  surname: string;
  username: string;
  restrictions?: string;
  clients?: UserClient[];
  isTelemedida?: boolean;

  // FrontEnd
  profileText?: string;
  creationDateParsed?: string;
  lastLoginParsed?: string;
  activeText?: string;
  disableErase?: boolean;
  disableDisable?: boolean;
  highlightUser?: boolean;
  access?: any;
}

export interface UserClient {
  client: string;
  entities: UserEntity[];
}

export interface UserEntity {
  entity: string;
  agrupations: UserAgrupation[];
  clave: string[];
}

export interface UserAgrupation {
  agrupation: string;
}

export class UserConfig {
  public user: string;
  public cardConf: object;
  public tableConf: object;
  public homeGraphsHidden: boolean;

  constructor(user: string) {
    this.user = user;
    this.cardConf = {};
    this.tableConf = {};
    this.homeGraphsHidden = null;
  }
}
