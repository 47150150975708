// @angular
import { Component, Input, OnInit } from "@angular/core";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../../services/shared/DeviceTypeService.service";
import { MANUFACTURER_INDEX } from "../../../../../../assets/manufacturers/MANUFACTURER_INDEX";

@Component({
  selector: "app-device-info-modal",
  templateUrl: "./device-info-modal.component.html",
  styleUrls: ["./device-info-modal.component.scss"],
})
export class DeviceInfoModalComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  deviceDetailModalData: any;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ManufacturerService: ManufacturerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data) {
      this.showMeterData();
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Mostrar datos del contador
  showMeterData(): void {
    let sessionLanguage = this.SessionDataService.getCurrentLanguage();
    let numberFormat = this.SessionDataService.getCurrentNumberFormat();
    let sessionProfile = this.SessionDataService.getCurrentProfile();
    let device: any = this.ManufacturerService.getManufacturer(
      this.data.device.idFabricante,
      this.data.device.idDevType,
      sessionLanguage
    );
    let deviceDetailModalData = [];

    // Lora
    if (
      this.data.deviceTypeByMask == DEVICE_BY_COMM.LW ||
      this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON ||
      this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_UNE_CON ||
      this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS
    ) {
      deviceDetailModalData.push({
        title: "DEV EUI",
        text: this.data.device.devEui,
      });
      deviceDetailModalData.push({
        title: "App EUI",
        text: this.data.device.appEui,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("version") + " LoRa",
        text: this.data.device.flags,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("max-power"),
        text: this.data.device.txPower,
      });
      if (this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON) {
        deviceDetailModalData.push({
          title: this.translate.instant("lw-mbus-retrys"),
          text:
            this.data.device.configuracion == 1
              ? this.translate.instant("no")
              : this.data.device.configuracion == 0
              ? this.translate.instant("yes")
              : null,
        });
      }
    }

    // Atributos comunes
    deviceDetailModalData.push({
      title: this.translate.instant("manufacturer"),
      text: device?.manufacturerText,
    });
    deviceDetailModalData.push({
      title: this.translate.instant("model"),
      text: device?.deviceText,
    });
    deviceDetailModalData.push({
      title:
        this.data.deviceTypeByMask == DEVICE_BY_COMM.EK280 ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.OWASYS
          ? this.translate.instant("serial-number-conversor")
          : this.data.deviceTypeByMask == DEVICE_BY_COMM.PLUM
          ? this.translate.instant("serial-number-recorder")
          : this.translate.instant("rf-module"),
      text: this.data.device.rfModule,
    });
    deviceDetailModalData.push({
      title: this.translate.instant("alias"),
      text: this.data.device.alias,
    });
    deviceDetailModalData.push({
      title: this.translate.instant("comments"),
      text: this.data.device.comments,
    });
    deviceDetailModalData.push({
      title:
        this.data.device.activate == 0
          ? this.translate.instant("import-date")
          : this.data.device.activate == 1
          ? this.translate.instant("uninstallation-date")
          : this.translate.instant("installation-date"),
      text: this.data.device.installationParsed
        ? this.data.device.installationParsed
        : this.data.device.fechaAltaParsed,
    });
    deviceDetailModalData.push({
      title: this.translate.instant("latitude"),
      text: this.data.device.latitude,
    });
    deviceDetailModalData.push({
      title: this.translate.instant("longitude"),
      text: this.data.device.longitude,
    });

    // Precinto
    if (this.data.device.precinto != null) {
      deviceDetailModalData.push({
        title: this.translate.instant("seal"),
        text: this.data.device.precinto,
      });
    }

    // Clave
    if (
      sessionProfile == PROFILES.ARSON ||
      sessionProfile == PROFILES.ADMIN_ENTIDAD ||
      sessionProfile == PROFILES.ADMIN_CLIENTE ||
      sessionProfile == PROFILES.USUARIO_CLIENTE ||
      sessionProfile == PROFILES.USUARIO_ENTIDAD
    ) {
      if (
        this.data.deviceTypeByMask != DEVICE_BY_COMM.MBUS &&
        this.data.device.networkKey != null
      ) {
        deviceDetailModalData.push({
          title: this.translate.instant("key"),
          text: this.data.device.networkKey,
        });
        if (
          this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS &&
          this.data.device.mbusKey != null
        ) {
          deviceDetailModalData.push({
            title: this.translate.instant("mbus-key"),
            text: this.data.device.mbusKey,
          });
        }
      } else if (
        this.data.deviceTypeByMask == DEVICE_BY_COMM.MBUS &&
        this.data.device.mbusKey != null
      ) {
        deviceDetailModalData.push({
          title: this.translate.instant("mbus-key"),
          text: this.data.device.mbusKey,
        });
      } else if (
        this.data.deviceTypeByMask != DEVICE_BY_COMM.UNE &&
        this.data.deviceTypeByMask != DEVICE_BY_COMM.API
      ) {
        deviceDetailModalData.push({
          title: this.translate.instant("key"),
          text: "",
        });
      }
    }

    // Cyble5
    if (
      (sessionProfile == PROFILES.ARSON ||
        sessionProfile == PROFILES.ADMIN_ENTIDAD ||
        sessionProfile == PROFILES.ADMIN_CLIENTE ||
        sessionProfile == PROFILES.USUARIO_CLIENTE ||
        sessionProfile == PROFILES.USUARIO_ENTIDAD) &&
      this.data.device.idFabricante == MANUFACTURER_INDEX.ITRON &&
      this.data.device.idDevType != MANUFACTURER_INDEX.ITRON_RF_CYBLE4IoT
    ) {
      deviceDetailModalData.push({
        title: this.translate.instant("encrypt-key"),
        text: this.data.device.cyble5EncKey,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("authentication-key"),
        text: this.data.device.cyble5AuthKey,
      });
    }
    if (
      this.data.device.idFabricante == MANUFACTURER_INDEX.ITRON &&
      this.data.device.idDevType != MANUFACTURER_INDEX.ITRON_RF_CYBLE4IoT
    ) {
      deviceDetailModalData.push({
        title: this.translate.instant("weigth-pulse"),
        text: this.data.device.cyble5PulseWeight,
      });
    }
    // Lora
    if (
      sessionProfile == PROFILES.ARSON &&
      (this.data.deviceTypeByMask == DEVICE_BY_COMM.LW ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_UNE_CON ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS)
    ) {
      deviceDetailModalData.push({
        title: "ADR",
        text:
          this.data.device.adrPermission == 1
            ? "Sí"
            : this.data.device.adrPermission == 0
            ? "No"
            : "",
      });
      deviceDetailModalData.push({
        title: "SF min",
        text: this.data.device.minSf,
      });
    }
    // MBus
    if (
      this.data.deviceTypeByMask == DEVICE_BY_COMM.MBUS ||
      this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS
    ) {
      deviceDetailModalData.push({
        title: this.translate.instant("mbus-address"),
        text: this.data.device.address,
      });
    }
    // Wavenis
    if (this.data.deviceTypeByMask == DEVICE_BY_COMM.WAVENIS) {
      deviceDetailModalData.push({
        title: this.translate.instant("wavenis-address"),
        text: this.data.device.address,
      });
    }
    // UNE
    if (this.data.deviceTypeByMask == DEVICE_BY_COMM.UNE) {
      deviceDetailModalData.push({
        title: this.translate.instant("une-address"),
        text: this.data.device.address,
      });
    }

    // Satélite
    if (this.data.device.metrologyType == METROLOGY_TYPE.SATELITE) {
      deviceDetailModalData.push({
        title: this.translate.instant("satelite-pulse"),
        text:
          this.data.device.pulseWeight != null
            ? formatNumber(this.data.device.pulseWeight, numberFormat) + " m³"
            : "",
      });
      deviceDetailModalData.push({
        title: this.translate.instant("satelite-max-value"),
        text:
          this.data.device.overflow != null
            ? formatNumber(this.data.device.overflow, numberFormat) + " m³"
            : "",
      });
    }

    // EK280/OWASYS
    if (
      this.data.deviceTypeByMask == DEVICE_BY_COMM.EK280 ||
      this.data.deviceTypeByMask == DEVICE_BY_COMM.OWASYS
    ) {
      deviceDetailModalData.push({
        title: this.translate.instant("code"),
        text: this.data.ekData.codigo,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("serial-number-conversor-owasys"),
        text: this.data.ekData.nserie,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("ek-file-serial-number"),
        text: this.data.ekData.fileNroSerie,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("ICCID"),
        text: this.data.ekData.iccid,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("ek-connected"),
        text:
          this.data.ekData.conectadoRedElectrica == 1
            ? "Sí"
            : this.data.ekData.conectadoRedElectrica == 0
            ? "No"
            : "",
      });
      if (this.SessionDataService.getCurrentProfile() == "ARSON") {
        deviceDetailModalData.push({
          title: this.translate.instant("ek-file-exclude"),
          text:
            this.data.ekData.excluirEnvioFacturacion == 1
              ? "Sí"
              : this.data.ekData.excluirEnvioFacturacion == 0
              ? "No"
              : "",
        });
      }
      deviceDetailModalData.push({
        title: this.translate.instant("window") + " 1",
        text: this.data.ekData.ventana1Parsed,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("window") + " 2",
        text: this.data.ekData.ventana2Parsed,
      });
      deviceDetailModalData.push({
        title: this.translate.instant("window") + " 3",
        text: this.data.ekData.ventana3Parsed,
      });
    }

    this.data.device.meterImageUrl =
      "data:image/jpg;base64," + this.data.device.meterImage;

    this.deviceDetailModalData = deviceDetailModalData;

    // Nonces
    if (
      sessionProfile == PROFILES.ARSON &&
      (this.data.deviceTypeByMask == DEVICE_BY_COMM.LW ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS_CON ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_UNE_CON ||
        this.data.deviceTypeByMask == DEVICE_BY_COMM.LW_MBUS)
    ) {
      deviceDetailModalData.push({
        title: "Nonces",
        text: this.data.device.hasNc ? "Sí" : "No",
      });
    }

    // Rango de sensor
    if (
      this.data.device.hasAs ||
      this.data.device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
    ) {
      deviceDetailModalData.push({
        title: this.translate.instant("sensor-range"),
        text: this.data.device.distanciaAcustica
          ? this.data.device.distanciaAcustica + " m"
          : "",
      });
    }
  }
}
