import { Component, Input } from "@angular/core";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";

@Component({
  selector: "app-satelite-offset-dialog",
  templateUrl: "./satelite-offset-dialog.component.html",
  styleUrls: ["./satelite-offset-dialog.component.scss"],
})
export class SateliteOffsetDialogComponent {
  @Input() data: any;

  constructor(public SessionDataService: SessionDataService) {}
}
