// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment from "moment";
// Servicios propios
import { AlarmControllerService } from "../../../../services/server/AlarmController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { MeterService } from "../../devices/meter/MeterService.service";
import { SuspicionsService } from "../../../../services/shared/SuspicionsService.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import {
  ALARM_GROUP_CODE,
  ALARM_STATES,
  AlarmData,
} from "../AlarmInterface.type";
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
import { MANUFACTURER_INDEX } from "../../../../../assets/manufacturers/MANUFACTURER_INDEX";
// Componentes
import { AlarmEditDialogComponent } from "../alarm-edit-dialog/alarm-edit-dialog.component";
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";
// Variables
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-alarm",
  templateUrl: "./alarms-list.component.html",
  styleUrls: ["./alarms-list.component.scss"],
})
export class AlarmsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConf: Subscription;

  // Tabla
  dateRange: string = history.state.dateRange;
  tableData: AlarmData[];
  tableSelectedData: AlarmData[];
  tableCupsColumns: TableDataColumn[];
  cups: EntityDefinition;
  entityNroSerie: EntityDefinition;
  from: string;
  to: string;
  tableMaxReg: number = 20;
  groupBy: object = { groupAttribute: "contador", groupName: "nroSerie" };
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment };
  exportFileName: string =
    this.translate.instant("alarms-export") +
    " " +
    this.DateParserService.getDate();
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "edit-alarms",
      icon: "fas fa-edit",
      selectionRequired: true,
    },
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
  ];
  quickFiltersExclusion: boolean[] = [true];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "active",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: false },
        active: this.dateRange == "month",
      },
      {
        name: "inactive",
        columnSearch: "finalDate",
        condition: { type: "boolean", rule: true },
        active: false,
      },
    ],
    [
      {
        name: "disabled",
        columnSearch: "disabled",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "enabled",
        columnSearch: "enabled",
        condition: { type: "boolean", rule: true },
        active: this.dateRange != "full",
      },
    ],
  ];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;

  // Mapa
  mapType: string = "alarmsMeterList";
  mapHeight: number = window.innerHeight - 520;
  devices: AlarmData[];
  gateways: [] = [];
  selectedDevices: AlarmData[];
  tableFilteredByMap: boolean = false;

  // Select
  alarmSelectOptions: MaterialSelectOption[] = [
    { value: true, name: this.translate.instant("alarm-all") },
    { value: false, name: this.translate.instant("alarm-active-enabled") },
  ];
  showInactives: boolean =
    history.state && history.state.showInactives !== undefined
      ? history.state.showInactives
      : true;

  dialog: Subscription;
  preselectedAlarmCodes: { id: number | string; name: string }[] =
    history.state?.alarmCodes;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AlarmController: AlarmControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MaterialDialogService: MaterialDialogService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private requestQueue: RequestQueueService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private SuspicionsService: SuspicionsService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entityCupsConf = this.SessionDataService.getEntityCupsConf().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getAlarms(this.from, this.to);
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation && this.currentEntityCupsConf) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entityCupsConf.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }
  getData(): void {
    this.getDate(this.dateRange);
    this.getAlarms(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de alarmas
  getAlarms(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let url =
      this.showInactives == true
        ? this.AlarmController.getAlarmsFromTo(
            this.currentAgrupation.id,
            from,
            to
          )
        : this.AlarmController.getActiveEnabledAlarmsFromTo(
            this.currentAgrupation.id,
            from,
            to
          );
    url.subscribe((response) => {
      if (response["code"] == 0) {
        let meterAlarmList = response["body"]?.alarmList;
        // Alarmas
        this.tableData = this.parseMeterAlarmList(meterAlarmList);

        // CUPS
        let entityCupsData = this.MeterService.getEntityCupsData();
        this.tableCupsColumns = entityCupsData.entityCupsColumns;
        this.cups = entityCupsData.entityCups;
        this.entityNroSerie = entityCupsData.entityNroSerie;

        this.setColumns(
          this.tableData.some(
            (alarm) => alarm.fabricante == MANUFACTURER_INDEX.BMETER
          )
        );
      }
    });
  }

  // Parseo de listado de contadores a listado de alarmas
  parseMeterAlarmList(meterAlarmList: AlarmData[]): AlarmData[] {
    let suspicionActive = this.SessionDataService.getCurrentSuspicionActive();
    let alarmList: AlarmData[] = [];
    meterAlarmList.forEach((meter) => {
      meter.alarmas.forEach((alarm) => {
        let alarmName =
          alarm.code != null
            ? this.translate.instant("AlertMeter" + alarm.code)
            : "";
        let alarmDescription =
          alarm.code != null
            ? this.translate.instant("AlertDescription" + alarm.code)
            : "";
        if (
          alarmDescription == "" ||
          alarmDescription == "AlertDescription" + alarm.code
        ) {
          alarmDescription = this.translate.instant("no-details");
        }

        let alarmData = {
          id: alarm.id,
          contador: meter.contador,
          claveMaestra: meter.claveMaestra,
          nroSerie: meter.nroSerie,
          claveMaestraKey: meter.claveMaestraKey,
          code: alarm.code,
          initDate: alarm.initDate,
          finalDate: alarm.finalDate,
          state: alarm.state,
          comments: alarm.comments,
          disabled: alarm.disabled,
          metrology_type: meter.metrology_type,
          latitude: meter.latitude,
          longitude: meter.longitude,
          fabricante: meter.fabricante,
          devType: meter.devType,
          lastConsumptionMax: meter.lastConsumptionMax,
          lastConsumptionMin: meter.lastConsumptionMin,
          lastConsumptionTotal: meter.lastConsumptionTotal,
          groupCode: alarm.groupCode,
          agrupationId: meter.agrupationId,
          entityId: meter.entityId,

          // Front
          metrologyType: meter.metrology_type,
          name: alarmName,
          description: alarmDescription,
          enabled: !alarm.disabled,
          stateParsed: ALARM_STATES[alarm.state]
            ? this.translate.instant(ALARM_STATES[alarm.state])
            : null,
          modalInfo: true,
          modalInfoData: {
            title: this.translate.instant("alarm-info"),
            html:
              `<h4><b> (` +
              this.translate.instant("code") +
              " " +
              alarm.code +
              ") " +
              alarmName +
              `: </b></h4>
                   <pre class="pre-without-format">` +
              alarmDescription +
              `</pre>`,
          },
          loadGraph:
            meter.metrology_type == METROLOGY_TYPE.WATER ||
            meter.metrology_type == METROLOGY_TYPE.GAS ||
            meter.metrology_type == METROLOGY_TYPE.SATELITE,
          claveParsed:
            meter.claveMaestraKey != meter.nroSerie
              ? meter.claveMaestraKey
              : null,
          groupCodeParsed: ALARM_GROUP_CODE[alarm.groupCode]
            ? this.translate.instant(ALARM_GROUP_CODE[alarm.groupCode])
            : null,
          count: meter.alarmas?.length,
        };
        for (let col in meter.cols) {
          alarmData[col] = meter.cols[col];
        }
        if (
          suspicionActive ||
          (!suspicionActive && !this.SuspicionsService.isSuspicion(alarmData))
        ) {
          alarmList.push(alarmData);
        }
      });
    });
    return alarmList;
  }

  // Seteo de las columnas de la tabla
  setColumns(bmeter: boolean): void {
    if (bmeter) {
      this.tableGlobalActions.unshift({
        title: "clean-alarms",
        icon: "fas fa-trash",
        selectionRequired: true,
      });
    }

    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "to-pending",
            tooltip: "to-pending",
            icon: "fas fa-binoculars",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
          },
          {
            name: "disable",
            tooltip: "disable",
            icon: "fas fa-eraser disable",
            visible: { attribute: "disabled", rule: false },
            disabled: false,
            warning: true,
          },
          {
            name: "enable",
            tooltip: "enable",
            icon: "fas fa-plus enable",
            visible: { attribute: "disabled", rule: true },
            disabled: false,
          },
        ],
        visible: true,
      },
      {
        title: this.cups?.name ? this.cups?.name : "CUPS",
        data: "claveParsed",
        search: "claveParsed",
        sort: "claveParsed",
        visible: this.cups ? true : null,
      },
      {
        title: this.entityNroSerie?.label
          ? this.entityNroSerie.label
          : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
      },
      {
        title: "enabled",
        data: "enabled",
        search: "enabled",
        sort: "enabled",
        alter: {
          condition: "enabled",
          skins: [
            { rule: true, class: "fas fa-check-circle" },
            { rule: false, class: "fas fa-times-circle" },
          ],
        },
        boolean: true,
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
      },
      {
        title: "last-consumption-min",
        data: "lastConsumptionMinParsed",
        search: "lastConsumptionMinParsed",
        sort: "lastConsumptionMin",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-max",
        data: "lastConsumptionMaxParsed",
        search: "lastConsumptionMaxParsed",
        sort: "lastConsumptionMax",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-total",
        data: "lastConsumptionTotalParsed",
        search: "lastConsumptionTotalParsed",
        sort: "lastConsumptionTotal",
        numerical: true,
        visible: true,
      },
      {
        title: "state",
        data: "stateParsed",
        search: "stateParsed",
        sort: "state",
        visible: true,
      },
      {
        title: "start-date",
        data: "initDateParsed",
        search: "initDateParsed",
        sort: "initDate",
        date: true,
        visible: true,
      },
      {
        title: "end-date",
        data: "finalDateParsed",
        search: "finalDateParsed",
        sort: "finalDate",
        date: true,
        visible: true,
      },
      {
        title: "total-alarms",
        data: "count",
        search: "count",
        sort: "count",
        visible: true,
        numerical: true,
      },
      {
        title: "group",
        data: "groupCodeParsed",
        search: "groupCodeParsed",
        sort: "groupCodeParsed",
        visible: true,
      },
      {
        title: "alarm",
        data: "name",
        search: "name",
        sort: "name",
        modalInfo: true,
        visible: true,
        typology: {
          showAttribute: "name",
          filterAttribute: "code",
          activeFilters: this.preselectedAlarmCodes
            ? this.preselectedAlarmCodes
            : null,
        },
      },
      {
        title: "latitude",
        data: "latitudeParsed",
        search: "latitudeParsed",
        sort: "latitude",
        visible: true,
        numerical: true,
      },
      {
        title: "longitude",
        data: "longitudeParsed",
        search: "longitudeParsed",
        sort: "longitude",
        visible: true,
        numerical: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
    ];

    if (this.tableCupsColumns) {
      columns = [...columns, ...this.tableCupsColumns];
    }

    this.columns = columns;
  }

  // Obtención del rango de fechas inicial
  getDate(dateRange?): void {
    // Determinar la fecha inicial según el rango de fechas seleccionado
    switch (dateRange) {
      case "full":
        this.handleFullRange();
        break;
      case "month":
        this.handleMonthRange();
        break;
      case "fiveDays":
        this.handleDefaultRange();
        break;
      default:
        this.handleDefaultRange();
        break;
    }
    this.dateRange = null;
    history.state.dateRange = null;
  }

  // Maneja el rango "full"
  private handleFullRange(): void {
    this.dataInitialDate = this.DateParserService.getLastDays();
  }

  // Maneja el rango "month"
  private handleMonthRange(): void {
    this.dataInitialDate = this.DateParserService.getLastDays("1", "month");
  }

  // Maneja el rango "default"
  private handleDefaultRange(): void {
    this.dataInitialDate = this.DateParserService.getLastDays("5");
  }

  // Acciones de la tabla
  tableActions(action: string, alarm: AlarmData): void {
    switch (action) {
      case "show-detail":
        this.DeviceRouteSelectorService.getDeviceRoute(
          alarm.metrology_type,
          alarm.contador
        );
        break;
      case "edit":
        this.editAlarm(alarm);
        break;
      case "enable":
        this.enableAlarm(alarm);
        break;
      case "disable":
        this.disableAlarm(alarm);
        break;
      case "load-graph":
        this.MeterService.getAlarmGraph(
          alarm,
          alarm.contador,
          alarm.metrology_type
        );
        break;
      case "to-pending":
        this.MeterService.toCheck({
          id: alarm.contador,
          nroSerie: alarm.nroSerie,
        });
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "edit-alarms":
        this.MaterialDialogService.openDialog(
          AlarmEditDialogComponent,
          this.tableSelectedData
        );
        break;
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "clean-alarms":
        this.requestQueue.setTask(
          "cleanAlarms",
          this.tableSelectedData
            .filter((alarm) => alarm.fabricante == 23)
            .map((alarm) => {
              return {
                id: alarm.contador,
                nroSerie: alarm.nroSerie,
                metrologyType: alarm.metrologyType,
              };
            })
        );
        break;
      default:
        break;
    }
  }

  // Habilitar alarma
  enableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-enabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.enableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-enabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }

  // Deshabilitar alarma
  disableAlarm(alarm: AlarmData): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("alarm-disabled-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AlarmController.disableAlarms(alarm.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("alarm-disabled")
            );
            this.getAlarms(this.from, this.to);
          }
        });
      }
    });
  }

  // Editar alarma
  editAlarm(alarm: AlarmData): void {
    this.MaterialDialogService.openDialog(AlarmEditDialogComponent, [alarm]);
  }

  // Redirección a la gráfica de datos
  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((device: AlarmData) => {
          return device.contador;
        }),
        to: this.to,
      },
    });
  }

  // Actualización de los datos seleccionados en la tabla
  selectedDataUpdate(data: AlarmData[]): void {
    this.tableSelectedData = [...data];
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    let devices: AlarmData[] = [];
    this.tableSelectedData.forEach((device: AlarmData) => {
      let pushedDeviceIndex: number = devices?.findIndex(
        (pushedDevice: AlarmData) => pushedDevice.nroSerie == device.nroSerie
      );
      if (pushedDeviceIndex >= 0) {
        if (devices[pushedDeviceIndex].initDate < device.initDate) {
          devices.splice(pushedDeviceIndex, 1);
          devices.push(device);
        }
      } else {
        devices.push(device);
      }
    });
    this.devices = devices;
    setTimeout(() => this.viewportScroller.scrollToAnchor("map-panel"), 0);
  }

  // Ir a revisión
  goToPending(): void {
    this.router.navigate(["dispositivos/listado/revision"]);
  }
  changeSelectedActiveAlarms(event: any) {
    this.showInactives = event?.value;
    if (this.showInactives == false) {
      this.dateRange = "full";
    } else {
      this.dateRange = "fiveDays";
    }
    this.getData();
  }
}
