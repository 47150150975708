// @angular
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
// Servicios propios
import { HttpRequestService } from "../shared/HttpRequestServices/HttpRequestService.service";
// Interfaces
import {
  Entity,
  EntityVisibility,
} from "../../screens/dashboard/entities/EntityInterface.type";
import { EntityConf } from "../../screens/dashboard/management/ManagementInterface.type";
// Variables
import { PROFILES } from "../../../assets/profiles/profiles";

@Injectable({
  providedIn: "root",
})
export class EntityControllerService {
  constructor(private HttpRequestService: HttpRequestService) {}

  // Entidades a las que tiene permisos el usuario para el desplegable de la página
  getEntityByUser(): Observable<object> {
    return this.HttpRequestService.get("/entity/byuser");
  }

  // Clientes a los que tiene permisos el usuario (Arson)
  getEntityByUserArson(): Observable<object> {
    return this.HttpRequestService.get("/entity/byuser-arson");
  }

  getEntityBy(user: string): Observable<object> {
    switch (user) {
      case PROFILES.ARSON:
        return this.getEntityByUserArson();
      default:
        return this.getEntityByUser();
    }
  }

  // Obtener el logo de la entidad
  getImg(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity/logo/" + entityId);
  }

  // Obtener información para precargar el formulario al actualizar una entidad
  getEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity/show/" + entityId);
  }

  // Guardar una entidad
  createEntity(data: Entity | FormData): Observable<object> {
    return this.HttpRequestService.post("/entity/save/", data);
  }

  // Actualizar una entidad
  updateEntity(data: Entity | FormData): Observable<object> {
    return this.HttpRequestService.post("/entity/update/", data);
  }

  // Eliminar una entidad
  deleteEntity(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity/delete/" + entityId);
  }

  // Listado de todas las enitdades
  getEntityList(): Observable<object> {
    return this.HttpRequestService.get("/entity/list");
  }

  // Actualizar restricción de visualización
  updateEntityVisibility(data: EntityVisibility): Observable<object> {
    return this.HttpRequestService.post("/entity/update-limit", data);
  }

  // Obtención de los datos de configuración de todas las entidades
  getAllEntitiesConfiguration(): Observable<object> {
    return this.HttpRequestService.get("/entity/conf/all");
  }

  // Actualizar restricción de visualización
  getEntityConf(entityId: number): Observable<object> {
    return this.HttpRequestService.get("/entity/conf/entity/" + entityId);
  }

  // Actualizar restricción de visualización
  saveEntityConf(data: EntityConf): Observable<object> {
    return this.HttpRequestService.post("/entity/conf/save", data);
  }

  // Borrado de datos de una entidad
  deleteEntityData(entityId: number): Observable<object> {
    return this.HttpRequestService.get(
      "/delete-data/by-conf/entity/" + entityId
    );
  }

  // Actualización de alias de entidad
  updateEntityAlias(data: {
    idEntity: number;
    alias: string;
  }): Observable<object> {
    return this.HttpRequestService.post("/entity/update/alias", data);
  }

  // Número total de dispositivos activos en la entidad
  getEntityTotalDevices(entityIds: number[]): Observable<object> {
    return this.HttpRequestService.post(
      "/contador/entity/count-activated",
      entityIds
    );
  }
}
