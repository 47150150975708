export interface TableDataColumn {
  title: string;
  data: string;
  search: string;
  sort: string;
  visible: boolean;
  excluding?: boolean;
  link?: string;
  linkCheck?: { condition: string; attribute: string; check: string };
  numerical?: boolean;
  boolean?: boolean;
  date?: boolean;
  dateLocal?: boolean;
  long?: boolean;
  extraTable?: boolean;
  extraTableLength?: boolean;
  extraInfo?: boolean;
  graph?: boolean;
  batteryGraph?: boolean;

  energyGraph?:boolean;
  html?: boolean;
  htmlData?: string;
  graphData?: string;
  modalInfo?: boolean;
  noExport?: boolean;
  export?: any;
  pinned?: boolean;
  grouped?: boolean;
  isSorted?: boolean;
  color?: string;
  alter?: {
    text?: string;
    condition: string;
    skins?: {
      rule: boolean | string;
      class: string;
    }[];
  };
  typology?: {
    showAttribute: string;
    filterAttribute: string;
    typologyList?: { active: boolean; name: string; id: any }[];
  };
  showTotal?: boolean;
  showAverage?: boolean;
  total?: number | string;
  average?: number | string;
  totalReferenceColumns?: string[];
}

export interface TableActionColumn {
  title: string;
  data: TableAction[];
  visible: boolean;
  pinned?: boolean;
  grouped?: boolean;
}

export interface TableSelectColumn {
  title: string;
  search: string;
  sort: string;
  visible: boolean;
  excluding?: boolean;
  disabled?: string;
  pinned?: boolean;
  grouped?: boolean;
}

export interface TableAction {
  name: string;
  tooltip: string;
  icon: string;
  visible: { attribute: string; rule: boolean };
  disabled: boolean | string;
  warning?: boolean;
}

export interface ExtraTableData {
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  data: any[];
  rowNumbers?: boolean;
  highlightRow?: { condition: string; color: string }[];
}

export interface TableQuickFilter {
  name: string;
  columnSearch: string;
  condition: { type: string; rule: boolean | number | string };
  active: boolean;
}

export interface TableHighlightRow {
  condition: string;
  color: string;
  title?: string;
}

export interface TableCupsFilters {
  name: string;
  data: string;
  value: string | number;
}

export interface TableGlobalAction {
  title: string;
  icon: string;
  disabled?: boolean;
  selectionRequired?: boolean;
  selectionExtraRequired?: boolean;
  profiles?: string[];
  class?: string;
  help?: string;
  submenu?: TableGlobalAction[];
}

export interface TableSearchFilter {
  inputData: any;
  inputDataString: string;
  selectedFilter: string;
  selectedFilterRaw: string;
  espComparison: boolean;
  dateComparison: boolean;
  booleanComparison: boolean;
  numericalComparison: boolean;
  showNull: boolean;
  label: string;
  inverse?: boolean;
}

export interface ImportColumn {
  title: string;
  info: string;
  required: boolean;
  profile?: boolean;
  profilesList?: string[];
  extraInfo?: string;
}

export interface DialogData {
  comparisonType: string;
  timezone: string;
}

export interface DialogAction {
  action: string;
  filterData: string;
  inputDataString?: string;
  showNull: boolean;
}

export interface TableGroupBy {
  groupAttribute: string;
  groupName: string;
}
