import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Turf
import * as Turf from "@turf/turf";
// Mathjs
import * as Mathjs from "mathjs";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { GatewayControllerService } from "../../../../../../services/server/GatewayController.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
// Interfaces
import {
  DeviceCoords,
  GeolocationGateway,
} from "../../../DeviceInterface.type";
import { GatewayTableMeter } from "../../../../../../interfaces/GatewayGlobalInterface.type";
import { TableDataColumn } from "../../../../../../modules/table-module/TableInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";

@Component({
  selector: "app-device-geolocation-dialog",
  templateUrl: "./device-geolocation-dialog.component.html",
  styleUrls: ["./device-geolocation-dialog.component.scss"],
})
export class DeviceGeolocationDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  gatewaysData: GeolocationGateway[] = [];
  meterData: any[] = [];
  agrupationOptions: Agrupation[];
  referenceAgrupationSelected: Agrupation;
  preselectedAgrupation: number;
  estimatedPonderationLocation: number[] = [];
  estimatedTrilaterationLocation: number[] = [];
  @ViewChild("geolocationData") geolocationData: ElementRef;
  @ViewChild("geolocationMapContainer") geolocationMapContainer: ElementRef;
  activateAllLayers: boolean;

  // Tabla
  gatewayColumns: TableDataColumn[] = [
    {
      title: "gateway",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
      link: "gatewayLink",
    },
    {
      title: "RSSI",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-communication-local",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "latitude",
      data: "latitude",
      search: "latitude",
      sort: "latitude",
      numerical: true,
      visible: true,
    },
    {
      title: "longitude",
      data: "longitude",
      search: "longitude",
      sort: "longitude",
      numerical: true,
      visible: true,
    },
    {
      title: "estimated-distance",
      data: "estimatedDistance",
      search: "estimatedDistance",
      sort: "estimatedDistance",
      numerical: true,
      visible: true,
    },
    {
      title: "meters",
      data: "metersCount",
      search: "metersCount",
      sort: "metersCount",
      visible: true,
    },
    {
      title: "more-info",
      data: null,
      search: "secondaryTableFlag",
      sort: "secondaryTableFlag",
      extraTable: true,
      visible: true,
      noExport: true,
    },
  ];
  meterColumns: TableDataColumn[] = [
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "RSSI",
      data: "rssiParsed",
      search: "rssiParsed",
      sort: "rssi",
      numerical: true,
      visible: true,
    },
    {
      title: "last-communication-local",
      data: "rssiTimestampParsed",
      search: "rssiTimestampParsed",
      sort: "rssiTimestamp",
      date: true,
      dateLocal: true,
      visible: true,
    },
    {
      title: "latitude",
      data: "latitude",
      search: "latitude",
      sort: "latitude",
      numerical: true,
      visible: true,
    },
    {
      title: "longitude",
      data: "longitude",
      search: "longitude",
      sort: "longitude",
      numerical: true,
      visible: true,
    },
    {
      title: "distance",
      data: "distance",
      search: "distance",
      sort: "distance",
      numerical: true,
      visible: true,
    },
  ];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParser: DateParserService,
    private GatewayController: GatewayControllerService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Agrupaciones disponibles en selector
    let agrupationOptions =
      this.SessionDataService.getCurrentEntity().agrupations;
    let virtualIndex = agrupationOptions.findIndex(
      (agrupation) => agrupation.showAllEntity
    );
    let virtual = agrupationOptions.splice(virtualIndex, 1);
    agrupationOptions.sort((a, b) => a.name.localeCompare(b.name));
    this.agrupationOptions = [...virtual, ...agrupationOptions];

    // Datos iniciales
    let currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.preselectedAgrupation = currentAgrupation.id;
    this.referenceAgrupationSelected = currentAgrupation;
    this.meterData = [this.data.device];
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  getGatewaysData(): void {
    // Gateways con los que ha comunicado en el último día
    this.gatewaysData = null;
    let gatewaysData = JSON.parse(
      JSON.stringify(
        this.data.gateways.filter(
          (gateway) =>
            gateway.rssiTimestamp >
            this.DateParser.getLastDays(1).startDate.valueOf()
        )
      )
    );
    // Ordenamiento por nivel de señal
    gatewaysData.sort((a, b) => b.rssi - a.rssi);
    gatewaysData = gatewaysData.slice(0, 5);
    // Petición de contadores de cada gateway
    let requests = gatewaysData.map((gateway) =>
      this.GatewayController.getGatewayMeters(
        gateway.id,
        this.referenceAgrupationSelected.id
      )
    );
    forkJoin(requests).subscribe((responses: any[]) => {
      responses.map((response, i) => {
        if (response["code"] == 0) {
          let meters = response["body"];
          // Filtrado de contadores que han comunicado en el último día
          let validMeters = meters.filter(
            (meter: GatewayTableMeter) =>
              meter.contadorRssiTimestamp >
              this.DateParser.getLastDays(1).startDate.valueOf()
          );
          // Parseo y ordenamiento por nivel de señal
          gatewaysData[i].meters = validMeters
            .map((meter) => {
              return {
                nroSerie: meter.contadorNroSerie,
                latitude: meter.latitude,
                longitude: meter.longitude,
                rssi: meter.contadorRssi,
                rssiTimestamp: meter.contadorRssiTimestamp,
                rssiTimestampParsed: this.DateParser.parseDate(
                  meter.contadorRssiTimestamp,
                  "L HH:mm:ss",
                  "Europe/Madrid"
                ),
                distance: Turf.distance(
                  Turf.point([
                    gatewaysData[i].longitude,
                    gatewaysData[i].latitude,
                  ]),
                  Turf.point([meter.longitude, meter.latitude]),
                  { units: "meters" }
                ),
              };
            })
            .sort((a, b) => b.rssi - a.rssi);
          gatewaysData[i].metersCount = gatewaysData[i].meters.length;

          // Datos para la tabla
          gatewaysData.map((gateway) => {
            gateway.extraTableData = {
              columns: this.meterColumns,
              data: gateway.meters,
              rowNumbers: true,
            };
          });
        }
      });
      this.calculateLocationByGatewayTrilateration(gatewaysData.slice(0, 3));
      this.calculateLocationByMetersPonderation(gatewaysData);
      this.gatewaysData = gatewaysData;
      this.addNewLocation();
      this.activateAllLayers = !this.activateAllLayers;
    });
  }

  // Calcular distancias usando promedio ponderado
  calculateLocationByGatewayTrilateration(
    gatewaysData: GeolocationGateway[]
  ): void {
    let estimatedDistances = [];
    gatewaysData.forEach((gateway) => {
      // Referencias para calibración
      const calibrateReferences = this.calculatePathLossExponent(gateway);
      // Estimación de distancia
      let estimatedDistance = this.estimateDistance(
        gateway.rssi,
        calibrateReferences.rssiReference,
        calibrateReferences.pathLossExponent
      );
      gateway.estimatedDistance = estimatedDistance;
      estimatedDistances.push(estimatedDistance);
    });

    // Cálculo de la ubicación
    let location = this.trilateration(estimatedDistances, gatewaysData);

    // Escalado de rangos si no interseccionan
    if (!location) {
      let locationScaled: any = this.locationScaled(
        gatewaysData[0].latitude,
        gatewaysData[0].longitude,
        gatewaysData[0].estimatedDistance,
        gatewaysData[1].latitude,
        gatewaysData[1].longitude,
        gatewaysData[1].estimatedDistance,
        gatewaysData[2].latitude,
        gatewaysData[2].longitude,
        gatewaysData[2].estimatedDistance
      );
      if (locationScaled[1] != null) {
        location = locationScaled[0];
        gatewaysData.map(
          (gateway) =>
            (gateway.estimatedDistanceScaled = Turf.distance(
              Turf.point([gateway.longitude, gateway.latitude]),
              Turf.point([locationScaled[0][1], locationScaled[0][0]]),
              { units: "meters" }
            ))
        );
      }
    }

    // Datos para mapa
    this.estimatedTrilaterationLocation = location ? location : [];
    this.gatewaysData = gatewaysData;
  }

  // Añadir resultado a mapa
  addNewLocation(): void {
    let meterData = [this.data.device];

    if (this.estimatedPonderationLocation?.length > 0) {
      let newDeviceLocation = JSON.parse(JSON.stringify(this.data.device));
      newDeviceLocation.latitude = this.estimatedPonderationLocation[0];
      newDeviceLocation.longitude = this.estimatedPonderationLocation[1];
      newDeviceLocation.selected = true;
      newDeviceLocation.nroSerie = this.translate.instant(
        "meter-geolocation-ponderation"
      );
      meterData.push(newDeviceLocation);
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("location-calculation-error")
      );
    }

    if (this.estimatedTrilaterationLocation?.length > 0) {
      let newDeviceLocation = JSON.parse(JSON.stringify(this.data.device));
      newDeviceLocation.latitude = this.estimatedTrilaterationLocation[0];
      newDeviceLocation.longitude = this.estimatedTrilaterationLocation[1];
      newDeviceLocation.selected = true;
      newDeviceLocation.nroSerie = this.translate.instant(
        "meter-geolocation-trilateration"
      );
      meterData.push(newDeviceLocation);
    } else {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("location-calculation-error")
      );
    }

    this.meterData = meterData;
  }

  // Calcular distancias usando promedio ponderado
  calculateLocationByMetersPonderation(
    gatewaysData: GeolocationGateway[]
  ): void {
    let distances = [];
    gatewaysData.forEach((gateway) => {
      // Referencias para calibración
      const calibrateReferences = this.calculatePathLossExponent(gateway);
      // Estimación de distancia para señal perdida
      const lost_dist = this.estimateDistance(
        gateway.rssi,
        calibrateReferences.rssiReference,
        calibrateReferences.pathLossExponent
      );
      // Distancias para contadores del gateway
      const ref_distances = gateway.meters.map((meter) => meter.distance);
      // Dar más peso a señales más fuertes
      const signal_weight = gateway.rssi > -100 ? 0.7 : 0.3;
      // Distancia media
      const avg_ref_distance =
        ref_distances.reduce((a, b) => a + b, 0) / ref_distances.length;
      // Combinar distancias
      const combined_distance =
        signal_weight * lost_dist + (1 - signal_weight) * avg_ref_distance;
      distances.push(combined_distance);
    });

    // Cálculo de la ubicación
    let location = this.getMeterLocation(gatewaysData, distances);
    this.estimatedPonderationLocation = location ? location : [];
  }

  // Función para calcular la ubicación
  getMeterLocation(gateways, distances): number[] {
    let x = 0,
      y = 0;
    for (let i = 0; i < gateways.length; i++) {
      const gw = gateways[i];
      const dist = distances[i];
      x += gw.latitude / dist ** 2;
      y += gw.longitude / dist ** 2;
    }
    const total = distances.reduce((sum, dist) => sum + 1 / dist ** 2, 0);
    let lat = x / total;
    let lon = y / total;
    return [lat, lon];
  }

  // Cálculo de pérdida
  calculatePathLossExponent(gateway: GeolocationGateway): {
    rssiReference: number;
    pathLossExponent: number;
  } {
    let filteredRssi = this.movingAverageFilter(
      gateway.meters.map((meter) => meter.rssi)
    );
    let filteredDistances = this.movingAverageFilter(
      gateway.meters.map((meter) => meter.distance).sort((a, b) => a - b)
    );
    let filteredMeters = gateway.meters.filter(
      (meter) =>
        meter.rssi <= filteredRssi[0] &&
        meter.rssi >= filteredRssi[filteredRssi.length - 1] &&
        meter.distance >= filteredDistances[0] &&
        meter.distance <= filteredDistances[filteredDistances.length - 1]
    );
    let linearRegression = this.linearRegression(
      filteredMeters.map((meter) => [meter.rssi, meter.distance]),
      0,
      1
    );
    // RSSI de referencia
    const minRssiReference = filteredMeters[filteredMeters.length - 1].rssi;
    return {
      rssiReference: -20,
      pathLossExponent: this.calibrateModel(
        [-20, minRssiReference],
        [
          1,
          minRssiReference * linearRegression.slope +
            linearRegression.intercept,
        ]
      ),
    };
  }

  // Filtro
  movingAverageFilter(values: number[], windowSize = 5) {
    const filteredValues = [];
    const window = new Array(windowSize).fill(1 / windowSize);

    for (let i = 0; i <= values.length - windowSize; i++) {
      const sum = window.reduce(
        (acc, val, index) => acc + values[i + index] * val,
        0
      );
      filteredValues.push(sum);
    }

    return filteredValues;
  }

  // Regresión lineal
  linearRegression(
    inputArray: number[][],
    xLabel: number | string,
    yLabel: number | string
  ): { intercept: number; slope: number } {
    const x = inputArray.map((element) => element[xLabel]);
    const y = inputArray.map((element) => element[yLabel]);
    const sumX = x.reduce((prev, curr) => prev + curr, 0);
    const avgX = sumX / x.length;
    const xDifferencesToAverage = x.map((value) => avgX - value);
    const xDifferencesToAverageSquared = xDifferencesToAverage.map(
      (value) => value ** 2
    );
    const SSxx = xDifferencesToAverageSquared.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const sumY = y.reduce((prev, curr) => prev + curr, 0);
    const avgY = sumY / y.length;
    const yDifferencesToAverage = y.map((value) => avgY - value);
    const xAndYDifferencesMultiplied = xDifferencesToAverage.map(
      (curr, index) => curr * yDifferencesToAverage[index]
    );
    const SSxy = xAndYDifferencesMultiplied.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const slope = SSxy / SSxx;
    const intercept = avgY - slope * avgX;
    return { intercept: intercept, slope: slope };
  }

  // Estimación de distancia basada en señal dBm
  estimateDistance(rssi, referenceRssi = -20, pathLossExponent = 3.0): number {
    return 10 ** ((rssi - referenceRssi) / (-10 * pathLossExponent));
  }

  // Calibración de modelo de pérdida de ruta
  calibrateModel(rssiValues, distances): number {
    const n =
      (rssiValues[0] - rssiValues[1]) /
      (10 * Math.log10(distances[1] / distances[0]));
    return n;
  }

  // Actualización de la posición del contador
  changeDevicePosition(location: number[]): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("change-location-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data: DeviceCoords = {
          id: this.data.device.id,
          latitude: location[0],
          longitude: location[1],
        };
        this.MeterController.newCoords(data).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("change-location-sucessfull")
            );
            this.SessionDataService.sendReloadPanelFlag();
          }
        });
      }
    });
  }

  // Escalado de rangos de gateways hasta intersección
  locationScaled(
    x1: number,
    y1: number,
    r1: number,
    x2: number,
    y2: number,
    r2: number,
    x3: number,
    y3: number,
    r3: number
  ) {
    const M = Mathjs.inv(
      Mathjs.multiply(
        2,
        Mathjs.matrix([
          [x2 - x1, y2 - y1],
          [x3 - x2, y3 - y2],
        ])
      )
    );
    const A = Mathjs.matrix([r1 ** 2 - r2 ** 2, r2 ** 2 - r3 ** 2]);
    const B = Mathjs.matrix([
      x2 ** 2 + y2 ** 2 - x1 ** 2 - y1 ** 2,
      x3 ** 2 + y3 ** 2 - x2 ** 2 - y2 ** 2,
    ]);
    const A_result = Mathjs.multiply(M, A);
    const B_result = Mathjs.multiply(M, B);
    const x1_y1 = Mathjs.matrix([x1, y1]);

    const a = Mathjs.dot(A_result, A_result);
    const b =
      2 * Mathjs.dot(B_result, A_result) -
      2 * Mathjs.dot(x1_y1, A_result) -
      r1 ** 2;
    const c =
      x1 * x1 +
      y1 * y1 -
      2 * Mathjs.dot(B_result, x1_y1) +
      Mathjs.dot(B_result, B_result);

    const k = (-b - Math.sqrt(Math.abs(b * b - 4 * a * c))) / (2 * a);
    return [
      Mathjs.add(Mathjs.multiply(k, A_result), B_result).toArray(),
      Math.sqrt(k),
    ];
  }

  // Función para calcular la ubicación
  trilateration(distances, gateways): number[] {
    const x1 = gateways[0].latitude;
    const y1 = gateways[0].longitude;
    const d1 = distances[0];
    const x2 = gateways[1].latitude;
    const y2 = gateways[1].longitude;
    const d2 = distances[1];
    const x3 = gateways[2].latitude;
    const y3 = gateways[2].longitude;
    const d3 = distances[2];

    const A = 2 * (x2 - x1);
    const B = 2 * (y2 - y1);
    const C = d1 ** 2 - d2 ** 2 - x1 ** 2 + x2 ** 2 - y1 ** 2 + y2 ** 2;
    const D = 2 * (x3 - x1);
    const E = 2 * (y3 - y1);
    const F = d1 ** 2 - d3 ** 2 - x1 ** 2 + x3 ** 2 - y1 ** 2 + y3 ** 2;

    const x = (C * E - F * B) / (E * A - B * D);
    const y = (C * D - A * F) / (B * D - A * E);

    if (x < -90 || x > 90 || y < -180 || y > 180) {
      return null;
    }
    return [x, y];
  }
}
