<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "gateways-list" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de gateways -->
        <app-table-controller
          [tableId]="'clientEntityGateways'"
          [columns]="columns"
          [rowNumbers]="true"
          [orderBy]="orderBy"
          [maxReg]="tableMaxReg"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
