// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin, Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Moment
import moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../../../modules/material-module/material-dialog/material-dialog.service";
import { GraphOptionsService } from "../../../../../../modules/graph-module/GraphOptionsService.service";
// Componentes
import { TableControllerComponent } from "../../../../../../modules/table-module/table-controller/table-controller.component";
import { MapControllerComponent } from "../../../../../../modules/map-module/map-controller/map-controller.component";
import { MeterSubstitutionDialogComponent } from "./meter-substitution-dialog/meter-substitution-dialog.component";
// Interfaces
import { Entity } from "../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableGlobalAction,
  TableHighlightRow,
  TableQuickFilter,
} from "../../../../../../modules/table-module/TableInterface.type";
import { SubstitutionDevice } from "../../../DeviceInterface.type";
import { PendingDevice } from "../../../DeviceInterface.type";
import { EntityDefinition } from "../../../../../../interfaces/CupsGlobalInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../../../modules/graph-module/GRAPH_CONFIG";
import { PROFILES } from "../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meter-substitutions",
  templateUrl: "./meter-substitutions.component.html",
  styleUrls: ["./meter-substitutions.component.scss"],
})
export class MeterSubstitutionsComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  cups: EntityDefinition;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  dialogSub: Subscription;

  // Tabla
  tableSelectedData: SubstitutionDevice[];
  tableMaxReg: number = 20;
  meterList: SubstitutionDevice[];
  originalMeterList: SubstitutionDevice[];
  orderBy: object = { attribute: "date", reverse: true };
  exportFileName: string =
    this.translate.instant("meters-export-substitutions") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  stayOnRoute: boolean = false;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  from: string;
  to: string;
  extraColumns: EntityDefinition[];
  tableGlobalActions: TableGlobalAction[];
  tableHighlightRow: TableHighlightRow[] = [
    { condition: "resubstitutedBy", color: "yellow", title: "resubstituted" },
  ];
  quickFilters: TableQuickFilter[][] = [
    [
      {
        name: "resubstituted",
        columnSearch: "resubstitutedBy",
        condition: { type: "boolean", rule: true },
        active: false,
      },
      {
        name: "substitution-success",
        columnSearch: "resubstitutedBy",
        condition: { type: "boolean", rule: false },
        active: false,
      },
    ],
  ];

  // Gráfica
  graphSeries: object;
  graphData: number[][];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];

  // Mapa
  @ViewChild("meterSubstitutionMap")
  meterSubstitutionMapController: MapControllerComponent;
  mapType: string = "substitutions";
  mapHeight: number = 500;
  mapData: (SubstitutionDevice | PendingDevice)[];
  activateAllLayers: boolean;

  // Fuente de datos
  substitutionDataSources: { id: number; name: string; type: string }[];
  selectedDataSource: { id: number; name: string; type: string };

  // Descarga de informe
  downloadIcon: string = "fas fa-download";
  downloadTitle: string = this.translate.instant("substitution-file-download");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GraphOptionsService: GraphOptionsService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
        if (!this.stayOnRoute) {
          this.RouteCheckService.stayOnRoute("agrupation") ||
          this.sessionProfile == PROFILES.ADMIN_INSTALLATION
            ? this.ReloadComponentService.reload()
            : this.router.navigate(["/principal"]);
        } else {
          this.stayOnRoute = false;
        }
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
          this.getCupsConf();
          this.setColumns();
        }
      );

    this.dialogSub = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getData(this.from, this.to);
        }
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.dialogSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Configuración de CUPS
    this.getCupsConf();

    // Informe
    if (
      this.currentEntity.entity.toUpperCase() == "SANTA MARIA DE GUIA" &&
      (this.sessionProfile == PROFILES.ARSON ||
        this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
        this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
        this.sessionProfile == PROFILES.ADMIN_AGRUPACION ||
        this.sessionProfile == PROFILES.USUARIO_CLIENTE ||
        this.sessionProfile == PROFILES.USUARIO_ENTIDAD ||
        this.sessionProfile == PROFILES.USUARIO_AGRUPACION)
    ) {
      this.tableGlobalActions = [
        {
          title: "generate-report",
          icon: "fas fa-file-pdf",
          selectionRequired: true,
        },
      ];
    }

    // Agrupación en curso
    this.substitutionDataSources = [
      {
        id: this.currentAgrupation.id,
        name:
          this.translate.instant("groups") + ": " + this.currentAgrupation.name,
        type: "agrupation",
      },
    ];

    // Agrupación virtual
    let virtual = this.currentEntity.agrupations.find(
      (agrupation: Agrupation) => agrupation.showAllEntity
    );
    if (virtual) {
      this.substitutionDataSources.push({
        id: virtual.id,
        name: this.translate.instant("groups") + ": " + virtual.name,
        type: "agrupation",
      });
    }

    // Entidad en curso
    this.substitutionDataSources.push({
      id: this.currentEntity.id,
      name: this.translate.instant("entity") + ": " + this.currentEntity.entity,
      type: "entity",
    });

    this.selectedDataSource = this.substitutionDataSources[0];

    // Obtención de los datos
    this.setHighchartsOptions();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de la configuración de CUPS
  getCupsConf(): void {
    if (this.entityCupsConf) {
      this.extraColumns = [...this.entityCupsConf];
      this.cups = this.extraColumns.find(
        (column: EntityDefinition) => column.colPosition == 0
      );
      let cupsIndex: number = this.extraColumns.findIndex(
        (column: EntityDefinition) => column.colPosition == 0
      );
      if (cupsIndex >= 0) {
        this.extraColumns.splice(cupsIndex, 1);
      }
    }
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    let meterList: SubstitutionDevice[] = [];
    this.getDataSourceUrl().subscribe((response) => {
      if (response["code"] == 0) {
        meterList = response["body"];
        meterList.forEach((meter: SubstitutionDevice) => {
          meter.newDeviceLink =
            meter.idNew != null &&
            this.sessionProfile != PROFILES.ADMIN_INSTALLATION
              ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  meter.metrologyTypeNew,
                  meter.idNew
                )
              : null;
          meter.oldDeviceLink =
            meter.idOld != null &&
            this.sessionProfile != PROFILES.ADMIN_INSTALLATION
              ? this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  meter.metrologyTypeOld,
                  meter.idOld
                )
              : null;
          if (meter.cm) {
            for (let attribute in meter.cm) {
              meter[attribute] = meter.cm[attribute];
            }
          }
          meter.cups = meter.isAssigned ? meter.clave : null;
          meter.imageDisabled = !meter.newHasImage && !meter.oldHasImage;
          meter.audioDisabled = !meter.hasAudio;
          meter.selectDisabled = meter.resubstitutedBy != null;
          meter.pending = false;
          meter.editDisabled =
            (this.sessionProfile != PROFILES.ARSON &&
              this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
              this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
              this.sessionProfile != PROFILES.ADMIN_AGRUPACION) ||
            meter.resubstitutedBy != null;
        });

        this.setColumns();
        this.meterList = this.sortMeters(meterList);
        this.originalMeterList = [...this.meterList];
        this.getMapData();
        this.getSeries();
      }
    });
  }

  // Ordenar sustituciones fallidas
  sortMeters(meterList: SubstitutionDevice[]): SubstitutionDevice[] {
    let resubstituted = meterList.filter(
      (meter: SubstitutionDevice) => meter.resubstitutedBy
    );
    let fixed = meterList.filter(
      (meter: SubstitutionDevice) => !meter.resubstitutedBy
    );
    if (this.sessionProfile == PROFILES.ARSON && resubstituted?.length > 0) {
      let sorted = [...fixed.sort((a, b) => b.id - a.id)];
      resubstituted.sort((a, b) => b.resubstitutedBy - a.resubstitutedBy);
      resubstituted.forEach((resubstitution: SubstitutionDevice) => {
        let resubstitutionIndex = sorted.findIndex(
          (substitution: SubstitutionDevice) =>
            substitution.id == resubstitution.resubstitutedBy
        );
        if (resubstitutionIndex >= 0) {
          sorted.splice(resubstitutionIndex, 0, resubstitution);
        }
      });
      return sorted;
    }
    return fixed;
  }

  // Obtención de la url de la fuente de datos
  getDataSourceUrl(): Observable<object> {
    if (this.selectedDataSource.type == "agrupation") {
      return this.MeterController.getSubstitutionListByAgrupation(
        this.selectedDataSource.id,
        this.from,
        this.to
      );
    } else {
      return this.MeterController.getSubstitutionListByEntity(
        this.selectedDataSource.id,
        this.from,
        this.to
      );
    }
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "show-image",
            tooltip: "show-image",
            icon: "fas fa-image",
            visible: { attribute: null, rule: true },
            disabled: "imageDisabled",
          },
          {
            name: "play-audio",
            tooltip: "play-audio",
            icon: "fas fa-play",
            visible: { attribute: null, rule: true },
            disabled: "audioDisabled",
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled: "editDisabled",
          },
        ],
        visible: true,
      },
      {
        title: "select",
        data: null,
        search: null,
        sort: null,
        visible: true,
      },
      {
        title: "groups",
        data: "agrupation",
        search: "agrupation",
        sort: "agrupation",
        visible:
          this.selectedDataSource?.type == "agrupation" &&
          this.selectedDataSource?.id == this.currentAgrupation.id &&
          !this.currentAgrupation.showAllEntity
            ? null
            : true,
      },
      {
        title: "serial-number-old",
        data: "nroSerieOld",
        search: "nroSerieOld",
        sort: "nroSerieOld",
        visible: true,
        link: "oldDeviceLink",
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupationId",
          check: "id",
        },
      },
      {
        title: "last-know-value",
        data: "valueOldParsed",
        search: "valueOldParsed",
        sort: "valueOld",
        visible: true,
        numerical: true,
      },
      {
        title: "serial-number-new",
        data: "nroSerieNew",
        search: "nroSerieNew",
        sort: "nroSerieNew",
        visible: true,
        link: "newDeviceLink",
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupationId",
          check: "id",
        },
      },
      {
        title: "rf-module",
        data: "rfModuleNew",
        search: "rfModuleNew",
        sort: "rfModuleNew",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: true,
      },
      {
        title: "value-new",
        data: "valueNewParsed",
        search: "valueNewParsed",
        sort: "valueNew",
        visible: true,
        numerical: true,
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "user",
        data: "user",
        search: "user",
        sort: "user",
        visible: true,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: true,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: true,
      },
      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
      {
        title: this.cups?.name,
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: this.cups != null ? true : null,
      },
    ];

    this.extraColumns?.forEach((extraColumn: EntityDefinition) => {
      if (extraColumn.show) {
        let attributePosition: string =
          extraColumn.colPosition <= 9
            ? "col0" + extraColumn.colPosition
            : "col" + extraColumn.colPosition;
        if (extraColumn.colPosition != 100) {
          let newColumn: TableDataColumn = {
            title: extraColumn.name,
            data: attributePosition,
            search: attributePosition,
            sort: attributePosition,
            long: true,
            visible: true,
          };
          columns.push(newColumn);
        }
      }
    });
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, substitution: SubstitutionDevice): void {
    switch (action) {
      case "play-audio":
        this.playSubstitutionAudio(substitution);
        break;
      case "show-image":
        this.showSubstitutionImages(substitution);
        break;
      case "edit":
        this.MaterialDialogService.openDialog(
          MeterSubstitutionDialogComponent,
          {
            show: "edit",
            substitution: { ...substitution },
          }
        );
      default:
        break;
    }
  }

  // Audio de sustitución
  playSubstitutionAudio(substitution: SubstitutionDevice): void {
    this.MeterController.getSubstitutionAudio(substitution.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          if (response["body"]) {
            this.MaterialDialogService.openDialog(
              MeterSubstitutionDialogComponent,
              {
                show: "audio",
                substitution: substitution,
                audio: response["body"],
              }
            );
          } else {
            this.ToastService.fireToast(
              "warning",
              this.translate.instant("audio-not-found")
            );
          }
        }
      }
    );
  }

  // Imágenes de sustitución
  showSubstitutionImages(substitution: SubstitutionDevice): void {
    let urls = [
      this.MeterController.getSubstitutionImage({
        substitutionId: substitution.id,
        nroSerie: substitution.nroSerieOld,
      }),
      this.MeterController.getSubstitutionImage({
        substitutionId: substitution.id,
        nroSerie: substitution.nroSerieNew,
      }),
    ];

    forkJoin(urls).subscribe((responses) => {
      let oldImage: any;
      let newImage: any;

      if (responses[0]["code"] == 0) {
        oldImage = responses[0]["body"];
      }
      if (responses[1]["code"] == 0) {
        newImage = responses[1]["body"];
      }

      this.MaterialDialogService.openDialog(MeterSubstitutionDialogComponent, {
        show: "images",
        substitution: substitution,
        oldImage: oldImage,
        newImage: newImage,
      });
    });
  }

  // Descarga de informe de sustituciones
  downloadSubstitutionFile(): void {
    this.MaterialDialogService.openDialog(MeterSubstitutionDialogComponent, {
      show: "file",
      agrupation: this.selectedDataSource.id,
      from: this.from,
      to: this.to,
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("substitutions")
      );
    highchartsOptions.plotOptions.series.dataGrouping.units = [["day", [1]]];
    highchartsOptions["plotOptions"]["series"]["marker"]["enabled"] = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["rangeSelector"]["buttons"].shift();
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] = "{value}";
    chartOptions["yAxis"][0]["title"]["text"] =
      this.translate.instant("substitutions");
    chartOptions["navigator"]["enabled"] = false;
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    // Lecturas
    this.graphSeries = [
      {
        id: "substitutions",
        name: this.translate.instant("substitutions"),
        type: "column",
        data: this.meterList
          .sort((a, b) => a.timestamp - b.timestamp)
          .map((meter: SubstitutionDevice) => {
            return [meter.timestamp, 1];
          }),
        dataGrouping: { approximation: "sum" },
        color: "#42a5f5",
      },
    ];
    this.setChartsOptions();
  }

  // Obtención de los datos del mapa
  getMapData(): void {
    this.MeterController.getPendingSubstitutions(
      this.selectedDataSource.type == "agrupation"
        ? this.selectedDataSource.id
        : this.substitutionDataSources[this.substitutionDataSources.length - 2]
            .id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let pendingDevices = response["body"];
        pendingDevices.map(
          (pendingDevice: PendingDevice) => (pendingDevice.pending = true)
        );
        this.mapData = JSON.parse(JSON.stringify(this.meterList)).concat(
          pendingDevices
        );
        this.activateAllLayers = !this.activateAllLayers;
      }
    });
  }

  // Actualización de selección en mapa
  updateMapSelected(): void {
    this.mapData.map(
      (data) =>
        (data.selected = this.tableSelectedData.some(
          (selected) => selected.id == data.id
        ))
    );
    this.meterSubstitutionMapController?.updateSelected();
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "generate-report":
        this.MaterialDialogService.openDialog(
          MeterSubstitutionDialogComponent,
          {
            show: "report",
            entity: this.currentEntity.entity,
            data: this.tableSelectedData?.filter(
              (device: SubstitutionDevice) => !device.resubstitutedBy
            ),
          }
        );
        break;
      default:
        break;
    }
  }
}
