<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "edit" | translate }}
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Alias -->
    <mat-form-field class="alarm-edit-comment" appearance="standard">
      <mat-label>{{ "alias" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="data.alias"></textarea>
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button mat-button class="mat-button-icon-text" (click)="editEntityAlias()">
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
