import { Injectable } from "@angular/core";
import { SepemDecoderService } from "./sepem-decoder.service";

@Injectable({
  providedIn: "root",
})
export class AimeiDecoderService {
  constructor(private SepemDecoderService: SepemDecoderService) {}

  FPORT_PERIODIC_UPLINK = 100;
  FPORT_PERIODIC_UPLINK_SHORT = 101;
  FPORT_INSTANT_ALARM = 102;
  FPORT_INSTANT_ALARM_SHORT = 103;
  FPORT_FRAGMENT = 105;

  Aimei_decoder(frmPayload, fport) {
    if (frmPayload) {
      if (frmPayload.bytes) {
        var outputData = {};
        switch (fport) {
          case this.FPORT_PERIODIC_UPLINK:
            if (frmPayload.bytes.length != 51) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  51 bytes)",
                ],
              };
            } else {
              return {
                data: this.periodicUplinkFrameDecoder(frmPayload.bytes, fport),
              };
            }
            break;
          case this.FPORT_PERIODIC_UPLINK_SHORT:
            if (frmPayload.bytes.length != 11) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  11 bytes)",
                ],
              };
            } else {
              return {
                data: this.periodicUplinkShortFrameDecoder(
                  frmPayload.bytes,
                  fport
                ),
              };
            }
          case this.FPORT_INSTANT_ALARM:
            if (frmPayload.bytes.length != 19) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  19 bytes)",
                ],
              };
            } else {
              return {
                data: this.alarmFrameDecoder(frmPayload.bytes, fport),
              };
            }
          case this.FPORT_INSTANT_ALARM_SHORT:
            if (frmPayload.bytes.length != 11) {
              return {
                errors: [
                  "LONGITUD ERRÓNEA: " +
                    frmPayload.bytes.length.toString() +
                    "bytes (debe ser  11 bytes)",
                ],
              };
            } else {
              return {
                data: this.alarmShortFrameDecoder(frmPayload.bytes, fport),
              };
            }
          case this.FPORT_FRAGMENT:
            return {
              data: { Tipo_Trama: "MAC ANSWER FRAME" },
            };
          default:
            return {
              errors: ["TIPO DE TRAMA DESCONOCIDO. Puerto " + fport.toString()],
            };
            break;
        }
      } else {
        return {
          errors: ["Unknown frmPayload.bytes."],
        };
      }
    } else {
      return {
        errors: ["Unknown frmPayload."],
      };
    }
  }

  parseaAlarmas(meterStatus) {
    var objetoAlarmas = {};
    if (meterStatus != 0) {
      if (meterStatus & (1 << 0)) {
        objetoAlarmas["Abnormal Data check"] = true;
      }
      if (meterStatus & (1 << 1)) {
        objetoAlarmas["Abnormal Parameter check"] = true;
      }
      if (meterStatus & (1 << 3)) {
        objetoAlarmas["Storage Error"] = true;
      }
      if (meterStatus & (1 << 4)) {
        objetoAlarmas["RF Error"] = true;
      }
      if (meterStatus & (1 << 5)) {
        objetoAlarmas["Measurement Error"] = true;
      }
      if (meterStatus & (1 << 7)) {
        objetoAlarmas["High water temperature"] = true;
      }
      if (meterStatus & (1 << 8)) {
        objetoAlarmas["Empty pipe"] = true;
      }
      if (meterStatus & (1 << 10)) {
        objetoAlarmas["Reverse flow"] = true;
      }
      if (meterStatus & (1 << 11)) {
        objetoAlarmas["Leakage"] = true;
      }
      if (meterStatus & (1 << 12)) {
        objetoAlarmas["Burst"] = true;
      }
      switch ((meterStatus >> 14) & 0x03) {
        case 0x00: {
          objetoAlarmas["Measuring battery Undervoltage"] = true;
          break;
        }
        case 0x02: {
          objetoAlarmas["Measuring battery Low voltage"] = true;
          break;
        }
        case 0x03: {
          objetoAlarmas["Measuring battery High voltage"] = true;
          break;
        }
      }
      if (meterStatus & (1 << 16)) {
        objetoAlarmas["Low water temperature"] = true;
      }
      if (meterStatus & (1 << 18)) {
        objetoAlarmas["No consumption"] = true;
      }
      switch ((meterStatus >> 22) & 0x03) {
        case 0x00: {
          objetoAlarmas["Communication battery Undervoltage"] = true;
          break;
        }
        case 0x02: {
          objetoAlarmas["Communication battery Low voltage"] = true;
          break;
        }
        case 0x03: {
          objetoAlarmas["Communication battery High voltage"] = true;
          break;
        }
      }
    }
    return objetoAlarmas;
  }

  /* Decoder de Periodic Uplink Frame*/
  periodicUplinkFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "PERIODIC UPLINK FRAME" };

    var referenceEpoch = new Date(
      2010,
      0,
      1,
      0,
      0,
      0
    ); /* El timestamp del contador está referido a 1-1-2010 00:00:00 UTC+1*/

    var rawTimestamp = this.readU32Lsb(bytes, 0);
    var currentIndex = this.readU32Lsb(bytes, 4);

    var rawFrozenTimestamp = this.readU32Lsb(bytes, 8);
    var frozenIndex = this.readU32Lsb(bytes, 12);

    var txTimestamp = new Date(rawTimestamp * 1000 + referenceEpoch.getTime());
    outputData["Fecha_actual"] = txTimestamp.toLocaleString("es-ES");
    outputData["Valor_actual"] =
      (currentIndex * 0.001).toLocaleString("es-ES") + " m3";

    var referenceDate = new Date(
      rawFrozenTimestamp * 1000 + referenceEpoch.getTime()
    );
    outputData["VALORES"] = {
      [referenceDate.toLocaleString("es-ES")]:
        (frozenIndex / 1000).toFixed(3) + " m3",
    };
    for (let ndxDelta = 0; ndxDelta < 15; ndxDelta++) {
      var delta = this.readU16Lsb(bytes, 16 + 2 * ndxDelta);

      frozenIndex -= delta;
      referenceDate = new Date(referenceDate.getTime() - 60 * 60 * 1000);
      outputData["VALORES"][referenceDate.toLocaleString("es-ES")] =
        (frozenIndex / 1000).toFixed(3) +
        " m3 (-" +
        delta.toString() +
        " litros)";
    }
    outputData["Operating_days"] = this.readU16Lsb(bytes, 46);
    var meterStatus = this.readU24Lsb(bytes, 48);
    if (meterStatus != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(meterStatus);
    }
    return outputData;
  }

  /* Decoder de Periodic Uplink Short Frame*/
  periodicUplinkShortFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "PERIODIC UPLINK SHORT FRAME" };

    var referenceEpoch = new Date(
      2010,
      0,
      1,
      1,
      0,
      0
    ); /* El timestamp del contador está referido a 1-1-2010 00:00:00 UTC+1*/

    var rawTimestamp = this.readU32Lsb(bytes, 0);
    var currentIndex = this.readU32Lsb(bytes, 4);
    var meterStatus = this.readU24Lsb(bytes, 8);

    var txTimestamp = new Date(rawTimestamp * 1000 + referenceEpoch.getTime());
    outputData["Fecha_actual"] = txTimestamp.toLocaleString("es-ES");
    outputData["Valor_actual"] =
      (currentIndex * 0.001).toLocaleString("es-ES") + " m3";
    if (meterStatus != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(meterStatus);
    }
    return outputData;
  }

  /* Decoder de Alarm Frame*/
  alarmFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "ALARM FRAME" };

    var referenceEpoch = new Date(
      2010,
      0,
      1,
      1,
      0,
      0
    ); /* El timestamp del contador está referido a 1-1-2010 00:00:00 UTC+1*/

    var rawTimestamp = this.readU32Lsb(bytes, 0);
    var currentIndex = this.readU32Lsb(bytes, 4);
    var dataTag = this.readU16Lsb(bytes, 8);
    var alarmType = this.readU16Lsb(bytes, 10);
    var comBatteryVoltage = this.readU16Lsb(bytes, 12) / 100;
    var measBatteryVoltage = this.readU16Lsb(bytes, 14) / 100;
    var meterStatus = this.readU24Lsb(bytes, 16);

    var txTimestamp = new Date(rawTimestamp * 1000 + referenceEpoch.getTime());
    outputData["Fecha_actual"] = txTimestamp.toLocaleString("es-ES");
    outputData["Valor_actual"] =
      (currentIndex * 0.001).toLocaleString("es-ES") + " m3";
    if (dataTag != 0x8001) {
      outputData["Data_Tag"] =
        "0x" +
        dataTag.toString(16).padStart(4, "0") +
        " - ERROR, debe ser 0x8001";
    } else {
      outputData["Data_Tag"] = "0x8001";
    }
    switch (alarmType) {
      case 0x0001: {
        outputData["Alarm_type"] = "0x0001 - BURST";
        break;
      }
      case 0x0002: {
        outputData["Alarm_type"] = "0x0002 - LEAKAGE";
        break;
      }
      case 0x0003: {
        outputData["Alarm_type"] = "0x0003 - REVERSE FLOW";
        break;
      }
      case 0x0004: {
        outputData["Alarm_type"] = "0x0004 - COMMUNICATION BATTERY LOW";
        break;
      }
      case 0x0005: {
        outputData["Alarm_type"] = "0x0005 - MEASURING BATTERY LOW";
        break;
      }
      case 0x0006: {
        outputData["Alarm_type"] = "0x0006 - WATER TEMPERATURE";
        break;
      }
    }
    outputData["Com_battery"] =
      comBatteryVoltage.toLocaleString("es-ES") + " V";
    outputData["Meas_battery"] =
      measBatteryVoltage.toLocaleString("es-ES") + " V";
    if (meterStatus != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(meterStatus);
    }
    return outputData;
  }

  /* Decoder de Alarm Short Frame*/
  alarmShortFrameDecoder(bytes, fport) {
    var outputData = { Tipo_Trama: "ALARM SHORT FRAME" };

    var referenceEpoch = new Date(
      2010,
      0,
      1,
      1,
      0,
      0
    ); /* El timestamp del contador está referido a 1-1-2010 00:00:00 UTC+1*/

    var rawTimestamp = this.readU32Lsb(bytes, 0);
    var dataTag = this.readU16Lsb(bytes, 4);
    var alarmType = this.readU16Lsb(bytes, 6);
    var meterStatus = this.readU24Lsb(bytes, 8);

    var txTimestamp = new Date(rawTimestamp * 1000 + referenceEpoch.getTime());
    outputData["Fecha_actual"] = txTimestamp.toLocaleString("es-ES");
    if (dataTag != 0x8001) {
      outputData["Data_Tag"] =
        "0x" +
        dataTag.toString(16).padStart(4, "0") +
        " - ERROR, debe ser 0x8001";
    } else {
      outputData["Data_Tag"] = "0x8001";
    }
    switch (alarmType) {
      case 0x0001: {
        outputData["Alarm_type"] = "0x0001 - BURST";
        break;
      }
      case 0x0002: {
        outputData["Alarm_type"] = "0x0002 - LEAKAGE";
        break;
      }
      case 0x0003: {
        outputData["Alarm_type"] = "0x0003 - REVERSE FLOW";
        break;
      }
      case 0x0004: {
        outputData["Alarm_type"] = "0x0004 - COMMUNICATION BATTERY LOW";
        break;
      }
      case 0x0005: {
        outputData["Alarm_type"] = "0x0005 - MEASURING BATTERY LOW";
        break;
      }
      case 0x0006: {
        outputData["Alarm_type"] = "0x0006 - WATER TEMPERATURE";
        break;
      }
    }
    if (meterStatus != 0) {
      outputData["Alarmas"] = this.parseaAlarmas(meterStatus);
    }
    return outputData;
  }

  readU32Lsb(bytes, start) {
    var res =
      (bytes[start + 3] << 24) +
      (bytes[start + 2] << 16) +
      (bytes[start + 1] << 8) +
      bytes[start];
    return res;
  }

  readU24Lsb(bytes, start) {
    var res = (bytes[start + 2] << 16) + (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  readU16Lsb(bytes, start) {
    var res = (bytes[start + 1] << 8) + bytes[start];
    return res;
  }

  Aimei_Descriptor(frame) {
    if (frame) {
      var frameBytes = this.SepemDecoderService.hexToBytes(frame);

      var fType = (frameBytes[0] >> 5) & 0x07;
      switch (fType) {
        case 0:
          return "JOIN REQUEST";
        case 1:
          return "JOIN ACCEPT";
        case 2:
        case 4:
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case this.FPORT_PERIODIC_UPLINK:
                return "PERIODIC UPLINK";
              case this.FPORT_PERIODIC_UPLINK_SHORT:
                return "PERIODIC UPLINK SHORT";
              case this.FPORT_INSTANT_ALARM:
                return "ALARMA";
              case this.FPORT_INSTANT_ALARM_SHORT:
                return "ALARMA CORTA";
              case this.FPORT_FRAGMENT:
                return "RESPUESTA MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 3:
        case 5:
          var fOptsLen = frameBytes[5] & 0x0f;
          var fOptsLen = frameBytes[5] & 0x0f;
          if (frameBytes.length >= 9 + fOptsLen + 4) {
            let fport = frameBytes[8 + fOptsLen];
            switch (fport) {
              case 0x00:
                return "MAC";
              default:
                return "";
            }
          } else {
            return "";
          }
          break;
        case 6:
          return "RFU";
        case 7:
          return "PROPRIETARY";
      }
    } else {
      return "";
    }
  }
}
