<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ data.id ? ("edit" | translate) : ("create" | translate) }}
      {{ "extra-data" | translate }}
    </h5>
  </div>
  <div class="dialog-body data-configuration-dialog">
    <!-- Nombre -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "name" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="data.name"
        placeholder="{{ 'name' | translate }}..."
        required
      />
    </mat-form-field>
    <!-- Label -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "label" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="data.label"
        placeholder="{{ 'label' | translate }}..."
        required
      />
    </mat-form-field>
    <!-- Descripción -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "description" | translate }}</mat-label>
      <input
        matInput
        [(ngModel)]="data.description"
        placeholder="{{ 'description' | translate }}..."
      />
    </mat-form-field>
    <!-- Ver en tabla -->
    <mat-checkbox class="mat-option" [(ngModel)]="data.show">
      {{ "show-table" | translate }}
    </mat-checkbox>
    <!-- Requerido -->
    <mat-checkbox class="mat-option" [(ngModel)]="data.required">
      {{ "required" | translate }}
    </mat-checkbox>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="saveExtraColumn()"
      [disabled]="
        data.name == null ||
        data.name == '' ||
        data.label == null ||
        data.label == ''
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
