import moment from "moment";
import { Card } from "../../../modules/card-module/CardInterface.type";

// ANCHOR Contador de CUPS
export interface CupsDevice {
  id: number;
  nroSerie: number;
  agrupation: number;
  alias: string;
  devAddr: string;
  flags: string;
  devEui: string;
  appEui: string;
  networkKey: string;
  idFabricante: number;
  idDevType: number;
  minSf: number;
  txPower: number;
  keys: string[];
  lastRssi: number;
  lastDataRate: number;
  activate: number;
  longitude: number;
  latitude: number;
  gateway: string;
  timezone: string;
  lastJoin: number;
  lastSnr: number;
  lastCommunication: number;
  rfModule: string;
  comments: string;
  adrPermission: number;
  meterImage: string[];
  lastReadedValue: number;
  lastReadedTimestamp: number;
  installation: number;
  metrologyType: number;
  valveState: number;
  lastBatValue: number;
  cyble5EncKey: string;
  cyble5AuthKey: string;
  cyble5PulseWeight: number;
  isBMode: boolean;
  consumptionCards: CupsConsumptionCards;
  metrologicalConf: CupsMetrologicalConf;
  tipo: number;
  enRevision: boolean;
  revisionComments: string;
  address: string;
  mbusKey: string;
  listaConcentradores: {
    idConcentrador: number;
    nroSerieConcentrador: string;
  }[];
  comunica: boolean;
  type: string;

  // Front End
  isLora?: boolean;
  lastReadedTimestampParsed?: string;
  lastReadedValueParsed?: string;
}

// ANCHOR Configuración metrológica
export interface CupsMetrologicalConf {
  id: number;
  contador: number;
  contadorNroSerie: string;
  conversionFactor: number;
  pcs: string;
}

// ANCHOR Gateway de CUPS
export interface CupsGateway {
  id: number;
  unidadVenta: string;
  longitude: string;
  latitude: string;
  rssi: number;
  rssiTimestamp: number;
  snr: number;
  mainMeters: number;
}

// ANCHOR Alarmas activas de CUPS
export interface CupsAlarm {
  // BackEnd
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;

  // FrontEnd
  initDateParsed?: moment.Moment;
  name?: string;
  description?: string;
}

// ANCHOR Sensor de CUPS
export interface CupsSensor {
  // BackEnd
  id: number;
  nroSerie: string;
  devType: number;
  fabricante: number;
  latitude: number;
  longitude: number;
  lastRssi: number;
  lastDataRate: number;
  gateway: number;
  gateway_name: string;
  metrologyType: number;
  comunica: boolean;
  tipo: number;

  // FrontEnd
  manufacturerText?: string;
  modelText?: string;
}

// ANCHOR Tarjetas de consumo
export interface CupsConsumptionCards {
  monthlyAverage: number;
  currentMonth: number;
  lastMonth: number;
  lastMonthlyValue: number;
  dailyAverage: number;
}

// ANCHOR Histórico de CUPS
export interface CupsHistorical {
  // BackEnd
  nroSerie: string;
  fabricante: number;
  devType: number;
  metrologyType: number;
  installation: number;
  uninstallation: number;

  // FrontEnd
  installationParsed?: string;
  uninstallationParsed?: string;
  metrologyTypeParsed?: string;
}

// ANCHOR Sector de CUPS
export interface CupsSector {
  id: number;
  name: string;
  description: string;
  type: number;
  agrupation: number;
}

// ANCHOR Dispositivo asignable a CUPS
export interface CupsAssignableDevice {
  id: number;
  nroSerie: string;
  clave?: string;
}

// ANCHOR Datos de tarjetas
export class CupsCardsData {
  public lastReadedTimestamp: string;
  public lastReadedValue: number;
  public consumptionCards: CupsConsumptionCards;

  constructor() {
    this.lastReadedTimestamp = null;
    this.lastReadedValue = null;
    this.consumptionCards = null;
  }
}

// ANCHOR Tarjetas de CUPS
export interface CupsCards {
  meterDetailLastDataMonth: Card;
  meterDetailLastMonthConsume: Card;
  meterDetailConsumeMonthAverage: Card;
  meterDetailCurrentMonthConsume: Card;
  meterDetailConsumeAverageDaily: Card;
}

// ANCHOR Datos de gráfica de CUPS
export interface CupsGraphData {
  readings: number[][];
  alarms: CupsAlarm[];
  meter?: number;
  nroSerie?: string;
}

// ANCHOR Subscriptor de CUPS
export interface CupsSubscriber {
  // BackEnd
  id: number;
  username: string;
  name: string;
  surname: string;

  // FrontEnd
  label?: string;
}

// ANCHOR Asignación de CUPS
export interface CupsUser {
  userId: number;
  cupsList: number[];
}
