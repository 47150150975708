// @angular
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  AfterViewInit,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Subscription } from "rxjs";
// Servicios propios
import { EntityControllerService } from "../../../../services/server/EntityController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";

@Component({
  selector: "app-entity-total-snapshot",
  templateUrl: "./entity-total-snapshot.component.html",
  styleUrls: ["./entity-total-snapshot.component.scss"],
})
export class EntityTotalSnapshotComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  currentEntity: Entity;
  entitySub: Subscription;

  entityTotals: {
    activated: number;
    project: number;
    license: number;
    projectParsed: string;
    licenseParsed: string;
    activatedParsed: string;
  };
  @ViewChild("entityTotalsGraph") entityTotalsGraph: ElementRef;
  @Input() entity: Entity;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private EntityController: EntityControllerService,
    private SessionDataService: SessionDataService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (!this.entity) {
      this.currentEntity = this.SessionDataService.getCurrentEntity();

      this.entitySub = this.SessionDataService.getEntity().subscribe(
        (entity) => {
          this.currentEntity = entity;
          if (this.currentEntity && this.entityTotalsGraph) {
            this.getEntityTotals();
          }
        }
      );
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.entity || this.currentEntity) {
        this.getEntityTotals();
      }
    }, 0);
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub?.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  setChart(): void {
    let max = Math.max(
      this.entityTotals.project,
      this.entityTotals.license,
      this.entityTotals.activated
    );
    this.entityTotalsGraph?.nativeElement.style.setProperty(
      "--entity-totals-graph-project",
      (this.entityTotals.project / max) * 100
    );
    this.entityTotalsGraph?.nativeElement.style.setProperty(
      "--entity-totals-graph-license",
      (this.entityTotals.license / max) * 100
    );
    this.entityTotalsGraph?.nativeElement.style.setProperty(
      "--entity-totals-graph-actives",
      (this.entityTotals.activated / max) * 100
    );
  }

  // Obtención de los totales por entidad
  getEntityTotals(): void {
    this.EntityController.getEntityTotalDevices([
      this.entity ? this.entity.id : this.currentEntity.id,
    ]).subscribe((response) => {
      if (response["code"] == 0) {
        let entityTotals = response["body"][0];
        let numberFormat = this.SessionDataService.getCurrentNumberFormat();
        if (
          entityTotals?.project != null &&
          entityTotals?.license != null &&
          entityTotals?.activated != null
        ) {
          this.entityTotals = {
            project: entityTotals.project,
            license: entityTotals.license,
            activated: entityTotals.activated,
            projectParsed: formatNumber(entityTotals.project, numberFormat),
            licenseParsed: formatNumber(entityTotals.license, numberFormat),
            activatedParsed: formatNumber(entityTotals.activated, numberFormat),
          };
          this.setChart();
        } else {
          this.entityTotals = null;
        }
      }
    });
  }
}
