<div *ngIf="_data" class="balance-graphs-container">
  <!-- Posibles contadores faltantes -->
  <div
    *ngIf="possibleMeters?.length > 0"
    class="balance-graphs-possibles-container"
  >
    <div class="balance-graphs-possibles-title">
      <div>
        <span
          ><b>{{ "balance-found-devices" | translate }}:</b>
          {{ possibleMeters.length }} {{ "of" | translate }}
          {{ maxMeters }}</span
        ><span
          ><b>{{ "reference-value" | translate }}:</b> {{ differenceMax }}</span
        >
      </div>
      <app-material-select
        [title]="'order-by' | translate"
        [bindValue]="'value'"
        [bindLabel]="'name'"
        [selected]="0"
        [data]="possiblesOrder"
        (selectedOption)="sortPossiblesBy = $event.value; balancePossibleSort()"
      ></app-material-select>
    </div>

    <!-- Scroll -->
    <div class="balance-graphs-possibles">
      <cdk-virtual-scroll-viewport
        #cdkVirtualScrollViewPort
        orientation="horizontal"
        itemSize="50"
        class="balance-virtual-scroll"
      >
        <div
          *cdkVirtualFor="let meterData of possibleMeters"
          class="balance-possible-meter"
        >
          <div class="balance-graph-possible">
            <i
              class="pointer fas"
              [ngClass]="{
                'fa-eye-slash disabled': meterData.disabled,
                'fa-eye-slash': meterData.selected,
                'fa-eye': !meterData.selected
              }"
              [title]="'show-in-graph' | translate"
              (click)="
                meterData.selected = !meterData.selected; updateGraphPossibles()
              "
            ></i>
            {{ meterData.meter.nroSerie }}
            <div class="balance-graph-possible-actions">
              <i
                class="fas fa-minus pointer"
                [ngClass]="{ hidden: !meterData.disabled }"
                (click)="removeChild(meterData)"
                [title]="'balance-child-quit' | translate"
              ></i>
              <i
                class="fas fa-plus pointer"
                [ngClass]="{
                  hidden:
                    meterData.disabled ||
                    (meterData.meter.otherAgrupation &&
                      !(
                        currentAgrupation.showAllEntity &&
                        currentAgrupation.id == _data.agrupation
                      ))
                }"
                (click)="addChild(meterData)"
                [title]="'balance-child-add' | translate"
              ></i>
            </div>
          </div>
          <div class="balance-graph-similarity">
            <!-- Agrupación -->
            <div
              class="balance-graph-agrupation"
              [title]="'groups' | translate"
            >
              <i class="fas fa-map"></i>
              {{ meterData.meter.agrupationName }}
            </div>
            <div>
              <!-- Distancia -->
              <div [title]="'distance' | translate">
                <i class="fas fa-map-marker-alt"></i>
                {{ meterData.distance }} m
              </div>
              <!-- Valor medio -->
              <div [title]="'balance-average-value' | translate">
                <i class="fas fa-tachometer-alt"></i>
                {{ meterData.average }}
              </div>
              <!-- Similaridad -->
              <div
                class="pointer"
                [title]="'balance-similarity' | translate"
                (click)="meterData.showDifference = !meterData.showDifference"
              >
                <i class="fas fa-chart-area"></i>
                {{ meterData.similarity }}
              </div>
            </div>
          </div>
          <!-- Gráfica -->
          <div class="balance-graph-similarity-chart">
            <app-graph-mini
              class="card-graph"
              [data]="
                meterData.showDifference
                  ? meterData.graph
                  : { series: [meterData.graph.series[1]] }
              "
              [width]="200"
            >
            </app-graph-mini>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>

  <!-- Opciones de gráfica -->
  <div class="balance-graphs-difference">
    <button
      *ngIf="sessionProfile == PROFILES.ARSON && overDifference"
      mat-button
      class="mat-button-icon-text"
      (click)="checkBalance()"
      [disabled]="!graphData"
    >
      <i class="fas fa-balance-scale"></i
      >{{ "balance-find-possibles" | translate }}
    </button>
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="updateDifference()"
      [disabled]="!graphData"
    >
      <i class="fas fa-eye"></i
      >{{
        showPercentageDifference
          ? ("absolute-difference" | translate)
          : ("percentage-difference" | translate)
      }}
    </button>
  </div>

  <!-- Gráfica -->
  <div #balanceGraphs class="balance-graphs">
    <div class="balance-graph">
      <app-graph-controller
        [highchartsOptions]="highchartsOptions"
        [seriesDisplace]="true"
        (seriesDisplaceFlag)="
          displaceSeries(
            $event.seriesId,
            $event.displacement,
            $event.axis,
            $event.displacementSign
          )
        "
        [data]="chartOptions"
        [chartConstructor]="chartConstructor"
        [dateRange]="defaultDateRange"
        (dataRequest)="loadGraphData($event.from, $event.to)"
        [filters]="graphFilters"
        (filterFlag)="graphType = $event.value"
      >
      </app-graph-controller>
    </div>
    <div class="balance-graphs-readings-percentage">
      <div>
        <span>{{ "readings" | translate }} ({{ "fathers" | translate }})</span>:
        {{
          graphData?.parentPercentage != null
            ? graphData.parentPercentage + "%"
            : ("unknown" | translate)
        }}
        ({{ graphData?.parentTotalHours }}/{{ graphData?.totalHours }})
      </div>
      <div>
        <span>{{ "readings" | translate }} ({{ "childs" | translate }})</span>:
        {{
          graphData?.childPercentage != null
            ? graphData.childPercentage + "%"
            : ("unknown" | translate)
        }}
        ({{ graphData?.childTotalHours }}/{{ graphData?.totalHours }})
      </div>
    </div>
  </div>

  <!-- Gráfica circular -->
  <div
    #graphCircle
    class="balance-graph-circle balance-graph-circle-initial"
    draggable="true"
    (dragstart)="DragElementService.getDrapgPoint(graphCircle, $event)"
    (dragend)="
      DragElementService.setPosition(
        graphCircle,
        $event,
        'balance-graph-circle-initial',
        '--graph-circle-y',
        '--graph-circle-x'
      )
    "
  >
    <app-graph-controller
      *ngIf="
        !loading && circleGraphSeries?.length > 0 && circleHighchartOptions
      "
      [highchartsOptions]="circleHighchartOptions"
      [noOptionsParse]="true"
      [onlyGraph]="true"
      [data]="circleChartOptions"
    >
    </app-graph-controller>
    <div
      *ngIf="
        !loading &&
        circleGraphSeries?.length > 0 &&
        circleHighchartOptions &&
        vnr
      "
      class="balance-graph-circle-vnr"
    >
      VNR: {{ vnr + (_data.metrologyType == 2 ? " Nm³/h" : " m³/h") }}
    </div>
  </div>
</div>
