// @angular
import {
  HostListener,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { Subscription } from "rxjs";
// Servicios propios
import { BrowserStorageLocalService } from "../../../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
import { ManufacturerService } from "../../../services/shared/ManufacturerService.service";
// Interfaces
import { SessionData } from "../../../interfaces/SessionGlobalInterface.type";
// Animaciones
import { SLIDE_ANIMATION } from "../../../animations/animation";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";

@Component({
  selector: "app-main-panel",
  templateUrl: "./main-panel.component.html",
  styleUrls: ["./main-panel.component.scss"],
  animations: [SLIDE_ANIMATION],
})
export class MainPanelComponent implements OnInit, OnDestroy, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Stream de datos de sesión
  agrupationSub: Subscription;
  languageSub: Subscription;
  reloadPanelFlag: Subscription;

  logoImgUrl: string;

  @Input() startUrl: string;
  @Input() profileStartUrl: string;
  @Input() sessionUser: string;
  @Input() sessionProfile: string;
  sessionProfileSub: Subscription;
  @Input() sessionLanguage: string;
  @Input() loadSession: boolean;
  @Input() appVersion: { module: string; version: string }[];
  @Output() logoutFlag = new EventEmitter<any>();
  sessionData: SessionData;
  readonly PROFILES = PROFILES;

  // Spinner de carga
  disableSpinnerSub: Subscription;
  disableSpinner: boolean;
  spinnerTextSub: Subscription;
  spinnerText: string;
  showGame: boolean = false;
  @ViewChild("elseBlock") elseBlock: TemplateRef<any>;

  // Barra lateral
  sidebarLocked: boolean;

  // Responsive control
  hideDesktopMenus: boolean = false;
  showResponsiveMenuFlag: boolean = false;
  @HostListener("window:resize", ["$event"])
  onResize() {
    window.innerWidth < 980
      ? (this.hideDesktopMenus = true)
      : (this.hideDesktopMenus = false);
  }

  // Escucha de combinación para recargar componente
  @HostListener("window:keydown.F4", ["$event"])
  onKeyDown() {
    this.ReloadComponentService.reload();
  }

  // Permitir drop en ventana
  enableDropSub: Subscription;
  enableDrop: boolean;

  // Cesium
  cesiumMapActive: boolean = false;

  // Game
  gameActive: boolean;
  gameActiveSub: Subscription;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/
  constructor(
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private ManufacturerService: ManufacturerService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.ManufacturerService.loadManufacturers();

    // Control de ancho de pantalla
    window.innerWidth < 980
      ? (this.hideDesktopMenus = true)
      : (this.hideDesktopMenus = false);

    // Comprobación de url inicial
    if (this.loadSession) {
      this.sessionData =
        this.BrowserStorageLocalService.getJsonValue("session");
      if (this.sessionData.userId) {
        // Formato numérico
        this.SessionDataService.sendNumberFormat(
          this.sessionData.numberFormat
            ? this.sessionData.numberFormat
            : this.sessionData.language
        );
        // Formato de fecha
        this.SessionDataService.sendDateFormat(
          this.sessionData.dateFormat
            ? this.sessionData.dateFormat
            : "DD/MM/YYYY"
        );
        // Redirección
        this.SessionDataService.sendUrlReloadFlag(true);
        this.router.navigate([this.sessionData.url], {
          state: { data: this.sessionData.urlData },
        });
      } else {
        this.logoutFlag.emit();
      }
    } else {
      this.router.navigate([this.startUrl]);
    }

    // Escucha de cambios en idioma
    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language) => {
        this.sessionLanguage = language;
        this.ReloadComponentService.reload();
      }
    );

    // Escucha de cambios en perfil
    this.sessionProfileSub = this.SessionDataService.getProfile().subscribe(
      () => {
        this.ReloadComponentService.reload();
      }
    );

    // Escucha de la deshabilitación del spinner de carga
    this.disableSpinnerSub =
      this.SessionDataService.getDisableSpinner().subscribe(
        (disableSpinner) => {
          this.disableSpinner = disableSpinner;
        }
      );

    // Escucha de cambios en el texto del spinner de carga
    this.spinnerTextSub = this.SessionDataService.getSpinnerText().subscribe(
      (spinnerText) => {
        this.spinnerText = spinnerText;
      }
    );

    this.reloadPanelFlag =
      this.SessionDataService.getReloadPanelFlag().subscribe(() => {
        this.reloadPanel();
      });

    // Escucha de la habilitación de drag and drop
    this.enableDropSub = this.SessionDataService.getComponentData().subscribe(
      (data) => {
        if (data?.enableDrop != null) {
          this.enableDrop = data.enableDrop;
        }
      }
    );

    // Escucha de la habilitación de juego en pantalla de carga
    this.gameActiveSub = this.SessionDataService.getGameActive().subscribe(
      (gameActive) => {
        this.gameActive = gameActive;
      }
    );
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.TemplateService.add("elseBlock", this.elseBlock);
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
    this.sessionProfileSub.unsubscribe();
    this.disableSpinnerSub.unsubscribe();
    this.spinnerTextSub.unsubscribe();
    this.enableDropSub.unsubscribe();
    this.reloadPanelFlag.unsubscribe();
    this.gameActiveSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  logoImgUrlUpdate(url: any): void {
    this.logoImgUrl = url;
  }

  logout(): void {
    this.logoutFlag.emit();
  }

  prepareRoute(outlet: RouterOutlet): void {
    return outlet?.activatedRouteData?.animation;
  }

  // Recarga de panel
  reloadPanel(): void {
    this.ReloadComponentService.reload();
  }
}
