<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{title}}</b>
        </h5>
        <!-- Botón actualizar -->
        <app-material-panel-menu [onlyButton]="true" [title]="updateTitle" [disabled]="updating" [updating]="updating"
          (action)="updateData()">
        </app-material-panel-menu>
      </div>
      <div class="panel-body">
        <!-- Tabla de dispositivos ignorados -->
        <app-table-controller [tableId]="'metersIgnored'" [export]="true" [exportSelection]="true"
          [exportFileName]="exportFileName" [columns]="columns" [rowNumbers]="true" [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)" [quickFilters]="quickFilters"
          [specialFilter]="true" (selectedDataUpdateFlag)="tableSelectedData = $event"
          [data]="meterList">
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
