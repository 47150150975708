<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">Offset</h5>
  </div>
  <div class="dialog-body satelite-scheduler-offset">
    <!-- Signo -->
    <mat-radio-group [(ngModel)]="data.offsetSign">
      <mat-radio-button value="1">+</mat-radio-button>
      <mat-radio-button value="-1">-</mat-radio-button>
    </mat-radio-group>
    <!-- Horas -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "hours" | translate }}</mat-label>
      <input
        matInput
        type="number"
        [min]="0"
        [max]="23"
        (keyup.enter)="
          SessionDataService.sendDialogAction({ action: 'offset', data: data })
        "
        [(ngModel)]="data.schedulerOffsetHour"
      />
    </mat-form-field>
    <!-- Minutos -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "minutes" | translate }}</mat-label>
      <input
        matInput
        type="number"
        [min]="0"
        [max]="59"
        (keyup.enter)="
          SessionDataService.sendDialogAction({ action: 'offset', data: data })
        "
        [(ngModel)]="data.schedulerOffsetMinute"
      />
    </mat-form-field>
  </div>
  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="SessionDataService.sendDialogAction({ action: 'offset', data: data })"
      [disabled]="!data.schedulerOffsetHour && !data.schedulerOffsetMinute"
    >
      <i class="fas fa-history"></i>
      {{ "apply" | translate }}
    </button>
    <button mat-button (click)="SessionDataService.sendDialogAction({ action: 'close' })">
      {{ "close" | translate }}
    </button>
  </div>
</div>
