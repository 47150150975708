<!-- Modal de nueva orden de trabajo -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "new" | translate }}</h5>
  </div>
  <div class="dialog-body new-order-dialog">
    <!-- Tipo -->
    <app-material-select
      [title]="'type' | translate"
      [bindValue]="'id'"
      [bindLabel]="'value'"
      [fullWidth]="true"
      [data]="orderTypeList"
      (selectedOption)="orderType = $event?.id"
      [required]="true"
    ></app-material-select>
    <!-- Comentarios -->
    <mat-form-field class="new-order-comments" appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea matInput [(ngModel)]="orderComments"></textarea>
    </mat-form-field>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="orderType == null"
      (click)="saveOrder()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
