<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ "test-devices" | translate }}</b>
        </h5>

        <!-- Menú de opciones del panel -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="'fas fa-plus'"
          [title]="'new' | translate"
          (action)="newEk()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Error de conexión con servidor EK -->
        <div *ngIf="serverConnError" class="telemetry-server-error">
          <i class="fas fa-exclamation-triangle"></i>
          <h3>{{ "ek-server-connection-error" | translate }}</h3>
          <i class="fas fa-exclamation-triangle"></i>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'controlTest'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
