// @angular
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription, Observable, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import moment_timezone from "moment-timezone";
// Servicios propios
import { EntityControllerService } from "../../../../services/server/EntityController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
// Interfaces
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../EntityInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { MaterialSelectOption } from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../../global/LOCAL_TIMEZONE";
import { PROFILES } from "../../../../../assets/profiles/profiles";

@Component({
  selector: "app-entityform",
  templateUrl: "./entity-form.component.html",
  styleUrls: ["./entity-form.component.scss"],
})
export class EntityFormComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentClient: Client;
  clientList: Client[];
  clientListSub: Subscription;
  agrupationList: Agrupation[];
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Entidad
  id: number;
  newImages: any;
  imagePreview: any;
  imageUpdated: boolean;
  fileName: string;
  entityData: Entity;
  initialClient: number;
  initialAgrupation: number;

  // Borrado de datos
  dataRestriction: boolean;

  // Campos personlizados
  customFields = [
    { name: this.translate.instant("filter-free"), value: 0 },
    { name: "API Id", value: 1 },
  ];
  selectedCustomField: number;

  // Zona horaria
  initialTimezone: string;
  tzNames: string[];
  timezoneOptions: MaterialSelectOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private domSanitizer: DomSanitizer,
    private EntityController: EntityControllerService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.clientList = this.SessionDataService.getCurrentClientList();
    this.currentClient = this.SessionDataService.getCurrentClient();

    // Escucha de cambios en los valores de agrupación
    this.clientListSub = this.SessionDataService.getClientList().subscribe(
      (clientList) => {
        this.clientList = clientList;
        this.RouteCheckService.stayOnRoute("client")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.clientList) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientListSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.id = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.tzNames = moment_timezone.tz.names();
    this.timezoneOptions = this.tzNames?.map((timezone: string) => {
      return { value: timezone, name: timezone };
    });

    // Si se edita entidad
    if (this.id) {
      let requestUrls = [this.EntityController.getEntity(this.id)];

      // Configuración de borrado
      if (this.sessionProfile == PROFILES.ARSON) {
        requestUrls.push(this.EntityController.getEntityConf(this.id));
      }

      forkJoin(requestUrls).subscribe((responses) => {
        // Datos de entidad
        if (responses[0]["code"] == 0) {
          let entityData = responses[0]["body"];
          entityData.imageFile = entityData.imageFile
            ? this.domSanitizer.bypassSecurityTrustUrl(
                "data:image/png;base64, " + entityData.imageFile
              )
            : null;
          this.dataRestriction = entityData.limitDays > 0;
          if (entityData.limitDays == 0) {
            entityData.limitDays = null;
          }
          this.entityData = entityData;
          this.initialClient = this.entityData.client;
          this.initialAgrupation = this.entityData.defaultAgrupation;
          this.selectedCustomField = this.entityData.isAqualia ? 1 : null;
          this.agrupationList = this.currentClient.entityList
            .find((entity) => entity.id == this.id)
            ?.agrupations?.filter((agrupation) => !agrupation.showAllEntity);
          this.initialTimezone = entityData.timezone;
        }

        // Datos de borrado
        if (
          this.sessionProfile == PROFILES.ARSON &&
          responses[1]["code"] == 0
        ) {
          this.entityData.entity = responses[1]["body"]?.entity;
          this.entityData.dDays = responses[1]["body"]?.days;
          this.entityData.readings = responses[1]["body"]?.readings;
          this.entityData.consumptions = responses[1]["body"]?.consumptions;
          this.entityData.events = responses[1]["body"]?.events;
          this.entityData.frames = responses[1]["body"]?.frames;
          this.entityData.alarms = responses[1]["body"]?.alarms;
          this.entityData.ddConf = responses[1]["body"]?.enabled;
        }
      });
      // Si se crea nueva entidad
    } else {
      this.newImages = null;
      this.imagePreview = null;
      this.imageUpdated = null;
      this.fileName = null;
      this.dataRestriction = null;
      this.entityData = new Entity();
      this.entityData.client = this.currentClient
        ? this.currentClient.clientId
        : null;
      this.initialClient = this.currentClient.clientId;
      this.entityData.timezone = LOCAL_TIMEZONE;
      this.initialTimezone = LOCAL_TIMEZONE;
    }
  }

  // Guardado de entidad
  saveEntity(): void {
    let formData: FormData = new FormData();
    let newEntityData = JSON.parse(JSON.stringify(this.entityData));
    newEntityData.imageFile = this.imageUpdated
      ? this.newImages
        ? new File([this.newImages], this.fileName)
        : null
      : null;
    newEntityData.updateImage = this.imageUpdated ? "1" : "0";

    // Conversión a tipo formulario
    let booleanAttributes = [
      "isAqualia",
      "readings",
      "consumptions",
      "events",
      "frames",
      "alarms",
      "ddConf",
    ];
    for (let attribute in newEntityData) {
      if (
        attribute == "limitDays" &&
        this.dataRestriction &&
        newEntityData[attribute] != null
      ) {
        formData.append(attribute, newEntityData[attribute]);
      } else if (newEntityData[attribute] != null) {
        formData.append(attribute, newEntityData[attribute]);
      } else if (
        newEntityData[attribute] == null &&
        booleanAttributes.includes(attribute)
      ) {
        formData.append(attribute, "false");
      }
    }

    // Creación/Actualización
    let saveUrl: Observable<object> = this.id
      ? this.EntityController.updateEntity(formData)
      : this.EntityController.createEntity(formData);

    saveUrl.subscribe((response) => {
      if (response["code"] == 0) {
        this.ToastService.fireToast("success", this.translate.instant("saved"));
        this.router.navigate(["/entidades/listado"]);
        this.SessionDataService.clearUpdateNavFlag();
      }
    });
  }

  // Eliminación de la imagen de entidad
  removeIcon(): void {
    if (this.entityData.imageFile) {
      this.imageUpdated = true;
    }
    this.entityData.imageFile = null;
    this.newImages = null;
    this.imagePreview = null;
    this.fileName = null;
  }

  // Cambio del archivo de imagen de entidad
  fileChangeEvent(event: any): void {
    this.fileName = event.target.files[0].name;
    this.newImages = event.target.files[0];
    this.getImagePreview(this.newImages);
    this.imageUpdated = true;
  }

  // Obtención de la preview del logo
  getImagePreview(img: any): void {
    let reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        this.imagePreview = reader.result;
      },
      false
    );

    if (img) {
      reader.readAsDataURL(img);
    }
  }
}
