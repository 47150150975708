// @angular
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Fichero
import saveAs from "file-saver";
// Servicios propios
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { DataConverterService } from "../../../../../services/shared/DataConverterService.service";
import { OutputFilesControllerService } from "../../../../../services/server/OutputFilesController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
// Interfaces
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { EkExtraData } from "../../ControlInterface.type";

@Component({
  selector: "app-control-telemetry-dialog",
  templateUrl: "./control-telemetry-dialog.component.html",
  styleUrls: ["./control-telemetry-dialog.component.scss"],
})
export class ControlTelemetryDialogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;

  // Tabla
  tableData: EkExtraData[];
  exportFileName: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  infoColumns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "date",
      data: "fechaParsed",
      search: "fechaParsed",
      sort: "fecha",
      date: true,
      visible: true,
    },
    {
      title: "ek-status",
      data: "s",
      search: "s",
      sort: "s",
      visible: true,
    },
    {
      title: "ek-status-reg",
      data: "sr",
      search: "sr",
      sort: "sr",
      visible: true,
    },
    {
      title: "ek-total-uncorrected-volume",
      data: "valorParsed",
      search: "valorParsed",
      sort: "valor",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-total-corrected-volume",
      data: "vcParsed",
      search: "vcParsed",
      sort: "vc",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-volume-uncorrected-error",
      data: "vmdParsed",
      search: "vmdParsed",
      sort: "vmd",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-total-corrected-volume-error",
      data: "vbdParsed",
      search: "vbdParsed",
      sort: "vbd",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-total-energy-error",
      data: "wstParsed",
      search: "wstParsed",
      sort: "wst",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-total-energy",
      data: "wgParsed",
      search: "wgParsed",
      sort: "wg",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-pressure",
      data: "pParsed",
      search: "pParsed",
      sort: "p",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-temperature",
      data: "tParsed",
      search: "tParsed",
      sort: "t",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-not-corrected-flow-total",
      data: "qmParsed",
      search: "qmParsed",
      sort: "qm",
      numerical: true,
      visible: true,
    },
    {
      title: "ek-corrected-flow-total",
      data: "qbParsed",
      search: "qbParsed",
      sort: "qb",
      numerical: true,
      visible: true,
    },
  ];
  errorColumns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "error-text",
      data: "errorParsed",
      search: "errorParsed",
      sort: "errorParsed",
      visible: true,
    },
    {
      title: "details",
      data: "detalle",
      search: "detalle",
      sort: "detalle",
      visible: true,
    },
    {
      title: "start-date",
      data: "fechaInicioParsed",
      search: "fechaInicioParsed",
      sort: "fechaInicio",
      date: true,
      visible: true,
    },
    {
      title: "end-date",
      data: "fechaFinParsed",
      search: "fechaFinParsed",
      sort: "fechaFin",
      date: true,
      visible: true,
    },
  ];

  // Descarga de fichero
  dateSelected: { startDate: moment.Moment; endDate: moment.Moment };
  fileType: string;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataConverterService: DataConverterService,
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private OutputFilesController: OutputFilesControllerService,
    public SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    if (this.data.action == "info") {
      this.exportFileName = this.data.nroSerie;
      this.getData(
        this.dataInitialDate.startDate.valueOf().toString(),
        this.dataInitialDate.endDate.valueOf().toString()
      );
    } else {
      this.fileType = this.data.meter ? "billing" : "values";
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Obtención todos los datos del EK
  getData(from: string, to: string) {
    let tableData = [];
    this.MeterController.getEkFullReadings(
      this.data.meter.id,
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        tableData = response["body"];
      }
      this.tableData = tableData;
    });
  }

  // Descarga de fichero
  downloadFile(): void {
    let fileName: string;
    let requestUrl: Observable<Object>;
    // Fichero de facturación
    if (this.data.action == "file-billing") {
      fileName =
        this.dateSelected.startDate.subtract(1, "d").format("DD-MM-YYYY") +
        "-ARSON__";
      requestUrl = this.OutputFilesController.getEkBillingFile(
        this.dateSelected.startDate.valueOf()
      );
      // Fichero de valores
    } else {
      fileName =
        this.dateSelected.startDate.format("DD-MM-YYYY") +
        "_" +
        this.dateSelected.endDate.format("DD-MM-YYYY") +
        "-ARSON";
      // Contador individual
      if (this.data.meter) {
        requestUrl = this.OutputFilesController.getEkValuesFile(
          [this.data.meter.id],
          this.dateSelected.startDate.valueOf(),
          this.dateSelected.endDate.valueOf()
        );
        // Múltiples contadores
      } else if (this.data.meters) {
        requestUrl = this.OutputFilesController.getEkValuesFile(
          this.data.meters,
          this.dateSelected.startDate.valueOf(),
          this.dateSelected.endDate.valueOf()
        );
      }
    }
    requestUrl.subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]?.file) {
          saveAs(
            this.DataConverterService.getDownloadBlobFromByteFile(
              response["body"].file,
              "text/plain;charset=ansi"
            ),
            fileName + ".csv"
          );
          this.SessionDataService.sendDialogAction({ action: "close" });
        } else {
          this.ToastService.fireToast(
            "warning",
            this.translate.instant("table-no-data")
          );
          this.SessionDataService.sendDialogAction({ action: "close" });
        }
      }
    });
  }
}
