import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-satelite-turns-clock",
  templateUrl: "./satelite-turns-clock.component.html",
  styleUrls: ["./satelite-turns-clock.component.scss"],
})
export class SateliteTurnsClockComponent implements OnInit, AfterViewInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input()
  get time(): string {
    return this._time;
  }
  set time(time: string) {
    this._time = time;
    this.hour = parseInt(this._time.substring(0, 2));
    this.minutes = parseInt(this.time.substring(3));
    if (this.hourArrow && this.minutesArrow) {
      this.setHour();
      this.setMinutes();
    }
  }
  _time: string;
  hour: number;
  minutes: number;
  @ViewChild("hourArrow") hourArrow: ElementRef;
  @ViewChild("minutesArrow") minutesArrow: ElementRef;
  @Output() timeUpdate = new EventEmitter<any>();
  scrollSpeed: number = 1;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor() {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {}

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/

  ngAfterViewInit(): void {
    this.setHour();
    this.setMinutes();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de la hora
  setHour(): void {
    let hourDegrees = (this.hour / 12) * 360 + (this.minutes / 60) * 30 + 90;
    this.hourArrow.nativeElement.style.transform = `rotate(${hourDegrees}deg)`;
  }

  // Seteo de los minutos
  setMinutes(): void {
    let minsDegrees = (this.minutes / 60) * 360 + 90;
    this.minutesArrow.nativeElement.style.transform = `rotate(${minsDegrees}deg)`;
  }

  // Movimiento de reloj
  updateTimer(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.timeUpdate.emit({
      value: event.deltaY < 0 ? 1 : event.deltaY > 0 ? -1 : 0,
      speed: this.scrollSpeed,
    });
    this.scrollSpeed += 1;
    setTimeout(() => (this.scrollSpeed -= 1), 500);
  }
}
