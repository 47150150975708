// @angular
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { GraphOptionsService } from "../../../../../modules/graph-module/GraphOptionsService.service";
// Interfaces
import { GRAPH_CONFIG } from "../../../../../modules/graph-module/GRAPH_CONFIG";
import { PressureSensor } from "../../SensoryInterface";

@Component({
  selector: "app-sensory-pressure-dialog",
  templateUrl: "./sensory-pressure-dialog.component.html",
  styleUrls: ["./sensory-pressure-dialog.component.scss"],
})
export class SensoryPressureDialogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: PressureSensor;

  // Gráfica
  graphSeries: any[];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  metersGraph: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private GraphOptionsService: GraphOptionsService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    this.loadGraph();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {}

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.setHighchartsOptions();
    this.loadGraphData();
  }

  // Obtención de los datos del gráfico
  loadGraphData(): void {
    this.getSeries();
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let graphSeries: any[] = [];

    // Ruido de sensor
    graphSeries[0] = {
      id: "presion",
      name: this.translate.instant("pressure"),
      type: "line",
      data: this.data.graph,
      dataGrouping: { approximation: "sum" },
      tooltip: {
        valueSuffix: " bar",
        valueDecimals: 1,
      },
      color: "#d32f2f",
      navigatorOptions: {
        type: "line",
      },
    };
    this.graphSeries = graphSeries;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] = "{value}" + " bar";
    chartOptions["yAxis"][0]["min"] = 0;
    chartOptions["yAxis"][0]["max"] = 3;
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }
}
