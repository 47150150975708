// @angular
import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// File saver
import saveAs from "file-saver";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { RequestQueueService } from "../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { DomControllerService } from "../../../../services/shared/DomControllerService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { DeviceTypeService } from "../../../../services/shared/DeviceTypeService.service";
// Componentes
import { TableControllerComponent } from "../../../../modules/table-module/table-controller/table-controller.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableGlobalAction,
} from "../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../interfaces/CupsGlobalInterface.type";
import { MeterService } from "../../../../screens/dashboard/devices/meter/MeterService.service";
import { MinifiedEntityDefinition } from "../ControlInterface.type";
// Variables
import {
  MapDevice,
  METROLOGY_TYPE,
} from "../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";
import {
  AssignedDevice,
  METER_ORDER,
} from "../../devices/DeviceInterface.type";
import { DeviceAgrupationDialogComponent } from "../../devices/devices-common-components/device-agrupation-dialog/device-agrupation-dialog.component";
import { Client } from "../../../../interfaces/ClientGlobalInterface.type";
import { MeterAssignedDialogComponent } from "../../devices/meter/meter-list/meter-assigned/meter-assigned-dialog/meter-assigned-dialog.component";
import { MapSelectorComponent } from "../../../../modules/map-module/map-selector/map-selector.component";

@Component({
  selector: "app-control-dataloggers",
  templateUrl: "./control-dataloggers.component.html",
  styleUrls: ["./control-dataloggers.component.scss"],
})
export class ControlDataloggersComponent {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/
  //Datos de entrada
  @ViewChild(MapSelectorComponent) mapSelector: MapSelectorComponent;
  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  currentClient: Client;
  agrupationSub: Subscription;
  clientSub: Subscription;
  sessionProfile: string;
  sessionLanguage: string;
  deviceType: string;

  // Datos del servicio
  data: any;
  // Tabla
  highlightRow: object[] = [
    { condition: "notInNetwork", color: "red", title: "without-communication" },
  ];
  meterWithBattery = false;
  meterWithPrecinto = false;
  tableSelectedData: AssignedDevice[];
  tableMaxReg: number = 100;
  meterList: AssignedDevice[];
  entitiesDefinitions: {
    entityId: number;
    definitionList: MinifiedEntityDefinition[];
    definitionListParsed: EntityDefinition[];
  }[];
  cups: string[];
  serialNumber: string[];
  isGasAgrupation: boolean;
  exportFileName: string =
    this.translate.instant("meters-export") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  quickFilters: TableQuickFilter[][];
  quickFiltersExclusion: boolean[];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "show-meters-map",
      icon: "fas fa-map-marker-alt",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "save-selection",
      icon: "fas fa-save",
      selectionRequired: true,
      help: "help-table-map",
    },
    {
      title: "show-selected-graph",
      icon: "fas fa-chart-area",
      selectionRequired: true,
      help: "help-table-graph",
    },
    {
      title: "show-consumption-evolution",
      icon: "fas fa-faucet",
      selectionRequired: true,
      help: "help-table-evolution",
    },
    {
      title: "association-new",
      icon: "fas fa-layer-group",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-asoc",
    },
    {
      title: "change-agrupation",
      icon: "fas fa-map",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-agrupation",
    },
    {
      title: "change-location",
      icon: "fas fa-map-marked-alt",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      help: "help-table-evolution",
    },
    {
      title: "substitution-order",
      icon: "fas fa-exchange-alt",
      selectionRequired: true,
      profiles: [PROFILES.ARSON],
      submenu: [
        {
          title: "substitution-only-meter",
          icon: "fas fa-tachometer-alt",
        },
        {
          title: "substitution-only-rf",
          icon: "fas fa-tower-broadcast",
        },
        {
          title: "substitution-all",
          icon: "fas fa-sync-alt",
        },
      ],
    },
    {
      title: "deactivate-multiple",
      icon: "fas fa-eraser",
      selectionRequired: true,
      profiles: [
        PROFILES.ARSON,
        PROFILES.ADMIN_CLIENTE,
        PROFILES.ADMIN_ENTIDAD,
        PROFILES.ADMIN_AGRUPACION,
      ],
      class: "btn-red",
      help: "help-table-deactivate",
    },
  ];
  @ViewChild(TableControllerComponent)
  tableController: TableControllerComponent;
  stayOnRoute: boolean = false;
  homeDevicesFilter: MapDevice[];
  homeDevicesFilterActive: boolean;
  homeDevicesOriginal: AssignedDevice[];
  homeDevicesFilteredOriginal: AssignedDevice[];

  // Menú de componente
  panelMenuOptions: PanelMenuOption[];
  exportIcon: string = "fas fa-download";
  exportTitle: string = this.translate.instant("export-all-devices");

  // Mapa
  mapSelectorActive: boolean = false;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private MeterService: MeterService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de agrupación e idioma
    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      this.currentClient = client;
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
    });

    if (this.currentAgrupation) {
      // Carga del componente
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.clientSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setPanelMenuOptions();
    this.getData();
  }

  getData(): void {
    this.MeterController.getDataloggerList(
      this.SessionDataService.getCurrentClient()?.clientId
    ).subscribe((response) => {
      if (response["code"] == 0) {
        // 1. Obtenemos los datos de la respuesta
        let data = response["body"]?.dataloggerList;

        // 2. Obtenemos la información de CUPS y otras configuraciones
        // CUPS de múltiples entidadedes
        this.entitiesDefinitions = response["body"]?.entitiesDefinitions;
        this.entitiesDefinitions?.forEach((entityDefinition) => {
          entityDefinition.definitionListParsed = [];
          entityDefinition.definitionList.forEach((definition) => {
            entityDefinition.definitionListParsed.push({
              id: definition.id,
              entity: definition.e,
              colPosition: definition.p,
              name: definition.n,
              label: definition.l,
              description: definition.d,
              show: definition.s,
              required: definition.r,
            });
          });
          this.cups = [];
          this.serialNumber = [];
          if (this.entitiesDefinitions?.length == 1) {
            this.entitiesDefinitions[0].definitionListParsed.forEach(
              (column: EntityDefinition) => {
                if (column.colPosition == 0) {
                  this.cups.push(column.label);
                }
                if (column.colPosition == 100) {
                  this.serialNumber.push(column.label);
                }
              }
            );
          }
        });

        // 3. Procesamos la lista de medidores
        let meterList = this.MeterService.parseAssignedMeterList(data);

        // 4. Recorremos cada medidor para procesarlo
        meterList?.forEach((meter: AssignedDevice) => {
          let manufacturer = this.ManufacturerService.getManufacturer(
            `${meter.fabricante}`,
            `${meter.devType}`,
            this.SessionDataService.getCurrentLanguage()
          );
          meter.fabricanteParsed = manufacturer?.manufacturerText;
          meter.devTypeParsed = manufacturer?.deviceText;

          meter.metrologyTypeParsed =
            meter.metrologyType != null
              ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
              : "-";
          meter.cups = meter.isAssigned ? meter.clave : null;
          meter.inNetwork = meter.comunica;
          meter.notInNetwork = !meter.comunica;
          this.setMeterEntityCupsColumns(meter);

          // 5. Buscamos la entidad correspondiente para este medidor
          const entity = this.currentClient.entityList.find(
            (entity: Entity) => entity.id == meter.entity
          );

          // 6. Buscamos la agrupación dentro de la entidad
          const agrupation = entity?.agrupations.find(
            (agrupation: Agrupation) => agrupation.id == meter.agrupation
          );
          meter.agrupationName = agrupation
            ? agrupation.name
            : this.translate.instant("httpError28"); // Agrupación no encontrada

          // 7. Asignamos el nombre de la entidad
          meter.entityName = entity
            ? entity.entity
            : this.translate.instant("httpError27"); // Entidad no encontrada

          this.meterWithBattery = meter.lastBatValue != null ? true : false;
          this.meterWithPrecinto = meter.precinto != null ? true : false;
          // 8. Otros valores del medidor
          meter.deviceType = this.ManufacturerService.getDeviceType(
            meter.fabricante,
            meter.devType
          );
          meter.isConcentrator =
            meter.metrologyType == METROLOGY_TYPE.UNE_CONCENTRATOR ||
            meter.metrologyType == METROLOGY_TYPE.MBUS_CONCENTRATOR;
          meter.loadGraph = true;
        });

        // 9. Finalmente, asignamos el resultado a meterList
        this.meterList = meterList;

        // 10. Llamamos a setColumns después de que todos los datos estén listos
        this.setColumns();
        this.setQuickFilters();
      }
    });
  }

  // Parseo de columnas de CUPS
  parseEntityDefinitions(entityDefinitions): EntityDefinition[] {
    return entityDefinitions.map((entityDefinition) => {
      return {
        id: entityDefinition.id,
        entity: entityDefinition.e,
        colPosition: entityDefinition.p,
        name: entityDefinition.n,
        label: entityDefinition.l,
        description: entityDefinition.d,
        show: entityDefinition.s,
        required: entityDefinition.r,
      };
    });
  }

  // Seteo de los filtros extra
  setQuickFilters(): void {
    this.quickFilters = [
      [
        {
          name: "in-network-with-comm",
          columnSearch: "inNetwork",
          condition: { type: "boolean", rule: true },
          active: false,
        },
      ],
    ];
  }

  // Seteo de las columnas de CUPS de contador según entidad
  setMeterEntityCupsColumns(meter: AssignedDevice): void {
    for (let attribute in meter) {
      if (attribute?.includes("col")) {
        meter[meter.entity + attribute] = meter[attribute];
      }
    }
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "show-detail",
            tooltip: "show-detail",
            icon: "fas fa-eye",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "meter-orders",
            tooltip: "meter-orders",
            icon: "fas fa-list-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "deactivate",
            tooltip: "deactivate",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: !(
              this.sessionProfile == PROFILES.ARSON ||
              this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
              this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
              this.sessionProfile == PROFILES.ADMIN_AGRUPACION
            ),
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "select",
        search: "selected",
        sort: "selected",
        visible: true,
      },
      {
        title: "entity",
        data: "entityName",
        search: "entityName",
        sort: "entityName",
        visible: true,
      },
      {
        title: "groups",
        data: "agrupationName",
        search: "agrupationName",
        sort: "agrupationName",
        visible: true,
      },
      // {
      //   title: "type",
      //   data: "metrologyTypeParsed",
      //   search: "metrologyTypeParsed",
      //   sort: "metrologyTypeParsed",
      //   visible: true,
      // },
      {
        title:
          this.serialNumber?.length > 0
            ? this.serialNumber[0]
            : "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: this.sessionProfile != PROFILES.TELEMEDIDA ? "meterLink" : null,
        linkCheck: {
          condition: "agrupation",
          attribute: "agrupation",
          check: "id",
        },
      },
      {
        title: "rf-module",
        data: "rfModule",
        search: "rfModule",
        sort: "rfModule",
        visible: true,
      },
      {
        title: "seal",
        data: "precinto",
        search: "precinto",
        sort: "precinto",
        visible: this.meterWithPrecinto,
      },
      {
        title: "FCV",
        data: "fcv",
        search: "fcv",
        sort: "fcv",
        visible: this.isGasAgrupation ? true : null,
      },
      {
        title: "PCS",
        data: "pcs",
        search: "pcs",
        sort: "pcs",
        visible: this.isGasAgrupation ? true : null,
      },
      // {
      //   title: "manufacturer",
      //   data: "fabricanteParsed",
      //   search: "fabricanteParsed",
      //   sort: "fabricanteParsed",
      //   visible: true,
      // },
      {
        title: "ICCID",
        data: "iccid",
        search: "iccid",
        sort: "iccid",
        visible: true,
      },

      {
        title: "model",
        data: "devTypeParsed",
        search: "devTypeParsed",
        sort: "devTypeParsed",
        visible: true,
      },

      {
        title: "comments",
        data: "comments",
        search: "comments",
        sort: "comments",
        visible: true,
      },
      {
        title: "date-last-value",
        data: "lastCommunicationParsed",
        search: "lastCommunicationParsed",
        sort: "lastCommunication",
        date: true,
        visible: true,
      },
      {
        title: "last-value",
        data: "lastReadedValueParsed",
        search: "lastReadedValueParsed",
        sort: "lastReadedValue",
        numerical: true,
        visible: true,
      },
      {
        title: "month-consumption",
        data: "consumptionCardsLastMonthParsed",
        search: "consumptionCardsLastMonthParsed",
        sort: "consumptionCardsLastMonth",
        numerical: true,
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
        batteryGraph: false,
      },
      {
        title: "battery-load",
        data: "lastBatValue",
        search: "lastBatValue",
        sort: "lastBatValue",
        numerical: true,
        visible: this.meterWithBattery,
      },
      {
        title: "battery-graph",
        data: null,
        search: null,
        sort: null,
        visible: this.meterWithBattery,
        batteryGraph: true,
        graph: true,
      },
      {
        title: "readings-percentage",
        data: "pcParsed",
        search: "pcParsed",
        sort: "pc",
        numerical: true,
        visible: this.meterList.some(
          (meter: AssignedDevice) =>
            this.DeviceTypeService.getDeviceTypeByMask(meter.tipo) ==
            DEVICE_BY_COMM.MBUS
        )
          ? true
          : null,
      },
      {
        title: "last-consumption-min",
        data: "lastConsumptionMinParsed",
        search: "lastConsumptionMinParsed",
        sort: "lastConsumptionMin",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-max",
        data: "lastConsumptionMaxParsed",
        search: "lastConsumptionMaxParsed",
        sort: "lastConsumptionMax",
        numerical: true,
        visible: true,
      },
      {
        title: "last-consumption-total",
        data: "lastConsumptionTotalParsed",
        search: "lastConsumptionTotalParsed",
        sort: "lastConsumptionTotal",
        numerical: true,
        visible: true,
      },
      {
        title: "latitude",
        data: "latitude",
        search: "latitude",
        sort: "latitude",
        visible: true,
      },
      {
        title: "longitude",
        data: "longitude",
        search: "longitude",
        sort: "longitude",
        visible: true,
      },
      {
        title: this.cups?.length > 0 ? this.cups[0] : "CUPS",
        data: "clave",
        search: "clave",
        sort: "clave",
        visible: true,
      },
    ];

    this.entitiesDefinitions?.forEach((entityDefinitions) => {
      entityDefinitions.definitionListParsed?.forEach(
        (extraColumn: EntityDefinition) => {
          if (extraColumn.show) {
            let attributePosition: string =
              entityDefinitions.entityId +
              (extraColumn.colPosition <= 9
                ? "col0" + extraColumn.colPosition
                : "col" + extraColumn.colPosition);
            if (
              extraColumn.colPosition != 100 &&
              extraColumn.colPosition != 0
            ) {
              let newColumn: TableDataColumn = {
                title: extraColumn.name,
                data: attributePosition,
                search: attributePosition,
                sort: attributePosition,
                long: true,
                visible: true,
              };
              columns.push(newColumn);
            }
          }
        }
      );
    });
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: AssignedDevice): void {
    switch (action) {
      case "show-detail":
        this.showMeter(meter);
        break;
      case "meter-orders":
        this.router.navigate(["/dispositivos/detalle/ordenes/" + meter.id], {
          state: { data: meter },
        });
        break;
      case "deactivate":
        this.resetMeter(meter);
        break;
      case "load-graph":
        this.MeterService.getAlarmGraph(meter);
        break;
      case "load-battery-graph":
        this.MeterService.getBatteryGraph(meter);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "show-meters-map":
        this.showOnMap();
        break;
      case "show-consumption-evolution":
        this.router.navigate(["/analisis-datos/evolucion-consumo"], {
          state: {
            data: this.tableSelectedData.map((device: AssignedDevice) => {
              return device.id;
            }),
          },
        });
        break;
      case "show-selected-graph":
        this.goToGraph();
        break;
      case "change-agrupation":
        this.showChangeAgrupationModal();
        break;
      case "change-location":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "changeLocation",
          meters: this.tableSelectedData,
        });
        break;
      case "deactivate-multiple":
        this.resetMultiple();
        break;
      case "association-new":
        this.router.navigate(["/analisis-datos/asociaciones/nuevo"], {
          state: {
            data: this.tableSelectedData.map(
              (device: AssignedDevice) => device.id
            ),
          },
        });
        break;
      case "save-selection":
        this.MaterialDialogService.openDialog(MeterAssignedDialogComponent, {
          action: "saveSelection",
          meters: this.tableSelectedData.map((meter) => {
            return {
              id: meter.id,
              nroSerie: meter.nroSerie,
              metrologyType: meter.metrologyType,
            };
          }),
        });
        break;
      case "substitution-only-meter":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_ONLY_DEVICE);
        break;
      case "substitution-only-rf":
        this.saveSubstitutionMeterOrder(METER_ORDER.SUSTITUTION_RF_MODULE);
        break;
      case "substitution-all":
        this.saveSubstitutionMeterOrder(
          METER_ORDER.SUSTITUTION_DEVICE_COMPLETE
        );
        break;
      // case "show-battery-multigraph":
      //   this.showOnBatteryGraph();
      default:
        break;
    }
  }

  goToGraph(): void {
    this.router.navigate(["analisis-datos/grafica"], {
      state: {
        data: this.tableSelectedData.map((meter: AssignedDevice) => {
          return meter.id;
        }),
        to: null,
      },
    });
  }

  // Guardado de orden de sustitución
  saveSubstitutionMeterOrder(order: number): void {
    this.ToastService.fireAlertWithTextarea(
      "info",
      this.translate.instant("substitution-order"),
      this.translate.instant("comments"),
      null,
      this.translate.instant("save"),
      this.translate.instant("cancel")
    ).then((userComments) => {
      if (userComments) {
        this.MeterController.saveOrder({
          idList: this.tableSelectedData.map((device) => device.id),
          type: order,
          comments: userComments,
        }).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("saved")
            );
          }
        });
      }
    });
  }

  // Redirección a contador
  showMeter(meter: AssignedDevice): void {
    this.DeviceRouteSelectorService.getDeviceRoute(
      meter.metrologyType,
      meter.id
    );
  }

  // Visualización de contadores en mapa
  showOnMap(): void {
    this.mapSelectorActive = true;
    this.DomControllerService.elementReady("#meter-list-map").then(() =>
      this.mapSelector.showOnMap()
    );
  }

  // Reseteo de contador
  resetMeter(meter: AssignedDevice): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.resetMeter(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            this.ToastService.fireToast(
              "success",
              this.translate.instant("device-meter-desactivated")
            );
            this.ReloadComponentService.reload();
          }
        });
      }
    });
  }

  // Reseteo de múltiples contadores
  resetMultiple(): void {
    this.ToastService.fireAlertWithCaptcha(
      "question",
      this.translate.instant("device-question-desactivate-multiple")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let data = this.tableSelectedData.map((device: AssignedDevice) => {
          return {
            id: device.id,
            nroSerie: device.nroSerie,
            metrologyType: device.metrologyType,
          };
        });
        this.requestQueue.setTask("deactivate", data);
      }
    });
  }

  // Exportación de todos los contadores
  exportAll(): void {
    this.MeterController.download(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response != null && response["code"] != 1000) {
          saveAs(
            response,
            this.translate.instant("all-meters-list-export") +
              " " +
              this.DateParserService.getDate() +
              ".csv"
          );
        }
      }
    );
  }

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "update-data",
        icon: "fas fa-sync-alt",
        text: this.translate.instant("update"),
        visible: true,
      },
      {
        action: "export-all",
        icon: "fas fa-download",
        text: this.translate.instant("export-all-devices"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
          this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
          this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "update-data":
        this.meterList = null;
        this.getData();

        break;
      case "export-all":
        this.exportAll();
        break;
      default:
        break;
    }
  }

  // Cambio de agrupación
  showChangeAgrupationModal(): void {
    this.MaterialDialogService.openDialog(DeviceAgrupationDialogComponent, {
      devices: this.tableSelectedData,
      agrupationList: this.currentEntity.agrupations
        .filter((agrupation: Agrupation) => !agrupation.showAllEntity)
        .sort((a, b) => a.name.localeCompare(b.name)),
      selectedAgrupation: null,
    });
  }
}
