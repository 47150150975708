// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
// Tranlsate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { MapDeviceMinimalParseService } from "../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { DomControllerService } from "../../../../services/shared/DomControllerService.service";
import { MapSizeService } from "../../../../modules/map-module/map-services/MapSizeService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { MapDevice } from "../../../../interfaces/DeviceGlobalInterface.type";
import { MapGateway } from "../../../../interfaces/GatewayGlobalInterface.type";
import { AgrupationChanges } from "../../../../modules/map-module/MapInterface.type";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";

@Component({
  selector: "app-management-agrupation-edition",
  templateUrl: "./management-agrupation-edition.component.html",
  styleUrls: ["./management-agrupation-edition.component.scss"],
})
export class ManagementAgrupationEditionComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Stream de datos de sesión
  entitySub: Subscription;
  currentEntity: Entity;

  // Arrays de contadores y gateways
  devices: MapDevice[];
  originalDevices: MapDevice[];
  gateways: MapGateway[];

  // Mapa
  mapType: string = "agrupationEdition";
  noVirtualAgrupation: boolean = false;
  agrupationChanges: AgrupationChanges;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  mapHeight: number;
  @ViewChild("agrupationPanel") agrupationPanel: ElementRef;
  mapDisabled: boolean = false;
  mapCleared: boolean = false;

  // Weather API
  weatherCoords: { latitude: number; longitude: number };

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private deviceMinimalParse: MapDeviceMinimalParseService,
    private DomControllerService: DomControllerService,
    private mapSize: MapSizeService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de cliente y entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización del componente
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/
  ngAfterViewInit(): void {
    // Ajuste del tamaño del mapa
    setTimeout(
      () =>
        (this.mapHeight = this.mapSize.calcMapHeight(
          this.agrupationPanel,
          null,
          -40
        )),
      0
    );
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub?.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Inicialización
  loadComponent(): void {
    this.devices = null;
    this.gateways = null;
    let agrupationId = this.currentEntity.agrupations.find(
      (agrupation: Agrupation) => agrupation.showAllEntity
    )?.id;
    if (agrupationId >= 0) {
      this.noVirtualAgrupation = false;
      this.getMapData(agrupationId);
    } else {
      this.noVirtualAgrupation = true;
    }
  }

  // Limpieza de mapa para evitar error al cambiar de url con selección activa
  clearMap(url: string): void {
    this.mapController?.mapComponent?.resetMap();
    this.mapCleared = true;
    setTimeout(() => this.router.navigate([url]), 0);
  }

  // Obtención de los datos para el mapa
  getMapData(agrupationId: number): void {
    let gateways = [];
    let devices = [];
    this.HomeController.getMarkers(agrupationId).subscribe((response) => {
      // Respuesta de mapa
      if (response["code"] == 0) {
        // Dispositivos
        devices = this.deviceMinimalParse.parseDevices(
          response["body"]["contadores"]
        );
        // Gateways
        gateways = response["body"]["gateways"];
      }
      this.devices = devices;
      this.originalDevices = JSON.parse(JSON.stringify(devices));
      this.gateways = gateways;
    });
  }

  // Actualización de los cambios en agrupación
  updateAgrupationChanges(agrupationChanges: AgrupationChanges) {
    this.agrupationChanges = agrupationChanges;
    this.DomControllerService.goToElement("#agrupation-edition-table");
    setTimeout(() => (this.mapDisabled = true), 0);
  }

  // Eliminación de cambio de dipositivo
  removeDevice(agrupation: any, deviceIndex: number): void {
    agrupation.devices[deviceIndex].selected = false;
    agrupation.devices.splice(deviceIndex, 1);
    if (agrupation.devices.length == 0) {
      let agrupationIndex = this.agrupationChanges.newDevices.findIndex(
        (agrupationWithChanges: any) =>
          agrupationWithChanges.agrupationId == agrupation.agrupationId
      );
      this.agrupationChanges.newDevices.splice(agrupationIndex, 1);
    }
    this.mapController.resetLayers();
  }

  // Eliminación de cambios en agrupación
  removeAgrupation(agrupationIndex: number): void {
    this.agrupationChanges.newDevices[agrupationIndex].devices.forEach(
      (changedDevice: any) => {
        changedDevice.selected = false;
      }
    );
    this.agrupationChanges.newDevices.splice(agrupationIndex, 1);
    if (this.agrupationChanges.newDevices.length == 0) {
      this.cancelChanges();
    }
    this.mapController.resetLayers();
  }

  // Cancelación de cambios
  cancelChanges(): void {
    this.devices = JSON.parse(JSON.stringify(this.originalDevices));
    this.agrupationChanges = null;
    this.mapDisabled = false;
    this.mapController.resetMap();
  }

  // Cambio de agrupación de dispositivos
  changeDevicesAgrupation(): void {
    this.ToastService.fireAlertWithOptions(
      "warning",
      this.translate.instant("agrupation-devices-change-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.changeDevice(0, 0);
      }
    });
  }

  // Bucle de movimiento de contadores
  changeDevice(agrupationIndex: number, deviceIndex: number): void {
    let device =
      this.agrupationChanges.newDevices[agrupationIndex].devices[deviceIndex];
    device.inProgress = true;
    this.MeterController.change(
      device.id,
      this.agrupationChanges.newAgrupationId
    ).subscribe((response) => {
      device.inProgress = false;
      if (response["code"] == 0) {
        device.changed = true;
      }

      // Comprobación de siguiente dispositivo
      if (
        deviceIndex <
        this.agrupationChanges.newDevices[agrupationIndex].devices.length - 1
      ) {
        deviceIndex++;
        this.changeDevice(agrupationIndex, deviceIndex);
      } else if (
        agrupationIndex <
        this.agrupationChanges.newDevices.length - 1
      ) {
        agrupationIndex++;
        deviceIndex = 0;
        this.changeDevice(agrupationIndex, deviceIndex);
        // Fin
      } else {
        this.mapDisabled = false;
        this.ReloadComponentService.reload();
      }
    });
  }

  // Aviso de edición pendiente
  pendingEditionAdvise(): void {
    this.ToastService.fireAlertWithTripleOptions(
      "warning",
      this.translate.instant("continue-editing") +
        " " +
        this.agrupationChanges.newAgrupationName +
        "?",
      this.translate.instant("cancel"),
      this.translate.instant("continue"),
      this.translate.instant("save")
    ).then((userSelection: string) => {
      if (userSelection == "option1") {
        this.agrupationChanges = null;
        this.mapDisabled = false;
        this.mapController.focusAgrupation();
      } else if (userSelection == "option2") {
        this.changeDevicesAgrupation();
      } else {
        this.cancelChanges();
      }
    });
  }
}
