// @angular
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  TemplateRef,
  SimpleChanges,
} from "@angular/core";
import { formatNumber } from "@angular/common";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
import moment_timezone from "moment-timezone";
// Spinner
import { NgxSpinnerService } from "ngx-spinner";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { BrowserStorageLocalService } from "../../../services/shared/BrowserStorageServices/BrowserStorageLocalService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { TableFilterService } from "../TableFilterService.service";
import { MaterialDialogService } from "../../../modules/material-module/material-dialog/material-dialog.service";
import { DateParserService } from "../../../services/shared/DateParserService.service";
import { TemplateService } from "../../../services/shared/TemplateService.service";
import { AssociationControllerService } from "../../../services/server/AssociationController.service";
// Componentes
import { TableComponent } from "../table/table.component";
import { TableDialogComponent } from "../table-dialog/table-dialog.component";
import { TableSpecialFilterDialogComponent } from "../table-special-filter-dialog/table-special-filter-dialog.component";
// Interfaces
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableSelectColumn,
  TableDataColumn,
  TableQuickFilter,
  TableSearchFilter,
  TableGroupBy,
  TableHighlightRow,
} from "../TableInterface.type";
import { MaterialSelectOption } from "../../material-module/MaterialInterface.type";
import { DialogAction } from "../TableInterface.type";
import { UserConfig } from "../../../interfaces/UserGlobalInterface.type";
import { Association } from "../../../screens/dashboard/data-management/DataManagementInterface.type";
// Variables
import { LOCAL_TIMEZONE } from "../../../global/LOCAL_TIMEZONE";
import { MeterBatteryDialogComponent } from "../../../screens/dashboard/devices/meter/meter-list/meter-battery/meter-battery-dialog/meter-battery-dialog.component";
import { GRAPH_TYPES } from "../../../screens/dashboard/devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
import { MeterVoltageDialogComponent } from "../../../screens/dashboard/devices/meter/meter-list/meter-voltage/meter-voltage-dialog/meter-voltage-dialog.component";

@Component({
  selector: "app-table-controller",
  templateUrl: "./table-controller.component.html",
  styleUrls: ["./table-controller.component.scss"],
})
export class TableControllerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Componente inicializado
  componentInitiated: boolean = false;

  // Variables de sesión
  currentAgrupation: Agrupation;
  currentUser: string;
  sessionLanguage: string;
  languageSub: Subscription;
  numberFormat: string;
  dateFormat: string;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Inputs
  @Input() tableId: string;
  @Input() columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  @Input() rowNumbers: boolean;
  @Input() maxReg: number;
  @Input() highlightRow: TableHighlightRow[];
  @Input() legendAlwaysActive: boolean;
  highlightRowActive: boolean = false;
  @Input() noPinnedColumns: boolean;
  @Input() specialFilter: boolean;
  @Input() initialSearch: any;
  @Input() cupsFilters: any[];
  @Input() export: boolean;
  @Input() exportSelection: boolean;
  @Input() exportFileName: string;
  @Input() quickFilters: TableQuickFilter[][];
  originalQuickFilters: TableQuickFilter[][];
  @Input() quickFiltersExclusion: boolean[];
  originalQuickFiltersExclusion: TableQuickFilter[][];
  quickFiltersChecked: boolean = false;
  @Input() hideQuickFilters: boolean;
  @Input()
  get externalFilters(): any[] {
    return this._externalFilters;
  }
  set externalFilters(externalFilters: any[]) {
    this._externalFilters = externalFilters;
    if (this._data) {
      this.updateFilters();
    }
  }
  _externalFilters: any[];
  @Input() tableGlobalActions: any[];
  @Input() dateRangeSelector: boolean;
  private _initialDate: any;
  @Input()
  public get initialDate(): any {
    return this._initialDate;
  }
  public set initialDate(value: any) {
    this._initialDate = value;
    this.dateRange = this.setDateRange();
  }
  @Input() initialDateNull: boolean;
  @Input() initialLockSelected: boolean;
  @Input() onlyTable: boolean;
  @Input() onlyMenu: boolean;
  @Input() orderBy: any;
  @Input() waitForData: any;
  @Input() columnOrderDisabled: boolean;
  @Input() paginationDisabled: boolean;
  @Input() topPaginationDisabled: boolean;
  @Input() transposeDisabled: boolean;
  @Input() parentElement: any;
  @Input() avoidRepaint: boolean;
  @Input() avoidFixColumns: boolean;
  @Input() groupBy: TableGroupBy;
  @Input()
  get data(): any[] {
    return this._data;
  }
  set data(data: any[]) {
    // Actualización de datos
    if (data) {
      // Ordenamiento de datos
      if (this.orderBy) {
        data.sort((a: any, b: any) => {
          return a[this.orderBy.attribute] - b[this.orderBy.attribute];
        });
        if (this.orderBy.reverse) {
          data.reverse();
        }
      }
      this.originalData = [...data];
      this._data = data;
      // Búsqueda precargada
      if (this.initialSearch) {
        this.loadInitialSearch();
      }
      // Reseteo de campo extra
      this._data.map((element: any) => {
        element.showExtraInfo = false;
        if (element.graphData) {
          element.showGraph = true;
        }
      });
      // Formateo numérico
      if (this.numberFormat) {
        this.checkNumberFormat();
      }
      // Formateo de fecha
      if (this.dateFormat) {
        this.checkDateFormat();
      }
      // Reseteo de selección
      this.initRowSelection();
      // Resaltado de filas
      this.setHighlightedRows();
      this.sortRowsByColor();
      // Comprobación de filtros rápidos activos
      if (this.quickFilters && !this.quickFiltersChecked) {
        this.originalQuickFilters = JSON.parse(
          JSON.stringify(this.quickFilters)
        );
        if (this.quickFiltersExclusion) {
          this.originalQuickFiltersExclusion = JSON.parse(
            JSON.stringify(this.quickFiltersExclusion)
          );
        }
        this.showQuickFilters = !this.hideQuickFilters;
        this.checkQuickFilters();
      } else if (this.originalQuickFilters) {
        this.checkQuickFilters();
      }
      // Filtros por tipología
      if (this.typologyFilters) {
        this.getTypologyFilters();
      }
      // Actualización de filtros
      this.updateFilters();
    }
    // Reseteo de columnas para provocar repintado de tabla
    if (!this.componentInitiated && this.columns && this._data) {
      this.loadComponent();
    } else if (this.componentInitiated && !this.avoidRepaint) {
      this.tableComponent?.updateColumnsWidth();
    }
  }
  _data: any[];
  originalData: any[];

  // Table
  @Output() dataRequest = new EventEmitter<any>();
  @Output() selectedDataUpdateFlag = new EventEmitter<any>();
  @Output() extraSelectedDataUpdateFlag = new EventEmitter<any>();
  @Output() resetSelectionFlag = new EventEmitter<any>();
  @Output() filterResetFlag = new EventEmitter<any>();
  visibleColumns: boolean = false;
  draggableColumns: boolean = false;
  columnDragged: number;
  sortedColumns = [];
  @ViewChild(TableComponent) tableComponent: TableComponent;
  lockSelected = false;
  lockSelectedBis = false;
  selectedCount: number;
  selectedBisCount: number;
  extraSelectedCount: number;
  originalColumnVisibility = [];
  originalColumnOrder = [];
  tableResponsive: boolean = window.innerWidth <= 980;

  // Menu
  showQuickFilters: boolean = false;
  showCupsFilters: boolean = false;
  quickFilterSaved: boolean = false;
  @Input() transpose: boolean;

  // Search
  selectedFilter = null;
  selectedFilterRaw = null;
  initialSelection: string;
  numericalComparison: boolean;
  dateComparison: boolean;
  booleanComparison: boolean;
  espComparison = false;
  filtersConcatenated = false;
  quickFilterActive: boolean;
  resetFilterSelection: boolean;
  filterOptions: MaterialSelectOption[];
  searchFiltersActive: TableSearchFilter[] = [];
  searchFilterExclusion: boolean = true;
  @ViewChild("searchBoxInput") searchBoxInput: ElementRef;
  @Output() dataFilteredFlag = new EventEmitter<any>();
  @Output() searchesActive = new EventEmitter<TableSearchFilter[]>();
  responsiveSearchActive: boolean = false;

  // Filtrado por fichero
  filterInput: string;
  filterByFileActive: boolean = false;
  filterByClipboardActive: boolean = false;
  filterFileColumnIndex: number;
  filterTableColumnIndex: number;
  fileColumnOptions: MaterialSelectOption[];
  tableColumnOptions: MaterialSelectOption[];

  // Filtrado por asociación
  @Input() associationFilter: boolean;
  filterByAssociationActive: boolean = false;
  associationFilterInverted: boolean = false;
  filterAssociation: Association[];
  filterSelectedAssociation: Association;
  filterAssociationMeters: number[];
  filterByAssociationShow: boolean = false;

  // Máximo de registros
  @ViewChild("tableMaxRegInput") tableMaxRegInput: ElementRef;

  // Acciones
  @Output() tableAction = new EventEmitter<any>();
  @Output() extraTableAction = new EventEmitter<any>();
  @Output() tableGlobalAction = new EventEmitter<any>();
  @Output() getExtraTableDataFlag = new EventEmitter<any>();

  // Selector de fecha
  dateRange: { startDate: moment.Moment; endDate: moment.Moment };
  dateRangeSelected: { startDate: moment.Moment; endDate: moment.Moment };
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  @Input() forceLocalTime: boolean;

  // Modal
  dialog: Subscription;

  // Tabla anidada
  @Input()
  get childTableResetFlag(): boolean {
    return this._childTableResetFlag;
  }
  set childTableResetFlag(childTableResetFlag: boolean) {
    this._childTableResetFlag = childTableResetFlag;
    this.tableComponent?.resetAllRowSelection();
    // El timeout evita el error de Angular por cambio de valor en variable chequeada
    setTimeout(() => this.selectedDataUpdate(), 0);
  }
  _childTableResetFlag: boolean;
  @Input() extraTableNoCollapse: boolean;

  // Archivo csv
  csv: boolean;
  fileToUploadKeys: File = null;
  fileNameKeys: string;
  fileToFilter: any;
  fileFilterInverted: boolean = false;
  fileFilterAllowDuplicates: boolean = false;
  @ViewChild("filterFile") filterFile: ElementRef;
  fileColumns: string[];
  fileData: string[][];
  showFileColumns: boolean = false;

  // Filtros por tipología
  @Input() typologyFilters: boolean;
  typologyFiltersColumns: {
    column: string;
    filters: { active: boolean; name: string; id: any }[];
  }[];
  typologyFiltersActive: string[];
  typologyFiltersToShow: any;
  typologyColumns: TableDataColumn[];

  GRAPH_TYPE_BATTERY: string = "Battery";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private DateParserService: DateParserService,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private MaterialDialogService: MaterialDialogService,
    private spinner: NgxSpinnerService,
    private SessionDataService: SessionDataService,
    private TableFilterService: TableFilterService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentUser = this.SessionDataService.getCurrentUser();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.numberFormat = this.SessionDataService.getCurrentNumberFormat();
    // Formateo numérico
    if (this.numberFormat && this._data) {
      this.checkNumberFormat();
    }
    // Formateo de fecha
    this.dateFormat = this.SessionDataService.getCurrentDateFormat();
    if (this.dateFormat && this._data) {
      this.checkDateFormat();
    }
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en agrupación e idioma
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      (agrupation) => {
        this.currentAgrupation = agrupation;
      }
    );

    this.languageSub = this.SessionDataService.getLanguage().subscribe(
      (language: string) => {
        this.sessionLanguage = language;
      }
    );

    // Dialog
    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: DialogAction) => {
        if (dialogAction.action == "filter") {
          this.executeSpecialFilter(
            dialogAction.filterData,
            dialogAction.showNull,
            dialogAction.inputDataString
          );
        } else if (dialogAction.action == "close") {
          this.espComparison = false;
        }
      }
    );

    // Inicialización
    if (this.columns) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.languageSub.unsubscribe();
    this.agrupationSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras el renderizado del componente
  /***************************************************************************/

  ngAfterViewInit(): void {
    // Timeout para evitar el error por cambio en variable chequeada de Angular
    // setTimeout(() => {
    //   this.loadInitialSearch();
    // }, 0);
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    // Ordenación de columnas
    this.columns.forEach((column: any) => {
      column["isSorted"] = false;
      column["sortDirection"] = "up";
      this.originalColumnVisibility.push(column.visible);
      this.originalColumnOrder.push(column.title);
    });
    // Comprobación de bloquedo de selección
    if (this.initialLockSelected) {
      this.lockSelected = true;
      this.lockSelectedBis = true;
    }
    // Máximos registros
    if (!this.maxReg) {
      this.maxReg = this._data.length;
    }
    // Seteo de filtros
    this.setFilterConfig();
    // Rango de fecha
    this.dateRange = this.setDateRange();
    // Carga de configuración local
    this.loadConfiguredData();
    // Comoponente iniciado
    this.componentInitiated = true;
  }

  // Comprobación de formato numérico
  checkNumberFormat(): void {
    this.originalData.forEach((element) => {
      if (element) {
        this.columns.forEach((column: TableDataColumn) => {
          if (column.numerical && element[column.sort] != null) {
            element[column.data] = formatNumber(
              element[column.sort],
              this.numberFormat
            );
            element[column.search] = element[column.data];
          }
        });
      }
    });
  }

  // Comprobación de formato de fecha
  checkDateFormat(): void {
    this.originalData.forEach((element) => {
      if (element) {
        this.columns.forEach((column: TableDataColumn) => {
          if (column.date && element[column.sort] != null) {
            element[column.data] = this.DateParserService.parseDate(
              element[column.sort],
              this.dateFormat + " HH:mm:ss",
              column.dateLocal ? LOCAL_TIMEZONE : null
            );
            element[column.search] = element[column.data];
          }
        });
      }
    });
  }

  // Agrupación de datos de columna por campo
  groupDataBy(): void {
    // Agrupación mediante objeto para optimizar la gestión de arrays grandes
    let groupedData = {};
    this._data.forEach((data) => {
      data.tableRowGroup = null;
      if (!groupedData[data[this.groupBy.groupAttribute]]) {
        data.tableRowGroup =
          this.translate.instant(this.groupBy.groupName) +
          ": " +
          (data[this.groupBy.groupAttribute] != null
            ? data[this.groupBy.groupAttribute]
            : this.translate.instant("unknown"));
        groupedData[data[this.groupBy.groupAttribute]] = [data];
      } else {
        groupedData[data[this.groupBy.groupAttribute]].push(data);
      }
    });
    // Conversión del objeto de agrupaciones a array
    let groupedDataArray = [];
    for (let attribute in groupedData) {
      groupedData[attribute][0].tableRowGroup +=
        " (" + groupedData[attribute].length + ")";
      groupedDataArray.push(groupedData[attribute]);
    }
    this._data = groupedDataArray.flat();
  }

  // Actualización de campo de agrupación
  updateGroupBy(groupBy?: TableGroupBy): void {
    if (groupBy && groupBy.groupAttribute != null) {
      this.groupBy = groupBy;
      this.groupDataBy();
    } else if (groupBy && groupBy.groupAttribute == null) {
      this.groupBy = null;
      this._data.map((data) => (data.tableRowGroup = null));
    }
  }

  /***************************************************************************/
  // ANCHOR Selector de fechas
  /***************************************************************************/

  // Seteo del rango de fecha
  setDateRange(): any {
    let dateRange: any;
    if (this.initialDate?.startDate && this.initialDate?.endDate) {
      dateRange = this.initialDate;
    } else if (!this.initialDateNull) {
      dateRange = this.defaultDateRange;
    }
    return dateRange;
  }

  // Seteo del filtro de búsqueda
  setFilterConfig(): void {
    let dataColumns: TableDataColumn[] = this.getDataColumns();
    // Selector de filtros
    this.filterOptions = [
      {
        value: "filterByFile",
        name: this.translate.instant("filter-table-by-file"),
      },
      {
        value: "filterByClipboard",
        name: this.translate.instant("filter-table-by-clipboard"),
      },
      ...dataColumns.map((dataColumn: any) => {
        return {
          value: dataColumn.title,
          name: this.translate.instant(dataColumn.title),
        };
      }),
    ];

    if (this.associationFilter) {
      this.filterOptions.splice(2, 0, {
        value: "filterByAssociation",
        name: this.translate.instant("filter-table-by-association"),
      });
    }

    this.getTableColumnsOptions(dataColumns);
  }

  // Obtención de las columnas de datos
  getDataColumns(): any[] {
    return this.columns.filter(
      (column: any) =>
        column.title != "select" &&
        column.title != "selectBis" &&
        column.sort != null &&
        column.search != null &&
        column.visible != null
    );
  }

  // Selector de columnas en filtro por fichero
  getTableColumnsOptions(dataColumns?: TableDataColumn[]): void {
    if (!dataColumns) {
      dataColumns = this.getDataColumns();
    }
    this.tableColumnOptions = dataColumns.map((dataColumn: any) => {
      return {
        value: this.columns.findIndex(
          (column: any) => column.title == dataColumn.title
        ),
        name: this.translate.instant(dataColumn.title),
      };
    });
  }

  // Petición de los datos según el selector de rango de fechas
  loadTableData(): void {
    let from: string = moment_timezone(this.dateRangeSelected.startDate)
      .tz(
        this.forceLocalTime ? LOCAL_TIMEZONE : this.currentAgrupation.timezone,
        true
      )
      .startOf("day")
      .valueOf()
      .toString();
    let to: string = moment_timezone(this.dateRangeSelected.endDate)
      .tz(
        this.forceLocalTime ? LOCAL_TIMEZONE : this.currentAgrupation.timezone,
        true
      )
      .endOf("day")
      .valueOf()
      .toString();
    this.dataRequest.emit({
      from: from,
      to: to,
      fromRaw: this.dateRangeSelected.startDate,
      toRaw: this.dateRangeSelected.endDate,
    });
  }

  /***************************************************************************/
  // ANCHOR Caja de búsqueda
  /***************************************************************************/

  // Filtrado por opción seleccionada
  filterBySelectedOption(selectedFilter: string): void {
    if (selectedFilter == "filterByFile") {
      this.filterFile.nativeElement.click();
    } else if (selectedFilter == "filterByClipboard") {
      this.filterByClipboard();
    } else if (selectedFilter == "filterByAssociation") {
      this.showFilterByAssociation();
    } else {
      let columnFilter: any = this.columns.find(
        (column: TableDataColumn) => column.title == selectedFilter
      );
      if (columnFilter) {
        this.selectedFilter = columnFilter.search;
        this.numericalComparison = columnFilter.numerical;
        this.dateComparison = columnFilter.date;
        this.booleanComparison = columnFilter.boolean;
        this.selectedFilterRaw = columnFilter.sort;
        this.espComparison = false;
      } else {
        this.resetFilter(true);
      }
      this.filterInput = null;
    }
  }

  // Reseteo de variables de filtro
  resetFilter(searchBox?: boolean): void {
    this.selectedFilter = null;
    this.numericalComparison = null;
    this.dateComparison = null;
    this.booleanComparison = null;
    this.espComparison = false;
    this.selectedFilterRaw = null;

    if (!searchBox) {
      this.filterByFileActive = false;
      this.filterByClipboardActive = false;
      this.fileNameKeys = null;
      this.fileToUploadKeys = null;
      this.fileData = null;
      this.fileColumns = null;
      this.filterTableColumnIndex = null;
      this.filterFileColumnIndex = null;
      this.fileFilterInverted = false;
      this.fileFilterAllowDuplicates = false;
      this.filterByAssociationActive = false;
      this.associationFilterInverted = false;
      this.filterAssociationMeters = null;
    }

    this.searchesActive.emit(this.searchFiltersActive);
  }

  // Precarga de búsqueda activa
  loadInitialSearch(): void {
    if (this.initialSearch?.data) {
      let columnSearched: any = this.columns.find(
        (column: any) => column.title == this.initialSearch.columnTitle
      );
      this.initialSelection = this.initialSearch.data;
      this.selectedFilter = columnSearched.search;
      this.numericalComparison = columnSearched.numerical;
      this.dateComparison = columnSearched.date;
      this.booleanComparison = columnSearched.boolean;
      this.selectedFilterRaw = columnSearched.sort;
      this.espComparison = this.initialSearch.espComparison;
      this.quickFilters?.map((quickFilterGroup: any) => {
        quickFilterGroup.map(
          (quickFilter: any) => (quickFilter.active = false)
        );
      });
      this.searchDropdownFilter(
        this.initialSearch.data,
        this.initialSearch.showNull,
        this.initialSearch.dataString
      );
      this.dateComparison = false;
      this.booleanComparison = false;
      this.numericalComparison = false;
    }
  }

  // Búsqueda en tabla
  searchDropdownFilter(
    inputData?: string,
    showNull?: boolean,
    inputDataString?: string
  ): void {
    // Guardado en búsquedas activas
    if (
      inputDataString != "ignoreInput" &&
      !this.searchFiltersActive.some(
        (search: TableSearchFilter) =>
          search.inputData == inputData &&
          search.selectedFilter == this.selectedFilter
      )
    ) {
      this.searchFiltersActive.push({
        inputData: inputData,
        inputDataString: inputDataString,
        selectedFilter: this.selectedFilter,
        selectedFilterRaw: this.selectedFilterRaw,
        espComparison: this.espComparison,
        dateComparison: this.dateComparison,
        booleanComparison: this.booleanComparison,
        numericalComparison: this.numericalComparison,
        showNull: showNull,
        label:
          this.selectedFilter != null
            ? this.translate.instant(
                this.columns.find(
                  (column: TableDataColumn) =>
                    column.sort == this.selectedFilterRaw
                )?.title
              )
            : null,
      });
    }

    // Filtrado
    if (this._data) {
      let newData: any[] = [...this._data];
      this.searchFiltersActive.forEach((search: TableSearchFilter) => {
        newData = this.TableFilterService.filterData(
          search.inputData,
          newData,
          this.columns,
          search.selectedFilter,
          search.selectedFilterRaw,
          search.espComparison,
          search.dateComparison,
          search.booleanComparison,
          search.numericalComparison,
          this.currentAgrupation?.timezone,
          search.showNull,
          this.searchFilterExclusion ? search.inverse : !search.inverse
        );
      });
      this._data = this.searchFilterExclusion
        ? [...newData]
        : this._data.filter(
            (dataElement) =>
              !newData.some((newDataElement) => newDataElement === dataElement)
          );
    }
    this.searchesActive.emit(this.searchFiltersActive);
  }

  // Eliminación de búsqueda activa
  removeActiveSearch(index: number): void {
    this.searchFiltersActive.splice(index, 1);
    this.updateFilters(null, true, "ignoreInput");
    this.searchesActive.emit(this.searchFiltersActive);
  }

  // Inversión de búsqueda activa
  invertActiveSearch(index: number): void {
    this.searchFiltersActive[index].inverse =
      !this.searchFiltersActive[index].inverse;
    this.updateFilters(null, true, "ignoreInput");
  }

  // Cierre de dropdown
  closeDropdown(dropdown: any): void {
    dropdown.classList.remove("table-filter-dropdown-opened");
  }

  // Apertura de dropdown
  openDropdown(dropdown: any): void {
    dropdown.classList.add("table-filter-dropdown-opened");
  }

  // Refresco de tabla
  refreshTableData(): void {
    this._data = [...this._data];
  }

  // Reseteo de los datos de la tabla
  resetData(): void {
    this.originalData.map((data) => (data.tableRowGroup = null));
    this._data = [...this.originalData];
    if (this.quickFilters) {
      this.quickFilters.forEach((filterGroup) =>
        filterGroup.map((filter: any) => (filter.active = false))
      );
      this.quickFilterActive = false;
    }
    this.searchFiltersActive = [];
    this.filterInput = null;
    this.resetFilterSelection = !this.resetFilterSelection;
    this.resetFilter();
    this.resetRowSelection();
    this.reorderTableData();
    this.filterResetFlag.emit();
    this.dataFilteredFlag.emit(this._data);
  }

  // Activación de filtros especiales
  activateSpecialFilter(keepComparison?: boolean): void {
    if (!keepComparison) {
      this.espComparison = !this.espComparison;
    }
    if (this.espComparison) {
      let comparisonType: string;
      if (this.numericalComparison) {
        comparisonType = "numerical";
      }
      if (this.dateComparison) {
        comparisonType = "date";
      }
      if (this.booleanComparison) {
        comparisonType = "boolean";
      }
      this.MaterialDialogService.openDialog(TableSpecialFilterDialogComponent, {
        comparisonType: comparisonType,
        timezone: this.currentAgrupation.timezone,
      });
    }
  }

  // Ejecución de filtro especial
  executeSpecialFilter(
    filterData: string,
    showNull: boolean,
    inputDataString?: string
  ) {
    this.filterInput = filterData;
    this.updateFilters(true, showNull, inputDataString);
  }

  // Actualización de los filtros
  updateFilters(
    searchBox?: boolean,
    showNull?: boolean,
    inputDataString?: string
  ): void {
    this._data = [...this.originalData];

    // Filtros rápidos activos
    if (this.quickFilters) {
      let quickFiltersActive: any[] = [false];
      this.quickFilters.forEach((filterGroup: any) => {
        quickFiltersActive.push(
          filterGroup.find((filter: any) => filter.active)?.active
        );
      });
      this.quickFilterActive = quickFiltersActive.reduce((a, b) => a || b);
    } else {
      this.quickFilterActive = false;
    }

    // Búsquedas activas
    let searchFilterActive: boolean =
      searchBox || this.searchFiltersActive?.length > 0;

    // Filtros externos
    if (this._externalFilters) {
      this.filterData(this._externalFilters, true);
    }
    // Filtros rápidos
    if (this.quickFilterActive) {
      this.quickFilter();
    }
    // Filtros por tipología
    if (this.typologyFilters) {
      this.filterByTypology();
    }
    // Filtros de desplegable
    if (searchFilterActive) {
      this.searchDropdownFilter(
        this.filterInput?.trim(),
        showNull,
        searchBox ? inputDataString : "ignoreInput"
      );
    }
    // Filtrado por fichero/portapapeles
    if (this.filterByFileActive || this.filterByClipboardActive) {
      this.filterByFile();
    }

    // Filtrado por asociación
    if (this.filterByAssociationActive) {
      this._data = this._data.filter(
        (device) =>
          (!this.associationFilterInverted &&
            this.filterAssociationMeters.includes(device.id)) ||
          (this.associationFilterInverted &&
            !this.filterAssociationMeters.includes(device.id))
      );
    }

    // Reseteo
    if (this.componentInitiated) {
      this.resetRowSelection();
    }
    this.reorderTableData();
    // Reseteo de input
    if (searchBox) {
      this.filterInput = null;
      this.searchBoxInput.nativeElement.blur();
    }
    // Agrupación por campo
    if (this.groupBy) {
      this.groupDataBy();
    }

    this.resetFilterSelection = !this.resetFilterSelection;
    this.espComparison = false;
    this.dataFilteredFlag.emit(this._data);
  }

  /***************************************************************************/
  // ANCHOR Filtros por tipología
  /***************************************************************************/

  // Obtención de los filtros por tipología
  getTypologyFilters(): void {
    this.typologyFiltersColumns = [];
    let typologyColumns: any[] = this.columns.filter(
      (column: TableDataColumn) => column.typology != null
    );
    let typologyFiltersToShow = {};
    typologyColumns?.forEach((typologyColumn: TableDataColumn) => {
      typologyColumn.typology.typologyList = [];
      this._data.map((element) => {
        if (
          !typologyColumn.typology.typologyList.some(
            (filter) =>
              filter.name == element[typologyColumn.typology.showAttribute]
          )
        ) {
          typologyColumn.typology.typologyList.push({
            active: typologyColumn.typology.activeFilters
              ? typologyColumn.typology.activeFilters.some(
                  (filter) =>
                    filter.id ==
                      element[typologyColumn.typology.filterAttribute] ||
                    filter.name ==
                      element[typologyColumn.typology.showAttribute]
                )
              : false,
            name: element[typologyColumn.typology.showAttribute],
            id: element[typologyColumn.typology.filterAttribute],
          });
        }
      });
      typologyFiltersToShow[typologyColumn.title] = typologyColumn.typology
        .activeFilters
        ? true
        : false;
    });
    this.typologyColumns = typologyColumns;
    this.typologyFiltersToShow = typologyFiltersToShow;
    this.typologyFiltersActive = typologyColumns.map((column) => column.title);
  }

  // Filtrado por tipología
  filterByTypology(): void {
    let typologyColumnsActive = this.typologyColumns.filter(
      (column: TableDataColumn) =>
        column.typology?.typologyList?.some((attribute) => attribute.active)
    );
    if (typologyColumnsActive?.length > 0) {
      let dataToFilter = [...this._data];
      typologyColumnsActive?.forEach((typologyColumn: TableDataColumn) => {
        let newData: any[] = [];
        let typologyActiveFilters = typologyColumn.typology.typologyList.filter(
          (filter) => filter.active
        );
        dataToFilter.forEach((element: any) => {
          if (
            typologyActiveFilters.some(
              (filter) =>
                filter.id == element[typologyColumn.typology.filterAttribute] ||
                filter.name == element[typologyColumn.typology.showAttribute]
            )
          ) {
            newData.push(element);
          }
        });
        dataToFilter = [...newData];
      });
      this._data = [...dataToFilter];
    }
  }

  // Visualización de filtros por tipología
  showTypologyFilters(filterColumn: string): void {
    this.typologyFiltersToShow[filterColumn] =
      !this.typologyFiltersToShow[filterColumn];
  }

  // Reseteo de filtros por tipología
  resetTypologyFilters(typologyColumn: TableDataColumn): void {
    typologyColumn.typology.typologyList.map(
      (typology) => (typology.active = false)
    );
    this.updateFilters();
  }

  /***************************************************************************/
  // ANCHOR Filtros rápidos
  /***************************************************************************/

  // Comprobación de filtros rápidos aptos
  checkQuickFilters(): void {
    let allowedQuickFiltersGroups = [];
    let exclusionDeleted = 0;
    this.quickFiltersExclusion = this.originalQuickFiltersExclusion
      ? JSON.parse(JSON.stringify(this.originalQuickFiltersExclusion))
      : null;

    this.originalQuickFilters?.forEach(
      (quickFilterGroup: TableQuickFilter[], i) => {
        let allowedQuickFilters = [];
        quickFilterGroup.forEach((quickFilter: TableQuickFilter) => {
          // Si existe algún elemento acorde al filtro
          if (
            this._data.some((element: any) =>
              this.checkQuickFilterElement(quickFilter, element)
            )
          ) {
            allowedQuickFilters.push(quickFilter);
          } else {
            quickFilter.active = false;
          }
        });
        // Actualización de grupo
        if (
          (quickFilterGroup.length > 1 && allowedQuickFilters.length > 1) ||
          (quickFilterGroup.length == 1 && allowedQuickFilters.length > 0)
        ) {
          allowedQuickFiltersGroups.push(allowedQuickFilters);
          // Eliminación de concatenación
        } else {
          this.quickFiltersExclusion?.splice(
            i == 0 ? 0 : i - exclusionDeleted - 1,
            1
          );
          exclusionDeleted++;
        }
      }
    );

    // Actualización de filtros rápidos
    this.quickFilters =
      allowedQuickFiltersGroups.length > 0 ? allowedQuickFiltersGroups : null;
    this.quickFiltersChecked = true;
  }

  // Comprobación de elemento según filto rápido
  checkQuickFilterElement(
    quickFilter: TableQuickFilter,
    element: any
  ): boolean {
    switch (quickFilter.condition.type) {
      case "boolean":
        if (
          (quickFilter.condition.rule && element[quickFilter.columnSearch]) ||
          (!quickFilter.condition.rule && !element[quickFilter.columnSearch])
        ) {
          return true;
        }
        break;
      case "text":
        if (element[quickFilter.columnSearch] == quickFilter.condition.rule) {
          return true;
        }
      case "number":
        if (element[quickFilter.columnSearch] == quickFilter.condition.rule) {
          return true;
        }
        break;
      default:
        break;
    }
    return false;
  }

  // Filtrado extra
  quickFilter(): void {
    let newData: any[] = [];
    let noActiveFilters: boolean[] = [];
    let i: number = -1;

    this.quickFilters?.forEach((filterGroup: any) => {
      let activeFilters = filterGroup.filter((filter: any) => filter.active);
      if (i >= 0) {
        if (
          !noActiveFilters.reduce((a, b) => a && b) &&
          activeFilters.length > 0 &&
          this.quickFiltersExclusion[i]
        ) {
          this._data = [...newData];
          newData = [];
        }
      }
      i++;
      // Si hay filtros extra activos se comprueba si cada elemento cumple alguno de ellos
      if (activeFilters.length != 0) {
        noActiveFilters.push(false);

        this._data.forEach((element: any) => {
          let isValid: boolean = false;
          let i: number = 0;
          do {
            if (this.checkQuickFilterElement(activeFilters[i], element)) {
              isValid = true;
            }
            i++;
          } while (i < activeFilters.length);
          if (
            isValid &&
            !newData.find((pushedElement: any) => pushedElement == element)
          ) {
            newData.push(element);
          }
        });
      } else {
        noActiveFilters.push(true);
      }
    });

    this._data = [...newData];
  }

  /***************************************************************************/
  // ANCHOR Ordenación de la tabla
  /***************************************************************************/

  // Ordenación de la tabla por columna
  sortTable(sortData: any): void {
    let sortedColumn: any;
    sortedColumn = this.columns.find(
      (column: any) => column.title == sortData.columnTitle
    );

    // Comprobación si la columna ya está ordenada o no
    if (this.sortedColumns.includes(sortedColumn.title) && !sortData.add) {
      sortedColumn.sortDirection == "up"
        ? (sortedColumn.sortDirection = "down")
        : (sortedColumn.sortDirection = "up");
    } else if (
      this.sortedColumns.includes(sortedColumn.title) &&
      sortData.add
    ) {
      sortedColumn.isSorted = false;
      sortedColumn.sortDirection = "up";
      this.sortedColumns.splice(
        this.sortedColumns.indexOf(sortedColumn.title),
        1
      );
    } else if (
      !this.sortedColumns.includes(sortedColumn.title) &&
      sortData.add
    ) {
      sortedColumn.isSorted = true;
      this.sortedColumns.push(sortedColumn.title);
    } else {
      this.sortedColumns = [];
      this.columns.forEach((column: any) => {
        column.isSorted = false;
        column.sortDirection = "up";
      });
      sortedColumn.isSorted = true;
      this.sortedColumns.push(sortedColumn.title);
    }

    // Actualización de la columna en la variable columnas
    this.columns = this.columns.map((column: any) => {
      if (column.title == sortedColumn.title) {
        return sortedColumn;
      } else {
        return column;
      }
    });

    // Ordenación de la tabla
    this.sortRowsByColumn();
  }

  // Ordenación de array de objetos por atributo
  sortRowsByColumn(): void {
    this._data.sort((a: any, b: any) => {
      let comparisonsArray: any[] = [];
      this.sortedColumns.forEach((sortedColumn: string) => {
        let currentColumn: any = this.columns.find(
          (column: any) => column.title == sortedColumn
        );
        let reverse: number = currentColumn.sortDirection == "up" ? 1 : -1;
        let aWasNull: boolean = false;
        let bWasNull: boolean = false;
        if (a[currentColumn.sort] === null) {
          aWasNull = true;
          a[currentColumn.sort] = "";
        }
        if (b[currentColumn.sort] === null) {
          bWasNull = true;
          b[currentColumn.sort] = "";
        }
        if (currentColumn.numerical) {
          let comparison: number;
          if (
            parseFloat(a[currentColumn.sort]) <
              parseFloat(b[currentColumn.sort]) ||
            aWasNull
          ) {
            comparison = -1;
          }
          if (
            parseFloat(a[currentColumn.sort]) ==
              parseFloat(b[currentColumn.sort]) &&
            !aWasNull &&
            !bWasNull
          ) {
            comparison = 0;
          }
          if (
            parseFloat(a[currentColumn.sort]) >
              parseFloat(b[currentColumn.sort]) ||
            bWasNull
          ) {
            comparison = 1;
          }
          comparisonsArray.push(comparison * reverse);
        } else {
          comparisonsArray.push(
            a[currentColumn.sort]
              ?.toString()
              ?.localeCompare(b[currentColumn.sort]?.toString()) * reverse
          );
        }
        if (aWasNull) {
          a[currentColumn.sort] = null;
        }
        if (bWasNull) {
          b[currentColumn.sort] = null;
        }
      });
      if (comparisonsArray.length > 0) {
        return comparisonsArray.reduce((a: any, b: any) => {
          return a || b;
        });
      } else {
        return null;
      }
    });

    if (this.groupBy) {
      this.groupDataBy();
    } else {
      this.refreshTableData();
    }
  }

  // Reordenación de la tabla tras búsqueda
  reorderTableData(): void {
    let currentSortedColums: any[] = [...this.sortedColumns];
    this.sortedColumns = [];
    currentSortedColums.forEach((columnTitle: any) => {
      let sortData: any = {
        columnTitle: columnTitle,
        add: true,
      };
      this.sortTable(sortData);
    });
  }

  // Obtención de las filas resaltadas
  setHighlightedRows(): void {
    this.highlightRowActive = false;
    this.cleanHighlightedRows();
    this.originalData?.map((row: any) => {
      this.highlightRow?.map(
        (highlight: { condition: string; color: string }) => {
          if (row[highlight.condition]) {
            row.highlightClass = "table-highlight-" + highlight.color;
            this.highlightRowActive = true;
          }
        }
      );
    });
  }

  // Función para ordenar las filas por color de resaltado
  sortRowsByColor(): void {
    if (this.originalData && this.highlightRowActive) {
      // Supón que tienes un orden predefinido para los colores (por ejemplo, ['red', 'green', 'blue'])
      const colorOrder = ["red", "orange", "yellow", "blue", "green"]; // Este es solo un ejemplo, ajusta según tus colores

      this.originalData.sort((a, b) => {
        // Obtener el color de resaltado para cada fila
        const colorA = a.highlightClass ? a.highlightClass.split("-")[2] : ""; // Si no tiene color, asigna vacío
        const colorB = b.highlightClass ? b.highlightClass.split("-")[2] : ""; // Si no tiene color, asigna vacío

        // Si alguna de las filas no tiene color de resaltado, colocarlas al final
        if (!colorA && colorB) return 1; // Si 'a' no tiene color y 'b' sí, 'a' va al final
        if (colorA && !colorB) return -1; // Si 'a' tiene color y 'b' no, 'a' va al principio

        // Si ambas tienen colores, ordenarlas según el orden de `colorOrder`
        const indexA = colorOrder.indexOf(colorA);
        const indexB = colorOrder.indexOf(colorB);

        // Si no se encuentra el color en `colorOrder`, se le asigna un valor muy alto para ir al final
        const adjustedIndexA = indexA === -1 ? colorOrder.length : indexA;
        const adjustedIndexB = indexB === -1 ? colorOrder.length : indexB;

        return adjustedIndexA - adjustedIndexB; // Orden ascendente por índice del color
      });
    }
  }
  // Limpieza de las filas resaltadas
  cleanHighlightedRows(): void {
    this.originalData?.map((row: any) => {
      row.highlightClass = null;
    });
  }

  /***************************************************************************/
  // ANCHOR Filtros externos
  /***************************************************************************/

  // Filtros de CUPS y externos
  filterData(filters: any, filterExact: boolean): void {
    let newData: any[] = [];
    // this.resetRowSelection();
    this._data.forEach((meter: any) => {
      let isValid: boolean[] = [true];
      let filtersEmpty: boolean = true;
      filters.forEach((filter: any) => {
        if (filter.value != null && filter.value.toString() != "") {
          if (!filterExact) {
            meter[filter.data]
              ?.toString()
              .toUpperCase()
              .includes(filter.value.toString().toUpperCase())
              ? isValid.push(true)
              : isValid.push(false);
          } else {
            meter[filter.data]?.toString().toUpperCase() ==
            filter.value.toString().toUpperCase()
              ? isValid.push(true)
              : isValid.push(false);
          }
          filtersEmpty = false;
        } else {
          isValid.push(true);
        }
      });
      if (isValid.reduce((a, b) => a && b) || filtersEmpty) {
        newData.push(meter);
      }
    });
    this._data = [...newData];
  }

  /***************************************************************************/
  // ANCHOR Selección de filas
  /***************************************************************************/

  // Selección/deselección de todas las filas
  selectAllRows(selectAll: boolean, bis: boolean, excluding: boolean): void {
    this._data.map((element: any) => {
      if (!bis) {
        if (
          !element.selectDisabled &&
          !(excluding && element.selectedBis && selectAll)
        ) {
          element.selected = selectAll;
        }
      } else {
        if (
          !element.selectBisDisabled &&
          !(excluding && element.selected && selectAll)
        ) {
          element.selectedBis = selectAll;
        }
      }
    });
    this.selectedDataUpdate();
  }

  // Actualización de los datos seleccionados
  selectedDataUpdate(): void {
    this.selectedCount = this.originalData?.filter((element: any) => {
      if (element.selected) {
        return element;
      }
    }).length;
    this.selectedBisCount = this.originalData?.filter((element: any) => {
      if (element.selectedBis) {
        return element;
      }
    }).length;
    if (this.parentElement) {
      this.extraSelectedDataUpdateFlag.emit({
        parentElement: this.parentElement,
        childsSelected: this._data.filter((element: any) => {
          if (element.selected || element.selectedBis) {
            return element;
          }
        }),
      });
    } else {
      this.selectedDataUpdateFlag.emit(
        this._data.filter((element: any) => {
          if (element.selected || element.selectedBis) {
            return element;
          }
        })
      );
    }
  }

  // Actualización de los datos de tabla anidada
  getExtraTableData(element: any): void {
    this.getExtraTableDataFlag.emit(element);
  }

  // Actualización de los datos seleccionados de tabla anidada
  extraSelectedDataUpdate(actionData: any): void {
    this.extraSelectedCount = actionData.childsSelected.filter(
      (element: any) => {
        if (element.selected) {
          return element;
        }
      }
    ).length;
    this.extraSelectedDataUpdateFlag.emit(actionData);
  }

  // Reseteo de filas seleccionadas de la tabla y la tabla anidada
  resetRowSelection(): void {
    this.originalData.map((element: any) => {
      if (!this.lockSelected) {
        element.selected = false;
      }
      if (!this.lockSelectedBis) {
        element.selectedBis = false;
      }
      if (element.extraTableData) {
        element.extraTableData.data?.map((extraTableElement: any) => {
          extraTableElement.selected = false;
          extraTableElement.selectedBis = false;
        });
      }
    });
    this.selectedCount = this.originalData.filter(
      (element: any) => element.selected
    ).length;
    this.selectedBisCount = this.originalData.filter(
      (element: any) => element.selectedBis
    ).length;
    this.tableComponent?.resetAllRowSelection();
    // El timeout evita el error de Angular por cambio de valor en variable chequeada
    setTimeout(() => this.selectedDataUpdate(), 0);
  }

  // Reseteo de filas seleccionadas de la tabla anidada
  resetExtraTableRowSelection(): void {
    this._data.map((element: any) => {
      element.extraTableData?.data?.map((extraTableElement: any) => {
        extraTableElement.selected = false;
        extraTableElement.selectedBis = false;
      });
    });
    // El timeout evita el error de Angular por cambio de valor en variable chequeada
    setTimeout(() => this.selectedDataUpdate(), 0);
  }

  // Reseteo de filas seleccionadas
  initRowSelection(): void {
    let selectedPreSet: boolean = false;

    this.originalData.map((element: any) => {
      if (element.selected == null) {
        element.selected = false;
        element.selectedBis = false;
      } else {
        selectedPreSet = true;
      }
    });

    if (selectedPreSet) {
      this.selectedCount = this.originalData.filter(
        (element: any) => element.selected
      ).length;
      this.selectedBisCount = this.originalData.filter(
        (element: any) => element.selectedBis
      ).length;
    } else {
      this.selectedCount = 0;
      this.selectedBisCount = 0;
    }
  }

  /***************************************************************************/
  // ANCHOR Configuración local de tabla
  /***************************************************************************/

  // Guardado de la configuración local de la tabla
  saveTableConfig(): void {
    let configData: any =
      this.BrowserStorageLocalService.getJsonValue("config");
    let userConfig: any;

    // Comprobación de si existe perfil local de usuario
    if (configData) {
      userConfig = configData.find(
        (config: any) => config.user == this.currentUser
      );
    } else {
      configData = [];
    }
    if (!userConfig) {
      userConfig = new UserConfig(this.currentUser);
      configData.push(userConfig);
    }

    // Creación de los datos del componente si no existe perfil local
    if (!userConfig.tableConf[this.tableId]) {
      userConfig.tableConf[this.tableId] = {
        columnVisibility: null,
        columnOrder: null,
        quickFiltersActive: null,
      };
    }

    // Actualización de la visibilidad de las columnas en el perfil local
    userConfig.tableConf[this.tableId].columnVisibility = this.columns.map(
      (column: any) => {
        return { title: column.title, visible: column.visible };
      }
    );

    // Actualización del orden de columnas
    userConfig.tableConf[this.tableId].columnOrder = this.columns.map(
      (column: any) => {
        return column.title;
      }
    );

    // Arrays de filtros activos actuales y por defecto
    let currentQuickFiltersActive: any[] = this.quickFilters?.map(
      (quickFilterArray: any) => {
        return quickFilterArray.map((quickFilter: any) => {
          return quickFilter.active;
        });
      }
    );
    let defaultQuickFiltersActive: any[] = this.originalQuickFilters?.map(
      (quickFilterArray: any) => {
        return quickFilterArray.map((quickFilter: any) => {
          return quickFilter.active;
        });
      }
    );

    // Guardado opcional de los filtros si hay cambios respecto a los filtros por defecto
    if (
      JSON.stringify(currentQuickFiltersActive) !=
      JSON.stringify(defaultQuickFiltersActive)
    ) {
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("config-save-filter-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          userConfig.tableConf[this.tableId].quickFiltersActive =
            currentQuickFiltersActive;
        }
        // Guardado en local
        this.BrowserStorageLocalService.setJsonValue("config", configData);
        this.ToastService.fireToast(
          "success",
          this.translate.instant("config-save")
        );
      });
    } else {
      // Guardado en local
      this.BrowserStorageLocalService.setJsonValue("config", configData);
      this.ToastService.fireToast(
        "success",
        this.translate.instant("config-save")
      );
    }
  }

  // Borrado de la configuración local de la tabla
  deleteTableConfig(): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("config-delete-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        let configData: any =
          this.BrowserStorageLocalService.getJsonValue("config");
        let userConfig: any;

        // Comprobación de si existe perfil local de usuario
        if (configData) {
          userConfig = configData.find(
            (config: any) => config.user == this.currentUser
          );
        }

        // Eliminación de los datos locales
        if (userConfig && userConfig.tableConf[this.tableId]) {
          userConfig.tableConf[this.tableId] = null;
        }

        // Reseteo de componente

        // Resteo de orden
        let unorderedColumns = this.originalColumnOrder.map(
          (columnTitle: string) => {
            return this.columns.find(
              (column: any) => column.title == columnTitle
            );
          }
        );
        this.columns = unorderedColumns;

        // Reseteo de visibilidad
        this.columns.map(
          (column: any, index) =>
            (column.visible = this.originalColumnVisibility[index])
        );

        // Reseteo de filtros
        if (this.originalQuickFilters) {
          this.quickFilters = JSON.parse(
            JSON.stringify(this.originalQuickFilters)
          );
        }
        this.updateFilters();

        // Guardado en local
        this.BrowserStorageLocalService.setJsonValue("config", configData);
        this.ToastService.fireToast(
          "success",
          this.translate.instant("config-delete")
        );
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Visibilidad de columnas
  /***************************************************************************/

  // Muestra u oculta todas las columnas
  showHideAll(visible: boolean): void {
    this.columns.forEach((column: any) => {
      if (column.visible != null) {
        column.visible = visible;
      }
    });
  }

  // Carga de la visibilidad de columnas
  loadConfiguredData(): void {
    let configData: any =
      this.BrowserStorageLocalService.getJsonValue("config");
    let userConfig: any;

    // Comprobación de si existe perfil local de usuario
    if (configData) {
      userConfig = configData.find(
        (config: any) => config.user == this.currentUser
      );
    }

    // Actualización de datos del componente si existen datos guardados
    if (userConfig && userConfig.tableConf[this.tableId]) {
      // Actualización de orden de columnas
      if (userConfig.tableConf[this.tableId].columnOrder) {
        let orderedColumns = userConfig.tableConf[this.tableId].columnOrder.map(
          (columnTitle: string) => {
            return this.columns.find(
              (column: any) => column.title == columnTitle
            );
          }
        );
        this.columns = orderedColumns
          .filter((column: any) => column != null)
          .concat(
            this.columns.filter(
              (column: any) =>
                !userConfig.tableConf[this.tableId].columnOrder.includes(
                  column.title
                )
            )
          );
        this.getTableColumnsOptions();
      }

      // Actualización de visibilidad de columnas
      if (userConfig.tableConf[this.tableId].columnVisibility) {
        this.columns.forEach((column: any) => {
          let configuredColumn: any = userConfig.tableConf[
            this.tableId
          ].columnVisibility.find(
            (configuredColumn: any) => configuredColumn.title == column.title
          );
          if (configuredColumn) {
            column.visible = configuredColumn?.visible;
          }
        });
      }

      // Actualización de filtros rápidos
      if (userConfig.tableConf[this.tableId].quickFiltersActive) {
        userConfig.tableConf[this.tableId].quickFiltersActive.map(
          (quickFilterArray: any, arrayIndex: number) =>
            quickFilterArray.map((quickFilter: any, index: number) => {
              this.quickFilters[arrayIndex][index].active = quickFilter;
              if (quickFilter) {
                this.quickFilterActive = true;
                this.quickFilterSaved = true;
                this.updateFilters();
              }
            })
        );
      }
    }
  }

  /***************************************************************************/
  // ANCHOR Acciones de la tabla
  /***************************************************************************/

  // Acción global de la tabla ejecutada
  sendTableGlobalAction(action: any): void {
    this.tableGlobalAction.emit(action);
  }

  // Obtención de la acción para el elemento
  getAction(actionData: any): void {
    if (this.parentElement) {
      this.extraTableAction.emit({
        action: actionData.action,
        childElement: actionData.element,
        parentElement: this.parentElement,
      });
    } else {
      this.tableAction.emit({
        action: actionData.action,
        element: actionData.element,
      });
    }
  }

  // Obtención de la acción para el elemento de la tabla anidada
  getExtraTableAction(actionData: any): void {
    this.extraTableAction.emit(actionData);
  }

  // Asignación del valor de registros máximos por página
  setMaxReg(maxRegInput: string): void {
    this.maxReg = parseInt(maxRegInput);
  }

  // Exportación de la tabla
  exportTable(selection: boolean, clipboard: boolean, event?: any): void {
    if (event) {
      event.preventDefault();
    }
    this.tableComponent.exportTableData(
      this.exportFileName ? this.exportFileName : "file",
      selection,
      clipboard
    );
  }

  // Visualización de modal
  showTableModal(data: any): void {
    this.MaterialDialogService.openDialog(TableDialogComponent, data);
  }

  // Expansión de gráfica
  expandGraph(data: any): void {
    let component: any;
    //constantes para tipos de grafica
    if (data.type == GRAPH_TYPES.BATTERY) {
      component = MeterBatteryDialogComponent;
      if (data.device.contador) {
        data = data.device.contador;
      } else {
        data = data.device.id;
      }
    } else if (data.type == GRAPH_TYPES.VOLTAGE) {
      component = MeterVoltageDialogComponent;
    } else {
      component = TableDialogComponent;
    }
    this.MaterialDialogService.openDialog(component, data);
  }

  /***************************************************************************/
  // ANCHOR Ajustes de tamaño y scroll
  /***************************************************************************/

  // Seteo de la barra de scroll superior
  setTopScrollBar(): void {
    setTimeout(() => this.tableComponent.setTopScrollBar(), 0);
  }

  /***************************************************************************/
  // ANCHOR Filtrado por archivo
  /***************************************************************************/

  // Filtrado por fichero
  resetFile(): void {
    this.csv = null;
    this.filterFile.nativeElement.value = null;
    this.showFileColumns = false;
    this.selectedFilter = null;
    this.resetFilterSelection = !this.resetFilterSelection;
  }

  // Comprobación de si el archivo elegido tiene la extensión correcta
  handleFileInputKeys(event: any): void {
    this.spinner.show("spinner-soft");
    this.csv = event.item(0).name.includes(".csv") ? true : false;
    if (this.csv == false) {
      this.ToastService.fireToast(
        "error",
        this.translate.instant("wrong-file")
      );
    }
    this.fileNameKeys = event.item(0)["name"];
    this.fileToUploadKeys = event.item(0);

    if (!this.fileNameKeys) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      let reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.fileToFilter = reader.result;
          this.getFileColumns();
        },
        false
      );

      if (this.csv) {
        reader.readAsText(this.fileToUploadKeys);
      }
    }
  }

  // Obtención de columnas del archivo de filtrado
  getFileColumns(): void {
    this.spinner.hide("spinner-soft");
    this.spinner.show("spinner-hard");
    let filterRows = this.fileToFilter.split("\n");
    filterRows = filterRows.map((row: string) => {
      return row.split(";").map((element: any) => element.replace("\r", ""));
    });
    this.fileColumns = [...filterRows[0]];
    this.fileData = [...filterRows.slice(1)];
    if (this.fileColumns.length > 0) {
      this.fileColumnOptions = this.fileColumns.map((column: any, i) => {
        return { value: i, name: column };
      });
      this.showFileColumns = true;
    }
    this.spinner.hide("spinner-hard");
  }

  // Filtrado por archivo
  filterByFile(): void {
    this.spinner.show("spinner-hard");
    let dataFiltered: any[] = [];
    // Filtrado inverso
    if (this.fileFilterInverted) {
      this._data.forEach((element: any) => {
        if (
          !this.fileData.some((rowData: any) =>
            this.columns[this.filterTableColumnIndex]["html"]
              ? element[
                  this.columns[this.filterTableColumnIndex]["search"]
                ].includes(rowData[this.filterFileColumnIndex])
              : element[this.columns[this.filterTableColumnIndex]["search"]] ==
                rowData[this.filterFileColumnIndex]
          )
        ) {
          dataFiltered.push(element);
        }
      });
      // Filtrado normal
    } else {
      this.fileData.forEach((rowData: string[]) => {
        if (rowData[this.filterFileColumnIndex] != "") {
          let dataFound = this._data.filter((element: any) =>
            this.columns[this.filterTableColumnIndex]["html"]
              ? element[
                  this.columns[this.filterTableColumnIndex]["search"]
                ].includes(rowData[this.filterFileColumnIndex])
              : element[this.columns[this.filterTableColumnIndex]["search"]] ==
                rowData[this.filterFileColumnIndex]
          );
          if (
            dataFound.length > 0 &&
            // Comprobación de duplicados desde fichero
            !dataFiltered.some(
              (data) =>
                dataFound[0][
                  this.columns[this.filterTableColumnIndex]["search"]
                ] == data[this.columns[this.filterTableColumnIndex]["search"]]
            )
          ) {
            dataFiltered = this.fileFilterAllowDuplicates
              ? dataFiltered.concat(dataFound)
              : dataFiltered.concat(dataFound[0]);
          }
        }
      });
    }
    this._data = [...dataFiltered];
    this.resetFile();
    this.spinner.hide("spinner-hard");
  }

  // Filtrado por portapapeles
  filterByClipboard(): void {
    this.fileToFilter = navigator.clipboard.readText().then((file) => {
      this.fileToFilter = file;
      this.getFileColumns();
    });
  }

  // Mostrar selector de filtrado por asociación
  showFilterByAssociation(): void {
    this.AssociationController.getAssociationList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"] != null) {
          this.filterAssociation = response["body"];
          this.filterByAssociationShow =
            true || this.filterAssociation?.length > 0;
        }
      }
    });
  }

  // Filtrado por asociación
  filterByAssociation(): void {
    this.AssociationController.getAssociationDevices(
      this.filterSelectedAssociation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.filterAssociationMeters = response["body"]?.map(
          (meter) => meter.id
        );
        this.filterByAssociationShow = false;
        this.filterByAssociationActive = true;
        this.updateFilters();
      }
    });
  }

  // Filtrado por fichero
  resetAssociationFilter(): void {
    this.selectedFilter = null;
    this.filterByAssociationShow = false;
    this.filterSelectedAssociation = null;
    this.filterByAssociationActive = false;
    this.resetFilterSelection = !this.resetFilterSelection;
  }

  /***************************************************************************/
  // ANCHOR Desplazamiento de columnas
  /***************************************************************************/

  // Dropeo de columna
  columnDrop(index: number) {
    let movedColumn: (
      | TableActionColumn
      | TableSelectColumn
      | TableDataColumn
    )[] = this.columns.splice(this.columnDragged, 1);
    this.columns.splice(index, 0, movedColumn[0]);
    this.getTableColumnsOptions();
  }
}
