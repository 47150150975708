// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import * as Highcharts from "highcharts/highstock";
// Estadísticas
import * as ss from "simple-statistics";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../services/server/MeterController.service";
import { ManufacturerService } from "../../../../../services/shared/ManufacturerService.service";
import { DeviceRouteSelectorService } from "../../../../../services/shared/DeviceRouteSelectorService.service";
import { MeterService } from "../../../devices/meter/MeterService.service";
import { DataAnalysisControllerService } from "../../../../../services/server/DataAnalysisController.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Agrupation } from "../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { EntityDefinition } from "../../../../../interfaces/CupsGlobalInterface.type";
import { AssignedDevice } from "../../../devices/DeviceInterface.type";
import {
  CONSUMPTION_PROFILES,
  ConsumptionProfile,
} from "../data-analysis-models-edit/data-analysis-patterns";
// Variables
import { METROLOGY_TYPE } from "../../../../../interfaces/DeviceGlobalInterface.type";

@Component({
  selector: "app-data-analysis-models-meters",
  templateUrl: "./data-analysis-models-meters.component.html",
  styleUrls: ["./data-analysis-models-meters.component.scss"],
})
export class DataAnalysisModelsMetersComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;

  // Tabla
  tableMaxReg: number = 100;
  meterList: AssignedDevice[];
  isGasAgrupation: boolean;
  originalMeterList: AssignedDevice[];
  columns: (TableActionColumn | TableDataColumn)[];

  // Patrones
  defaultDateRange = this.DateParserService.getLastDays(7);
  consumptionProfileList: ConsumptionProfile[];
  meterConsumptionProfileList: ConsumptionProfile[];
  profileCharts: any[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DataAnalysisController: DataAnalysisControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private ManufacturerService: ManufacturerService,
    private MeterController: MeterControllerService,
    private MeterService: MeterService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getConsumptionProfiles();
  }

  // Obtención de los datos
  getData(): void {
    this.MeterController.table(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          // Columnas extra
          let extraColumns: EntityDefinition[] = this.parseEntityDefinitions(
            response["body"]["entityDefinitions"]
          );
          let cups: EntityDefinition = extraColumns.find(
            (column: EntityDefinition) => column.colPosition == 0
          );
          let cupsIndex: number = extraColumns.findIndex(
            (column: EntityDefinition) => column.colPosition == 0
          );
          if (cupsIndex >= 0) {
            extraColumns.splice(cupsIndex, 1);
          }

          // Contadores
          let meterList: AssignedDevice[] =
            this.MeterService.parseAssignedMeterList(
              response["body"]["meterList"]
            );
          meterList.forEach((meter: AssignedDevice) => {
            meter.metrologyTypeParsed =
              meter.metrologyType != null
                ? this.translate.instant(METROLOGY_TYPE[meter.metrologyType])
                : "-";
            meter.cups = meter.isAssigned ? meter.clave : null;
            meter.deviceType = this.ManufacturerService.getDeviceType(
              meter.fabricante,
              meter.devType
            );
            meter.isConcentrator =
              meter.metrologyType == 3 || meter.metrologyType == 5;
            meter.loadGraph =
              meter.metrologyType == METROLOGY_TYPE.WATER ||
              meter.metrologyType == METROLOGY_TYPE.GAS ||
              meter.metrologyType == METROLOGY_TYPE.SATELITE;
            meter.meterLink = this.DeviceRouteSelectorService.getDeviceRouteUrl(
              meter.metrologyType,
              meter.id
            );
            meter.pattern = this.meterConsumptionProfileList.find(
              (profile) => profile.meterId == meter.id
            );
            if (meter.pattern) {
              meter.consumptionProfile = this.consumptionProfileList.find(
                (profile) => profile.profileId == meter.pattern.profileId
              );
              meter.consumptionProfileName =
                meter.consumptionProfile?.profileName;
            }
            meter.patternAssign = meter.pattern ? false : true;
          });
          this.meterList = meterList.filter((meter) => !meter.isConcentrator);
          this.originalMeterList = meterList;
          this.isGasAgrupation = this.meterList.some(
            (meter) => meter.metrologyType == METROLOGY_TYPE.GAS
          );
          this.setColumns(cups);
        }
      }
    );
  }

  // Parseo de columnas de CUPS
  parseEntityDefinitions(entityDefinitions): EntityDefinition[] {
    return entityDefinitions.map((entityDefinition) => {
      return {
        id: entityDefinition.id,
        entity: entityDefinition.e,
        colPosition: entityDefinition.p,
        name: entityDefinition.n,
        label: entityDefinition.l,
        description: entityDefinition.d,
        show: entityDefinition.s,
        required: entityDefinition.r,
      };
    });
  }

  // Seteo de las columnas de la tabla
  setColumns(cups: EntityDefinition): void {
    let columns: (TableActionColumn | TableDataColumn)[] = [
      {
        title: "action",
        data: [
          {
            name: "patterns-assign",
            tooltip: "patterns-assign",
            icon: "fas fa-edit",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-eraser",
            visible: { attribute: null, rule: true },
            disabled: "patternAssign",
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: cups?.name,
        data: "cups",
        search: "cups",
        sort: "cups",
        visible: cups != null ? true : null,
      },
      {
        title: "serial-number",
        data: "nroSerie",
        search: "nroSerie",
        sort: "nroSerie",
        visible: true,
        link: "meterLink",
      },
      {
        title: "profile",
        data: "consumptionProfileName",
        search: "consumptionProfileName",
        sort: "consumptionProfileName",
        visible: true,
      },
      {
        title: "graph",
        data: null,
        search: null,
        sort: null,
        visible: true,
        graph: true,
      },
    ];
    this.columns = columns;
  }

  // Acciones de la tabla
  tableActions(action: string, meter: AssignedDevice): void {
    switch (action) {
      case "load-graph":
        this.getAlarmGraph(meter);
        break;
      case "patterns-assign":
        this.router.navigate(["/analisis-datos/modelos-analiticos/editar"], {
          state: { data: [meter.id] },
        });
        break;
      case "delete":
        let data = this.meterConsumptionProfileList
          .filter((profile) => profile.meterId == meter.id)
          .map((profile) => profile.id);
        if (data.length > 0) {
          this.DataAnalysisController.deleteConsumptionProfiles(data).subscribe(
            (response) => {
              if (response["code"] == 0) {
                this.ToastService.fireToast(
                  "success",
                  this.translate.instant("deleted-success")
                );
                this.getConsumptionProfiles();
              }
            }
          );
        }
        break;
      default:
        break;
    }
  }

  // Obtención de perfiles de consumo
  getConsumptionProfiles(): void {
    let consumptionProfileList = [];
    this.DataAnalysisController.getConsumptionProfiles().subscribe(
      (response) => {
        if (response["code"] == 0) {
          let consumptionProfileData = response["body"];
          consumptionProfileData.forEach((consumptionProfile) => {
            if (consumptionProfile.profilePatterns) {
              consumptionProfile.profilePatterns = JSON.parse(
                consumptionProfile.profilePatterns
              );
            } else {
              consumptionProfile.profilePatterns = {};
            }
            consumptionProfile.meterPattern = consumptionProfile.meterPattern
              ?.split(",")
              .map((data) => parseFloat(data));
          });
          consumptionProfileList = consumptionProfileData.filter(
            (consumptionProfile: ConsumptionProfile) =>
              !consumptionProfile.meterId
          );
          this.meterConsumptionProfileList = consumptionProfileData.filter(
            (consumptionProfile: ConsumptionProfile) =>
              consumptionProfile.meterId
          );
        }
        let profiles = Object.keys(CONSUMPTION_PROFILES);
        for (let i = 0; i < profiles.length / 2; i++) {
          if (i == 8) {
            i++;
          }
          let profile = consumptionProfileList.find(
            (consumptionProfile) => consumptionProfile.profileId == i
          );
          if (profile) {
            profile.profileName = this.translate.instant(
              "consumptionProfile" + profile.profileId
            );
          } else {
            consumptionProfileList.push({
              profileId: profile?.profileId,
              profileName: this.translate.instant("consumptionProfile" + i),
              profileSimilarity: 0.95,
            });
          }
        }
        this.consumptionProfileList = consumptionProfileList.sort(
          (a, b) => a.profileId - b.profileId
        );
        this.getData();
      }
    );
  }

  // Obtención de la gráfica de dispositivo con alarma
  getAlarmGraph(meter: AssignedDevice): void {
    this.MeterController.getGraph(
      meter.id,
      meter.metrologyType == METROLOGY_TYPE.GAS ? "3" : "2",
      String(this.defaultDateRange.startDate.valueOf()),
      String(this.defaultDateRange.endDate.valueOf())
    ).subscribe((response) => {
      if (response["code"] == 0) {
        let meterSerie = {
          id: "valor",
          name:
            meter.metrologyType == METROLOGY_TYPE.GAS
              ? this.translate.instant("consumption-normalized")
              : this.translate.instant("consumption"),
          type: "line",
          data: response["body"]?.readings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix:
              meter.metrologyType == METROLOGY_TYPE.GAS ? " Nm³" : " m³",
            valueDecimals: 3,
          },
          color: "#42a5f5",
          navigatorOptions: {
            enabled: false,
          },
        };
        this.setProfileChartsOptions(meter, meterSerie);
      }
    });
  }

  // Asignación de las opciones concretas para la gráfica
  setProfileChartsOptions(meter: AssignedDevice, meterSerie: any): void {
    if (meter.consumptionProfile) {
      let meterMean = ss.mean(meter.pattern.meterPattern);
      let meterSd = ss.standardDeviation(meter.pattern.meterPattern);
      meter.loadGraph = false;
      meter.graphData = {
        title: meter.nroSerie,
        series: [
          {
            id: "profilePatternRange",
            name: this.translate.instant(
              "consumptionProfile" + meter.consumptionProfile.profileId
            ),
            tooltip: {
              split: false,
              shared: true,
              valueDecimals: 3,
              formatter: function () {
                return Highcharts.dateFormat("%A %H:%M", this.point.x);
              },
            },
            data: meter.consumptionProfile
              ? meter.consumptionProfile.profilePatterns["hourly"]?.map(
                  (data, i) => {
                    let min = data * meterSd + meterMean - meterSd;
                    let max = data * meterSd + meterMean + meterSd;
                    return [
                      this.defaultDateRange.startDate.valueOf() +
                        60 * 60 * 1000 * i,
                      min > 0 ? min : 0,
                      max > 0 ? max : 0,
                    ];
                  }
                )
              : [],
            dataGrouping: {
              forced: true,
              units: [["hour", [1]]],
            },
            type: "arearange",
            color: "#4caf50",
            fillColor: "rgba(76, 175, 80, 0.3)",
          },
          meterSerie,
        ],
      };
    } else {
      meter.loadGraph = false;
      meter.graphData = {
        title: meter.nroSerie,
        series: [meterSerie],
      };
    }
  }
}
