<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{ "patterns" | translate }}: {{ currentAgrupation?.name }}</b>
        </h5>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersAssigned'"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          [data]="meterList"
          (tableAction)="tableActions($event.action.name, $event.element)"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
