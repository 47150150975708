<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <div class="balance-detail-heading-info">
          <i
            class="fas fa-edit pointer"
            title="{{ 'edit' | translate }}"
            (click)="editGroup()"
          >
          </i>
        </div>
        <h5>
          <b
            >{{ "hydraulic-groups" | translate }} :
            {{ balance?.name != null ? balance?.name : null }}</b
          >
        </h5>
      </div>

      <div class="panel-body">
        <div #balanceDetail class="balance-detail-box">
          <div class="balance-detail-cards-box">
            <!-- Tarjetas de datos -->
            <app-cards-controller
              [cardsId]="'dataAnalysis'"
              [data]="balanceCardsData"
              [cardStretch]="true"
              [cardWidth]="48"
              [cardsGap]="1"
              (cardActionFlag)="cardsActions($event)"
            >
            </app-cards-controller>

            <!-- Listado de alarmas -->
            <div *ngIf="cardsData; else elseBlock" class="balance-alarms">
              <div
                class="balance-alarms-title"
                [ngClass]="
                  cardsData.alarms.length == 0 || cardsData.alarms == null
                    ? 'table-bg-green-gradient'
                    : 'table-bg-red-gradient'
                "
              >
                <i class="fa fa-tachometer-alt"></i> {{ "alarms" | translate }}
              </div>
              <div class="balance-alarms-table">
                <table>
                  <tr
                    *ngIf="
                      cardsData.alarms.length == 0 || cardsData.alarms == null
                    "
                  >
                    <td>
                      {{ "no-alarms" | translate }}
                    </td>
                  </tr>
                  <ng-container *ngFor="let alarm of cardsData.alarms">
                    <tr>
                      <td class="balance-alarms-table-date">
                        {{ alarm.initDateParsed }}
                        {{
                          alarm.finalDateParsed != null
                            ? "- " + alarm.finalDateParsed
                            : null
                        }}
                      </td>
                      <td class="meter-alarms-table-alarm">
                        {{ alarm.code }}
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </div>
          </div>

          <!-- Mapa de balance -->
          <div class="balance-detail-map">
            <app-map-controller
              *ngIf="mapHeight"
              [mapHeight]="mapHeight"
              [mapType]="mapType"
              [gatewaysData]="[]"
              [metersData]="metersData"
            >
            </app-map-controller>
          </div>
        </div>
      </div>
    </div>

    <!-- Gráficas -->
    <div *ngIf="balance" class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <b>{{ balance.name }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <app-balance-graphs
          *ngIf="cardsData; else elseBlock"
          [currentAgrupation]="currentAgrupation"
          [graphDataLoaded]="graphData"
          [data]="balance"
        >
        </app-balance-graphs>
      </div>
    </div>
  </div>
</section>
