import { Injectable } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Cesium
import { AcEntity } from "angular-cesium";
declare var Cesium: any;
// Variables de mapa
import { CESIUM_MAP_CONSTS } from "../CESIUM_MAP_CONSTS";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";

@Injectable({
  providedIn: "root",
})
export class CesiumMapDeviceIconService {
  constructor(private translate: TranslateService) {}

  // Obtención del icono de dispositivo
  getDeviceIcon(
    mapType: string,
    device: any,
    deviceType: string,
    deviceTypeByMask: string,
    isLwUne: boolean,
    isLwMbus,
    tooltip: string,
    gateway: any,
    locations?: any
  ): any {
    let color: string;
    let classes: string;
    let title: string;

    switch (mapType) {
      case "coverage":
        // Color de icono
        color =
          device.otherGateway == true
            ? "YELLOW"
            : CESIUM_MAP_CONSTS.markerVariables[deviceType].color;
        // Clases
        classes =
          device.otherGateway == true
            ? "yellowIcon"
            : CESIUM_MAP_CONSTS.markerVariables[deviceType].iconClasses;
        // Título
        title = this.translate.instant(
          CESIUM_MAP_CONSTS.markerVariables[deviceType].markerTitle
        );
        break;
      default:
        // Tipo de dispositivo
        let standardMeterType: string =
          deviceType == "NO_LORA"
            ? this.getDeviceType(deviceTypeByMask, device)
            : deviceType;
        if (device.selected) {
          standardMeterType = "selected";
        }
        // Color de icono
        color = CESIUM_MAP_CONSTS.markerVariables[standardMeterType].color;
        // Clases
        classes =
          device.deactivate == true && deviceTypeByMask != DEVICE_BY_COMM.NO_LORA_NO_COM
            ? "grayIcon"
            : CESIUM_MAP_CONSTS.markerVariables[standardMeterType].iconClasses +
              (isLwUne ? " LW_UNE_CON" : "") +
              (isLwMbus ? " LW_MBUS_CON" : "");
        // Título
        title =
          this.translate.instant(
            CESIUM_MAP_CONSTS.markerVariables[standardMeterType].markerTitle
          ) +
          " - " +
          device.nroSerie;
        break;
    }

    return this.convertToCesiumObj(
      device,
      deviceType,
      color,
      classes,
      title,
      tooltip,
      gateway,
      locations
    );
  }

  // Conversión a entidad Cesium
  convertToCesiumObj(
    device: any,
    deviceType: string,
    color: string,
    classes: string,
    title: string,
    tooltip: string,
    gateway: any,
    locations?: any
  ): any {
    // Posición del dispositivo
    let devicePosition = Cesium.Cartesian3.fromDegrees(
      device.longitude,
      device.latitude,
      1
    );
    // Posición del gateway
    let gatewayPosition = gateway
      ? Cesium.Cartesian3.fromDegrees(gateway.longitude, gateway.latitude, 1)
      : Cesium.Cartesian3.fromDegrees(device.longitude, device.latitude, 1);
    // Posición de localización
    if (device.locationList?.length > 0) {
      locations.map((location) => {
        location.position = Cesium.Cartesian3.fromDegrees(
          location.longitude,
          location.latitude,
          1
        );
      });
    }
    // Entidad de dispositivo
    return new AcEntity({
      id: device.id,
      name: device.nroSerie ? device.nroSerie : title,
      devEui: device.devEui,
      timestamp: device.timestamp,
      longitude: device.longitud,
      latitude: device.latitud,
      classes: classes,
      title: title,
      tooltip: tooltip,
      color: Cesium.Color[color],
      gateway: device.unidadVentaGw,
      deviceType: deviceType,
      locationList: device.locationList,
      type: "device",
      linkPositions: [devicePosition, gatewayPosition],
      locations: locations,
      position: devicePosition,
      show: true,
    });
  }

  // Tipo de dispositivo no Lora
  getDeviceType(deviceTypeByMask: string, device: any): string {
    switch (deviceTypeByMask) {
      case DEVICE_BY_COMM.EXTERNO:
        return device.comunica ? "EXTERNO_OK" : "EXTERNO_NO_COMUNICA";
      case DEVICE_BY_COMM.ERM:
        return device.comunica ? "ERM_OK" : "ERM_NO_COMUNICA";
      case DEVICE_BY_COMM.EK280:
        return device.comunica ? "EK_OK" : "EK_NO_COMUNICA";
      case DEVICE_BY_COMM.PLUM:
        return device.comunica ? "PLUM_OK" : "PLUM_NO_COMUNICA";
      default:
        return "NO_LORA";
    }
  }
}
