<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <div class="panel-nav">
          <!-- Título -->
          <h5>{{ "alarms" | translate }}</h5>
        </div>
        <div class="alarm-global-controls">
          <!-- Últimas horas -->
          <div class="alarm-global-hours">
            <mat-form-field class="material-header-input" appearance="standard">
              <mat-label>
                <span>{{ "show-alarms-hours" | translate }}</span>
                <i class="fas fa-clock"></i>
              </mat-label>

              <input matInput type="number" [(ngModel)]="alarmHours" min="1" />
              <span matSuffix>{{ "hours" | translate }}</span>
            </mat-form-field>
          </div>

          <!-- Refresco -->
          <div class="alarm-global-hours">
            <mat-form-field class="material-header-input" appearance="standard">
              <mat-label
                ><span>{{ "show-alarms-refresh" | translate }}</span>
                <i class="fas fa-sync-alt"></i
              ></mat-label>
              <input
                matInput
                type="number"
                [(ngModel)]="alarmRefresh"
                min="1"
              />
              <span matSuffix>{{ "minutes" | translate }}</span>
            </mat-form-field>
          </div>

          <!-- Sonido -->
          <button
            mat-button
            class="mat-button-icon"
            [color]="soundActive ? 'primary' : null"
            (click)="soundActive = !soundActive"
          >
            <i
              [ngClass]="
                soundActive ? 'fas fa-volume-up' : 'fas fa-volume-mute'
              "
            ></i>
          </button>

          <!-- Actualizar -->
          <button
            mat-button
            class="mat-button-icon"
            title="{{ 'update' | translate }}"
            (click)="
              loadComponent();
              ToastService.fireToast(
                'success',
                translate.instant('data-updated')
              )
            "
          >
            <i class="fas fa-sync-alt fa-spin"></i>
          </button>
        </div>

        <!-- Número de alarmas -->
        <div
          class="alarm-global-counter"
          [ngClass]="{
            'bg-red-gradient': tableData?.length > 0,
            'bg-green-gradient': tableData?.length == 0
          }"
        >
          <i class="fa fa-exclamation-triangle"></i>
          {{ activeAlarms ? activeAlarms : "-" }}
        </div>
      </div>
      <div class="other-alarms" *ngIf="numberDevicesWithoutFCV > 0">
        <button type="button" (click)="getDevicesWithoutPCSFCVTable()">
          <i class="fa-solid fa-exclamation-triangle"></i>
          {{ numberDevicesWithoutFCV }} {{ "devices-without" | translate }} FCV/PCS
        </button>
      </div>
      <div class="panel-body">
        <div class="map-panel">
          <!-- Mapa de contadores -->
          <app-map-controller
            *ngIf="devices && gateways"
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            (actionFlag)="updateAgrupation($event)"
            [gatewaysData]="gateways"
            [metersData]="devices"
          >
          </app-map-controller>
        </div>

        <!-- Tabla de alarmas -->
        <app-table-controller
          [tableId]="'alarmsGlobalList'"
          [export]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [avoidRepaint]="true"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [quickFilters]="quickFilters"
          [hideQuickFilters]="true"
          (selectedDataUpdateFlag)="selectedDataUpdate($event)"
          [specialFilter]="true"
          [highlightRow]="tableHighlightRow"
          [data]="tableData"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
