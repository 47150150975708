<!-- Tabla de CUPS -->
<ng-container *ngIf="cupsData?.columns.length > 1">
  <app-table-controller
    class="cups-table"
    [export]="false"
    [columns]="tableColumns"
    [rowNumbers]="false"
    [maxReg]="tableMaxReg"
    (tableAction)="tableActions($event.action.name, $event.element)"
    [onlyMenu]="false"
    [columnOrderDisabled]="true"
    [paginationDisabled]="true"
    [noPinnedColumns]="true"
    [avoidFixColumns]="true"
    [highlightRow]="tableHighlightRow"
    [data]="tableData"
  >
  </app-table-controller>
</ng-container>

<!-- Spinner de carga -->
<ng-template #elseBlock>
  <div class="loading-spinner">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
</ng-template>
