// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import { TooltipOptions, Options } from "highcharts";
// Servicios propios
import { NetworkStateControllerService } from "../../../../services/server/NetworkStateController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import {
  EntityTableData,
  NetworkStateCards,
  NetworkStateGraphData,
  NetworkStateGraphSerie,
} from "../NetworkStateInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-billingEntity",
  templateUrl: "./network-state-entity.component.html",
  styleUrls: ["./network-state-entity.component.scss"],
})
export class BillingEntityComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  // Variables de las tarjetas
  cardsData: NetworkStateCards;
  cardsOrder: string[] = [
    "metersDetected",
  ];
  cardsLoading: boolean;
  updateTitle: string =
    this.translate.instant("update") + " " + this.translate.instant("cards");

  // Tabla
  exportFileName: string;
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  entityData: EntityTableData;
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "name",
      data: "agrupationName",
      search: "agrupationName",
      sort: "agrupationName",
      visible: true,
    },
    {
      title: "meters-in-network",
      data: "contadoresAsignadosParsed",
      search: "contadoresAsignadosParsed",
      sort: "contadoresAsignados",
      numerical: true,
      visible: true,
    },
    {
      title: "meters-valid-read",
      data: "contadoresLeidosDiaParsed",
      search: "contadoresLeidosDiaParsed",
      sort: "contadoresLeidosDia",
      numerical: true,
      visible: true,
    },
    {
      title: "meters-read-last-month",
      data: "contadoresLeidosMesParsed",
      search: "contadoresLeidosMesParsed",
      sort: "contadoresLeidosMes",
      numerical: true,
      visible: true,
    },
  ];

  // Gráfica
  graphSeries: NetworkStateGraphSerie[];
  graphData: NetworkStateGraphData[];
  graphTooltip: TooltipOptions = {
    valueDecimals: 0,
    headerFormat: "",
    pointFormat:
      "<span style='color:{series.color}'>{series.name}</span>: <b>{point.y}</b><br/>",
  };
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private NetworkStateController: NetworkStateControllerService,
    private GraphOptionsService: GraphOptionsService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.exportFileName =
      this.translate.instant("billing-entity-export") +
      " " +
      moment().format("ll");

    // Escucha de cambios en los valores de entidad y agrupación
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation && this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getCards();
  }

  // Obtención de los datos de las tarjetas y la tabla
  getCards(reload?: boolean): void {
    this.cardsLoading = true;
    this.cardsData = null;
    let cardsData: NetworkStateCards = new NetworkStateCards();
    let entityData: EntityTableData;
    let request: Observable<object> =
      this.NetworkStateController.getEntityCards(this.currentEntity.id);

    if (reload) {
      request = this.NetworkStateController.getEntityNewCards(
        this.currentEntity.id
      );
    }

    request.subscribe((response) => {
      if (response["code"] == 0) {
        entityData = response["body"]["agrupations"];
        cardsData.metersDetected = {
          data: response["body"]["contadoresAsignados"],
          type: "number",
        };
        delete cardsData.metersInNetwork;
        cardsData.metersValidRead = {
          data: response["body"]["contadoresLeidosDia"],
          type: "number",
        };
        cardsData.metersReadLastMonth = {
          data: response["body"]["contadoresLeidosMes"],
          type: "number",
        };
      }

      this.entityData = entityData;
      this.cardsData = cardsData;
      this.cardsLoading = false;
      this.setHighchartsOptions();
    });
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    let graphData: NetworkStateGraphData[] = [];
    this.NetworkStateController.getEntityGraph(
      this.currentEntity.id,
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let entityData = response["body"]["entityGraphValues"].map(
          (value: number[]) => {
            value[0] = Math.trunc(value[0] / 1000) * 1000;
            return value;
          }
        );
        let entityDataChecked = [];
        entityData.forEach((value: number[]) => {
          let checkedIndex = entityDataChecked.findIndex(
            (checkedValue: number[]) => checkedValue[0] == value[0]
          );
          if (checkedIndex >= 0) {
            entityDataChecked[checkedIndex][1] += value[1];
          } else {
            entityDataChecked.push(value);
          }
        });
        graphData.push({
          name: this.translate.instant("entity"),
          data: entityDataChecked,
        });

        response["body"]["agrupationsGraphValues"].forEach(
          (agrupation: { agrupationName: string; graphValues: number[][] }) => {
            let agrupationData = agrupation["graphValues"].map(
              (value: number[]) => {
                value[0] = Math.trunc(value[0] / 1000) * 1000;
                return value;
              }
            );
            graphData.push({
              name: agrupation.agrupationName,
              data: agrupationData,
            });
          }
        );

        graphData.unshift({
          name: this.translate.instant("meters-installed-by-day"),
          data: entityDataChecked.map((data, i) => {
            if (i > 0) {
              return [data[0], data[1] - entityDataChecked[i - 1][1]];
            } else {
              return [data[0], 0];
            }
          }),
        });
      }
      this.graphData = graphData;
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let series: NetworkStateGraphSerie[] = [];
    this.graphData.forEach((element: NetworkStateGraphData, i) => {
      series.push({
        id: element.name,
        name: element.name,
        data: element.data,
        tooltip: this.graphTooltip,
        dataGrouping: { approximation: "sum" },
        type: i > 0 ? "line" : "column",
        yAxis: i > 0 ? 1 : 0,
      });
    });
    this.graphSeries = series;
    this.setChartOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    highchartsOptions.plotOptions.series.dataGrouping.units = [["day", [1]]];
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["rangeSelector"]["buttons"].shift();
    chartOptions["yAxis"] = [
      {
        title: {
          text: this.translate.instant("meters-installed-by-day"),
          style: {
            color: "#42a5f5",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: "#42a5f5",
          },
        },
        navigatorOptions: {
          type: "column",
        },
        visible: true,
        opposite: true,
      },
      {
        title: {
          text:
            this.translate.instant("meters") +
            " " +
            this.translate.instant("actives")?.toLowerCase(),
          style: {
            color: "#ef5350",
            fontWeight: "bold",
          },
        },
        labels: {
          style: {
            color: "#ef5350",
          },
        },
        visible: true,
        opposite: false,
      },
    ];
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }
}
