import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";

// jQuery
declare var $: any;

// Cesium
declare var Cesium: any;
Cesium.buildModuleUrl.setBaseUrl("/assets/cesium/");
Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiNmIzMWNhZC1hMzkzLTQ2NTctYjlhYi00YThhZTE3YTQzZGUiLCJpZCI6MTEwMDM0LCJpYXQiOjE2NjQ4Nzg5Mzh9.dik5dBSY20J_BIoWK9RgSwOHoz5CfuZVQMhyRF6HLi4";
platformBrowserDynamic().bootstrapModule(AppModule);

$("html").attr("lang", navigator.language);
