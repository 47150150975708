<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "pending-meter-list" | translate }}</b>
        </h5>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="updateTitle"
          [disabled]="updating"
          [updating]="updating"
          (action)="resetData()"
        >
        </app-material-panel-menu>
      </div>

      <div id="table-panel" class="panel-body meter-unassigned-panel">
        <!-- Filtros de fabricante/modelo -->
        <div class="meter-list-noassign-dropdowns">
          <app-device-type-filter
            *ngIf="meterList"
            [deviceList]="meterList"
            (selectionUpdate)="
              selectedManufacturer = $event.manufacturer;
              selectedModel = $event.model
            "
            [tableFilterActive]="true"
            (tableFilter)="deviceTypeFilters = $event"
          >
          </app-device-type-filter>
        </div>

        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersUnnassigned'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [quickFilters]="quickFilters"
          [quickFiltersExclusion]="quickFiltersExclusion"
          [externalFilters]="deviceTypeFilters"
          (filterResetFlag)="resetDeviceTypeFilter()"
          (selectedDataUpdateFlag)="tableSelectedData = $event"
          [tableGlobalActions]="tableGlobalActions"
          (tableGlobalAction)="tableGlobalAction($event.title)"
          [specialFilter]="true"
          [data]="meterList"
        >
        </app-table-controller>

        <div class="meter-list-map">
          <app-map-selector
            *ngIf="mapSelectorActive"
            id="meter-list-map"
            [dataList]="meterList"
            [originalDataList]="originalMeterList"
            [tableSelectedData]="tableSelectedData"
            [table]="tableController"
            [filterAttribute]="'nroSerie'"
            [anchorToScroll]="'table-panel'"
            (updateData)="meterList = $event"
          >
          </app-map-selector>
        </div>
      </div>
    </div>
  </div>
</section>
