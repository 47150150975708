<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h5>
          <app-panel-back class="panel-back-link"></app-panel-back>
          <b>{{ "ip-blocked" | translate }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <!-- Tabla de blacklist -->
        <app-table-controller
          [tableId]="'blackipList'"
          [specialFilter]="true"
          [export]="true"
          [exportFileName]="exportBlacklist"
          [columns]="blacklistColumns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="
            blacklistTableActions($event.action.name, $event.element)
          "
          [data]="blacklist"
        >
        </app-table-controller>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>
        <h5>
          <app-panel-back class="panel-back-link"></app-panel-back>
          <b>{{ "ip-allowed" | translate }}</b>
        </h5>

        <!-- Botón nuevo -->
        <app-material-panel-menu
          [onlyButton]="true"
          [icon]="newIcon"
          [title]="newTitle"
          (action)="newIp()"
        >
        </app-material-panel-menu>
      </div>

      <div class="panel-body">
        <!-- Tabla de whitelist -->
        <app-table-controller
          [tableId]="'whiteipList'"
          [specialFilter]="true"
          [export]="true"
          [exportFileName]="exportWhitelist"
          [columns]="whitelistColumns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          (tableAction)="
            whitelistTableActions($event.action.name, $event.element)
          "
          [data]="whitelist"
        >
        </app-table-controller>
      </div>
    </div>
  </div>
</section>
