<div class="dialog-container dialog-container-overflow">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "delete-readings" | translate }}</h5>
  </div>

  <div class="dialog-body">
    <!-- Contenedor flex para input y botón -->
    <div class="date-selector">
      <app-material-date-selector
        [initialDateNull]="false"
        [maxInterval]="null"
        [minInterval]="null"
        (dateRangeUpdated)="selectedDateRange = $event"
      >
      </app-material-date-selector>
    </div>
  </div>

  <div class="dialog-footer">
    <button mat-button (click)="deleteRange()">
      {{ "delete" | translate }}
    </button>
    <button mat-button (click)="close()">
      {{ "close" | translate }}
    </button>
  </div>
</div>
