import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Servicio propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { DragElementService } from "../../../../../../../services/shared/DragElementService.service";
import { DeviceConsumptionLeaksDetectionService } from "../../../../../../../screens/dashboard/devices/devices-common-components/device-consumption-graph/device-consumption-leaks-detection.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { GraphOptionsService } from "../../../../../../../modules/graph-module/GraphOptionsService.service";
// Variables
import { METROLOGY_TYPE } from "../../../../../../../interfaces/DeviceGlobalInterface.type";
import { GRAPH_CONFIG } from "../../../../../../../modules/graph-module/GRAPH_CONFIG";

@Component({
  selector: "app-meter-voltage-dialog",
  templateUrl: "./meter-voltage-dialog.component.html",
  styleUrls: ["./meter-voltage-dialog.component.scss"],
})
export class MeterVoltageDialogComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  @Input() data: any;
  highchartsOptions: Options;
  chartOptions: any;
  parsedHtml: string;
  defaultDateRange: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  } = this.DateParserService.getLastDays(7);
  graphInitiated: boolean = false;
  graphData: any;

  // Fugas
  circleHighchartOptions: Options;
  circleChartOptions: any;
  @ViewChild("graphCircle") graphCircle: ElementRef;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private DeviceConsumptionLeaksDetectionService: DeviceConsumptionLeaksDetectionService,
    public DragElementService: DragElementService,
    private GraphOptionsService: GraphOptionsService,
    private MeterController: MeterControllerService,
    public SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Si 'data.device.graphData' existe, procedemos con la lógica de gráficos
    if (this.data?.device?.graphData) {
      this.graphData = { ...this.data.device.graphData };

      // Aseguramos que 'graphData.series' existe antes de manipularla
      if (this.graphData.series) {
        this.graphData.series.forEach(
          (serie) => (serie.type = serie.type || this.graphData.type)
        );
      }

      // Configuramos las opciones de Highcharts y de la gráfica
      this.setHighchartsOptions();
      this.setChartsOptions();
    } else if (this.data?.html) {
      // Si 'data' no tiene 'graphData', asignamos el contenido HTML
      this.parsedHtml = this.data.html;
      this.graphData = null; // No usamos gráficos, por lo tanto 'graphData' es null
    }

    // Si 'graphData.html' existe, formateamos el HTML
    if (this.graphData?.html || this.parsedHtml) {
      this.parsedHtml = (this.graphData?.html || this.parsedHtml).replace(
        /\n/g,
        "<br />"
      );
    }
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions: any =
      this.GraphOptionsService.getDefaultHighchartsOptions();

    highchartsOptions.plotOptions.series.marker.enabled = false;
    highchartsOptions["tooltip"] = {
      shared: true,
      enabled: true,
      outside: false,
    };

    this.highchartsOptions = highchartsOptions;
  }

  setChartsOptions(): void {
    let months = this.translate.instant("months");
    let chartOptions = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["rangeSelector"];
    chartOptions["chart"].height = null;
    chartOptions["series"] = this.graphData.series;

    // Encontrar el máximo de todas las series
    let globalMax = -Infinity;
    this.graphData.series.forEach((serie) => {
      const serieData = serie.data.map((dataPoint: any) => dataPoint.y);
      const maxValue = Math.max(...serieData);
      globalMax = Math.max(globalMax, maxValue); // Actualizamos el globalMax con el máximo de la serie
    });

    // Configuramos los ejes Y para que ambos tengan el mismo rango
    let yAxes = this.graphData.series.map((serie, index) => {
      let previousValue = null; // Variable para recordar el valor anterior de la etiqueta

      // Asignar el título del eje como el nombre de la serie
      const axisConfig = {
        title: { text: serie.name }, // El título será el nombre de la serie
        min: 0, // Mínimo de los ejes Y
        max: globalMax, // Máximo común para ambos ejes Y
        labels: {
          formatter: function () {
            let currentValue = this.value;
            // Para el segundo eje (index 1), eliminamos los decimales
            if (index === 1) {
              currentValue = Math.round(currentValue); // Redondeamos el valor para eliminar los decimales

              // Si el valor actual es el mismo que el anterior, no mostramos la etiqueta
              if (currentValue === previousValue) {
                return null; // No mostrar la etiqueta
              }

              previousValue = currentValue; // Guardamos el valor actual para la próxima comparación

              return currentValue + months; // Mostramos el valor redondeado
            }
            return this.value + "V"; // El primer eje puede mostrar decimales
          },
          style: {
            color: index === 0 ? "#42a5f5" : "#ff7043", // Color del eje dependiendo de la serie
          },
        },
        showFirstLabel: true,
        showLastLabel: true,
        opposite: index > 0, // El primer eje estará en el lado izquierdo (opposite: false), el segundo en el derecho (opposite: true)
      };
      return axisConfig;
    });

    // Asignamos los ejes configurados a chartOptions["yAxis"]
    chartOptions["yAxis"] = yAxes;

    // Asignamos las series a las opciones de gráfico
    chartOptions["series"] = this.graphData.series;

    // Desactivar la leyenda y el navegador
    chartOptions["legend"] = {
      enabled: false,
    };
    chartOptions["navigator"] = {
      enabled: false,
    };

    this.chartOptions = chartOptions;

    // Fugas
    if (
      this.graphData.showLeaks &&
      this.data.device.metrologyType != METROLOGY_TYPE.GAS
    ) {
      let readings = this.graphData.series[0].data;
      let leaks: {
        serie: any;
        totalLeak: number;
        totalConsumption: number;
      } = this.DeviceConsumptionLeaksDetectionService.getLeakSeries(
        readings,
        this.graphData.units
      );

      // Si se detectan fugas, se añaden al gráfico
      if (
        leaks &&
        !this.graphData.series.some(
          (existingSerie) => existingSerie.name === leaks.serie.name
        )
      ) {
        this.graphData.series.push(leaks.serie);
        this.circleHighchartOptions =
          this.DeviceConsumptionLeaksDetectionService.setCircleHighchartsOptions();
        this.circleChartOptions =
          this.DeviceConsumptionLeaksDetectionService.setCircleChartsOptions(
            leaks.totalLeak,
            leaks.totalConsumption,
            this.graphData.units
          );
      }
    }
  }

  loadGraphData(from: string, to: string): void {
    // Llamada al servicio para obtener los datos de la gráfica de energía
    this.MeterController.getEkBatteryStateGraph(
      this.data.device.id,
      from,
      to
    ).subscribe({
      next: (response) => {
        // Aignamos los datos recibidos en el cuerpo de la respuesta
        response = response["body"];

        // Encontrar el valor máximo entre los valores de las lecturas
        const maxBatteryVoltage = Math.max(
          ...response.map((dataPoint) => dataPoint.tensionBateriaModem)
        );

        // Determinar el valor máximo global entre las dos series

        // Configuración de los datos del gráfico de energía
        this.graphData = {
          showLeaks: false, // Indica si mostrar filtrado de fugas
          title: this.data.device.nroSerie, // Título del gráfico (número de serie del medidor)
          navigatorOptions: {
            type: "column", // Tipo de gráfico para el navegador
          },
          yAxis: {
            min: 1, // Mínimo valor del eje Y
            max: maxBatteryVoltage, // Máximo común para ambos ejes Y
          },
          series: [
            {
              id: "lastModemBatteryVoltage",
              name: this.translate.instant("tension"), // Nombre de la serie (consumo de energía)
              type: "area", // Tipo de gráfico de área
              data: response.map((dataPoint) => ({
                x: dataPoint.timestamp, // Timestamp para el eje X
                y: dataPoint.tensionBateriaModem, // Usamos 'tensionBateriaModem' para el consumo de energía
              })),
              tooltip: {
                valueSuffix: "V", // Sufijo de la unidad en el tooltip
                valueDecimals: 3, // Número de decimales para mostrar
              },
              color: "#42a5f5", // Color del gráfico
              navigatorOptions: {
                type: "line", // Tipo de gráfico para el navegador
              },
            },
            // BATERIA RESTANTE
            // {
            //   id: "remainingBatteryLife",
            //   name: this.translate.instant("ek-remaining-battery-life"),  // Nombre de la serie (vida restante de la batería)
            //   type: "line",  // Tipo de gráfico de líneas
            //   data: response.map((dataPoint) => ({
            //     x: dataPoint.timestamp,  // Timestamp para el eje X
            //     y: dataPoint.tiempoRestanteVidaBateria,  // Usamos 'tiempoRestanteVidaBateria' para el tiempo restante
            //   })),
            //   tooltip: {
            //     valueSuffix: this.translate.instant("months"),  // Sufijo de la unidad en el tooltip (meses)
            //     valueDecimals: 0,  // Número de decimales para mostrar (sin decimales)
            //   },
            //   color: "#ff7043",  // Color del gráfico
            //   navigatorOptions: {
            //     type: "line",  // Tipo de gráfico para el navegador
            //   },
            // },
          ],
        };
        this.setChartsOptions();
      },
      error: (error) => {
        console.error(
          "Error en la llamada al servicio de gráficos de energía:",
          error
        );
      },
    });
  }
}
