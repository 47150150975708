// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { CupsControllerService } from "../../../../../services/server/CupsController.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import {
  ClaveMaestra,
  EntityDefinition,
} from "../../../../../interfaces/CupsGlobalInterface.type";
// Variables
import { PROFILES } from "../../../../../../assets/profiles/profiles";
import { MinifiedEntityDefinition } from "../../../control/ControlInterface.type";

@Component({
  selector: "app-cups-multiple-devices",
  templateUrl: "./cups-multiple-devices.component.html",
  styleUrls: ["./cups-multiple-devices.component.scss"],
})
export class CupsMultipleDevicesComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  sessionProfile: string;
  currentEntity: Entity;
  entitySub: Subscription;
  entityList: Entity[];

  // Tabla
  rowNumbers: boolean = true;
  maxReg: number = 100;
  tableData: ClaveMaestra[];
  exportFileName: string =
    this.translate.instant("export-info") +
    " " +
    this.DateParserService.getDate();
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[];
  entitiesDefinitions: {
    entityId: number;
    entityDesc: string;
    definitionList: MinifiedEntityDefinition[];
    definitionListParsed: EntityDefinition[];
  }[];
  cupsSelectorOptions: {
    label?: string;
    entityId: number;
    entityName: string;
  }[] = [];
  selectedCups: number;

  // CUPS
  cups: string[];
  serialNumber: string[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private DateParserService: DateParserService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos
  getData(): void {
    this.CupsController.getCupsWidthMultipleMeters().subscribe((response) => {
      if (response["code"] == 0) {
        let cmList = response["body"]["claveMaestraList"];
        let tableData: ClaveMaestra[] = [];
        cmList.forEach((data) => {
          let cups = data.cm;
          cups.meters = data.meters;
          cups.claveParsed = cups.clave != null ? cups.clave : null;
          cups.cupsLink = cups.claveParsed ? "/cups/detalle/" + cups.id : null;
          cups.entityDesc = data.entityDesc;
          tableData.push(cups);
          this.setMeterEntityCupsColumns(cups);
        });

        // CUPS
        this.cups = [];
        this.serialNumber = [];
        this.entitiesDefinitions =
          response["body"]?.entityClaveMaestraDefinitionList;
        this.entitiesDefinitions?.forEach((entityDefinition) => {
          entityDefinition.definitionListParsed = [];
          let cupsSelectorOption = {
            label: null,
            entityName: entityDefinition.entityDesc,
            entityId: entityDefinition.entityId,
          };
          entityDefinition.definitionList.forEach((definition) => {
            entityDefinition.definitionListParsed.push({
              id: definition.id,
              entity: definition.e,
              colPosition: definition.p,
              name: definition.n,
              label: definition.l,
              description: definition.d,
              show: definition.s,
              required: definition.r,
            });

            if (definition.p == 0) {
              this.cups.push(definition.l);
              cupsSelectorOption.label =
                cupsSelectorOption.entityName + " (" + definition.l + ")";
            }
            if (definition.p == 100) {
              this.serialNumber.push(definition.l);
            }
          });
          this.cupsSelectorOptions.push(cupsSelectorOption);
        });
        this.selectedCups = this.cupsSelectorOptions[0]?.entityId;
        this.tableData = tableData;
        this.setColumns();
      }
    });
  }

  // Seteo de las columnas de CUPS de contador según entidad
  setMeterEntityCupsColumns(cups: ClaveMaestra): void {
    for (let attribute in cups) {
      if (attribute?.includes("col")) {
        cups[cups.entity + attribute] = cups[attribute];
      }
    }
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    // let fcvAndPcsVisible = this.SessionDataService.getCurrentClient().gas;
    let columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
      {
        title: this.translate.instant("entity"),
        data: "entityDesc",
        search: "entityDesc",
        sort: "entityDesc",
        visible: true,
      },
      {
        title: "CUPS",
        data: "clave",
        search: "clave",
        sort: "clave",
        link: "cupsLink",
        linkCheck: {
          condition: "entity",
          attribute: "entity",
          check: "id",
        },
        visible: true,
      },
      {
        title: this.translate.instant("meters"),
        data: "meters",
        search: "meters",
        sort: "meters",
        numerical: true,
        visible: true,
      },
      {
        title: "creation-date",
        data: "installation",
        search: "installation",
        sort: "installation",
        date: true,
        visible: true,
      },
    ];

    this.entitiesDefinitions?.forEach((entityDefinitions) => {
      entityDefinitions.definitionListParsed?.forEach(
        (extraColumn: EntityDefinition) => {
          if (
            extraColumn.show &&
            this.selectedCups == entityDefinitions.entityId
          ) {
            let attributePosition: string =
              entityDefinitions.entityId +
              (extraColumn.colPosition <= 9
                ? "col0" + extraColumn.colPosition
                : "col" + extraColumn.colPosition);
            if (
              extraColumn.colPosition != 100 &&
              extraColumn.colPosition != 0
            ) {
              let newColumn: TableDataColumn = {
                title: extraColumn.name,
                data: attributePosition,
                search: attributePosition,
                sort: attributePosition,
                long: true,
                visible: true,
              };
              columns.push(newColumn);
            }
          }
        }
      );
    });
    this.columns = columns;
  }
}
