import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-404",
  templateUrl: "./404.component.html",
  styleUrls: ["./404.component.scss"],
})
export class NotFoundComponent implements OnInit {
  constructor(private location: Location, private router: Router) {}

  ngOnInit() {}

  goBack() {
    this.location.back();
  }
}
