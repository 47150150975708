<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b> {{ "unknown-devices" | translate }}</b>
        </h5>

        <!-- Botón actualizar -->
        <app-material-panel-menu
          [onlyButton]="true"
          [title]="updateTitle"
          [disabled]="updating"
          [updating]="updating"
          (action)="getData()"
        >
        </app-material-panel-menu>
      </div>

      <div id="table-panel" class="panel-body">
        <!-- Tabla -->
        <app-table-controller
          [tableId]="'metersUnkonwn'"
          [export]="true"
          [exportSelection]="true"
          [exportFileName]="exportFileName"
          [columns]="columns"
          [rowNumbers]="true"
          [maxReg]="tableMaxReg"
          [specialFilter]="true"
          (tableAction)="tableActions($event.action.name, $event.element)"
          [data]="tableData"
        >
        </app-table-controller>

        <div id="unknown-devices-map" class="unknown-devices-map">
          <div
            *ngIf="mapDevice"
            class="unknown-devices-map-device pointer"
            (click)="viewportScroller.scrollToPosition(scrollPosition)"
          >
            <b>{{ "mbus-address" | translate }}:</b> {{ mapDevice.dirMbus
            }}<span>/</span><b>{{ "serial-number" | translate }}:</b>
            {{ mapDevice.nroSerieToShow ? mapDevice.nroSerieToShow : " - " }}
            <i class="fas fa-table"></i>
          </div>
          <app-map-controller
            [mapHeight]="mapHeight"
            [mapType]="mapType"
            [gatewaysData]="[]"
            [metersData]="mapData"
            [hideMenu]="true"
          >
          </app-map-controller>
        </div>
      </div>
    </div>
  </div>
</section>
