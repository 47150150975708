// @angular
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Servicio de detección de timeout
import { BnNgIdleService } from "bn-ng-idle";
// Server
import { ENVIRONMENT } from "../../../environments/environment";
// Servicios propios
import { BrowserStorageLocalService } from "./BrowserStorageServices/BrowserStorageLocalService.service";
import { ToastService } from "./ToastService.service";
import { LoginControllerService } from "../server/LoginController.service";
import { SessionDataService } from "./SessionDataService.service";
// Interfaces
import { SessionData } from "../../interfaces/SessionGlobalInterface.type";
// Variables
import { PROFILES } from "../../../assets/profiles/profiles";

@Injectable({
  providedIn: "root",
})
export class TokenCheckService {
  // Tiempo máximo de sesión en milisegundos
  sessionMaxTimeMS: number = 36000000;
  // Tiempo en pantalla de la alerta
  toastTimer: number = 5000;

  constructor(
    private bnIdle: BnNgIdleService,
    private http: HttpClient,
    private BrowserStorageLocalService: BrowserStorageLocalService,
    private LoginController: LoginControllerService,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  checkAlive(): any {
    return this.http.get(ENVIRONMENT.API_ENDPOINT + "/im-alive/token-valid");
  }

  // Cálculo del tiempo restante de sesión
  sessionTimeRemaining(): void {
    let sessionData: SessionData =
      this.BrowserStorageLocalService.getJsonValue("session");
    if (sessionData.sessionNotFinished) {
      sessionData.sessionNotFinished = false;
      this.BrowserStorageLocalService.setJsonValue("session", sessionData);
      let timeRemaining: any = this.convertMS(
        this.sessionMaxTimeMS - (Date.now() - sessionData.sessionStartDate)
      );

      // Aviso si queda menos de 1 hora de sesión
      if (timeRemaining.hour < 1) {
        let hourText: string =
          timeRemaining.hour > 1
            ? this.translate.instant("hours")
            : timeRemaining.hour > 0
            ? this.translate.instant("hour")
            : "";
        let minuteText: string =
          timeRemaining.minute > 1
            ? this.translate.instant("minutes")
            : this.translate.instant("minute");

        this.ToastService.fireToastWithConfirmation(
          "warning",
          this.translate.instant("session-time-remaining") +
            " " +
            (timeRemaining.hour > 0 ? timeRemaining.hour : "") +
            " " +
            hourText +
            " " +
            timeRemaining.minute +
            " " +
            minuteText,
          this.translate.instant("renew-session"),
          this.translate.instant("close"),
          this.toastTimer
        ).then((userConfirmation: boolean) => {
          if (userConfirmation) {
            setTimeout(() => {
              this.ToastService.firePasswordInput().then((password: string) => {
                this.renewSession(password);
              });
            }, 0);
          }
        });
      }
    }
  }

  // Conversión de milisegundos a formato hora, minutos, segundos
  convertMS(milliseconds: number): any {
    let seconds: number = Math.floor(milliseconds / 1000);
    let minute: number = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hour: number = Math.floor(minute / 60);
    minute = minute % 60;
    return {
      hour: hour,
      minute: minute,
      seconds: seconds,
    };
  }

  // Renovación de sesión
  renewSession(password: string): void {
    this.LoginController.login({
      username: this.SessionDataService.getCurrentUser(),
      password: password,
    }).subscribe((response) => {
      if (response["token"]) {
        let sessionData: any =
          this.BrowserStorageLocalService.getJsonValue("session");
        sessionData.token = response["token"];
        sessionData.sessionStartDate = Date.now();
        this.BrowserStorageLocalService.setJsonValue("session", sessionData);
        this.SessionDataService.sendSessionRenewedFlag();
        this.ToastService.fireToast(
          "success",
          this.translate.instant("renewed-session")
        );
      } else {
        this.ToastService.fireToast(
          "error",
          this.translate.instant("password-incorrect")
        );
      }
    });
  }

  // Timeout de sesión
  tokenTimeout(): void {
    this.bnIdle
      .startWatching(this.sessionMaxTimeMS / 1000)
      .subscribe((sessionExpired) => {
        if (
          sessionExpired &&
          this.SessionDataService.getCurrentProfile() != PROFILES.ARSON
        ) {
          this.SessionDataService.sendLogoutFlag();
          this.ToastService.fireToast(
            "info",
            this.translate.instant("redirect-login")
          );
        }
      });
  }

  // Sesión caducada
  sessionExpired(): void {
    this.ToastService.firePasswordInput(true).then((password: string) => {
      this.renewSession(password);
    });
  }
}
