<!-- Modal de cambio de entidad -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">{{ "change-entity" | translate }}</h5>
  </div>
  <div class="dialog-body">
    <mat-form-field appearance="standard">
      <mat-label>{{ "name" | translate }}</mat-label>
      <input
        matInput
        type="text"
        [(ngModel)]="newProfile.profileName"
        placeholder="{{ 'name' | translate }}..."
        required
      />
    </mat-form-field>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      [disabled]="!newProfile.profileName"
      (click)="sendNewProfile()"
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
