/// <reference types='@runette/leaflet-fullscreen' />
// @angular
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostBinding,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Leaflet
import * as L from "leaflet";
import { tileLayer } from "leaflet";
import "leaflet.polylinemeasure";
import "@runette/ngx-leaflet-fullscreen";
import "leaflet-edgebuffer/src/leaflet.edgebuffer.js";
import "@geoman-io/leaflet-geoman-free";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
// Servicios propios
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
// Variables del mapa
import { MAP_CONFIG } from "../map-variables/MAP_CONFIG";
import { PROFILES } from "../../../../assets/profiles/profiles";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Flag de componente ya inicializado
  componentInitiated: boolean = false;

  // Variables del mapa
  @Input()
  get data(): [] {
    return this._data;
  }
  set data(data: []) {
    this._data = data;
    if (this.componentInitiated) {
      this.resetLayers();
    }
  }
  _data: any;
  @Input()
  get bounds(): [] {
    return this._bounds;
  }
  set bounds(bounds: []) {
    if (
      !this.boundsUpdateDisabled &&
      JSON.stringify(bounds) != JSON.stringify(this._bounds)
    ) {
      this._bounds = bounds;
      // Reposicionamiento del mapa en los contadores
      if (this.map && this._bounds?.length > 0) {
        this.flyToBounds(this._bounds, this.initialZoom, false);
      }
    }
  }
  _bounds: any;
  @Input() mapType: string;
  @Input() mapOnly: boolean;
  @Input() initialZoom: number;
  @Input() fitBounds: number;
  @Output() boundsUpdate = new EventEmitter<number>();
  @Input() searchAdress: boolean;
  @Input() disableLegend: boolean;
  @Input() disableMeasure: boolean;
  @Input() boundsUpdateDisabled: boolean;
  @Input() activeLayers: string[];
  @Input()
  get activateAllLayers(): boolean {
    return this._activateAllLayers;
  }
  set activateAllLayers(activateAllLayers: boolean) {
    if (activateAllLayers != null) {
      this._activateAllLayers = true;
      if (this.componentInitiated) {
        this.resetLayers();
      }
    }
  }
  _activateAllLayers: boolean;
  map: any;
  mapLayersControl: any;
  mapOverlayLayers: any = {};
  mapLayers: any[] = [];
  mapLoading: boolean;
  mapBaseLayers: any = {};
  mapOptions: any = {};
  @Output() resetRequest = new EventEmitter<any>();
  @ViewChild("mapContainer") mapContainer: ElementRef;
  @Output() activeLayersFlag = new EventEmitter<string[]>();
  @Output() editionActiveFlag = new EventEmitter<boolean>();
  @Output() updateDeviceLocationFlag = new EventEmitter<any>();

  // Variables de las opciones del mapa en pantalla completa
  fullscreenOptions: any = {
    position: "topleft",
  };
  hideFullscreen: boolean = false;

  // Variable para dibujar en el mapa
  @Input()
  get freeDrawEnabled(): boolean {
    return this._freeDrawEnabled;
  }
  set freeDrawEnabled(freeDrawEnabled: boolean) {
    this._freeDrawEnabled = freeDrawEnabled;
    if (this.map && this.freeDrawEnabled) {
      this.enableGeoman();
    } else if (this.map && !this.freeDrawEnabled) {
      this.disableGeoman();
    }
  }
  _freeDrawEnabled: boolean;
  @Output() drawedPolygon = new EventEmitter<any>();
  @Output() editedAgrupationPolygon = new EventEmitter<any>();
  editionActive: boolean = false;
  @Input() drawAgrupationOutline: boolean;
  @Input() agrupationEditionActive: boolean;

  // Tamaño del mapa
  @HostBinding("attr.style")
  public get valueAsStyle(): any {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--map-height: ${this.mapHeight}`
    );
  }
  @Input() mapHeight: number;

  // Heatmap
  @Input()
  get updateHeatMapFlag(): boolean {
    return this._updateHeatMapFlag;
  }
  set updateHeatMapFlag(updateHeatMapFlag: boolean) {
    if (this.componentInitiated) {
      this.resetLayers();
    }
  }
  _updateHeatMapFlag: boolean;

  // Panel
  circlePane: any;
  @Input() disableCirclePane: boolean;

  // Buscador
  provider = new OpenStreetMapProvider();
  searchControl = GeoSearchControl({
    provider: this.provider,
    style: "bar",
    showMarker: false,
    autoClose: true,
    searchLabel: this.translate.instant("search"),
  });

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private sanitizer: DomSanitizer,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Corrección de bug de imagen de icono no encontrada redirigiendo a png descargado
    L.Icon.Default.imagePath = "assets/img/";
    // Inicialización de las capas base y las opciones
    this.setBaseLayers();
    this.setMapOptions();
    // Inicialización de las capas de control
    this.mapLayersControl = {
      baseLayers: this.mapBaseLayers,
      overlays: this.mapOverlayLayers,
    };
    // Eliminación de los botones de zoom
    if (this.mapOnly) {
      this.hideFullscreen = true;
      this.mapOptions["zoomControl"] = false;
    } else {
      this.hideFullscreen = false;
      this.mapOptions["zoomControl"] = true;
    }

    this.componentInitiated = true;
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Seteo de las capas base
  setBaseLayers(): void {
    MAP_CONFIG.baseLayers.forEach((baseLayer: any) => {
      this.mapBaseLayers[baseLayer.layerName] = tileLayer(
        baseLayer.layerUrl,
        baseLayer.tileLayerOptions
      );
    });
  }

  // Seteo de las opciones
  setMapOptions(): void {
    let mapOptions = MAP_CONFIG.mapOptions;
    if (this.initialZoom) {
      mapOptions["zoom"] = this.initialZoom;
    }
    mapOptions["layers"] = this.mapBaseLayers[MAP_CONFIG.startLayer];
    this.mapOptions = mapOptions;
  }

  // Carga del mapa cuando esté preparado
  onMapReady(map: any): void {
    this.map = map;
    this.map.doubleClickZoom.disable();

    // Creación de los paneles de leyenda del mapa
    if (!this.disableLegend) {
      this.createMapLegend();
      this.createMapLegendIcon();
    }

    // Creación de los paneles del mapa
    if (this.mapType != "control") {
      this.createCirclePane();
    }

    // Creación de las capas del mapa
    this.createLayers();

    // Inclusión de la escala del mapa a la derecha
    L.control
      .scale({ position: "bottomright", imperial: false })
      .addTo(this.map);

    // Cálculo de distancias
    if (!this.disableMeasure && !this.mapOnly) {
      this.activateMeasures();
    }

    // Reposicionamiento del mapa en los contadores
    if (this._bounds?.length > 0) {
      this.flyToBounds(this._bounds, this.initialZoom, false);
    }

    // Activación de selección
    if (this.map && this.freeDrawEnabled) {
      this.enableGeoman();
    }

    // Buscador
    if (this.searchAdress) {
      this.map.addControl(this.searchControl);
    }
  }

  // Activación del cálculo de distancias
  activateMeasures(): void {
    let options = {
      position: "topleft", // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
      unit: "metres", // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
      clearMeasurementsOnStop: true, // Clear all the measurements when the control is unselected
      showBearings: false, // Whether bearings are displayed within the tooltips
      bearingTextIn: "In", // language dependend label for inbound bearings
      bearingTextOut: "Out", // language dependend label for outbound bearings
      tooltipTextFinish: this.translate.instant("leaflet-measure-finish"),
      tooltipTextDelete: this.translate.instant("leaflet-measure-delete"),
      tooltipTextMove: this.translate.instant("leaflet-measure-move"),
      tooltipTextResume: this.translate.instant("leaflet-measure-resume"),
      tooltipTextAdd: this.translate.instant("leaflet-measure-add"),
      // language dependend labels for point's tooltips
      measureControlTitleOn: this.translate.instant("leaflet-measure-on"), // Title for the control going to be switched on
      measureControlTitleOff: this.translate.instant("leaflet-measure-off"), // Title for the control going to be switched off
      measureControlLabel: "&#8614;", // Label of the Measure control (maybe a unicode symbol)
      measureControlClasses: [], // Classes to apply to the Measure control
      showClearControl: false, // Show a control to clear all the measurements
      clearControlTitle: "Clear Measurements", // Title text to show on the clear measurements control button
      clearControlLabel: "&times", // Label of the Clear control (maybe a unicode symbol)
      clearControlClasses: [], // Classes to apply to clear control button
      showUnitControl: false, // Show a control to change the units of measurements
      distanceShowSameUnit: false, // Keep same unit in tooltips in case of distance less then 1 km/mi/nm
      unitControlTitle: {
        // Title texts to show on the Unit Control button
        text: "Change Units",
        metres: "metres",
        landmiles: "land miles",
        nauticalmiles: "nautical miles",
      },
      unitControlLabel: {
        // Unit symbols to show in the Unit Control button and measurement labels
        metres: "m",
        kilometres: "km",
        feet: "ft",
        landmiles: "mi",
        nauticalmiles: "nm",
      },
      tempLine: {
        // Styling settings for the temporary dashed line
        color: "#00f", // Dashed line color
        weight: 2, // Dashed line weight
      },
      fixedLine: {
        // Styling for the solid line
        color: "#006", // Solid line color
        weight: 2, // Solid line weight
      },
      startCircle: {
        // Style settings for circle marker indicating the starting point of the polyline
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#0f0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      intermedCircle: {
        // Style settings for all circle markers between startCircle and endCircle
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#ff0", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      currentCircle: {
        // Style settings for circle marker indicating the latest point of the polyline during drawing a line
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#f0f", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
      endCircle: {
        // Style settings for circle marker indicating the last point of the polyline
        color: "#000", // Color of the border of the circle
        weight: 1, // Weight of the circle
        fillColor: "#f00", // Fill color of the circle
        fillOpacity: 1, // Fill opacity of the circle
        radius: 3, // Radius of the circle
      },
    };
    L.control.polylineMeasure(options).addTo(this.map);
  }

  // Activación de selección de mapa
  enableGeoman(): void {
    // Idioma
    let language = this.SessionDataService.getCurrentLanguage();
    this.map.pm.setLang(
      language == LANGUAGE.CATALAN ? LANGUAGE.ESPANOL : language
    );
    // Controles
    this.map.pm.addControls({
      position: "bottomright",
      // Botones habilitados
      drawRectangle: this.agrupationEditionActive ? false : true,
      drawPolygon: this.agrupationEditionActive ? false : true,
      drawCircle: this.agrupationEditionActive ? false : true,
      editMode: true,
      removalMode: this.agrupationEditionActive ? false : true,

      // Botones deshabilitados
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      // drawCircle: false,
      dragMode: false,
      rotateMode: false,
      drawText: false,
      cutPolygon: false,
    });

    // Cálculo de distancias
    // this.map.pm.setLang(LANGUAGE.ESPANOL);
    // this.map.pm.setGlobalOptions({
    //   measurements: {
    //     measurement: true,
    //     displayFormat: "metric",
    //     showTooltip: true,
    //     showTooltipOnHover: true,
    //   },
    // });

    // Polígono creado
    this.map.off("pm:create");
    this.map.on("pm:create", (e: any) => {
      this.getMapActiveOverlayLayers();
      let selectionLayers = this.map.pm.getGeomanDrawLayers();
      this.drawedPolygon.emit(selectionLayers);
    });

    // Polígono eliminado
    this.map.off("pm:remove");
    this.map.on("pm:remove", (e: any) => {
      this.getMapActiveOverlayLayers();
      let selectionLayers = this.map.pm.getGeomanDrawLayers();
      this.drawedPolygon.emit(selectionLayers);
      this.map.pm.toggleGlobalRemovalMode();
    });

    // Polígono editado
    this.map.off("pm:globaleditmodetoggled");
    this.map.on("pm:globaleditmodetoggled", (e: any) => {
      this.updateMarkersPointerEvents(e.enabled);
      this.editionActive = e.enabled;
      if (!e.enabled) {
        this.getMapActiveOverlayLayers();
        if (this.agrupationEditionActive) {
          let allLayers = this.map.pm.getGeomanLayers();
          this.editedAgrupationPolygon.emit(allLayers[allLayers.length - 1]);
          this.editionActiveFlag.emit(false);
        } else {
          let selectionLayers = this.map.pm.getGeomanDrawLayers();
          this.drawedPolygon.emit(selectionLayers);
        }
      }
    });

    // Anulación de eventos en capa de marcadores circulares al editar forma
    this.map.off("pm:globaldrawmodetoggled");
    this.map.on("pm:globaldrawmodetoggled", (e: any) => {
      this.updateMarkersPointerEvents(e.enabled);
    });

    // Anulación de eventos en capa de marcadores circulares al borrar forma
    this.map.off("pm:globalremovalmodetoggled");
    this.map.on("pm:globalremovalmodetoggled", (e: any) => {
      this.updateMarkersPointerEvents(e.enabled);
    });
  }

  // Borrado de dibujo
  deleteDrawedPolygon(): void {
    this.map.eachLayer((layer) => {
      if (typeof layer._latlngs !== "undefined" && layer._latlngs.length > 0) {
        layer.remove();
      }
    });
  }

  // Desactivación de la selección en mapa
  disableGeoman() {
    this.map?.pm.removeControls();
    this.map?.off("pm:create");
    this.map?.off("pm:remove");
    this.map?.off("pm:globaleditmodetoggled");
  }

  // Habilitación de edición poligonal
  enableEditPolygon(): void {
    this.map?.pm.enableGlobalEditMode();
    this.editionActive = true;
    this.editionActiveFlag.emit(true);
  }

  // Panel de leyenda izquierdo
  createMapLegend(): void {
    const self = this;
    const LEGEND = new (L.Control.extend({
      options: { position: "bottomleft" },
    }))();

    LEGEND.onAdd = () => {
      let legendHtml = L.DomUtil.create("div", "legend");
      let legendText: string = "<ul>";
      MAP_CONFIG.legend[this.mapType]?.forEach((element: any) => {
        legendText +=
          `<li>
              <i class="` +
          element.icon +
          `">`;
        if (element.iconExtra) {
          legendText += `<i class="` + element.iconExtra + ` icon-extra"></i>`;
        }
        legendText += "</i>" + self.translate.instant(element.text) + "</li>";
      });
      legendText += "</ul>";
      legendHtml.innerHTML = legendText;
      return legendHtml;
    };
    LEGEND.addTo(this.map);
  }

  // Icono del panel de leyenda izquierdo
  createMapLegendIcon(): void {
    const self = this;
    const LEGEND_ICON = new (L.Control.extend({
      options: { position: "bottomleft" },
    }))();

    LEGEND_ICON.onAdd = () => {
      var legendIconHtml = L.DomUtil.create("div", "icon");
      legendIconHtml.innerHTML =
        "<i class='map-legend fas fa-question' title='" +
        self.translate.instant("show-legend") +
        "' ></i>";
      return legendIconHtml;
    };
    LEGEND_ICON.addTo(this.map);
  }

  // Movimiento del mapa al punto seleccionado
  flyTo(coordinates: any, zoom?: number, options?: any): void {
    this.map.flyTo(coordinates, zoom, options);
  }

  // Reposicionamiento del mapa en los contadores
  flyToBounds(bounds: any, zoom: number, animate: boolean): void {
    if (bounds.length > 0) {
      this.map.flyToBounds(bounds, {
        maxZoom: zoom,
        padding: [50, 50],
        animate: animate,
      });
    }
  }

  // Creación de paneles
  createCirclePane(): void {
    this.circlePane = this.map.createPane(
      "circlePane",
      this.map.getPane("overlayPane")
    );
    this.updateMarkersPointerEvents(this.disableCirclePane);
  }

  // Inserción de las capas en el mapa
  createLayers(): void {
    let noActiveLayers = !this.activeLayers;
    let mapOverlayLayers = {};
    if (noActiveLayers) {
      this.activeLayers = [];
    }
    this._data.forEach((mapLayer: any) => {
      // Capas del mapa
      if (
        mapLayer.data &&
        (!mapLayer.overlay ||
          mapLayer.id == "selected" ||
          noActiveLayers ||
          this.activeLayers.includes(mapLayer.id))
      ) {
        this.mapLayers.push(mapLayer.data);
        if (noActiveLayers) {
          this.activeLayers.push(mapLayer.id);
        }
      }
      // Panel selector de capas visibles
      if (mapLayer.overlay) {
        mapOverlayLayers[mapLayer.name] = mapLayer.data;
      }
    });
    // Ordenar desplegable de capas
    this.sortMapOverlay(mapOverlayLayers);
  }

  // Ordenamiento de desplegable de capas
  sortMapOverlay(mapOverlayLayers): void {
    this.mapOverlayLayers = Object.keys(mapOverlayLayers)
      .sort()
      .reduce((obj, key) => {
        obj[key] = mapOverlayLayers[key];
        return obj;
      }, {});
    this.mapLayersControl.overlays = this.mapOverlayLayers;
  }

  // Reseteo de las capas del mapa
  resetLayers(): void {
    if (!this.editionActive) {
      // Comprobación de capas activas
      this._activateAllLayers
        ? (this.activeLayers = null)
        : this.getMapActiveOverlayLayers();

      // Reseteo de variables de capas
      this.mapLayers = [];
      this.mapOverlayLayers = {};
      this.mapLayersControl.overlays = {};

      // Creación de capas
      this.createLayers();
      this.mapLayersControl.overlays = this.mapOverlayLayers;
      this._activateAllLayers = false;
    }
  }

  // Borrado de capas
  eraseLayers(): void {
    this.map.eachLayer((layer) => {
      if (!(layer instanceof L.TileLayer)) {
        layer.remove();
      }
    });
  }

  // Obtención de las capas activas del mapa
  getMapActiveOverlayLayers(): void {
    let activeLayers = [];
    for (let layer in this.mapOverlayLayers) {
      if (this.map.hasLayer(this.mapOverlayLayers[layer])) {
        activeLayers.push(
          this._data.find((mapLayer: any) => mapLayer.name == layer)?.id
        );
      }
    }
    this.activeLayersFlag.emit(activeLayers);
    this.activeLayers = activeLayers;
  }

  // Petición de reset al controlador de mapa
  resetMap(): void {
    this.resetRequest.emit();
  }

  // Limpieza de popups en pantalla
  cleanPopups(): void {
    this.map.closePopup();
  }

  // Capas activas del mapa
  getMapActiveLayers(): string[] {
    return this.activeLayers;
  }

  // Apertura de Street View
  openStreetView(event: any): void {
    if (this.SessionDataService.getCurrentProfile() == PROFILES.ARSON)
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("open-street-view")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          window.open(
            "http://maps.google.com/maps?q=&layer=c&cbll=" +
              event?.latlng.lat +
              "," +
              event?.latlng.lng +
              "&cbp=11,0,0,0,0",
            "_newtab"
          );
        }
      });
  }

  // Actualización de la agrupación seleccionada
  updateSelectedAgrupation(selectedAgrupation: number): void {
    this.mapLayers.forEach((mapLayer: any) => {
      if (mapLayer.options.className == "agrupation" + selectedAgrupation) {
        mapLayer.options.opacity = 1;
        mapLayer.options.fillOpacity = 0.5;
        mapLayer.bringToFront();
        mapLayer.redraw();
      } else if (mapLayer.options.className?.includes("agrupation")) {
        mapLayer.options.opacity = 0.3;
        mapLayer.options.fillOpacity = 0.15;
        mapLayer.pm.disable();
        mapLayer.redraw();
      }
    });
  }

  // Actualización de la posición del dispositivo
  updateDeviceLocation(event: L.LeafletMouseEvent): void {
    this.updateDeviceLocationFlag.emit(event.latlng);
  }

  // Centrado del mapa en agrupación
  focusAgrupation(selectedAgrupation: number): void {
    let agrupationLayer = this.mapLayers.find(
      (layer) => layer.options.className == "agrupation" + selectedAgrupation
    );
    agrupationLayer.pm.enable();
    if (this.mapType != "agrupationEdition") {
      this.map.fitBounds(agrupationLayer?.getBounds());
    }
  }

  // Obtención de la última agrupación editada
  getEditedAgrupation(selectedAgrupation: number): void {
    let editedLayer = this.mapLayers.find(
      (layer) => layer.options.className == "agrupation" + selectedAgrupation
    );
    editedLayer.pm.disable();
    return editedLayer;
  }

  // Actualización de eventos en capa de marcadores
  updateMarkersPointerEvents(disabled?: boolean): void {
    this.circlePane.style.pointerEvents = disabled ? "none" : "all";
  }

  // Flag de movimiento del mapa
  moveFlag(): void {
    this.boundsUpdate.emit(this.map?.getBounds());
  }
}
