import { Injectable } from "@angular/core";
// Servicio de traducción
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ManufacturerService } from "../../../services/shared/ManufacturerService.service";
// Variables de mapa
import { MAP_CONFIG } from "../map-variables/MAP_CONFIG";
import { METROLOGY_TYPE } from "../../../interfaces/DeviceGlobalInterface.type";
import { MAP_LAYERS } from "../map-variables/MAP_TYPES";
import { DEVICE_BY_COMM } from "../../../services/shared/DeviceTypeService.service";
import { LANGUAGE } from "../../../services/language/LanguageController.service";

@Injectable({
  providedIn: "root",
})
export class MapDeviceIconService {
  constructor(
    private ManufacturerService: ManufacturerService,
    private translate: TranslateService
  ) {}

  // Obtención del icono de dispositivo
  getDeviceIcon(
    mapType: string,
    device: any,
    layer: string,
    deviceTypeByMask: string,
    isLwUne: boolean,
    isLwMbus: boolean
  ): any {
    // Icono de presión igual que fugas
    if (mapType == "pressureDetection") {
      mapType = "leakDetection";
    }
    // Icono de geolocalización igual que lista de contadores
    if (mapType == "geolocation") {
      mapType = "meterList";
    }
    switch (mapType) {
      // ANCHOR Cobertura
      case "coverage":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[layer].iconHtml,
            className:
              device.otherGateway == true
                ? "yellowIcon"
                : MAP_CONFIG.markerVariables[layer].iconClasses,
            color:
              device.otherGateway == true
                ? "rgba(255, 235, 59, 1)"
                : MAP_CONFIG.markerVariables[layer].iconColor,
          },
          title: this.translate.instant(
            MAP_CONFIG.markerVariables[layer].markerTitle
          ),
        };
      // ANCHOR Telecontrol
      case "control":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[layer].iconHtml,
            className: MAP_CONFIG.markerVariables[layer].iconClasses,
          },
          title: this.translate.instant(
            MAP_CONFIG.markerVariables[layer].markerTitle
          ),
        };
      // ANCHOR Cambio de localización
      case "changeLocation":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[layer].iconHtml,
            className: MAP_CONFIG.markerVariables[layer].iconClasses,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[layer].markerTitle
            ) +
            " - " +
            device.nroSerie +
            " (" +
            this.translate.instant("new-location") +
            ")",
        };
      // ANCHOR Balances
      case "balanceDetail":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[layer].iconHtml,
            className: MAP_CONFIG.markerVariables[layer].iconClasses,
            color: MAP_CONFIG.markerVariables[layer].iconColor,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[layer].markerTitle
            ) +
            " - " +
            device.meterNroSerie,
        };
      // ANCHOR Edición de balance
      case "balanceEdition":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[layer].iconHtml,
            className: MAP_CONFIG.markerVariables[layer].iconClasses,
            color: MAP_CONFIG.markerVariables[layer].iconColor,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[layer].markerTitle
            ) +
            " - " +
            device.meterNroSerie,
        };
      // ANCHOR Listado de contadores
      case "meterList":
        return {
          icon: {
            html: MAP_CONFIG.markerVariables[
              device.selected ? "SELECTED" : layer
            ].iconHtml,
            className:
              MAP_CONFIG.markerVariables[device.selected ? "SELECTED" : layer]
                .iconClasses,
            color: device.selected
              ? "rgba(255, 235, 59, 1)"
              : MAP_CONFIG.markerVariables[layer].iconColor,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[device.selected ? "SELECTED" : layer]
                .markerTitle
            ) +
            " - " +
            device.nroSerie,
        };
      // ANCHOR Detección de fugas
      case "leakDetection":
        return {
          icon: {
            html: device.selected
              ? MAP_CONFIG.markerVariables["LEAK_SENSOR_SELECTED"].iconHtml
              : device.warning
              ? MAP_CONFIG.markerVariables["SENSOR_WARNING"].iconHtml
              : MAP_CONFIG.markerVariables[layer].iconHtml,
            className:
              MAP_CONFIG.markerVariables[
                device.selected
                  ? "LEAK_SENSOR_SELECTED"
                  : device.warning
                  ? "SENSOR_WARNING"
                  : layer
              ].iconClasses,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[
                device.warning ? "SENSOR_WARNING" : layer
              ].markerTitle
            ) +
            " - " +
            device.nroSerie,
        };
      // ANCHOR Detección de caudal
      case "flowDetection":
        return {
          icon: {
            html:
              `<div class="flow-sensor-pipe" ` +
              `style="background-image: linear-gradient(Gainsboro ` +
              (100 - device.flowPercentage) +
              `%, blue 1%">` +
              `<img src="` +
              MAP_CONFIG.markerVariables["SENSOR_FLOW"].iconUrl +
              `"></div>`,
            className: MAP_CONFIG.markerVariables["SENSOR_FLOW"].iconClasses,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[layer].markerTitle
            ) +
            " - " +
            device.nroSerie,
        };
      // ANCHOR Por defecto
      default:
        let standardMeterType: string =
          layer == MAP_LAYERS.NO_LORA
            ? this.getDeviceType(deviceTypeByMask, device)
            : layer;
        let html: string =
          deviceTypeByMask == DEVICE_BY_COMM.TXN && device.devType
            ? MAP_CONFIG.markerVariables[standardMeterType][
                "iconHtml" +
                  this.ManufacturerService.getManufacturer(
                    device.fabricante,
                    device.devType,
                    LANGUAGE.ESPANOL
                  )?.deviceText
              ]
            : isLwUne
            ? MAP_CONFIG.markerVariables[standardMeterType].iconHtmlUne
            : isLwMbus
            ? MAP_CONFIG.markerVariables[standardMeterType].iconHtmlMbus
            : MAP_CONFIG.markerVariables[standardMeterType].iconHtml;
        // +
        //   `<div class="map-device-substitution"></div>`;
        if (
          device.metrologyType == METROLOGY_TYPE.SENSOR ||
          device.metrologyType == METROLOGY_TYPE.ACOUSTIC_SENSOR
        ) {
          html = html.replace("fa-circle", "fa-play rotated90");
        }
        return {
          icon: {
            html: html,
            className:
              (device.deactivate &&
              deviceTypeByMask != DEVICE_BY_COMM.NO_LORA_NO_COM
                ? " grayIcon deactivated"
                : MAP_CONFIG.markerVariables[
                    device.selected ? "SELECTED" : standardMeterType
                  ].iconClasses) +
              (isLwUne ? " LW-UNE" : "") +
              (isLwMbus ? " LW-MBUS" : ""),
            color:
              device.deactivate &&
              deviceTypeByMask != DEVICE_BY_COMM.NO_LORA_NO_COM
                ? "rgba(158, 158, 158, 1)"
                : MAP_CONFIG.markerVariables[standardMeterType].iconColor,
          },
          title:
            this.translate.instant(
              MAP_CONFIG.markerVariables[standardMeterType].markerTitle
            ) +
            " - " +
            device.nroSerie,
        };
    }
  }

  // ANCHOR Tipo de contador no LoraWAN
  getDeviceType(deviceTypeByMask: string, device: any): string {
    switch (deviceTypeByMask) {
      case DEVICE_BY_COMM.API:
      case DEVICE_BY_COMM.EXTERNO:
        return device.comunica ? "EXTERNO_OK" : "EXTERNO_NO_COMUNICA";
      case DEVICE_BY_COMM.ERM:
        return device.comunica ? "ERM_OK" : "ERM_NO_COMUNICA";
      case DEVICE_BY_COMM.EK280:
        return device.comunica ? "EK_OK" : "EK_NO_COMUNICA";
      case DEVICE_BY_COMM.OWASYS:
        return device.comunica ? "EK_OK" : "EK_NO_COMUNICA";
      case DEVICE_BY_COMM.PLUM:
        return device.comunica ? "PLUM_OK" : "PLUM_NO_COMUNICA";
      default:
        return MAP_LAYERS.NO_LORA;
    }
  }
}
