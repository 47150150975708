// @angular
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { formatNumber } from "@angular/common";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Highcharts
import { Options } from "highcharts";
// Swal
import Swal from "sweetalert2";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { CupsControllerService } from "../../../../services/server/CupsController.service";
import { MeterControllerService } from "../../../../services/server/MeterController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { ManufacturerService } from "../../../../services/shared/ManufacturerService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { UserControllerService } from "../../../../services/server/UserController.service";
import { NotificationModalService } from "../../../../modules/notification-module/notification-service/notification-modal.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { TemplateService } from "../../../../services/shared/TemplateService.service";
import { DeviceRouteSelectorService } from "../../../../services/shared/DeviceRouteSelectorService.service";
import { NavigationHelperService } from "../../../../services/shared/NavigationHelperService.service";
import { RedirectToService } from "../../../../services/shared/RedirectToService.service";
// Componentes
import { CupsDetailDialogComponent } from "./cups-detail-dialog/cups-detail-dialog.component";
import { GraphControllerComponent } from "../../../../modules/graph-module/graph-controller/graph-controller.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  ClaveMaestra,
  EntityDefinition,
} from "../../../../interfaces/CupsGlobalInterface.type";
import { PanelMenuOption } from "../../../../modules/material-module/MaterialInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableQuickFilter,
} from "../../../../modules/table-module/TableInterface.type";
import {
  CupsAlarm,
  CupsCards,
  CupsCardsData,
  CupsDevice,
  CupsGateway,
  CupsGraphData,
  CupsHistorical,
  CupsSensor,
  CupsSubscriber,
} from "../CupsInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { MaterialSelectConfig } from "../../../../modules/material-module/MaterialInterface.type";
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { METER_ORDER, VALVE_STATES } from "../../devices/DeviceInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { GRAPH_TYPES } from "../../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
import { CupsDetailOrderDialogComponent } from "./cups-detail-order-dialog/cups-detail-order-dialog.component";

@Component({
  selector: "app-cupsdetail",
  templateUrl: "./cups-detail.component.html",
  styleUrls: ["./cups-detail.component.scss"],
})
export class CupsDetailComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  elseBlock: TemplateRef<any> | null = this.TemplateService.get("elseBlock");

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  currentEntityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  currentEntityCupsName: string;
  sessionProfile: string;
  sessionLanguage: string;
  readonly PROFILES = PROFILES;

  dialog: Subscription;

  // CUPS
  deletionDisabled: boolean;
  cupsId: number;
  meters: CupsDevice[];
  metersAvailable: number;
  isGas: boolean;
  noValve: boolean;
  readonly METROLOGY_TYPE = METROLOGY_TYPE;

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  // Abonado
  subscriberData: CupsSubscriber;
  subscriberList: CupsSubscriber[];
  selectedSubscriber: CupsSubscriber;

  // Notificaciones
  pendingNotifications: number = 0;

  // Cards
  cardsData: CupsCards;
  activeAlarms: CupsAlarm[];
  sensorList: CupsSensor[];
  valveStates: object = VALVE_STATES;

  // Mapa
  mapType: string = "cupsDetail";
  mapGateways: CupsGateway[];
  mapMeter: (CupsDevice | CupsSensor)[];
  mapHeight: number = 400;
  initialZoom: number = 17;

  // Gráfica
  graphSeries: any[];
  graphData: CupsGraphData[];
  graphAlarms: any;
  graphType: number;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [],
      selected: 2,
    },
  ];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("6");
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;

  // Tabla de sectores
  sectorTableData: object[];

  // Tabla de histórico
  historicTableRowNumbers: boolean = true;
  historicTableMaxReg: number = 10;
  historicTableData: CupsHistorical[];
  historicOrderBy: object = { attribute: "installation", reverse: true };
  historicTableExportFileName: string =
    this.translate.instant("historical-export") +
    " " +
    this.DateParserService.getDate();
  meterMetrologyType: object = {
    0: this.translate.instant("meter"),
    1: this.translate.instant("sensor"),
    2: this.translate.instant("gas-meter"),
  };
  historicQuickFilters: TableQuickFilter[][] = [
    [
      {
        name: "meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 0 },
        active: false,
      },
      {
        name: "gas-meters",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 2 },
        active: false,
      },
      {
        name: "sensors",
        columnSearch: "metrologyType",
        condition: { type: "number", rule: 1 },
        active: false,
      },
    ],
  ];
  historicTableColumns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "type",
      data: "metrologyTypeParsed",
      search: "metrologyType",
      sort: "metrologyType",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "assign-date",
      data: "installationParsed",
      search: "installationParsed",
      sort: "installation",
      date: true,
      visible: true,
    },
    {
      title: "unassign-date",
      data: "uninstallationParsed",
      search: "uninstallationParsed",
      sort: "uninstallation",
      date: true,
      visible: true,
    },
  ];

  // Tabla de CUPS asociados al contador
  cupsData: { columns: EntityDefinition; data: ClaveMaestra };

  // Modal
  sensorTableData: CupsSensor[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private CupsController: CupsControllerService,
    private DateParserService: DateParserService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private GraphOptionsService: GraphOptionsService,
    private ManufacturerService: ManufacturerService,
    private MaterialDialogService: MaterialDialogService,
    private MeterController: MeterControllerService,
    private NavigationHelperService: NavigationHelperService,
    public notificationModal: NotificationModalService,
    private RedirectToService: RedirectToService,
    private ReloadComponentService: ReloadComponentService,
    private route: ActivatedRoute,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private TemplateService: TemplateService,
    private ToastService: ToastService,
    private translate: TranslateService,
    private UserController: UserControllerService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionLanguage = this.SessionDataService.getCurrentLanguage();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntityCupsConf =
      this.SessionDataService.getCurrentEntityCupsConf();
    this.currentEntityCupsName = this.currentEntityCupsConf?.find(
      (column: EntityDefinition) => column.colPosition == 0
    )?.name;

    // Escucha de cambios en los valores de agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Configuración de CUPS
    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConfs) => {
          this.currentEntityCupsConf = entityCupsConfs;
          this.currentEntityCupsName = this.currentEntityCupsConf?.find(
            (column: EntityDefinition) => column.colPosition == 0
          )?.name;
        }
      );

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: any) => {
        if (dialogAction.action == "reload") {
          this.getData();
        } else if (dialogAction.action == "delete") {
          this.unassignDevice(parseInt(dialogAction.data));
        }
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.cupsId = this.route.snapshot.paramMap.get("id")
      ? parseInt(this.route.snapshot.paramMap.get("id"))
      : null;
    this.getData();
  }

  // Obtención de los datos del CUPS
  getData(): void {
    this.CupsController.main(
      this.cupsId,
      this.DateParserService.getMomentDate(
        this.defaultDateRange.startDate,
        "x"
      ),
      this.DateParserService.getMomentDate(this.defaultDateRange.endDate, "x")
    ).subscribe((response) => {
      if (response["code"] == 0) {
        this.meters = response["body"]["meters"]
          ? response["body"]["meters"]?.map((meter) => meter.contadorMain)
          : [];
        this.metersAvailable = this.meters?.filter(
          (meter) => meter.metrologyType != METROLOGY_TYPE.WATER_VALVE
        )?.length;
        this.meters.forEach((meter) => {
          // Comprobación de tipo de contador Lora
          meter.type = meter.tipo?.toString(2);
          meter.isLora = meter.type?.charAt(meter.type?.length - 1) == "1";
          // Última lectura
          meter.lastReadedTimestampParsed = this.DateParserService.parseDate(
            meter.lastReadedTimestamp,
            this.SessionDataService.getCurrentDateFormat() + " HH:mm:ss"
          );
          meter.lastReadedValueParsed =
            formatNumber(
              meter.lastReadedValue,
              this.SessionDataService.getCurrentNumberFormat()
            ) + " m³";
        });
        this.isGas = this.SessionDataService.getCurrentClient().gas;
        this.noValve = !this.meters.some((meter) => meter?.valveState != null);

        // Gráfica
        this.graphType = this.isGas ? 3 : 2;
        this.graphFilters[0].selected = this.isGas ? 3 : 2;
        this.setGraphFilters();

        // Tarjetas
        let cardsData: CupsCardsData = new CupsCardsData();
        let activeAlarms: CupsAlarm[] = response["body"]["activeAlarms"];
        cardsData.consumptionCards = response["body"]["consumptionCards"];
        activeAlarms.forEach((alarm: CupsAlarm) => {
          alarm.initDateParsed = this.DateParserService.parseDateWithoutFormat(
            alarm.initDate
          );
          alarm.code != null
            ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
            : "";
        });
        this.activeAlarms = activeAlarms;
        this.sensorList = response["body"]["sensors"]
          ? response["body"]["sensors"]
          : [];
        this.getCupsCards(cardsData);

        // Tabla de CUPS
        this.cupsData = {
          columns: response["body"]["claveMaestraDefinitions"],
          data: response["body"]["claveMaestra"],
        };
        if (this.cupsData.data.abonado) {
          this.getSubscriberData();
        }

        // Mapa de contador
        this.mapGateways =
          response["body"]["meters"]?.length > 0
            ? response["body"]["meters"]
                ?.filter((meter) => meter.contadorMain.id)
                ?.map((meter) => meter.gatewayMeterMark)
                ?.reduce((a, b) => a.concat(b))
            : [];
        this.mapMeter =
          this.meters && this.sensorList
            ? [
                ...this.meters.filter((meter) => meter.id),
                ...this.sensorList.filter((sensor) => sensor.id),
              ]
            : this.meters
            ? [...this.meters.filter((meter) => meter.id)]
            : this.sensorList
            ? [...this.sensorList.filter((sensor) => sensor.id)]
            : [];
        // Notificaciones
        this.pendingNotifications =
          response["body"]["notificacionesPendientes"];

        this.deletionDisabled =
        this.meters.length > 0 ||
        this.sensorList.length > 0
        // Menú del panel
        this.setPanelMenuOptions();
        // Tabla de sectores
        this.sectorTableData = response["body"]["sectorList"];
        // Tabla de histórico
        this.getTableHistoricData(response["body"]["historical"]);
        // Tabla de sensores
        this.getTableSensorData(response["body"]["sensors"]);
        // Gráfica
        this.loadGraph();
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "user-log",
        icon: "fas fa-list-alt fa-fw",
        text: this.translate.instant("user-log"),
        visible: true,
      },
      {
        action: "alarms",
        icon: "fas fa-exclamation-triangle",
        text: this.translate.instant("alarms"),
        visible: true,
      },
      {
        action: "alerts",
        icon: "fas fa-circle-exclamation",
        text: this.translate.instant("alerts"),
        visible: true,
      },
      {
        action: "new-subscriber",
        icon: "fas fa-user-plus",
        text: this.translate.instant("subscriber-new"),
        visible:
          this.cupsData.data.abonado &&
          (this.sessionProfile == PROFILES.ARSON ||
            this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            this.sessionProfile == PROFILES.ADMIN_CLIENTE)
            ? false
            : true,
      },
      {
        action: "edit-subscriber",
        icon: "fas fa-user-pen",
        text: this.translate.instant("subscriber-edit"),
        visible:
          this.cupsData.data.abonado &&
          (this.sessionProfile == PROFILES.ARSON ||
            this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            this.sessionProfile == PROFILES.ADMIN_CLIENTE)
            ? true
            : false,
      },
      {
        action: "delete",
        text: this.translate.instant( "delete"),
        icon: "fas fa-trash",
        disabled: this.deletionDisabled,
        visible: true,
        bottom: true,
        highlight: true,
      },
      {
        action: "select-subscriber",
        icon: "fas fa-address-book",
        text: this.cupsData.data.abonado
          ? this.translate.instant("subscriber-replace")
          : this.translate.instant("subscriber-select"),
        disabled: true,
        visible:
          this.cupsData.data.abonado &&
          (this.sessionProfile == PROFILES.ARSON ||
            this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
            this.sessionProfile == PROFILES.ADMIN_CLIENTE)
            ? false
            : true,
      },
      {
        action:"meter-orders",
        icon: 'fas fa-tools',
        text: this.translate.instant("meter-orders"),
        visible: true,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "user-log":
        this.router.navigate(["/cups/log/usuarios/" + this.cupsId], {
          state: { data: this.cupsData?.data?.clave },
        });
        break;
      case "alarms":
        this.router.navigate(["/cups/alarmas/" + this.cupsId], {
          state: { data: this.cupsData?.data?.clave },
        });
        break;
      case "alerts":
        this.router.navigate(["/cups/alertas/" + this.cupsId], {
          state: { data: this.cupsData?.data?.clave },
        });
        break;
      case "new-subscriber":
        this.router.navigate(["/usuarios/formulario/nuevo"], {
          state: {
            data: { profile: PROFILES.ABONADO, cups: this.cupsData?.data },
          },
        });
        break;
      case "edit-subscriber":
        this.router.navigate([
          "/usuarios/formulario/editar/" + this.cupsData.data.abonado,
        ]);
        break;
        case "delete":
          this.deleteCups();
          break;
      case "select-subscriber":
        this.MaterialDialogService.openDialog(CupsDetailDialogComponent, {
          action: "subscriber",
          cupsId: this.cupsId,
          bindValue: "id",
          bindLabel: "label",
          title: this.translate.instant("subscriber-selection"),
        });
        break;
      case "meter-orders":
       this.router.navigate(["/cups/detalle/ordenes/"+this.cupsId],{
        state: {
          data:{
            cupsId:this.cupsId,
            cupsName: this.cupsData?.data?.clave
          }
        },
      });
      // Instalación de contador en cups
      break;
      case METER_ORDER[5]:
        this.MaterialDialogService.openDialog(CupsDetailOrderDialogComponent, {
          cupsIdList: [this.cupsId],
        }
        );
        break;
      default:
        break;
    }
  }
  deleteCups(){
      this.ToastService.fireAlertWithOptions(
        "question",
        this.translate.instant("action-question")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          this.CupsController.deleteEntityCups([this.cupsId]).subscribe((response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("deleted-success")
              );
              this.SessionDataService.sendUpdateSearchBoxFlag();
              this.SessionDataService.sendReloadPanelFlag();
            } else {
              let errors = response["body"]?.filter(
                (error) => error.responseCode != 0
              );
              if (errors.length > 1) {
                this.ToastService.fireToast(
                  "error",
                  this.translate.instant("some-element-error")
                );
              } else {
                this.ToastService.fireToast(
                  "error",
                  this.translate.instant("httpError" + errors[0].responseCode)
                );
              }
            }
            this.getData();
          });
        }
      });
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de contador
  /***************************************************************************/

  // Obtención de los datos de abonado
  getSubscriberData(): void {
    this.UserController.getUser(this.cupsData.data.abonado).subscribe(
      (response) => {
        if (response["code"] == 0) {
          this.subscriberData = response["body"]["user"];
        }
      }
    );
  }

  // Redirección a url
  openLink(meter: CupsDevice, newTab: boolean) {
    // Comprobación de link para actualizar la navegación
    if (meter.agrupation != this.currentAgrupation.id) {
      let navigationUpdate =
        this.NavigationHelperService.findAgrupationInCurrentEntity(
          "id",
          meter.agrupation
        );
      this.SessionDataService.sendAgrupation(navigationUpdate);
    }
    let url = this.DeviceRouteSelectorService.getDeviceRouteUrl(
      meter.metrologyType,
      meter.id
    );
    newTab
      ? this.RedirectToService.openNewTab(url)
      : this.router.navigate([url]);
  }

  /***************************************************************************/
  // ANCHOR Tarjetas de contador
  /***************************************************************************/

  // Creación de las tarjetas de contador
  getCupsCards(cardsData: CupsCardsData): void {
    this.cardsData = {
      meterDetailLastDataMonth: {
        data: cardsData?.consumptionCards?.lastMonthlyValue,
        type: "consumption",
      },
      meterDetailLastMonthConsume: {
        data: cardsData?.consumptionCards?.lastMonth,
        type: "consumption",
      },
      meterDetailConsumeMonthAverage: {
        data: cardsData?.consumptionCards?.monthlyAverage,
        type: "consumption",
      },
      meterDetailCurrentMonthConsume: {
        data: cardsData?.consumptionCards?.currentMonth,
        type: "consumption",
      },
      meterDetailConsumeAverageDaily: {
        data: cardsData?.consumptionCards?.dailyAverage,
        type: "consumption",
      },
    };
  }

  /***************************************************************************/
  // ANCHOR Gráfica
  /***************************************************************************/

  // Creación de la gráfica
  loadGraph(): void {
    this.setHighchartsOptions();
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    this.CupsController.getGraph(
      this.cupsId,
      this.graphType?.toString(),
      from,
      to
    ).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let graphData: CupsGraphData[] =
          response["body"]["meterReadingsAlarms"];
        this.graphData = graphData;
      } else {
        this.graphData = [
          {
            alarms: [],
            readings: [],
          },
        ];
      }
      this.getSeries();
    });
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    const self = this;
    this.graphSeries = [];
    this.graphData.forEach((meterData, i) => {
      let alarmsSeries: object[] = [];
      // Alarmas
      meterData.alarms.forEach((alarm: CupsAlarm) => {
        alarm.code != null
          ? (alarm.name = this.translate.instant("AlertMeter" + alarm.code))
          : "";
        alarm.code != null
          ? (alarm.description = this.translate.instant(
              "AlertDescription" + alarm.code
            ))
          : "";
        if (
          alarm.description == "" ||
          alarm.description == "AlertDescription" + alarm.code
        ) {
          alarm.description = this.translate.instant("no-details");
        }
        alarmsSeries.push({
          events: {
            click: function () {
              Swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                icon: "info",
                title: alarm.name,
                text: alarm.description,
                showCancelButton: true,
                cancelButtonText: self.translate.instant("close"),
                customClass: {
                  container: "swal2-container-scale-origin",
                },
              });
            },
            mouseOver: function () {
              if (alarm.initDate && alarm.finalDate) {
                self.showAlarm(alarm.initDate, alarm.finalDate);
              }
            },
            mouseOut: function () {
              if (alarm.initDate && alarm.finalDate) {
                self.hideAlarm();
              }
            },
          },
          x: alarm.initDate,
          y: 0,
          title: "!",
          text: alarm.finalDate
            ? "<b>" +
              alarm.name +
              "</b>." +
              this.translate.instant("fixed") +
              ": (" +
              this.DateParserService.getMomentDate(alarm.finalDate, "L") +
              ")"
            : "<b>" + alarm.name + ".</b> ",
          fillColor: alarm.finalDate ? "#388E3C" : "#f44336",
        });
      });

      this.graphSeries.push(
        // Lecturas
        {
          id: "readings" + i,
          name:
            (this.graphType == GRAPH_TYPES.INDEX
              ? this.translate.instant("value")
              : this.graphType == GRAPH_TYPES.CONSUMPTION
              ? this.translate.instant("consumption")
              : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
              ? this.translate.instant("consumption-normalized")
              : this.translate.instant("energy")) +
            " " +
            meterData.nroSerie,
          type: this.graphType == GRAPH_TYPES.INDEX ? "line" : "area",
          data: meterData.readings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.INDEX ||
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
            valueDecimals: 3,
          },
          color: this.graphData.length == 1 ? "#42a5f5" : null,
          navigatorOptions: {
            type: this.graphType == GRAPH_TYPES.INDEX ? "line" : "area",
          },
        },
        // Alarmas
        {
          id: "alarms" + i,
          name: this.translate.instant("alarms") + " " + meterData.nroSerie,
          type: "flags",
          onSeries: "dataseries",
          shape: "flag",
          color: "black",
          width: 16,
          style: {
            color: "white",
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
              cursor: "pointer",
            },
          },
          states: {
            hover: {
              fillColor: "#1976d2",
            },
          },
          data: alarmsSeries,
        }
      );
    });

    this.setChartsOptions();
  }

  // Visualización de periodo de alarma activa
  showAlarm(start: number, end: number): void {
    this.graphController.addTimestampsLine(
      start,
      end,
      "alarmDuration",
      this.translate.instant("alarm-duration")
    );
  }

  // Visualización de periodo de alarma activa
  hideAlarm(): void {
    this.graphController.removeTimestampsLine("alarmDuration");
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions(
        this.translate.instant("meters-export")
      );
    highchartsOptions.scrollbar = {
      barBackgroundColor: "rgba(0,0,0,0.25)",
      buttonBackgroundColor: "rgba(255,255,255,1)",
      buttonBorderRadius: 5,
      trackBorderRadius: 5,
    };
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] = this.graphData.length > 1;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == GRAPH_TYPES.INDEX ||
      this.graphType == GRAPH_TYPES.CONSUMPTION
        ? " m³"
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? " Nm³"
        : " kWh");
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == GRAPH_TYPES.INDEX
        ? this.translate.instant("value")
        : this.graphType == GRAPH_TYPES.CONSUMPTION
        ? this.translate.instant("consumption")
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Seteo de los filtros dependiendo del tipo de balance
  setGraphFilters(): void {
    if (this.isGas) {
      this.graphFilters[0].options = [
        { value: GRAPH_TYPES.INDEX, name: this.translate.instant("index") },
        {
          value: GRAPH_TYPES.CONSUMPTION,
          name: this.translate.instant("hour-consumption"),
        },
        {
          value: GRAPH_TYPES.NORMALIZED_CONSUMPTION,
          name: this.translate.instant("consumption-normalized"),
        },
        { value: GRAPH_TYPES.ENERGY, name: this.translate.instant("energy") },
      ];
    } else {
      this.graphFilters[0].options = [
        { value: GRAPH_TYPES.INDEX, name: this.translate.instant("index") },
        {
          value: GRAPH_TYPES.CONSUMPTION,
          name: this.translate.instant("hour-consumption"),
        },
      ];
    }
  }

  /***************************************************************************/
  // ANCHOR Tabla de histórico
  /***************************************************************************/

  // Obtención de los datos para la tabla de histórico
  getTableHistoricData(historicTableData: CupsHistorical[]): void {
    historicTableData.forEach((device: CupsHistorical) => {
      device.metrologyTypeParsed = device.metrologyTypeParsed =
        this.meterMetrologyType[device.metrologyType];
    });
    this.historicTableData = historicTableData;
  }

  /***************************************************************************/
  // ANCHOR Modales
  /***************************************************************************/

  // Apertura de modal
  showModal(metrologyType: string, action: string, meterId?: number): void {
    switch (action) {
      case "deviceAssign":
        this.MaterialDialogService.openDialog(CupsDetailDialogComponent, {
          action: "assign",
          cupsId: this.cupsId,
          bindValue: "id",
          bindLabel: "nroSerie",
          title: this.translate.instant("assign"),
          metrologyType: metrologyType,
        });
        break;
      case "deviceReplace":
        this.MaterialDialogService.openDialog(CupsDetailDialogComponent, {
          action: "replace",
          cupsId: this.cupsId,
          deviceId: meterId,
          bindValue: "id",
          bindLabel: "nroSerie",
          title: this.translate.instant("select-device-replace"),
          metrologyType: metrologyType,
        });
        break;
      default:
        break;
    }
  }

  // Obtención de los datos de la tabla de sensores
  getTableSensorData(sensorList: CupsSensor[]): void {
    sensorList.forEach((sensor: CupsSensor) => {
      let manufacturer: { manufacturerText: string; deviceText: string } =
        this.ManufacturerService.getManufacturer(
          sensor?.fabricante?.toString(),
          sensor?.devType?.toString(),
          this.sessionLanguage
        );
      sensor.manufacturerText = manufacturer.manufacturerText;
      sensor.modelText = manufacturer.deviceText;
    });
    this.sensorTableData = sensorList;
  }

  // Eliminación de dispositivo del CUPS
  unassignDevice(deviceId: number): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("remove-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.CupsController.meterRemove(this.cupsId, deviceId).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("meter-unassigned")
              );
              if (
                (this.meters.length == 1 ||
                  this.meters.filter((meter) => meter.id).length <= 1) &&
                this.sessionProfile != PROFILES.ARSON &&
                this.sessionProfile != PROFILES.ADMIN_CLIENTE &&
                this.sessionProfile != PROFILES.USUARIO_CLIENTE &&
                this.sessionProfile != PROFILES.ADMIN_ENTIDAD &&
                this.sessionProfile != PROFILES.USUARIO_ENTIDAD
              ) {
                this.router.navigate(["/principal"]);
                this.SessionDataService.sendUpdateSearchBoxFlag();
              } else {
                this.ReloadComponentService.reload();
              }
            }
          }
        );
      }
    });
  }

  /***************************************************************************/
  // ANCHOR Válvula
  /***************************************************************************/

  // Apertura de válvula
  openValve(meter: CupsDevice): void {
    let toastTitle: string =
      meter.valveState == 1 || meter.valveState == 2
        ? this.translate.instant("open-valve-already-question")
        : this.translate.instant("open-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MeterController.openValve(meter.id).subscribe((response) => {
            if (response["code"] == 0) {
              meter.valveState = response["body"];
              this.ToastService.fireToast(
                "success",
                this.translate.instant("open-valve-sucessfull")
              );
            }
          });
        }
      }
    );
  }

  // Lectura de válvula
  readValve(meter: CupsDevice): void {
    this.ToastService.fireAlertWithOptions(
      "info",
      this.translate.instant("read-valve-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.MeterController.readValve(meter.id).subscribe((response) => {
          if (response["code"] == 0) {
            meter.valveState = response["body"];
            this.ToastService.fireToast(
              "success",
              this.translate.instant("read-valve-successful")
            );
          }
        });
      }
    });
  }

  // Cierre de válvula
  closeValve(meter: CupsDevice): void {
    let toastTitle: string =
      meter.valveState == 0
        ? this.translate.instant("close-valve-already-question")
        : this.translate.instant("close-valve-question");
    this.ToastService.fireAlertWithOptions("warning", toastTitle).then(
      (userConfirmation: boolean) => {
        if (userConfirmation) {
          this.MeterController.closeValve(meter.id).subscribe((response) => {
            if (response["code"] == 0) {
              meter.valveState = response["body"];
              this.ToastService.fireToast(
                "success",
                this.translate.instant("close-valve-sended")
              );
            }
          });
        }
      }
    );
  }
}
