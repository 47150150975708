// ANCHOR Datos de alarma
export interface AlarmData {
  // BackEnd
  id: number;
  contador: number;
  claveMaestra: number;
  nroSerie: string;
  claveMaestraKey: string;
  code: number;
  initDate: number;
  finalDate: number;
  state: number;
  comments: string;
  disabled: boolean;
  metrology_type: number;
  latitude: number;
  longitude: number;
  agrupationId?: number;
  entityId?: number;
  timezone?: string;
  fabricante?: number;
  devType?: number;
  groupCode?: number;
  alarmas?: {
    id: number;
    code: number;
    initDate: number;
    finalDate: number;
    state: number;
    comments: string;
    disabled: boolean;
    groupCode: number;
  }[];
  cols?: object[];

  // FrontEnd
  metrologyType?: number;
  initDateParsed?: string;
  initLocalDateParsed?: string;
  finalDateParsed?: string;
  finalLocalDateParsed?: string;
  description?: string;
  name?: string;
  enabled?: boolean;
  stateParsed?: string;
  modalInfo?: boolean;
  modalInfoData?: {
    title?: string;
    html?: string;
  };
  newAlarm?: boolean;
  loadGraph?: boolean;
  graphData?: any;
  lastConsumptionMin?: number;
  lastConsumptionMax?: number;
  lastConsumptionTotal?: number;
  count?: number;
  claveParsed?: string;
  loadBatteryGraph?: boolean;
  client?: string;
  groupCodeParsed?: string;
}

export const ALARM_GROUP_CODE = {
  LIST_TYPE_LEAK: 0,
  LIST_TYPE_EXTREME_LEAK: 1,
  LIST_TYPE_TAMPER: 2,
  LIST_TYPE_BACKFLOW: 3,
  LIST_TYPE_BLOQUED_METER: 4,
  LIST_TYPE_WIRECUT: 5,
  LIST_TYPE_LOW_BATTERY: 6,
  LIST_SOSPECHAS: 7,
  LIST_TYPE_REED_FAULT: 8,
  0: "AlertMeter1103",
  1: "AlertMeter2270",
  2: "AlertMeter1131",
  3: "AlertMeter2311",
  4: "AlertMeter251",
  5: "AlertMeter337",
  6: "AlertMeter388",
  7: "suspicion",
  8: "reed-fault",
};

// ANCHOR Datos de actualización de alarma
export interface AlarmUpdateData {
  alarmId: number;
  alarmState: number;
  alarmComment: string;
}

// ANCHOR Alarmas de dispositivo
export interface DeviceAlarm {
  // BackEnd
  fabricante: number;
  devType: number;
  alarmList: number[];

  // FrontEnd
  manufacturer?: string;
  configured?: boolean;
  checks?: DeviceAlarmChecks[];
  allAlarmsCheck?: boolean;
  allNotificationsCheck?: boolean;
  allValvesCheck?: boolean;
  alarmsDisabled?: number;
}

// ANCHOR Estdo de la alarma del dispositivo
export interface DeviceAlarmChecks {
  id: number;
  check: boolean;
  notification?: boolean;
  open?: boolean;
}

// ANCHOR Estado de alarma/notificación
export interface AlarmStateData {
  alarm_code?: number;
  alarmCode?: number;
  dev_type: number;
  fabricante: number;
  isEnabledAlarm?: boolean;
  isCloseValveAlarm?: boolean;
}

// ANCHOR Dispositivos configurados
export interface AlarmConfiguredDevice {
  id: number;
  nroSerie: string;
  fabricante: number;
  devType: number;
}

// ANCHOR Actualización de alarmas
export interface AlarmsUpdate {
  agrupation?: number;
  entity?: number;
  disabledList: AlarmStateData[];
  disabledNotificationList: AlarmStateData[];
}

// ANCHOR Actualización de alertas
export interface AlertsUpdate {
  entity?: number;
  claveMaestra?: number;
  enabledAlarms: AlarmStateData[];
  closeValveAlarms: AlarmStateData[];
}

// ANCHOR Actualización de alarma
export interface DeviceAlarmUpdate {
  meterId: number;
  disabledList: number[];
  disabledNotificationList: number[];
}

export interface GasMeterWithoutMetrological {
  // BACKEND
  // Esta parte de la interfaz la utilizamos primero para obtener el listado de ids que vamos a pedir
  agrupationId?: number;
  entity?: number;
  count?: number;

  // Esta parte se completa después, aunque van a quedar inutilizables las dos primeras variables.
  agrupation?: number;
  claveMaestraId?: number;
  claveMaestra?: string;
  claveMaestraPcsId?: number;
  claveMaestraPcsValue?: string;
  claveMaestraConversionFactor?: number;
  metrologyType?: number;

  // Se añaden nuevas propiedades
  contadorId?: number; // ID del contador
  nroSerie?: string; // Número de serie del contador
  contadorPcsId?: number; // ID de PCS del contador
  contadorPcsValue?: string; // Valor PCS del contador
  contadorConversionFactor?: number; // Factor de conversión del contador

  //frontEnd
  client?: number;
  claveMaestraUrl?: string;
  deviceUrl?: string;
  entityString?: string;
  agrupationString?: string;
  clientString?: string;
}

// ANCHOR Estado de la alarma
export const ALARM_STATES = {
  0: "pending",
  1: "checked",
  2: "notified",
  3: "fixed",
  4: "critical",
  PENDING: 0,
  CHECKED: 1,
  NOTIFIED: 2,
  FIXED: 3,
  CRITICAL: 4,
};

// ANCHOR Tipos de alarma personalizada
export const ALARM_CUSTOM_TYPES = {
  0: "METER_STOPPED_OVER",
  1: "DAILY_CONSUMPTION_OVER",
  2: "DAILY_CONSUMPTION_BELOW",
  3: "MONTHLY_CONSUMPTION_OVER",
  4: "CONSUMPTION_START_OVER",
  5: "EMPTY_HOUSE",
  6: "LOW_MOBILITY_PERSON",
  7: "LOW_MOBILITY_HOUSE",
  METER_STOPPED: 0,
  DAILY_CONSUMPTION_OVER: 1,
  DAILY_CONSUMPTION_BELOW: 2,
  MONTHLY_CONSUMPTION_OVER: 3,
  CONSUMPTION_START_OVER: 4,
  EMPTY_HOUSE: 5,
  LOW_MOBILITY_PERSON: 6,
  LOW_MOBILITY_HOUSE: 7,
};

// ANCHOR Alarma personalizada
export class CustomAlarm {
  id: number;
  type: number;
  associationId?: number;
  meterId?: number;
  value: number;
  active: boolean;
  notification: boolean;
  subscriber: boolean;
  validation: boolean;

  constructor(
    id: number,
    type: number,
    associationId: number,
    meterId?: number
  ) {
    this.id = id;
    this.type = type;
    this.associationId = associationId;
    this.meterId = meterId;
    this.value = null;
    this.active = false;
    this.notification = false;
    this.subscriber = false;
    this.validation = false;
  }
}
