// @angular
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { ManagementControllerService } from "../../../../services/server/ManagementController.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
import { GatewayVersionParserService } from "../../gateways/GatewaVersionParserService.service";
// Componentes
import { ManagementEntityGatewaysDialogComponent } from "./management-entity-gateways-dialog/management-entity-gateways-dialog.component";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  ExtraTableData,
  TableQuickFilter,
} from "../../../../modules/table-module/TableInterface.type";
import {
  ManagementGateway,
  Maintenance,
  MaintenanceParent,
  MaintenanceModalData,
  MAINTENANCE_GROUPS,
} from "../ManagementInterface.type";

@Component({
  selector: "app-management-entity-gateways",
  templateUrl: "./management-entity-gateways.component.html",
  styleUrls: ["./management-entity-gateways.component.scss"],
})
export class ManagementEntityGatewaysComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  dialog: Subscription;

  // Tabla de estado de los gateways
   quickFiltersExclusion: boolean[] = [true];
      quickFilters: TableQuickFilter[][] = [[
        {
          name: "bbdd-final-points-error",
          columnSearch: "bbddFinalPointsError",
          condition: { type: "boolean", rule: true },
          active: false,
        }
      ]
      ];
  rowNumbersState: boolean = true;
  tableMaxRegState: number = 10;
  gatewayStateData: ManagementGateway[];
  columnsState: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "version-firmware",
      data: "fwVersion",
      search: "fwVersion",
      sort: "fwVersion",
      visible: true,
    },
    {
      title: "version-hardware",
      data: "hwVersion",
      search: "hwVersion",
      sort: "hwVersion",
      visible: true,
    },
    {
      title: "band",
      data: "bandaParsed",
      search: "bandaParsed",
      sort: "bandaParsed",
      visible: true,
    },
    {
      title: "micro",
      data: "microParsed",
      search: "microParsed",
      sort: "microParsed",
      visible: true,
    },
    {
      title: "LTE",
      data: "lte",
      search: "lte",
      sort: "lte",
      boolean: true,
      alter: {
        condition: "lte",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-mramqspi",
      data: "mramQspi",
      search: "mramQspi",
      sort: "mramQspi",
      boolean: true,
      alter: {
        condition: "mramQspi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-mramspi",
      data: "mramSpi",
      search: "mramSpi",
      sort: "mramSpi",
      boolean: true,
      alter: {
        condition: "mramSpi",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-gps",
      data: "gps",
      search: "gps",
      sort: "gps",
      boolean: true,
      alter: {
        condition: "gps",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: true,
    },
    {
      title: "gateway-options-port",
      data: "localPort",
      search: "localPort",
      sort: "localPort",
      boolean: true,
      alter: {
        condition: "localPort",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "gateway-options-debug",
      data: "debug",
      search: "debug",
      sort: "debug",
      boolean: true,
      alter: {
        condition: "debug",
        skins: [
          { rule: true, class: "fas fa-check-circle" },
          { rule: false, class: "fas fa-times-circle" },
        ],
      },
      visible: false,
    },
    {
      title: "channels",
      data: "canalesParsed",
      search: "canalesParsed",
      sort: "canalesParsed",
      numerical: true,
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      date: true,
      visible: true,
    },
    {
      title: "last-file",
      data: "lastFileReceivedParsed",
      search: "lastFileReceivedParsed",
      sort: "lastFileReceived",
      date: true,
      visible: true,
    },
    {
      title: "file-state",
      data: "lastFileStateText",
      search: "lastFileStateText",
      sort: "lastFileStateText",
      visible: true,
    },
    {
      title: "last-hello",
      data: "lastHelloParsed",
      search: "lastHelloParsed",
      sort: "lastHello",
      date: true,
      visible: true,
    },
    {
      title: "signal",
      data: "lastRssiParsed",
      search: "lastRssiParsed",
      sort: "lastRssi",
      numerical: true,
      visible: true,
    },
    {
      title: "battery",
      data: "lastVbatParsed",
      search: "lastVbatParsed",
      sort: "lastVbat",
      numerical: true,
      visible: true,
    },
    {
      title: "last-load",
      data: "lastChargeReadedParsed",
      search: "lastChargeReadedParsed",
      sort: "lastChargeReaded",
      numerical: true,
      visible: true,
    },
    {
      title: "end-points-total",
      data: "puntosFinalesTotales",
      search: "puntosFinalesTotales",
      sort: "puntosFinalesTotales",
      numerical: true,
      visible: true,
    },
    {
      title: "bbdd-final-points-error",
      data: "bbddFinalPointsError",
      search: "bbddFinalPointsError",
      sort: "bbddFinalPointsError",
      alter: {
        condition: "bbddFinalPointsError",
        skins: [
          { rule: true, class: "fas fa-times-circle" },
        ],
      },
      boolean: true,
      visible: true,
    },
  ];

  // Tabla de mantenimiento de los gateways
  rowNumbersMaintenance: boolean = true;
  tableMaxRegMaintenance: number = 10;
  gatewayMaintenanceData: Maintenance[];
  from: string;
  to: string;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  historicalStateText: object = {
    0: this.translate.instant("pending"),
    1: this.translate.instant("finished"),
  };
  columnsMaintenance: TableDataColumn[] = [
    {
      title: "management",
      data: null,
      search: "childsFlag",
      sort: "childsFlag",
      extraTable: true,
      visible: true,
    },
    {
      title: "sales-unit",
      data: "gwUnidadVenta",
      search: "gwUnidadVenta",
      sort: "gwUnidadVenta",
      visible: true,
    },
    {
      title: "user",
      data: "username",
      search: "username",
      sort: "username",
      visible: true,
    },
    {
      title: "date",
      data: "timestampParsed",
      search: "timestampParsed",
      sort: "timestamp",
      date: true,
      visible: true,
    },
    {
      title: "text",
      data: "historicalText",
      search: "historicalText",
      sort: "historicalText",
      visible: true,
    },
    {
      title: "group",
      data: "historicalGroup",
      search: "historicalGroup",
      sort: "historicalGroup",
      visible: true,
    },
    {
      title: "state",
      data: "historicalStateParsed",
      search: "historicalStateParsed",
      sort: "historicalState",
      visible: true,
    },
  ];

  // Selector de grupo
  groupSelected: number;
  groupSelect: object[] = MAINTENANCE_GROUPS.map((group) => {
    return {
      id: group.id,
      value: this.translate.instant(group.value),
    };
  });

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayVersionParserService: GatewayVersionParserService,
    private ManagementController: ManagementControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.sessionProfile = this.SessionDataService.getCurrentProfile();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.ReloadComponentService.reload();
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: any) => {
        if (dialogAction.action == "reload") {
          this.getGatewayMaintenanceData(this.from, this.to);
        }
      }
    );

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getGatewayStateData();
    this.getGatewayMaintenanceData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Obtención de los datos de estado de los gateways
  getGatewayStateData(): void {
    this.ManagementController.getEntityGatewaysState(
      this.currentEntity.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          let gatewayStateData: ManagementGateway[] = response["body"];
          gatewayStateData.forEach((gateway: ManagementGateway) => {
            gateway.lastFileStateText =
              gateway.lastFileState != null
                ? this.translate.instant("fileState" + gateway.lastFileState)
                : this.translate.instant("unknown");
            // Canales
            let canalesBinary = gateway.canales?.toString(2);
            gateway.canalesParsed = canalesBinary
              ?.split("")
              ?.filter((value) => value).length;
            // Versión
            gateway.bandaParsed = this.GatewayVersionParserService.getBand(
              gateway.banda
            );
            gateway.microParsed = this.GatewayVersionParserService.getMicro(
              gateway.micro
            );
            // Opciones
            if (gateway.options != null) {
              this.GatewayVersionParserService.setGatewayOptions(gateway);
            }
          });
          this.gatewayStateData = gatewayStateData;
        } else {
          this.gatewayStateData = [];
        }
      }
    });
  }

  // Obtención de los datos de estado de los gateways
  getGatewayMaintenanceData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.ManagementController.getEntityGatewaysMaintenance(
      this.currentEntity.id,
      this.groupSelected,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"]) {
          let gatewayMaintenanceData: MaintenanceParent[] = response["body"];

          gatewayMaintenanceData.forEach((maintenance: MaintenanceParent) => {
            maintenance.parent.historicalStateParsed =
              maintenance.parent.historicalState != null
                ? this.historicalStateText[maintenance.parent.historicalState]
                : "-";
          });

          this.gatewayMaintenanceData = gatewayMaintenanceData.map(
            (maintenance: MaintenanceParent) => {
              if (maintenance.childs.length > 0) {
                maintenance.parent.extraTableData = this.getExtraTableData(
                  maintenance.childs
                );
                maintenance.parent.childsFlag = true;
              } else {
                maintenance.parent.childsFlag = false;
              }
              return maintenance.parent;
            }
          );
        } else {
          this.gatewayMaintenanceData = [];
        }
      }
    });
  }

  // Acciones de la tabla de gateways
  tableActionsState(action: string, gateway: ManagementGateway): void {
    switch (action) {
      case "show-gateway":
        this.router.navigate(["/gateways/detalle/gateway/" + gateway.id]);
        break;
      default:
        break;
    }
  }

  // Acciones de la tabla de gateways
  extraTableActionsMaintenance(
    action: string,
    maintenanceParent: Maintenance,
    maintenanceChild: Maintenance
  ): void {
    let modalData: MaintenanceModalData = {
      maintenanceChild: maintenanceChild,
      maintenanceParent: maintenanceParent,
    };

    switch (action) {
      case "edit":
        this.editMaintenanceModal(modalData);
        break;
      default:
        break;
    }
  }

  // Obtención de los datos para la tabla anidada
  getExtraTableData(childsData: Maintenance[]): ExtraTableData {
    return {
      rowNumbers: true,
      data: childsData,
      columns: [
        {
          title: "action",
          data: [
            {
              name: "edit",
              tooltip: "edit",
              icon: "fas fa-edit",
              visible: { attribute: null, rule: true },
              disabled: false,
            },
          ],
          visible: true,
        },
        {
          title: "text",
          data: "historicalText",
          search: "historicalText",
          sort: "historicalText",
          visible: true,
        },
        {
          title: "date",
          data: "timestampParsed",
          search: "timestampParsed",
          sort: "timestamp",
          date: true,
          visible: true,
        },
      ],
    };
  }

  // Activación del modal de edición
  editMaintenanceModal(modalData: MaintenanceModalData): void {
    let data = JSON.parse(JSON.stringify(modalData.maintenanceChild));
    data.localTimezone = false;
    data.timestampParsed = this.DateParserService.parseDateWithoutFormat(
      data.timestamp
    )
      ?.toISOString(true)
      .split(".")[0]
      .slice(0, -3);
    this.MaterialDialogService.openDialog(
      ManagementEntityGatewaysDialogComponent,
      data
    );
  }
}
