<section class="content-header">
  <div class="container-fluid">
    <div class="panel panel-default">
      <div class="panel-heading">
        <app-panel-back class="panel-back-link"></app-panel-back>

        <h5>
          <b>{{
            id != null
              ? ("edit-entity" | translate) + ": " + entityData?.descripcion
              : ("new-entity" | translate)
          }}</b>
        </h5>
      </div>

      <div class="panel-body">
        <div *ngIf="entityData; else elseBlock" class="entity-form">
          <!-- Nombre de entidad -->
          <mat-form-field class="entity-form-name" appearance="standard">
            <mat-label>
              <span>{{ "name" | translate }}</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="entityData.descripcion"
              placeholder="{{ 'name' | translate }}..."
              required
            />
          </mat-form-field>
          <!-- Alias -->
          <mat-form-field class="entity-form-name" appearance="standard">
            <mat-label>
              <span>{{ "alias" | translate }}</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="entityData.alias"
              placeholder="{{ 'alias' | translate }}..."
            />
          </mat-form-field>
          <!-- Cliente -->
          <app-material-select
            [fullWidth]="true"
            [title]="'client' | translate"
            [bindValue]="'clientId'"
            [bindLabel]="'clientName'"
            [selected]="initialClient"
            [required]="true"
            [data]="clientList"
            (selectedOption)="entityData.client = $event?.clientId"
          ></app-material-select>
          <!-- Agrupación por defecto -->
          <app-material-select
            *ngIf="agrupationList"
            [fullWidth]="true"
            [title]="'default-agrupation' | translate"
            [bindValue]="'id'"
            [bindLabel]="'name'"
            [selected]="initialAgrupation"
            [noSelection]="true"
            [data]="agrupationList"
            (selectedOption)="entityData.defaultAgrupation = $event?.id"
          ></app-material-select>
          <!-- Zona horaria -->
          <app-material-select
            *ngIf="timezoneOptions"
            [fullWidth]="true"
            [title]="'timezone-default' | translate"
            [filter]="true"
            [bindValue]="'value'"
            [bindLabel]="'name'"
            [selected]="initialTimezone"
            [data]="timezoneOptions"
            (selectedOption)="entityData.timezone = $event?.value"
            [required]="true"
          ></app-material-select>
          <!-- apiId -->
          <mat-form-field
            class="entity-form-apiid"
            appearance="standard"
            *ngIf="selectedCustomField == 1"
          >
            <mat-label>
              <span>API Id</span>
            </mat-label>
            <input
              matInput
              [(ngModel)]="entityData.apiId"
              placeholder="API Id..."
            />
            <mat-hint class="entity-form-apiid-advise"
              ><i class="fa-solid fa-triangle-exclamation"></i
              ><span>{{ "api-id-advice" | translate }}</span
              ><i class="fa-solid fa-triangle-exclamation"></i
            ></mat-hint>
          </mat-form-field>
          <!-- Comentarios -->
          <mat-form-field class="entity-form-comments" appearance="standard">
            <mat-label>{{ "comments" | translate }}</mat-label>
            <textarea
              matInput
              [(ngModel)]="entityData.comments"
              placeholder="{{ 'comments' | translate }}..."
            ></textarea>
          </mat-form-field>
          <!-- Imagen de entidad -->
          <span class="btn btn-block btn-default btn-file">
            {{ fileName != null ? fileName : ("image-search" | translate) }}
            <input
              type="file"
              id="file"
              (change)="fileChangeEvent($event)"
              accept="image/x-png,image/jpeg,image/ico"
            />
          </span>
          <img
            *ngIf="entityData.imageFile || newImage"
            [src]="newImage ? imagePreview : entityData.imageFile"
            class="img-responsive img-rounded logo"
          />

          <!-- Visualización y borrado de datos -->
          <div
            *ngIf="sessionProfile == PROFILES.ARSON"
            class="entity-form-delete-config"
          >
            <div class="entity-form-delete-config-title">
              {{ "data-config" | translate }}
            </div>
            <!-- Restricción de visualización -->
            <div class="entity-form-delete-option">
              <mat-checkbox class="mat-option" [(ngModel)]="dataRestriction">
                {{ "entity-max-show" | translate }}:
              </mat-checkbox>
              <!-- Número de días -->
              <div>
                <input
                  type="number"
                  name="maxShowDays"
                  id="maxShowDays"
                  min="0"
                  [(ngModel)]="entityData.limitDays"
                  [disabled]="!dataRestriction"
                />
                <label for="maxShowDays">{{ "days" | translate }}</label>
                <!-- <span
                  >({{ "entity-restriction-aqualia-advise" | translate }})</span
                > -->
              </div>
            </div>
            <!-- Borrado de datos -->
            <div class="entity-form-delete-option">
              <mat-checkbox class="mat-option" [(ngModel)]="entityData.ddConf">
                {{ "entity-data-erase" | translate }}:
              </mat-checkbox>
            </div>
            <!-- Borrado de índices -->
            <div class="entity-form-delete-suboptions">
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.readings"
                [disabled]="!entityData.ddConf"
              >
                {{ "indexes" | translate }}
              </mat-checkbox>
              <!-- Borrado de consumos -->
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.consumptions"
                [disabled]="!entityData.ddConf"
              >
                {{ "une-consumptions" | translate }}
              </mat-checkbox>

              <!-- Borrado de tramas -->
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.frames"
                [disabled]="!entityData.ddConf"
              >
                {{ "frames" | translate }}
              </mat-checkbox>
              <!-- Borrado de eventos -->
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.events"
                [disabled]="!entityData.ddConf"
              >
                {{ "events" | translate }}
              </mat-checkbox>
              <!-- Borrado de alarmas -->
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.alarms"
                [disabled]="!entityData.ddConf"
              >
                {{ "alarms" | translate }}
              </mat-checkbox>
            </div>
            <!-- Días de borrado -->
            <div class="entity-form-delete-days">
              <span>{{ "entity-data-erase-days" | translate }}:</span>
              <input
                [ngClass]="{
                  'entity-form-delete-days-wrong':
                    entityData.dDays && entityData.dDays < 7
                }"
                type="number"
                name="dataEraseDays"
                min="7"
                [(ngModel)]="entityData.dDays"
                [disabled]="!entityData.ddConf"
              />
              <label for="dataEraseDays">{{ "days" | translate }}</label>
            </div>
          </div>

          <!-- Botones -->
          <div class="form-group entity-form-buttons">
            <!-- Campos personalizados -->
            <div *ngIf="sessionProfile == PROFILES.ARSON" class="entity-form-checkbox">
              <!-- Número total de contadores -->
              <div class="entity-form-devices">
                <!-- Contadores en el proyecto -->
                <div>
                  <span>{{ "devices-in-project" | translate }}:</span>
                  <input type="number" [(ngModel)]="entityData.projectMeters" />
                </div>
                <!-- Contadores incluidos en la licencia -->
                <div>
                  <span>{{ "devices-licensed" | translate }}:</span>
                  <input type="number" [(ngModel)]="entityData.licenseMeters" />
                </div>
              </div>

              <!-- Campos personalizados -->
              <app-material-select
                [title]="'custom-fields' | translate"
                [fullWidth]="true"
                [bindValue]="'value'"
                [bindLabel]="'name'"
                [data]="customFields"
                [selected]="entityData.isAqualia ? 1 : null"
                (selectedOption)="selectedCustomField = $event.value"
              ></app-material-select>

              <!-- Habilitar API -->
              <mat-checkbox
                class="mat-option"
                [(ngModel)]="entityData.isAqualia"
                (change)="selectedCustomField = entityData.isAqualia ? 1 : null"
              >
                {{ "is-aqualia" | translate }}
              </mat-checkbox>
            </div>

            <div class="btn-toolbar btn-as-block">
              <div class="btn-group">
                <!-- Actualizar -->
                <button
                  mat-button
                  class="mat-button-icon-text"
                  *ngIf="id != null"
                  [disabled]="
                    !entityData.descripcion ||
                    !entityData.client ||
                    (dataRestriction && entityData.limitDays == null) ||
                    (entityData.ddConf &&
                      (entityData.dDays == null || entityData.dDays < 7))
                  "
                  (click)="saveEntity()"
                >
                  <i class="fas fa-save"></i>
                  {{ "update" | translate }}
                </button>
                <!-- Guardar -->
                <button
                  mat-button
                  class="mat-button-icon-text"
                  *ngIf="id == null"
                  [disabled]="
                    !entityData.descripcion ||
                    !entityData.client ||
                    (dataRestriction && entityData.limitDays == null) ||
                    (entityData.ddConf &&
                      (entityData.dDays == null || entityData.dDays < 7))
                  "
                  (click)="saveEntity()"
                >
                  <i class="fas fa-save"></i>
                  {{ "save" | translate }}
                </button>
              </div>
              <!-- Quitar imagen -->
              <div *ngIf="entityData.imageFile || newImage" class="btn-group">
                <button
                  mat-button
                  class="mat-button-icon-text mat-button-red"
                  (click)="removeIcon()"
                >
                  <i class="fas fa-images"></i>
                  {{ "remove-icon" | translate }}
                </button>
              </div>
              <!-- Resetear -->
              <div class="btn-group">
                <button mat-button (click)="loadComponent()">
                  {{ "restart" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
