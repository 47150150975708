<div
  *ngIf="showCumulativeTotal && accumulatedTotals"
  class="graph-accumulated-totals"
>
  <div
    *ngFor="let accumulatedTotal of accumulatedTotals"
    [ngStyle]="{ color: accumulatedTotal.color }"
  >
    {{ "accumulated" | translate }}:
    <b>{{ accumulatedTotal.text }}</b>
  </div>
</div>
<div
  class="graph-container"
  (contextmenu)="!disableShowPoints ? showPointsValues($event) : null"
>
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="_data"
    [callbackFunction]="chartCallback"
    [(update)]="updateFlag"
    [oneToOne]="true"
    [constructorType]="chartConstructor"
  >
  </highcharts-chart>
</div>
