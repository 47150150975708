import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";

// Nav
import { NavModule } from "../nav-module/nav.module";

// Request progress
import { RequestQueueTableComponent } from "./request-queue/request-queue-table.component";
import { RequestProgressPanelComponent } from "../task-module/request-progress-panel/request-progress-panel.component";

// Routes
import { RoutesModule } from "../../routes.module";

// Table
import { TableModule } from "../table-module/table.module";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,

    // Nav
    NavModule,

    // Routes
    RoutesModule,

    // Table
    TableModule,

    // Traductor
    TranslateModule,
  ],
  declarations: [RequestQueueTableComponent, RequestProgressPanelComponent],
  exports: [RequestQueueTableComponent, RequestProgressPanelComponent],
})
export class TaskModule {}
