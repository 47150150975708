<!-- Editar alarma -->
<div class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{
        data.action == "edit"
          ? ("edit" | translate)
          : data.action == "new"
          ? ("new" | translate)
          : ("assign" | translate)
      }}
    </h5>
  </div>
  <div class="dialog-body device-cups-dialog">
    <!-- Edición de CUPS -->
    <div *ngIf="data.action == 'edit' || data.action == 'new'">
      <!-- CUPS -->
      <mat-form-field *ngIf="data.cupsExist" appearance="standard">
        <mat-label> {{ data.cupsData.cups?.name }}</mat-label>
        <input
          matInput
          [(ngModel)]="data.newCups.clave"
          placeholder="{{ data.cupsData.cups?.description }}"
          [required]="data.cupsData.cups?.required"
        />
      </mat-form-field>

      <!-- Columnas de CUPS -->
      <ng-container *ngFor="let column of data.cupsData.columns">
        <mat-form-field
          *ngIf="
            column.colPosition != 'col00' &&
            column.colPosition != 0 &&
            column.colPosition != 'col100' &&
            column.colPosition != 100
          "
          appearance="standard"
        >
          <mat-label> {{ column.name }}</mat-label>
          <input
            matInput
            [(ngModel)]="data.newCups[column.colPosition]"
            placeholder="{{ column.description }}"
            autocomplete="off"
            [required]="column.required"
          />
        </mat-form-field>
      </ng-container>
    </div>

    <!-- Asignación de CUPS -->
    <div *ngIf="data.action == 'assign'">
      <app-material-select
        [fullWidth]="true"
        [title]="data.cupsName"
        [bindValue]="'id'"
        [bindLabel]="'descripcion'"
        [data]="data.cupsList"
        (selectedOption)="assignedCups = $event?.id"
        [required]="true"
      ></app-material-select>
    </div>
  </div>

  <!-- Salvar cambios -->
  <div class="dialog-footer">
    <button
      mat-button
      class="mat-button-icon-text"
      (click)="
        data.action == 'edit'
          ? editCups()
          : data.action == 'new'
          ? newCups()
          : assignCups()
      "
      [disabled]="
        (data.action == 'assign' && assignedCups == null) ||
        ((data.action == 'edit' || data.action == 'new') &&
          data.cupsData.cups?.required &&
          (data.newCups.clave == null || data.newCups.clave == ''))
      "
    >
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
