import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Routes
import { RoutesModule } from "../../routes.module";

// Angular Material
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MaterialModule } from "../material-module/material.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatCheckboxModule } from "@angular/material/checkbox";

// Effects
import { WaterEffectComponent } from "./nav-effects/water-effect/water-effect.component";
import { GasEffectComponent } from "./nav-effects/gas-effect/gas-effect.component";

// Directives
import { DirectivesModule } from "../../directives/directives.module";

// Nav
import { NavbarSideComponent } from "./navbar-side/navbar-side.component";
import { NavbarTopComponent } from "./navbar-top/navbar-top.component";
import { FooterbarComponent } from "./footerbar/footerbar.component";
import { FooterbarDialogComponent } from "./footerbar/footerbar-dialog/footerbar-dialog.component";
import { NavbarResponsiveComponent } from "./navbar-responsive/navbar-responsive.component";
import { NavbarTopDropdownComponent } from "./navbar-top/navbar-top-dropdown/navbar-top-dropdown.component";
import { NavbarUserDropdownComponent } from "./navbar-top/navbar-user-dropdown/navbar-user-dropdown.component";
import { NavbarTopSearchBoxComponent } from "./navbar-top/navbar-top-search-box/navbar-top-search-box.component";
import { PanelBackComponent } from "./panel-back/panel-back.component";

// Screen recorder
import { ScreenRecorderComponent } from "../../global/screen-recorder/screen-recorder.component";

// Traductor
import { TranslateModule } from "@ngx-translate/core";

// Graph
import { GraphModule } from "../graph-module/graph.module";
import { EntityTotalSnapshotComponent } from "./navbar-top/entity-total-snapshot/entity-total-snapshot.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Angular Material
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MaterialModule,
    ScrollingModule,
    MatCheckboxModule,

    // Directives
    DirectivesModule,

    // Routes
    RoutesModule,

    // Traductor
    TranslateModule,

    // Graph
    GraphModule,
  ],
  declarations: [
    NavbarSideComponent,
    NavbarTopComponent,
    FooterbarComponent,
    FooterbarDialogComponent,
    NavbarResponsiveComponent,
    NavbarTopDropdownComponent,
    NavbarUserDropdownComponent,
    NavbarTopSearchBoxComponent,
    PanelBackComponent,
    EntityTotalSnapshotComponent,

    // Effects
    WaterEffectComponent,
    GasEffectComponent,

    // Screen recorder
    ScreenRecorderComponent,
  ],
  exports: [
    NavbarSideComponent,
    NavbarTopComponent,
    FooterbarComponent,
    NavbarResponsiveComponent,
    PanelBackComponent,
  ],
})
export class NavModule {}
