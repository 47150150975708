export const ENVIRONMENT = {
  production: false,
  // ** SATELITE
  // API_ENDPOINT: "https://igotasrv.arsondata.com/lora-webapp",
  // ** DEV
  API_ENDPOINT: "https://lorawansrvtest.arsonmetering.com/lora-webapp",
  // ** PROD
  // API_ENDPOINT: "https://lorawansrv.arsonmetering.com/lora-webapp",
  // ** FRAN
  // API_ENDPOINT: "http://192.168.1.18:8080",
  // ** IBAI
  // API_ENDPOINT: "http://192.168.1.52:8080",
  // API_ENDPOINT: "http://localhost:8080",
  enableDebug: true,
};
