<!-- Modal de cambio de entidad -->
<div *ngIf="data" class="dialog-container">
  <div class="dialog-header">
    <h5 class="dialog-title">
      {{ "to-pending" | translate }}
    </h5>
  </div>
  <div class="dialog-body meter-review-dialog">
    <!-- Comentarios -->
    <mat-form-field appearance="standard">
      <mat-label>{{ "comments" | translate }}</mat-label>
      <textarea
        #commentsArea
        matInput
        [(ngModel)]="comments"
        placeholder="{{ 'comments' | translate }}..."
      ></textarea>
    </mat-form-field>
    <!-- Estado -->
    <app-material-select
      [fullWidth]="true"
      [title]="'state' | translate"
      [bindValue]="'id'"
      [bindLabel]="'name'"
      [selected]="data.state ? data.state : 0"
      [data]="stateList"
      (selectedOption)="state = $event?.id"
    ></app-material-select>
  </div>
  <div class="dialog-footer">
    <button mat-button class="mat-button-icon-text" (click)="editReview()">
      <i class="fas fa-save"></i>
      {{ "save" | translate }}
    </button>
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
