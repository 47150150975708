import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, map, Subscription } from 'rxjs';
import { Options } from 'highcharts';
import { GraphControllerComponent } from '../../../../../../../modules/graph-module/graph-controller/graph-controller.component';
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from './../../../../../../../services/shared/ReloadComponentService.service';
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { TranslateService } from '@ngx-translate/core';
import { GRAPH_CONFIG } from '../../../../../../../modules/graph-module/GRAPH_CONFIG';
import { BatteryGraphData } from './BatteryInterface.type';
import { Client } from '../../../../../../../interfaces/ClientGlobalInterface.type';
import { GraphOptionsService } from '../../../../../../../modules/graph-module/GraphOptionsService.service';
import { DetailDevice, DetailDeviceAlarmSerie, DetailDeviceDataSerie } from '../../../../DeviceInterface.type';
import moment from 'moment';
import { RANDOM_COLORS } from '../../../../../../../modules/map-module/map-variables/MAP_COLORS';

@Component({
  selector: 'app-meter-battery-dialog',
  templateUrl: './meter-battery-dialog.component.html',
  styleUrls: ['./meter-battery-dialog.component.scss']
})
export class MeterBatteryDialogComponent implements OnInit, OnDestroy {

  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Cliente
  private clientSub: Subscription;
  private currentClient: Client;

  // Gráfica
  @Input() data: any;
  private graphData: BatteryGraphData;
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  updateFlag: boolean = true;
  graphSeries: (DetailDeviceAlarmSerie | DetailDeviceDataSerie)[];


  @ViewChild(GraphControllerComponent) graphController: GraphControllerComponent;
  from: number = 0;
  to: number = 0;
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("year").subtract("5", "years"),
    endDate: moment().endOf("day"),
  };
  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private SessionDataService: SessionDataService,
    private RouteCheckService: RouteCheckService,
    private ReloadComponentService: ReloadComponentService,
    private router: Router,
    private MeterController: MeterControllerService,
    private translate: TranslateService,
    private GraphOptionsService: GraphOptionsService,
  ) {}

  /***************************************************************************/
  // ANCHOR Lifecycle Hooks
  /***************************************************************************/

  ngOnInit(): void {
    this.initializeClient();
    this.loadComponentIfClientExists();
  }

  ngOnDestroy(): void {
    this.unsubscribeClient();
  }

  /***************************************************************************/
  // ANCHOR Initialization Methods
  /***************************************************************************/

  private initializeClient(): void {
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.clientSub = this.SessionDataService.getClient().subscribe(() => {
      this.RouteCheckService.stayOnRoute("client")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });
  }

  private loadComponentIfClientExists(): void {
    if (this.currentClient) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Cleanup Methods
  /***************************************************************************/

  private unsubscribeClient(): void {
    if (this.clientSub) {
      this.clientSub.unsubscribe();
    }
  }
  close() {
    this.SessionDataService.sendDialogAction({ action: "close" });
  }


  /***************************************************************************/
  // ANCHOR Component Loading Methods
  /***************************************************************************/

  private loadComponent(): void {
    this.setHighchartsOptions();
    this.loadGraphData(this.from, this.to);
  }

  private setHighchartsOptions(): void {
    const options = this.GraphOptionsService.getDefaultHighchartsOptions(
      this.translate.instant("battery-export")
    );
    options.exporting.enabled = false;
    this.highchartsOptions = options;
  }

  /***************************************************************************/
  // ANCHOR Data Loading Methods
  /***************************************************************************/

  loadGraphData(from?: number, to?: number): void {

    this.graphData = { readings: [] }; // Initialize graphData
    this.from = from;
    this.to = to;


    // Verificar si this.data es un número, si no lo es, nos estaran enviando el meter completo
    if (typeof this.data !== 'number') {
      let metersData: DetailDevice[]|any[] = this.data;
      // Crear un array de observables
      const meterRequests = metersData.map(element => {
        let contador:number = element.id;
        if(element.contador){
          contador=element.contador;
        }
        return this.MeterController.getBatteryHistoryById(contador, this.from, this.to).pipe(
          map(response => ({
            ...response, // Copia la respuesta original
            fabricanteName: element.fabricanteParsed, // Añade agrupationName
            devTypeParsed: element.devTypeParsed, // Añade devTypeParsed
            id: element.id // Añade el id
          }))
        );
      });


      // Usar forkJoin para combinar las respuestas
      forkJoin(meterRequests).subscribe({
        next: (responses) => {
          this.handleMultipleGraphDataResponse(responses);
        },
        error: (error) => this.handleError(error)
      });

    } else {
      // API call to get battery data
      const meterRequest = this.MeterController.getBatteryHistoryById(this.data, this.from, this.to);
      meterRequest.subscribe({
        next: (response) => this.handleGraphDataResponse(response),
        error: (error) => this.handleError(error)
      });
    }
  }

// Método para manejar múltiples respuestas
private handleMultipleGraphDataResponse(responses: any[]): void {
  this.graphSeries = []; // Resetear las series antes de agregar nuevas

  responses.forEach((response) => {
    if (response["code"] === 0) {
      const agrupationName = response.fabricanteName || 'Unknown'; // Asignar un valor por defecto
      const devTypeParsed = response.devTypeParsed || 'Unknown Type'; // Asignar un valor por defecto
      const contId = response.id || 'Unknown S/N'; // Asignar un valor por defecto

      const readings = response["body"]["contadorBatDtoList"].map(item => {
        const value = item.value > 100 ? 100 : item.value;
        return [item.timestamp, value, item.contador, agrupationName, devTypeParsed, contId];
      });

      // Asignar un color único para cada serie
      const color = RANDOM_COLORS[this.graphSeries.length]; // Usa la longitud para el índice

      // Aquí obtenemos el contador del primer reading para el id y el nombre
      const contador = readings.length > 0 ? readings[0][2] : 'unknown'; // Usar el contador del primer reading

      const seriesName = `${agrupationName} ${devTypeParsed} (${contId})`;

      const readingsSeries: DetailDeviceDataSerie = {
        id: contador.toString(), // Usar el contador como id
        name: seriesName, // Nombre de la serie basado en el contador
        type: 'line',
        data: readings.slice().reverse(), // Revertir datos para el orden correcto
        tooltip: {
          pointFormat: '{point.y} %'
        },
        color: color // Asignar color
      };

      this.graphSeries.push(readingsSeries); // Agregar la serie a graphSeries
    } else {
      console.error("Error en la respuesta de la API:", response);
    }
  });

  this.setChartsOptions(); // Llamar para configurar opciones del gráfico después de agregar todas las series
}



  private handleGraphDataResponse(response: any): void {
    if (response["code"] === 0 && response["body"]) {
      this.graphData.readings = response["body"]["contadorBatDtoList"].map(item => {
        const value = item.value > 100 ? 100 : item.value;
        return [item.timestamp, value];
      });
      this.getSeries();
      this.setChartsOptions();
    } else {
      console.error("Error en la respuesta de la API:", response);
      this.graphData.readings = []; // Clear data on error
    }
  }

  private handleError(error: any): void {
    console.error("Error en la llamada a la API:", error);
    this.graphData.readings = []; // Clear data on error
  }



  /***************************************************************************/
  // ANCHOR Chart Options Methods
  /***************************************************************************/

  private setChartsOptions(): void {
    let chartOptions = JSON.parse(JSON.stringify(GRAPH_CONFIG.default.chartOptions));
    chartOptions.rangeSelector.enabled = true;

    // Sobrescribir la configuración del eje Y para desactivar decimales
    chartOptions.yAxis.forEach(axis => {
      axis.allowDecimals = false; // Desactivar decimales
      axis.min = 0; // Establecer mínimo
      axis.max = 100; // Establecer máximo
    });

    // Configuración del eje X para mostrar el año
    chartOptions.xAxis = {
      ...chartOptions.xAxis,
      labels: {
        format: '{value:%d-%m-%Y}', // Mostrar año, mes y día
      },
    };

    // Cambiar showInLegend para cada serie dependiendo de la cantidad de series
    const showInLegend = this.graphSeries.length > 1; // Si hay más de una serie, mostrar en la leyenda
    chartOptions.series = this.graphSeries.map(series => ({
      ...series,
      showInLegend: showInLegend, // Cambiar según la condición
    }));

    this.chartOptions = chartOptions;
  }


  /***************************************************************************/
  // ANCHOR Series Methods
  /***************************************************************************/

  private getSeries(): void {
    const series: (DetailDeviceAlarmSerie | DetailDeviceDataSerie)[] = []; // Reset series array


    if (this.graphData && this.graphData.readings.length > 0) {
      const readingsSeries: DetailDeviceDataSerie = {
        id: "valor",
        name: this.translate.instant("battery-charge"),
        type: 'line',
        data: this.graphData.readings.slice().reverse(), // Reverse data for correct order
        tooltip: {
          pointFormat: '{point.y} %'
        }
      };


      series.push(readingsSeries);
    }

    this.graphSeries = series;
  }
}


