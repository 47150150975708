<div class="login-container">
  <div class="login-panel">
    <div class="panel panel-default">
      <!-- Título de login -->
      <div class="panel-heading">
        <h5 class="login-title">
          <!-- <b>{{"login-title" | translate}}</b> -->
          <b>{{ loginTitle ? loginTitle : "ArsonData Metering" }}</b>
        </h5>
      </div>

      <!-- Logo de login -->
      <span class="logo-lg">
        <img
          class="img-responsive center"
          src="{{ logoUrl ? logoUrl : defaultImg }}"
        />
      </span>

      <!-- Formulario de login -->
      <div class="panel-body">
        <!-- Usuario -->
        <div class="form-group has-feedback">
          <input
            [(ngModel)]="username"
            name="username"
            class="form-control"
            placeholder="{{ 'user' | translate }}..."
            [disabled]="loginWating || loginBlockedTime > 0"
            autocomplete="false"
            (keyup.enter)="login()"
          />
          <i class="glyphicon glyphicon-user form-control-feedback"></i>
        </div>

        <!-- Contraseña -->
        <div class="form-group has-feedback">
          <input
            [(ngModel)]="password"
            [type]="hidePassword ? 'password' : 'text'"
            type="password"
            name="password"
            class="form-control"
            placeholder="{{ 'password' | translate }}..."
            [disabled]="loginWating || loginBlockedTime > 0"
            autocomplete="false"
            (keyup.enter)="login()"
          />
          <i
            class="form-control-feedback pointer"
            [ngClass]="hidePassword ? 'fas fa-lock' : 'fas fa-unlock'"
            title="{{ 'show' | translate }}"
            (click)="hidePassword = !hidePassword"
          ></i>
        </div>

        <!-- Submit -->
        <div class="form-group">
          <button
            name="enter"
            [disabled]="loginWating || loginBlockedTime > 0"
            class="btn btn-primary btn-block"
            (click)="login()"
          >
            <div
              *ngIf="loginWating && loginAttemps == 0"
              class="fa-stack fa-4x hourglass-spin"
            >
              <i class="fa fa-stack-1x fa-hourglass-start"></i>
              <i class="fa fa-stack-1x fa-hourglass-half"></i>
              <i class="fa fa-stack-1x fa-hourglass-end"></i>
              <i class="fa fa-stack-1x fa-hourglass-end spin"></i>
            </div>
            {{
              loginBlockedTime > 0
                ? loginBlockedTime + " s"
                : ("enter" | translate)
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
