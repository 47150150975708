// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// HighCharts
import { Options } from "highcharts";
// Servicios propios
import { AssociationControllerService } from "../../../../services/server/AssociationController.service";
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { MaterialDialogService } from "../../../../modules/material-module/material-dialog/material-dialog.service";
// Interfaces
import { Entity } from "../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../modules/table-module/TableInterface.type";
import { GraphFilterOptionSelected } from "../../../../modules/graph-module/GraphInterface.type";
import {
  Association,
  AssociationGraphRequestData,
  AssociationGraphSumatoryData,
  AssociationGraphMeterData,
  AssociationGraphMeterSerie,
  AssociationGraphSumatorySerie,
} from "../DataManagementInterface.type";
import { MaterialSelectConfig } from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { METROLOGY_TYPE } from "../../../../interfaces/DeviceGlobalInterface.type";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { GRAPH_TYPES } from "../../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
import { GRAPH_SUMATORY } from "../../data-analysis/data-analysis-meter-graph/data-analysis-meter-graph.component";
// Componentes
import { GraphControllerComponent } from "../../../../modules/graph-module/graph-controller/graph-controller.component";
import { DataManagementAssociationDialogComponent } from "./data-management-association-dialog/data-management-association-dialog.component";

@Component({
  selector: "app-association",
  templateUrl: "./data-management-association.component.html",
  styleUrls: ["./data-management-association.component.scss"],
})
export class ManagementAsociationComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Tabla
  maxReg: number = 10;
  tableData: Association[];
  exportFileName: string =
    this.translate.instant("association-export") + " " + moment().format("ll");
  columns: (TableActionColumn | TableDataColumn)[];

  // Gráfica
  graphAssociation: Association;
  graphSeries: (AssociationGraphMeterSerie | AssociationGraphSumatorySerie)[];
  graphData: (AssociationGraphSumatoryData | AssociationGraphMeterData)[];
  graphType: number;
  sumatory: number;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [
        {
          value: GRAPH_SUMATORY.INDIVIDUAL,
          name: this.translate.instant("graph-individual"),
        },
        {
          value: GRAPH_SUMATORY.SUM,
          name: this.translate.instant("graph-sum"),
        },
      ],
      selected: GRAPH_SUMATORY.SUM,
    },
  ];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: moment().endOf("day"),
  };
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;
  isGas: boolean;
  showCumulative: boolean;

  // Nuevo
  newIcon: string = "fas fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private AssociationController: AssociationControllerService,
    private GraphOptionsService: GraphOptionsService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de agrupación y entidad
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.setColumns();
    this.getTableData();
    this.setHighchartsOptions();
  }

  // Obtención de los datos de la tabla
  getTableData(): void {
    this.AssociationController.getAssociationList(
      this.currentAgrupation.id
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"] != null) {
          let tableData: Association[] = response["body"];
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    });
  }

  setColumns(): void {
    this.columns = [
      {
        title: "action",
        data: [
          {
            name: "graph",
            tooltip: "graph",
            icon: "fas fa-chart-area",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "edit",
            tooltip: "edit",
            icon: "fas fa-edit",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == PROFILES.ARSON ||
                this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
                this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
            },
            disabled: false,
          },
          {
            name: "alarms-custom-config",
            tooltip: "alarms-custom-config",
            icon: "fas fa-cog",
            visible: { attribute: null, rule: true },
            disabled: true,
          },
          {
            name: "meter-orders",
            tooltip: "meter-orders",
            icon: "fas fa-list-check",
            visible: { attribute: null, rule: true },
            disabled: false,
          },
          {
            name: "delete",
            tooltip: "delete",
            icon: "fas fa-trash",
            visible: {
              attribute: null,
              rule:
                this.sessionProfile == PROFILES.ARSON ||
                this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
                this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
                this.sessionProfile == PROFILES.ADMIN_AGRUPACION,
            },
            disabled: false,
            warning: true,
          },
        ],
        visible: true,
      },
      {
        title: "name",
        data: "name",
        search: "name",
        sort: "name",
        visible: true,
      },
      {
        title: "description",
        data: "description",
        search: "description",
        sort: "description",
        visible: true,
      },
      {
        title: "meters",
        data: "totalMeters",
        search: "totalMeters",
        sort: "totalMeters",
        visible: true,
      },
    ];
  }

  // Acciones de la tabla
  tableActions(action: string, association: Association): void {
    switch (action) {
      case "edit":
        this.editAssociation(association);
        break;
      case "delete":
        this.deleteAssociation(association);
        break;
      case "graph":
        this.graphAssociation = { ...association };
        this.graphType = null;
        this.isGas = this.graphAssociation.metrologyType == 2;
        setTimeout(() => this.setTypeFilter(), 0);
        break;
      case "alarms-custom-config":
        this.router.navigate(["/alarmas/personalizadas/configuracion"], {
          state: { data: association.id },
        });
        break;
      case "meter-orders":
        this.MaterialDialogService.openDialog(
          DataManagementAssociationDialogComponent,
          association
        );
        break;
      default:
        break;
    }
  }

  // Edición de asociación
  editAssociation(association: Association): void {
    this.router.navigate(
      ["/analisis-datos/asociaciones/editar/" + association.id],
      {
        state: { data: association },
      }
    );
  }

  // Eliminación de asociación
  deleteAssociation(association: Association): void {
    this.ToastService.fireAlertWithOptions(
      "question",
      this.translate.instant("association-delete-question")
    ).then((userConfirmation: boolean) => {
      if (userConfirmation) {
        this.AssociationController.deleteAssociation(association.id).subscribe(
          (response) => {
            if (response["code"] == 0) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("association-deleted")
              );
              this.ReloadComponentService.reload();
            }
          }
        );
      }
    });
  }

  // Creación de asociación
  newAssociation(): void {
    this.router.navigate(["analisis-datos/asociaciones/nuevo"]);
  }

  // Seteo del filtro de tipo de gráfica
  setTypeFilter() {
    this.sumatory = GRAPH_SUMATORY.SUM;
    if (this.graphAssociation.metrologyType == METROLOGY_TYPE.GAS) {
      this.graphFilters[1] = {
        title: this.translate.instant("type"),
        options: [
          {
            value: GRAPH_TYPES.CONSUMPTION,
            name: this.translate.instant("consumption"),
          },
          {
            value: GRAPH_TYPES.NORMALIZED_CONSUMPTION,
            name: this.translate.instant("consumption-normalized"),
          },
          { value: GRAPH_TYPES.ENERGY, name: this.translate.instant("energy") },
        ],
        selected: GRAPH_TYPES.NORMALIZED_CONSUMPTION,
      };
      this.graphType = GRAPH_TYPES.NORMALIZED_CONSUMPTION;
    } else {
      this.graphFilters[1] = {
        title: this.translate.instant("type"),
        options: [
          {
            value: GRAPH_TYPES.CONSUMPTION,
            name: this.translate.instant("consumption"),
          },
        ],
        selected: GRAPH_TYPES.CONSUMPTION,
      };
      this.graphType = GRAPH_TYPES.CONSUMPTION;
    }
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string): void {
    let data: AssociationGraphRequestData = {
      meterList: null,
      sector: this.graphAssociation.id,
      fromTimestamp: parseInt(from),
      toTimestamp: parseInt(to),
      graphType: this.graphType,
    };

    this.AssociationController.getGraphGroup(this.sumatory, data).subscribe(
      (response) => {
        if (response["code"] == 0 && response["body"]) {
          let graphData: (
            | AssociationGraphSumatoryData
            | AssociationGraphMeterData
          )[] = response["body"];
          this.graphData = graphData;
        } else {
          this.graphData = [];
        }

        this.getSeries();
        this.showCumulative =
          this.isGas &&
          this.sumatory == GRAPH_SUMATORY.SUM &&
          this.graphType == GRAPH_TYPES.ENERGY;
      }
    );
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    let series: (AssociationGraphMeterSerie | AssociationGraphSumatorySerie)[] =
      [];

    if (this.graphData && this.sumatory == GRAPH_SUMATORY.INDIVIDUAL) {
      this.graphData.forEach((meter: AssociationGraphMeterData) => {
        series.push({
          id: meter.nroSerie,
          name: meter.nroSerie,
          data: meter.readings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
          },
          events: {
            click: () => {
              this.ToastService.fireAlertWithOptions(
                "question",
                this.translate.instant("question-show-meter") +
                  " " +
                  meter.nroSerie +
                  "?"
              ).then((userConfirmation: boolean) => {
                if (userConfirmation) {
                  this.router.navigate([
                    "/dispositivos/detalle/contador",
                    meter.meterId,
                  ]);
                }
              });
            },
          },
        });
      });
      series.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (this.graphData && this.sumatory == GRAPH_SUMATORY.SUM) {
      series = [
        {
          id: "suma",
          name: this.translate.instant("plus"),
          data: this.graphData["readings"],
          dataGrouping: { approximation: "sum" },
          color: "#000",
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
            valueDecimals: 3,
          },
        },
      ];
    } else {
      series = [];
    }
    this.graphSeries = series;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    highchartsOptions.scrollbar = {
      barBackgroundColor: "rgba(0,0,0,0.25)",
      buttonBackgroundColor: "rgba(255,255,255,1)",
      buttonBorderRadius: 5,
      trackBorderRadius: 5,
    };
    highchartsOptions.plotOptions.series.marker.enabled = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    delete chartOptions["chart"]["navigatorOptions"];
    chartOptions["legend"]["enabled"] =
      this.sumatory == GRAPH_SUMATORY.INDIVIDUAL ? true : false;
    chartOptions["chart"]["height"] = "35%";
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == GRAPH_TYPES.INDEX ||
      this.graphType == GRAPH_TYPES.CONSUMPTION
        ? " m³"
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? " Nm³"
        : " kWh");
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == GRAPH_TYPES.INDEX
        ? this.translate.instant("value")
        : this.graphType == GRAPH_TYPES.CONSUMPTION
        ? this.translate.instant("consumption")
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Actualización del tipo de gráfica
  updateGraphData(selected: GraphFilterOptionSelected): void {
    switch (selected.filterIndex) {
      case 0:
        this.sumatory = selected.value;
        break;
      case 1:
        this.graphType = selected.value;
        break;
      default:
        break;
    }
  }
}
