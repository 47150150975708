// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { SessionDataService } from "../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../services/shared/ReloadComponentService.service";
import { GatewayControllerService } from "../../../../../services/server/GatewayController.service";
import { ToastService } from "../../../../../services/shared/ToastService.service";
import { RequestQueueService } from "../../../../../modules/task-module/request-queue/request-queue-service/request-queue.service";
import { MaterialDialogService } from "../../../../../modules/material-module/material-dialog/material-dialog.service";
import { DateParserService } from "../../../../../services/shared/DateParserService.service";
import { RouteCheckService } from "../../../../../services/shared/RouteCheckService.service";
// Interfaces
import { Entity } from "../../../../../interfaces/EntityGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
  TableGlobalAction,
  TableSelectColumn,
} from "../../../../../modules/table-module/TableInterface.type";
import { Gateway } from "../../../../../interfaces/GatewayGlobalInterface.type";
// Componentes
import { GatewayInstockDialogComponent } from "./gateway-instock-dialog/gateway-instock-dialog.component";

@Component({
  selector: "app-gateways-instock",
  templateUrl: "./gateways-instock.component.html",
  styleUrls: ["./gateways-instock.component.scss"],
})
export class GatewaysInstockComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  sessionProfile: string;
  dialog: Subscription;

  // Table
  tableMaxReg: number = 100;
  tableData: Gateway[];
  tableSelectedData: Gateway[];
  columns: (TableActionColumn | TableSelectColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-gateway",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "assign-entity",
          tooltip: "assign-entity",
          icon: "fas fa-sitemap",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
        {
          name: "delete-gateway",
          tooltip: "gateway-delete",
          icon: "fas fa-trash",
          visible: { attribute: null, rule: true },
          disabled: false,
          warning: true,
        },
      ],
      visible: true,
    },
    {
      title: "select",
      search: "selected",
      sort: "selected",
      visible: true,
    },
    {
      title: "serial-number",
      data: "nroSerie",
      search: "nroSerie",
      sort: "nroSerie",
      visible: true,
    },
    {
      title: "sales-unit",
      data: "unidadVenta",
      search: "unidadVenta",
      sort: "unidadVenta",
      visible: true,
    },
    {
      title: "Amei",
      data: "amei",
      search: "amei",
      sort: "amei",
      visible: true,
    },
    {
      title: "last-communication",
      data: "lastCommunicationParsed",
      search: "lastCommunicationParsed",
      sort: "lastCommunication",
      visible: true,
    },
  ];
  tableGlobalActions: TableGlobalAction[] = [
    {
      title: "assign-entity",
      icon: "fas fa-sitemap",
      selectionRequired: true,
      help: "help-table-change-entity",
    },
  ];

  // Filtros
  nroSerieFilter: string = "";
  unidadVentaFilter: string = "";
  ameiFilter: string = "";

  // Menú de opciones del componente
  newIcon: string = "fa fa-plus";
  newTitle: string = this.translate.instant("new");

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private GatewayController: GatewayControllerService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private requestQueue: RequestQueueService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentEntity = this.SessionDataService.getCurrentEntity();

    // Escucha de cambios en los valores de entidad
    this.entitySub = this.SessionDataService.getEntity().subscribe(() => {
      this.RouteCheckService.stayOnRoute("entity")
        ? this.ReloadComponentService.reload()
        : this.router.navigate(["/principal"]);
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction) => {
        if (dialogAction.action == "reload") {
          this.getData();
        }
      }
    );

    // Inicialización
    if (this.currentEntity) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.entitySub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getData();
  }

  // Obtención de los datos de la tabla
  getData(): void {
    this.GatewayController.getInStockGateways().subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"] != null) {
          let tableData: Gateway[] = response["body"];
          tableData.forEach((gateway: Gateway) => {
            gateway.lastCommunicationParsed = this.DateParserService.parseDate(
              gateway.lastCommunication,
              "L HH:mm:ss"
            );
          });
          this.tableData = tableData;
        } else {
          this.tableData = [];
        }
      }
    });
  }

  // Acciones de la tabla
  tableActions(action: string, gateway: Gateway): void {
    switch (action) {
      case "show-gateway":
        this.router.navigate(["/gateways/detalle/gateway/" + gateway.id]);
        break;
      case "assign-entity":
        this.changeGatewayEntity([gateway.id]);
        break;
      case "delete-gateway":
        this.deleteGateway(gateway);
        break;
      default:
        break;
    }
  }

  // Acciones globales de la tabla
  tableGlobalAction(action: string): void {
    switch (action) {
      case "assign-entity":
        this.changeGatewayEntity(
          this.tableSelectedData?.map((gateway) => gateway.id)
        );
        break;
      default:
        break;
    }
  }

  // Cambio de entidad de un gateway
  changeGatewayEntity(gatewaysIds: number[]): void {
    this.MaterialDialogService.openDialog(GatewayInstockDialogComponent, {
      action: "newEntity",
      entity: this.currentEntity.id,
      gateways: gatewaysIds,
    });
  }

  // Borrado de gateway
  deleteGateway(gateway: Gateway): void {
    this.ToastService.fireAlertWithTripleOptions(
      "question",
      this.translate.instant("gateway-delete-question"),
      this.translate.instant("cancel"),
      this.translate.instant("with-communication"),
      this.translate.instant("without-communication")
    ).then((userSelection: string) => {
      if (userSelection == "option1") {
        this.requestQueue.deleteGatewayTask(gateway, 1);
      } else if (userSelection == "option2") {
        this.requestQueue.deleteGatewayTask(gateway, 0);
      }
    });
  }

  // Nuevo gateway
  newGatewayModal(): void {
    this.MaterialDialogService.openDialog(GatewayInstockDialogComponent, {
      action: "newGateway",
      entity: this.currentEntity.id,
      gateway: {
        id: null,
        nroSerie: null,
        amei: null,
        unidadVenta: null,
      },
    });
  }

  // Información del archivo de importación de gateways
  showInfo(): void {
    let html: string =
      `<table class="gateways-file-table">
                  <tr class="gateways-file-table-required">
                    <th>
                      UNIDAD_VENTA (*): 
                    </th>
                    <td>` +
      this.translate.instant("sales-unit-import") +
      `
                    </td>
                  </tr>
                  <tr class="gateways-file-table-required">
                    <th>
                      AMEI (*): 
                    </th>
                    <td>` +
      this.translate.instant("amei-import") +
      `
                    </td>
                  </tr>
                  <tr class="gateways-file-table-required">
                    <th>
                      NRO_SERIE (*): 
                    </th>
                    <td>` +
      this.translate.instant("serial-number-import") +
      `
                    </td>
                  </tr>
                  <tr>
                    <th>
                      COMMENTS: 
                    </th>
                    <td>` +
      this.translate.instant("comments") +
      `
                    </td>
                  </tr>
                                    <tr>
                    <th>
                      CANALES: 
                    </th>
                    <td>` +
      this.translate.instant("channels") +
      `
                    </td>
                  </tr>
                </table>`;
    this.ToastService.fireAlertInfoText(
      "info",
      this.translate.instant("file-format"),
      html
    );
  }

  // Importación de archivo de gateways
  importGateways(files: File[]): void {
    if (files?.length == 0) {
      this.ToastService.fireToastWithConfirmation(
        "warning",
        this.translate.instant("must-file")
      );
    } else {
      this.ToastService.fireAlertWithOptions(
        "warning",
        this.translate.instant("question-import")
      ).then((userConfirmation: boolean) => {
        if (userConfirmation) {
          let requests: any = [];
          files.forEach((file: File) => {
            let formData: FormData = new FormData();
            formData.set("file", file);
            requests.push(this.GatewayController.import(formData));
          });

          forkJoin(requests).subscribe((responses: any[]) => {
            if (responses.every((response) => response["code"] == 0)) {
              this.ToastService.fireToast(
                "success",
                this.translate.instant("success-import")
              );
              this.getData();
            }
          });
        }
      });
    }
  }
}
