// ANCHOR Datos de agrupación
export class AgrupationData {
  // BackEnd
  id: number;
  descripcion: string;
  alias: string;
  entity: number;
  timezone: string;
  mongoCollection?: string;
  mongoCollectionCh?: string;
  ftpPath: string;
  aquacityDomain: string;
  gestionFicherosPath: string;
  apiId: number;
  aqualiaFtpHour: number;
  showAllEntity: boolean;
  ignoreLorawanFrames: boolean;
  hideInVirtualMap: boolean;

  // FrontEnd
  highlightAgrupation?: boolean;

  constructor() {
    this.id = null;
    this.descripcion = null;
    this.alias = null;
    this.entity = null;
    this.timezone = null;
    this.mongoCollection = null;
    this.mongoCollectionCh = null;
    this.ftpPath = null;
    this.aquacityDomain = null;
    this.gestionFicherosPath = null;
    this.apiId = null;
    this.aqualiaFtpHour = null;
    this.showAllEntity = null;
    this.ignoreLorawanFrames = null;
    this.hideInVirtualMap = false;
  }
}

// ANCHOR Log de agrupación
export interface AgrupationLog {
  // BackEnd
  id: number;
  contador: number;
  meterNroSerie: string;
  gateway: number;
  gwUnidadVenta: string;
  username: string;
  eventType: number;
  eventDetails: string;
  responseCommand: number;
  timestamp: number;

  // FrontEnd
  timestampParsed?: string;
  eventTypeParsed?: string;
  responseCommandParsed?: string;
}
