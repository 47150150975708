// @angular
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, forkJoin, takeUntil, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Highcharts
import { Options } from "highcharts";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../services/shared/ReloadComponentService.service";
import { DataAnalysisControllerService } from "../../../../services/server/DataAnalysisController.service";
import { HomeControllerService } from "../../../../services/server/HomeController.service";
import { ToastService } from "../../../../services/shared/ToastService.service";
import { RouteCheckService } from "../../../../services/shared/RouteCheckService.service";
import { DeviceTypeService } from "../../../../services/shared/DeviceTypeService.service";
import { GraphOptionsService } from "../../../../modules/graph-module/GraphOptionsService.service";
import { MapDeviceMinimalParseService } from "../../../../modules/map-module/map-services/MapDeviceMinimalParseService.service";
import { GatewayControllerService } from "../../../../services/server/GatewayController.service";
import { HttpRequestService } from "../../../../services/shared/HttpRequestServices/HttpRequestService.service";
import { HomeService } from "../../home/home.service";
import { DomControllerService } from "../../../../services/shared/DomControllerService.service";
import { DateParserService } from "../../../../services/shared/DateParserService.service";
import { MachineLearningService } from "../../../../services/shared/MachineLearning.service";
// Interfaces
import { Agrupation } from "../../../../interfaces/AgrupationGlobalInterface.type";
import { GraphFilterOptionSelected } from "../../../../modules/graph-module/GraphInterface.type";
import {
  MeterGraphDeviceCoord,
  MeterGraphFoundDevice,
} from "../DataAnalysisInterface.type";
import {
  GraphMeterData,
  GraphRequestData,
  GraphMeterSerie,
  GraphSumatorySerie,
  GraphSumatoryData,
} from "../DataAnalysisInterface.type";
import { MapDevice } from "../../../../interfaces/DeviceGlobalInterface.type";
import { MapGateway } from "../../../../interfaces/GatewayGlobalInterface.type";
import { MaterialSelectConfig } from "../../../../modules/material-module/MaterialInterface.type";
// Variables
import { GRAPH_CONFIG } from "../../../../modules/graph-module/GRAPH_CONFIG";
import { PROFILES } from "../../../../../assets/profiles/profiles";
import { DEVICE_BY_COMM } from "../../../../services/shared/DeviceTypeService.service";
import { GRAPH_TYPES } from "../../devices/devices-common-components/device-consumption-graph/device-consumption-graph.component";
// Componentes
import { MapControllerComponent } from "../../../../modules/map-module/map-controller/map-controller.component";
import { GraphControllerComponent } from "../../../../modules/graph-module/graph-controller/graph-controller.component";

// This is a hack due to a bug in leaflet.draw
// https://github.com/Leaflet/Leaflet.draw/issues/1005
(window as any).type = undefined;

export enum GRAPH_SUMATORY {
  INDIVIDUAL = 1,
  SUM = 2,
}

@Component({
  selector: "app-metergraph",
  templateUrl: "./data-analysis-meter-graph.component.html",
  styleUrls: ["./data-analysis-meter-graph.component.scss"],
})
export class MeterGraphComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;
  readonly GRAPH_SUMATORY = GRAPH_SUMATORY;

  // Mapa
  mapType: string = "meterGraph";
  mapHeight: number = window.innerHeight - 370;
  metersData: MapDevice[];
  gatewaysData: MapGateway[];
  selectedGateway: MapGateway;
  preselectedGateway: number;
  temperatureData: number[][];
  selectedMetersCoord: MeterGraphDeviceCoord;
  selectedMeters: number[];
  preselectedMeters: number[];
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  deviceSelect: MaterialSelectConfig;

  // Gráfica
  isGas: boolean = false;
  from: string;
  to: string;
  metersList: { nroSerie: string; id: number }[];
  graphSeries: (GraphMeterSerie | GraphSumatorySerie)[];
  graphData: any[];
  graphType: number = GRAPH_TYPES.CONSUMPTION;
  sumatory: number = GRAPH_SUMATORY.SUM;
  graphFilters: MaterialSelectConfig[] = [
    {
      title: this.translate.instant("type"),
      options: [
        {
          value: GRAPH_SUMATORY.INDIVIDUAL,
          name: this.translate.instant("graph-individual"),
        },
        {
          value: GRAPH_SUMATORY.SUM,
          name: this.translate.instant("graph-sum"),
        },
      ],
      selected: GRAPH_SUMATORY.SUM,
    },
    {
      title: this.translate.instant("type"),
      options: [],
      selected: GRAPH_TYPES.CONSUMPTION,
    },
  ];
  highchartsOptions: Options;
  chartOptions: object;
  chartConstructor: string = "stockChart";
  defaultDateRange: { startDate: moment.Moment; endDate: moment.Moment } = {
    startDate: moment().startOf("day").subtract("7", "days"),
    endDate: history.state.to
      ? moment(parseInt(history.state.to)).endOf("day")
      : moment().endOf("day"),
  };
  @ViewChild(GraphControllerComponent)
  graphController: GraphControllerComponent;
  showCumulative: boolean;
  prediction: number[][];

  // Calibres
  gaugeList: { value: string }[];
  filteredGauges: string[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private HomeController: HomeControllerService,
    private MapDeviceMinimalParseService: MapDeviceMinimalParseService,
    private DateParserService: DateParserService,
    private DeviceTypeService: DeviceTypeService,
    private DomControllerService: DomControllerService,
    private GatewayController: GatewayControllerService,
    private GraphOptionsService: GraphOptionsService,
    private MachineLearningService: MachineLearningService,
    private HomeService: HomeService,
    private HttpRequestService: HttpRequestService,
    private DataAnalysisController: DataAnalysisControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    if (history.state.data) {
      let preselectedMeters: number[] = history.state.data;
      let metersFiltered: number[] = [];
      preselectedMeters.map((meterId: number) => {
        if (!metersFiltered.includes(meterId)) {
          metersFiltered.push(meterId);
        }
      });
      this.preselectedMeters = [...metersFiltered];
      this.selectedMeters = [...metersFiltered];
    }
    this.getMapData();
  }

  // Obtención de los contadores seleccionados
  selectMeters(selectedMeters: any[]): void {
    let newSelectedMeters: MapDevice[] = [];

    this.metersData.map((meter: MapDevice) => {
      let meterTypeByMask: string = this.DeviceTypeService.getDeviceTypeByMask(
        meter?.tipo,
        meter?.metrologyType
      );
      if (
        meterTypeByMask != DEVICE_BY_COMM.LW_UNE_CON &&
        selectedMeters.some(
          (selectedMeter: MeterGraphFoundDevice) => selectedMeter.id == meter.id
        )
      ) {
        meter.selected = true;
        newSelectedMeters.push(meter);
      } else {
        meter.selected = false;
      }
    });

    this.selectedMeters = newSelectedMeters.map((meter: MapDevice) => {
      return meter.id;
    });
    this.preselectedMeters = [...this.selectedMeters];
    this.updateGaugeList(newSelectedMeters);
  }

  // Actualización de gateway seleccionado
  updateSelectedGateway(gateway: MapGateway, mapUpdate?: boolean): void {
    this.gatewaysData.map((gateway) => (gateway.selected = false));
    this.selectedGateway = gateway;
    if (gateway) {
      this.selectedGateway.selected = true;
    }
    if (mapUpdate) {
      this.preselectedGateway = gateway?.id;
    } else {
      this.mapController.resetGatewayIcons("grayIconUrl");
      if (gateway) {
        this.mapController.updateGatewayIcon(gateway.id, "nodeviceIconUrl");
      }
    }
  }

  // Obtención de los datos del mapa
  getMapData(): void {
    this.HomeController.getMarkers(this.currentAgrupation.id).subscribe(
      (response) => {
        if (response["code"] == 0) {
          let metersData: MapDevice[] =
            this.MapDeviceMinimalParseService.parseDevices(
              response["body"]["contadores"]
            );
          // Filtrado de dispositivos de agrupaciones no visibles en virtual
          if (this.currentAgrupation.showAllEntity) {
            metersData = this.HomeService.filterDisabledAgrupations(metersData);
          }
          this.metersData = metersData.filter((meter: MapDevice) => {
            let meterTypeByMask: string =
              this.DeviceTypeService.getDeviceTypeByMask(
                meter?.tipo,
                meter?.metrologyType,
                meter?.fabricante
              );
            if (meterTypeByMask != DEVICE_BY_COMM.LW_UNE_CON) {
              return meter;
            }
          });
          this.metersData.map((meter: MapDevice) => {
            if (this.selectedMeters?.includes(meter.id)) {
              meter.selected = true;
            } else {
              meter.selected = false;
            }
          });
          this.metersList = this.metersData
            .map((meter: MapDevice) => {
              return {
                nroSerie: meter.nroSerie,
                id: meter.id,
              };
            })
            .sort((a, b) => a?.nroSerie?.localeCompare(b?.nroSerie));
          this.gatewaysData = response["body"]["gateways"];
          if (this.selectedMeters?.length > 0) {
            this.updateGaugeList(
              this.metersList.filter((meter) =>
                this.selectedMeters.includes(meter.id)
              )
            );
            setTimeout(() => this.mapController.updateSelected(), 0);
          }
          this.isGas = this.metersData?.some(
            (meter: MapDevice) => meter.metrologyType == 2
          );
          this.setTypeFilter();
          this.setHighchartsOptions();
        }
      }
    );
  }

  // Obtención de los datos del gráfico
  loadGraphData(from: string, to: string, onlyTemperature?: boolean): void {
    this.prediction = null;
    this.from = from;
    this.to = to;
    let graphData: (GraphMeterData | GraphSumatoryData)[] = [];
    let temperatureData: number[][] = this.selectedGateway ? [] : null;
    let filteredMeters: any[];
    let requests: Observable<object>[] = [];

    // Filtrado por calibre
    if (!onlyTemperature) {
      if (this.filteredGauges?.length > 0) {
        let metersToFilter = this.metersList.filter((meter) =>
          this.selectedMeters.includes(meter.id)
        );
        filteredMeters = metersToFilter
          .filter(
            (meter) => !this.filteredGauges.includes(meter.nroSerie.charAt(4))
          )
          .map((meter) => meter.id);
      } else {
        filteredMeters = [...this.selectedMeters];
      }

      // Aviso de cantidad de contadores elevada
      // if (filteredMeters?.length >= 15) {
      //   this.ToastService.fireToast(
      //     "info",
      //     this.translate.instant("too-much-data")
      //   );
      // }

      // Request
      if (filteredMeters?.length > 0) {
        let data: GraphRequestData = {
          meterList: filteredMeters,
          agrupation: this.currentAgrupation.id,
          fromTimestamp: parseInt(from),
          toTimestamp: parseInt(to),
          graphType: this.graphType,
        };

        requests.push(
          this.DataAnalysisController.getGraphGroup(this.sumatory, data)
        );
      }
    }

    // Temperatura
    if (this.selectedGateway) {
      requests.push(
        this.GatewayController.getGatewayTemperature(
          this.selectedGateway.id,
          from,
          to
        )
      );
    }

    // Peticiones
    if (requests.length > 0) {
      forkJoin(requests)
        .pipe(takeUntil(this.HttpRequestService.getRequestCancel()))
        .subscribe((responses) => {
          if (responses[0]["code"] == 0 && responses[0]["body"]) {
            if (onlyTemperature) {
              temperatureData = responses[0]["body"]
                .map((data: { tm: number; temp: number }) => {
                  return [data.tm, data.temp];
                })
                .sort((a, b) => a[0] - b[0]);
            } else {
              graphData = responses[0]["body"];
            }
          }
          if (
            responses[1] &&
            responses[1]["code"] == 0 &&
            responses[1]["body"]
          ) {
            temperatureData = responses[1]["body"]
              .map((data: { tm: number; temp: number }) => {
                return [data.tm, data.temp];
              })
              .sort((a, b) => a[0] - b[0]);
          }
          this.temperatureData = temperatureData;
          if (onlyTemperature) {
            this.graphController.updateSeriesData(
              "temperature",
              this.temperatureData
            );
          } else {
            this.graphData = graphData;
            this.getSeries();
            this.showCumulative =
              this.isGas &&
              this.sumatory == GRAPH_SUMATORY.SUM &&
              this.graphType == GRAPH_TYPES.ENERGY;
            this.DomControllerService.goToElement("#meters-graph-anchor");
          }
        });
    } else {
      // Evita el error de asincronía de angular por cambio de valor de variable
      setTimeout(() => {
        this.graphData = [];
        this.temperatureData = null;
        this.getSeries();
      }, 0);
    }
  }

  // Obtención de las series de datos para la gráfica
  getSeries(): void {
    const self = this;
    let series: (GraphMeterSerie | GraphSumatorySerie)[] = [];
    if (this.selectedMeters && this.sumatory == GRAPH_SUMATORY.INDIVIDUAL) {
      // Ordenamiento por número de serie
      this.graphData.sort((a, b) => {
        return a.nroSerie.localeCompare(b.nroSerie);
      });

      this.graphData.forEach((element: GraphMeterData, i) => {
        series.push({
          id: element.nroSerie,
          name: element.nroSerie,
          data: element.readings,
          dataGrouping: { approximation: "sum" },
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
            valueDecimals: 3,
          },
          events: {
            click: (event: any) => {
              if (event.altKey) {
                this.graphController.hideSerie(i);
              } else {
                this.ToastService.fireAlertWithOptions(
                  "question",
                  this.translate.instant("question-show-meter") +
                    " " +
                    element.nroSerie +
                    "?"
                ).then((userConfirmation: boolean) => {
                  if (userConfirmation) {
                    this.router.navigate([
                      "/dispositivos/detalle/contador",
                      element.meterId,
                    ]);
                  }
                });
              }
            },
          },
        });
      });
    } else if (this.selectedMeters && this.sumatory == GRAPH_SUMATORY.SUM) {
      series = [
        {
          id: "suma",
          name: this.translate.instant("plus"),
          data: this.graphData["readings"],
          dataGrouping: { approximation: "sum" },
          color: "#000",
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
            valueDecimals: 3,
          },
        },
      ];

      // Predicción
      if (this.prediction) {
        series.push({
          id: "prediction",
          name: self.translate.instant("prediction"),
          data: this.prediction,
          dataGrouping: { approximation: "sum" },
          color: "#ff9800",
          tooltip: {
            valueSuffix:
              this.graphType == GRAPH_TYPES.CONSUMPTION
                ? " m³"
                : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
                ? " Nm³"
                : " kWh",
            valueDecimals: 3,
          },
        });
      }
    } else {
      series = [];
    }

    if (this.temperatureData) {
      series.push({
        id: "temperature",
        name: this.translate.instant("temperature"),
        type: "line",
        marker: {
          symbol: "square",
        },
        tooltip: {
          valueDecimals: 0,
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
          valueSuffix: "°C ",
        },
        data: this.temperatureData,
        color: "#ff9800",
        yAxis: 1,
      });
    }

    this.graphSeries = series;
    this.setChartsOptions();
  }

  // Asignación de las opciones concretas para la gráfica
  setHighchartsOptions(): void {
    let highchartsOptions =
      this.GraphOptionsService.getDefaultHighchartsOptions();
    highchartsOptions.tooltip.split = false;
    this.highchartsOptions = highchartsOptions;
  }

  // Asignación de las opciones concretas para la gráfica
  setChartsOptions(): void {
    let chartOptions: object = JSON.parse(
      JSON.stringify(GRAPH_CONFIG.default.chartOptions)
    );
    chartOptions["legend"]["enabled"] =
      this.sumatory == GRAPH_SUMATORY.INDIVIDUAL ? true : false;
    chartOptions["yAxis"][0]["labels"]["format"] =
      "{value}" +
      (this.graphType == GRAPH_TYPES.CONSUMPTION
        ? " m³"
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? " Nm³"
        : " kWh");
    chartOptions["yAxis"][0]["labels"]["style"] = {
      color: this.sumatory == GRAPH_SUMATORY.SUM ? "#000" : "#42a5f5",
    };
    chartOptions["yAxis"][0]["title"]["text"] =
      this.graphType == GRAPH_TYPES.CONSUMPTION
        ? this.translate.instant("consumption")
        : this.graphType == GRAPH_TYPES.NORMALIZED_CONSUMPTION
        ? this.translate.instant("consumption-normalized")
        : this.translate.instant("energy");
    chartOptions["yAxis"][0]["title"]["style"] = {
      color: this.sumatory == GRAPH_SUMATORY.SUM ? "#000" : "#42a5f5",
      fontWeight: "bold",
    };
    if (this.temperatureData) {
      chartOptions["yAxis"][1] = {
        title: {
          text: this.translate.instant("temperature"),
          style: {
            color: "#ff9800",
            fontWeight: "bold",
          },
        },
        labels: {
          format: "{value} °C ",
          style: {
            color: "#ff9800",
          },
        },
        visible: true,
        opposite: true,
      };
    }
    chartOptions["series"] = this.graphSeries;
    this.chartOptions = chartOptions;
  }

  // Actualización de la selección en el mapa
  updateMapSelection(selectedMeters: MapDevice[]): void {
    this.selectedMeters = selectedMeters.map((meter: MapDevice) => {
      return meter.id;
    });
    this.metersData.map((meter: MapDevice) => {
      if (this.selectedMeters.includes(meter.id)) {
        meter.selected = true;
      } else {
        meter.selected = false;
      }
    });
    this.mapController?.updateSelected();
    this.updateGaugeList(selectedMeters);
  }

  // Actualización del tipo de gráfica
  updateGraphData(selected: GraphFilterOptionSelected): void {
    switch (selected.filterIndex) {
      case 0:
        this.sumatory = selected.value;
        break;
      case 1:
        this.graphType = selected.value;
        break;
      default:
        break;
    }
  }

  // Seteo del filtro de tipo de gráfica
  setTypeFilter(): void {
    if (this.isGas) {
      this.graphFilters[1] = {
        title: this.translate.instant("type"),
        options: [
          {
            value: GRAPH_TYPES.CONSUMPTION,
            name: this.translate.instant("consumption"),
          },
          {
            value: GRAPH_TYPES.NORMALIZED_CONSUMPTION,
            name: this.translate.instant("consumption-normalized"),
          },
          { value: GRAPH_TYPES.ENERGY, name: this.translate.instant("energy") },
        ],
        selected: GRAPH_TYPES.NORMALIZED_CONSUMPTION,
      };
      this.graphType = GRAPH_TYPES.NORMALIZED_CONSUMPTION;
    } else {
      this.graphFilters[1] = {
        title: this.translate.instant("type"),
        options: [
          {
            value: GRAPH_TYPES.CONSUMPTION,
            name: this.translate.instant("consumption"),
          },
        ],
        selected: GRAPH_TYPES.CONSUMPTION,
      };
      this.graphType = GRAPH_TYPES.CONSUMPTION;
    }
  }

  // Actualización del listado de calibres
  updateGaugeList(selectedMeters: any[]): void {
    let gaugeList = [];
    selectedMeters.forEach((meter) => {
      let meterGauge = meter.nroSerie.charAt(4);
      if (!gaugeList.some((gauge) => gauge.value == meterGauge)) {
        gaugeList.push({ value: meterGauge });
      }
    });
    this.gaugeList = gaugeList;
  }

  // Filtrado por calibre
  filterGauge(selectedGauges: { value: string }[]): void {
    this.filteredGauges = selectedGauges.map((gauge) => gauge.value);
  }

  // Predicción de mes
  calculatePrediction(): void {
    let predictionData: GraphSumatoryData[] = [];
    let predictionTimelapse = this.DateParserService.getLastNaturalWeeks(8);
    let data: GraphRequestData = {
      meterList: this.selectedMeters,
      agrupation: this.currentAgrupation.id,
      fromTimestamp: predictionTimelapse.startDate.valueOf(),
      toTimestamp: predictionTimelapse.endDate.valueOf(),
      graphType: this.graphType,
    };

    this.DataAnalysisController.getGraphGroup(2, data).subscribe((response) => {
      if (response["code"] == 0) {
        predictionData = response["body"]?.readings;
        if (predictionData) {
          let weekPattern = this.MachineLearningService.getHourlyMean(
            predictionData.map((data) => data[1])
          );
          let weekDay = moment().isoWeekday();
          weekPattern = [
            ...weekPattern.slice((weekDay - 1) * 24),
            ...weekPattern.slice(0, (weekDay - 1) * 24),
          ];
          this.prediction = [
            ...weekPattern,
            ...weekPattern,
            ...weekPattern,
            ...weekPattern,
          ].map((data, i) => [
            this.DateParserService.getNowTimestamp() + i * 60 * 60 * 1000,
            data,
          ]);
          this.getSeries();
        }
      }
    });
  }
}
