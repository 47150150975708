// @angular
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Moment
import * as moment from "moment";
// Servicios propios
import { SessionDataService } from "../../../../../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../../../../../services/shared/ReloadComponentService.service";
import { MeterControllerService } from "../../../../../../../services/server/MeterController.service";
import { RouteCheckService } from "../../../../../../../services/shared/RouteCheckService.service";
import { DateParserService } from "../../../../../../../services/shared/DateParserService.service";
// Interfaces
import { Entity } from "../../../../../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../../../../../interfaces/AgrupationGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../../../../../modules/table-module/TableInterface.type";
import { DetailDevice, DeviceUserLog } from "../../../../DeviceInterface.type";
import { PanelMenuOption } from "../../../../../../../modules/material-module/MaterialInterface.type";
// Variables
import { PROFILES } from "../../../../../../../../assets/profiles/profiles";

@Component({
  selector: "app-meteruserlog",
  templateUrl: "./meter-log-user.component.html",
  styleUrls: ["./meter-log-user.component.scss"],
})
export class MeterUserLogComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentEntity: Entity;
  entitySub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;
  readonly PROFILES = PROFILES;

  // Table
  tableMaxReg: number = 50;
  dataInitialDate: { startDate: moment.Moment; endDate: moment.Moment } =
    this.DateParserService.getLastDays("7");
  meter: DetailDevice;
  meterUserEvents: DeviceUserLog[];
  orderBy: object = { attribute: "timestamp", reverse: true };
  from: string;
  to: string;
  columns: (TableActionColumn | TableDataColumn)[];

  // Opciones del panel
  panelMenuOptions: PanelMenuOption[];

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private DateParserService: DateParserService,
    private MeterController: MeterControllerService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();

    // Escucha de cambios en agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    // Carga del componente
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    this.agrupationSub.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.meter = history.state.data;
    this.setPanelMenuOptions();
    this.setColumns();
    this.getData(
      this.dataInitialDate.startDate.valueOf().toString(),
      this.dataInitialDate.endDate.valueOf().toString()
    );
  }

  // Seteo de las columnas de la tabla
  setColumns(): void {
    this.columns = [
      {
        title: "sales-unit",
        data: "gwUnidadVenta",
        search: "gwUnidadVenta",
        sort: "gwUnidadVenta",
        visible: true,
      },
      {
        title: "date",
        data: "timestampParsed",
        search: "timestampParsed",
        sort: "timestamp",
        date: true,
        visible: true,
      },
      {
        title: "user",
        data: "username",
        search: "username",
        sort: "username",
        visible: true,
      },
      {
        title: "event",
        data: "eventTypeParsed",
        search: "eventTypeParsed",
        sort: "eventType",
        visible: true,
      },
      {
        title: "details",
        data: "eventDetails",
        search: "eventDetails",
        sort: "eventDetails",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
      {
        title: "command-response",
        data: "responseCommandParsed",
        search: "responseCommandParsed",
        sort: "responseCommandParsed",
        visible: this.sessionProfile == PROFILES.ARSON ? true : null,
      },
    ];
  }

  // Obtención de los datos
  getData(from: string, to: string): void {
    this.from = from;
    this.to = to;
    this.MeterController.getMeterUserEvents(
      this.meter.id,
      this.from,
      this.to
    ).subscribe((response) => {
      if (response["code"] == 0) {
        if (response["body"].length > 0) {
          let meterUserEvents: DeviceUserLog[] = response["body"];
          meterUserEvents.forEach((event: DeviceUserLog) => {
            event.eventTypeParsed =
              event.eventType != null
                ? this.translate.instant("UserEvent" + event.eventType)
                : "-";
            event.responseCommandParsed =
              event.responseCommand != null
                ? this.translate.instant("httpError" + event.responseCommand)
                : "-";
          });
          this.meterUserEvents = meterUserEvents;
        } else {
          this.meterUserEvents = [];
        }
      }
    });
  }

  // Actualización del componente
  updateData(): void {
    this.meterUserEvents = [];
    this.getData(this.from, this.to);
  }

  /***************************************************************************/
  // ANCHOR Panel de menú de componente
  /***************************************************************************/

  // Seteo de las opciones del panel
  setPanelMenuOptions(): void {
    this.panelMenuOptions = [
      {
        action: "event-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("event-log"),
        visible: this.sessionProfile == PROFILES.ARSON,
      },
      {
        action: "communication-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("communication-log"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.currentAgrupation.id == 60,
      },
      {
        action: "frame-log",
        icon: "fas fa-list-alt",
        text: this.translate.instant("frame-log"),
        visible:
          this.sessionProfile == PROFILES.ARSON ||
          this.currentAgrupation.id == 60,
      },
    ];
  }

  // Acciones de las opciones del panel
  menuAction(action: string): void {
    switch (action) {
      case "event-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/eventos/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "communication-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/comunicaciones/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      case "frame-log":
        this.router.navigate(
          ["/dispositivos/detalle/log/tramas/" + this.meter.id],
          { state: { data: this.meter } }
        );
        break;
      default:
        break;
    }
  }
}
