<div class="dialog-container">
  <div class="dialog-header">
    <h5 *ngIf="data.action == 'relaunch'" class="dialog-title">
      {{ "frames-relaunch" | translate }}
    </h5>
    <h5 *ngIf="data.action == 'parser'" class="dialog-title">
      <ng-container *ngIf="data.payload"
        ><b>Payload:</b> {{ data.payload?.toUpperCase() }}</ng-container
      >
      <ng-container *ngIf="data.frame"
        ><b>{{ "frame" | translate }}:</b> {{ data.frame }}</ng-container
      >
    </h5>
  </div>
  <div class="dialog-body">
    <!-- Parseo de trama -->
    <div *ngIf="data.action == 'parser'" class="frames-log-parser">
      <span *ngIf="data.error"
        ><b>{{ "error-text" | translate }}</b></span
      >
      <div
        *ngFor="let key of payloadParsed?.keys"
        class="frames-log-parser-result"
        [ngClass]="{ 'frames-log-parser-result-tab': key.includes('tab') }"
      >
        <span>{{ key.replace("tab", "") }}</span
        ><span>{{ payloadParsed.data[key] }}</span>
      </div>
    </div>

    <!-- Relanzar seleccionados -->
    <div *ngIf="data.action == 'relaunch'" class="frames-log-relaunch">
      <!-- Checks -->
      <mat-checkbox
        class="dialog-checkbox"
        [color]="'primary'"
        [(ngModel)]="relaunchOnlyMic"
        >{{ "frames-relaunch-only-mic" | translate }}</mat-checkbox
      >
      <mat-checkbox
        class="dialog-checkbox"
        [color]="'primary'"
        [(ngModel)]="relaunchWithAlarms"
        >{{ "frames-relaunch-alarms" | translate }}</mat-checkbox
      >
      <button
        mat-raised-button
        color="primary"
        [disabled]="!data?.meters || data?.meters.length == 0"
        (click)="
          SessionDataService.sendDialogAction({
            action: 'relaunchFramesSelected',
            relaunchOnlyMic: relaunchOnlyMic,
            relaunchWithAlarms: relaunchWithAlarms
          });
          SessionDataService.sendDialogAction({ action: 'close' })
        "
      >
        {{ "frames-relaunch-selected" | translate }}
      </button>
      <!-- Relanzar rango de fecha -->
      <div class="frames-log-relaunch-date" *ngIf="!data.avoidDate">
        <!-- Hora inicial -->
        <div>
          <label for="relaunch-init-date"
            >{{ "start-date" | translate }}:</label
          >
          <div>
            <input
              type="date"
              name="relaunch-init-date"
              id="relaunch-init-date"
              [(ngModel)]="relaunchInitDate"
            />
            <input
              type="time"
              name="relaunch-init-hour"
              id="relaunch-init-hour"
              [(ngModel)]="relaunchInitHour"
            />
          </div>
        </div>
        <!-- Hora final -->
        <div>
          <label for="relaunch-init-date">{{ "end-date" | translate }}:</label>
          <div>
            <input
              type="date"
              name="relaunch-final-date"
              id="relaunch-final-date"
              [min]="relaunchInitDate"
              [(ngModel)]="relaunchFinalDate"
              [disabled]="!relaunchInitDate"
            />
            <input
              type="time"
              name="relaunch-final-hour"
              id="relaunch-final-hour"
              [(ngModel)]="relaunchFinalHour"
              [disabled]="!relaunchInitDate"
            />
          </div>
        </div>
        <button
          mat-raised-button
          color="primary"
          [disabled]="!relaunchInitDate || !relaunchFinalDate"
          (click)="
            SessionDataService.sendDialogAction({
              action: 'relaunchFramesInRange',
              relaunchOnlyMic: relaunchOnlyMic,
              relaunchWithAlarms: relaunchWithAlarms,
              relaunchInitDate: relaunchInitDate,
              relaunchInitHour: relaunchInitHour,
              relaunchFinalDate: relaunchFinalDate,
              relaunchFinalHour: relaunchFinalHour
            });
            SessionDataService.sendDialogAction({ action: 'close' })
          "
        >
          {{ "frames-relaunch-date" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button
      mat-button
      (click)="SessionDataService.sendDialogAction({ action: 'close' })"
    >
      {{ "close" | translate }}
    </button>
  </div>
</div>
