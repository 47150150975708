// @angular
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Servicios propios
import { ControlAlertsControllerService } from "../../../services/server/ControlAlertsController.service";
import { SessionDataService } from "../../../services/shared/SessionDataService.service";
import { ReloadComponentService } from "../../../services/shared/ReloadComponentService.service";
import { RouteCheckService } from "../../../services/shared/RouteCheckService.service";
import { ToastService } from "../../../services/shared/ToastService.service";
import { MaterialDialogService } from "../../../modules/material-module/material-dialog/material-dialog.service";
import { DeviceRouteSelectorService } from "../../../services/shared/DeviceRouteSelectorService.service";
import { MapSizeService } from "../../../modules/map-module/map-services/MapSizeService.service";
// Componentes
import { ControlModalComponent } from "./control-modal/control-modal.component";
import { MapControllerComponent } from "../../../modules/map-module/map-controller/map-controller.component";
// Interfaces
import { Client } from "../../../interfaces/ClientGlobalInterface.type";
import { Entity } from "../../../interfaces/EntityGlobalInterface.type";
import { Agrupation } from "../../../interfaces/AgrupationGlobalInterface.type";
import { EntityDefinition } from "../../../interfaces/CupsGlobalInterface.type";
import {
  TableActionColumn,
  TableDataColumn,
} from "../../../modules/table-module/TableInterface.type";
import { ControlCloseValve, ControlAlert } from "./ControlInterface.type";
// Variables
import { PROFILES } from "../../../../assets/profiles/profiles";

@Component({
  selector: "app-control",
  templateUrl: "./control.component.html",
  styleUrls: ["./control.component.scss"],
})
export class ControlComponent implements OnInit, OnDestroy {
  /***************************************************************************/
  // ANCHOR Variables
  /***************************************************************************/

  // Variables de sesión
  currentClient: Client;
  clientSub: Subscription;
  currentEntity: Entity;
  entitySub: Subscription;
  entityCupsConf: EntityDefinition[];
  entityCupsConfSub: Subscription;
  currentAgrupation: Agrupation;
  agrupationSub: Subscription;
  sessionProfile: string;

  dialog: Subscription;

  // Fuente de datos
  dataRequestTimer: any;
  dataRequestTimerMS: number = 30000;

  // Tarjetas
  cardsData: any;

  // Tabla
  alertsList: any;
  rowNumbers: boolean = true;
  tableMaxReg: number = 10;
  onlyTable: boolean = true;
  tableHighlightRow: object[] = [
    { condition: "noAssigned", color: "red-flicker" },
  ];
  columns: (TableActionColumn | TableDataColumn)[] = [
    {
      title: "action",
      data: [
        {
          name: "show-alert",
          tooltip: "show-detail",
          icon: "fas fa-eye",
          visible: { attribute: null, rule: true },
          disabled: false,
        },
      ],
      visible: true,
    },
    {
      title: "serial-number",
      data: "meterNroSerie",
      search: "meterNroSerie",
      sort: "meterNroSerie",
      visible: true,
    },
    {
      title: "state",
      data: "stateParsed",
      search: "stateParsed",
      sort: "stateParsed",
      visible: true,
    },
  ];

  // Mapa
  mapType: string = "control";
  mapData: any;
  mapHeight: number;
  @ViewChild(MapControllerComponent) mapController: MapControllerComponent;
  activateAllLayers: boolean = true;
  @ViewChild("controlPanel") controlPanel: ElementRef;

  // Menú de niveles de alerta
  dataSource: string;
  alertLevels: object[];
  initialAlertLevel: string;

  // Alerta activa
  currentAlert: any;

  /***************************************************************************/
  // ANCHOR Constructor
  /***************************************************************************/

  constructor(
    private ControlAlertsController: ControlAlertsControllerService,
    private DeviceRouteSelectorService: DeviceRouteSelectorService,
    private MapSizeService: MapSizeService,
    private MaterialDialogService: MaterialDialogService,
    private ReloadComponentService: ReloadComponentService,
    private RouteCheckService: RouteCheckService,
    private router: Router,
    private SessionDataService: SessionDataService,
    private ToastService: ToastService,
    private translate: TranslateService
  ) {}

  /***************************************************************************/
  // ANCHOR Inicialización del componente
  /***************************************************************************/

  ngOnInit(): void {
    // Carga de valores iniciales
    this.sessionProfile = this.SessionDataService.getCurrentProfile();
    this.currentAgrupation = this.SessionDataService.getCurrentAgrupation();
    this.currentEntity = this.SessionDataService.getCurrentEntity();
    this.currentClient = this.SessionDataService.getCurrentClient();
    this.entityCupsConf = this.SessionDataService.getCurrentEntityCupsConf();

    // Escucha de cambios en los valores de agrupación
    this.agrupationSub = this.SessionDataService.getAgrupation().subscribe(
      () => {
        this.RouteCheckService.stayOnRoute("agrupation")
          ? this.ReloadComponentService.reload()
          : this.router.navigate(["/principal"]);
      }
    );

    this.entitySub = this.SessionDataService.getEntity().subscribe((entity) => {
      this.currentEntity = entity;
      if (!this.RouteCheckService.stayOnRoute("entity")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.clientSub = this.SessionDataService.getClient().subscribe((client) => {
      this.currentClient = client;
      if (!this.RouteCheckService.stayOnRoute("client")) {
        this.router.navigate(["/principal"]);
      }
    });

    this.dialog = this.SessionDataService.getDialogAction().subscribe(
      (dialogAction: any) => {
        if (dialogAction.action == "reload") {
          this.startRequest();
        }
      }
    );

    this.entityCupsConfSub =
      this.SessionDataService.getEntityCupsConf().subscribe(
        (entityCupsConf) => {
          this.entityCupsConf = entityCupsConf;
        }
      );

    // Inicialización
    if (this.currentAgrupation) {
      this.loadComponent();
    }
  }

  /***************************************************************************/
  // ANCHOR Ejecución tras renderizado
  /***************************************************************************/
  ngAfterViewInit(): void {
    // Ajuste del tamaño del mapa
    setTimeout(() => {
      this.mapHeight = this.MapSizeService.calcMapHeight(
        this.controlPanel,
        null,
        0
      );
    }, 0);
  }

  /***************************************************************************/
  // ANCHOR Destrucción del componente
  /***************************************************************************/

  ngOnDestroy(): void {
    clearInterval(this.dataRequestTimer);
    this.agrupationSub.unsubscribe();
    this.entitySub.unsubscribe();
    this.entityCupsConfSub.unsubscribe();
    this.clientSub.unsubscribe();
    this.dialog.unsubscribe();
  }

  /***************************************************************************/
  // ANCHOR Funciones
  /***************************************************************************/

  // Carga del componente
  loadComponent(): void {
    this.getAlertLevels();
    this.dataSource = "global";
    this.initialAlertLevel = this.dataSource;
    this.startRequest();
  }

  // Obtención de los niveles de alerta
  getAlertLevels(): void {
    this.alertLevels = [
      {
        value: "global",
        name: this.translate.instant("global"),
      },
    ];
    if (this.sessionProfile == PROFILES.ARSON) {
      this.alertLevels.push({
        value: "client",
        name:
          this.translate.instant("client") +
          ": " +
          this.currentClient.clientName,
      });
    }
    if (
      this.sessionProfile == PROFILES.ADMIN_ENTIDAD ||
      this.sessionProfile == PROFILES.USUARIO_ENTIDAD ||
      this.sessionProfile == PROFILES.ADMIN_CLIENTE ||
      this.sessionProfile == PROFILES.USUARIO_CLIENTE ||
      this.sessionProfile == PROFILES.ARSON
    ) {
      this.alertLevels.push({
        value: "entity",
        name:
          this.translate.instant("entity") + ": " + this.currentEntity.entity,
      });
    }
    this.alertLevels.push({
      value: "agrupation",
      name:
        this.translate.instant("groups") + ": " + this.currentAgrupation.name,
    });
  }

  // Comienzo de peticiones
  startRequest(): void {
    clearInterval(this.dataRequestTimer);
    this.getData();
    this.dataRequestTimer = setInterval(
      () => this.getData(),
      this.dataRequestTimerMS
    );
  }

  // Obtención de los datos
  getData(): void {
    this.activateAllLayers = false;
    let httpRequest: Observable<object>;
    let cardsData: any;
    let alertsList: ControlAlert[] = [];
    let mapData: any = {};

    // Petición http dependiendo del selector elegido
    switch (this.dataSource) {
      case "client":
        httpRequest =
          this.ControlAlertsController.getMarkersAndCardClientByClient(
            this.currentClient.clientId
          );
        break;
      case "entity":
        httpRequest =
          this.ControlAlertsController.getMarkersAndCardClientByEntity(
            this.currentEntity.id
          );
        break;
      case "agrupation":
        httpRequest =
          this.ControlAlertsController.getMarkersAndCardClientByAgrupation(
            this.currentAgrupation.id
          );
        break;
      default:
        httpRequest = this.ControlAlertsController.getMarkersAndCardClientAll();
        break;
    }

    // Petición de los datos
    httpRequest.subscribe((response) => {
      if (response["code"] == 0) {
        alertsList = response["body"]["alertList"];
        alertsList.map((alert: ControlAlert) => {
          if (alert.state == 0) {
            alert["stateParsed"] = this.translate.instant("opened");
            alert["noAssigned"] = true;
          } else {
            alert["stateParsed"] = this.translate.instant("assigned");
            alert["noAssigned"] = false;
          }
          alert.meterNroSerieList = `<div class="table-meter-list">`;
          alert.meterNroSerie.forEach((nroSerie) => {
            alert.meterNroSerieList += `<span>` + nroSerie + `</span>`;
          });
          alert.meterNroSerieList += `</div>`;
        });
        this.alertsList = alertsList;
        this.checkCurrentAlert();

        cardsData = {
          controlAlerts: {
            data: response["body"]["cards"]["nroActiveAlarms"],
            type: "number",
          },
        };
        this.cardsData = cardsData;

        mapData = [
          {
            controlAlertsAssigned: response["body"]["alertList"].filter(
              (alert: ControlAlert) => alert.state != 0
            ),
            controlAlertsOpened: response["body"]["alertList"].filter(
              (alert: ControlAlert) => alert.state == 0
            ),
            controlSensors: response["body"]["sensorList"],
            controlValveMeters: response["body"]["closeValveMeters"].filter(
              (meter: ControlCloseValve) => meter.valveState >= 0
            ),
          },
        ];

        this.mapData = { ...mapData };
      }
      setTimeout(() => (this.activateAllLayers = true), 0);
    });
  }

  // Comprobación de si la alerta en curso sigue activa
  checkCurrentAlert() {
    if (
      this.currentAlert &&
      !this.alertsList.find(
        (alert: ControlAlert) => alert?.id == this.currentAlert.id
      )
    ) {
      this.ToastService.fireToast(
        "success",
        this.translate.instant("finish-alarm-already")
      );
      this.SessionDataService.sendDialogAction({ action: "close" });
    }
  }

  // Acciones de la tabla
  tableActions(action: string, alert: ControlAlert): void {
    switch (action) {
      case "show-alert":
        this.showModal(alert);
        break;
      default:
        break;
    }
  }

  // Activación de la alerta
  getModalData(alert: ControlAlert): void {
    this.ControlAlertsController.getInfo(alert.id).subscribe((response) => {
      if (response["code"] == 0 && response["body"]) {
        let data = {
          alertId: alert.id,
          alarmCause: response["body"]["alarmCause"]
            ? this.translate.instant(
                "AlertMeter" + response["body"]["alarmCause"]
              )
            : "",
          user: response["body"]["abonado"],
          cups: response["body"]["cups"],
          cupsName: this.entityCupsConf.find(
            (column: EntityDefinition) => column.colPosition == 0
          )?.name,
          currentAlarm: response["body"]["currentAlarm"],
          currentAlarmParsed:
            response["body"]["currentAlarm"] != null
              ? this.translate.instant(
                  "AlertMeter" + response["body"]["currentAlarm"]
                )
              : this.translate.instant("no-alerts"),
          meters: response["body"]["cups"]?.meters,
          events: response["body"]["events"].map((event) => {
            if (event.contador) {
              event.deviceLink =
                this.DeviceRouteSelectorService.getDeviceRouteUrl(
                  event.metrologyType,
                  event.contador
                );
            }
            return event;
          }),
        };
        if (!data.events) {
          data.events = [];
        }
        this.MaterialDialogService.openDialog(ControlModalComponent, data);
      }
    });
  }

  // Mostrar modal
  showModal(alert: ControlAlert): void {
    this.currentAlert = alert;
    this.getModalData(alert);
  }
}
